import {useTheme} from "@mui/material";
import React from "react";
import {getBubbleHeader} from "../../../../base/plus/ChatPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import {LayoutGap} from "../../layout/LayoutGap";
import BubbleLineHeader from "../line/BubbleLineHeader";

export default function BubbleRawHeader(props: {
  header: IBubbleHeader
  onClickPrimary?: CbMenuAnchor
})
{
  const theme = useTheme();
  const heightLine = theme.common.bubbleHeightLine;
  const text = getBubbleHeader(props.header);
  return (
    <LayoutFlexRow
      width={"100%"}
    >
      {
        text
          ? <BubbleLineHeader
            primary={{
              text: text,
              color: props.header.color || "primary"
            }}
            heightLine={heightLine}
            onClickPrimary={props.onClickPrimary}
          />
          : props.header.alwaysShowHeader &&
          <LayoutGap height={px(heightLine)} />
      }
    </LayoutFlexRow>
  );
}

