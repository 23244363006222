import {useCallback} from "react";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {useAppSelector} from "../../app/AppHooks";
import {BoxPS} from "../../atom/box/BoxPS";
import {CbOnClickListItemInternal} from "../impl/ListItem";
import ListItemShell from "../impl/ListItemShell";
import {TypeEnumCaptionButton} from "../List";

export function ListItemPS(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItemInternal,
})
{
  const itemId = props.itemId;
  const onClickInternal = props.onClickListItem;
  const itemHeight = getListItemHeightAPSA("ps");
  const searchWords = useAppSelector(state => props.selectList(state).searchWords);
  const item = useAppSelector(state => props.selectList(state).itemsById[itemId]) as IListItemAPSA;
  const primary = item?.primary;
  const secondary = item?.secondary;
  const ignoreSelectionCaption = item?.ignoreSelectionCaption;

  const cbOnClickCaption = useCallback((e: React.MouseEvent<HTMLElement>) =>
  {
    onClickInternal && onClickInternal(e.currentTarget, itemId, item, "apsaPrimaryCaption");
  }, [onClickInternal, itemId, item]);

  const onClickIconButtonCaption = useCallback((
    e: React.MouseEvent<HTMLButtonElement>,
    type: TypeEnumCaptionButton) =>
  {
    onClickInternal && onClickInternal(e.currentTarget, itemId, item, type);
  }, [onClickInternal, itemId, item]);

  return (
    <ListItemShell itemHeight={itemHeight} {...props}>
      <BoxPS
        primary={primary}
        secondary={secondary}
        searchWords={searchWords}
        onClickCaption={(!ignoreSelectionCaption && onClickInternal) ? cbOnClickCaption : undefined}
        onClickIconButtonCaption={(!ignoreSelectionCaption && onClickInternal) ? onClickIconButtonCaption : undefined}
        flexGrow={1}
      />
    </ListItemShell>
  );
}
