import {AttachFileRounded} from "@mui/icons-material";
import {ArrowForwardRounded} from "@mui/icons-material";
import {CameraAltRounded} from "@mui/icons-material";
import {EditRounded} from "@mui/icons-material";
import {AddRounded} from "@mui/icons-material";
import {SendRounded} from "@mui/icons-material";
import {CheckRounded} from "@mui/icons-material";
import React from "react";

export type EnumIconFAB =
  | "check"
  | "send"
  | "add"
  | "edit"
  | "camera"
  | "arrow"
  | "attach";

export default function IconFAB(props: {
  type: EnumIconFAB
})
{
  const type = props.type;
  if(type === undefined)
  {
    return null;
  }
  switch(type)
  {
    case "check":
      return <CheckRounded />;
    case "send":
      return <SendRounded />;
    case "add":
      return <AddRounded />;
    case "edit":
      return <EditRounded />;
    case "camera":
      return <CameraAltRounded />;
    case "arrow":
      return <ArrowForwardRounded />;
    case "attach":
      return <AttachFileRounded />;
  }
}
