import {lighten} from "@mui/material";
import {lightBlue} from "@mui/material/colors";
import {deepPurple} from "@mui/material/colors";
import {indigo} from "@mui/material/colors";
import {lightGreen} from "@mui/material/colors";
import {deepOrange} from "@mui/material/colors";
import {lime} from "@mui/material/colors";
import {cyan} from "@mui/material/colors";
import {teal} from "@mui/material/colors";
import {green} from "@mui/material/colors";
import {orange} from "@mui/material/colors";
import {purple} from "@mui/material/colors";
import {pink} from "@mui/material/colors";
import {amber} from "@mui/material/colors";
import {red} from "@mui/material/colors";
import {blue} from "@mui/material/colors";
import {yellow} from "@mui/material/colors";
import {grey} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import {createTheme} from "@mui/material/styles";
import {SvgIconPropsSizeOverrides} from "@mui/material/SvgIcon/SvgIcon";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {OverridableStringUnion} from "@mui/types";
import Color from "color";
import React from "react";
import {EnumDefnThemeDividerKind} from "../../api/meta/base/Types";
import {EnumDefnShowBorderKind} from "../../api/meta/base/Types";
import {EnumDefnThemeColorShade} from "../../api/meta/base/Types";
import {EnumDefnThemeColor} from "../../api/meta/base/Types";
import {ISize} from "../types/TypesGlobal";
import {TypeTextColor} from "../types/TypesGlobal";
import {calcAspectWidth} from "./MathPlus";
import {calcAspectHeight} from "./MathPlus";
import {getAspectRatio} from "./MathPlus";
import {px} from "./StringPlus";

export type CssBackgroundColor = StandardCSSProperties["backgroundColor"];
export type CssColor = StandardCSSProperties["color"];
export type MuiAvatarFontSize = OverridableStringUnion<"inherit" | "large" | "medium" | "small", SvgIconPropsSizeOverrides>;
export type TypeMuiSupportedColor = "primary" | "secondary" | "success" | "error" | "info" | "warning";

type TypeThemeColorShade = "50" | "100" | "200" | "500";

const defaultBackground: CssBackgroundColor = grey[100];
const defaultTheme = createTheme();
const darkTheme = createTheme({palette: {mode: "dark"}});

declare module "@mui/material/styles"
{
  interface TypographyVariants
  {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
  }

  interface TypographyVariantsOptions
  {
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography"
{
  interface TypographyPropsVariantOverrides
  {
    subtitle3: true;
    subtitle4: true;
  }
}

const themeCommon =
  {
    durationSnackbar: 6000,
    durationLoader: 600,
    durationNothingHere: 0,
    durationTransition: defaultTheme.transitions.duration.shorter,
    mediumButtonHeight: 56,

    fgcolorIconDisabled: defaultTheme.palette.text.disabled,
    fgcolorIcon: defaultTheme.palette.text.secondary,
    fgcolorAvatar: defaultTheme.palette.text.secondary,

    bgcolorActionBar: grey[200],
    bgcolorContent: "background.paper" as CssBackgroundColor,
    bgcolorContentChat: "#efeae2", // Color("#fff8eb").hex(),
    bgcolorSidePane: grey[50],
    bgcolorDefault: defaultBackground,

    bgcolorPrompt: Color(blue[50])
    .fade(defaultTheme.palette.action.hoverOpacity)
    .hexa(),

    bgcolorSelection: Color(blue[100])
    .fade(defaultTheme.palette.action.hoverOpacity)
    .hexa(),

    bgcolorHoverSelection: Color(blue[200])
    .fade(defaultTheme.palette.action.hoverOpacity)
    .hexa(),

    bgcolorHighlight: yellow["A200"],
    bgcolorHover: defaultTheme.palette.action.hover,
    bgcolorTooltip: defaultTheme.palette.common.black,
    bgcolorError: red[50],
    bgcolorDarkError: red[300],
    bgcolorGreenLight: "#e8f5e9",
    bgcolorMappingIsPrimary: yellow[50],
    contentShadow: "0 1px 2px rgba(0, 0, 0, 0.12)",

    borderColor: grey[300],
    hyperLinkColor: "#1565c0",

    gapHalf: 8,
    gapStd: 16,
    gapQuarter: 4,
    sizeDivider: 1,
    heightLine: 20,
    heightInbox: 40, // heightLine * 2
    heightFooter: 56, // gapHalf + heightInbox + gapHalf
    sizeDot: 6,
    sizeAvatar: 42, // heightLine + vertInner + heightLine
    vertInner: 2,
    borderRadius: 4,
    listGroupItemGap: 40, // gapStd + collapse iconSize

    smallFieldSize: 40,
    mediumFieldSize: 55,

    listOverScanCount: 12,

    bubbleBgcolorRight: "#d9fdd3", // lightGreen["A100"]
    bubbleBgcolorLeft: "background.paper" as CssBackgroundColor,
    bubbleBgcolorDelete: "rgba(0, 0, 0, 0.08)",
    bubbleBgcolorCenter: "#fefefe",
    bubbleBoxShadowCenter: "0 1px 0.5px rgba(0, 0, 0, 0.13)",
    bubbleBlinkColorRight: "#a3c086",
    bubbleBlinkColorLeft: grey[400],
    bubbleBorderColor: "rgba(0, 0, 0, 0.12)",

    bubbleHeightLine: 24,
    bubblePollBorderRadius: 2,
    bubbleVariantFixedWidth: 328,
    bubbleTextMaxWidth: 700,
    bubbleImageMinWidth: 220,
    bubbleImageMinHeight: 82,
    bubbleImageMaxHeight: 338,
    bubbleImageBorderRadius: 6,
    bubbleShellGap: 2,
    bubbleShellBorder: 1,
    bubbleShellFixedWidth: 334, // bubbleVariantFixedWidth + 2*(bubbleShellGap + bubbleShellBorder)
    bubbleFooterTimerSize: 18,
    bubbleFooterTimerInnerIconSize: 12,
    bubbleFooterItemSpacing: 10,

    bubbleReactionBoxBorderRadius: 26,
    bubbleReactionBoxHeight: 40,
    bubbleReactionIconSize: 30,

    bubbleReactionIconPreviewRadius: 50,

    gridItemMinWidth: 283,
    gridImageMinWidth: 357,
    gridImageMinHeight: 234,

    reportBorder: `1px solid ${grey[300]}`, // sizeDivider solid borderColor,
    reportA4Width: "210mm",
    reportA4Height: "297mm",
    reportMinGridHeight: 200,
    receiptHeight: "140mm",

    appMinHeight: 200,
    appMinMobileWidth: 280,   // drawer | ent | aside
    appMinTabletWidth: 680,   // (drawer + ent) => aside on top
    appMinTabletLandScapeWidth: 1024,   // (drawer + ent) => aside on top
    appMinTabletPortraitWidth: 680,   // (drawer + ent) => aside on top
    appMinDesktopWidth: 1320, // (drawer + ent + aside)
    appMinListWidth: 366,     // bubbleShellFixedWidth + 2*gapStd

    chipHeight: 22,
    chipCrossButtonSize: 16,

    terminalBgcolorSuccess: green[50],
    terminalBgcolorError: red[50],
    terminalBgcolorPending: blue[50],
    bgcolorPrint: "#fffde7", //yellow[50],
    borderColorPrint: "f4f1d0",
    printFontSize: 14,

    defaultLineCount: 3,

    colorWithShade: (color: TypeTextColor, shade?: EnumDefnThemeColorShade): string =>
    {
      const _shade = (shade ? shade?.slice(1) : 100) as TypeThemeColorShade;
      switch(color)
      {
        case "amber":
          return amber[_shade];
        case "blue":
          return blue[_shade];
        case "pink":
          return pink[_shade];
        case "red":
          return red[_shade];
        case "yellow":
          return yellow[_shade];
        case "grey":
          return grey[_shade];
        case "green":
          return green[_shade];
        case "orange":
          return orange[_shade];
        case "purple":
          return purple[_shade];
        case "teal":
          return teal[_shade];
        case "cyan":
          return cyan[_shade];
        case "lime":
          return lime[_shade];
        case "deepOrange":
          return deepOrange[_shade];
        case "lightGreen":
          return lightGreen[_shade];
        case "indigo":
          return indigo[_shade];
        case "deepPurple":
          return deepPurple[_shade];
        case "lightBlue":
          return lightBlue[_shade];

        default:
          return themeCommon.color(color);
      }
    },

    color: (color: TypeTextColor): string =>
    {
      switch(color)
      {
        case "textPrimary":
          return theme.palette.text.primary;
        case "textSecondary":
          return theme.palette.text.secondary;
        case "textDisabled":
          return theme.palette.text.disabled;

        case "primary":
          return theme.palette.primary.main;
        case "primaryDark":
          return theme.palette.primary.dark;
        case "primaryLight":
          return theme.palette.primary.light;

        case "secondary":
          return theme.palette.secondary.main;
        case "secondaryDark":
          return theme.palette.secondary.dark;
        case "secondaryLight":
          return theme.palette.secondary.light;

        case "success":
          return theme.palette.success.main;
        case "successDark":
          return theme.palette.success.dark;
        case "successLight":
          return theme.palette.success.light;

        case "error":
          return theme.palette.error.main;
        case "errorDark":
          return theme.palette.error.dark;
        case "errorLight":
          return theme.palette.error.light;

        case "warning":
          return theme.palette.warning.main;
        case "warningDark":
          return theme.palette.warning.dark;
        case "warningLight":
          return theme.palette.warning.light;

        case "info":
          return theme.palette.info.main;
        case "infoDark":
          return theme.palette.info.dark;
        case "infoLight":
          return theme.palette.info.light;

        case "black":
          return theme.palette.common.black;

        case "white":
          return theme.palette.common.white;

        case "textInverse":
          return theme.palette.background.paper;

        case "transparent":
          return "transparent";

        default:
          return "";
      }
    },

    calcBubbleSize: (actualWidth: number, actualHeight: number, maxWidth?: number): ISize =>
    {
      const bubbleImageMaxWidth = maxWidth ?? theme.common.bubbleVariantFixedWidth;
      const bubbleImageMaxHeight = theme.common.bubbleImageMaxHeight;
      const bubbleImageMinWidth = theme.common.bubbleImageMinWidth;
      const bubbleImageMinHeight = theme.common.bubbleImageMinHeight;

      const aspectRatio = getAspectRatio(actualWidth, actualHeight);

      let width = Math.max(
        Math.min(calcAspectWidth(bubbleImageMaxHeight, aspectRatio), bubbleImageMaxWidth),
        bubbleImageMinWidth
      );

      const height = Math.max(
        Math.min(calcAspectHeight(width, aspectRatio), bubbleImageMaxHeight),
        bubbleImageMinHeight
      );

      return {
        width,
        height
      };
    },

    calcImageSize: (actualWidth: number, actualHeight: number, maxWidth: number, maxHeight: number): ISize =>
    {
      const minImageWidth = maxWidth;
      const minImageHeight = maxHeight;

      const aspectRatio = getAspectRatio(actualWidth, actualHeight);

      const width = Math.max(
        Math.min(calcAspectWidth(maxHeight, aspectRatio), maxWidth),
        minImageWidth
      );

      const height = Math.max(
        Math.min(calcAspectHeight(width, aspectRatio), maxHeight),
        minImageHeight
      );

      return {
        width,
        height
      };
    },

    calcBubbleFixedWidth: (listWidth?: number) =>
    {
      const bubbleShellBorder = theme.common.bubbleShellBorder;
      const bubbleShell = theme.common.bubbleShellGap;
      const gapStd = theme.common.gapStd;
      const gap = ((bubbleShell + bubbleShellBorder) * 2) + (gapStd * 3);
      return listWidth
        ? Math.min((listWidth - gap), theme.common.bubbleVariantFixedWidth)
        : theme.common.bubbleVariantFixedWidth;
    },

    lightColor(color: TypeTextColor)
    {
      switch(color)
      {
        case "info":
        case "error":
        case "primary":
        case "secondary":
        case "success":
        case "warning":
        case "black":
          return lighten(theme.common.color(color), 0.8);
        case "amber":
        case "blue":
        case "cyan":
        case "deepOrange":
        case "deepPurple":
        case "green":
        case "grey":
        case "indigo":
        case "lightBlue":
        case "lightGreen":
        case "lime":
        case "orange":
        case "pink":
        case "purple":
        case "red":
        case "teal":
        case "yellow":
          return theme.common.colorWithShade(color, "s50");
        default:
          return "";
      }
    }
  };

export type IThemeCommon = typeof themeCommon;

// TypeScript module augmentation for custom theme variables
declare module "@mui/material/styles"
{
  // noinspection JSUnusedGlobalSymbols
  interface Theme
  {
    dark: typeof darkTheme;
    common: IThemeCommon;
  }

  // allow configuration using `createTheme`
  // noinspection JSUnusedGlobalSymbols
  interface ThemeOptions
  {
    dark?: typeof darkTheme;
    common?: IThemeCommon;
  }

  // noinspection JSUnusedGlobalSymbols
  interface BreakpointOverrides
  {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    invisible: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme = createTheme({
  spacing: 8,

  palette: {
    background: {
      default: defaultBackground
    }
  },
  typography: {
    subtitle3: {
      fontSize: "12px"
    },
    subtitle4: {
      fontSize: "10px"
    }
  },

  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    }
  },

  dark: darkTheme,
  common: themeCommon as IThemeCommon
});

export default theme;
export const gapStd = theme.common.gapStd;
export const gapHalf = theme.common.gapHalf;
export const gapQuarter = theme.common.gapQuarter;

const BoldSpan = styled("span")({
  fontWeight: 500,
  color: theme.palette.text.primary
});

const ThinSpan = styled("span")({
  fontWeight: 300,
  color: theme.palette.text.primary
});

export function stripIconButtonSx(color?: string)
{
  if(color === undefined)
  {
    color = theme.common.fgcolorIcon;
  }

  return {
    width: "18px",
    height: "inherit",
    alignSelf: "center",
    color: color
  };
}

export function stripIconSx(color?: string)
{
  if(color === undefined)
  {
    color = theme.common.fgcolorIcon;
  }

  return {
    width: "24px",
    height: "inherit",
    alignSelf: "center",
    color: color
  };
}

export function textIconSx(color?: string)
{
  if(color === undefined)
  {
    color = theme.common.fgcolorIconDisabled;
  }

  return {
    mr: "2px",
    width: "14px",
    height: "inherit",
    alignSelf: "center",
    color: color
  };
}

export function getUISupportedColor(color?: EnumDefnThemeColor)
{
  if(color === "transparent")
  {
    return undefined;
  }
  else
  {
    return color;
  }
}

export function getSupportedColor(color?: TypeTextColor): string | undefined
{
  if(color)
  {
    return theme.common.color(color);
  }
}

export function calcPaddingFromEnum(
  showPaddingSet?: EnumDefnShowBorderKind[],
  enumDefnThemeDividerKind?: EnumDefnThemeDividerKind)
{
  const pt = showPaddingSet?.includes("top");
  const pb = showPaddingSet?.includes("bottom");
  const pl = showPaddingSet?.includes("left");
  const pr = showPaddingSet?.includes("right");

  return {
    pt: pt ? getLayoutPadding(enumDefnThemeDividerKind) : 0,
    pb: pb ? getLayoutPadding(enumDefnThemeDividerKind) : 0,
    pl: pl ? getLayoutPadding(enumDefnThemeDividerKind) : 0,
    pr: pr ? getLayoutPadding(enumDefnThemeDividerKind) : 0
  };
}

function getLayoutPadding(enumDefnThemeDividerKind?: EnumDefnThemeDividerKind)
{
  if(enumDefnThemeDividerKind === "thick")
  {
    return gapStd;
  }
  else if(enumDefnThemeDividerKind === "thin")
  {
    return gapHalf;
  }
}

export function calcBorderFromEnum(showBorderSet?: EnumDefnShowBorderKind[])
{
  const bt = showBorderSet?.includes("top");
  const bb = showBorderSet?.includes("bottom");
  const bl = showBorderSet?.includes("left");
  const br = showBorderSet?.includes("right");

  const borderCss = `${px(theme.common.sizeDivider)} solid ${theme.common.borderColor}`;

  return {
    borderTop: bt ? borderCss : undefined,
    borderBottom: bb ? borderCss : undefined,
    borderLeft: bl ? borderCss : undefined,
    borderRight: br ? borderCss : undefined
  };
}

