import {nextMetaIdTranslation} from "../../../../api/meta/base/ApiPlus";
import {StudioEntTranslation} from "../../../../api/meta/base/dto/StudioEntTranslation";
import {MetaIdTranslation} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {TypeStudioFilterType} from "../../../../base/plus/StudioPlus";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {setEntUsageFilter} from "../SliceCacheStudioEnt";
import {removeEntTranslationLanguage} from "../SliceCacheStudioEnt";
import {removeEntTranslation} from "../SliceCacheStudioEnt";
import {addEntTranslation} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntTranslations extends ISrvc
{
  addEntTranslation(entId: EntId, translation: StudioEntTranslation)
  {
    dispatchEnt(entId, addEntTranslation({
      entId: entId,
      sig: {
        ...translation,
        metaId: translation.metaId || nextMetaIdTranslation()
      }
    }));
  }

  removeEntTranslation(entId: EntId, metaIdTranslation: MetaIdTranslation)
  {
    dispatchEnt(entId, removeEntTranslation({
      entId: entId,
      metaId: metaIdTranslation
    }));
  }

  removeEntTranslationLanguage(entId: EntId, translation: StudioEntTranslation, languageKey: string)
  {
    dispatchEnt(entId, removeEntTranslationLanguage({
      entId: entId,
      metaId: translation.metaId,
      languageKey: languageKey
    }));
  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    store.dispatch(setEntUsageFilter({
      entId: entId,
      translationUsageFilter: filterType
    }));
  }
}
