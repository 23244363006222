import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldAvtar} from "../../../../api/meta/base/dto/DefnFieldAvtar";
import {MediaIdAvatar} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {ILineSecondary} from "../../../../base/types/TypesGlobal";
import {ILinePrimary} from "../../../../base/types/TypesGlobal";
import {IAvatar} from "../../../../base/types/TypesGlobal";
import PaneHeader from "../../../atom/pane/PaneHeader";
import {useFormCtx} from "../base/CtxForm";
import FieldBase from "../raw/FieldBase";

export interface FieldValueAPS
{
  primary?: string,
  secondary?: string,
  avatar?: MediaIdAvatar,
}

export default function FieldAvtar(props: {
  defn: DefnFieldAvtar
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const theme = useTheme();
  const fieldId = getFieldKey(defn);
  const gap = theme.common.gapHalf;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState,
        formState
      }) =>
      {
        const fieldValue = field.value as FieldValueAPS | undefined;
        const primary = getPrimary(fieldValue);
        const secondary = getSecondary(fieldValue);
        const avatar = getAvatar(fieldValue);

        return (
          <Box
            overflow={"hidden"}
            minWidth={"40%"}
          >
            <FieldBase
              fieldId={fieldId}
              pt={defn.pt}
              pr={defn.pr}
              pb={defn.pb}
              pl={defn.pl}
              flexGrow={1}
              width={"100%"}
            >

              <PaneHeader
                primaryText={primary?.text}
                secondaryText={secondary?.text}
                avatarSrc1={avatar?.src}
                avatarIcon1={avatar?.icon}
                headerBgColor={theme.common.color("transparent")}
              />
            </FieldBase>
          </Box>
        );
      }}
    />
  );
}

const getAvatar = (fieldValue?: FieldValueAPS): IAvatar | undefined =>
{
  return {
    src: fieldValue?.avatar ? fieldValue.avatar : undefined,
    icon: "enterprise"
  };
};

const getPrimary = (fieldValue?: FieldValueAPS): ILinePrimary | undefined =>
{
  return {
    text: fieldValue?.primary
  };
};

const getSecondary = (fieldValue?: FieldValueAPS): ILineSecondary | undefined =>
{
  if(fieldValue?.secondary)
  {
    return {
      text: fieldValue.secondary
    };
  }
};
