import {isEmpty} from "lodash";
import {useMemo} from "react";
import {useCallback} from "react";
import {useState} from "react";
import {useEffect} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldRef} from "../../../../api/meta/base/dto/DefnFieldRef";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";

type typeRefButton =
  | "add"
  | "refresh"
  | "delete"

export default function FieldRawRefButton(props: {
  defn: DefnField,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const hookFormCtx = useFormContext();

  const defn = props.defn;

  const fieldId = defn.metaId;
  const cbOnClick = formCtx.getOnClick();
  const tooltip = formCtx.getTooltipMap && formCtx.getTooltipMap(fieldId);
  const values = hookFormCtx.getValues && hookFormCtx.getValues();
  const defnFormUi = formSectionCtx.getDefnForm();
  const parentRefId = formCtx.getRefChildParentId && formCtx.getRefChildParentId(fieldId);
  const fieldRef = parentRefId
    ? defnFormUi.compMap[parentRefId] as DefnFieldRef
    : undefined;
  const copyFieldMap = fieldRef?.copyFieldMap;
  const refCopyFieldId = copyFieldMap
    ? copyFieldMap[fieldId]
    : undefined;
  const canCreateRefRecord = fieldRef?.canCreateRefRecord;
  const refreshOn = fieldRef?.refreshOn;
  const showRefreshOnFieldIdSet = fieldRef?.showRefreshOnFieldIdSet;

  const [refFirstFieldId, setRefFirstFieldId] = useState<MetaIdField>();
  const [showRefDelete, setShowRefDelete] = useState<boolean>(false);

  const showRefRefresh = useMemo(() =>
  {
    if(refreshOn === "refreshOnDemand")
    {
      if(showRefreshOnFieldIdSet
        && refCopyFieldId
        && showRefreshOnFieldIdSet.includes(refCopyFieldId))
      {
        return true;
      }
      else if(!showRefreshOnFieldIdSet?.length)
      {
        return true;
      }
    }

    return false;

  }, [showRefreshOnFieldIdSet, refCopyFieldId]);

  const onClickIcon = useCallback((icon: typeRefButton) =>
  {
    if(icon === "add")
    {
      cbOnClick && cbOnClick(fieldId, "refAddNew");
    }
    else if(icon === "refresh")
    {
      cbOnClick && cbOnClick(fieldId, "refRefresh");
    }
    else if(icon === "delete" && parentRefId)
    {
      cbOnClick && cbOnClick(parentRefId, "refDelete");
      setShowRefDelete(false);
    }

  }, [cbOnClick, fieldId, parentRefId]);

  const isRefFirstFieldId = useMemo(() => refFirstFieldId === fieldId,
    [refFirstFieldId, fieldId]
  );

  useEffect(() =>
  {
    if(!isEmpty(copyFieldMap))
    {
      const copyFieldMapKeys = Object.keys(copyFieldMap);

      for(let i = 0; i < copyFieldMapKeys.length; i++)
      {
        const copyFieldId = copyFieldMapKeys[i];
        const field = defnFormUi.compMap[copyFieldId];

        if(!field.invisible && !field.hidden)
        {
          if(fieldId === copyFieldId)
          {
            setRefFirstFieldId(fieldId);
          }
          break;
        }
      }
    }

  }, [copyFieldMap, fieldId]);

  useEffect(() =>
  {
    if(!isEmpty(copyFieldMap)
      && !isEmpty(values)
      && isRefFirstFieldId)
    {
      const copyFieldMapKeys = Object.keys(copyFieldMap);

      for(let i = 0; i < copyFieldMapKeys.length; i++)
      {
        const copyFieldId = copyFieldMapKeys[i];

        if(!isEmpty(values[copyFieldId]))
        {
          setShowRefDelete(true);
          break;
        }
      }
    }

  }, [isRefFirstFieldId, values]);

  return <RawRefButton
    showRefCreateRecord={canCreateRefRecord && isRefFirstFieldId}
    showRefRefresh={showRefRefresh}
    showRefDelete={showRefDelete && isRefFirstFieldId}
    cbOnClick={onClickIcon}
    tooltip={tooltip?.plusButton}
  />;
}

function RawRefButton(props: {
  showRefCreateRecord?: boolean,
  showRefRefresh?: boolean,
  showRefDelete?: boolean,
  tooltip?: string,
  cbOnClick?: (icon: typeRefButton) => void
})
{
  const {
    showRefCreateRecord,
    cbOnClick,
    showRefRefresh,
    showRefDelete
  } = props;

  if(!(showRefCreateRecord || showRefRefresh || showRefDelete))
  {
    return null;
  }

  return (
    <LayoutFlexRow>
      {
        showRefRefresh &&
        <RawIconStrip
          iconStrip={["refresh"]}
          onClick={(icon) =>
          {
            if(icon === "refresh")
            {
              cbOnClick && cbOnClick("refresh");
            }
          }}
        />
      }

      {
        showRefCreateRecord &&
        <RawIconStrip
          iconStrip={["add"]}
          onClick={(icon) =>
          {
            if(icon === "add")
            {
              cbOnClick && cbOnClick("add");
            }
          }}
          toolTipMap={{
            add: props.tooltip ?? ""
          } as Record<EnumIconStrip, string>}
        />
      }

      {
        showRefDelete &&
        <RawIconStrip
          iconStrip={["delete"]}
          onClick={(icon) =>
          {
            if(icon === "delete")
            {
              cbOnClick && cbOnClick("delete");
            }
          }}
        />
      }
    </LayoutFlexRow>
  );
}
