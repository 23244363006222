import {StudioVarValueScheduler} from "../../../api/meta/base/dto/StudioVarValueScheduler";
import {EntId} from "../../../api/meta/base/Types";
import {MsgScheduleNextExecutionList} from "../../../api/studio/studioMain/msg/MsgScheduleNextExecutionList";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigScheduleNextExecutionList} from "../../../api/studio/studioMain/sig/SigScheduleNextExecutionList";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioApp extends ISrvc
{
  // variable scheduler
  selectVariableSchedulerNextExecutionList(state: RootState)
  {
    return state.studio.app.variableSchedulerNextExecutionList;
  }

  rpcStudioVariableSchedulerExecutionList(
    listName: string,
    entId: EntId,
    studioVarValueScheduler: StudioVarValueScheduler,
    iterations?: number,
    onError?: () => void)
  {
    const msg = {
      iterations: iterations,
      studioVarValueScheduler: studioVarValueScheduler
    } as MsgScheduleNextExecutionList;

    RpcStudioMain.entScheduleNextExecutionListGet(entId, msg, (envSig) =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        if(envSig.sig)
        {
          this.doLoad(listName, envSig.sig, () =>
          {
            onError && onError();
          });
        }
      }
      else
      {
        onError && onError();
      }
    });
  }

  private doLoad(
    listName: string,
    sig: SigScheduleNextExecutionList,
    onNoDataFound?: () => void
  )
  {
    const dateList = sig.dateList;

    if(dateList.length === 0)
    {
      onNoDataFound && onNoDataFound();
      return;
    }

    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    dateList.forEach((dateValue) =>
    {
      if(dateValue)
      {
        uiItemIds.push(dateValue);

        uiItemsById[dateValue] = {
          type: "p",
          primary: {
            text: dateValue
          },
          hideMenu: true,
          ignoreSelection: true
        } as IListItemAPSA;
      }
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));

  }

  // variable scheduler
}
