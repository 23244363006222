import React, {useRef, useState} from "react";
import {AudioBarDurationTimer} from "../../../atom/audioBar/AudioBarDurationTimer";
import AudioBarPlayButton from "../../../atom/audioBar/AudioBarPlayButton";
import {AudioBarPlayer} from "../../../atom/audioBar/AudioBarPlayer";
import {IAudioBarRef} from "../../../atom/audioBar/AudioBarPlayer";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";

type RawWaveAudioBarProps = {
  cursorColor?: string;
  progressColor?: string;
  waveColor?: string;
  src?: string;
  scrollable?: boolean,
  durationMs?: number,
  showDuration?: boolean,
  caption?: React.ReactNode,
};

export default function RawFieldAudioBar(props: RawWaveAudioBarProps)
{
  const [isPlaying, setIsPlaying] = useState(false);
  const [timer, setTimer] = useState<number>();
  const audioBarRef = useRef<IAudioBarRef>(null);

  return (
    <LayoutFlexRow
      alignItems={"center"}
      justifyContent={"center"}
      flexGrow={1}
      width="100%"
      onClick={(e: React.MouseEvent<HTMLElement>) =>
      {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <AudioBarPlayButton
        isPlaying={isPlaying}
        cbOnPlay={audioBarRef.current?.play}
        cbOnPause={audioBarRef.current?.pause}
      />

      <LayoutFlexRow
        width={"85%"}
        flexGrow={1}
        height={"20px"}
      >
        <AudioBarPlayer
          {...props}
          ref={audioBarRef}
          onStartPlaying={(currentDurationMs) =>
          {
            setTimer(currentDurationMs * 1000);
            setIsPlaying(true);
          }}
          onStopPlaying={() => setIsPlaying(false)}
          onChangeDuration={value => value !== undefined
            ? setTimer(value * 1000)
            : setTimer(undefined)}
        />
        <AudioBarDurationTimer
          durationMs={timer !== undefined ? timer : props.durationMs}
          showDuration={props.showDuration}
          caption={props.caption}
        />
      </LayoutFlexRow>
    </LayoutFlexRow>
  );
};



