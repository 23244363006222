import {Visibility} from "@mui/icons-material";
import {VisibilityOff} from "@mui/icons-material";
import {FilledInput} from "@mui/material";
import {Input} from "@mui/material";
import {OutlinedInput} from "@mui/material";
import {IconButton} from "@mui/material";
import {InputAdornment} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {InputLabel} from "@mui/material";
import {FormControl} from "@mui/material";
import * as React from "react";
import {SyntheticEvent} from "react";
import {useState} from "react";
import {Controller} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {Noop} from "react-hook-form";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPassword} from "../../../../api/meta/base/dto/DefnFieldPassword";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import theme from "../../../../base/plus/ThemePlus";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldPassword(props: {
  defn: DefnFieldPassword
})
{
  const [showPassword, setShowPassword] = useState(false);

  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const labelText = getCompLabel(defn);
  const label = Boolean(defn.required)
    ? labelText + " *"
    : labelText;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const padding = getDefnFieldPadding(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as FieldValueText;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <RawFieldPassword
              defnPassword={defn}
              fieldId={fieldId}
              error={error}
              showPassword={showPassword}
              value={field.value}
              onBlur={field.onBlur}
              onChange={field.onChange}
              onClick={() => setShowPassword(!showPassword)}
              defnTheme={defnTheme}
              label={label}
              isError={isError}
              disabled={formCtx.isFieldDisable(props.defn as DefnFieldEditable)}
            />
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawFieldPassword(props: {
  fieldId: MetaIdField,
  defnPassword: DefnFieldPassword,
  value: FieldValueText,
  showPassword: boolean
  onChange: (value?: FieldValueText) => void,
  onClick: () => void,
  defnTheme: DefnDtoFormTheme,
  onBlur?: Noop;
  disabled?: boolean,
  isError?: boolean,
  label?: string,
  error?: FieldError
})
{
  const {
    defnPassword,
    onBlur,
    onChange,
    label,
    isError,
    defnTheme,
    disabled,
    fieldId,
    value,
    showPassword,
    onClick,
    error
  } = props;
  return (
    <FormControl
      fullWidth
      variant={defnTheme.fieldVariant}
      size={defnTheme.fieldSize}
      margin={defnTheme.fieldMargin}
      disabled={disabled}
    >
      <InputLabel
        htmlFor={fieldId}
        error={isError}
      >
        {label}
      </InputLabel>

      <LocalInput
        fieldVariant={defnTheme.fieldVariant}
        showPassword={showPassword}
        defn={defnPassword}
        label={label}
        value={value}
        onBlur={onBlur}
        onChange={(event) => onChange && onChange(fnRawValueToFieldValue("text",
          event.currentTarget.value
        ) as FieldValueText | undefined)}
        error={isError ?? false}
        onClick={onClick}
        onMouseDown={event => event.preventDefault()}
      />

      {
        isError ? (
          <FormHelperText
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexGrow: 1
            }}
            error={isError}
          >
            {error?.message}
          </FormHelperText>
        ) : (
          <FormHelperText
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexGrow: 1,
              ml: 0,
              mt: 0,
              fontStyle: "italic",
              color: theme.common.color("textDisabled")
            }}
            error={false}
          >
            {defnPassword.helperTextVar?.value}
          </FormHelperText>
        )

      }
    </FormControl>
  );
}

function LocalInput(props: {
  fieldVariant?: EnumDefnThemeFieldVariant,
  showPassword: boolean,
  defn: DefnFieldText,
  label: string | undefined,
  value?: FieldValueText,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  error: boolean,
  onClick: () => void,
  onMouseDown: (event: SyntheticEvent) => void
})
{
  return (
    <>
      {
        props.fieldVariant === "outlined"
          ? <LocalOutlinedInput {...props} />
          : props.fieldVariant === "filled"
            ? <LocalFilledInput {...props} />
            : <LocalStandardInput {...props} />
      }
    </>
  );
}

function LocalOutlinedInput(props: {
  showPassword: boolean,
  defn: DefnFieldText,
  label: string | undefined,
  value?: FieldValueText,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  error: boolean,
  onClick: () => void,
  onMouseDown: (event: SyntheticEvent) => void
})
{
  const formCtx = useFormCtx();
  const fieldKey = getFieldKey(props.defn);

  return (
    <OutlinedInput
      type={props.showPassword ? "text" : "password"}

      // props dependent
      autoComplete={Boolean(props.defn.autoFill) ? "on" : "off"}
      autoFocus={Boolean(props.defn.autoFocus)}
      name={fieldKey}
      id={fieldKey}
      placeholder={defnDtoTextToString(props.defn.placeHolderVar)}
      readOnly={formCtx.isReadonly()}
      required={Boolean(props.defn.required)}
      value={props.value?.value ?? ""}
      onBlur={props.onBlur}
      onChange={props.onChange}
      error={props.error}
      label={props.label}

      // extra
      endAdornment={
        <LocalEndAdornment
          onClick={props.onClick}
          onMouseDown={props.onMouseDown}
          showPassword={props.showPassword}
        />
      }
    />
  );
}

function LocalFilledInput(props: {
  showPassword: boolean,
  defn: DefnFieldText,
  label: string | undefined,
  value?: FieldValueText,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  error: boolean,
  onClick: () => void,
  onMouseDown: (event: SyntheticEvent) => void
})
{
  const formCtx = useFormCtx();
  const fieldKey = getFieldKey(props.defn);
  return (
    <FilledInput
      type={props.showPassword ? "text" : "password"}

      // props dependent
      autoComplete={Boolean(props.defn.autoFill) ? "on" : "off"}
      autoFocus={Boolean(props.defn.autoFocus)}
      name={fieldKey}
      id={fieldKey}
      placeholder={defnDtoTextToString(props.defn.placeHolderVar)}
      readOnly={formCtx.isReadonly()}
      required={Boolean(props.defn.required)}
      value={props.value?.value ?? ""}
      onBlur={props.onBlur}
      onChange={props.onChange}
      error={props.error}

      // extra
      endAdornment={
        <LocalEndAdornment
          onClick={props.onClick}
          onMouseDown={props.onMouseDown}
          showPassword={props.showPassword}
        />
      }
    />
  );
}

function LocalStandardInput(props: {
  showPassword: boolean,
  defn: DefnFieldText,
  label: string | undefined,
  value?: FieldValueText,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  error: boolean,
  onClick: () => void,
  onMouseDown: (event: SyntheticEvent) => void
})
{
  const formCtx = useFormCtx();
  const fieldKey = getFieldKey(props.defn);

  return <Input
    type={props.showPassword ? "text" : "password"}

    // props dependent
    autoComplete={Boolean(props.defn.autoFill) ? "on" : "off"}
    autoFocus={Boolean(props.defn.autoFocus)}
    name={fieldKey}
    id={fieldKey}
    placeholder={defnDtoTextToString(props.defn.placeHolderVar)}
    readOnly={formCtx.isReadonly()}
    required={Boolean(props.defn.required)}
    value={props.value?.value ?? ""}
    onBlur={props.onBlur}
    onChange={props.onChange}
    error={props.error}

    // extra
    endAdornment={
      <LocalEndAdornment
        onClick={props.onClick}
        onMouseDown={props.onMouseDown}
        showPassword={props.showPassword}
      />
    }
  />;
}

function LocalEndAdornment(props: {
  onClick: () => void,
  onMouseDown: (event: React.SyntheticEvent) => void,
  showPassword: boolean
})
{
  return <InputAdornment position="end">
    <IconButton
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      size="small"
      edge="end"
    >
      {props.showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
    </IconButton>
  </InputAdornment>;
}
