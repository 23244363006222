import {useTheme} from "@mui/material";
import React from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {MAX_ASIDE_AND_DRAWER_WIDTH} from "../../base/plus/ConstantsPlus";
import {mapToRecord} from "../../base/plus/JsPlus";
import {textAvatar} from "../../base/plus/SrvcPlus";
import {TypeDeviceType} from "../../base/types/TypesGlobal";
import {ISize} from "../../base/types/TypesGlobal";
import type {AppDispatch, RootState} from "../../Store";
import {minAsideWidthDesktop} from "../template/TemplateAppDesktop";
import {minDrawerWidthDesktop} from "../template/TemplateAppDesktop";

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function usePrevious<T>(value: T)
{
  const ref = useRef<T>();
  useEffect(() =>
  {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useWinSize(): ISize
{
  const [size, setSize] = useState<ISize>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() =>
  {
    const handleResize = () => setSize({
      width: window.innerWidth,
      height: window.innerHeight
    });

    window.addEventListener("resize", handleResize);

    return () =>
    {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
}

export function useScreeHeight(): number
{
  const [height, setHeight] = useState(window.screen.height);

  useEffect(() =>
  {
    const handleOrientationChange = () => setHeight(window.screen.height);

    window.addEventListener("orientationchange", handleOrientationChange);

    return () =>
    {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return height;
}

export function useWinWidth(): number
{
  const [value, setValue] = useState(window.innerWidth);

  useEffect(() =>
  {
    const handleResize = () => setValue(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () =>
    {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return value;
}

export function useWinHeight(): number
{
  const [value, setValue] = useState(window.innerHeight);

  useEffect(() =>
  {
    const handleResize = () => setValue(window.innerHeight);

    window.addEventListener("resize", handleResize);

    return () =>
    {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return value;
}

export function useUrlSearchParams(): URLSearchParams
{
  const {search} = useLocation();
  const encodedUrl = search.replaceAll("+", "%2B");
  /*
  `+` sign has been replaced with a space character
 to preserve the + sign in the query string, you will need to encode it as %2B before passing
 */

  return React.useMemo(() => new URLSearchParams(encodedUrl), [search]);
}

export function useUpdateUrlSearchParams(): [
  URLSearchParams, (
    operation: "remove" | "append",
    paramKey: string,
    paramValue?: string) => void
]
{
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const updateUrlSearchParams = (operation: "remove" | "append", paramKey: string, paramValue?: string) =>
  {
    const oldParams = mapToRecord(urlSearchParams.entries());

    switch(operation)
    {
      case "append":
        paramValue && setUrlSearchParams({
          ...oldParams,
          [paramKey]: paramValue
        });
        break;
      case "remove":
        const newParams: Record<string, string> = {};
        Object.entries(oldParams).forEach(([_paramKey, _paramValue]) =>
        {
          if(paramKey !== _paramKey)
          {
            newParams[_paramKey] = _paramValue;
          }
        });
        setUrlSearchParams(newParams);
        break;
    }
  };

  return [urlSearchParams, updateUrlSearchParams];
}

export function useDocumentTitle(title: string, prevailOnUnmount = false)
{
  const defaultTitle = useRef(document.title);

  useEffect(() =>
  {
    document.title = title;
  }, [title]);

  useEffect(() => () =>
  {
    if(!prevailOnUnmount)
    {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount]);
}

export function useTabTitle(suffix?: string)
{
  const callerFirstName = useAppSelector(state => state.cache.app.caller.callerFirstName);
  const callerLastName = useAppSelector(state => state.cache.app.caller.callerLastName);
  const aggBadgeCount = useAppSelector(state => state.cache.app.badge.aggBadgeCount);

  let title = "";
  if(aggBadgeCount > 0)
  {
    title += `(${aggBadgeCount}) `;
  }

  const avatar = textAvatar(callerFirstName, callerLastName);
  if(avatar)
  {
    title += avatar + " ";
  }

  if(suffix)
  {
    title += "- " + suffix;
  }

  useDocumentTitle(title);
}

export function useDeviceType(): TypeDeviceType
{
  const theme = useTheme();
  const winWidth = useWinWidth();

  const appMinMobileWidth = theme.common.appMinMobileWidth;
  const appMinTabletWidth = theme.common.appMinTabletWidth;
  const appMinDesktopWidth = theme.common.appMinDesktopWidth;

  if(winWidth >= appMinMobileWidth && winWidth < appMinTabletWidth)
  {
    return "mobile";
  }
  else if(winWidth >= appMinTabletWidth && winWidth < appMinDesktopWidth)
  {
    return "tablet";
  }
  else if(winWidth >= appMinDesktopWidth)
  {
    return "desktop";
  }
  else
  {
    return "desktop";
  }
}

export function useAppSizeDesktop(props: {
  winWidth: number,
  drawerVisible: boolean,
  asideVisible: boolean,
})
{
  const theme = useTheme();
  const sizeDivider = theme.common.sizeDivider;
  const appMinDesktopWidth = theme.common.appMinDesktopWidth;
  const winWidth = props.winWidth;

  const maxDrawerWidth = MAX_ASIDE_AND_DRAWER_WIDTH;
  const maxAsideWidth = MAX_ASIDE_AND_DRAWER_WIDTH;

  const proportionDrawer = 1;
  const proportionMain = 4;
  const proportionAside = 1;

  let drawerWidth = 0;
  let asideWidth = 0;
  if(props.drawerVisible)
  {
    const delta = (winWidth - appMinDesktopWidth) / (proportionDrawer + proportionMain + proportionAside);

    if(props.asideVisible)
    {
      drawerWidth = minDrawerWidthDesktop + (delta * proportionDrawer);
      asideWidth = minAsideWidthDesktop + (delta * proportionAside);
    }
    else
    {
      drawerWidth = minDrawerWidthDesktop + (delta * proportionDrawer);
    }
  }
  else if(props.asideVisible)
  {
    const delta = (winWidth - appMinDesktopWidth) / (proportionMain + proportionAside);
    asideWidth = minAsideWidthDesktop + delta;
  }

  let dividerWidth = sizeDivider * 2;

  drawerWidth = Math.floor(drawerWidth);
  if(drawerWidth < minDrawerWidthDesktop)
  {
    drawerWidth = 0;
    dividerWidth -= sizeDivider;
  }
  else if(drawerWidth > maxDrawerWidth)
  {
    drawerWidth = maxDrawerWidth;
  }

  asideWidth = Math.floor(asideWidth);
  if(asideWidth < minAsideWidthDesktop)
  {
    asideWidth = 0;
    dividerWidth -= sizeDivider;
  }
  else if(asideWidth > maxAsideWidth)
  {
    asideWidth = maxAsideWidth;
  }

  let mainWidth = winWidth - drawerWidth - asideWidth - dividerWidth;

  const increasedAsideWidth = asideWidth * 0.5;

  return {
    drawerWidth: drawerWidth,
    asideWidth: !props.drawerVisible ? asideWidth + increasedAsideWidth : asideWidth,
    mainWidth: !props.drawerVisible ? mainWidth - increasedAsideWidth : mainWidth
  };
}

export function useAppSizeTablet(props: {
  winWidth: number,
  drawerVisible: boolean,
  asideVisible: boolean
})
{
  const theme = useTheme();
  const sizeDivider = theme.common.sizeDivider;
  const appMinTabletWidth = theme.common.appMinTabletWidth;
  const winWidth = props.winWidth;

  const minDrawerWidth = 260;
  const maxDrawerWidth = minDrawerWidthDesktop;

  const proportionDrawer = 1;
  const proportionMain = 4;

  let drawerWidth = 0;
  if(props.drawerVisible)
  {
    const delta = (winWidth - appMinTabletWidth) / (proportionDrawer + proportionMain);
    drawerWidth = minDrawerWidth + (delta * proportionDrawer);
  }

  let dividerWidth = sizeDivider;

  drawerWidth = Math.floor(drawerWidth);
  if(drawerWidth < minDrawerWidth)
  {
    drawerWidth = 0;
    dividerWidth -= sizeDivider;
  }
  else if(drawerWidth > maxDrawerWidth)
  {
    drawerWidth = maxDrawerWidth;
  }

  let mainWidth = winWidth - drawerWidth - dividerWidth;

  const increasedAsideWidth = minAsideWidthDesktop + 0.5 * minAsideWidthDesktop;

  return {
    drawerWidth: drawerWidth,
    asideWidth: props.asideVisible ? !props.drawerVisible ? increasedAsideWidth : minAsideWidthDesktop : 0,
    mainWidth: mainWidth
  };
}
