import {cloneDeep} from "lodash";
import {MsgReportShare} from "../../../api/ent/entMain/msg/MsgReportShare";
import {MsgSpreadsheetEditorShare} from "../../../api/ent/entMain/msg/MsgSpreadsheetEditorShare";
import {MsgSpreadsheetInsertShare} from "../../../api/ent/entMain/msg/MsgSpreadsheetInsertShare";
import {MsgSpreadsheetRowShare} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowShare";
import {RpcEntMain} from "../../../api/ent/entMain/RpcEntMain";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {MetaIdReport} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {MetaIdAction} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {removeUnnecessaryFieldValues} from "../../../nucleus/form/viewer/base/FormViewerPlus";

export default class SrvcHomeAsideShare extends ISrvc
{
  getReportDeepLink(
    entId: EntId,
    actionId: MetaIdAction,
    reportId: MetaIdReport,
    inputFormValueRaw?: FormValueRaw,
    cbSuccess?: (link: string) => void,
    cbError?: (msg: string | undefined) => void
  )
  {
    const msg: MsgReportShare = {
      actionId: actionId,
      reportId: reportId,
      inputFormValueRaw: this.filterFormValueRaw(inputFormValueRaw)
    };

    RpcEntMain.entReportShare(entId, msg, (envSig) =>
    {
      if(envSig.sig?.url)
      {
        cbSuccess && cbSuccess(envSig.sig?.url);
      }
      else if(envSig.error?.validationErrors)
      {
        if(envSig.error?.validationErrors[0].errorCode === "ruleViolation")
        {
          cbError && cbError(envSig.error?.validationErrors[0].errorMessage);
        }
      }
    });
  }

  getSpreadsheetRowDeeplink(
    entId: EntId,
    rowId: RowId,
    spreadsheetId: MetaIdSpreadsheet,
    reset?: boolean,
    cbSuccess?: (link: string) => void,
    cbError?: (msg: (string | undefined)) => void
  )
  {
    const msg: MsgSpreadsheetRowShare = {
      rowId: rowId,
      spreadsheetId: spreadsheetId,
      reset: reset
    };

    RpcEntMain.entSpreadsheetRowShare(entId, msg, (envSig) =>
    {
      if(envSig.sig?.url)
      {
        cbSuccess && cbSuccess(envSig.sig.url);
      }
      else if(envSig.error?.validationErrors)
      {
        if(envSig.error?.validationErrors[0].errorCode === "ruleViolation")
        {
          cbError && cbError(envSig.error?.validationErrors[0].errorMessage);
        }
      }
    });
  }

  getSpreadsheetInsertDeeplink(
    entId: EntId,
    chatId: ChatId,
    metaIdAction: MetaIdAction,
    cbSuccess: (link: string) => void,
    formValueRaw?: FormValueRaw,
    reset?: boolean,
    cbError?: (msg: string | undefined) => void
  )
  {
    const msg: MsgSpreadsheetInsertShare = {
      chatId: chatId,
      actionId: metaIdAction,
      reset: reset,
      formValueRaw: this.filterFormValueRaw(formValueRaw)
    };

    RpcEntMain.entSpreadsheetInsertShare(entId, msg, (envSig) =>
    {
      if(envSig.sig?.url)
      {
        cbSuccess(envSig.sig?.url);
      }
      else if(envSig.error?.validationErrors)
      {
        if(envSig.error?.validationErrors[0].errorCode === "ruleViolation")
        {
          cbError && cbError(envSig.error?.validationErrors[0].errorMessage);
        }
      }
    });
  }

  getSpreadsheetEditorDeeplink(
    entId: EntId,
    chatId: ChatId,
    metaIdAction: MetaIdAction,
    cbSuccess: (link: string) => void,
    reset?: boolean,
    cbError?: (msg: string | undefined) => void
  )
  {
    const msg: MsgSpreadsheetEditorShare = {
      chatId: chatId,
      actionId: metaIdAction,
      reset: reset
    };

    RpcEntMain.entSpreadsheetEditorShare(entId, msg, (envSig) =>
    {
      if(envSig.sig?.url)
      {
        cbSuccess(envSig.sig?.url);
      }
      else if(envSig.error?.validationErrors)
      {
        if(envSig.error?.validationErrors[0].errorCode === "ruleViolation")
        {
          cbError && cbError(envSig.error?.validationErrors[0].errorMessage);
        }
      }
    });
  }

  private filterFormValueRaw(_formValueRaw?: FormValueRaw): FormValueRaw | undefined
  {
    const formValueRaw = cloneDeep(_formValueRaw);

    removeUnnecessaryFieldValues(formValueRaw?.valueMap ?? {});

    return formValueRaw;
  }
}
