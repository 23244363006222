import {SigEntAvatarUser} from "../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {isPluginId} from "../../api/meta/base/ApiPlus";
import {isEntId} from "../../api/meta/base/ApiPlus";
import {StoreItemId} from "../../api/meta/base/Types";
import {ArtifactId} from "../../api/meta/base/Types";
import {PluginBundleId} from "../../api/meta/base/Types";
import {SigApiLib} from "../../api/nucleus/api/sig/SigApiLib";
import {SigStoreItemAvatar} from "../../api/store/store/sig/SigStoreItemAvatar";
import {SigStudioPluginAvatar} from "../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import ISrvc from "../../base/ISrvc";
import {dispatchList} from "../../base/plus/ListPlus";
import {dispatchListItemStudio} from "../../base/plus/SrvcPlus";
import {dispatchListItemStore} from "../../base/plus/SrvcPlus";
import {dispatchListItemEnt} from "../../base/plus/SrvcPlus";
import {listReset} from "../../base/slices/list/SliceList";
import {listSetUserFieldVar} from "../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../base/slices/list/SliceListSharedActions";
import {listClearPickItemIds} from "../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../base/types/list/TypesList";
import {IListBinder} from "../../base/types/list/TypesList";
import {IListData} from "../../base/types/list/TypesList";
import {setApiBrFilter} from "../../cache/apiBr/SliceCacheApiBrFilter";
import {store} from "../../Store";
import {RootState} from "../../Store";

type TypeSigAvatarArtifact = SigEntAvatarUser | SigStudioPluginAvatar | SigStoreItemAvatar | undefined;

export type CbSuccessGetAll = (data: SigApiLib) => void;

export default class SrvcApiBrFilter extends ISrvc
{
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.onApplySourceItem.bind(this)
    } as IListBinder<TypeSigAvatarArtifact>;
  }

  load(listName: string, artifactId: ArtifactId[], pluginIdSet: PluginBundleId[], storeItemIdAdminSet: StoreItemId[])
  {
    const listData = this.createListData(artifactId, pluginIdSet, storeItemIdAdminSet);

    const filter = store.getState().cache.apiBr.filter.artifactId;

    if(artifactId.length === 0)
    {
      dispatchList(listName, listReset());
    }
    else
    {
      dispatchList(listName, listRefresh(listData));

      this.calcShowFilter(listName, filter);
    }
  }

  setFilter(listName: string, artifactId: ArtifactId)
  {
    store.dispatch((dispatch, getState) =>
    {
      const callerIdHash = getState().cache.app.caller.callerUserIdHash as string;

      dispatch(setApiBrFilter({
        callerIdHash: callerIdHash as string,
        artifactId: artifactId
      }));

      this.calcShowFilter(listName, artifactId);
    });
  }

  clearFilter(listName: string)
  {
    store.dispatch((dispatch, getState) =>
    {
      const callerIdHash = getState().cache.app.caller.callerUserIdHash as string;
      dispatch(setApiBrFilter({
        callerIdHash: callerIdHash as string,
        artifactId: undefined
      }));
      dispatchList(listName, listClearPickItemIds());
      this.calcShowFilter(listName, undefined);
    });
  }

  protected createListData(
    artifactIdSet: ArtifactId[],
    pluginIdSet: PluginBundleId[],
    storeItemIdAdminSet: StoreItemId[]): IListData
  {
    return {
      itemIds: [...artifactIdSet, ...pluginIdSet, ...storeItemIdAdminSet],
      itemsById: {}
    } as IListData;
  }

  //region private

  private selectSourceItem(state: RootState, itemId: TypeListItemId): TypeSigAvatarArtifact
  {
    const caller = state.cache.app.caller;

    if(isEntId(itemId))
    {
      return caller.entIdAdminAvatarMap[itemId] ?? caller.entIdUserAvatarMap[itemId];
    }
    else if(isPluginId(itemId))
    {
      return caller.pluginIdAdminAvatarMap[itemId];
    }
    else
    {
      return caller.storeItemIdAdminAvatarMap[itemId];
    }

  }

  private onApplySourceItem(listName: string, itemId: TypeListItemId, avatar?: TypeSigAvatarArtifact): void
  {
    if(isEntId(itemId))
    {
      dispatchListItemEnt(listName, itemId, avatar as SigEntAvatarUser);
    }
    else if(isPluginId(itemId))
    {
      dispatchListItemStudio(listName, itemId, avatar as SigStudioPluginAvatar);
    }
    else
    {
      dispatchListItemStore(listName, itemId, avatar as SigStoreItemAvatar);
    }

    const filter = store.getState().cache.apiBr.filter.artifactId;
    if(itemId)
    {
      dispatchList(listName, listSetPick({
        itemId: itemId,
        pickValue: Boolean(filter && filter === itemId)
      }));
    }
  }

  private calcShowFilter(listName: string, filter?: ArtifactId)
  {
    dispatchList(listName, listSetUserFieldVar({
      varName: "value",
      varValue: Boolean(filter)
    }));
  }

  //endregion
}
