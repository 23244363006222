import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldPickRole} from "../../api/meta/base/dto/DefnFieldPickRole";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickGroupId} from "../../api/meta/base/dto/DefnStudioPickGroupId";
import {MetaIdGroup} from "../../api/meta/base/Types";
import {MetaIdRole} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldRole = "role";
const fieldGroupSet = "groupSet";

export interface IStudioForwardGroupPermission
{
  roleId: MetaIdRole,
  groupIdSet: MetaIdGroup[]
}

export default function DialogForwardGroupPermission(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  values?: IStudioForwardGroupPermission,
  onClickOk: (values: IStudioForwardGroupPermission) => void,
  onClose?: () => void,
  excludeRoleIdSet?: MetaIdRole[],
  validationError?: IFormFieldError[]
})
{
  const values = props.values;
  const excludeRoleIdSet = props.excludeRoleIdSet;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} forward group permission`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(excludeRoleIdSet),
        store: props.formStore,
        formReadonly: props.isFormReadOnly,
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: values ? {...dtoToValue(values)} : undefined
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? "Add more forward group permission"
        : undefined}
    />
  );
}

function valueToDto(values: FieldValues): IStudioForwardGroupPermission
{
  return {
    roleId: fnFieldValueToRawValue("pickRole", values[fieldRole]),
    groupIdSet: fnFieldValueToRawValue("studioSetOfGroupId", values[fieldGroupSet])
  } as IStudioForwardGroupPermission;
}

function dtoToValue(dto?: IStudioForwardGroupPermission)
{
  if(dto)
  {
    return {
      [fieldRole]: fnRawValueToFieldValue("pickRole", dto.roleId),
      [fieldGroupSet]: fnRawValueToFieldValue("studioSetOfGroupId", dto.groupIdSet)
    };
  }
}

function getDefnForm(excludeRoleIdSet?: MetaIdRole[])
{
  return createDefaultDefnFormStudio({
    [fieldRole]: {
      type: "pickRole",
      name: fieldRole,
      metaId: fieldRole,
      label: "Role",
      required: true,
      excludeRoleIdSet: excludeRoleIdSet
    } as DefnFieldPickRole,
    [fieldGroupSet]: {
      type: "studioSetOfGroupId",
      name: fieldGroupSet,
      metaId: fieldGroupSet,
      label: "Group set"
    } as DefnStudioPickGroupId,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldRole, fieldGroupSet]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);

}


