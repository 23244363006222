import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useCallback} from "react";
import React from "react";
import {calendarShellItemBgColor} from "../../../../base/plus/CalendarPlus";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {ICalendarEvent} from "../../../../base/types/TypeCalendar";
import {CbOnClickCalendarVariant} from "../../../../base/types/TypeCalendar";
import {ICalendarBinderAll} from "../../../../base/types/TypeCalendar";
import {CbOnClickCalendarItem} from "../../../../base/types/TypeCalendar";
import {TypeCalendarItemId} from "../../../../base/types/TypeCalendar";
import {useAppSelector} from "../../../app/AppHooks";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawTooltip from "../../../atom/raw/RawTooltip";
import useCalendarPubSub from "../hook/CalendarPubSub";

export default function CalendarEventItem<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  selectCalendar: SelectCalendar,
  itemId: TypeCalendarItemId,
  event: ICalendarEvent,
  cbOnClick?: CbOnClickCalendarItem,
  calendarBinder?: ICalendarBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  onItemSubscribe?: (itemId: TypeCalendarItemId) => void,
  onItemUnsubscribe?: (itemId: TypeCalendarItemId) => void,
})
{
  const theme = useTheme();

  const selectCalendar = props.selectCalendar;
  const itemId = props.itemId;
  const event = props.event;
  const cbOnClick = props.cbOnClick;
  const calendarBinder = props.calendarBinder;
  const onItemSubscribe = props.onItemSubscribe;
  const onItemUnsubscribe = props.onItemUnsubscribe;

  const calendarDefnForm = useAppSelector(state => selectCalendar(state).defnForm);

  const bgcolor = event?.bgColor;
  const toolTipValue = event?.tooltip;
  const displayText = event?.title;

  const onClickItem = useCallback((menuAnchor: Element, variant: CbOnClickCalendarVariant) =>
  {
    if(cbOnClick)
    {
      cbOnClick(menuAnchor, variant, itemId, calendarDefnForm);
    }
  }, [cbOnClick, itemId, calendarDefnForm]);

  useCalendarPubSub({
    selectCalendar: selectCalendar,
    itemId: itemId,
    cbOnClick: cbOnClick,
    calendarBinder: calendarBinder,
    onItemSubscribe: onItemSubscribe,
    onItemUnsubscribe: onItemUnsubscribe
  });

  if(!displayText)
  {
    return null;
  }

  return (
    <RawTooltip title={toolTipValue}>
      <Box
        key={itemId}
        sx={{
          overflow: "visible",
          display: "flex",
          alignItems: "center",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          lineHeight: "normal",
          paddingX: px(gapQuarter),
          borderRadius: px(gapQuarter),
          bgcolor: bgcolor || calendarShellItemBgColor,
          cursor: "pointer"
        }}
        onClick={(e) =>
        {
          onClickItem(e.currentTarget, "calendarItem");
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <RawHighlighter
            variant={"caption"}
            color={theme.common.color("textPrimary")}
            value={displayText}
            flexGrow={1}
            breakWords={false}
            lineHeight={1.5}
          />
        </Box>
      </Box>
    </RawTooltip>
  );
}
