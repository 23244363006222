import {combineReducers} from "@reduxjs/toolkit";
import {createCalendarSlice} from "../../../base/plus/CalendarPlus";
import {createKanbanSlice} from "../../../base/plus/KanbanPlus";
import {createListSlice} from "../../../base/plus/ListPlus";

export function reducersAppSpreadsheet()
{
  return combineReducers({
    ssEditorKanban: createKanbanSlice("ssEditorKanban", {
      externalSearch: true,
      showMenu: true
    }).reducer,
    ssEditorListOrCard: createListSlice("ssEditorListOrCard", {
      externalSearch: true
    }).reducer,
    ssEditorCalendar: createCalendarSlice("ssEditorCalendar").reducer
  });
}
