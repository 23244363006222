// noinspection DuplicatedCode

import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {useCallback} from "react";
import React from "react";
import {formatDetailedDateTime} from "../../../base/plus/DatePlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {useAppSelector} from "../../app/AppHooks";
import {BoxShellChat} from "../../atom/box/BoxShellChat";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import {CbOnClickListItem} from "../List";

export default function ListItemShellChat(props: {
  itemId: TypeListItemId,
  listItem: IListItemChat,
  selectList: SelectList,
  maxWidth: number,
  onClickListItem?: CbOnClickListItem,
  children: React.ReactNode,
})
{
  const theme = useTheme();
  const gapQuarter = theme.common.gapQuarter;

  const item = props.listItem;
  const itemId = props.itemId;
  const selectList = props.selectList;
  const onClickListItem = props.onClickListItem;
  const hideMenu = item.hideMenu;

  const pickType = useAppSelector(state => selectList(state).pickType);
  const listName = useAppSelector(state => selectList(state).listName);
  const pickCount = useAppSelector(state => selectList(state).pickCount);
  const pickValue = useAppSelector(state =>
  {
    const pickItemIds = props.selectList(state).pickItemIds;
    return pickItemIds !== undefined ? pickItemIds[itemId] : undefined;
  });
  const pickMode = pickType === "pickMany";

  const cbOnClick = useCallback((menuAnchor: Element, pickValue?: boolean) =>
  {
    if(pickMode)
    {
      dispatchList(listName,
        listSetPick({
          itemId: itemId,
          pickValue: Boolean(pickValue)
        })
      );
    }
    else
    {
      onClickListItem && onClickListItem(menuAnchor, itemId, item, "listItem");
    }

  }, [pickMode, onClickListItem, itemId, item]);

  const onClickMenu = useCallback((menuAnchor: Element) =>
  {
    onClickListItem && onClickListItem(menuAnchor, itemId, item, "spotMenu");
  }, [itemId, item]);

  return (
    <BoxShellChat
      bgcolor={"transparent"}
      pickMode={pickMode}
      pickValue={Boolean(pickValue)}
      showCheckBoxOnlyOnHover={Boolean(pickCount <= 0)}
      hideMenu={hideMenu}
      ignoreSelection={item.ignoreSelection}
      onClickMenu={onClickMenu}
      onClick={cbOnClick}
      cursorPointer={pickMode}
      justifyContent={"start"}
      showMenu={true}
    >
      <LayoutFlexCol
        pt={px(gapQuarter)}
        pb={px(gapQuarter)}
        alignItems={"space-between"}
      >
        <Typography variant={"caption"}>
          {
            item?.sig?.creationTime
              ? formatDetailedDateTime(new Date(item?.sig?.creationTime))
              : undefined
          }
        </Typography>
        {props.children}
      </LayoutFlexCol>
    </BoxShellChat>
  );
}
