import {combineReducers} from "@reduxjs/toolkit";
import {dispatchList} from "../../../base/plus/ListPlus";
import {createListSlice} from "../../../base/plus/ListPlus";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";

const productionRecentList = "productionRecentList";

export function reducersProductionDrawer()
{
  return combineReducers({
    productionRecentList: createListSlice(productionRecentList, {defaultListItemType: "aps"}).reducer,
    productionSearch: createListSlice("productionSearch",
      {
        defaultListItemType: "ps",
        showEmptyGroups: true,
        canShowMenu: false,
        externalSearch: true
      }
    ).reducer,
    productionFilter: createListSlice("productionFilter",
      {
        defaultListItemType: "aps",
        pickType: "pickMany"
      }
    ).reducer
  });
}

export function onClickProductionActionMainBack()
{
  dispatchList(productionRecentList, listSetSelectedItemId());
}
