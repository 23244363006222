import {IconButton} from "@mui/material";
import {Box, Typography} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldError} from "../../../../api/meta/base/dto/DefnFieldError";
import {FieldValueError} from "../../../../api/meta/base/dto/FieldValueError";
import {EnumDefnThemeColor} from "../../../../api/meta/base/Types";
import {EnumDefnErrorSeverity} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import IconStrip from "../../../atom/icon/IconStrip";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldError(props: {
  defn: DefnFieldError
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({field}) =>
      {
        const fieldValue = field.value as FieldValueError | undefined;
        const showCloseButton = defn.showCloseButton;

        return fieldValue?.errorReason
          ? (
            <FieldRawTemplate
              defn={defn}
              fieldValue={fieldValue}
            >
              <Box
                sx={{
                  borderRadius: theme.common.bubblePollBorderRadius,
                  backgroundColor: theme.common.colorWithShade(getBackgroundColor(fieldValue.severity)),
                  minHeight: px(36),
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                {showCloseButton && <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0
                  }}
                >
                  <IconButton
                    disableRipple={true}
                    sx={{
                      position: "relative",
                      top: 0,
                      right: 0,
                      height: "36px",
                      color: theme.common.fgcolorIcon,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    onClick={() =>
                    {
                      field.onChange(null);
                    }}
                  >
                    <IconStrip value={"close"} />
                  </IconButton>
                </Box>}
                <Typography
                  variant={"body2"}
                  sx={{
                    userSelect: "all"
                  }}
                  width={"90%"}
                >
                  {resolveError(fieldValue.errorReason, fieldValue.errorParameterSet ?? [])}
                </Typography>
              </Box>

            </FieldRawTemplate>
          )
          : <></>;
      }}
    />
  );
}

function getBackgroundColor(severity: EnumDefnErrorSeverity): EnumDefnThemeColor
{
  switch(severity)
  {
    case "error":
      return "red";
    case "suggestion":
      return "lightBlue";
    case "warning":
      return "amber";
  }
}

export function resolveError(message: string, params: string[]): string
{
  return message.replace(/%s/g, () => params.shift() as string);
}
