import {Box} from "@mui/material";
import {useEffect} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {useState} from "react";
import {useRef} from "react";
import React from "react";
import {SlotInfo} from "react-big-calendar";
import {Calendar as BigCalendar, Components} from "react-big-calendar";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnLayoutCalendar} from "../../api/meta/base/dto/DefnLayoutCalendar";
import {useCalendarData} from "../../base/plus/CalendarPlus";
import {useCalendarProperty} from "../../base/plus/CalendarPlus";
import {SelectCalendar} from "../../base/plus/CalendarPlus";
import theme from "../../base/plus/ThemePlus";
import {CbOnDoubleClickCalendarCell} from "../../base/types/TypeCalendar";
import {TypeCalendarCacheEvents} from "../../base/types/TypeCalendar";
import {CbOnClickCalendarVariant} from "../../base/types/TypeCalendar";
import {ICalendarEvent} from "../../base/types/TypeCalendar";
import {CbOnClickCalendarShowMore} from "../../base/types/TypeCalendar";
import {TypeCalendarItemId} from "../../base/types/TypeCalendar";
import {CbOnClickCalendarItem} from "../../base/types/TypeCalendar";
import {ICalendarBinderAll} from "../../base/types/TypeCalendar";
import {IAsidePropsCalendarItems} from "../../base/types/TypesAside";
import {useAppSelector} from "../app/AppHooks";
import {useAppCtx} from "../ctx/CtxApp";
import {usePageCtx} from "../ctx/CtxPage";
import CalendarCellDate from "./base/calendarModule/CalendarCellDate";
import CalendarCellWrapper from "./base/calendarModule/CalendarCellWrapper";
import CalendarEvent from "./base/calendarModule/CalendarEvent";
import CalendarHeader from "./base/calendarModule/CalendarHeader";
import {ICbRefSetCurrentDate} from "./base/calendarModule/CalendarHeader";
import CalendarHeaderLabel from "./base/calendarModule/CalendarHeaderLabel";
import CalendarItemAside from "./base/calendarModule/CalendarItemAside";
import CalendarShellBubbleForm from "./base/calendarModule/CalendarShellBubbleForm";

export interface ICalendarProps<SR1, SR2, SR3, SR4, SR5, SR6>
{
  selectCalendar: SelectCalendar;
  calendarBinder?: ICalendarBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  cbOnClick?: CbOnClickCalendarItem,
  cbOnClickShowMore?: CbOnClickCalendarShowMore,
  onDoubleClickCell?: CbOnDoubleClickCalendarCell,
  ignoreItemOverview?: boolean,
  onItemSubscribe?: (itemId: TypeCalendarItemId) => void,
  onItemUnsubscribe?: (itemId: TypeCalendarItemId) => void,
  layout?: DefnLayoutCalendar;
  onMonthChange?: (startDate: Date, endDate: Date, cacheMonthEventData?: TypeCalendarCacheEvents) => void
}

export default function Calendar<SR1, SR2, SR3, SR4, SR5, SR6>(props: ICalendarProps<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        flex: 1,
        display: "flex",
        bgcolor: theme.common.bgcolorSidePane,
        overflow: "auto"
      }}
    >
      {
        <CalendarReal {...props} />
      }
    </Box>
  );
}

function CalendarReal<SR1, SR2, SR3, SR4, SR5, SR6>(props: ICalendarProps<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const selectCalendar = props.selectCalendar;
  const calendarBinder = props.calendarBinder;
  const cbOnClick = props.cbOnClick;
  const cbOnClickShowMore = props.cbOnClickShowMore;
  const onDoubleClickCell = props.onDoubleClickCell;
  const ignoreItemOverview = props.ignoreItemOverview;
  const onItemSubscribe = props.onItemSubscribe;
  const onItemUnsubscribe = props.onItemUnsubscribe;

  const onMonthChange = props.onMonthChange;
  const layout = props.layout;
  const itemsById = useAppSelector(state => selectCalendar(state).itemsById);
  const defnForm = useAppSelector(state => selectCalendar(state).defnForm);

  const values = useMemo(() =>
  {
    return itemsById;
  }, [itemsById]);

  const {eventData} = useCalendarData({
    layout: layout,
    values: values,
    defnForm: defnForm
  });

  const pageCtx = usePageCtx();
  const appCtx = useAppCtx();

  const dateRef = useRef<ICbRefSetCurrentDate>({} as ICbRefSetCurrentDate);

  const [events, setEvents] = useState<ICalendarEvent[]>([]);

  useEffect(() =>
  {
    const timer = setTimeout(() =>
    {
      if(JSON.stringify(events) !== JSON.stringify(eventData))
      {
        setEvents(eventData);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [eventData, events]);

  //region handle calendar click event
  const onClickOpenAsideItemList = (events: ICalendarEvent[]) =>
  {
    appCtx.setAsideProps({
      type: "CalendarItems",
      getContent: (selectList) => (
        <CalendarItemAside
          selectCalendar={selectCalendar}
          events={events}
          selectList={selectList}
          defnForm={defnForm}
          cbOnClickBack={() => appCtx.setAsideProps(undefined)}
        />
      )
    } as IAsidePropsCalendarItems);
  };

  const onClickOpenTooltipReport = useCallback((
    menuAnchor: Element,
    itemId: TypeCalendarItemId,
    calendarItemForm?: DefnForm
  ) =>
  {
    const node = <CalendarShellBubbleForm
      selectCalendar={selectCalendar}
      itemId={itemId}
      calendarItemForm={calendarItemForm}
    />;
    pageCtx.showPopover(menuAnchor, undefined, node);
  }, []);

  const onClickCalendarItem: CbOnClickCalendarItem = useCallback((
    menuAnchor,
    variant: CbOnClickCalendarVariant,
    itemId: TypeCalendarItemId,
    calendarItemForm?: DefnForm) =>
  {
    if(ignoreItemOverview)
    {
      cbOnClick && cbOnClick(menuAnchor, "calendarItem", itemId, calendarItemForm);
    }
    else
    {
      switch(variant)
      {
        case "calendarItem":
        {
          onClickOpenTooltipReport && onClickOpenTooltipReport(menuAnchor, itemId, calendarItemForm);
        }
          break;
      }
    }
  }, [ignoreItemOverview]);

  const onClickCalendarShell: CbOnClickCalendarShowMore = useCallback((events) =>
  {
    if(ignoreItemOverview)
    {
      cbOnClickShowMore && cbOnClickShowMore(events);
    }
    else if(onClickOpenAsideItemList)
    {
      onClickOpenAsideItemList(events);
    }
  }, [ignoreItemOverview]);

  //endregion

  //region BigCalendar property
  const {
    views,
    localizer,
    formats,
    eventPropGetter,
    dayPropGetter
  } = useCalendarProperty();

  const [components] = useState<Components<ICalendarEvent>>({
    event: (props) => <CalendarEvent
      event={props.event}
      selectCalendar={selectCalendar}
      calendarBinder={calendarBinder}
      onItemSubscribe={onItemSubscribe}
      onItemUnsubscribe={onItemUnsubscribe}
      cbOnClick={onClickCalendarItem}
    />,
    toolbar: (props) => <CalendarHeader
      headerProp={{
        date: props.date,
        view: props.view,
        label: props.label,
        onNavigate: props.onNavigate,
        onView: props.onView
      }}
      selectCalendar={selectCalendar}
      dateRef={dateRef.current}
      onMonthChange={onMonthChange}
    />,
    dateCellWrapper: (props) => <CalendarCellWrapper
      dateCellProps={props}
      currentDate={dateRef.current?.getCurrentDate && dateRef.current.getCurrentDate()}
    />,
    month: {
      dateHeader: (props) => <CalendarCellDate
        dateHeaderProps={props}
      />,
      header: (props) => <CalendarHeaderLabel
        headerProps={props}
        view={"month"}
      />
    },
    week: {
      header: (props) => <CalendarHeaderLabel
        headerProps={props}
        view={"week"}
      />
    }
  });

  const onClickShowMore = (events: ICalendarEvent[], _date: Date) =>
  {
    onClickCalendarShell(events);
  };

  const onSelectSlot = useCallback((slotInfo: SlotInfo) =>
  {
    const slotValue = slotInfo.start;
    const slotAction = slotInfo.action === "doubleClick";

    if(slotAction && slotValue)
    {
      onDoubleClickCell && onDoubleClickCell(slotValue);
    }
  }, [onDoubleClickCell]);

  //endregion

  const applyCustomStyles = () =>
  {
    // Select the rbc-month-view elements and remove the left border
    const monthViewElements = document.querySelectorAll(".rbc-month-view");
    const timeViewElements = document.querySelectorAll(".rbc-time-view");
    const weekViewContainer = document.querySelectorAll(".rbc-events-container");
    hideElementBorder(monthViewElements);
    hideElementBorder(timeViewElements);
    setContainerSpacing(weekViewContainer);
  };

  useEffect(() =>
  {
    applyCustomStyles();

    const observer = new MutationObserver(applyCustomStyles);
    const targetNode = document.querySelector(".rbc-calendar");

    if(targetNode)
    {
      observer.observe(targetNode, {
        childList: true,
        subtree: true
      });
    }

    return () =>
    {
      if(observer)
      {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <Box
      flexGrow={1}
      sx={{
        overflowX: "hidden"
      }}
    >
      <BigCalendar
        localizer={localizer}
        events={events}
        components={components}
        onShowMore={onClickShowMore}
        startAccessor={"start"}
        endAccessor={"end"}
        views={views}
        dayLayoutAlgorithm={"no-overlap"}
        tooltipAccessor={null}
        formats={formats}
        eventPropGetter={eventPropGetter}
        dayPropGetter={dayPropGetter}
        onSelectSlot={onSelectSlot}
        selectable={ignoreItemOverview}
        style={{
          flexGrow: 1
        }}
      />
    </Box>
  );
}

const hideElementBorder = (nodeList: NodeListOf<Element>) =>
{
  nodeList.forEach(element =>
  {
    (element as HTMLElement).style.borderLeft = "none";
  });
};

const setContainerSpacing = (nodeList: NodeListOf<Element>) =>
{
  nodeList.forEach(element =>
  {
    (element as HTMLElement).style.marginRight = `0px`;
  });
};

