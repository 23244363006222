import {useTheme} from "@mui/material";
import {cloneDeep} from "lodash";
import {isEqual} from "lodash";
import {useEffect} from "react";
import {useRef} from "react";
import React, {useCallback, useState} from "react";
import {FieldErrors} from "react-hook-form";
import {MetaId} from "../../../../api/meta/base/Types";
import {isDeepEqual} from "../../../../base/plus/JsPlus";
import {getListItemHeightAPSA} from "../../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {ILineSecondary} from "../../../../base/types/TypesGlobal";
import {TypeUserField} from "../../../../base/types/TypesGlobal";
import {ILinePrimary} from "../../../../base/types/TypesGlobal";
import {ITreeNode, ITreeRef} from "../../../../base/types/TypesTree";
import {BoxP} from "../../../atom/box/BoxP";
import {BoxPS} from "../../../atom/box/BoxPS";
import {BoxShell} from "../../../atom/box/BoxShell";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import RawTree from "../../../atom/raw/RawTree";
import {usePageCtx} from "../../../ctx/CtxPage";
import FieldRawTreeShell from "./FieldRawTreeShell";

interface NodeUserField<T> extends TypeUserField
{
  dto?: ITreeConditionDto<T>,
}

interface ITreeConditionDto<T>
{
  metaId: MetaId;
  statement?: T;
  andOr?: boolean;
  keys?: MetaId[];
  map?: Record<MetaId, ITreeConditionDto<T>>;
}

export interface IResolvedStatement
{
  primary?: string,
  caption?: string,
  secondary?: string
}

export default function FieldRawTreeCondition<T>(props: {
  fieldValue?: ITreeConditionDto<T>,
  label?: string
  isLastField?: boolean,
  isFormReadOnly?: boolean,
  errors?: FieldErrors,
  cbCreateStatementNode: (item?: ITreeConditionDto<T>, cb?: (newItem: T) => void) => void,
  cbResolveStatement: (statement: T) => IResolvedStatement
  onChange: (value: ITreeConditionDto<T> | null) => void,
  cbGetMetaId: () => MetaId
})
{
  const pageCtx = usePageCtx();
  const theme = useTheme();

  const isLastField = props.isLastField;
  const fieldValue = props.fieldValue;
  const cbCreateStatementNode = props.cbCreateStatementNode;
  const isFormReadOnly = props.isFormReadOnly;
  const label = props.label;
  const cbResolveStatement = props.cbResolveStatement;
  const cbGetMetaId = props.cbGetMetaId;

  const cbRefTree = useRef({} as ITreeRef);
  const showAddBtn = true;
  const errors = props.errors;

  const [rootNodes, setRootNodes] = useState<ITreeNode[]>(convertDtoToRootNodes(fieldValue, errors));
  const [selectedNode, setSelectedNode] = useState<ITreeNode>();
  const refRootNodes = useRef<ITreeNode[]>(rootNodes);
  const refSelectedNode = useRef<ITreeNode | undefined>(selectedNode);

  const onChange = (rootNodes?: ITreeNode[]) =>
  {
    const newValue = convertRootNodesToDto<T>(rootNodes || []);
    if(!isEqual(newValue, fieldValue))
    {
      props.onChange(newValue);
    }
  };

  const cbOnConditionOperator = useCallback((type: "and" | "or", node?: ITreeNode) =>
  {
    const newNode: ITreeNode = createAndOrNode({
      metaId: cbGetMetaId(),
      andOr: type === "and"
    });

    if(selectedNode && !node && rootNodes.length)
    {
      const userField = selectedNode.userField as NodeUserField<T>;
      const dto = userField?.dto;

      if(dto?.andOr !== undefined)
      {
        const updatedNode: ITreeNode = {
          ...selectedNode,
          children: [
            ...selectedNode.children ?? [],
            newNode
          ]
        };

        cbRefTree.current.nodeUpdate(updatedNode, selectedNode);
      }
      else
      {
        if(rootNodes.length === 1 && !rootNodes[0].children?.length)
        {
          newNode.children = [rootNodes[0]];
          cbRefTree.current.nodeUpdate(newNode, rootNodes[0]);
        }
        else
        {
          cbRefTree.current.nodeFindParent(selectedNode, rootNodes, (_parentNode) =>
          {
            newNode.children = [selectedNode];

            const children = _parentNode.children?.map(node =>
            {
              if(node.id === selectedNode.id)
              {
                return newNode;
              }
              else
              {
                return node;
              }
            });

            const parentNode: ITreeNode = {
              ..._parentNode,
              children: children
            };

            cbRefTree.current.nodeUpdate(parentNode, _parentNode);
          });
        }
      }
    }
    else
    {
      if(!node && rootNodes.length === 1 && rootNodes[0])
      {
        newNode.children = [rootNodes[0]];
        cbRefTree.current.nodeUpdate(newNode, rootNodes[0]);
      }
      else
      {
        cbRefTree.current.nodeAdd(newNode, node);
      }
    }

  }, [cbGetMetaId, rootNodes, selectedNode]);

  const cbOnAddStatement = useCallback((values: T, parentNode?: ITreeNode) =>
  {
    const newNode: ITreeNode = createStatementNode({
      statement: values,
      metaId: cbGetMetaId()
    } as ITreeConditionDto<T>);

    if(refSelectedNode.current && !parentNode && refRootNodes.current.length)
    {
      const userField = refSelectedNode.current.userField as NodeUserField<T>;
      const dto = userField?.dto;

      if(dto?.andOr !== undefined)
      {
        const updatedNode: ITreeNode = {
          ...refSelectedNode.current,
          children: [
            ...refSelectedNode.current.children ?? [],
            newNode
          ]
        };

        cbRefTree.current.nodeUpdate(updatedNode, refSelectedNode.current);
      }
      else
      {
        cbRefTree.current.nodeFindParent(refSelectedNode.current, refRootNodes.current, (_parentNode) =>
        {
          const parentNode: ITreeNode = {
            ..._parentNode,
            children: [
              ..._parentNode.children ?? [],
              newNode
            ]
          };

          cbRefTree.current.nodeUpdate(parentNode, _parentNode);
        });
      }
    }
    else
    {
      cbRefTree.current.nodeAdd(newNode, parentNode);
    }

  }, [cbGetMetaId]);

  const cbOnEditStatement = useCallback((values: T, oldNode: ITreeNode) =>
  {
    const newNode: ITreeNode = createStatementNode({
      statement: values,
      metaId: oldNode.id
    } as ITreeConditionDto<T>);

    cbRefTree.current.nodeUpdate(newNode, oldNode);

  }, []);

  const cbOnEditOperator = useCallback((node: ITreeNode) =>
  {
    const newNode = cloneDeep(node);
    const userField = newNode.userField && newNode.userField as NodeUserField<T>;

    if(userField?.dto?.andOr !== undefined)
    {
      userField.dto.andOr = !userField.dto.andOr;
      cbRefTree.current.nodeUpdate(newNode, node);
    }
  }, []);

  function addChild(dto: ITreeConditionDto<T>, parentNode: ITreeNode)
  {
    if(dto)
    {
      if(dto.andOr !== undefined)
      {
        const newNode: ITreeNode = createAndOrNode({
          metaId: cbGetMetaId(),
          andOr: dto.andOr,
          map: dto.map,
          keys: dto.keys
        });

        cbRefTree.current.nodeAdd(newNode, parentNode);

        dto.keys && dto.keys.forEach(key =>
        {
          if(dto.map)
          {
            const statement = dto.map[key].statement;
            if(statement)
            {
              cbOnAddStatement(statement, newNode);
            }
            if(dto.map[key].andOr !== undefined)
            {
              addChild(dto.map[key], newNode);
            }
          }
        });
      }
      else if(dto.statement)
      {
        cbOnAddStatement(dto.statement, parentNode);
      }
    }
  }

  const onClickPaste = useCallback((parentNode: ITreeNode) =>
  {
    navigator.clipboard.readText()
    .then(stringifyDTO =>
    {
      const dto = JSON.parse(stringifyDTO) as ITreeConditionDto<T>;

      addChild(dto, parentNode);

    })
    .catch(() =>
    {
      pageCtx.showErrorToast("Invalid condition");
    });
  }, []);

  const cbOnClickMenu = useCallback((
      menuAnchor: Element,
      node: ITreeNode,
      isFirst: boolean,
      isLast: boolean) =>
    {
      const userField = node.userField && node.userField as NodeUserField<T>;
      const dto = userField?.dto;

      let menuProps: IMenuProps = {
        ...dto?.andOr === undefined
          ? {
            "Edit": {
              onClick: () => cbCreateStatementNode(dto, (newItem) => cbOnEditStatement(newItem, node)),
              disabled: false
            }
          }
          : {
            [`Edit to ${!dto.andOr ? "and" : "or"}`]: () => cbOnEditOperator(node)
          },

        "Remove": {
          onClick: () =>
          {
            if(selectedNode === node)
            {
              setSelectedNode(undefined);
            }
            cbRefTree.current.nodeRemove(node);
          },
          disabled: isFormReadOnly
        },
        "gap1": undefined,
        "Copy": {
          onClick: () => copyToClipboard(updateAllMetaIds(JSON.stringify(dto)))
        },
        "Paste": {
          onClick: () => onClickPaste(node),
          disabled: dto?.andOr === undefined
        },
        "gap2": undefined,
        "Move up": {
          onClick: () => cbRefTree.current.nodeMoveUp(node),
          disabled: isFirst
        },
        "Move down": {
          onClick: () => cbRefTree.current.nodeMoveDown(node),
          disabled: isLast
        },
        "Move top": {
          onClick: () => cbRefTree.current.nodeMoveTop(node),
          disabled: isFirst
        },
        "Move bottom": {
          onClick: () => cbRefTree.current.nodeMoveBottom(node),
          disabled: isLast
        },
        ...dto?.andOr !== undefined && {
          "gap2": undefined,
          "And": () => cbOnConditionOperator("and", node),
          "Or": () => cbOnConditionOperator("or", node),
          "Statement": () => cbCreateStatementNode(dto, (newItem) =>
          {
            cbOnAddStatement(newItem, node);
          })
        }
      };
      pageCtx.showMenu(menuAnchor, menuProps);
    },
    [
      isFormReadOnly,
      pageCtx,
      cbCreateStatementNode,
      cbOnEditStatement,
      cbOnEditOperator,
      selectedNode,
      onClickPaste,
      cbOnConditionOperator,
      cbOnAddStatement
    ]
  );

  const getLabel = useCallback((node: ITreeNode, isFirst?: boolean, isLast?: boolean) =>
    {
      const userField = node.userField && node.userField as NodeUserField<T>;
      const dto = userField?.dto;
      const statement = dto?.statement;
      const andOr = dto?.andOr;
      const resolvedStatement = statement && cbResolveStatement(statement);
      const secondary = resolvedStatement?.secondary || undefined;

      const text = (andOr === undefined && statement)
        ? resolvedStatement?.primary
        ?? "Statement"
        : andOr
          ? "And"
          : "Or";

      const color = statement ? undefined : "primary";
      const variant = statement ? "subtitle1" : undefined;

      const primary: ILinePrimary = {
        text: text,
        color: color,
        variant: variant,
        caption: {
          text: resolvedStatement?.caption
        }
      };

      const lineSecondary: ILineSecondary = {
        text: secondary
      };

      const height = getListItemHeightAPSA((statement && !secondary) ? "p" : "ps");

      return (
        <BoxShell
          pl={0}
          height={height}
          infoSpot={node.infoSpot}
          ignoreSelection={true}
          onClick={() => andOr === undefined && cbCreateStatementNode(dto, newItem => cbOnEditStatement(newItem, node))}
          onClickMenu={(menuAnchor => cbOnClickMenu(menuAnchor, node, Boolean(isFirst), Boolean(isLast)))}
          onClickInfoSpot={(menuAnchor) =>
          {
            const errorMsg = (errors?.[node.id]?.message || errors?.["node/statement"]?.message) as string;
            if(errorMsg)
            {
              pageCtx.showPopover(menuAnchor, {
                msg: errorMsg,
                bgcolor: theme.common.bgcolorError
              });
            }
          }}
          hideMenu={isFormReadOnly}
        >
          {secondary
            ? <BoxPS
              primary={primary}
              secondary={lineSecondary}
              flexGrow={1}
            /> : <BoxP
              primary={primary}
              flexGrow={1}
            />
          }
        </BoxShell>
      );
    },
    [
      cbCreateStatementNode,
      cbOnClickMenu,
      cbOnEditStatement,
      cbResolveStatement,
      errors,
      isFormReadOnly,
      pageCtx
    ]
  );

  useEffect(() =>
  {
    const newTree = convertDtoToRootNodes(fieldValue, errors);

    if(!isEqual(rootNodes, newTree))
    {
      setRootNodes(newTree);
      refRootNodes.current = newTree;
    }

  }, [fieldValue]);

  useEffect(() =>
  {
    if(selectedNode)
    {
      cbRefTree.current.nodeFind(selectedNode, rootNodes, (index, nodes) =>
      {
        const updatedNode = nodes[index];

        if(!isDeepEqual(updatedNode, selectedNode))
        {
          setSelectedNode(updatedNode);
        }
      });
    }

    refSelectedNode.current = selectedNode;

  }, [rootNodes, selectedNode]);

  useEffect(() =>
  {
    cbRefTree.current.expandAll();
  }, []);

  return (
    <FieldRawTreeShell
      rawTree={<RawTree
        cbRef={cbRefTree.current}
        rootNodes={rootNodes}
        onChange={onChange}
        getLabel={getLabel}
        cbSelectedNode={(nodeId) =>
        {
          setSelectedNode(getNode(nodeId, rootNodes));
        }}
      />}
      buttonStrip={
        showAddBtn &&
        <RawButtonStrip
          onClick={(iconName) =>
          {
            if(iconName === "and")
            {
              cbOnConditionOperator("and");
            }
            else if(iconName === "or")
            {
              cbOnConditionOperator("or");
            }
            else if(iconName === "statement")
            {
              cbCreateStatementNode(undefined, (newItem) => cbOnAddStatement(newItem));
            }
          }}
          iconButtonList={["and", "or", "statement"]}
          iconButtonDisable={(isFormReadOnly || (!selectedNode && rootNodes.length))
            ? ["and", "or", "statement"]
            : (rootNodes?.length === 1 && fieldValue?.andOr === undefined)
              ? ["statement"]
              : undefined}
          toolTipMap={{
            add: "Add node"
          } as Record<EnumIconButton, string>}
          labelMap={{
            and: "AND",
            or: "OR",
            statement: "STATEMENT"
          } as Record<EnumIconButton, string>}
        />
      }
      label={label}
      showButtonStripAtBottom={isLastField}
    />
  );
}

function getNode(nodeId: string, rootNodes: ITreeNode[]): ITreeNode | undefined
{
  let resNode: ITreeNode | undefined;

  const findNode = (rootNodes: ITreeNode[]) =>
  {
    for(let i = 0; i < rootNodes.length; i++)
    {
      const node = rootNodes[i];
      if(node.id === nodeId)
      {
        resNode = node;
      }
      else if(node.children)
      {
        findNode(node.children);
      }
    }
  };

  findNode(rootNodes);

  return resNode;
}

function createAndOrNode<T>(dto: ITreeConditionDto<T>)
{
  return {
    id: dto?.metaId,
    name: dto?.metaId,
    userField: {
      dto: dto
    } as NodeUserField<T>,
    children: [] as ITreeNode[]
  } as ITreeNode;
}

function createStatementNode<T>(dto: ITreeConditionDto<T>)
{
  return {
    id: dto?.metaId,
    name: dto?.metaId,
    children: undefined,
    userField: {
      dto: dto
    } as NodeUserField<T>
  } as ITreeNode;
}

function convertRootNodesToDto<T>(rootNodes: ITreeNode[]): ITreeConditionDto<T> | null
{
  let condition = null as ITreeConditionDto<T> | null;

  const insertKeysAndMap = (node: ITreeNode, dto: ITreeConditionDto<T>) =>
  {
    const userField = node.userField && node.userField as NodeUserField<T>;
    const _dto = userField?.dto;
    if(_dto)
    {
      dto.metaId = _dto.metaId;
      if(_dto.andOr !== undefined)
      {
        dto.andOr = _dto.andOr;
      }
      if(_dto.statement !== undefined)
      {
        dto.statement = _dto.statement;
      }
      node.children?.forEach((child) =>
      {
        if(!dto.keys)
        {
          dto.keys = [];
          dto.map = {};
        }
        dto.keys?.push(child.id);
        if(dto.map)
        {
          dto.map[child.id] = {} as ITreeConditionDto<T>;
          insertKeysAndMap(child, dto.map[child.id]);
        }
      });
    }
  };

  rootNodes.forEach((node) =>
  {
    if(!condition)
    {
      condition = {} as ITreeConditionDto<T>;
    }
    insertKeysAndMap(node, condition);
  });
  return condition;
}

function convertDtoToRootNodes<T>(fieldValue?: ITreeConditionDto<T>, errors?: FieldErrors): ITreeNode[]
{
  if(!fieldValue)
  {
    return [] as ITreeNode [];
  }
  const convertDtoToRootNode = (fieldValue: ITreeConditionDto<T>): ITreeNode | undefined =>
  {
    if(fieldValue.metaId)
    {
      const node = {} as ITreeNode;
      node.id = fieldValue.metaId;
      node.name = fieldValue.metaId;
      node.userField = {
        dto: fieldValue
      } as NodeUserField<T>;
      node.infoSpot = (errors?.[fieldValue.metaId] || errors?.["node/statement"])
        ? "error"
        : undefined;
      node.children = [] as ITreeNode[];

      if(fieldValue.keys)
      {
        fieldValue.keys.forEach((key) =>
        {
          if(fieldValue.map)
          {
            const child = convertDtoToRootNode(fieldValue.map[key]);
            if(child)
            {
              node.children?.push(child);
            }
          }
        });
      }
      return node;
    }
  };
  const node = convertDtoToRootNode(fieldValue);
  return node ? [node] : [] as ITreeNode[];
}
