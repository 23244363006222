import {batch} from "react-redux";
import {RpcAside} from "../../../../api/home/aside/RpcAside";
import {MsgChatId} from "../../../../api/home/base/msg/MsgChatId";
import {SigMediaList} from "../../../../api/home/main/sig/SigMediaList";
import {isMessageId} from "../../../../api/meta/base/ApiPlus";
import {MessageId} from "../../../../api/meta/base/Types";
import {ChatId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {listRecursiveCallsWithUserParams} from "../../../../base/plus/ListPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {collectListChatItems} from "../../../../base/plus/ListPlus";
import {listReset} from "../../../../base/slices/list/SliceList";
import {listChatSetIfExistIsStar} from "../../../../base/slices/list/SliceListChatAction";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../../base/slices/list/SliceListSharedActions";
import {listClearPickItemIds} from "../../../../base/slices/list/SliceListSharedActions";
import {IListBinder} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {RootState} from "../../../../Store";
import {store} from "../../../../Store";

interface IUserParams
{
  entId: EntId;
  chatId: ChatId;
}

export default class SrvcHomeMedia extends ISrvc
{
  rpcMediaListGet(entId: EntId, chatId: ChatId)
  {
    const msg = {
      chatId: chatId
    } as MsgChatId;

    RpcAside.mediaListGet(entId, msg, envSig => listRecursiveCallsWithUserParams<SigMediaList, IUserParams>(
      envSig,
      state => state.home.aside.media.galleryList,
      () => this.rpcMediaListGet(entId, chatId),
      this.load.bind(this),
      {
        entId,
        chatId
      }
    ));
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectIsStarMsg.bind(this),
      onBindSourceItem1: this.onBindIsStarMsg.bind(this)
    } as IListBinder<boolean>;
  }

  load(listName: string, sig: SigMediaList, userParams: IUserParams)
  {
    const entId = userParams.entId;
    const chatId = userParams.chatId;
    this.doLoadMediaList(entId, chatId, sig);
    this.doLoadDocumentList(entId, chatId, sig);
    this.doLoadLinksList(entId, chatId, sig);
  }

  selectIsStarMsg(rootState: RootState, itemId: TypeListItemId): boolean
  {
    if(!isMessageId(itemId))
    {
      return false;
    }
    return Boolean(rootState.cache.app.starMsg.starMsgMap[itemId]);
  }

  onBindIsStarMsg(listName: string, itemId: TypeListItemId, isStar: boolean): void
  {
    if(!isMessageId(itemId))
    {
      return;
    }
    dispatchList(listName, listChatSetIfExistIsStar({
      itemId: itemId,
      isStar: isStar
    }));
  }

  setListPickItems(messageId: string)
  {
    const rootState = store.getState();
    const mediaListName = rootState.home.aside.media.galleryList.listName;
    const mediaPickItems = rootState.home.aside.media.galleryList.pickItemIds?.[messageId];
    dispatchList(mediaListName,
      listSetPick({
        itemId: messageId,
        pickValue: !mediaPickItems
      })
    );
  }

  private doLoadMediaList(entId: EntId, chatId: ChatId, sig: SigMediaList)
  {
    const uiItemIds = [] as string[];
    const uiItemsById = {} as IListItemsById;

    const listName = store.getState().home.aside.media.galleryList.listName;
    const mediaList = sig.mediaList;
    const mediaCount = sig.mediaCount;

    if(mediaList)
    {
      collectListChatItems(entId, chatId, mediaList, uiItemIds, uiItemsById);
    }

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds,
      userField: {mediaCount}
    }));
  }

  private doLoadDocumentList(entId: EntId, chatId: ChatId, sig: SigMediaList)
  {
    const uiItemIds = [] as MessageId[];
    const uiItemsById = {} as IListItemsById;

    const listName = store.getState().home.aside.media.docsList.listName;
    const documentList = sig.documentList;

    if(documentList)
    {
      collectListChatItems(entId, chatId, documentList, uiItemIds, uiItemsById);
    }

    dispatchList(listName, listRefresh({
      itemIds: uiItemIds,
      itemsById: uiItemsById
    }));
  }

  private doLoadLinksList(entId: EntId, chatId: ChatId, sig: SigMediaList)
  {
    const uiItemIds = [] as MessageId[];
    const uiItemsById = {} as IListItemsById;

    const listName = store.getState().home.aside.media.linksList.listName;
    const linkList = sig.linkList;

    if(linkList)
    {
      collectListChatItems(entId, chatId, linkList, uiItemIds, uiItemsById);
    }

    dispatchList(listName, listRefresh({
      itemIds: uiItemIds,
      itemsById: uiItemsById
    }));
  }
}

export function resetAllMediaLists()
{
  store.dispatch((_, getState) =>
  {
    const rootState = getState();
    const mediaListName = rootState.home.aside.media.galleryList.listName;
    const docsListName = rootState.home.aside.media.docsList.listName;
    const linksListName = rootState.home.aside.media.linksList.listName;

    batch(() =>
    {
      dispatchList(mediaListName, listReset());
      dispatchList(docsListName, listReset());
      dispatchList(linksListName, listReset());
    });
  });
}

export function clearMediaSelection()
{
  store.dispatch((_, getState) =>
  {
    const rootState = getState();
    const mediaListName = rootState.home.aside.media.galleryList.listName;
    const docsListName = rootState.home.aside.media.docsList.listName;
    const linksListName = rootState.home.aside.media.linksList.listName;

    batch(() =>
    {
      dispatchList(mediaListName, listClearPickItemIds());
      dispatchList(docsListName, listClearPickItemIds());
      dispatchList(linksListName, listClearPickItemIds());
    });
  });
}
