import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {StudioEntDeeplinkMap} from "../../../../api/meta/base/dto/StudioEntDeeplinkMap";
import {MetaIdDeeplink} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdDeeplink | MetaIdDeeplink[] | undefined

export default function FieldRawStudioPickDeeplinkId(props: {
  defn: DefnFieldEditable,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const defn = props.defn;
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const deeplinkMap = formStore?.deeplinkMap
    ? formStore.deeplinkMap
    : undefined;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const dtoOptions = useMemo(() => getOption(
    deeplinkMap
  ), [fieldValue, formStore]);

  const [options] = useInsertTrashOptions({
    type: "deepLinkMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOption(deeplinkMap?: StudioEntDeeplinkMap): DefnDtoOption[]
{
  if(!deeplinkMap)
  {
    return [];
  }

  const options = [] as DefnDtoOption[];

  deeplinkMap.keys.forEach((deeplinkId) =>
  {
    const deeplink = deeplinkMap.map[deeplinkId];
    const value = deeplink.name || deeplink.metaId;

    options.push({
      metaId: deeplink.metaId,
      value: value
    } as DefnDtoOption);
  });

  return options;
}
