import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {isEqual} from "lodash";
import {DtoEntDeeplinkReport} from "../../../api/ent/base/dto/DtoEntDeeplinkReport";
import {DtoEntDeeplinkSpreadsheetEditor} from "../../../api/ent/base/dto/DtoEntDeeplinkSpreadsheetEditor";
import {DtoEntDeeplinkSpreadsheetInsert} from "../../../api/ent/base/dto/DtoEntDeeplinkSpreadsheetInsert";
import {DtoEntDeeplinkSpreadsheetRow} from "../../../api/ent/base/dto/DtoEntDeeplinkSpreadsheetRow";
import {SigEntCaller} from "../../../api/ent/entDrawer/sig/SigEntCaller";
import {EntId} from "../../../api/meta/base/Types";
import {ICallerEnt} from "./TypesCacheCallerEnt";
import {ICallerEntState} from "./TypesCacheCallerEnt";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export const sliceCacheCallerEnt = createSlice({
  name: "sliceCacheCallerEnt",
  initialState: {
    callerEntMap: {}
  } as ICallerEntState,
  reducers: {
    setCallerEnt: (state, action: PayloadAction<SigEntCaller>) =>
    {
      const newCallerEnt = action.payload;
      const callerEnt = state.callerEntMap[newCallerEnt.entId];
      if(callerEnt)
      {
        if(callerEnt.callerEntVersion === newCallerEnt.version)
        {
          return;
        }

        if(!isEqual(callerEnt.nickName, newCallerEnt.nickName))
        {
          callerEnt.nickName = newCallerEnt.nickName;
        }
        if(!isEqual(callerEnt.handle, newCallerEnt.handle))
        {
          callerEnt.handle = newCallerEnt.handle;
        }
        if(!isEqual(callerEnt.color, newCallerEnt.color))
        {
          callerEnt.color = newCallerEnt.color;
        }
        if(!isEqual(callerEnt.deeplinkMap, newCallerEnt.deeplinkMap))
        {
          callerEnt.deeplinkMap = newCallerEnt.deeplinkMap;
        }
        if(!isEqual(callerEnt.formMap, newCallerEnt.formMap))
        {
          callerEnt.formMap = newCallerEnt.formMap;
        }
        if(!isEqual(callerEnt.actionMap, newCallerEnt.actionMap))
        {
          callerEnt.actionMap = newCallerEnt.actionMap;
        }
        if(!isEqual(callerEnt.groupActionPermissionMap, newCallerEnt.groupActionPermissionMap))
        {
          callerEnt.groupActionPermissionMap = newCallerEnt.groupActionPermissionMap;
        }
        if(!isEqual(callerEnt.roleIdSet, newCallerEnt.roleIdSet))
        {
          callerEnt.roleIdSet = newCallerEnt.roleIdSet;
        }
        if(!isEqual(callerEnt.roleMap, newCallerEnt.roleMap))
        {
          callerEnt.roleMap = newCallerEnt.roleMap;
        }
        if(!isEqual(callerEnt.callerEntVersion, newCallerEnt.version))
        {
          callerEnt.callerEntVersion = newCallerEnt.version;
        }
        if(!isEqual(callerEnt.spreadsheetMap, newCallerEnt.spreadsheetMap))
        {
          callerEnt.spreadsheetMap = newCallerEnt.spreadsheetMap;
        }
        if(!isEqual(callerEnt.userSettingVarMap, newCallerEnt.userSettingVarMap))
        {
          callerEnt.userSettingVarMap = newCallerEnt.userSettingVarMap;
        }
        if(!isEqual(callerEnt.managerialRelationshipMap, newCallerEnt.managerialRelationshipMap))
        {
          callerEnt.managerialRelationshipMap = newCallerEnt.managerialRelationshipMap;
        }
        if(!isEqual(callerEnt.groupIdMapping, newCallerEnt.groupIdMapping))
        {
          callerEnt.groupIdMapping = newCallerEnt.groupIdMapping;
        }
        if(!isEqual(callerEnt.paymentProvider, newCallerEnt.paymentProvider))
        {
          callerEnt.paymentProvider = newCallerEnt.paymentProvider;
        }
      }
      else
      {
        state.callerEntMap[newCallerEnt.entId] = newCallerEnt;
      }
      fnSetCallerShareDLPermissionMap(state.callerEntMap[newCallerEnt.entId], newCallerEnt);
    },

    removeCallerEnt: (state, action: PayloadAction<EntId>) =>
    {
      const entId = action.payload;
      delete state.callerEntMap[entId];
    }
  }
});

export const {
  setCallerEnt,
  removeCallerEnt
} = sliceCacheCallerEnt.actions;

function fnSetCallerShareDLPermissionMap(state: ICallerEnt, payload: SigEntCaller)
{
  if(payload.deeplinkMap)
  {
    if(!state.callerShareDLPermissionMap)
    {
      state.callerShareDLPermissionMap = {};
    }

    Object.values(payload.deeplinkMap).forEach((deeplink) =>
    {
      if(deeplink.kind === "report")
      {
        const reportDeeplink = deeplink as DtoEntDeeplinkReport;
        if(reportDeeplink.reportId)
        {
          const reportId = reportDeeplink.reportId;
          state.callerShareDLPermissionMap = {
            ...state.callerShareDLPermissionMap,
            [reportId]: {
              ...state.callerShareDLPermissionMap && state.callerShareDLPermissionMap[reportId],
              report: true
            }
          };
        }
      }
      else if(deeplink.kind === "spreadsheetRow")
      {
        const formDeeplink = deeplink as DtoEntDeeplinkSpreadsheetRow;
        if(formDeeplink.spreadsheetId)
        {
          const spreadsheetId = formDeeplink.spreadsheetId;
          state.callerShareDLPermissionMap = {
            ...state.callerShareDLPermissionMap,
            [spreadsheetId]: {
              ...state.callerShareDLPermissionMap && state.callerShareDLPermissionMap[spreadsheetId],
              spreadsheetRow: true
            }
          };
        }
      }
      else if(deeplink.kind === "spreadsheetInsert")
      {
        const spreadsheetDeeplink = deeplink as DtoEntDeeplinkSpreadsheetInsert;
        if(spreadsheetDeeplink.spreadsheetId)
        {
          const spreadsheetId = spreadsheetDeeplink.spreadsheetId;
          state.callerShareDLPermissionMap = {
            ...state.callerShareDLPermissionMap,
            [spreadsheetId]: {
              ...state.callerShareDLPermissionMap && state.callerShareDLPermissionMap[spreadsheetId],
              spreadsheetInsert: true
            }
          };
        }
      }
      else if(deeplink.kind === "spreadsheetEditor")
      {
        const spreadsheetDeeplink = deeplink as DtoEntDeeplinkSpreadsheetEditor;
        if(spreadsheetDeeplink.spreadsheetId)
        {
          const spreadsheetId = spreadsheetDeeplink.spreadsheetId;
          state.callerShareDLPermissionMap = {
            ...state.callerShareDLPermissionMap,
            [spreadsheetId]: {
              ...state.callerShareDLPermissionMap && state.callerShareDLPermissionMap[spreadsheetId],
              spreadsheetEditor: true
            }
          };
        }
      }
    });
  }
}
