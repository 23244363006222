import {Property} from "csstype";
import React from "react";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import BubbleRawMediaAudio from "./bubbleForm/BubbleRawMediaAudio";
import BubbleRawMediaHtml from "./bubbleForm/BubbleRawMediaHtml";
import BubbleRawMediaHyperlink from "./bubbleForm/BubbleRawMediaHyperlink";
import BubbleRawMediaImage from "./bubbleForm/BubbleRawMediaImage";
import BubbleRawMediaVideo from "./bubbleForm/BubbleRawMediaVideo";
import {IBubbleRawMediaCarouselItem} from "./BubbleRawMediaCarousel";

export default function BubbleRawMediaCarouselFactory(props: {
  media: IBubbleRawMediaCarouselItem,
  onClick?: (media: IBubbleRawMediaCarouselItem) => void,
  borderRadius?: Property.BorderRadius,
  borderTopLeftRadius?: Property.BorderTopLeftRadius,
  borderTopRightRadius?: Property.BorderTopRightRadius,
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius,
  borderBottomRightRadius?: Property.BorderBottomRightRadius,
  disableOnClick?: boolean
})
{
  const media = props.media;
  const height = media.height ? px(Math.round(media.height)) : "auto";
  const width = media.width ? px(media.width) : "100%";

  const borderProps = {
    borderRadius: props.borderRadius,
    borderTopLeftRadius: props.borderTopLeftRadius,
    borderTopRightRadius: props.borderTopRightRadius,
    borderBottomLeftRadius: props.borderBottomLeftRadius,
    borderBottomRightRadius: props.borderBottomRightRadius
  };

  switch(media.type)
  {
    case "image":
    {
      const mediaIdImage = media.fieldValueImage?.value.mediaIdImage;
      const mediaIdBlurImage = media.fieldValueImage?.value.mediaIdBlurImage;
      const tempMediaSrc = mediaIdImage ? MediaStore.getMedia(mediaIdImage) : undefined;
      const tempMediaBlurSrc = mediaIdBlurImage ? MediaStore.getMedia(mediaIdBlurImage) : undefined;
      return <BubbleRawMediaImage
        src={tempMediaSrc || getMediaSrc(media.fieldValueImage?.value.mediaIdImage)}
        height={height}
        width={width}
        actualWidth={media.actualWidth}
        actualHeight={media.actualHeight}
        blurSrc={tempMediaBlurSrc || getMediaSrc(media.fieldValueImage?.value.mediaIdBlurImage)}
        primaryColor={media.fieldValueImage?.value.primaryColor}
        disableOnClick={props.disableOnClick}
        {...borderProps}
      />;
    }
    case "audio":
    case "voice":
    {
      const mediaIdAudio = media.fieldValueAudio?.mediaIdAudio;
      const tempMediaSrcAudio = mediaIdAudio ? MediaStore.getMedia(mediaIdAudio) : undefined;
      return <BubbleRawMediaAudio
        src={tempMediaSrcAudio || getMediaSrc(media.fieldValueAudio?.mediaIdAudio)}
        height={height}
        width={width}
        type={media.type}
      />;
    }
    case "video":
    {
      const mediaIdVideo = media.fieldValueVideo?.mediaIdVideo;
      const mediaIdImage = media.fieldValueVideo?.mediaIdImage;
      const mediaIdBlurImage = media.fieldValueVideo?.mediaIdBlurImage;
      const tempMediaSrcVideo = mediaIdVideo ? MediaStore.getMedia(mediaIdVideo) : undefined;
      const tempMediaSrcImage = mediaIdImage ? MediaStore.getMedia(mediaIdImage) : undefined;
      const tempMediaSrcBlurImage = mediaIdBlurImage ? MediaStore.getMedia(mediaIdBlurImage) : undefined;
      return <BubbleRawMediaVideo
        src={tempMediaSrcVideo || getMediaSrc(media.fieldValueVideo?.mediaIdVideo)}
        srcImage={tempMediaSrcImage || getMediaSrc(media.fieldValueVideo?.mediaIdImage)}
        height={height}
        width={width}
        blurImageSrc={tempMediaSrcBlurImage || getMediaSrc(media.fieldValueVideo?.mediaIdBlurImage)}
        primaryColor={media.fieldValueVideo?.primaryColor}
      />;
    }
    case "html":
      return <BubbleRawMediaHtml
        value={media.fieldValueHtml?.value?.join()}
        height={height}
        width={width}
      />;
    case "hyperlink":
      return <BubbleRawMediaHyperlink
        height={height}
        width={width}
        value={media.fieldValueHyperlink?.value}
      />;
  }
}
