import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldSetOfUser} from "../../../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldSetOfEntUserId} from "../../../../api/meta/base/dto/FieldSetOfEntUserId";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import RawChipsInput from "../../../atom/raw/RawChipsInput";
import RawPickManyAutoComplete from "../../../atom/raw/RawPickManyAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";

type TypeOnChange = (value: FieldSetOfEntUserId | null) => void;
export default function FieldSetOfUser(props: {
  defn: DefnFieldSetOfUser
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldIcon,
    getFieldShowAsDropdown
  } = useFieldPropertiesResolver(defn);

  const helperText = getFieldHelperText();
  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();
  const showAsDropdown = getFieldShowAsDropdown();

  const fieldId = getFieldKey(defn);

  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const fieldSize = defnTheme.fieldSize;
  const label = getCompLabel(defn);

  const [optionMap, setOptionMap] = useState<DefnStudioMapOfDtoOption | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const getOnClick = formCtx.getOnClick();
  const getOption = formCtx.getOnGetFieldOptions();

  const onOpen = () =>
  {
    if(showAsDropdown)
    {
      if(getOption)
      {
        setLoading(true);
        getOption(fieldId, options =>
        {
          if(options)
          {
            setOptionMap(options as DefnStudioMapOfDtoOption);
          }
          setLoading(false);
        });
      }
    }
  };

  useEffect(() =>
  {
    onOpen();
  }, []);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const onChange: TypeOnChange = field.onChange;
        const fieldValue: MetaIdOption[] = setOfEntUserIdToMetaIdOption(field.value as FieldSetOfEntUserId);

        const _fieldValue: DefnDtoOption[] = setOfEntUserIdToDefnDtoOption(field.value as FieldSetOfEntUserId,
          optionMap
        );

        const {error} = fieldState;
        const isError = Boolean(error);

        const onClick = () =>
        {
          getOnClick && getOnClick(fieldId, "field");
        };
        const cbOnChangeAutoComplete = (values?: MetaIdOption[] | null) =>
        {
          if(values === null || values === undefined)
          {
            onChange(null);
          }
          else
          {
            const displaySet = getValues(values, optionMap);
            onChange({
              displaySet: displaySet,
              valueSet: values
            } as FieldSetOfEntUserId);
          }
        };

        const cbOnChangeChipsInput = (values?: DefnDtoOption[]) =>
        {
          if(values === null || values === undefined)
          {
            onChange(null);
          }
          else
          {
            onChange({
              displaySet: values.map(keys => keys.value),
              valueSet: values.map(keys => keys.metaId)
            } as FieldSetOfEntUserId);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              {defn.showAsDropdown
                ? <RawPickManyAutoComplete
                  ref={field.ref}
                  fieldId={fieldId}
                  name={defn.name}
                  placeHolder={placeHolder}
                  label={label}
                  error={error}
                  required={required}
                  onBlur={field.onBlur}
                  disabled={formCtx.isFieldDisable(defn as DefnFieldEditable)}
                  readOnly={formCtx.isReadonly()}
                  inputFieldSize={fieldSize}
                  autoFocus={Boolean(defn.autoFocus)}
                  showChip={true}
                  icon={icon}
                  onChange={cbOnChangeAutoComplete}
                  value={fieldValue}
                  onClick={onClick}
                  optionMap={optionMap}
                  onOpen={onOpen}
                  loading={loading}
                />
                : <RawChipsInput
                  chipList={_fieldValue}
                  placeHolder={placeHolder}
                  label={label}
                  required={required}
                  onClick={onClick}
                  inputFieldSize={fieldSize}
                  disableEditing={true}
                  name={defn.name}
                  onChange={cbOnChangeChipsInput}
                  readOnly={formCtx.isReadonly()}
                  disabled={formCtx.isFieldDisable(defn as DefnFieldEditable)}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  isError={isError}
                  autoFocus={Boolean(defn.autoFocus)}
                  formCtx={formCtx}
                  fieldId={fieldId}
                />}
              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};

function setOfEntUserIdToMetaIdOption(value?: FieldSetOfEntUserId): MetaIdOption[]
{
  const optionIdSet = [] as MetaIdOption[];
  if(value?.valueSet)
  {
    value.valueSet.forEach(optionId =>
    {
      optionIdSet.push(optionId);
    });
  }

  return optionIdSet;
}

function setOfEntUserIdToDefnDtoOption(
  value?: FieldSetOfEntUserId,
  optionMap?: DefnStudioMapOfDtoOption): DefnDtoOption[]
{
  const dtoOptionSet = [] as DefnDtoOption[];
  if(value?.valueSet)
  {
    value?.valueSet.forEach((optionId, i) =>
    {
      const val = optionMap?.map[optionId]?.value || value.displaySet?.[i];
      if(val)
      {
        dtoOptionSet.push({
          metaId: optionId,
          value: val
        });
      }
    });
  }

  return dtoOptionSet as DefnDtoOption[];
}

function getValues(keys: MetaIdOption[], optionMap?: DefnStudioMapOfDtoOption)
{
  return optionMap && keys.map(value => optionMap.map[value].value);
}

