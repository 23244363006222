import {Button} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect} from "react";
import React from "react";
import {useCallback} from "react";
import {useRef} from "react";
import SignaturePad from "react-signature-canvas";
import {px} from "../../../base/plus/StringPlus";
import {DividerHorizontal} from "../layout/DividerHorizontal";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import DialogAtom from "./DialogAtom";
import {IDialogAtomRef} from "./DialogAtom";
import {Cancel} from "./DialogConfirm";
import {OK} from "./DialogConfirm";

export default function DialogSignature(props: {
  fullScreen?: boolean,
  onClose: () => void
  onSave: (dataUrl?: string) => void,
  value?: string,
  readOnly?: boolean
})
{
  const {
    fullScreen,
    onSave,
    onClose,
    value,
    readOnly
  } = props;

  const theme = useTheme();
  const cbDialogRef = {} as IDialogAtomRef;
  const sigCanvas = useRef<SignaturePad>(null);

  const onCLickClear = useCallback(() => sigCanvas.current?.clear(), []);

  const onClickSave = useCallback(() =>
  {
    const imageData = sigCanvas.current?.getCanvas().toDataURL("image/png");

    if(imageData && !sigCanvas.current?.isEmpty())
    {
      onSave(imageData);
    }
    else
    {
      onSave(undefined);
    }
  }, [onSave]);

  const setSignature = useCallback(() =>
  {
    if(value)
    {
      if(!sigCanvas.current)
      {
        setTimeout(() =>
        {
          setSignature();
        }, 100);
      }
      else
      {
        sigCanvas.current?.fromDataURL(value);
      }
    }
  }, [value]);

  useEffect(() =>
  {
    setSignature();
  }, [value]);

  return (
    <DialogAtom
      cbRef={cbDialogRef}
      onClose={onClose}
      title={"Draw signature"}
      fullScreen={fullScreen}
      content={
        <LayoutFlexCol
          width={fullScreen ? "100%" : "600px"}
          height={fullScreen ? "100%" : "400px"}
        >
          <LayoutFlexCol
            width={"100%"}
            flexGrow={1}
          >
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                style: {
                  flexGrow: 1,
                  width: "100%"
                }
              }}
            />

          </LayoutFlexCol>

          <DividerHorizontal />

          {!readOnly &&
            <LayoutFlexRow
              bgcolor={theme.common.bgcolorActionBar}
              width={"100%"}
              justifyContent={"space-between"}
              padding={px(theme.common.gapStd)}
            >
              <Box>
                <Button
                  disableFocusRipple={true}
                  sx={{
                    padding: "0px"
                  }}
                  onClick={onCLickClear}
                >
                  Clear
                </Button>
              </Box>
              <Box>
                <Button
                  disableFocusRipple={true}
                  sx={{
                    padding: "0px"
                  }}
                  onClick={onClose}
                >
                  {Cancel}
                </Button>
                <Button
                  disableFocusRipple={true}
                  sx={{
                    padding: "0px"
                  }}
                  onClick={onClickSave}
                >
                  {OK}
                </Button>
              </Box>
            </LayoutFlexRow>
          }

        </LayoutFlexCol>
      }
    />
  );
}
