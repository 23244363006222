import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {getListItemHeight} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {MuiAvatarFontSize} from "../../../base/plus/ThemePlus";
import {listSetLoadMoreFlag} from "../../../base/slices/list/SliceListFooterActions";
import {useAppSelector} from "../../app/AppHooks";
import LayoutFlexRow from "../../atom/layout/LayoutFlexRow";

export type EnumIconLoadMore = "downArrow" | undefined;

export function ListItemLoadMore(props: {
  selectList: SelectList,
})
{
  const theme = useTheme();
  const listName = useAppSelector(state => props.selectList(state).listName);
  const itemHeight = getListItemHeight("aps") as number;

  return (
    <LayoutFlexRow
      width={"100%"}
      height={px(itemHeight)}
      justifyContent={"flex-start"}
      onClick={() => dispatchList(listName, listSetLoadMoreFlag())}
      cursorPointer={true}
    >
      <LayoutFlexRow
        mr={px(theme.common.gapStd)}
        ml={px(theme.common.gapStd)}
      >
        <LoadMoreIcon
          icon={"downArrow"}
          fontSize={"medium"}
        />
      </LayoutFlexRow>
      <Typography>Load more</Typography>
    </LayoutFlexRow>
  );
}

function LoadMoreIcon(
  props: {
    icon: EnumIconLoadMore,
    fontSize?: MuiAvatarFontSize,
    iconSize?: StandardCSSProperties["width"]
  }
)
{
  const icon = props.icon;
  const fontSize = props.fontSize;
  const size = props.iconSize;

  switch(icon)
  {
    case "downArrow":
      return <KeyboardArrowDownIcon
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    default:
      return null;
  }
}
