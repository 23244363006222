import {CalendarPickerView} from "@mui/lab";
import {Box} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {isEmpty} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {Noop} from "react-hook-form";
import {DefnBuildDate} from "../../../../api/meta/base/dto/DefnBuildDate";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldDateRange} from "../../../../api/meta/base/dto/DefnFieldDateRange";
import {FieldValueDateRange} from "../../../../api/meta/base/dto/FieldValueDateRange";
import {getLocalDateFormat} from "../../../../base/plus/DatePlus";
import {extractDateFormat} from "../../../../base/plus/DatePlus";
import {formatDateToUTCMidnight} from "../../../../base/plus/DatePlus";
import {getBuildDateDefaultValue} from "../../../../base/plus/FieldValuePlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import {getRawIcon} from "../../../atom/raw/RawIcon";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import IFormCtx from "../base/CtxForm";
import FieldRawRefButton from "./FieldRawRefButton";

const dateFormat = getLocalDateFormat();
export default function FieldRawDateRange(props: {
  defn: DefnFieldDateRange,
  onChange: (value?: FieldValueDateRange | null) => void,
  value?: FieldValueDateRange,
  views?: CalendarPickerView[],
  defnTheme: DefnDtoFormTheme,
  formCtx: IFormCtx,
  fieldId: string,
  inputFormat?: string,
  autoFocus?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  isError?: boolean,
  required?: boolean,
  icon?: string,
  minDate?: DefnBuildDate,
  maxDate?: DefnBuildDate,
  placeholder?: string,
  onBlur?: Noop,
  onClick?: () => void,
})
{
  const {
    defn,
    formCtx,
    fieldId,
    views,
    autoFocus,
    readOnly,
    disabled,
    onChange,
    value,
    defnTheme,
    isError,
    required,
    icon,
    placeholder,
    minDate,
    maxDate,
    onBlur,
    onClick
  } = props;

  const [startDate, setStartDate] = useState<string>();

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const dateOnly = defn?.displayDateFormat
    ? extractDateFormat(defn.displayDateFormat)
    : extractDateFormat(dateFormat);

  const buildMinDate = minDate ? getBuildDateDefaultValue(minDate) : undefined;
  const buildMaxDate = maxDate ? getBuildDateDefaultValue(maxDate) : undefined;

  const _minDate = buildMinDate ? new Date(buildMinDate) : undefined;
  const _maxDate = buildMaxDate ? new Date(buildMaxDate) : undefined;

  useEffect(() =>
  {
    if(value && !isEmpty(value.from))
    {
      setStartDate(value.from);
    }
    else
    {
      setStartDate(undefined);
    }
  }, [value]);

  return (
    <LayoutFlexCol
      width={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
      onClick={() =>
      {
        onClick && onClick();
      }}
    >
      <RawLocalizationProvider>
        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DatePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            format={dateOnly}
            minDate={_minDate}
            maxDate={_maxDate}
            label={"Date From"}
            value={(value && value.from) ? new Date(value.from) : undefined}
            views={["year", "month", "day"]}
            autoFocus={autoFocus}
            readOnly={readOnly}
            disabled={disabled}
            onClose={onBlur}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            slotProps={{
              textField: {
                placeholder: placeholder,
                fullWidth: true,
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: defnTheme.fieldVariant,
                autoComplete: Boolean(defn.autoFill) ? "on" : "off",
                required: required,
                onBlur: onBlur,
                error: isError,
                value: (value && value.from) ? new Date(value.from) : undefined
              }
            }}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const date = new Date(event);
                onChange({
                  from: formatDateToUTCMidnight(date).toISOString(),
                  to: value ? value?.to : undefined
                });
                setStartDate(formatDateToUTCMidnight(date).toISOString());
              }
            }}
          />

          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                iconStrip={["delete"]}
                onClick={(icon) =>
                {
                  if(icon === "delete")
                  {
                    props.onChange(null);
                  }
                }}
                iconStripDisable={(!value || readOnly || disabled) ? ["delete"] : []}
              />
            </LayoutFlexRow>
          }
          <FieldRawRefButton
            defn={defn}
          />
        </LayoutFlexRow>
        <LayoutGap height={px(gapStd)} />
        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DatePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            value={(value && value.to) ? new Date(value.to) : undefined}
            label={"Date To"}
            views={views}
            format={dateOnly}
            autoFocus={autoFocus}
            readOnly={readOnly}
            disabled={disabled || !startDate}
            minDate={startDate ? new Date(startDate) : undefined}
            maxDate={_maxDate}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const date = new Date(event);
                onChange({
                  to: formatDateToUTCMidnight(date).toISOString(),
                  from: value ? value.from : undefined
                });
              }
            }}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            slotProps={{
              textField: {
                fullWidth: true,
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: defnTheme.fieldVariant,
                autoComplete: Boolean(defn.autoFill) ? "on" : "off",
                required: required,
                onBlur: onBlur,
                onClick: onClick,
                error: isError,
                value: (value && value.to) ? new Date(value.to) : undefined
              }
            }}
          />
          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                iconStrip={["delete"]}
                onClick={(icon) =>
                {
                  if(icon === "delete")
                  {
                    props.onChange({
                      from: value ? value.from : undefined,
                      to: undefined
                    });
                  }
                }}
                iconStripDisable={(!value || readOnly || disabled) ? ["delete"] : []}
              />
            </LayoutFlexRow>
          }
          <Box sx={{visibility: "hidden"}}>
            <FieldRawRefButton
              defn={defn}
            />
          </Box>
        </LayoutFlexRow>
      </RawLocalizationProvider>

    </LayoutFlexCol>
  );
}

