import {useCallback} from "react";
import {useMemo} from "react";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {TypeDataGridClickVariant} from "../../../../base/types/TypeDataGrid";
import {IDataGridCellRendererParams} from "../../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {rowMaster} from "../DataGridPlus";
import GridCellFooter from "./GridCellFooter";

export default function GridCellActions<SR1, SR2, SR3, SR4, SR5, SR6>(props: IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const colId = props.colDef?.colId;
  const row = props.data;
  const cbOnClick = props.cbOnClickDataGrid;
  const showMenu = (props.context as AgGridContext).showColMenu;
  const layout = (props.context as AgGridContext).layout;
  const isFooterRow = props.data?.isFooterRow;
  const [expanded, setExpanded] = useState(props.node.expanded || false);
  const [iconStrip, setIconStrip] = useState<EnumIconStrip[]>();

  const isExpandable = props.node.isExpandable();
  const isRowMaster = useMemo(() =>
  {
    const keys = layout?.masterDetailGridLayoutMap ? Object.keys(layout.masterDetailGridLayoutMap) : [];
    return row ? rowMaster(row, keys) : undefined;
  }, [layout?.masterDetailGridLayoutMap, row]);

  const iconStripDisable = useMemo(() => [
    ...isRowMaster ? [] : ["expandArrowRight", "expandArrowDown"],
    ...row?.hideMenu ? ["moreHorizontal"] : []
  ] as EnumIconStrip[], [isRowMaster, row?.hideMenu]);

  const cbOnClickIcon = useCallback((iconName: EnumIconStrip, menuAnchor: Element) =>
  {
    let variant: TypeDataGridClickVariant | undefined = "cell";
    switch(iconName)
    {
      case "expandArrowRight":
      case "expandArrowDown":
        setExpanded(prevState => !prevState);
        return;
      case "moreHorizontal":
        variant = "actionBtnSpotMenu";
        break;
    }
    if(cbOnClick && row)
    {
      cbOnClick(menuAnchor, variant, row, colId, props.node.isSelected());
    }
  }, [cbOnClick, colId, props.node, row]);

  useEffect(() =>
  {
    props.node.setExpanded(expanded);
  }, [expanded]);

  useEffect(() =>
  {
    const _iconStrip = [
      // expanded && collapsed
      ...isExpandable
        ? !expanded
          ? ["expandArrowRight"] as EnumIconStrip[]
          : ["expandArrowDown"] as EnumIconStrip[]
        : [],
      // menu
      ...showMenu
        ? ["moreHorizontal"] as EnumIconStrip[]
        : []
    ] as EnumIconStrip[];

    if(_iconStrip.length)
    {
      setIconStrip(_iconStrip);
    }
    else
    {
      setIconStrip(undefined);
    }

  }, [isExpandable, showMenu, expanded]);

  if(isFooterRow)
  {
    return <GridCellFooter {...props} />;
  }

  if(iconStrip)
  {

    return <LayoutFlexRow
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <RawIconStrip
        iconStrip={iconStrip}
        onClick={cbOnClickIcon}
        iconStripDisable={iconStripDisable}
      />
    </LayoutFlexRow>;
  }
  return null;
}
