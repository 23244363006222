import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickReportId} from "../../../../api/meta/base/dto/DefnStudioPickReportId";
import {StudioEntReportMap} from "../../../../api/meta/base/dto/StudioEntReportMap";
import {MetaIdReport} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdReport | MetaIdReport[] | undefined

export default function FieldRawStudioPickReportId(props: {
  defn: DefnStudioPickReportId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const reportDefn = props.defn;
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();

  const formStore = formCtx.getStore();
  const reportMap = formStore?.reportMap;

  const excludeReportSet = reportDefn.excludeReportIdSet;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const dtoOptions = useMemo(() => getOption(
    reportMap,
    excludeReportSet
  ), [fieldValue, formStore, reportMap, excludeReportSet]);

  const [options] = useInsertTrashOptions({
    type: "reportMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOption(reportMap?: StudioEntReportMap, excludeReportSet?: MetaIdReport[]): DefnDtoOption[]
{
  if(!reportMap)
  {
    return [];
  }
  const options: DefnDtoOption[] = [];
  reportMap.keys.forEach(reportId =>
  {
    const report = reportMap.map[reportId];
    if(excludeReportSet?.includes(report.metaId))
    {
      return;
    }
    options.push({
      metaId: report.metaId,
      value: report.details.name || report.metaId
    });
  });
  return options;
}
