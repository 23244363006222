import {useTheme} from "@mui/material";
import {Typography} from "@mui/material";
import {isEmpty} from "lodash";
import {useMemo} from "react";
import React from "react";
import {DtoMessagePayloadText} from "../../../../api/home/base/dto/DtoMessagePayloadText";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import IconBubbleCaption from "../../icon/IconBubbleCaption";
import BubbleRawCaption from "../raw/BubbleRawCaption";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleRawText from "../raw/BubbleRawText";
import BubbleShell from "./BubbleShell";

export default function BubbleText(props: {
  payload: DtoMessagePayloadText,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  header?: IBubbleHeader,
  isStar?: boolean,
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
  onClickSendMessageToUser?: (entUserId: EntUserId, menuAnchor: Element) => void
})
{
  const theme = useTheme();
  const payload = props.payload;
  const isStar = props.isStar;
  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));
  const captionIcon = props.receiptStatus;
  const onClickSendMessageToUser = props.onClickSendMessageToUser;
  const mentionMap = payload.mentionMap;
  const isCallerSender = props.isCallerSender;
  const maxWidth = (props.maxWidth > theme.common.bubbleTextMaxWidth
    ? theme.common.bubbleTextMaxWidth
    : props.maxWidth) - 42; // 42 pixels gap for reaction icon that appears on mouse hover

  const mentionUserNameSet = useMemo(() =>
  {
    if(!isEmpty(mentionMap))
    {
      return [...Object.keys(mentionMap)] as string[];
    }
    else
    {
      return undefined;
    }
  }, [mentionMap]);

  const onClickMessageToUser = (mentionUser: string, menuAnchor: Element) =>
  {
    if(mentionMap)
    {
      const entUserId = mentionMap[mentionUser];
      onClickSendMessageToUser && onClickSendMessageToUser(entUserId, menuAnchor);
    }
  };

  const text = payload.text;
  return (
    <BubbleShell
      isCallerSender={isCallerSender}
      maxWidth={maxWidth}
      fullWidth={true}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleHover={props.isBubbleHover}
      isBubbleBlinking={props.isBubbleBlinking}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      {
        props.header !== undefined &&
        <BubbleRawHeader
          header={props.header}
          onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
        />
      }

      {
        props.replyPayload &&
        <BubbleRawReplyPreview
          replyPayload={props.replyPayload}
          replyInfo={props.replyInfo}
          onClickPreview={props.cbOnClickReplyPreview}
        />
      }

      <BubbleRawText
        value={text}
        maxCharLimit={800}
        mentionWords={mentionUserNameSet}
        searchWords={props.searchWords}
        onClickSendMessageToUser={onClickMessageToUser}
        fakeSpace={
          <>
            {
              Boolean(isStar) &&
              <IconBubbleCaption
                value={"star"}
                color={"dark"}
              />
            }

            <Typography
              ml={px(theme.common.gapQuarter)}
              variant={"caption"}
            >
              {captionTime}
            </Typography>

            <IconBubbleCaption
              value={captionIcon}
              color={"dark"}
            />
          </>
        }
        caption={
          <BubbleRawCaption
            isStar={Boolean(isStar)}
            textRight={captionTime}
            icon={captionIcon}
            iconColor={"dark"}
            onClickCaption={props.cbOnClickFooterCaption}
          />}
      />
    </BubbleShell>
  );
}
