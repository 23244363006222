import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {TypeProgressId} from "./TypesStatus";
import {TypeLockId} from "./TypesStatus";
import {IStatusState} from "./TypesStatus";

function getInitialState()
{
  const date = new Date();
  return {
    flagNetworkOn: false,
    flagFirstBoot: true,
    currentHour: date.getHours(),
    browserTabFocus: false,
    progressMap: {},
    lockMap: {}
  } as IStatusState;
}

export const sliceStatus = createSlice({
  name: "status",
  initialState: getInitialState(),
  reducers: {
    setFlagNetworkOn: (state, action: PayloadAction<boolean>) =>
    {
      state.flagNetworkOn = action.payload;
    },
    setFlagFirstBoot: (state, action: PayloadAction<boolean>) =>
    {
      state.flagFirstBoot = action.payload;
    },
    resetCurrentHour: (state, action: PayloadAction<number>) =>
    {
      state.currentHour = action.payload;
    },
    setBrowserTabFocus: (state, action: PayloadAction<boolean>) =>
    {
      state.browserTabFocus = action.payload;
    },
    statusLock: (state, action: PayloadAction<{lockId: TypeLockId, value: boolean}>) =>
    {
      const payload = action.payload;
      const lockId = payload.lockId;
      const value = payload.value;
      if(value)
      {
        state.lockMap[lockId] = true;
      }
      else
      {
        delete state.lockMap[lockId];
      }
    },
    statusProgress: (state, action: PayloadAction<{progressId: TypeProgressId, value: number}>) =>
    {
      const payload = action.payload;
      const progressId = payload.progressId;
      const value = payload.value % 100;
      if(value > 0 && value < 100)
      {
        state.progressMap[progressId] = value;
      }
      else
      {
        delete state.progressMap[progressId];
      }
    }
  }
});

export const {
  setFlagNetworkOn,
  setFlagFirstBoot,
  resetCurrentHour,
  setBrowserTabFocus,
  statusLock,
  statusProgress
} = sliceStatus.actions;

