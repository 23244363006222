// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgStudioEntCreate} from './msg/MsgStudioEntCreate';
import {MsgStudioEntDeploy} from '../studioMain/msg/MsgStudioEntDeploy';
import {MsgStudioEntMergeTest} from './msg/MsgStudioEntMergeTest';
import {MsgStudioEntPartMerge} from './msg/MsgStudioEntPartMerge';
import {MsgStudioPluginCreate} from './msg/MsgStudioPluginCreate';
import {MsgStudioSearch} from './msg/MsgStudioSearch';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {PluginBundleId} from '../../meta/base/Types';
import {ServiceName} from '../../meta/base/Types';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigStudioDeployedArtifactList} from './sig/SigStudioDeployedArtifactList';
import {SigStudioEnt} from '../studioMain/sig/SigStudioEnt';
import {SigStudioEntAvatar} from './sig/SigStudioEntAvatar';
import {SigStudioEntDeploy} from './sig/SigStudioEntDeploy';
import {SigStudioPluginAvatar} from './sig/SigStudioPluginAvatar';
import {SigStudioPluginBundle} from './sig/SigStudioPluginBundle';
import {SigStudioSearch} from './sig/SigStudioSearch';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcStudioDrawer
{
  static readonly SN: ServiceName = "studioDrawer";

  static studioDeployedArtifactListGet(sigAcceptor: ISigAcceptor<SigStudioDeployedArtifactList>): void
  {
    rpcCall<SigStudioDeployedArtifactList>(ENT_ID_GLOBAL, RpcStudioDrawer.SN, "studioDeployedArtifactListGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static studioEntAvatarGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigStudioEntAvatar>): void
  {
    rpcCall<SigStudioEntAvatar>(entId, RpcStudioDrawer.SN, "studioEntAvatarGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static studioEntCreate(msg: MsgStudioEntCreate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcStudioDrawer.SN, "studioEntCreate")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static studioEntDeploy(entId: EntId, msg: MsgStudioEntDeploy, sigAcceptor: ISigAcceptor<SigStudioEntDeploy>): void
  {
    rpcCall<SigStudioEntDeploy>(entId, RpcStudioDrawer.SN, "studioEntDeploy")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static studioEntGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigStudioEnt>): void
  {
    rpcCall<SigStudioEnt>(entId, RpcStudioDrawer.SN, "studioEntGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static studioEntMergeTest(msg: MsgStudioEntMergeTest, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcStudioDrawer.SN, "studioEntMergeTest")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static studioEntPartMerge(entId: EntId, msg: MsgStudioEntPartMerge, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcStudioDrawer.SN, "studioEntPartMerge")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static studioEntRemove(entId: EntId, sigAcceptor: ISigAcceptor<SigStudioEntDeploy>): void
  {
    rpcCall<SigStudioEntDeploy>(entId, RpcStudioDrawer.SN, "studioEntRemove")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static studioPluginAvatarGet(pluginBundleId: PluginBundleId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigStudioPluginAvatar>): void
  {
    rpcCall<SigStudioPluginAvatar>(pluginBundleId, RpcStudioDrawer.SN, "studioPluginAvatarGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static studioPluginBundleGet(pluginBundleId: PluginBundleId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigStudioPluginBundle>): void
  {
    rpcCall<SigStudioPluginBundle>(pluginBundleId, RpcStudioDrawer.SN, "studioPluginBundleGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static studioPluginBundleRemove(pluginBundleId: PluginBundleId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioDrawer.SN, "studioPluginBundleRemove")
      .sendBearerToken()
      .delete(undefined, sigAcceptor);
  }

  static studioPluginCreate(msg: MsgStudioPluginCreate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcStudioDrawer.SN, "studioPluginCreate")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static studioPluginDeploy(pluginBundleId: PluginBundleId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(pluginBundleId, RpcStudioDrawer.SN, "studioPluginDeploy")
      .sendBearerToken()
      .put(undefined, sigAcceptor);
  }

  static studioSearch(msg: MsgStudioSearch, sigAcceptor: ISigAcceptor<SigStudioSearch>): void
  {
    rpcCall<SigStudioSearch>(ENT_ID_GLOBAL, RpcStudioDrawer.SN, "studioSearch")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }
}