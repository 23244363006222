import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {isStoreItemId} from "../../../api/meta/base/ApiPlus";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {StoreItemId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {removeStoreItem} from "../../../cache/store/storeItem/SliceCacheStoreItem";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcPubSubStore extends ISrvc
{
  storeItem(
    subscriberId: TypeSubscriberId,
    storeItemId: StoreItemId,
    unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: storeItemId,
      artifactId: ENT_ID_GLOBAL,
      type: "storeItem"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  storeItemAdminBook(
    subscriberId: TypeSubscriberId,
    storeItemId: StoreItemId,
    unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: storeItemId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioAdminBook"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  handleSigTopic(sig: SigTopic, isOnSub?: boolean): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "storeItem":
        Srvc.store.storeItem.rpcStoreItemGet(sig.aboutId as StoreItemId);
        return true;
    }
    return false;
  }

  handleTopicUnSub(sig: SigTopic): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "storeItem":
        store.dispatch(removeStoreItem(sig.aboutId as StoreItemId));
        return true;
      case "studioAdminBook":
        if(isStoreItemId(sig.aboutId))
        {
          Srvc.store.admins.resetStoreItemAdminBook(sig.aboutId as StoreItemId);
        }
        return true;
    }

    return false;
  }

}
