import {isEmpty} from "lodash";
import {nextMetaIdDeeplink} from "../../../../api/meta/base/ApiPlus";
import {StudioEntDeeplink} from "../../../../api/meta/base/dto/StudioEntDeeplink";
import {EntId, MetaIdDeeplink} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntDeeplink, moveEntItem, removeEntDeeplink} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntDeeplinks extends ISrvc
{
  addEntDeeplink(entId: EntId, deeplink: StudioEntDeeplink)
  {
    dispatchEnt(entId, addEntDeeplink({
      entId: entId,
      sig: {
        ...deeplink,
        metaId: deeplink.metaId || nextMetaIdDeeplink()
      }
    }));
  }

  removeEntDeeplink(entId: EntId, metaIdDeeplink: MetaIdDeeplink)
  {
    dispatchEnt(entId, removeEntDeeplink({
      entId: entId,
      metaId: metaIdDeeplink
    }));
  }

  copyEntDeeplink(entId: EntId, metaIdDeeplink: MetaIdDeeplink)
  {
    const rootState = store.getState();
    const deeplinkMapMap = selectCacheStudioEnt(rootState, entId)?.ent.deeplinkMap;
    const deepLink = deeplinkMapMap?.map[metaIdDeeplink];

    if(!isEmpty(deepLink))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "deeplink",
        payload: deepLink
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntDeeplink(entId: EntId, metaIdDeeplink: MetaIdDeeplink)
  {
    const rootState = store.getState();
    const deeplinkMap = rootState.cache.studio.ent.entMap[entId].ent.deeplinkMap;
    if(!deeplinkMap)
    {
      return;
    }

    const index = deeplinkMap.keys.indexOf(metaIdDeeplink);
    if(index === -1)
    {
      return;
    }

    const entDeepLink = deeplinkMap.map[metaIdDeeplink]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(deeplinkMap.map[metaIdDeeplink])))
      : undefined;

    if(entDeepLink)
    {
      dispatchEnt(entId, addEntDeeplink({
        entId: entId,
        sig: entDeepLink,
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdDeeplink,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "deeplinkMap",
      moveDirection
    }));
  }

}
