import {Box} from "@mui/material";
import React from "react";

export default function AppWrapper(props: {
  width: number,
  content: React.ReactNode
})
{
  return (
    <Box
      sx={{
        position: "relative",
        display: props.width === 0 ? "none" : "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: props.width,
        height: "100%"
      }}
    >
      {props.content}
    </Box>
  );
}
