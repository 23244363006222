import {useMemo} from "react";
import {useRef} from "react";
import {useState} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {StudioDtoFieldDynamicCondition} from "../../api/meta/base/dto/StudioDtoFieldDynamicCondition";
import {ValidationResult} from "../../api/meta/base/dto/ValidationResult";
import {VisibilityType} from "../../api/meta/base/StudioSetsFieldType";
import {EnumDefnDynamicOperator} from "../../api/meta/base/Types";
import {MetaIdFieldDynamicCondition} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {getFormFieldsErrorList} from "../../base/plus/StudioPlus";
import theme from "../../base/plus/ThemePlus";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {EnumStudioSearchPathKeys} from "../../base/types/TypesStudio";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const contentWidth = theme.common.appMinTabletWidth;

export default function DialogNewFormDynamicCondition(props: {
  sourceFormId?: MetaIdForm,
  formStore: FormStore,
  isFormReadOnly?: boolean,
  values?: StudioDtoFieldDynamicCondition,
  onClickOk: (values: StudioDtoFieldDynamicCondition) => void,
  onClose?: () => void,
  validationResult?: ValidationResult,
  metaId?: MetaIdField,
  nodeId?: MetaIdFieldDynamicCondition
})
{
  const values = props.values;
  const onClickSubmit = props.onClickOk;
  const formId = props.sourceFormId;
  const validationResult = props.validationResult;
  const metaId = props.metaId;
  const nodeId = props.nodeId;

  const cbRef = useRef({} as IFormRef);

  const errorList = useMemo(() =>
  {
    return getFormFieldsErrorList(metaId as EnumStudioSearchPathKeys, nodeId, validationResult, 5);
  }, [metaId, nodeId, validationResult]);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: errorList
  });

  const [fieldId, setFieldId] = useState<MetaIdField | undefined>(values?.lhs);
  const [operator, setOperator] = useState<EnumDefnDynamicOperator | undefined>(values?.operator);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} dynamic rule condition`}
      formProps={{
        store: props.formStore,
        defnForm: getDefnForm(formId, fieldId, operator),
        formReadonly: props.isFormReadOnly,
        cbRef: cbRef.current,
        onSubmit: values =>
        {
          onClickSubmit(valueToDto(values));
        },
        initValues: dtoToValue(values),
        onWatch: (key, value) =>
        {
          if(key === fieldLhs)
          {
            cbRef.current.setValue && cbRef.current.setValue(fieldRhs, null);
            setFieldId(fnFieldValueToRawValue("pickFieldId", value) as MetaIdField);
          }
          if(key === fieldOperator)
          {
            setOperator(fnFieldValueToRawValue("enumDynamicOperator", value) as EnumDefnDynamicOperator);
          }
        }
      }}
      addMoreCheckBoxLabel={!values
        ? "Add more dynamic rule condition"
        : undefined}
      onClose={props.onClose}
      contentWidth={contentWidth}
    />
  );
}

function valueToDto(values: FieldValues): StudioDtoFieldDynamicCondition
{
  const visibilityOperator = fnFieldValueToRawValue("enumDynamicOperator",
    values[fieldOperator]
  ) as EnumDefnDynamicOperator;
  const rhs = visibilityOperator === "hasValue" || visibilityOperator === "hasNoValue"
    ? undefined
    : values[fieldRhs];

  return {
    lhs: fnFieldValueToRawValue("pickFieldId", values[fieldLhs]),
    operator: visibilityOperator,
    rhs: fnFieldValueToRawValueArgBinder(rhs)
  } as StudioDtoFieldDynamicCondition;
}

function dtoToValue(dto?: StudioDtoFieldDynamicCondition)
{
  if(dto)
  {
    return {
      [fieldOperator]: fnRawValueToFieldValue("enumDynamicOperator", dto?.operator),
      [fieldLhs]: fnRawValueToFieldValue("pickFieldId", dto?.lhs),
      ...fnRawValueToFieldValueArgBinder(fieldRhs, dto?.rhs)
    };
  }
}

const fieldLhs = "lhs";
const fieldOperator = "operator";
const fieldRhs = "rhs";

function getDefnForm(
  formId?: MetaIdForm,
  fieldId?: MetaIdField,
  operator?: EnumDefnDynamicOperator)
{
  const isSourceDisable = !fieldId
    || operator === "hasValue"
    || operator === "hasNoValue";

  return createDefaultDefnFormStudio({
    [fieldLhs]: {
      type: "pickFieldId",
      name: fieldLhs,
      metaId: fieldLhs,
      label: "Field",
      formId: formId,
      filterFieldTypeSet: VisibilityType,
      showCompositeName: true,
      required: true
    } as DefnStudioPickFieldId,

    [fieldOperator]: {
      type: "enumDynamicOperator",
      name: fieldOperator,
      metaId: fieldOperator,
      label: "Operator",
      required: true
    } as DefnFieldPickEnum,

    [fieldRhs]: {
      type: "studioBuildArgBinder",
      name: "source",
      metaId: fieldRhs,
      formId: formId,
      derivedFormId: formId,
      peerFieldId: fieldId,
      disabled: isSourceDisable
    } as DefnStudioBuildArgBinder,

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: [
        fieldLhs,
        fieldOperator,
        fieldRhs
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}
