import {Property} from "csstype";
import {isArray} from "lodash";
import {forwardRef} from "react";
import React from "react";
import {RefCallBack} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {DefnStudioMapOfDtoOption} from "../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {MetaIdOption} from "../../../api/meta/base/Types";
import {EnumDefnThemeSectionVariant} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../api/meta/base/Types";
import RawAutocomplete from "./RawAutocomplete";

export interface FieldPickManyAutocomplete
{
  sectionVariant?: EnumDefnThemeSectionVariant;
  width?: Property.Width,
  height?: Property.Height,
  inputFieldSize?: EnumDefnThemeFieldSize,
  fieldId: MetaIdField,
  name: string,
  label?: string,
  optionMap?: DefnStudioMapOfDtoOption,
  value?: MetaIdOption[],
  disableCloseOnSelect?: boolean,
  disableClearable?: boolean,
  disabled?: boolean,
  onChange?: (value: MetaIdOption[] | null) => void,
  fieldVariant?: EnumDefnThemeFieldVariant;
  fieldSize?: EnumDefnThemeFieldSize;
  error?: FieldError,
  helperText?: string,
  placeholder?: string,
  required?: boolean,
  autoFocus?: boolean,
  placeHolder?: string,
  hideLabel?: boolean,
  readOnly?: boolean,
  onOpen?: () => void,
  onClick?: () => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  endAdornment?: React.ReactNode,
  showChip?: boolean,
  searchWords?: string[],
  loading?: boolean,
  hideChips?: boolean,
  icon?: string
}

const RawPickManyAutoComplete = forwardRef<RefCallBack, FieldPickManyAutocomplete>((
  props: FieldPickManyAutocomplete,
  ref) =>
{
  const options = props.optionMap;
  const value = props.value;

  return (
    <RawAutocomplete
      {...props}
      ref={ref}
      freeSolo={false}
      placeHolder={props.placeHolder}
      multiple={true}
      optionMap={options}
      value={value}
      endAdornment={props.endAdornment}
      onChange={(data?: MetaIdOption | MetaIdOption[] | null) =>
      {
        if(data && isArray(data))
        {
          props.onChange && props.onChange(data as MetaIdOption[]);
        }
        else
        {
          props.onChange && props.onChange(null);
        }
      }}
    />
  );
});

export default RawPickManyAutoComplete;
