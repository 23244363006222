import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioBuildTree} from "../../../../api/meta/base/dto/DefnStudioBuildTree";
import {StudioVarValueTree} from "../../../../api/meta/base/dto/StudioVarValueTree";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import FieldRawTreeBuilder from "../raw/FieldRawTreeBuilder";

export default function FieldStudioBuildTree(props: {
  defn: DefnStudioBuildTree
})
{
  const formCtx = useFormCtx();

  const defnTree = props.defn;
  const fieldId = getFieldKey(defnTree);
  const defnTheme = formCtx.getDefnTheme();
  // const isLastField = formCtx.isLastFieldInDisplay(defnTree.metaId);
  const isLastField = false;
  const label = defnTree.label;

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const isReadOnly = formCtx.isReadonly();
  const showAddBtn = !isReadOnly && !isReport;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue: StudioVarValueTree | undefined = field.value;
        const onChange = field.onChange;
        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <FieldRawTreeBuilder
                  fieldValue={fieldValue}
                  isFormReadOnly={isReadOnly}
                  isLastField={isLastField}
                  showAddBtn={showAddBtn}
                  onChange={onChange}
                />
              }
            />
          );
        }

        return <FieldRawTreeBuilder
          fieldValue={fieldValue}
          isLastField={isLastField}
          isFormReadOnly={isReadOnly}
          showAddBtn={showAddBtn}
          onChange={onChange}
        />;
      }}
    />
  );
}

