import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarDirection} from "../../../api/meta/base/dto/StudioVarDirection";

export function fnVariableBuilderDirection(fieldValueDirection: string)
{
  function getVarComp()
  {
    return {
      [fieldValueDirection]: {
        type: "enumThemeDirection",
        metaId: fieldValueDirection,
        label: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarDirection
  )
  {
    valueMap[fieldValueDirection] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueDirection];

    return {
      value: value
    } as StudioVarDirection;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
