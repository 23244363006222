import React from "react";
import {SpreadsheetFilterValue} from "../../api/ent/base/dto/SpreadsheetFilterValue";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawNothingHere from "../atom/raw/RawNothingHere";
import SsBrFilterChipset from "./SsBrFilterChipset";

export default function SsBrMain(props: {
  defnFormRef: DefnForm,
  spreadSheetBrContent?: React.ReactNode
  onFilterUpdate?: (selectedFilters?: SpreadsheetFilterValue[], searchStr?: string) => void,
})
{
  const spreadSheetBrContent = props.spreadSheetBrContent;

  return (
    <LayoutFlexCol
      flexGrow={1}
      width={"100%"}
      alignItems={"flex-start"}
      justifyContent={"start"}
    >
      <SsBrFilterChipset
        defnFormRef={props.defnFormRef}
        onFilterUpdate={props.onFilterUpdate}
      />
      <LayoutFlexCol
        width={"100%"}
        flexGrow={1}
      >
        {spreadSheetBrContent || <RawNothingHere />}
      </LayoutFlexCol>
    </LayoutFlexCol>
  );
}
