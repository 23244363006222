import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickDeployPaymentProviderId from "./FieldRawStudioPickDeployPaymentProviderId";

export default function FieldStudioPickPaymentProviderId(props: {
  defn: DefnFieldEditable
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickDeployPaymentProviderId
        fieldProps={fieldProps}
        defn={props.defn}
        showChip={false}
        multiSelect={false}
      />
      }
    />
  );
}
