import {useCallback} from "react";
import {useEffect, useMemo, useState} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {StudioDtoConditionStatement} from "../../api/meta/base/dto/StudioDtoConditionStatement";
import {StudioForm} from "../../api/meta/base/dto/StudioForm";
import {ValidationResult} from "../../api/meta/base/dto/ValidationResult";
import {ConditionDerivedFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {ConditionFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {TextMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {NumberMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {EntUserIdMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {DecimalMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {DateRangeMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {DateMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {MetaIdCondition} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {EnumDefnFields} from "../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../api/meta/base/Types";
import {MetaIdPlugin} from "../../api/meta/base/Types";
import {EnumDefnArgBinder} from "../../api/meta/base/Types";
import {EnumStudioCompType} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {EnumDefnConditionOperator, MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {IArgBinderValue} from "../../base/plus/ArgBinderPlus";
import {getFieldArgBinderValue} from "../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {loopDefnForm} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {getStudioFieldType} from "../../base/plus/StudioFormPlus";
import {getSystemFieldTypeSet} from "../../base/plus/StudioFormPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {getFormFieldsErrorList} from "../../base/plus/StudioPlus";
import theme from "../../base/plus/ThemePlus";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {EnumStudioSearchPathKeys} from "../../base/types/TypesStudio";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldLhs = "fieldLhs";
const fieldOperator = "operator";
const fieldRhs = "fieldRhs";

const contentWidth = theme.common.appMinTabletWidth;

export default function DialogNewCondition(props: {
  sourceFormId?: MetaIdForm,
  formStore: FormStore,
  isFormReadOnly?: boolean,
  sourceGridId?: MetaIdField,
  inputFormId?: MetaIdForm,
  sourcePluginId?: MetaIdPlugin,
  values?: StudioDtoConditionStatement,
  onClickOk: (values: StudioDtoConditionStatement) => void,
  onClose?: () => void,
  excludeVarIdSet?: MetaIdVar[],
  validationResult?: ValidationResult,
  fieldId?: MetaIdField,
  nodeId?: MetaIdCondition
})
{
  const values = props.values;
  const onClickSubmit = props.onClickOk;
  const sourceFormId = props.sourceFormId;
  const sourceGridId = props.sourceGridId;
  const inputFormId = props.inputFormId;
  const excludeVarIdSet = props.excludeVarIdSet;
  const sourcePluginId = props.sourcePluginId;
  const formStore = props.formStore;
  const fieldId = props.fieldId;
  const nodeId = props.nodeId;
  const validationResult = props.validationResult;

  const [isRhsDisabled, setIsRhsDisabled] = useState<boolean>(false);
  const [lhsValue, setLhsValue] = useState<IArgBinderValue>();
  const [rhsValue, setRhsValue] = useState<IArgBinderValue>();
  const [lhsSelectedFieldType, setLhsSelectedFieldType] = useState<EnumDefnCompType>();

  const formRef = useMemo(() => ({} as IFormRef), []);

  const errorList = useMemo(() =>
  {
    return getFormFieldsErrorList(fieldId as EnumStudioSearchPathKeys, nodeId, validationResult, 5);
  }, [fieldId, nodeId, validationResult]);

  useDialogFormValidationError({
    cbFormRef: formRef,
    validationError: errorList
  });

  const inputForm = useMemo(() => inputFormId
    ? formStore.formMap?.map?.[inputFormId]
    : undefined, [inputFormId, formStore.formMap]);

  const sourceForm = useMemo(() => sourceFormId
    ? formStore.formMap?.map?.[sourceFormId]
    : undefined, [sourceFormId, formStore.formMap]);

  const sourcePluginForm = useMemo(() => sourcePluginId && sourceFormId
    ? formStore.pluginMap?.map?.[sourcePluginId]?.pluginFormMap?.[sourceFormId]
    : undefined, [sourceFormId, sourcePluginId, formStore.pluginMap]);

  const rhsSystemFields = useMemo(() =>
  {
    const systemFields: EnumDefnFields[] = [];

    (EnumArrayDefnFields as EnumDefnFields[]).forEach(field =>
    {
      if(lhsSelectedFieldType
        && lhsSelectedFieldType.includes(getSystemFieldTypeSet(field)[0])
        && (lhsValue?.kind === "field" && lhsValue?.value !== field))
      {
        systemFields.push(field);
      }
    });

    return systemFields.length > 0
      ? systemFields
      : lhsValue?.value === undefined
        ? EnumArrayDefnFields as EnumDefnFields[]
        : undefined;

  }, [lhsSelectedFieldType, lhsValue]);

  const defnForm = useMemo(() => getDefnForm(
    isRhsDisabled,
    sourceFormId,
    sourceGridId,
    inputFormId,
    excludeVarIdSet,
    formStore,
    lhsValue,
    rhsValue,
    sourcePluginId,
    rhsSystemFields
  ), [
    rhsSystemFields,
    excludeVarIdSet,
    inputFormId,
    isRhsDisabled,
    lhsValue,
    formStore,
    rhsValue,
    sourceFormId,
    sourceGridId,
    sourcePluginId
  ]);

  const onWatch = useCallback((key: string, value: any) =>
  {
    if(key === fieldLhs)
    {
      if(value)
      {
        const lhsArgValue = getFieldArgBinderValue(value, inputForm);

        if(lhsArgValue.kind === "field" && lhsArgValue.value)
        {
          if(EnumArrayDefnFields.includes(lhsArgValue.value as EnumDefnFields))
          {
            setLhsSelectedFieldType(getSystemFieldTypeSet(lhsArgValue.value as EnumDefnFields)[0]);
          }
          else
          {
            setLhsSelectedFieldType(getStudioFieldType(lhsArgValue.value as MetaIdField, sourceForm, sourcePluginForm));
          }
        }
        else
        {
          setLhsSelectedFieldType(undefined);
        }

        setLhsValue(lhsArgValue);
      }
      else
      {
        setLhsSelectedFieldType(undefined);
      }
    }
    if(key === fieldRhs)
    {
      if(value)
      {
        const rhsArgValue = getFieldArgBinderValue(value, inputForm);
        setRhsValue(rhsArgValue);
      }
    }
    if(key === fieldOperator)
    {
      const _value = value as EnumDefnConditionOperator;
      if(_value === "hasValue" || _value === "hasNoValue")
      {
        setIsRhsDisabled(true);
      }
      else
      {
        setIsRhsDisabled(false);
      }
    }

  }, [inputForm, sourceForm, sourcePluginForm]);

  useEffect(() =>
  {
    if(values)
    {
      if(values.operator === "hasValue" || values.operator === "hasNoValue")
      {
        setIsRhsDisabled(true);
      }
      else
      {
        setIsRhsDisabled(false);
      }
    }
  }, []);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} condition`}
      formProps={{
        store: formStore,
        formReadonly: props.isFormReadOnly,
        defnForm: defnForm,
        cbRef: formRef,
        onSubmit: val => onClickSubmit(valueToDto(val, isRhsDisabled, values)),
        initValues: dtoToValue(values),
        onWatch: onWatch
      }}
      addMoreCheckBoxLabel={!values
        ? "Add more conditions"
        : undefined}
      onClose={props.onClose}
      contentWidth={contentWidth}
    />
  );
}

function valueToDto(
  val: FieldValues,
  isRhsDisabled: boolean,
  values?: StudioDtoConditionStatement): StudioDtoConditionStatement
{
  let lhs = val[fieldLhs];
  if(lhs && typeof lhs === "object" && Object.keys(lhs).length === 0)
  {
    lhs = values?.lhs;
  }

  let rhs = val[fieldRhs];
  if(rhs && typeof rhs === "object" && Object.keys(rhs).length === 0)
  {
    rhs = values?.rhs;
  }

  return {
    lhs: fnFieldValueToRawValueArgBinder(lhs),
    operator: val[fieldOperator],
    rhs: !isRhsDisabled ? fnFieldValueToRawValueArgBinder(rhs) : undefined
  } as StudioDtoConditionStatement;
}

function dtoToValue(dto?: StudioDtoConditionStatement): FieldValues
{
  if(!dto)
  {
    return {};
  }

  return {
    ...fnRawValueToFieldValueArgBinder(fieldLhs, dto.lhs),
    [fieldOperator]: dto.operator,
    ...fnRawValueToFieldValueArgBinder(fieldRhs, dto.rhs)
  } as FieldValues;
}

function getDefnForm(
  isRhsDisabled: boolean,
  formId?: MetaIdForm,
  gridId?: MetaIdField,
  inputFormId?: MetaIdForm,
  excludeVarIdSet?: MetaIdVar[],
  formStore?: FormStore,
  lhsValue?: IArgBinderValue,
  rhsValue?: IArgBinderValue,
  sourcePluginId?: MetaIdPlugin,
  rhsSystemFields?: EnumDefnFields[]
)
{
  const getRhsPropsBasedOnLhs = getPropsBasedOnArgValue(formStore, lhsValue, formId, rhsValue?.kind, sourcePluginId);

  return createDefaultDefnFormStudio({
    [fieldLhs]: {
      type: "studioBuildArgBinder",
      name: "lhs",
      label: "LHS",
      metaId: fieldLhs,
      formId: formId,
      derivedFormId: formId,
      gridId: gridId,
      inputFormId: inputFormId,
      required: true,
      filterKindSet: formId ? ["derived"] : ["derived", "field"],
      pluginId: sourcePluginId,
      derivedPluginId: sourcePluginId,
      includeOptionMap: arrayToMapOfOption(EnumArrayDefnFields, true),
      filterFieldTypeSet: ConditionFieldType,
      filterDerivedFieldTypeSet: ConditionDerivedFieldType
    } as DefnStudioBuildArgBinder,

    [fieldOperator]: {
      type: "enumConditionOperator",
      metaId: fieldOperator,
      name: fieldOperator,
      required: true
    } as DefnFieldEditable,

    [fieldRhs]: {
      type: "studioBuildArgBinder",
      name: "rhs",
      label: "RHS",
      metaId: fieldRhs,
      formId: formId,
      derivedFormId: formId,
      gridId: gridId,
      inputFormId: inputFormId,
      disabled: isRhsDisabled,
      filterKindSet: formId ? [] : ["derived", "field"],
      excludeVarIdSet: getRhsPropsBasedOnLhs.excludeVarIdSet && lhsValue?.value
        ? [...excludeVarIdSet || [], ...getRhsPropsBasedOnLhs.excludeVarIdSet]
        : excludeVarIdSet,
      excludeFieldIdSet: getRhsPropsBasedOnLhs.excludeFieldIdSet,
      pluginId: sourcePluginId,
      derivedPluginId: sourcePluginId,
      includeOptionMap: rhsSystemFields
        ? arrayToMapOfOption(rhsSystemFields, true)
        : undefined,
      filterFieldTypeSet: ConditionFieldType,
      filterDerivedFieldTypeSet: ConditionDerivedFieldType
    } as DefnStudioBuildArgBinder,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      name: defaultSectionKey,
      fieldIdSet: [fieldLhs, fieldOperator, fieldRhs]
    } as DefnSection

  } as Record<MetaIdField, DefnField>);
}

function getPropsBasedOnArgValue(
  formStore?: FormStore,
  oppositeArgValue?: IArgBinderValue,
  formId?: MetaIdForm,
  currentArgValueKind?: EnumDefnArgBinder,
  sourcePluginId?: MetaIdPlugin
)
{
  let excludeFieldIdSet: MetaIdField[] | undefined;
  let excludeVarIdSet: MetaIdVar[] | undefined;
  let studioForm: StudioForm | undefined;
  let pluginForm: DefnForm | undefined;

  const varMap = formStore?.varMap;

  if(formId)
  {
    if(sourcePluginId)
    {
      const plugin = formStore?.pluginMap?.map?.[sourcePluginId];
      if(plugin)
      {
        pluginForm = plugin.pluginFormMap[formId];
      }
    }
    else
    {
      studioForm = formStore?.formMap?.map[formId];
    }
  }

  switch(oppositeArgValue?.kind)
  {
    case "variable":
    {
      const variable = oppositeArgValue.value
        ? varMap?.map?.[oppositeArgValue.value as MetaIdVar]
        : undefined;

      excludeVarIdSet = (varMap?.keys || []).filter(varId => varMap?.map?.[varId].kind !== variable?.kind);
      variable?.metaId && excludeVarIdSet.push(variable.metaId);
    }
      break;
    case "field":
      if(studioForm)
      {
        let oppositeFieldType: EnumStudioCompType | undefined;

        // exclude opposite value field id
        loopStudioForm(studioForm, (_composite, field) =>
        {
          if(oppositeArgValue.value && field.metaId === oppositeArgValue.value)
          {
            oppositeFieldType = field.type;
            if(currentArgValueKind === "field")
            {
              if(excludeFieldIdSet)
              {
                excludeFieldIdSet.push(field.metaId);
              }
              else
              {
                excludeFieldIdSet = [field.metaId];
              }
            }
            return;
          }
        });

        if(!oppositeFieldType && EnumArrayDefnFields.includes(oppositeArgValue.value as EnumDefnFields))
        {
          oppositeFieldType = getSystemFieldTypeSet(oppositeArgValue.value as EnumDefnFields)[0];
        }

        // only allow same fieldValue type
        loopStudioForm(studioForm, (_composite, field) =>
        {
          const currentFieldType = field.type;

          if(oppositeFieldType && currentFieldType && !isFieldTypeAllowed(oppositeFieldType, currentFieldType))
          {
            if(excludeFieldIdSet)
            {
              excludeFieldIdSet.push(field.metaId);
            }
            else
            {
              excludeFieldIdSet = [field.metaId];
            }
          }
        });
      }
      else if(pluginForm)
      {
        let fieldType: EnumDefnCompType | undefined;

        loopDefnForm(pluginForm, (_composite, field) =>
        {
          if(oppositeArgValue.value && field.metaId === oppositeArgValue.value)
          {
            fieldType = field.type;
            if(currentArgValueKind === "field")
            {
              if(excludeFieldIdSet)
              {
                excludeFieldIdSet.push(field.metaId);
              }
              else
              {
                excludeFieldIdSet = [field.metaId];
              }
            }
            return;
          }
        });

        if(!fieldType && EnumArrayDefnFields.includes(oppositeArgValue.value as EnumDefnFields))
        {
          fieldType = getSystemFieldTypeSet(oppositeArgValue.value as EnumDefnFields)[0];
        }

        loopDefnForm(pluginForm, (_composite, field) =>
        {
          if(fieldType && field.type !== fieldType)
          {
            if(excludeFieldIdSet)
            {
              excludeFieldIdSet.push(field.metaId);
            }
            else
            {
              excludeFieldIdSet = [field.metaId];
            }
          }
        });
      }
      break;
  }

  return {
    excludeFieldIdSet,
    excludeVarIdSet
  };
}

function isFieldTypeAllowed(fieldType1: EnumDefnCompType, fieldType2: EnumDefnCompType): boolean
{
  if(fieldType1 === fieldType2)
  {
    return true;
  }

  return (DateMappingTypeSet.includes(fieldType1) && DateMappingTypeSet.includes(fieldType2))
    || (DateRangeMappingTypeSet.includes(fieldType1) && DateRangeMappingTypeSet.includes(fieldType2))
    || (DecimalMappingTypeSet.includes(fieldType1) && DecimalMappingTypeSet.includes(fieldType2))
    || (EntUserIdMappingTypeSet.includes(fieldType1) && EntUserIdMappingTypeSet.includes(fieldType2))
    || (NumberMappingTypeSet.includes(fieldType1) && NumberMappingTypeSet.includes(fieldType2))
    || (TextMappingTypeSet.includes(fieldType1) && TextMappingTypeSet.includes(fieldType2));
}
