import ISrvc from "../../../base/ISrvc";
import {store} from "../../../Store";
import {setFlagFirstBoot} from "./SliceStatus";
import {setFlagNetworkOn} from "./SliceStatus";
import {setBrowserTabFocus} from "./SliceStatus";
import {resetCurrentHour} from "./SliceStatus";

export default class SrvcStatus extends ISrvc
{
  private currentHour = new Date().getHours();

  doInit()
  {
    setTimeout(() => store.dispatch(resetCurrentHour(this.currentHour)));

    document.addEventListener("visibilitychange", () =>
    {
      store.dispatch(setBrowserTabFocus(document.visibilityState === "visible"));
    });
  }

  setFlagNetworkOn(networkOn: boolean)
  {
    store.dispatch(setFlagNetworkOn(networkOn));
  }

  setFlagFirstBoot(firstBoot: boolean)
  {
    store.dispatch(setFlagFirstBoot(firstBoot));
  }

  protected doTick(dateNow: Date)
  {
    const now = dateNow.getHours();
    if(this.currentHour !== now)
    {
      this.currentHour = now;

      // prevent too much dispatching, so we can use Redux inspector
      store.dispatch(resetCurrentHour(now));
    }

    super.doTick(dateNow);
  }
}
