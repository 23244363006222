import {DtoPaymentProvider} from "../../../api/ent/base/dto/DtoPaymentProvider";
import {DtoPaymentProviderRazorpay} from "../../../api/ent/base/dto/DtoPaymentProviderRazorpay";
import {MsgPaymentStatus} from "../../../api/ent/entAside/msg/MsgPaymentStatus";
import {MsgPaymentVerify} from "../../../api/ent/entAside/msg/MsgPaymentVerify";
import {RpcEntAside} from "../../../api/ent/entAside/RpcEntAside";
import {SigPaymentStatus} from "../../../api/ent/entAside/sig/SigPaymentStatus";
import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {DefnFieldCurrency} from "../../../api/meta/base/dto/DefnFieldCurrency";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnPaymentConfig} from "../../../api/meta/base/dto/DefnPaymentConfig";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {CurrencyKey} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {getMediaSrc} from "../../../base/plus/MediaPlus";
import theme from "../../../base/plus/ThemePlus";
import {Srvc} from "../../Srvc";
import {FIELD_PAYMENT_ID} from "./TypesPayment";
import {FIELD_INVOICE_ID} from "./TypesPayment";
import {FIELD_PAYMENT_STATUS} from "./TypesPayment";
import {FIELD_INVOICE_AMOUNT} from "./TypesPayment";
import {IPaymentParams} from "./TypesPayment";

export default class SrvcPayment extends ISrvc
{
  constructor()
  {
    super();
  }

  //region rpc

  rpcEntPaymentVerify(
    entId: EntId,
    invoiceId: string,
    paymentId: string,
    signature: string,
    cbSuccess?: () => void,
    cbError?: () => void)
  {
    const msg = {
      invoiceId: invoiceId,
      paymentId: paymentId,
      signature: signature
    } as MsgPaymentVerify;

    RpcEntAside.entPaymentVerify(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  rpcEntPaymentStatusGet(
    entId: EntId,
    invoiceId: string,
    cbSuccess?: (sig: SigPaymentStatus) => void,
    cbError?: () => void)
  {
    const msg = {
      invoiceId: invoiceId
    } as MsgPaymentStatus;

    RpcEntAside.entPaymentStatusGet(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        if(envSig.sig)
        {
          cbSuccess && cbSuccess(envSig.sig);
        }
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  //endregion

  //region public

  processPayment(
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    paymentProvider: DtoPaymentProvider,
    paymentConfig: DefnPaymentConfig,
    defnForm: DefnForm,
    formValueRaw: FormValueRaw,
    entAvatar?: SigEntAvatarUser,
    onSuccess?: () => void)
  {
    Srvc.app.spreadsheet.rpcSpreadsheetRowGet(entId, formValueRaw.rowId, spreadsheetId, undefined, (sig) =>
    {
      const updatedFormValueRaw = {
        ...formValueRaw,
        valueMap: sig.formValue?.valueMap,
        rowId: formValueRaw.rowId
      } as FormValueRaw;

      const paymentParams = this.getPaymentParams(entId,
        paymentProvider,
        paymentConfig,
        defnForm,
        updatedFormValueRaw,
        entAvatar);

      if(paymentParams)
      {
        this.checkoutPayment(
          paymentParams,
          paymentProvider,
          paymentParams.orderId,
          entId,
          spreadsheetId,
          updatedFormValueRaw,
          onSuccess
        );
      }
    });
  }

  retryPayment(
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    paymentProvider: DtoPaymentProvider,
    paymentConfig: DefnPaymentConfig,
    defnForm: DefnForm,
    formValueRaw: FormValueRaw,
    entAvatar?: SigEntAvatarUser,
    onSuccess?: () => void)
  {
    const paymentParams = this.getPaymentParams(entId,
      paymentProvider,
      paymentConfig,
      defnForm,
      formValueRaw,
      entAvatar);

    if(paymentParams)
    {
      this.checkoutPayment(
        paymentParams,
        paymentProvider,
        paymentParams.orderId,
        entId,
        spreadsheetId,
        formValueRaw,
        onSuccess
      );
    }
  }

  //endregion

  //region private

  private getPaymentParams(
    entId: EntId,
    paymentProvider: DtoPaymentProvider,
    paymentConfig: DefnPaymentConfig,
    defnForm: DefnForm,
    formValueRaw: FormValueRaw,
    entAvatar?: SigEntAvatarUser)
  {
    const razorpayPaymentProvider = paymentProvider.kind === "razorpay" ?
      (paymentProvider as DtoPaymentProviderRazorpay) : undefined;
    const apiKey = razorpayPaymentProvider?.apiKey;
    const defaultCurrency = razorpayPaymentProvider?.defaultCurrency;

    const currencyFieldId = paymentConfig?.currencyFieldId;
    const currencyField = currencyFieldId ? defnForm.compMap[currencyFieldId] as DefnFieldCurrency : undefined;
    const currency = currencyField ?
      fnFieldValueToRawValue(currencyField.type, formValueRaw.valueMap[currencyField.metaId]) as CurrencyKey :
      (defaultCurrency ?? "INR");

    const descriptionFieldId = paymentConfig?.descriptionFieldId;
    const descriptionField = descriptionFieldId ? defnForm.compMap[descriptionFieldId] as DefnFieldEditable :
      undefined;
    const description = descriptionField ?
      fnFieldValueToRawValue(descriptionField.type, formValueRaw.valueMap[descriptionField.metaId]) as string :
      undefined;

    const customerNameFieldId = paymentConfig?.customerNameFieldId;
    const customerNameField = customerNameFieldId ? defnForm.compMap[customerNameFieldId] as DefnFieldEditable :
      undefined;
    const customerName = customerNameField ?
      fnFieldValueToRawValue(customerNameField.type, formValueRaw.valueMap[customerNameField.metaId]) as string :
      undefined;

    const customerEmailFieldId = paymentConfig?.customerEmailFieldId;
    const customerEmailField = customerEmailFieldId ? defnForm.compMap[customerEmailFieldId] as DefnFieldEditable :
      undefined;
    const customerEmail = customerEmailField ?
      fnFieldValueToRawValue(customerEmailField.type, formValueRaw.valueMap[customerEmailField.metaId]) as string :
      undefined;

    const customerPhoneFieldId = paymentConfig?.customerContactFieldId;
    const customerPhoneField = customerPhoneFieldId ? defnForm.compMap[customerPhoneFieldId] as DefnFieldEditable :
      undefined;
    const customerPhone = customerPhoneField ?
      fnFieldValueToRawValue(customerPhoneField.type, formValueRaw.valueMap[customerPhoneField.metaId]) as string :
      undefined;

    const invoiceId = fnFieldValueToRawValue("text", formValueRaw.valueMap[FIELD_INVOICE_ID]) as string;
    const invoiceAmount = fnFieldValueToRawValue("number",
      formValueRaw.valueMap[FIELD_INVOICE_AMOUNT]) as number;

    const entName = entAvatar?.name ?? "";
    const entImage = getMediaSrc(entAvatar?.avatarId);

    const notes = {
      "spreadsheetRowId": formValueRaw.rowId,
      "entId": entId
    };

    if(apiKey)
    {
      return {
        apiKey: apiKey,
        orderId: invoiceId,
        amount: invoiceAmount,
        currency: currency,
        entName: entName,
        entImage: entImage,
        description: description,
        customerName: customerName,
        customerEmail: customerEmail,
        customerPhone: customerPhone,
        notes: notes,
        themeColor: theme.common.color("primaryDark")
      } as IPaymentParams;
    }
    else
    {
      return undefined;
    }
  }

  private checkoutPayment(
    paymentParams: IPaymentParams,
    paymentProvider: DtoPaymentProvider,
    invoiceId: string,
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    formValueRaw: FormValueRaw,
    onSuccess?: () => void)
  {
    const handleSuccess = (response: any) =>
    {
      const paymentId = response.razorpay_payment_id;
      const signature = response.razorpay_signature;

      this.rpcEntPaymentVerify(
        entId,
        invoiceId,
        paymentId,
        signature,
        () =>
        {
          formValueRaw.valueMap[FIELD_PAYMENT_STATUS] = fnRawValueToFieldValue("paymentStatus", "paid");
          formValueRaw.valueMap[FIELD_PAYMENT_ID] = fnRawValueToFieldValue("text", paymentId);

          Srvc.app.spreadsheet.rpcSpreadsheetRowUpdate(
            entId,
            spreadsheetId,
            formValueRaw,
            () =>
            {
              onSuccess && onSuccess();
            });
        });
    };

    switch(paymentProvider.kind)
    {
      case "razorpay":
        this.checkoutRazorpayPayment(paymentParams, handleSuccess);
    }
  }

  private checkoutRazorpayPayment(paymentParams: IPaymentParams, onSuccess?: (response: any) => void)
  {
    const options = {
      key: paymentParams.apiKey,
      amount: paymentParams.amount,
      currency: paymentParams.currency,
      order_id: paymentParams.orderId,
      name: paymentParams.entName,
      image: paymentParams.entImage,
      description: paymentParams.description,
      handler: onSuccess,
      prefill: {
        name: paymentParams.customerName,
        email: paymentParams.customerEmail,
        contact: paymentParams.customerPhone
      },
      notes: paymentParams.notes,
      theme: {
        color: paymentParams.themeColor
      }
    };

    const rzp = new (window as any).Razorpay(options);
    rzp.open();
  };

  //endregion
}
