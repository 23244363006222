import {FieldValues} from "react-hook-form";
import {DefnFieldPickTree} from "../../../../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickImportPluginApiId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginApiId";
import {DefnStudioPickImportPluginId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {FieldDtoTree} from "../../../../../api/meta/base/dto/FieldDtoTree";
import {StudioFieldPickTree} from "../../../../../api/meta/base/dto/StudioFieldPickTree";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {PluginApiId} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {IResolvedIOFormNames} from "../../../../../base/plus/StudioPlus";
import {IExcludeMappingVars} from "../../../../../base/plus/StudioPlus";
import {getFieldBuilderSourceSubTabs} from "../../base/FieldBuilderFactory";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultOptionFieldId} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyIncludeAllChildrenInReport} from "../../base/TypesFormBuilder";
import {propKeyForceLeafSelection} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeySourceVarId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyButtonPluginInputMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyPluginErrorField} from "../../base/TypesFormBuilder";
import {propKeyPluginApiId} from "../../base/TypesFormBuilder";
import {propKeyMetaIdPlugin} from "../../base/TypesFormBuilder";

export function getDefnFieldPickTree(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[],
  sourceVar?: FieldDtoTree,
  includeFieldIdSet?: MetaIdField[]
)
{

  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultOptionFieldId,
    propKeyHelperTextFieldId

  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarHelperText
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultValue,
    propKeyHelperTextValue,
    fieldGap2,
    propKeyForceLeafSelection,
    propKeyIncludeAllChildrenInReport
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "pickTree",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDefaultValue]: {
      type: "pickTree",
      name: propKeyDefaultValue,
      metaId: propKeyDefaultValue,
      sourceVar: sourceVar,
      showAs: "dropdown",
      label: "Default value"
    } as DefnFieldPickTree,

    [propKeyDefaultOptionFieldId]: {
      type: "pickFieldId",
      metaId: propKeyDefaultOptionFieldId,
      name: propKeyDefaultOptionFieldId,
      label: "Default value",
      formId: formId,
      filterFieldTypeSet: ["pickTree"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      includeFieldIdSet: includeFieldIdSet
    } as DefnStudioPickFieldId,

    [propKeyForceLeafSelection]: {
      type: "bool",
      metaId: propKeyForceLeafSelection,
      name: propKeyForceLeafSelection,
      label: "Force leaf selection",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyIncludeAllChildrenInReport]: {
      type: "bool",
      metaId: propKeyForceLeafSelection,
      name: propKeyForceLeafSelection,
      label: "Include all children in report",
      showAsCheckboxVar: true
    } as DefnFieldSwitch
  };
}

export function getDefnFieldTreeSourceTab(
  formId: MetaIdForm,
  metaIdPlugin?: MetaIdPlugin,
  isPluginInputFormPresent?: boolean,
  helperTextPluginApiIOFormName?: IResolvedIOFormNames,
  isPluginApiHelperTextVisible?: boolean,
  excludePluginApiIdSet?: PluginApiId[],
  notValidPluginInputFormMappingVars?: IExcludeMappingVars,
  isPluginForm?: boolean
)
{

  const variableFieldIdSet = [
    propKeySourceVarId
  ] as MetaIdField[];

  const pluginFieldIdSet: MetaIdField[] = !isPluginForm
    ? [
      propKeyMetaIdPlugin,
      propKeyPluginApiId,
      ...isPluginInputFormPresent ? [propKeyButtonPluginInputMappingVarId] : [],
      propKeyPluginErrorField
    ]
    : [];

  return {
    [propKeySourceVarId]: {
      type: "pickVarId",
      name: propKeySourceVarId,
      metaId: propKeySourceVarId,
      varKind: "tree",
      showAsEdit: true,
      formId: formId,
      label: "Default source variable"
    } as DefnStudioPickVarId,

    ...!isPluginForm && {

      [propKeyMetaIdPlugin]: {
        type: "pickImportPluginId",
        metaId: propKeyMetaIdPlugin,
        name: propKeyMetaIdPlugin,
        label: "Plugin"
      } as DefnStudioPickImportPluginId,

      [propKeyPluginApiId]: {
        type: "pickImportPluginApiId",
        name: propKeyPluginApiId,
        metaId: propKeyPluginApiId,
        label: "Plugin API",
        metaIdPlugin: metaIdPlugin,
        excludePluginApiIdSet: excludePluginApiIdSet,
        helperTextVar: isPluginApiHelperTextVisible
          ? stringToDefnDtoText(`Input form: ${helperTextPluginApiIOFormName?.inputFormName
          ?? "None"}, Output form: ${helperTextPluginApiIOFormName?.outputFormName ?? "None"}`)
          : undefined
      } as DefnStudioPickImportPluginApiId,

      ...isPluginInputFormPresent && {
        [propKeyButtonPluginInputMappingVarId]: {
          type: "pickVarId",
          metaId: propKeyButtonPluginInputMappingVarId,
          name: propKeyButtonPluginInputMappingVarId,
          label: "Plugin input mapping variable",
          disabled: !notValidPluginInputFormMappingVars?.src || !notValidPluginInputFormMappingVars?.target,
          excludeVarIdSet: notValidPluginInputFormMappingVars?.excludeIdSet,
          varKind: "mapping",
          showAsEdit: true
        } as DefnStudioPickVarId
      },

      [propKeyPluginErrorField]: {
        type: "pickFieldId",
        name: propKeyPluginErrorField,
        metaId: propKeyPluginErrorField,
        label: "Plugin error field",
        filterFieldTypeSet: ["error"],
        showCompositeName: true,
        formId: formId
      } as DefnStudioPickFieldId

    },
    ...getFieldBuilderSourceSubTabs(undefined, variableFieldIdSet, pluginFieldIdSet)
  };
}

export function defnValueToStudioFieldPickTree(values: FieldValues): StudioFieldPickTree
{
  return {
    type: "pickTree",
    defaultOptionId: fnFieldValueToRawValue("pickTree", values[propKeyDefaultValue]),
    defaultOptionFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultOptionFieldId]),
    forceLeafSelection: fnFieldValueToRawValue("bool", values[propKeyForceLeafSelection]),
    includeAllChildrenInReport: fnFieldValueToRawValue("bool", values[propKeyIncludeAllChildrenInReport]),
    sourceVarId: fnFieldValueToRawValue("pickVarId", values[propKeySourceVarId]),
    pluginApi: (values[propKeyMetaIdPlugin])
      ? {
        metaIdPlugin: fnFieldValueToRawValue("pickImportPluginId", values[propKeyMetaIdPlugin]),
        pluginApiId: fnFieldValueToRawValue("pickImportPluginApiId", values[propKeyPluginApiId])
      }
      : undefined,
    pluginInputMappingVarId: fnFieldValueToRawValue("pickVarId", values[propKeyButtonPluginInputMappingVarId]),
    pluginErrorFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyPluginErrorField])
  } as StudioFieldPickTree;
}

export function studioFieldPickTreeToDefnValue(studioField: StudioFieldPickTree)
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("pickTree", studioField.defaultOptionId),
    [propKeyDefaultOptionFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultOptionFieldId),
    [propKeyForceLeafSelection]: fnRawValueToFieldValue("bool", studioField.forceLeafSelection),
    [propKeyIncludeAllChildrenInReport]: fnRawValueToFieldValue("bool", studioField.includeAllChildrenInReport),
    [propKeySourceVarId]: fnRawValueToFieldValue("pickVarId", studioField.sourceVarId),
    [propKeyMetaIdPlugin]: fnRawValueToFieldValue("pickImportPluginId", studioField.pluginApi?.metaIdPlugin),
    [propKeyPluginApiId]: fnRawValueToFieldValue("pickImportPluginApiId", studioField.pluginApi?.pluginApiId),
    [propKeyButtonPluginInputMappingVarId]: fnRawValueToFieldValue("pickVarId", studioField.pluginInputMappingVarId),
    [propKeyPluginErrorField]: fnRawValueToFieldValue("pickFieldId", studioField.pluginErrorFieldId)
  };
}

