import {CircleRounded} from "@mui/icons-material";
import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";

export default function RawDot(props: {
  color?: TypeTextColor,
  fontSize?: number,
  pl?: number,
  pr?: number,
  ml?: number,
  mr?: number
})
{
  const theme = useTheme();
  const {
    color,
    ml,
    mr
  } = props;
  const gap = theme.common.sizeDot;
  return (
    <CircleRounded
      sx={{
        color: theme.common.color(color ?? "primary"),
        fontSize: px(gap),
        pl: props.pl,
        pr: props.pr,
        ml: px(ml),
        mr: px(mr)
      }}
    />
  );
}
