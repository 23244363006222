import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {EnumDefnDate} from "../../api/meta/base/Types";
import {MetaIdPlugin} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {EnumDefnDeploy} from "../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../api/meta/base/Types";
import {EnumDefnUserSettingOptions} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {fieldGap1} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import {getStudioDetailsCompMap} from "../form/builder/base/TypesFormBuilder";
import {fieldTabProperties} from "../form/builder/base/TypesFormBuilder";
import {TypeFromToType} from "./base/VariablePlus";
import {fieldVariableValue} from "./base/VariablePlus";
import {compVariableDeployType} from "./base/VariablePlus";
import {fnVariableBuilderBoolean} from "./builder/VariableBuilderBoolean";
import {fnVariableBuilderButtonVariant} from "./builder/VariableBuilderButtonVariant";
import {fnVariableBuilderColor} from "./builder/VariableBuilderColor";
import {fnVariableBuilderCondition} from "./builder/VariableBuilderCondition";
import {fnVariableBuilderCurrency} from "./builder/VariableBuilderCurrency";
import {fnVariableBuilderDate} from "./builder/VariableBuilderDate";
import {fnVariableBuilderDateTime} from "./builder/VariableBuilderDateTime";
import {fnVariableBuilderDay} from "./builder/VariableBuilderDay";
import {fnVariableBuilderSetOfDay} from "./builder/VariableBuilderDaySet";
import {fnVariableBuilderDecimal} from "./builder/VariableBuilderDecimal";
import {fnVariableBuilderDeviceSize} from "./builder/VariableBuilderDeviceSize";
import {fnVariableBuilderDeviceType} from "./builder/VariableBuilderDeviceType";
import {fnVariableBuilderDirection} from "./builder/VariableBuilderDirection";
import {fnVariableBuilderDividerType} from "./builder/VariableBuilderDividerType";
import {fnVariableBuilderDocument} from "./builder/VariableBuilderDocument";
import {fnVariableBuilderDuration} from "./builder/VariableBuilderDuration";
import {fnVariableBuilderEmail} from "./builder/VariableBuilderEmail";
import {fnVariableBuilderFont} from "./builder/VariableBuilderFont";
import {fnVariableBuilderFunction} from "./builder/VariableBuilderFunction";
import {fnVariableBuilderHtml} from "./builder/VariableBuilderHtml";
import {fnVariableBuilderHyperlink} from "./builder/VariableBuilderHyperlink";
import {fnVariableBuilderIcon} from "./builder/VariableBuilderIcon";
import {fnVariableBuilderImage} from "./builder/VariableBuilderImage";
import {fnVariableBuilderImageCorner} from "./builder/VariableBuilderImageCorner";
import {fnVariableBuilderLanguage} from "./builder/VariableBuilderLanguage";
import {fnVariableBuilderLocation} from "./builder/VariableBuilderLocation";
import {fnVariableBuilderMapOfText} from "./builder/VariableBuilderMapOfText";
import {fnVariableBuilderMapPinShape} from "./builder/VariableBuilderMapPinShape";
import {fnVariableBuilderMobileNumber} from "./builder/VariableBuilderMobileNumber";
import {fnVariableBuilderMonth} from "./builder/VariableBuilderMonth";
import {fnVariableBuilderNumber} from "./builder/VariableBuilderNumber";
import {fnVariableBuilderSetOfNumber} from "./builder/VariableBuilderNumberSet";
import {fnVariableBuilderParagraph} from "./builder/VariableBuilderParagraph";
import {fnVariableBuilderPlacement} from "./builder/VariableBuilderPlacement";
import {fnVariableBuilderQuarter} from "./builder/VariableBuilderQuarter";
import {fnVariableBuilderRatingKind} from "./builder/VariableBuilderRatingKind";
import {fnVariableBuilderSequence} from "./builder/VariableBuilderSequence";
import {fnVariableBuilderSetOfDate} from "./builder/VariableBuilderSetOfDate";
import {fnVariableBuilderSetOfTime} from "./builder/VariableBuilderSetOfTime";
import {fnVariableBuilderSetOfUsers} from "./builder/VariableBuilderSetOfUsers";
import {fnVariableBuilderStroke} from "./builder/VariableBuilderStroke";
import {fnVariableBuilderSymbol} from "./builder/VariableBuilderSymbol";
import {fnVariableBuilderText} from "./builder/VariableBuilderText";
import {fnVariableBuilderSetOfText} from "./builder/VariableBuilderTextSet";
import {fnVariableBuilderTime} from "./builder/VariableBuilderTime";
import {fnVariableBuilderTimeZone} from "./builder/VariableBuilderTimeZone";
import {fnVariableBuilderTree} from "./builder/VariableBuilderTree";
import {fnVariableBuilderUserSetting} from "./builder/VariableBuilderUserSetting";

const compDetails = "details";
export const fieldMappingFromToTab = "sectionMappingFromTo";
export const compMappingMappingTab = "compMappingMappingTab";

export function getVariableDefnForm(
  variableType: EnumStudioVarKind,
  deployType: EnumDefnDeploy,
  variablePropertyTab: Record<MetaIdVar, DefnField>,
  isPluginVar?: boolean,
  labelHelperTextName?: string,
  showLabelHelperTextName?: boolean)
{
  return createDefaultDefnFormStudio({
    ...getVariableDetailsTab(isPluginVar, labelHelperTextName, showLabelHelperTextName),
    ...variablePropertyTab,

    ...variableType !== "mapping" && {
      [fieldTabProperties]: {
        type: "section",
        metaId: fieldTabProperties,
        fieldIdSet: [
          ...deployType === "fixedOnDeploy"
            ? Object.keys(variablePropertyTab)
            : []
        ]
      } as DefnSection

    },
    ...getVariablePropertiesTab(deployType, variableType)
  } as Record<MetaIdField, DefnField>);
}

function getVariableDetailsTab(
  isPluginVar?: boolean,
  labelHelperTextName?: string,
  showLabelHelperTextName?: boolean)
{
  const fieldDefaultProperties = getStudioDetailsCompMap(labelHelperTextName, showLabelHelperTextName, true);

  return {
    ...fieldDefaultProperties,

    ...!isPluginVar && {

      ...getFieldGap(fieldGap1, "thick"),

      [compVariableDeployType]: {
        type: "enumDeployVar",
        name: "Deploy",
        required: true,
        metaId: compVariableDeployType
      } as DefnFieldPickEnum
    },

    [compDetails]: {
      type: "section",
      metaId: compDetails,
      fieldIdSet: [
        ...Object.keys(fieldDefaultProperties),
        ...!isPluginVar
          ? [fieldGap1, compVariableDeployType]
          : []
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>;
}

function getVariablePropertiesTab(deployType: EnumDefnDeploy, variableType: EnumStudioVarKind)
{
  const tabIdSet = [compDetails];

  if(deployType === "fixedOnDeploy")
  {
    if(variableType === "mapping")
    {
      tabIdSet.push(fieldMappingFromToTab, compMappingMappingTab);
    }
    else
    {
      tabIdSet.push(fieldTabProperties);
    }
  }

  return {
    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: deployType === "requiredOnDeploy" ? "standard" : "fullWidth",
      tabIdSet: tabIdSet
    } as DefnTab
  };
}

export function getCompDefaultVariableValue(
  variableType: EnumStudioVarKind,
  variableFunctionNameAndParams: string,
  variableDateType: EnumDefnDate,
  variableDateTimeType: EnumDefnDate,
  variableUserType: EnumDefnUserSettingOptions,
  variableConditionSourceType: TypeFromToType,
  variableConditionTreeSourceForm: MetaIdForm,
  variableConditionTreeSourceGrid: MetaIdField,
  variableConditionTreeInputForm: MetaIdForm,
  selectedConditionVarId?: MetaIdVar,
  showHyperlinkValueOnly?: boolean,
  variableMapOfTextFormId?: MetaIdForm,
  variableConditionSourcePluginId?: MetaIdPlugin,
  filterOptionSet?: string[],
  dateTimeFormat?: string,
  variableTimeType?: string,
  setOfUsersFormId?: MetaIdForm
): Record<MetaIdVar, DefnField>
{
  const valueKey = fieldVariableValue;//getCombinedString([fieldVariableValue, variableType]);

  switch(variableType)
  {
    case "mapping":
    {
      // mapping is handled in useVariableValueInput separately
      return {};
    }
    case "setOfTime":
    {
      const {getVarComp} = fnVariableBuilderSetOfTime(valueKey);
      return getVarComp();
    }
    case "bool":
    {
      const {getVarComp} = fnVariableBuilderBoolean(valueKey);
      return getVarComp();
    }
    case "color":
    {
      const {getVarComp} = fnVariableBuilderColor(valueKey);
      return getVarComp();
    }
    case "condition":
    {
      const {getVarComp} = fnVariableBuilderCondition(valueKey);
      return getVarComp(
        variableConditionSourceType,
        variableConditionTreeSourceForm,
        variableConditionTreeSourceGrid,
        selectedConditionVarId,
        variableConditionTreeInputForm,
        variableConditionSourcePluginId
      );
    }
    case "decimal":
    {
      const {getVarComp} = fnVariableBuilderDecimal(valueKey);
      return getVarComp();
    }
    case "document":
    {
      const {getVarComp} = fnVariableBuilderDocument(valueKey);
      return getVarComp();
    }
    case "email":
    {
      const {getVarComp} = fnVariableBuilderEmail(valueKey);
      return getVarComp();
    }
    case "hyperlink":
    {
      const {getVarComp} = fnVariableBuilderHyperlink(valueKey, showHyperlinkValueOnly);
      return getVarComp();
    }
    case "image":
    {
      const {getVarComp} = fnVariableBuilderImage(valueKey);
      return getVarComp();
    }
    case "userSetting":
    {
      const {getVarComp} = fnVariableBuilderUserSetting(valueKey);
      return getVarComp(variableUserType);
    }
    case "time":
    {
      const {getVarComp} = fnVariableBuilderTime(valueKey);
      return getVarComp(variableTimeType);
    }
    case "date":
    {
      const {getVarComp} = fnVariableBuilderDate(valueKey, dateTimeFormat);
      return getVarComp(variableDateType);
    }
    case "duration":
    {
      const {getVarComp} = fnVariableBuilderDuration(valueKey);
      return getVarComp();
    }
    case "setOfDay":
    {
      const {getVarComp} = fnVariableBuilderSetOfDay(valueKey);
      return getVarComp();
    }
    case "timeZone":
    {
      const {getVarComp} = fnVariableBuilderTimeZone(valueKey);
      return getVarComp();
    }
    case "dateTime":
    {
      const {getVarComp} = fnVariableBuilderDateTime(valueKey, dateTimeFormat);
      return getVarComp(variableDateTimeType);
    }
    case "location":
    {
      const {getVarComp} = fnVariableBuilderLocation(valueKey);
      return getVarComp();
    }
    case "sequence":
    {
      const {getVarComp} = fnVariableBuilderSequence(valueKey);
      return getVarComp();
    }
    case "day":
    {
      const {getVarComp} = fnVariableBuilderDay(valueKey);
      return getVarComp();
    }
    case "deviceSize":
    {
      const {getVarComp} = fnVariableBuilderDeviceSize(valueKey);
      return getVarComp();
    }
    case "deviceType":
    {
      const {getVarComp} = fnVariableBuilderDeviceType(valueKey);
      return getVarComp();
    }
    case "textSize":
    {
      const {getVarComp} = fnVariableBuilderFont(valueKey);
      return getVarComp();
    }
    case "stroke":
    {
      const {getVarComp} = fnVariableBuilderStroke(valueKey);
      return getVarComp();
    }
    case "icon":
    {
      const {getVarComp} = fnVariableBuilderIcon(valueKey);
      return getVarComp();
    }
    case "buttonVariant":
    {
      const {getVarComp} = fnVariableBuilderButtonVariant(valueKey);
      return getVarComp();
    }
    case "direction":
    {
      const {getVarComp} = fnVariableBuilderDirection(valueKey);
      return getVarComp();
    }
    case "imageCorner":
    {
      const {getVarComp} = fnVariableBuilderImageCorner(valueKey);
      return getVarComp();
    }
    case "mapPinShape":
    {
      const {getVarComp} = fnVariableBuilderMapPinShape(valueKey);
      return getVarComp();
    }
    case "month":
    {
      const {getVarComp} = fnVariableBuilderMonth(valueKey);
      return getVarComp();
    }
    case "quarter":
    {
      const {getVarComp} = fnVariableBuilderQuarter(valueKey);
      return getVarComp();
    }
    case "placement":
    {
      const {getVarComp} = fnVariableBuilderPlacement(valueKey);
      return getVarComp(filterOptionSet);
    }
    case "setOfText":
    {
      const {getVarComp} = fnVariableBuilderSetOfText(valueKey);
      return getVarComp();
    }
    case "mapOfText":
    {
      const {getVarComp} = fnVariableBuilderMapOfText(valueKey);
      return getVarComp(variableMapOfTextFormId);
    }
    case "mobileNumber":
    {
      const {getVarComp} = fnVariableBuilderMobileNumber(valueKey);
      return getVarComp();
    }
    case "number":
    {
      const {getVarComp} = fnVariableBuilderNumber(valueKey);
      return getVarComp();
    }
    case "setOfNumber":
    {
      const {getVarComp} = fnVariableBuilderSetOfNumber(valueKey);
      return getVarComp();
    }
    case "dividerKind":
    {
      const {getVarComp} = fnVariableBuilderDividerType(valueKey);
      return getVarComp();
    }
    case "symbol":
    {
      const {getVarComp} = fnVariableBuilderSymbol(valueKey);
      return getVarComp();
    }
    case "language":
    {
      const {getVarComp} = fnVariableBuilderLanguage(valueKey);
      return getVarComp();
    }
    case "currency":
    {
      const {getVarComp} = fnVariableBuilderCurrency(valueKey);
      return getVarComp();
    }
    case "tree":
    {
      const {getVarComp} = fnVariableBuilderTree(valueKey);
      return getVarComp();
    }
    case "setOfDate":
    {
      const {getVarComp} = fnVariableBuilderSetOfDate(valueKey);
      return getVarComp();
    }
    case "html":
    {
      const {getVarComp} = fnVariableBuilderHtml(valueKey);
      return getVarComp();
    }
    case "paragraph":
    {
      const {getVarComp} = fnVariableBuilderParagraph(valueKey);
      return getVarComp();
    }
    case "text":
    {
      const {getVarComp} = fnVariableBuilderText(valueKey);
      return getVarComp();
    }
    case "function":
    {
      const {getVarComp} = fnVariableBuilderFunction(valueKey);
      return getVarComp(variableFunctionNameAndParams);
    }
    case "ratingKind":
    {
      const {getVarComp} = fnVariableBuilderRatingKind(valueKey);
      return getVarComp();
    }
    case "setOfUser":
    {
      const {getVarComp} = fnVariableBuilderSetOfUsers(valueKey);
      return getVarComp(setOfUsersFormId);
    }
    default:
      return {};
  }

}
