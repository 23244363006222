import {isEmpty} from "lodash";
import {camelCase} from "lodash";
import {useState} from "react";
import {useRef} from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdField} from "../../api/meta/base/ApiPlus";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickReportId} from "../../api/meta/base/dto/DefnStudioPickReportId";
import {DefnStudioPickSpreadsheetId} from "../../api/meta/base/dto/DefnStudioPickSpreadsheetId";
import {StudioEntReport} from "../../api/meta/base/dto/StudioEntReport";
import {StudioPluginValidCompType} from "../../api/meta/base/StudioSetsFieldType";
import {StudioCompProType} from "../../api/meta/base/StudioSetsFieldType";
import {StudioCompEnum} from "../../api/meta/base/StudioSetsFieldType";
import {StudioCompAdvanceType} from "../../api/meta/base/StudioSetsFieldType";
import {StudioCompChipSetType} from "../../api/meta/base/StudioSetsFieldType";
import {StudioCompPickerType} from "../../api/meta/base/StudioSetsFieldType";
import {StudioCompBasicType} from "../../api/meta/base/StudioSetsFieldType";
import {LoggedFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {MultiSelectFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdReport} from "../../api/meta/base/Types";
import {Symbol} from "../../api/meta/base/Types";
import {EnumStudioCompType, MetaIdField, MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {toLabel} from "../../base/plus/StringPlus";
import {toSymbolCase} from "../../base/plus/StringPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import theme from "../../base/plus/ThemePlus";
import {IListItem} from "../../base/types/list/TypesList";
import {TypeListItemId} from "../../base/types/list/TypesList";
import {IListItemAPSA} from "../../base/types/list/TypesListAPSA";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {ListClickVariant} from "../list/List";
import {convertEnumArrToDialogListArr} from "./base/DialogPlus";
import {TypeListMap} from "./base/impl/DialogList";
import DialogList from "./base/impl/DialogList";

interface IDialogNewField
{
  fieldType: EnumStudioCompType;
  name: Symbol;
  metaIdField: MetaIdField;
  refFieldSpreadsheet?: MetaIdSpreadsheet;
  refFieldReport?: MetaIdReport;
}

const fieldArrayBasicsAPSA = getKindArr(StudioCompBasicType);
const fieldArrayAdvancedAPSA = getKindArr(StudioCompAdvanceType);
const fieldArrayPickerAPSA = getKindArr(StudioCompPickerType);
const fieldArrayChipsetAPSA = getKindArr(StudioCompChipSetType);
const fieldArrayEnumAPSA = getKindArr(StudioCompEnum);
const fieldArrayProAPSA = getKindArr(StudioCompProType);

export default function DialogNewField(props: {
  formStore?: FormStore;
  onClickOk: (values: IDialogNewField) => void;
  onClose?: () => void;
  isPluginForm?: boolean;
  formId?: MetaIdForm;
})
{
  const onClickOK = props.onClickOk;
  const formId = props.formId;
  const formStore = props.formStore;
  const isPluginForm = props.isPluginForm;

  const cbRef = useRef({} as IFormRef);

  const [searchText, setSearchText] = useState("");
  const [excludeReportIdSet, setExcludeReportIdSet] = useState<MetaIdReport[]>([]);
  const [selectedField, setSelectedField] = useState<EnumStudioCompType>();

  const reportMap = formStore?.reportMap;

  const onSubmit = useCallback(
    (values: FieldValues) =>
    {
      if(selectedField)
      {
        const dto = valueToDto(values, selectedField);
        if(dto)
        {
          onClickOK(dto);
        }
      }
    },
    [selectedField, onClickOK]
  );

  const onWatch = useCallback(
    (key: MetaIdField, value: any) =>
    {
      if(key === fieldMultiSelect)
      {
        handleMultiSelectCase(value);
      }
      else if(key === fieldLogged)
      {
        handleLoggedCase(value);
      }
    },
    [selectedField, fieldMultiSelect, fieldLogged, fnFieldValueToRawValue, cbRef, fieldKeyName]
  );

  const handleMultiSelectCase = (value: any) =>
  {
    const isValueSelected = Boolean(fnFieldValueToRawValue("bool", value));
    let fieldValue;

    switch(selectedField)
    {
      case "pickText":
        fieldValue = isValueSelected ? "SetOfText" : "PickText";
        break;
      case "pickUser":
        fieldValue = isValueSelected ? "SetOfUser" : "PickUser";
        break;
      case "pickRole":
        fieldValue = isValueSelected ? "SetOfRole" : "PickUser";
        break;
      default:
        return;
    }

    cbRef.current.setValue(fieldKeyName, fieldValue);
  };

  const handleLoggedCase = (value: any) =>
  {
    const isValueSelected = Boolean(fnFieldValueToRawValue("bool", value));
    let fieldValue;

    switch(selectedField)
    {
      case "number":
        fieldValue = isValueSelected ? "LogNumber" : "Number";
        break;
      case "decimal":
        fieldValue = isValueSelected ? "LogDecimal" : "Decimal";
        break;
      case "counter":
        fieldValue = isValueSelected ? "LogCounter" : "Counter";
        break;
      default:
        return;
    }

    cbRef.current.setValue(fieldKeyName, fieldValue);
  };

  const {
    filteredTabs,
    filteredBasics,
    filteredPickers,
    filteredChipSets,
    filteredAdvances,
    filteredEnums,
    filteredPros
  } = getListOptions(searchText, isPluginForm);

  const listMap = {
    keys: filteredTabs,
    map: {
      "Basic": fieldArrayBasicsAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredBasics.includes(searchElement);
      }),
      "Picker": fieldArrayPickerAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredPickers.includes(searchElement);
      }),
      "Chipset": fieldArrayChipsetAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredChipSets.includes(searchElement);
      }),
      "Advanced": fieldArrayAdvancedAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredAdvances.includes(searchElement);
      }),
      "Enum": fieldArrayEnumAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredEnums.includes(searchElement);
      }),
      "Pro": fieldArrayProAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredPros.includes(searchElement);
      })
    }
  } as TypeListMap;

  const onClickList2 = (
    _menuAnchor: Element,
    _itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant
  ) =>
  {
    switch(variant)
    {
      case "listItem":
      {
        const fieldName = (item as IListItemAPSA)?.primary?.text;
        setSelectedField(camelCase(fieldName) as EnumStudioCompType);
        cbRef.current.setValue(fieldKeyName, fieldName ? toSymbolCase(fieldName) : undefined);
        cbRef.current.setFieldDisable(fieldKeyName, isEmpty(fieldName));
        cbRef.current.setFieldReadonly(fieldKeyName, isEmpty(fieldName));
      }
        break;
    }
  };

  useEffect(() =>
  {
    const _excludeReportIdSet = [] as MetaIdReport[];

    if(reportMap && reportMap.keys.length)
    {
      reportMap.keys.forEach(reportId =>
      {
        const report = reportMap.map[reportId] as StudioEntReport;
        const inputFormId = report.inputFormId;
        if(inputFormId && inputFormId !== formId)
        {
          _excludeReportIdSet.push(reportId);
        }
      });
    }

    setExcludeReportIdSet(_excludeReportIdSet);
  }, [formId, reportMap]);

  const [defnForm, setDefnForm] = useState(getDefnForm(selectedField, excludeReportIdSet));

  useEffect(() =>
  {
    if(selectedField)
    {
      setDefnForm(getDefnForm(selectedField, excludeReportIdSet));
    }
  }, [selectedField, excludeReportIdSet]);

  return (
    <DialogList
      formProps={{
        cbRef: cbRef.current,
        store: formStore,
        defnForm: defnForm,
        onSubmit: onSubmit,
        onWatch: onWatch
      }}
      listMap={listMap}
      title={"New field"}
      onSearch={setSearchText}
      onClickList2={onClickList2}
      leftFooterCheckBoxActions={[
        {
          label: "Add more fields",
          value: "addMore"
        }
      ]}
    />
  );
}

const fieldTabBasic = "Basic";
const fieldTabAdvanced = "Advanced";
const fieldTabPicker = "Picker";
const fieldTabChipSet = "Chipset";
const fieldTabEnum = "Enum";
const fieldTabPro = "Pro";

const fieldRefFieldSpreadsheet = "refFieldSpreadsheet";
const fieldRefReportFieldReport = "refFieldReport";
const fieldMultiSelect = "multiSelect";
const fieldLogged = "logged";

function getValidStudioCompTypes(isPluginForm?: boolean)
{
  let basics = StudioCompBasicType;
  let advances = StudioCompAdvanceType;
  let pickers = StudioCompPickerType;
  let chipsets = StudioCompChipSetType;
  let enums = StudioCompEnum;
  let pros = StudioCompProType;

  if(isPluginForm)
  {
    basics = basics.filter(type => StudioPluginValidCompType.includes(type));
    advances = advances.filter(type => StudioPluginValidCompType.includes(type));
    pickers = pickers.filter(type => StudioPluginValidCompType.includes(type));
    chipsets = chipsets.filter(type => StudioPluginValidCompType.includes(type));
    enums = enums.filter(type => StudioPluginValidCompType.includes(type));
    pros = pros.filter(type => StudioPluginValidCompType.includes(type));
  }

  return {
    basics,
    advances,
    pickers,
    chipsets,
    enums,
    pros
  };
}

function getListOptions(searchText: string, isPluginForm?: boolean)
{
  let filteredTabs = [
    fieldTabBasic,
    fieldTabAdvanced,
    fieldTabPicker,
    fieldTabChipSet,
    fieldTabEnum,
    fieldTabPro
  ];

  const filteredBasics = getValidStudioCompTypes(isPluginForm)
  .basics
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredAdvances = getValidStudioCompTypes(isPluginForm)
  .advances
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredPickers = getValidStudioCompTypes(isPluginForm)
  .pickers
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredChipSets = getValidStudioCompTypes(isPluginForm)
  .chipsets
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredEnums = getValidStudioCompTypes(isPluginForm)
  .enums
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredPros = getValidStudioCompTypes(isPluginForm)
  .pros
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  if(!filteredBasics.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabBasic);
  }

  if(!filteredPickers.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabPicker);
  }

  if(!filteredChipSets.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabChipSet);
  }

  if(!filteredEnums.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabEnum);
  }

  if(!filteredAdvances.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabAdvanced);
  }

  if(!filteredPros.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabPro);
  }

  return {
    filteredTabs,
    filteredBasics,
    filteredPickers,
    filteredChipSets,
    filteredAdvances,
    filteredEnums,
    filteredPros
  };
}

function getDefnForm(
  currentFieldType?: EnumStudioCompType,
  excludeReportIdSet?: MetaIdReport[]
)
{
  const showReport = currentFieldType === "pickReportRow" || currentFieldType === "refReport";
  const disableFieldTitle = Boolean(currentFieldType);

  return createDefaultDefnFormStudio({
    [fieldKeyName]: {
      type: "symbol",
      metaId: fieldKeyName,
      name: fieldKeyName,
      label: "Name",
      disabled: !disableFieldTitle,
      required: true,
      maxWidth: 1050
    } as DefnFieldText,

    ...currentFieldType === "ref" && {
      [fieldRefFieldSpreadsheet]: {
        type: "pickSpreadsheetId",
        metaId: fieldRefFieldSpreadsheet,
        name: fieldRefFieldSpreadsheet,
        required: true,
        label: "Ref field spreadsheet"
      } as DefnStudioPickSpreadsheetId
    },

    ...(showReport && {
      [fieldRefReportFieldReport]: {
        type: "pickReportId",
        metaId: fieldRefReportFieldReport,
        name: fieldRefReportFieldReport,
        required: true,
        label: "Report",
        excludeReportIdSet: excludeReportIdSet
      } as DefnStudioPickReportId
    }),

    [fieldMultiSelect]: {
      type: "bool",
      name: fieldMultiSelect,
      metaId: fieldMultiSelect,
      showAsCheckboxVar: true,
      pt: theme.common.gapHalf
    } as DefnFieldSwitch,

    [fieldLogged]: {
      type: "bool",
      name: fieldLogged,
      metaId: fieldLogged,
      label: "Create change log",
      showAsCheckboxVar: true,
      pt: theme.common.gapHalf
    } as DefnFieldSwitch,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      sectionDirection: "horizontal",
      justifyContent: "spaceBetween",
      flexGrow: false,
      pb: gapStd,
      fieldIdSet: [
        fieldKeyName,
        ...(currentFieldType && MultiSelectFieldType.includes(currentFieldType))
          ? [fieldMultiSelect]
          : [],
        ...(currentFieldType && LoggedFieldType.includes(currentFieldType))
          ? [fieldLogged]
          : [],
        ...(currentFieldType === "ref")
          ? [fieldRefFieldSpreadsheet]
          : [],
        ...(showReport)
          ? [fieldRefReportFieldReport]
          : []
      ]
    } as DefnSection
  });
}

function valueToDto(values: FieldValues, selectedField: EnumStudioCompType): IDialogNewField | undefined
{
  const multiSelect = fnFieldValueToRawValue("bool", values[fieldMultiSelect]) as boolean;
  const logged = fnFieldValueToRawValue("bool", values[fieldLogged]) as boolean;

  return {
    fieldType: getFieldType(selectedField, multiSelect, logged),
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]) as Symbol,
    refFieldSpreadsheet: values[fieldRefFieldSpreadsheet],
    refFieldReport: values[fieldRefReportFieldReport],
    metaIdField: nextMetaIdField()
  };
}

function getFieldType(
  fieldType: EnumStudioCompType,
  multiSelect?: boolean,
  logged?: boolean
): EnumStudioCompType
{
  if(multiSelect)
  {
    switch(fieldType)
    {
      case "pickText":
        return "setOfText";
      case "pickUser":
        return "setOfUser";
      case "pickRole":
        return "setOfRole";
    }
  }

  if(logged)
  {
    switch(fieldType)
    {
      case "number":
        return "logNumber";
      case "decimal":
        return "logDecimal";
      case "counter":
        return "logCounter";
    }
  }

  return fieldType;
}

function checkSearchContains(variableText: string, searchText: string)
{
  return variableText.toUpperCase().indexOf(searchText.toUpperCase()) !== -1;
}

function getKindArr(arr: string[])
{
  return convertEnumArrToDialogListArr(arr as EnumDefnCompType[], getDescription);
}

function getDescription(kind: EnumDefnCompType): string | undefined
{
  switch(kind)
  {
    case "bool":
      return "Allow users to input true or false values, or 'true' or 'false' strings";
    case "date":
      return "Allow users to input dates";
    case "decimal":
      return "Allow users to input precise numeric values with decimal points";
    case "image":
      return "Allow users to input image files";
    case "label":
      return "A label field gives context to the information that a user needs to input";
    case "number":
      return "Allow users to enter numerical data in your spreadsheets";
    case "paragraph":
      return "Allow users to enter text such as address in a textarea form field";
    case "text":
      return "Allow users to enter text such as name, area of living etc";
    case "audio":
      return "Allow users to input or upload audio data, such as recordings or files.";
    case "camera":
      return "Allow users to capture and submit images using the camera functionality of their device, such as a smartphone or webcam.";
    case "color":
      return "Allow users to select or input a specific color through a color palette or rgb code.";
    case "counter":
      return "Allow users to increment or decrement a numerical value using a + icon.";
    case "dateRange":
      return "Allow users to input both a start and end date to capture a time period.";
    case "dateTime":
      return "Allow users to input both a specific date and the time on the selected date.";
    case "dateTimeRange":
      return "Allow users to input a start and end date-time value to capture a specific time period.";
    case "duration":
      return "Allow users to specify a length of time, indicating a duration such as hours, minutes, or seconds.";
    case "email":
      return "Allow users to enter their email address.";
    case "handle":
      return "Allow users to enter a unique identifier or username.";
    case "hyperlink":
      return "Allow users to enter or provide a web link (URL), enabling the inclusion of clickable hyperlinks.";
    case "location":
      return "Allow users to enter geographic location information.";
    case "mobileNumber":
      return "Allow users to enter mobile numbers with country codes.";
    case "rating":
      return "Allow users to assign a rating or score to a particular item or experience by selecting a number of stars.";
    case "signature":
      return "Allow users to provide their digital or electronic signature.";
    case "slider":
      return "Allow users to select a value within a specified range by moving a slider.";
    case "time":
      return "Allow users to enter or select a specific time.";
    case "video":
      return "Allow users to upload a video.";
    case "voice":
      return "Allow users to send recorded audio messages.";
    case "pickGridRow":
      return "Allow users to select values from another grid in the same form.";
    case "pickText":
      return "Allow users to select inputs from a source variable with a predefined list of values.";
    case "pickTree":
      return "Allow users to select inputs from a source variable with a predefined tree of values.";
    case "pickUser":
      return "Allow users to select specific roles within an enterprise.";
    case "chipSet":
      return "Allow users to select multiple text or numerical values represented as a chip.";
    case "chipSetDateTime":
      return "Allow users to select multiple date and time inputs.";
    case "chipSetDay":
      return "Allow users to select multiple values from a predefined set of days.";
    case "chipSetDeviceSize":
      return "Allow users to select multiple inputs from a predefined set of device sizes.";
    case "chipSetDeviceType":
      return "Allow users to select multiple inputs from a predefined set of device types.";
    case "chipSetTime":
      return "Allow users to select multiple time inputs.";
    case "currency":
      return "Allow users to choose currency from a set of international currencies.";
    case "icon":
      return "Allow users to choose icons from a set of icons.";
    case "language":
      return "Allow users to choose languages from a set of languages.";
    case "lineStroke":
      return "Allow users to choose line stroke style from dash, dotted, and solid.";
    case "month":
      return "Allow users to choose month name.";
    case "pinShape":
      return "Allow users to choose map pin shape from the dropdown.";
    case "quarter":
      return "Allow users to choose quarter from four available quarters.";
    case "textSize":
      return "Allow users to choose text size.";
    case "timeZone":
      return "Allow users to choose time zone.";
    case "button":
      return "Allow users to add a button to the form.";
    case "divider":
      return "Allow users to add a divider to the form.";
    case "document":
      return "Allow users to choose documents to be uploaded.";
    case "error":
      return "Allow users to display error messages.";
    case "html":
      return "Allow users to add HTML content.";
    case "identifier":
      return "Allow users to add a unique identifier.";
    case "info":
      return "Allow users to add information field in the form.";
    case "paymentStatus":
      return "Allow users to choose payment status.";
    case "propertyMap":
      return "Allow users to map properties.";
    case "ref":
      return "Allow users to reference another spreadsheet values in the form.";
    case "rowId":
      return "Allow users to uniquely identify each record or row within the system or application database.";
    case "scanCode":
      return "Allow users to scan QR code.";
    case "symbol":
      return "Allow users to add Symbols.";
    case "userId":
      return "Allow users to uniquely identify an individual user within a system or application.";
  }
}
