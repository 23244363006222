import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueOptionId} from "../../../../api/meta/base/dto/FieldValueOptionId";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {EnumDefnThemePickVariant} from "../../../../api/meta/base/Types";
import {EnumDefnThemeSectionVariant} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRadioGroup from "../raw/FieldRawRadioGroup";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldPickText(props: {
  defn: DefnFieldPickText
})
{
  const formCtx = useFormCtx();
  const searchText = formCtx.getSearchText();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);

  const label = getCompLabel(defn);
  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();

  const sectionVariant = formSection.sectionVariant;
  const variant = defnTheme.fieldVariant;
  const fieldVariant = variant === "standard" ? "outlined" : variant;

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const optionMap = defn.optionMap;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const helperText = getFieldHelperText();
  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const fieldValue = field.value as FieldValueOptionId | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <LayoutFlexRow
              alignItems={"flex-start"}
              overflowX={"visible"}
              overflowY={"visible"}
              width={"100%"}
            >
              <FactoryFieldPickType
                pickVariant={defn.showAs}
                defn={defn}
                hideLabel={defn.hideLabel}
                fieldVariant={fieldVariant}
                sectionVariant={sectionVariant}
                compId={fieldId}
                optionMap={optionMap}
                label={label}
                placeHolder={placeHolder}
                required={required}
                helperText={helperText}
                error={error}
                fieldSize={defnTheme.fieldSize}
                onClick={onClick}
                readOnly={readOnly}
                disabled={disabled}
                field={field}
                fieldId={fieldId}
                searchText={searchText}
                icon={icon as string}
              />
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
                pt={px(gapQuarter)}
              >
                <FieldRawRefButton defn={defn} />
              </LayoutFlexRow>
            </LayoutFlexRow>
          </FieldRawTemplate>
        );

      }}
    />
  );
}

function FactoryFieldPickType(props: {
  field: ControllerRenderProps<FieldValues, string>,
  fieldId: string,
  searchText?: string,
  pickVariant?: EnumDefnThemePickVariant,
  defn: DefnFieldPickText,
  compId: MetaIdField,
  optionMap?: DefnStudioMapOfDtoOption,
  fieldVariant?: EnumDefnThemeFieldVariant;
  sectionVariant?: EnumDefnThemeSectionVariant;
  fieldSize?: EnumDefnThemeFieldSize;
  error?: FieldError,
  helperText?: string,
  required?: boolean,
  label?: string,
  placeHolder?: string,
  hideLabel?: boolean,
  icon?: string,
  readOnly?: boolean,
  disabled?: boolean,
  onClick?: () => void
})
{
  const formCtx = useFormCtx();

  const field = props.field;
  const defn = props.defn;
  const fieldValue = field.value as FieldValueOptionId | undefined;
  const fieldId = props.fieldId;
  const disabled = props.disabled;
  const error = props.error;
  const fieldVariant = props.fieldVariant;
  const fieldSize = props.fieldSize;
  const hideLabel = props.hideLabel;
  const helperText = props.helperText;
  const placeholder = props.placeHolder;
  const label = props.label;
  const required = props.required;
  const readOnly = props.readOnly;
  const searchText = props.searchText;
  const icon = props.icon;

  const [optionMap, setOptionMap] = useState<DefnStudioMapOfDtoOption | undefined>(props.optionMap);
  const [loading, setLoading] = useState<boolean>(false);

  const getOption = formCtx.getOnGetFieldOptions();

  const onClick = () =>
  {
    props.onClick && props.onClick();
  };

  const onOpen = () =>
  {
    if(defn.pluginApi)
    {
      setLoading(true);
      getOption && getOption(fieldId, options =>
      {
        if(options)
        {
          setOptionMap(options as DefnStudioMapOfDtoOption);
        }
        setLoading(false);
      });
    }
  };
  const onBlur = () =>
  {
    field.onBlur();
    setLoading(false);
  };

  const onChange = (value?: MetaIdOption | null) =>
  {
    if(value === null || value === undefined)
    {
      field.onChange(null);
    }
    else
    {
      const option = optionMap?.map[value];
      field.onChange(option
        ? {
          value: option.value,
          optionId: option.metaId
        } as FieldValueOptionId
        : null);
    }
  };

  useEffect(() =>
  {
    if(defn.pluginApi && !optionMap?.keys.length && fieldValue?.optionId)
    {
      const optionId = fieldValue.optionId;
      setOptionMap({
        keys: [optionId],
        map: {
          [optionId]: {
            metaId: optionId,
            value: fieldValue.value,
            disabled: true
          } as DefnDtoOption
        }
      });
    }
    if(defn.pluginApi && props.pickVariant && props.pickVariant !== "dropdown")
    {
      onOpen();
    }
  }, []);

  useEffect(() =>
  {
    if(!defn.pluginApi)
    {
      setOptionMap(props.optionMap);
    }
  }, [props.optionMap]);

  switch(props.pickVariant)
  {
    case "radioButtonVertical":
    case "radioButtonHorizontal":
      return <FieldRawRadioGroup
        defn={defn}
        fieldValue={field.value}
        onChange={onChange}
        optionMap={optionMap}
        disabled={disabled}
        readOnly={readOnly}
      />;
    case "led":
      return <FieldRawRadioGroup
        defn={defn}
        fieldValue={field.value}
        onChange={onChange}
        optionMap={optionMap}
        isLed={true}
        disabled={disabled}
        readOnly={readOnly}
      />;
    default:
      return (
        <RawPickOneAutoComplete
          optionMap={optionMap}
          label={label}
          value={fieldValue?.optionId}
          disabled={disabled}
          readOnly={readOnly}
          error={error}
          onChange={onChange}
          helperText={helperText}
          placeHolder={placeholder}
          required={required}
          onClick={onClick}
          onOpen={onOpen}
          onBlur={onBlur}
          searchWords={searchText ? [searchText] : undefined}
          fieldVariant={fieldVariant}
          fieldSize={fieldSize}
          hideLabel={hideLabel}
          name={field.name}
          fieldId={fieldId}
          icon={icon}
          loading={loading}
        />
      );
  }
}
