import {cloneDeep} from "lodash";
import {debounce} from "lodash";
import {useState} from "react";
import {useEffect} from "react";
import React from "react";
import {useMemo} from "react";
import {useRef} from "react";
import {useCallback} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DtoFieldFilter} from "../../../api/ent/base/dto/DtoFieldFilter";
import {SpreadsheetFilterValue} from "../../../api/ent/base/dto/SpreadsheetFilterValue";
import {SigRefFieldPaginatedData} from "../../../api/ent/entAside/sig/SigRefFieldPaginatedData";
import {isGridId} from "../../../api/meta/base/ApiPlus";
import {DefnFieldRef} from "../../../api/meta/base/dto/DefnFieldRef";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnLayoutCard} from "../../../api/meta/base/dto/DefnLayoutCard";
import {DefnLayoutGridMap} from "../../../api/meta/base/dto/DefnLayoutGridMap";
import {FieldDtoGridRow} from "../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueGrid} from "../../../api/meta/base/dto/FieldValueGrid";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {RowId} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {publicRole} from "../../../base/plus/FormPlus";
import {filterForm} from "../../../base/plus/FormPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {isProd} from "../../../base/plus/SysPlus";
import {listSetLoadingFooter} from "../../../base/slices/list/SliceListSharedActions";
import {listSetScrollToTop} from "../../../base/slices/list/SliceListSharedActions";
import {listSetShowPicksOnly} from "../../../base/slices/list/SliceListSharedActions";
import {IFormRef} from "../../../base/types/TypesForm";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {useAppSelector} from "../../../nucleus/app/AppHooks";
import helperTextData from "../../../nucleus/atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../../../nucleus/atom/raw/RawNothingHere";
import {CbOnClickForm} from "../../../nucleus/form/viewer/base/CtxForm";
import {FieldValueFormListItem} from "../../../nucleus/form/viewer/uiOnly/FieldFormList";
import {ISsBrRef} from "../../../nucleus/ssBr/SsBr";
import SsBr from "../../../nucleus/ssBr/SsBr";
import {ISsBrItemUserField} from "../../../nucleus/ssBr/TypesSsBr";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import UiRefBrForm from "./base/UiRefBrForm";

export default function UiSsBr(props: {
  entId: EntId,
  defnForm: DefnForm,
  refFieldId: MetaIdField;
  compositeId: MetaIdComposite;
  isMultiPick: boolean;
  formValue?: FormValue,        // for single select
  gridValue?: FieldValueGrid,     // for multi select
  enableSelection?: boolean,
  gridRow?: FieldDtoGridRow,
  cbOnSubmit?: (value: FieldValues) => void,
  cbOnClickListItem?: (rowId: RowId, spreadSheetId: MetaIdSpreadsheet, listName: string, selected?: boolean) => void,
})
{
  const srvc = Srvc.app.spreadsheet.SsBr;
  const formRef = useRef<IFormRef>({} as IFormRef);
  const ssBrRef = useRef({} as ISsBrRef);
  const selectList = srvc.selectList;

  const entId = props.entId;
  const defnForm = props.defnForm;
  const formId = defnForm.metaId;
  const refFieldId = props.refFieldId;
  const cbOnClickListItem = props.cbOnClickListItem;
  const compositeId = props.compositeId;
  const enableSelection = props.enableSelection;
  const isMultiPick = props.isMultiPick;
  const isGrid = isGridId(compositeId);
  const gridRow = isGrid ? props.gridRow : undefined;

  const fieldFormList = srvc.fnGetRefBrListFieldName();
  const [formValue] = useState(cloneDeep(props.formValue));
  const [gridValue] = useState(cloneDeep(props.gridValue));
  const [displayForm, setDisplayForm] = useState<DefnForm | null>();
  const [initValues, setInitValues] = useState<FieldValues>();
  const [filters, setFilters] = useState<DtoFieldFilter[]>();
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [submitEnable, setSubmitEnable] = useState(false);
  const refSelectedFilters = useRef<SpreadsheetFilterValue[]>();
  const refSearchStr = useRef<string>();
  const refSortByFieldId = useRef<MetaIdField>();
  const currentPageNumber = useRef<number>(1);
  const hasMoreRows = useRef<boolean>(true);
  const loading = useRef<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  const [layoutMap, setLayoutMap] = useState<DefnLayoutGridMap>();
  const [sourceDefnForm, setSourceDefnForm] = useState<DefnForm>();
  const [refSpreadsheetId, setRefSpreadsheetId] = useState<MetaIdSpreadsheet>();

  const defnFieldRef = useMemo(() =>
    defnForm?.compMap[refFieldId] as DefnFieldRef | undefined, [defnForm?.compMap, refFieldId]);
  const listName = useAppSelector(state => selectList(state).listName);
  const displayItemCount = useAppSelector(state => selectList(state).displayItemCount);
  const showPicksOnly = useAppSelector(state => selectList(state).showPicksOnly);

  const [currentLayoutId, setCurrentLayoutId] =
    useState<MetaIdLayoutGrid | undefined>(defnFieldRef?.layoutSpreadsheet?.metaId);

  const layout = useMemo(() =>
  {
    if(currentLayoutId && layoutMap?.map?.[currentLayoutId])
    {
      return layoutMap.map[currentLayoutId] as DefnLayoutCard;
    }
    return defnFieldRef?.layoutSpreadsheet as DefnLayoutCard;
  }, [currentLayoutId, defnFieldRef?.layoutSpreadsheet, layoutMap?.map]);

  const allowToSwitchLayoutIdSet = useMemo(() =>
    srvc.fnGetSwitchLayoutMap(layoutMap, layout), [layout, layoutMap, srvc]);
  const filterViewType = srvc.fnGetLayoutFilterViewType(layout as DefnLayoutCard);
  const showSearchBar = srvc.fnGetLayoutFilterSearch(layout as DefnLayoutCard);

  const overlayLayout = useMemo(() =>
  {
    if(srvc.fnCheckLayoutHasFields(defnFieldRef?.overlayLayoutSpreadsheet))
    {
      return defnFieldRef?.overlayLayoutSpreadsheet;
    }
  }, [defnFieldRef?.overlayLayoutSpreadsheet, srvc]);

  const showSelectedItems = useCallback((showSelectedItems: boolean) =>
  {
    dispatchList(listName, listSetShowPicksOnly(showSelectedItems));
  }, [listName]);

  const cbResult = useCallback((sig: SigRefFieldPaginatedData, reset?: boolean) =>
  {
    loading.current = false;
    hasMoreRows.current = sig.hasMoreRows;
    if(sourceDefnForm && sig.rowList)
    {
      const rootState = store.getState();
      const userAvatarMap = rootState.cache.app.user.userAvatarMap;
      const callerEnt = selectCallerEnt(rootState, entId);
      const value = (defnForm && layout)
        ? srvc.calcFieldValues(defnForm,
          layout,
          sourceDefnForm,
          overlayLayout,
          sig.rowList,
          callerEnt ? srvc.insertEntUserNameInFormField(entId, sourceDefnForm, userAvatarMap, callerEnt) : undefined
        )
        : undefined;
      if(reset)
      {
        formRef.current.getFieldFormListApis &&
        formRef.current.getFieldFormListApis(fieldFormList as string)?.updateItems(value);
        return;
      }
      else
      {
        formRef.current.getFieldFormListApis &&
        formRef.current.getFieldFormListApis(fieldFormList as string)?.appendItems(value);
      }
    }
  }, [defnForm, fieldFormList, layout, overlayLayout, sourceDefnForm, srvc]);

  const rpcRefFieldDataGet = useCallback((params: {
    pageNumber: number,
    includeFilters?: boolean,
    sortByFieldId?: MetaIdField,
    ascOrder?: boolean,
    appliedFilters?: SpreadsheetFilterValue[],
    searchText?: string,
    cbSuccess: (sig: SigRefFieldPaginatedData) => void
  }) =>
  {
    if(loading.current)
    {
      return;
    }
    loading.current = true;
    const gridId = isGrid ? compositeId : undefined;
    const inputFormValue = srvc.fnInsertGridRowInInputFormValue(formValue, gridId, gridRow);

    srvc.rpcRefFieldPaginatedDataGet({
      entId: entId,
      metaIdForm: formId,
      refField: refFieldId,
      ascOrder: params.ascOrder,
      pageNumber: params.pageNumber,
      includeFilters: params.includeFilters,
      inputFormValue: inputFormValue,
      inputFormCompositeId: compositeId,
      inputFormGridRowId: gridRow?.rowId,
      layoutSpreadsheetId: currentLayoutId,
      sortByFieldId: params.sortByFieldId,
      appliedFilters: params.appliedFilters,
      searchText: params.searchText,
      cbSuccess: params.cbSuccess,
      cbError: () =>
      {
        loading.current = false;
        dispatchList(listName, listSetLoadingFooter(false));
        ssBrRef.current.setFilterState?.({state: false});
        ssBrRef.current.setSearchState?.({search: false});
      }
    });

  }, [compositeId, currentLayoutId, entId, formId, formValue, gridRow, isGrid, listName, refFieldId, srvc]);

  const onFilterUpdate = debounce(useCallback((
    selectedFilters?: SpreadsheetFilterValue[],
    searchStr?: string,
    sortByFieldId?: MetaIdField) =>
  {
    if(refSearchStr.current === searchStr &&
      refSelectedFilters.current === selectedFilters &&
      refSortByFieldId.current === sortByFieldId)
    {
      return;
    }
    hasMoreRows.current = true;
    refSearchStr.current = searchStr;
    refSelectedFilters.current = selectedFilters;
    refSortByFieldId.current = sortByFieldId;
    currentPageNumber.current = 1;
    dispatchList(listName, listSetLoadingFooter(false));
    dispatchList(listName, listSetScrollToTop());
    rpcRefFieldDataGet({
      pageNumber: 1,
      appliedFilters: selectedFilters,
      searchText: searchStr,
      sortByFieldId: sortByFieldId,
      cbSuccess: sig =>
      {
        setTimeout(() =>
        {
          ssBrRef.current.setFilterState?.({state: false});
          ssBrRef.current.setSearchState?.({search: false});
        }, 100);
        cbResult(sig, true);
      }
    });
  }, [cbResult, listName, rpcRefFieldDataGet]), 100);

  const onClickFormItem = useCallback<CbOnClickForm>((metaId, variant, value) =>
  {
    switch(variant)
    {
      case "listBottomReached":
        if(hasMoreRows.current && !loading.current)
        {
          dispatchList(listName, listSetLoadingFooter(true));
          rpcRefFieldDataGet({
            pageNumber: currentPageNumber.current + 1,
            searchText: refSearchStr.current,
            appliedFilters: refSelectedFilters.current,
            sortByFieldId: refSortByFieldId.current,
            cbSuccess: sig =>
            {
              currentPageNumber.current++;
              // keep loader at bottom till hasMoreRows
              if(!sig.hasMoreRows || showPicksOnly)
              {
                dispatchList(listName, listSetLoadingFooter(false));
              }
              cbResult(sig);
            }
          });
        }
        break;
      case "listItem":
        if(isMultiPick)
        {
          const fieldValue = formRef.current.getValue(fieldFormList)?.map[metaId] as FieldValueFormListItem;
          const fieldDtoGridRow = (fieldValue?.userField as ISsBrItemUserField)?.fieldDtoGridRow;
          if(cbOnClickListItem && fieldDtoGridRow && refSpreadsheetId)
          {
            cbOnClickListItem(fieldDtoGridRow.rowId,
              refSpreadsheetId,
              listName,
              Boolean(value as boolean)
            );
          }
        }
    }
  }, [
    cbOnClickListItem,
    cbResult,
    fieldFormList,
    isMultiPick,
    listName,
    refSpreadsheetId,
    rpcRefFieldDataGet,
    showPicksOnly
  ]);

  useEffect(() =>
  {
    refSortByFieldId.current = layout?.filter?.sortByFieldIdSet?.at(0);
    currentPageNumber.current = 1;
    Srvc.cache.app.callerEnt.rpcEntUserAvatarListGet(entId, () =>
    {
      rpcRefFieldDataGet({
        sortByFieldId: refSortByFieldId.current,
        ascOrder: layout.filter?.sortOrder === "ascending"
          ? true
          : layout.filter?.sortOrder === "descending"
            ? false
            : undefined,
        pageNumber: currentPageNumber.current,
        includeFilters: true,
        cbSuccess: sig =>
        {
          loading.current = false;
          if(sig.filterList?.length)
          {
            ssBrRef.current.setFilters && ssBrRef.current.setFilters(sig.filterList);
            setFilters(sig.filterList);
          }
          setLayoutMap(sig.spreadSheetLayoutMap);

          const rootState = store.getState();
          const callerEnt = selectCallerEnt(rootState, entId);
          const sourceDefnForm = filterForm(sig.outputForm, callerEnt?.roleIdSet || [publicRole], callerEnt);
          setSourceDefnForm(sourceDefnForm);
          const userAvatarMap = rootState.cache.app.user.userAvatarMap;
          const value = (defnForm && layout)
            ? srvc.calcFieldValues(defnForm,
              layout,
              sourceDefnForm,
              overlayLayout,
              sig?.rowList,
              callerEnt
                ? srvc.insertEntUserNameInFormField(entId, sourceDefnForm, userAvatarMap, callerEnt)
                : undefined
            )
            : undefined;

          setInitValues({
            [fieldFormList as string]: value
          });
          setLoaded(true);
          setRefSpreadsheetId(sig.spreadsheetId);
          hasMoreRows.current = sig.hasMoreRows;
        }
      });
    });
  }, [entId, formId, refFieldId, srvc]);

  useEffect(() =>
  {
    if(loaded)
    {
      if(layout && defnForm && sourceDefnForm)
      {
        const displayForm = srvc.fnGetDisplayForm(sourceDefnForm,
          defnForm,
          layout,
          overlayLayout,
          isMultiPick,
          enableSelection
        );
        setDisplayForm(displayForm || null);
      }
      else
      {
        setDisplayForm(null);
      }
    }

  }, [loaded, defnForm, sourceDefnForm, layout, overlayLayout, isMultiPick, srvc, enableSelection]);

  useEffect(() =>
  {
    ssBrRef.current.setFooterPayload &&
    ssBrRef.current.setFooterPayload({
      totalItems: displayItemCount || 0,
      selectedItems: selectedItemsCount,
      submitEnable: submitEnable
    });

  }, [selectedItemsCount, displayItemCount, submitEnable]);

  useEffect(() =>
  {
    const formValue = !isMultiPick ? ((gridRow as FormValue) || props.formValue) : undefined;
    const _gridValue = defnFieldRef?.keyFieldIdSet?.length ? gridValue : undefined;

    srvc.onMount(defnFieldRef?.copyFieldMap, _gridValue, defnFieldRef?.keyFieldIdSet, formValue);

    return () => srvc.onUnMount();

  }, [defnFieldRef]);

  if(!loaded || displayForm === undefined)
  {
    return <RawNothingHere helperTextData={helperTextData.loadingData} />;
  }

  if(!sourceDefnForm || !displayForm)
  {
    if(isProd())
    {
      return <RawNothingHere />;
    }
    else if(!sourceDefnForm)
    {
      return <RawNothingHere helperTextData={{title: "Ref defnForm not found"}} />;
    }
    else if(!displayForm)
    {
      return <RawNothingHere helperTextData={{title: "DisplayForm not found"}} />;
    }
    else
    {
      return <RawNothingHere helperTextData={{title: "SigRefFieldData not found"}} />;
    }
  }

  if(!layout)
  {
    return <RawNothingHere helperTextData={helperTextData.noDefaultLayoutFound} />;
  }

  if(displayForm)
  {
    return (
      <SsBr
        spreadSheetBrContent={
          <UiRefBrForm
            srvcRefBr={srvc}
            entId={entId}
            formId={formId}
            isMultiPick={isMultiPick}
            cbRefForm={formRef.current}
            compositeId={compositeId}
            cbOnSubmit={props.cbOnSubmit}
            layout={layout}
            overlayLayout={overlayLayout}
            displayForm={displayForm}
            defnForm={defnForm}
            sourceDefnForm={sourceDefnForm}
            cbOnClickFormItem={onClickFormItem}
            selectList={selectList.bind(srvc)}
            initValues={initValues}
            cbOnSubmitEnable={setSubmitEnable}
            cbOnSelectedItemsCountChange={setSelectedItemsCount}
            fieldValueGrid={gridValue}
          />
        }
        cbRef={ssBrRef.current}
        defnFormRef={sourceDefnForm}
        currentLayout={layout}
        hideFooter={!isMultiPick}
        allowToSwitchLayoutMap={allowToSwitchLayoutIdSet}
        filters={filters}
        showFilters={Boolean(filters?.length || layout.filter?.sortByFieldIdSet?.length)}
        filterViewType={filterViewType}
        showSearchBar={showSearchBar}
        onFilterUpdate={onFilterUpdate}
        onSwitchLayout={setCurrentLayoutId}
        onClickSubmit={() => formRef.current.remoteSubmit(true)}
        cbOnClickShowSelected={showSelectedItems}
      />
    );
  }

  return <RawNothingHere helperTextData={helperTextData.nothingToShow} />;
};
