import React from "react";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {EntUserId} from "../../../api/meta/base/Types";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {IListItemSsRowBubble} from "../../../base/types/list/TypeListSsRowBubble";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {useAppSelector} from "../../app/AppHooks";
import BubbleRawForm from "../../atom/bubble/raw/bubbleForm/BubbleRawForm";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import ListItemShellChat from "../impl/ListItemShellChat";
import {CbOnClickListItem} from "../List";

export function ListItemSsRowBubble(props: {
  itemId: TypeListItemId,
  listItem: IListItemSsRowBubble,
  selectList: SelectList,
  itemMaxWidth: number,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
  onClickListItem?: CbOnClickListItem,
  cbOnClickFooterCaption?: CbMenuAnchor,
})
{
  const selectList = props.selectList;
  const searchWords = useAppSelector(state => selectList(state).searchWords);
  const listItem = props.listItem;
  const sig = listItem.sig;

  return (
    <ListItemShellChat
      itemId={props.itemId}
      listItem={props.listItem as IListItemChat}
      selectList={props.selectList}
      maxWidth={props.itemMaxWidth}
      onClickListItem={props.onClickListItem}
    >
      <LayoutFlexCol
        width={"100%"}
        height={"100%"}
        pt={px(gapHalf + gapQuarter)}
        pb={px(gapHalf + gapQuarter)}
      >
        <BubbleRawForm
          receiptStatus={sig.receiptStatus}
          isCallerSender={Boolean(sig.isCallerSender)}
          creationTime={sig?.creationTime ?? ""}
          titleColor={sig?.formBubbleTitleColor}
          maxWidth={props.itemMaxWidth}
          header={sig?.header}
          searchWords={searchWords}
          isStar={sig?.isStar}
          defnForm={sig?.defnForm}
          formValue={listItem?.formValue}
          showComments={sig?.isCommentable}
          bgColorShell={sig?.bgColorShell}
          isFormWithMedia={sig?.isFormWithMedia}
          cbOnClickFooterCaption={props.cbOnClickFooterCaption}
          isInvisibleSpreadsheetRow={sig?.isInvisibleSpreadsheetRow}
          sigSpreadsheetRowExpiry={sig?.sigSpreadsheetRowExpiry}
          canExpire={sig?.canExpire}
          chatPatternVar={sig?.chatPatternVar}
          hideFooter={listItem?.hideFooter}
          disableMenuHover={listItem?.ignoreSelection}
          selectUserAvatar={props.selectUserAvatar}
        />
      </LayoutFlexCol>
    </ListItemShellChat>
  );
}
