import {isEmpty} from "lodash";
import {uniq} from "lodash";
import {camelCase} from "lodash";
import SparkMD5 from "spark-md5";
import {MetaIdComp} from "../../api/meta/base/Types";
import {GeoPoint} from "../../api/meta/base/Types";
import {ILatLng} from "../types/TypesStudio";
import {MENTION_SYMBOL} from "./ConstantsPlus";
import {isValidUrl} from "./UrlPlus";

export interface IStringStartEndIndex
{
  startIndex: number;
  endIndex: number;
}

export const getCombinedStringConstantSymbol = "<>";
export const md5 = require("md5");

export function hasValue(str?: string | null): boolean
{
  if(str === undefined || str == null)
  {
    return false;
  }

  str = str.trim();
  return str.length > 0;
}

export function hasWhiteSpace(str: string | undefined)
{
  const whitespaceChars = [" ", "\t", "\n", "\r"];
  return str && whitespaceChars.some(char => str.includes(char));
}

export function toLabel(input: string): string
{
  let result: string = "";

  if(input)
  {
    const isAllUppercase = input === input.toUpperCase();

    if(!isAllUppercase)
    {
      let index = 0;

      for(let i = 0; i < input.length; i++)
      {
        if(isSpecialCharacter(input.charAt(i)))
        {
          result += input.charAt(i);
        }
        else
        {
          result += input.charAt(i).toUpperCase();
          index = i + 1;
          break;
        }
      }

      for(let i = index; i < input.length; i++)
      {
        const char = input.charAt(i);

        if(char === char.toUpperCase()
          && char.toUpperCase() !== char.toLowerCase()
        )
        {
          if(isNumber(input.charAt(i - 1)))
          {
            result += " " + char.toLowerCase();
          }
          else if(input.charAt(i - 1) === input.charAt(i - 1).toUpperCase())
          {
            result += char.toLowerCase();
          }
          else
          {
            result += " " + char.toLowerCase();
          }
        }
        else if(char === "_")
        {
          result += " ";
        }
        else
        {
          result += char;
        }
      }
    }
    else
    {
      result = input;
    }

  }

  return result;
}

function isNumber(character: string)
{
  return (character >= "0" && character <= "9");
}

function isSpecialCharacter(char: string): boolean
{
  const specialCharPattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|-]/;

  return specialCharPattern.test(char);
}

export function removeSpecialChars(str: string): string
{
  return str
  .split("")
  .filter(char =>
  {
    const code = char.charCodeAt(0);
    return (
      (code >= 48 && code <= 57) || // 0-9
      (code >= 65 && code <= 90) || // A-Z
      (code >= 97 && code <= 122) || // a-z
      char === "." || // allow dot
      char === " " // allow space
    );
  })
  .join("")
  .trim();
}

export function toSymbolCase(str: string)
{
  str = camelCase(str);
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
}

export function px(value?: number): string | undefined
{
  return value !== undefined
    ? value + "px"
    : undefined;
}

export function searchHit(text?: string, searchWordsLowercase?: string[]): boolean
{
  if(!text || text.length === 0)
  {
    return false;
  }

  if(!searchWordsLowercase || searchWordsLowercase.length === 0)
  {
    return true;
  }

  const textLowercase = text.toLowerCase();
  for(const searchWord of searchWordsLowercase)
  {
    if(!textLowercase.includes(searchWord))
    {
      return false;
    }
  }

  return true;
}

export function copyToClipboard(str: string, clipboardItem?: ClipboardItem)
{
  if(!clipboardItem)
  {
    navigator.clipboard.writeText(str);
  }
  else
  {
    if(typeof clipboardItem && navigator.clipboard.write)
    {
      navigator.clipboard.write([clipboardItem]);
    }
  }
}

export function random(length: number = 16)
{
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for(let i = 0; i < length; i += 1)
  {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

export function generateMD5File(file: File)
{
  return md5FromFile(file);
}

function md5FromFile(file: File)
{
  return new Promise((resolve, reject) =>
  {
    const reader = new FileReader();
    reader.onload = (event) =>
    {
      const arrayBuffer = event.target?.result as ArrayBuffer | null;
      if(arrayBuffer)
      {
        const data = new Uint8Array(arrayBuffer);
        const spark = new SparkMD5.ArrayBuffer();
        spark.append(data);

        resolve(spark.end());
      }
      else
      {
        reject("Failed to read file data.");
      }
    };

    reader.onerror = () =>
    {
      reject(" there is issue with generate md5 ");
    };

    reader.readAsArrayBuffer(file);

  });
}

export function getLinkFromText(text: string)
{
  let link: string | undefined;
  let matchedUrl: string | undefined;

  const words = text.split(/\s+/);
  for(const word of words)
  {
    if(isValidUrl(word))
    {
      matchedUrl = word;
      break;
    }
  }

  if(matchedUrl)
  {
    link = matchedUrl;
  }

  return {
    text: text,
    url: link
  };
}

export function removeFileNameExt(str: string)
{
  return str.replace(/\.[^\/.]+$/, "");
}

export function getDurationFromMs(ms: number): string
{
  const totalSeconds: number = Math.floor(ms / 1000);

  const hours: number = Math.floor(totalSeconds / 3600);
  const minutes: number = Math.floor((totalSeconds % 3600) / 60);
  const seconds: number = totalSeconds % 60;

  const formattedHours: string = String(hours).padStart(2, "0");
  const formattedMinutes: string = String(minutes).padStart(2, "0");
  const formattedSeconds: string = String(seconds).padStart(2, "0");

  if(hours > 0)
  {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  else
  {
    return `${minutes}:${formattedSeconds}`;
  }
}

export function isJsonString(str: string)
{
  try
  {
    JSON.parse(str);
  }
  catch(e)
  {
    return false;
  }
  return true;
}

export function wrapString(str: string, prefix: string, suffix: string)
{
  return `${prefix}${str}${suffix}`;
}

export function getCombinedString(str: string[], symbol: string = getCombinedStringConstantSymbol)
{
  return str.join(symbol);
}

export function wrapStringBetweenDollarBrace(str: string): string
{
  return wrapString(str, "${", "}");
}

export function findWordsBetweenDollarBraces(_query?: string, withDollarBrace?: boolean)
{
  const result = [];
  const stack = [];
  let substringStart = null;
  let query = _query;

  if(query)
  {
    for(let i = 0; i < query.length; i++)
    {
      if(query[i] === "$" && query[i + 1] === "{")
      {
        if(substringStart === null)
        {
          substringStart = i + 2;
        }

        stack.push("{");
      }
      else if(query[i] === "}")
      {
        if(stack.length > 0)
        {
          stack.pop();

          if(stack.length === 0 && substringStart !== null)
          {
            let substring = query.substring(substringStart, i).trim();

            if(!isEmpty(substring))
            {
              result.push(withDollarBrace ? wrapStringBetweenDollarBrace(substring) : substring);
            }

            query = query.slice(0, substringStart) + substring + query.slice(i);
            substringStart = null;
          }
        }
      }
    }
  }

  return {
    queryStr: query,
    paramSet: uniq(result)
  };
}

export function findFirstSubStrBetweenPrefixAndSuffix(
  query: string,
  prefix: string,
  suffix: string): IStringStartEndIndex
{
  let start = -1;
  let end = -1;
  const prefixLength = prefix.length;
  const stack = [];
  let substringStart = null;

  for(let i = 0; i < query.length; i++)
  {
    if(prefix === suffix)
    {
      if(query.substring(i).startsWith(prefix))
      {
        if(start === -1)
        {
          start = i + prefixLength;
        }
        else
        {
          end = i;
          break;
        }
      }

    }
    else
    {
      if(query.substring(i).startsWith(prefix))
      {
        if(substringStart === null)
        {
          substringStart = i + prefixLength;
        }
        stack.push(prefix);
      }
      else if(query.substring(i).startsWith(suffix))
      {
        if(stack.length > 0)
        {
          stack.pop();
          if(stack.length === 0 && substringStart !== null)
          {
            if(start === -1 && end === -1)
            {
              start = substringStart;
              end = i;
              break;
            }
          }
        }
      }
    }
  }
  return {
    startIndex: start,
    endIndex: end
  };
}

export function findSubStrBetweenPrefixAndSuffix(
  query: string,
  prefix: string,
  suffix: string
)
{
  const result = [];
  const prefixLength = prefix.length;
  const stack = [];
  let substringStart = null;

  for(let i = 0; i < query.length; i++)
  {
    if(prefix === suffix)
    {
      if(query.substring(i).startsWith(prefix))
      {
        if(substringStart === null)
        {
          substringStart = i + prefixLength;
        }
        else
        {
          result.push(query.substring(substringStart, i));
          substringStart = null;
        }
      }

    }
    else
    {
      if(query.substring(i).startsWith(prefix))
      {
        if(substringStart === null)
        {
          substringStart = i + prefixLength;
        }
        stack.push(prefix);
      }
      else if(query.substring(i).startsWith(suffix))
      {
        if(stack.length > 0)
        {
          stack.pop();
          if(stack.length === 0 && substringStart !== null)
          {
            result.push(query.substring(substringStart, i));
            substringStart = null;
          }
        }
      }
    }
  }

  return uniq(result);
}

export function findObjectsInString(str: string, objectKey: string): IStringStartEndIndex | undefined
{
  const searchStr = `"${objectKey}":`;
  const searchStrLen = searchStr.length;
  if(str.startsWith(searchStr))
  {
    return findFirstSubStrBetweenPrefixAndSuffix(str.substring(searchStrLen), "{", "}");
  }
}

export function getLatLngFromGeoPoint(geoPoint: GeoPoint): ILatLng
{
  const geoPointArray = geoPoint.split(",");
  const latitude = parseFloat(geoPointArray[0]);
  const longitude = parseFloat(geoPointArray[1]);

  return {
    lat: latitude,
    lng: longitude
  };
}

export function fnMarkdownParser(text: string, includeColor: boolean = false, includeNewLine?: boolean)
{
  const escapeHtml = (unsafe: string) => unsafe.replace(/</g, "&lt;").replace(/>/g, "&gt;");

  const colorStyle = (color: string) => includeColor ?
    `style="color: ${color}; user-select: text; -webkit-user-select: text;"` : "";

  const parseBoldSyntax = (match: string, content: string) =>
    content ? `<b style="user-select: text; -webkit-user-select: text;">${content}</b>` : match;

  const parseItalicSyntax = (match: string, content: string) =>
    content ? `<i style="user-select: text; -webkit-user-select: text;">${content}</i>` : match;

  const parseStrikethroughSyntax = (match: string, content: string) =>
    content ? `<s style="user-select: text; -webkit-user-select: text;">${content}</s>` : match;

  const parseColorSyntax = (match: string, color: string, content: string) =>
    includeColor && content ? `<span ${colorStyle(color)}>${content}</span>` : match;

  const breakLine = includeNewLine ? "<br/>" : "\n";

  return escapeHtml(text)
  .replace(/(?<![a-zA-Z0-9])~(.*?\S)~(?![a-zA-Z0-9])/g, parseStrikethroughSyntax)
  .replace(/(?<![a-zA-Z0-9])_(.*?\S)_(?![a-zA-Z0-9])/g, parseItalicSyntax)
  .replace(/(?<![a-zA-Z0-9])\*(.*?\S)\*(?![a-zA-Z0-9])/g, parseBoldSyntax)
  .replace(/\[(\w+)](.*?)\[\/\1]/g, parseColorSyntax)
  .replace(/\[(#[0-9a-fA-F]{6})] (.*?) \[\/\1]/g, parseColorSyntax)
  .replace(/[\r\n]/g, breakLine);
}

export function hasFormatMarkdownText(value?: string)
{
  if(value === undefined || null || "")
  {
    return false;
  }
  return Boolean(value.match(/(~(.*\S)~|_(.*\S)_|\*(.*\S)\*)/g)) ?? false;
}

export function hasFormatMentionUser(value?: string, mentionUserNameSet?: string[])
{
  if(value === undefined || null || "" || mentionUserNameSet === undefined || mentionUserNameSet.length === 0)
  {
    return false;
  }
  return mentionUserNameSet.some(mention => value.includes(mention));
}

export function addMentionSymbolToText(value: string)
{
  return MENTION_SYMBOL.concat(value);
}

export function getLabel(obj: {
  label?: string,
  name: string
})
{
  return obj?.label && !isEmpty(obj.label) ? obj.label : toLabel(obj.name);
}

export function getExtensionsFromStringArray(filenames: string[])
{
  const extensions = new Set();

  for(const filename of filenames)
  {
    const extension = filename.split(".").pop();
    extensions.add(extension);
  }

  return [...extensions].join(", ");
}

export function fnRemovedMarkDownSymbols(text: string)
{
  const removeSymbols = (match: string, content: string) =>
    content ? `${content}` : match;

  return text
  .replace(/(?<![a-zA-Z0-9])~(.*?\S)~(?![a-zA-Z0-9])/g, removeSymbols)
  .replace(/(?<![a-zA-Z0-9])_(.*?\S)_(?![a-zA-Z0-9])/g, removeSymbols)
  .replace(/(?<![a-zA-Z0-9])\*(.*?\S)\*(?![a-zA-Z0-9])/g, removeSymbols)
  .replace(/[\r\n]/g, "\n");
}

export function getStudioArrayKey(fieldId: MetaIdComp, index: number, compKey: MetaIdComp)
{
  return `${fieldId}[${index}][${compKey}]`;
}

export function maskSecureText(text: string): string
{
  let maskedText = "";
  for(let i = 0; i < text.length; i++)
  {
    maskedText += "*";
  }
  return maskedText;
}
