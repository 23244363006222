import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickVarId from "../raw/FieldRawStudioPickVarId";

export default function FieldStudioPickVarId(props: {
  defn: DefnStudioPickVarId
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickVarId
        fieldProps={fieldProps}
        defn={defn}
        formId={defn.formId}
      />}
    />
  );
}
                             
