import axios from "axios";
import {useCallback} from "react";
import React from "react";
import {useImperativeHandle} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import {forwardRef} from "react";
import WaveSurfer from "wavesurfer.js";
import {RPC_RETRY_DELAY} from "../../../base/plus/ConstantsPlus";

interface AudioBarProps
{
  cursorColor?: string;
  progressColor?: string;
  waveColor?: string;
  src?: string;
  durationMs?: number,
  scrollable?: boolean,
  onChangeDuration: (value?: number) => void;
  onStartPlaying: (currentDurationMs: number) => void;
  onStopPlaying: () => void;
}

export interface IAudioBarRef
{
  play: () => void,
  pause: () => void
}

export const AudioBarPlayer = forwardRef<IAudioBarRef, AudioBarProps>((props: AudioBarProps, ref) =>
{
  const src = props.src;
  const scrollable = props.scrollable;
  const onChangeDuration = props.onChangeDuration;
  const onStopPlaying = props.onStopPlaying;
  const onStartPlaying = props.onStartPlaying;
  const cursorColor = props.cursorColor;
  const progressColor = props.progressColor;
  const waveColor = props.waveColor;
  const audioElementRef = useRef(new Audio());
  const containerRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<WaveSurfer>();
  const loadCount = useRef(0);

  const loadWaveSurfer = useCallback((src: string) =>
  {
    waveSurferRef.current?.load(src)
    .then(() =>
    {
      if(waveSurferRef.current)
      {
        onChangeDuration(props.durationMs
          ? (props.durationMs / 1000)
          : parseInt(waveSurferRef.current.getDuration().toString()));
      }
      waveSurferRef.current?.on("seeking", (args) =>
      {
        onChangeDuration(parseInt(args.toString()));
      });
      waveSurferRef.current?.on("finish", () =>
      {
        waveSurferRef.current?.seekTo(0);
        onStopPlaying();
        onChangeDuration(undefined);
      });
      waveSurferRef.current?.on("audioprocess", (args) =>
      {
        onChangeDuration(parseInt(args.toString()));
      });
      waveSurferRef.current?.on("play", () => onStartPlaying(waveSurferRef.current?.getCurrentTime() || 0));
      waveSurferRef.current?.on("pause", onStopPlaying);
      waveSurferRef.current?.on("destroy", onStopPlaying);
    })
    .catch((e) =>
    {
      if(loadCount.current > 5)
      {
        console.error("Error loading audio retrying", e);
      }
      else
      {
        setTimeout(() => loadWaveSurfer(src), RPC_RETRY_DELAY);
        loadCount.current += 1;
      }
    });
  }, [onChangeDuration, onStartPlaying, onStopPlaying, props.durationMs]);

  useEffect(() =>
  {
    audioElementRef.current.controls = true;
    waveSurferRef.current = WaveSurfer.create({
      cursorColor: cursorColor,
      progressColor: progressColor,
      // @ts-ignore
      container: containerRef.current,
      barWidth: 2,
      barRadius: 1,
      barGap: 3,
      waveColor: waveColor,
      cursorWidth: 2,
      height: 30,
      barHeight: scrollable ? 3 : 1,
      hideScrollbar: true,
      media: audioElementRef.current,
      duration: props.durationMs && (props.durationMs / 1000)
    });

    return () =>
    {
      waveSurferRef.current?.destroy();
    };
  }, []);

  useEffect(() =>
  {
    if(src)
    {
      loadWaveSurfer(src);
    }
  }, [src]);

  useImperativeHandle(ref, () =>
  {
    return {
      play: () => waveSurferRef.current?.play().catch(() =>
      {
        if(src)
        {
          axios.get(src, {
              responseType: "blob"
            }
          ).then((response) =>
          {
            const blob = new Blob([response.data]);
            // @ts-ignore
            waveSurferRef.current?.loadBlob(blob).then(() => waveSurferRef.current?.play());
          });
        }
      }),
      pause: () => waveSurferRef.current?.pause()
    };
  }, [waveSurferRef.current]);

  return (
    <div
      id="AudioBar"
      style={{
        width: "100%"
      }}
      ref={containerRef}
    />
  );
});
