import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {FieldValueOptionId} from "../../../api/meta/base/dto/FieldValueOptionId";
import {StudioVarBoolean} from "../../../api/meta/base/dto/StudioVarBoolean";
import {MetaIdField} from "../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {arrayToMapOfOption} from "../../../base/plus/JsPlus";

const fieldValueBool = "fieldValueBool";

export function fnVariableBuilderBoolean(fieldValueLabel: string)
{
  function getVarComp(): Record<MetaIdField, DefnField>
  {
    return {
      [fieldValueLabel]: {
        type: "label",
        metaId: fieldValueLabel,
        name: fieldValueLabel,
        label: "Value *"
      } as DefnFieldLabel,
      [fieldValueBool]: {
        type: "pickText",
        metaId: fieldValueBool,
        name: fieldValueBool,
        optionMap: arrayToMapOfOption(["True", "False"]),
        showAs: "radioButtonVertical"
      } as DefnFieldPickText
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarBoolean
  )
  {
    const value = fnFieldValueToRawValue("bool", variable.value);
    valueMap[fieldValueBool] = value !== undefined
      ? fnRawValueToFieldValue("pickText", value ? "True" : "False")
      : undefined;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const varValue = fnFieldValueToRawValue("pickText", valueMap[fieldValueBool] as FieldValueOptionId);

    return {
      value: fnRawValueToFieldValue("bool", varValue === "True")
    } as StudioVarBoolean;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
