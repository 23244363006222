import {useTheme} from "@mui/material";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfPartition} from "../../../../api/meta/base/dto/DefnStudioMapOfPartition";
import {StudioDtoPartition} from "../../../../api/meta/base/dto/StudioDtoPartition";
import {StudioMapOfPartition} from "../../../../api/meta/base/dto/StudioMapOfPartition";
import {MetaIdPartition} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getStudioFormParentMap} from "../../../../base/plus/StudioFormPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewPartition from "../../../dialog/DialogNewPartition";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfPartition(props: {
  defn: DefnStudioMapOfPartition,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnFieldBuilderFormulaList = props.defn;
  const fieldId = getFieldKey(defnFieldBuilderFormulaList);
  const formId = defnFieldBuilderFormulaList.formId;

  const label = defnFieldBuilderFormulaList.label;

  const defnTheme = formCtx.getDefnTheme();
  const formReadOnly = formCtx.isReadonly();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={defnFieldBuilderFormulaList.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfPartition;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfPartition
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  onChange={onChange}
                  isFormReadOnly={formReadOnly}
                  formId={formId}
                />
              }
            />
          );
        }

        return (
          <RawSetOfPartition
            fieldId={fieldId}
            fieldValue={fieldValue}
            onChange={onChange}
            isFormReadOnly={formReadOnly}
            formId={formId}
          />
        );
      }
      }
    />
  );
}

function RawSetOfPartition(props: {
  fieldId: MetaIdField,
  fieldValue: StudioMapOfPartition,
  formId?: MetaIdForm,
  isFormReadOnly?: boolean,
  onChange: (value: StudioMapOfPartition) => void
})
{
  const {
    fieldId,
    isFormReadOnly
  } = props;

  const theme = useTheme();
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<StudioDtoPartition>;
  const metaIdForm = props.formId;
  const formStore = formCtx.getStore();
  const studioForm = metaIdForm
    ? formStore?.formMap?.map[metaIdForm]
    : undefined;
  const varMap = formStore?.varMap;
  const compositeMap = studioForm?.compositeMap;
  const parentMap = useMemo(() => getStudioFormParentMap(studioForm), [studioForm]);

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: StudioDtoPartition[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = useCallback((selectList: SelectList, fieldValue: StudioDtoPartition[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const metaIdFieldOutput = value.assignPartitionFieldId;
        if(compositeMap && parentMap && metaIdFieldOutput && studioForm)
        {
          const parentComp = parentMap[metaIdFieldOutput];
          const field = parentComp?.fieldMap.map[metaIdFieldOutput];

          const listId = value.metaId;
          uiItemIds.push(listId);

          let label = field?.details.name;

          if(parentComp?.type === "grid")
          {
            label = `${parentComp.details.name}.${field?.details.name}`;
          }

          if(field && parentComp)
          {
            uiItemsById[listId] = {
              type: "ps",
              primary: {
                text: value.name,
                caption: {
                  text: label,
                  ignoreSelection: true
                }
              },
              secondary: {
                text: value.formula?.value
              },
              hideMenu: isFormReadOnly,
              userField: {
                [fieldListItemVal]: value
              }
            } as IListItemAPSA;
          }
        }
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  }, [varMap, compositeMap, parentMap]);

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add" && formStore && metaIdForm)
    {
      pageCtx.showDialog(
        <DialogNewPartition
          metaIdForm={metaIdForm}
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) => cbRefList.addItem(newValue)}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem" && formStore && metaIdForm)
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioDtoPartition
        : undefined;

      pageCtx.showDialog(
        <DialogNewPartition
          metaIdForm={metaIdForm}
          formStore={formStore}
          values={oldValue}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) => cbRefList.updateItem(newValue, key)}
          validationError={validationError}
        />
      );
    }
  };

  return (
    <FieldRawList
      fieldId={fieldId}
      cbRef={cbRefList}
      fieldValue={fieldValue}
      disableSpotMenu={isFormReadOnly}
      onChange={onChange}
      onClickList={onClickList}
      buttonStrip={
        <LayoutFlexRow
          width={"100%"}
          justifyContent={"flex-end"}
          mt={px(theme.common.gapStd)}
        >
          <RawButtonStrip
            iconButtonList={["add"]}
            onClick={onClickIconBtn}
            iconButtonDisable={isFormReadOnly ? ["add"] : []}
          />
        </LayoutFlexRow>
      }
      doLoad={doLoad}
    />
  );

}

function convertMapToArray(map?: StudioMapOfPartition): StudioDtoPartition[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoPartition[]): StudioMapOfPartition
{
  const keys = [] as MetaIdPartition[];
  const map = {} as Record<MetaIdPartition, StudioDtoPartition>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfPartition;
}

