import React from "react";
import {DefnDtoParagraph} from "../../../../api/meta/base/dto/DefnDtoParagraph";
import {FieldValueCamera} from "../../../../api/meta/base/dto/FieldValueCamera";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {FieldValueVideo} from "../../../../api/meta/base/dto/FieldValueVideo";
import {FieldValueVoice} from "../../../../api/meta/base/dto/FieldValueVoice";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../base/plus/ConstantsPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import Carousel from "../../carousel/Carousel";
import LayoutFlexCol from "../../layout/LayoutFlexCol";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import RawLinerGradient from "../../raw/RawLinerGradient";
import BubbleRawMediaCarouselFactory from "./BubbleRawMediaCarouselFactory";

export interface IBubbleRawMediaCarouselItem
{
  fieldValueImage?: FieldValueCamera,
  fieldValueVideo?: FieldValueVideo,
  fieldValueAudio?: FieldValueVoice,
  fieldValueHtml?: DefnDtoParagraph,
  fieldValueHyperlink?: FieldValueText,
  height?: number,
  width?: number,
  actualHeight?: number,
  actualWidth?: number
  type: "image" | "video" | "audio" | "voice" | "html" | "hyperlink",
  validHyperlink?: boolean
}

export default function BubbleRawMediaCarousel(props: {
  mediaList: IBubbleRawMediaCarouselItem[],
  captionLeft?: React.ReactNode,
  captionRight?: React.ReactNode,
  swipeAble?: boolean,
  infiniteLoop?: boolean,
  maxWidth?: number,
})
{
  const {
    mediaList,
    captionLeft,
    captionRight,
    infiniteLoop,
    swipeAble = true,
    maxWidth
  } = props;

  const firstMedia = mediaList[0];
  const carouselBorderRadius = px(CHAT_ITEM_IMAGE_BORDER_RADIUS);

  return (
    <LayoutFlexCol
      overflowX={"visible"}
      overflowY={"visible"}
      justifyContent={"flex-start"}
    >
      {
        mediaList.length > 1
          ? <Carousel
            swipeAble={swipeAble}
            infiniteLoop={infiniteLoop}
            maxWidth={maxWidth}
            dotOverflow={true}
            borderRadius={carouselBorderRadius}
          >
            {
              mediaList.map(media =>
              {
                return (
                  <BubbleRawMediaCarouselFactory
                    media={media}
                  />
                );
              })
            }
          </Carousel>
          : <BubbleRawMediaCarouselFactory
            media={firstMedia}
            borderRadius={carouselBorderRadius}
          />
      }

      {
        (captionLeft || captionRight) &&
        <RawLinerGradient
          type={"bottom"}
        >
          <LayoutFlexRow
            pr={px(theme.common.gapQuarter)}
            justifyContent={captionRight ? "space-between" : "end"}
            width={"100%"}
          >
            {captionLeft && captionLeft}
            {captionRight && captionRight}
          </LayoutFlexRow>
        </RawLinerGradient>
      }
    </LayoutFlexCol>
  );
}
