import {combineReducers} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {IDebugServiceReport} from "../../../base/ISrvc";
import {createListSlice} from "../../../base/plus/ListPlus";
import {TypeUserField} from "../../../base/types/TypesGlobal";

export interface IDebugReport extends IDebugServiceReport
{
  compMap: Record<string, Record<string, TypeUserField>>; // compName -> key -> optionId
}

export function reducersAppDebug()
{
  return combineReducers({
    logList: createListSlice("logList").reducer,
    pubSubLogList: createListSlice("pubSubLogList").reducer,
    pubSubHistoryLogList: createListSlice("pubSubHistoryLogList").reducer,
    state: sliceDebug.reducer
  });
}

export const sliceDebug = createSlice({
  name: "debug",
  initialState: {
    compMap: {}
  } as IDebugReport,
  reducers: {
    setDebugServiceReport: (state, action: PayloadAction<IDebugServiceReport>) =>
    {
      const debugReport = action.payload;
      state.serviceReportDate = debugReport.serviceReportDate;
      state.serviceMap = debugReport.serviceMap;
    },
    setDebugCompKeyValue: (state, action: PayloadAction<ActionSetCompKeyValue>) =>
    {
      const payload = action.payload;

      let compState = state.compMap[payload.compName];
      if(compState === undefined)
      {
        compState = {};
        state.compMap[payload.compName] = compState;
      }

      if(payload.value)
      {
        compState[payload.key] = payload.value;
      }
      else
      {
        delete compState[payload.key];

        if(Object.keys(compState).length === 0)
        {
          delete state.compMap[payload.compName];
        }
      }
    }
  }
});

export const {
  setDebugServiceReport,
  setDebugCompKeyValue
} = sliceDebug.actions;

export type ActionSetCompKeyValue = {compName: string, key: string, value?: TypeUserField};
