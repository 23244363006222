import {Box} from "@mui/material";
import React from "react";
import {MIN_ASIDE_AND_DRAWER_WIDTH} from "../../base/plus/ConstantsPlus";
import {useAppSizeDesktop} from "../app/AppHooks";
import {DividerVertical} from "../atom/layout/DividerVertical";
import {useAppCtx} from "../ctx/CtxApp";
import {AppMain} from "./AppMain";
import AppWrapper from "./AppWrapper";

export default function TemplateAppDesktop(props: {
  drawer: React.ReactNode,
  mainHeader: React.ReactNode,
  mainContent: React.ReactNode,
  mainFooter?: React.ReactNode,
  aside?: React.ReactNode,
  mainOverlay?: React.ReactNode
})
{
  const appCtx = useAppCtx();
  const winWidth = appCtx.getWinWidth();
  const winHeight = appCtx.getWinHeight();
  const asideProps = appCtx.getAsideProps();
  const expand = appCtx.isExpand();
  const allowAside = appCtx.isAllowAside();
  const drawerVisibility = appCtx.isDrawerOpen();

  const {
    drawerWidth,
    asideWidth,
    mainWidth
  } = useAppSizeDesktop({
    winWidth: appCtx.getWinWidth(),
    drawerVisible: drawerVisibility,
    asideVisible: (expand && !allowAside)
      ? false
      : props.aside !== undefined && asideProps !== undefined
  });

  appCtx.getDrawerWidth = () => drawerWidth;
  appCtx.getAsideWidth = () => asideWidth;
  appCtx.getMainWidth = () => mainWidth;
  appCtx.getMainOverlayWidth = () => mainWidth;

  appCtx.getFormContainerWidth = () => asideWidth;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        height: winHeight,
        width: winWidth
      }}
    >
      <AppWrapper
        content={props.drawer}
        width={drawerWidth}
      />

      {
        drawerWidth > 0
          ? <DividerVertical />
          : null
      }

      <AppMain
        {...props}
        expand={expand}
        mainWidth={mainWidth}
      />

      {
        asideWidth > 0
          ? <DividerVertical />
          : null
      }

      {
        props.aside &&
        <AppWrapper
          content={props.aside}
          width={asideWidth}
        />
      }

    </Box>
  );
}

export const minDrawerWidthDesktop = MIN_ASIDE_AND_DRAWER_WIDTH;
export const minAsideWidthDesktop = MIN_ASIDE_AND_DRAWER_WIDTH;

