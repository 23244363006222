import {useState} from "react";
import {useMemo} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {EnumDefnLayoutGridKind} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {getLayoutLabel} from "../form/builder/base/FieldBuilderPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const xyChart = "xyChart";

export default function DialogNewLayoutSpreadsheet(props: {
  onClickOk: (browserLayoutType: EnumDefnLayoutGridKind) => void,
  onClose?: () => void,
})
{
  const formRef = useMemo(() => ({} as IFormRef), []);
  const onClickOK = props.onClickOk;

  const [gridLayout, setGridLayout] = useState<string>("list");

  const defnForm = useMemo(() => getDefnForm(gridLayout), [gridLayout]);

  return (
    <DialogDefnForm
      formProps={{
        cbRef: formRef,
        defnForm: defnForm,
        onSubmit: values => onClickOK(valueToDto(values)),
        initValues: {
          [fieldBrowserLayoutType]: fnRawValueToFieldValue("pickText", "list")
        },
        onWatch: (key, value) =>
        {
          if(key === fieldBrowserLayoutType)
          {
            const val = fnFieldValueToRawValue("pickText", value) as string;
            if(val !== xyChart)
            {
              formRef.resetField(fieldXYChartType);
            }
            else
            {
              formRef.setValue(fieldXYChartType,
                fnRawValueToFieldValue("pickText", "xyChartBarGraph" as EnumDefnLayoutGridKind));
            }
            setGridLayout(val);
          }
        }
      }}
      title={"New spreadsheet layout"}
      onClose={props.onClose}
      contentHeight={510}
    />
  );
}

const fieldBrowserLayoutType = "spreadsheet layout type";
const fieldXYChartType = "xyChartType";

function getDefnForm(gridLayout?: string)
{
  const layoutOptions = [
    "card",
    "list",
    "map",
    "table",
    xyChart,
    "kanban",
    "calendar"
  ];
  const xyChartTypeOptions = [
    "xyChartBarGraph",
    "xyChartLineChart",
    "xyChartPieChart",
    "xyChartDoughnut",
    "xyChartScatterPlot"
  ] as EnumDefnLayoutGridKind[];

  const mapOfOption = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  xyChartTypeOptions.forEach(option =>
  {
    const value = getLayoutLabel(option);

    mapOfOption.keys.push(option);
    mapOfOption.map[option] = {
      metaId: option,
      value: value
    };
  });

  const isLayoutXY = gridLayout === xyChart;

  return createDefaultDefnFormStudio({
    [fieldBrowserLayoutType]: {
      type: "pickText",
      metaId: fieldBrowserLayoutType,
      name: fieldBrowserLayoutType,
      optionMap: arrayToMapOfOption(layoutOptions, false),
      showAs: "radioButtonVertical",
      hideDirtyIndicator: true,
      pageSize: 7
    } as DefnFieldPickText,

    [fieldXYChartType]: {
      type: "pickText",
      metaId: fieldXYChartType,
      name: fieldXYChartType,
      optionMap: mapOfOption,
      required: isLayoutXY
    } as DefnFieldPickText,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [
        fieldBrowserLayoutType,
        ...isLayoutXY
          ? [fieldXYChartType]
          : []
      ]
    } as DefnSection
  });
}

function valueToDto(values: FieldValues): EnumDefnLayoutGridKind
{
  let gridLayout = fnFieldValueToRawValue("pickText", values[fieldBrowserLayoutType]);

  if(gridLayout === xyChart)
  {
    gridLayout = fnFieldValueToRawValue("pickText", values[fieldXYChartType]);
  }

  return gridLayout as EnumDefnLayoutGridKind;
}
