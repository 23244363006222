import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {dispatchListItemStudio} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {listSetIfExistSecondaryLineBadge} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetVersion} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {listClearPickItemIds} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinder} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {ActionUpdateStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {clearStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {updateStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigAvatarEnt = SigStudioEntAvatar | undefined | SigStudioPluginAvatar;
export type FnAcceptFilter = (filter?: EntId[]) => void;

export default class SrvcStudioDrawerFilter extends ISrvc
{
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSigAvatarEnt.bind(this),
      onBindSourceItem1: this.onBindSigAvatarEnt.bind(this)
    } as IListBinder<TypeSigAvatarEnt>;
  }

  load(listName: string, srcEntIdSet: EntId[], srcPluginIdSet: PluginBundleId[])
  {
    const listData = this.createListData(srcEntIdSet, srcPluginIdSet);

    Promise
    .resolve(dispatchList(listName, listRefresh(listData)))
    .then(() =>
    {
      dispatchList(listName, listSetVersion(random()));
    });
  }

  setFilter(listName: string, itemId: string, pickValue: boolean)
  {
    dispatchList(listName, listSetSelectedItemId(undefined));

    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    store.dispatch(updateStudioFilter({
      callerIdHash: callerIdHash,
      artifactId: itemId,
      pickValue: pickValue
    } as ActionUpdateStudioFilter));
  }

  clearFilter(listName: string)
  {
    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    Promise
    .resolve(dispatchList(listName, listClearPickItemIds()))
    .then(() =>
    {
      store.dispatch(clearStudioFilter(callerIdHash));
    });
  }

  filter(fn: FnAcceptFilter)
  {
    fn(store.getState().cache.studio.filter.artifactIdSet);
  }

  subscribe(subscriberId: TypeSubscriberId, artifactId: ArtifactId, unSubscribe?: boolean)
  {
    if(isEntId(artifactId))
    {
      Srvc.app.pubsub.caller.entAvatarAdmin(subscriberId, artifactId, unSubscribe);
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
    else if(isPluginId(artifactId))
    {
      Srvc.app.pubsub.caller.pluginAvatarAdmin(subscriberId, artifactId, unSubscribe);
      Srvc.app.pubsub.studio.studioAdminEditLock(subscriberId, artifactId, unSubscribe);
    }
  }

  protected createListData(srcEntIdAdminSet: EntId[], srcPluginIdAdminSet: PluginBundleId[]): IListData
  {
    return {
      itemIds: [...srcEntIdAdminSet, ...srcPluginIdAdminSet],
      itemsById: {}
    } as IListData;
  }

  private selectSigAvatarEnt(state: RootState, itemId: TypeListItemId): TypeSigAvatarEnt
  {
    if(isEntId(itemId))
    {
      return state.cache.app.caller.entIdAdminAvatarMap[itemId];
    }
    else
    {
      return state.cache.app.caller.pluginIdAdminAvatarMap[itemId];
    }
  }

  private onBindSigAvatarEnt(
    listName: string,
    itemId: TypeListItemId,
    avatar?: TypeSigAvatarEnt): void
  {
    const rootState = store.getState();
    const sig = this.selectSigAvatarEnt(rootState, itemId);
    const errorCount = sig?.errorCount;

    Promise
    .resolve(isEntId(itemId)
      ? dispatchListItemStudio(listName, itemId, avatar, false, true)
      : dispatchListItemStudio(listName, itemId, avatar))
    .then(() => dispatchList(listName,
      listSetIfExistSecondaryLineBadge({
        itemId: itemId,
        value: errorCount
      })
    ));

    const filter = rootState.cache.studio.filter.artifactIdSet;

    dispatchList(listName, listSetPick({
      itemId: itemId,
      pickValue: Boolean(filter && filter.includes(itemId))
    }));
  }

}
