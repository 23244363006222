import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {IToastState} from "./TypesToast";

export const sliceToast = createSlice({
  name: "toast",
  initialState: {
    toastSeverity: "error"
  } as IToastState,
  reducers: {
    clearToast: (state) =>
    {
      state.toastMessage = undefined;
      state.toastSeverity = "error";
    },
    setToast: (state, action: PayloadAction<IToastState>) =>
    {
      state.toastMessage = action.payload.toastMessage;
      state.toastSeverity = action.payload.toastSeverity;
    }
  }
});

export const {
  clearToast,
  setToast
} = sliceToast.actions;
