import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconPicker from "../../../atom/raw/RawIconPicker";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldPickIcon(props: {
  defn: DefnFieldEditable
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);

  const fieldVariant = defnTheme.fieldVariant;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const padding = getDefnFieldPadding(defn);

  const {
    getFieldRequired,
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const helperText = getFieldHelperText();
  const icon = getFieldIcon();

  return (
    <Controller
      name={fieldId ?? "chip"}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as FieldValueText;
        const fieldSize = defnTheme.fieldSize;
        const hiddenLabel = defn.hideLabel;

        const onClick = (value?: string) =>
        {
          if(value)
          {
            field.onChange({
              value: value
            } as FieldValueText);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
              >
                <RawIconPicker
                  defn={defn}
                  fieldSize={fieldSize}
                  value={fieldValue?.value}
                  onClick={onClick}
                  label={label}
                  placeHolder={placeHolder}
                  helperText={helperText}
                  error={error}
                  required={required}
                  icon={icon}
                  autoFocus={defn.autoFocus}
                  hiddenLabel={hiddenLabel}
                  name={defn.name}
                  readonly={readOnly}
                  disabled={disabled}
                />
                <FieldRawRefButton defn={defn} />
              </LayoutFlexRow>
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
