import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfArgBinder} from "../../../../api/meta/base/dto/DefnStudioMapOfArgBinder";
import {StudioBuildArgBinder} from "../../../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioMapOfArgBinder} from "../../../../api/meta/base/dto/StudioMapOfArgBinder";
import {MetaIdAction} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnResolveArgBinderValue} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import {IStudioMapOfArgBinder} from "../../../dialog/DialogStudioMapOfArgBinder";
import DialogStudioMapOfArgBinder from "../../../dialog/DialogStudioMapOfArgBinder";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfArgBinder(props: {
  defn: DefnStudioMapOfArgBinder
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();

  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const label = defn.label;

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfArgBinder;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfArgBinder
                  fieldId={fieldId}
                  formStore={formStore}
                  isFormReadOnly={formReadOnly}
                  onChange={onChange}
                  fieldValue={fieldValue}
                  defn={defn}
                />
              }
            />
          );
        }

        return (
          <RawSetOfArgBinder
            fieldId={fieldId}
            formStore={formStore}
            isFormReadOnly={formReadOnly}
            onChange={onChange}
            fieldValue={fieldValue}
            defn={defn}
          />
        );
      }
      }
    />
  );
}

function RawSetOfArgBinder(props: {
  defn: DefnStudioMapOfArgBinder,
  fieldId: MetaIdField,
  fieldValue: StudioMapOfArgBinder,
  isFormReadOnly?: boolean,
  formStore?: FormStore,
  onChange: (value: StudioMapOfArgBinder) => void,
})
{
  const {
    fieldId,
    formStore,
    isFormReadOnly,
    defn
  } = props;

  const formId = defn.formId;
  const label = getCompLabel(defn);

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<IStudioMapOfArgBinder>;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);

  const fnResolver = formStore
    ? fnUseStudioResolver(formStore)
    : undefined;

  const onChange = (fieldValue: IStudioMapOfArgBinder[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = (selectList: SelectList, fieldValue: IStudioMapOfArgBinder[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.key;
        const name = value.key;
        const query = value.value;

        uiItemIds.push(listId);

        const resolvedQuery = fnResolver && query
          ? fnResolveArgBinderValue(fnResolver, query, formId)
          : "No query";

        uiItemsById[listId] = {
          type: "mpsl",
          primary: {
            text: name
          },
          secondaryList: [
            {
              text: resolvedQuery
            }
          ],
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemMPSL;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogStudioMapOfArgBinder
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) =>
          {
            cbRefList.addItem(newValue);
          }}
          formId={formId}
          label={label}
        />
      );
    }
  };

  const onClickList = (
    key: MetaIdField,
    action: FormClickVariant,
    value?: any) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as IStudioMapOfArgBinder
        : undefined;

      pageCtx.showDialog(
        <DialogStudioMapOfArgBinder
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) =>
          {
            cbRefList.addItem(newValue);
          }}
          formId={formId}
          label={label}
          values={oldValue}
        />
      );
    }
  };

  return (
    <FieldRawList
      isLastField={true}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(map?: StudioMapOfArgBinder): IStudioMapOfArgBinder[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return {
      key: key,
      value: map.map[key]
    };
  });
}

function convertArrayToMap(arr: IStudioMapOfArgBinder[]): StudioMapOfArgBinder
{
  const keys = [] as MetaIdAction[];
  const map = {} as Record<MetaIdAction, StudioBuildArgBinder>;

  arr.forEach((value) =>
  {
    const key = value.key;
    keys.push(key);
    map[key] = {
      ...value.value,
      argName: key
    };
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfArgBinder;
}
