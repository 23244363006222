import {DoneAllRounded} from "@mui/icons-material";
import {DoneRounded} from "@mui/icons-material";
import {SendRounded} from "@mui/icons-material";
import {CancelScheduleSendRounded} from "@mui/icons-material";
import {useTheme} from "@mui/material";
import React from "react";
import {textIconSx} from "../../../base/plus/ThemePlus";
import {EnumIconListItemSecondary1} from "../../../base/types/TypesIcon";

export default function IconListItemSecondary1(props: {
  value?: EnumIconListItemSecondary1
})
{
  const theme = useTheme();

  const value = props.value;
  if(!value)
  {
    return null;
  }

  switch(value)
  {
    case "error":
      return <CancelScheduleSendRounded sx={textIconSx()} />;
    case "send":
      return <SendRounded sx={textIconSx()} />;
    case "singleTick":
      return <DoneRounded sx={textIconSx()} />;
    case "doubleTick":
      return <DoneAllRounded sx={textIconSx()} />;
    case "blueTick":
      return <DoneAllRounded sx={textIconSx(theme.palette.primary.light)} />;
  }

  return <p>Icon missing ${value}</p>;
}
