import styled from "@emotion/styled";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Property} from "csstype";
import {isEmpty} from "lodash";
import React from "react";
import {CSSProperties} from "react";
import {Carousel} from "react-responsive-carousel";
import {px} from "../../../base/plus/StringPlus";
import theme from "../../../base/plus/ThemePlus";
import {ListLayoutType} from "../../../base/types/list/TypesList";
import {IMedia} from "../../../base/types/list/TypesListAPSA";
import BubbleRawMediaAudio from "../bubble/raw/bubbleForm/BubbleRawMediaAudio";
import BubbleRawMediaHtml from "../bubble/raw/bubbleForm/BubbleRawMediaHtml";
import BubbleRawMediaHyperlink from "../bubble/raw/bubbleForm/BubbleRawMediaHyperlink";
import BubbleRawMediaImage from "../bubble/raw/bubbleForm/BubbleRawMediaImage";
import BubbleRawMediaVideo from "../bubble/raw/bubbleForm/BubbleRawMediaVideo";
import {IBubbleRawMediaCarouselItem} from "../bubble/raw/BubbleRawMediaCarousel";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import {LayoutGap} from "../layout/LayoutGap";
import RawLazyImage from "../raw/RawLazyImage";
import RawSkeleton from "../raw/RawSkeleton";

export function BoxM(props: {
  media?: IMedia[],
  showMediaPlaceholder?: boolean,
  width?: Property.Width,
  height?: Property.Height,
  cursor?: Property.Cursor,
  listLayoutType?: ListLayoutType,
})
{
  const listLayoutType = props.listLayoutType;
  const theme = useTheme();
  const gapStd = theme.common.gapStd;
  const imgBorderRadius = theme.common.gapHalf - theme.common.bubbleShellGap;

  return (
    <>
      {
        !props.media && props.showMediaPlaceholder && (
          <>
            <Box
              sx={{
                width: props.width,
                height: props.height,
                borderRadius: px(imgBorderRadius),
                cursor: props.cursor
              }}
              flexShrink={1}
            >
              <RawSkeleton
                borderRadius={px(imgBorderRadius)}
                variant={"rectangular"}
                width={"100%"}
                height={"100%"}
              />
            </Box>
            <LayoutGap width={px(gapStd)} />
          </>
        )}

      {
        props.media && !isEmpty(props.media) && (
          <>
            <Box
              borderRadius={px(imgBorderRadius)}
              flexShrink={1}
              width={"100%"}
            >
              {props.media.length > 1 ? (
                <LayoutFlexRow
                  onClick={(e) => e.stopPropagation()}
                  overflowX={"visible"}
                  overflowY={"visible"}
                  alignItems={"start"}
                >
                  <StyledCarouselComponent
                    media={props.media}
                    width={props.width}
                    height={props.height}
                    listLayoutType={listLayoutType}
                    imgBorderRadius={imgBorderRadius}
                  />
                </LayoutFlexRow>
              ) : (
                <RawLazyImage
                  src={props.media[0].src}
                  srcBlur={props.media[0].srcBlur}
                  width={props.width}
                  height={props.height}
                  objectFit={"cover"}
                  primaryColor={props.media[0].primaryColor}
                  {...(listLayoutType === "card"
                    ? {
                      borderTopLeftRadius: px(imgBorderRadius),
                      borderTopRightRadius: px(imgBorderRadius)
                    }
                    : {
                      borderRadius: px(imgBorderRadius)
                    })}
                />
              )}

            </Box>
            <LayoutGap width={px(gapStd)} />
          </>
        )}
    </>
  );
}

const indicatorStyles: CSSProperties = {
  width: 7,
  height: 7,
  display: "inline-block",
  margin: "0 6px",
  borderRadius: "50%",
  cursor: "pointer",
  zIndex: 1
};

function StyledCarouselComponent(props: {
  media: IMedia[],
  width?: Property.Width,
  height?: Property.Height,
  listLayoutType?: ListLayoutType,
  imgBorderRadius?: number
})
{
  return (
    <StyledCarousel
      dynamicHeight={false}
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      emulateTouch={true}
      infiniteLoop={false}
      onSwipeStart={(e) =>
      {
        e.stopPropagation();
        return false;
      }}
      onSwipeMove={(e) =>
      {
        e.stopPropagation();
        return false;
      }}
      animationHandler={"slide"}
      width={props.width}
      renderIndicator={(onClickHandler, isSelected, index, label) =>
      {
        return (
          <li
            style={{
              ...indicatorStyles,
              background: isSelected
                ? theme.common.color("textPrimary")
                : theme.common.color("textDisabled")
            }}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      {
        props.media.map((media) => (
          <BoxMediaFactory
            media={media}
            width={props.width}
            height={props.height}
            {...(props.listLayoutType === "card"
              ? {
                borderTopLeftRadius: px(props.imgBorderRadius),
                borderTopRightRadius: px(props.imgBorderRadius)
              }
              : {
                borderRadius: px(props.imgBorderRadius)
              })}
          />
        ))
      }
    </StyledCarousel>
  );
}

const StyledCarousel = styled(Carousel)`
  .carousel-slider
  {
    overflow: visible !important;
    display: flex;
    justify-content: center;
  }

  .thumbs-wrapper
  {
    display: block;
    text-align: center;
  }

  .thumbs
  {
    display: inline-block;
  }

  .control-dots
  {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 12px !important;
    width: auto !important;
    position: absolute !important;
    overflow: visible !important;
    margin-right: 0 !important;
    display: flex;
    align-items: center;
    height: 18px !important;
    bottom: -5px !important;
  }
`;

function BoxMediaFactory(props: {
  media: IMedia,
  width?: Property.Width,
  height?: Property.Height,
  onClick?: (media: IBubbleRawMediaCarouselItem) => void,
  borderRadius?: Property.BorderRadius,
  borderTopLeftRadius?: Property.BorderTopLeftRadius,
  borderTopRightRadius?: Property.BorderTopRightRadius
})
{
  const media = props.media;

  switch(media.type)
  {
    case "image":
      return <BubbleRawMediaImage
        src={media?.src}
        width={props.width}
        height={props.height}
        blurSrc={media?.srcBlur}
        primaryColor={media?.primaryColor}
        borderRadius={props.borderRadius}
        borderTopLeftRadius={props.borderTopLeftRadius}
        borderTopRightRadius={props.borderTopLeftRadius}
      />;
    case "audio":
    case "voice":
      return <BubbleRawMediaAudio
        src={media.src}
        width={props.width}
        height={props.height}
        type={media.type}
      />;
    case "video":
      return <BubbleRawMediaVideo
        src={media.src}
        srcImage={media.srcBlur}
        width={props.width}
        height={props.height}
        blurImageSrc={media.srcBlur}
        primaryColor={media.primaryColor}
      />;
    case "html":
      return <BubbleRawMediaHtml
        value={media.src}
        width={props.width}
        height={props.height}
      />;
    case "hyperlink":
      return <BubbleRawMediaHyperlink
        width={props.width}
        height={props.height}
        value={media.src}
      />;
  }
}
