import {Box} from "@mui/material";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {useAppCtx} from "../../ctx/CtxApp";
import {TypeOverlayTransition} from "./PaneOverlay";
import PaneOverlay from "./PaneOverlay";

export interface IPaneOverlayStackRef
{
  showOverlay(content: React.ReactNode): void;

  closeOverlay(instant?: boolean): void;
}

export default function PaneOverlayStack(props: {
  cbRef?: IPaneOverlayStackRef,
  width: number,
  transition?: TypeOverlayTransition,
  children: React.ReactNode
})
{
  const appCtx = useAppCtx();
  const [overlay, setOverlay] = useState<React.ReactNode>();
  const [overlayVisible, setOverlayVisible] = useState(false);

  const refShowOverlay = useCallback((overlay?: React.ReactNode) =>
  {
    setOverlay(overlay);
    setOverlayVisible(true);
  }, []);

  const refCloseOverlay = useCallback((instant: boolean) =>
  {
    if(instant)
    {
      setOverlay(undefined);
    }
    else
    {
      setOverlayVisible(false);
    }
  }, []);

  const cbRef = props.cbRef;
  if(cbRef)
  {
    cbRef.showOverlay = refShowOverlay;
    cbRef.closeOverlay = refCloseOverlay;
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
        height: "100%"
      }}
    >

      {props.children}

      {
        overlay
          ? (<PaneOverlay
            overlay={overlay}
            overlayVisible={overlayVisible}
            width={props.width}
            transition={props.transition ?? appCtx.isMobile() ? "back" : "forward"}
            onInvisible={() => setOverlay(undefined)}
          />)
          : null
      }

    </Box>
  );
}
