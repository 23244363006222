import {nextMetaIdField, nextMetaIdForm, nextMetaIdGrid, nextMetaIdSection} from "../../../../api/meta/base/ApiPlus";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioField} from "../../../../api/meta/base/dto/StudioField";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {MetaIdComposite, MetaIdField, MetaIdForm, MetaIdSection, PluginBundleId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {selectCacheStudioPluginBundle} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchPlugin} from "../../SrvcCacheStudio";
import {ActionMovePluginFormItem, addPluginForm, addPluginFormComposite, addPluginFormField, movePluginFormItem, movePluginItem, removePluginForm, removePluginFormComposite, removePluginFormField} from "../SliceCacheStudioPlugin";

export default class SrvcCacheStudioPluginForms extends ISrvc
{
  //region Form
  addPluginForm(pluginBundleId: PluginBundleId, form: StudioForm)
  {
    dispatchPlugin(pluginBundleId, addPluginForm({
      pluginBundleId: pluginBundleId,
      sig: {
        ...form,
        metaId: form.metaId || nextMetaIdForm()
      }
    }));
  }

  removePluginForm(pluginBundleId: PluginBundleId, formId: MetaIdForm)
  {
    dispatchPlugin(pluginBundleId, removePluginForm({
      pluginBundleId: pluginBundleId,
      metaId: formId
    }));
  }

  duplicatePluginForm(pluginBundleId: PluginBundleId, formId: MetaIdForm)
  {
    const selectedVersion = selectCacheStudioPluginBundle(store.getState(),
      pluginBundleId
    )?.draft?.studioPlugin;

    if(selectedVersion)
    {
      const pluginFormMap = selectedVersion.formMap;
      if(!pluginFormMap)
      {
        return;
      }

      const index = pluginFormMap.keys.indexOf(formId);
      if(index === -1)
      {
        return;
      }

      const form = pluginFormMap.map[formId]
        ? JSON.parse(updateAllMetaIds(JSON.stringify(pluginFormMap.map[formId]))) :
        undefined;

      dispatchPlugin(pluginBundleId, addPluginForm({
        pluginBundleId: pluginBundleId,
        sig: form,
        insertIndex: index + 1
      }));
    }
  }

  //endregion

  //region Section

  addPluginFormComposite(
    pluginBundleId: PluginBundleId,
    formId: MetaIdForm,
    studioComposite: StudioComposite
  )
  {
    if(studioComposite.type === "section" && !(studioComposite as StudioSection).metaId)
    {
      (studioComposite as StudioSection).metaId = nextMetaIdSection();
    }
    else if(studioComposite.type === "grid" && !(studioComposite as StudioGrid).metaId)
    {
      (studioComposite as StudioGrid).metaId = nextMetaIdGrid();
    }

    dispatchPlugin(pluginBundleId, addPluginFormComposite({
      pluginBundleId: pluginBundleId,
      formId: formId,
      sig: studioComposite
    }));
  }

  removePluginFormComposite(
    pluginBundleId: PluginBundleId,
    metaIdForm: MetaIdForm,
    metaIdComposite: MetaIdComposite)
  {
    dispatchPlugin(pluginBundleId, removePluginFormComposite({
      pluginBundleId: pluginBundleId,
      formId: metaIdForm,
      metaId: metaIdComposite
    }));
  }

  duplicatePluginFormComposite(
    pluginBundleId: PluginBundleId,
    metaIdForm: MetaIdForm,
    metaIdComposite: MetaIdComposite)
  {
    const selectedVersion = selectCacheStudioPluginBundle(store.getState(),
      pluginBundleId
    )?.draft?.studioPlugin;

    if(selectedVersion)
    {
      const pluginFormMap = selectedVersion.formMap;
      if(!pluginFormMap)
      {
        return;
      }

      const form = pluginFormMap.map[metaIdForm];
      const composite = form.compositeMap.map[metaIdComposite];

      const index = form.compositeMap.keys.indexOf(metaIdComposite);
      if(index === -1)
      {
        return;
      }

      const pluginComposite = composite
        ? JSON.parse(updateAllMetaIds(JSON.stringify(composite)))
        : undefined;

      dispatchPlugin(pluginBundleId, addPluginFormComposite({
        pluginBundleId: pluginBundleId,
        formId: metaIdForm,
        sig: pluginComposite,
        insertIndex: index + 1
      }));
    }
  }

  //endregion

  //region Field

  addPluginFormField(
    pluginBundleId: PluginBundleId,
    metaIdForm: MetaIdForm,
    compositeId: MetaIdComposite,
    studioField: StudioField
  )
  {
    if(!studioField.metaId)
    {
      studioField.metaId = nextMetaIdField();
    }
    dispatchPlugin(pluginBundleId, addPluginFormField({
      pluginBundleId: pluginBundleId,
      formId: metaIdForm,
      parentCompositeId: compositeId,
      sig: studioField
    }));
  }

  removePluginFormField(
    pluginBundleId: PluginBundleId,
    metaIdForm: MetaIdForm,
    metaIdSection: MetaIdField,
    metaIdField: MetaIdField
  )
  {
    dispatchPlugin(pluginBundleId, removePluginFormField({
      pluginBundleId: pluginBundleId,
      formId: metaIdForm,
      sectionId: metaIdSection,
      metaId: metaIdField
    }));
  }

  duplicatePluginFormField(
    pluginBundleId: PluginBundleId,
    formId: MetaIdForm,
    compositeId: MetaIdComposite,
    fieldId: MetaIdField)
  {
    const selectedVersion = selectCacheStudioPluginBundle(store.getState(),
      pluginBundleId
    )?.draft?.studioPlugin;

    if(selectedVersion)
    {
      const pluginFormMap = selectedVersion.formMap;
      if(!pluginFormMap)
      {
        return;
      }

      const form = pluginFormMap.map[formId];

      const composite = form.compositeMap.map[compositeId];

      const index = composite?.fieldMap.keys.indexOf(fieldId);
      if(index === -1)
      {
        return;
      }

      const field = composite?.fieldMap?.map[fieldId];

      const pluginField = field
        ? JSON.parse(updateAllMetaIds(JSON.stringify(field)))
        : undefined;

      dispatchPlugin(pluginBundleId, addPluginFormField({
        pluginBundleId: pluginBundleId,
        formId: formId,
        parentCompositeId: compositeId,
        sig: pluginField,
        insertIndex: index + 1
      }));
    }
  }

  // endregion

  moveItem(
    pluginBundleId: PluginBundleId,
    metaId: MetaIdForm,
    moveDirection: EnumListItemDirection)
  {
    dispatchPlugin(pluginBundleId, movePluginItem({
      pluginBundleId,
      metaId,
      setKey: "formList",
      moveDirection
    }));
  }

  movePluginFormItem(
    pluginBundleId: PluginBundleId,
    formId: MetaIdForm,
    compositeId: MetaIdComposite,
    moveDirection: EnumListItemDirection,
    headerFooterId?: MetaIdSection,
    fieldId?: MetaIdField)
  {
    dispatchPlugin(pluginBundleId, movePluginFormItem({
      pluginBundleId,
      formId,
      fieldId,
      moveDirection,
      compositeId,
      headerFooterId
    } as ActionMovePluginFormItem));
  }
}
