import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {Property} from "csstype";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {DividerHorizontalLight} from "../layout/DividerHorizontalLight";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import {BoxMenuButton} from "./BoxMenuButton";
import {BoxPick} from "./BoxPick";

export function BoxShellChat(props: {
  pickMode: boolean,
  showCheckBoxOnlyOnHover: boolean,
  pickValue?: boolean,
  selected?: boolean,
  ignoreSelection?: boolean,
  hideMenu?: boolean,
  showMenu?: boolean,
  bgcolor?: CssBackgroundColor,
  cursorPointer?: boolean,
  justifyContent?: Property.JustifyContent,
  alignItems?: Property.AlignItems,
  onClick?: (menuAnchor: Element, pickValue?: boolean) => void,
  onHover?: (isHover: boolean) => void,
  onClickMenu?: CbMenuAnchor,
  pt?: StandardCSSProperties["paddingTop"],
  pb?: StandardCSSProperties["paddingBottom"],
  children: React.ReactNode,
  reactionNode?: React.ReactNode
})
{
  const theme = useTheme();
  const [bgcolor, setBgcolor] = useState(props.bgcolor);
  const [hover, setHover] = useState<boolean>();

  const pickMode = props.pickMode;
  const pickValue = props.pickValue;
  const gapStd = theme.common.gapStd;
  const gapQuarter = theme.common.gapQuarter;

  const reactionNode = props.reactionNode;

  const onClick = props.onClick;
  const onHover = props.onHover;
  const onClickMenu = props.onClickMenu;
  const isSpotMenu = Boolean(!props.hideMenu || onClickMenu !== undefined);
  const showMenu = props.showMenu;
  const bgcolorHoverSelection = theme.common.bgcolorHoverSelection;
  const bgcolorSelection = theme.common.bgcolorSelection;
  const bgcolorHover = theme.common.bgcolorHover;

  useEffect(() =>
  {
    setBgcolor(hover
      ? bgcolorHover
      : props.bgcolor);
  }, [props.selected, hover, bgcolorSelection, bgcolorHoverSelection, bgcolorHover]);

  return (

    <LayoutFlexRow
      width={"100%"}
      height={"auto"}
      pb={props.pb ? props.pb : undefined}
      pt={props.pt ? props.pt : undefined}
    >
      <LayoutFlexCol
        bgcolor={props.ignoreSelection ? undefined : bgcolor}
        width={"100%"}
        overflowY={"visible"}
        maxWidth={px(1200)}
        onClick={onClick
          ? (e: React.MouseEvent<HTMLElement>) =>
            onClick && onClick(e.currentTarget, pickMode ? !pickValue : undefined)
          : undefined}
        cursorPointer={props.cursorPointer}
        onMouseEnter={() =>
        {
          if(!props.ignoreSelection)
          {
            setHover(true);
          }
          onHover && onHover(true);
        }}
        onMouseLeave={() =>
        {
          if(!props.ignoreSelection)
          {
            setHover(false);
          }
          onHover && onHover(false);
        }}
        justifyContent={props.justifyContent || "end"}
      >
        <LayoutFlexRow
          pl={!pickMode ? px(gapStd) : undefined}
          pr={!isSpotMenu ? px(gapStd) : undefined}
          width={"100%"}
          justifyContent={props.justifyContent || "end"}
        >
          {/* list pick check box  */}
          {
            pickMode &&
            <Box
              sx={{
                visibility: (hover || !props.showCheckBoxOnlyOnHover)
                  ? "visible"
                  : "hidden"
              }}
            >
              <BoxPick
                pickType={"pickMany"}
                pickValue={pickValue}
                ml={gapQuarter}
                mr={gapQuarter}
              />
            </Box>
          }

          <Box
            height={"auto"}
            width={isSpotMenu ? "100%" : undefined}
            display={"flex"}
            alignItems={props.alignItems}
            flexDirection={"column"}
            justifyContent={props.justifyContent || "end"}
            overflow={"visible"}
            position={"relative"}
          >
            <LayoutFlexRow
              height={"auto"}
              alignItems={"end"}
              width={"100%"}
              justifyContent={"space-between"}
              overflowY={"visible"}
              overflowX={"visible"}
            >
              {props.children}

              {/* list spot menu */}
              {
                !props.hideMenu && onClickMenu &&
                <LayoutFlexCol
                  width={px(gapStd)}
                  height={"100%"}
                >
                  <BoxMenuButton onClick={onClickMenu} />
                </LayoutFlexCol>
              }
            </LayoutFlexRow>

            {reactionNode}
          </Box>
        </LayoutFlexRow>

        {
          showMenu &&
          <DividerHorizontalLight />
        }
      </LayoutFlexCol>
    </LayoutFlexRow>
  );
}
