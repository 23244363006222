import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioSetOfRowAuditTrail} from "../../../../api/meta/base/dto/DefnStudioSetOfRowAuditTrail";
import {EnumArrayDefnRowAuditTrail} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {arrayToOptions} from "../../../../base/plus/JsPlus";
import {getSortedOptionByLabel} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioSetOfRowAuditTrail(props: {
  defn: DefnStudioSetOfRowAuditTrail
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={optionsToMapOfOption(getSortedOptionByLabel(arrayToOptions(EnumArrayDefnRowAuditTrail)))}
        multiSelect={true}
        showChip={true}
      />}
    />
  );
}
