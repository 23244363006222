// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgDeeplinkCode} from './msg/MsgDeeplinkCode';
import {ServiceName} from '../../meta/base/Types';
import {SigCallback} from '../../nucleus/base/sig/SigCallback';
import {SigDeeplinkData} from './sig/SigDeeplinkData';
import {SigDeeplinkPreview} from './sig/SigDeeplinkPreview';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcDeeplink
{
  static readonly SN: ServiceName = "deeplink";

  static deeplinkAction(msg: MsgDeeplinkCode, sigAcceptor: ISigAcceptor<SigCallback>): void
  {
    rpcCall<SigCallback>(ENT_ID_GLOBAL, RpcDeeplink.SN, "deeplinkAction")
      .sendRefreshToken()
      .post(msg, sigAcceptor);
  }

  static deeplinkDataGet(msg: MsgDeeplinkCode, sigAcceptor: ISigAcceptor<SigDeeplinkData>): void
  {
    rpcCall<SigDeeplinkData>(ENT_ID_GLOBAL, RpcDeeplink.SN, "deeplinkDataGet")
      .sendRefreshToken()
      .get(msg, sigAcceptor);
  }

  static deeplinkPreviewGet(msg: MsgDeeplinkCode, sigAcceptor: ISigAcceptor<SigDeeplinkPreview>): void
  {
    rpcCall<SigDeeplinkPreview>(ENT_ID_GLOBAL, RpcDeeplink.SN, "deeplinkPreviewGet")
      .get(msg, sigAcceptor);
  }
}