import {SelectChangeEvent} from "@mui/material";
import {useTheme} from "@mui/material";
import {Select} from "@mui/material";
import {InputLabel} from "@mui/material";
import {FormControl} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useCallback} from "react";
import React from "react";
import {StudioFormMap} from "../../../../api/meta/base/dto/StudioFormMap";
import {px} from "../../../../base/plus/StringPlus";
import {IMainPropsStudioFormBuilder} from "../../../../base/types/TypesMain";
import {IMainPropsStudio} from "../../../../base/types/TypesMain";
import {IStudioSize} from "../../../../base/types/TypesStudio";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useAppCtx} from "../../../ctx/CtxApp";

export default function FormBuilderPickForm(props: {
  studioSize: IStudioSize,
  formMap: StudioFormMap
})
{
  const appCtx = useAppCtx();
  const theme = useTheme();
  const gapStd = theme.common.gapStd;
  const gapHalf = theme.common.gapHalf;
  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const formId = mainProps.formId;
  const formMap = props.formMap;

  const onChangeForm = useCallback((e: SelectChangeEvent) =>
  {
    const metaIdForm = e.target.value;
    appCtx.setMainProps({
      ...mainProps,
      jumpStep: "Forms",
      jumpStepItem: "FormDetails",
      formId: metaIdForm
    } as IMainPropsStudioFormBuilder);

    appCtx.setAsideProps(undefined);

  }, [appCtx, mainProps]);

  const onClickBack = useCallback(() =>
  {
    appCtx.setMainProps({
      ...mainProps,
      jumpStep: "Forms",
      jumpStepItem: undefined
    } as IMainPropsStudio);

    appCtx.setAsideProps(undefined);

  }, [appCtx, mainProps]);
  return (
    <LayoutFlexRow
      width={"100%"}
      height={px(theme.common.heightFooter + gapHalf)}
      justifyContent={"space-between"}
    >
      <LayoutFlexRow
        justifyContent={"flex-start"}
        pl={px(gapHalf)}
        overflowY={"visible"}
        overflowX={"visible"}
      >
        <RawIconStrip
          iconStrip={["backIos"]}
          onClick={onClickBack}
        />
      </LayoutFlexRow>
      <LayoutFlexRow
        padding={px(gapStd)}
        flexGrow={1}
      >
        <FormControl fullWidth>
          <InputLabel id="studio-form-select">Select form</InputLabel>
          <Select
            labelId="studio-form-select"
            label="Select form"
            value={formId}
            onChange={onChangeForm}
            size={"small"}
          >
            {formMap?.keys?.map(formId =>
            {
              const form = formMap?.map[formId];
              return (
                <MenuItem
                  value={form?.metaId}
                  key={form?.metaId}
                >
                  {form?.details.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </LayoutFlexRow>

    </LayoutFlexRow>
  );
}
