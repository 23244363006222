import {isEmpty} from "lodash";
import {SigTopic} from "../../api/core/session/sig/SigTopic";
import {SigEntAvatarUser} from "../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {SigGroupAvatar} from "../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../api/home/drawer/sig/SigUserAvatar";
import {isTemplateId} from "../../api/meta/base/ApiPlus";
import {isPluginId} from "../../api/meta/base/ApiPlus";
import {isEntId} from "../../api/meta/base/ApiPlus";
import {ENT_ID_GLOBAL} from "../../api/meta/base/ApiPlus";
import {EnumDefnStoreItem} from "../../api/meta/base/Types";
import {EnumDefnPluginMode} from "../../api/meta/base/Types";
import {EntUserId} from "../../api/meta/base/Types";
import {EnumStoreItemArtifact} from "../../api/meta/base/Types";
import {MediaIdAvatar} from "../../api/meta/base/Types";
import {SigStoreItem} from "../../api/store/store/sig/SigStoreItem";
import {SigStoreItemAvatar} from "../../api/store/store/sig/SigStoreItemAvatar";
import {SigStudioEntAvatar} from "../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import {ICallerEnt} from "../../cache/app/callerEnt/TypesCacheCallerEnt";
import {store} from "../../Store";
import {listSetUserFieldVar} from "../slices/list/SliceListSharedActions";
import {listClearItem} from "../slices/list/SliceListSharedActions";
import {listSetItem} from "../slices/list/SliceListSharedActions";
import {TypeListItemId} from "../types/list/TypesList";
import {IListItemAPSA} from "../types/list/TypesListAPSA";
import {TypeArtifact} from "../types/TypesForm";
import {IServerPushListTopic} from "../types/TypesGlobal";
import {TypeTooltip} from "../types/TypesGlobal";
import {STR_NO_ABOUT} from "./ConstantsPlus";
import {STR_ADMIN} from "./ConstantsPlus";
import {toDateFromDetailedDateTime} from "./DatePlus";
import {formatCaptionDateTime} from "./DatePlus";
import {selfRole} from "./FormPlus";
import {insertManagerialRelationship} from "./FormPlus";
import {dispatchList} from "./ListPlus";
import {listName} from "./ListPlus";
import {SelectList} from "./ListPlus";
import {getImageThumbnail} from "./MediaPlus";
import {getLabel} from "./StringPlus";
import {toLabel} from "./StringPlus";
import {random} from "./StringPlus";
import {hasValue} from "./StringPlus";
import {hasEditLockPlugin} from "./StudioPlus";
import {hasEditLockEnt} from "./StudioPlus";

export function getPasteFailMsg(sourceType: string, targetType: string)
{
  return toLabel(sourceType) + " can't be added in " + toLabel(targetType);
}

export function dispatchListItemGroup(
  listName: string,
  itemId: TypeListItemId,
  sig?: SigGroupAvatar,
  ignoreSelection?: boolean,
  hideMenu?: boolean,
  isNotificationMuted?: boolean)
{
  if(sig)
  {
    const hasAbout = hasValue(sig.about);
    dispatchList(listName, listSetItem({
      itemId: itemId,
      newItem: {
        version: sig.version,
        type: "aps",
        ignoreSelection: ignoreSelection,
        avatarLeft: {
          src: getImageThumbnail(sig.avatarId),
          icon: "group"
        },
        primary: {
          text: getLabel(sig),
          caption: {
            text: sig.isAdmin ? STR_ADMIN : undefined,
            color: "successLight",
            ignoreSelection: true
          }
        },
        secondary: {
          text: hasAbout ? sig.about : STR_NO_ABOUT,
          color: hasAbout ? "textSecondary" : "textDisabled",
          ...isNotificationMuted && {
            icon4: "mute"
          }
        },
        hideMenu: hideMenu
      } as IListItemAPSA
    }));
  }
  else
  {
    dispatchList(listName, listClearItem(itemId));
  }
}

export function dispatchListItemEnt(
  listName: string,
  itemId: TypeListItemId,
  sig?: SigEntAvatarUser,
  ignoreSelection?: boolean,
  hideMenu?: boolean,
  isNotificationMuted?: boolean
)
{
  if(sig)
  {
    return dispatchListItemStudioArtifact(
      listName,
      itemId,
      "enterprise",
      sig.name,
      sig.about,
      sig.avatarId,
      sig.version,
      undefined,
      undefined,
      ignoreSelection,
      hideMenu,
      undefined,
      undefined,
      undefined,
      isNotificationMuted
    );
  }
  else
  {
    return dispatchList(listName, listClearItem(itemId));
  }
}

export function dispatchListItemStudio(
  listName: string,
  itemId: TypeListItemId,
  sig?: SigStudioEntAvatar | SigStudioPluginAvatar,
  ignoreSelection?: boolean,
  hideMenu?: boolean
)
{
  if(sig)
  {
    const rootState = store.getState();
    const isEnt = isEntId(itemId);
    const isPlugin = isPluginId(itemId);
    const isTemplate = isTemplateId(itemId);
    const hasLock = Boolean((isEnt && hasEditLockEnt(rootState, itemId))
      || (isPlugin && hasEditLockPlugin(rootState, itemId)));
    const errorCount = sig.errorCount;
    const isDirty = sig.isDirty;
    const lastUpdateTime = sig.lastUpdateTime;
    const icon = isEnt ? "enterprise" : "plugin";
    const storeItemArtifactKind = isEnt
      ? isTemplate
        ? "template"
        : "ent"
      : isPlugin
        ? "plugin"
        : undefined;
    const pluginMode: EnumDefnPluginMode | undefined = isPlugin
      ? (sig as SigStudioPluginAvatar)?.mode || "agent"
      : undefined;

    return dispatchListItemStudioArtifact(
      listName,
      itemId,
      icon,
      sig.name,
      sig.about,
      sig.avatarId,
      sig.version,
      lastUpdateTime,
      errorCount,
      ignoreSelection,
      hideMenu,
      isDirty,
      hasLock,
      storeItemArtifactKind,
      undefined,
      pluginMode
    );
  }
  else
  {
    return dispatchList(listName, listClearItem(itemId));
  }
}

export function dispatchListItemStore(
  listName: string,
  itemId: TypeListItemId,
  sig?: SigStoreItemAvatar,
  ignoreSelection?: boolean,
  hideMenu?: boolean
)
{
  if(sig)
  {
    const sigStoreItemAvatar = sig as SigStoreItem;
    const lastUpdateTime = sigStoreItemAvatar.updatedOn;
    const storeItemArtifactKind = sigStoreItemAvatar.artifactKind;
    const storeItem = sigStoreItemAvatar.storeItem;
    const pluginMode: EnumDefnPluginMode | undefined = storeItemArtifactKind === "plugin"
      ? storeItem?.pluginBundle?.draft?.studioPlugin?.mode || "agent"
      : undefined;

    return dispatchListItemStudioArtifact(listName,
      itemId,
      "enterpriseFromStore",
      sig.name,
      sig.about,
      sig.avatarId,
      sig.version,
      lastUpdateTime,
      undefined,
      ignoreSelection,
      hideMenu,
      undefined,
      undefined,
      storeItemArtifactKind,
      undefined,
      pluginMode
    );
  }
  else
  {
    return dispatchList(listName, listClearItem(itemId));
  }
}

export function dispatchListItemProduction(
  listName: string,
  itemId: TypeListItemId,
  sig?: SigStudioEntAvatar | SigStudioPluginAvatar,
  hideMenu?: boolean,
  ignoreSelection?: boolean
)
{
  if(sig)
  {
    const name = sig.name;
    const about = sig.about;
    const version = sig.version;
    const lastUpdateTime = sig.lastUpdateTime;

    const rootState = store.getState();
    const isEnt = isEntId(itemId);
    const isPlugin = isPluginId(itemId);
    const isTemplate = isTemplateId(itemId);
    const artifactKind = isEnt
      ? isTemplate
        ? "template"
        : "ent"
      : isPlugin
        ? "plugin"
        : undefined;
    const pluginMode: EnumDefnPluginMode | undefined = isPlugin
      ? (sig as SigStudioPluginAvatar)?.mode || "agent"
      : undefined;
    const hasLock = Boolean((isEntId(itemId) && hasEditLockEnt(rootState, itemId)) || (isPluginId(itemId)
      && hasEditLockPlugin(rootState, itemId)));

    return dispatchList(listName, listSetItem({
      itemId: itemId,
      newItem: {
        version: version,
        type: "aps",
        ignoreSelection: ignoreSelection,
        hideMenu: hideMenu,
        avatarLeft: {
          src: getImageThumbnail(sig.avatarId),
          icon: artifactKind === "template" || artifactKind === "ent"
            ? "enterprise"
            : artifactKind === "plugin"
              ? "plugin"
              : undefined
        },
        primary: {
          text: name,
          caption: {
            text: lastUpdateTime
              ? formatCaptionDateTime(toDateFromDetailedDateTime(lastUpdateTime))
              : undefined,
            ignoreSelection: true
          }
        },
        secondary: {
          text: about,
          caption: {
            text: artifactKind === "ent"
              ? toLabel("application" as EnumDefnStoreItem)
              : artifactKind === "template"
                ? toLabel("template" as EnumDefnStoreItem)
                : pluginMode
                  ? toLabel(pluginMode)
                  : undefined
          },
          icon4: hasLock
            ? "unlock"
            : undefined
        },
        userField: {
          artifactName: name,
          storeItemArtifactKind: artifactKind
        }
      } as IListItemAPSA
    }));
  }
  else
  {
    return dispatchList(listName, listClearItem(itemId));
  }
}

export function textUserReal(sig: SigUserAvatar): string
{
  return (sig.firstName) ? `${textAvatar(sig.firstName, sig.lastName)}` : sig.nickName as string;
}

export function textUser(sig: SigUserAvatar): string
{
  return sig.nickName ? sig.nickName : textUserReal(sig);
}

export function textAvatar(firstName?: string, lastName?: string)
{
  return (firstName ? (lastName ? `${firstName} ${lastName}` : firstName) : lastName) || "";
}

export function getEntTooltip(entAvatar?: SigEntAvatarUser)
{
  return entAvatar && entAvatar.entId !== ENT_ID_GLOBAL
    ? {
      primary: entAvatar.name,
      secondary: entAvatar.about
    } as TypeTooltip
    : undefined;
}

export const ListVarNameTopic = "~~topic~~";

export function serverPush(sig: SigTopic, ...selectLists: SelectList[]): void
{
  const version = random();
  const rootState = store.getState();
  selectLists.forEach(selectList =>
  {
    const _listName = listName(rootState, selectList);
    dispatchList(_listName, listSetUserFieldVar({
      varName: ListVarNameTopic,
      varValue: {
        topic: sig,
        version: version
      }
    } as IServerPushListTopic));
  });
}

function dispatchListItemStudioArtifact(
  listName: string,
  itemId: TypeListItemId,
  icon: TypeArtifact,
  name: string,
  about?: string,
  mediaIdAvatar?: MediaIdAvatar,
  version?: string,
  lastUpdateTime?: string,
  errorCount?: number,
  ignoreSelection?: boolean,
  hideMenu?: boolean,
  isDirty?: boolean,
  hasLock?: boolean,
  storeItemArtifactKind?: EnumStoreItemArtifact,
  isNotificationMuted?: boolean,
  pluginMode?: EnumDefnPluginMode
)
{
  const _icon = icon === "enterpriseFromStore"
    ? storeItemArtifactKind === "ent"
      ? "enterprise"
      : "plugin"
    : icon;

  return dispatchList(listName, listSetItem({
    itemId: itemId,
    newItem: {
      version: version,
      type: "aps",
      ignoreSelection: ignoreSelection,
      hideMenu: hideMenu,
      avatarLeft: {
        src: getImageThumbnail(mediaIdAvatar),
        icon: _icon,
        dotColor: isDirty
          ? "successLight"
          : undefined
      },
      primary: {
        text: name,
        caption: {
          text: lastUpdateTime
            ? formatCaptionDateTime(toDateFromDetailedDateTime(lastUpdateTime))
            : undefined,
          ignoreSelection: true
        }
      },
      secondary: {
        text: about,
        badge: {
          value: errorCount && errorCount !== 0 ? errorCount : undefined,
          color: "error"
        },
        caption: {
          text: pluginMode
            ? toLabel(pluginMode)
            : storeItemArtifactKind === "ent"
              ? toLabel("application" as EnumDefnStoreItem)
              : storeItemArtifactKind === "template"
                ? toLabel("template" as EnumDefnStoreItem)
                : undefined
        },
        icon4: hasLock
          ? "unlock"
          : undefined,
        ...isNotificationMuted && {
          icon3: hasLock
            ? "unlock"
            : undefined,
          icon4: "mute"
        }
      },
      userField: {
        artifactName: name,
        storeItemArtifactKind: storeItemArtifactKind
      }
    } as IListItemAPSA
  }));
}

export function insertSystemRoleIds(callerEnt: ICallerEnt, senderId: EntUserId, roleIdSet: string[])
{
  const managerialRelationshipMap = callerEnt?.managerialRelationshipMap;
  const entUserId = callerEnt?.entUserId;

  if(senderId && senderId === entUserId)
  {
    roleIdSet.push(selfRole);
  }

  if(senderId && !isEmpty(managerialRelationshipMap))
  {
    insertManagerialRelationship(managerialRelationshipMap, senderId, roleIdSet);
  }
}

export function matchRoles(callerRoles: string[], itemRoles: string[])
{
  return callerRoles.some(callerRole => itemRoles.includes(callerRole));
}
