import {Box} from "@mui/material";
import {useEffect} from "react";
import {useCallback} from "react";
import React from "react";
import {MetaId} from "../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../base/plus/ConstantsPlus";
import {dispatchList} from "../../base/plus/ListPlus";
import theme from "../../base/plus/ThemePlus";
import {listSetSelectedItemId} from "../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../base/slices/list/SliceListSharedActions";
import {IListData} from "../../base/types/list/TypesList";
import {IListItem} from "../../base/types/list/TypesList";
import {TypeListItemId} from "../../base/types/list/TypesList";
import {RootState} from "../../Store";
import {useAppSelector} from "../app/AppHooks";
import DialogAtom from "../atom/dialog/DialogAtom";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawSearch from "../atom/raw/RawSearch";
import {usePageCtx} from "../ctx/CtxPage";
import {ListClickVariant} from "../list/List";
import {List} from "../list/List";

export default function DialogStudioEntElementPicker(props: {
  onClickOk: (metaId: MetaId) => void,
  title: string,
  searchPlaceHolder: string,
  doLoad?: () => IListData,
})
{
  const doLoad = props.doLoad;
  const title = props.title;
  const searchPlaceHolder = props.searchPlaceHolder;
  const onClickOk = props.onClickOk;

  const pageCtx = usePageCtx();

  const listName = useAppSelector(state => selectList(state).listName);

  const cbOnClick = useCallback((
    menuAnchor: Element,
    itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant) =>
  {
    switch(variant)
    {
      case "listItem":
        pageCtx.showDialog(undefined);
        onClickOk(itemId);
        break;
    }
  }, [onClickOk, pageCtx]);

  useEffect(() =>
  {
    if(doLoad)
    {
      const {
        itemIds,
        itemsById
      } = doLoad();

      dispatchList(listName, listRefresh({
        itemsById: itemsById,
        itemIds: itemIds
      }));

      dispatchList(listName, listSetSelectedItemId(undefined));
    }

  }, []);

  return (
    <DialogAtom
      title={title}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            bgcolor: theme.common.bgcolorContent
          }}
        >
          <RawSearch
            bgColor={theme.common.bgcolorContent}
            placeHolder={searchPlaceHolder}
            onSearchBindListName={listName}
          />

          <LayoutFlexCol
            width={"100%"}
            flexGrow={1}
          >
            <List
              selectList={selectList}
              emptyComp={STR_NOTHING_HERE}
              emptySearch={STR_NOTHING_HERE}
              onClickListItem={cbOnClick}
            />
          </LayoutFlexCol>
        </Box>
      }
      contentHeight={600}
    />
  );
}

function selectList(state: RootState)
{
  return state.studio.app.studioEntElementPicker;
}
