import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {useMemo} from "react";
import {DefnFieldSetOfText} from "../../../../../../api/meta/base/dto/DefnFieldSetOfText";
import {FieldSetOfOptionId} from "../../../../../../api/meta/base/dto/FieldSetOfOptionId";
import {MetaIdField} from "../../../../../../api/meta/base/Types";
import {getPrefixSuffixText} from "../../../../../../base/plus/FieldValuePlus";
import {getDefnDtoColorToCssColor} from "../../../../../../base/plus/FormPlus";
import {px} from "../../../../../../base/plus/StringPlus";
import RawHighlighter from "../../../../raw/RawHighlighter";

export function BubbleFieldSetOfText(props: {
  field: DefnFieldSetOfText,
  variant: Variant,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const theme = useTheme();
  const field = props.field;
  const variant = props.variant;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;

  const valueSet = useMemo(() =>
  {
    const value = valueMap?.[fieldId] as FieldSetOfOptionId | undefined;
    return value?.valueSet;

  }, [fieldId, valueMap]);

  return valueSet?.map((optionId, index) =>
  {
    const option = optionId ? field.optionMap?.map[optionId] : undefined;
    const dtoColor = option?.color;

    const pickTextColor = getDefnDtoColorToCssColor(dtoColor);

    const value = valueMap?.[fieldId] as FieldSetOfOptionId | undefined;

    const text = getPrefixSuffixText(field, option?.value || value?.displaySet?.[index]);

    return <>
      <RawHighlighter
        variant={variant}
        value={text}
        color={pickTextColor || "textSecondary"}
        searchWords={props.searchWords}
        isUserSelect={true}

      />
      {
        (valueSet?.length > index + 1)
        && <Typography
          variant={variant}
          mr={px(theme.common.gapQuarter)}
          children={", "}
        />
      }
    </>;
  });
}
