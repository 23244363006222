import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Typography} from "@mui/material";
import React from "react";
import {px} from "../../../../base/plus/StringPlus";
import {textIconSx} from "../../../../base/plus/ThemePlus";
import {EnumBubbleIconCaption} from "../../../../base/types/TypesBubble";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import IconBubbleCaption from "../../icon/IconBubbleCaption";
import {LayoutGap} from "../../layout/LayoutGap";

export default function BubbleRawCaption(props: {
  textRight?: string,
  textLeft?: string,
  textColor?: TypeTextColor
  icon?: EnumBubbleIconCaption,
  iconColor?: "light" | "dark",
  isStar?: boolean,
  onClickCaption?: CbMenuAnchor,
})
{
  const {
    textRight,
    textLeft,
    icon,
    iconColor,
    isStar,
    onClickCaption
  } = props;
  const theme = useTheme();
  const gapQuarter = theme.common.gapQuarter;
  const gapHalf = theme.common.gapHalf;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: onClickCaption ? "pointer" : undefined,
        justifyContent: textLeft ? "space-between" : "end"
      }}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
      {
        e.stopPropagation();
        onClickCaption && onClickCaption(e.currentTarget);
      }}
    >
      {textLeft && (
        <>
          <LayoutGap width={px(gapQuarter + gapHalf)} />
          <VideocamRoundedIcon sx={textIconSx(theme.palette.primary.contrastText)} />
          <Typography
            variant={"caption"}
            color={props.textColor ? theme.common.color(props.textColor) : theme.palette.text.disabled}
          >
            {textLeft}
          </Typography>
        </>
      )}

      {textRight && (
        <>
          {
            isStar &&
            <IconBubbleCaption
              value={"star"}
              color={iconColor ?? "light"}
            />
          }
          <Typography
            variant={"caption"}
            color={props.textColor ? theme.common.color(props.textColor) : theme.palette.text.disabled}
          >
            {textRight}
          </Typography>

          <LayoutGap width={px(gapQuarter)} />
        </>
      )}

      {
        icon &&
        <IconBubbleCaption
          value={icon}
          color={iconColor ?? "light"}
        />
      }
    </Box>
  );
}
