import {FieldValues} from "react-hook-form";
import {DefnFieldEditable} from "../../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldDocument} from "../../../../../api/meta/base/dto/StudioFieldDocument";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyShowSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeyShowSizeVar} from "../../base/TypesFormBuilder";
import {propKeyShowSize} from "../../base/TypesFormBuilder";
import {propKeyMaxSize} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeVar} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyFileTypeSet} from "../../base/TypesFormBuilder";

export function getDefnFieldDocument(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    propKeyShowSizeFieldId,
    propKeyMaxSizeFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    propKeyShowSizeVar,
    propKeyMaxSizeVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap2,
    propKeyShowSize,
    propKeyFileTypeSet,
    propKeyMaxSize
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "document",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),
    [propKeyFileTypeSet]: {
      type: "studioSetOfDocFileExt",
      metaId: propKeyFileTypeSet,
      name: propKeyFileTypeSet,
      label: "File type"
    } as DefnFieldEditable,

    [propKeyShowSize]: {
      type: "bool",
      metaId: propKeyShowSize,
      name: propKeyShowSize,
      label: "Show size",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowSizeVar]: {
      type: "pickVarId",
      name: propKeyShowSizeVar,
      metaId: propKeyShowSizeVar,
      label: "Show size",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyShowSizeFieldId]: {
      type: "pickFieldId",
      metaId: propKeyShowSizeFieldId,
      name: propKeyShowSizeFieldId,
      label: "Show size",
      formId: formId,
      filterFieldTypeSet: ["bool"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId
  };
}

export function defnValueToStudioFieldDocument(values: FieldValues): StudioFieldDocument
{
  return {
    type: "document",
    fileTypeSet: fnFieldValueToRawValue("studioSetOfDocFileExt", values[propKeyFileTypeSet]),
    maxSize: fnFieldValueToRawValue("number", values[propKeyMaxSize]),
    maxSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxSizeVar]),
    maxSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxSizeFieldId]),
    showSize: fnFieldValueToRawValue("bool", values[propKeyShowSize]),
    showSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyShowSizeVar]),
    showSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyShowSizeFieldId])
  } as StudioFieldDocument;
}

export function studioFieldDocumentToDefnValue(studioField: StudioFieldDocument)
{
  return {
    [propKeyFileTypeSet]: fnRawValueToFieldValue("studioSetOfDocFileExt", studioField.fileTypeSet),
    [propKeyMaxSize]: fnRawValueToFieldValue("number", studioField.maxSize),
    [propKeyMaxSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.maxSizeVarId),
    [propKeyMaxSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxSizeFieldId),
    [propKeyShowSize]: fnRawValueToFieldValue("bool", studioField.showSize),
    [propKeyShowSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.showSizeVarId),
    [propKeyShowSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.showSizeFieldId)
  };
}
