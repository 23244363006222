import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {IDialogAtomRef} from "../atom/dialog/DialogAtom";
import DialogAtom from "../atom/dialog/DialogAtom";
import {Cancel} from "../atom/dialog/DialogConfirm";
import {OK} from "../atom/dialog/DialogConfirm";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawCodeEditor from "../atom/raw/RawCodeEditor";

const contentWidth = 1050;
const contentHeight = 583;

export function DialogImportOrExportXML(props: {
  xml?: string,
  rightFooterActions?: string[],
  onClickOk?: (xml: string) => void,
})
{
  const onClickOK = props.onClickOk;
  const xml = props.xml;
  const [queryContent, setQueryContent] = useState(xml);
  const dialogRef = {} as IDialogAtomRef;

  const onClickFooterAction = useCallback((action: string) =>
  {
    if(action === OK)
    {
      onClickOK && onClickOK(queryContent ?? "");
      dialogRef.close();
    }
    else
    {
      dialogRef.close();
    }
  }, [dialogRef]);

  return (
    <DialogAtom
      title={`${xml === undefined ? "Import" : "Export"} XML`}
      contentWidth={contentWidth}
      contentHeight={contentHeight}
      cbRef={dialogRef}
      content={
        <LayoutFlexCol
          flexGrow={1}
          width={"100%"}
        >
          <RawCodeEditor
            value={xml}
            onChange={newValue => setQueryContent(newValue)}
            defaultLanguage={"xml"}
            editorTheme={"light"}
            readonly={false}
            dragAndDrop={true}
          />
        </LayoutFlexCol>
      }
      rightFooterActions={xml === undefined ? [Cancel, OK] : [Cancel]}
      onClickFooterAction={onClickFooterAction}
      disableRightFooterActions={queryContent ? undefined : ["OK"]}
    />
  );
}
