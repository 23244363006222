import {MetaIdRole} from "../../../api/meta/base/Types";
import {EntUserId} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {insertSystemRoleIds} from "../../../base/plus/SrvcPlus";
import {matchRoles} from "../../../base/plus/SrvcPlus";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {RootState} from "../../../Store";
import SrvcFormBuilder from "./SrvcFormBuilder";
import SrvcFormViewer from "./SrvcFormViewer";
import SrvcReportBr from "./SrvcReportBr";

export default class SrvcForm extends ISrvc
{
  public readonly formViewer = new SrvcFormViewer();
  public readonly formBuilder = new SrvcFormBuilder();
  public readonly reportBr = new SrvcReportBr(state => state.app.form.reportBrList);

  constructor()
  {
    super();

    this.setSrvcArray(
      this.formViewer,
      this.formBuilder,
      this.reportBr
    );
  }
}

export function isCommentableForm(rootState: RootState, entId: EntId, formId?: MetaIdForm, senderId?: EntUserId)
{
  const callerEnt = selectCallerEnt(rootState, entId);
  const form = formId ? callerEnt?.formMap[formId] : undefined;
  const commentRoleSet = form?.commentRoleSet;
  const commentReadOnlyRoleSet = form?.commentReadOnlyRoleSet;

  if(!callerEnt || (!commentRoleSet?.length && !commentReadOnlyRoleSet?.length))
  {
    return false;
  }
  const roleIdSet: MetaIdRole[] = [...callerEnt?.roleIdSet || []];
  if(senderId)
  {
    insertSystemRoleIds(callerEnt, senderId, roleIdSet);
  }

  if(commentRoleSet?.length && matchRoles(roleIdSet, commentRoleSet))
  {
    return true;
  }
  else if(commentReadOnlyRoleSet?.length && matchRoles(roleIdSet, commentReadOnlyRoleSet))
  {
    return true;
  }
  return false;
}
