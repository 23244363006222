import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {StudioVarSymbol} from "../../../api/meta/base/dto/StudioVarSymbol";

export function fnVariableBuilderSymbol(fieldValueSymbol: string)
{

  function getVarComp()
  {
    return {
      [fieldValueSymbol]: {
        type: "symbol",
        metaId: fieldValueSymbol,
        label: "Value",
        required: true
      } as DefnFieldEditable
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarSymbol
  )
  {
    valueMap[fieldValueSymbol] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueSymbol];

    return {
      value: value
    } as StudioVarSymbol;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
