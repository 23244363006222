import {FormControl} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickUser} from "../../../../api/meta/base/dto/DefnFieldPickUser";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldPickUser(props: {
  defn: DefnFieldPickUser
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldIcon,
    getFieldShowAsDropdown
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);

  const label = getCompLabel(defn);

  const helperText = getFieldHelperText();
  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();
  const showAsDropdown = getFieldShowAsDropdown();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
      {
        const fieldValue = fieldProps.field.value as FieldValueEntUserId | null;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <RealFieldPickUser
              key={fieldProps.field.name}
              defn={defn}
              fieldProps={fieldProps}
              fieldValue={fieldValue}
              label={label}
              helperText={helperText}
              placeHolder={placeHolder}
              required={required}
              icon={icon}
              showAsDropdown={showAsDropdown as boolean}
            />
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealFieldPickUser(props: {
  defn: DefnFieldPickUser,
  fieldValue?: FieldValueEntUserId | null,
  fieldProps: IControllerFieldProps,
  label?: string,
  helperText?: string,
  placeHolder?: string,
  required?: boolean,
  icon?: string,
  showAsDropdown?: boolean
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const label = props.label;

  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const required = props.required;
  const placeHolder = props.placeHolder;
  const helperText = props.helperText;
  const icon = props.icon;

  const searchText = formCtx.getSearchText();
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const getOnClick = formCtx.getOnClick();
  const getOption = formCtx.getOnGetFieldOptions();
  const showAsDropdown = props.showAsDropdown;

  const [optionMap, setOptionMap] = useState<DefnStudioMapOfDtoOption>();
  const [loading, setLoading] = useState<boolean>(false);

  const field = props.fieldProps.field;
  const error = props.fieldProps.fieldState.error;
  const isError = Boolean(error);

  const onChangePicker = (value?: string | null) =>
  {
    field.onChange && field.onChange(fnRawValueToFieldValue("text", value) as FieldValueText | null);
  };

  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const onOpen = () =>
  {
    if(showAsDropdown)
    {
      if(getOption)
      {
        setLoading(true);
        getOption(fieldId, options =>
        {
          if(options)
          {
            setOptionMap(options as DefnStudioMapOfDtoOption);
          }
          setLoading(false);
        });
      }
    }
  };

  useEffect(() =>
  {
    onOpen();
  }, []);

  const onBlur = () =>
  {
    field.onBlur();
    setLoading(false);
  };

  return (
    <FormControl
      fullWidth
      variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
      error={isError}
    >
      {
        !showAsDropdown
          ? <FieldRawTextField
            {...props}
            value={fnRawValueToFieldValue("text", fieldValue?.displayField) as FieldValueText | undefined}
            onBlur={field.onBlur}
            error={error}
            ref={field.ref}
            clearButton={Boolean(fieldValue)}
            onChange={value =>
            {
              if(!value)
              {
                field.onChange(null);
              }
            }}
            onClick={onClick}
          />
          : <RawPickOneAutoComplete
            ref={field.ref}
            optionMap={optionMap}
            value={fieldValue?.value}
            onChange={onChangePicker}
            label={label}
            disabled={disabled}
            readOnly={readOnly}
            error={error}
            helperText={helperText}
            placeHolder={placeHolder}
            required={required}
            icon={icon}
            onClick={onClick}
            onBlur={onBlur}
            searchWords={searchText ? [searchText] : undefined}
            fieldVariant={fieldVariant}
            fieldSize={defnTheme.fieldSize}
            hideLabel={defn.hideLabel}
            name={field.name}
            fieldId={fieldId}
            loading={loading}
            disableSelected={true}
            onOpen={onOpen}
          />
      }
    </FormControl>
  );
}
