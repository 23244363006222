import React, {useCallback, useEffect, useMemo, useState} from "react";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {IFormRef} from "../../../../base/types/TypesForm";
import DialogAtom, {IDialogAtomRef} from "../../../atom/dialog/DialogAtom";
import {Cancel, OK} from "../../../atom/dialog/DialogConfirm";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawSearch from "../../../atom/raw/RawSearch";
import Form, {IFormProps} from "../../../form/viewer/Form";
import {dialogGetFieldValueMap} from "../DialogPlus";

export default function DialogDefnForm<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  formProps: IFormProps<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
  title: string,
  dialogRef?: IDialogAtomRef
  rightFooterActions?: string[],
  leftFooterActions?: string[],
  addMoreCheckBoxLabel?: string,
  contentWidth?: number,
  contentHeight?: number
  setFullScreen?: boolean
  onClickFooterAction?: (action: string) => void,
  onClose?: () => void,
  onChangeAddMore?: (enable: boolean) => void,
  onSearch?: (search: string) => void,
  preventEnterKey?: boolean,
  escapeToClose?: boolean,
  doNotCloseActions?: string[],
  middleFooterText?: string,
  disabledButtons?: string[]
})
{
  const formReadonly = props.formProps.formReadonly;

  const [submitEnable, setSubmitEnable] = useState(false);
  const [isAddMoreEnable, setIsAddMoreEnable] = useState(false);

  const dialogRef = useMemo(() => props.dialogRef || {} as IDialogAtomRef, [props.dialogRef]);
  const formRef = useMemo(() => props.formProps.cbRef || {} as IFormRef, [props.formProps.cbRef]);

  const formProps = useMemo(() => ({
    ...props.formProps,
    cbRef: formRef
  }), [props.formProps, formRef]);

  const close = useCallback(() =>
  {
    dialogRef.close();
  }, [dialogRef]);

  const onClickFooterAction = useCallback((reason: string) =>
  {
    if(reason === OK)
    {
      if(formRef)
      {
        formRef.remoteSubmit();

        const resetKeys = dialogGetFieldValueMap(formProps.defnForm, undefined);
        formRef.remoteReset(resetKeys);

        setTimeout(() =>
        {
          formRef.checkSubmit();
          !isAddMoreEnable && close();
        });
      }
    }
    else if(reason === Cancel)
    {
      close();
    }
    if(reason !== OK && reason !== Cancel)
    {
      props.onClickFooterAction && props.onClickFooterAction(reason);
    }
  }, [close, formProps.defnForm, formRef, isAddMoreEnable, props.onClickFooterAction]);

  useEffect(() =>
  {
    dialogRef.setContent(<DialogDefnFormContent
      formProps={formProps}
      onSubmitEnable={setSubmitEnable}
      onSearch={props.onSearch}
      contentWidth={props.contentWidth}
      contentHeight={props.contentHeight}
    />);
  }, [formProps, dialogRef, props.formProps]);

  useEffect(() =>
  {
    props.onChangeAddMore && props.onChangeAddMore(isAddMoreEnable);

  }, [isAddMoreEnable]);

  useEffect(() =>
  {
    const onClick = (e: KeyboardEvent) =>
    {
      if(e.key === "Enter" && submitEnable && !props.preventEnterKey)
      {
        e.preventDefault();
        e.stopPropagation();
        onClickFooterAction(OK);
      }
      if(e.key === "Escape" && props.escapeToClose)
      {
        e.preventDefault();
        e.stopPropagation();
        onClickFooterAction(Cancel);
      }
    };

    window.addEventListener("keydown", onClick);

    return () => window.removeEventListener("keydown", onClick);

  }, [onClickFooterAction, submitEnable]);

  return (
    <DialogAtom
      draggable={true}
      title={props.title}
      cbRef={dialogRef}
      onClose={props.onClose}
      contentWidth={props.contentWidth}
      contentHeight={props.contentHeight}
      fullScreen={props.setFullScreen}
      leftFooterActions={props.leftFooterActions}
      rightFooterActions={props.rightFooterActions
        ? props.rightFooterActions
        : [Cancel, OK]}
      middleFooterText={props.middleFooterText}
      leftFooterCheckBoxActions={props.addMoreCheckBoxLabel
        ? [
          {
            label: props.addMoreCheckBoxLabel,
            value: "addMore"
          }
        ]
        : undefined}
      disableRightFooterActions={(!submitEnable || formReadonly)
        ? [OK, ...(props.disabledButtons ?? [])]
        : [...(props.disabledButtons ?? [])]}
      doNotCloseActions={
        props.doNotCloseActions && props.doNotCloseActions.length > 0
          ? [OK, ...props.doNotCloseActions]
          : [OK]
      }
      onClickFooterCheckBoxAction={(action, checked) =>
      {
        if(action.value === "addMore")
        {
          setIsAddMoreEnable(checked);
        }
      }}
      onClickFooterAction={onClickFooterAction}
    />
  );
}

function DialogDefnFormContent<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  formProps: IFormProps<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>,
  contentWidth?: number,
  contentHeight?: number,
  onSubmitEnable?: (enable: boolean) => void,
  onSearch?: (search: string) => void
})
{
  return (
    <LayoutFlexCol
      width={"100%"}
      height={"100%"}
      maxWidth={px(props.contentWidth)}
      maxHeight={px(props.contentHeight)}
      overflowY={"auto"}
      overflowX={"auto"}
      justifyContent={"start"}
      flexGrow={1}
    >
      {
        props.onSearch &&
        <RawSearch
          onSearch={props.onSearch}
          placeHolder={`Search`}
          bgColor={theme.common.bgcolorContent}
        />
      }
      <Form
        {...props.formProps}
        onSubmitEnable={props.onSubmitEnable}
      />
    </LayoutFlexCol>
  );
}

