import {useTheme} from "@mui/material";
import {Property} from "csstype";
import {useRef} from "react";
import {useCallback} from "react";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import RawImage from "./RawImage";

const transparentImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBkZAAAAAoAAx9k7/gAAAAASUVORK5CYII=";

export default function RawLazyImage(props: {
  borderRadius?: Property.BorderRadius,
  borderTopLeftRadius?: Property.BorderTopLeftRadius,
  borderTopRightRadius?: Property.BorderTopRightRadius,
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius,
  borderBottomRightRadius?: Property.BorderBottomRightRadius,
  width?: Property.Width,
  height?: Property.Height,
  src?: string,
  srcBlur?: string,
  alt?: string,
  label?: string
  primaryColor?: Property.BackgroundColor,
  objectFit?: Property.ObjectFit,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
})
{
  const theme = useTheme();
  const onClick = props.onClick;
  const primaryColor = props.primaryColor;

  const [loadedWithRetry, setLoadedWithRetry] = useState(false);
  const [errorWithRetry, setErrorWithRetry] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const doLazyLoadWithRetry = useLazyLoadWithRetry({
    cbOnError: (src) =>
    {
      if(src === props.src)
      {
        setErrorWithRetry(true);
      }
    },
    cbOnLoad: (src) =>
    {
      if(src === props.src)
      {
        setErrorWithRetry(false);
        setLoadedWithRetry(true);
      }
    }
  });

  useEffect(() =>
  {
    if(props.src)
    {
      doLazyLoadWithRetry(props.src, true);
    }
  }, [props.src]);

  useEffect(() =>
  {
    if(props.srcBlur)
    {
      doLazyLoadWithRetry(props.srcBlur, true);
    }
  }, [props.srcBlur]);

  if(!loadedWithRetry)
  {
    return (
      <RawImage
        {...props}
        alt={"blur image"}
        src={props.srcBlur}
        bgColor={primaryColor}
        onError={e => e.currentTarget.src = transparentImg}
        applyBlurFilter={true}
      />
    );
  }

  return (
    <>
      <RawImage
        {...props}
        src={props.srcBlur}
        alt={"blur image"}
        bgColor={primaryColor}
        display={loaded ? "none" : undefined}
        onError={e => e.currentTarget.src = transparentImg}
      />
      <RawImage
        {...props}
        alt={"main image"}
        src={props.src}
        bgColor={theme.common.bgcolorContent}
        display={!loaded ? "none" : undefined}
        onLoad={() => setLoaded(true)}
        onError={(e) =>
        {
          if(!errorWithRetry && props.src)
          {
            setLoadedWithRetry(false);
          }
          else
          {
            e.currentTarget.src = transparentImg;
          }
        }}
        showErrorPlaceholder={errorWithRetry}
        error={errorWithRetry}
        onClick={onClick
          ? (e: React.MouseEvent<HTMLElement>) => onClick(e)
          : undefined}
      />
    </>
  );
}
const useLazyLoadWithRetry = (props: {
  cbOnLoad?: (src: string) => void,
  cbOnError?: (src: string) => void,
  retryCount?: number
  retryInterval?: number
}) =>
{
  const cbOnLoad = props.cbOnLoad;
  const cbOnError = props.cbOnError;
  const retryCount = props.retryCount ?? 5;
  const retryInterval = props.retryInterval ?? 1000;
  const count = useRef({} as Record<string, number>);

  const retryLoading = useCallback((imgSrc: string, init?: boolean) =>
  {
    if(init || !count.current[imgSrc])
    {
      count.current[imgSrc] = 1;
    }
    if(count.current[imgSrc] > retryCount)
    {
      cbOnError && cbOnError(imgSrc);
      return;
    }
    count.current[imgSrc] = count.current[imgSrc] + 1;

    const img = new Image();
    img.src = imgSrc;
    img.onload = () => cbOnLoad && cbOnLoad(imgSrc);
    img.onerror = () => setTimeout(() => retryLoading(imgSrc), count.current[imgSrc] * retryInterval);
  }, [retryCount, retryInterval, cbOnLoad, cbOnError]);

  return retryLoading;
};

