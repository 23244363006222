import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {ILineSecondaryAttributedText} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {TypeListCounterPositionVariant} from "../../list/List";
import IconListItemSecondary1 from "../icon/IconListItemSecondary1";
import IconListItemSecondary2 from "../icon/IconListItemSecondary2";
import IconListItemSecondary3 from "../icon/IconListItemSecondary3";
import IconListItemSecondary4 from "../icon/IconListItemSecondary4";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import {LayoutGap} from "../layout/LayoutGap";
import RawSkeleton from "../raw/RawSkeleton";
import LineBadge from "./LineBadge";
import LineCaption from "./LineCaption";
import LineTextSecondary from "./LineTextSecondary";

export function LineSecondary(props: {
  secondary?: ILineSecondary,
  searchWords?: string[],
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onChangeCounterValue?: (e: Element, countValue: number, variant: TypeListCounterPositionVariant) => void,
  mt?: number
})
{
  const theme = useTheme();
  const lineHeight = px(theme.common.heightLine) as string;
  const secondary = props.secondary;
  const onChangeCounterValue = props.onChangeCounterValue;
  const gapQuarter = theme.common.gapQuarter;
  if(secondary)
  {
    const caption = secondary.caption;
    const middle = secondary.middle;
    const badge = secondary.badge;
    const badge2 = secondary.badge2;
    const overwriteIcon1Icon2AndText = secondary.overwriteIcon1Icon2AndText;

    return (
      <LayoutFlexRow
        width={"100%"}
        height={lineHeight}
        mt={px(props.mt)}
        flexShrink={1}
        pl={px(secondary.pl)}
        justifyContent={!middle ? "space-between" : "start"}
      >
        <LayoutFlexRow
          {...middle && {maxWidth: "40%"}}
          {...middle ? {width: "100%"} : {width: 0}}
          flexGrow={1}
        >
          {
            overwriteIcon1Icon2AndText
              ? <>
                <LineTextSecondary
                  value={overwriteIcon1Icon2AndText}
                  variant={secondary.overwriteIcon1Icon2AndTextVariant ?? secondary.variant}
                  color={secondary.overwriteIcon1Icon2AndTextColor ?? secondary.color}
                  height={"100%"}
                  width={0}      //this is just a workaround because, truncate need width attribute
                  flexGrow={1}
                />
              </>
              : <>
                <IconListItemSecondary1 value={secondary.icon1} />

                {
                  secondary.icon1 &&
                  <LayoutGap width={gapQuarter} />
                }

                <IconListItemSecondary2 value={secondary.icon2} />

                {
                  secondary.icon2 &&
                  <LayoutGap width={gapQuarter} />
                }

                {
                  isLineSecondaryAttributedText(secondary)
                    ? <LayoutFlexRow
                      flexGrow={1}
                      justifyContent={"start"}
                      width={0}      //this is just a workaround because, truncate need width attribute
                    >
                      {
                        secondary.attributedText.map((attrText, index) => (
                          <LineTextSecondary
                            key={index}
                            value={attrText.text}
                            searchWords={props.searchWords}
                            textDecoration={attrText.textDecoration}
                            mr={theme.common.gapQuarter}
                            variant={attrText.variant ?? secondary.variant}
                            color={attrText.color}
                            hasMarkDownText={attrText.hasMarkDownText}
                          />
                        ))
                      }
                    </LayoutFlexRow>
                    : <LineTextSecondary
                      value={secondary.text}
                      variant={secondary.variant}
                      optional={secondary.optional}
                      color={secondary.color}
                      searchWords={props.searchWords}
                      height={"100%"}
                      width={0}      //this is just a workaround because, truncate need width attribute
                      flexGrow={1}
                      hasMarkDownText={secondary.hasMarkDownText}
                    />
                }

              </>
          }
        </LayoutFlexRow>

        {
          middle &&
          <LayoutFlexRow
            width={"30%"}
            height={"100%"}
            justifyContent={"start"}
          >
            <LineCaption
              caption={middle}
              height={"100%"}
              width={"30%"}
              textAlign={"left"}
              ml={gapQuarter}
              searchWords={props.searchWords}
              overflow={"hidden"}
              onChangeCounterValue={(e, count) => onChangeCounterValue && onChangeCounterValue(e, count, "middle")}
            />
          </LayoutFlexRow>
        }

        {(caption || secondary.icon3 || secondary.icon4 || badge || badge2) && (
          <LayoutFlexRow
            justifyContent={"end"}
            flexGrow={middle ? 1 : undefined}
          >
            {
              secondary.icon3 &&
              <>
                <IconListItemSecondary3 value={secondary.icon3} />
                <LayoutGap width={gapQuarter} />
              </>
            }

            {caption &&
              <LineCaption
                caption={caption}
                height={"100%"}
                textAlign={"right"}
                onClick={props.onClickCaption}
                ml={gapQuarter}
                searchWords={props.searchWords}
                onChangeCounterValue={(e, count) => onChangeCounterValue && onChangeCounterValue(e, count, "caption")}
              />
            }

            {
              secondary.icon4 &&
              <>
                <LayoutGap width={gapQuarter} />
                <IconListItemSecondary4 value={secondary.icon4} />
              </>
            }

            {badge2 &&
              <LineBadge
                badge={badge2}
                ml={gapQuarter}
                maxVar={100}
              />
            }

            {badge &&
              <LineBadge
                badge={badge}
                ml={gapQuarter}
                maxVar={100}
              />
            }
          </LayoutFlexRow>
        )}

      </LayoutFlexRow>
    );
  }
  else
  {
    return (
      <RawSkeleton
        variant="text"
        height={lineHeight}
        width={"100%"}
        mt={props.mt}
      />
    );
  }
}

function isLineSecondaryAttributedText(secondary: ILineSecondary): secondary is ILineSecondaryAttributedText
{
  return (secondary as ILineSecondaryAttributedText)?.attributedText !== undefined;
}
