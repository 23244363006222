import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioPickPluginFieldId} from "../../../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {loopDefnForm} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

export default function FieldRawStudioPickPluginFieldId(props: {
  defn: DefnStudioPickPluginFieldId
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const defn = props.defn;
  const pluginId = defn.pluginId;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const pickFieldMetaIdForm = defn.formId;

  const plugin = pluginId
    ? formStore?.pluginMap?.map[pluginId]
    : undefined;
  const form = plugin?.pluginFormMap[pickFieldMetaIdForm] as DefnForm | undefined;

  const options = useMemo<DefnDtoOption[]>(() =>
      getPickFieldOptions(defn, form)
    , [defn, form]);

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
    showChip={props.showChip}
  />;
}

function getPickFieldOptions(
  defn: DefnStudioPickPluginFieldId,
  form?: DefnForm
): DefnDtoOption[]
{
  if(!form)
  {
    return [];
  }
  const options: DefnDtoOption[] = [];

  const compositeIdSet = defn.compositeIdSet;
  const excludeFieldIdSet = defn.excludeFieldIdSet;
  const filterFieldTypeSet = defn.filterFieldTypeSet;
  const includeFieldIdSet = defn.includeFieldIdSet;
  const includeOptionMap = defn.includeOptionMap;
  const showCompositeName = defn.showCompositeName;

  loopDefnForm(form, (_parent, comp) =>
  {
    if(comp)
    {
      if((!filterFieldTypeSet || filterFieldTypeSet?.includes(comp.type))
        && (!excludeFieldIdSet || !excludeFieldIdSet?.includes(comp.metaId))
        && (!includeFieldIdSet || includeFieldIdSet?.includes(comp.metaId))
        && (!compositeIdSet || compositeIdSet?.includes((_parent as DefnSection | DefnTab | DefnGrid).metaId)))
      {
        const option: DefnDtoOption = {
          metaId: comp.metaId,
          value: comp.name
        };
        if(showCompositeName && _parent.type === "grid")
        {
          option.value = _parent.name + "." + comp.name;
        }
        options.push(option);
      }
    }
  }, {
    onlyField: true
  });

  if(includeOptionMap)
  {
    includeOptionMap.keys.forEach((fieldId) =>
    {
      const option = includeOptionMap.map[fieldId];
      options.push(option);
    });
  }

  return options;
}
