import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {FieldValues} from "react-hook-form";
import {DtoMessagePayloadLinkText} from "../../../api/home/base/dto/DtoMessagePayloadLinkText";
import {DtoMessagePayloadText} from "../../../api/home/base/dto/DtoMessagePayloadText";
import {loopLayoutItemLine} from "../../../nucleus/form/viewer/base/FormViewerPlus";
import {getFormName} from "../../plus/FormPlus";
import {isFormSearchHit} from "../../plus/FormPlus";
import {searchHit} from "../../plus/StringPlus";
import {IListItemForm} from "../../types/list/TypeListForm";
import {ListItemType} from "../../types/list/TypesList";
import {TypeListItemId} from "../../types/list/TypesList";
import {IList} from "../../types/list/TypesList";
import {IListItem} from "../../types/list/TypesList";
import {IListConfig} from "../../types/list/TypesList";
import {IListItemMPSL} from "../../types/list/TypesListAPSA";
import {IListItemAPSA} from "../../types/list/TypesListAPSA";
import {isListItemTypeAPSA} from "../../types/list/TypesListAPSA";
import {IListItemChat} from "../../types/list/TypesListChat";
import {IListGroupsById} from "../../types/list/TypesListGroup";
import {IListGroup} from "../../types/list/TypesListGroup";
import {IListItemGroup} from "../../types/list/TypesListGroup";
import {sliceListAPSA} from "./SliceListAPSA";
import {sliceListChat} from "./SliceListChat";
import {getListItemIdFooter} from "./SliceListFooter";
import {getListItemIdLoadMore} from "./SliceListFooter";
import {sliceListFooter} from "./SliceListFooter";
import {sliceListGap} from "./SliceListGap";
import {sliceListGroup} from "./SliceListGroup";
import {sliceListShared} from "./SliceListShared";

export function getListNameFake()
{
  return `Fake~~List~~`;
}

// hack: const causes TypeError
export function getListItemIdPrefixSkeleton()
{
  return `Skeleton~~Item~~Id`;
}

export const sliceFakeList = fnCreateListSlice(getListNameFake());

export const {
  listReset
} = sliceFakeList.actions;

export function fnCreateListSlice(name: string, config?: IListConfig)
{
  return createSlice({
    name: name,
    initialState: fnListReset({
      listName: name
    } as IList, config),
    reducers: {
      ...sliceListAPSA,
      ...sliceListGap,
      ...sliceListGroup,
      ...sliceListChat,
      ...sliceListShared,
      ...sliceListFooter,
      listReset: (state: IList, action: PayloadAction<IListConfig | undefined>) =>
      {
        fnListReset(state, action.payload ?? config);
      }
    }
  });
}

export function fnListSetDisplay(state: IList): void
{
  const searchWordsLowercase = fnListSetSearchWords(state);

  let displayItemIds: TypeListItemId[];
  let groupsById: IListGroupsById | undefined = state.groupsById;
  if(groupsById)
  {
    groupsById = fnListFilterPickGroupsById(state, groupsById);
    groupsById = fnListFilterSearchGroupsById(state, groupsById, searchWordsLowercase);
    displayItemIds = fnListGroupsByIdToDisplayIds(state, groupsById);
  }
  else
  {
    let itemIds = state.itemIds;

    if(itemIds)
    {
      itemIds = fnListFilterPickItemIds(state, itemIds);
      itemIds = fnListFilterSearchItemIds(state, itemIds, searchWordsLowercase);
      displayItemIds = itemIds;
    }
    else
    {
      displayItemIds = [];
    }
  }

  if(displayItemIds.length > 0)
  {
    if(!Boolean(state.loadMoreFlag) && state.defaultListItemType === "loadMore")
    {
      const loadMoreItemLimit = state.loadMoreItemLimit;
      if(loadMoreItemLimit && loadMoreItemLimit > 0)
      {
        if(displayItemIds.length > loadMoreItemLimit)
        {
          displayItemIds = displayItemIds.slice(0, loadMoreItemLimit);
          displayItemIds.push(getListItemIdLoadMore());
        }
      }
    }
    // const footer = state.itemsById[getListItemIdFooter()];
    if(state.defaultListItemType === "footer")
    {
      const loadMoreItemLimit = state.loadMoreItemLimit;
      if(loadMoreItemLimit && loadMoreItemLimit > 0)
      {
        if(displayItemIds.length > loadMoreItemLimit)
        {
          displayItemIds = displayItemIds.slice(0, loadMoreItemLimit);
          displayItemIds.push(getListItemIdFooter());
        }
      }
    }
  }

  state.displayItemIds = displayItemIds;
  fnListSetDerivedValues(state);
}

//region private

function fnListReset(state: IList, config?: IListConfig): IList
{
  state.version = undefined;
  state.userField = {};
  state.itemIds = [];
  state.groupsById = undefined;
  state.itemsById = {};
  state.pickItemIds = undefined;
  state.searchWords = undefined;
  state.error = undefined;
  state.selectedItemId = undefined;
  state.showPicksOnly = false;
  state.scrollToItemId = undefined;
  state.loadingFooter = undefined;

  state.pickType = config?.pickType;
  state.canShowMenu = config?.canShowMenu ?? true;
  state.ignoreSelection = config?.ignoreSelection;
  state.defaultListItemType = config?.defaultListItemType;
  state.listLayoutType = config?.listLayoutType || "list";
  state.loadMoreItemLimit = config?.loadMoreItemLimit;
  state.showEmptyGroups = config?.showEmptyGroups;
  state.draggable = config?.draggable;
  state.externalSearch = config?.externalSearch;
  state.defnForm = undefined;
  state.layout = undefined;
  state.defaultItemHeight = config?.defaultItemHeight;

  state.displayItemIds = [];
  state.displayFirstItemId = undefined;
  state.displayLastItemId = undefined;
  state.displayItemCount = 0;

  if(state.loadMoreItemLimit && state.loadMoreItemLimit > 0)
  {
    state.itemsById[getListItemIdLoadMore()] = {
      type: "loadMore"
    } as IListItem;
  }

  state.pickCount = 0;
  state.loaded = undefined;
  state.loadMoreFlag = undefined;

  return state;
}

function fnListSetSearchWords(state: IList): string[] | undefined
{
  // noinspection DuplicatedCode
  const searchWords = state.searchWords;
  if(searchWords && searchWords.length > 0)
  {
    if(state.externalSearch)
    {
      return undefined;
    }

    const searchWordsLowercase = new Set<string>();
    searchWords.forEach(word =>
    {
      searchWordsLowercase.add(word.toLowerCase());
    });

    return Array.from(searchWordsLowercase);
  }
  else
  {
    state.searchWords = undefined;
    return undefined;
  }
}

function fnListFilterPickGroupsById(state: IList, groupsById: IListGroupsById): IListGroupsById
{
  if(state.showPicksOnly && state.pickItemIds)
  {
    const newGroupsById = {} as IListGroupsById;
    Object
    .keys(groupsById)
    .forEach(groupId =>
    {
      const group = groupsById[groupId];
      if(group === "gapStd" || group === "gapHalf" || group === "gapQuarter")
      {
        newGroupsById[groupId] = group;
      }
      else
      {
        const pickItemIds = fnListFilterPickItemIds(state, group.itemIds);
        if(pickItemIds.length > 0)
        {
          newGroupsById[groupId] = {
            itemIds: pickItemIds
          } as IListGroup;
        }
      }
    });

    return newGroupsById;
  }
  else
  {
    return groupsById;
  }
}

function fnListFilterSearchGroupsById(
  state: IList,
  groupsById: IListGroupsById,
  searchWordsLowercase?: string[]): IListGroupsById
{
  if(searchWordsLowercase && searchWordsLowercase.length > 0)
  {
    const newGroupsById = {} as IListGroupsById;
    Object
    .keys(groupsById)
    .forEach(groupId =>
    {
      const group = groupsById[groupId];
      if(group === "gapStd" || group === "gapHalf" || group === "gapQuarter")
      {
        newGroupsById[groupId] = group;
      }
      else
      {
        const groupItemIds = fnListFilterSearchItemIds(state, group.itemIds, searchWordsLowercase);
        if(groupItemIds.length > 0)
        {
          newGroupsById[groupId] = {
            itemIds: groupItemIds
          } as IListGroup;
        }
      }
    });
    return newGroupsById;
  }
  else
  {
    return groupsById;
  }
}

function fnListFilterPickItemIds(state: IList, itemIds: TypeListItemId[]): TypeListItemId[]
{
  const showPicksOnly = state.showPicksOnly;
  const pickItemIds = state.pickItemIds;

  if(showPicksOnly && pickItemIds)
  {
    return itemIds.filter((itemId) => pickItemIds.hasOwnProperty(itemId));
  }
  else
  {
    return itemIds;
  }
}

function fnListFilterSearchItemIds(
  state: IList,
  itemIds: TypeListItemId[],
  searchWordsLowercase?: string[]
): TypeListItemId[]
{
  if(searchWordsLowercase && searchWordsLowercase.length > 0)
  {
    const searchItemIds: string[] = [];
    itemIds.forEach(itemId =>
    {
      const item = state.itemsById[itemId] as IListItem;
      const itemType = item?.type;
      if(itemType)
      {
        if(isListItemTypeAPSA(itemType))
        {
          if(isFilterSearchAPSA(item, searchWordsLowercase))
          {
            searchItemIds.push(itemId);
          }
        }
        else if(isListItemChatSearchable(itemType))
        {
          if(isFilterSearchChat(item, searchWordsLowercase))
          {
            searchItemIds.push(itemId);
          }
        }
        else if(itemType === "listGroup")
        {
          if(isFilterSearchListGroup(item, searchWordsLowercase))
          {
            searchItemIds.push(itemId);
          }
        }
        else if(itemType === "form")
        {
          if(isFilterSearchForm(state, item as IListItemForm, searchWordsLowercase))
          {
            searchItemIds.push(itemId);
          }
        }
      }
    });

    return searchItemIds;
  }
  else
  {
    return itemIds;
  }
}

function fnListGroupsByIdToDisplayIds(state: IList, groupsById: IListGroupsById): TypeListItemId[]
{
  const displayItemIds: TypeListItemId[] = [];
  const itemsById = state.itemsById;

  Object
  .keys(groupsById)
  .forEach(groupId =>
  {
    const group = groupsById[groupId];
    if(group === "gapQuarter" || group === "gapHalf" || group === "gapStd")
    {
      displayItemIds.push(groupId);

      itemsById[groupId] = {
        type: group
      } as IListItem;
    }
    else
    {
      fnListInsertGroupItemIdsToDisplayIds(state, groupId, groupsById, displayItemIds);
    }
  });

  return displayItemIds;
}

function fnListInsertGroupItemIdsToDisplayIds(
  state: IList,
  groupId: TypeListItemId,
  groupsById: IListGroupsById,
  displayItemIds: TypeListItemId[])
{
  const itemsById = state.itemsById;
  const group = itemsById[groupId] as IListItemGroup;
  const groupItemIds = (groupsById[groupId] as IListGroup).itemIds;
  if(groupItemIds.length > 0 || Boolean(state.showEmptyGroups))
  {
    if(!group.hideHeader)
    {
      displayItemIds.push(groupId);
    }
    const expand = group?.header?.expand;
    if(expand !== false)
    {
      groupItemIds.forEach(itemId =>
      {
        if(!displayItemIds.includes(itemId))
        {
          displayItemIds.push(itemId);
        }
        const item = itemsById[itemId] as IListItem;
        if(item?.type === "listGroup")
        {
          fnListInsertGroupItemIdsToDisplayIds(state, itemId, groupsById, displayItemIds);
        }
      });
    }
  }
}

function fnListSetDerivedValues(state: IList)
{
  const displayItemIds = state.displayItemIds;
  const len = displayItemIds.length;
  if(len > 0)
  {
    state.displayFirstItemId = displayItemIds[0];
    state.displayLastItemId = displayItemIds[len - 1];
  }
  else
  {
    state.displayFirstItemId = undefined;
    state.displayLastItemId = undefined;
  }

  state.displayItemCount = len;

  const pickItemIds = state.pickItemIds;
  state.pickCount = pickItemIds === undefined ? 0 : Object.keys(pickItemIds).length;
}

//endregion

function isListItemChatSearchable(listItemType: ListItemType): boolean
{

  return listItemType === "text"
    || listItemType === "linkText"
    || listItemType === "spreadsheetRow"
    || listItemType === "spreadsheetPartition"
    || listItemType === "report";
}

function isFilterSearchChat(
  searchItem: IListItem,
  searchWordsLowercase?: string[]): boolean
{
  switch(searchItem.type)
  {
    case "text":
      return isFilterSearchChatText(searchItem as IListItemChat, searchWordsLowercase);
    case "linkText":
      return isFilterSearchChatTextLinkText(searchItem as IListItemChat, searchWordsLowercase);
    case "spreadsheetRow":
    case "spreadsheetPartition":
    case "report":
      return isFilterSearchChatForm(searchItem as IListItemChat, searchWordsLowercase);
  }

  return false;
}

function isFilterSearchChatText(searchItem: IListItemChat, searchWordsLowercase?: string[])
{
  let payload = searchItem.sig.payload as DtoMessagePayloadText;
  return searchHit(payload.text, searchWordsLowercase);
}

function isFilterSearchChatTextLinkText(searchItem: IListItemChat, searchWordsLowercase?: string[])
{
  let payload = searchItem.sig.payload as DtoMessagePayloadLinkText;

  return searchHit(payload.text, searchWordsLowercase)
    || searchHit(payload.pageUrl, searchWordsLowercase)
    || searchHit(payload.pageTitle, searchWordsLowercase)
    || searchHit(payload.pageSubTitle, searchWordsLowercase);
}

function isFilterSearchChatForm(searchItem: IListItemChat, searchWordsLowercase?: string[])
{
  let sig = searchItem.sig;
  const formName = getFormName(sig.defnForm, sig.sigSpreadsheetRow?.formValue);

  return isFormSearchHit(sig.defnForm, sig.sigSpreadsheetRow?.formValue?.valueMap, searchWordsLowercase)
    || searchHit(formName, searchWordsLowercase);
}

function isFilterSearchAPSA(
  searchItem: IListItem,
  searchWordsLowercase?: string[]): boolean
{
  const item = searchItem as IListItemAPSA & IListItemMPSL;
  const isSecondaryListSearched = item.secondaryList?.some(item =>
  {
    return searchHit(item.text, searchWordsLowercase)
      || searchHit(item.caption?.text, searchWordsLowercase)
      || searchHit(item.middle?.text, searchWordsLowercase);
  });

  return searchHit(item.primary?.text, searchWordsLowercase)
    || searchHit(item.primary?.middle?.text, searchWordsLowercase)
    || searchHit(item.primary?.caption?.text, searchWordsLowercase)
    || searchHit(item.secondary?.text, searchWordsLowercase)
    || searchHit(item.secondary?.middle?.text, searchWordsLowercase)
    || searchHit(item.secondary?.caption?.text, searchWordsLowercase)
    || Boolean(isSecondaryListSearched);
}

function isFilterSearchListGroup(
  searchItem: IListItem,
  searchWordsLowercase?: string[]
): boolean
{
  const item = searchItem as IListItemGroup;
  return searchHit(item.header?.text, searchWordsLowercase)
    || searchHit(item.header?.secondary?.text, searchWordsLowercase)
    || searchHit(item.header?.secondary?.caption?.text, searchWordsLowercase)
    || searchHit(item.header?.secondary?.middle?.text, searchWordsLowercase)
    || searchHit(item.header?.caption?.text, searchWordsLowercase)
    || searchHit(item.header?.middle?.text, searchWordsLowercase);
}

function isFilterSearchForm(
  state: IList,
  searchItem: IListItemForm,
  searchWordsLowercase?: string[]
): boolean
{
  const layout = state.layout;
  const item = searchItem as IListItemForm;
  const valueMap = item.valueMap;

  let hit = false;
  layout && loopLayoutItemLine(layout, (payload) =>
  {
    if(hit)
    {
      return;
    }

    const {segment} = payload;
    if(segment.lineFieldIdSet?.length)
    {
      const lineFieldIdSetValueMap = segment.lineFieldIdSet.reduce((acc, fieldId) =>
      {
        acc[fieldId] = valueMap?.[fieldId];
        return acc;
      }, {} as FieldValues);

      const stringifyValueMap = JSON.stringify(lineFieldIdSetValueMap);
      hit = searchHit(stringifyValueMap, searchWordsLowercase);
    }
    else if(segment.lineVar?.value?.length)
    {
      const stringifyVarSet = JSON.stringify(segment.lineVar.value);
      hit = searchHit(stringifyVarSet, searchWordsLowercase);
    }
    else if(segment.line)
    {
      hit = searchHit(segment.line, searchWordsLowercase);
    }
  });

  return hit;
}
