import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../base/plus/ConstantsPlus";
import {px} from "../../../base/plus/StringPlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";

export type TypeGradient =
  | "top"
  | "bottom"
  | "left"
  | "right"

export default function RawLinerGradient(props: {
  type: TypeGradient,
  children?: React.ReactNode,
  visibilityHidden?: boolean,
  mt?: StandardCSSProperties["marginTop"],
  mb?: StandardCSSProperties["marginBottom"],
  ml?: StandardCSSProperties["marginLeft"],
  mr?: StandardCSSProperties["marginRight"],
})
{
  const {
    type,
    children,
    visibilityHidden
  } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        borderBottomLeftRadius: px(CHAT_ITEM_IMAGE_BORDER_RADIUS),
        borderBottomRightRadius: px(CHAT_ITEM_IMAGE_BORDER_RADIUS),
        ...visibilityHidden &&
        {
          visibility: "hidden"
        },
        ...getPositionSx(type),
        width: "100%",
        mt: props.mt,
        mr: props.mr,
        mb: props.mb,
        ml: props.ml
      }}
    >
      <LayoutFlexCol
        justifyContent={"end"}
        alignItems={"end"}
      >
        {children}
      </LayoutFlexCol>
    </Box>
  );
}

function getPositionSx(type: TypeGradient)
{
  switch(type)
  {
    case "top":
      return {
        top: 0,
        background: getLinerGradient(type)
      };
    case "bottom":
      return {
        bottom: 0,
        background: getLinerGradient(type)
      };
    case "left":
      return {
        left: 0,
        background: getLinerGradient(type)
      };
    case "right":
      return {
        right: 0,
        background: getLinerGradient(type)
      };
  }
}

function getLinerGradient(type: TypeGradient)
{
  switch(type)
  {
    case "bottom":
      return "linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(88,88,88,0) 100%)";
  }
}
