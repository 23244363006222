import {useTheme} from "@mui/material";
import React from "react";
import {DtoChatBubbleHeader} from "../../../../../api/home/base/dto/DtoChatBubbleHeader";
import {getMediaSrc} from "../../../../../base/plus/MediaPlus";
import {px} from "../../../../../base/plus/StringPlus";
import {TypeTextColor} from "../../../../../base/types/TypesGlobal";
import {BoxAPS} from "../../../box/BoxAPS";
import LayoutFlexCol from "../../../layout/LayoutFlexCol";
import LayoutFlexRow from "../../../layout/LayoutFlexRow";
import RawHighlighter from "../../../raw/RawHighlighter";
import BubbleRawDivider from "../BubbleRawDivider";

export default function BubbleRawFormTitle(props: {
  title?: string,
  titleColor?: TypeTextColor,
  searchWords?: string[],
  chatBubbleHeader?: DtoChatBubbleHeader;
  hideTitle?: boolean,
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const gapQuarter = theme.common.gapQuarter;
  const chatBubbleHeader = props.chatBubbleHeader;
  const searchWords = props.searchWords;
  const hideTitle = props.hideTitle;
  const title = props.title;
  const titleColor = props.titleColor ?? "textPrimary";

  if(chatBubbleHeader === undefined && hideTitle)
  {
    return null;
  }

  return (
    <>
      {
        chatBubbleHeader !== undefined &&
        <LayoutFlexRow
          width={"100%"}
          pl={px(gapHalf)}
          pr={px(gapHalf)}
        >

          <BoxAPS
            flexGrow={1}
            primary={{
              text: chatBubbleHeader.title
            }}
            secondary={{
              text: chatBubbleHeader.subTitle
            }}
            avatar={{
              icon: "grid",
              src: getMediaSrc(chatBubbleHeader.image?.mediaIdImage)
            }}
          />
        </LayoutFlexRow>
      }

      {
        chatBubbleHeader !== undefined &&
        <BubbleRawDivider />
      }

      {
        !hideTitle &&
        <LayoutFlexCol
          pb={px(theme.common.gapQuarter)}
          pt={px(gapQuarter)}
          pl={px(gapHalf)}
          pr={px(gapHalf)}
        >
          <RawHighlighter
            variant={"subtitle1"}
            color={titleColor}
            value={title}
            noWrap={false}
            searchWords={searchWords}
            breakWords={true}
            maxNumberOfLine={2}
          />
        </LayoutFlexCol>
      }
    </>
  );
}
