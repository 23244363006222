import {Collapse} from "@mui/material";
import {useTheme} from "@mui/material";
import {Typography} from "@mui/material";
import React from "react";
import {EnumDefnThemeColor} from "../../../api/meta/base/Types";
import {DividerHorizontal} from "../layout/DividerHorizontal";

// This is embedded util
export default function RawToast(props: {
  value: string,
  bgcolor: EnumDefnThemeColor
})
{
  const theme = useTheme();
  const gap = theme.spacing(2);
  const bgcolor = theme.common.color(props.bgcolor);
  return (
    // some bug in collapse gives extra padding
    <Collapse
      in={props.value !== undefined}
      sx={{
        p: 0,
        m: 0,
        bgcolor: bgcolor
      }}
    >
      <Typography
        paragraph
        sx={{
          pl: gap,
          pr: gap,
          pt: gap,
          bgcolor: bgcolor,
          color: theme.common.color("textInverse")
        }}
      >
        {props.value}
      </Typography>
      <DividerHorizontal />
    </Collapse>
  );
}



