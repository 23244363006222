import React from "react";
import {useFormContext} from "react-hook-form";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import RawLabel from "../../../atom/raw/RawLabel";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";

export default function FieldLabel(props: {
  defn: DefnFieldLabel
})
{
  const defn = props.defn;
  const {
    getFieldBold,
    getFieldItalic,
    getFieldStrikeThrough,
    getFieldUnderline,
    getFieldTextPattern,
    getFieldTextSize,
    getFieldOpacity
  } = useFieldPropertiesResolver(defn);

  const bold = getFieldBold();
  const italic = getFieldItalic();
  const strikeThrough = getFieldStrikeThrough();
  const underline = getFieldUnderline();
  const textSize = getFieldTextSize();
  const opacity = getFieldOpacity();
  const value = getFieldTextPattern() ?? getCompLabel(defn);

  const formCtx = useFormCtx();
  const formHookCtx = useFormContext();
  const fieldId = defn.metaId;

  const error = formHookCtx.getFieldState(fieldId)?.error;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";

  const ctxFormSection = useFormSectionCtx();
  const parentSection = ctxFormSection.getParent();
  const sectionDirection = parentSection.sectionDirection;

  return (
    <RawLabel
      defn={defn}
      isReport={isReport}
      error={error}
      bold={bold as boolean}
      italic={italic as boolean}
      strikeThrough={strikeThrough as boolean}
      underline={underline as boolean}
      value={value as string}
      textSize={textSize as string}
      opacity={opacity as number}
      sectionDirection={sectionDirection}
    />
  );
}
