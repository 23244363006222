import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../base/plus/ListPlus";
import {reducersPluginAside} from "./StoreStudioPluginAside";

export function reducersPlugin()
{
  return combineReducers({
    pluginVariableList: createListSlice("pluginVariableList", {defaultListItemType: "ps"}).reducer,
    pluginVersionList: createListSlice("pluginVersionList", {defaultListItemType: "p"}).reducer,
    pluginApiList: createListSlice("pluginApiList", {defaultListItemType: "ps"}).reducer,
    pluginResourceList: createListSlice("pluginResourcesList", {defaultListItemType: "ps"}).reducer,
    pluginAdminList: createListSlice("pluginAdminList", {defaultListItemType: "ps"}).reducer,
    pluginFormSectionList: createListSlice("pluginFormSectionList",
      {
        defaultListItemType: "p",
        showEmptyGroups: true
      }
    ).reducer,
    pluginFormList: createListSlice("pluginFormList").reducer,
    aside: reducersPluginAside()
  });
}
