import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {DtoMessageReplyPayloadCamera} from "../../../../api/home/base/dto/DtoMessageReplyPayloadCamera";
import {DtoMessageReplyPayloadDocument} from "../../../../api/home/base/dto/DtoMessageReplyPayloadDocument";
import {DtoMessageReplyPayloadGroup} from "../../../../api/home/base/dto/DtoMessageReplyPayloadGroup";
import {DtoMessageReplyPayloadImage} from "../../../../api/home/base/dto/DtoMessageReplyPayloadImage";
import {DtoMessageReplyPayloadLinkText} from "../../../../api/home/base/dto/DtoMessageReplyPayloadLinkText";
import {DtoMessageReplyPayloadLocation} from "../../../../api/home/base/dto/DtoMessageReplyPayloadLocation";
import {DtoMessageReplyPayloadReport} from "../../../../api/home/base/dto/DtoMessageReplyPayloadReport";
import {DtoMessageReplyPayloadText} from "../../../../api/home/base/dto/DtoMessageReplyPayloadText";
import {DtoMessageReplyPayloadUser} from "../../../../api/home/base/dto/DtoMessageReplyPayloadUser";
import {DtoMessageReplyPayloadVideo} from "../../../../api/home/base/dto/DtoMessageReplyPayloadVideo";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {hasFormatMarkdownText} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import {LayoutGap} from "../../layout/LayoutGap";
import LineTextPrimary from "../../line/LineTextPrimary";
import LineTextSecondary from "../../line/LineTextSecondary";
import RawIcon from "../../raw/RawIcon";
import RawImage from "../../raw/RawImage";
import RawSkeleton from "../../raw/RawSkeleton";

interface IReplyInfo
{
  previewSrcLeft?: string;
  primary?: string;
  secondary?: string;
  previewSrcRight?: string;
  senderColor?: string;
  icon?: string;
}

export default function BubbleRawReplyPreview(props: {
  replyPayload: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  onClickPreview?: CbMenuAnchor
})
{
  const theme = useTheme();
  const gapStd = theme.common.gapStd;
  const gapQuarter = theme.common.gapQuarter;
  const replyPayload = props.replyPayload;
  const replyInfo = props.replyInfo;
  const heightInbox = theme.common.heightInbox + (gapStd);

  if(!replyInfo)
  {
    return <Box
      width={"100%"}
      height={px(heightInbox)}
      mb={px(theme.common.bubbleShellGap)}
      sx={{
        display: "flex",
        borderRadius: px(gapQuarter)
      }}
    >
      <RawSkeleton
        variant="rounded"
        height={"100%"}
        width={"100%"}
      />
    </Box>;
  }

  const replyInfoData = getReplyInfo(replyPayload, replyInfo);
  const hasMarkdownInReplay = (replyPayload.messageType === "text" || replyPayload.messageType === "linkText")
    ? hasFormatMarkdownText(replyInfoData.secondary)
    : false;

  return (
    <Box
      width={"100%"}
      flexGrow={1}
      mb={px(theme.common.bubbleShellGap)}
      onClick={e => props.onClickPreview && props.onClickPreview(e.currentTarget)}
      bgcolor={"rgba(0,0,0,0.075)"}
      sx={{
        display: "flex",
        borderRadius: px(gapQuarter),
        borderLeftWidth: px(gapQuarter),
        borderLeftStyle: "solid",
        borderLeftColor: replyInfoData.senderColor || theme.palette.success.main,
        cursor: "pointer"
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: px(gapQuarter + 2)
        }}
      >
        <LineTextPrimary
          value={replyInfoData.primary}
          variant={"body2"}
          breakWords={true}
          color={replyInfoData.senderColor}
        />

        <LayoutFlexRow
          width={"100%"}
          justifyContent={"start"}
        >
          {
            replyInfoData.icon &&
            <>
              <RawIcon
                icon={replyInfoData.icon}
                disabled={true}
                width={px(gapStd)}
              />
              <LayoutGap width={px(theme.common.gapQuarter)} />
            </>
          }
          <LineTextSecondary
            value={replyInfoData.secondary}
            variant={"caption"}
            breakWords={true}
            hasMarkDownText={hasMarkdownInReplay}
            maxNumberOfLine={1}
          />
        </LayoutFlexRow>
      </Box>

      {
        replyInfoData.previewSrcRight &&
        <Box
          minWidth={px(heightInbox)}
          maxWidth={px(heightInbox)}
          height={"auto"}
        >
          <RawImage
            src={replyInfoData.previewSrcRight}
            height={"100%"}
            width={"100%"}
            objectFit={"cover"}
            borderTopRightRadius={px(gapQuarter)}
            borderBottomRightRadius={px(gapQuarter)}
            aspectRatio={"1"}
          />
        </Box>
      }
    </Box>
  );
}

function getReplyInfo(replyPayload: DtoMessageReplyPayload, replyInfo: IBubbleReplyInfo)
{
  const primary = replyInfo.senderName;
  let secondary;
  let previewSrcRight;
  let icon;

  switch(replyPayload.messageType)
  {
    case "camera":
    {
      const {mediaIdImage} = replyPayload as DtoMessageReplyPayloadCamera;
      secondary = "Camera";
      previewSrcRight = getMediaSrc(mediaIdImage);
      icon = "CameraAltRounded";
    }
      break;
    case "document":
    {
      const {fileName} = replyPayload as DtoMessageReplyPayloadDocument;
      secondary = fileName;
      icon = "InsertDriveFileRounded";
    }
      break;
    case "spreadsheetRow":
      secondary = "Form";
      icon = "GridOnRounded";
      break;
    case "image":
    {
      const {mediaId} = replyPayload as DtoMessageReplyPayloadImage;
      secondary = "Image";
      previewSrcRight = getMediaSrc(mediaId);
      icon = "InsertPhotoRounded";
    }
      break;
    case "linkText":
    {
      const {textSummary, imageUrl} = replyPayload as DtoMessageReplyPayloadLinkText;
      secondary = textSummary;
      previewSrcRight = imageUrl;
    }
      break;
    case "location":
    {
      const {mapImageUrl} = replyPayload as DtoMessageReplyPayloadLocation;
      secondary = "Location";
      previewSrcRight = mapImageUrl;
      icon = "PlaceRounded";
    }
      break;
    case "report":
    {
      const {reportName} = replyPayload as DtoMessageReplyPayloadReport;
      secondary = reportName;
      icon = "GridOnRounded";
    }
      break;
    case "spreadsheetPartition":
      secondary = "Form";
      icon = "GridOnRounded";
      break;
    case "text":
    {
      const {textSummary} = replyPayload as DtoMessageReplyPayloadText;
      secondary = textSummary;
    }
      break;
    case "user":
    {
      const {mediaIdAvatar, userName} = replyPayload as DtoMessageReplyPayloadUser;
      secondary = userName;
      icon = "PersonRounded";
      if(mediaIdAvatar)
      {
        previewSrcRight = getMediaSrc(mediaIdAvatar);
      }
    }
      break;
    case "video":
    {
      const {mediaId} = replyPayload as DtoMessageReplyPayloadVideo;
      secondary = "Video";
      icon = "VideocamRounded";
      previewSrcRight = getMediaSrc(mediaId);
    }
      break;
    case "voice":
      secondary = "Voice";
      icon = "MicRounded";
      break;
    case "audio":
      secondary = "Audio";
      icon = "AudiotrackRounded";
      break;
    case "group":
    {
      const {name} = replyPayload as DtoMessageReplyPayloadGroup;
      secondary = name;
      icon = "PeopleAltRounded";
    }
      break;
  }

  return {
    primary: primary,
    secondary: secondary,
    previewSrcRight: previewSrcRight,
    senderColor: replyInfo.senderColor,
    icon: icon
  } as IReplyInfo;
}
