import {FormLabel} from "@mui/material";
import {Box} from "@mui/system";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {Property} from "csstype";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";

interface IBorderLabel
{
  label: String,
  children: React.ReactNode,
  borderColor?: Property.BorderColor,
  onClick?: () => void,
  labelColor?: StandardCSSProperties["color"],
}

const HEIGHT_BORDER = "40px";

export const BorderLabel = (props: IBorderLabel) =>
{
  const {
    label,
    children,
    borderColor,
    onClick,
    labelColor
  } = props;
  return (
    <Box
      border={borderColor ? `1px solid ${borderColor}` : `1px solid ${theme.common.borderColor}`}
      borderRadius={px(gapQuarter)}
      width={"100%"}
      flexGrow={1}
      sx={{"&:hover": {border: "1px solid black"}}}
      onClick={onClick}
    >
      <FormLabel
        sx={{
          marginLeft: "0.71em",
          marginTop: "-0.71em",
          paddingLeft: "0.44em",
          paddingRight: "0.44em",
          backgroundColor: "white",
          position: "absolute",
          fontSize: "0.75em",
          width: "auto",
          color: labelColor
        }}
      >{label}</FormLabel>
      <Box
        sx={{
          position: "relative",
          borderRadius: px(gapQuarter),
          fontSize: "0.875rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: (theme) =>
            {
              return theme.spacing(1);
            },
            flexWrap: "wrap",
            overflow: "auto",
            height: HEIGHT_BORDER
          }}

        >
          {children}
        </Box>
        <fieldset
          aria-hidden={"true"}
          style={{
            textAlign: "left",
            position: "absolute",
            bottom: 0,
            right: 0,
            top: "-5px",
            left: 0,
            margin: 0,
            padding: "0 8px",
            pointerEvents: "none",
            borderRadius: "inherit",
            borderStyle: "solid",
            borderWidth: "1px",
            overflow: "hidden",
            minWidth: "0%",
            borderColor: "rgba(255, 255, 255, 0.23)"
          }}
        >
          <legend
            style={{
              float: "unset",
              overflow: "hidden",
              display: "block",
              width: "auto",
              padding: 0,
              height: "11px",
              fontSize: "0.75em",
              visibility: "hidden",
              maxWidth: "100%",
              transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
              whiteSpace: "nowrap"
            }}
          ><span>{label}</span></legend>
        </fieldset>
      </Box>
    </Box>
  );
};
