import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {ArtifactId} from "../../api/meta/base/Types";
import {TypeProductionUserFilterType} from "./TypeCacheControlPanel";
import {ActionSetProductionCurrentTab} from "./TypeCacheControlPanel";
import {ICacheProductionMap} from "./TypeCacheControlPanel";

export const sliceCacheControlPanel = createSlice({
  name: "productionEnt",
  initialState: {
    stateMap: {}
  } as ICacheProductionMap,
  reducers: {

    setProductionCurrentTab: (state, action: PayloadAction<ActionSetProductionCurrentTab>) =>
    {
      const {
        artifactId,
        currentStep,
        currentTab
      } = action.payload;

      if(currentTab && state.stateMap[artifactId]?.currentTab !== currentTab)
      {
        state.stateMap[artifactId] = {
          ...state.stateMap[artifactId],
          currentTab: currentTab
        };
      }

      if(currentStep && state.stateMap[artifactId]?.currentStep !== currentStep)
      {
        state.stateMap[artifactId] = {
          ...state.stateMap[artifactId],
          currentStep: currentStep
        };
      }
    },

    setProductionUserFilter(
      state: ICacheProductionMap,
      action: PayloadAction<{userFilterType: TypeProductionUserFilterType} & {artifactId: ArtifactId}>)
    {
      const {
        artifactId,
        userFilterType
      } = action.payload;

      if(userFilterType && state.stateMap[artifactId]?.userFilter !== userFilterType)
      {
        state.stateMap[artifactId] = {
          ...state.stateMap[artifactId],
          userFilter: userFilterType
        };
      }

    }

  }
});

export const {
  setProductionCurrentTab,
  setProductionUserFilter
} = sliceCacheControlPanel.actions;
