import {useEffect} from "react";
import {RowId} from "../../../api/meta/base/Types";
import {ILocationMarkerBinderAll} from "../../../base/types/TypeMap";
import {useAppSelector} from "../../app/AppHooks";

export default function useLocationPubSub<SR1, SR2, SR3>(props: {
  markerId: RowId,
  locationMarkerBinder?: ILocationMarkerBinderAll<SR1, SR2, SR3>,
})
{
  const markerId = props.markerId;
  const locationMarkerBinder = props.locationMarkerBinder;

  const selectSourceRow1 = locationMarkerBinder?.selectSourceRow1;
  const selectSourceRow2 = locationMarkerBinder?.selectSourceRow2;
  const selectSourceRow3 = locationMarkerBinder?.selectSourceRow3;

  const onBindSourceRow1 = locationMarkerBinder?.onBindSourceRow1;
  const onBindSourceRow2 = locationMarkerBinder?.onBindSourceRow2;
  const onBindSourceRow3 = locationMarkerBinder?.onBindSourceRow3;

  const sourceItem1 = useAppSelector(state => (selectSourceRow1 && markerId) ? selectSourceRow1(state,
    markerId
  ) : undefined);
  const sourceItem2 = useAppSelector(state => (selectSourceRow2 && markerId) ? selectSourceRow2(state,
    markerId
  ) : undefined);
  const sourceItem3 = useAppSelector(state => (selectSourceRow3 && markerId) ? selectSourceRow3(state,
    markerId
  ) : undefined);

  useEffect(() =>
  {
    if(onBindSourceRow1 && markerId)
    {
      onBindSourceRow1(markerId, sourceItem1);
    }
  }, [sourceItem1, onBindSourceRow1, markerId]);

  useEffect(() =>
  {
    if(onBindSourceRow2 && markerId)
    {
      onBindSourceRow2(markerId, sourceItem2);
    }
  }, [sourceItem2, onBindSourceRow2, markerId]);

  useEffect(() =>
  {
    if(onBindSourceRow3 && markerId)
    {
      onBindSourceRow3(markerId, sourceItem3);
    }
  }, [sourceItem3, onBindSourceRow3, markerId]);

  return null;
}
