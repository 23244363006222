import {FormControl} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {useMemo} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldSetOfLanguageKeys} from "../../../../api/meta/base/dto/FieldSetOfLanguageKeys";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import languages from "../../../atom/assets/Languages.json";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawAutocomplete from "../../../atom/raw/RawAutocomplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

interface ILanguage
{
  name: string,
  nativeName: string
}

function getOptions()
{
  if((languages as Record<string, ILanguage>) && typeof languages === "object")
  {
    return Object.entries(languages).map(([languageKey, language]) =>
    {
      return {
        metaId: languageKey,
        value: `${language.name}, ${language.nativeName}`
      } as DefnDtoOption;
    });
  }

  return [];
}

export default function FieldPickLanguage(props: {
  defn: DefnFieldEditable,
  multiSelect?: boolean
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const helperText = getFieldHelperText();
  const icon = getFieldIcon();

  const multiSelect = props.multiSelect;
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <RealLanguagePicker
              defn={defn}
              required={required}
              multiSelect={multiSelect}
              options={optionsToMapOfOption(getOptions())}
              fieldVariant={fieldVariant}
              error={error}
              isError={isError}
              helperText={helperText}
              placeHolder={placeHolder}
              valueLanguage={fieldValue as string}
              valueSetOfLanguage={fieldValue as FieldSetOfLanguageKeys}
              onChangeLanguage={(value) =>
              {
                field.onChange(value);
              }}
              onChangeLanguageSet={(value: FieldSetOfLanguageKeys) =>
              {
                field.onChange(value);
              }}
              fieldId={fieldId}
              icon={icon}
            />
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealLanguagePicker(props: {
  defn: DefnFieldEditable,
  fieldId: string,
  options: DefnStudioMapOfDtoOption,
  fieldVariant?: EnumDefnThemeFieldVariant,
  isError?: boolean,
  error?: FieldError,
  valueLanguage?: string,
  valueSetOfLanguage?: FieldSetOfLanguageKeys,
  multiSelect?: boolean,
  helperText?: string,
  placeHolder?: string,
  required?: boolean,
  icon?: string,
  onChangeLanguage: (value: string) => void,
  onChangeLanguageSet: (value: FieldSetOfLanguageKeys) => void
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const multiSelect = props.multiSelect;
  const valueLanguage = props.valueLanguage;
  const valueSetOfLanguage = props.valueSetOfLanguage;
  const defn = props.defn;
  const required = props.required;
  const icon = props.icon;
  const optionMap = props.options;
  const labelText = getCompLabel(defn);
  const label = labelText ?? "Pick language";
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () =>
    {
      getOnClick(getFieldKey(defn), "field");
    }
    : undefined;

  const value = useMemo(() =>
  {
    if(multiSelect)
    {
      return valueSetOfLanguage?.valueSet || [];
    }
    else
    {
      if(valueLanguage)
      {
        return valueLanguage;
      }
    }
  }, [multiSelect, optionMap, valueLanguage, valueSetOfLanguage]);

  return (
    <FormControl
      fullWidth
      variant={props.fieldVariant === "standard" ? "outlined" : props.fieldVariant}
      error={props.isError}
      disabled={disabled}
    >
      <LayoutFlexRow
        overflowX={"visible"}
        overflowY={"visible"}
      >
        <RawAutocomplete
          label={label}
          required={required}
          fieldId={props.fieldId}
          name={defn.name}
          error={props.error}
          hideLabel={defn.hideLabel}
          placeHolder={props.placeHolder}
          helperText={props.helperText}
          freeSolo={false}
          multiple={multiSelect}
          optionMap={optionMap}
          inputFieldSize={defnTheme.fieldSize}
          value={value as MetaIdOption | MetaIdOption[]}
          onChange={(data: MetaIdOption | MetaIdOption[] | null) =>
          {
            if(data)
            {
              if(multiSelect)
              {
                props.onChangeLanguageSet({
                  valueSet: data as MetaIdOption[]
                });
              }
              else
              {
                props.onChangeLanguage(data as MetaIdOption);
              }
            }
            else
            {
              if(multiSelect)
              {
                props.onChangeLanguageSet({
                  valueSet: []
                });
              }
              else
              {
                props.onChangeLanguage("");
              }
            }
          }}
          disabled={disabled}
          onClick={onClick}
          readOnly={readOnly}
          icon={icon}
        />
        <FieldRawRefButton defn={defn} />
      </LayoutFlexRow>
      {props.isError &&
        <FormHelperText
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexGrow: 1
          }}
        >{props.error?.message}
        </FormHelperText>
      }
    </FormControl>
  );
}
