import {Options} from "browser-image-compression";
import imageCompression from "browser-image-compression";
import {copyImageToClipboard} from "copy-image-clipboard";
import {requestClipboardWritePermission} from "copy-image-clipboard";
import {canCopyImagesToClipboard} from "copy-image-clipboard";
import {FastAverageColor} from "fast-average-color";
import {Accept} from "react-dropzone";
import {ENT_ID_GLOBAL} from "../../api/meta/base/ApiPlus";
import {createMediaIdThumbnail} from "../../api/meta/base/ApiPlus";
import {nextMediaIdAvatar} from "../../api/meta/base/ApiPlus";
import {EntId} from "../../api/meta/base/Types";
import {MediaId} from "../../api/meta/base/Types";
import {MediaIdAvatar} from "../../api/meta/base/Types";
import {THUMBNAIL_SIZE} from "../../api/meta/base/Types";
import {THUMBNAIL_MAX_MB} from "../../api/meta/base/Types";
import {MediaIdThumbnail} from "../../api/meta/base/Types";
import {mediaCall} from "../../api/nucleus/base/IMediaCall";
import {getErrorMessage} from "../../api/nucleus/base/Protocol";
import {Srvc} from "../../srvc/Srvc";
import {store} from "../../Store";
import {CbProgress} from "../types/TypesGlobal";
import {CbUpload} from "../types/TypesGlobal";
import {IResultCompressImage} from "../types/TypesGlobal";
import {logError} from "../util/AppLog";
import {hostPortProvider} from "./HostPortPlus";
import {getHttpProtocol} from "./SysPlus";
import {getFileType} from "./SysPlus";

export const VALID_FILE_TYPES: Accept = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/svg+xml": [".svg"],
  "image/vnd.dwg": [".dwg"], // DWG
  "audio/*": [".mp3", ".wav", ".oga", ".ogg"],
  "video/*": [".mp4", ".avi", ".mov", ".webm", ".gif"],
  "text/csv": [".csv"],
  "text/plain": [".txt"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"], // Excel
  "application/vnd.ms-powerpoint": [".ppt"], // PPT
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"], // PPTX
  "application/vnd.sun.xml.draw.template": [".std"], // Std
  "application/vnd.ms-outlook": [".msg"], // Outlook
  "application/vnd.ms-excel": [".xls"],
  "application/zip": [".zip"],
  "application/xml": [".xml"],
  "application/json": [".json"],
  "application/java-archive": [".jar"],
  "application/pdf": [".pdf"],
  "application/x-revit": [".rvt"], // Revit
  "application/octet-stream": [".dll"],
  "message/rfc822": [".eml"] // Mail
};

const fac = new FastAverageColor();

export function compressImage(
  file: File,
  maxSizeMB: number,
  maxWidthOrHeight: number,
  cbResult: (compressedFile: IResultCompressImage) => void)
{
  const options = {
    maxSizeMB: maxSizeMB,
    maxWidthOrHeight: maxWidthOrHeight,
    useWebWorker: true,
    alwaysKeepResolution: true
  } as Options;

  imageCompression(file, options)
  .then((compressedFile: File) =>
  {
    const url = URL.createObjectURL(file);
    const newImg = new Image();
    newImg.onload = function()
    {
      const imgHeight = newImg.naturalHeight;
      const imgWidth = newImg.naturalWidth;
      URL.revokeObjectURL(url);
      cbResult({
        file: compressedFile,
        size: {
          width: imgWidth,
          height: imgHeight
        }
      });
    };

    newImg.src = url; // this must be done AFTER setting onload
  })
  .catch(function(error)
  {
    cbResult({
      error: error.message
    });
  });
}

export function getPrimaryColorOfImage(file: File, cb?: (primaryColor?: string) => void): Promise<string>
{
  return new Promise((resolve, reject) =>
  {
    if(getFileType(file) !== "image")
    {
      reject(new Error("getPrimaryColorOfImage"));
    }
    fac.getColorAsync(window.URL.createObjectURL(file), {
      ignoredColor: [255, 255, 255, 255], // white,
      mode: "precision"
    })
    .then(color =>
    {
      resolve(color.hex);
      cb?.(color.hex);
    })
    .catch(e =>
    {
      logError("MediaPlus", e);
      reject();
      cb?.(undefined);
    });
  });
}

export function getBlurredImage(file: File, cb?: (blob: Blob) => void): Promise<Blob>
{
  // #1 compressing image          -> it will compress the image 6MB to 700KB
  // #2 blurring compressed image  -> it will compress the image 700KB to 70KB
  // #3 compressing blurred  image  -> it will compress the image 70KB to 7KB

  const IMAGE_BLURRED_MAX_MB = 1.5;
  const IMAGE_BLURRED_SIZE_MAX = 150;

  return new Promise((resolve, reject) =>
  {
    compressImage(
      file,
      IMAGE_BLURRED_MAX_MB,
      IMAGE_BLURRED_SIZE_MAX,
      resultCompressedFile =>
      {
        if(resultCompressedFile.file)
        {
          const compressedFile = resultCompressedFile.file;
          const img = new Image();
          img.src = URL.createObjectURL(compressedFile);
          img.onload = function()
          {
            const c = document.createElement("canvas");
            if(c)
            {
              const ctx = c.getContext("2d");
              c.width = img.width;
              c.height = img.height;
              if(ctx)
              {
                ctx.imageSmoothingEnabled = false;
                ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height);
              }
              c.toBlob(blob =>
              {
                if(blob)
                {
                  compressImage(
                    blob as File,
                    IMAGE_BLURRED_MAX_MB,
                    1000,
                    resultCompressedBlurredFile =>
                    {
                      if(resultCompressedBlurredFile.file)
                      {
                        cb && cb(resultCompressedBlurredFile.file as Blob);
                        resolve(resultCompressedBlurredFile.file as Blob);
                      }
                    }
                  );
                }
                else
                {
                  reject();
                }
              });
            }
          };
        }
      }
    );
  });
}

export function getVideoMetaData(
  file: File,
  cb?: (thumbnail: Blob, duration: number, width: number, height: number) => void)
{
  function createThumbCanvas(video: HTMLVideoElement, w: number, h: number)
  {
    const c = document.createElement("canvas"),    // create a canvas
      ctx = c.getContext("2d");                   // get context
    c.width = w;                                 // set size = thumb
    c.height = h;
    ctx?.drawImage(video, 0, 0, w, h);            // draw in frame
    return c;                                    // return canvas
  }

  const video = document.createElement("video");

  video.setAttribute(
    "src",
    window.URL.createObjectURL(file)
  );
  video.preload = "metadata";
  video.currentTime = 1;// getting snapshot of 1st second of video
  video.addEventListener("loadeddata", () =>
  {
    const canvas = createThumbCanvas(video, video.videoWidth, video.videoHeight);
    if(canvas)
    {
      canvas.toBlob((blob) =>
      {
        cb && cb(
          blob as Blob,
          video.duration,
          video.videoWidth,
          video.videoHeight
        );
      });
    }
  });
}

export interface IUploadAvatar
{
  entId: EntId,
  blob: Blob,
  cbSuccess?: CbUpload,
  cbError?: CbProgress
}

function mediaUpload(
  entId: EntId,
  mediaId: MediaId,
  file: File,
  cbSuccess?: CbUpload,
  cbError?: CbProgress
)
{
  const rootState = store.getState();
  const entUserId = rootState.cache.app.caller.entUserIdMap[entId];

  mediaCall(entId, "doc", mediaId)
  .upload(file, (envSig) =>
    {
      if(Srvc.app.toast.showErrorToast(envSig))
      {
        cbError && cbError(getErrorMessage(envSig.error));
      }
      else
      {
        cbSuccess && cbSuccess(mediaId);
      }
    },
    file.name,
    entUserId
  );

}

export function uploadMedia(
  entId: EntId,
  mediaId: MediaId,
  file: File,
  cbSuccess?: CbUpload,
  cbError?: CbProgress)
{
  mediaUpload(entId,
    mediaId,
    file,
    cbSuccess,
    cbError
  );
}

export function uploadAvatar(params: IUploadAvatar)
{
  const mediaIdAvatar = nextMediaIdAvatar();

  mediaUpload(params.entId,
    mediaIdAvatar,
    (params.blob as File),
    (mediaId) =>
    {
      const mediaIdThumbnail = createMediaIdThumbnail(mediaId);
      uploadThumbnail(params.entId, params.blob, mediaIdThumbnail, (error =>
      {
        if(error)
        {
          params.cbError && params.cbError(error);
        }
        else
        {
          params.cbSuccess && params.cbSuccess(mediaIdAvatar);
        }
      }));
    },
    params.cbError
  );
}

export function uploadThumbnail(entId: EntId, blob: Blob, mediaIdThumbnail: MediaIdThumbnail, cbProgress: CbProgress)
{
  const file = new File([blob], mediaIdThumbnail, {
    type: "image/jpeg",
    lastModified: new Date().getTime()
  });

  compressImage(file, THUMBNAIL_MAX_MB, THUMBNAIL_SIZE,
    compressedFile =>
    {
      if(compressedFile.error)
      {
        cbProgress(compressedFile.error);
        return;
      }

      const compressedBlob = compressedFile.file;
      if(compressedBlob)
      {
        mediaUpload(entId,
          mediaIdThumbnail,
          (blob as File),
          (mediaId) =>
          {
            cbProgress();
          },
          cbProgress
        );
      }
      else
      {
        cbProgress();
      }
    }
  );
}

export function getImageThumbnail(mediaId?: MediaIdAvatar, entId?: EntId): string | undefined
{
  return mediaId
    ? getMediaSrc(createMediaIdThumbnail(mediaId), entId)
    : undefined;
}

export function getMediaSrc(mediaId?: MediaId, _entId?: EntId): string | undefined
{
  if(mediaId === undefined)
  {
    return undefined;
  }

  let entId = _entId;
  if(entId === undefined)
  {
    entId = ENT_ID_GLOBAL;
  }

  const hostPort = hostPortProvider.getHostPort();
  return `${getHttpProtocol()}://${hostPort}/${entId}/doc/${mediaId}`;
}

export async function getPrimaryColorAndBlurredImg(
  originalImg: File,
  cb: (primaryColor?: string, blurredImage?: File) => void)
{
  const primaryColor = await getPrimaryColorOfImage(originalImg);
  const blurredImage = await getBlurredImage(originalImg);
  cb(primaryColor, blurredImage as File);
}

export class MediaStore
{
  private static readonly mediaStore = new Map<MediaId, string>();

  static setBlobToUrl(mediaId: MediaId, file: File)
  {
    const url = URL.createObjectURL(file);
    if(url)
    {
      this.mediaStore.set(mediaId, url);
    }
  }

  static removeMedia(mediaId: MediaId)
  {
    this.mediaStore.delete(mediaId);
  }

  static getMedia(mediaId: MediaId): string | undefined
  {
    return this.mediaStore.get(mediaId);
  }

  static async getMediaBlobs(mediaIdSet: string[])
  {
    const fileMap: Record<string, File> = {};

    for(const mediaId of mediaIdSet)
    {
      const url = this.mediaStore.get(mediaId);
      if(url)
      {
        try
        {
          const response = await fetch(url);
          const blob = await response.blob() as File;
          if(blob)
          {
            fileMap[mediaId] = new File([blob], blob.name || mediaId, {type: blob.type});
          }
        }
        catch(error)
        {
          console.error(`Error fetching blob for mediaId ${mediaId}:`, error);
        }
      }
    }

    return fileMap;
  }

  static clear()
  {
    this.mediaStore.clear();
  }
}

export async function copyImgToClipboard(imgUrl: string)
{
  if(!canCopyImagesToClipboard())
  {
    requestClipboardWritePermission().then((hasPermission) =>
    {
      if(hasPermission)
      {
        copyImageToClipboard(imgUrl);
      }
    });
  }
  else
  {
    copyImageToClipboard(imgUrl);
  }
}



