import {FieldValues} from "react-hook-form";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioBuildColor} from "../../../../../api/meta/base/dto/DefnStudioBuildColor";
import {StudioFieldColor} from "../../../../../api/meta/base/dto/StudioFieldColor";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyShowLabel} from "../../base/TypesFormBuilder";
import {propKeyColor} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";

export function getDefnFieldColor(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap3,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarHelperText
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyShowLabel,
    propKeyColor,
    fieldGap2,
    propKeyHelperTextValue
  ];
  return {
    ...getFieldBuilderPropertySubTabs(
      "color",
      formId,
      "color",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),
    [propKeyShowLabel]: {
      type: "label",
      label: "Default value",
      name: propKeyShowLabel,
      metaId: propKeyShowLabel,
      disabled: true
    } as DefnFieldLabel,
    [propKeyColor]: {
      type: "color",
      metaId: propKeyColor,
      label: "Color",
      allowShades: true
    } as DefnStudioBuildColor

  };
}

export function defnValueToStudioFieldColor(values: FieldValues): StudioFieldColor
{
  return {
    type: "color",
    defaultValue: fnFieldValueToRawValue("color", values[propKeyColor]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId])
  } as StudioFieldColor;
}

export function studioFieldToDefnValueColor(studioField: StudioFieldColor)
{
  return {
    [propKeyColor]: fnRawValueToFieldValue("color", studioField.defaultValue),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId)
  };
}
