import styled from "@emotion/styled";
import {IconButton} from "@mui/material";
import {Box} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {Size} from "react-virtualized-auto-sizer";
import AutoSizer from "react-virtualized-auto-sizer";
import {VirtuosoGrid} from "react-virtuoso";
import {px} from "../../base/plus/StringPlus";
import theme from "../../base/plus/ThemePlus";
import helperTextData from "../atom/assets/PlaceholderTextHome.json";
import DialogAtom from "../atom/dialog/DialogAtom";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import {LayoutGap} from "../atom/layout/LayoutGap";
import {LinePrimary} from "../atom/line/LinePrimary";
import RawNothingHere from "../atom/raw/RawNothingHere";
import RawSearch from "../atom/raw/RawSearch";
import RawTooltip from "../atom/raw/RawTooltip";
import {usePageCtx} from "../ctx/CtxPage";

const dialogHeight = 600;
const dialogWidth = 576;

export default function DialogIconPicker(props: {
  value?: string,
  title?: string,
  onClickOk?: (value: string) => void
})
{
  const {
    value,
    title,
    onClickOk
  } = props;
  const pageCtx = usePageCtx();
  return (
    <DialogAtom
      title={title ?? "Icon picker"}
      isOverflowHidden={true}
      onClose={() =>
      {
        pageCtx.showDialog(undefined);
      }}
      contentWidth={dialogWidth}
      contentHeight={dialogHeight}
      content={
        <DialogIconPickerContent
          selected={value}
          onClick={(value) =>
          {
            pageCtx.showDialog(undefined);
            onClickOk && onClickOk(value);
          }
          }
        />
      }
    />
  );
}

function DialogIconPickerContent(props: {
  selected?: string
  onClick?: (value: string) => void,
})
{
  const [iconSearchValue, setIconSearchValue] = useState<string>("");
  const [filteredIconList, setFilteredIconList] = useState<string[]>();
  const [allIconList, setAllIconList] = useState<any>();

  const gapHalf = theme.common.gapHalf;
  const bgcolorSelection = theme.common.bgcolorSelection;

  useEffect(() =>
  {
    import(`@mui/icons-material`).then(icons =>
    {
      setAllIconList(icons);
    });
  }, []);

  useEffect(() =>
  {
    if(allIconList)
    {
      setFilteredIconList(Object.keys(allIconList).filter((icon) =>
      {
        if(!(icon.toLowerCase().includes("rounded")))
        {
          return false;
        }
        if(!iconSearchValue)
        {
          return true;
        }
        const _icon = icon + "icon";
        return _icon.toLowerCase().includes(iconSearchValue.toLowerCase());
      }));
    }

  }, [iconSearchValue, allIconList]);

  return (
    <Box
      height={px(dialogHeight)}
      width={px(dialogWidth)}
      bgcolor={theme.common.bgcolorContent}
    >
      <Box
        flexGrow={1}
        width={"100%"}
        display={"flex"}
        position={"relative"}
        bgcolor={"bisque"}
      >
        <RawSearch
          placeHolder={"Search icons"}
          onSearch={(value) =>
          {
            setIconSearchValue(value);
          }}
          bgColor={theme.common.bgcolorContent}
        />
      </Box>
      <LayoutGap
        width={"100%"}
        height={gapHalf}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          alignItems: "start",
          overflowY: "auto",
          width: "100%",
          height: "100%"
        }}
      >

        {filteredIconList === undefined &&
          <RawNothingHere helperTextData={helperTextData.loadingData} />}
        {(filteredIconList !== undefined && filteredIconList.length === 0) &&
          <RawNothingHere />}

        {filteredIconList && filteredIconList?.length > 0 && (
          <AutoSizer>
            {({
              height,
              width
            }: Size) =>
            {
              return <VirtuosoGrid
                style={{
                  height: height,
                  width: width
                }}
                totalCount={filteredIconList?.length}
                components={{
                  Item: ItemContainer,
                  // @ts-ignore
                  List: ListContainer
                }}
                itemContent={(index) =>
                {
                  const iconName = filteredIconList[index];
                  const Icon: any = allIconList[iconName];
                  return (
                    <LayoutFlexCol
                      key={iconName}
                      padding={px(gapHalf)}
                      bgcolor={props.selected === iconName ? bgcolorSelection : undefined}
                    >
                      <RawTooltip
                        title={iconName}
                      >
                        <IconButton
                          size={"large"}
                          onClick={() =>
                          {
                            props.onClick && props.onClick(iconName);
                          }}
                        >
                          <Icon />
                        </IconButton>
                      </RawTooltip>
                      <LinePrimary
                        primary={{
                          text: iconName,
                          variant: "caption"
                        }}
                      />
                    </LayoutFlexCol>
                  );
                }}
              />;
            }}
          </AutoSizer>
        )}
      </Box>
    </Box>
  );
}

const gapHalf = theme.common.gapHalf;

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex: none;
  align-items: stretch;
  overflow: hidden;
`;

const ListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${64}px, 1fr));
  gap: ${px(gapHalf)};

  :first-of-type
  {
    padding-left: ${px(gapHalf)};
    margin-top: ${px(gapHalf)};
  }

  :last-of-type
  {
    padding-right: ${px(gapHalf)};
  }
`;
