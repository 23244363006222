import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {SigLastMessage} from "../../../../api/home/main/sig/SigLastMessage";
import {ChatId, EntId} from "../../../../api/meta/base/Types";
import {formatCaptionDateTime} from "../../../../base/plus/DatePlus";
import {toComboId} from "../../../../base/types/TypesComboId";
import {ILastMessage} from "./TypesCacheHomeDrawerMsgLast";
import {ICacheLastMessageState} from "./TypesCacheHomeDrawerMsgLast";

export const sliceCacheHomeDrawerMsgLast = createSlice({
  name: "cacheHomeDrawerMsgLast",
  initialState: {
    lastMessageMap: {},
    lastMessageMsgIdToChatIdMap: {}
  } as ICacheLastMessageState,
  reducers: {
    setLastMessage: (state, action: PayloadAction<SigLastMessage>) =>
    {
      const sig = action.payload;
      const entChatId = toComboId(sig.entId, sig.chatId);

      state.lastMessageMap[entChatId] = {
        messageId: sig.messageId,
        messageOffset: sig.messageOffset,
        messageSummary: sig.messageSummary,
        messageType: sig.messageType,
        messageTime: formatCaptionDateTime(new Date(sig.messageTime)),
        receiptStatus: sig.receiptStatus,
        senderId: sig.senderId
      } as ILastMessage;
      state.lastMessageMsgIdToChatIdMap[sig.messageId] = entChatId;
    },
    setLastMessageReceiptStatus: (
      state,
      action: PayloadAction<{entId: EntId, chatId: ChatId, receiptStatus: EnumReceiptStatus}>) =>
    {
      const payload = action.payload;
      const entChatId = toComboId(payload.entId, payload.chatId);
      state.lastMessageMap[entChatId] = {
        ...state.lastMessageMap[entChatId],
        receiptStatus: payload.receiptStatus
      } as ILastMessage;
    },
    removeLastMessage: (state, action: PayloadAction<{entId: EntId, chatId: ChatId}>) =>
    {
      const payload = action.payload;
      const entChatId = toComboId(payload.entId, payload.chatId);
      const lastMessage = state.lastMessageMap[entChatId];
      if(lastMessage)
      {
        delete state.lastMessageMsgIdToChatIdMap[lastMessage.messageId];
      }
      delete state.lastMessageMap[entChatId];

    }
  }
});

export const {
  setLastMessage,
  setLastMessageReceiptStatus,
  removeLastMessage
} = sliceCacheHomeDrawerMsgLast.actions;
