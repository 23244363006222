import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawSkeleton from "../raw/RawSkeleton";
import LineCaption from "./LineCaption";
import LineTextPrimary from "./LineTextPrimary";

export function LinePrimary3Text(props: {
  primary?: ILinePrimary
  searchWords?: string[],
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  mb?: number
})
{
  const theme = useTheme();
  const lineHeight = px(theme.common.heightLine) as string;
  const primary = props.primary;
  const gapQuarter = theme.common.gapQuarter;

  if(primary)
  {
    const caption = primary.caption;
    const middle = primary.middle;

    return (
      <LayoutFlexRow
        width={"100%"}
        height={lineHeight}
        mb={px(props.mb)}
        justifyContent={"space-between"}
      >

        <LineTextPrimary
          value={primary.text}
          optional={primary.optional}
          variant={primary.variant}
          color={primary.color}
          searchWords={props.searchWords}
          height={"100%"}
          width={"40%"}
          bold={primary.bold}
        />

        {middle &&
          <LineCaption
            caption={middle}
            height={"100%"}
            width={"30%"}
            textAlign={"left"}
            ml={gapQuarter}
          />
        }

        {caption &&
          <LineCaption
            caption={caption}
            searchWords={props.searchWords}
            height={"100%"}
            width={"30%"}
            textAlign={"right"}
            onClick={caption?.ignoreSelection ? undefined : props.onClickCaption}
            ml={gapQuarter}
          />
        }

      </LayoutFlexRow>
    );
  }
  else
  {
    return (
      <RawSkeleton
        variant="text"
        height={lineHeight}
        width={"100%"}
        mb={props.mb}
      />
    );
  }
}
