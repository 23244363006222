import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdFuncArg} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfFuncArg} from "../../../../api/meta/base/dto/DefnStudioMapOfFuncArg";
import {StudioDtoFuncArg} from "../../../../api/meta/base/dto/StudioDtoFuncArg";
import {StudioMapOfFuncArg} from "../../../../api/meta/base/dto/StudioMapOfFuncArg";
import {MetaIdFuncArg} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import {DialogVariableFunctionInputArgument} from "../../../dialog/DialogVariableFunctionInputArgument";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfFuncArg(props: {
  defn: DefnStudioMapOfFuncArg,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnFieldFunctionInputArguments = props.defn;
  const fieldId = getFieldKey(defnFieldFunctionInputArguments);

  // const isLastField = formCtx.isLastFieldInDisplay(defnFieldFunctionInputArguments.metaId);
  const isLastField = false;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const formReadOnly = formCtx.isReadonly();
  const isReport = defnTheme.formVariant === "report";
  const label = getLabel(defnFieldFunctionInputArguments);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      defaultValue={convertArrayToMap([])}
      render={({
        field
      }) =>
      {
        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={<FieldLabel defn={defnLabel} />}
              right={
                <RawFunctionInputArgument
                  fieldId={fieldId}
                  onChange={field.onChange}
                  isFormReadOnly={formReadOnly}
                  fieldValue={field.value}
                  isLastField={isLastField}
                />
              }
            />
          );
        }

        return (
          <RawFunctionInputArgument
            fieldId={fieldId}
            isFormReadOnly={formReadOnly}
            onChange={field.onChange}
            fieldValue={field.value}
            isLastField={isLastField}
          />
        );
      }
      }
    />
  );
}

function RawFunctionInputArgument(props: {
  fieldId: MetaIdField,
  isFormReadOnly?: boolean,
  fieldValue: StudioMapOfFuncArg,
  isLastField?: boolean,
  onChange: (value: StudioMapOfFuncArg) => void
})
{
  const {
    fieldId,
    isLastField,
    isFormReadOnly
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<StudioDtoFuncArg>;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: StudioDtoFuncArg[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = (selectList: SelectList, fieldValue: StudioDtoFuncArg[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue?.length)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.metaId;
        uiItemIds.push(listId);

        const name = value?.name ? value.name : "";
        const type = value?.funcArgKind ? value.funcArgKind : "";
        const required = value?.required ? value.required : false;

        uiItemsById[listId] = {
          type: "p",
          primary: {
            text: `${name}${!required ? "?" : ""}`,
            caption: {
              text: type,
              ignoreSelection: true
            }
          },
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemAPSA;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogVariableFunctionInputArgument
          onClickOk={(newValue) => cbRefList.addItem({
            ...newValue,
            metaId: nextMetaIdFuncArg()
          })}
          isFormReadonly={isFormReadOnly}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioDtoFuncArg
        : undefined;

      if(oldValue?.metaId)
      {
        pageCtx.showDialog(
          <DialogVariableFunctionInputArgument
            values={oldValue}
            isFormReadonly={isFormReadOnly}
            onClickOk={(newValue) => cbRefList.updateItem({
              ...newValue,
              metaId: oldValue?.metaId
            }, key)}
            validationError={validationError}
          />
        );
      }
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <LayoutFlexRow
          width={"100%"}
          justifyContent={"flex-end"}
        >
          <RawButtonStrip
            iconButtonList={["add"]}
            onClick={onClickIconBtn}
            iconButtonDisable={isFormReadOnly ? ["add"] : []}
          />
        </LayoutFlexRow>
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(map?: StudioMapOfFuncArg): StudioDtoFuncArg[]
{
  if(!map?.keys)
  {
    return [];
  }
  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoFuncArg[]): StudioMapOfFuncArg
{
  const keys = [] as MetaIdFuncArg[];
  const map = {} as Record<MetaIdFuncArg, StudioDtoFuncArg>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfFuncArg;
}
