import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickRole} from "../../../../../api/meta/base/dto/DefnFieldPickRole";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {StudioFieldPickRole} from "../../../../../api/meta/base/dto/StudioFieldPickRole";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyDefaultRoleFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultRoleId} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldPickRole(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  includeFieldIdSet?: MetaIdField[],
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{

  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultRoleFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultRoleId,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "pickRole",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDefaultRoleId]: {
      type: "pickRole",
      name: propKeyDefaultRoleId,
      metaId: propKeyDefaultRoleId,
      label: "Default value"
    } as DefnFieldPickRole,

    [propKeyDefaultRoleFieldId]: {
      type: "pickFieldId",
      metaId: propKeyDefaultRoleFieldId,
      name: propKeyDefaultRoleFieldId,
      label: "Default value",
      formId: formId,
      filterFieldTypeSet: ["pickRole"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      includeFieldIdSet: includeFieldIdSet
    } as DefnStudioPickFieldId
  };
}

export function defnValueToStudioFieldPickRole(values: FieldValues): StudioFieldPickRole
{
  return {
    type: "pickRole",
    defaultRoleId: fnFieldValueToRawValue("pickRole", values[propKeyDefaultRoleId]),
    defaultRoleFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultRoleFieldId])
  } as StudioFieldPickRole;
}

export function studioFieldPickRoleToDefnValue(studioField: StudioFieldPickRole): FieldValues
{
  return {
    [propKeyDefaultRoleId]: fnRawValueToFieldValue("pickRole", studioField.defaultRoleId),
    [propKeyDefaultRoleFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultRoleFieldId)
  };
}
