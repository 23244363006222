import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {ILineHeader} from "../../../base/types/TypesGlobal";
import {TypeEnumCaptionButton} from "../../list/List";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import {LineHeader} from "../line/LineHeader";

export function BoxHeader(props: {
  header?: ILineHeader,
  searchWords?: string[],
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  flexGrow?: StandardCSSProperties["flexGrow"],
  onClickPrefixIcon?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickIconButtonCaption?: (e: React.MouseEvent<HTMLButtonElement>, type: TypeEnumCaptionButton) => void,
})
{
  const theme = useTheme();

  const mt = props.mt ?? 0;
  const mb = props.mb ?? 0;
  let itemWithSecondaryHeight = getListItemHeightAPSA("ps");

  const heightLine = theme.common.heightLine + mt + mb;
  itemWithSecondaryHeight += mt + mb;
  return (
    <LayoutFlexCol
      mt={px(mt)}
      mb={px(mb)}
      ml={px(props.ml)}
      mr={px(props.mr)}
      flexGrow={props.flexGrow}
      height={px(props.header?.secondary ? itemWithSecondaryHeight : heightLine)}
      flexShrink={1}
    >

      <LineHeader
        header={props.header}
        searchWords={props.searchWords}
        onClickCaption={props.onClickCaption}
        onClickIconButtonCaption={props.onClickIconButtonCaption}
        onClickPrefixIcon={props.onClickPrefixIcon}
      />

    </LayoutFlexCol>
  );
}
