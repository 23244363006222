import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioBuildActionPermission} from "../../../../api/meta/base/dto/DefnStudioBuildActionPermission";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {StudioDtoActionPermission} from "../../../../api/meta/base/dto/StudioDtoActionPermission";
import {StudioMapOfActionPermission} from "../../../../api/meta/base/dto/StudioMapOfActionPermission";
import {MetaIdAction} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {insertSystemRolesStudio} from "../../../../base/plus/StudioPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogAssignAction from "../../../dialog/DialogAssignAction";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfActionPermission(props: {
  defn: DefnStudioBuildActionPermission
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();

  const isLastField = false;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const label = defn.label;

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfActionPermission;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfAction
                  fieldId={fieldId}
                  formStore={formStore}
                  isFormReadOnly={formReadOnly}
                  onChange={onChange}
                  isLastField={isLastField}
                  fieldValue={fieldValue}
                  defn={defn}
                />
              }
            />
          );
        }

        return (
          <RawSetOfAction
            fieldId={fieldId}
            formStore={formStore}
            isFormReadOnly={formReadOnly}
            onChange={onChange}
            isLastField={isLastField}
            fieldValue={fieldValue}
            defn={defn}
          />
        );
      }
      }
    />
  );
}

function RawSetOfAction(props: {
  defn: DefnStudioBuildActionPermission,
  fieldId: MetaIdField,
  fieldValue: StudioMapOfActionPermission,
  isFormReadOnly?: boolean,
  formStore?: FormStore,
  isLastField?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption,
  onChange: (value: StudioMapOfActionPermission) => void,
})
{
  const {
    fieldId,
    formStore,
    isLastField,
    isFormReadOnly,
    defn
  } = props;

  const allowGrouping = defn.allowGrouping;
  const allowShowMessageTooltip = defn.allowShowMessageTooltip;
  const includeActionIdSet = defn.includeActionIdSet;
  const allowSystemRoles = defn.allowSystemRoles;
  const includeOptionMap = defn.includeOptionMap;
  const isGroupAction = defn.isGroupAction;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<StudioDtoActionPermission>;

  const actionMap = formStore?.actionMap;
  const roleMap = insertSystemRolesStudio(formStore?.roleMap);
  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const trash = formStore?.trash;
  const trashActionMap = trash?.actionMap;

  const onChange = (fieldValue: StudioDtoActionPermission[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = (selectList: SelectList, fieldValue: StudioDtoActionPermission[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {

        const listId = value.metaId;
        uiItemIds.push(listId);

        const removedAction = trashActionMap
          ? trashActionMap[listId]
          : undefined;

        const removedActionName = removedAction?.details.name;

        const actionName = actionMap?.map[listId]?.details.name;

        const roleNameList = [] as string[];
        value.roleIdSet?.forEach((metaIdRole) =>
        {
          const role = roleMap.map[metaIdRole];
          if(role)
          {
            roleNameList.push(role.details.name);
          }
        });

        const menuGroup = value?.menuGroup ?? "";

        uiItemsById[listId] = {
          type: "mpsl",
          primary: {
            text: actionName || removedActionName,
            caption: {
              text: menuGroup
            },
            color: removedActionName ? "errorDark" : undefined
          },
          secondaryList: [
            {
              text: roleNameList.length > 0
                ? roleNameList.join(", ")
                : "No roles"
            }
          ],
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemMPSL;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogAssignAction
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          allowGrouping={allowGrouping}
          includeActionIdSet={includeActionIdSet}
          onClickOk={(newValue) =>
          {
            cbRefList.addItem(newValue);
          }}
          excludeActionIdSet={fieldValue
            ? fieldValue.map((value) => value.metaId)
            : undefined}
          allowSystemRoles={allowSystemRoles}
          includeOptionMap={includeOptionMap}
          allowShowMessageTooltip={allowShowMessageTooltip}
          isGroupAction={isGroupAction}
        />
      );
    }
  };

  const onClickList = (
    key: MetaIdField,
    action: FormClickVariant,
    value?: any) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioDtoActionPermission
        : undefined;

      let excludeActionIdSet: MetaIdAction[] = [];

      fieldValue.forEach(value =>
      {
        if(value.metaId !== oldValue?.metaId)
        {
          excludeActionIdSet.push(value.metaId);
        }
      });

      pageCtx.showDialog(
        <DialogAssignAction
          values={oldValue}
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          allowGrouping={allowGrouping}
          includeActionIdSet={includeActionIdSet}
          onClickOk={(newValue) => cbRefList.updateItem(newValue, key)}
          excludeActionIdSet={excludeActionIdSet.length > 0 ? excludeActionIdSet : undefined}
          allowSystemRoles={allowSystemRoles}
          includeOptionMap={includeOptionMap}
          allowShowMessageTooltip={allowShowMessageTooltip}
          isGroupAction={isGroupAction}
        />
      );
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(map?: StudioMapOfActionPermission): StudioDtoActionPermission[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoActionPermission[]): StudioMapOfActionPermission
{
  const keys = [] as MetaIdAction[];
  const map = {} as Record<MetaIdAction, StudioDtoActionPermission>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfActionPermission;
}
