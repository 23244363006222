import LocalLocalStorage from "./LocalLocalStorage";
import LocalSessionStorage from "./LocalSessionStorage";

const KEY_COOKIE = "cookie";

// cookie is stored only in production because actual cookie requires CORS, HTTPS, Secure, HttpOnly etc.
export default class LocalCookie
{
  static get(): string | null
  {
    let cookie = LocalSessionStorage.getItem(KEY_COOKIE);
    if(!cookie)
    {
      cookie = LocalLocalStorage.getItem(KEY_COOKIE);
    }
    return cookie;
  }

  static set(cookie: string, rememberMe: boolean): void
  {
    this.remove();

    // cookieValue with length == 0 implies delete cookie
    if(cookie.length > 0)
    {
      const storage = rememberMe ? LocalLocalStorage : LocalSessionStorage;
      storage.setItem(KEY_COOKIE, cookie);
    }
  }

  static remove(): void
  {
    LocalSessionStorage.removeItem(KEY_COOKIE);
    LocalLocalStorage.removeItem(KEY_COOKIE);
  }
}
