import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";

export type TypeOverlayTransition = "back" | "forward";

export default function PaneOverlay(props: {
  width: number,
  transition: TypeOverlayTransition,
  overlay: React.ReactNode,
  overlayVisible: boolean,
  onVisible?: () => void,
  onInvisible?: () => void,
})
{
  const theme = useTheme();
  const [transitionState, setTransitionState] = useState<| "visibleInit"
    | "visibleStart"
    | "visibleEnd"
    | "invisibleInit"
    | "invisibleStart"
    | undefined>();
  const overlayVisible = props.overlayVisible;
  const durationTransition = theme.common.durationTransition;
  const onVisible = props.onVisible;
  const onInvisible = props.onInvisible;

  useEffect(() =>
  {
    if(overlayVisible)
    {
      if(transitionState === undefined || transitionState === "invisibleInit" || transitionState === "invisibleStart")
      {
        setTransitionState("visibleInit");
      }
      else if(transitionState === "visibleInit")
      {
        setTransitionState("visibleStart");
        //setTimeout(() =>
        //{
        setTransitionState("visibleEnd");
        onVisible && onVisible();
        //}, durationTransition);
      }
    }
    else
    {
      if(transitionState === "visibleEnd" || transitionState === "visibleStart" || transitionState === "visibleInit")
      {
        setTransitionState("invisibleInit");
      }
      else if(transitionState === "invisibleInit")
      {
        setTransitionState("invisibleStart");
        //setTimeout(() =>
        //{
        setTransitionState(undefined);
        onInvisible && onInvisible();
        //}, durationTransition);
      }
    }

  }, [durationTransition, transitionState, overlayVisible, onVisible, onInvisible]);

  if(transitionState === "visibleStart" || transitionState === "visibleEnd" || transitionState === "invisibleInit")
  {
    //width = props.width;
  }

  const display = props.overlayVisible
    || transitionState === "visibleEnd"
    || transitionState === "invisibleInit"
    || transitionState === "invisibleStart";

  //logRender("PaneOverlay", `overlayVisible = ${overlayVisible}, transitionState = ${transitionState}`)

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        display: display ? "flex" : "none",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        zIndex: "1"
      }}
    >
      {props.overlay}
    </Box>
  );
}
