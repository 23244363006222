import {isProdEnvironment} from "../../../base/plus/SysPlus";

const REDIRECT_URI_DEVELOPMENT = "https://web.orgbeat.com/signIn";
const REDIRECT_URI_PRODUCTION = "https://web.neome.ai/signIn";

const MS_CLIENT_ID_DEVELOPMENT = "e8a93c6e-ff6d-4c22-b941-7e295ab3c486";
const MS_CLIENT_ID_PRODUCTION = "9055f31d-bdbf-4fcf-8cad-5261b0c4d5f7";

const GOOGLE_CLIENT_ID_DEVELOPMENT = "364482309046-gsntseuvu66ge3ilgpbcvku7k21o43b6.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID_PRODUCTION = "567655086659-jlj6ltr3cs77i7hla7nsc6rcoubdebmd.apps.googleusercontent.com";

export const msalConfig = {
  auth: {
    clientId: getAzureClientId(),
    authority: "https://login.microsoftonline.com/common",
    redirectUri: getAuthRedirectUri()
  }
};

export function getAuthRedirectUri()
{
  return isProdEnvironment()
    ? REDIRECT_URI_PRODUCTION
    : REDIRECT_URI_DEVELOPMENT;
}

function getAzureClientId()
{
  return isProdEnvironment()
    ? MS_CLIENT_ID_PRODUCTION
    : MS_CLIENT_ID_DEVELOPMENT;
}

export function getGoogleClientId()
{
  return isProdEnvironment()
    ? GOOGLE_CLIENT_ID_PRODUCTION
    : GOOGLE_CLIENT_ID_DEVELOPMENT;
}
