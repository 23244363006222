import {MsgEntFilter} from "../../../api/home/drawer/msg/MsgEntFilter";
import {RpcDrawer} from "../../../api/home/drawer/RpcDrawer";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {SigChatCandidateMap} from "../../../api/home/main/sig/SigChatCandidateMap";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {isEntUserId} from "../../../api/meta/base/ApiPlus";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {createListItemGroup} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRecursiveCalls} from "../../../base/plus/ListPlus";
import {random} from "../../../base/plus/StringPlus";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinderTwo} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {toComboId} from "../../../base/types/TypesComboId";
import {isComboId} from "../../../base/types/TypesComboId";
import {TypeComboId, TypeComboIdUser} from "../../../base/types/TypesComboId";
import {comboToAboutId} from "../../../base/types/TypesComboId";
import {TypeComboIdGroup} from "../../../base/types/TypesComboId";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export type TypeSigUserAvatar = SigUserAvatar | undefined;
export type TypeSigGroupAvatar = SigGroupAvatar | undefined;

export class SrvcHomeDrawerNewChat extends ISrvc
{
  rpcNewChatCandidateListGet(filter?: EntId[])
  {
    const msg = {
      filterEntIdSet: filter
    } as MsgEntFilter;

    RpcDrawer.newChatCandidateListGet(msg, envSig => listRecursiveCalls<SigChatCandidateMap>(
      envSig,
      state => state.home.drawer.homeNewChat,
      () => this.rpcNewChatCandidateListGet(filter),
      this.doLoad.bind(this)
    ));
  }

  getListBinder()
  {
    return {
      // user
      selectSourceItem1: this.selectUserAvatar.bind(this),
      onBindSourceItem1: this.bindUserAvatar.bind(this),

      // group
      selectSourceItem2: this.selectGroupAvatar.bind(this),
      onBindSourceItem2: this.bindGroupAvatar.bind(this)

    } as IListBinderTwo<TypeSigUserAvatar, TypeSigGroupAvatar>;
  }

  private selectUserAvatar(state: RootState, entChatId: TypeComboId): TypeSigUserAvatar
  {
    if(isComboId(entChatId))
    {
      const chatId = comboToAboutId(entChatId);
      if(isEntUserId(chatId))
      {
        return state.cache.app.user.userAvatarMap[entChatId as TypeComboIdUser];
      }
    }

    return undefined;
  }

  private bindUserAvatar(listName: string, entChatId: TypeComboId, avatar?: TypeSigUserAvatar): void
  {
    if(!avatar)
    {
      return;
    }

    const userAvatar = Srvc.home.app.userAndGroupSelection.createUserAvatar(store.getState(), avatar);
    dispatchList(listName, listSetItem({
      itemId: entChatId,
      newItem: userAvatar
    }));
  }

  private selectGroupAvatar(state: RootState, entChatId: TypeComboId): TypeSigGroupAvatar
  {
    if(isComboId(entChatId))
    {
      const chatId = comboToAboutId(entChatId);
      if(isGroupId(chatId))
      {
        return state.cache.app.group.groupAvatarMap[entChatId as TypeComboIdGroup];
      }
    }

    return undefined;
  }

  private bindGroupAvatar(listName: string, entChatId: TypeComboId, avatar?: TypeSigGroupAvatar): void
  {
    if(!avatar)
    {
      return;
    }
    const groupAvatar = Srvc.home.app.userAndGroupSelection.createGroupAvatar(store.getState(), avatar);
    dispatchList(listName, listSetItem({
      itemId: entChatId,
      newItem: groupAvatar
    }));
  }

  private doLoad(listName: string, sig: SigChatCandidateMap)
  {
    const candidateMap = sig.candidateMap;
    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;
    Object
    .keys(candidateMap)
    .forEach((prefix) =>
    {
      const itemIds = [] as TypeListItemId[];
      const rootState = store.getState();
      candidateMap[prefix].forEach((candidate) =>
      {
        if(candidate.userAvatar)
        {
          const userAvatar = candidate.userAvatar;
          const itemId = toComboId(userAvatar.entId, userAvatar.entUserId);

          uiItemsById[itemId] = Srvc.home.app.userAndGroupSelection.createUserAvatar(rootState, userAvatar);
          itemIds.push(itemId);

        }
        if(candidate.groupAvatar)
        {
          const groupAvatar = candidate.groupAvatar;
          const itemId = toComboId(groupAvatar.entId, groupAvatar.groupId);
          uiItemsById[itemId] = Srvc.home.app.userAndGroupSelection.createGroupAvatar(rootState, groupAvatar);
          itemIds.push(itemId);
        }
      });

      uiItemsById[prefix] = createListItemGroup(prefix);
      uiGroupsById[prefix] = {
        itemIds: itemIds
      };
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById,
      version: random(16)
    }));
  }

}
