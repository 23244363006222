import {StoreItemId} from "../../../api/meta/base/Types";
import {ArtifactId} from "../../../api/meta/base/Types";
import {MsgStoreSearch} from "../../../api/store/store/msg/MsgStoreSearch";
import {RpcStore} from "../../../api/store/store/RpcStore";
import {SigStoreItemAvatar} from "../../../api/store/store/sig/SigStoreItemAvatar";
import {SigStoreSearch} from "../../../api/store/store/sig/SigStoreSearch";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {listSetSearch} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStoreDrawerSearch extends ISrvc
{
  constructor(readonly selectList: SelectList)
  {
    super();
  }

  rpcSearch(query: string, searchId: string, artifactIdSet: ArtifactId[])
  {
    const msg: MsgStoreSearch = {
      query: query,
      searchId: searchId,
      filterStoreItemIdSet: artifactIdSet
    };

    RpcStore.storeSearch(msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }

      const searchMap = envSig.sig;
      if(searchMap)
      {
        this.doLoad(searchMap, query);
      }
    });
  }

  private doLoad(sig: SigStoreSearch, query: string)
  {
    const storeSearchList = this.selectList(store.getState());
    const storeItemAdminIdMap = store.getState().cache.app.caller.storeItemIdAdminAvatarMap;
    const listName = storeSearchList.listName;

    const {
      uiItemsById,
      uiGroupsById
    } = this.getListIds(sig, storeItemAdminIdMap);

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById
    }));

    dispatchList(listName, listSetSearch(query));
  }

  private getListIds(
    sig: SigStoreSearch,
    storeItemAdminIdMap: Record<StoreItemId, SigStoreItemAvatar>
  )
  {
    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;

    const searchResultMap = Object.entries(sig.searchResultMap ?? {});

    searchResultMap.forEach(([artifactId, dtoSearchNeatPath], index) =>
    {
      const artifact = storeItemAdminIdMap[artifactId];

      const artifactItemIds = [] as TypeListItemId[];

      const searchResults = dtoSearchNeatPath.searchResult?.result;
      const neatPathMap = dtoSearchNeatPath.neatPathMap;

      if(searchResults && neatPathMap)
      {
        Object.entries(searchResults).forEach(([searchPath, results]) =>
        {
          const neatPath = neatPathMap[searchPath];

          if(!neatPath)
          {
            return;
          }

          const secondaryText = `${neatPath.secondary} : ${results?.join(" > ")}`;

          artifactItemIds.push(searchPath);

          uiItemsById[searchPath] = {
            type: "ps",
            primary: {
              text: neatPath.primary?.join(" > "),
              caption: {
                text: neatPath.caption,
                type: "text",
                ignoreSelection: true
              }
            },
            secondary: {
              text: secondaryText
            },
            hideMenu: true,
            userField: {
              artifactId: artifactId,
              searchPath: searchPath
            }
          } as IListItemAPSA;
        });
      }

      uiItemsById[artifactId] = {
        type: "listGroup",
        hideMenu: true,
        ignoreSelection: true,
        header: {
          text: artifact.name,
          mt: gapQuarter,
          mb: gapQuarter,
          expand: true
        }
      } as IListItemGroup;

      uiGroupsById[artifactId] = {
        itemIds: artifactItemIds
      };

      if(index !== searchResultMap.length - 1)
      {
        uiGroupsById[artifactId + "-gapId1"] = "gapHalf";
      }
    });

    return {
      uiItemsById,
      uiGroupsById
    };
  }
}
