import {Property} from "csstype";
import {useMemo} from "react";
import React from "react";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {EnumDefnPlacement} from "../../../../api/meta/base/Types";
import {fieldReportLeftLabel} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../../base/plus/ThemePlus";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import RawKeyValuePair from "../../../atom/raw/RawKeyValuePair";
import {useFormSectionCtx} from "../base/CtxFormSection";

export default function FieldRawKeyValuePair(props: {
  left: React.ReactNode,
  right: React.ReactNode,
  leftHeight?: number,
  leftMaxWidth?: Property.Width,
  rightMaxWidth?: Property.Width,
  bgcolor?: CssBackgroundColor,
  justifyContent?: EnumDefnPlacement,
  mr?: number,
  height?: Property.Height,
  flexGrow?: number,
  disableFlexBasis?: boolean
})
{
  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const form = formSectionCtx.getDefnForm();
  const maxWithForLeft = useMemo(() => getMaxWithForLeft(form, formSection), [form, formSection]);
  const isReport = form._defnTheme?.formVariant === "report";

  return (
    <RawKeyValuePair
      {...props}
      rightOverflowX={isReport ? "hidden" : "visible"}
      rightOverflowY={isReport ? "hidden" : "visible"}
      leftMaxWidth={isReport ? maxWithForLeft : (props.leftMaxWidth || "30%")}
    />
  );
}

function getMaxWithForLeft(defnForm?: DefnFormUi, formSection?: DefnSection): Property.Width | undefined
{
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if(context)
  {
    context.font = "15px Roboto";
  }
  let maxWidth = 0;
  formSection?.fieldIdSet?.forEach((fieldId) =>
  {
    const defn = defnForm?.compMap[fieldId] as DefnField;

    const label = !defn.hidden ? fieldReportLeftLabel(defn) : "";
    const width = context?.measureText(label)?.width || 0;
    maxWidth = Math.floor(Math.max(maxWidth, width));
  });
  return px(maxWidth);
}
