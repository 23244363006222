import {useEffect} from "react";
import React from "react";
import {isNotListItemIdSkeleton} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {IListItem} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinderAll} from "../../../base/types/list/TypesList";
import {useAppSelector} from "../../app/AppHooks";
import {ListClickVariant} from "../List";
import {CbOnClickListItem} from "../List";
import ListItemChat from "./ListItemChat";
import ListItemNoChat from "./ListItemNoChat";

export type CbOnClickListItemInternal = (
  menuAnchor: Element,
  itemId: TypeListItemId,
  item: IListItem,
  variant: ListClickVariant
) => void;

export function ListItem<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  itemId: TypeListItemId,
  itemWidth: number,
  selectList: SelectList,
  isScrolling: boolean,
  onClickListItem?: CbOnClickListItem,
  onItemSubscribe?: (itemId: TypeListItemId) => void,
  onItemUnsubscribe?: (itemId: TypeListItemId) => void,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>,
  defaultItemHeight?: number
})
{
  const itemId = props.itemId;
  const selectList = props.selectList;
  const onItemSubscribe = props.onItemSubscribe;
  const onItemUnsubscribe = props.onItemUnsubscribe;
  const listBinder = props.listBinder;
  const isScrolling = props.isScrolling;

  const selectSourceItem1 = listBinder?.selectSourceItem1;
  const selectSourceItem2 = listBinder?.selectSourceItem2;
  const selectSourceItem3 = listBinder?.selectSourceItem3;
  const selectSourceItem4 = listBinder?.selectSourceItem4;
  const selectSourceItem5 = listBinder?.selectSourceItem5;
  const selectSourceItem6 = listBinder?.selectSourceItem6;
  const selectSourceItem7 = listBinder?.selectSourceItem7;
  const selectSourceItem8 = listBinder?.selectSourceItem8;
  const selectSourceItem9 = listBinder?.selectSourceItem9;
  const selectSourceItem10 = listBinder?.selectSourceItem10;
  const onBindSourceItem1 = listBinder?.onBindSourceItem1;
  const onBindSourceItem2 = listBinder?.onBindSourceItem2;
  const onBindSourceItem3 = listBinder?.onBindSourceItem3;
  const onBindSourceItem4 = listBinder?.onBindSourceItem4;
  const onBindSourceItem5 = listBinder?.onBindSourceItem5;
  const onBindSourceItem6 = listBinder?.onBindSourceItem6;
  const onBindSourceItem7 = listBinder?.onBindSourceItem7;
  const onBindSourceItem8 = listBinder?.onBindSourceItem8;
  const onBindSourceItem9 = listBinder?.onBindSourceItem9;
  const onBindSourceItem10 = listBinder?.onBindSourceItem10;

  const listName = useAppSelector(state => selectList(state).listName);
  const defaultListItemType = useAppSelector(state => selectList(state).defaultListItemType);
  const itemType = useAppSelector(state => selectList(state).itemsById[itemId]?.type) ?? defaultListItemType;
  const sourceItem1 = useAppSelector(state => selectSourceItem1 ? selectSourceItem1(state, itemId) : undefined);
  const sourceItem2 = useAppSelector(state => selectSourceItem2 ? selectSourceItem2(state, itemId) : undefined);
  const sourceItem3 = useAppSelector(state => selectSourceItem3 ? selectSourceItem3(state, itemId) : undefined);
  const sourceItem4 = useAppSelector(state => selectSourceItem4 ? selectSourceItem4(state, itemId) : undefined);
  const sourceItem5 = useAppSelector(state => selectSourceItem5 ? selectSourceItem5(state, itemId) : undefined);
  const sourceItem6 = useAppSelector(state => selectSourceItem6 ? selectSourceItem6(state, itemId) : undefined);
  const sourceItem7 = useAppSelector(state => selectSourceItem7 ? selectSourceItem7(state, itemId) : undefined);
  const sourceItem8 = useAppSelector(state => selectSourceItem8 ? selectSourceItem8(state, itemId) : undefined);
  const sourceItem9 = useAppSelector(state => selectSourceItem9 ? selectSourceItem9(state, itemId) : undefined);
  const sourceItem10 = useAppSelector(state => selectSourceItem10 ? selectSourceItem10(state, itemId) : undefined);

  useEffect(() =>
  {
    if(onBindSourceItem1)
    {
      onBindSourceItem1(listName, itemId, sourceItem1);
    }
  }, [sourceItem1, onBindSourceItem1, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem2)
    {
      onBindSourceItem2(listName, itemId, sourceItem2);
    }
  }, [sourceItem2, onBindSourceItem2, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem3)
    {
      onBindSourceItem3(listName, itemId, sourceItem3);
    }
  }, [sourceItem3, onBindSourceItem3, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem4)
    {
      onBindSourceItem4(listName, itemId, sourceItem4);
    }
  }, [sourceItem4, onBindSourceItem4, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem5)
    {
      onBindSourceItem5(listName, itemId, sourceItem5);
    }
  }, [sourceItem5, onBindSourceItem5, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem6)
    {
      onBindSourceItem6(listName, itemId, sourceItem6);
    }
  }, [sourceItem6, onBindSourceItem6, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem7)
    {
      onBindSourceItem7(listName, itemId, sourceItem7);
    }
  }, [sourceItem7, onBindSourceItem7, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem8)
    {
      onBindSourceItem8(listName, itemId, sourceItem8);
    }
  }, [sourceItem8, onBindSourceItem8, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem9)
    {
      onBindSourceItem9(listName, itemId, sourceItem9);
    }
  }, [sourceItem9, onBindSourceItem9, listName, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem10)
    {
      onBindSourceItem10(listName, itemId, sourceItem10);
    }
  }, [sourceItem10, onBindSourceItem10, listName, itemId]);

  useEffect(() =>
  {
    if(onItemSubscribe && isNotListItemIdSkeleton(itemId))
    {
      onItemSubscribe(itemId);
    }

  }, [onItemSubscribe, onItemUnsubscribe, itemId]);

  useEffect(() =>
  {
    return () =>
    {
      onItemUnsubscribe && isNotListItemIdSkeleton(itemId) && onItemUnsubscribe(itemId);
    };
  }, [itemId, onItemUnsubscribe]);

  if(itemType === undefined)
  {
    return null;
  }

  switch(itemType)
  {
    case "p":
    case "ps":
    case "ap":
    case "aps":
    case "apsa":
    case "listGroup":
    case "gapQuarter":
    case "gapHalf":
    case "gapStd":
    case "loadMore":
    case "footer":
    case "p3ss":
    case "p3":
    case "mpsl":
    case "form":
      return <ListItemNoChat
        itemType={itemType}
        itemId={itemId}
        selectList={selectList}
        isScrolling={isScrolling}
        onClickListItem={props.onClickListItem}
        listBinder={listBinder}
        defaultItemHeight={props.defaultItemHeight}
      />;

    case "audio":
    case "camera":
    case "document":
    case "spreadsheetRow":
    case "spreadsheetPartition":
    case "spreadsheetRowDeleted":
    case "group":
    case "image":
    case "linkText":
    case "location":
    case "report":
    case "text":
    case "user":
    case "video":
    case "voice":
    case "ssRowBubble":
      return <ListItemChat
        itemType={itemType}
        itemId={itemId}
        itemWidth={props.itemWidth}
        selectList={selectList}
        isScrolling={isScrolling}
        listBinder={listBinder}
        onClickListItem={props.onClickListItem}
      />;

  }
}
