import EditSharpIcon from "@mui/icons-material/EditSharp";
import {Box} from "@mui/material";
import {Badge} from "@mui/material";
import {AvatarProps} from "@mui/material";
import {useTheme} from "@mui/material";
import {Avatar} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {MuiAvatarFontSize} from "../../../base/plus/ThemePlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {TypeTooltip} from "../../../base/types/TypesGlobal";
import {EnumIconAvatar} from "../../../base/types/TypesIcon";
import {IconAvatar} from "../icon/IconAvatar";
import RawTooltip from "../raw/RawTooltip";

export default function AvatarAtom(props: {
  icon?: EnumIconAvatar,
  text?: string,
  src?: string,
  bgcolor?: CssBackgroundColor,
  isScrolling?: boolean,
  pl?: number,
  pr?: number,
  ml?: number,
  mr?: number,
  avatarSize?: StandardCSSProperties["width"],
  iconSize?: StandardCSSProperties["width"],
  iconFontSize?: MuiAvatarFontSize;
  borderRadius?: StandardCSSProperties["borderRadius"],
  onClick?: () => void,
  color?: string,
  tooltip?: TypeTooltip,
  variant?: AvatarProps["variant"],
  onClickEdit?: () => void,
  showIcon?: boolean
})
{
  const theme = useTheme();

  let avatarSize = props.avatarSize;
  if(!avatarSize)
  {
    avatarSize = theme.common.sizeAvatar;
  }

  const iconSize = props.iconSize;

  const bgcolor = props.bgcolor === undefined
    ? theme.common.bgcolorActionBar
    : props.bgcolor;

  return (
    <RawTooltip
      title={props.tooltip ?? ""}
      placement={"right-start"}
    >
      <Badge
        overlap={"circular"}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        badgeContent={props.showIcon &&
          <Box
            onClick={(e) =>
            {
              e.stopPropagation();
              e.preventDefault();
              props.onClickEdit && props.onClickEdit();
            }}
            sx={{
              cursor: props.onClickEdit ? "pointer" : "default",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.common.colorWithShade("grey", "s300"),
              padding: px(gapHalf),
              borderRadius: px(gapStd * 2)
            }}
          >
            <EditSharpIcon />
          </Box>
        }
      >
        <Avatar
          variant={props.variant}
          onClick={e =>
          {
            e.stopPropagation();
            e.preventDefault();
            if(props.onClick)
            {
              props.onClick();
            }
          }}
          src={props.src} // do not load images while scrolling
          sx={{
            bgcolor: bgcolor,
            ml: px(props.ml),
            mr: px(props.mr),
            pl: px(props.pl),
            pr: px(props.pr),
            borderRadius: props.borderRadius,
            color: props.color === undefined ? theme.common.fgcolorAvatar : props.color,
            width: avatarSize,
            height: avatarSize,
            cursor: props.onClick ? "pointer" : "default"
          }}
        >
          {
            props.icon
              ? (<IconAvatar
                avatarIcon={props.icon}
                fontSize={props.iconFontSize ?? "medium"}
                iconSize={iconSize}
              />)
              : props.text ? props.text : "?"
          }
        </Avatar>
      </Badge>
    </RawTooltip>
  );
}


