import {Typography} from "@mui/material";
import {ToggleButton} from "@mui/material";
import {ToggleButtonGroup} from "@mui/material";
import {Button} from "@mui/material";
import {IconButton} from "@mui/material";
import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ArrowDropDownIcon} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers";
import {getMonth} from "date-fns";
import {format} from "date-fns";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {NavigateAction} from "react-big-calendar";
import {View} from "react-big-calendar";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {nowDate} from "../../../../base/plus/CalendarPlus";
import {calendarShellTodayDateColor} from "../../../../base/plus/CalendarPlus";
import {useCalendarToolBarHandler} from "../../../../base/plus/CalendarPlus";
import {getMonthRange} from "../../../../base/plus/DatePlus";
import {checkIsDateToday} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {TypeCalendarCacheEvents} from "../../../../base/types/TypeCalendar";
import {useAppSelector} from "../../../app/AppHooks";
import IconButtonStrip from "../../../atom/icon/IconButtonStrip";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import {useAppCtx} from "../../../ctx/CtxApp";

const STR_TODAY = "Today";
const STR_MONTH = "Month";
const STR_WEEK = "Week";
const STR_DAY = "Day";

export interface ICbRefSetCurrentDate
{
  getCurrentDate: () => Date;
}

export interface IHeaderProps
{
  view: View,
  date: Date,
  label: string,
  onNavigate: (navigate: NavigateAction, date?: (Date | undefined)) => void,
  onView: (view: View) => void,
}

interface CbCalendarHeaderProps
{
  headerProp: IHeaderProps
  selectCalendar: SelectCalendar,
  dateRef: ICbRefSetCurrentDate;
  cbSelectedView?: (view: View) => void;
  onMonthChange?: (startDate: Date, endDate: Date, cacheMonthEventData?: TypeCalendarCacheEvents) => void;
}

export default function CalendarHeader(props: CbCalendarHeaderProps)
{
  const headerProp = props.headerProp;
  const dateRef = props.dateRef;
  const onMonthChange = props.onMonthChange;
  const view = headerProp.view;
  const dateValue = headerProp.date;
  const label = headerProp.label;
  const cachedMonthlyData = useAppSelector(state => props.selectCalendar(state).cachedMonthlyEvents);

  const [initialDate, setInitialDate] = useState(dateValue);

  const {
    goToBack,
    goToNext,
    goToToday,
    changeView,
    onChangeYear,
    onChangeMonth,
    selectedMonth,
    selectedYear,
    currentDate
  } = useCalendarToolBarHandler({
    toolbar: headerProp
  });

  const isMobile = useAppCtx().isMobile();
  const isTablet = useAppCtx().isTablet();
  const isMobOrTab = isTablet || isMobile;

  const todayDate = checkIsDateToday(dateValue);
  const dayView = view === "day";
  const displayDate = dayView
    ? format(dateValue, "EEE dd")
    : label;

  if(dateRef)
  {
    dateRef.getCurrentDate = () => currentDate;
  }

  useEffect(() =>
  {
    const {
      startDate,
      endDate
    } = getMonthRange(nowDate);

    if(onMonthChange)
    {
      onMonthChange(startDate, endDate);
    }
  }, [onMonthChange]);

  useEffect(() =>
  {
    const newDate = new Date(selectedYear, getMonth(selectedMonth), 1);
    const {
      startDate,
      endDate
    } = getMonthRange(newDate);

    if(initialDate.getMonth() !== getMonth(selectedMonth)
      || initialDate.getFullYear() !== selectedYear)
    {
      setInitialDate(newDate);
      if(onMonthChange)
      {
        onMonthChange(startDate, endDate, cachedMonthlyData);
      }
    }
  }, [initialDate, selectedMonth, selectedYear, onMonthChange, cachedMonthlyData]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: isMobOrTab ? "column" : "row",
        alignItems: isMobOrTab ? "center" : undefined,
        pr: px(gapStd),
        pl: px(gapStd),
        mt: px(gapStd),
        mb: px(gapStd)
      }}
    >
      <Box
        sx={{
          width: isMobOrTab ? "100%" : "35%",
          display: "flex",
          paddingX: isMobOrTab ? undefined : px(gapQuarter),
          alignItems: "center",
          justifyContent: isMobOrTab ? "space-between" : "start"
        }}
      >
        <NavigationButtons
          goToBack={goToBack}
          goToNext={goToNext}
          goToToday={goToToday}
        />
        <Typography
          variant={"body1"}
          color={theme.common.color("textPrimary")}
          ml={px(gapStd * 2)}
          sx={{
            position: "relative",
            "&::before": (todayDate && dayView) ? {
              content: "\"\"",
              position: "absolute",
              transform: "translate(-16px, 6px)",
              width: px(10),
              height: px(10),
              borderRadius: "50%",
              backgroundColor: calendarShellTodayDateColor
            } : {}
          }}
        >
          {displayDate}
        </Typography>
      </Box>
      <Box
        sx={{
          width: isMobOrTab ? "100%" : "65%",
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          paddingX: isMobOrTab ? undefined : px(gapQuarter),
          alignItems: "center",
          justifyContent: isMobOrTab ? "center" : "end"
        }}

      >
        <DateSelection
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          onChangeMonth={onChangeMonth}
          onChangeYear={onChangeYear}
        />
        <ViewSelectionButtons
          view={view}
          changeView={(view) =>
          {
            changeView(view);
            props.cbSelectedView && props.cbSelectedView(view);
          }}
        />
      </Box>
    </Box>
  );
}

function NavigationButtons(props: {
  goToBack: () => void;
  goToToday: () => void;
  goToNext: () => void;
})
{
  return (
    <Box>
      <IconButton
        size={"large"}
        onClick={props.goToBack}
        sx={{
          padding: px(gapQuarter)
        }}
      >
        <IconButtonStrip
          iconButton={"backIos"}
          color={theme.common.color("primary")}
        />
      </IconButton>

      <Button
        size={"small"}
        onClick={props.goToToday}
      >
        {STR_TODAY}
      </Button>

      <IconButton
        size={"large"}
        onClick={props.goToNext}
        sx={{
          padding: px(gapQuarter)
        }}
      >
        <IconButtonStrip
          iconButton={"forwardIos"}
          color={theme.common.color("primary")}
        />
      </IconButton>
    </Box>
  );
}

function DateSelection(props: {
  selectedMonth: Date,
  selectedYear: number,
  onChangeMonth: (newDate: any) => void,
  onChangeYear: (newDate: any) => void
})
{

  return (
    <RawLocalizationProvider>
      <LayoutFlexRow
        width={px(320)}
        height={px(50)}
        justifyContent={"space-between"}
      >
        <DatePicker
          views={["month"]}
          openTo={"month"}
          label={"Month"}
          value={props.selectedMonth}
          onChange={props.onChangeMonth}
          slots={{
            openPickerIcon: ArrowDropDownIcon
          }}
          slotProps={{
            textField: () => ({
              sx: {
                width: px(200),
                height: px(30)
              }
            })
          }}
        />
        <DatePicker
          views={["year"]}
          format={"yyyy"}
          label={"Year"}
          openTo={"year"}
          value={new Date(props.selectedYear, 0, 1)}
          onChange={props.onChangeYear}
          showDaysOutsideCurrentMonth={false}
          slots={{
            openPickerIcon: ArrowDropDownIcon
          }}
          slotProps={{
            textField: () => ({
              sx: {
                width: px(200),
                height: px(30),
                ml: px(gapStd)
              }
            })
          }}
        />
      </LayoutFlexRow>
    </RawLocalizationProvider>
  );
}

function ViewSelectionButtons(props: {
  changeView: (view: View) => void,
  view?: View
})
{
  const StyledToggleButton = styled(ToggleButton)({
    flex: 1,
    width: px(100),
    height: px(30)
  });

  const handleChange = (_: React.MouseEvent<HTMLElement>, value: View | null) =>
  {
    if(value !== null)
    {
      props.changeView(value);
    }
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      ml={px(gapStd)}
    >
      <ToggleButtonGroup
        color={"primary"}
        value={props.view}
        exclusive={true}
        onChange={handleChange}
        aria-label="Platform"
      >
        <StyledToggleButton
          value={"month"}
          color={"primary"}
        >
          {STR_MONTH}
        </StyledToggleButton>
        <StyledToggleButton
          value={"week"}
          color={"primary"}
        >
          {STR_WEEK}
        </StyledToggleButton>
        <StyledToggleButton
          value={"day"}
          color={"primary"}
        >
          {STR_DAY}
        </StyledToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
