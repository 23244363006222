import {MsgGroupInfoGet} from "../../../api/home/aside/msg/MsgGroupInfoGet";
import {SigGroupInfo} from "../../../api/home/aside/sig/SigGroupInfo";
import {WsocAside} from "../../../api/home/aside/WsocAside";
import {MsgGroupId} from "../../../api/home/base/msg/MsgGroupId";
import {WsocDrawer} from "../../../api/home/drawer/WsocDrawer";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {EntId, GroupId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {TypeComboIdGroup} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import {setGroupInfo} from "./SliceCacheGroup";
import {setGroupAvatar} from "./SliceCacheGroup";
import {calcGroupsTypingText} from "./SliceCacheGroup";
import {clearGroupInfo} from "./SliceCacheGroup";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export default class SrvcCacheGroup extends ISrvc
{
  private readonly subId = "SrvcCacheGroup";

  private readonly maxTicks = 2;
  private currTick = 0;

  wsocGroupAvatarGet(entId: EntId, groupId: GroupId)
  {
    const msg: MsgGroupId = {
      groupId: groupId
    };

    const entGroupId = toComboId(entId, groupId) as TypeComboIdGroup;
    const group = store.getState().cache.app.group.groupAvatarMap[entGroupId];
    if(group)
    {
      msg.version = group.version;
    }

    WsocDrawer.groupAvatarGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setGroupAvatar(sig));
      }
    });
  }

  wsocGroupInfoGet(entId: EntId, groupId: GroupId, version?: string, cbSuccess?: (sig?: SigGroupInfo) => void)
  {
    const msg = {
      groupId: groupId,
      version: version
    } as MsgGroupInfoGet;

    WsocAside.groupInfoGet(entId, msg, (envSig) =>
    {
      if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig?.sig);

        store.dispatch(setGroupInfo({
          entId: entId,
          groupId: groupId,
          groupInfo: envSig.sig
        }));
      }
    });
  }

  clearGroupInfo(entId: EntId, groupId: GroupId)
  {
    store.dispatch(clearGroupInfo({
      entId: entId,
      groupId: groupId
    }));
  }

  wsocGroupExit(groupId: GroupId)
  {
    const msg = {
      groupId: groupId
    } as MsgGroupId;

    WsocDrawer.groupExit(msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        Srvc.app.pubsub.group.groupAvatar(this.subId, ENT_ID_GLOBAL, groupId, true);
      }
    });
  }

  protected doTick(dateNow: Date)
  {
    if(this.currTick === this.maxTicks)
    {
      this.currTick = 0;

      store.dispatch((dispatch, getState) =>
      {
        if(getState().cache.app.group.groupsTypingCount > 0)
        {
          dispatch(calcGroupsTypingText());
        }
      });
    }
    else
    {
      this.currTick++;
    }

    super.doTick(dateNow);
  }
}
