import {combineReducers} from "@reduxjs/toolkit";
import {sliceCacheSpreadsheetEditor} from "./ssEditor/StoreCacheSpreadsheetEditor";
import {sliceCacheSpreadsheetRow} from "./ssRow/StoreCacheSpreadsheetRow";

export function reducersCacheSpreadsheet()
{
  return combineReducers({
    ssRow: sliceCacheSpreadsheetRow.reducer,
    ssEditor: sliceCacheSpreadsheetEditor.reducer
  });
}
