import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {isString} from "lodash";
import {useEffect} from "react";
import React from "react";
import {useMemo} from "react";
import {useState} from "react";
import {DtoFieldFilter} from "../../api/ent/base/dto/DtoFieldFilter";
import {SpreadsheetFilterValue} from "../../api/ent/base/dto/SpreadsheetFilterValue";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnLayoutCard} from "../../api/meta/base/dto/DefnLayoutCard";
import {DefnLayoutGrid} from "../../api/meta/base/dto/DefnLayoutGrid";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {EnumDefnLayoutCardFilterKind} from "../../api/meta/base/Types";
import {useSsBrCtx} from "./base/CtxSsBr";
import CtxSsBrProvider from "./base/CtxSsBr";
import {IActionSSBrPayloadSearchState} from "./base/ICtxSsBr";
import {IActionSSBrPayloadFilterState} from "./base/ICtxSsBr";
import {ISsBrFooterPayload} from "./base/ICtxSsBr";
import useSsBrRefSetter from "./base/SsBtRefSetter";
import SsBrFilters from "./SsBrFilters";
import SsBrFooter from "./SsBrFooter";
import SsBrMain from "./SsBrMain";
import SsBrSearch from "./SsBrSearch";
import TemplateSsBr from "./TemplateSsBr";

export interface ISsBrRef
{
  setFilters: (filters: DtoFieldFilter[]) => void,
  setFooterPayload: (payload: ISsBrFooterPayload) => void,
  setFilterState: (payload: IActionSSBrPayloadFilterState) => void
  setSearchState: (payload: IActionSSBrPayloadSearchState) => void
}

interface ISpreadSheetBrProps
{
  filters?: DtoFieldFilter[],
  showFilters?: boolean,
  spreadSheetBrContent: React.ReactNode
  cbRef?: ISsBrRef,
  defnFormRef: DefnForm,
  filterViewType?: EnumDefnLayoutCardFilterKind,
  showSearchBar?: boolean
  hideFooter: boolean,
  // layout
  currentLayout?: DefnLayoutGrid,
  allowToSwitchLayoutMap?: Record<MetaIdLayoutGrid, DefnLayoutGrid>,
  onClickSubmit?: () => void,
  cbOnClickShowSelected?: (showSelectedItems: boolean) => void,
  onFilterUpdate?: (
    selectedFilters?: SpreadsheetFilterValue[],
    searchStr?: string,
    sortByFieldId?: MetaIdField) => void,
  onSwitchLayout?: (layoutId: MetaIdLayoutGrid) => void
}

export default function SsBr(props: ISpreadSheetBrProps)
{
  return (
    <CtxSsBrProvider filters={props.filters}>
      <RealSsBr {...props} />
    </CtxSsBrProvider>
  );
}

function RealSsBr(props: ISpreadSheetBrProps)
{
  const theme = useTheme();

  const filterViewType = props.filterViewType || "tree";
  const onFilterUpdate = props.onFilterUpdate;
  const hideFooter = props.hideFooter;
  const allowToSwitchLayoutMap = props.allowToSwitchLayoutMap;

  const spreadSheetBrCtx = useSsBrCtx();
  const [searchStr, setSearchStr] = useState<string>();
  const filters = spreadSheetBrCtx.getFilters();
  const sortByFieldId = spreadSheetBrCtx.getSortByFieldId();
  const selectedFilters = useMemo(() => spreadSheetBrCtx.getSelectedFilters(), [spreadSheetBrCtx]);
  const [firstBoot, setFirstBoot] = useState(true);

  useEffect(() =>
  {
    if(!firstBoot && onFilterUpdate && selectedFilters)
    {
      onFilterUpdate(
        selectedFilters.length ? selectedFilters : undefined,
        searchStr || undefined
      );
      spreadSheetBrCtx.setFilterState({state: true});
    }
  }, [selectedFilters]);

  useEffect(() =>
  {
    if(!firstBoot && onFilterUpdate && isString(searchStr))
    {
      onFilterUpdate(
        selectedFilters?.length ? selectedFilters : undefined,
        searchStr || undefined
      );
      spreadSheetBrCtx.setSearchState({search: true});
    }
  }, [searchStr]);

  useEffect(() =>
  {
    if(!firstBoot && onFilterUpdate)
    {
      onFilterUpdate(
        selectedFilters?.length ? selectedFilters : undefined,
        searchStr || undefined,
        sortByFieldId
      );
    }
  }, [sortByFieldId]);

  useEffect(() =>
  {
    setFirstBoot(false);
  }, []);

  useSsBrRefSetter({
    cbRef: props.cbRef
  });

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      zIndex={1}
      width={"100%"}
      height={"inherit"}
      flexGrow={1}
      bgcolor={theme.common.bgcolorSidePane}
    >
      <TemplateSsBr
        search={<SsBrSearch
          allowToSwitchLayoutMap={allowToSwitchLayoutMap}
          onSearch={(str) => setSearchStr(str)}
          showSearchBar={props.showSearchBar}
          onSwitchLayout={props.onSwitchLayout}
        />}
        filters={((filters && filters.length >= 1)
          || (props.currentLayout as DefnLayoutCard)?.filter?.sortByFieldIdSet?.length)
          ? <SsBrFilters
            defnFormRef={props.defnFormRef}
            filterViewType={filterViewType}
            cbOnClickShowSelected={props.cbOnClickShowSelected}
            currentLayout={props.currentLayout}
          />
          : undefined}

        main={<SsBrMain
          spreadSheetBrContent={props.spreadSheetBrContent}
          defnFormRef={props.defnFormRef}
          onFilterUpdate={props.onFilterUpdate}
        />}

        footer={!hideFooter
          ? <SsBrFooter
            onClickSubmit={props.onClickSubmit}
          />
          : undefined}
      />
    </Box>
  );
}
