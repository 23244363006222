import {Link} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import {DtoMessagePayloadLocation} from "../../../../api/home/base/dto/DtoMessagePayloadLocation";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../base/plus/ConstantsPlus";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {googleMapSearchUrl} from "../../../../base/plus/LocationPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {TypeChatImageMode} from "../../../../base/types/TypesChat";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import locationPlaceholderImage from "../../assets/placeholderImageLocation.png";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import RawHighlighter from "../../raw/RawHighlighter";
import RawLazyImage from "../../raw/RawLazyImage";
import BubbleLineFooter from "../line/BubbleLineFooter";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleShell from "./BubbleShell";

export default function BubbleLocation(props: {
  payload: DtoMessagePayloadLocation,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  uiImageMode?: TypeChatImageMode,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  caption?: string,
  header?: IBubbleHeader,
  isStar?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  onClickImage?: () => void,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor
})
{
  const theme = useTheme();
  const payload = props.payload;
  const header = props.header;
  const gap = gapHalf - (theme.common.bubbleShellGap + theme.common.bubbleShellBorder);
  const onClickImage = () =>
  {
    const mapUrl = `${googleMapSearchUrl}` + payload.latitude + "," + payload.longitude;
    window.open(mapUrl);
  };

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={props.maxWidth}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleBlinking={props.isBubbleBlinking}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      {
        header !== undefined &&
        <BubbleRawHeader
          header={header}
          onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
        />
      }

      {
        props.replyPayload &&
        <BubbleRawReplyPreview
          replyPayload={props.replyPayload}
          replyInfo={props.replyInfo}
          onClickPreview={props.cbOnClickReplyPreview}
        />
      }

      {
        payload.mediaIdImage &&
        <RawLazyImage
          borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
          src={getMediaSrc(payload.mediaIdImage)}
          srcBlur={locationPlaceholderImage}
          onClick={onClickImage}
          objectFit={"cover"}
        />
      }
      {
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            pt: px(gap),
            pl: px(gap)
          }}
        >
          <Link
            href={`${googleMapSearchUrl}` + payload.latitude + "," + payload.longitude}
            target={"_blank"}
            underline="none"
          >
            <LayoutFlexRow justifyContent={"start"}>
              <RawHighlighter
                variant={"body2"}
                color={"primaryDark"}
                value={payload.text}
                noWrap={false}
                searchWords={props.searchWords}
                breakWords={true}
              />
            </LayoutFlexRow>
          </Link>
        </Box>
      }

      <BubbleLineFooter
        isStar={props.isStar}
        heightLine={theme.common.bubbleHeightLine}
        textRight={{
          iconRight: props.receiptStatus,
          text: formatCaptionOnlyTime(new Date(props.creationTime)),
          onClick: props.cbOnClickFooterCaption
        }}
      />

    </BubbleShell>
  );
}
