import {useEffect} from "react";
import {DefnFieldRef} from "../../../../api/meta/base/dto/DefnFieldRef";
import {useFormCtx} from "../base/CtxForm";

export default function FieldRef(props: {
  defn: DefnFieldRef
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;

  const copyFieldMap = defn.copyFieldMap;

  useEffect(() =>
  {
    if(copyFieldMap)
    {
      Object.keys(copyFieldMap).forEach(fieldId =>
      {
        formCtx.setRefChildParentId(fieldId, defn.metaId);
      });
    }

  }, [copyFieldMap]);

  return null;
};
