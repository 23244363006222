import {useTheme} from "@mui/material";
import {ListItemText} from "@mui/material";
import {Box} from "@mui/material";
import {ITooltipParams} from "ag-grid-community";
import React from "react";
import {px} from "../../../base/plus/StringPlus";

export default function DataGridTooltip(props: ITooltipParams)
{
  const theme = useTheme();
  const cellTooltip = props.valueFormatted
    ? props.valueFormatted
    : props.value;
  const tooltip = (props.location === "header" ? props.value : cellTooltip);

  return (
    <Box
      className="custom-tooltip"
    >
      <span>
        <ListItemText
          sx={{
            bgcolor: theme.common.bgcolorTooltip,
            color: theme.common.color("textInverse"),
            px: px(theme.common.gapHalf),
            borderRadius: px(theme.common.borderRadius)
          }}
          primary={tooltip}
        />
      </span>
    </Box>
  );
};
