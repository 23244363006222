import {FieldValues} from "react-hook-form";
import {DefnFieldNumber} from "../../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioBuildColor} from "../../../../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldInfo} from "../../../../../api/meta/base/dto/StudioFieldInfo";
import {StudioVarMap} from "../../../../../api/meta/base/dto/StudioVarMap";
import {JustifyText} from "../../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getExcludeVarIdSet} from "../../../../../base/plus/FormPlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyBgColor} from "../../base/TypesFormBuilder";
import {fieldGap5} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyBackgroundColorVar} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyRightPadding} from "../../base/TypesFormBuilder";
import {propKeyLeftPadding} from "../../base/TypesFormBuilder";
import {propKeyBottomPadding} from "../../base/TypesFormBuilder";
import {propKeyTopPadding} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyTextSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeyLineCount} from "../../base/TypesFormBuilder";
import {propKeyLineCountFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyJustifyText} from "../../base/TypesFormBuilder";
import {propKeyTextSize} from "../../base/TypesFormBuilder";
import {propKeyJustifyTextVar} from "../../base/TypesFormBuilder";
import {propKeyRightPaddingVar} from "../../base/TypesFormBuilder";
import {propKeyLeftPaddingVar} from "../../base/TypesFormBuilder";
import {propKeyShowLabel} from "../../base/TypesFormBuilder";
import {propKeyBgColorVarId} from "../../base/TypesFormBuilder";
import {propKeyShowBorder} from "../../base/TypesFormBuilder";
import {propKeyFlexGrow} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueTextVar} from "../../base/TypesFormBuilder";
import {propKeyLineCountVar} from "../../base/TypesFormBuilder";
import {propKeyTextSizeVar} from "../../base/TypesFormBuilder";
import {propKeyShowCloseButton} from "../../base/TypesFormBuilder";
import {propKeyTopPaddingVar} from "../../base/TypesFormBuilder";
import {propKeyBottomPaddingVar} from "../../base/TypesFormBuilder";

export function getDefnFieldInfo(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  varMap?: StudioVarMap,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyDefaultFieldId,
    propKeyLineCountFieldId,
    propKeyTextSizeFieldId
  ];

  const varIds = [
    propKeyDefaultValueTextVar,
    propKeyLineCountVar,
    propKeyTextSizeVar,
    propKeyBackgroundColorVar,

    fieldGap3,

    propKeyTopPaddingVar,
    propKeyBottomPaddingVar,
    propKeyLeftPaddingVar,
    propKeyRightPaddingVar,

    fieldGap4,

    propKeyJustifyTextVar

  ];

  const constIds = [
    propKeyShowCloseButton,
    propKeyFlexGrow,
    propKeyShowBorder,
    propKeyShowLabel,

    fieldGap3,

    propKeyDefaultValue,
    propKeyLineCount,
    propKeyTextSize,
    propKeyBgColor,

    fieldGap4,

    propKeyTopPadding,
    propKeyBottomPadding,
    propKeyLeftPadding,
    propKeyRightPadding,

    fieldGap5,

    propKeyJustifyText
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "info",
      formId,
      "paragraph",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyShowCloseButton]: {
      type: "bool",
      name: propKeyShowCloseButton,
      label: "Show close button",
      metaId: propKeyShowCloseButton,
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyFlexGrow]: {
      type: "bool",
      name: propKeyFlexGrow,
      metaId: propKeyFlexGrow,
      varKind: "bool",
      label: "Flex grow",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowBorder]: {
      type: "bool",
      name: propKeyShowBorder,
      metaId: propKeyShowBorder,
      varKind: "bool",
      label: "Show border",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyLineCount]: {
      type: "number",
      name: propKeyLineCount,
      metaId: propKeyLineCount,
      label: "Line count"
    } as DefnFieldNumber,

    [propKeyLineCountVar]: {
      type: "pickVarId",
      name: propKeyLineCountVar,
      label: "Line count",
      metaId: propKeyLineCountVar,
      varKind: "number",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyLineCountFieldId]: {
      type: "pickFieldId",
      name: propKeyLineCountFieldId,
      metaId: propKeyLineCountFieldId,
      label: "Line count",
      formId: formId,
      filterFieldTypeSet: ["number"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyTextSize]: {
      type: "textSize",
      metaId: propKeyTextSize,
      name: propKeyTextSize,
      label: "Text size"
    } as DefnFieldPickEnum,

    [propKeyTextSizeVar]: {
      type: "pickVarId",
      metaId: propKeyTextSizeVar,
      name: propKeyTextSizeVar,
      label: "Text size",
      varKind: "textSize",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyTextSizeFieldId]: {
      type: "pickFieldId",
      metaId: propKeyTextSizeFieldId,
      name: propKeyTextSizeFieldId,
      label: "Text size",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["textSize"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyTopPadding]: {
      type: "enumThemeDividerThickness",
      metaId: propKeyTopPadding,
      name: propKeyTopPadding,
      label: "Top padding"
    } as DefnFieldPickEnum,

    [propKeyTopPaddingVar]: {
      type: "pickVarId",
      name: propKeyTopPaddingVar,
      label: "Top padding",
      metaId: propKeyTopPaddingVar,
      varKind: "dividerKind",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyBottomPadding]: {
      type: "enumThemeDividerThickness",
      metaId: propKeyBottomPadding,
      name: propKeyBottomPadding,
      label: "Bottom padding"
    } as DefnFieldPickEnum,

    [propKeyBottomPaddingVar]: {
      type: "pickVarId",
      name: propKeyBottomPaddingVar,
      metaId: propKeyBottomPaddingVar,
      varKind: "dividerKind",
      label: "Bottom padding",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyLeftPadding]: {
      type: "enumThemeDividerThickness",
      metaId: propKeyLeftPadding,
      name: propKeyLeftPadding,
      label: "Left padding"
    } as DefnFieldPickEnum,

    [propKeyLeftPaddingVar]: {
      type: "pickVarId",
      name: propKeyLeftPaddingVar,
      label: "Left padding",
      metaId: propKeyLeftPaddingVar,
      varKind: "dividerKind",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyRightPadding]: {
      type: "enumThemeDividerThickness",
      metaId: propKeyRightPadding,
      name: propKeyRightPadding,
      label: "Right padding"
    } as DefnFieldPickEnum,

    [propKeyRightPaddingVar]: {
      type: "pickVarId",
      name: propKeyRightPaddingVar,
      metaId: propKeyRightPaddingVar,
      varKind: "dividerKind",
      label: "Right padding",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    //gap4

    [propKeyJustifyText]: {
      type: "enumPlacement",
      metaId: propKeyJustifyText,
      name: propKeyJustifyText,
      label: "Justify text",
      filterOptionSet: ["justify", "center", "start", "end" as EnumDefnPlacement]
    } as DefnFieldPickEnum,

    [propKeyJustifyTextVar]: {
      type: "pickVarId",
      metaId: propKeyJustifyTextVar,
      name: propKeyJustifyTextVar,
      label: "Justify text",
      varKind: "placement",
      showAsEdit: true,
      formId: formId,
      filterOptionSet: JustifyText,
      excludeVarIdSet: getExcludeVarIdSet(JustifyText as EnumDefnPlacement[], varMap)
    } as DefnStudioPickVarId,

    [propKeyBgColorVarId]: {
      type: "pickVarId",
      metaId: propKeyBgColorVarId,
      name: propKeyBgColorVarId,
      varKind: "color",
      label: "Background color",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyBgColor]: {
      type: "studioBuildColor",
      metaId: propKeyBgColor,
      name: propKeyBgColor,
      label: "Background color",
      allowShades: true
    } as DefnStudioBuildColor

  };
}

export function defnValueToStudioFieldInfo(values: FieldValues): StudioFieldInfo
{
  return {
    type: "info",

    defaultValue: fnFieldValueToRawValue("paragraph", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[propKeyDefaultValueTextVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),

    lineCount: fnFieldValueToRawValue("number", values[propKeyLineCount]),
    lineCountVarId: fnFieldValueToRawValue("pickVarId", values[propKeyLineCountVar]),
    lineCountFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyLineCountFieldId]),

    topPadding: fnFieldValueToRawValue("enumThemeDividerThickness", values[propKeyTopPadding]),
    topPaddingVarId: fnFieldValueToRawValue("pickVarId", values[propKeyTopPaddingVar]),

    bottomPadding: fnFieldValueToRawValue("enumThemeDividerThickness", values[propKeyBottomPadding]),
    bottomPaddingVarId: fnFieldValueToRawValue("pickVarId", values[propKeyBottomPaddingVar]),

    leftPadding: fnFieldValueToRawValue("enumThemeDividerThickness", values[propKeyLeftPadding]),
    leftPaddingVarId: fnFieldValueToRawValue("pickVarId", values[propKeyLeftPaddingVar]),

    rightPadding: fnFieldValueToRawValue("enumThemeDividerThickness", values[propKeyRightPadding]),
    rightPaddingVarId: fnFieldValueToRawValue("pickVarId", values[propKeyRightPaddingVar]),

    showCloseButton: fnFieldValueToRawValue("bool", values[propKeyShowCloseButton]),
    flexGrow: fnFieldValueToRawValue("bool", values[propKeyFlexGrow]),
    showBorder: fnFieldValueToRawValue("bool", values[propKeyShowBorder]),
    showLabel: fnFieldValueToRawValue("bool", values[propKeyShowLabel]),

    //inherited from label
    bgColorVarId: fnFieldValueToRawValue("pickVarId", values[propKeyBgColorVarId]),
    bgColor: fnFieldValueToRawValue("studioBuildColor", values[propKeyBgColor]),

    justifyText: fnFieldValueToRawValue("enumPlacement", values[propKeyJustifyText]),
    justifyTextVarId: fnFieldValueToRawValue("pickVarId", values[propKeyJustifyTextVar]),

    textSize: fnFieldValueToRawValue("textSize", values[propKeyTextSize]),
    textSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyTextSizeVar]),
    textSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyTextSizeFieldId])

  } as StudioFieldInfo;
}

export function studioFieldInfoToDefnValue(studioField: StudioFieldInfo)
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("paragraph", studioField.defaultValue),
    [propKeyDefaultValueTextVar]: fnRawValueToFieldValue("studioVarIdTextEditor", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),

    [propKeyLineCount]: fnRawValueToFieldValue("number", studioField.lineCount),
    [propKeyLineCountVar]: fnRawValueToFieldValue("pickVarId", studioField.lineCountVarId),
    [propKeyLineCountFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.lineCountFieldId),

    [propKeyTopPadding]: fnRawValueToFieldValue("enumThemeDividerThickness", studioField.topPadding),
    [propKeyTopPaddingVar]: fnRawValueToFieldValue("pickVarId", studioField.topPaddingVarId),

    [propKeyBottomPadding]: fnRawValueToFieldValue("enumThemeDividerThickness", studioField.bottomPadding),
    [propKeyBottomPaddingVar]: fnRawValueToFieldValue("pickVarId", studioField.bottomPaddingVarId),

    [propKeyLeftPadding]: fnRawValueToFieldValue("enumThemeDividerThickness", studioField.leftPadding),
    [propKeyLeftPaddingVar]: fnRawValueToFieldValue("pickVarId", studioField.leftPaddingVarId),

    [propKeyRightPadding]: fnRawValueToFieldValue("enumThemeDividerThickness", studioField.rightPadding),
    [propKeyRightPaddingVar]: fnRawValueToFieldValue("pickVarId", studioField.rightPaddingVarId),

    [propKeyShowCloseButton]: fnRawValueToFieldValue("bool", studioField.showCloseButton),
    [propKeyFlexGrow]: fnRawValueToFieldValue("bool", studioField.flexGrow),
    [propKeyShowBorder]: fnRawValueToFieldValue("bool", studioField.showBorder),
    [propKeyShowLabel]: fnRawValueToFieldValue("bool", studioField.showLabel),

    //inherited from label
    [propKeyBgColorVarId]: fnRawValueToFieldValue("pickVarId", studioField.bgColorVarId),
    [propKeyBgColor]: fnRawValueToFieldValue("studioBuildColor", studioField.bgColor),

    [propKeyJustifyText]: fnRawValueToFieldValue("enumPlacement", studioField.justifyText),
    [propKeyJustifyTextVar]: fnRawValueToFieldValue("pickVarId", studioField.justifyTextVarId),

    [propKeyTextSize]: fnRawValueToFieldValue("textSize", studioField.textSize),
    [propKeyTextSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.textSizeVarId),
    [propKeyTextSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.textSizeFieldId)

  };
}

