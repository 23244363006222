import {combineReducers} from "@reduxjs/toolkit";
import {reducersStudioApp} from "./app/StoreStudioApp";
import {reducersStudioDrawer} from "./drawer/StoreStudioDrawer";
import {reducersEnt} from "./ent/StoreStudioEnt";
import {reducersPlugin} from "./plugin/StoreStudioPlugin";

export function reducersStudio()
{
  //combine reducers inside object can't be empty, give key as name and optionId as reducer name
  return combineReducers({
    app: reducersStudioApp(),
    drawer: reducersStudioDrawer(),
    ent: reducersEnt(),
    plugin: reducersPlugin()
  });
}

                          
