import {useTheme} from "@mui/material";
import {useRef} from "react";
import {useEffect} from "react";
import React from "react";
import {useCallback} from "react";
import {getListItemHeightMPSL} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {listSetIfExistMultiSecondaryLineCaption} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistMultiSecondaryLineMiddle} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistMultiSecondaryLineNumber} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistPrimaryLineMiddle} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistPrimaryLineCaption} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistPrimaryLineNumber} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistBgcolor} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../base/types/list/TypesListAPSA";
import {store} from "../../../Store";
import {useAppSelector} from "../../app/AppHooks";
import {BoxM} from "../../atom/box/BoxM";
import {BoxMPSL} from "../../atom/box/BoxMPSL";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import {CbOnClickListItemInternal} from "../impl/ListItem";
import ListItemShell from "../impl/ListItemShell";
import {CbOnClickListItem} from "../List";
import {TypeListCounterPositionVariant} from "../List";

export function ListItemMPSL(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItemInternal,
  isScrolling: boolean,
  defaultItemHeight?: number
})
{
  const theme = useTheme();
  const itemId = props.itemId;
  const isScrolling = props.isScrolling;
  const onClickListItem = props.onClickListItem;

  const searchWords = useAppSelector(state => props.selectList(state).searchWords);
  const item = useAppSelector(state => props.selectList(state).itemsById[itemId]) as IListItemMPSL;
  const listLayoutType = useAppSelector(state => props.selectList(state).listLayoutType);

  const media = item?.media;
  const primary = item?.primary;
  const secondaryList = item?.secondaryList;
  const showMediaPlaceholder = item?.showMediaPlaceholder;
  const itemHeight = getListItemHeightMPSL((secondaryList?.length) ?? 0, Boolean(showMediaPlaceholder), listLayoutType);
  const {
    cbOnChangePrimaryCounter,
    cbOnChangeSecondaryCounter
  } = useOnClickListItemMPSL(props);

  return (
    <LayoutFlexCol
      width={"100%"}
      height={"100%"}
      bgcolor={item.bgColor}
      border={listLayoutType === "card" ? `1px solid ${theme.common.bgcolorActionBar}` : undefined}
      borderRadius={listLayoutType === "card" ? px(theme.common.gapHalf) : undefined}
    >
      {
        listLayoutType === "card" && showMediaPlaceholder &&
        <LayoutFlexCol
          width={"100%"}
          pl={px(theme.common.bubbleShellGap)}
          pr={px(theme.common.bubbleShellGap)}
          pt={px(theme.common.bubbleShellGap)}
          pb={px(theme.common.bubbleShellGap)}
          borderRadius={px(theme.common.gapHalf)}
        >
          <BoxM
            media={media}
            width={"100%"}
            height={px(theme.common.gridImageMinHeight)}
            showMediaPlaceholder={showMediaPlaceholder}
            listLayoutType={listLayoutType}
          />
        </LayoutFlexCol>
      }

      <ListItemShell
        {...props}
        itemHeight={itemHeight}
      >
        <BoxMPSL
          media={media}
          primary={primary}
          secondaryList={secondaryList}
          showMediaPlaceholder={showMediaPlaceholder}
          searchWords={searchWords}
          isScrolling={isScrolling}
          listLayoutType={listLayoutType}
          onChangePrimaryCounter={cbOnChangePrimaryCounter}
          onChangeSecondaryCounter={cbOnChangeSecondaryCounter}
          onClickCaption={(e) =>
          {
            onClickListItem && onClickListItem(e.target as Element, itemId, item, "apsaSecondaryCaption");
          }}
          onClickIconButtonCaption={(e, type) =>
          {
            onClickListItem && onClickListItem(e.target as Element, itemId, item, type);
          }}
          flexGrow={1}
        />
      </ListItemShell>
    </LayoutFlexCol>
  );
}

function useOnClickListItemMPSL(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItem,
})
{
  const itemId = props.itemId;
  const selectList = props.selectList;
  const onClickInternal = props.onClickListItem;
  const theme = useTheme();

  const listName = useAppSelector(state => selectList(state).listName);
  const item = useAppSelector(state => selectList(state).itemsById[itemId]) as IListItemMPSL;
  const primary = item?.primary;
  const primaryCounter = primary?.number;
  const primaryCaptionCounter = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemMPSL)?.primary?.caption?.value);
  const primaryMiddleCounter = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemMPSL)?.primary?.middle?.value);

  const menuAnchor = useRef<Element>();

  const cbOnChangeCounter = useCallback((countValue: number) =>
  {
    if(countValue > 0 && countValue < 2)
    {
      dispatchList(listName, listSetIfExistBgcolor({
        itemId: itemId,
        bgcolor: theme.common.bgcolorGreenLight
      }));
    }
    else if(countValue < 1)
    {
      dispatchList(listName, listSetIfExistBgcolor({
        itemId: itemId,
        bgcolor: theme.common.bgcolorContent
      }));
    }
  }, [itemId, listName, theme.common.bgcolorContent, theme.common.bgcolorGreenLight]);

  const cbOnChangePrimaryCounter = useCallback((
    e: Element,
    countValue: number,
    variant: TypeListCounterPositionVariant) =>
  {
    menuAnchor.current = e;
    const payload = {
      itemId: itemId,
      value: countValue
    };
    if(variant === "text")
    {
      dispatchList(listName, listSetIfExistPrimaryLineNumber(payload));
    }
    else if(variant === "middle")
    {
      dispatchList(listName, listSetIfExistPrimaryLineMiddle({
        ...payload,
        type: "counter"
      }));
    }
    else if(variant === "caption")
    {
      dispatchList(listName, listSetIfExistPrimaryLineCaption({
        ...payload,
        type: "counter"
      }));
    }
    cbOnChangeCounter(countValue);

  }, [cbOnChangeCounter, itemId, listName]);

  const cbOnChangeSecondaryCounter = useCallback((
    e: Element,
    countValue: number,
    variant: TypeListCounterPositionVariant,
    index: number) =>
  {
    menuAnchor.current = e;
    const payload = {
      itemId: itemId,
      value: countValue,
      indexes: [index]
    };
    if(variant === "text")
    {
      dispatchList(listName, listSetIfExistMultiSecondaryLineNumber(payload));
    }
    else if(variant === "middle")
    {
      Promise.resolve(
        dispatchList(listName, listSetIfExistMultiSecondaryLineMiddle({
          ...payload,
          type: "counter"
        }))
      ).then(() =>
      {
        const rootState = store.getState();
        const item = selectList(rootState).itemsById[itemId];
        onClickInternal && onClickInternal(e,
          itemId,
          item,
          "apsaSecondaryMiddleCounter",
          undefined,
          undefined
        );
      });
    }
    else if(variant === "caption")
    {
      Promise.resolve(
        dispatchList(listName, listSetIfExistMultiSecondaryLineCaption({
          ...payload,
          type: "counter"
        }))
      ).then(() =>
      {
        const rootState = store.getState();
        const item = selectList(rootState).itemsById[itemId];
        onClickInternal && onClickInternal(e,
          itemId,
          item,
          "apsaSecondaryCaptionCounter",
          undefined,
          undefined
        );
      });
    }
    cbOnChangeCounter(countValue);

  }, [cbOnChangeCounter, itemId, listName, onClickInternal, selectList]);

  useEffect(() =>
  {
    if(menuAnchor.current && primaryCounter !== undefined)
    {
      onClickInternal && onClickInternal(menuAnchor.current, itemId, item, "apsaPrimaryCounter");
    }
  }, [primaryCounter]);

  useEffect(() =>
  {
    if(menuAnchor.current && primaryCaptionCounter !== undefined)
    {
      onClickInternal && onClickInternal(menuAnchor.current, itemId, item, "apsaPrimaryCaptionCounter");
    }
  }, [primaryCaptionCounter]);

  useEffect(() =>
  {
    if(menuAnchor.current && primaryMiddleCounter !== undefined)
    {
      onClickInternal && onClickInternal(menuAnchor.current, itemId, item, "apsaPrimaryMiddleCounter");
    }
  }, [primaryMiddleCounter]);

  return {
    cbOnChangePrimaryCounter,
    cbOnChangeSecondaryCounter
  };
}

