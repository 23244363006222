import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {StudioVarTimeZone} from "../../../api/meta/base/dto/StudioVarTimeZone";
import {TimeZoneKey} from "../../../api/meta/base/Types";

export function fnVariableBuilderTimeZone(fieldValueTimeZone: string)
{
  function getVarComp()
  {
    return {
      [fieldValueTimeZone]: {
        type: "timeZone",
        metaId: fieldValueTimeZone,
        name: "Value",
        required: true
      } as DefnFieldEditable
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarTimeZone,
    timeZone?: TimeZoneKey
  )
  {
    const varValue = variable.value;
    if(varValue)
    {
      valueMap[fieldValueTimeZone] = varValue.value;
    }
    else
    {
      valueMap[fieldValueTimeZone] = timeZone;
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueTimeZone];

    return {
      value: {
        value: value
      }
    } as StudioVarTimeZone;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
