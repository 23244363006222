import {useMemo} from "react";
import React from "react";
import {DefnFieldUserId} from "../../../../api/meta/base/dto/DefnFieldUserId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawUser from "./FieldRawUser";

export default function FieldUserId(props: {
  defn: DefnFieldUserId
})
{

  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const newDefn = useMemo(() =>
  {
    const isRefChild = Boolean(formCtx.getRefChildParentId(fieldId));

    return {
      ...defn,
      disabled: isRefChild ? defn.disabled : true
    } as DefnFieldUserId;
  }, [defn, fieldId]);

  return (
    <FieldRawUser
      defn={newDefn}
    />
  );
}
