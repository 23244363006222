import {useTheme} from "@mui/material";
import {Stack} from "@mui/material";
import {Popover} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useState} from "react";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {IAvatar} from "../../../base/types/TypesGlobal";
import {EnumIconAvatar} from "../../../base/types/TypesIcon";
import AvatarAtom from "../avatar/AvatarAtom";
import {IMenuRef} from "./RawMenu";

const CustomPopover = styled(Popover)`

  & .MuiPopover-paper
  {
    background-color: transparent;
    box-shadow: none;
  }
`;

export default function RawMenuAvatar(props: {
  cbRef?: IMenuRef,
  menuAnchor: Element,
  avatarList: IAvatar[],
  onMenuGone?: () => void,
  onAvatarClick?: (avatarName: EnumIconAvatar) => void
})
{
  const [open, setOpen] = useState<boolean>(true);
  const {
    cbRef,
    menuAnchor,
    avatarList,
    onMenuGone,
    onAvatarClick
  } = props;
  const theme = useTheme();
  const gap = theme.common.gapHalf;

  function closeMenu()
  {
    setOpen(false);
    onMenuGone && onMenuGone();
  }

  if(cbRef)
  {
    cbRef.closeMenu = closeMenu;
  }

  return (
    <CustomPopover
      open={open}
      anchorEl={menuAnchor}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      <Stack
        bgcolor={"transparent"}
        gap={px(gap)}
      >
        {
          avatarList.map(avatar =>
            <AvatarAtom
              icon={avatar.icon}
              text={avatar.text}
              src={avatar.src}
              bgcolor={avatar.bgcolor}
              color={avatar.color}
              tooltip={avatar.tooltip}
              onClick={() => onAvatarClick && onAvatarClick(avatar.icon)}
            />)
        }
      </Stack>
    </CustomPopover>

  );
}
