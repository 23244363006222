import {RpcApi} from "../../api/nucleus/api/RpcApi";
import ISrvc from "../../base/ISrvc";
import {Srvc} from "../Srvc";
import SrvcApiBrFilter from "./SrvcApiBrFilter";
import SrvcApibrTree from "./SrvcApiBrTree";

export default class SrvcApiBr extends ISrvc
{
  public readonly apiBrTree = new SrvcApibrTree();
  public readonly apiBrFilter = new SrvcApiBrFilter();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.apiBrTree,
      this.apiBrFilter
    );
  }

  rpcApiCodeGet()
  {
    RpcApi.apiCodeGet(envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        const sig = envSig.sig;
        if(sig)
        {
          alert("Get Api code executed");
        }
      }
    });
  }
}
