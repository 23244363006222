import {Box} from "@mui/material";
import {Fragment} from "react";
import {useCallback} from "react";
import React from "react";
import {OnDragUpdateResponder} from "react-beautiful-dnd";
import {OnDragStartResponder} from "react-beautiful-dnd";
import {OnDragEndResponder} from "react-beautiful-dnd";
import {DragDropContext} from "react-beautiful-dnd";
import {dispatchKanban} from "../../base/plus/KanbanPlus";
import {fnGetOnChangeKanbanPayload} from "../../base/plus/KanbanPlus";
import {SelectKanban} from "../../base/plus/KanbanPlus";
import theme from "../../base/plus/ThemePlus";
import {kanbanMoveItem} from "../../base/slices/kanban/SliceKanbanSharedActions";
import {CbOnChangeKanban} from "../../base/types/TypeKanban";
import {TypeKanbanItemId} from "../../base/types/TypeKanban";
import {CbOnClickKanban} from "../../base/types/TypeKanban";
import {IKanbanBinderAll} from "../../base/types/TypeKanban";
import {useAppSelector} from "../app/AppHooks";
import helperTextData from "../atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../atom/raw/RawNothingHere";
import KanbanCols from "./base/KanbanCols";

export interface IKanbanProps<SR1, SR2, SR3, SR4, SR5, SR6>
{
  selectKanban: SelectKanban
  kanbanBinder?: IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  cbOnClick?: CbOnClickKanban
  cbOnChange?: CbOnChangeKanban,
  blankNode?: React.ReactNode
  onRowsSubscribe?: (itemIds: TypeKanbanItemId[]) => void,
  onRowsUnsubscribe?: (itemIds: TypeKanbanItemId[]) => void,
}

export default function Kanban<SR1, SR2, SR3, SR4, SR5, SR6>(props: IKanbanProps<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const selectKanban = props.selectKanban;

  const loaded = useAppSelector(state => selectKanban(state).loaded);
  const error = useAppSelector(state => selectKanban(state).error);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        bgcolor: theme.common.bgcolorSidePane,
        overflow: "auto"
      }}
    >
      {loaded
        ? !error
          ? <KanbanReal {...props} />
          : <KanbanError selectKanban={selectKanban} />
        : <KanbanLoading selectKanban={selectKanban} />}
    </Box>
  );

}

function KanbanReal<SR1, SR2, SR3, SR4, SR5, SR6>(props: IKanbanProps<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const selectKanban = props.selectKanban;
  const cbOnChange = props.cbOnChange;
  const displayColumnIds = useAppSelector(state => selectKanban(state).displayColumnIds);
  const kanbanName = useAppSelector(state => selectKanban(state).kanbanName);

  const onDragEnd: OnDragEndResponder = useCallback((result, provided) =>
  {
    const payload = fnGetOnChangeKanbanPayload(result);
    cbOnChange &&
    cbOnChange("dragEnd", payload);

    const destinationIndex = payload.destinationIndex;
    const destinationColumnId = payload.destinationColumnId;
    if(destinationIndex !== undefined && destinationColumnId !== undefined)
    {
      dispatchKanban(kanbanName, kanbanMoveItem({
        ...payload,
        destinationIndex: destinationIndex,
        destinationColumnId: destinationColumnId
      }));
    }
  }, [cbOnChange, kanbanName]);

  const onDragStart: OnDragStartResponder = useCallback((result, provided) =>
  {
    cbOnChange &&
    cbOnChange("dragStart", fnGetOnChangeKanbanPayload(result));
  }, [cbOnChange]);

  const onDragUpdate: OnDragUpdateResponder = useCallback((result, provided) =>
  {
    cbOnChange &&
    cbOnChange("dragUpdate", fnGetOnChangeKanbanPayload(result));
  }, [cbOnChange]);

  if(displayColumnIds.length <= 0)
  {
    return <KanbanNull
      selectKanban={selectKanban}
      blankNode={props.blankNode}
    />;
  }

  return <Fragment>
    <style>
      {
        `.height-preserving-container:empty {
            min-height: calc(var(--child-height));
            box-sizing: border-box;
         }`
      }
    </style>
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      <KanbanCols {...props} />
    </DragDropContext>
  </Fragment>;
}

function KanbanNull(props: {
  selectKanban: SelectKanban,
  blankNode?: React.ReactNode
})
{
  return props.blankNode === undefined
    ? <RawNothingHere />
    : <>{props.blankNode}</>;
}

function KanbanLoading(props: {
  selectKanban: SelectKanban,
})
{
  const loaded = useAppSelector(state => props.selectKanban(state).loaded);

  return !loaded
    ? <RawNothingHere helperTextData={helperTextData.loadingData} />
    : <></>;
}

function KanbanError(props: {
  selectKanban: SelectKanban,
})
{
  const error = useAppSelector(state => props.selectKanban(state).error);

  return <RawNothingHere
    helperTextData={{
      title: error
    }}
  />;
}

