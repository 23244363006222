import {useTheme} from "@mui/material";
import React from "react";
import {SigSpreadsheetRowExpiry} from "../../../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {EnumReceiptStatus} from "../../../../../api/home/base/Types";
import {SigSpreadsheetRowCommentCount} from "../../../../../api/home/main/sig/SigSpreadsheetRowCommentCount";
import {resolveBubbleFooterTimer} from "../../../../../base/plus/BubblePlus";
import {formatCaptionOnlyTime} from "../../../../../base/plus/DatePlus";
import {CbMenuAnchor} from "../../../../../base/types/TypesGlobal";
import BubbleLineFooter from "../../line/BubbleLineFooter";
import BubbleRawDivider from "../BubbleRawDivider";

export default function BubbleRawFormFooter(props: {
  creationTime: string,
  receiptStatus?: EnumReceiptStatus,
  sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry
  isStar?: boolean,
  hideFooter?: boolean,
  showComments?: boolean,
  rowCommentCount?: SigSpreadsheetRowCommentCount;

  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickFooterComments?: CbMenuAnchor,
  cbOnClickFooter?: CbMenuAnchor
})
{
  if(!props.hideFooter)
  {
    return <RealBubbleFormFooter {...props} />;
  }

  return null;
}

function RealBubbleFormFooter(props: {
  creationTime: string,
  receiptStatus?: EnumReceiptStatus,
  rowCommentCount?: SigSpreadsheetRowCommentCount;
  sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry
  isStar?: boolean,
  showComments?: boolean,

  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickFooterComments?: CbMenuAnchor,
})
{
  const theme = useTheme();

  const sigSpreadsheetRowExpiry = props.sigSpreadsheetRowExpiry;

  const commentCount = props?.rowCommentCount?.commentCount ?? 0;
  const unreadCommentCount = props.rowCommentCount?.unreadCommentCount;
  const receiptStatus = props.receiptStatus;
  const isStar = props.isStar;
  const cbOnClickFooterCaption = props.cbOnClickFooterCaption;
  const cbOnClickFooterComments = props.cbOnClickFooterComments;
  const showComments = props.showComments;
  const creationTime = props.creationTime;

  const comments = commentCount === 0
    ? undefined :
    commentCount.toString();

  return <>
    <BubbleRawDivider />
    <BubbleLineFooter
      isStar={isStar}
      heightLine={theme.common.bubbleHeightLine}
      textLeft={sigSpreadsheetRowExpiry?.showTimer ? resolveBubbleFooterTimer(sigSpreadsheetRowExpiry) : undefined}
      textRight={{
        text: creationTime ? formatCaptionOnlyTime(new Date(creationTime)) : "",
        iconRight: receiptStatus,
        onClick: cbOnClickFooterCaption
      }}
      textCenter={showComments
        ? {
          text: comments,
          color: unreadCommentCount ? "success" : undefined,
          iconLeft: showComments
            ? unreadCommentCount
              ? "commentGreen"
              : "comment"
            : undefined,
          onClick: cbOnClickFooterComments
        }
        : undefined}
      onClick={cbOnClickFooterComments}
    />
  </>;
}
