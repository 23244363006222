import {SvgIconTypeMap} from "@mui/material";
import {TextField} from "@mui/material";
import {IconButton} from "@mui/material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {EnumDefnThemeFieldSize} from "../../../api/meta/base/Types";
import {getFieldKey} from "../../../base/plus/FormPlus";
import {getIcon} from "../../../base/plus/JsPlus";
import {usePageCtx} from "../../ctx/CtxPage";
import DialogIconPicker from "../../dialog/DialogIconPicker";
import {useFormCtx} from "../../form/viewer/base/CtxForm";
import IconStrip from "../icon/IconStrip";
import RawIcon from "./RawIcon";

export default function RawIconPicker(props: {
  defn: DefnFieldEditable
  fieldSize?: EnumDefnThemeFieldSize
  value?: string,
  label?: string,
  onClick?: (value?: string) => void,
  placeHolder?: string,
  required?: boolean,
  autoFocus?: boolean,
  hiddenLabel?: boolean,
  disabled?: boolean,
  name?: string,
  readonly?: boolean,
  error?: FieldError,
  helperText?: string,
  icon?: string
})
{
  const {
    defn,
    value,
    label,
    onClick,
    placeHolder,
    required,
    autoFocus,
    hiddenLabel,
    disabled,
    name,
    readonly,
    fieldSize,
    error,
    helperText,
    icon
  } = props;

  const pageCtx = usePageCtx();
  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defn);

  const getOnClick = formCtx.getOnClick();

  const [SelectedIcon, setSelectedIcon] = useState<OverridableComponent<SvgIconTypeMap>>();

  const fieldBorderColor = formCtx.getFieldBorderColor;

  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const openDialog = () =>
  {
    if(!(readonly || disabled))
    {
      pageCtx.showDialog(<DialogIconPicker
        value={value}
        onClickOk={(value) =>
        {
          onClick && onClick(value);
        }}
      />);
    }
    getOnClick && getOnClick(fieldId, "field");
  };

  useEffect(() =>
  {
    if(value)
    {
      getIcon(value).then((icon) => setSelectedIcon(icon));
    }
    else
    {
      setSelectedIcon(undefined);
    }
  }, [value]);

  return (
    <TextField
      sx={{
        ...borderColor && {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: borderColor
            }
          }
        }
      }}
      fullWidth
      size={fieldSize}
      value={value ?? ""}
      label={!hiddenLabel ? label ?? "Icon" : undefined}
      name={name}
      required={required}
      autoFocus={autoFocus}
      aria-readonly={readonly}
      placeholder={placeHolder}
      error={Boolean(error?.message)}
      helperText={error?.message ? error.message : helperText}
      disabled={disabled}
      onKeyDown={(event) =>
      {
        if(event.key === "Enter")
        {
          openDialog();
        }
      }}
      InputProps={{
        startAdornment: (
          SelectedIcon &&
          <IconButton
            size={"small"}
          >
            <SelectedIcon />
          </IconButton>
        ),
        endAdornment: (
          icon ? <RawIcon icon={icon} />
            : SelectedIcon && !readonly &&
            <IconButton
              size={"small"}
              onClick={(event) =>
              {
                event.stopPropagation();
                setSelectedIcon(undefined);
                onClick && onClick(undefined);
              }}
            >
              <IconStrip
                value={"close"}
              />
            </IconButton>

        )
      }}
      onClick={openDialog}
    />
  );
}


