import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {OverridableStringUnion} from "@mui/types";
import parse from "html-react-parser";
import {CSSProperties} from "react";
import React from "react";
import Highlighter from "react-highlight-words";
import {EnumDefnPlacement} from "../../../api/meta/base/Types";
import {fnRemovedMarkDownSymbols} from "../../../base/plus/StringPlus";
import {removeSpecialChars} from "../../../base/plus/StringPlus";
import {fnMarkdownParser} from "../../../base/plus/StringPlus";
import {px} from "../../../base/plus/StringPlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";

export default function RawMarkDown(props: {
  value?: string,
  optional?: string,
  color?: StandardCSSProperties["color"],
  variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>,
  searchWords?: string[],
  flexGrow?: StandardCSSProperties["flexGrow"],
  flex?: StandardCSSProperties["flex"],
  width?: StandardCSSProperties["width"],
  maxWidth?: StandardCSSProperties["maxWidth"],
  height?: StandardCSSProperties["height"],
  noWrap?: boolean,
  ml?: number,
  mr?: number,
  mt?: number,
  mb?: number,
  pl?: number,
  pr?: number,
  pt?: number,
  pb?: number,
  bold?: boolean,
  italic?: boolean,
  textAlign?: EnumDefnPlacement,
  breakWords?: boolean,
  fakeSpace?: React.ReactNode,
  textDecoration?: StandardCSSProperties["textDecoration"],
  cursor?: CSSProperties["cursor"]
  maxNumberOfLine?: number,
  isUserSelect?: boolean,
  includeColor?: boolean,
  includeNewLine?: boolean,
  customStyle?: React.CSSProperties | undefined
}): React.JSX.Element | null
{
  const theme = useTheme();

  const {
    searchWords,
    variant,
    textDecoration,
    textAlign,
    isUserSelect,
    breakWords,
    fakeSpace,
    bold,
    maxWidth,
    maxNumberOfLine,
    value,
    mb,
    mt,
    mr,
    ml,
    pb,
    pt,
    pr,
    pl,
    italic,
    flexGrow,
    flex,
    color,
    noWrap,
    width,
    includeNewLine,
    includeColor
  } = props;

  if(!value)
  {
    return null;
  }

  return (
    <Typography
      variant={variant}
      component={"span"}
      noWrap={noWrap ?? true}
      sx={{
        color: theme.common.color(color as TypeTextColor ?? "textPrimary") || color,
        cursor: props.cursor,
        width: width,
        flex: flex,
        flexGrow: flexGrow,
        ...bold && {fontWeight: 500},
        ...noWrap && {overflow: "hidden"},
        ...italic && {
          fontStyle: "italic",
          overflow: "visible"
        },
        ml: px(ml),
        mr: px(mr),
        mt: px(mt),
        mb: px(mb),
        pl: px(pl),
        pr: px(pr),
        pt: px(pt),
        pb: px(pb),
        maxWidth: maxWidth,
        textAlign: textAlign as CSSProperties["textAlign"],
        textDecoration: textDecoration,
        overflowWrap: breakWords ? "anywhere" : "normal",
        ...props.breakWords && {wordBreak: "break-word"},
        whiteSpace: breakWords ? "pre-wrap" : "nowrap",
        userSelect: "text",
        ...maxNumberOfLine && {
          display: "-webkit-box",
          overflowY: "hidden",
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis"
        },
        WebkitLineClamp: maxNumberOfLine,
        ...isUserSelect && {userSelect: "text"}
      }}
      style={props.customStyle}
    >
      {
        searchWords === undefined
          ? parse(fnMarkdownParser(value, includeColor, includeNewLine))
          :
          (<Highlighter
            searchWords={searchWords.map(str => removeSpecialChars(str))}
            textToHighlight={fnRemovedMarkDownSymbols(value)}
            highlightStyle={{background: theme.common.bgcolorHighlight}}
          />)
      }
      {fakeSpace}
    </Typography>
  );
}
