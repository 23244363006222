import styled from "@emotion/styled";
import {Box} from "@mui/material";
import {useCallback} from "react";
import {useRef} from "react";
import {useState} from "react";
import React from "react";
import {Size} from "react-virtualized-auto-sizer";
import AutoSizer from "react-virtualized-auto-sizer";
import {VirtuosoGrid} from "react-virtuoso";
import {VirtuosoHandle} from "react-virtuoso";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import theme from "../../../base/plus/ThemePlus";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinderAll} from "../../../base/types/list/TypesList";
import {useAppSelector} from "../../app/AppHooks";
import {CbOnClickListItem} from "../List";
import {ListItem} from "./ListItem";

const minColumnWidth = theme.common.gridItemMinWidth;
const gapHalf = theme.common.gapHalf;

export default function GridVirtuoso<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  selectList: SelectList,
  onClickListItem?: CbOnClickListItem,
  onItemSubscribe?: (itemId: TypeListItemId) => void,
  onItemUnsubscribe?: (itemId: TypeListItemId) => void,
  onBottomReached?: () => void,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>,
})
{
  const selectList = props.selectList;
  const onBottomReached = props.onBottomReached;

  const displayItemIds = useAppSelector(state => selectList(state).displayItemIds);

  const [isScrolling, setIsScrolling] = useState(false);
  const refList = useRef<VirtuosoHandle | null>(null);

  const cbComputeItemKey = useCallback((index: number) => displayItemIds[index], [displayItemIds]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%"
      }}
    >
      <AutoSizer>
        {({
          height,
          width
        }: Size) =>
        {
          return (
            <VirtuosoGrid
              atBottomStateChange={atBottom => (onBottomReached && atBottom) && onBottomReached()}
              style={{
                height: height,
                width: width,
                overflowY: "auto"
              }}
              totalCount={displayItemIds.length}
              scrollSeekConfiguration={{
                enter: (velocity) =>
                {
                  if(Math.abs(velocity) > 50)
                  {
                    setIsScrolling(true);
                  }
                  else
                  {
                    setIsScrolling(false);
                  }
                  return false;
                },
                exit: (velocity) =>
                {
                  if(Math.abs(velocity) < 10)
                  {
                    setIsScrolling(false);
                  }
                  return false;
                }
              }}
              ref={refList}
              components={{
                Item: ItemContainer,
                List: ListContainer
              }}
              computeItemKey={cbComputeItemKey}
              overscan={height / 2}
              itemContent={(index) =>
              {
                const itemId = displayItemIds[index];
                return (
                  <ListItem
                    key={itemId}
                    itemId={itemId}
                    itemWidth={width}
                    selectList={selectList}
                    isScrolling={isScrolling}
                    onClickListItem={props.onClickListItem}
                    onItemSubscribe={props.onItemSubscribe}
                    onItemUnsubscribe={props.onItemUnsubscribe}
                    listBinder={props.listBinder}
                  />
                );
              }}
            />
          );
        }}
      </AutoSizer>
    </Box>
  );
}

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex: none;
  align-items: stretch;
  overflow: hidden;
`;

const ListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${minColumnWidth}px, 1fr));
  gap: ${px(gapHalf)};

  :first-of-type
  {
    padding-left: ${px(gapHalf)};
    margin-top: ${px(gapHalf)};
  }

  :last-of-type
  {
    padding-right: ${px(gapHalf)};
  }
`;
