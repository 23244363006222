import {useEffect} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {useState} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {isGridId} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoLayoutOverlaySpreadsheet} from "../../../../api/meta/base/dto/DefnDtoLayoutOverlaySpreadsheet";
import {DefnFieldRef} from "../../../../api/meta/base/dto/DefnFieldRef";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnLayoutCard} from "../../../../api/meta/base/dto/DefnLayoutCard";
import {DefnLayoutGrid} from "../../../../api/meta/base/dto/DefnLayoutGrid";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {FormValue} from "../../../../api/meta/base/dto/FormValue";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {toFieldFormListItemsKey} from "../../../../base/plus/FormPlus";
import {loopDefnForm} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {random} from "../../../../base/plus/StringPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {useAppSelector} from "../../../../nucleus/app/AppHooks";
import {useDeviceType} from "../../../../nucleus/app/AppHooks";
import DialogAtom from "../../../../nucleus/atom/dialog/DialogAtom";
import {usePageCtx} from "../../../../nucleus/ctx/CtxPage";
import {IRefBrParams} from "../../../../nucleus/form/viewer/base/formViewer/TypesFormViewer";
import {loopLayoutItemLine} from "../../../../nucleus/form/viewer/base/FormViewerPlus";
import {FieldValueFormList} from "../../../../nucleus/form/viewer/uiOnly/FieldFormList";
import {FieldValueFormListItem} from "../../../../nucleus/form/viewer/uiOnly/FieldFormList";
import SrvcRefBr from "../../../../srvc/app/form/SrvcRefBr";
import UiRefBr from "../UiRefBr";

const dialogWidth = 900;
const dialogHeight = 655;

export default function useRefBr(props: {
  entId: EntId,
  getValues?: () => FormValue | undefined,
  cbOnClickItem?: (rowId: RowId, spreadSheetId: MetaIdSpreadsheet, listName: string, selected?: boolean) => void
})
{
  const entId = props.entId;
  const getValues = props.getValues;
  const onClickItem = props.cbOnClickItem;
  const deviceType = useDeviceType();
  const pageCtx = usePageCtx();

  const getRefBrNode = useCallback((params: IRefBrParams) =>
  {
    const formValue = getValues && getValues();
    const fieldId = params.fieldId;
    const defnForm = params.defnForm;
    const onSubmit = params.onSubmit;
    const isFieldIdGrid = isGridId(fieldId);
    const fieldValueGrid = isFieldIdGrid ? formValue?.valueMap[fieldId] : undefined;

    return (
      <UiRefBr
        defnForm={defnForm}
        entId={entId}
        refFieldId={params.fieldRefId}
        isMultiPick={isGridId(fieldId)}
        gridValue={isFieldIdGrid ? fieldValueGrid : undefined}
        formValue={formValue}
        cbOnSubmit={(value) =>
        {
          onSubmit && onSubmit(value);
        }}
        cbOnClickListItem={onClickItem}
        gridRow={params.gridRow}
        compositeId={params.compositeId}
        enableSelection={params.enableSelection}
      />
    );

  }, [entId, getValues, onClickItem]);

  const openRefBrDialog = useCallback((params: IRefBrParams) =>
  {
    const cbOnClickShare = params.cbOnClickShare;
    const showShareIconInDialogHeader = params.showShareIconInDialogHeader;

    const onClickHeaderIcon = (headerIcon: EnumIconStrip) =>
    {
      if(headerIcon === "share")
      {
        pageCtx.showDialog(undefined);
        cbOnClickShare && cbOnClickShare();
      }
    };

    const onSubmit = params.onSubmit;
    const form = params.defnForm;
    const title = form ? getLabel(form) : "";
    const headerIcon: EnumIconStrip[] = [];
    deviceType === "desktop" && headerIcon.push("openInFull");
    showShareIconInDialogHeader && headerIcon.push("share");

    const refBrNode = getRefBrNode({
      ...params,
      onSubmit: value =>
      {
        pageCtx.showDialog(undefined);
        setTimeout(() => onSubmit && onSubmit(value));
      }
    });

    pageCtx.showDialog(<DialogAtom
      fullScreen={deviceType !== "desktop"}
      title={title}
      contentWidth={dialogWidth}
      contentHeight={dialogHeight}
      onClickHeaderIcon={onClickHeaderIcon}
      headerIcons={headerIcon}
      content={refBrNode}
    />);

    return true;
  }, [deviceType, getRefBrNode, pageCtx]);

  return {
    openRefBrDialog,
    getRefBrNode
  };
}

interface IRefBrForceOpenParams
{
  fieldId: MetaIdField;
  fieldRefId: MetaIdField;
  compositeId: MetaIdComposite;
}

export function useRefBrForceOpen(props: {
  entId: EntId,
  cbOnClickItem?: (rowId: RowId, spreadSheetId: MetaIdSpreadsheet, listName: string, selected?: boolean) => void
})
{
  const entId = props.entId;
  const {
    openRefBrDialog,
    getRefBrNode
  } = useRefBr({
    entId: entId,
    cbOnClickItem: props.cbOnClickItem
  });

  const getParams = useCallback((form: DefnForm) =>
  {
    const params = [] as IRefBrForceOpenParams[];

    loopDefnForm(form, (parent, comp) =>
    {
      if(comp?.type === "ref" || comp?.type === "refReport")
      {
        const refField = (comp as DefnFieldRef);
        if(refField.forceOpenOnFormCreate)
        {
          const fieldRefId = refField.metaId;
          const compositeId = (parent as DefnGrid | DefnSection).metaId;
          params.push({
            fieldId: isGridId(compositeId) ? compositeId : fieldRefId,
            fieldRefId: fieldRefId,
            compositeId: compositeId
          });
        }
      }
    });

    return params;
  }, []);

  const getNodeForceOpenRefDialogOnFormCreate = useCallback((params: {
    defnForm: DefnForm,
    enableSelection?: boolean,
    onSubmit: (value: FieldValues) => void,
  }) =>
  {
    const defnForm = params.defnForm;
    const refBrParams = getParams(defnForm);
    if(refBrParams.length)
    {
      return refBrParams.map(refParams => getRefBrNode({
        ...refParams,
        defnForm: defnForm,
        enableSelection: params.enableSelection,
        onSubmit: params.onSubmit
      }));
    }
    return false;
  }, [getParams, getRefBrNode]);

  const openRefBrDialogRecursive = useCallback((params: {
    values: FieldValues,
    refParams: IRefBrForceOpenParams[],
    defnForm: DefnForm,
    enableSelection?: boolean,
    showShareIconInDialogHeader?: boolean,
    cbOnClickShare?: () => void,
    onSubmit: (value: FieldValues) => void,
    index: number,
  }) =>
  {
    if(params.index < params.refParams.length)
    {
      const refParams = params.refParams[params.index];
      if(refParams)
      {
        openRefBrDialog({
          ...refParams,
          defnForm: params.defnForm,
          cbOnClickShare: params.cbOnClickShare,
          showShareIconInDialogHeader: params.showShareIconInDialogHeader,
          enableSelection: params.enableSelection,
          onSubmit: value =>
          {
            params.values = {
              ...params.values,
              ...value
            };

            if(params.index === params.refParams.length - 1)
            {
              params.onSubmit(params.values);
            }
            else
            {
              openRefBrDialogRecursive({
                ...params,
                index: params.index + 1
              });
            }
          }
        });
      }
    }
  }, [openRefBrDialog]);

  const forceOpenRefDialogOnFormCreate = useCallback((params: {
    defnForm: DefnForm,
    enableSelection?: boolean,
    showShareIconInDialogHeader?: boolean,
    cbOnClickShare?: () => void,
    onSubmit: (value: FieldValues) => void,
  }) =>
  {
    const defnForm = params.defnForm;
    const refBrParams = getParams(defnForm);
    let values = {} as FieldValues;

    if(refBrParams.length)
    {
      openRefBrDialogRecursive({
        values: values,
        refParams: refBrParams,
        defnForm: defnForm,
        enableSelection: params.enableSelection,
        showShareIconInDialogHeader: params.showShareIconInDialogHeader,
        cbOnClickShare: params.cbOnClickShare,
        index: 0,
        onSubmit: params.onSubmit
      });
      return true;
    }

    return false;
  }, [getParams, openRefBrDialogRecursive]);

  return {
    forceOpenRefDialogOnFormCreate,
    getNodeForceOpenRefDialogOnFormCreate
  };
}

export function useRefBrCalcFormValue(props: {
  defnForm?: DefnForm,
  sourceDefnForm?: DefnForm,
  srvcRefBr: SrvcRefBr
  selectList: SelectList,
  isMultiPick: boolean,
  gridValue?: FieldValueGrid,
  layout?: DefnLayoutGrid,
  overlayLayout?: DefnDtoLayoutOverlaySpreadsheet,
})
{
  const srvc = props.srvcRefBr;
  const defnForm = props.defnForm;
  const sourceDefnForm = props.sourceDefnForm;
  const overlayLayout = props.overlayLayout;

  const gridValue = props.gridValue;
  const isMultiPick = props.isMultiPick;
  const selectList = props.selectList;

  const pickItemIds = useAppSelector(state => selectList(state).pickItemIds);

  const [countVersion, setCountVersion] = useState<string>();
  const [selectedCount, setSelectedCount] = useState(0);
  const [submitEnable, setSubmitEnable] = useState<boolean>(false);
  const fieldFormList = srvc.fnGetRefBrListFieldName();

  const editableFieldIdSet = useMemo(() =>
    fnGetEditableFieldIdSet(overlayLayout, defnForm), [overlayLayout, defnForm]);

  const {
    isMultiPickWithEditable
  } = srvc.fnGetPickType(isMultiPick, overlayLayout);

  const calcValuesSinglePick = useCallback((value?: FieldValueFormListItem): FieldValues | undefined =>
  {
    return srvc.calcFieldSectionValue(value, defnForm, editableFieldIdSet);
  }, [defnForm, editableFieldIdSet, srvc]);

  const calcValuesMultiPick = useCallback((fieldValue: FieldValueFormListItem): void =>
  {
    srvc.calcFieldGridValue(fieldValue,
      defnForm,
      sourceDefnForm,
      editableFieldIdSet
    );
  }, [defnForm, editableFieldIdSet, sourceDefnForm, srvc]);

  const calcSubmitGridValue = useCallback((): FieldValueGrid =>
  {
    return srvc.getCurrentGridValue();
  }, [srvc]);

  const onWatch = useCallback((key: string, _: any, values: FieldValues) =>
  {
    const fieldFormListDto = toFieldFormListItemsKey(key);
    if(fieldFormListDto?.fieldFormListId === fieldFormList && editableFieldIdSet?.length)
    {
      setCountVersion(random());
      const fieldValues = values[fieldFormList] as FieldValueFormList;
      const rowId = fieldFormListDto?.rowId;
      const fieldValue = fieldValues.map[rowId] as FieldValueFormListItem;
      if(fieldValue)
      {
        calcValuesMultiPick(fieldValue);
      }
    }
  }, [calcValuesMultiPick, editableFieldIdSet?.length, fieldFormList]);

  useEffect(() =>
  {
    if(isMultiPick && !isMultiPickWithEditable)
    {
      srvc.calcPickedItems(pickItemIds, defnForm, sourceDefnForm);
      if(pickItemIds)
      {
        setCountVersion(random());
      }
    }
  }, [defnForm, isMultiPick, isMultiPickWithEditable, pickItemIds, sourceDefnForm, srvc]);

  useEffect(() =>
  {
    const length = srvc.getCurrentGridValue().keys.length;
    setSelectedCount(length);
    if(length === 0 && gridValue?.keys.length)
    {
      setSubmitEnable(true);
    }
    else if(length > 0)
    {
      setSubmitEnable(true);
    }
    else
    {
      setSubmitEnable(false);
    }
  }, [countVersion]);

  useEffect(() =>
  {
    if(gridValue?.keys.length)
    {
      setCountVersion(random());
    }
  }, [gridValue?.keys.length, srvc]);

  return {
    onWatch: onWatch,
    calcValuesSinglePick: calcValuesSinglePick,
    calcSubmitGridValue: calcSubmitGridValue,
    selectedItemsCount: selectedCount,
    submitEnable: submitEnable,
    editableFieldIdSet: editableFieldIdSet
  };
}

export function fnGetLayoutItemListOrCard(layout?: DefnLayoutGrid)
{
  return (layout?.kind === "list" || layout?.kind === "card")
    ? (layout as DefnLayoutCard)?.item
    : undefined;
}

export function fnGetEditableFieldIdSet(
  overlayLayout?: DefnDtoLayoutOverlaySpreadsheet,
  defnForm?: DefnForm
)
{
  const layoutItem = overlayLayout?.item;
  const editableFieldIdSet = [] as MetaIdField[];
  if(layoutItem)
  {
    loopLayoutItemLine(layoutItem, (payload) =>
    {
      const fieldId = payload.segment.lineFieldIdSet?.[0];
      if(fieldId)
      {
        const comp = defnForm?.compMap ? defnForm.compMap[fieldId] : undefined;
        if(comp)
        {
          editableFieldIdSet.push(fieldId);
        }
      }
    });
  }
  return editableFieldIdSet;
}
