import {StudioEntActionMap} from "../../../api/meta/base/dto/StudioEntActionMap";
import {StudioEntGroupMap} from "../../../api/meta/base/dto/StudioEntGroupMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {EntId} from "../../../api/meta/base/Types";
import {MetaIdAction} from "../../../api/meta/base/Types";
import {Symbol} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../base/types/list/TypesListAPSA";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntGroups extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entGroupList;
  }

  loadGroupList(
    entId: EntId,
    listName: string,
    groupMap?: StudioEntGroupMap,
    actionMap?: StudioEntActionMap,
    readOnly?: boolean)
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    if(groupMap)
    {
      groupMap.keys.forEach((itemId) =>
      {
        const group = groupMap.map[itemId];
        const groupDetails = group.details;

        const groupFilter: StudioModuleSelection | undefined = groupDetails.modules;

        if(Srvc.studio.ent.filterListByTag(entId, groupFilter))
        {
          uiItemIds.push(itemId);
        }

        const description: ILineSecondary = {};
        description.color = "textDisabled";
        if(groupDetails.description && groupDetails.description.length > 0)
        {
          description.text = groupDetails.description;
        }
        else
        {
          description.text = "No description";
        }

        const actionNameList: Symbol[] = group.actionPermissionMap ?
          this.getActionNameList(actionMap, group.actionPermissionMap.keys) : [];

        const lineSecondary2: ILineSecondary = {};
        lineSecondary2.color = "textDisabled";
        if(actionNameList.length > 0)
        {
          lineSecondary2.text = actionNameList.join(", ");
        }
        else
        {
          lineSecondary2.text = "No actions";
        }

        uiItemsById[itemId] = {
          type: "mpsl",
          hideMenu: readOnly,
          primary: {
            text: groupDetails.name
          },
          secondaryList: [description, lineSecondary2]
        } as IListItemMPSL;
      });
    }

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));

  }

  private getActionNameList(actionMap?: StudioEntActionMap, metaIdActionList?: MetaIdAction[])
  {
    const actionNameList: Symbol[] = [];
    if(actionMap && actionMap.keys.length > 0 && metaIdActionList && metaIdActionList.length > 0)
    {
      actionMap.keys.forEach((metaIdAction) =>
      {
        if(metaIdActionList.includes(metaIdAction))
        {
          const actionDetails = actionMap.map[metaIdAction].details;
          if(actionDetails?.name)
          {
            actionNameList.push(actionDetails?.name);
          }
        }
      });
    }
    return actionNameList;
  }
}
