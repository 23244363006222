import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioSetOfNumber} from "../../../api/meta/base/dto/DefnStudioSetOfNumber";
import {StudioVarSetOfNumber} from "../../../api/meta/base/dto/StudioVarSetOfNumber";

const fieldValueSetOfNumberLabel = "valueSetOfNumberLabel";

export function fnVariableBuilderSetOfNumber(fieldValueSetOfNumber: string)
{
  function getVarComp()
  {
    return {
      [fieldValueSetOfNumberLabel]: {
        type: "label",
        metaId: fieldValueSetOfNumberLabel,
        name: fieldValueSetOfNumberLabel,
        label: "Values*",
        colorVar: {
          value: "textSecondary"
        }
      } as DefnFieldLabel,
      [fieldValueSetOfNumber]: {
        type: "studioSetOfNumber",
        metaId: fieldValueSetOfNumber,
        name: "numberList"
      } as DefnStudioSetOfNumber
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarSetOfNumber
  )
  {
    const varValue = variable.value;
    if(varValue && varValue.valueSet)
    {
      valueMap[fieldValueSetOfNumber] = varValue.valueSet;
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueSetOfNumber] as number[];
    if(value && !isEmpty(value))
    {
      return {
        value: {
          valueSet: value
        }
      } as StudioVarSetOfNumber;
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
