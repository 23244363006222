import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDocument} from "../../../api/meta/base/dto/DefnFieldDocument";
import {StudioVarDocument} from "../../../api/meta/base/dto/StudioVarDocument";
import {stringToDefnDtoText} from "../../../base/plus/ArgBinderPlus";

export function fnVariableBuilderDocument(fieldValueDocument: string)
{
  function getVarComp()
  {
    return {
      [fieldValueDocument]: {
        type: "document",
        metaId: fieldValueDocument,
        label: "Value",
        required: true,
        helperTextVar: stringToDefnDtoText("Max size: 100 MB")
      } as DefnFieldDocument
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarDocument
  )
  {
    valueMap[fieldValueDocument] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueDocument];

    return {
      value: value
    } as StudioVarDocument;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
