import ISrvc from "../../../../base/ISrvc";
import SrvcCacheStudioEntDeployPaymentProviders from "./paymentProviders/SrvcCacheStudioEntDeployPaymentProviders";
import SrvcCacheStudioEntDeployPlugins from "./plugins/SrvcCacheStudioEntDeployPlugins";
import SrvcCacheStudioEntDeployVariables from "./variables/SrvcCacheStudioEntDeployVariables";

export default class SrvcCacheStudioEntDeploy extends ISrvc
{
  public readonly plugins = new SrvcCacheStudioEntDeployPlugins();
  public readonly variables = new SrvcCacheStudioEntDeployVariables();
  public readonly paymentProviders = new SrvcCacheStudioEntDeployPaymentProviders();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.plugins,
      this.variables,
      this.paymentProviders
    );
  }
}
