import {EntId} from "../../../../api/meta/base/Types";
import {SigEntAdminCaller} from "../../../../api/studio/studioMain/sig/SigEntAdminCaller";
import {SigEntAdminList} from "../../../../api/studio/studioMain/sig/SigEntAdminList";
import ISrvc from "../../../../base/ISrvc";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {setEntAdminCaller} from "../SliceCacheStudioEnt";
import {removeEntAdminList} from "../SliceCacheStudioEnt";
import {setEntAdminList} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntAdmins extends ISrvc
{
  setEntAdminList(entId: EntId, sig: SigEntAdminList)
  {
    dispatchEnt(entId, setEntAdminList({
      entId: entId,
      sigEntAdminList: sig
    }));
  }

  removeEntAdminList(entId: EntId)
  {
    dispatchEnt(entId, removeEntAdminList(entId));
  }

  setEntAdminCaller(entId: EntId, adminCaller: SigEntAdminCaller)
  {
    dispatchEnt(entId, setEntAdminCaller({
      entId: entId,
      sigEntAdminCaller: adminCaller
    }));
  }
}
