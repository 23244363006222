import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickActionId} from "../../../../api/meta/base/dto/DefnStudioPickActionId";
import {StudioEntActionMap} from "../../../../api/meta/base/dto/StudioEntActionMap";
import {EnumDefnKindAction} from "../../../../api/meta/base/Types";
import {MetaIdAction} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdAction | MetaIdAction[] | undefined;

export default function FieldRawStudioPickActionId(props: {
  defn: DefnStudioPickActionId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean
})
{
  const defn = props.defn;
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const actionMap = formStore?.actionMap;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const filterActionKindSet = defn.filterActionKindSet;
  const includeActionIdSet = defn.includeActionIdSet;
  const excludeActionIdSet = defn.excludeActionIdSet;

  const dtoOptions = useMemo(() => getOption(
    actionMap,
    filterActionKindSet,
    includeActionIdSet,
    excludeActionIdSet
  ), [actionMap, excludeActionIdSet, filterActionKindSet, includeActionIdSet, fieldValue, formStore]);

  const [options] = useInsertTrashOptions({
    type: "actionMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOption(
  actionMap?: StudioEntActionMap,
  filterActionTypeSet?: EnumDefnKindAction[],
  includeActionIdSet?: MetaIdAction[],
  excludeActionIdSet?: MetaIdAction[]): DefnDtoOption[]
{
  if(!actionMap)
  {
    return [];
  }

  const options: DefnDtoOption[] = [];

  actionMap.keys.forEach(actionId =>
  {
    const action = actionMap.map[actionId];
    const optionId = action.metaId;

    if(action && optionId)
    {
      if(!filterActionTypeSet
        || (filterActionTypeSet && filterActionTypeSet.includes(action.kind)))
      {
        if(excludeActionIdSet && excludeActionIdSet.includes(optionId))
        {
          return;
        }
        if(includeActionIdSet && !includeActionIdSet.includes(optionId))
        {
          return;
        }

        options.push({
          metaId: optionId,
          value: action.details.name || optionId
        });
      }
    }
  });

  return options;
}
