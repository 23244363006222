import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {isNonGlobalEntId} from "../../../api/meta/base/ApiPlus";
import {isEntUserId} from "../../../api/meta/base/ApiPlus";
import {EntUserId} from "../../../api/meta/base/Types";
import {GroupId} from "../../../api/meta/base/Types";
import {StoreItemId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {setCbSessionSigSignOut} from "../../../api/PushSigs";
import {setCbSessionSigTopicMessageNew} from "../../../api/PushSigs";
import {setCbSessionSigTopicMessageProps} from "../../../api/PushSigs";
import {setCbSessionSigTopicGroupTyping} from "../../../api/PushSigs";
import {setCbSessionSigTopic} from "../../../api/PushSigs";
import ISrvc from "../../../base/ISrvc";
import {textUser} from "../../../base/plus/SrvcPlus";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {TypeComboIdChat} from "../../../base/types/TypesComboId";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {toComboIdDto} from "../../../base/types/TypesComboId";
import {isComboId} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {isDebugLog} from "../../../base/util/AppLog";
import {logDebug} from "../../../base/util/AppLog";
import {setGroupTyping} from "../../../cache/app/group/SliceCacheGroup";
import {isYou} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";
import SrvcPubSubCaller from "./SrvcPubSubCaller";
import SrvcPubSubGroup from "./SrvcPubSubGroup";
import SrvcPubSubHome from "./SrvcPubSubHome";
import SrvcPubSubMsg from "./SrvcPubSubMsg";
import SrvcPubSubStore from "./SrvcPubSubStore";
import SrvcPubSubStudio from "./SrvcPubSubStudio";
import SrvcPubSubUser from "./SrvcPubSubUser";

export default class SrvcPubSub extends ISrvc
{
  public readonly caller = new SrvcPubSubCaller();
  public readonly group = new SrvcPubSubGroup();
  public readonly home = new SrvcPubSubHome();
  public readonly msg = new SrvcPubSubMsg();
  public readonly studio = new SrvcPubSubStudio();
  public readonly user = new SrvcPubSubUser();
  public readonly store = new SrvcPubSubStore();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.caller,
      this.group,
      this.home,
      this.msg,
      this.studio,
      this.user,
      this.store
    );
  }

  doInit()
  {
    setCbSessionSigTopic(sig =>
    {
      if(isDebugLog())
      {
        debug(`## sigTopic, sig = ${JSON.stringify(sig)}`);
      }
      Srvc.app.session.insertPubSubLog(sig);
      this.handleSigTopic(sig);
    });

    setCbSessionSigTopicGroupTyping(sig =>
    {
      if(isDebugLog())
      {
        debug(`## sigTopicTyping, sig = ${JSON.stringify(sig)}`);
      }
      store.dispatch((dispatch, getState) =>
      {
        const userId = toComboId(sig.artifactId, sig.targetEntUserId);
        const rootState = getState();
        const user = rootState.cache.app.user.userAvatarMap[userId];
        if(user && !isYou(rootState, user))
        {
          sig.targetUserText = user
            ? textUser(user)
            : sig.targetUserText;
          dispatch(setGroupTyping(sig));
        }
      });
    });

    setCbSessionSigTopicMessageNew((sig) =>
    {
      if(isDebugLog())
      {
        Srvc.app.session.insertPubSubLog(sig);
        debug(`## setCbSessionSigTopicMessageNew, sig = ${JSON.stringify(sig)}`);
      }

      store.dispatch((_, getState) =>
        Srvc.getSrvcChatArray().forEach(srvc => srvc.handleSigTopicMessageNew(getState(), sig)));
    });

    setCbSessionSigTopicMessageProps((sig) =>
    {
      if(isDebugLog())
      {
        Srvc.app.session.insertPubSubLog(sig);
        debug(`## setCbSessionSigTopicMessageProps, sig = ${JSON.stringify(sig)}`);
      }

      store.dispatch((_, getState) =>
        Srvc.getSrvcChatArray().forEach(srvc => srvc.handleSigTopicMessageProps(getState(), sig)));
      Srvc.cache.home.drawer.msgLast.handleSigTopicMessageProps(sig);
    });

    setCbSessionSigSignOut(() =>
    {
      if(isDebugLog())
      {
        debug("## sigSignOut");
      }

      Srvc.signOut();
    });
  }

  homeAvatarTopic(itemId: TypeComboId | EntId)
  {
    if(isComboId(itemId))
    {
      const split = toComboIdDto(itemId);
      const entId = split.entId;
      if(isEntId(entId))
      {
        const chatId = split.chatId;
        if(isGroupId(chatId))
        {
          return this.group.getGroupAvatarTopic(entId, chatId);
        }
        else if(isEntUserId(chatId))
        {
          return this.user.getUserAvatarTopic(entId, chatId);
        }

        if(isNonGlobalEntId(entId))
        {
          return this.caller.getEntAvatarUserTopic(entId as EntId);
        }
      }
    }
    else if(isNonGlobalEntId(itemId))
    {
      return this.caller.getEntAvatarUserTopic(itemId as EntId);
    }
  }

  homeAvatarBulk(subscriberId: TypeSubscriberId, dtoTopicList: DtoTopic[], unsubscribe?: boolean): void
  {
    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unSubscribeBulk(subscriberId, dtoTopicList);

    }
    else
    {
      Srvc.app.session.subscribeBulk(subscriberId,
        dtoTopicList,
        dtoTopicList =>
        {
          dtoTopicList.forEach(dtoTopic =>
          {
            switch(dtoTopic?.type)
            {
              case "groupAvatar":
                Srvc.cache.app.group.wsocGroupAvatarGet(dtoTopic.artifactId, dtoTopic.aboutId as GroupId);
                break;
              case "userAvatar":
                Srvc.cache.app.user.wsocUserAvatarGet(dtoTopic.artifactId, dtoTopic.aboutId as EntUserId);
                break;
              case "entAvatarUser":
                Srvc.cache.app.caller.wsocEntAvatarUserGet(dtoTopic.aboutId);
                break;
            }
          });
        }
      );
    }
  }

  homeAvatar(subscriberId: TypeSubscriberId, itemId: TypeListItemId, unsubscribe?: boolean): void
  {
    const dtoTopic = this.homeAvatarTopic(itemId);
    switch(dtoTopic?.type)
    {
      case "groupAvatar":
        this.group.groupAvatar(subscriberId, dtoTopic.artifactId, dtoTopic.aboutId as string, unsubscribe);
        break;
      case "userAvatar":
        this.user.userAvatar(subscriberId, dtoTopic.artifactId, dtoTopic.aboutId as string, unsubscribe);
        break;
      case "entAvatarUser":
        this.caller.entAvatarUser(subscriberId, dtoTopic.artifactId, unsubscribe);
        break;
    }
  }

  storeAvtar(subscriberId: TypeSubscriberId, storeItemId: StoreItemId, unsubscribe?: boolean)
  {
    this.caller.storeItemAvatar(subscriberId, storeItemId, unsubscribe);
  }

  handleSigTopic(sig: SigTopic, isOnSub?: boolean)
  {
    if(!this.msg.handleSigTopic(sig, isOnSub))
    {
      if(!this.user.handleSigTopic(sig, isOnSub))
      {
        if(!this.group.handleSigTopic(sig, isOnSub))
        {
          if(!this.caller.handleSigTopic(sig, isOnSub))
          {
            if(!this.home.handleSigTopic(sig, isOnSub))
            {
              if(!this.studio.handleSigTopic(sig, isOnSub))
              {
                this.store.handleSigTopic(sig, isOnSub);
              }
            }
          }
        }
      }
    }
    if(!isOnSub)
    {
      logDebug("SrvcPubSub", `## sigTopic, sig = ${JSON.stringify(sig)}`);
    }
  }

  handleTopicUnSub(sig: SigTopic)
  {
    if(!this.msg.handleTopicUnSub(sig))
    {
      if(!this.user.handleTopicUnSub(sig))
      {
        if(!this.group.handleTopicUnSub(sig))
        {
          if(!this.caller.handleTopicUnSub(sig))
          {
            if(!this.home.handleTopicUnSub(sig))
            {
              if(!this.studio.handleTopicUnSub(sig))
              {
                this.store.handleTopicUnSub(sig);
              }
            }
          }
        }
      }
    }
    logDebug("SrvcPubSub", `## sigTopic unsubscribe, sig = ${JSON.stringify(sig)}`);
  }

  handleSubscribeRecentList(
    subscriberId: TypeSubscriberId,
    dtoTopicList: DtoTopic[],
    forceGet?: boolean,
    unsubscribe?: boolean,
    cbSuccess?: () => void)
  {
    if(dtoTopicList.length)
    {
      if(Boolean(unsubscribe))
      {
        Srvc.app.session.unSubscribeBulk(subscriberId, dtoTopicList);
        cbSuccess && cbSuccess();
      }
      else
      {
        Srvc.app.session.subscribeBulk(subscriberId, dtoTopicList);

        const rootState = store.getState();
        dtoTopicList.forEach(dtoTopic =>
        {
          switch(dtoTopic?.type)
          {
            case "groupAvatar":
              const entGroupId = toComboId(dtoTopic.artifactId, dtoTopic.aboutId) as TypeComboIdChat;
              const group = rootState.cache.app.group.groupAvatarMap[entGroupId];
              if(!group || forceGet)
              {
                Srvc.cache.app.group.wsocGroupAvatarGet(dtoTopic.artifactId, dtoTopic.aboutId as GroupId);
              }
              break;
            case "userAvatar":
              const userId = toComboId(dtoTopic.artifactId, dtoTopic.aboutId) as TypeComboIdChat;
              const user = rootState.cache.app.user.userAvatarMap[userId];
              if(!user || forceGet)
              {
                Srvc.cache.app.user.wsocUserAvatarGet(dtoTopic.artifactId, dtoTopic.aboutId as EntUserId);
              }
              break;
            case "entAvatarUser":
              const entUserId = toComboId(dtoTopic.artifactId, dtoTopic.aboutId) as TypeComboIdChat;
              const entUser = rootState.cache.app.user.userAvatarMap[entUserId];
              if(!entUser || forceGet)
              {
                Srvc.cache.app.caller.wsocEntAvatarUserGet(dtoTopic.aboutId);
              }
              break;
            case "messageLast":
              const entChatId = toComboId(dtoTopic.artifactId, dtoTopic.aboutId) as TypeComboIdChat;
              const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
              if(!lastMessage || forceGet)
              {
                Srvc.cache.home.drawer.msgLast.wsocLastMessageGet(dtoTopic.artifactId, dtoTopic.aboutId);
              }
          }
        });
        cbSuccess && cbSuccess();
      }
    }
    else
    {
      cbSuccess && cbSuccess();
    }
  }

  protected doSignOut()
  {
    super.doSignOut();

    setCbSessionSigTopic(undefined);
    setCbSessionSigTopicGroupTyping(undefined);
    setCbSessionSigTopicMessageNew(undefined);
    setCbSessionSigTopicMessageProps(undefined);
    setCbSessionSigSignOut(undefined);
  }
}

function debug(message: any)
{
  logDebug("SrvcPubSub", message);
}
