import {SpreadsheetFilterValue} from "../../../api/ent/base/dto/SpreadsheetFilterValue";
import {MsgRefFieldDataPaginatedGet} from "../../../api/ent/entAside/msg/MsgRefFieldDataPaginatedGet";
import {RpcEntAside} from "../../../api/ent/entAside/RpcEntAside";
import {SigRefFieldPaginatedData} from "../../../api/ent/entAside/sig/SigRefFieldPaginatedData";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {MetaIdComposite} from "../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {SelectList} from "../../../base/plus/ListPlus";
import {Srvc} from "../../Srvc";
import SrvcRefBr from "../form/SrvcRefBr";

export default class SrvcSpreadSheetBr extends SrvcRefBr
{
  private readonly pageSize = 50;

  constructor(readonly selectList: SelectList)
  {
    super(selectList);
  }

  rpcRefFieldPaginatedDataGet(params: {
    entId: EntId,
    metaIdForm: MetaIdForm,
    refField: MetaIdField,
    inputFormValue: FormValueRaw;
    pageNumber: number,
    searchText?: string,
    sortByFieldId?: MetaIdField,
    ascOrder?: boolean,
    appliedFilters?: SpreadsheetFilterValue[],
    includeFilters?: boolean,
    layoutSpreadsheetId?: MetaIdLayoutGrid,
    inputFormCompositeId?: MetaIdComposite;
    inputFormGridRowId?: RowId;
    cbSuccess?: (sig: SigRefFieldPaginatedData) => void,
    cbError?: () => void
  })
  {
    const {
      entId,
      metaIdForm,
      refField,
      pageNumber,
      searchText,
      sortByFieldId,
      ascOrder,
      appliedFilters,
      includeFilters,
      layoutSpreadsheetId,
      inputFormValue,
      inputFormCompositeId,
      inputFormGridRowId,
      cbSuccess,
      cbError
    } = params;

    const msg = {
      formId: metaIdForm,
      refFieldId: refField,
      searchText: searchText,
      appliedFilters: appliedFilters,
      includeFilters: includeFilters,
      layoutSpreadsheetId: layoutSpreadsheetId,
      inputFormValueRaw: inputFormValue,
      inputFormCompositeId: inputFormCompositeId,
      inputFormGridRowId: inputFormGridRowId,
      orderByFieldId: sortByFieldId,
      pageSize: this.pageSize,
      pageNumber: pageNumber,
      ascOrder: ascOrder === undefined ? undefined : ascOrder
    } as MsgRefFieldDataPaginatedGet;

    RpcEntAside.entRefFieldPaginatedDataGet(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        if(envSig.sig)
        {
          cbSuccess && cbSuccess(envSig.sig);
        }
      }
      else
      {
        cbError && cbError();
      }
    });

  }

}

