import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../../base/plus/ListPlus";

export function reducersHomeAsideMedia()
{
  return combineReducers({
    galleryList: createListSlice("homeAsideMediaGallery",
      {
        defaultListItemType: "aps",
        pickType: "pickMany"
      }
    ).reducer,
    docsList: createListSlice("homeAsideMediaDocs", {pickType: "pickMany"}).reducer,
    linksList: createListSlice("homeAsideMediaLinks", {pickType: "pickMany"}).reducer
  });
}
