import {useEffect} from "react";
import {useState} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickOption} from "../../api/meta/base/dto/DefnFieldPickOption";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldSetOfText} from "../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioBuildColor} from "../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioPickCompId} from "../../api/meta/base/dto/DefnStudioPickCompId";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutDriveSheet} from "../../api/meta/base/dto/StudioDtoLayoutDriveSheet";
import {EnumArrayDefnFields} from "../../api/meta/base/Types";
import {EnumArrayDefnContentAlignment} from "../../api/meta/base/Types";
import {EnumDefnDriveSheetFieldLayoutOn} from "../../api/meta/base/Types";
import {EnumArrayDefnTextStyle} from "../../api/meta/base/Types";
import {EnumDefnConditionOperator} from "../../api/meta/base/Types";
import {EnumDefnDriveSheetLayoutFor} from "../../api/meta/base/Types";
import {EnumArrayDefnDriveSheetFieldLayoutOn} from "../../api/meta/base/Types";
import {EnumArrayDefnShowBorderKind} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdDriveSheet} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldGap1} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldDriveSheetLayoutFor = "driveSheetLayoutFor";
const fieldDriveSheetPickSectionId = "driveSheetSectionId";
const fieldDriveSheetPickFieldId = "driveSheetFieldId";
const fieldDriveSheetFieldLayoutOn = "driveSheetFieldLayoutOn";

const fieldConditionLabel = "conditionLabel";
const fieldConditionOperator = "conditionOperator";
const fieldConditionValue = "conditionValue";

const fieldBgColor = "bgColor";
const fieldTextColor = "textColor";
const fieldTextStyle = "textStyle";
const fieldBorderSet = "borderSet";
const fieldAlignment = "alignment";
const fieldFontSize = "fontSize";
const fieldWidth = "width";

const fieldTabDetails = "tabDetails";
const fieldTabStyles = "tabStyles";

const HEIGHT_CONTENT = 600;
const WIDTH_CONTENT = 450;

export default function DialogNewDriveSheetLayout(props: {
  formStore?: FormStore,
  driveSheetId?: MetaIdDriveSheet
  values?: StudioDtoLayoutDriveSheet,
  isFormReadOnly?: boolean,
  onClickOk: (values: StudioDtoLayoutDriveSheet) => void,
  onClose?: () => void,
})
{
  const values = props.values;
  const formStore = props.formStore;
  const driveSheetId = props.driveSheetId;
  const isFormReadOnly = props.isFormReadOnly;

  const driveSheetMap = formStore?.driveSheetMap;
  const driveSheet = driveSheetId
    ? driveSheetMap?.map[driveSheetId]
    : undefined;

  const spreadsheetId = driveSheet?.spreadsheetId;
  const formId = spreadsheetId
    ? formStore?.spreadsheetMap?.map[spreadsheetId]?.formId
    : undefined;

  const cbRef = useMemo(() => ({} as IFormRef), []);

  const [layoutType, setLayoutType] = useState<EnumDefnDriveSheetLayoutFor>();
  const [layoutOn, setLayoutOn] = useState<EnumDefnDriveSheetFieldLayoutOn>();
  const [fieldId, setFieldId] = useState<MetaIdField>();
  const [isRhsDisabled, setIsRhsDisabled] = useState<boolean>(false);

  const defnForm = useMemo(() => getDefnForm(formId, layoutType, layoutOn, fieldId, isRhsDisabled),
    [layoutType, layoutOn, formId, isRhsDisabled, fieldId]
  );

  const onSubmit = useCallback((values: FieldValues, isRhsDisabled?: boolean) =>
  {
    const dto = valueToDto(values, isRhsDisabled);
    props.onClickOk(dto);
  }, []);

  const onWatch = useCallback((key: string, value: any) =>
  {
    if(key === fieldDriveSheetLayoutFor)
    {
      setLayoutType(value);
    }
    if(key === fieldDriveSheetFieldLayoutOn)
    {
      setLayoutOn(value);
    }
    if(key === fieldDriveSheetPickFieldId)
    {
      cbRef.setValue && cbRef.setValue(fieldConditionValue, null);
      setFieldId(value);
    }
    if(key === fieldConditionOperator)
    {
      const _value = value as EnumDefnConditionOperator;
      if(_value === "hasValue" || _value === "hasNoValue")
      {
        setIsRhsDisabled(true);
      }
      else
      {
        setIsRhsDisabled(false);
      }
    }

  }, []);

  useEffect(() =>
  {
    if(values)
    {
      if(values.layoutFor)
      {
        setLayoutType(values.layoutFor);
      }
      if(values.fieldLayoutOn)
      {
        setLayoutOn(values.fieldLayoutOn);
      }
      if(values.fieldId)
      {
        setFieldId(values.fieldId);
      }
      if(values.conditionOperator === "hasValue" || values.conditionOperator === "hasNoValue")
      {
        setIsRhsDisabled(true);
      }
      else
      {
        setIsRhsDisabled(false);
      }
    }
  }, [values]);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} drive sheet layout`}
      contentHeight={HEIGHT_CONTENT}
      contentWidth={WIDTH_CONTENT}
      formProps={{
        cbRef: cbRef,
        defnForm: defnForm,
        store: formStore,
        onSubmit: val => onSubmit(val, isRhsDisabled),
        initValues: values ? dtoToValue(values) : undefined,
        onWatch: onWatch,
        formReadonly: isFormReadOnly
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? "Add more drive sheet layout"
        : undefined}
    />
  );
}

function valueToDto(values: FieldValues, isRhsDisabled?: boolean): StudioDtoLayoutDriveSheet
{
  const layoutFor = fnFieldValueToRawValue("enumDriveSheetLayoutFor",
    values[fieldDriveSheetLayoutFor]
  ) as EnumDefnDriveSheetLayoutFor;
  const layoutOn = fnFieldValueToRawValue("enumDriveSheetFieldLayoutOn",
    values[fieldDriveSheetFieldLayoutOn]
  ) as EnumDefnDriveSheetFieldLayoutOn;

  return {
    layoutFor: layoutFor,
    ...layoutFor === "sectionOrGrid" && {
      compositeId: fnFieldValueToRawValue("pickCompId", values[fieldDriveSheetPickSectionId])
    },
    ...layoutFor === "field" && {
      fieldId: fnFieldValueToRawValue("pickFieldId", values[fieldDriveSheetPickFieldId]),
      fieldLayoutOn: layoutOn,
      conditionOperator: fnFieldValueToRawValue("enumConditionOperator", values[fieldConditionOperator]),
      conditionValue: !isRhsDisabled
        ? fnFieldValueToRawValueArgBinder(values[fieldConditionValue])
        : undefined
    },
    bgColor: fnFieldValueToRawValue("studioBuildColor", values[fieldBgColor]),
    textColor: fnFieldValueToRawValue("studioBuildColor", values[fieldTextColor]),
    textStyleSet: fnFieldValueToRawValue("setOfText", values[fieldTextStyle]),
    borderSet: fnFieldValueToRawValue("setOfText", values[fieldBorderSet]),
    alignment: fnFieldValueToRawValue("pickOption", values[fieldAlignment]),
    fontSize: fnFieldValueToRawValue("number", values[fieldFontSize]),
    ...(layoutFor === "field" && layoutOn === "header") && {
      width: fnFieldValueToRawValue("number", values[fieldWidth])
    }
  } as StudioDtoLayoutDriveSheet;
}

function dtoToValue(dto: StudioDtoLayoutDriveSheet)
{
  const layoutFor = dto.layoutFor;
  return {
    [fieldDriveSheetLayoutFor]: fnRawValueToFieldValue("enumDriveSheetLayoutFor", dto?.layoutFor),
    ...layoutFor === "sectionOrGrid" && {
      [fieldDriveSheetPickSectionId]: fnRawValueToFieldValue("pickCompId", dto?.compositeId)
    },
    ...layoutFor === "field" && {
      [fieldDriveSheetPickFieldId]: fnRawValueToFieldValue("pickFieldId", dto?.fieldId),
      [fieldDriveSheetFieldLayoutOn]: fnRawValueToFieldValue("enumDriveSheetFieldLayoutOn", dto?.fieldLayoutOn),
      [fieldConditionOperator]: fnRawValueToFieldValue("enumConditionOperator", dto?.conditionOperator),
      ...fnRawValueToFieldValueArgBinder(fieldConditionValue, dto?.conditionValue)
    },
    [fieldBgColor]: fnRawValueToFieldValue("studioBuildColor", dto?.bgColor),
    [fieldTextColor]: fnRawValueToFieldValue("studioBuildColor", dto?.textColor),
    [fieldTextStyle]: fnRawValueToFieldValue("setOfText", dto?.textStyleSet),
    [fieldBorderSet]: fnRawValueToFieldValue("setOfText", dto?.borderSet),
    [fieldAlignment]: fnRawValueToFieldValue("pickOption", dto?.alignment),
    [fieldFontSize]: fnRawValueToFieldValue("number", dto?.fontSize),
    [fieldWidth]: fnRawValueToFieldValue("number", dto?.width)
  };
}

function getDefnForm(
  formId?: MetaIdForm,
  layoutType?: EnumDefnDriveSheetLayoutFor,
  layoutOn?: EnumDefnDriveSheetFieldLayoutOn,
  fieldId?: MetaIdField,
  isRhsDisabled?: boolean)
{
  const layoutConfigure = getLayoutConfigure(formId, layoutType, layoutOn, fieldId, isRhsDisabled);
  const stylesComp = getStylesComp(layoutType, layoutOn);
  const newFieldLayoutOnArray = EnumArrayDefnDriveSheetFieldLayoutOn;
  const headerIndex = newFieldLayoutOnArray.findIndex(item => item === "header");
  if(headerIndex !== -1)
  {
    const header = newFieldLayoutOnArray.splice(headerIndex, 1)[0];
    newFieldLayoutOnArray.unshift(header);
  }

  return createDefaultDefnFormStudio({

    [fieldDriveSheetLayoutFor]: {
      type: "enumDriveSheetLayoutFor",
      metaId: fieldDriveSheetLayoutFor,
      name: fieldDriveSheetLayoutFor,
      label: "Layout for",
      formId: formId,
      required: true
    } as DefnFieldPickEnum,

    ...layoutType === "sectionOrGrid" && {
      [fieldDriveSheetPickSectionId]: {
        type: "pickCompId",
        metaId: fieldDriveSheetPickSectionId,
        name: fieldDriveSheetPickSectionId,
        label: "Pick section or grid",
        formId: formId,
        required: true
      } as DefnStudioPickCompId
    },

    ...layoutType === "field" && {
      [fieldDriveSheetPickFieldId]: {
        type: "pickFieldId",
        metaId: fieldDriveSheetPickFieldId,
        name: fieldDriveSheetPickFieldId,
        label: "Pick field",
        formId: formId,
        required: true,
        includeOptionMap: arrayToMapOfOption(EnumArrayDefnFields)
      } as DefnStudioPickFieldId,

      [fieldDriveSheetFieldLayoutOn]: {
        type: "enumDriveSheetFieldLayoutOn",
        metaId: fieldDriveSheetFieldLayoutOn,
        name: fieldDriveSheetFieldLayoutOn,
        label: "Apply layout on"
      } as DefnFieldPickText
    },

    ...layoutConfigure,

    [fieldTabDetails]: {
      type: "section",
      metaId: fieldTabDetails,
      name: fieldTabDetails,
      label: "Details",
      fieldIdSet: [
        fieldDriveSheetLayoutFor,
        ...layoutType === "sectionOrGrid"
          ? [fieldDriveSheetPickSectionId]
          : [],
        ...layoutType === "field"
          ? [
            fieldDriveSheetPickFieldId,
            fieldDriveSheetFieldLayoutOn
          ]
          : [],
        ...Object.keys(layoutConfigure)
      ]
    } as DefnSection,

    ...stylesComp,

    [fieldTabStyles]: {
      type: "section",
      metaId: fieldTabStyles,
      name: fieldTabStyles,
      label: "Style",
      fieldIdSet: Object.keys(stylesComp)
    } as DefnSection,

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabIdSet: [
        fieldTabDetails,
        fieldTabStyles
      ]
    } as DefnTab
  } as Record<MetaIdField, DefnField>);

}

function getLayoutConfigure(
  formId?: MetaIdForm,
  layoutType?: EnumDefnDriveSheetLayoutFor,
  layoutOn?: EnumDefnDriveSheetFieldLayoutOn,
  fieldId?: MetaIdField,
  isRhsDisabled?: boolean)
{
  return {
    ...(fieldId && layoutType === "field" && layoutOn === "column") && {
      ...getFieldGap(fieldGap1, "thick"),

      [fieldConditionLabel]: {
        type: "label",
        metaId: fieldConditionLabel,
        name: fieldConditionLabel,
        label: "Condition",
        disabled: true
      } as DefnFieldLabel,

      [fieldConditionOperator]: {
        type: "enumConditionOperator",
        name: fieldConditionOperator,
        metaId: fieldConditionOperator,
        label: "Operator"
      } as DefnFieldPickEnum,

      [fieldConditionValue]: {
        type: "studioBuildArgBinder",
        name: fieldConditionValue,
        metaId: fieldConditionValue,
        formId: formId,
        derivedFormId: formId,
        peerFieldId: fieldId,
        peerKind: "derived",
        label: "Value",
        disabled: isRhsDisabled
      } as DefnStudioBuildArgBinder
    }

  };
}

function getStylesComp(
  layoutType?: EnumDefnDriveSheetLayoutFor,
  layoutOn?: EnumDefnDriveSheetFieldLayoutOn
)
{
  return {
    ...(layoutType === "field" && layoutOn === "header") && {
      [fieldWidth]: {
        type: "number",
        metaId: fieldWidth,
        name: fieldWidth,
        label: "Width"
      } as DefnFieldNumber
    },

    [fieldBgColor]: {
      type: "studioBuildColor",
      metaId: fieldBgColor,
      name: fieldBgColor,
      label: "Background color",
      allowShades: true,
      direction: "horizontal"
    } as DefnStudioBuildColor,

    [fieldTextColor]: {
      type: "studioBuildColor",
      metaId: fieldTextColor,
      name: fieldTextColor,
      label: "Text color",
      allowShades: true,
      direction: "horizontal"
    } as DefnStudioBuildColor,

    [fieldTextStyle]: {
      type: "setOfText",
      metaId: fieldTextStyle,
      name: fieldTextStyle,
      label: "Text styles",
      optionMap: arrayToMapOfOption(EnumArrayDefnTextStyle)
    } as DefnFieldPickEnum,

    [fieldAlignment]: {
      type: "pickOption",
      metaId: fieldAlignment,
      name: fieldAlignment,
      label: "Text alignment",
      optionSet: EnumArrayDefnContentAlignment
    } as DefnFieldPickOption,

    [fieldFontSize]: {
      type: "number",
      metaId: fieldFontSize,
      name: fieldFontSize,
      label: "Font size",
      min: 0,
      max: 400
    } as DefnFieldNumber,

    [fieldBorderSet]: {
      type: "setOfText",
      metaId: fieldBorderSet,
      name: fieldBorderSet,
      label: "Borders",
      optionMap: arrayToMapOfOption(EnumArrayDefnShowBorderKind)
    } as DefnFieldSetOfText
  };
}


