import {IconButton} from "@mui/material";
import {GoogleMapProps} from "@react-google-maps/api";
import {GoogleMap} from "@react-google-maps/api";
import {useMemo} from "react";
import {memo} from "react";
import {useReducer} from "react";
import {useState} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnLayoutGridLocmap} from "../../api/meta/base/dto/DefnLayoutGridLocmap";
import {FieldValueGeoPoint} from "../../api/meta/base/dto/FieldValueGeoPoint";
import {FieldValueLocation} from "../../api/meta/base/dto/FieldValueLocation";
import {FormValue} from "../../api/meta/base/dto/FormValue";
import {MetaIdField} from "../../api/meta/base/Types";
import {RowId} from "../../api/meta/base/Types";
import {STR_FAIL_TO_LOAD_GOOGLE_MAP} from "../../base/plus/ConstantsPlus";
import {useLocationMapResolver} from "../../base/plus/MapLayoutPlus";
import {px} from "../../base/plus/StringPlus";
import theme from "../../base/plus/ThemePlus";
import {gapStd} from "../../base/plus/ThemePlus";
import {TypeMapMarker} from "../../base/types/TypeMap";
import {ILocationMarkerBinderAll} from "../../base/types/TypeMap";
import {ILocationMapRef} from "../../base/types/TypeMap";
import {CbOnClickMapMarker} from "../../base/types/TypeMap";
import {ILatLng} from "../../base/types/TypesStudio";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawHighlighter from "../atom/raw/RawHighlighter";
import RawIcon from "../atom/raw/RawIcon";
import RawNothingHere from "../atom/raw/RawNothingHere";
import {MapMarker} from "./MapMarker";
import {IMarkerTooltip} from "./MapMarkerTooltip";
import {TooltipTitle} from "./MapMarkerTooltip";
import {MarkerTooltip} from "./MapMarkerTooltip";
import {MapPolyLine} from "./MapPolyLine";

interface FieldValueMap
{
  keys: RowId[];
  map: Record<RowId, FormValue>;
}

interface IActionMap<P>
{
  type: "add" | "update" | "remove";
  payload: P;
}

interface IActionMapAddUpdate
{
  value: FormValue;
}

interface IActionMapRemoved
{
  rowId: RowId;
}

type IActionMapPayload =
  | IActionMapAddUpdate
  | IActionMapRemoved

const mapStyles = {
  height: "100%",
  width: "100%"
} as GoogleMapProps;

export default function RawGoogleMap<SR1, SR2, SR3>(props: {
  defnForm?: DefnForm,
  layout?: DefnLayoutGridLocmap,
  initialDataMap?: FieldValueMap,
  cbRef?: ILocationMapRef,
  locationMarkerBinder?: ILocationMarkerBinderAll<SR1, SR2, SR3>,
  cbOnClickMarker?: CbOnClickMapMarker
})
{
  const defnForm = props.defnForm;
  const layout = props.layout;
  const cbRef = props.cbRef;
  const initialDataMap = props.initialDataMap;
  const cbOnClickMarker = props.cbOnClickMarker;
  const locationMarkerBinder = props.locationMarkerBinder;

  const [loadError] = useState<Error | undefined>(!(window?.google?.maps?.Map)
    ? new Error(STR_FAIL_TO_LOAD_GOOGLE_MAP)
    : undefined);

  if(loadError)
  {
    const errorMsg = loadError?.message
      ? loadError?.message
      : STR_FAIL_TO_LOAD_GOOGLE_MAP;

    return (
      <RawNothingHere
        helperTextData={{
          title: errorMsg
        }}
      />
    );
  }

  return (
    <GoogleMapRaw
      defnForm={defnForm}
      layout={layout}
      cbRef={cbRef}
      initialDataMap={initialDataMap}
      cbOnClickMarker={cbOnClickMarker}
      locationMarkerBinder={locationMarkerBinder}
    />
  );
}

function GoogleMapRaw<SR1, SR2, SR3>(props: {
  defnForm?: DefnForm,
  layout?: DefnLayoutGridLocmap,
  cbRef?: ILocationMapRef,
  initialDataMap?: FieldValueMap,
  locationMarkerBinder?: ILocationMarkerBinderAll<SR1, SR2, SR3>,
  cbOnClickMarker?: CbOnClickMapMarker,
})
{
  const defnForm = props.defnForm;
  const layout = props.layout;
  const initialDataMap = props.initialDataMap;
  const cbRef = props.cbRef;
  const locationMarkerBinder = props.locationMarkerBinder;
  const onClickMarker = props.cbOnClickMarker;

  const [infoWindowToolTipTitle, setInfoWindowToolTipTitle] = useState<IMarkerTooltip | undefined>(undefined);
  const [infoWindowPosition, setInfoWindowPosition] = useState<google.maps.LatLng | undefined>(undefined);
  const [map, setMap] = useState<google.maps.Map>();
  const [bounds] = useState(new google.maps.LatLngBounds());
  const [reCentre, setReCentre] = useState<boolean>(false);

  const [state, dispatch] = useReducer(fnMapReducer, {
    keys: initialDataMap?.keys ?? [],
    map: initialDataMap?.map ?? {}
  } as FieldValueMap);

  const {
    getGroupByFieldValue,
    getLntLng
  } = useLocationMapResolver(defnForm, layout);

  const polylineGroupMap = useMemo(() =>
  {
    if(defnForm && layout?.groupByFieldId && layout?.locationFieldId)
    {
      return getPolylineGroup({
        formValues: state.keys.map((rowId) => state.map[rowId]),
        getGroupByFieldValue: getGroupByFieldValue,
        defnForm: defnForm,
        locationFieldId: layout.locationFieldId
      }) as Record<RowId, RowId[]>;
    }

  }, [defnForm, layout, state, getGroupByFieldValue]);

  const cbOnClickMarker = useCallback((markerId: RowId, eventTarget: (EventTarget | null), latLng?: ILatLng) =>
  {
    onClickMarker && onClickMarker(markerId, eventTarget, latLng);

  }, [onClickMarker]);

  const onMouseOverMarker = useCallback((position: google.maps.LatLng, markerToolTip?: IMarkerTooltip) =>
  {
    setInfoWindowPosition(position);
    setInfoWindowToolTipTitle(markerToolTip);

  }, []);

  const onMouseOutMarker = useCallback(() =>
  {
    setInfoWindowPosition(undefined);
    setInfoWindowToolTipTitle(undefined);

  }, []);

  const onLoadMap = useCallback((map: google.maps.Map) =>
  {
    map.setOptions({
      minZoom: 3,
      maxZoom: 20
    });

    if(initialDataMap?.keys.length)
    {
      initialDataMap.keys.forEach(rowId =>
      {
        const latLng = getLntLng(initialDataMap.map[rowId]?.valueMap);

        if(latLng)
        {
          bounds.extend({
            lat: latLng.lat,
            lng: latLng.lng
          });
        }
      });
    }

    map.fitBounds(bounds);

    setMap(map);

  }, [bounds, initialDataMap, getLntLng]);

  const addRow = useCallback((formValue: FormValue) =>
  {
    dispatch({
      type: "add",
      payload: {
        value: formValue
      }
    });

    const latLng = getLntLng(formValue.valueMap);

    if(!reCentre && latLng)
    {
      bounds.extend({
        lat: latLng.lat,
        lng: latLng.lng
      });

      map?.fitBounds(bounds);
    }

  }, [map, bounds, getLntLng, reCentre]);

  const removeRow = useCallback((rowId: RowId) =>
  {
    dispatch({
      type: "remove",
      payload: {
        rowId: rowId
      }
    });

  }, []);

  const onCLickReCentre = useCallback((e: React.MouseEvent<HTMLButtonElement>) =>
  {
    e.stopPropagation();

    if(map)
    {
      if(polylineGroupMap && Object.keys(polylineGroupMap).length)
      {
        Object.values(polylineGroupMap).forEach(rowIdSet =>
        {
          const lastRowId = rowIdSet.length
            ? rowIdSet[rowIdSet.length - 1]
            : undefined;
          const formValueMap = lastRowId
            ? state.map[lastRowId]
            : undefined;
          const latLng = getLntLng(formValueMap?.valueMap);

          if(latLng)
          {
            bounds.extend({
              lat: latLng.lat,
              lng: latLng.lng
            });
          }

          map.fitBounds(bounds);
        });
      }
      else
      {
        state.keys.forEach(rowId =>
        {
          const formValueMap = state.map[rowId];
          const latLng = getLntLng(formValueMap.valueMap);

          if(latLng)
          {
            bounds.extend({
              lat: latLng.lat,
              lng: latLng.lng
            });
          }

          map.fitBounds(bounds);
        });
      }
    }

    setReCentre(false);

  }, [bounds, getLntLng, map, polylineGroupMap, state]);

  if(cbRef)
  {
    cbRef.addRow = addRow;
    cbRef.removeRow = removeRow;
  }

  return (
    <LayoutFlexCol
      height={"100%"}
      width={"100%"}
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        onLoad={onLoadMap}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          streetViewControl: false,
          disableDefaultUI: true
        }}
        onDrag={() => setReCentre(true)}
      >
        {layout?.renderingMode !== "liveLocation" &&
          <MapMarkerSet
            defnForm={defnForm}
            layout={layout}
            cbOnClickMarker={cbOnClickMarker}
            onMouseOverMarker={onMouseOverMarker}
            onMouseOutMarker={onMouseOutMarker}
            state={state}
            locationMarkerBinder={locationMarkerBinder}
          />
        }

        {(layout?.groupByFieldId && polylineGroupMap) &&
          <MapPolylineSet
            defnForm={defnForm}
            layout={layout}
            state={state}
            onClickMarker={cbOnClickMarker}
            onMouseOverMarker={onMouseOverMarker}
            onMouseOutMarker={onMouseOutMarker}
            locationMarkerBinder={locationMarkerBinder}
            polylineGroupMap={polylineGroupMap}
          />
        }

        <MapMarkerTooltip
          position={infoWindowPosition}
        >
          {
            infoWindowToolTipTitle &&
            <TooltipTitle tooltip={infoWindowToolTipTitle} />
          }
        </MapMarkerTooltip>

      </GoogleMap>

      {reCentre &&
        <MapReCentreBtn
          onCLickReCentre={onCLickReCentre}
        />
      }

    </LayoutFlexCol>
  );
}

const MemoMapMarker = memo(MapMarker) as <SR1, SR2, SR3>(props: TypeMapMarker<SR1, SR2, SR3>) => React.ReactNode;

function MapMarkerSet<SR1, SR2, SR3>(props: {
  state: FieldValueMap,
  defnForm?: DefnForm,
  layout?: DefnLayoutGridLocmap,
  locationMarkerBinder?: ILocationMarkerBinderAll<SR1, SR2, SR3>,
  cbOnClickMarker?: CbOnClickMapMarker,
  onMouseOverMarker?: (position: google.maps.LatLng, markerToolTip?: IMarkerTooltip) => void,
  onMouseOutMarker?: (position: google.maps.LatLng) => void,
})
{
  const state = props.state;

  return state.keys.map((rowId) =>
  {
    return (
      <MemoMapMarker
        key={rowId}
        value={state.map[rowId]}
        defnForm={props.defnForm}
        layout={props.layout}
        cbOnClickMarker={props.cbOnClickMarker}
        onMouseOverMarker={props.onMouseOverMarker}
        onMouseOutMarker={props.onMouseOutMarker}
        locationMarkerBinder={props.locationMarkerBinder}
      />
    );
  });
}

function MapPolylineSet<SR1, SR2, SR3>(props: {
  state: FieldValueMap,
  polylineGroupMap: Record<RowId, RowId[]>
  defnForm?: DefnForm,
  layout?: DefnLayoutGridLocmap,
  onClickMarker?: CbOnClickMapMarker,
  onMouseOverMarker?: (position: google.maps.LatLng, markerToolTip?: IMarkerTooltip) => void,
  onMouseOutMarker?: (position: google.maps.LatLng) => void,
  locationMarkerBinder?: ILocationMarkerBinderAll<SR1, SR2, SR3>,
})
{
  const defnForm = props.defnForm;
  const layout = props.layout;
  const state = props.state;
  const polylineGroupMap = props.polylineGroupMap;

  const {
    getStrokeValue,
    getLntLng,
    getColorValue
  } = useLocationMapResolver(defnForm, layout);

  return (
    <>
      {
        Object.values(polylineGroupMap).map((rowIdSet) =>
        {
          return rowIdSet.map((rowId, i) =>
          {
            if(i === rowIdSet.length - 1)
            {
              return layout?.renderingMode === "liveLocation"
                ? <MemoMapMarker
                  key={rowId}
                  value={state.map[rowId]}
                  defnForm={defnForm}
                  layout={layout}
                  cbOnClickMarker={props.onClickMarker}
                  onMouseOverMarker={props.onMouseOverMarker}
                  onMouseOutMarker={props.onMouseOutMarker}
                  locationMarkerBinder={props.locationMarkerBinder}
                />
                : null;
            }

            const startPointValue = state.map[rowId];
            const endPointValue = state.map[rowIdSet[i + 1]];

            const startPointLatLng = getLntLng(startPointValue.valueMap);
            const endPointLatLng = getLntLng(endPointValue.valueMap);

            return (startPointLatLng && endPointLatLng)
              ? <MapPolyLine
                key={rowId}
                polyLineArray={[{
                  lat: startPointLatLng.lat,
                  lng: startPointLatLng.lng
                }, {
                  lat: endPointLatLng.lat,
                  lng: endPointLatLng.lng
                }]}
                polyLineStroke={getStrokeValue(startPointValue.valueMap)}
                polyLineColor={getColorValue(startPointValue.valueMap)}
              />
              : null;
          });
        })
      }
    </>
  );
}

function getPolylineGroup(props: {
  defnForm: DefnForm,
  formValues: FormValue[],
  locationFieldId: MetaIdField,
  getGroupByFieldValue: (valueMap?: FieldValues) => string | undefined
})
{
  const defnForm = props.defnForm;
  const formValues = props.formValues;
  const locationFieldId = props.locationFieldId;
  const getGroupByFieldValue = props.getGroupByFieldValue;

  const polylineGroup = {} as Record<RowId, RowId[]>;

  sortDataByRowOrder(formValues).forEach(value =>
  {
    const groupByIdFieldValue = getGroupByFieldValue(value.valueMap);

    if(groupByIdFieldValue)
    {
      if(defnForm.compMap[locationFieldId].type === "location")
      {
        const fieldValueLocation = value.valueMap[locationFieldId] as FieldValueLocation | undefined;

        if(!fieldValueLocation || !fieldValueLocation.value.geoPoint)
        {
          return;
        }
      }
      else if(defnForm.compMap[locationFieldId].type === "geoPoint")
      {
        const fieldValueGeoPoint = value.valueMap[locationFieldId] as FieldValueGeoPoint | undefined;

        if(!fieldValueGeoPoint || !fieldValueGeoPoint.value)
        {
          return;
        }
      }

      if(!polylineGroup[groupByIdFieldValue])
      {
        polylineGroup[groupByIdFieldValue] = [value.rowId];
      }
      else
      {
        polylineGroup[groupByIdFieldValue].push(value.rowId);
      }
    }
  });

  return polylineGroup;
}

function MapMarkerTooltip(props: {
  children?: React.ReactNode,
  position?: google.maps.LatLng
})
{
  const position = props.position;
  const children = props.children;

  if(!position || !children)
  {
    return null;
  }

  return (
    <MarkerTooltip
      position={position}
    >
      {children}
    </MarkerTooltip>
  );
}

function MapReCentreBtn(props: {
  onCLickReCentre: (e: React.MouseEvent<HTMLButtonElement>) => void
})
{
  return (
    <IconButton
      sx={{
        position: "absolute",
        height: "50px",
        width: "120px",
        left: px(gapStd),
        bottom: px(gapStd),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTopLeftRadius: px(gapStd),
        borderBottomLeftRadius: px(gapStd),
        borderTopRightRadius: px(gapStd),
        borderBottomRightRadius: px(gapStd),
        backgroundColor: theme.common.color("white"),
        border: `1px solid ${theme.common.color("textDisabled")}`
      }}
      onClick={props.onCLickReCentre}
      disableRipple={true}
    >
      <RawIcon
        icon={"NavigationRounded"}
        color={"primaryDark"}
      />
      <RawHighlighter
        variant={"body2"}
        value={"Re-centre"}
        color={theme.common.color("primaryDark")}
      />
    </IconButton>
  );
}

function fnMapReducer(state: FieldValueMap, action: IActionMap<IActionMapPayload>)
{
  switch(action.type)
  {
    case "add":
    {
      const payload = action.payload as IActionMapAddUpdate;
      const rowId = payload.value.rowId;

      return {
        ...state,
        keys: [...new Set([...state.keys, rowId])],
        map: {
          ...state.map,
          [rowId]: payload.value
        }
      };
    }
    case "update":
    {
      const payload = action.payload as IActionMapAddUpdate;
      const formValue = payload.value;
      const rowId = formValue.rowId;

      const map = {...state.map};

      if(map[rowId])
      {
        map[rowId] = formValue;
      }

      return {
        ...state,
        map: map
      };
    }
    case "remove":
    {
      const payload = action.payload as IActionMapRemoved;
      const rowId = payload.rowId;
      const newRowIds = [...state.keys];
      const index = newRowIds.findIndex(id => id === rowId);

      if(index !== -1)
      {
        newRowIds.splice(index, 1);
      }

      const map = {...state.map};
      delete map[rowId];

      return {
        ...state,
        keys: [...new Set(newRowIds)],
        map: map
      };
    }
  }
}

function sortDataByRowOrder(initialDataMap: FormValue[])
{
  return initialDataMap?.sort((a, b) =>
  {
    if(a.rowOrder && b.rowOrder)
    {
      return a.rowOrder.localeCompare(b.rowOrder);
    }

    return 0;
  });
}
