import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../base/plus/ListPlus";

const pipelineVariableList = "pipelineVariableList";
const reportFormList = "reportFormList";

export function reducersProductionMain()
{
  return combineReducers({
    variableList: createListSlice(pipelineVariableList, {defaultListItemType: "p"}).reducer,
    formList: createListSlice(reportFormList, {defaultListItemType: "p"}).reducer,
    inactiveUsersList: createListSlice("inactiveUsersList", {defaultListItemType: "ps"}).reducer,
    paymentHistoryList: createListSlice("paymentHistoryList", {defaultListItemType: "ps"}).reducer
  });
}
