import {Box} from "@mui/material";
import {Typography} from "@mui/material";
import React from "react";
import {FallbackProps} from "react-error-boundary";
import {px} from "../../../base/plus/StringPlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {logError} from "../../../base/util/AppLog";
import {useWinSize} from "../../../nucleus/app/AppHooks";
import OopsImage from "../../../nucleus/atom/assets/oops.svg";
import LayoutFlexRow from "../../../nucleus/atom/layout/LayoutFlexRow";
import RawFadeLoader from "../../../nucleus/atom/raw/RawFadeLoader";
import UiTemplateAuth from "../template/UiTemplateAuth";

const IMAGE_HEIGHT_MOBILE = 250;

export default function UiAuthPageFallback(props: {
  title: string,
  message?: string,
  showSpinner?: boolean,
  image?: string
})
{
  const winSize = useWinSize();
  const gapStd = theme.common.gapStd;
  const winWidth = winSize.width;
  const isMobile = winWidth <= (1110);

  return (
    <UiTemplateAuth>
      <Box
        height={"100%"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
        whiteSpace={"wrap"}
        flexGrow={1}
        overflow={"auto"}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            style={{
              width: "100%",
              height: isMobile ? px(IMAGE_HEIGHT_MOBILE) : "auto"
            }}
            src={props.image ?? OopsImage}
            alt={"Fall back image"}
          />
          <MessageFallback
            message={props.title}
            isMainMessage={true}
            isMobile={isMobile}
          />
          {
            props.message &&
            <MessageFallback
              message={props.message}
              isMainMessage={false}
              isMobile={isMobile}
            />
          }
          {
            Boolean(props.showSpinner)
              ? (
                <LayoutFlexRow
                  pb={px(gapStd)}
                  pl={px(gapStd)}
                  pr={px(gapStd)}
                  pt={px(gapStd)}
                  width={"100%"}
                  flexGrow={1}
                >
                  <RawFadeLoader />
                </LayoutFlexRow>
              )
              : null
          }
        </Box>
      </Box>
    </UiTemplateAuth>
  );
}

export function ErrorFallback({error}: FallbackProps)
{
  logError("UiAuthPageFallback", error);

  return (
    <UiAuthPageFallback
      title={"Well, what can we say 🙏."}
      message={"Please refresh the page."}
      image={OopsImage}
    />
  );
}

function MessageFallback(props: {message: string, isMainMessage: boolean, isMobile: boolean})
{

  const isMobile = props.isMobile;
  const mainMessageStyle = {
    fontSize: isMobile ? px(20) : px(28),
    fontWeight: px(500),
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingBottom: px(gapHalf)
  };

  const subMessageStyle = {
    fontSize: isMobile ? px(14) : px(18),
    fontWeight: px(400),
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.disabled,
    component: "pre"
  };

  return (
    <Typography
      textAlign={"center"}
      whiteSpace={"wrap"}
      style={props.isMainMessage ? mainMessageStyle : subMessageStyle}
      pr={px(30)}
      pl={px(30)}
    >
      {props.message}
    </Typography>
  );
}
