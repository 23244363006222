import {useEffect} from "react";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {CbOnClickCalendarItem} from "../../../../base/types/TypeCalendar";
import {ICalendarBinderAll} from "../../../../base/types/TypeCalendar";
import {TypeCalendarItemId} from "../../../../base/types/TypeCalendar";
import {useAppSelector} from "../../../app/AppHooks";

export default function useCalendarPubSub<SR1, SR2, SR3, SR4, SR5, SR6>(
  props: {
    itemId: TypeCalendarItemId,
    selectCalendar: SelectCalendar
    calendarBinder?: ICalendarBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
    cbOnClick?: CbOnClickCalendarItem
    onItemSubscribe?: (itemId: TypeCalendarItemId) => void,
    onItemUnsubscribe?: (itemId: TypeCalendarItemId) => void,
  }
)
{
  const onItemSubscribe = props.onItemSubscribe;
  const onItemUnsubscribe = props.onItemUnsubscribe;
  const calendarBinder = props.calendarBinder;
  const selectCalendar = props.selectCalendar;
  const itemId = props.itemId;
  const calendarName = useAppSelector(state => selectCalendar(state).calendarName);

  const selectSourceItem1 = calendarBinder?.selectSourceItem1;
  const selectSourceItem2 = calendarBinder?.selectSourceItem2;
  const selectSourceItem3 = calendarBinder?.selectSourceItem3;
  const selectSourceItem4 = calendarBinder?.selectSourceItem4;
  const selectSourceItem5 = calendarBinder?.selectSourceItem5;
  const selectSourceItem6 = calendarBinder?.selectSourceItem6;
  const onBindSourceItem1 = calendarBinder?.onBindSourceItem1;
  const onBindSourceItem2 = calendarBinder?.onBindSourceItem2;
  const onBindSourceItem3 = calendarBinder?.onBindSourceItem3;
  const onBindSourceItem4 = calendarBinder?.onBindSourceItem4;
  const onBindSourceItem5 = calendarBinder?.onBindSourceItem5;
  const onBindSourceItem6 = calendarBinder?.onBindSourceItem6;

  const sourceItem1 = useAppSelector(state => (selectSourceItem1 && itemId)
    ? selectSourceItem1(state, itemId)
    : undefined);
  const sourceItem2 = useAppSelector(state => (selectSourceItem2 && itemId)
    ? selectSourceItem2(state, itemId)
    : undefined);
  const sourceItem3 = useAppSelector(state => (selectSourceItem3 && itemId)
    ? selectSourceItem3(state, itemId)
    : undefined);
  const sourceItem4 = useAppSelector(state => (selectSourceItem4 && itemId)
    ? selectSourceItem4(state, itemId)
    : undefined);
  const sourceItem5 = useAppSelector(state => (selectSourceItem5 && itemId)
    ? selectSourceItem5(state, itemId)
    : undefined);
  const sourceItem6 = useAppSelector(state => (selectSourceItem6 && itemId)
    ? selectSourceItem6(state, itemId)
    : undefined);

  useEffect(() =>
  {
    if(onBindSourceItem1 && itemId)
    {
      onBindSourceItem1(calendarName, itemId, sourceItem1);
    }
  }, [sourceItem1, onBindSourceItem1, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem2 && itemId)
    {
      onBindSourceItem2(calendarName, itemId, sourceItem2);
    }
  }, [sourceItem2, onBindSourceItem2, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem3 && itemId)
    {
      onBindSourceItem3(calendarName, itemId, sourceItem3);
    }
  }, [sourceItem3, onBindSourceItem3, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem4 && itemId)
    {
      onBindSourceItem4(calendarName, itemId, sourceItem4);
    }
  }, [sourceItem4, onBindSourceItem4, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem5 && itemId)
    {
      onBindSourceItem5(calendarName, itemId, sourceItem5);
    }
  }, [sourceItem5, onBindSourceItem5, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem6 && itemId)
    {
      onBindSourceItem6(calendarName, itemId, sourceItem6);
    }
  }, [sourceItem6, onBindSourceItem6, itemId]);

  useEffect(() =>
  {

    if(onItemSubscribe && itemId)
    {
      onItemSubscribe(itemId);
    }
    return () =>
    {
      (onItemUnsubscribe && itemId) && onItemUnsubscribe(itemId);
    };
  }, [onItemSubscribe, onItemUnsubscribe, itemId]);

  return null;
}
