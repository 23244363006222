import {StoreItem} from "../../api/meta/base/dto/StoreItem";
import ISrvc from "../../base/ISrvc";
import SrvcCacheStoreItem from "./storeItem/SrvcCacheStoreItem";

export type CbStoreItemEntGetSuccess = (storeItem: StoreItem) => void;

export default class SrvcCacheStore extends ISrvc
{
  public readonly storeItem = new SrvcCacheStoreItem();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.storeItem
    );
  }
}
