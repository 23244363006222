import {FieldValues} from "react-hook-form";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {StudioFieldError} from "../../../../../api/meta/base/dto/StudioFieldError";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyShowCloseButton} from "../../base/TypesFormBuilder";

export function getDefnFieldError(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{

  const constIds = [
    propKeyShowCloseButton
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "error",
      formId,
      "paragraph",
      fieldId,
      undefined,
      undefined,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyShowCloseButton]: {
      type: "bool",
      name: propKeyShowCloseButton,
      label: "Show close button",
      metaId: propKeyShowCloseButton,
      showAsCheckboxVar: true
    } as DefnFieldSwitch

  };
}

export function defnValueToStudioFieldError(values: FieldValues): StudioFieldError
{
  return {
    type: "error",
    showCloseButton: fnFieldValueToRawValue("bool", values[propKeyShowCloseButton])
  } as StudioFieldError;
}

export function studioFieldErrorToDefnValue(studioField: StudioFieldError)
{
  return {
    [propKeyShowCloseButton]: fnRawValueToFieldValue("bool", studioField.showCloseButton)
  };
}

