import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioSetOfAdminDoNotOption} from "../../../../api/meta/base/dto/DefnStudioSetOfAdminDoNotOption";
import {DefnAdminDoNotEditOptionEnt} from "../../../../api/meta/base/StudioSetsEnumType";
import {DefnAdminDoNotShowOptionEnt} from "../../../../api/meta/base/StudioSetsEnumType";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {getSortedOptionByLabel} from "../../../../base/plus/JsPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioSetOfAdminDoNotOption(props: {
  defn: DefnStudioSetOfAdminDoNotOption
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const adminPermissionType = defn.adminPermissionType;
  const optionSet = adminPermissionType === "doNotShow"
    ? DefnAdminDoNotShowOptionEnt
    : DefnAdminDoNotEditOptionEnt;

  const options = optionSet.map((item) =>
  {
    const parts = item.split(/(?=[A-Z])/);
    const newValue = parts[0] + (parts.length > 1 ? " > " + parts.slice(1).join(" ") : "");

    return {
      metaId: item,
      value: toLabel(newValue)
    };
  });

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={optionsToMapOfOption(getSortedOptionByLabel(options))}
        multiSelect={true}
        showChip={true}
      />}
    />
  );
}
