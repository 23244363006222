import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldChipSetDate} from "../../../api/meta/base/dto/DefnFieldChipSetDate";
import {StudioVarSetOfDate} from "../../../api/meta/base/dto/StudioVarSetOfDate";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";

export function fnVariableBuilderSetOfDate(fieldValueSetOfDate: string)
{
  function getVarComp()
  {
    return {
      [fieldValueSetOfDate]: {
        type: "chipSetDate",
        metaId: fieldValueSetOfDate,
        name: "Value",
        required: true,
        multiSelect: true
      } as DefnFieldChipSetDate
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarSetOfDate
  )
  {
    valueMap[fieldValueSetOfDate] = fnRawValueToFieldValue("chipSetDate", variable.value?.valueSet);
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = fnFieldValueToRawValue("chipSetDate", valueMap[fieldValueSetOfDate]);

    return {
      value: value
        ? {
          valueSet: value
        }
        : undefined
    } as StudioVarSetOfDate;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
