import {combineReducers} from "@reduxjs/toolkit";
import {sliceAuth} from "../../base/slices/SliceAuth";
import {reducersAppDebug} from "./debug/SliceDebug";
import {reducersAppForm} from "./form/StoreAppForm";
import {reducersAppSpreadsheet} from "./spreadsheet/StoreAppSpreadsheet";
import {sliceStatus} from "./status/SliceStatus";
import {sliceToast} from "./toast/SliceToast";
import {sliceWidget} from "./widget/SliceWidget";

export function reducersApp()
{
  return combineReducers({
    auth: sliceAuth.reducer,
    toast: sliceToast.reducer,
    status: sliceStatus.reducer,
    debug: reducersAppDebug(),
    form: reducersAppForm(),
    spreadsheet: reducersAppSpreadsheet(),
    widget: sliceWidget.reducer
  });
}
