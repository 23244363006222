import {RootState} from "../../../Store";
import SrvcChat from "../../app/chat/SrvcChat";

export default class SrvcHomeMainChat extends SrvcChat
{
  constructor()
  {
    super((state: RootState) => state.home.main.chat);
  }
}
