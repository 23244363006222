import {AxiosRequestConfig} from "axios";
import axios from "axios";
import {nextRequestId} from "../../api/meta/base/ApiPlus";
import {IMsg} from "../../api/meta/base/msg/IMsg";
import {ISig} from "../../api/meta/base/sig/ISig";
import {ServiceName} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {EnvError} from "../../api/nucleus/base/dto/EnvError";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {IRpcCall} from "../../api/nucleus/base/IRpcCall";
import {ISigAcceptor} from "../../api/nucleus/base/ISigAcceptor";
import {EnumEnvErrorCode} from "../../api/nucleus/base/Types";
import ISrvcAuth from "../ISrvcAuth";
import LocalCookie from "../local/LocalCookie";
import {hostPortProvider} from "../plus/HostPortPlus";
import {getHttpProtocol} from "../plus/SysPlus";
import {LOG_SEND_COLOR} from "../util/AppLog";
import {LOG_RECV_COLOR} from "../util/AppLog";
import {LOG_WTF} from "../util/AppLog";
import {logError} from "../util/AppLog";
import {LOG_RECV} from "../util/AppLog";
import {isInfoLog} from "../util/AppLog";
import {logInfo} from "../util/AppLog";
import {LOG_SEND} from "../util/AppLog";

const MAX_CONTENT_LENGTH = 5 * 1024 * 1024;
const HEADER_AUTHORIZATION = "Authorization";
const HEADER_REQUEST_ID = "X-Request-Id";
const HEADER_REFRESH_TOKEN = "X-Auth-Token"; // this is used only during development
const HEADER_LANGUAGE_KEY = "X-Language-Key";

const RPC_INSTANCE = axios.create({
  timeout: 20000,

  headers: {
    "accept": "application/json",
    "content-type": "application/json;charset=utf-8"
  },

  maxContentLength: MAX_CONTENT_LENGTH,
  maxBodyLength: MAX_CONTENT_LENGTH
});

export default abstract class RpcCall<S extends ISig> implements IRpcCall<S>
{
  private readonly requestId = nextRequestId();
  private _sendBearerToken = false;
  private _sendRefreshToken = false;

  protected constructor(
    public readonly srvcAuth: ISrvcAuth,
    public readonly entId: EntId,
    public readonly serviceName: ServiceName,
    public readonly apiName: string
  )
  {
  }

  isSendBearerToken(): boolean
  {
    return this._sendBearerToken;
  }

  sendBearerToken(): IRpcCall<S>
  {
    this._sendBearerToken = true;
    this._sendRefreshToken = false;
    return this;
  }

  sendRefreshToken(): IRpcCall<S>
  {
    this._sendBearerToken = false;
    this._sendRefreshToken = true;
    return this;
  }

  get(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>
  {
    return this.execute({
      method: "get",
      params: msg
    } as AxiosRequestConfig, sigAcceptor);
  }

  post(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>
  {
    return this.execute({
      method: "post",
      data: msg
    } as AxiosRequestConfig, sigAcceptor);
  }

  patch(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>
  {
    return this.execute({
      method: "patch",
      data: msg
    } as AxiosRequestConfig, sigAcceptor);
  }

  put(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>
  {
    return this.execute({
      method: "put",
      data: msg
    } as AxiosRequestConfig, sigAcceptor);
  }

  delete(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>
  {
    return this.execute({
      method: "delete",
      data: msg
    } as AxiosRequestConfig, sigAcceptor);
  }

  execute(config: AxiosRequestConfig, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>
  {
    config.headers = {};
    config.headers[HEADER_REQUEST_ID] = this.requestId;

    if(this._sendBearerToken)
    {
      const bearerToken = this.srvcAuth.getBearerToken();
      if(bearerToken === undefined)
      {
        const envSig = this.createErrorSignal<S>("unauthorizedBearerToken");
        sigAcceptor(envSig);
        return this;
      }

      config.headers[HEADER_AUTHORIZATION] = bearerToken;
    }

    if(this._sendRefreshToken)
    {
      const refreshTokenCookie = LocalCookie.get();
      if(refreshTokenCookie && refreshTokenCookie.length > 0)
      {
        config.headers[HEADER_REFRESH_TOKEN] = refreshTokenCookie as string;
      }
    }

    const hostPort = hostPortProvider.getHostPort();
    config.baseURL = `${getHttpProtocol()}://${hostPort}`;

    config.url = `${(this.entId)}/rpc/v1/${this.serviceName}/${this.apiName}`;

    const method = config.method;
    if(isInfoLog())
    {
      if(config.data)
      {
        logInfo("RpcCall",
          `${LOG_SEND}, ${method} url = ${config.url}, msg = ${JSON.stringify(config.data)}`,
          LOG_SEND_COLOR
        );
      }
      else
      {
        logInfo("RpcCall", `${LOG_SEND}, ${method} url = ${config.url}`, LOG_SEND_COLOR);
      }
    }

    RPC_INSTANCE(config)
    .then(response =>
    {
      let envSig = response.data as EnvSignal<S>;
      if(envSig === undefined)
      {
        envSig = this.createErrorSignal("networkError");
      }

      const refreshToken = envSig.cookieValue;
      const requireToStoreCookieInLocal = this.srvcAuth.getRequireToStoreCookieInLocal();
      if(refreshToken !== undefined && requireToStoreCookieInLocal)
      {
        LocalCookie.set(refreshToken, Boolean(envSig.cookieRememberMe));
      }

      if(isInfoLog())
      {
        if(envSig.error)
        {
          logError("RpcCall",
            `${LOG_RECV} ${method}, url = ${config.url}, error = ${JSON.stringify(envSig.error)}`
          );
        }
        else if(envSig.serviceName)
        {
          if(envSig.sig)
          {
            logInfo("RpcCall",
              `${LOG_RECV} ${method}, url = ${config.url}, sig = ${JSON.stringify(envSig.sig)}`,
              LOG_RECV_COLOR
            );
          }
          else
          {
            logInfo("RpcCall", `${LOG_RECV} ${method}, url = ${config.url}`);
          }
        }
        else
        {
          logInfo("RpcCall",
            `${LOG_RECV} ${method}, url = ${config.url}, envSig = ${JSON.stringify(envSig)}`,
            LOG_RECV_COLOR
          );
        }
      }

      sigAcceptor(envSig);
    })
    .catch(error =>
    {
      logError("RpcCall", error);
      if(error.response && error.response.data)
      {
        let envSig = error.response.data as EnvSignal<S>;
        logError("RpcCall",
          `${LOG_WTF} ${method}, url = ${config.url}, envSig = ${JSON.stringify(envSig)}`
        );
        sigAcceptor(envSig);
      }
      else
      {
        const envSig = this.createErrorSignal<S>("networkError");
        if(isInfoLog())
        {
          logError("RpcCall",
            `${LOG_WTF} ${method}, url = ${config.url}, error = ${JSON.stringify(error)}`
          );
        }
        sigAcceptor(envSig);
      }
    });

    return this;
  }

  private createErrorSignal<S extends ISig>(errorCode: EnumEnvErrorCode): EnvSignal<S>
  {
    return {
      requestId: this.requestId,
      error: {errorCode: errorCode} as EnvError
    } as EnvSignal<S>;
  }
}
