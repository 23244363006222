import {FormControl} from "@mui/material";
import {IconButton} from "@mui/material";
import {Box} from "@mui/material";
import parse from "html-react-parser";
import {isEmpty} from "lodash";
import {useState} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldHtml} from "../../../../api/meta/base/dto/DefnFieldHtml";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import IconStrip from "../../../atom/icon/IconStrip";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import {useFormCtx} from "../base/CtxForm";
import FieldBase from "../raw/FieldBase";

export default function FieldHtml(props: {
  defn: DefnFieldHtml;
})
{
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const padding = getDefnFieldPadding(defn);

  return (
    <Controller
      name={fieldId}
      render={({
        field,
        fieldState
      }) =>
      {
        const fieldValue = field.value as FieldValueText;
        if(isEmpty(fieldValue?.value))
        {
          return <></>;
        }
        const {
          error
        } = fieldState;
        const isError = Boolean(error);
        return (
          <FieldBase
            fieldId={fieldId}
            {...padding}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <RealHTML
                defn={defn}
                fieldValue={fieldValue}
              />
            </FormControl>
          </FieldBase>
        );
      }}
    />
  );
}

function RealHTML(props: {
  defn: DefnFieldHtml,
  fieldValue: FieldValueText
})
{
  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const showCloseButton = defn.showCloseButton;
  const [closeHTML, setCloseHTML] = useState(false);

  return (
    <LayoutFlexCol
      displayNone={closeHTML}
      flexGrow={1}
      justifyContent={"start"}
      alignItems={"start"}
    >
      {
        fieldValue && parse(fieldValue.value)
      }
      {
        showCloseButton &&
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            pt: px(gapQuarter),
            pr: px(gapHalf)
          }}
        >
          <IconButton
            sx={{
              width: "24px",
              height: "24px",
              color: theme.common.fgcolorIcon
            }}
            onClick={() =>
            {
              setCloseHTML(true);
            }}
          >
            <IconStrip value={"close"} />
          </IconButton>
        </Box>
      }
    </LayoutFlexCol>
  );
}
