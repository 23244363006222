import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdLayoutFormContent} from "../../api/meta/base/ApiPlus";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {StudioDtoLayoutFormContent} from "../../api/meta/base/dto/StudioDtoLayoutFormContent";
import {EnumDefnFormLayoutType} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export default function DialogNewFormContentLayout(props: {
  onClickOk: (value: StudioDtoLayoutFormContent) => void,
  values?: StudioDtoLayoutFormContent,
  onClose?: () => void,
})
{

  const onClickSubmit = props.onClickOk;
  const value = props.values;

  return (
    <DialogDefnForm
      title={`${value ? "Update" : "New"} form content layout`}
      formProps={{
        defnForm: getDefnForm(),
        initValues: dtoToValue(value),
        onSubmit: values => onClickSubmit(valueToDto(values))
      }}
      addMoreCheckBoxLabel={!value
        ? "Add more form content layouts"
        : undefined}
      onClose={props.onClose}
      contentHeight={280}
    />
  );
}

function valueToDto(values: FieldValues)
{
  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    metaId: nextMetaIdLayoutFormContent(),
    type: fnFieldValueToRawValue("pickText", values[fieldContentLayoutType] as EnumDefnFormLayoutType),
    direction: "vertical",
    contentPadding: "thick",
    start: {
      padding: "thick"
    },
    flexCenter: {
      padding: "thick"
    },
    end: {
      padding: "thick"
    }
  } as StudioDtoLayoutFormContent;
}

function dtoToValue(dto?: StudioDtoLayoutFormContent)
{
  if(dto)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", dto?.name)
    };
  }
}

const fieldContentLayoutType = "contentLayoutType";

function getDefnForm()
{
  return createDefaultDefnFormStudio({
    [fieldKeyName]: {
      type: "symbol",
      name: "Name",
      label: "Name",
      metaId: fieldKeyName,
      required: true
    } as DefnFieldText,

    [fieldContentLayoutType]: {
      type: "pickText",
      metaId: fieldContentLayoutType,
      name: "Layout type",
      label: "Layout type",
      required: true,
      optionMap: arrayToMapOfOption(["editor", "content", "template"] as EnumDefnFormLayoutType[])
    } as DefnFieldPickText,

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: [fieldKeyName, fieldContentLayoutType]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}
