import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigGroupIdList} from "../../../api/home/aside/sig/SigGroupIdList";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {SigUserLastOnline} from "../../../api/home/main/sig/SigUserLastOnline";
import {EntUserId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {TIME_GAP_TYPING} from "../../../base/plus/ConstantsPlus";
import {isDateWithinRange} from "../../../base/plus/DatePlus";
import {formatDetailedDateTime} from "../../../base/plus/DatePlus";
import {formatCaptionDateTime} from "../../../base/plus/DatePlus";
import {random} from "../../../base/plus/StringPlus";
import {toComboId} from "../../../base/types/TypesComboId";
import {ICacheUserState} from "./TypesCacheUser";

export const sliceCacheUser = createSlice({
  name: "cacheUser",
  initialState: {
    userAvatarMap: {},
    userCommonGroupListMap: {},
    userLastOnlineMap: {},
    userTyping: {},
    userTypingText: {},
    usersTypingCount: 0,
    fcmToken: undefined
  } as ICacheUserState,
  reducers: {
    setUserAvatar: (state, action: PayloadAction<SigUserAvatar>) =>
    {
      const sig = action.payload;
      const entUserId = toComboId(sig.entId, sig.entUserId);
      state.userAvatarMap[entUserId] = sig;
    },
    removeUserAvatar: (state, action: PayloadAction<{entId: EntId, userId: EntUserId}>) =>
    {
      const payload = action.payload;
      const entUserId = toComboId(payload.entId, payload.userId);
      delete state.userAvatarMap[entUserId];
    },
    setUserCommonGroups: (state, action: PayloadAction<{entId: EntId, entUserId: EntUserId, sig: SigGroupIdList}>) =>
    {
      const payload = action.payload;
      const entUserId = toComboId(payload.entId, payload.entUserId);
      state.userCommonGroupListMap[entUserId] = {
        userCommonGroupList: payload.sig,
        version: random(16)
      };
    },
    clearUserCommonGroups: (state, action: PayloadAction<{entId: EntId, entUserId: EntUserId}>) =>
    {
      const payload = action.payload;
      const entUserId = toComboId(payload.entId, payload.entUserId);
      delete state.userCommonGroupListMap[entUserId];
    },
    setUserLastOnline: (state, action: PayloadAction<SigUserLastOnline>) =>
    {
      const sig = action.payload;
      state.userLastOnlineMap[toComboId(sig.entId, sig.entUserId)] = Boolean(sig.online)
        ? "online"
        : sig.lastOnline ? ("last seen " + formatCaptionDateTime(new Date(sig.lastOnline), true)) :
          "click here for user info";
    },
    removeUserLastOnline: (state, action: PayloadAction<EntUserId>) =>
    {
      delete state.userLastOnlineMap[action.payload];
    },
    setUserTyping: (state, action: PayloadAction<{entId: EntId, userId: EntUserId}>) =>
    {
      const payload = action.payload;
      const entUserId = toComboId(payload.entId, payload.userId);
      state.userTyping[entUserId] = formatDetailedDateTime(new Date());
      state.usersTypingCount = Object.keys(state.userTyping).length;
    },
    removeUserTyping: (state, action: PayloadAction<{entId: EntId, userId: EntUserId}>) =>
    {
      const payload = action.payload;
      const entUserId = toComboId(payload.entId, payload.userId);
      delete state.userTyping[entUserId];
      delete state.userTypingText[entUserId];
      state.usersTypingCount = Object.keys(state.userTyping).length;
    },
    calcUsersTypingText: (state) =>
    {
      const userTypingText = state.userTypingText;
      const userTyping = state.userTyping;
      const now = new Date();

      Object.keys(userTyping).forEach(entUserId =>
      {
        if(isDateWithinRange(now, userTyping[entUserId], TIME_GAP_TYPING))
        {
          userTypingText[entUserId] = "typing...";
        }
        else
        {
          delete state.userTyping[entUserId];
          delete state.userTypingText[entUserId];
        }
      });

      state.usersTypingCount = Object.keys(state.userTyping).length;
    },
    setFcmToken: (state, action: PayloadAction<string>) =>
    {
      state.fcmToken = action.payload;
    },
    calcUserLastOnline: (state) =>
    {
      state.userLastOnlineMap = {};
    }
  }
});

export const {
  setUserAvatar,
  removeUserAvatar,
  setUserCommonGroups,
  clearUserCommonGroups,
  setUserLastOnline,
  removeUserLastOnline,
  setUserTyping,
  removeUserTyping,
  calcUsersTypingText,
  setFcmToken,
  calcUserLastOnline
} = sliceCacheUser.actions;
