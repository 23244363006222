import {AxiosRequestConfig} from "axios";
import {ISig} from "../../api/meta/base/sig/ISig";
import {EntId} from "../../api/meta/base/Types";
import {ServiceName} from "../../api/meta/base/Types";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {IRpcCall} from "../../api/nucleus/base/IRpcCall";
import {ISigAcceptor} from "../../api/nucleus/base/ISigAcceptor";
import ISrvcAuth from "../ISrvcAuth";
import RpcCall from "./RpcCall";

export default class RpcCallWithRetry<S extends ISig> extends RpcCall<S>
{
  constructor(
    public readonly srvcAuth: ISrvcAuth,
    public readonly entId: EntId,
    public readonly serviceName: ServiceName,
    public readonly apiName: string
  )
  {
    super(srvcAuth, entId, serviceName, apiName);
  }

  execute(config: AxiosRequestConfig, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>
  {
    const self = this;
    return super.execute(config, envSig =>
    {
      const envError = envSig.error;
      if(envError)
      {
        const errorCode = envError.errorCode;
        if(errorCode === "unauthorizedRefreshToken")
        {
          self.srvcAuth.rpcSignOut();
        }
        else if(errorCode === "unauthorizedBearerToken")
        {
          // do not dispatch flag bearer token since we are retrying the message
          //store.dispatch(setFlagBearerToken(false));

          if(this.isSendBearerToken())
          {
            // does not throw UnauthorizedBearerToken
            self.srvcAuth.rpcGrantBearerToken(true, rpcEnvSig =>
            {
              if(rpcEnvSig.error)
              {
                if(sigAcceptor)
                {
                  sigAcceptor(errorSig<S>(rpcEnvSig));
                }
              }
              else
              {
                self.execute(config, sigAcceptor);
              }
            });

            return;
          }
        }
      }

      if(sigAcceptor)
      {
        sigAcceptor(envSig);
      }
    });
  }
}

function errorSig<T extends ISig>(envSig: EnvSignal<ISig>): EnvSignal<T>
{
  envSig.sig = undefined;
  return envSig as EnvSignal<T>;
}
