import {useTheme} from "@mui/material";
import {Stack} from "@mui/material";
import {Box} from "@mui/material";
import {Property} from "csstype";
import React from "react";
import {EnumDefnPlacement} from "../../../api/meta/base/Types";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";

export default function RawKeyValuePair(props: {
  left: React.ReactNode,
  right: React.ReactNode,
  leftHeight?: number,
  leftMaxWidth?: Property.Width,
  rightMaxWidth?: Property.Width,
  bgcolor?: CssBackgroundColor,
  justifyContent?: EnumDefnPlacement,
  mr?: number,
  height?: Property.Height,
  rightOverflowY?: Property.OverflowY,
  rightOverflowX?: Property.OverflowX,
  flexGrow?: number,
  disableFlexBasis?: boolean
})
{
  const {
    left,
    right,
    leftHeight,
    bgcolor,
    justifyContent,
    leftMaxWidth,
    rightMaxWidth,
    mr,
    height,
    rightOverflowY,
    rightOverflowX,
    flexGrow,
    disableFlexBasis
  } = props;
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      width={"100%"}
      height={flexGrow ? "100%" : height}
      bgcolor={bgcolor}
      justifyContent={justifyContent ?? "space-between"}
      flexGrow={flexGrow}
    >

      <Box
        sx={{
          height: leftHeight,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "start",
          mr: px(mr ?? theme.common.gapQuarter),
          flexBasis: disableFlexBasis ? undefined : `min(50%, ${leftMaxWidth})`,
          maxWidth: `min(50%, ${leftMaxWidth})`
        }}
      >
        {left}
      </Box>
      <LayoutFlexCol
        maxWidth={rightMaxWidth}
        flexGrow={1}
        alignItems={"flex-start"}
        overflowX={rightOverflowX}
        overflowY={rightOverflowY}
        justifyContent={"flex-start"}
        width={0}
      >
        {right}
      </LayoutFlexCol>
    </Stack>
  );
}

