import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigCaller} from "../../../api/core/user/sig/SigCaller";
import {SigGroupInfo} from "../../../api/home/aside/sig/SigGroupInfo";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigTopicGroupTyping} from "../../../api/home/session/sig/SigTopicGroupTyping";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {EntId, GroupId} from "../../../api/meta/base/Types";
import {STR_YOU} from "../../../base/plus/ConstantsPlus";
import {TIME_GAP_TYPING} from "../../../base/plus/ConstantsPlus";
import {isDateWithinRange} from "../../../base/plus/DatePlus";
import {formatDetailedDateTime} from "../../../base/plus/DatePlus";
import {hasDelta} from "../../../base/plus/JsPlus";
import {calcDeltaArray} from "../../../base/plus/JsPlus";
import {TypeComboIdGroup} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {ICacheGroupState} from "./TypesCacheGroup";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export const sliceCacheGroup = createSlice({
  name: "cacheGroup",
  initialState: {
    groupTypingTextShort: {},
    groupTypingTextLong: {},
    groupTyping: {},
    groupsTypingCount: 0,
    groupAvatarMap: {},
    groupInfo: {},
    callerComboGroupIdSet: [],
    mutationCount: 0
  } as ICacheGroupState,
  reducers: {
    clearGroupCallerDelta: (state) =>
    {
      state.callerDelta = undefined;
    },
    setGroupCaller: (state, action: PayloadAction<SigCaller>) =>
    {
      const newCaller = action.payload;
      if(state.callerVersion === newCaller.version)
      {
        return;
      }

      const newCallerComboGroupIdSet = [] as TypeComboIdGroup[];
      newCaller.groupIdSet.forEach(groupId =>
      {
        const entGroupId = toComboId(ENT_ID_GLOBAL, groupId);
        newCallerComboGroupIdSet.push(entGroupId);
      });

      const callerDelta = calcDeltaArray(state.callerComboGroupIdSet, newCallerComboGroupIdSet);
      if(hasDelta(callerDelta))
      {
        state.callerComboGroupIdSet = newCallerComboGroupIdSet;
      }

      state.callerVersion = newCaller.version;
      state.callerDelta = callerDelta;
    },
    setGroupAvatar: (state, action: PayloadAction<SigGroupAvatar>) =>
    {
      const sig = action.payload;
      const entGroupId = toComboId(sig.entId, sig.groupId);
      state.groupAvatarMap[entGroupId] = sig;
      state.mutationCount = state.mutationCount + 1;
    },
    removeGroupAvatar: (state, action: PayloadAction<{entId: EntId, groupId: GroupId}>) =>
    {
      const payload = action.payload;
      const entGroupId = toComboId(payload.entId, payload.groupId);
      delete state.groupAvatarMap[entGroupId];
      state.mutationCount = state.mutationCount + 1;
    },
    setGroupTyping: (state, action: PayloadAction<SigTopicGroupTyping>) =>
    {
      const sig = action.payload;
      const entGroupId = toComboId(sig.artifactId, sig.aboutId as GroupId);

      let typingRecord = state.groupTyping[entGroupId];
      if(typingRecord === undefined)
      {
        typingRecord = {};
        state.groupTyping[entGroupId] = typingRecord;
      }

      if(sig.targetUserText !== STR_YOU)
      {
        typingRecord[sig.targetUserText] = formatDetailedDateTime(new Date());
        state.groupsTypingCount = Object.keys(state.groupTyping).length;
      }

    },
    removeGroupTyping: (state, action: PayloadAction<{entId: EntId, groupId: GroupId}>) =>
    {
      const payload = action.payload;
      const entGroupId = toComboId(payload.entId, payload.groupId);
      delete state.groupTyping[entGroupId];
      delete state.groupTypingTextShort[entGroupId];
      delete state.groupTypingTextLong[entGroupId];
      state.groupsTypingCount = Object.keys(state.groupTyping).length;
    },
    calcGroupsTypingText: (state) =>
    {
      const groupTypingTextShort = state.groupTypingTextShort;
      const groupTypingTextLong = state.groupTypingTextLong;
      const groupTyping = state.groupTyping;
      const now = new Date();

      Object.keys(groupTyping).forEach(entGroupId =>
      {
        const typingRecord = groupTyping[entGroupId];
        let typingStr = "";
        let typingCount = 0;
        Object.keys(typingRecord).forEach(targetUserText =>
        {
          if(isDateWithinRange(now, typingRecord[targetUserText], TIME_GAP_TYPING))
          {
            if(typingCount === 0)
            {
              typingStr = targetUserText;
            }
            else
            {
              typingStr = typingStr + ", " + targetUserText;
            }

            typingCount++;
          }
          else
          {
            delete typingRecord[targetUserText];
          }
        });

        if(typingCount === 0)
        {
          delete groupTyping[entGroupId];
          delete groupTypingTextShort[entGroupId];
          delete groupTypingTextLong[entGroupId];
        }
        else
        {
          let typingStrShort;
          let typingStrLong;

          if(typingCount === 1 && typingStr !== STR_YOU)
          {
            typingStrShort = typingStr + " is typing...";
            typingStrLong = typingStrShort;
          }
          else if(typingCount === 2)
          {
            typingStrShort = typingStr + " are typing...";
            typingStrLong = typingStrShort;
          }
          else
          {
            typingStrShort = typingCount + " people are typing...";
            typingStrLong = typingStrShort;
          }

          if(groupTypingTextShort[entGroupId] !== typingStrShort)
          {
            groupTypingTextShort[entGroupId] = typingStrShort;
          }

          if(groupTypingTextLong[entGroupId] !== typingStrLong)
          {
            groupTypingTextLong[entGroupId] = typingStrLong;
          }
        }
      });

      state.groupsTypingCount = Object.keys(state.groupTyping).length;
    },
    setGroupInfo: (state, action: PayloadAction<{entId: EntId, groupId: GroupId, groupInfo: SigGroupInfo}>) =>
    {
      const sig = action.payload;
      if(sig.groupInfo)
      {
        const entGroupId = toComboId(sig.entId, sig.groupId as GroupId);
        state.groupInfo[entGroupId] = sig.groupInfo;
      }
    },
    clearGroupInfo: (state, action: PayloadAction<{entId: EntId, groupId: GroupId}>) =>
    {
      const payload = action.payload;
      const entUserId = toComboId(payload.entId, payload.groupId);
      delete state.groupInfo[entUserId];
    }
  }
});

export const {
  clearGroupCallerDelta,
  setGroupCaller,
  setGroupAvatar,
  setGroupInfo,
  clearGroupInfo,
  removeGroupAvatar,
  setGroupTyping,
  removeGroupTyping,
  calcGroupsTypingText
} = sliceCacheGroup.actions;

