import ISrvc from "../../../base/ISrvc";
import SrvcHomeAsideGroupInfo from "./groupInfo/SrvcHomeAsideGroupInfo";
import SrvcHomeMedia from "./media/SrvcHomeMedia";
import SrvcHomeAsideChat from "./SrvcHomeAsideChat";
import SrvcHomeAsideFormInfo from "./SrvcHomeAsideFormInfo";
import {SrvcHomeAsideMessageInfo} from "./SrvcHomeAsideMessageInfo";
import SrvcHomeAsideSearch from "./SrvcHomeAsideSearch";
import SrvcHomeAsideShare from "./SrvcHomeAsideShare";
import SrvcHomeAsideUserInfo from "./userInfo/SrvcHomeAsideUserInfo";

export default class SrvcHomeAside extends ISrvc
{
  public readonly chat = new SrvcHomeAsideChat();
  public readonly groupInfo = new SrvcHomeAsideGroupInfo();
  public readonly userInfo = new SrvcHomeAsideUserInfo();
  public readonly media = new SrvcHomeMedia();
  public readonly messageInfo = new SrvcHomeAsideMessageInfo();
  public readonly search = new SrvcHomeAsideSearch();
  public readonly formInfo = new SrvcHomeAsideFormInfo();
  public readonly messageShare = new SrvcHomeAsideShare();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.chat,
      this.groupInfo,
      this.userInfo,
      this.messageInfo,
      this.search,
      this.formInfo,
      this.messageShare
    );
  }
}
