import {TypeUserField} from "./types/TypesGlobal";

export interface IDebugServiceReport
{
  serviceReportDate: string,
  serviceMap: Record<string, Record<string, TypeUserField>>; // serviceName -> key -> optionId
}

export default abstract class ISrvc
{
  protected srvcArray?: ISrvc[];

  isSrvcChat(): boolean
  {
    return false;
  }

  protected doInit()
  {
    this.srvcArray?.forEach(srvc => srvc.doInit());
  }

  protected doSignOut()
  {
    this.srvcArray?.forEach(srvc => srvc.doSignOut());
  }

  protected doTick(dateNow: Date)
  {
    this.srvcArray?.forEach(srvc => srvc.doTick(dateNow));
  }

  protected onWsocAuth()
  {
    this.srvcArray?.forEach(srvc => srvc.onWsocAuth());
  }

  protected onWsocClose()
  {
    this.srvcArray?.forEach(srvc => srvc.onWsocClose());
  }

  protected doDebug(report: IDebugServiceReport): void
  {
    this.srvcArray?.forEach(srvc => srvc.doDebug(report));
  }

  protected collectSrvcsChat(srvcArray: ISrvc[]): void
  {
    if(this.isSrvcChat())
    {
      srvcArray.push(this);
    }

    this.srvcArray?.forEach(srvc => srvc.collectSrvcsChat(srvcArray));
  }

  protected setSrvcArray(...srvcArray: ISrvc[])
  {
    this.srvcArray = srvcArray;
  }
}
