import {FieldValues} from "react-hook-form";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldHandle} from "../../../../../api/meta/base/dto/StudioFieldHandle";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyValidDomain} from "../../base/TypesFormBuilder";
import {propKeyInvalidDomain} from "../../base/TypesFormBuilder";
import {propKeyAutoPickSelf} from "../../base/TypesFormBuilder";
import {propKeyValidMobileCountryCodes} from "../../base/TypesFormBuilder";
import {propKeyInvalidMobileCountryCodes} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldHandle(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyValidDomain,
    propKeyInvalidDomain,
    propKeyValidMobileCountryCodes,
    propKeyInvalidMobileCountryCodes
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    propKeyAutoPickSelf,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "handle",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDefaultValueVar]: {
      type: "pickVarId",
      metaId: propKeyDefaultValueVar,
      name: "Default value",
      varKindSet: ["mobileNumber", "email"],
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyAutoPickSelf]: {
      type: "bool",
      metaId: propKeyAutoPickSelf,
      name: propKeyAutoPickSelf,
      label: "Auto pick self",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyValidDomain]: {
      type: "pickVarId",
      name: propKeyValidDomain,
      metaId: propKeyValidDomain,
      label: "Valid domains",
      varKind: "setOfText",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyInvalidDomain]: {
      type: "pickVarId",
      name: propKeyInvalidDomain,
      metaId: propKeyInvalidDomain,
      label: "Invalid domains",
      varKind: "setOfText",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyValidMobileCountryCodes]: {
      type: "pickVarId",
      name: propKeyValidMobileCountryCodes,
      label: "Valid mobile country codes",
      metaId: propKeyValidMobileCountryCodes,
      varKind: "setOfText",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyInvalidMobileCountryCodes]: {
      type: "pickVarId",
      metaId: propKeyInvalidMobileCountryCodes,
      name: propKeyInvalidMobileCountryCodes,
      label: "Invalid mobile country codes",
      varKind: "setOfText",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId
  };
}

export function defnValueToStudioFieldHandle(values: FieldValues): StudioFieldHandle
{
  return {
    type: "handle",
    defaultValue: fnFieldValueToRawValue("handle", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    invalidMobileCountryCodeSetVarId: fnFieldValueToRawValue("pickVarId", values[propKeyInvalidMobileCountryCodes]),
    validMobileCountryCodeSetVarId: fnFieldValueToRawValue("pickVarId", values[propKeyValidMobileCountryCodes]),
    autoPickSelf: fnFieldValueToRawValue("bool", values[propKeyAutoPickSelf]),
    invalidDomainVarId: fnFieldValueToRawValue("pickVarId", values[propKeyInvalidDomain]),
    validDomainVarId: fnFieldValueToRawValue("pickVarId", values[propKeyValidDomain])
  } as StudioFieldHandle;
}

export function studioFieldHandleToDefnValue(studioField: StudioFieldHandle)
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("handle", studioField.defaultValue),
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyInvalidMobileCountryCodes]: fnRawValueToFieldValue("pickVarId",
      studioField.invalidMobileCountryCodeSetVarId
    ),
    [propKeyValidMobileCountryCodes]: fnRawValueToFieldValue("pickVarId", studioField.validMobileCountryCodeSetVarId),
    [propKeyAutoPickSelf]: fnRawValueToFieldValue("bool", studioField.autoPickSelf),
    [propKeyInvalidDomain]: fnRawValueToFieldValue("pickVarId", studioField.invalidDomainVarId),
    [propKeyValidDomain]: fnRawValueToFieldValue("pickVarId", studioField.validDomainVarId)
  };
}
