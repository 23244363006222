import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useMemo} from "react";
import {useEffect} from "react";
import React from "react";
import {getListItemHeightAPSA} from "../../base/plus/ListPlus";
import {px} from "../../base/plus/StringPlus";
import {DividerHorizontal} from "../atom/layout/DividerHorizontal";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import {IPaneOverlayStackRef} from "../atom/pane/PaneOverlayStack";
import PaneOverlayStack from "../atom/pane/PaneOverlayStack";
import {useAppCtx} from "../ctx/CtxApp";

export function AppMain(props: {
  mainHeader?: React.ReactNode,
  mainContent: React.ReactNode,
  mainFooter?: React.ReactNode,
  mainWidth: number,
  expand: boolean
})
{
  const theme = useTheme();
  const appCtx = useAppCtx();
  const mainProps = appCtx.getMainProps();
  const overlay = appCtx.getMainOverlay();
  const heightHeader = getListItemHeightAPSA("aps");
  const mainWidth = props.mainWidth;
  const expand = props.expand;
  const cbOverlayRef = useMemo(() => ({} as IPaneOverlayStackRef), []);

  const showDivider = (!expand && mainProps);

  useEffect(() =>
  {
    if(overlay)
    {
      cbOverlayRef.showOverlay(overlay);
    }
    else
    {
      cbOverlayRef.closeOverlay();
    }
  }, [overlay, cbOverlayRef]);

  return (
    <LayoutFlexCol
      width={px(mainWidth)}
      displayNone={mainWidth === 0}
    >
      <PaneOverlayStack
        width={mainWidth}
        cbRef={cbOverlayRef}
      >
        {
          !expand && props.mainHeader &&
          <Box
            sx={{
              width: "100%",
              overflow: "hidden",
              height: px(heightHeader),
              flexShrink: 0
            }}
          >
            {props.mainHeader}
          </Box>
        }

        {
          showDivider && props.mainHeader &&
          <DividerHorizontal />
        }

        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: theme.common.bgcolorContent
          }}
        >
          {props.mainContent}
        </Box>

        {
          showDivider && props.mainFooter &&
          <DividerHorizontal />
        }

        {
          !expand &&
          props.mainFooter
        }
      </PaneOverlayStack>
    </LayoutFlexCol>
  );
}
