import {useState} from "react";
import {useCallback} from "react";
import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {MetaIdField} from "../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../base/plus/ArgBinderPlus";
import {MAX_CHARACTER_COUNT_SYMBOL} from "../../base/plus/ConstantsPlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {toSymbolCase} from "../../base/plus/StringPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {VAR_SET_OF_TEXT_KEY} from "../atom/assets/HelperTextStudio";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const compVariableTextSetTextKey = "variableTextSetTextKey";
const compVariableTextSetTextName = "variableTextSetTextName";
const compVariableTextSetTextColor = "variableTextSetTextColor";

export function DialogVariableTextSetText(props: {
  showColorField: boolean,
  isFormReadonly?: boolean,
  values?: DefnDtoOption,
  defaultValues?: DefnDtoOption,
  onClickOk: (values: DefnDtoOption) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const dto = props.values;
  const defaultValues = props.defaultValues;
  const showColorField = props.showColorField;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  const [autoGenerate, setAutoGenerate] = useState<boolean>(dto === undefined);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(key === compVariableTextSetTextName && autoGenerate)
    {
      cbRef.current.setValue(compVariableTextSetTextKey,
        fnRawValueToFieldValue("text", "key" + toSymbolCase(fnFieldValueToRawValue("text", value) as string))
      );
    }

    if(key === compVariableTextSetTextKey)
    {
      const _value = fnFieldValueToRawValue("text", cbRef.current.getValue(compVariableTextSetTextName)) as string;

      if(`key${toSymbolCase(_value)}` !== fnFieldValueToRawValue("text", value))
      {
        setAutoGenerate(false);
      }
    }

  }, [cbRef, autoGenerate]);

  return (
    <DialogDefnForm
      title={dto === undefined ? "Add text set" : "Update text set"}
      formProps={{
        cbRef: cbRef.current,
        onWatch: onWatch,
        defnForm: getDefnForm(showColorField, Boolean(dto?.metaId)),
        formReadonly: props.isFormReadonly,
        onSubmit: (fieldValues) =>
        {
          setAutoGenerate(true);
          return props.onClickOk(valueToDto(showColorField, fieldValues, dto));
        },
        initValues: dtoToValue(dto ?? defaultValues)
      }}
      addMoreCheckBoxLabel={!dto
        ? "Add more text sets"
        : undefined}
      onClose={props.onClose}
    />
  );
}

function valueToDto(showColorField: boolean, values: FieldValues, dto?: DefnDtoOption): DefnDtoOption
{
  return {
    ...dto,
    metaId: fnFieldValueToRawValue("text", values[compVariableTextSetTextKey]),
    value: fnFieldValueToRawValue("text", values[compVariableTextSetTextName]),
    color: showColorField
      ? values[compVariableTextSetTextColor]
      : undefined
  } as DefnDtoOption;
}

function dtoToValue(dto?: DefnDtoOption)
{
  if(dto)
  {
    return {
      [compVariableTextSetTextKey]: fnRawValueToFieldValue("text", dto.metaId),
      [compVariableTextSetTextName]: fnRawValueToFieldValue("text", dto.value),
      [compVariableTextSetTextColor]: dto.color
    };
  }
}

function getDefnForm(showColorField: boolean, disableMetaId?: boolean)
{
  return createDefaultDefnFormStudio({

    [compVariableTextSetTextName]: {
      type: "text",
      metaId: compVariableTextSetTextName,
      name: "Value",
      required: true,
      maxCharCountVar: MAX_CHARACTER_COUNT_SYMBOL
    } as DefnFieldText,

    [compVariableTextSetTextKey]: {
      type: "text",
      metaId: compVariableTextSetTextKey,
      name: "Key",
      required: true,
      maxCharCountVar: MAX_CHARACTER_COUNT_SYMBOL,
      disabled: disableMetaId,
      helperTextVar: stringToDefnDtoText(VAR_SET_OF_TEXT_KEY)
    } as DefnFieldText,

    ...showColorField && {
      [compVariableTextSetTextColor]: {
        type: "studioBuildColor",
        metaId: compVariableTextSetTextColor,
        defaultVar: "primary",
        name: "Color",
        required: true,
        allowShades: true
      } as DefnFieldEditable
    },

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: showColorField
        ? [compVariableTextSetTextName, compVariableTextSetTextKey, compVariableTextSetTextColor]
        : [compVariableTextSetTextName, compVariableTextSetTextKey]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}
