import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {isStoreItemId} from "../../../api/meta/base/ApiPlus";
import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {StoreItemId} from "../../../api/meta/base/Types";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {selectCacheStudio} from "../../../base/plus/StudioPlus";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {removeEnt} from "../../../cache/studio/ent/SliceCacheStudioEnt";
import {removePlugin} from "../../../cache/studio/plugin/SliceCacheStudioPlugin";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcPubSubStudio extends ISrvc
{
  //region enterprise

  ent(subscriberId: TypeSubscriberId, entId: EntId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: entId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioEnt"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  entAddressBook(subscriberId: TypeSubscriberId, entId: EntId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: entId,
      artifactId: ENT_ID_GLOBAL,
      type: "entAddrBook"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  entAdminBook(subscriberId: TypeSubscriberId, entId: EntId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: entId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioAdminBook"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  artifactAdminCaller(subscriberId: TypeSubscriberId, artifactId: ArtifactId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: artifactId,
      artifactId: ENT_ID_GLOBAL,
      type: "callerStudioAdmin"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  entDeployStatus(subscriberId: TypeSubscriberId, entId: EntId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: entId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioEntDeploy"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  studioAdminEditLock(subscriberId: TypeSubscriberId, artifactId: ArtifactId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: artifactId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioAdminEditLock"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      if(isEntId(artifactId))
      {
        Srvc.app.session.subscribe(subscriberId, dtoTopic);
      }
      else if(isPluginId(artifactId))
      {
        Srvc.app.session.subscribe(subscriberId, dtoTopic);
      }
    }
  }

  //endregion

  //region plugin

  plugin(subscriberId: TypeSubscriberId, pluginBundleId: PluginBundleId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: pluginBundleId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioPlugin"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  pluginAdminBook(subscriberId: TypeSubscriberId, pluginBundleId: PluginBundleId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: pluginBundleId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioAdminBook"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  //endregion

  handleSigTopic(sig: SigTopic, isOnSub?: boolean): boolean
  {
    const rootState = store.getState();
    const aboutId = sig.aboutId;
    const type = sig.type;

    const entMap = selectCacheStudio(rootState).ent.entMap;
    const pluginMap = selectCacheStudio(rootState).plugin.pluginMap;
    const isArtifactPresent = aboutId && (isEntId(aboutId) || isPluginId(aboutId))
      ? isEntId(aboutId)
        ? Boolean(entMap && entMap[aboutId])
        : Boolean(pluginMap && pluginMap[aboutId])
      : undefined;

    if(!isArtifactPresent && !isOnSub)
    {
      return false;
    }

    switch(type)
    {
      case "studioEnt":
        Srvc.cache.studio.ent.rpcStudioEntGet(aboutId);
        break;
      case "entAddrBook":
        Srvc.studio.ent.users.rpcEntUserListGet(aboutId);
        return true;
      case "studioAdminBook":
        if(isEntId(aboutId))
        {
          Srvc.studio.ent.admins.rpcEntAdminListGet(aboutId);
        }
        else if(isPluginId(aboutId))
        {
          Srvc.studio.plugin.admins.rpcPluginAdminListGet(aboutId);
        }
        else if(isStoreItemId(aboutId))
        {
          Srvc.store.admins.rpcStoreItemAdminListGet(aboutId as StoreItemId);
        }
        return true;

      case "studioPlugin":
        Srvc.cache.studio.plugin.rpcPluginGet(aboutId);
        return true;

      case "callerStudioAdmin":
        Srvc.studio.drawer.rpcEntAdminCallerGet(aboutId);
        return true;

      case "studioEntDeploy":
        Srvc.studio.ent.deploy.rpcEntDeployStatusGet(aboutId);
        return true;

      case "studioAdminEditLock":
        if(isEntId(aboutId))
        {
          Srvc.studio.ent.rpcEntAdminEditLockDetailGet(aboutId);
        }
        else if(isPluginId(aboutId))
        {
          Srvc.studio.plugin.rpcPluginAdminEditLockDetailGet(aboutId);
        }
        return true;
    }

    return false;
  }

  handleTopicUnSub(sig: SigTopic): boolean
  {
    const type = sig.type;

    switch(type)
    {
      case "studioEnt":
        store.dispatch(removeEnt(sig.aboutId as EntId));
        return true;
      case "entAddrBook":
        Srvc.studio.ent.users.resetEntMainAddressBook(sig.aboutId as EntId);
        return true;
      case "studioAdminBook":
        if(isEntId(sig.aboutId))
        {
          Srvc.studio.ent.admins.resetEntAdminBook(sig.aboutId as EntId);
        }
        else if(isPluginId(sig.aboutId))
        {
          Srvc.studio.plugin.admins.resetPluginAdminBook(sig.aboutId as PluginBundleId);
        }
        return true;
      case "studioPlugin":
        store.dispatch(removePlugin(sig.aboutId as PluginBundleId));
        return true;

      case "callerStudioAdmin":
      case "studioEntDeploy":
      case "studioAdminEditLock":
        // ignore
        return true;
    }

    return false;
  }
}
