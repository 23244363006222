import {RemoveRedEyeRounded} from "@mui/icons-material";
import {LockRounded} from "@mui/icons-material";
import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";

export type EnumProgressInnerIcon =
  | "lock"
  | "invisible"

export default function IconProgressInner(props: {
  icon: EnumProgressInnerIcon,
  color?: string,
  size?: number
})
{
  const theme = useTheme();

  const sx = {
    height: "inherit",
    alignSelf: "center",
    width: px(props.size ?? 12),
    color: props.color || theme.common.color("textSecondary")
  };
  const icon = props.icon;
  if(icon === undefined)
  {
    return null;
  }

  switch(icon)
  {
    case "invisible":
      return <RemoveRedEyeRounded sx={sx} />;
    case "lock":
      return <LockRounded sx={sx} />;
  }
}
