import {FieldValues} from "react-hook-form";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldIcon} from "../../../../../api/meta/base/dto/StudioFieldIcon";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";

export function getDefnFieldPickIcon(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    propKeyVarHelperText
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyDefaultValue,
    propKeyHelperTextValue
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "icon",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDefaultFieldId]: {
      type: "pickFieldId",
      name: propKeyDefaultFieldId,
      metaId: propKeyDefaultFieldId,
      label: "Default value",
      filterFieldTypeSet: ["icon"]
    } as DefnStudioPickFieldId,

    [propKeyDefaultValueVar]: {
      type: "pickVarId",
      name: propKeyDefaultValueVar,
      metaId: propKeyDefaultValueVar,
      label: "Default value",
      varKind: "icon",
      showAsEdit: true
    } as DefnStudioPickVarId,

    [propKeyDefaultValue]: {
      type: "icon",
      name: propKeyDefaultValue,
      metaId: propKeyDefaultValue,
      label: "Default value"
    }

  };
}

export function defnValueToStudioFieldIcon(values: FieldValues): StudioFieldIcon
{
  return {
    type: "decimal",
    defaultValue: fnFieldValueToRawValue("icon", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId])
  } as StudioFieldIcon;
}

export function studioFieldIconToDefnValue(studioField: StudioFieldIcon)
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("icon", studioField.defaultValue),
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId)
  };
}

