import {IconButton} from "@mui/material";
import {useTheme} from "@mui/material";
import {Button} from "@mui/material";
import {Stack} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {EnumIconButton} from "../icon/IconButtonStrip";
import IconButtonStrip from "../icon/IconButtonStrip";
import RawTooltip from "./RawTooltip";

export default function RawButtonStrip(props: {
  iconButtonList: EnumIconButton[],
  iconButtonDisable?: EnumIconButton[],
  toolTipMap?: Record<EnumIconButton, string>
  labelMap?: Record<EnumIconButton, string>,
  onClick?: (iconName: EnumIconButton, menuAnchor: HTMLButtonElement) => void,
  hideLabel?: boolean,
  size?: "small" | "medium" | "large";
})
{
  const {
    iconButtonList,
    iconButtonDisable,
    onClick,
    toolTipMap,
    labelMap,
    hideLabel
  } = props;

  const theme = useTheme();

  const size = props.size ?? "small";

  const label = (iconButton: EnumIconButton) =>
  {
    return (labelMap && labelMap[iconButton])
      ? labelMap[iconButton]
      : iconButton.toUpperCase();
  };

  return (
    <Stack
      direction={"row"}
      width={"auto"}
      spacing={px(theme.common.gapStd)}
    >
      {iconButtonList.map(iconButton =>
        <RenderButton
          key={iconButton}
          toolTipMap={toolTipMap}
          iconButton={iconButton}
          disabled={iconButtonDisable?.includes(iconButton)}
          uiButton={
            hideLabel ?
              <IconButton
                disableFocusRipple={true}
                size={size}
                onClick={(event) => onClick && onClick(iconButton, event.currentTarget)}
                disabled={iconButtonDisable?.includes(iconButton)}
              >
                <IconButtonStrip
                  iconButton={iconButton}
                  color={"primary"}
                />
              </IconButton>
              :
              <Button
                disableFocusRipple={true}
                size={size}
                onClick={(event) => onClick && onClick(iconButton, event.currentTarget)}
                disabled={iconButtonDisable?.includes(iconButton)}
                startIcon={
                  <IconButtonStrip
                    iconButton={iconButton}
                    color={"primary"}
                  />}
              >
                {label(iconButton)}
              </Button>
          }
        />
      )}
    </Stack>
  );
}

function RenderButton(props: {
  uiButton: React.ReactElement<any, any>,
  iconButton: EnumIconButton,
  disabled?: boolean,
  toolTipMap?: Record<EnumIconButton, string>
})
{
  const toolTipMap = props.toolTipMap;
  const iconButton = props.iconButton;
  const uiButton = props.uiButton;

  if(props.disabled)
  {
    return uiButton;
  }

  return (
    <RawTooltip
      title={toolTipMap ? toolTipMap[iconButton] : ""}
      key={iconButton}
    >
      {uiButton}
    </RawTooltip>
  );
}
