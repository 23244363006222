import {EnumDefnThemeColor} from "../../api/meta/base/Types";
import {CssBackgroundColor} from "../plus/ThemePlus";

export const ID_SEP = "_";

export type TypeTreeNodeUserFieldValue = object | boolean | string | number | undefined;
export type TypeTreeNodeUserField = Record<string, TypeTreeNodeUserFieldValue>;

export type TypeCbOnChangeTreeNodes = (rootNodes?: ITreeNode[]) => void;

export interface ITreeNode
{
  name: string,
  id: string,
  infoSpot?: EnumDefnThemeColor,
  bgcolor?: CssBackgroundColor,
  userField?: TypeTreeNodeUserField,
  children?: ITreeNode[];
}

export type TypeSelectedNodes = Record<string, string>

export interface ITreeRef
{
  nodeFind: (
    node: ITreeNode,
    nodes?: ITreeNode[],
    cb?: (index: number, nodes: ITreeNode[]) => void) => void;
  nodeFindParent: (
    node: ITreeNode,
    nodes?: ITreeNode[],
    cb?: (parentNode: ITreeNode) => void) => void;
  nodeAdd: (newNode: ITreeNode, parentNode?: ITreeNode) => void;
  nodeUpdate: (newNode: ITreeNode, oldNode: ITreeNode) => void;
  nodeRemove: (node: ITreeNode) => void;
  nodeMoveUp: (node: ITreeNode) => void;
  nodeMoveDown: (node: ITreeNode) => void;
  nodeMoveTop: (node: ITreeNode) => void;
  nodeMoveBottom: (node: ITreeNode) => void;
  expandAll: () => void;
  collapseAll: () => void;
  nodeExpand: (node: ITreeNode) => void;
  nodeCollapse: (node: ITreeNode) => void;
}
