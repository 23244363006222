import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldInfo} from "../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickVarId} from "../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioBuildUserSetting} from "../../../api/meta/base/dto/StudioBuildUserSetting";
import {StudioVarUserSetting} from "../../../api/meta/base/dto/StudioVarUserSetting";
import {EnumDefnUserSettingOptions} from "../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fieldVariableUserSettingValueKind} from "../base/VariablePlus";

const fieldDataSource = "optionDataSourceVarId";
const fieldDoNotShowOnUserSettings = "doNotShowOnUserSettings";
const fieldDoNotShowValueToAdmin = "doNotShowValueToAdmin";
const fieldUserSettingsNoteFlexGrow = "userSettingsNoteFlexGrow";
const fieldUserSettingsNote = "userSettingsNote";
const fieldUserSettingsNoteLabel = "Note: User variables captured during enterprise joining and in user settings";

export function fnVariableBuilderUserSetting(fieldValueUserSetting: string)
{
  function getVarComp(variableUserKind?: EnumDefnUserSettingOptions)
  {
    return {
      [fieldVariableUserSettingValueKind]: {
        type: "enumUserSettingOptions",
        metaId: fieldVariableUserSettingValueKind,
        label: "Kind",
        required: true
      } as DefnFieldPickEnum,

      ...(variableUserKind === "pickOne" || variableUserKind === "pickMany") && {
        [fieldDataSource]: {
          type: "pickVarId",
          metaId: fieldDataSource,
          showAsEdit: true,
          label: "Data source variable",
          varKind: "setOfText",
          required: true
        } as DefnStudioPickVarId
      },

      [fieldDoNotShowOnUserSettings]: {
        type: "bool",
        metaId: fieldDoNotShowOnUserSettings,
        label: "Do not show on user settings",
        showAsCheckboxVar: true
      } as DefnFieldSwitch,
      [fieldDoNotShowValueToAdmin]: {
        type: "bool",
        metaId: fieldDoNotShowValueToAdmin,
        label: "Do not show value to admin",
        showAsCheckboxVar: true
      } as DefnFieldSwitch,

      [fieldUserSettingsNoteFlexGrow]: {
        type: "info",
        metaId: fieldUserSettingsNoteFlexGrow,
        name: "",
        label: "",
        flexGrow: true
      } as DefnFieldInfo,

      [fieldUserSettingsNote]: {
        type: "label",
        metaId: fieldUserSettingsNote,
        label: fieldUserSettingsNoteLabel,
        textSizeVar: "caption",
        italicVar: true,
        disabled: true
      } as DefnFieldLabel
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarUserSetting
  )
  {
    const varValue = variable.value;
    if(varValue && varValue.userSettingOptionKind)
    {
      valueMap[fieldVariableUserSettingValueKind] = varValue.userSettingOptionKind;
      valueMap[fieldDataSource] = varValue.optionDataSourceVarId;
      valueMap[fieldDoNotShowOnUserSettings] = fnRawValueToFieldValue("bool", varValue.doNotShowOnUserSettings);
      valueMap[fieldDoNotShowValueToAdmin] = fnRawValueToFieldValue("bool", varValue.doNotShowValueToAdmin);
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const kind = valueMap[fieldVariableUserSettingValueKind] as EnumDefnUserSettingOptions;
    if(!kind)
    {
      return;
    }

    const valueDto = {
      doNotShowValueToAdmin: fnFieldValueToRawValue("bool", valueMap[fieldDoNotShowValueToAdmin]),
      doNotShowOnUserSettings: fnFieldValueToRawValue("bool", valueMap[fieldDoNotShowOnUserSettings]),
      userSettingOptionKind: kind
    } as StudioBuildUserSetting;

    if(kind === "pickOne" || kind === "pickMany")
    {
      valueDto.optionDataSourceVarId = valueMap[fieldDataSource];
    }

    return {
      value: valueDto
    } as StudioVarUserSetting;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
