import {useTheme} from "@mui/material";
import {Dialog} from "@mui/material";
import {Property} from "csstype";
import {useState} from "react";
import {useEffect} from "react";
import React from "react";
import {calcAspectHeight} from "../../../base/plus/MathPlus";
import {getAspectRatio} from "../../../base/plus/MathPlus";
import {calcAspectWidth} from "../../../base/plus/MathPlus";
import {px} from "../../../base/plus/StringPlus";
import {useWinSize} from "../../app/AppHooks";
import RawLazyImage from "../raw/RawLazyImage";

export default function ImageViewDialog(props: {
  onClose: () => void,
  src?: string,
  srcBlur?: string,
  bgColor?: Property.BackgroundColor,
  width?: number,
  height?: number,
  objectFit?: Property.ObjectFit
})
{
  const [open, setOpen] = useState<boolean>(true);

  const {
    calculatedWidth,
    calculatedHeight
  } = useCalcSize({
    height: props.height,
    width: props.width
  });

  return (
    <Dialog
      disableAutoFocus={true}
      open={open}
      onClose={() =>
      {
        props.onClose();
        setOpen(false);
      }}
      sx={{
        maxWidth: "100%",
        maxHeight: "100%"
      }}
      PaperProps={{
        sx: {
          borderRadius: 0,
          maxWidth: "100%",
          maxHeight: "100%",
          overflow: "hidden"
        }
      }}
    >
      <RawLazyImage
        src={props.src}
        srcBlur={props.srcBlur}
        width={px(calculatedWidth)}
        height={px(calculatedHeight)}
        primaryColor={props.bgColor}
        objectFit={props.objectFit}
      />
    </Dialog>
  );
}

function useCalcSize(props: {
  height?: number,
  width?: number
})
{
  const getWinSize = useWinSize();
  const theme = useTheme();
  const verticalGap = theme.common.gapStd * 5;
  const horizontalGap = theme.common.gapStd * 5;

  const actualWidth = props.width;
  const actualHeight = props.height;
  const imageMaxHeight = (getWinSize.height - verticalGap);
  const imageMaxWidth = (getWinSize.width - horizontalGap);

  const [calculatedWidth, setCalculatedWidth] = useState<number>();
  const [calculatedHeight, setCalculatedHeight] = useState<number>();

  useEffect(() =>
  {
    // stretch image to fit the screen (only 1.5X) if the image is too small
    // otherwise, shrink the image to fit the screen if the image is too big

    if(actualWidth && actualHeight)
    {
      const aspectRatio = getAspectRatio(actualWidth, actualHeight);
      const _imageMaxHeight = imageMaxHeight > (actualHeight * 1.5) ? (actualHeight * 1.5) : imageMaxHeight;
      const width = calcAspectWidth(_imageMaxHeight, aspectRatio);
      const height = calcAspectHeight(width, aspectRatio);
      if(width > imageMaxWidth)
      {
        const _imageMaxWidth = imageMaxWidth > (actualWidth * 1.5) ? (actualWidth * 1.5) : imageMaxWidth;
        const height = calcAspectHeight(_imageMaxWidth, aspectRatio);
        const width = calcAspectWidth(height, aspectRatio);
        setCalculatedWidth(width);
        setCalculatedHeight(height);
      }
      else
      {
        setCalculatedWidth(width);
        setCalculatedHeight(height);
      }
    }
  }, [actualHeight, actualWidth, imageMaxHeight, imageMaxWidth]);

  return {
    calculatedWidth,
    calculatedHeight
  };

}
