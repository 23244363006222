import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {EnumDefnSyncMode} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickEnumSyncMode(props: {
  defn: DefnFieldPickEnum
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const options = [
    {
      metaId: "backup",
      value: "Backup (write)"
    },
    {
      metaId: "biDirectional",
      value: "Bidirectional (read/write)"
    }
  ] as {
    metaId: EnumDefnSyncMode;
    value: string;
  }[];

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={optionsToMapOfOption(options)}
      />}
    />
  );
}
