import {Box} from "@mui/material";
import React from "react";
import {View} from "react-big-calendar";
import {HeaderProps} from "react-big-calendar";
import {calendarShellTodayDateColor} from "../../../../base/plus/CalendarPlus";
import {checkIsDateToday} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import RawHighlighter from "../../../atom/raw/RawHighlighter";

export default function CalendarHeaderLabel(props: {
  headerProps: HeaderProps, view: View
})
{
  const weekView = props.view === "week";
  const date = props.headerProps.date;
  const todayDate = checkIsDateToday(date);
  const defaultTextColor = theme.common.color("textPrimary");

  return (
    <Box
      width={"100%"}
      height={px(30)}
      mb={weekView ? px(gapStd) : undefined}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        paddingX: px(gapQuarter),
        "&::before": (todayDate && weekView) ? {
          content: "\"\"",
          transform: "translateX(-8px)",
          width: px(10),
          height: px(10),
          borderRadius: "50%",
          backgroundColor: calendarShellTodayDateColor
        } : {}
      }}
    >
      <RawHighlighter
        variant={"body2"}
        color={defaultTextColor}
        value={props.headerProps.label}
      />
    </Box>
  );
};
