import {useMemo} from "react";
import React from "react";
import {FieldValues} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnFieldFormList} from "../../../../api/meta/base/dto/DefnFieldFormList";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {TypeUserField} from "../../../../base/types/TypesGlobal";
import {useFormCtx} from "../base/CtxForm";
import FieldRawFormList from "../raw/FieldRawFormList";

// id is unique of useFieldArray
export type  FieldValueFormListItem = {
  rowId: string;
  valueMap: FieldValues;
  version?: string// to reduce re-render from binder
  uiVersion?: string // to rerender item when layout change
  userField?: TypeUserField;
}

export interface FieldValueFormList
{
  keys: string[],
  map: Record<string, FieldValueFormListItem>
}

export default function FieldFormList<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defn: DefnFieldFormList,
  defnForm: DefnFormUi,
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defn);
  const listBinder = useMemo(() => props.listBinderMap?.[fieldId], [props.listBinderMap?.[fieldId]]);
  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field: {
          value: fieldValue,
          onChange
        }
      }) =>
      {
        return (
          <FieldRawFormList
            {...props}
            onChange={onChange}
            listBinder={listBinder}
            fieldValue={fieldValue}
          />
        );
      }}
    />
  );
}
