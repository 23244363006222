import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldNumber} from "../../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldLogCounter} from "../../../../../api/meta/base/dto/StudioFieldLogCounter";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldLogReadRoleSet} from "../../base/FormBuilderPlus";
import {propKeyStepFieldId} from "../../base/TypesFormBuilder";
import {propKeyStep} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFill} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {propKeyStepVar} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldCounterLogged(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinFieldId,
    propKeyMaxFieldId,
    propKeyStepFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinVar,
    propKeyMaxVar,
    propKeyStepVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    propKeyAutoFill,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMin,
    propKeyMax,
    propKeyStep
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "number",
      formId,
      "number",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),
    [propKeyStep]: {
      type: "number",
      metaId: propKeyStep,
      name: propKeyStep,
      label: "Step"
    } as DefnFieldNumber,

    [propKeyStepVar]: {
      type: "pickVarId",
      name: propKeyStepVar,
      metaId: propKeyStepVar,
      label: "Step",
      varKind: "number",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyStepFieldId]: {
      type: "pickFieldId",
      name: propKeyStepFieldId,
      metaId: propKeyStepFieldId,
      label: "Step",
      formId: formId,
      filterFieldTypeSet: ["number"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId
  };
}

export function defnValueToStudioFieldCounterLogged(values: FieldValues): StudioFieldLogCounter
{
  return {
    type: "counter",
    defaultValue: fnFieldValueToRawValue("decimal", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    min: fnFieldValueToRawValue("decimal", values[propKeyMin]),
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    max: fnFieldValueToRawValue("decimal", values[propKeyMax]),
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    step: fnFieldValueToRawValue("number", values[propKeyStep]),
    stepVarId: fnFieldValueToRawValue("pickVarId", values[propKeyStepVar]),
    stepFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyStepFieldId]),
    logReadRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldLogReadRoleSet])
  } as StudioFieldLogCounter;
}

export function studioFieldToDefnValueCounterLogged(studioField: StudioFieldLogCounter)
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("decimal", studioField.defaultValue),
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMin]: fnRawValueToFieldValue("decimal", studioField.min),
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMax]: fnRawValueToFieldValue("decimal", studioField.max),
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyStep]: fnRawValueToFieldValue("number", studioField.step),
    [propKeyStepVar]: fnRawValueToFieldValue("pickVarId", studioField.stepVarId),
    [propKeyStepFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.stepFieldId),
    [fieldLogReadRoleSet]: fnRawValueToFieldValue("setOfRole", studioField.logReadRoleSet)
  };
}
