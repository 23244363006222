import {useCallback} from "react";
import {useMemo} from "react";
import {useState} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFormId} from "../../api/meta/base/dto/DefnStudioPickFormId";
import {StudioEntSpreadsheet} from "../../api/meta/base/dto/StudioEntSpreadsheet";
import {MetaIdField} from "../../api/meta/base/Types";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {toSymbolCase} from "../../base/plus/StringPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export const fieldSelectForm = "form";
export default function DialogNewSpreadsheet(props: {
  formStore?: FormStore
  onClickOk: (studioEntSpreadsheet: StudioEntSpreadsheet, isAddMoreEnable: boolean) => void
  onClose?: () => void,
})
{
  const formRef = useMemo(() => ({} as IFormRef), []);
  const [currentName, setCurrentName] = useState<string>();
  const [isAddMoreEnable, setIsAddMoreEnable] = useState(false);
  const formStore = props.formStore;

  const onSubmit = useCallback((formValues: FieldValues) =>
  {
    const spreadsheetName = formValues[fieldKeyName];
    const selectedForm = formValues[fieldSelectForm];
    if(spreadsheetName && selectedForm)
    {
      props.onClickOk(
        {
          details: {
            name: spreadsheetName
          },
          formId: selectedForm
        } as StudioEntSpreadsheet,
        isAddMoreEnable
      );
    }
  }, [isAddMoreEnable]);

  return (
    <DialogDefnForm
      title={"New spreadsheet"}
      formProps={{
        cbRef: formRef,
        defnForm: getDefnForm(),
        onSubmit: (formValue) =>
        {
          onSubmit(formValue);
          setCurrentName(undefined);
        },
        store: formStore,
        onWatch: (key, value) =>
        {
          if(key === fieldSelectForm)
          {
            const formMap = formStore?.formMap;

            if(formMap)
            {
              const _value = formMap.map[value]?.details.name || "";
              const sheetName = "Sheet" + toSymbolCase(_value)
              .replace("Form", "");
              const oldValue = formRef.getValue(fieldKeyName);

              if(!_value)
              {
                setCurrentName(undefined);
              }

              if(!currentName || currentName === oldValue || !oldValue)
              {
                const newValue = _value ? sheetName : undefined;
                formRef.setValue(fieldKeyName, newValue);
                setCurrentName(newValue);
              }
            }
          }

        }
      }}
      addMoreCheckBoxLabel={"Add more spreadsheets"}
      onClose={props.onClose}
      onChangeAddMore={setIsAddMoreEnable}
    />
  );
}

function getDefnForm()
{
  return createDefaultDefnFormStudio({
    [fieldSelectForm]: {
      type: "pickFormId",
      name: fieldSelectForm,
      label: "Form",
      metaId: fieldSelectForm,
      required: true,
      allowSystemForms: false
    } as DefnStudioPickFormId,
    [fieldKeyName]: {
      type: "symbol",
      name: "Name",
      label: "Name",
      metaId: fieldKeyName,
      required: true
    } as DefnFieldEditable,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldSelectForm, fieldKeyName]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}
