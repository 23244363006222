import {useTheme} from "@mui/material";
import {Checkbox} from "@mui/material";
import {Radio} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {TypePick} from "../../../base/types/TypesGlobal";
import RawSkeleton from "../raw/RawSkeleton";

export function BoxPick(props: {
  pickType: TypePick,
  pickValue?: boolean,
  onClick?: (value: boolean) => void,
  mr?: number,
  ml?: number,
})
{
  const {
    pickType,
    pickValue,
    onClick
  } = props;
  const theme = useTheme();
  const size = px(theme.common.gapStd) as string;
  if(pickType)
  {
    if(pickValue !== undefined)
    {
      return (
        <Box
          sx={{
            mr: px(props.mr),
            ml: px(props.ml)
          }}
        >
          {pickType === "pickOne"
            ? <Radio
              size={"small"} checked={pickValue} onChange={(event, checked) =>
            {
              onClick && onClick(checked);
            }}
            />
            : <Checkbox
              size={"small"} checked={pickValue} onChange={(event, checked) =>
            {
              onClick && onClick(checked);
            }}
            />}
        </Box>
      );
    }
    else
    {
      return <RawSkeleton
        variant={pickType === "pickOne" ? "circular" : "rectangular"}
        width={size}
        height={size}
        ml={props.ml}
        mr={props.mr}
      />;
    }
  }
  else
  {
    return null;
  }
}
