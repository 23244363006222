import {StudioEntSpreadsheetMap} from "../../../api/meta/base/dto/StudioEntSpreadsheetMap";
import {StudioFormMap} from "../../../api/meta/base/dto/StudioFormMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntSpreadsheets extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entSpreadsheetList;
  }

  loadSpreadsheetList(
    entId: EntId,
    listName: string,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    readOnly?: boolean)
  {

    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    if(!spreadsheetMap)
    {
      return;
    }

    spreadsheetMap && spreadsheetMap.keys.forEach((itemId) =>
    {
      const spreadsheet = spreadsheetMap.map[itemId];
      const form = formMap && Object.values(formMap.map).find((formId) =>
        formId.metaId === spreadsheet.formId);

      const spreadSheetFilter: StudioModuleSelection | undefined = spreadsheet.details.modules;

      if(Srvc.studio.ent.filterListByTag(entId, spreadSheetFilter))
      {
        uiItemIds.push(itemId);
      }

      const description: ILineSecondary = {};

      if(spreadsheet.details.description && spreadsheet.details.description.length > 0)
      {
        description.text = spreadsheet.details.description;
      }
      else
      {
        description.text = "No description";
        description.color = "textDisabled";
      }

      if(spreadsheet.partitionMap && spreadsheet.partitionMap.keys?.length)
      {
        description.caption = {
          text: "Partition"
        };
      }

      uiItemsById[itemId] = {
        type: "ps",
        primary: {
          text: spreadsheet.details.name,
          caption: {
            text: form?.details.name,
            ignoreSelection: true
          }
        },
        secondary: description,
        ignoreSelectionCaption: true,
        hideMenu: readOnly
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }
}
