import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickPipelineVarId} from "../../../../api/meta/base/dto/DefnStudioPickPipelineVarId";
import {StudioEntAutomation} from "../../../../api/meta/base/dto/StudioEntAutomation";
import {StudioEntAutomationPluginWebhook} from "../../../../api/meta/base/dto/StudioEntAutomationPluginWebhook";
import {StudioEntAutomationScheduled} from "../../../../api/meta/base/dto/StudioEntAutomationScheduled";
import {StudioEntAutomationSpreadsheet} from "../../../../api/meta/base/dto/StudioEntAutomationSpreadsheet";
import {StudioEntAutomationWebhook} from "../../../../api/meta/base/dto/StudioEntAutomationWebhook";
import {StudioEntPipelineVarMap} from "../../../../api/meta/base/dto/StudioEntPipelineVarMap";
import {EnumDefnKindWebhookEvent} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {EnumDefnKindPluginWebhookEvent} from "../../../../api/meta/base/Types";
import {EnumDefnKindScheduledEvent} from "../../../../api/meta/base/Types";
import {EnumDefnKindAutomation} from "../../../../api/meta/base/Types";
import {EnumDefnKindSpreadsheetEvent} from "../../../../api/meta/base/Types";
import {MetaIdPipelineVar} from "../../../../api/meta/base/Types";
import {mapToOptions} from "../../../../base/plus/JsPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdPipelineVar | MetaIdPipelineVar[] | undefined

export default function FieldRawStudioPickPipelineVarId(props: {
  defn: DefnStudioPickPipelineVarId
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const defn = props.defn;
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const automationMap = formStore?.automationMap;
  const pluginMap = formStore?.pluginMap;

  const formId = defn.formId;
  const automationId = defn.automationId;
  const automationKind = defn.automationKind;
  const filterSpreadsheetEventTypeSet = defn.filterSpreadsheetEventTypeSet;
  const filterScheduledEventTypeSet = defn.filterScheduledEventTypeSet;
  const filterWebhookEventTypeSet = defn.filterWebhookEventTypeSet;
  const includeOptionMap = defn.includeOptionMap;
  const pluginId = defn.pluginId;
  const pluginApiId = defn.pluginApiId;

  const plugin = pluginId
    ? pluginMap?.map[pluginId]
    : undefined;
  const pluginApi = plugin && pluginApiId
    ? plugin.pluginApiIdMap[pluginApiId]
    : undefined;
  const outputFormId = pluginApi && pluginApi.apiType === "webhook"
    ? pluginApi.outputFormId
    : undefined;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const automation = automationId
    ? automationMap?.map[automationId]
    : undefined;

  const dtoOptions = useMemo(() => getPickFieldOptions(
      formId,
      outputFormId,
      automation,
      automationKind,
      filterSpreadsheetEventTypeSet,
      filterScheduledEventTypeSet,
      filterWebhookEventTypeSet,
      includeOptionMap
    ),
    [
      formId,
      outputFormId,
      automation,
      automationKind,
      filterSpreadsheetEventTypeSet,
      filterScheduledEventTypeSet,
      filterWebhookEventTypeSet,
      includeOptionMap
    ]
  );

  const [options] = useInsertTrashOptions({
    type: "automationMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
    showChip={props.showChip}
  />;
}

type TypeDefnKindEventType =
  | EnumDefnKindSpreadsheetEvent
  | EnumDefnKindScheduledEvent
  | EnumDefnKindPluginWebhookEvent
  | EnumDefnKindWebhookEvent;

function getPickFieldOptions(
  formId?: MetaIdForm,
  pluginFormId?: MetaIdForm,
  automation?: StudioEntAutomation,
  automationKind?: EnumDefnKindAutomation,
  filterSpreadsheetEventTypeSet?: EnumDefnKindSpreadsheetEvent[],
  filterScheduledEventTypeSet?: EnumDefnKindScheduledEvent[],
  filterWebhookEventTypeSet?: EnumDefnKindPluginWebhookEvent[],
  extraOptionSet?: DefnStudioMapOfDtoOption): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];

  if(automation && automationKind)
  {
    const pipelineVarMap = {
      keys: [],
      map: {}
    } as StudioEntPipelineVarMap;

    if(formId && automationKind === "spreadsheet")
    {
      pipelineVarMap.keys.push(formId);
      pipelineVarMap.map[formId] = {
        metaId: formId,
        name: "Current form"
      };
    }

    if(pluginFormId && automationKind === "pluginWebhook")
    {
      pipelineVarMap.keys.push(pluginFormId);
      pipelineVarMap.map[pluginFormId] = {
        metaId: pluginFormId,
        name: "Current form"
      };
    }

    switch(automationKind)
    {
      case "spreadsheet":
      case "scheduled":
      case "pluginWebhook":
      case "webhook":
      {
        const eventMap = (automation as
          | StudioEntAutomationSpreadsheet
          | StudioEntAutomationScheduled
          | StudioEntAutomationPluginWebhook
          | StudioEntAutomationWebhook).eventMap;

        const filterEventTypeSet: TypeDefnKindEventType[] | undefined = automationKind === "spreadsheet"
          ? filterSpreadsheetEventTypeSet
          : automationKind === "scheduled"
            ? filterScheduledEventTypeSet
            : automationKind === "pluginWebhook"
              ? filterWebhookEventTypeSet
              : undefined;

        eventMap.keys.forEach(eventId =>
        {
          const event = eventMap.map[eventId];
          const type = event.fire as TypeDefnKindEventType | undefined;

          if(type
            && event.pipelineVarMap
            && (!filterEventTypeSet || filterEventTypeSet.includes(type)))
          {
            pipelineVarMap.keys = [...pipelineVarMap.keys, ...event.pipelineVarMap.keys];
            pipelineVarMap.map = {
              ...pipelineVarMap.map,
              ...event.pipelineVarMap.map
            };
          }
        });
      }
        break;
    }

    pipelineVarMap.keys.forEach(pipelineId =>
    {
      const pipelineVar = pipelineVarMap?.map[pipelineId];
      if(pipelineVar?.name)
      {
        options.push({
          metaId: pipelineId,
          value: pipelineVar.name
        });
      }
    });
  }

  if(extraOptionSet && extraOptionSet.keys.length > 0)
  {
    const extraOptions = mapToOptions(extraOptionSet);
    extraOptions && options.push(...extraOptions);
  }

  return options;
}
