import {MsgEntUserIdNoVersion} from "../../../api/core/base/msg/MsgEntUserIdNoVersion";
import {WsocAside} from "../../../api/home/aside/WsocAside";
import {MsgEntUserId} from "../../../api/home/base/msg/MsgEntUserId";
import {WsocDrawer} from "../../../api/home/drawer/WsocDrawer";
import {WsocMain} from "../../../api/home/main/WsocMain";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {EntId, EntUserId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {store} from "../../../Store";
import {calcUserLastOnline} from "./SliceCacheUser";
import {clearUserCommonGroups} from "./SliceCacheUser";
import {setUserCommonGroups} from "./SliceCacheUser";
import {calcUsersTypingText} from "./SliceCacheUser";
import {setUserLastOnline} from "./SliceCacheUser";
import {setUserAvatar} from "./SliceCacheUser";

export default class SrvcCacheUser extends ISrvc
{
  private readonly maxTicks = 2;
  private currTick = 0;

  wsocUserAvatarGet(entId: EntId, entUserId: EntUserId)
  {
    const msg: MsgEntUserId = {
      entUserId: entUserId
    };

    const currAvatar = store.getState().cache.app.user.userAvatarMap[entUserId];
    if(currAvatar)
    {
      msg.version = currAvatar.version;
    }

    WsocDrawer.userAvatarGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setUserAvatar(sig));
      }
    });
  }

  wsocUserLastOnline(entId: EntId, entUserId: EntUserId)
  {
    const msg: MsgEntUserIdNoVersion = {
      entUserId: entUserId
    };

    if(entId === ENT_ID_GLOBAL)
    {
      WsocMain.userLastOnlineGet(entId, msg, envSig =>
      {
        const sig = envSig.sig;
        if(sig)
        {
          store.dispatch(setUserLastOnline(sig));
        }
      });
    }
    else
    {
      WsocMain.userLastOnlineGet(entId, msg, envSig =>
      {
        const sig = envSig.sig;
        if(sig)
        {
          store.dispatch(setUserLastOnline(sig));
        }
      });
    }
  }

  wsocUserCommonGroupsGet(entId: EntId, entUserId: EntUserId)
  {
    const msg: MsgEntUserIdNoVersion = {
      entUserId: entUserId
    };
    WsocAside.groupCommonListGet(msg, envSig =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setUserCommonGroups({
          entId: entId,
          entUserId: entUserId,
          sig: sig
        }));
      }
    });
  }

  clearUserCommonGroups(entId: EntId, entUserId: EntUserId)
  {
    store.dispatch(clearUserCommonGroups({
      entId: entId,
      entUserId: entUserId
    }));
  }

  protected doTick(dateNow: Date)
  {
    if(this.currTick === this.maxTicks)
    {
      this.currTick = 0;

      store.dispatch((dispatch, getState) =>
      {
        if(getState().cache.app.user.usersTypingCount > 0)
        {
          dispatch(calcUsersTypingText());
        }
      });
    }
    else
    {
      this.currTick++;
    }

    super.doTick(dateNow);
  }

  protected onWsocClose()
  {
    this.clearAllUserOnline();
  }

  private clearAllUserOnline()
  {
    store.dispatch(calcUserLastOnline());
  }
}
