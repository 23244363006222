import {TabList} from "@mui/lab";
import {TabPanel} from "@mui/lab";
import {TabContext} from "@mui/lab";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Tab} from "@mui/material";
import {SimpleTreeView} from "@mui/x-tree-view";
import {TreeItem} from "@mui/x-tree-view";
import {isArray} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import {useRef} from "react";
import {useCallback} from "react";
import {SyntheticEvent} from "react";
import {useMemo} from "react";
import React from "react";
import {FieldErrors} from "react-hook-form";
import {useFormContext} from "react-hook-form";
import {Controller} from "react-hook-form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {FieldValueError} from "../../../../api/meta/base/dto/FieldValueError";
import {MetaIdTab} from "../../../../api/meta/base/Types";
import {MetaIdSection} from "../../../../api/meta/base/Types";
import {MetaIdComp} from "../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {getComp} from "../../../../base/plus/FormPlus";
import {hasValues} from "../../../../base/plus/JsPlus";
import {random} from "../../../../base/plus/StringPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {DividerHorizontal} from "../../../atom/layout/DividerHorizontal";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LineBadge from "../../../atom/line/LineBadge";
import RawDot from "../../../atom/raw/RawDot";
import {IPopoverMsg} from "../../../atom/raw/RawPopover";
import {useAppCtx} from "../../../ctx/CtxApp";
import IFormCtx from "../base/CtxForm";
import {useFormCtx} from "../base/CtxForm";
import FieldFactory from "../base/FieldFactory";
import {getCompLabel} from "../base/FormViewerPlus";

export default function FieldTab<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnTab
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
})
{
  const formCtx = useFormCtx();
  const hookFormCtx = useFormContext();

  const defnTab = props.defn;
  const defnForm = props.defnForm;
  const dirtyMap = useRef({} as Record<MetaIdTab, boolean>);
  const [version, setVersion] = useState<string>();
  const values = hookFormCtx.formState.defaultValues;
  const tabList = useMemo(() =>
  {
    return defnTab.tabIdSet
      ? defnTab.tabIdSet?.filter(value =>
      {
        const visibilityOption = formCtx.getFieldVisibilityOption(value);
        return !(formCtx.getDefnComp(value)?.hidden
          || visibilityOption?.hidden
          || formCtx.getDefnComp(value)?.invisible
          || visibilityOption?.invisible
        );
      })
      : [];
  }, [defnTab.tabIdSet]);

  useEffect(() =>
  {
    let subscription = hookFormCtx.watch(() =>
    {
      setTimeout(() =>
      {
        tabList?.forEach(value =>
        {
          const dirty = checkIfChildrenDirty(value, defnForm, hookFormCtx.getValues());
          dirtyMap.current[value] = Boolean(dirty);
        });
        setVersion(random(5));
      });
    });

    return () =>
    {
      if(subscription !== undefined)
      {
        subscription.unsubscribe();
      }
    };
  }, [defnForm, tabList]);

  useEffect(() =>
  {
    if(values)
    {
      tabList?.forEach(value =>
      {
        const dirty = checkIfChildrenDirty(value, defnForm, values);
        dirtyMap.current[value] = Boolean(dirty);
        setVersion(random(5));
      });
    }
  }, [values]);

  return (
    <Controller
      name={defnTab.metaId}
      control={formCtx.control()}
      defaultValue={tabList[0]}
      render={({
        field,
        formState: {
          errors
        }
      }) =>
      {
        return <RealFieldTab
          {...props}
          tabList={tabList}
          fieldValue={field.value}
          onChange={field.onChange}
          errors={errors}
          formCtx={formCtx}
          dirtyMap={version ? dirtyMap.current : {}}
        />;
      }}
    />
  );
}

function RealFieldTab<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  formCtx: IFormCtx
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnTab
  tabList: MetaIdComposite[];
  fieldValue: MetaIdSection,
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
  errors: FieldErrors
  onChange: (value: MetaIdSection) => void,
  dirtyMap: Record<string, boolean>
})
{
  const theme = useTheme();
  const hookFormCtx = useFormContext();
  const appCtx = useAppCtx();

  const gapStd = theme.common.gapStd;
  const defn = props.defn;
  const defnForm = props.defnForm;
  const listBinderMap = props.listBinderMap;
  const defnTheme = props.defnTheme;
  const tabList = props.tabList;
  const timeOutRef = React.useRef<NodeJS.Timeout>();
  const fieldValue = props.fieldValue;
  const dirtyMap = props.dirtyMap;

  const hidden = defn.hidden || (tabList.length === 1 && !defn.showSingleTab);
  const tabVariant = defn.tabVariant;
  const formContainerWidth = appCtx.getFormContainerWidth && appCtx.getFormContainerWidth();

  const key = getFieldKey(defn);
  const fieldValues = hookFormCtx.getValues && hookFormCtx.getValues();
  const currentTab = fieldValues
    ? fieldValues[defn.metaId]
    : undefined;

  const [tabErrorMap, setTabErrorMap] = useState(isCurrentTabFieldInErrorMap(defnForm,
    props.errors,
    tabList,
    fieldValues
  ));
  const [expanded, setExpanded] = useState<string[]>([]);
  const [isScrollable, setIsScrollable] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | string[] | IPopoverMsg[] | undefined>();

  const onChange = (key: MetaIdField) =>
  {
    hookFormCtx.setValue(defn.metaId, key, {
      shouldDirty: false,
      shouldValidate: false,
      shouldTouch: true
    });
  };

  const cbOnClickBadge = useCallback((_: React.MouseEvent<HTMLElement>) =>
  {
    const errorMessages: string[] = [];

    Object.keys(props.errors || {}).forEach((key) =>
    {
      const errorMessage = props.errors[key]?.message as string;
      errorMessages.push(errorMessage);
    });

    setErrorMessage(errorMessages);
  }, [props.errors]);

  const isTabScrollable = useMemo(() => calculateIsTabScrollable(
      defnForm,
      tabList,
      formContainerWidth,
      dirtyMap,
      tabErrorMap
    ),
    [
      defnForm,
      dirtyMap,
      formContainerWidth,
      tabErrorMap,
      tabList
    ]
  );

  useEffect(() =>
  {
    setTimeout(() =>
    {
      if(tabList.length > 0 && (!fieldValue || !tabList.includes(fieldValue)))
      {
        onChange(tabList[0]);
      }
    });
  }, [fieldValue, tabList]);

  useEffect(() =>
  {
    if(timeOutRef.current)
    {
      clearTimeout(timeOutRef.current);
    }
    timeOutRef.current = setTimeout(() =>
    {
      setTabErrorMap(isCurrentTabFieldInErrorMap(defnForm, props.errors, tabList, fieldValues));
    });

    return () =>
    {
      clearTimeout(timeOutRef.current);
    };

  }, [defnForm, props.errors, tabList]);

  useEffect(() =>
  {
    setIsScrollable(isTabScrollable);
  }, [isTabScrollable, formContainerWidth]);

  if(defn.showAsTree)
  {
    return (
      <React.Fragment>
        <SimpleTreeView
          multiSelect={false}
          key={key}
          expandedItems={expanded}
          onExpandedItemsChange={(_event: SyntheticEvent, nodeIds: string[]) => setExpanded(nodeIds)}
          sx={{
            flexGrow: 1,
            margin: 0,
            marginTop: "0 !important"
          }}
        >
          {
            tabList.map(field =>
            {
              const defnChildComp = getComp(defnForm, field) as DefnSection;

              return (
                <TreeItem
                  itemId={defnChildComp.metaId}
                  key={defnChildComp.metaId}
                  autoFocus={false}
                  onKeyDownCapture={e => e.stopPropagation()}
                  label={getLabel(defnChildComp)}
                  sx={{
                    flexGrow: 1,
                    paddingTop: px(gapQuarter),
                    background: "none",
                    "&:hover": {
                      background: "none"
                    },
                    "& .Mui-selected .MuiTreeItem-content:focus": {
                      background: "none"
                    },
                    "& .Mui-selected .MuiTreeItem-content:hover": {
                      background: "none"
                    },
                    "& .MuiTreeItem-content:hover": {
                      background: "none"
                    },
                    "& .MuiTreeItem-content .MuiTreeItem-iconContainer": {
                      width: "auto"
                    },
                    "& .MuiTreeItem-content": {
                      marginBottom: px(gapHalf)
                    },
                    "& .MuiTreeItem-content.Mui-selected": {
                      background: "none !important"
                    },
                    "& .MuiFormControlLabel-root ": {
                      margin: 0,
                      marginBottom: px(gapHalf)
                    }
                  }}
                >
                  <LayoutFlexCol
                    key={field}
                    flexGrow={1}
                    overflowX={"visible"}
                    overflowY={"visible"}
                  >
                    <FieldFactory
                      key={field}
                      defnForm={defnForm}
                      defnTheme={defnTheme}
                      defnComp={defnChildComp}
                      listBinderMap={listBinderMap}
                    />
                  </LayoutFlexCol>
                </TreeItem>
              );
            })
          }
        </SimpleTreeView>
      </React.Fragment>
    );
  }

  return (
    <TabContext value={fieldValue}>
      <LayoutFlexCol
        width={"100%"}
        flexGrow={1}
        alignItems={"start"}
        justifyContent={"start"}
      >
        <TabList
          sx={{
            width: "100%",
            ".MuiTabs-scrollButtons.Mui-disabled": {
              opacity: 0.3
            },
            ...hidden && {
              display: "none"
            }
          }}
          onChange={(_, tabKey) => onChange(tabKey)}
          variant={tabVariant
            ? tabVariant
            : isScrollable
              ? "scrollable"
              : "fullWidth"
          }
          scrollButtons="auto"
        >
          {tabList.map((tabKey) =>
          {
            return (
              <Tab
                key={tabKey}
                sx={{
                  padding: 0
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      padding: 0,
                      paddingRight: px(gapStd),
                      paddingLeft: px(gapStd),
                      overflow: "hidden"
                    }}
                  >

                    {dirtyMap[tabKey] &&
                      <LayoutFlexCol mr={px(gapHalf)}>
                        {currentTab === tabKey
                          ? <RawDot />
                          : <RawDot color={"textSecondary"} />
                        }
                      </LayoutFlexCol>
                    }

                    {
                      getCompNameByKey(tabKey, defnForm)
                    }

                    {
                      tabErrorMap[tabKey] &&
                      <LineBadge
                        badge={{
                          value: tabErrorMap[tabKey],
                          color: "error"
                        }}
                        ml={gapHalf}
                        onClickBadge={e =>
                        {
                          cbOnClickBadge(e);
                        }}
                        errorMessage={errorMessage}
                      />
                    }
                  </Box>
                }
                value={tabKey}
                id={tabKey}
                itemID={tabKey}
              />
            );
          })}

        </TabList>

        {
          defn.showDivider && <DividerHorizontal />
        }

        {
          tabList.map((compKey) =>
          {
            return (
              <TabPanel
                value={compKey}
                key={compKey}
                sx={{
                  pl: defn.pl !== undefined ? px(defn.pl) : 0,
                  pr: defn.pr !== undefined ? px(defn.pr) : 0,
                  pt: defn.pt !== undefined ? px(defn.pt) : px(gapStd),
                  pb: defn.pb !== undefined ? px(defn.pb) : px(gapStd),
                  flexGrow: 1,
                  width: "100%",
                  height: "100%",
                  overflow: "auto"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <FieldFactory
                    defnForm={defnForm}
                    defnTheme={defnTheme}
                    defnComp={getComp(defnForm, compKey)}
                    listBinderMap={listBinderMap}
                  />
                </Box>
              </TabPanel>
            );
          })
        }
      </LayoutFlexCol>
    </TabContext>
  );
}

function getCompNameByKey(compId: MetaIdField, defnForm: DefnFormUi)
{
  const defn = getComp(defnForm, compId);
  return getCompLabel(defn);
}

function isCurrentTabFieldInErrorMap(
  defnForm: DefnFormUi,
  errorMap: FieldErrors,
  tabList: MetaIdComposite[],
  valueMap?: FieldValues)
{
  const tabErrorMap = {} as Record<string, number>;
  const recursion = (
    compId: MetaIdField,
    tabKey: MetaIdField,
    compMap: Record<MetaIdComp, DefnComp>
  ) =>
  {
    const comp = compMap && compMap[compId];
    const internalError = "InternalError";

    if(comp.type === "tab")
    {
      (comp as DefnTab).tabIdSet?.forEach(tabId => recursion(tabId, tabKey, compMap));
    }
    else if(comp.type === "section")
    {
      (comp as DefnSection).fieldIdSet?.forEach(fieldId => recursion(fieldId, tabKey, compMap));
    }
    else if(comp.type === "grid")
    {
      (comp as DefnGrid).fieldIdSet?.forEach(fieldId => recursion(fieldId, tabKey, compMap));
    }
    else if((comp as DefnField).metaId)
    {
      const value = valueMap
        ? valueMap[compId]
        : undefined;

      if((errorMap[compId] && errorMap[compId]?.type !== internalError)
        || (comp.type === "error" && value && (value as FieldValueError).errorReason))
      {
        if(!tabErrorMap[tabKey])
        {
          tabErrorMap[tabKey] = 1;
        }
        else
        {
          tabErrorMap[tabKey] += 1;
        }
      }
    }
  };

  const compMap = defnForm.compMap;
  tabList.forEach(tabKey =>
  {
    recursion(tabKey, tabKey, compMap);
  });
  return tabErrorMap;
}

function calculateIsTabScrollable(
  defnForm: DefnFormUi,
  tabList: MetaIdComposite[],
  containerWidth: number,
  dirtyMap?: Record<string, boolean>,
  tabErrorMap?: Record<string, number>
)
{
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if(context && tabList.length && containerWidth)
  {
    const individualWidth = (containerWidth / tabList.length);

    for(let i = 0; i < tabList.length; i++)
    {
      const tabId = tabList[i];
      const defn = defnForm?.compMap[tabId] as DefnTab;
      const isHidden = Boolean(defn.hidden);

      const fontFamily = "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif";
      const fontWeight = 400;

      // Set the font properties on the context
      context.font = `${fontWeight} ${gapStd}px ${fontFamily}`;

      const label = !isHidden ? getLabel(defn) : "";
      const textWidth = context?.measureText(label)?.width || 0;

      const leftPadding = dirtyMap && dirtyMap[tabId] ? (gapHalf + theme.common.sizeDot) : 0;
      const rightPadding = tabErrorMap && tabErrorMap[tabId] ? (gapHalf + theme.common.heightLine) : 0;
      const calcWidth = Math.ceil(textWidth + leftPadding + (2 * gapStd) + rightPadding);

      if(calcWidth >= individualWidth)
      {
        return true;
      }
    }
  }

  return false;
}

function checkIfChildrenDirty(fieldId: MetaIdField, defnForm: DefnFormUi, valueMap: FieldValues)
{
  const comp = getComp(defnForm, fieldId);
  const compType = comp.type;
  if(compType === "tab" || compType === "section")
  {
    const fieldIdSet = compType === "tab"
      ? (comp as DefnTab).tabIdSet
      : compType === "section"
        ? (comp as DefnSection).fieldIdSet
        : undefined;

    const length = fieldIdSet?.length;
    if(length)
    {
      for(let i = 0; i < length; i++)
      {
        const fieldId = fieldIdSet[i];
        const dirty = checkIfFieldDirty(fieldId, defnForm, valueMap);
        if(dirty)
        {
          return true;
        }
      }
    }
  }
  else if(compType === "grid")
  {
    const dirty = checkIfFieldDirty(comp.metaId, defnForm, valueMap);
    if(dirty)
    {
      return true;
    }
  }
}

function checkIfFieldDirty(fieldId: MetaIdField, defnForm: DefnFormUi, valueMap: FieldValues)
{
  const comp = getComp(defnForm, fieldId);
  const compType = comp.type;
  if(compType === "section" || compType === "tab")
  {
    return checkIfChildrenDirty(fieldId, defnForm, valueMap);
  }

  return isFieldDirty(comp, valueMap[fieldId]);
}

const isFieldDirty = (comp: DefnComp, value: any): boolean =>
{
  const compType = comp.type;
  if(compType === "tab" || compType === "section" || comp.hideDirtyIndicator)
  {
    return false;
  }

  const rawFieldValue = fnFieldValueToRawValue(compType, value);
  if(isArray(rawFieldValue))
  {
    return rawFieldValue.length > 0;
  }
  if(typeof rawFieldValue === "object" && rawFieldValue !== null)
  {
    // @ts-ignore
    return rawFieldValue?.keys ? rawFieldValue.keys?.length > 0 : hasValues(rawFieldValue);
  }
  return Boolean(rawFieldValue);
};
