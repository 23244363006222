import {FieldValues} from "react-hook-form";
import {nextMetaIdCode} from "../../../../../api/meta/base/ApiPlus";
import {DefnFieldPickOption} from "../../../../../api/meta/base/dto/DefnFieldPickOption";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioCodeEditor} from "../../../../../api/meta/base/dto/DefnStudioCodeEditor";
import {StudioFieldText} from "../../../../../api/meta/base/dto/StudioFieldText";
import {StudioValueCodeJavascript} from "../../../../../api/meta/base/dto/StudioValueCodeJavascript";
import {EnumArrayDefnTextValidationPattern} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {TypeTextValidationPattern} from "../../base/FieldBuilderPlus";
import {propKeySecureEntry} from "../../base/TypesFormBuilder";
import {propKeyTextValidationPatternCustomValue} from "../../base/TypesFormBuilder";
import {propKeyTextValidationPattern} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFill} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyMaxCharCount} from "../../base/TypesFormBuilder";
import {propKeyMinCharCount} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyMaxCharCountFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinCharCountFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueTextVar} from "../../base/TypesFormBuilder";
import {propKeyMinCharacterCount} from "../../base/TypesFormBuilder";
import {propKeyMaxCharacterCount} from "../../base/TypesFormBuilder";

export function getDefnFieldText(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[],
  validationPattern?: TypeTextValidationPattern
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinCharCountFieldId,
    propKeyMaxCharCountFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueTextVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinCharacterCount,
    propKeyMaxCharacterCount
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    propKeyAutoFill,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMinCharCount,
    propKeyMaxCharCount,
    fieldGap4,
    propKeyTextValidationPattern,
    ...validationPattern === "custom"
      ? [propKeyTextValidationPatternCustomValue]
      : [],
    propKeySecureEntry
  ];

  return {
    [propKeyTextValidationPattern]: {
      type: "pickOption",
      metaId: propKeyTextValidationPattern,
      name: propKeyTextValidationPattern,
      label: "Validation pattern",
      optionSet: [...EnumArrayDefnTextValidationPattern, "custom"]
    } as DefnFieldPickOption,

    ...validationPattern === "custom" && {
      [propKeyTextValidationPatternCustomValue]: {
        type: "studioCodeEditor",
        metaId: propKeyTextValidationPatternCustomValue,
        name: propKeyTextValidationPatternCustomValue,
        label: "Regex",
        language: "javascript",
        lineCount: 4
      } as DefnStudioCodeEditor
    },

    [propKeySecureEntry]: {
      type: "bool",
      metaId: propKeySecureEntry,
      name: propKeySecureEntry,
      label: "Secure text",
      showAsCheckbox: true
    } as DefnFieldSwitch,

    ...getFieldBuilderPropertySubTabs(
      "text",
      formId,
      "text",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    )
  };
}

export function defnValueToStudioFieldText(values: FieldValues): StudioFieldText
{
  const textValidationPattern = fnFieldValueToRawValue("pickOption",
    values[propKeyTextValidationPattern]) as TypeTextValidationPattern | undefined;
  const textValidationPatternCustomValue = fnFieldValueToRawValue("studioCodeEditor",
    values[propKeyTextValidationPatternCustomValue]) as StudioValueCodeJavascript | undefined;

  return {
    type: "text",
    defaultValue: fnFieldValueToRawValue("text", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[propKeyDefaultValueTextVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    minCharCount: fnFieldValueToRawValue("number", values[propKeyMinCharCount]),
    minCharCountVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinCharacterCount]),
    minCharCountFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinCharCountFieldId]),
    maxCharCount: fnFieldValueToRawValue("number", values[propKeyMaxCharCount]),
    maxCharCountVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxCharacterCount]),
    maxCharCountFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxCharCountFieldId]),
    secureTextEntry: fnFieldValueToRawValue("bool", values[propKeySecureEntry]),
    validationPattern: textValidationPattern
      ? {
        value: textValidationPattern !== "custom" ? textValidationPattern : undefined,
        customValue: textValidationPattern === "custom" ? textValidationPatternCustomValue?.value : undefined
      }
      : undefined
  } as StudioFieldText;
}

export function studioFieldTextToDefnValue(studioField: StudioFieldText): FieldValues
{
  const textValidationPatternCustomValue = fnRawValueToFieldValue("studioCodeEditor",
    studioField.validationPattern?.customValue);

  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("text", studioField.defaultValue),
    [propKeyDefaultValueTextVar]: fnRawValueToFieldValue("studioVarIdTextEditor", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMinCharCount]: fnRawValueToFieldValue("number", studioField.minCharCount),
    [propKeyMinCharacterCount]: fnRawValueToFieldValue("pickVarId", studioField.minCharCountVarId),
    [propKeyMinCharCountFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minCharCountFieldId),
    [propKeyMaxCharCount]: fnRawValueToFieldValue("number", studioField.maxCharCount),
    [propKeyMaxCharacterCount]: fnRawValueToFieldValue("pickVarId", studioField.maxCharCountVarId),
    [propKeyMaxCharCountFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxCharCountFieldId),
    [propKeySecureEntry]: fnRawValueToFieldValue("bool", studioField.secureTextEntry),
    [propKeyTextValidationPattern]: fnRawValueToFieldValue("pickOption", !textValidationPatternCustomValue
      ? studioField.validationPattern?.value
      : "custom"),
    [propKeyTextValidationPatternCustomValue]: textValidationPatternCustomValue
      ? {
        metaId: nextMetaIdCode(),
        value: textValidationPatternCustomValue
      } as StudioValueCodeJavascript
      : undefined
  };
}

