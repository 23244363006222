import {useState} from "react";
import {useMemo} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {EnumDefnLayoutGridKind} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {getLayoutLabel} from "../form/builder/base/FieldBuilderPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const xyChart = "xyChart";

export default function DialogNewLayoutGrid(props: {
  onClickOk: (values: EnumDefnLayoutGridKind) => void,
  isFormReadonly?: boolean,
  onClose?: () => void,
  isPluginForm?: boolean
})
{
  const onClickOK = props.onClickOk;
  const isPluginForm = props.isPluginForm;

  const formRef = useMemo(() => ({} as IFormRef), []);

  const [gridLayout, setGridLayout] = useState<string>("list");

  const defnForm = useMemo(() => getDefnForm(isPluginForm, gridLayout), [gridLayout, isPluginForm]);

  return (
    <DialogDefnForm
      formProps={{
        cbRef: formRef,
        defnForm: defnForm,
        formReadonly: props.isFormReadonly,
        onSubmit: values => onClickOK(valueToDto(values)),
        initValues: {
          [compGridLayout]: fnRawValueToFieldValue("pickText", "list")
        },
        onWatch: (key, value) =>
        {
          if(key === compGridLayout)
          {
            const val = fnFieldValueToRawValue("pickText", value) as string;
            if(val !== xyChart)
            {
              formRef.resetField(fieldXYChartType);
            }
            else
            {
              formRef.setValue(fieldXYChartType,
                fnRawValueToFieldValue("pickText", "xyChartBarGraph" as EnumDefnLayoutGridKind));
            }
            setGridLayout(val);
          }
        }
      }}
      title={"New grid layout"}
      contentHeight={510}
    />
  );
}

const compGridLayout = "gridLayout";
const fieldXYChartType = "xyChartType";

function getDefnForm(
  isPluginForm?: boolean,
  gridLayout?: string)
{
  const layoutOptions = [
    "card",
    "list",
    "map",
    "table",
    xyChart,
    "kanban",
    "calendar"
  ];
  const xyChartTypeOptions = [
    "xyChartBarGraph",
    "xyChartLineChart",
    "xyChartPieChart",
    "xyChartDoughnut",
    "xyChartScatterPlot"
  ] as EnumDefnLayoutGridKind[];

  const mapOfOption = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  xyChartTypeOptions.forEach(option =>
  {
    const value = getLayoutLabel(option);

    mapOfOption.keys.push(option);
    mapOfOption.map[option] = {
      metaId: option,
      value: value
    };
  });

  const enumArrayDefnLayoutGridMode = isPluginForm
    ? layoutOptions.filter(kind => kind === "list" || kind === "table")
    : layoutOptions;

  const isLayoutXY = gridLayout === xyChart;

  return createDefaultDefnFormStudio({
    [compGridLayout]: {
      type: "pickText",
      metaId: compGridLayout,
      name: compGridLayout,
      showAs: "radioButtonVertical",
      hideDirtyIndicator: true,
      optionMap: arrayToMapOfOption(enumArrayDefnLayoutGridMode, false),
      pageSize: 7
    } as DefnFieldPickText,

    ...isLayoutXY && {
      [fieldXYChartType]: {
        type: "pickText",
        metaId: fieldXYChartType,
        name: fieldXYChartType,
        optionMap: mapOfOption,
        required: true
      } as DefnFieldPickText
    },

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [
        compGridLayout,
        ...isLayoutXY
          ? [fieldXYChartType]
          : []
      ]
    } as DefnSection
  });
}

function valueToDto(values: FieldValues): EnumDefnLayoutGridKind
{
  let gridLayout = fnFieldValueToRawValue("pickText", values[compGridLayout]);

  if(gridLayout === xyChart)
  {
    gridLayout = fnFieldValueToRawValue("pickText", values[fieldXYChartType]);
  }

  return gridLayout as EnumDefnLayoutGridKind;
}
