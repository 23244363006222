import {useTheme} from "@mui/material";
import {CircularProgress} from "@mui/material";
import {Fade} from "@mui/material";
import React from "react";

export default function RawFadeLoader()
{
  const theme = useTheme();

  return (
    <Fade
      in={true}
      style={{
        transitionDelay: theme.common.durationLoader + "ms"
      }}
      unmountOnExit
    >
      <CircularProgress />
    </Fade>
  );
}
