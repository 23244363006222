import {CSSProperties} from "react";
import React from "react";
import theme from "../../../base/plus/ThemePlus";

export default function CarouselDot(props: {
  clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
  isSelected: boolean,
  index: number,
  label: string
})
{
  const {
    clickHandler: onClickHandler,
    isSelected,
    index,
    label
  } = props;

  if(isSelected)
  {
    return (
      <li
        style={{
          ...indicatorStyles,
          background: theme.common.color("textPrimary")
        }}
        aria-label={`Selected: ${label} ${index + 1}`}
        title={`Selected: ${label} ${index + 1}`}
      />
    );
  }

  return (
    <li
      style={indicatorStyles}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      value={index}
      key={index}
      role="button"
      tabIndex={0}
      title={`${label} ${index + 1}`}
      aria-label={`${label} ${index + 1}`}
    />

  );
}

const indicatorStyles: CSSProperties = {
  background: theme.common.color("textDisabled"),
  width: 8,
  height: 8,
  display: "inline-block",
  margin: "0 8px",
  borderRadius: "50%",
  cursor: "pointer"
};
