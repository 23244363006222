import {toLower} from "lodash";
import {useMemo} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {StudioBuildArgBinder} from "../../api/meta/base/dto/StudioBuildArgBinder";
import {ArgBinderType} from "../../api/meta/base/StudioSetsArgBinder";
import {EnumDefnArgBinder} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldName = "name";
const fieldQuery = "query";

const HEIGHT_CONTENT = 300;
const WIDTH_CONTENT = 500;

export interface IStudioMapOfArgBinder
{
  key: string,
  value: StudioBuildArgBinder
}

export default function DialogStudioMapOfArgBinder(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  values?: IStudioMapOfArgBinder,
  onClickOk: (values: IStudioMapOfArgBinder) => void,
  onClose?: () => void,
  formId?: MetaIdForm,
  label?: string
})
{
  const formStore = props.formStore;
  const values = props.values;
  const formId = props.formId;
  const label = props.label;
  const onClickOk = props.onClickOk;

  const cbRef = useMemo(() => ({} as IFormRef), []);
  const dialogLabel = toLower(label);

  const defnForm = getDefnForm(formId, label);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} ${dialogLabel}`}
      contentHeight={HEIGHT_CONTENT}
      contentWidth={WIDTH_CONTENT}
      formProps={{
        cbRef: cbRef,
        defnForm: defnForm,
        store: formStore,
        formReadonly: props.isFormReadOnly,
        initValues: values ? dtoToValue(values) : undefined,
        onSubmit: values => onClickOk(valueToDto(values))
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? `Add more ${dialogLabel}`
        : undefined}
    />
  );
}

function valueToDto(values: FieldValues): IStudioMapOfArgBinder
{
  return {
    key: fnFieldValueToRawValue("text", values[fieldName]) as string,
    value: fnFieldValueToRawValueArgBinder(values[fieldQuery]) ?? {} as StudioBuildArgBinder
  };
}

function dtoToValue(dto: IStudioMapOfArgBinder)
{
  return {
    [fieldName]: fnRawValueToFieldValue("text", dto.key),
    ...fnRawValueToFieldValueArgBinder(fieldQuery, dto.value)
  };
}

function getDefnForm(formId?: MetaIdForm, label?: string)
{
  return createDefaultDefnFormStudio({
    [fieldName]: {
      type: "text",
      metaId: fieldName,
      name: fieldName,
      label: "Name",
      required: true
    } as DefnFieldText,

    [fieldQuery]: {
      type: "studioBuildArgBinder",
      metaId: fieldQuery,
      name: fieldName,
      label: label,
      formId: formId,
      required: true,
      derivedFormId: formId,
      filterKindSet: (ArgBinderType as EnumDefnArgBinder[]).filter(type =>
      {
        return !(type === "field"
          || type === "derived"
          || type === "constant"
          || type === "variable"
          || type === "context");
      })
    } as DefnStudioBuildArgBinder,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [
        fieldName,
        fieldQuery
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}


