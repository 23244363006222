import {stringify} from "qs";
import ReconnectingWebSocket from "reconnecting-websocket";
import {Event} from "reconnecting-websocket/reconnecting-websocket";
import {IMsg} from "../../api/meta/base/msg/IMsg";
import {newGuid} from "../../api/meta/base/NanoId";
import {ISig} from "../../api/meta/base/sig/ISig";
import {RequestId} from "../../api/meta/base/Types";
import {ServiceName} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {SigAuth} from "../../api/nucleus/base/sig/SigAuth";
import {EnumApiMethod} from "../../api/nucleus/base/Types";
import {onSignal} from "../../api/PushSigs";
import {store} from "../../Store";
import ISrvcAuth from "../ISrvcAuth";
import {hostPortProvider} from "../plus/HostPortPlus";
import {setFlagBearerToken} from "../slices/SliceAuth";
import {LOG_RECV_COLOR} from "../util/AppLog";
import {LOG_SEND_COLOR} from "../util/AppLog";
import {logRequired} from "../util/AppLog";
import {isErrorLog} from "../util/AppLog";
import {logError} from "../util/AppLog";
import {isTraceLog} from "../util/AppLog";
import {logTrace} from "../util/AppLog";
import {logDebug} from "../util/AppLog";
import {logInfo} from "../util/AppLog";
import {LOG_SEND} from "../util/AppLog";
import {isDebugLog} from "../util/AppLog";
import {LOG_RECV} from "../util/AppLog";
import {isInfoLog} from "../util/AppLog";
import WsocCall from "./WsocCall";

let CLIENT_ID = 0;
const SEP = ", "; // keep the space char
const API_VERSION = "v1";

const OPTIONS = {
  minReconnectionDelay: 1000,
  maxReconnectionDelay: 4000,
  connectionTimeout: 4000,
  pingFreq: 30000,
  reconnectionDelayGrowFactor: 1.25
};

const MSG_PING = "MsgPing";
const SIG_AUTH = "SigAuth";
const SIG_PONG = "SigPong";

// A new wsoc client is created for each bearer token
export default class WsocClient
{
  readonly clientId = nextClientId();
  private ws?: ReconnectingWebSocket;
  private pingerIntervalId?: number;
  private networkOn = false;

  // Map preserves the insertion order while iteration
  constructor(
    private readonly srvcAuth: ISrvcAuth,
    readonly callMap: Map<RequestId, WsocCall<any>>)
  {
    info(`** clientId = ${this.clientId}, callMapSize = ${callMap.size}`);
  }

  open(cbOnSuccess: () => void): void
  {
    debug("!! open");

    if(this.ws !== undefined)
    {
      return;
    }

    const self = this;
    const urlProvider = hostPortProvider.wsocUrlProvider(this.srvcAuth);
    this.ws = new ReconnectingWebSocket(urlProvider, [], OPTIONS);

    this.ws.onopen = function(): void
    {
      trace("== connected");

      self.networkOn = true;
      cbOnSuccess();
    };

    this.ws.onerror = function(event: Event): void
    {
      if(isErrorLog() && self.networkOn)
      {
        error("!! onError, " + JSON.stringify(event));
      }
    };

    this.ws.onclose = (event: Event) =>
    {
      self.onClose(event);
    };

    this.ws.onmessage = (event: MessageEvent) =>
    {
      const data = event.data;
      if(typeof data !== "string")
      {
        error(new Error("Bad data received on websocket"));
        return;
      }

      let envSig: EnvSignal<ISig>;
      try
      {
        envSig = JSON.parse(data as string) as EnvSignal<ISig>;
      }
      catch(e)
      {
        error(e);
        return;
      }

      if(envSig === undefined)
      {
        error(new Error("EnvSignal is required"));
        return;
      }

      const refreshToken = envSig.cookieValue;
      if(refreshToken !== undefined)
      {
        error(new Error("Refresh tokens should not be sent on a web socket connections"));
        this.srvcAuth.rpcSignOut();
        return;
      }

      if(envSig.sigName === SIG_PONG)
      {
        trace(`${LOG_RECV} pong`);
        return;
      }

      if(envSig.sigName === SIG_AUTH)
      {
        trace(`${LOG_RECV} onAuth`);
        self.onAuth(envSig as EnvSignal<SigAuth>);
        return;
      }

      const envError = envSig.error;
      if(envError !== undefined)
      {
        error(`${LOG_RECV} onError, ${JSON.stringify(envError)}`);

        const errorCode = envError.errorCode;
        if(errorCode === "unauthorizedRefreshToken")
        {
          this.srvcAuth.rpcSignOut();
          return;
        }
        else if(errorCode === "unauthorizedBearerToken")
        {
          store.dispatch(setFlagBearerToken(false));
          this.srvcAuth.rpcGrantBearerToken(true);
          return;
        }
      }

      const requestId = envSig.requestId;
      if(requestId)
      {
        let wsocCall = this.callMap.get(requestId);
        if(wsocCall)
        {
          if(!envSig.error && isInfoLog())
          {
            const sig = envSig.sig;
            if(sig)
            {
              logInfo(
                "WsocCall",
                `${LOG_RECV} onSignal, requestId = ${requestId}, sig = ${JSON.stringify(sig)}`,
                LOG_RECV_COLOR
              );
            }
            else
            {
              logInfo("WsocCall", `${LOG_RECV} onSignal, requestId = ${requestId}`, LOG_RECV_COLOR);
            }
          }

          this.callMap.delete(requestId);
          wsocCall.onSignal(envSig);
          return;
        }
      }

      const srvcName = envSig.serviceName;
      const sigName = envSig.sigName;
      const sig = envSig.sig;
      if(srvcName && sigName && sig)
      {
        if(isTraceLog())
        {
          const log = `serviceName = ${srvcName}, sigName = ${sigName}, sig = ${JSON.stringify(sig)}`;
          trace(`${LOG_RECV} onSignalPush, ${log}`);
        }

        if(onSignal(srvcName, sigName, sig))
        {
          return;
        }
      }

      error(`!! badSignal, envSig = ${JSON.stringify(envSig)}`);
    };
  }

  send(wsocCall: WsocCall<any>): void
  {
    const msg = wsocCall.getMsg();

    // ws is undefined only after close
    const ws = this.ws;
    if(ws)
    {
      if(wsocCall.hasSent)
      {
        if(!wsocCall.hasReceived)
        {
          this.callMap.set(wsocCall.requestId, wsocCall);
        }
        return;
      }

      const address = encodeAddress(
        wsocCall.requestId,
        wsocCall.getApiMethod(),
        wsocCall.entId,
        wsocCall.serviceName,
        wsocCall.apiName,
        msg
      );

      const body = encodeBody(wsocCall.getApiMethod(), msg);
      if(isInfoLog())
      {
        if(body)
        {
          info(`${LOG_SEND} ${address}, msg = ${body}`, LOG_SEND_COLOR);
        }
        else
        {
          info(`${LOG_SEND} ${address}`, LOG_SEND_COLOR);
        }
      }

      this.callMap.set(wsocCall.requestId, wsocCall);
      ws.send(encodeEnvelop(address, body));
      wsocCall.hasSent = true;
    }
    else
    {
      error("!! message lost, msg = " + JSON.stringify(msg));
    }
  }

  close(): void
  {
    const ws = this.ws;
    if(ws)
    {
      ws.send("MsgClose");
      ws.close();
    }
    else
    {
      this.onClose();
    }

    this.ws = undefined;

    debug(">< close");
  }

  private onClose(event?: Event): void
  {
    if(isDebugLog() && this.networkOn)
    {
      if(event)
      {
        trace("!! onClose, event = " + JSON.stringify(event));
      }
      else
      {
        trace("!! onClose");
      }
    }

    window.clearInterval(this.pingerIntervalId);

    if(CLIENT_ID === this.clientId)
    {
      this.networkOn = false;

      this.srvcAuth.fireWsocClose();
    }
  }

  private onAuth(envSig: EnvSignal<SigAuth>): void
  {
    const sig = envSig.sig as SigAuth;
    if(sig.unauthorizedBearerToken)
    {
      //TODO: Commented to fix the auto logout issue while the machine wakes up after idle mode
      // store.dispatch(setFlagBearerToken(false));
      this.srvcAuth.rpcGrantBearerToken(true);
    }
    else
    {
      const self = this;
      this.pingerIntervalId = window.setInterval(() =>
      {
        trace(`${LOG_SEND} ping`);

        const ws = self.ws;
        ws?.send(`${MSG_PING}${SEP}{randomText:"${newGuid()}"`);
      }, OPTIONS.pingFreq);

      const callMap = new Map(this.callMap);

      // for the calls that don't succeed because of disconnection the callMap iteration below
      // will put back the messages in callMap if the socket is not closed (i.e. ws is undefined)
      this.callMap.clear();

      this.srvcAuth.fireWsocAuth(); // subscribe

      setTimeout(() =>
      {
        callMap.forEach(value => value.onSend());
      });
    }
  }
}

function info(message: any, color?: string)
{
  logInfo("WsocClient", message, color);
}

function debug(message: any)
{
  logDebug("WsocClient", message);
}

function trace(message: any, color?: string)
{
  logTrace("WsocClient", message, color);
}

function error(message: any)
{
  logError("WsocClient", message);
}

function nextClientId()
{
  CLIENT_ID++;
  return CLIENT_ID;
}

function encodeEnvelop(address: string, body?: string): string
{
  return body === undefined
    ? address
    : address + SEP + body;
}

function encodeAddress(
  requestId: RequestId,
  method: EnumApiMethod,
  entId: EntId,
  serviceName: ServiceName,
  apiName: string,
  msg?: IMsg): string
{
  logRequired("WsocClient", requestId, "requestId");
  logRequired("WsocClient", method, "method");
  logRequired("WsocClient", entId, "entId");
  logRequired("WsocClient", serviceName, "serviceName");
  logRequired("WsocClient", apiName, "apiName");

  let address = `/${method}/${entId}/${API_VERSION}/${serviceName}/${apiName}/${requestId}`;

  if(method === "get" || method === "delete")
  {
    if(msg !== undefined)
    {
      address = address + stringify(msg, {
        arrayFormat: "brackets",
        encodeValuesOnly: true,
        addQueryPrefix: true,
        skipNulls: true
      });
    }
  }

  return address;
}

function encodeBody(method: EnumApiMethod, msg?: IMsg): string | undefined
{
  return method !== "get" && method !== "delete" && msg !== undefined
    ? JSON.stringify(msg)
    : undefined;
}
