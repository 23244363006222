import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {Chip} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {TextField} from "@mui/material";
import React from "react";
import {forwardRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import {RefCallBack} from "react-hook-form";
import {nextOptionId} from "../../../api/meta/base/ApiPlus";
import {DefnDtoOption} from "../../../api/meta/base/dto/DefnDtoOption";
import {px} from "../../../base/plus/StringPlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import IFormCtx from "../../form/viewer/base/CtxForm";
import RawIconButton from "./RawIconButton";

interface FieldRawTextChipsInput
{
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onChange: (event?: DefnDtoOption[]) => void,
  formCtx: IFormCtx,
  fieldId: string,
  chipList?: DefnDtoOption[]
  placeHolder?: string,
  label?: string,
  required?: boolean,
  disabled?: boolean,
  name?: string,
  readOnly?: boolean,
  inputFieldSize?: "small" | "medium",
  hideLabel?: boolean,
  onBlur?: () => void,
  isError?: boolean,
  disableEditing?: boolean,
  autoFocus?: boolean,
  allowDuplicate?: boolean,
  icon?: string
}

const RawChipsInput = forwardRef<RefCallBack, FieldRawTextChipsInput>((props: FieldRawTextChipsInput, ref) =>
{
  const {
    chipList,
    placeHolder,
    label,
    required,
    disabled,
    name,
    onChange,
    readOnly,
    inputFieldSize,
    hideLabel,
    allowDuplicate,
    formCtx,
    fieldId,
    icon
  } = props;

  const theme = useTheme();

  const getShrinkValue = (value?: DefnDtoOption[]) =>
  {
    return !!(value && value.length > 0);
  };

  const [chips, setChips] = useState<DefnDtoOption[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [shrink, setShrink] = useState<boolean>(getShrinkValue(chipList));

  const onDelete = (chipToDelete: DefnDtoOption) =>
  {
    const _chips = chips.filter((currChip) => currChip !== chipToDelete);
    setChips(_chips);
    onChange(_chips);
  };

  const onChangeChipInput = (value: readonly (string | DefnDtoOption)[]) =>
  {
    const chips = [] as DefnDtoOption[];
    value.forEach((val: any) =>
    {
      if(val && typeof val === "string")
      {
        const stringValues = val.trim().split(",").filter(item => item !== "");

        if(!allowDuplicate)
        {
          const filteredValues = [...new Set(stringValues)];
          filteredValues.forEach(filteredValue =>
          {
            const isDuplicate = chips.some(chip => chip.value === filteredValue);
            if(!isDuplicate)
            {
              chips.push({
                metaId: nextOptionId(),
                value: filteredValue
              });
            }
          });
        }
        else
        {
          stringValues.forEach(stringValue =>
          {
            chips.push({
              metaId: nextOptionId(),
              value: stringValue
            });
          });
        }
      }
      else
      {
        chips.push(val);
      }
    });
    onChange(chips);
  };

  const getOnClick = formCtx && formCtx.getOnClick();
  const fieldBorderColor = formCtx && formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId ?? "");

  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId ?? "", "field");
    }
    : undefined;

  useEffect(() =>
  {
    setChips(chipList || []);
  }, [chipList]);

  useEffect(() =>
  {
    setShrink(getShrinkValue(chipList));
  }, [chipList]);

  return (
    <Autocomplete
      ref={ref}
      multiple
      freeSolo
      fullWidth={true}
      options={[]}
      readOnly={readOnly}
      disabled={disabled}
      value={chips}
      inputValue={inputValue}
      onInputChange={(_, value) =>
      {
        if(props.disableEditing)
        {
          return;
        }

        if(!(value.charAt(0) === " "))
        {
          setShrink(true);
        }

        setInputValue(value);
      }}
      onChange={!props.disableEditing
        ? (_, value) => onChangeChipInput(value)
        : undefined
      }
      renderTags={(chips: DefnDtoOption[]) =>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.5
          }}
        >
          {
            chips.map((chip, index) => (
              <Chip
                key={chip.metaId + index}
                label={chip.value}
                onDelete={!readOnly
                  ? () => onDelete(chip)
                  : undefined}
                size={"small"}
                sx={{
                  borderRadius: px(gapQuarter),
                  height: px(theme.common.chipHeight)
                }}
              />
            ))
          }
        </Box>
      }
      onFocus={(event) =>
      {
        if(event.type === "focus")
        {
          setShrink(true);
        }
      }}
      onClose={(event) =>
      {
        if(event.type === "blur")
        {
          if(inputValue.trim() !== "")
          {
            setInputValue("");
          }
        }
        if(event.type === "blur" && (!chips || chips.length === 0))
        {
          setShrink(false);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          error={props.isError}
          onBlur={props.onBlur}
          autoFocus={props.autoFocus}
          placeholder={placeHolder}
          label={hideLabel ? undefined : label}
          required={required}
          disabled={disabled}
          name={name}
          onClick={onClick}
          aria-readonly={readOnly}
          size={inputFieldSize ?? "small"}
          hiddenLabel={hideLabel}
          onKeyDown={(event) =>
          {
            if(event.key === "Backspace" && chips.length > 0 && inputValue === "")
            {
              const _chips = chips.slice(0, -1);
              setChips(_chips);
              onChange(_chips);
            }
          }}
          InputProps={{
            ...params.InputProps,
            onClick: props.onClick,
            endAdornment: icon ?
              <Box
                position={"absolute"}
                right={px(gapHalf)}
              >
                <RawIconButton
                  name={icon}
                  icon={icon}
                />
              </Box> : undefined
          }}
          InputLabelProps={{
            shrink: shrink
          }}
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }
          }
        />
      )}
    />
  );
});

export default RawChipsInput;
