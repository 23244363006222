import ISrvc from "../../../../base/ISrvc";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {updateEntDeployManage} from "../SliceCacheStudioEnt";

import {ActionUpdateEntDeployLock} from "../TypesCacheStudioEnt";

export default class SrvcCacheStudioEntDeployManage extends ISrvc
{
  updateEntDeployManage(payload: ActionUpdateEntDeployLock)
  {
    dispatchEnt(payload.entId, updateEntDeployManage(payload));
  }
}
