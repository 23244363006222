import {MsgHandle} from "../../../api/core/base/msg/MsgHandle";
import {MsgAddressBookContact} from "../../../api/home/drawer/msg/MsgAddressBookContact";
import {RpcDrawer} from "../../../api/home/drawer/RpcDrawer";
import {SigAddressBook} from "../../../api/home/drawer/sig/SigAddressBook";
import {WsocDrawer} from "../../../api/home/drawer/WsocDrawer";
import {ISigAcceptor} from "../../../api/nucleus/base/ISigAcceptor";
import {createMsgVersion} from "../../../api/nucleus/base/Protocol";
import {SigDone} from "../../../api/nucleus/base/sig/SigDone";
import ISrvc from "../../../base/ISrvc";
import {STR_YOU} from "../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {listVersion} from "../../../base/plus/ListPlus";
import {listRecursiveCalls} from "../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../base/plus/MediaPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {listReset} from "../../../base/slices/list/SliceList";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcHomeDrawerAddressBook extends ISrvc
{

  constructor(public selectList: SelectList)
  {
    super();
  }

  //region rpc

  rpcAddressBookGet()
  {
    const selectList: SelectList = state => state.home.drawer.homeAddressBook;

    const version = listVersion(store.getState(), selectList);
    const msg = createMsgVersion(version);
    RpcDrawer.addressBookGet(msg, envSig => listRecursiveCalls<SigAddressBook>(
      envSig,
      selectList,
      this.rpcAddressBookGet,
      this.doLoad.bind(this)
    ));
  }

  wsocAddressBookContactUpdate(patch: boolean, msg: MsgAddressBookContact, sigAcceptor: ISigAcceptor<SigDone>)
  {
    if(patch)
    {
      WsocDrawer.addressBookContactPatch(msg, sigAcceptor);
    }
    else
    {
      WsocDrawer.addressBookContactPut(msg, sigAcceptor);
    }
  }

  wsocAddressBookContactRemove(handle: string)
  {
    const msg = {
      handle: handle
    } as MsgHandle;

    WsocDrawer.addressBookContactRemove(msg, Srvc.app.toast.showErrorToast);
  }

  wsocAddressBookInvite(handle: string, cbSuccess: CbSuccess)
  {
    const callerHandle = store.getState().cache.app.caller.callerHandle;

    if(handle === callerHandle)
    {
      return;
    }

    const msg = {
      handle: handle
    } as MsgHandle;

    WsocDrawer.addressBookInvite(msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  //endregion

  clearAddressBook()
  {
    const listName = this.selectList(store.getState()).listName;
    dispatchList(listName, listReset());
  }

  //region private

  private doLoad(listName: string, sig: SigAddressBook): void
  {
    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;

    this.doLoadCandidates(sig, uiItemsById, uiGroupsById);
    this.doLoadOthers(sig, uiItemsById, uiGroupsById);

    dispatchList(listName, listRefresh({
      version: sig.version,
      itemsById: uiItemsById,
      groupsById: uiGroupsById
    } as IListData));

  }

  private doLoadCandidates(sig: SigAddressBook, uiItemsById: IListItemsById, uiGroupsById: IListGroupsById)
  {
    const candidateMap = sig.candidateMap;
    const candidateKeys = Object.keys(candidateMap);
    if(candidateKeys.length)
    {
      const callerHandle = store.getState().cache.app.caller.callerHandle;

      candidateKeys.forEach((prefix) =>
      {
        const candidateArray = candidateMap[prefix];
        const groupItemIds = [] as TypeListItemId[];
        candidateArray.forEach(candidate =>
        {
          const itemId = candidate.handle as TypeListItemId;
          groupItemIds.push(itemId);

          uiItemsById[itemId] = {
            type: "aps",
            avatarLeft: {
              icon: "user",
              src: getImageThumbnail(candidate.mediaIdAvatar)
            },
            primary: {
              text: candidate.nickName,
              caption: {
                text: candidate.handle === callerHandle ? STR_YOU : ""
              }
            },
            secondary: {
              text: itemId
            },
            userField: {value: candidate.entUserId}
          } as IListItemAPSA;
        });

        uiItemsById[prefix] = {
          type: "listGroup",
          ignoreSelection: true,
          hideMenu: true,
          header: {
            text: prefix,
            expand: true,
            mt: gapQuarter,
            mb: gapQuarter
          }
        } as IListItemGroup;

        uiGroupsById[prefix] = {
          itemIds: groupItemIds
        };
      });

      // adding gap between Candidates and Others
      if(candidateKeys.length && sig.othersList.length)
      {
        uiGroupsById["gapId1"] = "gapHalf";
      }
    }
  };

  private doLoadOthers(sig: SigAddressBook, uiItemsById: IListItemsById, uiGroupsById: IListGroupsById)
  {
    const othersList = sig.othersList;

    if(othersList.length)
    {
      const headerItemId = "id~other~contacts";
      uiItemsById[headerItemId] = {
        type: "listGroup",
        ignoreSelection: true,
        hideMenu: true,
        header: {
          text: "Invite contacts",
          mt: gapHalf,
          mb: gapHalf,
          ml: gapStd
        }
      } as IListItemGroup;

      const otherHandles: string[] = [];
      othersList.forEach(other =>
      {
        const handle = other.handle;
        otherHandles.push(handle);

        uiItemsById[handle] = {
          type: "ps",
          userField: {value: "other"},
          ignoreSelection: true,
          primary: {
            text: other.nickName,
            caption: {
              text: "INVITE",
              color: "primary"
            }
          },
          secondary: {
            text: other.handle
          }
        } as IListItemAPSA;
      });

      uiGroupsById[headerItemId] = {
        itemIds: otherHandles
      };
    }
  }

  //endregion
}

