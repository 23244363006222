import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickPluginFormId} from "../../../../api/meta/base/dto/DefnStudioPickPluginFormId";
import {StudioEntPlugin} from "../../../../api/meta/base/dto/StudioEntPlugin";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

export default function FieldRawStudioPickPluginFormId(props: {
  defn: DefnStudioPickPluginFormId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const defn = props.defn;
  const pluginId = defn.pluginId;
  const pluginApiId = defn.pluginApiId;

  const excludeFormIdSet = defn.excludeFormIdSet;
  const alias = defn.alias;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const plugin = pluginId
    ? formStore?.pluginMap?.map[pluginId]
    : undefined;

  const options = useMemo(() =>
  {
    if(!plugin)
    {
      return [];
    }
    return getOption(plugin,
      pluginApiId,
      excludeFormIdSet,
      alias
    );
  }, [plugin, pluginApiId, excludeFormIdSet, alias]);

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOption(
  plugin: StudioEntPlugin,
  pluginApiId?: string,
  excludeFormIdSet?: MetaIdForm[],
  alias?: string): DefnDtoOption[]
{
  const options = [] as DefnDtoOption[];
  let pluginFormKeys = plugin.pluginFormKeys;
  if(pluginApiId)
  {
    const inputFormId = plugin.pluginApiIdMap[pluginApiId].inputFormId;
    const outputFormId = plugin.pluginApiIdMap[pluginApiId].outputFormId;
    pluginFormKeys = pluginFormKeys.filter((formId) => (formId === inputFormId || formId === outputFormId));
  }

  const pluginFormMap = plugin.pluginFormMap;
  pluginFormKeys.forEach((formId) =>
  {
    if(excludeFormIdSet?.includes(formId))
    {
      return;
    }

    const form = pluginFormMap[formId];
    const value = form.name || form.metaId;

    options.push({
      metaId: form.metaId,
      value: (alias
        ? `${value} -> ${alias}`
        : value)
    } as DefnDtoOption);
  });

  return options;
}
