import {isEmpty} from "lodash";
import {StudioEnt} from "../../../api/meta/base/dto/StudioEnt";
import {StudioEntTranslation} from "../../../api/meta/base/dto/StudioEntTranslation";
import {StudioEntTranslationMap} from "../../../api/meta/base/dto/StudioEntTranslationMap";
import {LanguageKey} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {TypeStudioFilterType} from "../../../base/plus/StudioPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntTranslations extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entTranslationList;
  }

  addTranslation(entId: EntId, translation: StudioEntTranslation)
  {
    if(translation.phrase === undefined && isEmpty(translation.translationMap))
    {
      return;
    }

    Srvc.cache.studio.ent.translations.addEntTranslation(entId, translation);
  }

  loadTranslationList(
    entId: EntId,
    listName: string,
    ent: StudioEnt,
    isStoreItem?: boolean,
    translateSet?: string[],
    usageFilter?: TypeStudioFilterType
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    const entLanguages = ent.details.languageSet;
    const showMissingUnusedPhrases = usageFilter === "Unused";

    const translationMap = ent.translationMap;
    const translationPhraseMap = this.createTranslationPhraseMap(translationMap);

    if(translateSet && translateSet?.length > 0)
    {
      translateSet?.forEach((translate) =>
      {
        const translation = translationPhraseMap[translate];
        const isTranslationHasLanguageMap = this.checkLanguageHasTranslation(translation);

        if(showMissingUnusedPhrases && isTranslationHasLanguageMap)
        {
          return;
        }

        uiItemIds.push(translate);

        uiItemsById[translate] = {
          type: "p",
          primary: this.getTranslationPrimary(translate, translation),
          hideMenu: true
        } as IListItemAPSA;
      });
    }

    if(isStoreItem)
    {
      Object.entries(translationPhraseMap).forEach(([translate, translation]) =>
      {
        const isTranslationHasLanguageMap = this.checkLanguageHasTranslation(translation);

        if(showMissingUnusedPhrases && isTranslationHasLanguageMap)
        {
          return;
        }

        if(!translateSet?.includes(translate))
        {
          uiItemIds.push(translate);

          uiItemsById[translate] = {
            type: "p",
            primary: this.getTranslationPrimary(translate, translation),
            hideMenu: true
          } as IListItemAPSA;
        }
      });
    }

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));

    this.removeTranslations(entId, translationMap, entLanguages);
  }

  createTranslationPhraseMap(translationMap: StudioEntTranslationMap)
  {
    const phraseMap = {} as Record<string, StudioEntTranslation>;
    const keys = translationMap.keys;
    const map = translationMap.map;

    keys && map && keys.forEach(metaIdTranslation =>
    {
      const translation = map[metaIdTranslation];

      if(translation && translation.phrase)
      {
        phraseMap[translation.phrase] = translation;
      }
    });

    return phraseMap;
  }

  getTranslationPrimary(translate: string, translation: StudioEntTranslation)
  {
    let primary = {} as ILinePrimary;
    const color = this.getTranslationColor(translation);
    const doesContainNewLine = translate.includes("\n");

    primary.text = translate;
    primary.color = color;
    primary.caption = {
      text: doesContainNewLine ? "Paragraph" : "Text",
      color: color,
      ignoreSelection: true
    };

    return primary;
  }

  checkLanguageHasTranslation(translation?: StudioEntTranslation)
  {
    const translationLangMap = translation?.translationMap;
    if(translationLangMap)
    {
      return Object.values(translationLangMap).some(value => value !== "" || value !== undefined);
    }
    return false;
  }

  getTranslationColor(translation?: StudioEntTranslation)
  {
    const languageHasTranslation = this.checkLanguageHasTranslation(translation);
    return languageHasTranslation ? "textPrimary" : "textDisabled";
  }

  removeTranslations(
    entId: EntId,
    translationMap: StudioEntTranslationMap,
    entLanguages?: LanguageKey[])
  {
    const keys = translationMap.keys;
    const map = translationMap.map;

    keys && map && Object.values(map).forEach(translation =>
    {
      const phrase = translation.phrase;
      const languageMap = translation.translationMap;
      if(phrase)
      {
        const isAllTranslationEmpty = languageMap
          && Object.values(languageMap).every(_translation => _translation === undefined || _translation === "");

        if(isEmpty(languageMap) || isAllTranslationEmpty)
        {
          Srvc.cache.studio.ent.translations.removeEntTranslation(entId, translation.metaId);
        }

        if(languageMap && entLanguages)
        {
          Object.keys(languageMap).forEach(languageKey =>
          {
            if(languageMap[languageKey] === undefined || languageMap[languageKey] === "")
            {
              Srvc.cache.studio.ent.translations.removeEntTranslationLanguage(entId, translation, languageKey);
            }
          });
        }
      }
    });
  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    Srvc.cache.studio.ent.translations.setUsageFilter(entId, filterType);
  }

}
