import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {IAvatar} from "../../../base/types/TypesGlobal";
import AvatarAtom from "../avatar/AvatarAtom";
import AvatarDot from "../avatar/AvatarDot";
import AvatarGap from "../avatar/AvatarGap";
import AvatarSkeleton from "../avatar/AvatarSkeleton";

export default function BoxAvatar(props: {
  avatar?: IAvatar,
  isScrolling?: boolean,
  mr?: number,
  ml?: number,
  avatarSize?: StandardCSSProperties["width"],
  borderRadius?: StandardCSSProperties["borderRadius"]
})
{
  const avatar = props.avatar;
  if(avatar === undefined)
  {
    return (
      <AvatarSkeleton
        mr={props.mr}
        ml={props.ml}
      />
    );
  }
  else if(avatar.text === undefined && avatar.icon === undefined && avatar.src === undefined)
  {
    return (
      <AvatarGap
        mr={props.mr}
        ml={props.ml}
      />
    );
  }
  else if(avatar.dotColor)
  {
    return (
      <AvatarDot
        icon={avatar.icon}
        text={avatar.text}
        dotColor={avatar.dotColor}
        src={avatar.src}
        bgcolor={avatar.bgcolor}
        color={avatar.color}
        iconSize={avatar.iconSize}
        iconFontSize={avatar.iconFontSize}
        tooltip={avatar.tooltip}
        isScrolling={props.isScrolling}
        mr={props.mr}
        ml={props.ml}
      />
    );
  }
  else
  {
    return (
      <AvatarAtom
        icon={avatar.icon}
        text={avatar.text}
        src={avatar.src}
        bgcolor={avatar.bgcolor}
        color={avatar.color}
        iconSize={avatar.iconSize}
        iconFontSize={avatar.iconFontSize}
        tooltip={avatar.tooltip}
        isScrolling={props.isScrolling}
        avatarSize={props.avatarSize}
        borderRadius={props.borderRadius}
        mr={props.mr}
        ml={props.ml}
      />
    );
  }
}
