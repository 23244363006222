import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {FormControlLabel} from "@mui/material";
import {Checkbox} from "@mui/material";
import {camelCase} from "lodash";
import {isArray} from "lodash";
import {useState} from "react";
import {useEffect} from "react";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {StudioMapOfOption} from "../../../../api/meta/base/dto/StudioMapOfOption";
import {EnumArrayDefnThemeColorShade} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeColor} from "../../../../api/meta/base/Types";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import {DialogImportOrExportXML} from "../../../dialog/DialogImportOrExportXML";
import {DialogVariableTextSetText} from "../../../dialog/DialogVariableTextSetText";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfText(props: {
  defn: DefnFieldEditable,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnFieldBuilderSetOfText = props.defn;
  const fieldId = getFieldKey(defnFieldBuilderSetOfText);

  const defnTheme = formCtx.getDefnTheme();
  const formReadOnly = formCtx.isReadonly();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const label = defnFieldBuilderSetOfText.name ?? defnFieldBuilderSetOfText.label;

  return (
    <Controller
      name={defnFieldBuilderSetOfText.metaId}
      control={formCtx.control()}
      defaultValue={{
        map: {},
        keys: []
      } as StudioMapOfOption}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfOption | undefined;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfText
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  isFormReadOnly={formReadOnly}
                  required={defnFieldBuilderSetOfText.required}
                  onChange={onChange}
                />
              }
            />
          );
        }

        return (
          <RawSetOfText
            fieldId={fieldId}
            fieldValue={fieldValue}
            isFormReadOnly={formReadOnly}
            required={defnFieldBuilderSetOfText.required}
            onChange={onChange}
          />
        );
      }
      }
    />
  );
}

function RawSetOfText(props: {
  fieldId: MetaIdField,
  fieldValue?: StudioMapOfOption,
  isFormReadOnly?: boolean,
  required?: boolean,
  onChange: (value: StudioMapOfOption) => void
})
{
  const fieldId = props.fieldId;
  const required = props.required;
  const isFormReadOnly = props.isFormReadOnly;

  const pageCtx = usePageCtx();
  const theme = useTheme();
  const cbRefList = {} as IFieldRawListRef<DefnDtoOption>;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const [previousValue, setPreviousValue] = useState<DefnDtoOption[]>(fieldValue);

  const onChange = (fieldValue: DefnDtoOption[], addTextColor: boolean) =>
  {
    props.onChange(convertArrayToMap(fieldValue, addTextColor));
  };

  const doLoad = (selectList: SelectList, fieldValue: DefnDtoOption[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue?.length)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.metaId || value.value;
        if(listId)
        {
          uiItemIds.push(listId);
          uiItemsById[listId] = {
            type: "p",
            primary: {
              text: value.value,
              color: value.color?.value && theme.common?.colorWithShade(value.color?.value,
                value.color?.shade || "s500")
            },
            hideMenu: isFormReadOnly,
            userField: {
              [fieldListItemVal]: value
            }
          } as IListItemAPSA;
        }
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    switch(iconName)
    {
      case "add":
        pageCtx.showDialog(
          <DialogVariableTextSetText
            showColorField={Boolean(props.fieldValue?.addTextColor)}
            isFormReadonly={isFormReadOnly}
            onClickOk={(newValue) => cbRefList.addItem({...newValue})}
          />
        );
        break;
      case "importXml":
        pageCtx.showDialog(<DialogImportOrExportXML
          onClickOk={(xml) =>
          {
            const convertedDto = xmlToDto(xml);
            const updatedDto = updateIdsAndValidation(convertedDto);

            if(!convertedDto.length || !updatedDto.length)
            {
              pageCtx.showErrorToast("Invalid XML");
              return;
            }
            if(updatedDto.length < convertedDto.length)
            {
              pageCtx.showErrorToast("Some values skipped");
            }

            cbRefList.setItems(updatedDto);
          }}
        />);
        break;
      case "exportXml":
        pageCtx.showDialog(
          <DialogImportOrExportXML
            xml={dtoToXml(fieldValue)}
          />);
        break;
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as DefnDtoOption
        : undefined;

      pageCtx.showDialog(
        <DialogVariableTextSetText
          showColorField={Boolean(props.fieldValue?.addTextColor)}
          isFormReadonly={isFormReadOnly}
          values={oldValue}
          onClickOk={(newValue) =>
          {
            cbRefList.updateItem(newValue, key);
            setPreviousValue((prev) =>
              prev.map((item) =>
                item.metaId === newValue.metaId ? newValue : item
              )
            );
          }}
          validationError={validationError}
        />
      );
    }
  };

  useEffect(() =>
  {
    if(!Boolean(props.fieldValue?.addTextColor))
    {
      const value = fieldValue.map(value => ({
        ...value,
        color: undefined
      }));

      onChange(value, false);
    }
    else
    {
      const value = previousValue.map(value => ({
          ...value
        })
      );
      onChange(value, true);
    }

  }, [props.fieldValue?.addTextColor]);

  return (
    <LayoutFlexCol
      width={"100%"}
      alignItems={"start"}
      justifyContent={"start"}
    >
      <FormControlLabel
        sx={{
          px: FORM_FIELD_MEDIUM_PADDING
        }}
        onChange={(_, checked) => onChange(fieldValue, checked)}
        control={<Checkbox
          checked={Boolean(props.fieldValue?.addTextColor)}
          disabled={isFormReadOnly}
        />}
        disabled={isFormReadOnly}
        label={"Add text color"}
      />

      <Typography
        mt={px(theme.common.gapStd)}
        mb={px(theme.common.gapStd)}
        pl={FORM_FIELD_MEDIUM_PADDING}
        pr={FORM_FIELD_MEDIUM_PADDING}
        color={theme.palette.text.secondary}
      >
        Values {required && "*"}
      </Typography>

      <FieldRawList
        fieldId={fieldId}
        fieldValue={fieldValue}
        disableSpotMenu={isFormReadOnly}
        cbRef={cbRefList}
        onChange={(fieldValue) =>
        {
          onChange(fieldValue, Boolean(props.fieldValue?.addTextColor));
        }}
        onClickList={onClickList}
        buttonStrip={
          <LayoutFlexRow
            width={"100%"}
            justifyContent={"flex-end"}
            mt={px(theme.common.gapStd)}
          >
            <RawButtonStrip
              iconButtonList={["importXml", "exportXml", "add"]}
              labelMap={{
                importXml: "IMPORT XML",
                exportXml: "EXPORT XML",
                add: "ADD..."
              } as Record<EnumIconButton, string>}
              onClick={onClickIconBtn}
              iconButtonDisable={isFormReadOnly
                ? ["importXml", "exportXml", "add"]
                : []}
            />
          </LayoutFlexRow>
        }
        doLoad={doLoad}
      />
    </LayoutFlexCol>
  );
}

function convertMapToArray(dto?: StudioMapOfOption): DefnDtoOption[]
{
  if(!dto || !isArray(dto?.keys))
  {
    return [];
  }
  dto.keys = removeDuplicates(dto.keys);
  return dto.keys.map((key) =>
  {
    return dto.map[key];
  });
}

function convertArrayToMap(arr: DefnDtoOption[], addTextColor: boolean): StudioMapOfOption
{
  const keys = [] as MetaIdOption[];
  const map = {} as Record<MetaIdOption, DefnDtoOption>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    addTextColor: Boolean(addTextColor),
    keys: keys,
    map: map
  } as StudioMapOfOption;
}

function dtoToXml(dto: DefnDtoOption[])
{
  let xml = "";
  const nodeName = "node";

  dto.forEach((node) =>
  {
    let attributes = "";
    if(node.metaId)
    {
      attributes += ` key="${node.metaId}"`;
    }
    if(node.color)
    {
      if(node.color.value)
      {
        attributes += ` colorValue="${node.color.value}"`;
      }
      if(node.color.shade)
      {
        attributes += ` colorShade="${node.color.shade}"`;
      }
    }

    xml += `<${nodeName}${attributes}>${node.value}</${nodeName}>\n`;

  });
  return xml;
}

function xmlToDto(xml: string): DefnDtoOption[]
{
  const dto = [] as DefnDtoOption[];

  const parser = new DOMParser();
  const rootXml = `<node key="value">${xml}</node>`.trim(); //  DOMParser requires a root element
  const xmlDoc = parser.parseFromString(rootXml, "application/xhtml+xml");

  const root = xmlDoc.getElementsByTagName("node")[0];
  const nodes = root.children;
  if(nodes && nodes.length > 0)
  {
    for(let i = 0; i < nodes.length; i++)
    {
      const element = nodes[i];
      if(element.nodeName === "node")
      {
        const obj = {} as DefnDtoOption;
        const attrNames = element.getAttributeNames();

        attrNames.forEach((attrName) =>
        {
          if(attrName === "colorValue" || attrName === "colorShade")
          {
            const color = obj?.color || {};
            // @ts-ignore
            color[camelCase(attrName.replace("color", ""))] = element.getAttribute(attrName) || "";
            obj.color = color;
            return;
          }
          else if(attrName === "key")
          {
            obj["metaId"] = element.getAttribute(attrName) || "";
            return;
          }

        });
        obj["value"] = element.textContent || "";
        if(!obj["metaId"])
        {
          obj["metaId"] = obj["value"];
        }

        dto.push(obj);
      }
    }
  }
  return dto;
}

function updateIdsAndValidation(optionList: DefnDtoOption[])
{
  const newOptionList = [] as DefnDtoOption[];

  optionList.forEach((option) =>
  {
    if(typeof option === "object" && Boolean(option.value))
    {
      const textItem = {
        value: option.value,
        metaId: option.metaId
      } as DefnDtoOption;

      if(Boolean(option.color) && typeof option.color === "object")
      {
        const optionColor = option.color;

        if(optionColor.value && EnumArrayDefnThemeColor.includes(optionColor.value))
        {
          textItem.color = {
            ...textItem.color,
            value: optionColor.value
          };
        }
        if(optionColor.shade && EnumArrayDefnThemeColorShade.includes(optionColor.shade))
        {
          textItem.color = {
            ...textItem.color,
            shade: optionColor.shade
          };
        }
      }

      newOptionList.push(textItem);
    }
  });

  return newOptionList;
}

function removeDuplicates(arr: string[])
{
  const map = new Set<string>(arr);
  return Array.from(map);
}
