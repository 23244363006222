import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldParagraph} from "../../../api/meta/base/dto/DefnFieldParagraph";
import {StudioVarParagraph} from "../../../api/meta/base/dto/StudioVarParagraph";
import {stringToDefnDtoText} from "../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {findWordsBetweenDollarBraces} from "../../../base/plus/StringPlus";
import {VAR_TEXT_MAX_LENGTH} from "../../atom/assets/HelperTextStudio";

export function fnVariableBuilderParagraph(fieldValueParagraph: string)
{
  function getVarComp()
  {
    return {
      [fieldValueParagraph]: {
        type: "paragraph",
        metaId: fieldValueParagraph,
        name: fieldValueParagraph,
        label: "Value",
        required: true,
        enableMiniMap: false,
        lineCount: 4,
        flexHeight: true,
        helperTextVar: stringToDefnDtoText(VAR_TEXT_MAX_LENGTH)
      } as DefnFieldParagraph
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarParagraph
  )
  {
    const varValue = entVariable.value;
    if(varValue)
    {
      valueMap[fieldValueParagraph] = fnRawValueToFieldValue("text", varValue.value);
    }
  }

  function defnToDto(
    valueMap: FieldValues
  )
  {
    const value = fnFieldValueToRawValue("text", valueMap[fieldValueParagraph]) as string;

    const val = findWordsBetweenDollarBraces(value);
    const paramSet = val.paramSet;

    return {
      value: {
        value: val.queryStr,
        paramSet: isEmpty(paramSet) ? undefined : paramSet
      }
    } as StudioVarParagraph;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
