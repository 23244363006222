import {InfoOutlined} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import React from "react";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {stripIconSx} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawTooltip from "../../../atom/raw/RawTooltip";
import {useFormCtx} from "../base/CtxForm";

export default function FieldRawLogInfoButton(props: {
  defn: DefnField,
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;

  const fieldId = defn.metaId;
  const cbOnClick = formCtx.getOnClick();

  const sx = stripIconSx(theme.common.color("primaryLight"));

  const onClick = () =>
  {
    cbOnClick && cbOnClick(fieldId, "logViewer");
  };

  return (
    <LayoutFlexRow
      ml={px(gapHalf)}
      mr={`-${gapHalf}px`}
    >
      <RawTooltip
        title={"View logs"}
      >
        <IconButton
          size={"small"}
          onClick={onClick}
        >
          <InfoOutlined sx={sx} />
        </IconButton>
      </RawTooltip>
    </LayoutFlexRow>
  );
}
