import {sliceFakeList} from "./SliceList";

export const {
  listSetGroup,
  listSetGroupsById,
  listSetGroupExpand,
  listSetGroupExpandAll,
  listSetGroupIfExistHeaderLine,
  listSetGroupIfExistHeaderCaptionLine
} = sliceFakeList.actions;
