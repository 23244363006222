import {copyToClipboard} from "../../base/plus/StringPlus";
import {languageId} from "../../routes/terminal/input/editor/neomeTheme";
import {themeName} from "../../routes/terminal/input/editor/neomeTheme";
import DialogAtom from "../atom/dialog/DialogAtom";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawCodeEditor from "../atom/raw/RawCodeEditor";

export default function DialogGetCliCode(props: {
  code?: string
})
{
  const code = props.code || "";

  return (
    <DialogAtom
      title={"CLI code"}
      contentWidth={1000}
      contentHeight={700}
      headerIcons={["copy"]}
      onClickHeaderIcon={(headerIcon) =>
      {
        if(headerIcon === "copy")
        {
          copyToClipboard(code);
        }
      }}
      content={
        <LayoutFlexCol
          width={"100%"}
          flexGrow={1}
        >
          <RawCodeEditor
            value={code}
            dragAndDrop={true}
            enableMiniMap={true}
            enableLineNumbers={true}
            language={languageId}
            editorTheme={themeName}
            defaultLanguage={languageId}
            className={"neomeEditorClass"}
            styled={true}
            readonly={true}
          />
        </LayoutFlexCol>
      }
    />
  );
}

