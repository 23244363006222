import {Box} from "@mui/material";
import {Property} from "csstype";
import {ReactElement} from "react";
import React from "react";
import {Carousel as ReactCarousel} from "react-responsive-carousel";
import {px} from "../../../base/plus/StringPlus";
import CarouselDot from "./CarouselDot";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Carousel(props: {
  swipeAble?: boolean,
  infiniteLoop?: boolean,
  maxWidth?: number,
  height?: number
  children: ReactElement[],

  // ui
  dotOverflow?: boolean,
  dotBgColor?: string,
  indicatorRadius?: number,
  showArrows?: boolean,
  showStatus?: boolean,
  showIndicators?: boolean,
  showThumbs?: boolean,
  padding?: number,
  borderRadius?: Property.BorderRadius,
  borderTopLeftRadius?: Property.BorderTopLeftRadius,
  borderTopRightRadius?: Property.BorderTopRightRadius,
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius,
  borderBottomRightRadius?: Property.BorderBottomRightRadius,
  onChange?: (index: number, item: React.ReactNode) => void
})
{
  const {
    children,
    swipeAble = true,
    infiniteLoop,
    maxWidth,
    dotOverflow,
    dotBgColor,
    padding,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius
  } = props;

  return (
    <Box
      onClick={(event: Event) => event.stopPropagation()}
      //@ts-ignore
      sx={{
        width: props.maxWidth || "100%",
        height: props.height || "100%",
        padding: padding ? px(padding) : undefined,

        "& .carousel-slider": {
          overflow: "visible !important",
          display: "flex",
          justifyContent: "center"
        },
        "& .carousel .slide iframe": {
          width: "100% !important",
          margin: "0 !important"
        },
        "& .slider-wrapper": {
          borderRadius: borderRadius + "!important",
          borderTopLeftRadius: borderTopLeftRadius + "!important",
          borderTopRightRadius: borderTopRightRadius + "!important",
          borderBottomLeftRadius: borderBottomLeftRadius + "!important",
          borderBottomRightRadius: borderBottomRightRadius + "!important"
        },
        "& .thumbs-wrapper": {
          display: "block",
          textAlign: " center"
        },
        "& .thumbs": {
          display: "inlineBlock"
        },
        "& .control-dots": {
          position: "absolute !important",
          zIndex: "999 !important",
          background: dotBgColor,
          borderRadius: "12px !important",
          width: "auto !important",
          overflow: "hidden !important",
          marginRight: "0 !important",
          display: "flex",
          alignItems: "center",
          height: "auto !important",
          bottom: dotOverflow
            ? "-35px !important"
            : "-5px !important",
          maxWidth: "80% !important",
          flexWrap: "nowrap !important",
          padding: "6px !important",
          justifyContent: "flex-start"
        },
        "& .control-dots li": {
          margin: "0 !important",
          marginRight: "8px !important",
          flexShrink: "0 !important"
        },
        "& .control-dots li:last-child": {
          margin: "0 !important"
        }
      }}
    >
      <ReactCarousel
        dynamicHeight={false}
        showThumbs={props.showThumbs || false}
        showArrows={props.showArrows || false}
        showStatus={props.showStatus || false}
        showIndicators={props.showIndicators || true}
        emulateTouch={swipeAble}
        infiniteLoop={infiniteLoop}
        animationHandler={"slide"}
        width={maxWidth || "100%"}
        onChange={props.onChange}
        renderIndicator={(clickHandler, isSelected, index, label) =>
          <CarouselDot
            index={index}
            clickHandler={clickHandler}
            isSelected={isSelected}
            label={label}
          />}
        children={children}
      />
    </Box>
  );
}
