import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {StudioBuildArgBinderHolder} from "../../api/meta/base/dto/StudioBuildArgBinderHolder";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import theme from "../../base/plus/ThemePlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldKey = "key";
const fieldValue = "value";

const contentWidth = theme.common.appMinTabletWidth;

export interface IStudioMapOfText
{
  key: string,
  value: StudioBuildArgBinderHolder
}

export default function DialogMapOfText(props: {
  sourceFormId?: MetaIdForm,
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  values?: IStudioMapOfText,
  onClickOk: (values: IStudioMapOfText) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const values = props.values;
  const sourceFormId = props.sourceFormId;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} key value`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(sourceFormId),
        store: props.formStore,
        formReadonly: props.isFormReadOnly,
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: values ? {...dtoToValue(values)} : undefined
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? "Add more key value"
        : undefined}
      contentWidth={contentWidth}
    />
  );
}

function valueToDto(values: FieldValues): IStudioMapOfText
{
  return {
    key: fnFieldValueToRawValue("text", values[fieldKey]),
    value: {
      argBinder: fnFieldValueToRawValueArgBinder(values[fieldValue])
    }
  } as IStudioMapOfText;
}

function dtoToValue(dto?: IStudioMapOfText)
{
  if(dto)
  {
    return {
      [fieldKey]: fnRawValueToFieldValue("text", dto.key),
      ...fnRawValueToFieldValueArgBinder(fieldValue, dto.value.argBinder)
    };
  }
}

function getDefnForm(sourceFormId?: MetaIdForm)
{
  return createDefaultDefnFormStudio({
    [fieldKey]: {
      type: "text",
      name: fieldKey,
      metaId: fieldKey,
      label: "Key",
      required: true
    } as DefnFieldText,
    [fieldValue]: {
      type: "studioBuildArgBinder",
      name: fieldValue,
      metaId: fieldValue,
      label: "Value",
      formId: sourceFormId,
      derivedFormId: sourceFormId,
      filterKindSet: !sourceFormId ? ["derived", "field"] : undefined
    } as DefnStudioBuildArgBinder,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldKey, fieldValue]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}


