import {isEmpty} from "lodash";
import {nextMetaIdVar} from "../../../../api/meta/base/ApiPlus";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {TypeStudioFilterType} from "../../../../base/plus/StudioPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {setEntUsageFilter} from "../SliceCacheStudioEnt";
import {removeEntVariable} from "../SliceCacheStudioEnt";
import {addEntVariable} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntVariables extends ISrvc
{
  addEntVariable(entId: EntId, variable: StudioVar)
  {
    dispatchEnt(entId, addEntVariable({
      entId: entId,
      sig: {
        ...variable,
        metaId: variable.metaId || nextMetaIdVar()
      }
    }));
  }

  removeEntVariable(entId: EntId, metaIdVar: MetaIdVar)
  {
    dispatchEnt(entId, removeEntVariable({
      entId: entId,
      metaId: metaIdVar
    }));
  }

  copyEntVariable(entId: EntId, metaIdVar: MetaIdVar)
  {
    const rootState = store.getState();
    const varMap = selectCacheStudioEnt(rootState, entId)?.ent?.varMap;
    const variable = varMap?.map[metaIdVar];

    if(!isEmpty(variable))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "variable",
        payload: variable
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntVariable(entId: EntId, metaIdVar: MetaIdVar)
  {
    const rootState = store.getState();
    const varMap = rootState.cache.studio.ent.entMap[entId]?.ent?.varMap;
    if(!varMap)
    {
      return;
    }

    const index = varMap.keys.indexOf(metaIdVar);
    if(index === -1)
    {
      return;
    }

    const entVariable = varMap.map[metaIdVar]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(varMap.map[metaIdVar])))
      : undefined;

    if(entVariable)
    {
      dispatchEnt(entId, addEntVariable({
        entId: entId,
        sig: entVariable,
        insertIndex: index + 1
      }));
    }
  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    store.dispatch(setEntUsageFilter({
      entId: entId,
      variableUsageFilter: filterType
    }));
  }
}
