import {lightBlue} from "@mui/material/colors";
import {cyan} from "@mui/material/colors";
import {amber} from "@mui/material/colors";
import {red} from "@mui/material/colors";
import {green} from "@mui/material/colors";
import {padEnd} from "lodash";

let errorLog = true; //!isProd(); TODO: Enable this before we go live
let infoLog = true; //!isProd(); TODO: Enable this before we go live
let debugLog = true; //!isProd(); TODO: Enable this before we go live
let renderLog = true; //!isProd(); TODO: Enable this before we go live
let traceLog = true; //!isProd(); TODO: Enable this before we go live
let assertLog = true;

export const LOG_RECV_COLOR = green[500];
export const LOG_SEND_COLOR = amber[500];
export const LOG_ERROR_COLOR = red[400];
export const LOG_SUB_COLOR = lightBlue[500];
export const LOG_UN_SUB_COLOR = cyan[500];

export const LOG_RECV = ">>";
export const LOG_SEND = "<<";
export const LOG_WTF = "!!";

export function isErrorLog(): boolean
{
  return errorLog;
}

export function isInfoLog(): boolean
{
  return infoLog;
}

export function isDebugLog(): boolean
{
  return debugLog;
}

export function isRenderLog(): boolean
{
  return renderLog;
}

export function isTraceLog(): boolean
{
  return traceLog;
}

export function setErrorLog(value: boolean): void
{
  errorLog = value;
}

export function setInfoLog(value: boolean): void
{
  infoLog = value;
}

export function setDebugLog(value: boolean): void
{
  debugLog = value;
}

export function setRenderLog(value: boolean): void
{
  renderLog = value;
}

export function setTraceLog(value: boolean): void
{
  traceLog = value;
}

export function logError(cls: string, message?: any)
{
  if(errorLog)
  {
    const logText = `${logNow()} | E | ${logClass(cls)} | ${message ? message : ""}`;
    logQueue.push(`${logText} | color = ${LOG_ERROR_COLOR}`);
    console.log(`%c${logText}`, `color: ${LOG_ERROR_COLOR}`);
  }
}

export function logInfo(cls: string, message?: any, color?: string)
{
  if(infoLog)
  {
    const logText = `${logNow()} | I | ${logClass(cls)} | ${message ? message : ""}`;
    logQueue.push(`${logText} | color = ${color}`);
    console.log(`%c${logText}`, `color: ${color}`);
  }
}

export function logDebug(cls: string, message?: any, color?: string)
{
  if(debugLog)
  {
    const logText = `${logNow()} | D | ${logClass(cls)} | ${message ? message : ""}`;
    logQueue.push(`${logText} | color = ${color}`);
    console.log(`%c${logText}`, `color: ${color}`);
  }
}

export function logTrace(cls: string, message?: any, color?: string)
{
  if(traceLog)
  {
    const logText = `${logNow()} | T | ${logClass(cls)} | ${message ? message : ""}`;
    logQueue.push(`${logText} | color = ${color}`);
    console.log(`%c${logText}`, `color: ${color}`);
  }
}

export function logRender(cls: string, message?: any)
{
  if(renderLog)
  {
    const logText = `%c${logNow()} | R | ${logClass(cls)} | ${message ? message : ""}`;
    logQueue.push(logText);
    console.log(`%c${logText}`);
  }
}

export function logClass(cls: string): string
{
  const max = 25;
  if(cls.length > max)
  {
    return cls.substring(0, max);
  }
  else
  {
    return padEnd(cls, max);
  }
}

export function logAssert(cls: string, value: any, message?: any)
{
  if(assertLog)
  {
    if(!Boolean(value))
    {
      console.log(`${logNow()} | A | ${logClass(cls)} | ${message ? message : ""}`);
      throw new Error();
    }
  }
}

export function logRequired(cls: string, value: any, symbol: string)
{
  logAssert(cls, value, `${symbol} is required`);
}

// use this function only while debugging
export function logNow()
{
  return new Date().toLocaleString();
}

class LogQueue
{
  private logs = [] as string[];
  private timer: NodeJS.Timeout | undefined;
  private canStoreLog = true;

  initLogQueue(saveLog: (logs: string[]) => void, canStoreLog: boolean)
  {
    this.canStoreLog = canStoreLog;
    setErrorLog(canStoreLog);
    setInfoLog(canStoreLog);
    setDebugLog(canStoreLog);
    setRenderLog(canStoreLog);
    setTraceLog(canStoreLog);
    this.logs = [];
    if(!canStoreLog)
    {
      logDebug("AppLog", "LogQueue: clearing logs...");
      clearInterval(this.timer);
      return;
    }
    clearInterval(this.timer);

    this.timer = setInterval(() =>
    {
      if(this.logs.length > 0)
      {
        logDebug("AppLog", "LogQueue: saving logs...");
        saveLog(this.logs);
        this.logs = [];
      }
    }, 5000);
  }

  doSaveLog(saveLog: (logs: string[]) => void)
  {
    saveLog(this.logs);
    this.logs = [];
  }

  clearAllLogs()
  {
    this.logs = [];
  }

  push(log: string)
  {
    if(!this.canStoreLog)
    {
      return;
    }
    this.logs.push(log);
  }

}

export const logQueue = new LogQueue();


