import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {ListLayoutType} from "../../../base/types/list/TypesList";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {IAvatar} from "../../../base/types/TypesGlobal";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import BoxAvatar from "./BoxAvatar";
import {BoxPS} from "./BoxPS";

export function BoxAPS(props: {
  avatar?: IAvatar,
  primary?: ILinePrimary,
  secondary?: ILineSecondary,
  searchWords?: string[],
  onClick?: () => void,
  listLayoutType?: ListLayoutType,
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  isScrolling?: boolean,
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  flexGrow?: StandardCSSProperties["flexGrow"],
})
{
  const theme = useTheme();
  const itemHeight = getListItemHeightAPSA("aps");

  return (
    <LayoutFlexRow
      mt={px(props.mt)}
      mb={px(props.mb)}
      ml={px(props.ml)}
      mr={px(props.mr)}
      flexGrow={props.flexGrow}
      height={px(itemHeight)}
      onClick={props.onClick}
      flexShrink={1}
    >

      <LayoutFlexRow>
        <BoxAvatar
          avatar={props.avatar}
          mr={theme.common.gapStd}
          isScrolling={props.isScrolling}
        />
      </LayoutFlexRow>

      <BoxPS
        flexGrow={1}
        primary={props.primary}
        secondary={props.secondary}
        searchWords={props.searchWords}
        onClickCaption={props.onClickCaption}
      />

    </LayoutFlexRow>
  );
}
