import {useTheme} from "@mui/material";
import React from "react";
import {stripIconSx} from "../../../base/plus/ThemePlus";

export function UnStarIcon(props: {
  disabled?: boolean,
  color?: string
})
{
  const theme = useTheme();
  const sx = stripIconSx(props.disabled ? theme.common.fgcolorIconDisabled : props.color);

  return (
    <svg
      style={sx}
      width="24"
      fill={"currentColor"}
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.34 12.4276L20.21 10.8076C20.88 10.2276 20.52 9.12758 19.64 9.05758L14.81 8.64758L12.92 4.18758C12.58 3.37758 11.42 3.37758 11.08 4.18758L9.69995 7.43758L18.34 12.4276ZM16.7 14.657L20.65 16.937L21.34 15.747L2.65997 4.95703L1.96997 6.14703L6.64997 8.85703L4.35997 9.04703C3.47997 9.11703 3.11997 10.217 3.78997 10.797L7.45997 13.977L6.35997 18.697C6.15997 19.557 7.08997 20.237 7.84997 19.777L12 17.277L16.15 19.787C16.91 20.247 17.84 19.567 17.64 18.707L16.7 14.657Z"
      />
    </svg>
  );
}

export function PromptIcon(props: {
  sx: {width: string, height: string, alignSelf: string, color: string};
})
{

  const sx = props.sx;
  // const sx = stripIconSx(props.disabled ? theme.common.fgcolorIconDisabled : props.color);

  return (
    <svg
      style={sx}
      fill={"currentColor"}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.89375 14.3827C8.42732 14.3827 9.66526 13.1048 9.66526 11.5219V5.80026C9.66526 4.21728 8.42732 2.93945 6.89375 2.93945C5.36019 2.93945 4.12225 4.21728 4.12225 5.80026V11.5219C4.12225 13.1048 5.36019 14.3827 6.89375 14.3827ZM12.3536 11.5219C11.9009 11.5219 11.5222 11.8652 11.4483 12.3324C11.0695 14.5734 9.17563 16.2899 6.89375 16.2899C4.61188 16.2899 2.71802 14.5734 2.33925 12.3324C2.26534 11.8652 1.88657 11.5219 1.43389 11.5219C0.870351 11.5219 0.426911 12.0368 0.510056 12.609C0.962735 15.4698 3.17994 17.7107 5.96992 18.1208V20.1043C5.96992 20.6288 6.38564 21.0579 6.89375 21.0579C7.40186 21.0579 7.81759 20.6288 7.81759 20.1043V18.1208C10.6076 17.7107 12.8248 15.4698 13.2775 12.609C13.3698 12.0368 12.9172 11.5219 12.3536 11.5219Z"
        fill={sx.color}
      />
      <path
        d="M13.5 19.9395C13.5 20.4895 13.9091 20.9395 14.4091 20.9395H22.5909C23.0909 20.9395 23.5 20.4895 23.5 19.9395C23.5 19.3895 23.0909 18.9395 22.5909 18.9395L14.4091 18.9395C13.9091 18.9395 13.5 19.3895 13.5 19.9395Z"
        fill={sx.color}
      />
    </svg>
  );
}
