import {Radio} from "@mui/material";
import {Box} from "@mui/material";
import {FormControl} from "@mui/material";
import {RadioGroup} from "@mui/material";
import {FormControlLabel} from "@mui/material";
import {Property} from "csstype";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueOptionId} from "../../../../api/meta/base/dto/FieldValueOptionId";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {EnumDefnThemeDirection} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {mapToOptions} from "../../../../base/plus/JsPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {useFormCtx} from "../base/CtxForm";

const defaultRadioRowPerLine = 6;
const radioFieldHeight = 42;

export default function FieldRawRadioGroup(props: {
  defn: DefnFieldPickText,
  fieldValue: FieldValueOptionId,
  onChange: (value?: MetaIdOption) => void,
  optionMap?: DefnStudioMapOfDtoOption,
  disabled?: boolean,
  readOnly?: boolean,
  isLed?: boolean
})
{
  const formCtx = useFormCtx();
  const onClick = formCtx.getOnClick();
  const searchText = formCtx.getSearchText();
  const defn = props.defn;
  const onChange = props.onChange;
  const fieldValue = props.fieldValue;
  const fieldId = getFieldKey(defn);

  const optionArray = mapToOptions(defn.optionMap);
  const direction = defn.showAs === "radioButtonHorizontal" ? "horizontal" : "vertical";
  const isLed = props.isLed;
  const fieldBorderColor = formCtx.getFieldBorderColor;

  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  return (
    <FormControl
      fullWidth={true}
      onClick={() =>
      {
        onClick && onClick(defn.metaId, "field", fieldValue);
      }}
    >
      <RadioGroup
        value={fieldValue?.optionId ?? null}
        onChange={(_, value) =>
        {
          if(!props.readOnly && !props.disabled)
          {
            onChange(value);
          }
        }}
      >
        <RadioGroupOptions
          defn={props.defn}
          options={optionArray}
          direction={direction}
          searchWords={searchText ? [searchText] : undefined}
          fieldValue={fieldValue}
          isLed={isLed}
          disabled={props.disabled}
          borderColor={borderColor}
        />
      </RadioGroup>
    </FormControl>
  );

};

function RadioField(props: {
  value: string,
  label: string,
  pt?: number,
  pb?: number,
  pr?: number,
  pl?: number,
  bgColor?: string,
  disabled?: boolean,
  searchWords?: string[],
  disableLabel?: boolean,
  isLed?: boolean
  borderColor?: Property.BorderColor
})
{
  const value = props.value;
  const label = props.label;
  const pt = props.pt;
  const pb = props.pb;
  const pr = props.pr;
  const pl = props.pl;
  const searchWords = props.searchWords;
  const disabled = props.disabled;
  const color = props.bgColor;
  const isLed = props.isLed;
  const borderColor = props.borderColor;

  const style = isLed
    ? {
      "&, &.Mui-checked": {
        color: `${theme.common.colorWithShade(color as TypeTextColor, "s500")}`,
        background: theme.common.lightColor(color as TypeTextColor),
        border: "none",
        width: "10px",
        marginRight: "10px",
        height: "10px"
      },
      "&.Mui-checked": {
        background: `${theme.common.colorWithShade(color as TypeTextColor, "s500")}`
      },
      "&:hover": {
        background: `${theme.common.colorWithShade(color as TypeTextColor, "s500")}`
      }
    }
    : {
      border: "none",
      ...borderColor && {
        "&, &.Mui-checked": {
          color: borderColor
        }
      }
    };

  return (
    <FormControlLabel
      key={value}
      value={value}
      sx={{
        margin: 0,
        pt: `${pt}px`,
        pb: `${pb}px`,
        pr: `${pr}px`,
        pl: `${pl}px`
      }}
      control={<Radio
        disabled={disabled}
        sx={style}
      />}
      label={<RawHighlighter
        searchWords={searchWords}
        value={label}
        variant={"body1"}
        color={((isLed && props.disableLabel) || disabled) ? "grey" : "black"}
      />}
    />
  );
}

function RadioGroupOptions(props: {
  defn: DefnFieldPickText,
  options?: DefnDtoOption[],
  disabled?: boolean,
  direction?: EnumDefnThemeDirection,
  searchWords?: string[],
  fieldValue?: FieldValueOptionId
  isLed?: boolean
  maxHeight?: string
  borderColor?: Property.BorderColor
})
{
  const defn = props.defn;
  const isLed = props.isLed;
  const maxHeight = props.maxHeight;
  const disabled = props.disabled;
  const direction = props.direction;

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: direction === "vertical"
          ? maxHeight ?? px(radioFieldHeight * (defn.pageSize || defaultRadioRowPerLine))
          : "auto",
        display: "flex",
        flexDirection: direction === "horizontal"
          ? "row"
          : "column",
        flexWrap: "wrap",
        paddingLeft: "0px"
      }}
    >
      {
        props.options?.map((option, index) =>
        {
          if(option.metaId)
          {
            if(option.value)
            {
              return <RadioField
                key={option.metaId}
                value={option.metaId}
                label={option.value ?? ""}
                disabled={disabled || option.disabled}
                searchWords={props.searchWords}
                bgColor={option.color?.value}
                disableLabel={Boolean(props.fieldValue?.optionId !== option.metaId)}
                isLed={isLed}
                borderColor={props.borderColor}
              />;
            }
            return <LayoutGap
              key={option.metaId}
              height={px(radioFieldHeight)}
            />;
          }
          return <React.Fragment key={index} />;
        })
      }
    </Box>
  );
}

