import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdLayoutGrid} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfLayoutSpreadsheet} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutSpreadsheet";
import {StudioDtoLayoutCalendar} from "../../../../api/meta/base/dto/StudioDtoLayoutCalendar";
import {StudioDtoLayoutCard} from "../../../../api/meta/base/dto/StudioDtoLayoutCard";
import {StudioDtoLayoutGrid} from "../../../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioDtoLayoutKanban} from "../../../../api/meta/base/dto/StudioDtoLayoutKanban";
import {StudioDtoLayoutLocmap} from "../../../../api/meta/base/dto/StudioDtoLayoutLocmap";
import {StudioDtoLayoutTable} from "../../../../api/meta/base/dto/StudioDtoLayoutTable";
import {StudioDtoLayoutXYChart} from "../../../../api/meta/base/dto/StudioDtoLayoutXYChart";
import {StudioMapOfLayoutGrid} from "../../../../api/meta/base/dto/StudioMapOfLayoutGrid";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {EnumDefnLayoutGridKind} from "../../../../api/meta/base/Types";
import {MetaIdFuncArg} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {IFormFieldError} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewLayoutMap from "../../../dialog/DialogNewLayoutMap";
import DialogNewLayoutSpreadsheet from "../../../dialog/DialogNewLayoutSpreadsheet";
import DialogNewLayoutSpreadsheetCalendar from "../../../dialog/DialogNewLayoutSpreadsheetCalendar";
import DialogNewLayoutSpreadsheetCard from "../../../dialog/DialogNewLayoutSpreadsheetCard";
import DialogNewLayoutSpreadsheetKanban from "../../../dialog/DialogNewLayoutSpreadsheetKanban";
import DialogNewLayoutSpreadsheetList from "../../../dialog/DialogNewLayoutSpreadsheetList";
import DialogNewLayoutSpreadsheetTable from "../../../dialog/DialogNewLayoutSpreadsheetTable";
import DialogNewLayoutXYChart from "../../../dialog/DialogNewLayoutXYChart";
import {getLayoutLabel} from "../../builder/base/FieldBuilderPlus";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {fieldListItemVal} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfLayoutSpreadsheet(props: {
  defn: DefnStudioMapOfLayoutSpreadsheet,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();
  const defnTheme = formCtx.getDefnTheme();

  const defnFieldBuilderBrowserLayout = props.defn;
  const fieldId = getFieldKey(defnFieldBuilderBrowserLayout);
  const spreadsheetId = defnFieldBuilderBrowserLayout.spreadsheetId;

  const formId = spreadsheetId
    ? formStore?.spreadsheetMap?.map[spreadsheetId]?.formId
    : undefined;
  const isLastField = false;
  const label = defnFieldBuilderBrowserLayout.label;

  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={defnFieldBuilderBrowserLayout.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfLayoutGrid;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfSpreadSheetBrLayout
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  spreadsheetId={spreadsheetId}
                  onChange={onChange}
                  isLastField={isLastField}
                  formStore={formStore}
                  isFormReadOnly={formReadOnly}
                  metaIdForm={formId}
                />
              }
            />
          );
        }

        return (
          <RawSetOfSpreadSheetBrLayout
            fieldId={fieldId}
            fieldValue={fieldValue}
            spreadsheetId={spreadsheetId}
            onChange={onChange}
            isLastField={isLastField}
            formStore={formStore}
            isFormReadOnly={formReadOnly}
            metaIdForm={formId}
          />
        );
      }
      }
    />
  );
}

function RawSetOfSpreadSheetBrLayout(props: {
  fieldId: MetaIdField,
  fieldValue: StudioMapOfLayoutGrid,
  spreadsheetId: MetaIdSpreadsheet;
  metaIdForm?: MetaIdForm,
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  isLastField?: boolean,
  onChange: (value: StudioMapOfLayoutGrid) => void
})
{
  const {
    formStore,
    fieldId,
    metaIdForm,
    isLastField,
    isFormReadOnly,
    spreadsheetId
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<StudioDtoLayoutGrid>;
  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);

  const onChange = (fieldValue: StudioDtoLayoutGrid[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = (selectList: SelectList, fieldValue: StudioDtoLayoutGrid[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.metaId;
        uiItemIds.push(listId);
        uiItemsById[listId] = {
          type: "mpsl",
          primary: {
            text: value.name,
            caption: {
              text: getLayoutLabel(value.kind),
              ignoreSelection: true
            }
          },
          secondaryList: [
            {
              text: value.description || "No description"
            }
          ],
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemMPSL;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add" && formStore)
    {
      pageCtx.showDialog(
        <DialogNewLayoutSpreadsheet
          onClickOk={(spreadsheetLayout) =>
          {
            pageCtx.showDialog(
              <LayoutSpreadsheetDialog
                spreadsheetLayout={spreadsheetLayout}
                formStore={formStore}
                formId={metaIdForm}
                spreadsheetId={spreadsheetId}
                cbRefList={cbRefList}
                isFormReadOnly={isFormReadOnly}
              />
            );
          }}
        />
      );
    }
  };

  const onClickList = useCallback((
    key: MetaIdField,
    action: FormClickVariant,
    value?: any,
    menuAnchor?: Element,
    menuProps?: IMenuProps,
    isFirstItem?: boolean,
    isLastItem?: boolean,
    validationError?: IFormFieldError[]
  ) =>
  {
    if(action === "spotMenu" && menuProps)
    {
      menuProps = {
        ["Remove"]: menuProps["Remove"]
      } as IMenuProps;

      menuAnchor && pageCtx.showMenu(menuAnchor, menuProps);
    }
    else if(action === "listItem" && formStore)
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioDtoLayoutGrid
        : undefined;

      if(oldValue)
      {
        pageCtx.showDialog(
          <LayoutSpreadsheetDialog
            spreadsheetLayout={oldValue.kind}
            formStore={formStore}
            formId={metaIdForm}
            spreadsheetId={spreadsheetId}
            cbRefList={cbRefList}
            updateKey={key}
            validationError={validationError}
            value={oldValue}
            isFormReadOnly={isFormReadOnly}
          />
        );
      }
    }
  }, [formStore]);

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function LayoutSpreadsheetDialog(props: {
  spreadsheetLayout: EnumDefnLayoutGridKind,
  formStore: FormStore,
  formId?: MetaIdForm,
  spreadsheetId: MetaIdSpreadsheet,
  validationError?: IFormFieldError[]
  isFormReadOnly?: boolean,
  value?: StudioDtoLayoutGrid,
  updateKey?: MetaIdField,
  cbRefList: IFieldRawListRef<StudioDtoLayoutGrid>
})
{
  const formStore = props.formStore;
  const metaIdForm = props.formId;
  const spreadsheetId = props.spreadsheetId;
  const cbRefList = props.cbRefList;
  const oldValue = props.value;
  const key = props.updateKey;
  const isFormReadOnly = props.isFormReadOnly;
  const spreadsheetLayout = props.spreadsheetLayout;
  const validationError = props.validationError;

  switch(spreadsheetLayout)
  {
    case "card":
      return <DialogNewLayoutSpreadsheetCard
        values={oldValue as StudioDtoLayoutCard}
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        spreadsheetId={spreadsheetId}
        metaIdForm={metaIdForm}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
        validationError={validationError}
      />;

    case "list":
      return <DialogNewLayoutSpreadsheetList
        values={oldValue as StudioDtoLayoutCard}
        formStore={formStore}
        spreadsheetId={spreadsheetId}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={metaIdForm}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
        validationError={validationError}
      />;

    case "table":
      return <DialogNewLayoutSpreadsheetTable
        values={oldValue as StudioDtoLayoutTable}
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={metaIdForm}
        spreadsheetId={spreadsheetId}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
        validationError={validationError}
      />;

    case "map":
      return <DialogNewLayoutMap
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={metaIdForm}
        spreadsheetId={spreadsheetId}
        values={oldValue as StudioDtoLayoutLocmap}
        validationError={props.validationError}
        mapType={"spreadsheet"}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    case "xyChartBarGraph":
    case "xyChartLineChart":
    case "xyChartPieChart":
    case "xyChartDoughnut":
    case "xyChartScatterPlot":
      return <DialogNewLayoutXYChart
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        formId={metaIdForm}
        spreadsheetId={spreadsheetId}
        values={oldValue as StudioDtoLayoutXYChart}
        validationError={props.validationError}
        xyChartType={"spreadsheet"}
        defaultGridLayout={spreadsheetLayout}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            :
            cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    case "kanban":
      return <DialogNewLayoutSpreadsheetKanban
        values={oldValue as StudioDtoLayoutKanban}
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={metaIdForm}
        spreadsheetId={spreadsheetId}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
        validationError={validationError}
      />;

    case "calendar":
      return <DialogNewLayoutSpreadsheetCalendar
        values={oldValue as StudioDtoLayoutCalendar}
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={metaIdForm}
        spreadsheetId={spreadsheetId}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
        validationError={validationError}
      />;
  }
}

function convertMapToArray(map?: StudioMapOfLayoutGrid): StudioDtoLayoutGrid[]
{
  if(!map?.keys || !map?.map)
  {
    return [];
  }
  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoLayoutGrid[]): StudioMapOfLayoutGrid
{
  const keys = [] as MetaIdFuncArg[];
  const map = {} as Record<MetaIdFuncArg, StudioDtoLayoutGrid>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfLayoutGrid;
}
