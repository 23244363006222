import {useTheme} from "@mui/material";
import {IconButton} from "@mui/material";
import {CircularProgress} from "@mui/material";
import React from "react";
import {px} from "../../../../base/plus/StringPlus";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import {IAvatar} from "../../../../base/types/TypesGlobal";
import BoxAvatar from "../../box/BoxAvatar";
import IconStrip from "../../icon/IconStrip";
import LayoutFlexCol from "../../layout/LayoutFlexCol";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import {LinePrimary} from "../../line/LinePrimary";

export default function BubbleRawDoc(props: {
  fileName?: string,
  isLoading?: boolean,
  searchWords?: string[],
  itemHeight?: number,
  onClickDownload?: CbMenuAnchor,

})
{
  const {
    fileName,
    isLoading,
    searchWords,
    itemHeight,
    onClickDownload
  } = props;

  const avatar: IAvatar = {
    icon: "document",
    bgcolor: "transparent",
    iconFontSize: "large"
  };

  const theme = useTheme();
  const innerGap = theme.common.vertInner / 2;
  const gapHalf = theme.common.gapHalf;

  return (
    <LayoutFlexRow
      width={"100%"}
      height={px(itemHeight)}
      pl={px(gapHalf)}
      pr={px(gapHalf)}
    >
      <BoxAvatar avatar={avatar} mr={gapHalf} />

      <LayoutFlexCol
        flexGrow={1}
        height={px(itemHeight)}
      >
        <LinePrimary
          primary={{
            text: fileName
          }}
          searchWords={searchWords}
        />
      </LayoutFlexCol>

      {isLoading ?
        <CircularProgress sx={{p: px(gapHalf)}} /> :
        <IconButton
          onClick={(e: React.MouseEvent<HTMLElement>) =>
          {
            e.stopPropagation();
            onClickDownload && onClickDownload(e.currentTarget);
          }}
        >
          <IconStrip value={"download"} />
        </IconButton>
      }
    </LayoutFlexRow>
  );
}
