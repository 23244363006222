import {ICellRendererParams} from "ag-grid-community";
import {useMemo} from "react";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import RawMarkDown from "../../../atom/raw/RawMarkDown";
import {getResolvedCellStyle} from "../DataGridPlus";
import {GRID_FONT_VARIANT} from "../DataGridPlus";

export default function GridCellInfo(props: ICellRendererParams<IDataGridCell>)
{
  const searchWords = (props.context as AgGridContext).searchWords;

  const cellValue = props.valueFormatted
    ? props.valueFormatted
    : props.value;
  const colId = props.colDef?.colId;
  const isHyperLink = colId ? (props.context as AgGridContext).hyperLinkColIdMap?.[colId] : undefined;

  const style = useMemo(() => getResolvedCellStyle(
    colId,
    (props.context as AgGridContext).layout?.styleMap,
    (props.context as AgGridContext).colIdToStyleIdMap,
    (props.context as AgGridContext).defnForm,
    props.data as IDataGridCell
  ), [colId, props.context, props.data]);

  return <RawMarkDown
    value={typeof cellValue === "string" ? cellValue : `${cellValue ?? ""}`}
    width={"100%"}
    searchWords={searchWords}
    includeColor={true}
    includeNewLine={true}

    //style
    variant={style?.variant || GRID_FONT_VARIANT}
    color={style?.color || "textSecondary"}
    textDecoration={isHyperLink ? "underline" : style?.textDecoration}
    bold={style?.isBold}
    italic={style?.isItalic}
  />;
}

