import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnLayoutGrid} from "../../../../api/meta/base/dto/DefnLayoutGrid";
import {EntUserId} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {TypeUserField} from "../../../../base/types/TypesGlobal";
import {selectCallerEnt} from "../../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {selectCallerEntForm} from "../../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {RootState} from "../../../../Store";
import {Srvc} from "../../../Srvc";
import SrvcSsEditorCalendar from "./SrvcSsEditorCalendar";
import SrvcSsEditorKanban from "./SrvcSsEditorKanban";
import SrvcSsEditorList from "./SrvcSsEditorList";
import SrvcSsEditorMap from "./SrvcSsEditorMap";
import SrvcSsEditorTable from "./SrvcSsEditorTable";

export interface TypeUserFieldSsEditorItem extends TypeUserField
{
  sigSpreadsheetRow?: SigSpreadsheetRow,
  expired?: boolean,
}

export interface TypeUserFieldSsEditor extends TypeUserField
{
  entId: EntId,
  spreadsheetId: MetaIdSpreadsheet,
  defnForm?: DefnForm,
  spreadsheetLayout?: DefnLayoutGrid,
}

export default class SrvcSsEditor extends ISrvc
{
  public readonly list = new SrvcSsEditorList(state => state.app.spreadsheet.ssEditorListOrCard);
  public readonly kanban = new SrvcSsEditorKanban(state => state.app.spreadsheet.ssEditorKanban);
  public readonly table = new SrvcSsEditorTable();
  public readonly calendar = new SrvcSsEditorCalendar(state => state.app.spreadsheet.ssEditorCalendar,
    (state: RootState) => state.home.aside.calendarItems
  );
  public readonly map = new SrvcSsEditorMap();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.list,
      this.kanban,
      this.table,
      this.calendar,
      this.map
    );
  }

  initSsEditorState(spreadsheetId: MetaIdSpreadsheet): void
  {
    Srvc.cache.app.spreadsheet.ssEditor.initSsEditorState(spreadsheetId);
  }

  clearSsEditorState(spreadsheetId: MetaIdSpreadsheet): void
  {
    Srvc.cache.app.spreadsheet.ssEditor.clearSsState(spreadsheetId);
  }

  hideMenu(
    rootState: RootState,
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    senderId?: EntUserId,
    expired?: boolean): boolean
  {
    const formId = selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.spreadsheetFormId;
    const form = formId ? selectCallerEntForm(rootState, entId, formId) : undefined;
    if(expired)
    {
      return true;
    }
    else if(Boolean(form?.actionPermissionMap?.keys.length))
    {
      return false;
    }
    else if(Srvc.app.spreadsheet.canForward(rootState,
      entId,
      spreadsheetId,
      senderId
    ))
    {
      return false;
    }
    else if(Srvc.app.spreadsheet.canUpdate(rootState,
      entId,
      spreadsheetId,
      senderId
    ))
    {
      return false;
    }
    else if(Srvc.app.spreadsheet.canRemove(rootState,
      entId,
      spreadsheetId,
      senderId
    ))
    {
      return false;
    }
    return true;
  }
}
