import {isEmpty} from "lodash";
import {cloneDeep} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {DtoEntRole} from "../../../api/ent/base/dto/DtoEntRole";
import {DtoPickerEntUser} from "../../../api/ent/base/dto/DtoPickerEntUser";
import {SigReportOutputFormGet} from "../../../api/ent/ent/sig/SigReportOutputFormGet";
import {MsgEntLogNumberFieldDataGet} from "../../../api/ent/entAside/msg/MsgEntLogNumberFieldDataGet";
import {MsgEntUserPickerCandidateListGet} from "../../../api/ent/entAside/msg/MsgEntUserPickerCandidateListGet";
import {MsgPluginApiOutput} from "../../../api/ent/entAside/msg/MsgPluginApiOutput";
import {RpcEntAside} from "../../../api/ent/entAside/RpcEntAside";
import {SigEntLogNumberFieldDataGet} from "../../../api/ent/entAside/sig/SigEntLogNumberFieldDataGet";
import {SigEntUserPickerCandidateListGet} from "../../../api/ent/entAside/sig/SigEntUserPickerCandidateListGet";
import {SigPluginApiOutput} from "../../../api/ent/entAside/sig/SigPluginApiOutput";
import {SigReportFieldData} from "../../../api/ent/entAside/sig/SigReportFieldData";
import {WsocEntAside} from "../../../api/ent/entAside/WsocEntAside";
import {MsgEntVarSeqIncr} from "../../../api/ent/entMain/msg/MsgEntVarSeqIncr";
import {MsgFormMappingResultGet} from "../../../api/ent/entMain/msg/MsgFormMappingResultGet";
import {MsgFormResultCalc} from "../../../api/ent/entMain/msg/MsgFormResultCalc";
import {MsgReportOutputFormGet} from "../../../api/ent/entMain/msg/MsgReportOutputFormGet";
import {MsgSpreadsheetPartitionSend} from "../../../api/ent/entMain/msg/MsgSpreadsheetPartitionSend";
import {MsgSpreadsheetRowMarkRead} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowMarkRead";
import {MsgSpreadsheetRowSend} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowSend";
import {MsgSpreadsheetRowUpdate} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowUpdate";
import {RpcEntMain} from "../../../api/ent/entMain/RpcEntMain";
import {SigFormMappingResultGet} from "../../../api/ent/entMain/sig/SigFormMappingResultGet";
import {SigSpreadsheetRowSend} from "../../../api/ent/entMain/sig/SigSpreadsheetRowSend";
import {WsocEntMain} from "../../../api/ent/entMain/WsocEntMain";
import {DtoMessagePayload} from "../../../api/home/base/dto/DtoMessagePayload";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {MsgMessageSend} from "../../../api/home/main/msg/MsgMessageSend";
import {RpcMain} from "../../../api/home/main/RpcMain";
import {nextMessageId} from "../../../api/meta/base/ApiPlus";
import {nextRowId} from "../../../api/meta/base/ApiPlus";
import {nextTransactionId} from "../../../api/meta/base/ApiPlus";
import {isEntUserId} from "../../../api/meta/base/ApiPlus";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldCamera} from "../../../api/meta/base/dto/DefnFieldCamera";
import {DefnFieldPickReportRow} from "../../../api/meta/base/dto/DefnFieldPickReportRow";
import {DefnFieldPickRole} from "../../../api/meta/base/dto/DefnFieldPickRole";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldPickTree} from "../../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldPickUser} from "../../../api/meta/base/dto/DefnFieldPickUser";
import {DefnFieldScanCode} from "../../../api/meta/base/dto/DefnFieldScanCode";
import {DefnFieldSetOfRole} from "../../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSetOfText} from "../../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSetOfUser} from "../../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnFieldSignature} from "../../../api/meta/base/dto/DefnFieldSignature";
import {DefnFieldSwitch} from "../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldUserId} from "../../../api/meta/base/dto/DefnFieldUserId";
import {DefnFieldVoice} from "../../../api/meta/base/dto/DefnFieldVoice";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../api/meta/base/dto/DefnGrid";
import {DefnStudioMapOfDtoOption} from "../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldDtoGridRow} from "../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldSetOfEntUserId} from "../../../api/meta/base/dto/FieldSetOfEntUserId";
import {FieldSetOfRole} from "../../../api/meta/base/dto/FieldSetOfRole";
import {FieldValueAudio} from "../../../api/meta/base/dto/FieldValueAudio";
import {FieldValueCamera} from "../../../api/meta/base/dto/FieldValueCamera";
import {FieldValueDocument} from "../../../api/meta/base/dto/FieldValueDocument";
import {FieldValueEntUserId} from "../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueGrid} from "../../../api/meta/base/dto/FieldValueGrid";
import {FieldValueImage} from "../../../api/meta/base/dto/FieldValueImage";
import {FieldValueRole} from "../../../api/meta/base/dto/FieldValueRole";
import {FieldValueScanCode} from "../../../api/meta/base/dto/FieldValueScanCode";
import {FieldValueSignature} from "../../../api/meta/base/dto/FieldValueSignature";
import {FieldValueSwitch} from "../../../api/meta/base/dto/FieldValueSwitch";
import {FieldValueVideo} from "../../../api/meta/base/dto/FieldValueVideo";
import {FieldValueVoice} from "../../../api/meta/base/dto/FieldValueVoice";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {MetaIdComposite} from "../../../api/meta/base/Types";
import {MetaIdReport} from "../../../api/meta/base/Types";
import {EnumDefnFields} from "../../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../api/meta/base/Types";
import {MetaIdComp} from "../../../api/meta/base/Types";
import {MediaId} from "../../../api/meta/base/Types";
import {EntUserId} from "../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {MetaIdAction} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {MetaIdRole} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {EnvError} from "../../../api/nucleus/base/dto/EnvError";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import ISrvc from "../../../base/ISrvc";
import {SelectCalendar} from "../../../base/plus/CalendarPlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {getValidFormFieldValueMap} from "../../../base/plus/FieldValuePlus";
import {loopDefnForm} from "../../../base/plus/FormPlus";
import {SelectKanban} from "../../../base/plus/KanbanPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {MediaStore} from "../../../base/plus/MediaPlus";
import {uploadMedia} from "../../../base/plus/MediaPlus";
import {textUser} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {listSetPickType} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {TypeComboIdUser} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {TypeFieldOptionResponse} from "../../../base/types/TypesForm";
import {CbProgress} from "../../../base/types/TypesGlobal";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {ICallerEnt} from "../../../cache/app/callerEnt/TypesCacheCallerEnt";
import {removeUnnecessaryFieldValues} from "../../../nucleus/form/viewer/base/FormViewerPlus";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcFormViewer extends ISrvc
{
  //region APIs

  rpcEntUserPickerCandidateListGet(
    entId: EntId,
    sourceFormId?: MetaIdForm,
    setOfUserVarId?: MetaIdVar,
    roleIdSet?: MetaIdRole[],
    formValueRaw?: FormValueRaw,
    cbSuccess?: (sig?: SigEntUserPickerCandidateListGet) => void
  )
  {
    const msg = {
      roleIdSet,
      sourceFormId,
      setOfUserVarId,
      formValueRaw
    } as MsgEntUserPickerCandidateListGet;

    RpcEntAside.entUserPickerCandidateListGet(entId, msg,
      envSig =>
      {
        Srvc.app.toast.showErrorToast(envSig);
        cbSuccess && cbSuccess(envSig.sig);
      }
    );
  }

  rpcReportOutputFormGet(
    entId: EntId,
    reportId: MetaIdReport,
    actionId: MetaIdAction,
    inputFormValue?: FormValueRaw,
    inputFormCompositeId?: MetaIdComposite,
    inputFormGridRowId?: RowId,
    cbResult?: (sig: SigReportOutputFormGet) => void,
    cbError?: (sig: EnvError) => void)
  {
    const msg = {
      reportId: reportId,
      actionId: actionId,
      inputFormValue: inputFormValue,
      inputFormCompositeId: inputFormCompositeId,
      inputFormGridRowId: inputFormGridRowId
    } as MsgReportOutputFormGet;

    RpcEntMain.entReportOutputFormGet(
      entId,
      msg,
      envSig =>
      {
        if(Srvc.app.toast.showErrorToast(envSig))
        {
          cbError && cbError(envSig.error as EnvError);
        }
        else
        {
          cbResult && cbResult(envSig.sig as SigReportOutputFormGet);
        }
      }
    );
  }

  //endregion

  //region Wsoc

  wsocEntLogNumberFieldDataGet(params: {
    entId: EntId,
    fieldId: MetaIdField;
    rowId: RowId;
    spreadsheetId: MetaIdSpreadsheet;
    gridId?: MetaIdGrid;
    gridRowId?: RowId;
    cbSuccess?: (sig: SigEntLogNumberFieldDataGet) => void,
    cbError?: CbProgress,
  })
  {
    const {
      entId,
      fieldId,
      rowId,
      spreadsheetId,
      gridId,
      gridRowId,
      cbSuccess,
      cbError
    } = params;

    const msg = {
      fieldId: fieldId,
      rowId: rowId,
      spreadsheetId: spreadsheetId,
      gridId: gridId,
      gridRowId: gridRowId
    } as MsgEntLogNumberFieldDataGet;

    WsocEntAside.entLogNumberFieldDataGet(entId, msg, (envSig) =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        cbError && cbError(getErrorMessage(envSig.error));
        return;
      }

      if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  rpcSpreadsheetRowSend(
    entId: EntId,
    formValueRaw: FormValueRaw,
    actionId?: MetaIdAction,
    mappingVarId?: MetaIdVar,
    targetSpreadsheetId?: MetaIdSpreadsheet,
    cbSuccess?: (sig: SigSpreadsheetRowSend) => void,
    cbError?: () => void
  )
  {
    const msg: MsgSpreadsheetRowSend = {
      actionId: actionId,
      formValueRaw: formValueRaw,
      mappingVarId: mappingVarId,
      targetSpreadsheetId: targetSpreadsheetId
    };

    RpcEntMain.spreadsheetRowSend(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && envSig.sig && cbSuccess(envSig.sig);
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  wsocSpreadsheetPartitionSend(
    entId: EntId,
    chatId: string,
    metaIdAction: MetaIdAction,
    metaIdForm: MetaIdForm,
    cbSuccess?: CbSuccess,
    cbError?: () => void
  )
  {
    const msg: MsgSpreadsheetPartitionSend = {
      toGroupId: chatId,
      actionId: metaIdAction,
      formId: metaIdForm
    };

    WsocEntMain.spreadsheetPartitionSend(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  rpcMessageSend(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayload,
    cbSuccess?: CbSuccess,
    onError?: () => void)
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      payload: payload
    };

    RpcMain.messageSend(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
      else
      {
        onError && onError();
      }
    });
  }

  rpcSpreadsheetRowUpdate(
    entId: EntId,
    metaIdForm: MetaIdForm,
    metaIdSpreadsheet: MetaIdSpreadsheet,
    formValueRaw: FormValueRaw,
    cbSuccess?: CbSuccess,
    cbError?: () => void
  )
  {
    const msg: MsgSpreadsheetRowUpdate = {
      spreadsheetId: metaIdSpreadsheet,
      transactionId: nextTransactionId(),
      formValueRaw: formValueRaw
    };

    RpcEntMain.spreadsheetRowUpdate(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  rpcSpreadsheetRowMarkRead(
    entId: EntId,
    metaIdSpreadsheet: MetaIdSpreadsheet,
    rowId: RowId,
    formValue: FormValue,
    version?: string,
    cbSuccess?: CbSuccess)
  {
    const msg: MsgSpreadsheetRowMarkRead = {
      spreadsheetId: metaIdSpreadsheet,
      rowId: rowId,
      formValueRaw: formValue,
      version: version
    };

    RpcEntMain.spreadsheetRowMarkRead(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcEntVarSeqIncr(
    entId: EntId,
    varId: MetaIdVar,
    step?: number,
    cbSuccess?: (sequence?: number[]) => void)
  {
    const msg = {
      seqVarId: varId,
      step: step
    } as MsgEntVarSeqIncr;

    RpcEntMain.entVarSeqIncr(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        const start = envSig.sig?.startSeq;
        const end = envSig.sig?.endSeq;
        if(start && end)
        {
          const result: number[] = Array.from({length: end - start + 1}, (_, index) => start + index);
          cbSuccess && cbSuccess(result);
        }
      }
    });
  }

  rpcPluginApiOutputGet(
    entId: EntId,
    fieldId: MetaIdField,
    formId: MetaIdForm,
    formValueRaw?: FormValueRaw,
    cbEnvSig?: (envSig: EnvSignal<SigPluginApiOutput>) => void)
  {
    const msg = {
      fieldId: fieldId,
      formId: formId,
      formValueRaw: formValueRaw
    } as MsgPluginApiOutput;

    RpcEntAside.pluginApiOutputGet(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
      cbEnvSig && cbEnvSig(envSig);
    });
  }

  wsocEntFormMappingResultGet(
    entId: EntId,
    mappingVarId: MetaIdVar,
    inputFormId: MetaIdForm,
    inputFormValue: FormValue,
    outputFormId: MetaIdForm,
    inputFormGridRowId?: RowId,
    outputFormGridRowId?: RowId,
    outputFormValueRaw?: FormValueRaw,
    cbEnvSig?: (envSig: EnvSignal<SigFormMappingResultGet>) => void
  )
  {
    const msg: MsgFormMappingResultGet = {
      inputFormGridRowId: inputFormGridRowId,
      inputFormId: inputFormId,
      inputFormValueRaw: inputFormValue,
      mappingVarId: mappingVarId,
      outputFormGridRowId: outputFormGridRowId,
      outputFormId: outputFormId,
      outputFormValueRaw: outputFormValueRaw
    };

    WsocEntMain.entFormMappingResultGet(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbEnvSig && cbEnvSig(envSig);
      }
    });
  }

  //endregion

  //region Public

  fnInsertRefFieldInValueMap(
    valueMap: FieldValues,
    fieldDtoGridRow: FieldDtoGridRow,
    flipCopyFieldMap: Record<MetaIdField, MetaIdField>)
  {
    const itemValueMap = fieldDtoGridRow.valueMap as FieldValues;

    Object.entries(flipCopyFieldMap).forEach(([key, value]) =>
    {
      valueMap[value] = itemValueMap[key] || null;
    });
  }

  fnInsertRefSystemFieldInValueMap(
    valueMap: FieldValues,
    fieldDtoGridRow: FieldDtoGridRow,
    flipCopyFieldMap: Record<MetaIdField, MetaIdField>)
  {
    EnumArrayDefnFields.forEach((systemFieldId) =>
    {
      const _systemFieldId = systemFieldId as EnumDefnFields;
      if(flipCopyFieldMap[systemFieldId])
      {
        valueMap[flipCopyFieldMap[systemFieldId]] =
          this.fnSystemFieldToFieldValue(_systemFieldId, fieldDtoGridRow as FormValueRaw);
      }
    });
  }

  fnSystemFieldToFieldValue(systemFieldId: EnumDefnFields, formValueRaw: FormValueRaw)
  {
    switch(systemFieldId)
    {
      case "$CreatedBy":
        return fnRawValueToFieldValue("pickUser", formValueRaw?.createdBy);
      case "$CreatedOn":
        return fnRawValueToFieldValue("dateTime", formValueRaw?.createdOn);
      case "$RowId":
        return formValueRaw?.rowId;
      case "$RowOrder":
        return fnRawValueToFieldValue("number", formValueRaw?.rowOrder);
      case "$UpdatedBy":
        return fnRawValueToFieldValue("pickUser", formValueRaw?.updatedBy);
      case "$UpdatedOn":
        return fnRawValueToFieldValue("dateTime", formValueRaw?.updatedOn);
    }
  }

  calculateFormValues(
    entId: EntId,
    formValueRaw: FormValueRaw,
    defnForm: DefnForm,
    callerEntUserId: EntUserId,
    callerHandle?: string,
    cbSuccess?: (formValue?: FormValueRaw, isCalculating?: boolean) => void)
  {

    const formulaFieldIdSet = defnForm.formulaFieldIdSet;
    const metaIdForm = defnForm.metaId;
    if(formulaFieldIdSet && formulaFieldIdSet.length)
    {
      const values = formValueRaw.valueMap;
      const formValues = {
        ...formValueRaw,
        valueMap: getValidFormFieldValueMap(values, callerEntUserId, callerHandle, defnForm)
      } as FormValueRaw;

      this.rpcFormResultCalc(
        entId,
        metaIdForm,
        formValues,
        (formValue?: FormValue) =>
        {
          const filteredValueMap = formValue?.valueMap;

          let isFormulaFieldEmpty = formulaFieldIdSet.some((fieldId) =>
          {
            return Boolean(filteredValueMap && filteredValueMap[fieldId]);
          });

          cbSuccess &&
          cbSuccess({
            ...formValueRaw,
            valueMap: filteredValueMap || {}
          }, Boolean(isFormulaFieldEmpty));
        }
      );
    }
  };

  resolveUserAvatar(entId: EntId, defnForm: DefnForm, valueMap?: Record<MetaIdComp, any>)
  {
    const valueMapClone = cloneDeep(valueMap || {});

    // Set user avtar using pub-sub to show username instead of id, in report
    const rootState = store.getState();
    const avatarMap = rootState.cache.app.user.userAvatarMap;
    const callerEnt = selectCallerEnt(rootState, entId);

    this.insertEntUserNameInFormField(entId, defnForm, valueMapClone, avatarMap, callerEnt);

    return valueMapClone;
  }

  getFieldOptionFromPluginApi(
    entId: EntId,
    defnForm: DefnForm,
    fieldId: MetaIdField,
    formValueRaw?: FormValueRaw,
    cbSuccess?: (field: DefnField, envSig: EnvSignal<SigPluginApiOutput>) => void)
  {
    const field = defnForm.compMap[fieldId] as DefnField;
    const type = field.type;
    const pluginApi = (field as DefnFieldPickText | DefnFieldSetOfText | DefnFieldPickTree | DefnFieldPickUser | DefnFieldSetOfUser)?.pluginApi;
    if(pluginApi)
    {
      if(type === "pickText"
        || type === "setOfText"
        || type === "pickTree"
        || type === "pickUser"
        || type === "setOfUser")
      {
        Srvc.app.form.formViewer.rpcPluginApiOutputGet(
          entId,
          fieldId,
          defnForm.metaId,
          formValueRaw,
          (cbEnvSig: EnvSignal<SigPluginApiOutput>) =>
          {
            cbSuccess && cbSuccess(field, cbEnvSig);
          }
        );
      }
    }
  }

  getFieldPickReportRowOption(
    entId: EntId,
    defnForm: DefnForm,
    fieldId: MetaIdField,
    formValue?: FormValue,
    compositeId?: MetaIdComp,
    gridRow?: FieldDtoGridRow,
    cb?: (options?: TypeFieldOptionResponse) => void)
  {

    Srvc.app.form.reportBr.rpcEntReportFieldDataGet({
      entId: entId,
      formId: defnForm.metaId,
      refReportFieldId: fieldId,
      inputFormValue: this.fnInsertGridRowInInputFormValue(formValue, compositeId, gridRow),
      inputFormCompositeId: compositeId,
      inputFormGridRowId: gridRow?.rowId,
      cbSuccess: envSig =>
      {
        if(envSig.sig)
        {
          this.calcPickReportRowOptions(fieldId, defnForm, envSig.sig, cb);
        }
        else
        {
          cb && cb();
        }
      }
    });
  }

  uploadFormValueMedia(entId: EntId, defnForm: DefnForm, valueMap?: FieldValues, cbSuccess?: CbSuccess)
  {
    let mediaIdList = [] as MediaId[];

    const addToMediaList = (defnField: DefnField, value: any) =>
    {
      switch(defnField.type)
      {
        case "audio":
        case "voice":
          mediaIdList.push((value as FieldValueAudio | FieldValueVoice).mediaIdAudio);
          break;
        case "camera":
        case "image":
          mediaIdList.push((value as FieldValueCamera | FieldValueImage).value.mediaIdImage);
          mediaIdList.push((value as FieldValueCamera | FieldValueImage).value.mediaIdBlurImage);
          break;
        case "video":
          mediaIdList.push((value as FieldValueVideo).mediaIdImage);
          mediaIdList.push((value as FieldValueVideo).mediaIdVideo);
          mediaIdList.push((value as FieldValueVideo).mediaIdBlurImage);
          break;
        case "document":
          mediaIdList.push((value as FieldValueDocument).value.mediaIdDocument);
          break;
      }
    };

    loopDefnForm(defnForm, (parent, comp) =>
    {
      const value = valueMap?.[comp.metaId];
      if(!value)
      {
        return;
      }

      if(comp.type === "grid")
      {
        const fieldValueGrid = value as FieldValueGrid | null | undefined;
        fieldValueGrid?.keys.forEach(rowId =>
        {
          const gridRowValueMap = fieldValueGrid?.map[rowId].valueMap;
          if(gridRowValueMap)
          {
            Object.entries(gridRowValueMap).forEach(([fieldId, rowValue]) =>
            {
              const _comp = defnForm.compMap[fieldId] as DefnField;
              rowValue && !isEmpty(rowValue) && addToMediaList(_comp, rowValue);
            });
          }
        });
      }
      else if(parent.type === "section")
      {
        addToMediaList(comp, value);
      }
    });

    mediaIdList = mediaIdList.filter(mediaId => Boolean(MediaStore.getMedia(mediaId)));

    if(!mediaIdList.length)
    {
      cbSuccess && cbSuccess();
      return;
    }

    this.uploadMediaIdList(entId, mediaIdList, cbSuccess);
  }

  uploadMediaIdList(entId: EntId, mediaIdList: MediaId[], cbSuccess?: () => void)
  {
    Promise.resolve(MediaStore.getMediaBlobs(mediaIdList))
    .then((mediaMap) =>
    {
      Promise.allSettled(Object.entries(mediaMap).map(([mediaId, file]) =>
      {
        return new Promise((resolve, reject) =>
        {
          uploadMedia(entId, mediaId, file, (mediaId: string) =>
          {
            MediaStore.removeMedia(mediaId);
            resolve(mediaId);
          }, reject);
        });
      }))
      .then(() => cbSuccess && cbSuccess())
      .catch((reason) => console.error("Error during media upload:", reason));
    })
    .catch((reason) => console.error("Error during media upload:", reason));
  }

  getReportListSelectListMap(defnForm: DefnForm)
  {
    const selectList1 = (state: RootState) => state.app.form.reportLayoutList1;
    const selectList2 = (state: RootState) => state.app.form.reportLayoutList2;
    const selectList3 = (state: RootState) => state.app.form.reportLayoutList3;
    const selectList4 = (state: RootState) => state.app.form.reportLayoutList4;
    const selectList5 = (state: RootState) => state.app.form.reportLayoutList5;
    const selectList6 = (state: RootState) => state.app.form.reportLayoutList6;
    const selectList7 = (state: RootState) => state.app.form.reportLayoutList7;
    const selectList8 = (state: RootState) => state.app.form.reportLayoutList8;
    const selectList9 = (state: RootState) => state.app.form.reportLayoutList9;
    const selectList10 = (state: RootState) => state.app.form.reportLayoutList10;

    return this.getFormSelectListMap(defnForm, [
      selectList1,
      selectList2,
      selectList3,
      selectList4,
      selectList5,
      selectList6,
      selectList7,
      selectList8,
      selectList9,
      selectList10
    ]);
  }

  getReportListSelectKanbanMap(defnForm: DefnForm)
  {
    const selectKanban1 = (state: RootState) => state.app.form.reportLayoutKanban1;
    const selectKanban2 = (state: RootState) => state.app.form.reportLayoutKanban2;
    const selectKanban3 = (state: RootState) => state.app.form.reportLayoutKanban3;
    const selectKanban4 = (state: RootState) => state.app.form.reportLayoutKanban4;
    const selectKanban5 = (state: RootState) => state.app.form.reportLayoutKanban5;
    const selectKanban6 = (state: RootState) => state.app.form.reportLayoutKanban6;
    const selectKanban7 = (state: RootState) => state.app.form.reportLayoutKanban7;
    const selectKanban8 = (state: RootState) => state.app.form.reportLayoutKanban8;
    const selectKanban9 = (state: RootState) => state.app.form.reportLayoutKanban9;
    const selectKanban10 = (state: RootState) => state.app.form.reportLayoutKanban10;

    return this.getFormSelectKanbanMap(defnForm, [
      selectKanban1,
      selectKanban2,
      selectKanban3,
      selectKanban4,
      selectKanban5,
      selectKanban6,
      selectKanban7,
      selectKanban8,
      selectKanban9,
      selectKanban10
    ]);
  }

  getReportListSelectCalendarMap(defnForm: DefnForm)
  {
    const selectCalendar1 = (state: RootState) => state.app.form.reportLayoutCalendar1;
    const selectCalendar2 = (state: RootState) => state.app.form.reportLayoutCalendar2;
    const selectCalendar3 = (state: RootState) => state.app.form.reportLayoutCalendar3;
    const selectCalendar4 = (state: RootState) => state.app.form.reportLayoutCalendar4;
    const selectCalendar5 = (state: RootState) => state.app.form.reportLayoutCalendar5;
    const selectCalendar6 = (state: RootState) => state.app.form.reportLayoutCalendar6;
    const selectCalendar7 = (state: RootState) => state.app.form.reportLayoutCalendar7;
    const selectCalendar8 = (state: RootState) => state.app.form.reportLayoutCalendar8;
    const selectCalendar9 = (state: RootState) => state.app.form.reportLayoutCalendar9;
    const selectCalendar10 = (state: RootState) => state.app.form.reportLayoutCalendar10;

    return this.getFormSelectCalendarMap(defnForm, [
      selectCalendar1,
      selectCalendar2,
      selectCalendar3,
      selectCalendar4,
      selectCalendar5,
      selectCalendar6,
      selectCalendar7,
      selectCalendar8,
      selectCalendar9,
      selectCalendar10
    ]);
  }

  getFormGridListSelectListMap(defnForm: DefnForm)
  {
    const selectList1 = (state: RootState) => state.app.form.formGridList1;
    const selectList2 = (state: RootState) => state.app.form.formGridList2;
    const selectList3 = (state: RootState) => state.app.form.formGridList3;
    const selectList4 = (state: RootState) => state.app.form.formGridList4;
    const selectList5 = (state: RootState) => state.app.form.formGridList5;
    const selectList6 = (state: RootState) => state.app.form.formGridList6;
    const selectList7 = (state: RootState) => state.app.form.formGridList7;
    const selectList8 = (state: RootState) => state.app.form.formGridList8;
    const selectList9 = (state: RootState) => state.app.form.formGridList9;
    const selectList10 = (state: RootState) => state.app.form.formGridList10;

    const propertyMap1 = (state: RootState) => state.app.form.formFieldPropertyMap1;
    const propertyMap2 = (state: RootState) => state.app.form.formFieldPropertyMap2;
    const propertyMap3 = (state: RootState) => state.app.form.formFieldPropertyMap3;
    const propertyMap4 = (state: RootState) => state.app.form.formFieldPropertyMap4;
    const propertyMap5 = (state: RootState) => state.app.form.formFieldPropertyMap5;
    const propertyMap6 = (state: RootState) => state.app.form.formFieldPropertyMap6;
    const propertyMap7 = (state: RootState) => state.app.form.formFieldPropertyMap7;
    const propertyMap8 = (state: RootState) => state.app.form.formFieldPropertyMap8;
    const propertyMap9 = (state: RootState) => state.app.form.formFieldPropertyMap9;
    const propertyMap10 = (state: RootState) => state.app.form.formFieldPropertyMap10;

    return {
      ...this.getFormSelectListMap(defnForm, [
          selectList1,
          selectList2,
          selectList3,
          selectList4,
          selectList5,
          selectList6,
          selectList7,
          selectList8,
          selectList9,
          selectList10
        ],
        [
          propertyMap1,
          propertyMap2,
          propertyMap3,
          propertyMap4,
          propertyMap5,
          propertyMap6,
          propertyMap7,
          propertyMap8,
          propertyMap9,
          propertyMap10
        ])
    };
  }

  doLoadUsers(listName: string, candidateList: DtoPickerEntUser[], isMultiPick: boolean)
  {
    const uiItemsById = {} as IListItemsById;
    const uiItemIds = [] as TypeComboId[];

    candidateList?.forEach(user =>
    {
      uiItemsById[user.entUserId] = {
        type: "p",
        primary: {
          text: user.nickName
        },
        userField: {
          payload: user
        },
        hideMenu: true
      } as IListItemAPSA;

      uiItemIds.push(user.entUserId);
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds,
      userField: {
        payload: candidateList
      },
      version: random()
    }));
    if(isMultiPick)
    {
      dispatchList(listName, listSetPickType("pickMany"));
    }

  }

  subscribeFieldEntUser(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    defnForm: DefnForm,
    valueMap: FieldValues,
    unSubscribe?: boolean): EntUserId[]
  {
    const entUserIds = new Set<EntUserId>();

    const insetEntUserId = (entUser?: FieldValueEntUserId) =>
    {
      if(entUser && entUser.value && isEntUserId(entUser.value))
      {
        entUserIds.add(entUser.value);
      }
    };

    const insetEntUserIds = (userCompId: MetaIdField, type: EnumDefnCompType, valueMap: FieldValues) =>
    {
      switch(type)
      {
        case "pickUser":
        case "userId":
        {
          const entUser: FieldValueEntUserId = valueMap[userCompId];
          insetEntUserId(entUser);
        }
          break;
        case "setOfUser":
        {
          const entUsers: FieldSetOfEntUserId = valueMap[userCompId];
          if(entUsers?.valueSet)
          {
            entUsers?.valueSet.forEach((entUser) =>
            {
              if(isEntUserId(entUser))
              {
                entUserIds.add(entUser);
              }
            });
          }
        }
          break;
        case "bool" :
        {
          const boolValue: FieldValueSwitch = valueMap[userCompId];
          if(boolValue?.captureUser)
          {
            insetEntUserId(boolValue?.captureUser);
          }
        }
          break;
        case "camera":
        {
          const cameraValue: FieldValueCamera = valueMap[userCompId];
          if(cameraValue?.captureUser)
          {
            insetEntUserId(cameraValue?.captureUser);
          }
        }
          break;
        case "scanCode":
        {
          const scanCodeValue: FieldValueScanCode = valueMap[userCompId];
          if(scanCodeValue?.captureUser)
          {
            insetEntUserId(scanCodeValue?.captureUser);
          }
        }
          break;
        case "voice":
        {
          const voiceValue: FieldValueVoice = valueMap[userCompId];
          if(voiceValue?.captureUser)
          {
            insetEntUserId(voiceValue?.captureUser);
          }
        }
          break;
        case "signature":
        {
          const signatureValue: FieldValueSignature = valueMap[userCompId];
          if(signatureValue?.captureUser)
          {
            insetEntUserId(signatureValue?.captureUser);
          }
        }
          break;
      }
    };

    loopDefnForm(defnForm, (_, comp) =>
    {
      const type = comp?.type;
      const metaId = comp?.metaId;
      if(metaId && type)
      {
        if(type === "pickUser" || type === "userId" || type === "setOfUser")
        {
          insetEntUserIds(metaId, type, valueMap);
        }
        else if(type === "grid")
        {
          const fieldValueGrid = valueMap[metaId] as FieldValueGrid;
          if(fieldValueGrid)
          {
            fieldValueGrid.keys.forEach((rowId) =>
            {
              const row = fieldValueGrid.map[rowId];
              const gridValueMap = row.valueMap;
              if(gridValueMap)
              {
                Object.keys(gridValueMap).forEach((compKey) =>
                {
                  const gridComp = defnForm.compMap[compKey];
                  if(gridComp?.type === "pickUser"
                    || gridComp?.type === "setOfUser"
                    || gridComp?.type === "userId")
                  {
                    insetEntUserIds(compKey, gridComp.type, gridValueMap);
                  }
                });
              }
            });
          }
        }
      }
    });
    const topics = [] as DtoTopic[];
    entUserIds.forEach((entUserId) =>
    {
      const entUserIdComboId = toComboId(entId, entUserId);
      const topic = Srvc.app.pubsub.homeAvatarTopic(entUserIdComboId);
      if(topic)
      {
        topics.push(topic);
      }
    });
    if(topics.length)
    {
      Srvc.app.pubsub.homeAvatarBulk(subscriberId, topics, unSubscribe);
    }

    return [...entUserIds];
  }

  insertEntUserNameInFormField(
    entId: EntId,
    defnForm: DefnForm,
    valueMap: FieldValues,
    avatarMap?: Record<TypeComboIdUser, SigUserAvatar>,
    callerEnt?: ICallerEnt)
  {
    loopDefnForm(defnForm, (parent, comp) =>
    {
      const type = comp?.type;
      const fieldId = comp?.metaId;
      if(fieldId)
      {
        if(parent.type === "section" && insertUserNameValidFields(type))
        {
          this.insertEntUserNameInValueMap(entId, comp, valueMap, avatarMap, callerEnt);
        }
        if(comp?.type === "grid")
        {
          const fieldValueGrid = valueMap[fieldId] as FieldValueGrid;
          if(fieldValueGrid)
          {
            fieldValueGrid.keys.forEach((rowId) =>
            {
              const row = fieldValueGrid.map[rowId];
              const gridValueMap = row.valueMap;
              if(gridValueMap)
              {
                Object.keys(gridValueMap).forEach((compKey) =>
                {
                  const gridComp = defnForm.compMap[compKey];
                  if(insertUserNameValidFields(gridComp?.type))
                  {
                    this.insertEntUserNameInValueMap(entId,
                      gridComp as DefnField,
                      gridValueMap,
                      avatarMap,
                      callerEnt
                    );
                  }
                });
              }
            });
          }
        }
      }
    }, {
      excludeGridItem: true
    });
  }

  checkFormHasCaptureLocationValue(filteredForm?: DefnForm)
  {
    if(!filteredForm)
    {
      return undefined;
    }

    const captureFields: EnumDefnCompType[] = [
      "bool",
      "scanCode",
      "camera",
      "signature",
      "voice"
    ];

    return Object.values(filteredForm.compMap).some(val =>
    {
      if(captureFields.includes(val.type))
      {
        const value = val as DefnFieldSwitch
          | DefnFieldScanCode
          | DefnFieldCamera
          | DefnFieldSignature
          | DefnFieldVoice;

        return Boolean(value.captureLocation);
      }
      return false;
    });
  }

  fnInsertGridRowInInputFormValue(
    formValue?: FormValue,
    gridId?: MetaIdGrid,
    gridRow?: FieldDtoGridRow
  )
  {
    const valueMap = {
      ...formValue?.valueMap
    } as FieldValues;

    removeUnnecessaryFieldValues(valueMap);

    if(gridId && gridRow)
    {
      if(!valueMap[gridId])
      {
        valueMap[gridId] = {
          keys: [],
          map: {}
        } as FieldValueGrid;
      }
      const gridValue = valueMap[gridId] as FieldValueGrid | undefined;
      if(gridValue)
      {
        if(!gridValue?.map[gridRow.rowId])
        {
          gridValue.keys.push(gridRow.rowId);
        }
        gridValue.map[gridRow.rowId] = gridRow;
      }

    }

    return {
      ...formValue,
      rowId: formValue?.rowId || nextRowId(),
      valueMap: valueMap
    } as FormValue;
  }

  insertEntUserNameInValueMap(
    entId: EntId,
    comp: DefnField,
    valueMap: FieldValues,
    avatarMap?: Record<TypeComboIdUser, SigUserAvatar>,
    callerEnt?: ICallerEnt)
  {
    const fieldId = comp?.metaId;
    const type = comp?.type;
    const entUserId = callerEnt?.entUserId;
    const managerId = callerEnt?.managerId;
    const grandManagerId = callerEnt?.grandManagerId;
    const nickName = callerEnt?.nickName;
    const roleMap = callerEnt?.roleMap;

    switch(type)
    {
      case "pickUser":
        const fieldPickUser = comp as DefnFieldPickUser;
        if(!valueMap[fieldId] && fieldPickUser.defaultValue === "$Self")
        {
          valueMap[fieldId] = {
            value: entUserId,
            displayField: nickName
          } as FieldValueEntUserId;
        }
        if(!valueMap[fieldId] && fieldPickUser.defaultValue === "$Manager")
        {
          valueMap[fieldId] = {
            value: managerId
          } as FieldValueEntUserId;
        }
        if(!valueMap[fieldId] && fieldPickUser.defaultValue === "$GrandManager")
        {
          valueMap[fieldId] = {
            value: grandManagerId
          } as FieldValueEntUserId;
        }
        this.getEntUserNameValue(
          entId,
          avatarMap,
          valueMap[fieldId] as FieldValueEntUserId
        );
        break;
      case "userId":
        const fieldUserId = comp as DefnFieldUserId;
        if(!valueMap[fieldId] && fieldUserId?.defaultValue === "createdBy")
        {
          valueMap[fieldId] = {
            value: entUserId
          } as FieldValueEntUserId;
        }

        this.getEntUserNameValue(
          entId,
          avatarMap,
          valueMap[fieldId] as FieldValueEntUserId,
          fieldUserId.displayProperty === "handle"
        );

        break;
      case "setOfUser":
        const fieldPickSetOfUser = comp as DefnFieldPickUser;
        if(!valueMap[fieldId] && fieldPickSetOfUser.defaultValue === "$Self")
        {
          valueMap[fieldId] = {
            valueSet: [entUserId],
            displayField: nickName
          } as FieldSetOfEntUserId;
        }
        if(!valueMap[fieldId] && fieldPickSetOfUser.defaultValue === "$Manager")
        {
          valueMap[fieldId] = {
            valueSet: managerId ? [managerId] : []
          } as FieldSetOfEntUserId;
        }
        if(!valueMap[fieldId] && fieldPickSetOfUser.defaultValue === "$GrandManager")
        {
          valueMap[fieldId] = {
            valueSet: grandManagerId ? [grandManagerId] : []
          } as FieldSetOfEntUserId;
        }
        this.getEntSetOfUserNameValue(
          entId,
          avatarMap,
          valueMap[fieldId] as FieldSetOfEntUserId
        );
        break;
      case "bool":
        this.getEntUserNameValue(
          entId,
          avatarMap,
          (valueMap[fieldId] as FieldValueSwitch)?.captureUser
        );
        break;
      case "camera":
        this.getEntUserNameValue(
          entId,
          avatarMap,
          (valueMap[fieldId] as FieldValueCamera)?.captureUser
        );
        break;
      case "scanCode":
        this.getEntUserNameValue(
          entId,
          avatarMap,
          (valueMap[fieldId] as FieldValueScanCode)?.captureUser
        );
        break;
      case "voice":
        this.getEntUserNameValue(
          entId,
          avatarMap,
          (valueMap[fieldId] as FieldValueVoice)?.captureUser
        );
        break;
      case "signature":
        this.getEntUserNameValue(
          entId,
          avatarMap,
          (valueMap[fieldId] as FieldValueSignature)?.captureUser
        );
        break;
      case "pickRole":
        const fieldPickRole = comp as DefnFieldPickRole;
        if(!valueMap[fieldId] && fieldPickRole.defaultRoleId)
        {
          valueMap[fieldId] = {
            value: fieldPickRole.defaultRoleId
          } as FieldValueRole;
        }
        this.getEntRoleValue(
          type,
          roleMap,
          valueMap[fieldId]
        );
        break;
      case "setOfRole":
        const fieldSetOfRole = comp as DefnFieldSetOfRole;
        if(!valueMap[fieldId] && fieldSetOfRole.defaultRoleIdSet)
        {
          valueMap[fieldId] = {
            valueSet: fieldSetOfRole.defaultRoleIdSet
          } as FieldSetOfRole;
        }
        this.getEntRoleValue(
          type,
          roleMap,
          valueMap[fieldId]
        );
        break;
    }

  }

  //endregion

  //region Private

  private getEntUserNameValue(
    entId: EntId,
    avatarMap?: Record<TypeComboIdUser, SigUserAvatar>,
    value?: FieldValueEntUserId,
    handle?: boolean)
  {
    if(value && avatarMap)
    {
      const entUserId = toComboId(entId, value?.value);
      const avatar = avatarMap[entUserId];

      if(avatar)
      {
        value.displayField = handle
          ? avatar.handle
          : textUser(avatar);
      }
    }
  }

  private getEntSetOfUserNameValue(
    entId: EntId,
    avatarMap?: Record<TypeComboIdUser, SigUserAvatar>,
    value?: FieldSetOfEntUserId,
    handle?: boolean)
  {
    if(value && avatarMap)
    {
      const entUserIdSet = value.valueSet.map(value =>
      {
        return toComboId(entId, value);
      });

      const avatarSet = [] as SigUserAvatar[];
      const displaySet = [] as string[];

      entUserIdSet.forEach(entUserId =>
      {
        const avatar = avatarMap[entUserId];
        avatarSet.push(avatar);
        if(avatar)
        {
          if(avatar.handle && handle)
          {
            displaySet.push(avatar.handle);
          }
          else
          {
            displaySet.push(textUser(avatar));
          }
        }
      });

      value.displaySet = displaySet;

    }
  }

  private getEntRoleValue(
    fieldType: EnumDefnCompType,
    roleMap?: Record<MetaIdRole, DtoEntRole>,
    value?: FieldValueRole | FieldSetOfRole
  )
  {
    if(roleMap && value)
    {
      if(fieldType === "pickRole")
      {
        const valuePickRole = value as FieldValueRole;
        valuePickRole.displayValue = roleMap[valuePickRole.value].name;
      }
      else if(fieldType === "setOfRole")
      {
        const valueSetOfRole = value as FieldSetOfRole;
        valueSetOfRole.displaySet = valueSetOfRole.valueSet.map(roleId =>
        {
          return roleMap[roleId].name;
        });
      }
    }
  }

  private rpcFormResultCalc(
    entId: EntId,
    metaIdForm: MetaIdForm,
    formValueRaw: FormValueRaw,
    cbSuccess?: (formValue?: FormValue) => void)
  {
    const msg = {
      formId: metaIdForm,
      formValueRaw: formValueRaw
    } as MsgFormResultCalc;

    RpcEntMain.entFormResultCalc(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess(envSig.sig?.formValue);
      }
    });
  }

  private getFormSelectListMap(
    defnForm: DefnForm,
    gridlayoutSelectListSet: SelectList[],
    fieldPropertySelectListSet?: SelectList[])
  {
    const usedGridFieldPropertySelectListSet = [] as string[];
    const usedGridLayoutSelectListSet = [] as string[];

    const selectListMap = {} as Record<MetaIdGrid, SelectList>;

    loopDefnForm(defnForm, (_, defnField) =>
    {
      if(defnField?.type === "grid")
      {
        const grid = defnField as DefnGrid;

        grid.layoutGridMap?.keys.forEach((key) =>
        {
          const layout = grid.layoutGridMap?.map[key];
          if(layout)
          {
            if(layout?.kind === "list" || layout?.kind === "card")
            {
              if(usedGridLayoutSelectListSet.length < gridlayoutSelectListSet.length)
              {
                selectListMap[defnField.metaId] = gridlayoutSelectListSet[usedGridLayoutSelectListSet.length];
                usedGridLayoutSelectListSet.push(random());
              }
            }
          }

        });
      }
      else if(fieldPropertySelectListSet && defnField?.type === "propertyMap")
      {
        if(usedGridFieldPropertySelectListSet.length < fieldPropertySelectListSet.length)
        {
          selectListMap[defnField.metaId] = fieldPropertySelectListSet[usedGridFieldPropertySelectListSet.length];
          usedGridFieldPropertySelectListSet.push(random());
        }
      }
      else if(defnField?.type === "pickGridRow")
      {

      }
    });
    return selectListMap;
  }

  private getFormSelectKanbanMap(
    defnForm: DefnForm,
    gridlayoutSelectKanbanSet: SelectKanban[])
  {
    const usedGridLayoutSelectKanbanSet = [] as string[];

    const selectKanbanMap = {} as Record<MetaIdGrid, SelectKanban>;

    loopDefnForm(defnForm, (_, defnField) =>
    {
      if(defnField?.type === "grid")
      {
        const grid = defnField as DefnGrid;

        grid.layoutGridMap?.keys.forEach((key) =>
        {
          const layout = grid.layoutGridMap?.map[key];
          if(layout)
          {
            if(layout?.kind === "kanban")
            {
              if(usedGridLayoutSelectKanbanSet.length < gridlayoutSelectKanbanSet.length)
              {
                selectKanbanMap[defnField.metaId] = gridlayoutSelectKanbanSet[usedGridLayoutSelectKanbanSet.length];
                usedGridLayoutSelectKanbanSet.push(random());
              }
            }
          }

        });
      }
    });
    return selectKanbanMap;
  }

  private getFormSelectCalendarMap(
    defnForm: DefnForm,
    gridlayoutSelectCalendarSet: SelectCalendar[])
  {
    const usedGridLayoutSelectCalendarSet = [] as string[];

    const selectCalendarMap = {} as Record<MetaIdGrid, SelectCalendar>;

    loopDefnForm(defnForm, (_, defnField) =>
    {
      if(defnField?.type === "grid")
      {
        const grid = defnField as DefnGrid;

        grid.layoutGridMap?.keys.forEach((key) =>
        {
          const layout = grid.layoutGridMap?.map[key];
          if(layout)
          {
            if(layout?.kind === "calendar")
            {
              if(usedGridLayoutSelectCalendarSet.length < gridlayoutSelectCalendarSet.length)
              {
                selectCalendarMap[defnField.metaId] =
                  gridlayoutSelectCalendarSet[usedGridLayoutSelectCalendarSet.length];
                usedGridLayoutSelectCalendarSet.push(random());
              }
            }
          }

        });
      }
    });
    return selectCalendarMap;
  }

  private calcPickReportRowOptions(
    fieldId: MetaIdField,
    defnForm: DefnForm,
    sig: SigReportFieldData,
    cb?: (options?: TypeFieldOptionResponse) => void)
  {
    const optionMap = {
      map: {},
      keys: []
    } as DefnStudioMapOfDtoOption;

    const field = defnForm?.compMap[fieldId] as DefnFieldPickReportRow;
    const reportOutputFormGridId = field?.reportOutputFormGridId;
    const reportOutputForm = sig?.reportOutputForm;

    const displayFieldId = field?.gridDisplayFieldId;
    if(reportOutputFormGridId && displayFieldId)
    {
      const displayField = reportOutputForm?.compMap[displayFieldId] as DefnField;
      const outputGrid = sig.reportOutputFormValue.valueMap[reportOutputFormGridId] as FieldValueGrid | undefined;

      outputGrid?.keys.forEach(key =>
      {
        const gridRow = outputGrid?.map[key];
        const displayValue = fnFieldValueToRawValue(displayField.type, gridRow?.valueMap?.[displayFieldId]) as string;
        if(displayValue)
        {
          if(!optionMap.map[displayValue])
          {
            optionMap.keys.push(displayValue);
          }
          optionMap.map[displayValue] = {
            metaId: displayValue,
            value: displayValue
          };
        }
      });
      cb && cb(optionMap);
    }
  }

  //endregion
}

function insertUserNameValidFields(fieldType?: EnumDefnCompType): boolean
{
  return (fieldType === "pickUser"
    || fieldType === "setOfUser"
    || fieldType === "bool"
    || fieldType === "camera"
    || fieldType === "scanCode"
    || fieldType === "voice"
    || fieldType === "signature"
    || fieldType === "grid"
    || fieldType === "userId"
    || fieldType === "pickRole"
    || fieldType === "setOfRole"
  );
}
