import {combineReducers} from "redux";
import {dispatchList} from "../../../base/plus/ListPlus";
import {createListSlice} from "../../../base/plus/ListPlus";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";

const storeRecentList = "storeRecentList";

export function reducersStoreDrawer()
{
  return combineReducers({
    storeRecentList: createListSlice("storeRecentList", {defaultListItemType: "aps"}).reducer,
    storeSearch: createListSlice("storeSearch",
      {
        defaultListItemType: "ps",
        showEmptyGroups: true,
        canShowMenu: false,
        externalSearch: true
      }
    ).reducer,
    storeFilter: createListSlice("storeFilter",
      {
        defaultListItemType: "p",
        pickType: "pickMany"
      }
    ).reducer,
    storePicker: createListSlice("storePicker", {defaultListItemType: "aps"}).reducer,
    storePickerFilter: createListSlice("storePickerFilter",
      {
        defaultListItemType: "p",
        pickType: "pickMany"
      }
    ).reducer
  });
}

export function onClickStoreActionMainBack()
{
  dispatchList(storeRecentList, listSetSelectedItemId());
}
