import {StudioEntPaymentProvider} from "../../../../../api/meta/base/dto/StudioEntPaymentProvider";
import {MetaIdPaymentProvider} from "../../../../../api/meta/base/Types";
import {EntId} from "../../../../../api/meta/base/Types";
import ISrvc from "../../../../../base/ISrvc";
import {EnumListItemDirection} from "../../../../../base/types/TypesStudio";
import {dispatchEnt} from "../../../SrvcCacheStudio";
import {addEntDeployDefaultPaymentProviderId} from "../../SliceCacheStudioEnt";
import {moveEntItem} from "../../SliceCacheStudioEnt";
import {removeEntDeployPaymentProvider} from "../../SliceCacheStudioEnt";
import {addEntDeployPaymentProvider} from "../../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntDeployPaymentProviders extends ISrvc
{
  addEntDeployDefaultPaymentProviderId(entId: EntId, defaultPaymentProviderId?: MetaIdPaymentProvider)
  {
    dispatchEnt(entId, addEntDeployDefaultPaymentProviderId({
      entId: entId,
      defaultPaymentProviderId: defaultPaymentProviderId
    }));
  }

  addEntDeployPaymentProvider(entId: EntId, studioEntDeployPaymentProvider: StudioEntPaymentProvider)
  {
    dispatchEnt(entId, addEntDeployPaymentProvider({
      entId: entId,
      sig: studioEntDeployPaymentProvider
    }));
  }

  removeEntDeployPaymentProvider(entId: EntId, metaIdPaymentProvider: MetaIdPaymentProvider)
  {
    dispatchEnt(entId, removeEntDeployPaymentProvider({
      entId: entId,
      metaId: metaIdPaymentProvider
    }));
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdPaymentProvider,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "deployPaymentProviderList",
      moveDirection
    }));
  }
}
