import {StudioEntPlugin} from "../../../api/meta/base/dto/StudioEntPlugin";
import {StudioEntPluginMap} from "../../../api/meta/base/dto/StudioEntPluginMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {EntId} from "../../../api/meta/base/Types";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigStudioPluginMap} from "../../../api/studio/studioMain/sig/SigStudioPluginMap";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getMediaSrc} from "../../../base/plus/MediaPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export type CbPluginMapGetSuccess = (pluginMap: SigStudioPluginMap) => void;

export default class SrvcStudioEntPlugins extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entImportList;
  }

  loadImportList(
    entId: EntId,
    listName: string,
    importMap?: StudioEntPluginMap,
    readOnly?: boolean
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    importMap?.keys.forEach((itemId) =>
    {
      const importItem = importMap.map[itemId] as StudioEntPlugin;
      const importModules: StudioModuleSelection | undefined = importItem.modules;

      if(Srvc.studio.ent.filterListByTag(entId, importModules))
      {
        uiItemIds.push(itemId);
      }

      switch(importItem.kind)
      {
        case "plugin":
          uiItemsById[itemId] = {
            type: "aps",
            hideMenu: readOnly,
            avatarLeft: {
              src: getMediaSrc(importItem.pluginAvatarId),
              icon: "plugin"
            },
            primary: {
              text: importItem.pluginName,
              caption: {
                type: "text",
                text: toLabel(importItem.kind),
                ignoreSelection: true
              }
            },
            secondary: {
              text: importItem.description,
              caption: {
                text: toLabel(importItem.pluginVersion),
                ignoreSelection: true
              }
            },
            ignoreSelectionCaption: true,
            userField: {
              value: importItem
            }
          } as IListItemAPSA;
      }
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  rpcPluginMapGet(cbSuccess: CbPluginMapGetSuccess)
  {
    RpcStudioMain.studioPluginMapGet(pluginMapSig =>
    {
      if(pluginMapSig.error)
      {
        return;
      }
      pluginMapSig.sig && cbSuccess(pluginMapSig.sig);
    });
  }
}
