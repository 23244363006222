import {AppVersion} from "../../api/nucleus/base/Types";
import {FILE_MB_SIZE_LIMIT_MAX} from "../types/TypesGlobal";
import {FILE_UPLOAD_LIMIT_MAX} from "../types/TypesGlobal";

//region routes

export const ROUTE_SIGN_UP = "/signUp";
export const ROUTE_REGISTER = "/register";
export const ROUTE_CREATE_ACCOUNT = "/createAccount";
export const ROUTE_PREVIEW = "/preview";
export const ROUTE_PREVIEW_CODE = "/preview/:code";
export const ROUTE_DEEP_LINK = "/deepLink";
export const ROUTE_STUDIO = "/studio";
export const ROUTE_STORE = "/store";
export const ROUTE_WIZARD = "/wizard";
export const ROUTE_CONTROL_PANEL = "/controlPanel";
export const ROUTE_SIGN_IN = "/signIn";
export const ROUTE_SIGN_IN_PASSWORD = "/signInPassword";
export const ROUTE_SIGN_IN_USER_ID = "/signInUserId";
export const ROUTE_USER_ID = "/userId";
export const ROUTE_USER_ID_SIGN_IN = "/userIdSignIn";
export const ROUTE_LOGIN = "/login";
export const ROUTE_QR_CODE = "/qrCode";
export const ROUTE_VERIFY_OTP = "/verifyOtp";
export const ROUTE_FORGOT_PASSWORD = "/forgotPassword";
export const ROUTE_USER_PROFILE = "/userProfile";
export const ROUTE_USER_SETTING = "/userSetting";
export const ROUTE_CHANGE_PASSWORD = "/changePassword";
export const ROUTE_RESET_PASSWORD = "/resetPassword";
export const ROUTE_API_Browser = "/api";
export const ROUTE_COMPONENTS_BROWSER = "/components";
export const ROUTE_HOME = "/";

//endregion

//region strings

export const STR_APP_NAME = "neome.ai";
export const STR_APP_VERSION = "2021-12-31-v1" as AppVersion;
export const STR_SEND_A_MESSAGE = "Send a message";
export const STR_SEND_A_PROMPT = "Send command lines or help";
export const STR_EDIT_LOCK_REQUIRED = "Edit lock is required";
export const STR_WHAT_IS_THIS_ABOUT = "What is this about?";
export const STR_NO_ABOUT = "No about";
export const STR_NO_MESSAGE = "No message";
export const STR_YOU = "You";
export const MENTION_SYMBOL = "@";
export const STR_ADMIN = "Admin";
export const STR_SIGNIN_LABEL = "Sign in";
export const STR_CREATE_ACCOUNT_LABEL = "Create account";
export const STR_WHATSAPP_SHARE_API = "https://api.whatsapp.com/send?text=";
export const STR_NEOME_URL = "https://www.neome.ai";
export const WHY_NEOME_URL = "https://www.neome.ai";
export const STR_NEOME_WIDGET_LINK = "https://neome.ai/reference/virtual-assistant";
export const STR_NEOME_DOWNLOAD_DRIVE_LINK = "https://neome.ai/reference/drive";
export const TERMS_OF_SERVICE_URL = "https://www.neome.ai/about/terms-of-services";
export const PRIVACY_POLICY_URL = "https://www.neome.ai/about/privacy-policy";
export const DEFAULT_WIDGET_HOST_URL = "https://web.neome.ai";
export const PRICING_PAGE_URL = "https://www.neome.ai/pricing";

export const STR_LIST_ITEM_MEDIA_PLACEHOLDER_SRC = process.env.PUBLIC_URL + "/placeholderImageMedia.png";
export const STR_CHAT_WALLPAPER = process.env.PUBLIC_URL + "/chatBgPattern.png";

export const GOOGLE_MAP_API_KEY_DEVELOPMENT = "AIzaSyA3WRiDggOUIxi6IAWkfiF67TT9gGKtHO4";
export const GOOGLE_MAP_API_KEY_PRODUCTION = "AIzaSyB3qz7uNlAa3Bme0fT70XsX45tlZugzVng";

export const STR_REMOVE_FOR_ME = "Remove for me";
export const STR_REMOVE_FOR_EVERYONE = "Remove for everyone";
export const STR_YOU_DELETED_THIS_MESSAGE = "You removed this message";
export const STR_THIS_MESSAGE_WAS_DELETED = "This message was removed";
export const STR_FORM_SUBMIT_ERROR = "Oops! There was a problem with your form submission";
export const STR_ROW_DELETED = "Row deleted";
export const STR_SELECT_ROW = "Select rows";
export const STR_CLICK_TO_REMOVE = "Click to remove";
export const STR_INVITE_SUBMIT_SUCCESSFULLY = "Invite sent successfully";
export const STR_DEEPLINK_CONTENT_MESSAGE = "Anyone with web browser can view the contents through this link";
export const STR_GROUP_INVITE_MESSAGE = "A neome user can join this group by following the link. Only share it with people you trust.";
export const STR_LINK_COPY_TO_CLIPBOARD = "Link copied to clipboard";
export const STR_ALLOW_LOCATION_PERMISSION = "Please enable location service";
export const STR_FAIL_TO_LOAD_GOOGLE_MAP = "Failed to load Google Maps";
export const STR_MAX_FILE_UPLOAD_LIMIT = `Can't share more than ${FILE_UPLOAD_LIMIT_MAX} media files`;
export const STR_MAX_FILE_UPLOAD_SIZE_LIMIT = `Can't share media/doc larger than ${FILE_MB_SIZE_LIMIT_MAX} MB`;
export const STR_MODULES = "modules";
export const STR_USERS_IMPORTED_SUCCESSFULLY = "Users imported successfully";
export const STR_INVALID_DATA_SHEET = "Invalid data sheet";
export const STR_BLOCK_USER_MESSAGE = "If you block, this person won't be able to message you.";
export const STR_NOTHING_HERE = "Nothing here";
export const STR_CLICK_HERE_TO_ADD_RECORDS = "Click here to add records";

export const STR_AG_GRID_LICENSE_KEY = `Using_this_{AG_Grid}_Enterprise_key_{AG-058591}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Neomenta}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{neome}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{neome}_need_to_be_licensed___{neome}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{17_April_2025}____[v3]_[01]_MTc0NDg0NDQwMDAwMA==8598d3d8071287bbbdab2e751f2fab7d`;
export const STR_EXPIRED_TEXT = "Expired";

//endregion

//region numbers

export const CHAT_ITEM_IMAGE_BORDER_RADIUS = 6;
export const CHAT_ITEM_SIZE_AVG = 50;
export const CHAT_ITEM_PAGE_SIZE_NEW_MSG = 10;
export const TIME_GAP_TYPING = 1500;
export const ALERT_DIALOG_CONTENT_WIDTH = 500;
export const ALERT_DIALOG_CONTENT_HEIGHT = 270;
export const MAIN_FOOTER_DIALOG_WIDTH = 450;
export const MAIN_FOOTER_DIALOG_HEIGHT = 755;
export const ASIDE_GROUP_INFO_DIALOG_WIDTH = 450;
export const MAX_ASIDE_AND_DRAWER_WIDTH = 600;
export const MIN_ASIDE_AND_DRAWER_WIDTH = 340;
export const ASIDE_GROUP_INFO_DIALOG_HEIGHT = 650;
export const RPC_RETRY_DELAY = 3000;
export const DEEPLINK_PREVIEW_DIALOG_WIDTH = 400;
export const MAX_DEEPLINK_FORM_CONTAINER_WIDTH = 882;
export const MAX_TOOLTIP_BUBBLE_WIDTH = 600;
export const MAX_CHARACTER_COUNT_SYMBOL = 64;
export const MIX_REACTION_DIALOG_SIZE_WIDTH = 375;
export const MAX_REACTION_DIALOG_SIZE_HEIGHT = 350;
export const DEFAULT_PARAGRAPH_LINE_COUNT = 5;

//endregion

export const FORM_IMAGE_MAX_MB = 1.5;
export const FORM_IMAGE_SIZE_MAX = 640 * 3;
export const FORM_FIELD_MEDIUM_PADDING = "16px";
export const FORM_FIELD_SMALL_PADDING = "8px";

export const IMAGE_MAX_MB = 1.5;
export const IMAGE_SIZE_MAX = 640 * 3;
export const IMAGE_SIZE_MIN = 192;
