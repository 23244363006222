import {StudioEntDriveSheetMap} from "../../../api/meta/base/dto/StudioEntDriveSheetMap";
import {StudioEntSpreadsheet} from "../../../api/meta/base/dto/StudioEntSpreadsheet";
import {StudioEntSpreadsheetMap} from "../../../api/meta/base/dto/StudioEntSpreadsheetMap";
import {StudioFormMap} from "../../../api/meta/base/dto/StudioFormMap";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";

export default class SrvcStudioEntDriveSheet extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entDriveList;
  }

  loadDriveSheetList(
    listName: string,
    driveSheetMap?: StudioEntDriveSheetMap,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    trashSpreadsheetMap?: Record<MetaIdSpreadsheet, StudioEntSpreadsheet>,
    readOnly?: boolean
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    if(!driveSheetMap)
    {
      return;
    }

    driveSheetMap.keys.forEach((itemId) =>
    {
      const driveSheet = driveSheetMap.map[itemId];
      uiItemIds.push(itemId);

      let spreadsheet = {} as StudioEntSpreadsheet;
      if(spreadsheetMap)
      {
        const spreadsheetId = spreadsheetMap.keys.find((spreadsheetId) => spreadsheetId === driveSheet.spreadsheetId);

        if(spreadsheetId)
        {
          spreadsheet = spreadsheetMap.map[spreadsheetId];
        }
      }

      let driveSheetName = spreadsheet.details?.name;
      const spreadsheetFormId = spreadsheet.formId;
      const formName = spreadsheetFormId
        ? formMap?.map[spreadsheetFormId]?.details?.name
        : undefined;
      let isDriveSheetNameTrash = false;

      if(!driveSheetName && trashSpreadsheetMap)
      {
        const trashSpreadsheet = trashSpreadsheetMap[driveSheet.spreadsheetId];
        if(trashSpreadsheet)
        {
          driveSheetName = trashSpreadsheet.details?.name;
          if(driveSheetName)
          {
            isDriveSheetNameTrash = true;
          }
        }
      }

      uiItemsById[itemId] = {
        type: "ps",
        primary: {
          text: driveSheetName,
          caption: {
            text: driveSheet.partitionedData
              ? "Partitioned data"
              : "",
            ignoreSelection: true
          },
          color: isDriveSheetNameTrash ? "error" : undefined
        },
        secondary: {
          text: formName,
          color: "textDisabled"
        },
        ignoreSelectionCaption: true,
        hideMenu: readOnly
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));

  }
}
