import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {StudioVarMap} from "../../../api/meta/base/dto/StudioVarMap";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {PluginId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getVariablesUiItemsById} from "../../../base/plus/StudioPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioPluginVariables extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.plugin.pluginVariableList;
  }

  addPluginVariable(pluginBundleId: PluginBundleId, variable: StudioVar)
  {
    Srvc.cache.studio.plugin.variables.addPluginVariable(pluginBundleId, variable);
  }

  copyPluginVariable(pluginBundleId: PluginBundleId, metaIdVar: MetaIdVar)
  {
    Srvc.cache.studio.plugin.variables.copyPluginVariable(pluginBundleId, metaIdVar);
  }

  loadVariableList(
    pluginBundleId: PluginBundleId,
    pluginId: PluginId,
    listName: string,
    studioVarMap?: StudioVarMap,
    readOnly?: boolean
  )
  {
    if(studioVarMap)
    {
      const {
        uiItemIds,
        uiItemsById
      } = getVariablesUiItemsById(studioVarMap, readOnly);

      const filterUiItemIds = uiItemIds.filter(varId =>
      {
        const variable = studioVarMap.map[varId];
        if(variable)
        {
          const modules = variable.details.modules;
          if(Srvc.studio.plugin.filterListByTag(pluginBundleId, pluginId, modules))
          {
            return true;
          }
        }
        return false;
      });

      dispatchList(listName, listRefresh({
        itemsById: uiItemsById,
        itemIds: filterUiItemIds
      }));
    }
  }
}
