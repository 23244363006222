import {FormControl} from "@mui/material";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {RowId} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldRowId(props: {
  defn: DefnFieldEditable,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;

  const newDefn = useMemo(() =>
  {
    const isRefChild = Boolean(formCtx.getRefChildParentId(fieldId));

    return {
      ...defn,
      disabled: isRefChild ? disabled : true
    } as DefnFieldEditable;
  }, [defn, fieldId]);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as RowId;

        function onChangeRowId(value?: FieldValueText | null)
        {
          field.onChange(fnFieldValueToRawValue("text", value));
        }

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawTextField
                {...props}
                value={fieldValue ? {value: fieldValue} as FieldValueText : undefined}
                onBlur={field.onBlur}
                onChange={onChangeRowId}
                ref={field.ref}
                error={error}
                defn={newDefn}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
