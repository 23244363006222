import {isArray} from "lodash";
import {useState} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import {useMemo} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdChartXAxis} from "../../api/meta/base/ApiPlus";
import {nextMetaIdChartYAxis} from "../../api/meta/base/ApiPlus";
import {DefnDtoColor} from "../../api/meta/base/dto/DefnDtoColor";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldParagraph} from "../../api/meta/base/dto/DefnFieldParagraph";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutGridId} from "../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnStudioPickLayoutSpreadsheetId} from "../../api/meta/base/dto/DefnStudioPickLayoutSpreadsheetId";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoChartYAxis} from "../../api/meta/base/dto/StudioDtoChartYAxis";
import {StudioDtoLayoutGrid} from "../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioDtoLayoutXYChart} from "../../api/meta/base/dto/StudioDtoLayoutXYChart";
import {StudioDtoOption} from "../../api/meta/base/dto/StudioDtoOption";
import {StudioDtoSwimlane} from "../../api/meta/base/dto/StudioDtoSwimlane";
import {StudioFieldPickText} from "../../api/meta/base/dto/StudioFieldPickText";
import {StudioMapOfChartXAxis} from "../../api/meta/base/dto/StudioMapOfChartXAxis";
import {StudioMapOfChartYAxis} from "../../api/meta/base/dto/StudioMapOfChartYAxis";
import {StudioMapOfSwimlane} from "../../api/meta/base/dto/StudioMapOfSwimlane";
import {StudioSection} from "../../api/meta/base/dto/StudioSection";
import {StudioVarSetOfText} from "../../api/meta/base/dto/StudioVarSetOfText";
import {YAxisFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {XAxisFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {MetaIdSwimlane} from "../../api/meta/base/Types";
import {EnumDefnLayoutGridKind} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {MetaId} from "../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdChartYAxis} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {hasValues} from "../../base/plus/JsPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {getEmptyKeysAndMap} from "../../base/plus/StudioPlus";
import {fnUseStudioResolver} from "../../base/plus/StudioPlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {SPREADSHEETS_LAYOUT_XY_CHART_Y_AXIS_FIELD} from "../atom/assets/HelperTextStudio";
import {getLayoutLabel} from "../form/builder/base/FieldBuilderPlus";
import {fieldKeyLabel} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription, fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {getSwimlaneLabelKey} from "./base/DialogPlus";
import {getSwimlaneColorKey} from "./base/DialogPlus";
import {getSwimlaneColorKeyVar} from "./base/DialogPlus";
import {getSwimlaneCompMap} from "./base/DialogPlus";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {allowToSwitchLayoutsLabel} from "./base/TypesLayoutBuilder";

const dialogContentWidth = 1200;
const dialogContentHeight = 700;
const tabVariant = "standard";

type TypeXYChartType = "grid" | "spreadsheet"

export default function DialogNewLayoutXYChart(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  formId?: MetaIdForm,
  gridId?: MetaIdGrid,
  xyChartType: TypeXYChartType,
  spreadsheetId?: MetaIdSpreadsheet,
  validationError?: IFormFieldError[]
  values?: StudioDtoLayoutXYChart,
  defaultGridLayout?: EnumDefnLayoutGridKind
  onClickOk: (values: StudioDtoLayoutXYChart) => void,
  onClose?: () => void
})
{
  const gridId = props.gridId;
  const spreadsheetId = props.spreadsheetId;
  const xyChart = props.values;
  const formStore = props.formStore;
  const formId = props.formId;
  const formReadonly = props.isFormReadOnly;
  const onClickOk = props.onClickOk;
  const onClose = props.onClose;
  const validationError = props.validationError;
  const xyChartType = props.xyChartType;
  const defaultGridLayout = props.defaultGridLayout;

  const form = formId
    ? formStore?.formMap?.map[formId]
    : undefined;
  const excludeLayoutIdSet = xyChart?.metaId
    ? [xyChart?.metaId]
    : undefined;

  const cbRef = useRef({} as IFormRef);

  const [yAxisMap, setYAxisMap] = useState<StudioMapOfChartYAxis>(getEmptyKeysAndMap());
  const [yAxisIdSet, setYAxisIdSet] = useState<MetaIdField[] | undefined>(undefined);
  const [xAxisId, setXAxisId] = useState<MetaIdField | undefined>(undefined);
  const [compositeIdSet, setCompositeIdSet] = useState<MetaIdComposite[] | undefined>(xyChartType === "grid"
    ? gridId
      ? [gridId]
      : undefined
    : undefined);
  const [xAxisMap, setXAxisMap] = useState<StudioMapOfChartXAxis>(getEmptyKeysAndMap());
  const [xAxisFieldType, setXAxisFieldType] = useState<EnumDefnCompType>();

  const fnResolver = useMemo(() => formStore
    ? fnUseStudioResolver(formStore)
    : undefined, [formStore]);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const defnForm = useMemo(() => getDefnForm(
      xyChartType,
      formId,
      compositeIdSet,
      excludeLayoutIdSet,
      defaultGridLayout,
      yAxisMap,
      xAxisId,
      yAxisIdSet,
      spreadsheetId,
      xAxisMap,
      xAxisFieldType
    ),
    [
      defaultGridLayout,
      yAxisMap,
      compositeIdSet,
      formId,
      spreadsheetId,
      xAxisId,
      xAxisMap,
      xyChartType,
      yAxisIdSet,
      xAxisFieldType
    ]
  );

  useEffect(() =>
  {
    if(yAxisIdSet && yAxisIdSet.length > 0)
    {
      const _yAxisKeys = [] as MetaIdChartYAxis[];
      const _yAxisMap = {} as Record<MetaIdChartYAxis, StudioDtoChartYAxis>;

      yAxisIdSet.forEach(fieldId =>
      {
        const fieldName = fnResolver && formId
          ? fnResolver.getFormFieldName(formId, fieldId)
          : undefined;

        const yAxisMetaId = nextMetaIdChartYAxis();

        if(fieldName)
        {
          _yAxisKeys.push(yAxisMetaId);
          _yAxisMap[yAxisMetaId] = {
            fieldId: fieldId,
            metaId: yAxisMetaId,
            label: fieldName,
            name: fieldName
          } as StudioDtoChartYAxis;
        }
      });
      setYAxisMap({
        keys: _yAxisKeys,
        map: _yAxisMap
      });
    }
    else
    {
      setYAxisMap(getEmptyKeysAndMap());
    }
  }, [yAxisIdSet]);

  useEffect(() =>
  {
    const _yAxisMap = {
      keys: [],
      map: {}
    } as StudioMapOfChartYAxis;

    if(xyChart && xyChart.yAxisMap?.keys.length)
    {
      Object.values(xyChart.yAxisMap.keys).forEach((chartYAxisId) =>
      {
        const chartAxis = xyChart.yAxisMap?.map[chartYAxisId];

        if(chartAxis?.fieldId)
        {
          const fieldName = fnResolver && formId
            ? fnResolver.getFormFieldName(formId, chartAxis.fieldId)
            : undefined;

          if(fieldName)
          {
            _yAxisMap.keys.push(chartAxis.metaId);
            _yAxisMap.map[chartAxis.metaId] = {
              fieldId: chartAxis.fieldId,
              metaId: chartAxis.metaId,
              label: fieldName,
              name: fieldName
            } as StudioDtoChartYAxis;
          }
        }
      });
    }

    setYAxisMap(_yAxisMap);

  }, [xyChart]);

  useEffect(() =>
  {
    if(form && xyChartType === "spreadsheet")
    {
      const compositeIdSet = [] as MetaIdComposite[];
      loopStudioForm(form, (composite) =>
      {
        const sectionId = (composite as StudioSection).metaId;
        if(composite.type === "section" && !compositeIdSet.includes(sectionId))
        {
          compositeIdSet.push(sectionId);
        }
      });

      setCompositeIdSet(compositeIdSet);
    }

  }, [form, xyChartType]);

  useEffect(() =>
  {
    if(xyChart)
    {
      setXAxisId(xyChart.xAxis);
    }
  }, []);

  useEffect(() =>
  {
    if(form)
    {
      let textSetVarId: MetaIdVar | undefined = undefined;
      let xAxisIdName: string | undefined = undefined;
      const defaultXAxisMap = xyChart?.xAxisMap;

      loopStudioForm(form, (_, field) =>
      {
        if(field.metaId === xAxisId)
        {
          setXAxisFieldType(field.type);
          if(field.type === "pickText")
          {
            textSetVarId = (field as StudioFieldPickText).sourceVarId;
          }
          else
          {
            xAxisIdName = field.details.name;
          }
        }
      });

      if(textSetVarId)
      {
        let _swimlane = {
          keys: [],
          map: {}
        } as StudioMapOfChartXAxis;
        const variable = formStore?.varMap?.map[textSetVarId];

        if(variable && variable.kind === "setOfText")
        {
          const setOfTextVariable = variable as StudioVarSetOfText;

          if(setOfTextVariable
            && setOfTextVariable.value
            && setOfTextVariable.value?.keys?.length)
          {
            setOfTextVariable.value.keys.forEach(key =>
            {
              const value = setOfTextVariable.value?.map[key] as StudioDtoOption;
              let swimlaneId = nextMetaIdChartXAxis();

              if(defaultXAxisMap?.map)
              {
                Object.values(defaultXAxisMap.map).forEach(swimlane =>
                {
                  if(swimlane.valueOptionId === value.metaId)
                  {
                    swimlaneId = swimlane.metaId;
                  }
                });
              }

              _swimlane.keys.push(swimlaneId);
              _swimlane.map[swimlaneId] = {
                metaId: swimlaneId,
                label: value.value,
                valueOptionId: value.metaId
              };
            });
          }
        }
        setXAxisMap(_swimlane);
      }
      else if(xAxisIdName)
      {
        let _swimlane = {
          keys: [],
          map: {}
        } as StudioMapOfChartXAxis;
        const swimlaneId = defaultXAxisMap?.keys?.[0] || nextMetaIdChartXAxis();

        _swimlane.keys.push(swimlaneId);
        _swimlane.map[swimlaneId] = {
          metaId: swimlaneId,
          label: xAxisIdName
        };

        setXAxisMap(_swimlane);
      }
      else
      {
        setXAxisMap(getEmptyKeysAndMap());
      }
    }

  }, [form, formStore?.varMap?.map, xyChart?.xAxisMap, xAxisId]);

  return (
    <DialogDefnForm
      formProps={{
        cbRef: cbRef.current,
        store: formStore,
        defnForm: defnForm,
        formReadonly: formReadonly,
        initValues: dtoToValue(xyChartType, xyChart),
        onSubmit: value => onClickOk(valueToDto(value, xyChartType, yAxisMap, xAxisMap, defaultGridLayout)),
        onWatch: (key, value) =>
        {
          if(key === fieldXAxis)
          {
            setXAxisId(value);
          }
          if(key === fieldYAxis)
          {
            if(!value || value.length === 0)
            {
              setYAxisIdSet(undefined);
            }
            else if(isArray(value))
            {
              setYAxisIdSet(value);
            }
            else
            {
              setYAxisIdSet([value]);
            }
          }
        }
      }}
      title={`${xyChart ? "Update" : "New"} ${xyChartType} layout - ${defaultGridLayout
        ? getLayoutLabel(defaultGridLayout)
        : "chart"}`}
      addMoreCheckBoxLabel={!xyChart
        ? `Add more ${xyChartType} layouts`
        : undefined}
      onClose={onClose}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
    />
  );
}

const fieldTabDetails = "tabDetails";
const fieldTabXYChart = "tabXYChart";
const fieldAxisTab = "tabAxis";

const fieldAllowToSwitchLayoutIdSet = "allowToSwitchLayoutIdSet";
const fieldXAxisSection = "xAxisSection";
const fieldYAxisSection = "yAxisSection";
const fieldXAxis = "xAxis";
const fieldYAxis = "yAxisMap";

const fieldYAxisValuesLabel = "yAxisValuesLabel";

function getDefnForm(
  xyChartType: TypeXYChartType,
  metaIdForm?: MetaIdForm,
  compositeIdSet?: MetaIdComposite[],
  excludeLayoutIdSet?: MetaId[],
  chartType?: EnumDefnLayoutGridKind,
  yAxisMap?: StudioMapOfChartYAxis,
  xAxisField?: MetaIdField,
  yAxisField?: MetaIdField[],
  spreadsheetId?: MetaIdSpreadsheet,
  xAxisMap?: StudioMapOfChartXAxis,
  xAxisFieldType?: EnumDefnCompType
)
{
  const defnDetailsSection = getDetailsSection(xyChartType, metaIdForm, excludeLayoutIdSet, spreadsheetId);

  const defnXY = getDefnXY(yAxisMap);

  const defnXAxis = getDefnXAxis(metaIdForm, compositeIdSet, chartType, yAxisField);
  const defnYAxis = getDefnYAxis(metaIdForm, compositeIdSet, chartType, xAxisField);

  const excludeNote = xAxisFieldType !== "pickText";

  const {
    swimlaneSectionKeys: xAxisSectionKeys,
    compMap: xAxisCompMap
  } = getSwimlaneCompMap(xAxisMap, excludeNote);

  const {
    swimlaneSectionKeys: yAxisSectionKeys,
    compMap: yAxisCompMap
  } = getSwimlaneCompMap(yAxisMap, true);

  return createDefaultDefnFormStudio({

    ...defnDetailsSection,

    ...defnXAxis,

    ...defnYAxis,

    [fieldTabDetails]: {
      type: "section",
      metaId: fieldTabDetails,
      name: fieldTabDetails,
      label: "Details",
      fieldIdSet: Object.keys(defnDetailsSection)
    } as DefnSection,

    ...defnXY,

    ...xAxisCompMap,

    ...yAxisCompMap,

    [fieldXAxisSection]: {
      type: "section",
      metaId: fieldXAxisSection,
      name: fieldXAxisSection,
      label: "X - axis",
      fieldIdSet: [
        ...Object.keys(defnXAxis),
        ...xAxisSectionKeys
      ]
    } as DefnSection,

    [fieldYAxisSection]: {
      type: "section",
      metaId: fieldYAxisSection,
      name: fieldYAxisSection,
      label: "Y - axis",
      fieldIdSet: [...Object.keys(defnYAxis), ...yAxisSectionKeys]
    } as DefnSection,

    [fieldAxisTab]: {
      type: "tab",
      metaId: fieldAxisTab,
      name: fieldAxisTab,
      tabIdSet: [fieldXAxisSection, fieldYAxisSection]
    } as DefnTab,

    [fieldTabXYChart]: {
      type: "section",
      metaId: fieldTabXYChart,
      name: fieldTabXYChart,
      label: "XY chart",
      fieldIdSet: [fieldAxisTab]
    } as DefnSection,

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: tabVariant,
      tabIdSet: [
        fieldTabDetails,
        fieldTabXYChart
      ]
    } as DefnTab
  });
}

function getDetailsSection(
  xyChartType: TypeXYChartType,
  metaIdForm?: MetaIdForm,
  excludeLayoutIdSet?: MetaId[],
  spreadsheetId?: MetaIdSpreadsheet)
{
  return {
    [fieldKeyName]: {
      type: "symbol",
      metaId: fieldKeyName,
      name: fieldKeyName,
      label: "Name",
      required: true
    } as DefnFieldEditable,

    [fieldKeyLabel]: {
      type: "text",
      metaId: fieldKeyLabel,
      name: fieldKeyLabel,
      label: "Label"
    } as DefnFieldEditable,

    [fieldKeyDescription]: {
      type: "paragraph",
      metaId: fieldKeyDescription,
      name: fieldKeyDescription,
      label: "Description",
      lineCountVar: 4
    } as DefnFieldParagraph,

    ...xyChartType === "grid" ? {
        [fieldAllowToSwitchLayoutIdSet]: {
          type: "studioSetOfLayoutGridId",
          metaId: fieldAllowToSwitchLayoutIdSet,
          name: fieldAllowToSwitchLayoutIdSet,
          label: allowToSwitchLayoutsLabel,
          excludeLayoutGridIdSet: excludeLayoutIdSet,
          formId: metaIdForm
        } as DefnStudioPickLayoutGridId
      }
      : {
        [fieldAllowToSwitchLayoutIdSet]: {
          type: "studioSetOfLayoutSpreadsheetId",
          metaId: fieldAllowToSwitchLayoutIdSet,
          name: fieldAllowToSwitchLayoutIdSet,
          label: allowToSwitchLayoutsLabel,
          spreadsheetId: spreadsheetId,
          excludeLayoutSpreadsheetIdSet: excludeLayoutIdSet
        } as DefnStudioPickLayoutSpreadsheetId
      }
  };
}

function getDefnXAxis(
  metaIdForm?: MetaIdForm,
  compositeIdSet?: MetaIdComposite[],
  chartType?: EnumDefnLayoutGridKind,
  yAxisField?: MetaIdField[]
)
{
  return {
    [fieldXAxis]: {
      type: "pickFieldId",
      metaId: fieldXAxis,
      name: fieldXAxis,
      label: "X axis field",
      formId: metaIdForm,
      filterFieldTypeSet: chartType === "xyChartScatterPlot"
        ? YAxisFieldType
        : XAxisFieldType,
      compositeIdSet: compositeIdSet,
      excludeFieldIdSet: yAxisField,
      required: true
    } as DefnStudioPickFieldId
  };
}

function getDefnYAxis(
  metaIdForm?: MetaIdForm,
  compositeIdSet?: MetaIdComposite[],
  chartType?: EnumDefnLayoutGridKind,
  xAxisField?: MetaIdField
)
{
  const multipleFieldPickAllow = chartType === "xyChartBarGraph" || chartType === "xyChartLineChart" || chartType
    === "xyChartScatterPlot";

  return {
    ...multipleFieldPickAllow
      ? {
        [fieldYAxis]: {
          type: "studioSetOfFieldId",
          metaId: fieldYAxis,
          name: fieldYAxis,
          label: "Y axis fields",
          formId: metaIdForm,
          filterFieldTypeSet: YAxisFieldType,
          compositeIdSet: compositeIdSet,
          excludeFieldIdSet: [xAxisField]
        } as DefnStudioPickFieldId
      }
      : {
        [fieldYAxis]: {
          type: "pickFieldId",
          metaId: fieldYAxis,
          name: fieldYAxis,
          label: "Y axis field",
          formId: metaIdForm,
          filterFieldTypeSet: YAxisFieldType,
          compositeIdSet: compositeIdSet,
          excludeFieldIdSet: [xAxisField],
          helperTextVar: {value: [SPREADSHEETS_LAYOUT_XY_CHART_Y_AXIS_FIELD]}
        } as DefnStudioPickFieldId
      }

  };
}

function getDefnXY(
  yAxisMap?: StudioMapOfChartYAxis
)
{
  return {
    ...(yAxisMap && yAxisMap.keys.length) && {
      [fieldYAxisValuesLabel]: {
        type: "label",
        metaId: fieldYAxisValuesLabel,
        name: fieldYAxisValuesLabel,
        label: "Y axis configuration",
        disabled: true
      } as DefnFieldLabel
    }
  };
}

function dtoToValue(
  xyChartType: TypeXYChartType,
  dto?: StudioDtoLayoutXYChart)
{
  if(!dto)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "XYChart")
    };
  }

  const xAxisMap = getXAxisMapDtoToValue(dto?.xAxisMap);
  const yAxisMap = getYAxisMapDtoToValue(dto);

  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", dto.name ?? "XYChart"),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", dto.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", dto.description),
    [fieldXAxis]: fnRawValueToFieldValue("pickFieldId", dto?.xAxis),
    [fieldAllowToSwitchLayoutIdSet]: xyChartType === "spreadsheet"
      ? fnRawValueToFieldValue("studioSetOfLayoutSpreadsheetId",
        (dto as StudioDtoLayoutGrid)?.allowToSwitchLayoutIdSet
      )
      : fnRawValueToFieldValue("studioSetOfLayoutGridId", (dto as StudioDtoLayoutGrid)?.allowToSwitchLayoutIdSet),
    ...xAxisMap,
    ...yAxisMap
  };
}

function valueToDto(
  values: FieldValues,
  xyChartType: TypeXYChartType,
  _yAxisMap?: StudioMapOfChartYAxis,
  _xAxisMap?: StudioMapOfChartXAxis,
  defaultGridLayout?: EnumDefnLayoutGridKind
): StudioDtoLayoutXYChart
{
  const xAxisMap = getXAxisMapValueToDto(values, _xAxisMap);
  const yAxisMap = getYAxisMapValueToDto(values, _yAxisMap);

  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    label: fnFieldValueToRawValue("text", values[fieldKeyLabel]),
    description: fnFieldValueToRawValue("text", values[fieldKeyDescription]),
    kind: defaultGridLayout,
    allowToSwitchLayoutIdSet: xyChartType === "spreadsheet"
      ? fnFieldValueToRawValue("studioSetOfLayoutSpreadsheetId", values[fieldAllowToSwitchLayoutIdSet])
      : fnFieldValueToRawValue("studioSetOfLayoutGridId", values[fieldAllowToSwitchLayoutIdSet]),
    xAxis: fnFieldValueToRawValue("pickFieldId", values[fieldXAxis]),
    yAxisMap: yAxisMap,
    xAxisMap: xAxisMap
  } as StudioDtoLayoutXYChart;

}

function getXAxisMapValueToDto(
  values: FieldValues,
  swimlane?: StudioMapOfChartXAxis
): StudioMapOfSwimlane
{
  const swimlaneMap = {
    keys: [],
    map: {}
  } as StudioMapOfSwimlane;

  if(swimlane && swimlane.keys.length)
  {
    const swimlaneKeys = swimlane.keys;
    const _swimlaneMap = {} as Record<MetaIdSwimlane, StudioDtoSwimlane>;

    Object.values(swimlane.map).forEach(option =>
    {
      const optionId = option.metaId;
      const swimLaneColorVarKey = getSwimlaneColorKeyVar(optionId);
      const swimLaneColorKey = getSwimlaneColorKey(optionId);
      const swimLaneLabelKey = getSwimlaneLabelKey(optionId);

      const optionLabelValue = fnFieldValueToRawValue("text", values[swimLaneLabelKey]);
      const colorVarId = fnFieldValueToRawValue("pickVarId", values[swimLaneColorVarKey]);
      const color = fnFieldValueToRawValue("studioBuildColor", values[swimLaneColorKey]) as DefnDtoColor;

      _swimlaneMap[optionId] = {
        label: optionLabelValue,
        colorVarId: colorVarId,
        color: hasValues(color) ? color : undefined,
        metaId: optionId,
        valueOptionId: option.valueOptionId
      } as StudioDtoSwimlane;

    });

    swimlaneMap.keys = swimlaneKeys;
    swimlaneMap.map = _swimlaneMap;
  }

  return swimlaneMap;

}

function getYAxisMapValueToDto(
  values: FieldValues,
  yAxisMap?: StudioMapOfChartYAxis
): StudioMapOfChartYAxis | undefined
{
  const chartAxisMap = {
    keys: [],
    map: {}
  } as StudioMapOfChartYAxis;

  if(yAxisMap && yAxisMap.keys.length)
  {
    const chartAxisKeys = yAxisMap.keys;
    const _chartAxisMap = {} as Record<MetaIdChartYAxis, StudioDtoChartYAxis>;

    Object.values(yAxisMap.map).forEach(yAxis =>
    {
      const yAxisId = yAxis.metaId;

      const swimLaneColorVarKey = getSwimlaneColorKeyVar(yAxisId);
      const swimLaneColorKey = getSwimlaneColorKey(yAxisId);
      const swimLaneLabelKey = getSwimlaneLabelKey(yAxisId);

      const yAxisLabelValue = fnFieldValueToRawValue("text", values[swimLaneLabelKey]);
      const colorVarId = fnFieldValueToRawValue("pickVarId", values[swimLaneColorVarKey]);
      const color = fnFieldValueToRawValue("studioBuildColor", values[swimLaneColorKey]);

      _chartAxisMap[yAxisId] = {
        label: yAxisLabelValue,
        colorVarId: colorVarId,
        color: color,
        metaId: yAxisId,
        fieldId: yAxis.fieldId
      } as StudioDtoChartYAxis;

    });

    chartAxisMap.keys = chartAxisKeys;
    chartAxisMap.map = _chartAxisMap;
  }

  return chartAxisMap;
}

function getXAxisMapDtoToValue(
  xAxisMap?: StudioMapOfChartXAxis
): FieldValues
{
  const filteredSwimlaneMap = {} as FieldValues;

  if(xAxisMap && xAxisMap.keys?.length)
  {
    Object.values(xAxisMap.keys).forEach(swimlaneId =>
    {
      const swimlane = xAxisMap?.map[swimlaneId];

      if(swimlane)
      {
        const colorVarKey = getSwimlaneColorKeyVar(swimlaneId);
        const colorKey = getSwimlaneColorKey(swimlaneId);
        const labelValueKey = getSwimlaneLabelKey(swimlaneId);

        filteredSwimlaneMap[colorVarKey] = fnRawValueToFieldValue("pickVarId", swimlane.colorVarId);
        filteredSwimlaneMap[colorKey] = fnRawValueToFieldValue("studioBuildColor", swimlane.color);
        filteredSwimlaneMap[labelValueKey] = fnRawValueToFieldValue("text", swimlane.label);
      }
    });
  }

  return filteredSwimlaneMap;
}

function getYAxisMapDtoToValue(
  xyChart?: StudioDtoLayoutXYChart
): FieldValues
{
  const yAxisMap = xyChart?.yAxisMap;
  const chartType = xyChart?.kind;

  const chartAxisMap = {} as FieldValues;
  const fieldIdSet = [] as MetaIdField[];
  const multipleFiledPick = chartType === "xyChartBarGraph" || chartType === "xyChartLineChart" || chartType
    === "xyChartScatterPlot";

  if(yAxisMap && yAxisMap.keys?.length)
  {
    Object.values(yAxisMap.keys).forEach(chartAxisId =>
    {
      const chartAxis = yAxisMap?.map[chartAxisId];

      if(chartAxis?.fieldId)
      {
        const colorVarKey = getSwimlaneColorKeyVar(chartAxisId);
        const colorKey = getSwimlaneColorKey(chartAxisId);
        const labelValueKey = getSwimlaneLabelKey(chartAxisId);

        chartAxisMap[colorVarKey] = fnRawValueToFieldValue("pickVarId", chartAxis.colorVarId);
        chartAxisMap[colorKey] = fnRawValueToFieldValue("studioBuildColor", chartAxis.color);
        chartAxisMap[labelValueKey] = fnRawValueToFieldValue("text", chartAxis.label);

        fieldIdSet.push(chartAxis.fieldId);
      }
    });
  }

  chartAxisMap[fieldYAxis] = multipleFiledPick
    ? fnRawValueToFieldValue("studioSetOfFieldId", fieldIdSet)
    : fnRawValueToFieldValue("pickFieldId", fieldIdSet[0]);

  return chartAxisMap;
}
