import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {StudioVarMap} from "../../../api/meta/base/dto/StudioVarMap";
import {EnumStudioVarKind} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {IResolveFuncs} from "../../../base/plus/StudioPlus";
import {TypeStudioFilterType} from "../../../base/plus/StudioPlus";
import {selectCacheStudioEntState} from "../../../base/plus/StudioPlus";
import {getVariablesUiItemsById} from "../../../base/plus/StudioPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntVariables extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entVariableList;
  }

  addVariable(entId: EntId, variable: StudioVar)
  {
    Srvc.cache.studio.ent.variables.addEntVariable(entId, variable);
  }

  loadVariableList(
    entId: EntId,
    listName: string,
    varMap: StudioVarMap,
    readOnly: boolean,
    usageFilter?: TypeStudioFilterType,
    fnResolve?: IResolveFuncs
  )
  {
    const {
      uiItemIds,
      uiItemsById
    } = getVariablesUiItemsById(varMap, false, readOnly, fnResolve);
    const rootState = store.getState();
    const filterUiItemIds = uiItemIds.filter(varId =>
    {
      const variable = varMap.map[varId];
      if(variable)
      {
        const modules = variable.details.modules;

        if(!Srvc.studio.ent.filterListByTag(entId, modules))
        {
          return false;
        }

        //variable usages filter
        const variableUsageCount = selectCacheStudioEntState(rootState, entId)?.usageMap?.varUsageMap[varId];
        if(!variableUsageCount && !this.isUserSetting(variable.kind))
        {
          const primary = (uiItemsById[varId] as IListItemAPSA).primary;
          if(primary)
          {
            primary.color = "textDisabled";
          }
        }

        if(usageFilter)
        {
          if(this.isUserSetting(variable.kind) && usageFilter === "SingleUse")
          {
            return true;
          }
          switch(usageFilter)
          {
            case "SingleUse":
              return variableUsageCount === 1;
            case "MultiUse":
              return (variableUsageCount || 0) > 1;
            case "Unused":
              return (!variableUsageCount && !this.isUserSetting(variable.kind));
            case "All":
              return true;
          }
        }
      }
      return true;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: filterUiItemIds
    }));
  }

  copyEntVariable(entId: EntId, varId: MetaIdVar)
  {
    Srvc.cache.studio.ent.variables.copyEntVariable(entId, varId);
  }

  removeEntVariable(entId: EntId, varId: MetaIdVar)
  {
    Srvc.cache.studio.ent.variables.removeEntVariable(entId, varId);
  }

  duplicateEntVariable(entId: EntId, varId: MetaIdVar)
  {
    Srvc.cache.studio.ent.variables.duplicateEntVariable(entId, varId);
  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    Srvc.cache.studio.ent.variables.setUsageFilter(entId, filterType);
  }

  private isUserSetting(kind: EnumStudioVarKind)
  {
    return kind === "userSetting";
  }
}
