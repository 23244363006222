import {isEmpty} from "lodash";
import {isArray} from "lodash";
import {UserFieldTreeBuilder} from "../../nucleus/form/viewer/raw/FieldRawTreeBuilder";
import {ID_SEP} from "../types/TypesTree";
import {ITreeNode} from "../types/TypesTree";

export function flatListToTree(keyArray: string[]): ITreeNode[]
{
  const nodeIdMap = new Map<string, ITreeNode>();

  // loop on input to produce output
  keyArray
  .forEach(key =>
  {
    // fill nodes
    const nodeIds = getNodeIds(key);
    nodeIds.forEach(nodeId =>
    {
      ensureNode(nodeIdMap, nodeId);
    });
  });

  // convert nodeIdMap to rootNodes
  const rootNodes: ITreeNode[] = [];

  for(const [key, value] of nodeIdMap.entries())
  {
    if(key.indexOf(ID_SEP) === -1)
    {
      rootNodes.push(value);
    }
  }

  return rootNodes;
}

// key = nucleus/atom/dialog/dialogAtom
// return = dialogAtom
function getNodeName(nodeId: string): string
{
  const lastIndex = nodeId.lastIndexOf(ID_SEP);
  if(lastIndex !== -1)
  {
    return nodeId.slice(lastIndex + 1);
  }
  return nodeId;
}

// key = nucleus/atom/dialog/dialogAtom/TestDialogAtom
// return = ["nucleus", "nucleus/atom", "nucleus/atom/dialog", "nucleus/atom/dialog/dialogAtom"]
export function getNodeIds(key: string): string[]
{
  const list = key.split(ID_SEP);

  const nodeIds: string[] = [];
  let prefix = "";

  for(let i = 0; i < list.length - 1; i++)
  {
    const nodeId = prefix.length > 0 ? (prefix + ID_SEP + list[i]) : list[i];
    nodeIds.push(nodeId);
    prefix = nodeId;
  }

  return nodeIds;
}

// nodeId = nucleus/atom/dialog/dialogAtom
// return = nucleus/atom/dialog
function getParentNodeId(nodeId: string): string | undefined
{
  const lastIndex = nodeId.lastIndexOf(ID_SEP);
  if(lastIndex !== -1)
  {
    return nodeId.slice(0, lastIndex);
  }
  return undefined;
}

function ensureNode(nodeIdMap: Map<string, ITreeNode>, nodeId: string)
{
  let node = nodeIdMap.get(nodeId);
  if(!node)
  {
    node = {
      name: getNodeName(nodeId),
      id: nodeId
    } as ITreeNode;

    const parentNodeId = getParentNodeId(nodeId);
    if(parentNodeId)
    {
      const parent = nodeIdMap.get(parentNodeId);
      if(!parent)
      {
        throw new Error("Should have a parent");
      }

      if(!parent.children)
      {
        parent.children = [];
      }

      parent.children.push(node);
    }

    nodeIdMap.set(nodeId, node);
  }
}

export function isValidTreeNodeList(treeNodeList: ITreeNode[])
{
  return treeNodeList.every((treeNode) => isValidTreeNode(treeNode));
}

export function isValidTreeNode(treeNode: ITreeNode): boolean
{
  const userField = treeNode?.userField as UserFieldTreeBuilder;

  if(!treeNode?.name || !treeNode?.id)
  {
    return false;
  }
  if(treeNode?.children !== undefined && !isArray(treeNode?.children))
  {
    return false;
  }
  if(isEmpty(userField) || !userField.name || !userField.metaId)
  {
    return false;
  }
  if(treeNode?.children !== undefined)
  {
    return treeNode?.children.every((_treeNode) => isValidTreeNode(_treeNode));
  }
  return true;
}

