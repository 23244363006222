import {useState} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldSetOfRole} from "../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {StudioDtoUserConditionStatement} from "../../api/meta/base/dto/StudioDtoUserConditionStatement";
import {ValidationResult} from "../../api/meta/base/dto/ValidationResult";
import {EnumArrayDefnUserContext} from "../../api/meta/base/Types";
import {EnumDefnKindSetOfUser} from "../../api/meta/base/Types";
import {EnumArrayDefnRoles} from "../../api/meta/base/Types";
import {MetaIdVisibilityCondition} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {getFormFieldsErrorList} from "../../base/plus/StudioPlus";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {EnumStudioSearchPathKeys} from "../../base/types/TypesStudio";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldSetOfUserKind = "setOfUserKind";
const fieldSetOfUserRoleIdSet = "setOfUserRoleIdSet";
const fieldSetOfUserFieldIdSet = "setOfUserFieldIdSet";
const fieldSetOfUserContextSet = "setOfUserContextSet";
const fieldSetOfUsersDerivedRoleIdSet = "setOfUsersDerivedRoleIdSet";

export default function DialogNewSetOfUsers(props: {
  sourceFormId?: MetaIdForm,
  formStore: FormStore,
  isFormReadOnly?: boolean,
  values?: StudioDtoUserConditionStatement,
  onClickOk: (values: StudioDtoUserConditionStatement) => void,
  onClose?: () => void,
  validationResult?: ValidationResult,
  metaId?: MetaIdField,
  nodeId?: MetaIdVisibilityCondition
})
{
  const values = props.values;
  const onClickSubmit = props.onClickOk;
  const formId = props.sourceFormId;
  const validationResult = props.validationResult;
  const nodeId = props.nodeId;
  const metaId = props.metaId;

  const cbRef = useRef({} as IFormRef);

  const errorList = useMemo(() =>
  {
    return getFormFieldsErrorList(metaId as EnumStudioSearchPathKeys, nodeId, validationResult, 5);
  }, [metaId, nodeId, validationResult]);

  const [pickType, setPickType] = useState<EnumDefnKindSetOfUser | undefined>(values?.kind);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: errorList
  });

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(key === fieldSetOfUserKind)
    {
      if(value)
      {
        setPickType(value as EnumDefnKindSetOfUser);
      }
      else
      {
        setPickType(undefined);
      }

      if(value as EnumDefnKindSetOfUser === "roles")
      {
        cbRef.current.setValue(fieldSetOfUserFieldIdSet, null);
        cbRef.current.setValue(fieldSetOfUserContextSet, null);
      }
      if(value as EnumDefnKindSetOfUser === "fields")
      {
        cbRef.current.setValue(fieldSetOfUserRoleIdSet, null);
        cbRef.current.setValue(fieldSetOfUserContextSet, null);
      }
      if(value as EnumDefnKindSetOfUser === "context")
      {
        cbRef.current.setValue(fieldSetOfUserFieldIdSet, null);
        cbRef.current.setValue(fieldSetOfUserContextSet, null);
      }
    }

  }, []);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} set of users`}
      formProps={{
        store: props.formStore,
        defnForm: getDefnForm(formId, pickType),
        formReadonly: props.isFormReadOnly,
        cbRef: cbRef.current,
        onWatch: onWatch,
        initValues: dtoToValue(values),
        onSubmit: values =>
        {
          onClickSubmit(valueToDto(values));
        }
      }}
      addMoreCheckBoxLabel={!values
        ? "Add more set of users"
        : undefined}
      onClose={props.onClose}
      contentHeight={340}
      contentWidth={500}
    />
  );
}

function valueToDto(values: FieldValues): StudioDtoUserConditionStatement
{

  return {
    kind: fnFieldValueToRawValue("enumSetOfUserKind", values[fieldSetOfUserKind]),
    roleIdSet: fnFieldValueToRawValue("setOfRole", values[fieldSetOfUserRoleIdSet]),
    fieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldSetOfUserFieldIdSet]),
    userContextSet: fnFieldValueToRawValue("setOfText", values[fieldSetOfUserContextSet]),
    derivedRoleIdSet: fnFieldValueToRawValue("setOfText", values[fieldSetOfUsersDerivedRoleIdSet])
  } as StudioDtoUserConditionStatement;
}

function dtoToValue(dto?: StudioDtoUserConditionStatement)
{
  if(dto)
  {
    return {
      [fieldSetOfUserKind]: fnRawValueToFieldValue("enumSetOfUserKind", dto.kind),
      [fieldSetOfUserRoleIdSet]: fnRawValueToFieldValue("setOfRole", dto.roleIdSet),
      [fieldSetOfUserFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", dto.fieldIdSet),
      [fieldSetOfUserContextSet]: fnRawValueToFieldValue("setOfText", dto.userContextSet),
      [fieldSetOfUsersDerivedRoleIdSet]: fnRawValueToFieldValue("setOfText", dto.derivedRoleIdSet)
    };
  }
}

function getDefnForm(
  formId?: MetaIdForm,
  pickType?: EnumDefnKindSetOfUser
)
{
  return createDefaultDefnFormStudio({

    [fieldSetOfUserKind]: {
      type: "enumSetOfUserKind",
      name: fieldSetOfUserKind,
      metaId: fieldSetOfUserKind,
      label: "Kind",
      required: true
    } as DefnFieldPickEnum,

    [fieldSetOfUserRoleIdSet]: {
      type: "setOfRole",
      name: fieldSetOfUserRoleIdSet,
      metaId: fieldSetOfUserRoleIdSet,
      label: "Roles",
      required: true
    } as DefnFieldSetOfRole,

    [fieldSetOfUserFieldIdSet]: {
      type: "studioSetOfFieldId",
      name: fieldSetOfUserFieldIdSet,
      metaId: fieldSetOfUserFieldIdSet,
      label: "Fields",
      formId: formId,
      required: true,
      filterFieldTypeSet: ["pickUser", "setOfUser", "userId"]
    } as DefnStudioPickFieldId,

    [fieldSetOfUserContextSet]: {
      type: "setOfText",
      name: fieldSetOfUserContextSet,
      metaId: fieldSetOfUserContextSet,
      label: "Contexts",
      required: true,
      optionMap: arrayToMapOfOption(EnumArrayDefnUserContext)
    } as DefnFieldPickEnum,

    [fieldSetOfUsersDerivedRoleIdSet]: {
      type: "setOfText",
      name: fieldSetOfUsersDerivedRoleIdSet,
      metaId: fieldSetOfUsersDerivedRoleIdSet,
      label: "Derived roles",
      optionMap: arrayToMapOfOption(EnumArrayDefnRoles.filter((value) => value !== "$Public"))
    } as DefnFieldPickText,

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: pickType === "roles" ? [
        fieldSetOfUserKind,
        fieldSetOfUserRoleIdSet,
        fieldSetOfUsersDerivedRoleIdSet
      ] : pickType === "fields" ? [
        fieldSetOfUserKind,
        fieldSetOfUserFieldIdSet,
        fieldSetOfUsersDerivedRoleIdSet
      ] : pickType === "context" ? [
        fieldSetOfUserKind,
        fieldSetOfUserContextSet,
        fieldSetOfUsersDerivedRoleIdSet
      ] : [
        fieldSetOfUserKind,
        fieldSetOfUsersDerivedRoleIdSet

      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}
