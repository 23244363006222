import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldLocation} from "../../../api/meta/base/dto/DefnFieldLocation";
import {FieldValueLocation} from "../../../api/meta/base/dto/FieldValueLocation";
import {StudioVarLocation} from "../../../api/meta/base/dto/StudioVarLocation";

export function fnVariableBuilderLocation(fieldValueLocation: string)
{
  function getVarComp()
  {
    return {
      [fieldValueLocation]: {
        type: "location",
        metaId: fieldValueLocation,
        name: "Value",
        required: true
      } as DefnFieldLocation
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarLocation
  )
  {
    const varValue = variable.value;
    if(!varValue)
    {
      return;
    }

    valueMap[fieldValueLocation] = varValue;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const location = (valueMap[fieldValueLocation] as FieldValueLocation | undefined)?.value;
    if(location)
    {
      return {
        value: {
          value: location
        }
      } as StudioVarLocation;
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
