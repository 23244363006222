import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigSpreadsheetRowExpiry} from "../../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {SigSpreadsheetRowCommentCount} from "../../../../api/home/main/sig/SigSpreadsheetRowCommentCount";
import {SpreadsheetPartitionId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {random} from "../../../../base/plus/StringPlus";
import {ICacheHomeAppSpreadsheetRowSlice} from "./TypesCacheSpreadsheetRow";
import {ICacheHomeAppSpreadsheetRow} from "./TypesCacheSpreadsheetRow";

type ActionSpreadsheetRowRemove = {
  entId: EntId,
  rowId: RowId
};
type ActionSpreadsheetRowsRemove = {
  entId: EntId,
  rowIds: RowId[]
};

type ActionRowIdToSsIdMapInsert = {
  entId: EntId,
  rowId: RowId,
  spreadsheetId: MetaIdSpreadsheet
};

type ActionSsPartitionIdToRowIdMapInsert = {
  entId: EntId,
  spreadsheetPartitionId: SpreadsheetPartitionId
  rowId: RowId,
};

type ActionRowIdToSsIdMapBulkInsert = {
  entId: EntId,
  rowIds: RowId[],
  spreadsheetId: MetaIdSpreadsheet
};

type ActionSsPartitionIdToRowIdMapBulkInsert = {
  entId: EntId,
  rowMap: Record<RowId, SigSpreadsheetRow>;
};

type ActionSsRowAdd = {
  entId: EntId,
  rowId: RowId,
  sig: SigSpreadsheetRow
};
type ActionSsBulkRowAdd = {
  entId: EntId,
  rowMap?: Record<RowId, SigSpreadsheetRow>;
};

type ActionSsRowExpiryAdd = {
  entId: EntId,
  rowId: RowId,
  sig: SigSpreadsheetRowExpiry
};

type ActionSsRowExpiryRemove = {
  entId: EntId,
  spreadsheetPartitionId: SpreadsheetPartitionId,
};

type ActionSsRowsExpiryRemove = {
  entId: EntId,
  spreadsheetPartitionIds: SpreadsheetPartitionId[],
};

type ActionSsRowCommentCount = {
  entId: EntId,
  rowId: RowId,
  sig: SigSpreadsheetRowCommentCount
};

export const sliceCacheSpreadsheetRow = createSlice({
  name: "cacheSpreadsheetRow",
  initialState: {
    entSpreadsheetRowMap: {} as Record<EntId, ICacheHomeAppSpreadsheetRow>
  } as ICacheHomeAppSpreadsheetRowSlice,
  reducers: {
    setCacheSsRow: (state: ICacheHomeAppSpreadsheetRowSlice, action: PayloadAction<ActionSsRowAdd>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      fnSetIfNotExistEntSpreadsheetRowMap(state, entId);

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      if(sigEntFormResult)
      {
        sigEntFormResult.spreadsheetRowMap[payload.rowId] = payload.sig;
      }
      state.entSpreadsheetRowMap[entId].version = payload.sig.version;
    },
    setBulkCacheSsRow: (state: ICacheHomeAppSpreadsheetRowSlice, action: PayloadAction<ActionSsBulkRowAdd>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      fnSetIfNotExistEntSpreadsheetRowMap(state, entId);

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      if(sigEntFormResult)
      {
        sigEntFormResult.spreadsheetRowMap = {
          ...sigEntFormResult.spreadsheetRowMap,
          ...payload.rowMap
        };
      }
      state.entSpreadsheetRowMap[entId].version = random();
    },
    setCacheSsRowExpiry: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSsRowExpiryAdd>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      fnSetIfNotExistEntSpreadsheetRowMap(state, entId);
      const spreadsheetRowMapElement = state.entSpreadsheetRowMap[entId];
      spreadsheetRowMapElement.spreadsheetRowExpiryMap[payload.rowId] = payload.sig;
      fnUpdateVersion(spreadsheetRowMapElement);
    },

    setIfExistCacheSsRowCommentCount: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSsRowCommentCount>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;

      const sigEntFormResult = state.entSpreadsheetRowMap[entId].spreadsheetRowMap[payload.rowId];
      if(!sigEntFormResult)
      {
        return;
      }

      sigEntFormResult.rowCommentCount = payload.sig;
    },
    setCacheSsRowIdToSsIdMap: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionRowIdToSsIdMapInsert>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      fnSetIfNotExistEntSpreadsheetRowMap(state, entId);

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      sigEntFormResult.rowIdToSsIdMap[payload.rowId] = payload.spreadsheetId;
      fnUpdateVersion(sigEntFormResult);
    },

    setCacheSsPartitionIdToRowIdMap: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSsPartitionIdToRowIdMapInsert>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      fnSetIfNotExistEntSpreadsheetRowMap(state, entId);

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];

      sigEntFormResult.ssPartitionIdToRowIdMap[payload.spreadsheetPartitionId] = payload.rowId;
      fnUpdateVersion(sigEntFormResult);
    },

    setBulkCacheSsRowIdToSsIdMap: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionRowIdToSsIdMapBulkInsert>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      fnSetIfNotExistEntSpreadsheetRowMap(state, entId);
      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      if(sigEntFormResult)
      {
        sigEntFormResult.rowIdToSsIdMap = {
          ...sigEntFormResult.rowIdToSsIdMap,
          ...payload.rowIds.reduce((acc, rowId) =>
          {
            acc[rowId] = payload.spreadsheetId;
            return acc;
          }, {} as Record<RowId, MetaIdSpreadsheet>)
        };
      }
      fnUpdateVersion(sigEntFormResult);
    },

    setBulkCacheSsPartitionIdToRowIdMap: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSsPartitionIdToRowIdMapBulkInsert>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      fnSetIfNotExistEntSpreadsheetRowMap(state, entId);

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      if(sigEntFormResult)
      {
        const newSsPartitionIdToRowIdMap = Object.values(payload.rowMap).reduce((acc, spreadsheetRow) =>
        {
          const rowId = spreadsheetRow.formValue?.rowId;
          if(rowId)
          {
            acc[spreadsheetRow.spreadsheetPartitionId] = rowId;
          }
          return acc;
        }, {} as Record<RowId, SpreadsheetPartitionId>);

        sigEntFormResult.ssPartitionIdToRowIdMap = {
          ...sigEntFormResult.ssPartitionIdToRowIdMap,
          ...newSsPartitionIdToRowIdMap
        };
      }
      fnUpdateVersion(sigEntFormResult);
    },
    removeBulkCacheSsRowExpiry: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSsRowsExpiryRemove>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;

      payload.spreadsheetPartitionIds.forEach(spreadsheetPartitionId =>
      {
        const sigEntFormResult = state.entSpreadsheetRowMap[entId];
        if(sigEntFormResult && sigEntFormResult.spreadsheetRowExpiryMap)
        {
          delete sigEntFormResult.spreadsheetRowExpiryMap[spreadsheetPartitionId];
        }
        fnUpdateVersion(sigEntFormResult);
      });
    },

    removeCacheSsRowExpiry: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSsRowExpiryRemove>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      const spreadsheetPartitionId = payload.spreadsheetPartitionId;

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      if(sigEntFormResult && sigEntFormResult.spreadsheetRowExpiryMap)
      {
        delete sigEntFormResult.spreadsheetRowExpiryMap[spreadsheetPartitionId];
        fnUpdateVersion(sigEntFormResult);
      }
    },

    removeBulkCacheSsRow: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSpreadsheetRowsRemove>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      if(sigEntFormResult)
      {
        payload.rowIds.forEach((rowId) =>
        {
          const row = sigEntFormResult.spreadsheetRowMap[rowId];
          if(row && row.spreadsheetPartitionId)
          {
            delete sigEntFormResult.ssPartitionIdToRowIdMap[row.spreadsheetPartitionId];
            delete sigEntFormResult.spreadsheetRowExpiryMap[rowId];
          }
          delete sigEntFormResult.spreadsheetRowMap[rowId];
          delete sigEntFormResult.rowIdToSsIdMap[rowId];
        });
        fnUpdateVersion(sigEntFormResult);
      }
    },
    removeCacheSsRow: (
      state: ICacheHomeAppSpreadsheetRowSlice,
      action: PayloadAction<ActionSpreadsheetRowRemove>) =>
    {
      const payload = action.payload;
      const entId = payload.entId;
      const rowId = payload.rowId;

      const sigEntFormResult = state.entSpreadsheetRowMap[entId];
      if(sigEntFormResult)
      {
        const row = sigEntFormResult.spreadsheetRowMap[rowId];
        if(row && row.spreadsheetPartitionId)
        {
          delete sigEntFormResult.ssPartitionIdToRowIdMap[row.spreadsheetPartitionId];
          delete sigEntFormResult.spreadsheetRowExpiryMap[rowId];
        }
        delete sigEntFormResult.spreadsheetRowMap[rowId];
        delete sigEntFormResult.rowIdToSsIdMap[rowId];
        fnUpdateVersion(sigEntFormResult);
      }
    }
  }
});

function fnUpdateVersion(state: ICacheHomeAppSpreadsheetRow)
{
  state.version = random();
}

export const {
  setCacheSsRow,
  setCacheSsRowIdToSsIdMap,
  setCacheSsPartitionIdToRowIdMap,
  setCacheSsRowExpiry,
  setIfExistCacheSsRowCommentCount,

  setBulkCacheSsRow,
  setBulkCacheSsRowIdToSsIdMap,
  setBulkCacheSsPartitionIdToRowIdMap,
  removeBulkCacheSsRow,
  removeBulkCacheSsRowExpiry,

  removeCacheSsRowExpiry,
  removeCacheSsRow
} = sliceCacheSpreadsheetRow.actions;

function fnSetIfNotExistEntSpreadsheetRowMap(state: ICacheHomeAppSpreadsheetRowSlice, entId: EntId)
{
  if(!state.entSpreadsheetRowMap[entId])
  {
    state.entSpreadsheetRowMap[entId] = {
      spreadsheetRowMap: {},
      spreadsheetRowExpiryMap: {},
      rowIdToSsIdMap: {},
      ssPartitionIdToRowIdMap: {},
      version: random()
    } as ICacheHomeAppSpreadsheetRow;
  }
}
