import {GroupId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {toComboId} from "../../../base/types/TypesComboId";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcHomeMainHeader extends ISrvc
{
  //region rpc
  setGroupInfo(entId: EntId, groupId: GroupId)
  {
    const comboId = toComboId(entId, groupId);
    const group = store.getState().cache.app.group.groupInfo[comboId];
    Srvc.cache.app.group.wsocGroupInfoGet(entId, groupId, group?.version);
  }

  //endregion

}
