import {SvgIconTypeMap} from "@mui/material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {isArray} from "lodash";
import {cloneDeep} from "lodash";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioMapOfDtoOption} from "../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {MetaId} from "../../api/meta/base/Types";
import {logDebug} from "../util/AppLog";
import {toLabel} from "./StringPlus";

export interface Delta
{
  inserts: string[];
  deletes: string[];
}

export function hasDelta(delta: Delta): boolean
{
  return delta.inserts.length > 0 || delta.deletes.length > 0;
}

export function calcDeltaArray(oldArray: string[], newArray: string[]): Delta
{
  return calcDeltaSet(createSet(oldArray), createSet(newArray));
}

export function calcDeltaSet(oldSet: Set<string>, newSet: Set<string>): Delta
{
  const inserts: string[] = [];
  newSet.forEach(newItem =>
  {
    if(!oldSet.has(newItem))
    {
      inserts.push(newItem);
    }
  });

  const deletes: string[] = [];
  oldSet.forEach(oldItem =>
  {
    if(!newSet.has(oldItem))
    {
      deletes.push(oldItem);
    }
  });

  return {
    inserts: inserts,
    deletes: deletes
  } as Delta;
}

export function putItem<T>(array: T[], item: T)
{
  if(!array.includes(item))
  {
    array.push(item);
  }
}

export function removeItem<T>(array: T[], item: T): void
{
  const index = array.indexOf(item);
  if(index >= 0)
  {
    array.splice(index, 1);
  }
}

export function removeItems<T>(array: T[], items: T[]): void
{
  items.forEach(item => removeItem(array, item));
}

export function createSet<T>(arrays1?: T[], arrays2?: T[], arrays3?: T[], arrays4?: T[]): Set<T>
{
  const set = new Set<T>();

  if(arrays1)
  {
    arrays1.forEach(item => set.add(item));
  }

  if(arrays2)
  {
    arrays2.forEach(item => set.add(item));
  }

  if(arrays3)
  {
    arrays3.forEach(item => set.add(item));
  }

  if(arrays4)
  {
    arrays4.forEach(item => set.add(item));
  }

  return set;
}

export function removeAllSet<T>(fromSet: Set<T>, thiSet: Set<T>)
{
  thiSet.forEach(item => fromSet.delete(item));
}

export function mergeArraySets<T>(set1: T[], set2: T[]): T[]
{
  const result = [...set1];
  set2.forEach(item =>
  {
    if(!set1.includes(item))
    {
      result.push(item);
    }
  });
  return result;
}

export function arrayToOptions(nameList: string[], disableToLabel?: boolean): DefnDtoOption[]
{
  return nameList.map(name =>
  {
    return {
      value: disableToLabel
        ? name
        : toLabel(name),
      metaId: name
    };
  });
}

export function arrayToMapOfOption(
  optionList: string[],
  disableToLabel?: boolean,
  suffix?: string): DefnStudioMapOfDtoOption
{
  const mapOfOption = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  optionList.forEach(option =>
  {
    let value = suffix ? `${option} ${suffix}` : option;
    value = disableToLabel ? value : toLabel(value);
    value = handleEnumSpecialCase(value);

    mapOfOption.keys.push(option);
    mapOfOption.map[option] = {
      metaId: option,
      value: value
    };
  });

  return mapOfOption;
}

function handleEnumSpecialCase(value: string)
{
  if(value === "Ios")
  {
    return "iOS";
  }
  if(value === "Heart 1")
  {
    return "1 heart";
  }
  if(value === "Thumbs 2")
  {
    return "2 thumbs";
  }
  if(value === "Star 3")
  {
    return "3 star";
  }
  if(value === "Star 4")
  {
    return "4 star";
  }
  if(value === "Star 5")
  {
    return "5 star";
  }
  else
  {
    return value;
  }
}

export function optionsToMapOfOption(optionList?: DefnDtoOption[]): DefnStudioMapOfDtoOption
{
  const mapOfOption = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  optionList?.forEach(option =>
  {
    mapOfOption.keys.push(option.metaId);
    mapOfOption.map[option.metaId] = option;
  });

  return mapOfOption;
}

export function mapToOptions(dtoOption?: DefnStudioMapOfDtoOption): DefnDtoOption[] | undefined
{
  return dtoOption?.keys && dtoOption.keys.length > 0
    ? dtoOption.keys.map(key => dtoOption.map[key])
    : undefined;
}

export function arrayToRecord(arr: string[]): Record<string, string>
{
  const obj: Record<string, string> = {};
  for(const item of arr)
  {
    obj[item] = item;
  }
  return obj;
}

export function mapToRecord(entries: IterableIterator<[string, string]>)
{
  const obj: Record<string, string> = {};
  for(const [key, value] of entries)
  {
    obj[key] = value;
  }
  return obj;
}

export function groupByKey(array: any[], key: string)
{
  return array
  .reduce((hash, obj) =>
  {
    if(obj[key] === undefined)
    {
      return hash;
    }
    return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)});
  }, {});
}

export function flipRecord(record: Record<string, string>)
{
  const flippedRecord = {} as Record<string, string>;
  for(const recordKey in record)
  {
    flippedRecord[record[recordKey]] = recordKey;
  }
  return flippedRecord;
}

export function getIcon(iconName: string): Promise<OverridableComponent<SvgIconTypeMap>>
{
  // I tried to use direct import, but it's not working e.g. `@mui/icons-material/${iconName}`
  return new Promise((resolve, reject) =>
    import(`@mui/icons-material`).then(icons =>
    {
      resolve(icons[iconName] as OverridableComponent<SvgIconTypeMap>);
    }).catch(reject)
  );
}

//use this function to sort alphabetic sort option of pick field
export function getSortedOptionByLabel(optObject: DefnDtoOption[]): DefnDtoOption[]
{
  return [...optObject].sort((a, b) =>
  {
    const labelA = a.value && a.value.toUpperCase();
    const labelB = b.value && b.value.toUpperCase();
    if((labelB && labelA) && labelA < labelB)
    {
      return -1;
    }
    if((labelB && labelA) && labelA > labelB)
    {
      return 1;
    }
    return 0;
  });
}

export function jsonToXml(json: object): string
{

  let xml = "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n";
  xml += "<root>\n";
  xml += generateXml(json, isArray(json) ? "Array" : typeof json);
  xml += "</root>\n";
  return xml;
}

function generateXml(json: object, parentName: string): string
{
  let xml = "";
  if(isArray(json))
  {
    json.map((node) =>
    {
      xml += `<${parentName} type="Array" typeOfArray="${typeof node}">`;
      xml += generateXml(node, parentName);
      xml += `</${parentName}>`;
    });
  }
  else
  {
    if(typeof json !== "object" &&
      typeof json !== "function" &&
      typeof json !== "undefined")
    {
      xml += `${json}`;
    }
    else
    {
      Object.entries(json).map(([key, value]) =>
      {
        if(typeof value === "object")
        {
          if(isArray(value))
          {
            xml += `${generateXml(value, key)}`;
          }
          else
          {
            xml += `<${key} type="object">`;
            xml += generateXml(value, key);
            xml += `</${key}>`;
          }
        }
        else
        {
          xml += `<${key} type="${typeof value}">${value}</${key}>`;
        }
      });
    }
  }
  return xml;
}

export function xmlToJson(xml: string): object | undefined
{
  const json = {} as Record<string, any>;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "text/xml");
  const root = xmlDoc.getElementsByTagName("root")[0];
  if(root && root.nodeName === "root")
  {
    generateJson(root, json);
  }
  return json["root"];
}

function generateJson(element: Element, json: Record<string, any>)
{
  const type = element.getAttribute("type");
  const children = element.children;
  if(type === "Array")
  {
    const typeOfArray = element.getAttribute("typeOfArray");
    if(!json[element.nodeName])
    {
      json[element.nodeName] = [];
    }
    if(typeOfArray !== "object")
    {
      json[element.nodeName].push(element.textContent);
    }
    if(children.length > 0 && typeOfArray === "object")
    {
      const obj = {};
      for(let i = 0; i < children.length; i++)
      {
        generateJson(children[i], obj);
      }
      json[element.nodeName].push(obj);
    }
  }
  else if(type === "object" || !type)
  {
    json[element.nodeName] = {};
    if(children.length > 0)
    {
      for(let i = 0; i < children.length; i++)
      {
        generateJson(children[i], json[element.nodeName]);
      }
    }
  }
  else
  {
    json[element.nodeName] = element.textContent;
  }
}

export function mergeObjects<T>(obj1: T, obj2: T): T // same key will be overwritten by obj2
{
  const _obj = cloneDeep(obj1 || {} as T);
  const _obj2 = cloneDeep(obj2 || {} as T);

  const newObject = {} as T;
  _mergeObjects(_obj, newObject);
  _mergeObjects(_obj2, newObject);

  return newObject;
}

function _mergeObjects<T>(obj: T, newObject: T)
{
  Object.entries(obj as object || {}).forEach(([key, value]) =>
  {
    if(typeof value === "object" && !isArray(value))
    {
      if(!newObject[key as keyof object])
      {
        newObject[key as keyof object] = {} as keyof object;
      }
      _mergeObjects(value, newObject[key as keyof object]);
    }
    else if(value !== undefined)
    {
      if(!Array.isArray(value))
      {
        newObject[key as keyof T] = value;
      }
      else if((Array.isArray(value)))
      {
        const newObjectElement = newObject[key as keyof T];
        if(Array.isArray(newObjectElement))
        {
          newObject[key as keyof T] = [...new Set([...value, ...newObjectElement])] as unknown as T[keyof T];
        }
        else
        {
          newObject[key as keyof T] = value as unknown as T[keyof T];
        }
      }
    }
  });
}

export function isDeepEqual<OBJ1, OBJ2>(obj1: OBJ1, obj2: OBJ2, showLogs?: boolean): boolean
{
  return (_isDeepEqual(obj1, obj2, showLogs) && _isDeepEqual(obj2, obj1, showLogs));
}

function _isDeepEqual<OBJ1, OBJ2>(obj1: OBJ1, obj2: OBJ2, showLogs?: boolean): boolean
{
  const entries = Object.entries(obj1 as object || {});
  for(let i = 0; i < entries.length; i++)
  {
    const [key, value] = entries[i];

    if(typeof value === "object" && !isArray(value))
    {
      if(!obj2[key as keyof object])
      {
        if(showLogs)
        {
          logDebug("isDeepEqual object not found",
            `key: ${key}, obj1: ${JSON.stringify(value)}, obj2: ${JSON.stringify(obj2[key as keyof object])}`
          );
        }
        return false;
      }
      if(!_isDeepEqual(value, obj2[key as keyof object], showLogs))
      {
        return false;
      }
    }
    else if(value !== undefined && value !== null)
    {
      const newObjectElement = obj2[key as keyof OBJ2];
      if(!Array.isArray(value))
      {
        if(obj2[key as keyof OBJ2] !== value)
        {
          if(showLogs)
          {
            logDebug("isDeepEqual property", `key: ${key}, obj1: ${value}, obj2: ${obj2[key as keyof OBJ2]}`);
          }
          return false;
        }
      }
      else if(Array.isArray(value) && Array.isArray(newObjectElement))
      {
        const sameArray = newObjectElement.every((element, index) =>
        {
          return element === value[index];
        });
        if(!sameArray)
        {
          if(showLogs)
          {
            logDebug("isDeepEqual array not equal",
              `key: ${key}, obj1: ${JSON.stringify(value)}, obj2: ${JSON.stringify(newObjectElement)}`
            );
          }
          return false;
        }
      }
      else
      {
        logDebug("isDeepEqual not array",
          `key: ${key}, obj1: ${JSON.stringify(value)}, obj2: ${JSON.stringify(newObjectElement)}`
        );
        return false;
      }
    }
  }
  return true;
}

export function loopKeysMap<T>(
  params: {keys: MetaId[], map: Record<MetaId, T>},
  cb: (key: MetaId, value: T, index: number) => boolean | void)
{
  params.keys.every((key, index) => !cb(key, params.map[key], index));
}

export function randomArrayElement<T>(array: T[])
{
  const random = Math.floor(Math.random() * array.length);
  return array[random];
}

export class PromiseQueue
{
  private readonly queue: Array<() => Promise<void>>;
  private pendingPromise: boolean;

  constructor()
  {
    this.queue = [];
    this.pendingPromise = false;
  }

  add(task: () => Promise<void>)
  {
    this.queue.push(task);
    this.run();
  }

  async run()
  {
    if(this.pendingPromise)
    {
      return;
    }

    if(this.queue.length === 0)
    {
      return;
    }

    try
    {
      this.pendingPromise = true;
      await this.queue[0]();
    }
    finally
    {
      this.queue.shift();
      this.pendingPromise = false;
      this.run();
    }
  }
}

interface CallbackItem
{
  id: string;
  callback: () => void;
}

export class ThrottledQueue
{
  private queue: Map<string, CallbackItem>;
  private isProcessing: boolean;
  private throttleInterval: number;
  private timerId: ReturnType<typeof setTimeout> | null;

  constructor(throttleInterval?: number)
  {
    this.queue = new Map<string, CallbackItem>();
    this.isProcessing = false;
    this.throttleInterval = throttleInterval || 1000;
    this.timerId = null;
  }

  // Add a callback to the queue with an ID
  enqueue(id: string, callback: () => void): void
  {
    this.queue.set(id,
      {
        id,
        callback
      }
    );
    if(!this.isProcessing)
    {
      this.processQueue();
    }
  }

  // Optional method to clear the queue
  clearQueue(): void
  {
    if(this.timerId)
    {
      clearTimeout(this.timerId);
    }
    this.queue.clear();
    this.isProcessing = false;
  }

  // Process the queue with throttling
  private processQueue(): void
  {
    if(this.queue.size === 0)
    {
      this.isProcessing = false;
      return;
    }

    this.isProcessing = true;

    const nextEntry = this.queue.entries().next().value;
    if(nextEntry !== undefined)
    {
      const [key, {callback}] = nextEntry;
      this.queue.delete(key);
      callback();
    }

    this.timerId = setTimeout(() =>
    {
      this.processQueue();
    }, this.throttleInterval);
  }
}

export function hasValues(obj?: Object, ignoreKeys?: string[]): boolean
{
  if(!obj)
  {
    return false;
  }

  let flag = false;
  JSON.stringify(obj, (key, value) =>
  {
    if(value && typeof value !== "object" && !ignoreKeys?.includes(key))
    {
      flag = true;
    }
    else if(isArray(value) && value.length > 0)
    {
      flag = true;
    }
    return value;

  });

  return flag;
}

