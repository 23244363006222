import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {StudioFieldEditable} from "../../../../api/meta/base/dto/StudioFieldEditable";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {mapToOptions} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {loopStudioForm} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {isSystemForm} from "../../../../routes/studio/ent/deploy/plugins/StudioEntDeployPluginPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type EnumFieldPickerValueType = "onlyField" | "withSection" | "withFormAndSection"
type TypeFieldValue = MetaIdField | MetaIdField[] | undefined

export default function FieldRawStudioPickFieldId(props: {
  defn: DefnStudioPickFieldId
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const defn = props.defn;
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const formMap = formStore?.formMap;
  const sysFormMap = formStore?.sysFormMap;

  const formId = defn.formId;
  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const studioForm = formId
    ? isSystemForm(formId)
      ? sysFormMap?.map[formId]
      : formMap?.map[formId]
    : undefined;

  const filterFieldType = defn.filterFieldTypeSet;
  const filterCompositeIdSet = defn.compositeIdSet;
  const excludeFieldIdSet = defn.excludeFieldIdSet;
  const includeFieldIdSet = defn.includeFieldIdSet;
  const includeOptionSet = defn.includeOptionMap;
  const showCompositeName = defn.showCompositeName;

  const dtoOptions = useMemo(() => getPickFieldOptions(
    studioForm,
    "onlyField",
    filterFieldType,
    filterCompositeIdSet,
    excludeFieldIdSet,
    includeFieldIdSet,
    includeOptionSet,
    showCompositeName
  ), [
    studioForm,
    filterFieldType,
    filterCompositeIdSet,
    excludeFieldIdSet,
    includeFieldIdSet,
    includeOptionSet,
    showCompositeName
  ]);

  const [options] = useInsertTrashOptions({
    type: "fieldMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
    showChip={props.showChip}
  />;
}

function getPickFieldOptions(
  studioForm?: StudioForm,
  valueFormat?: EnumFieldPickerValueType,
  filterFieldTypeSet?: EnumDefnCompType[],
  filterCompositeIdSet?: MetaIdComposite[],
  excludeFieldIdSet?: MetaIdField[],
  includeFieldIdSet?: MetaIdField[],
  extraOptionSet?: DefnStudioMapOfDtoOption,
  showCompositeName?: boolean): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];

  if(studioForm)
  {
    loopStudioForm(studioForm, (composite, field) =>
    {
      if((!filterCompositeIdSet || filterCompositeIdSet.includes((composite as StudioSection | StudioGrid).metaId)))
      {
        const refParentId = (field as StudioFieldEditable)?.refFieldId;
        const refParentName = refParentId
          ? composite.fieldMap.map[refParentId]?.details.name
          : undefined;

        const label = (showCompositeName && composite.type === "grid")
          ? `${composite.details.name}.${(refParentId && refParentName)
            ? (refParentName + ".")
            : ""}${field.details.name}`
          : field.details.name;

        const compId = (composite as StudioSection | StudioGrid).metaId;
        const fieldMetaId = field.metaId;

        let optionId;

        switch(valueFormat)
        {
          case "withSection":
            optionId = `${compId}.${fieldMetaId}`;
            break;
          case "withFormAndSection":
            optionId = `${studioForm.metaId}:${compId}.${fieldMetaId}`;
            break;
          default:
            optionId = fieldMetaId;
        }

        if(field && field.type)
        {
          if(!filterFieldTypeSet || (filterFieldTypeSet && filterFieldTypeSet.includes(field.type)))
          {
            if(excludeFieldIdSet && excludeFieldIdSet.includes(optionId))
            {
              return;
            }
            if(includeFieldIdSet && !includeFieldIdSet.includes(optionId))
            {
              return;
            }

            options.push({
              value: label,
              metaId: optionId
            });
          }
        }
      }
    });
  }

  if(extraOptionSet && extraOptionSet.keys.length > 0)
  {
    const extraOptions = mapToOptions(extraOptionSet);
    extraOptions && options.push(...extraOptions);
  }

  return options;
}
