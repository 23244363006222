import {PluginBundleId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {DtoPluginAdmin} from "../../../api/studio/base/dto/DtoPluginAdmin";
import {MsgAdminId} from "../../../api/studio/studioMain/msg/MsgAdminId";
import {MsgAdminInvite} from "../../../api/studio/studioMain/msg/MsgAdminInvite";
import {MsgPluginAdminAdd} from "../../../api/studio/studioMain/msg/MsgPluginAdminAdd";
import {MsgPluginAdminUpdate} from "../../../api/studio/studioMain/msg/MsgPluginAdminUpdate";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import ISrvc from "../../../base/ISrvc";
import {STR_INVITE_SUBMIT_SUCCESSFULLY} from "../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {removePluginAdminList} from "../../../cache/studio/plugin/SliceCacheStudioPlugin";
import {setPluginAdminList} from "../../../cache/studio/plugin/SliceCacheStudioPlugin";
import {dispatchPlugin} from "../../../cache/studio/SrvcCacheStudio";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioPluginAdmins extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.plugin.pluginAdminList;
  }

  rpcPluginAdminListGet(pluginBundleId: PluginBundleId)
  {
    RpcStudioMain.pluginAdminListGet(pluginBundleId, pluginSig =>
    {
      if(pluginSig.error)
      {
        const errorMsg = getErrorMessage(pluginSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = pluginSig.sig;

      if(sig)
      {
        dispatchPlugin(pluginBundleId, setPluginAdminList({
          pluginBundleId: pluginBundleId,
          sigPluginAdminList: sig
        }));
      }
    });
  }

  rpcPluginAdminAdd(pluginBundleId: PluginBundleId, msg: MsgPluginAdminAdd, cbSuccess?: CbSuccess)
  {
    RpcStudioMain.pluginAdminAdd(pluginBundleId, msg, sig =>
    {
      if(sig.error)
      {
        const errorMsg = getErrorMessage(sig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        Srvc.app.toast.showSuccessToast("Admin added successfully");
      }

      cbSuccess && cbSuccess();
    });
  }

  rpcPluginAdminUpdate(pluginBundleId: PluginBundleId, msg: MsgPluginAdminUpdate, cbSuccess: CbSuccess)
  {
    RpcStudioMain.pluginAdminUpdate(pluginBundleId, msg, sig =>
    {
      if(sig.error)
      {
        const errorMsg = getErrorMessage(sig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }

      cbSuccess();
    });
  }

  rpcPluginAdminRemove(pluginBundleId: PluginBundleId, msg: MsgAdminId)
  {
    RpcStudioMain.pluginAdminRemove(pluginBundleId, msg, sig =>
    {
      if(sig.error)
      {
        const errorMsg = getErrorMessage(sig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  rpcPluginAdminInvite(pluginBundleId: PluginBundleId, msg: MsgAdminInvite)
  {
    RpcStudioMain.pluginAdminInvite(pluginBundleId, msg, sig =>
    {
      if(sig.error)
      {
        const errorMsg = getErrorMessage(sig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        Srvc.app.toast.showSuccessToast(STR_INVITE_SUBMIT_SUCCESSFULLY);
      }
    });
  }

  rpcPluginAdminTransferLock(pluginBundleId: PluginBundleId, msg: MsgAdminId)
  {
    RpcStudioMain.pluginAdminEditLockTransfer(pluginBundleId, msg, sig =>
    {
      if(sig.error)
      {
        const errorMsg = getErrorMessage(sig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  copyPluginAdmin(handle: string)
  {
    const rootState = store.getState();
    const adminList = rootState.studio.plugin.pluginAdminList.userField?.value as DtoPluginAdmin[];
    const admin = adminList.find(_admin => _admin.handle === handle);
    copyToClipboard(JSON.stringify(admin));
  }

  loadAdminList(listName: string, pluginAdminList?: DtoPluginAdmin[])
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    pluginAdminList?.forEach(admin =>
    {
      const itemId = admin.adminId;
      uiItemIds.push(itemId);

      uiItemsById[itemId] = {
        type: "ps",
        userField: {
          value: admin
        },
        primary: {
          text: admin.nickName,
          middle: admin.hasLock
            ? {
              text: "Edit lock",
              color: "error"
            }
            : undefined,
          caption: {
            text: admin.handle,
            ignoreSelection: true
          }
        },
        secondary: {
          text: "Super admin",
          color: "textDisabled",
          caption: {
            text: (admin.isMember || admin.isCurrentUser) ? undefined : "INVITE",
            color: "successLight"
          }
        }
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  resetPluginAdminBook(pluginBundleId: PluginBundleId)
  {
    dispatchPlugin(pluginBundleId, removePluginAdminList(pluginBundleId));
  }
}
