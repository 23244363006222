import React from "react";
import {useCallback} from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemP3} from "../../../base/types/list/TypesListAPSA";
import {useAppSelector} from "../../app/AppHooks";
import {BoxP3} from "../../atom/box/BoxP3";
import {CbOnClickListItemInternal} from "../impl/ListItem";
import ListItemShell from "../impl/ListItemShell";

export function ListItemP3(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItemInternal,
})
{
  const itemId = props.itemId;
  const onClickInternal = props.onClickListItem;
  const itemHeight = getListItemHeightAPSA("p3");
  const searchWords = useAppSelector(state => props.selectList(state).searchWords);
  const item = useAppSelector(state => props.selectList(state).itemsById[itemId]) as IListItemP3;
  const ignoreSelection = item?.primary?.caption?.ignoreSelection;
  const primary = item?.primary;

  const cbOnClickCaption = useCallback((e: React.MouseEvent<HTMLElement>) =>
  {
    onClickInternal && onClickInternal(e.currentTarget, itemId, item, "apsaPrimaryCaption");
  }, [onClickInternal, itemId, item]);

  return (
    <ListItemShell itemHeight={itemHeight} {...props}>
      <BoxP3
        primary={primary}
        searchWords={searchWords}
        onClickCaption={ignoreSelection ? undefined : cbOnClickCaption}
        flexGrow={1}
      />
    </ListItemShell>
  );
}
