import {combineReducers} from "@reduxjs/toolkit";
import {dispatchList} from "../../../base/plus/ListPlus";
import {createListSlice} from "../../../base/plus/ListPlus";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";

const studioRecentList = "studioRecentList";

export function reducersStudioDrawer()
{
  return combineReducers({
    studioRecentList: createListSlice(studioRecentList, {defaultListItemType: "aps"}).reducer,
    studioFindUsages: createListSlice("studioFindUsages",
      {
        defaultListItemType: "ps",
        canShowMenu: false
      }
    ).reducer,
    studioSearch: createListSlice("studioSearch",
      {
        defaultListItemType: "ps",
        showEmptyGroups: true,
        externalSearch: true
      }
    ).reducer,
    studioFilter: createListSlice("studioFilter",
      {
        defaultListItemType: "aps",
        pickType: "pickMany"
      }
    ).reducer
  });
}

export function onClickStudioActionMainBack()
{
  dispatchList(studioRecentList, listSetSelectedItemId());
}
