import ISrvc from "../../../base/ISrvc";
import SrvcControlPanelAutomation from "./automation/SrvcControlPanelAutomation";
import SrvcControlPanelLog from "./log/SrvcControlPanelLog";
import SrvcControlPanelManage from "./manage/SrvcControlPanelManage";

export class SrvcControlPanelMain extends ISrvc
{
  public readonly automation = new SrvcControlPanelAutomation();
  public readonly log = new SrvcControlPanelLog();
  public readonly manage = new SrvcControlPanelManage();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.automation,
      this.log,
      this.manage
    );
  }
}
