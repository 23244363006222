import {SnackbarCloseReason} from "@mui/material";
import {useTheme} from "@mui/material";
import {Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {PopoverOrigin} from "@mui/material/Popover/Popover";
import {useEffect} from "react";
import {useRef} from "react";
import {SyntheticEvent} from "react";
import {useContext} from "react";
import React, {createContext, useState} from "react";
import {TypeUserField} from "../../base/types/TypesGlobal";
import {IAvatar} from "../../base/types/TypesGlobal";
import {EnumIconAvatar} from "../../base/types/TypesIcon";
import {setDebugCompKeyValue} from "../../srvc/app/debug/SliceDebug";
import {Srvc} from "../../srvc/Srvc";
import {RootState} from "../../Store";
import {useAppDispatch} from "../app/AppHooks";
import {useAppSelector} from "../app/AppHooks";
import DialogAlert from "../atom/dialog/DialogAlert";
import DialogConfirm from "../atom/dialog/DialogConfirm";
import {IDialogMsg} from "../atom/dialog/TypesDialog";
import RawMenu from "../atom/raw/RawMenu";
import {IMenuRef} from "../atom/raw/RawMenu";
import {IMenuProps} from "../atom/raw/RawMenu";
import RawMenuAvatar from "../atom/raw/RawMenuAvatar";
import {IMenuMultiselectProps} from "../atom/raw/RawMultiselectMenu";
import {RawMultiselectMenu} from "../atom/raw/RawMultiselectMenu";
import {IPopoverProps} from "../atom/raw/RawPopover";
import RawPopover from "../atom/raw/RawPopover";
import ICtxPage from "./ICtxPage";

const ctxPage = createContext({} as ICtxPage);

export function usePageCtx()
{
  return useContext(ctxPage);
}

export default function PageCtxProvider(props: {
  children: React.ReactNode
})
{
  const dispatch = useAppDispatch();
  const callerIdHash = useAppSelector(state => state.cache.app.caller.callerUserIdHash as string);
  const [dialog, setDialog] = useState<React.ReactNode | undefined>();
  const [menuNode, setMenuNode] = useState<React.ReactNode | undefined>();
  const [popover, setPopover] = useState<React.ReactNode | undefined>();

  const pageCtx = {} as ICtxPage;

  pageCtx.debugComp = (compName: string, key: string, value?: TypeUserField) =>
  {
    dispatch(setDebugCompKeyValue({
      compName: compName,
      key: key,
      value: value
    }));
  };

  pageCtx.showErrorToast = (msg) =>
  {
    Srvc.app.toast.showErrorToast(msg);
  };

  pageCtx.showSuccessToast = (msg) =>
  {
    Srvc.app.toast.showSuccessToast(msg);
  };

  pageCtx.showInfoToast = (msg) =>
  {
    Srvc.app.toast.showInfoToast(msg);
  };

  pageCtx.showWarningToast = (msg: string) =>
  {
    Srvc.app.toast.showWarningToast(msg);
  };

  pageCtx.showDialog = (dialog?: React.ReactNode) =>
  {
    if(dialog)
    {
      setDialog(dialog);
    }
    else
    {
      setDialog(undefined);
    }
  };

  pageCtx.showAlertDialog = (msg: IDialogMsg | string, title?: string, fullScreen?: boolean) =>
  {
    setDialog(<DialogAlert
      title={title}
      msg={msg}
      fullScreen={fullScreen}
    />);
  };

  pageCtx.showConfirmDialog = (
    msg: IDialogMsg | string,
    onOk?: () => void,
    title?: string,
    onCancel?: () => void,
    fullScreen?: boolean) =>
  {
    setDialog(<DialogConfirm
      title={title}
      msg={msg}
      onOk={onOk}
      onCancel={onCancel}
      fullScreen={fullScreen}
    />);
  };

  pageCtx.showMenu = (
    menuAnchor: Element,
    menuProps: IMenuProps,
    onMenuGone?: () => void,
    cbRef?: IMenuRef,
    anchorOrigin?: PopoverOrigin
  ): void =>
  {
    setMenuNode(
      <RawMenu
        menuAnchor={menuAnchor}
        menuProps={menuProps}
        onMenuGone={() =>
        {
          setMenuNode(undefined);
          onMenuGone && onMenuGone();
        }}
        cbRef={cbRef}
        anchorOrigin={anchorOrigin}
      />
    );
  };

  pageCtx.showMultiselectMenu = (
    menuProps: IMenuMultiselectProps,
    onSelectionChange: (selectedValues: string[]) => void,
    anchor?: Element | null,
    onMenuGone?: () => void,
    cbRef?: IMenuRef
  ): void =>
  {
    setMenuNode(
      <RawMultiselectMenu
        menuProps={menuProps}
        onSelectionChange={onSelectionChange}
        anchor={anchor}
        cbRef={cbRef}
        onMenuGone={() =>
        {
          setMenuNode(undefined);
          onMenuGone && onMenuGone();
        }}
      />
    );
  };

  pageCtx.showMenuAvatar = (
    menuAnchor: Element,
    avatarList: IAvatar[],
    onMenuGone?: () => void,
    onAvatarClick?: (avatarName: EnumIconAvatar) => void,
    cbRef?: IMenuRef
  ): void =>
  {
    setMenuNode(
      <RawMenuAvatar
        menuAnchor={menuAnchor}
        avatarList={avatarList}
        onMenuGone={() =>
        {
          setMenuNode(undefined);
          onMenuGone && onMenuGone();
        }}
        onAvatarClick={onAvatarClick}
        cbRef={cbRef}
      />
    );
  };

  pageCtx.showPopover = (
    anchorEl: Element,
    popoverProps?: IPopoverProps,
    node?: React.ReactNode,
    onClose?: () => void): void =>
  {
    setPopover(
      <RawPopover
        anchorEl={anchorEl}
        popoverProps={popoverProps}
        childNode={node}
        onClose={() =>
        {
          onClose && onClose();
          setPopover(undefined);
        }}
      />
    );
  };

  pageCtx.getCallerIdHash = () =>
  {
    return callerIdHash;
  };

  pageCtx.clearDialog = () =>
  {
    setDialog(undefined);
  };
  pageCtx.clearPopover = () =>
  {
    setPopover(undefined);
  };
  pageCtx.clearMenu = () =>
  {
    setMenuNode(undefined);
  };

  return (
    <ctxPage.Provider value={pageCtx}>
      {props.children}

      <SnackbarToast />

      {
        dialog
      }

      {
        menuNode
      }

      {
        popover
      }

    </ctxPage.Provider>
  );
}

function SnackbarToast()
{
  const toastMessage = useAppSelector((state: RootState) => state.app.toast.toastMessage);
  const toastSeverity = useAppSelector((state: RootState) => state.app.toast.toastSeverity);
  const [showToast, setShowToast] = useState(false);
  const theme = useTheme();
  const toastMessagePrev = useRef(toastMessage);
  const handleCloseToast = (event: Event | SyntheticEvent, reason?: SnackbarCloseReason) =>
  {
    if(reason === "clickaway")
    {
      return;
    }
    setShowToast(false);

    setTimeout(() =>
    {
      Srvc.app.toast.clearToast();
    }, theme.transitions.duration.leavingScreen);
  };

  useEffect(() =>
  {
    if(toastMessage && !showToast)
    {
      setShowToast(true);
    }
    else if(!toastMessage && showToast)
    {
      setShowToast(false);
    }
  }, [toastMessage]);

  useEffect(() =>
  {
    if(toastMessage)
    {
      toastMessagePrev.current = toastMessage;
    }
  }, [toastMessage]);

  return (
    <Snackbar
      open={showToast}
      autoHideDuration={theme.common.durationSnackbar}
      onClose={handleCloseToast}
      hidden={!toastMessage}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleCloseToast}
        severity={toastSeverity}
        children={toastMessage || toastMessagePrev.current}
      />
    </Snackbar>
  );
}
