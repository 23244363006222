// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {newGuidBig} from "./NanoId";
import {newGuid} from "./NanoId";

export type SysId = string;

export const SEP_EXT = ".";
export const SEP_PREFIX = "-";

export function nextSysId<T extends SysId>(prefix: string, suffix: string, ext?: string): T
{
  let sysId = prefix + SEP_PREFIX + suffix;
  if(ext)
  {
    sysId += "." + ext;
  }
  return sysId as T;
}

export function nextId<T extends SysId>(prefix: string, ext?: string): T
{
  return nextSysId<T>(prefix, newGuid(), ext);
}

export function nextIdBig<T extends SysId>(prefix: string, ext?: string): T
{
  return nextSysId<T>(prefix, newGuidBig(), ext);
}

export function getPrefix(sysId: SysId): string
{
  const index = sysId.indexOf(SEP_PREFIX);
  return sysId.slice(0, index);
}

export function getSuffix(sysId: SysId): string
{
  const index = sysId.indexOf(SEP_PREFIX);
  return sysId.slice(index + 1);
}

export function getExt(sysId: SysId): string | undefined
{
  const index = sysId.indexOf(SEP_EXT);
  return index < 0 ? undefined : sysId.slice(index + 1);
}

export function getIdWithoutPrefix(sysId: SysId): string
{
  const index = sysId.indexOf(SEP_PREFIX);
  return sysId.slice(index + 1);
}
