import {useContext} from "react";
import {createContext} from "react";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";

export default interface IFormSectionCtx
{
  getParent(): DefnSection;

  getDefnForm(): DefnFormUi;

  getDefn(key: MetaIdField): DefnFieldUi;

}

export const ctxFormSection = createContext({} as IFormSectionCtx);

export const useFormSectionCtx = () =>
{
  return useContext(ctxFormSection);
};
