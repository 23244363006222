import React from "react";
import {DtoMessagePayloadDocument} from "../../../api/home/base/dto/DtoMessagePayloadDocument";
import {SelectList} from "../../../base/plus/ListPlus";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {useAppSelector} from "../../app/AppHooks";
import BubbleDocument from "../../atom/bubble/leftright/BubbleDocument";
import ListItemShellChat from "../impl/ListItemShellChat";
import {CbOnClickListItem} from "../List";

export default function ListItemChatDocument(props: {
  itemId: TypeListItemId,
  listItem: IListItemChat,
  selectList: SelectList,
  itemMaxWidth: number,
  onClickListItem?: CbOnClickListItem,
  cbOnClickFooterCaption?: CbMenuAnchor,
})
{
  const onClickListItem = props.onClickListItem;
  const selectList = props.selectList;
  const searchWords = useAppSelector(state => selectList(state).searchWords);
  const itemId = props.itemId;
  const listItem = props.listItem;
  const sig = listItem.sig;
  const cbOnClickDocDownload = (menuAnchor: Element) => onClickListItem &&
    onClickListItem(menuAnchor, itemId, listItem, "chatDownloadDocument");

  return (
    <ListItemShellChat
      itemId={itemId}
      listItem={props.listItem}
      selectList={props.selectList}
      maxWidth={props.itemMaxWidth}
      onClickListItem={props.onClickListItem}
    >
      <BubbleDocument
        payload={sig.payload as DtoMessagePayloadDocument}
        replyPayload={sig.replyPayload}
        replyInfo={sig.replyInfo}
        receiptStatus={sig.receiptStatus}
        isCallerSender={Boolean(sig.isCallerSender)}
        creationTime={sig.creationTime}
        maxWidth={props.itemMaxWidth}
        header={sig.header}
        searchWords={searchWords}
        isDownloading={sig.isDownloading}
        isStar={sig.isStar}
        onClickDownload={cbOnClickDocDownload}
        cbOnClickFooterCaption={props.cbOnClickFooterCaption}
      />
    </ListItemShellChat>
  );
}
