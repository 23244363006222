import {combineReducers} from "@reduxjs/toolkit";
import {sliceCacheBadge} from "./badge/SliceCacheBadge";
import {sliceCacheCaller} from "./caller/SliceCacheCaller";
import {sliceCacheCallerEnt} from "./callerEnt/SliceCacheCallerEnt";
import {sliceCacheGroup} from "./group/SliceCacheGroup";
import {reducersCacheSpreadsheet} from "./spreadsheet/StoreCacheSpreadsheet";
import {sliceCacheStarMsgList} from "./starMsg/SliceCacheStarMsgList";
import {sliceCacheUser} from "./user/SliceCacheUser";

export function reducersCacheApp()
{
  return combineReducers({
    spreadsheet: reducersCacheSpreadsheet(),
    badge: sliceCacheBadge.reducer,
    caller: sliceCacheCaller.reducer,
    callerEnt: sliceCacheCallerEnt.reducer,
    user: sliceCacheUser.reducer,
    group: sliceCacheGroup.reducer,
    starMsg: sliceCacheStarMsgList.reducer
  });
}
