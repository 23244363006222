import {combineReducers} from "@reduxjs/toolkit";
import {reducersProductionDrawer} from "./drawer/StoreControlPanelDrawer";
import {reducersProductionMain} from "./main/StoreControlPanelMain";

export function reducersProduction()
{
  //combine reducers inside object can't be empty, give key as name and optionId as reducer name
  return combineReducers({
    drawer: reducersProductionDrawer(),
    main: reducersProductionMain()
  });
}
