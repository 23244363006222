import {nextRequestId} from "../../api/meta/base/ApiPlus";
import {IMsg} from "../../api/meta/base/msg/IMsg";
import {ISig} from "../../api/meta/base/sig/ISig";
import {ServiceName} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {ISigAcceptor} from "../../api/nucleus/base/ISigAcceptor";
import {IWsocCall} from "../../api/nucleus/base/IWsocCall";
import {EnumApiMethod} from "../../api/nucleus/base/Types";
import {Srvc} from "../../srvc/Srvc";

export default class WsocCall<S extends ISig> implements IWsocCall<S>
{
  readonly requestId = nextRequestId();
  hasSent: boolean;
  hasReceived: boolean;
  private method?: EnumApiMethod;
  private msg?: IMsg;
  private sigAcceptor?: ISigAcceptor<S>;

  constructor(
    public readonly entId: EntId,
    public readonly serviceName: ServiceName,
    public readonly apiName: string
  )
  {
    this.hasSent = false;
    this.hasReceived = false;
  }

  getApiMethod(): EnumApiMethod
  {
    return this.method as EnumApiMethod;
  }

  getMsg(): IMsg | undefined
  {
    return this.msg;
  }

  delete(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>
  {
    return this.execute("delete", msg, sigAcceptor);
  }

  get(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>
  {
    return this.execute("get", msg, sigAcceptor);
  }

  patch(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>
  {
    return this.execute("patch", msg, sigAcceptor);
  }

  post(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>
  {
    return this.execute("post", msg, sigAcceptor);
  }

  put(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>
  {
    return this.execute("put", msg, sigAcceptor);
  }

  onSignal(envSig: EnvSignal<S>)
  {
    const sigAcceptor = this.sigAcceptor;
    if(sigAcceptor)
    {
      this.hasReceived = true;
      sigAcceptor(envSig);
    }
  }

  onSend()
  {
    const wsocClient = Srvc.app.auth.getWsocClient();
    if(wsocClient)
    {
      wsocClient.send(this);
    }
  }

  private execute(method: EnumApiMethod, msg?: IMsg, sigAcceptor?: ISigAcceptor<S>): IWsocCall<S>
  {
    this.method = method;
    this.msg = msg;
    this.sigAcceptor = sigAcceptor;

    this.onSend();
    return this;
  }
}
