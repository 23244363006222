import {FieldValues} from "react-hook-form";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldNumber} from "../../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../../../../api/meta/base/dto/DefnFieldText";
import {DefnStudioBuildColor} from "../../../../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnStudioVarIdTextEditor} from "../../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {StudioFieldLabel} from "../../../../../api/meta/base/dto/StudioFieldLabel";
import {StudioVarMap} from "../../../../../api/meta/base/dto/StudioVarMap";
import {JustifyText} from "../../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getExcludeVarIdSet} from "../../../../../base/plus/FormPlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyBgColor} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyTextPatternFieldId} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyTextSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeyOpacityFieldId} from "../../base/TypesFormBuilder";
import {propKeyOpacityVar} from "../../base/TypesFormBuilder";
import {propKeyOpacity} from "../../base/TypesFormBuilder";
import {propKeyStrikeThroughFieldId} from "../../base/TypesFormBuilder";
import {propKeyStrikeThroughVar} from "../../base/TypesFormBuilder";
import {propKeyStrikeThrough} from "../../base/TypesFormBuilder";
import {propKeyUnderlineFieldId} from "../../base/TypesFormBuilder";
import {propKeyUnderlineVar} from "../../base/TypesFormBuilder";
import {propKeyUnderline} from "../../base/TypesFormBuilder";
import {propKeyTextPattern} from "../../base/TypesFormBuilder";
import {propKeyJustifyText} from "../../base/TypesFormBuilder";
import {propKeyTextSize} from "../../base/TypesFormBuilder";
import {propKeyItalic} from "../../base/TypesFormBuilder";
import {propKeyBold} from "../../base/TypesFormBuilder";
import {propKeyItalicFieldId} from "../../base/TypesFormBuilder";
import {propKeyBoldFieldId} from "../../base/TypesFormBuilder";
import {propKeyJustifyTextVar} from "../../base/TypesFormBuilder";
import {propKeyBgColorVarId} from "../../base/TypesFormBuilder";
import {propKeyColorVar} from "../../base/TypesFormBuilder";
import {propKeyTextPatternVar} from "../../base/TypesFormBuilder";
import {propKeyTextSizeVar} from "../../base/TypesFormBuilder";
import {propKeyBoldVar} from "../../base/TypesFormBuilder";
import {propKeyItalicVar} from "../../base/TypesFormBuilder";

const fieldLabelOr = "fieldLabelOr";

export function getDefnFieldLabel(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  varMap?: StudioVarMap,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyBoldFieldId,
    propKeyItalicFieldId,

    fieldGap1,

    propKeyUnderlineFieldId,
    fieldLabelOr,
    propKeyStrikeThroughFieldId,

    fieldGap2,

    propKeyTextPatternFieldId,
    propKeyTextSizeFieldId,

    fieldGap3,

    propKeyOpacityFieldId
  ];

  const varIds = [
    propKeyBoldVar,
    propKeyItalicVar,

    fieldGap1,

    propKeyUnderlineVar,
    fieldLabelOr,
    propKeyStrikeThroughVar,

    fieldGap2,

    propKeyTextPatternVar,
    propKeyTextSizeVar,
    propKeyJustifyTextVar,
    propKeyBgColorVarId,

    fieldGap3,

    propKeyColorVar,
    propKeyOpacityVar
  ];

  const constIds = [
    propKeyBold,
    propKeyItalic,

    fieldGap1,

    propKeyUnderline,
    fieldLabelOr,
    propKeyStrikeThrough,

    fieldGap2,

    propKeyTextPattern,
    propKeyTextSize,
    propKeyJustifyText,
    propKeyBgColor,

    fieldGap3,
    propKeyOpacity
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "text",
      formId,
      "text",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [fieldLabelOr]: {
      type: "label",
      name: fieldLabelOr,
      metaId: fieldLabelOr,
      label: "OR",
      disabled: true,
      justifyText: "center"
    } as DefnFieldLabel,

    [propKeyTextPatternVar]: {
      type: "studioVarIdTextEditor",
      metaId: propKeyTextPatternVar,
      label: "Text pattern",
      argBinderFormId: formId,
      showAsEdit: true,
      filterVarKindSet: ["text"]
    } as DefnStudioVarIdTextEditor,

    [propKeyTextPattern]: {
      type: "text",
      metaId: propKeyTextPattern,
      name: propKeyTextPattern,
      label: "Text pattern"
    } as DefnFieldText,

    [propKeyTextPatternFieldId]: {
      type: "pickFieldId",
      metaId: propKeyTextPatternFieldId,
      name: propKeyTextPatternFieldId,
      label: "Text pattern",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["text"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyBold]: {
      type: "bool",
      metaId: propKeyBold,
      name: propKeyBold,
      label: "Bold",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyBoldVar]: {
      type: "pickVarId",
      metaId: propKeyBoldVar,
      name: propKeyBoldVar,
      label: "Bold",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyBoldFieldId]: {
      type: "pickFieldId",
      metaId: propKeyBoldFieldId,
      name: propKeyBoldFieldId,
      label: "Bold",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["bool"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyItalic]: {
      type: "bool",
      metaId: propKeyItalic,
      name: propKeyItalic,
      label: "Italic",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyItalicVar]: {
      type: "pickVarId",
      metaId: propKeyItalicVar,
      name: propKeyItalicVar,
      label: "Italic",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyItalicFieldId]: {
      type: "pickFieldId",
      metaId: propKeyItalicFieldId,
      name: propKeyItalicFieldId,
      label: "Italic",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["bool"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyTextSize]: {
      type: "textSize",
      metaId: propKeyTextSize,
      name: propKeyTextSize,
      label: "Text size"
    } as DefnFieldPickEnum,

    [propKeyTextSizeVar]: {
      type: "pickVarId",
      metaId: propKeyTextSizeVar,
      name: propKeyTextSizeVar,
      label: "Text size",
      varKind: "textSize",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyTextSizeFieldId]: {
      type: "pickFieldId",
      metaId: propKeyTextSizeFieldId,
      name: propKeyTextSizeFieldId,
      label: "Text size",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["textSize"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyColorVar]: {
      type: "pickVarId",
      metaId: propKeyColorVar,
      name: propKeyColorVar,
      label: "Font color",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyJustifyText]: {
      type: "enumPlacement",
      metaId: propKeyJustifyText,
      name: propKeyJustifyText,
      label: "Justify text",
      filterOptionSet: ["center", "start", "end" as EnumDefnPlacement]
    } as DefnFieldPickEnum,

    [propKeyJustifyTextVar]: {
      type: "pickVarId",
      metaId: propKeyJustifyTextVar,
      name: propKeyJustifyTextVar,
      label: "Justify text",
      varKind: "placement",
      showAsEdit: true,
      formId: formId,
      filterOptionSet: JustifyText,
      excludeVarIdSet: getExcludeVarIdSet(JustifyText as EnumDefnPlacement[], varMap)
    } as DefnStudioPickVarId,

    [propKeyUnderline]: {
      type: "bool",
      metaId: propKeyUnderline,
      name: propKeyUnderline,
      label: "Underline",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyUnderlineVar]: {
      type: "pickVarId",
      metaId: propKeyUnderlineVar,
      name: propKeyUnderlineVar,
      label: "Underline",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyUnderlineFieldId]: {
      type: "pickFieldId",
      metaId: propKeyUnderlineFieldId,
      name: propKeyUnderlineFieldId,
      label: "Underline",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["bool"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyStrikeThrough]: {
      type: "bool",
      metaId: propKeyStrikeThrough,
      name: propKeyStrikeThrough,
      label: "Strike through",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyStrikeThroughVar]: {
      type: "pickVarId",
      metaId: propKeyStrikeThroughVar,
      name: propKeyStrikeThroughVar,
      label: "Strike through",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyStrikeThroughFieldId]: {
      type: "pickFieldId",
      metaId: propKeyStrikeThroughFieldId,
      name: propKeyStrikeThroughFieldId,
      label: "Strike through",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["bool"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyOpacity]: {
      type: "decimal",
      metaId: propKeyOpacity,
      name: propKeyOpacity,
      label: "Opacity",
      min: 0,
      max: 1
    } as DefnFieldNumber,

    [propKeyOpacityVar]: {
      type: "pickVarId",
      metaId: propKeyOpacityVar,
      name: propKeyOpacityVar,
      label: "Opacity",
      varKind: "decimal",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyOpacityFieldId]: {
      type: "pickFieldId",
      metaId: propKeyOpacityFieldId,
      name: propKeyOpacityFieldId,
      label: "Opacity",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["decimal"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyBgColorVarId]: {
      type: "pickVarId",
      metaId: propKeyBgColorVarId,
      name: propKeyBgColorVarId,
      varKind: "color",
      label: "Background color",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyBgColor]: {
      type: "studioBuildColor",
      metaId: propKeyBgColor,
      name: propKeyBgColor,
      label: "Background color"
    } as DefnStudioBuildColor

  };
}

export function defnValueToStudioFieldLabel(values: FieldValues): StudioFieldLabel
{
  return {
    type: "label",

    bgColorVarId: fnFieldValueToRawValue("pickVarId", values[propKeyBgColorVarId]),
    bgColor: fnFieldValueToRawValue("pickVarId", values[propKeyBgColor]),
    colorVarId: fnFieldValueToRawValue("pickVarId", values[propKeyColorVar]),

    justifyText: fnFieldValueToRawValue("enumPlacement", values[propKeyJustifyText]),
    justifyTextVarId: fnFieldValueToRawValue("pickVarId", values[propKeyJustifyTextVar]),

    textSize: fnFieldValueToRawValue("textSize", values[propKeyTextSize]),
    textSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyTextSizeVar]),
    textSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyTextSizeFieldId]),

    textPattern: fnFieldValueToRawValue("text", values[propKeyTextPattern]),
    textPatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[propKeyTextPatternVar]),
    textPatternFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyTextPatternFieldId]),

    bold: fnFieldValueToRawValue("bool", values[propKeyBold]),
    boldVarId: fnFieldValueToRawValue("pickVarId", values[propKeyBoldVar]),
    boldFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyBoldFieldId]),

    italic: fnFieldValueToRawValue("bool", values[propKeyItalic]),
    italicVarId: fnFieldValueToRawValue("pickVarId", values[propKeyItalicVar]),
    italicFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyItalicFieldId]),

    underline: fnFieldValueToRawValue("bool", values[propKeyUnderline]),
    underlineVarId: fnFieldValueToRawValue("pickVarId", values[propKeyUnderlineVar]),
    underlineFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyUnderlineFieldId]),

    strikeThrough: fnFieldValueToRawValue("bool", values[propKeyStrikeThrough]),
    strikeThroughVarId: fnFieldValueToRawValue("pickVarId", values[propKeyStrikeThroughVar]),
    strikeThroughFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyStrikeThroughFieldId]),

    opacity: fnFieldValueToRawValue("number", values[propKeyOpacity]),
    opacityVarId: fnFieldValueToRawValue("pickVarId", values[propKeyOpacityVar]),
    opacityFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyOpacityFieldId])

  } as StudioFieldLabel;
}

export function studioFieldLabelToDefnValue(studioField: StudioFieldLabel)
{
  return {

    [propKeyBgColorVarId]: fnRawValueToFieldValue("pickVarId", studioField.bgColorVarId),
    [propKeyBgColor]: fnRawValueToFieldValue("pickVarId", studioField.bgColor),
    [propKeyColorVar]: fnRawValueToFieldValue("pickVarId", studioField.colorVarId),

    [propKeyJustifyText]: fnRawValueToFieldValue("enumPlacement", studioField.justifyText),
    [propKeyJustifyTextVar]: fnRawValueToFieldValue("pickVarId", studioField.justifyTextVarId),

    [propKeyTextSize]: fnRawValueToFieldValue("textSize", studioField.textSize),
    [propKeyTextSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.textSizeVarId),
    [propKeyTextSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.textSizeFieldId),

    [propKeyTextPattern]: fnRawValueToFieldValue("text", studioField.textPattern),
    [propKeyTextPatternVar]: fnRawValueToFieldValue("studioVarIdTextEditor", studioField.textPatternVarId),
    [propKeyTextPatternFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.textPatternFieldId),

    [propKeyBold]: fnRawValueToFieldValue("bool", studioField.bold),
    [propKeyBoldVar]: fnRawValueToFieldValue("pickVarId", studioField.boldVarId),
    [propKeyBoldFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.boldFieldId),

    [propKeyItalic]: fnRawValueToFieldValue("bool", studioField.italic),
    [propKeyItalicVar]: fnRawValueToFieldValue("pickVarId", studioField.italicVarId),
    [propKeyItalicFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.italicFieldId),

    [propKeyUnderline]: fnRawValueToFieldValue("bool", studioField.underline),
    [propKeyUnderlineVar]: fnRawValueToFieldValue("pickVarId", studioField.underlineVarId),
    [propKeyUnderlineFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.underlineFieldId),

    [propKeyStrikeThrough]: fnRawValueToFieldValue("bool", studioField.strikeThrough),
    [propKeyStrikeThroughVar]: fnRawValueToFieldValue("pickVarId", studioField.strikeThroughVarId),
    [propKeyStrikeThroughFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.strikeThroughFieldId),

    [propKeyOpacity]: fnRawValueToFieldValue("decimal", studioField.opacity),
    [propKeyOpacityVar]: fnRawValueToFieldValue("pickVarId", studioField.opacityVarId),
    [propKeyOpacityFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.opacityFieldId)

  };
}
