import {ArrowForwardRounded} from "@mui/icons-material";
import {ArrowBackRounded} from "@mui/icons-material";
import {Box} from "@mui/material";
import {Button} from "@mui/material";
import {useEffect} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {FieldErrors} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnWizard} from "../../../../api/meta/base/dto/DefnWizard";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getComp} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFormCtx} from "../base/CtxForm";
import FieldFactory from "../base/FieldFactory";

export default function FieldWizard<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnWizard
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
})
{
  const formCtx = useFormCtx();

  const defnWizard = props.defn;

  const fieldList = useMemo(() =>
  {
    return defnWizard.compositeIdSet && defnWizard.compositeIdSet.length
      ? defnWizard.compositeIdSet.filter(value =>
      {
        const visibilityOption = formCtx.getFieldVisibilityOption(value);
        return !(formCtx.getDefnComp(value)?.hidden || visibilityOption?.hidden);
      })
      : [];

  }, [defnWizard.compositeIdSet]);

  return (
    <Controller
      name={defnWizard.metaId}
      control={formCtx.control()}
      defaultValue={fieldList[0]}
      render={({
        field,
        formState: {
          errors
        }
      }) =>
      {
        return <RealFieldWizard
          {...props}
          fieldList={fieldList}
          fieldValue={field.value}
          onChange={field.onChange}
          errors={errors}
        />;
      }}
    />
  );
}

function RealFieldWizard<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnWizard
  fieldList: MetaIdComposite[];
  fieldValue?: MetaIdComposite,
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
  errors: FieldErrors
  onChange: (value: MetaIdComposite) => void,
})
{
  const fieldList = props.fieldList;
  const defn = props.defn;
  const defnForm = props.defnForm;
  const listBinderMap = props.listBinderMap;
  const defnTheme = props.defnTheme;
  const fieldValue = props.fieldValue;
  const onChange = props.onChange;

  const currentIndex = fieldValue
    ? fieldList.indexOf(fieldValue)
    : -1;

  const handlePrev = useCallback(() =>
  {
    onChange(fieldList[currentIndex - 1]);

  }, [currentIndex]);

  const handleNext = useCallback(() =>
  {
    onChange(fieldList[currentIndex + 1]);

  }, [currentIndex]);

  useEffect(() =>
  {
    if(fieldList.length > 0 && (!fieldValue || !fieldList.includes(fieldValue)))
    {
      onChange(fieldList[0]);
    }

  }, [fieldValue, fieldList]);

  if(!fieldValue)
  {
    return;
  }

  return (
    <LayoutFlexCol
      height={"100%"}
      width={"100%"}
      alignItems={"start"}
      justifyContent={"space-between"}
      flexGrow={1}
      pl={defn.pl !== undefined ? px(defn.pl) : 0}
      pr={defn.pr !== undefined ? px(defn.pr) : 0}
      pt={defn.pt !== undefined ? px(defn.pt) : 0}
      pb={defn.pb !== undefined ? px(defn.pb) : 0}
    >
      <Box
        height={"100%"}
        width={"100%"}
        overflow={"auto"}
        display={"flex"}
        flexDirection={"column"}
      >
        <FieldFactory
          defnForm={defnForm}
          defnTheme={defnTheme}
          defnComp={getComp(defnForm, fieldValue)}
          listBinderMap={listBinderMap}
        />
      </Box>

      <LayoutFlexRow
        width={"100%"}
        justifyContent={"space-between"}
        pl={px(gapStd)}
        pr={px(gapStd)}
        pt={px(gapHalf)}
        pb={px(gapHalf)}
      >
        <WizardButton
          label={"Prev"}
          cbOnClick={handlePrev}
          startIcon={<ArrowBackRounded />}
          disabled={currentIndex === 0 || currentIndex === -1}
        />
        <WizardButton
          label={"Next"}
          cbOnClick={handleNext}
          endIcon={<ArrowForwardRounded />}
          disabled={currentIndex === fieldList.length - 1 || currentIndex === -1}
        />
      </LayoutFlexRow>
    </LayoutFlexCol>
  );
}

function WizardButton(props: {
  label?: string,
  disabled?: boolean,
  startIcon?: React.ReactNode,
  endIcon?: React.ReactNode,
  cbOnClick: () => void,
})
{
  const {
    label,
    disabled,
    startIcon,
    endIcon,
    cbOnClick
  } = props;

  return (
    <Button
      disableFocusRipple={true}
      onClick={cbOnClick}
      startIcon={startIcon}
      endIcon={endIcon}
      size={"small"}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}
