import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {isEmpty} from "lodash";
import React from "react";
import {useEffect} from "react";
import {useMemo} from "react";
import {useState} from "react";
import {SigUserAvatar} from "../../../../../api/home/drawer/sig/SigUserAvatar";
import {DefnField} from "../../../../../api/meta/base/dto/DefnField";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {FieldValueEntUserId} from "../../../../../api/meta/base/dto/FieldValueEntUserId";
import {EntUserId} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {fnGetBubbleFormFieldText} from "../../../../../base/plus/BubblePlus";
import {fnIsHiddenBubbleFormField} from "../../../../../base/plus/BubblePlus";
import {getLabel} from "../../../../../base/plus/StringPlus";
import {TypeTextColor} from "../../../../../base/types/TypesGlobal";
import {RootState} from "../../../../../Store";
import RawHighlighter from "../../../raw/RawHighlighter";
import {BubbleFieldFactory} from "./bubbleFields/BubbleFieldFactory";

const variant: Variant = "body2";

export function BubbleRawFormField(props: {
  defnForm: DefnForm,
  compKey: MetaIdField,
  valueMap?: Record<MetaIdField, any>;
  updatedKeySet?: MetaIdField[];
  hiddenFieldsType?: string[];
  searchWords?: string[],
  maxWidth?: number,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const [color, setColor] = useState<TypeTextColor | undefined>(undefined);
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const fieldId = props.compKey;
  const field = props.defnForm.compMap[fieldId] as DefnField;
  const label = getLabel(field);
  const valueMap = props.valueMap;

  const isHidden = useMemo(() =>
  {
    return fnIsHiddenBubbleFormField(field, valueMap, props.hiddenFieldsType);
  }, [field, fieldId, props.hiddenFieldsType, valueMap]);

  const text = useMemo(() =>
  {
    if((field.type === "refUser" || field.type === "userId" || field.type === "pickUser")
      && (valueMap?.[fieldId] as FieldValueEntUserId)?.value)
    {
      // special case for pickUser because we get displayField with pubSub
      return (valueMap?.[fieldId] as FieldValueEntUserId)?.displayField || " ";
    }
    const formFieldValue = fnGetBubbleFormFieldText(field, valueMap);

    if(formFieldValue && !isEmpty(formFieldValue))
    {
      return formFieldValue;
    }

  }, [field, fieldId, valueMap]);

  useEffect(() =>
  {
    props.updatedKeySet?.includes(fieldId)
      ? setColor("success")
      : setColor(undefined);

  }, [fieldId, props.updatedKeySet]);

  if(isHidden || !text)
  {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "row",
        flexShrink: 0,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%"
      }}
    >
      <RawHighlighter
        variant={variant}
        value={label + ": "}
        color={color ?? "primary"}
        noWrap={true}
        maxWidth={"75%"}
        isUserSelect={true}
        mr={gapHalf}
      />

      <BubbleFieldFactory
        field={field}
        searchWords={props.searchWords}
        valueMap={props.valueMap}
        selectUserAvatar={props.selectUserAvatar}
        text={text}
        variant={variant}
      />

    </Box>
  );
}

