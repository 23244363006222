import ISrvc from "../../base/ISrvc";
import {store} from "../../Store";
import SrvcCacheControlPanelEnt from "./ent/SrvcCacheControlPanelEnt";
import SrvcCacheControlPanelPlugin from "./plugin/SrvcCacheControlPanelPlugin";
import {setProductionCurrentTab} from "./SliceCacheControlPanel";
import {ActionSetProductionCurrentTab} from "./TypeCacheControlPanel";

export default class SrvcCacheControlPanel extends ISrvc
{

  public readonly ent = new SrvcCacheControlPanelEnt();
  public readonly plugin = new SrvcCacheControlPanelPlugin();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.ent,
      this.plugin
    );
  }

  setProductionCurrentTab(payload: ActionSetProductionCurrentTab)
  {
    store.dispatch(setProductionCurrentTab(payload));
  }
}
