import {useEffect} from "react";
import React from "react";
import {useCallback} from "react";
import {useState} from "react";
import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {StudioVar} from "../../api/meta/base/dto/StudioVar";
import {EnumArrayStudioVarKind} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {EnumDefnDeploy} from "../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../api/meta/base/Types";
import {ArtifactId} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {getPasteFailMsg} from "../../base/plus/SrvcPlus";
import {toLabel} from "../../base/plus/StringPlus";
import theme from "../../base/plus/ThemePlus";
import {FormClickVariant} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {IFormRef} from "../../base/types/TypesForm";
import {EnumIconStrip} from "../../base/types/TypesIcon";
import {IDtoEntCopy} from "../../base/types/TypesStudio";
import {RootState} from "../../Store";
import {useAppSelector} from "../app/AppHooks";
import DialogPaste from "../atom/dialog/DialogPaste";
import {DividerHorizontal} from "../atom/layout/DividerHorizontal";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import PaneFooter from "../atom/pane/PaneFooter";
import PaneSide from "../atom/pane/PaneSide";
import {usePageCtx} from "../ctx/CtxPage";
import {ASIDE_FOOTER_BUTTON_KIND} from "../form/builder/base/TypesFormBuilder";
import {fieldCustomDateTime} from "../form/builder/base/TypesFormBuilder";
import {fieldTimeDateTime} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyLabel} from "../form/builder/base/TypesFormBuilder";
import {CbOnClickForm} from "../form/viewer/base/CtxForm";
import Form from "../form/viewer/Form";
import {defnToDtoVariable} from "./base/MapDefnToDtoVariable";
import {dtoVariableToDefn} from "./base/MapDtoVariableToDefn";
import {fieldVariableValue} from "./base/VariablePlus";
import {compVariableDeployType} from "./base/VariablePlus";
import {getVariableSelectListMap} from "./base/VariablePlus";
import {useVariableValueInput} from "./base/VariablePlus";
import {getVariableDefnForm} from "./VariableBuilder";

export function VariableEditor(props: {
  artifactId: ArtifactId,
  isPluginVariable?: boolean,
  onClickClose: () => void,
  variable?: StudioVar,
  readonly?: boolean,
  cbRef: IFormRef;
  reset?: boolean,
  entFormStore: (state: RootState) => FormStore;
  displayDateFormat?: string;
  cbAddVariable?: (variable: StudioVar, uploadMedia?: boolean) => void,
  cbCopyVariable?: () => void
  cbUploadFormMedia?: (mediaMap: Record<string, Record<string, File>>) => void;
})
{
  const pageCtx = usePageCtx();
  const artifactId = props.artifactId;
  const closeAside = props.onClickClose;
  const variable = props.variable;
  const reset = props.reset;
  const readonly = props.readonly;
  const displayDateFormat = props.displayDateFormat;
  const entFormStore = props.entFormStore;
  const cbAddVariable = props.cbAddVariable;
  const cbRef = props.cbRef;
  const cbCopyVariable = props.cbCopyVariable;
  const isPluginVariable = props.isPluginVariable;

  const varId = variable?.metaId;

  const formStore = useAppSelector(state => entFormStore(state));

  const refVariable = useRef<StudioVar | undefined>(variable);
  const variableType = variable?.kind as EnumStudioVarKind;

  const {
    variablePropertyTab,
    onWatch,
    onFormClick: cbOnClickFormItem
  } = useVariableValueInput(variableType, cbRef, varId, undefined, undefined, displayDateFormat);

  const [deployType, setDeployType] = useState<EnumDefnDeploy>(refVariable.current?.deploy ?? "fixedOnDeploy");
  const [labelHelperTextName, setLabelHelperTextName] = useState<string | undefined>();
  const [showLabelHelperTextName, setShowLabelHelperTextName] = useState<boolean>();

  const [defnForm, setDefnForm] = useState<DefnForm>(getVariableDefnForm(
    variable?.kind as EnumStudioVarKind,
    variable?.deploy ?? deployType,
    variablePropertyTab,
    isPluginVariable,
    labelHelperTextName,
    showLabelHelperTextName
  ));

  const selectListMap = getVariableSelectListMap(variableType);

  // region set, reset and submit

  const dtoToValue = useCallback((variable: StudioVar) =>
  {
    return dtoVariableToDefn(variable);
  }, []);

  const setValues = useCallback((variable: StudioVar) =>
  {
    cbRef.remoteReset(dtoToValue(variable));
  }, [cbRef, dtoToValue]);

  const resetValues = useCallback(() =>
  {
    cbRef.checkSubmit();
  }, [cbRef]);

  const onSubmit = useCallback((values: FieldValues) =>
  {
    if(variable)
    {
      const variableType = refVariable.current?.kind as EnumStudioVarKind;
      const entVariable = {
        ...defnToDtoVariable(values,
          variableType,
          refVariable.current
        ),
        metaId: refVariable.current?.metaId as MetaIdVar
      } as StudioVar;

      cbAddVariable && cbAddVariable(entVariable, true);
      resetValues();
    }
  }, [artifactId, variable]);

  // endregion

  //region copy & paste

  const onClickApply = useCallback((stringifyVariable: string) =>
  {
    const variablePayload = JSON.parse(stringifyVariable) as IDtoEntCopy;

    if(variablePayload.type === "variable")
    {
      const variable = variablePayload.payload as StudioVar;

      if(variable.kind !== variableType)
      {
        pageCtx.showErrorToast(getPasteFailMsg(variablePayload.type, variableType));
        return;
      }
      if(isValidVariable(variable))
      {
        setValues(variable);
        refVariable.current = {
          ...variable,
          metaId: refVariable.current?.metaId as MetaIdVar
        };
      }
      else
      {
        pageCtx.showErrorToast("Invalid variable");
      }
    }
    else
    {
      pageCtx.showErrorToast(getPasteFailMsg(variablePayload.type, "variable"));
    }
  }, [pageCtx, setValues, variableType]);

  const onClickPaste = useCallback(() =>
  {
    pageCtx.showDialog(
      <DialogPaste
        title={"Paste action"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={onClickApply}
      />
    );
  }, [pageCtx, onClickApply]);

  const onClickIconStrip = useCallback((iconStrip: EnumIconStrip) =>
  {
    switch(iconStrip)
    {
      case "copy":
        cbCopyVariable && cbCopyVariable();
        break;
      case "paste":
        onClickPaste();
        break;
    }
  }, [onClickPaste, cbCopyVariable]);

  // endregion

  const onFormClick = useCallback<CbOnClickForm>((
    key: MetaIdField,
    action: FormClickVariant,
    value: any,
    menuAnchor,
    menuProps
  ) =>
  {
    cbOnClickFormItem(key, action, value, menuAnchor, menuProps);
    if(action === "varAddNew")
    {
      if(value && isValidVariable(value))
      {
        cbAddVariable && cbAddVariable(value);
      }
    }
  }, [cbOnClickFormItem, cbAddVariable]);

  const cbOnWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(key === compVariableDeployType)
    {
      setDeployType(value as EnumDefnDeploy);
    }
    if(key === compVariableDeployType && value && variable?.kind)
    {
      setDefnForm(getVariableDefnForm(variable?.kind,
        value,
        variablePropertyTab,
        isPluginVariable,
        labelHelperTextName,
        showLabelHelperTextName
      ));
    }
    if(key === fieldKeyLabel)
    {
      if(fnFieldValueToRawValue("text", value))
      {
        setShowLabelHelperTextName(false);
      }
      else
      {
        setShowLabelHelperTextName(true);
      }
    }
    if(key === fieldKeyName && fnFieldValueToRawValue("symbol", value))
    {
      setLabelHelperTextName(toLabel(fnFieldValueToRawValue("symbol", value) as string));
    }
    if(key === fieldVariableValue)
    {
      cbRef.resetField(fieldTimeDateTime);
      cbRef.resetField(fieldCustomDateTime);
    }

    onWatch(key, value);
  }, [cbRef, labelHelperTextName, onWatch, showLabelHelperTextName, variable?.kind, variablePropertyTab]);

  useEffect(() =>
  {
    if(!reset)
    {
      refVariable.current = variable;
      if(variable)
      {
        setValues(variable);
        Object.entries(dtoVariableToDefn(variable)).forEach(([key, value]) =>
        {
          cbOnWatch(key, value);
        });
      }
    }
  }, [varId, reset]);

  useEffect(() =>
  {
    if(variable)
    {
      setDefnForm(getVariableDefnForm(
        variable.kind,
        variable.deploy,
        variablePropertyTab,
        isPluginVariable,
        labelHelperTextName,
        showLabelHelperTextName
      ));
    }
  }, [variablePropertyTab, labelHelperTextName, showLabelHelperTextName]);

  useEffect(() =>
  {
    setLabelHelperTextName(refVariable.current?.details.name);
    setShowLabelHelperTextName(!refVariable.current?.details?.label);
  }, [refVariable.current]);

  useEffect(() =>
  {
    return () => props.onClickClose();
  }, []);

  return (
    <PaneSide
      primaryText={"Update variable"}
      iconStrip={["copy", "paste"]}
      iconStripDisable={readonly ? ["copy", "paste"] : []}
      toolTipMap={{
        copy: "Copy variable",
        paste: "Paste variable"
      } as Record<EnumIconStrip, string>}
      onClickIconStrip={onClickIconStrip}
      onClickAction={closeAside}
    >
      <LayoutFlexCol
        flexGrow={1}
        overflowY={"auto"}
        height={0}
        bgcolor={theme.common.bgcolorContent}
      >
        <Form
          cbRef={cbRef}
          store={formStore}
          defnForm={defnForm}
          onSubmit={onSubmit}
          onClickFormItem={onFormClick}
          onWatch={cbOnWatch}
          formReadonly={readonly}
          selectListMap={selectListMap}
          initValues={variable
            ? dtoVariableToDefn(variable)
            : undefined}
        />
      </LayoutFlexCol>

      <DividerHorizontal />

      <PaneFooter
        footerButtonSet={[
          {icon: "apply"}
        ]}
        onIconButtonClick={(btnName) =>
        {
          switch(btnName)
          {
            case ASIDE_FOOTER_BUTTON_KIND:
              closeAside();
              break;
          }
        }}
      />
    </PaneSide>
  );
}

function isValidVariable(variable: StudioVar)
{
  return Boolean(variable
    && variable.metaId
    && variable.details.name
    && EnumArrayStudioVarKind.includes(variable.kind)
  );
}
