import {FormControl} from "@mui/material";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickGridRow} from "../../../../api/meta/base/dto/DefnFieldPickGridRow";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {FieldValueRowId} from "../../../../api/meta/base/dto/FieldValueRowId";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {FormValue} from "../../../../api/meta/base/dto/FormValue";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import {validateCondition} from "../base/FormVisibilityPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldPickGridRow(props: {
  defn: DefnFieldPickGridRow
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldShowAsDropdown
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const showAsDropdown = getFieldShowAsDropdown() as boolean;

  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const copyFieldMap = defn.copyFieldMap;

  useEffect(() =>
  {
    if(copyFieldMap)
    {
      Object.keys(copyFieldMap).forEach(fieldId =>
      {
        formCtx.setRefChildParentId(fieldId, defn.metaId);
      });
    }

  }, [copyFieldMap]);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueRowId | null;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <RealPickGridRow
                defn={defn}
                field={field}
                error={error}
                fieldValue={fieldValue}
                required={required}
                helperText={helperText}
                placeHolder={placeHolder}
                showAsDropdown={showAsDropdown}
                onChange={field.onChange}
              />
            </FormControl>
          </FieldRawTemplate>
        );

      }}
    />
  );
}

function RealPickGridRow(props: {
  defn: DefnFieldPickGridRow,
  field: ControllerRenderProps,
  error?: FieldError,
  fieldValue?: FieldValueRowId | null,
  required?: boolean,
  helperText?: string,
  placeHolder?: string,
  showAsDropdown?: boolean,
  onChange?: (value?: FieldValueRowId | null) => void
})
{
  const hookFormCtx = useFormContext();
  const formCtx = useFormCtx();
  const defnForm = useFormSectionCtx().getDefnForm();

  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const field = props.field;
  const error = props.error;

  const required = props.required;
  const helperText = props.helperText;
  const placeHolder = props.placeHolder;
  const showAsDropdown = props.showAsDropdown;

  const defnTheme = formCtx.getDefnTheme();
  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const searchText = formCtx.getSearchText();
  const variant = defnTheme.fieldVariant;
  const fieldVariant = variant === "standard" ? "outlined" : variant;
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;

  const [optionMap, setOptionMap] = useState<DefnStudioMapOfDtoOption>();
  const [loading, setLoading] = useState<boolean>(false);

  const onChangePickText = useCallback((value: string | null) =>
  {
    const val = value
      ? {
        value: value,
        displayField: optionMap?.map[value].value
      } as FieldValueRowId
      : null;

    if(!val)
    {
      Object.keys(defn.copyFieldMap ?? {}).forEach(fieldId =>
      {
        hookFormCtx.setValue(fieldId, null);
      });
    }

    props.onChange && props.onChange(val);

  }, [optionMap]);

  const onChangeText = useCallback((_value?: FieldValueText | null) =>
  {
    const value = fnFieldValueToRawValue("text", _value as FieldValueText);
    const val = value
      ? {
        value: value
      } as FieldValueRowId
      : null;

    if(!val)
    {
      Object.keys(defn.copyFieldMap ?? {}).forEach(fieldId =>
      {
        hookFormCtx.setValue(fieldId, null);
      });
    }
    props.onChange && props.onChange(val);

  }, []);

  const onClick = useCallback(() =>
  {
    const optionArray = [] as DefnDtoOption[];
    const formValueMap = hookFormCtx.getValues();
    const gridValue = formValueMap[defn.gridId] ? formValueMap[defn.gridId] as FieldValueGrid : null;
    const condition = defn.filterConditionVar;
    const gridDisplayFieldId = defn.gridDisplayFieldId;

    if(gridValue && gridValue.keys && gridValue.keys.length > 0)
    {
      const comp = gridDisplayFieldId
        ? defnForm.compMap[gridDisplayFieldId]
        : undefined;

      if(comp)
      {
        gridDisplayFieldId && gridValue.keys.forEach((rowId) =>
        {
          const fieldDtoGridRow = gridValue.map[rowId];
          const rowValueMap = fieldDtoGridRow.valueMap;

          if(rowValueMap && rowValueMap[gridDisplayFieldId])
          {
            const formValue = {
              rowId: fieldDtoGridRow.rowId,
              createdBy: fieldDtoGridRow.createdBy,
              createdOn: fieldDtoGridRow.createdOn,
              rowOrder: fieldDtoGridRow.rowOrder,
              updatedBy: fieldDtoGridRow.updatedBy,
              updatedOn: fieldDtoGridRow.updatedOn,
              valueMap: rowValueMap
            } as FormValue;

            const displayValue = fnFieldValueToRawValue(comp.type, rowValueMap[gridDisplayFieldId]) as string;
            
            if(condition && validateCondition(condition, defnForm, formValue))
            {
              optionArray.push({
                value: displayValue,
                metaId: rowId
              });
            }
            else
            {
              optionArray.push({
                value: displayValue,
                metaId: rowId
              });
            }
          }
        });
      }
      setOptionMap(optionsToMapOfOption(optionArray));
    }

  }, []);

  useEffect(() =>
  {
    if(showAsDropdown)
    {
      onClick && onClick();
    }
  }, []);

  const onBlur = () =>
  {
    field.onBlur();
    setLoading(false);
  };

  if(showAsDropdown)
  {
    return (
      <RawPickOneAutoComplete
        optionMap={optionMap}
        value={fieldValue?.value}
        onChange={onChangePickText}
        label={label}
        disabled={disabled}
        error={error}
        helperText={helperText}
        placeHolder={placeHolder}
        required={required}
        onClick={onClick}
        onBlur={onBlur}
        searchWords={searchText ? [searchText] : undefined}
        fieldVariant={fieldVariant}
        fieldSize={defnTheme.fieldSize}
        hideLabel={defn.hideLabel}
        name={field.name}
        fieldId={fieldId}
        loading={loading}
        disableSelected={true}
      />
    );
  }
  else
  {
    return (
      <FieldRawTextField
        defn={defn}
        value={fnRawValueToFieldValue("text", fieldValue?.displayField) as FieldValueText | undefined}
        onBlur={field.onBlur}
        error={error}
        ref={field.ref}
        onChange={onChangeText}
        onClick={onClick}
      />
    );
  }

}
