import {useTheme} from "@mui/material";
import {Box, Chip, TextField} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {FieldSection} from "@mui/x-date-pickers";
import {BaseSingleInputFieldProps} from "@mui/x-date-pickers";
import {PickerValidDate} from "@mui/x-date-pickers/models/pickers";
import {isArray} from "lodash";
import {useMemo} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {DefnFieldChipSetDate} from "../../../api/meta/base/dto/DefnFieldChipSetDate";
import {StudioSetOfDate} from "../../../api/meta/base/dto/StudioSetOfDate";
import {AnyTime} from "../../../api/meta/base/Types";
import {extractDateFormat} from "../../../base/plus/DatePlus";
import {formatDate} from "../../../base/plus/DatePlus";
import {getLocalDateFormat} from "../../../base/plus/DatePlus";
import {px} from "../../../base/plus/StringPlus";
import IFormCtx from "../../form/viewer/base/CtxForm";
import {getRawIcon} from "./RawIcon";
import RawLocalizationProvider from "./RawLocalizationProvider";

export default function RawDateSet(props: {
  defn: DefnFieldChipSetDate
  onChange: (values: StudioSetOfDate) => void;
  value?: StudioSetOfDate;
  formCtx: IFormCtx,
  fieldId: string,
  readonly?: boolean;
  disabled?: boolean;
  label?: string;
  placeHolder?: string;
  name?: string;
  allowDuplicate?: boolean;
  inputFieldSize?: "small" | "medium";
  hideLabel?: boolean;
  error?: FieldError;
  required?: boolean,
  icon?: string
})
{
  const {
    defn,
    value,
    onChange,
    readonly,
    disabled,
    placeHolder,
    allowDuplicate,
    formCtx,
    fieldId,
    icon
  } = props;

  const fieldValueDateTimeSet = useMemo(() => value?.valueSet && isArray(value.valueSet)
    ? value.valueSet
    : [], [value?.valueSet]);

  function formatDateToISOString(date: Date)
  {
    const isoString = date.toISOString();
    return isoString.slice(0, 16) + "Z";
  }

  const onAccept = useCallback((_inputValue: any) =>
  {
    const inputValue = formatDateToISOString(_inputValue);
    if(inputValue)
    {
      const updatedValue = [...fieldValueDateTimeSet, inputValue];

      if(!allowDuplicate && fieldValueDateTimeSet && fieldValueDateTimeSet.length > 0)
      {
        fieldValueDateTimeSet.indexOf(inputValue) === -1 &&
        onChange({
          valueSet: updatedValue
        });
      }
      else
      {
        onChange({
          valueSet: updatedValue
        });
      }
    }
  }, [allowDuplicate, fieldValueDateTimeSet, onChange]);

  const getOnClick = formCtx.getOnClick();
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);
  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const dateTimeFormat = defn?.displayDateFormat
    ? defn.displayDateFormat
    : getLocalDateFormat();

  return (
    <RawLocalizationProvider>
      <DatePicker
        sx={{
          ...borderColor && {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: borderColor
              }
            }
          }
        }}
        readOnly={readonly}
        disabled={disabled}
        slots={{
          // @ts-ignore
          field: AutocompleteField,
          openPickerIcon: getRawIcon(icon ?? "InsertInvitation")
        }}
        slotProps={{
          field: () => ({
            ...props,
            placeHolder: placeHolder,
            dateTimeFormat: dateTimeFormat,
            onChangeField: onChange
          })
        }}
        onAccept={onAccept}
        format={dateTimeFormat}
      />
    </RawLocalizationProvider>
  );
}

type AutoCompleteFieldProps = BaseSingleInputFieldProps<any, PickerValidDate, FieldSection, boolean, any> &
  {
    /**
     * @typescript-to-proptypes-ignore
     */
    onChangeField: (values: StudioSetOfDate) => void,
    openPickerIcon: React.ReactNode,
    value?: StudioSetOfDate;
    readonly?: boolean;
    disabled?: boolean;
    label?: string;
    placeHolder?: string;
    name?: string;
    allowDuplicate?: boolean;
    inputFieldSize?: "small" | "medium";
    hideLabel?: boolean;
    error?: FieldError;
    required?: boolean,
    fieldId: string,
    dateTimeFormat?: string,
    formCtx: IFormCtx,
  }

function AutocompleteField(props: AutoCompleteFieldProps)
{
  const {
    label,
    disabled,
    readOnly,
    value,
    onChangeField,
    error,
    placeHolder,
    inputFieldSize,
    InputProps: {
      ref,
      endAdornment
    } = {},
    inputProps,
    dateTimeFormat,
    formCtx,
    fieldId
  } = props;

  const fieldValueDateTimeSet = value?.valueSet && isArray(value.valueSet)
    ? value.valueSet
    : [];

  const theme = useTheme();

  const onDelete = useCallback((chipToDelete: string) => () =>
  {
    if(value)
    {
      const chips = value.valueSet.filter((currChip: AnyTime) =>
        currChip !== chipToDelete);

      onChangeField({
        valueSet: chips
      });
    }
  }, [onChangeField, value]);

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  return (
    <Autocomplete
      multiple
      freeSolo
      readOnly={true}
      ref={ref}
      fullWidth={true}
      disabled={disabled}
      renderTags={(chips: string[]) =>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.7
          }}
        >
          {
            chips.map((chip, index) =>
            {
              const formatedDate = formatDate(chip, dateTimeFormat, true);

              return (
                <Chip
                  key={chip + index}
                  deleteIcon={disabled ? <></> : undefined}
                  label={formatedDate ? extractDateFormat(formatedDate) : undefined}
                  onDelete={!readOnly || !disabled
                    ? onDelete(chip)
                    : undefined}
                  sx={{
                    height: px(theme.common.chipHeight),
                    borderRadius: "4px",
                    "& .MuiSvgIcon-root": {
                      height: px(theme.common.chipCrossButtonSize),
                      fontSize: px(theme.common.chipCrossButtonSize)
                    }
                  }}
                />
              );
            })
          }
        </Box>
      }
      renderInput={(params) =>
      {
        return <TextField
          {...params}
          inputProps={{...params.inputProps, ...inputProps}}
          InputProps={{
            ...params.InputProps,
            endAdornment: endAdornment || params.InputProps.endAdornment
          }}
          onClick={onClick}
          sx={{
            "& .MuiInputAdornment-positionEnd": {
              "& .MuiButtonBase-root": {
                position: "absolute",
                right: theme.common.gapStd
              }
            },
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          label={label}
          placeholder={placeHolder}
          error={Boolean(error?.message)}
          aria-readonly={readOnly}
          disabled={disabled}
          size={inputFieldSize ?? "small"}
        />;
      }}
      options={[]}
      value={fieldValueDateTimeSet}
    />
  );
}
