import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {EnumDefnConditionOperator} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickEnumConditionOperator(props: {
  defn: DefnFieldPickEnum
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const options = [
    {
      metaId: "greaterThan",
      value: ">"
    },
    {
      metaId: "lessThan",
      value: "<"
    },
    {
      metaId: "greaterThanOrEqualTo",
      value: ">="
    },
    {
      metaId: "lessThanOrEqualTo",
      value: "<="
    },
    {
      metaId: "equalTo",
      value: "=="
    },
    {
      metaId: "notEqualTo",
      value: "!="
    },
    {
      metaId: "hasValue",
      value: "Has value"
    },
    {
      metaId: "hasNoValue",
      value: "Has no value"
    }
  ] as {
    metaId: EnumDefnConditionOperator;
    value: string;
  }[];

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={optionsToMapOfOption(options)}
      />}
    />
  );
}
