import {FieldValues} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldCamera} from "../../../../../api/meta/base/dto/StudioFieldCamera";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyShowPreviewFieldId} from "../../base/TypesFormBuilder";
import {propKeyShowPreviewVar} from "../../base/TypesFormBuilder";
import {propKeyShowPreview} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeVar} from "../../base/TypesFormBuilder";
import {propKeyMaxSize} from "../../base/TypesFormBuilder";
import {propKeyShowCapturedValuesOnAside} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyShowSizeVar} from "../../base/TypesFormBuilder";
import {propKeyShowSize} from "../../base/TypesFormBuilder";
import {propKeyShowSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeyShowLabelFieldId} from "../../base/TypesFormBuilder";
import {propKeyShowLabelVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyShowLabel} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyCaptureTime} from "../../base/TypesFormBuilder";
import {propKeyCaptureUser} from "../../base/TypesFormBuilder";
import {propKeyCaptureLocation} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldCamera(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{

  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    propKeyShowLabelFieldId,
    propKeyShowPreviewFieldId,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    propKeyShowSizeFieldId,
    propKeyMaxSizeFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    propKeyShowLabelVarId,
    propKeyShowPreviewVar,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyShowSizeVar,
    propKeyMaxSizeVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyShowLabel,
    fieldGap1,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyShowSize,
    propKeyShowPreview,
    fieldGap2,
    propKeyCaptureLocation,
    propKeyCaptureTime,
    propKeyCaptureUser,
    fieldGap3,
    propKeyShowCapturedValuesOnAside,
    propKeyMaxSize
  ];

  return {

    ...getFieldBuilderPropertySubTabs(
      "camera",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId),

    [propKeyCaptureLocation]: {
      type: "bool",
      name: propKeyCaptureLocation,
      metaId: propKeyCaptureLocation,
      label: "Capture location",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureTime]: {
      type: "bool",
      name: propKeyCaptureTime,
      metaId: propKeyCaptureTime,
      label: "Capture time",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureUser]: {
      type: "bool",
      name: propKeyCaptureUser,
      metaId: propKeyCaptureUser,
      label: "Capture user",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowLabel]: {
      type: "bool",
      metaId: "showLabel",
      name: "Show label",
      label: "Show label",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowLabelVarId]: {
      type: "pickVarId",
      metaId: propKeyShowLabelVarId,
      name: propKeyShowLabelVarId,
      label: "Show label",
      formId: formId,
      varKind: "bool",
      showAsEdit: true
    } as DefnStudioPickVarId,

    [propKeyShowLabelFieldId]: {
      type: "pickFieldId",
      metaId: propKeyShowLabelFieldId,
      name: propKeyShowLabelFieldId,
      label: "Show label",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["bool"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyShowPreview]: {
      type: "bool",
      metaId: propKeyShowPreview,
      name: propKeyShowPreview,
      label: "Show preview",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowPreviewVar]: {
      type: "pickVarId",
      name: propKeyShowPreviewVar,
      metaId: propKeyShowPreviewVar,
      label: "Show preview",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyShowPreviewFieldId]: {
      type: "pickFieldId",
      metaId: propKeyShowPreviewFieldId,
      name: propKeyShowPreviewFieldId,
      label: "Show preview",
      formId: formId,
      filterFieldTypeSet: ["bool"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyShowSize]: {
      type: "bool",
      metaId: propKeyShowSize,
      name: propKeyShowSize,
      label: "Show size",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowSizeVar]: {
      type: "pickVarId",
      name: propKeyShowSizeVar,
      metaId: propKeyShowSizeVar,
      label: "Show size",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyShowSizeFieldId]: {
      type: "pickFieldId",
      metaId: propKeyShowSizeFieldId,
      name: propKeyShowSizeFieldId,
      label: "Show size",
      formId: formId,
      filterFieldTypeSet: ["bool"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyShowCapturedValuesOnAside]: {
      type: "enumCaptureValueKind",
      name: propKeyShowCapturedValuesOnAside,
      metaId: propKeyShowCapturedValuesOnAside,
      label: "Show captured values on aside"
    } as DefnFieldPickEnum
  };
}

export function defnValueToStudioFieldCamera(values: FieldValues): StudioFieldCamera
{
  return {
    type: "camera",
    captureLocation: fnFieldValueToRawValue("bool", values[propKeyCaptureLocation]),
    captureTime: fnFieldValueToRawValue("bool", values[propKeyCaptureTime]),
    captureUser: fnFieldValueToRawValue("bool", values[propKeyCaptureUser]),
    showLabel: fnFieldValueToRawValue("bool", values[propKeyShowLabel]),
    showLabelVarId: fnFieldValueToRawValue("pickVarId", values[propKeyShowLabelVarId]),
    showLabelFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyShowLabelFieldId]),
    showSize: fnFieldValueToRawValue("bool", values[propKeyShowSize]),
    showSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyShowSizeVar]),
    showSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyShowSizeFieldId]),
    showPreview: fnFieldValueToRawValue("bool", values[propKeyShowPreview]),
    showPreviewVarId: fnFieldValueToRawValue("pickVarId", values[propKeyShowPreviewVar]),
    showPreviewFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyShowPreviewFieldId]),
    showCapturedValuesOnAside: fnFieldValueToRawValue("enumCaptureValueKind", values[propKeyShowCapturedValuesOnAside]),
    maxSize: fnFieldValueToRawValue("number", values[propKeyMaxSize]),
    maxSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxSizeVar]),
    maxSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxSizeFieldId])
  } as StudioFieldCamera;
}

export function studioFieldToDefnValueCamera(studioField: StudioFieldCamera)
{
  return {
    [propKeyCaptureLocation]: fnRawValueToFieldValue("bool", studioField.captureLocation),
    [propKeyCaptureTime]: fnRawValueToFieldValue("bool", studioField.captureTime),
    [propKeyCaptureUser]: fnRawValueToFieldValue("bool", studioField.captureUser),
    [propKeyShowLabel]: fnRawValueToFieldValue("bool", studioField.showLabel),
    [propKeyShowLabelVarId]: fnRawValueToFieldValue("pickVarId", studioField.showLabelVarId),
    [propKeyShowLabelFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.showLabelFieldId),
    [propKeyShowSize]: fnRawValueToFieldValue("bool", studioField.showSizeFieldId),
    [propKeyShowSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.showSizeVarId),
    [propKeyShowSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.showSizeFieldId),
    [propKeyShowPreview]: fnRawValueToFieldValue("bool", studioField.showPreview),
    [propKeyShowPreviewVar]: fnRawValueToFieldValue("pickVarId", studioField.showPreviewVarId),
    [propKeyShowPreviewFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.showPreviewFieldId),
    [propKeyShowCapturedValuesOnAside]: fnRawValueToFieldValue("enumCaptureValueKind",
      studioField.showCapturedValuesOnAside),
    [propKeyMaxSize]: fnRawValueToFieldValue("number", studioField.maxSize),
    [propKeyMaxSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.maxSizeVarId),
    [propKeyMaxSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxSizeFieldId)
  };
}


