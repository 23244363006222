import {Collapse} from "@mui/material";
import {isEmpty} from "lodash";
import {useCallback} from "react";
import React from "react";
import {useMemo} from "react";
import {DtoFieldFilter} from "../../api/ent/base/dto/DtoFieldFilter";
import {DtoFieldFilterOption} from "../../api/ent/base/dto/DtoFieldFilterOption";
import {SpreadsheetFilterValue} from "../../api/ent/base/dto/SpreadsheetFilterValue";
import {SpreadsheetFilterValueDateRange} from "../../api/ent/base/dto/SpreadsheetFilterValueDateRange";
import {SpreadsheetFilterValueDoubleRange} from "../../api/ent/base/dto/SpreadsheetFilterValueDoubleRange";
import {SpreadsheetFilterValueLongRange} from "../../api/ent/base/dto/SpreadsheetFilterValueLongRange";
import {SpreadsheetFilterValueStringSet} from "../../api/ent/base/dto/SpreadsheetFilterValueStringSet";
import {SpreadsheetFilterValueSysIdSet} from "../../api/ent/base/dto/SpreadsheetFilterValueSysIdSet";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {formatDate} from "../../base/plus/DatePlus";
import {TypeComboId} from "../../base/types/TypesComboId";
import {toComboId} from "../../base/types/TypesComboId";
import {DividerHorizontal} from "../atom/layout/DividerHorizontal";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawTags from "../atom/raw/RawTags";
import {useSsBrCtx} from "./base/CtxSsBr";
import {TypeSpreadSheetBrModules} from "./TypesSsBr";

export default function SsBrFilterChipset(props: {
  defnFormRef: DefnForm,
  onFilterUpdate?: (selectedFilters?: SpreadsheetFilterValue[], searchStr?: string) => void
})
{
  const defnFormRef = props.defnFormRef;
  const onFilterUpdate = props.onFilterUpdate;
  const spreadSheetBrCtx = useSsBrCtx();
  const allFilters = spreadSheetBrCtx.getFilters();
  const selectedFilters = spreadSheetBrCtx.getSelectedFilters();
  const filterState = spreadSheetBrCtx.getFilterState();

  const tags = useMemo(() => getTags(allFilters, defnFormRef, selectedFilters),
    [allFilters, selectedFilters, defnFormRef]
  );

  const onClearAll = useCallback(() =>
  {
    spreadSheetBrCtx.onClearAll({reset: true});
    onFilterUpdate && onFilterUpdate();
  }, [onFilterUpdate, spreadSheetBrCtx]);

  const onRemoveModule = useCallback((tagId: TypeComboId) =>
  {
    const [filterId, valueId] = tagId.split("<>");
    if(valueId)
    {
      spreadSheetBrCtx.removeModule({
        filterId: filterId,
        valueId: valueId
      });
    }
    else
    {
      spreadSheetBrCtx.removeModule({filterId: filterId});
    }
  }, [spreadSheetBrCtx]);

  return (
    <Collapse
      sx={{width: "100%"}}
      in={!Boolean(isEmpty(tags))}
    >
      <LayoutFlexCol width={"100%"}>
        <RawTags
          tags={tags}
          onRemove={onRemoveModule}
          onClearAll={onClearAll}
          showProgress={filterState}
        />
        <DividerHorizontal />
      </LayoutFlexCol>
    </Collapse>
  );
}

function getTags(
  allFilters: DtoFieldFilter[],
  defnFormRef: DefnForm,
  selectedFilters?: SpreadsheetFilterValue[]
)
{
  const tags = {} as TypeSpreadSheetBrModules;
  const filterLabelMap = getFilterLabelMap(allFilters);

  selectedFilters?.forEach(filter =>
  {
    const filterId = filter.metaIdField;
    const comp = defnFormRef.compMap[filterId];

    switch(filter.type)
    {
      case "stringSet":
        if(comp.type === "text")
        {
          const valueSet = (filter as SpreadsheetFilterValueStringSet).valueSet?.at(0);
          if(valueSet)
          {
            tags[filterId] = valueSet;
          }
        }
        else
        {
          const valueSet = (filter as SpreadsheetFilterValueStringSet).valueSet;
          valueSet?.forEach(valueId =>
          {
            const comboId = toComboId(filterId, valueId);
            tags[comboId] = filterLabelMap[valueId];
          });
        }
        break;
      case "sysIdSet":
      {
        const valueSet = (filter as SpreadsheetFilterValueSysIdSet).valueSet;
        valueSet?.forEach(valueId =>
        {
          const comboId = toComboId(filterId, valueId);
          tags[comboId] = filterLabelMap[valueId];
        });
      }
        break;

      case "bool":
      {
        const label = filterLabelMap[filterId];
        const comboId = toComboId(filterId, "");
        if(label)
        {
          tags[comboId] = label;
        }
      }
        break;

      case "longRange":
      {
        const value = (filter as SpreadsheetFilterValueLongRange);
        const comboId = toComboId(filterId, "");
        const label = filterLabelMap[filterId];
        tags[comboId] = `${label}: ${getNumberRangeString(value)}`;
      }
        break;

      case "doubleRange":
      {
        const value = (filter as SpreadsheetFilterValueDoubleRange);
        const comboId = toComboId(filterId, "");
        const label = filterLabelMap[filterId];
        tags[comboId] = `${label}: ${getNumberRangeString(value)}`;
      }
        break;

      case "dateRange":
      {
        const value = (filter as SpreadsheetFilterValueDateRange);
        const comboId = toComboId(filterId, "");
        const label = filterLabelMap[filterId];
        tags[comboId] = `${label}: ${value.from
          ? formatDate(value.from, "local")
          : ""} - ${value.to
          ? formatDate(value.to, "local")
          : ""}`;
      }
    }
  });

  return tags;
}

function getFilterLabelMap(filters: DtoFieldFilter[])
{
  const filterLabelMap = {} as Record<string, string>;

  function recursive(valueList: DtoFieldFilterOption[], filter: DtoFieldFilter)
  {
    valueList.forEach(value =>
    {
      if(value.childFilters?.length)
      {
        recursive(value.childFilters, filter);
      }
      else
      {
        filterLabelMap[value.value] = value.label;
      }
    });
  }

  filters.forEach(filter =>
  {
    filterLabelMap[filter.metaIdField] = filter.name ?? filter.label;

    if(filter.valueList?.length)
    {
      recursive(filter.valueList, filter);
    }
  });

  return filterLabelMap;
}

function getNumberRangeString(range: SpreadsheetFilterValueLongRange | SpreadsheetFilterValueDoubleRange)
{
  let str = "";
  if(range.min)
  {
    str += range.min;
    if(range.max)
    {
      str += " - ";
    }
  }
  if(range.max)
  {
    str += range.max;
  }

  return str;
}
