import {EntId} from "../../api/meta/base/Types";
import {FnKeyProvider} from "./LocalFilterPickMany";
import LocalSessionStorage from "./LocalSessionStorage";

export default class LocalFilterPickOne
{
  constructor(readonly keyProvider: FnKeyProvider)
  {
  }

  get(callerIdHash: string)
  {
    return this.getStorage().getItem(this.getKey(callerIdHash));
  }

  set(callerIdHash: string, entId: EntId)
  {
    this.getStorage().setItem(this.getKey(callerIdHash), entId);
  }

  clear(callerIdHash: string)
  {
    return this.getStorage().removeItem(this.getKey(callerIdHash));
  }

  private getKey(callerIdHash: string)
  {
    return this.keyProvider(callerIdHash);
  }

  private getStorage(): Storage
  {
    return LocalSessionStorage;
  }
}
