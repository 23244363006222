import {useState} from "react";
import React, {useCallback} from "react";
import {isJsonString} from "../../../base/plus/StringPlus";
import {updateAllMetaIds} from "../../../base/plus/SysPlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import {IEditorRef} from "../raw/RawCodeEditor";
import RawCodeEditor from "../raw/RawCodeEditor";
import DialogAtom, {IDialogAtomRef} from "./DialogAtom";

const footerActionsValidContent = ["Cancel", "Apply"];
const footerActionsInValidContent = ["Cancel"];

export default function DialogPaste(props: {
  title: string
  onClose: () => void
  onApply: (text: string) => void
  fullScreen?: boolean
})
{
  const {
    title,
    fullScreen,
    onClose,
    onApply
  } = props;
  const cbDialogRef = {} as IDialogAtomRef;
  const cbEditorRef = {} as IEditorRef;
  const [textContent, setTextContent] = useState<string>("");
  const onChangeInput = useCallback((newValue?: string) =>
  {
    const isValidJson = newValue ? isJsonString(newValue) : false;
    if(isValidJson)
    {
      cbDialogRef.setRightFooterActions(footerActionsValidContent);
    }
    else
    {
      cbDialogRef.setRightFooterActions(footerActionsInValidContent);
    }
    setTextContent(newValue ?? "");
  }, []);

  const onClickAction = useCallback((actionName: string) =>
  {
    if(actionName === "Apply")
    {
      onApply(updateAllMetaIds(textContent));
    }
    else if(actionName === "Cancel")
    {
      onClose();
    }
  }, [textContent]);

  return (
    <DialogAtom
      cbRef={cbDialogRef}
      title={title}
      contentWidth={640}
      contentHeight={540}
      fullScreen={props.fullScreen}
      content={
        <LayoutFlexCol
          flexGrow={1}
          width={"100%"}
        >
          <RawCodeEditor
            value={textContent}
            onChange={onChangeInput}
            dragAndDrop={true}
            enableMiniMap={true}
            enableLineNumbers={true}
            cbRef={cbEditorRef}
          />
        </LayoutFlexCol>
      }
      rightFooterActions={footerActionsValidContent}
      onClickFooterAction={onClickAction}
    />
  );
}

