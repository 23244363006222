import {FormControl} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {useMemo} from "react";
import {useCallback} from "react";
import {useEffect} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldButton} from "../../../../api/meta/base/dto/DefnFieldButton";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldBase from "../raw/FieldBase";
import FieldRawButton from "../raw/FieldRawButton";

export default function FieldButton(props: {
  defn: DefnFieldButton,
  defnForm: DefnFormUi
})
{
  const formCtx = useFormCtx();
  const menuAnchorEl = React.useRef<HTMLButtonElement>(null);
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldDisableElevation,
    getFieldTextSize,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const disabledElevation = getFieldDisableElevation();
  const textSize = getFieldTextSize();
  const icon = getFieldIcon();

  const fieldId = getFieldKey(defn);
  const isValid = formCtx.isValid();
  const isSubmitting = formCtx.isSubmitting();
  const getOnClick = formCtx.getOnClick();
  const isReport = defnTheme.formVariant === "report";
  const helperText = defnDtoTextToString(defn.helperTextVar);
  const fieldVariant = defnTheme.fieldVariant;

  const {
    disabled,
    type
  } = useMemo(() =>
  {
    let disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
    let type: "submit" | "button" | "reset" | undefined;

    switch(defn.buttonKind)
    {
      case "submit":
        type = "submit";
        if(disabled === undefined)
        {
          disabled = isSubmitting || !isValid || !formCtx.isDirty();
        }
        break;
      case "reset":
        type = "reset";
        if(disabled === undefined)
        {
          disabled = !formCtx.isDirty();
        }
        break;
      case "refresh":
        break;
      default:
        type = "button";
        if(disabled === undefined)
        {
          disabled = false;
        }
        break;
    }
    return {
      disabled,
      type
    };

  }, [formCtx, defn, isSubmitting, isValid]);

  const onClick = useCallback(() =>
  {
    if(getOnClick)
    {
      getOnClick(fieldId, "field", fieldId, menuAnchorEl.current || undefined);
    }
  }, [fieldId, getOnClick]);

  useEffect(() =>
  {
    formCtx.setCbFieldRemoteClick(fieldId, onClick);
  }, [onClick, fieldId]);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fullWidth = Boolean(!isReport && props.defn.buttonPositionVar === "flexCenter");
        return (
          !defn.btnPosDoNotInline
            ? <FieldBase
              width={isReport ? "auto" : "100%"}
              fieldId={fieldId}
              pt={defn.pt}
              pr={defn.pr}
              pb={defn.pb}
              pl={defn.pl}
            >
              <FormControl
                fullWidth
                variant={fieldVariant}
                error={isError}
              >
                <FieldRawButton
                  ref={menuAnchorEl}
                  defn={defn}
                  cbOnClickBtn={onClick}
                  type={type}
                  size={defnTheme.buttonSize}
                  fullWidth={fullWidth}
                  isReport={isReport}
                  disabled={disabled}
                  disabledElevation={disabledElevation}
                  textSize={textSize}
                  icon={icon}
                />
                {(isError || helperText) &&
                  <FormHelperText
                    sx={{
                      whiteSpace: "pre-wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flexGrow: 1
                    }}
                  >
                    {error?.message ? error.message : helperText}
                  </FormHelperText>
                }
              </FormControl>
            </FieldBase> : <></>
        );
      }}
    />
  );
}

