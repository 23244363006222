import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../base/plus/ListPlus";

export function reducersStudioApp()
{
  return combineReducers({
    fieldPermissionMatrixList: createListSlice("fieldPermissionMatrixList", {defaultListItemType: "p"}).reducer,
    formVisibilityActionListIfTrue: createListSlice("formVisibilityActionList", {defaultListItemType: "ps"}).reducer,
    formVisibilityActionListIfFalse: createListSlice("formVisibilityActionListIfFalse",
      {defaultListItemType: "ps"}).reducer,
    conditionList: createListSlice("conditionList", {defaultListItemType: "ps"}).reducer,
    formVisibilityList: createListSlice("formVisibilityList", {defaultListItemType: "ps"}).reducer,
    formFormulaSetList: createListSlice("formFormulaSetList", {defaultListItemType: "ps"}).reducer,
    formContentLayoutList: createListSlice("formContentLayoutList", {defaultListItemType: "ps"}).reducer,
    formLayoutGridList: createListSlice("formLayoutGridList", {defaultListItemType: "ps"}).reducer,
    formulaSetList: createListSlice("formulaSetList", {defaultListItemType: "ps"}).reducer,
    variableSetOfTextList: createListSlice("variableSetOfTextList", {defaultListItemType: "p"}).reducer,
    variableCalendarList: createListSlice("variableCalendarList", {defaultListItemType: "p"}).reducer,
    variableFunctionInputList: createListSlice("variableFunctionInputList", {defaultListItemType: "p"}).reducer,
    variableSetOfNumberList: createListSlice("variableSetOfNumberList", {defaultListItemType: "p"}).reducer,
    variableMapOfText: createListSlice("variableMapOfText", {defaultListItemType: "ps"}).reducer,
    studioArtifactPicker: createListSlice("studioArtifactPicker", {defaultListItemType: "aps"}).reducer,
    variableSchedulerNextExecutionList: createListSlice("variableSchedulerNextExecutionList",
      {defaultListItemType: "p"}).reducer,
    studioEntElementPicker: createListSlice("studioEntElementPicker", {defaultListItemType: "p"}).reducer
  });
}
