import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldPickOption} from "../../../../api/meta/base/dto/DefnFieldPickOption";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickOption(props: {
  defn: DefnFieldPickOption
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const optionSet = defn.optionSet;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={arrayToMapOfOption(optionSet)}
      />}
    />
  );
}
