import {KeyboardArrowDownRounded} from "@mui/icons-material";
import {KeyboardArrowRightRounded} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {stripIconSx} from "../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import {ILineHeader} from "../../../base/types/TypesGlobal";
import {TypeEnumCaptionButton} from "../../list/List";
import {BoxP} from "../box/BoxP";
import {BoxPS} from "../box/BoxPS";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawSkeleton from "../raw/RawSkeleton";

export function LineHeader(props: {
  header?: ILineHeader
  searchWords?: string[],
  mb?: number,
  onClickPrefixIcon?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickIconButtonCaption?: (e: React.MouseEvent<HTMLButtonElement>, type: TypeEnumCaptionButton) => void,
})
{
  const theme = useTheme();
  let itemWithSecondaryHeight = getListItemHeightAPSA("ps");

  const header = props.header;
  const lineHeight = px(header?.secondary ? itemWithSecondaryHeight : theme.common.heightLine) as string;

  if(header)
  {
    return (
      <LocalLineHeader
        header={header}
        lineHeight={lineHeight}
        searchWords={props.searchWords}
        mb={props.mb}
        onClickPrefixIcon={props.onClickPrefixIcon}
        onClickCaption={props.onClickCaption}
        onClickIconButtonCaption={props.onClickIconButtonCaption}
      />
    );
  }
  else
  {
    return (
      <RawSkeleton
        variant="text"
        height={lineHeight}
        width={"100%"}
        mb={props.mb}
      />
    );
  }
}

function LocalLineHeader(props: {
  header: ILineHeader,
  lineHeight: string,
  mb?: number,
  onClickPrefixIcon?: (e: React.MouseEvent<HTMLElement>) => void,
  searchWords?: string[],
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickIconButtonCaption?: (e: React.MouseEvent<HTMLButtonElement>, type: TypeEnumCaptionButton) => void,
})
{
  const theme = useTheme();
  const header = props.header;
  const caption = header.caption;
  const expand = header.expand;
  let color = header.color;

  if(!color)
  {
    color = "primary";
  }

  const sx = stripIconSx(theme.common.color(color as TypeTextColor));

  return (
    <LayoutFlexRow
      width={"100%"}
      height={props.lineHeight}
      mb={px(props.mb)}
    >
      {
        expand !== undefined &&
        <IconButton
          edge={"start"}
          sx={{
            p: 0,
            margin: px(theme.common.gapStd),
            color: header.expandIconColor
          }}
          disableRipple={true}
          onClick={(e) =>
          {
            e.stopPropagation();
            e.preventDefault();
            props.onClickPrefixIcon && props.onClickPrefixIcon(e);
          }}
        >
          {
            expand
              ? <KeyboardArrowDownRounded sx={sx} />
              : <KeyboardArrowRightRounded sx={sx} />
          }
        </IconButton>
      }

      {
        header.secondary
          ? <BoxPS
            searchWords={props.searchWords}
            onClickCaption={props.onClickCaption}
            onClickIconButtonCaption={props.onClickIconButtonCaption}
            flexGrow={1}
            primary={{
              text: header.text,
              caption: header.caption,
              middle: header.middle,
              variant: header.variant,
              color: header.color ?? "primary"
            }}
            secondary={header.secondary}
          />
          : <BoxP
            searchWords={props.searchWords}
            onClickCaption={props.onClickCaption}
            onClickIconButtonCaption={props.onClickIconButtonCaption}
            flexGrow={1}
            primary={{
              text: header.text,
              middle: header.middle,
              caption: caption,
              variant: header.variant,
              color: color
            }}
          />
      }
    </LayoutFlexRow>
  );
}
