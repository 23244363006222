// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../base/ISigAcceptor';
import {ServiceName} from '../../meta/base/Types';
import {SigApiCode} from './sig/SigApiCode';
import {SigApiLib} from './sig/SigApiLib';
import {SigClassTree} from './sig/SigClassTree';
import {rpcCall} from '../base/IRpcCall';

export class RpcApi
{
  static readonly SN: ServiceName = "api";

  static apiCodeGet(sigAcceptor: ISigAcceptor<SigApiCode>): void
  {
    rpcCall<SigApiCode>(ENT_ID_GLOBAL, RpcApi.SN, "apiCodeGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static apiListGet(sigAcceptor: ISigAcceptor<SigApiLib>): void
  {
    rpcCall<SigApiLib>(ENT_ID_GLOBAL, RpcApi.SN, "apiListGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static sysIdTreeGet(sigAcceptor: ISigAcceptor<SigClassTree>): void
  {
    rpcCall<SigClassTree>(ENT_ID_GLOBAL, RpcApi.SN, "sysIdTreeGet")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }
}