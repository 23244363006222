import {SigCaller} from "../../../../api/core/user/sig/SigCaller";
import {RpcDrawer} from "../../../../api/home/drawer/RpcDrawer";
import {SigGroupCandidateMap} from "../../../../api/home/drawer/sig/SigGroupCandidateMap";
import {SigUserAvatar} from "../../../../api/home/drawer/sig/SigUserAvatar";
import {EntUserId} from "../../../../api/meta/base/Types";
import {GroupId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {createListItemGroup} from "../../../../base/plus/ListPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {listRecursiveCalls} from "../../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../../base/plus/MediaPlus";
import {textUser} from "../../../../base/plus/SrvcPlus";
import {random} from "../../../../base/plus/StringPlus";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetPickType} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItem} from "../../../../base/types/list/TypesList";
import {IListData} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListBinder} from "../../../../base/types/list/TypesList";
import {IListGroupsById} from "../../../../base/types/list/TypesListGroup";
import {toComboId} from "../../../../base/types/TypesComboId";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import {RootState} from "../../../../Store";
import {Srvc} from "../../../Srvc";

type TypeSigAvatarNewGroup = SigUserAvatar | undefined;

type TypeSigUserAvatar = SigUserAvatar | SigCaller | undefined

const loadMoreGroupMember = "Footer~~Item~~Id";

function isReservedItemId(itemId: TypeListItemId): boolean
{
  return itemId === (loadMoreGroupMember);
}

export default class SrvcHomeAsideGroupInfoAddMembers extends ISrvc
{
  private readonly subscriberId = "SrvcHomeAsideGroupInfo";

  subscribe(entChatId: TypeComboId, unsubscribe?: boolean): void
  {
    const subscriberId = this.subscriberId;
    Srvc.app.pubsub.homeAvatar(subscriberId, entChatId, unsubscribe);
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectAvatar.bind(this),
      onBindSourceItem1: this.onBindAvatar.bind(this)
    } as IListBinder<TypeSigUserAvatar>;
  }

  rpcContactsGet(groupId: GroupId)
  {
    const selectList: SelectList = state => state.home.aside.groupInfo.memberListAdd;
    const msg = {groupId: groupId};

    RpcDrawer.groupCandidateListGet(msg, envSig => listRecursiveCalls<SigGroupCandidateMap>(
      envSig,
      selectList,
      () => this.rpcContactsGet(groupId),
      this.doLoadContacts.bind(this)
    ));
  }

  private selectAvatar(state: RootState, entChatId: TypeComboId): TypeSigUserAvatar
  {
    if(isReservedItemId(entChatId))
    {
      return undefined;
    }
    return state.cache.app.user.userAvatarMap[entChatId as EntUserId];
  }

  private onBindAvatar(listName: string, aboutId: EntUserId, avatar?: TypeSigAvatarNewGroup): void
  {
    if(avatar)
    {
      const newItem = this.createUserAvatar(avatar);

      dispatchList(listName, listSetItem({
        itemId: aboutId,
        newItem: newItem
      }));
    }
  }

  private doLoadContacts(listName: string, sig: SigGroupCandidateMap): void
  {
    const version = random(16);
    const candidateMap = sig.candidateMap;

    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;

    const contactKeys = Object.keys(candidateMap);
    dispatchList(listName, listSetPickType("pickMany"));

    if(contactKeys.length)
    {
      contactKeys.forEach((prefix) =>
      {
        const itemIds = [] as TypeListItemId[];
        candidateMap[prefix].forEach((avatar) =>
        {
          const comboUserId = toComboId(avatar.entId, avatar.entUserId);
          uiItemsById[comboUserId] = this.createUserAvatar(avatar);
          itemIds.push(comboUserId);

          uiItemsById[prefix] = createListItemGroup(prefix);

        });

        uiGroupsById[prefix] = {
          itemIds: itemIds
        };

      });
      dispatchList(listName, listRefresh({
        itemsById: uiItemsById,
        groupsById: uiGroupsById,
        version: version
      } as IListData));
    }
  }

  private createUserAvatar(sig: SigUserAvatar)
  {
    return {
      version: sig.version,
      type: "aps",
      avatarLeft: {
        src: getImageThumbnail(sig.avatarId),
        icon: "user"
      },
      primary: {
        text: textUser(sig)
      },
      secondary: {
        text: sig.handle
      },
      hideMenu: true
    } as IListItem;
  }
}
