import {StudioPluginDev} from "../../../../api/meta/base/dto/StudioPluginDev";
import {StudioPluginJar} from "../../../../api/meta/base/dto/StudioPluginJar";
import {StudioPluginRpc} from "../../../../api/meta/base/dto/StudioPluginRpc";
import {EnumDefnPluginResources} from "../../../../api/meta/base/Types";
import {PluginBundleId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {dispatchPlugin} from "../../SrvcCacheStudio";
import {addPluginResourceDev} from "../SliceCacheStudioPlugin";
import {removePluginResource} from "../SliceCacheStudioPlugin";
import {addPluginResourceJar} from "../SliceCacheStudioPlugin";
import {addPluginResourceRpc} from "../SliceCacheStudioPlugin";

export default class SrvcCacheStudioPluginResource extends ISrvc
{
  addPluginResourceRpc(pluginBundleId: PluginBundleId, pluginRpc: StudioPluginRpc)
  {
    dispatchPlugin(pluginBundleId, addPluginResourceRpc({
      pluginBundleId: pluginBundleId,
      sig: pluginRpc
    }));
  }

  addPluginResourceJar(pluginBundleId: PluginBundleId, pluginJar: StudioPluginJar)
  {
    dispatchPlugin(pluginBundleId, addPluginResourceJar({
      pluginBundleId: pluginBundleId,
      sig: pluginJar
    }));
  }

  addPluginResourceDev(pluginBundleId: PluginBundleId, pluginDev: StudioPluginDev)
  {
    dispatchPlugin(pluginBundleId, addPluginResourceDev({
      pluginBundleId: pluginBundleId,
      sig: pluginDev
    }));
  }

  removePluginResource(pluginBundleId: PluginBundleId, resourceType: EnumDefnPluginResources)
  {
    dispatchPlugin(pluginBundleId, removePluginResource({
      pluginBundleId: pluginBundleId,
      resourceType
    }));
  }

}
