import {cloneDeepWith} from "lodash";
import {isEqual} from "lodash";
import {isEmpty} from "lodash";
import {cloneDeep} from "lodash";
import {Action} from "redux";
import {isRoleId} from "../../api/meta/base/ApiPlus";
import {isFormId} from "../../api/meta/base/ApiPlus";
import {isVariableId} from "../../api/meta/base/ApiPlus";
import {StudioEnt} from "../../api/meta/base/dto/StudioEnt";
import {StudioPluginBundle} from "../../api/meta/base/dto/StudioPluginBundle";
import {MetaIdRole} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {PluginBundleId} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {MetaId} from "../../api/meta/base/Types";
import ISrvc from "../../base/ISrvc";
import {isDeepEqual} from "../../base/plus/JsPlus";
import {mergeObjects} from "../../base/plus/JsPlus";
import {selectCacheStudio} from "../../base/plus/StudioPlus";
import {store} from "../../Store";
import {addDeltaEntLocalState} from "./ent/SliceCacheStudioEnt";
import SrvcCacheStudioEnt from "./ent/SrvcCacheStudioEnt";
import {ICacheStudioArtifactUsage} from "./ent/TypesCacheStudioEnt";
import {addDeltaPluginLocalState} from "./plugin/SliceCacheStudioPlugin";
import SrvcCacheStudioPlugin from "./plugin/SrvcCacheStudioPlugin";
import {DeltaStudioArtifact} from "./TypesCacheStudio";
import {TypeStepItemMap} from "./TypesCacheStudio";

type KeyOfObject = keyof object;

export default class SrvcCacheStudio extends ISrvc
{
  public readonly ent = new SrvcCacheStudioEnt();
  public readonly plugin = new SrvcCacheStudioPlugin();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.ent,
      this.plugin
    );
  }

  findStudioItemUsage(studioArtifact: StudioEnt | StudioPluginBundle): ICacheStudioArtifactUsage
  {
    const varUsageMap = {} as Record<MetaIdVar, number>;
    const roleUsageMap = {} as Record<MetaIdRole, number>;
    const formUsageMap = {} as Record<MetaIdForm, number>;

    JSON.stringify(studioArtifact, (key, value) =>
    {
      // exclude varMap > keys and trash
      if(key === "keys" || key === "trash")
      {
        return undefined;
      }
      if(key !== "metaId" && typeof value === "string")
      {
        if(isVariableId(value))
        {
          varUsageMap[value] = (varUsageMap[value] || 0) + 1;
        }
        if(isRoleId(value))
        {
          roleUsageMap[value] = (roleUsageMap[value] || 0) + 1;
        }
        if(isFormId(value))
        {
          formUsageMap[value] = (formUsageMap[value] || 0) + 1;
        }
      }
      return value;
    });

    return {
      varUsageMap: varUsageMap,
      formUsageMap: formUsageMap,
      roleUsageMap: roleUsageMap
    } as ICacheStudioArtifactUsage;
  }

  // region insert / remove
  cacheStudioItemInsert<T>(
    sigMap: TypeStepItemMap<T>,
    sig: T,
    sigMetaId: MetaId,
    insertIndex?: number): boolean
  {
    let isDirty = true;
    const metaId = sigMetaId;
    const keys = sigMap.keys;
    const map = sigMap.map;

    let oldSig = cloneDeep(map[metaId]);
    if(oldSig)
    {
      // const cleanedSig = JSON.parse(JSON.stringify(sig)); // remove undefined properties

      if(isDeepEqual(oldSig, sig))
      {
        isDirty = false;
      }
      else
      {
        map[metaId] = sig;
      }
    }
    else if(insertIndex !== undefined)
    {
      keys.splice(insertIndex, 0, metaId);
      map[metaId] = sig;
    }
    else
    {
      map[metaId] = sig;
      keys.push(metaId);
    }
    return isDirty;
  }

  cacheStudioItemRemove<T>(
    sigMap: TypeStepItemMap<T>,
    sigMetaId: MetaId): boolean
  {
    let isDirty = false;
    const keys = sigMap.keys;
    const map = sigMap.map;
    const index = keys.findIndex(key => key === sigMetaId);
    if(index !== -1)
    {
      isDirty = true;
      delete map[sigMetaId];
      keys.splice(index, 1);
    }
    return isDirty;
  }

  // endregion

  // region delta
  fnCalcDeltaStudioArtifactItem<T>(
    artifactItemNew: T,
    artifactItemOld: T,
    oldDelta?: DeltaStudioArtifact<T>): DeltaStudioArtifact<T>
  {
    const clonedArtifactItemNew = cloneDeep(artifactItemNew);
    const clonedArtifactItemOld = cloneDeep(artifactItemOld);

    const insert = oldDelta?.insert || {} as T;
    const update = oldDelta?.update || {} as T;
    const remove = oldDelta?.remove || {} as T;

    this._calcDeltaStudioArtifactItem(clonedArtifactItemNew, clonedArtifactItemOld, insert, update, remove);

    return {
      insert: insert,
      update: update,
      remove: remove
    } as DeltaStudioArtifact<T>;
  }

  fnApplyDeltaStudioArtifactItem<T>(
    setDirty: () => void,
    artifactItemNew: T,
    artifactItemOld?: T,
    deltaStudioEnt?: DeltaStudioArtifact<T>
  )
  {
    if(!deltaStudioEnt)
    {
      return artifactItemNew;
    }
    const clonedArtifactItemOld = artifactItemOld
      ? JSON.parse(JSON.stringify(artifactItemOld, (key, value) =>
      {
        return (value !== undefined && value !== null && value !== "") ? value : undefined;
      }))
      : undefined;
    const clonedArtifactItemNew = cloneDeepWith(artifactItemNew, (value, key) =>
    {
      return (value !== undefined && value !== null) ? value : undefined;
    });

    const deltaStudioArtifactInsert = deltaStudioEnt?.insert;
    const deltaStudioArtifactUpdate = deltaStudioEnt?.update;
    const deltaStudioArtifactDelete = deltaStudioEnt?.remove;
    if(artifactItemOld && !isEmpty(deltaStudioArtifactInsert))
    {
      this.fnApplyDeltaInsert(clonedArtifactItemNew as object,
        clonedArtifactItemOld,
        deltaStudioArtifactInsert,
        setDirty
      );
    }

    if(clonedArtifactItemOld && !isEmpty(deltaStudioArtifactUpdate))
    {
      this.fnApplyDeltaUpdate(clonedArtifactItemNew as object,
        clonedArtifactItemOld,
        deltaStudioArtifactUpdate,
        setDirty
      );
    }

    if(!isEmpty(deltaStudioArtifactDelete))
    {
      this.fnApplyDeltaRemove(clonedArtifactItemNew as object, deltaStudioArtifactDelete, setDirty);
    }
    return clonedArtifactItemNew;
  }

  private _calcDeltaStudioArtifactItem<T>(
    newObject: T,
    oldObject: T,
    insert: T,
    update: T,
    remove: T)
  {
    Object.entries(newObject as object).forEach(([key, val]) =>
    {
      if(val && !isEmpty(val) && typeof val === "object")
      {
        if(!remove[key as KeyOfObject])
        {
          remove[key as KeyOfObject] = {} as KeyOfObject;
        }

        if(!insert[key as KeyOfObject])
        {
          insert[key as KeyOfObject] = {} as KeyOfObject;
        }

        if(!update[key as KeyOfObject])
        {
          update[key as KeyOfObject] = {} as KeyOfObject;
        }

        const oldObjectElement = oldObject[key as KeyOfObject] as object;

        if(hasKeysAndMap(val) && hasKeysAndMap(oldObjectElement))
        {
          // @ts-ignore
          const oldRemoveKeys = remove[key as KeyOfObject]?.keys || [];
          const newRemoveKeys = [...oldRemoveKeys, ...oldObjectElement.keys.filter(key => !val.keys.includes(key))];
          remove[key as KeyOfObject] = {
            keys: newRemoveKeys
          } as KeyOfObject;

          // @ts-ignore
          const oldInsertKeys = insert[key as KeyOfObject]?.keys || [];
          const newInsertKeys =
            [...oldInsertKeys, ...val.keys.filter(key => !oldObjectElement.keys.includes(key))]
            .filter(k => !newRemoveKeys.includes(k));

          insert[key as KeyOfObject] = {
            keys: newInsertKeys
          } as KeyOfObject;

          // @ts-ignore
          const oldUpdateKeys = update[key as KeyOfObject]?.keys || [];
          val.keys.forEach((key: string) =>
          {
            const newItem = val.map[key];
            const oldItem = oldObjectElement.map[key];
            if(newItem && oldItem && !isEqual(newItem, oldItem))
            {
              oldUpdateKeys.push(key);
            }
          });
          update[key as KeyOfObject] = {
            keys: [...new Set(oldUpdateKeys).values()]
          } as KeyOfObject;

        }
        if(oldObject[key as KeyOfObject])
        {
          this._calcDeltaStudioArtifactItem(val,
            oldObject[key as KeyOfObject],
            insert[key as KeyOfObject],
            update[key as KeyOfObject],
            remove[key as KeyOfObject]
          );
        }
      }
      if(isEmpty(remove[key as KeyOfObject]))
      {
        delete remove[key as KeyOfObject];
      }
      if(isEmpty(insert[key as KeyOfObject]))
      {
        delete insert[key as KeyOfObject];
      }
      if(isEmpty(update[key as KeyOfObject]))
      {
        delete update[key as KeyOfObject];
      }
    });
    return;
  };

  private fnApplyDeltaInsert(
    artifactItemNew: object,
    artifactItemOld: object,
    deltaStudioArtifactInsert: object,
    makeDirty: () => void)
  {
    Object.entries(deltaStudioArtifactInsert).forEach(([key, val]) =>
    {
      if(val && typeof val === "object")
      {
        const newEntElement = artifactItemNew[key as KeyOfObject] as TypeStepItemMap<object>;
        const oldEntElement = artifactItemOld[key as KeyOfObject] as TypeStepItemMap<object>;
        if(val.hasOwnProperty("keys")
          && Array.isArray(val["keys" as KeyOfObject])
          && hasKeysAndMap(newEntElement))
        {
          const _valKeys = [...val.keys];
          _valKeys.forEach((_key: string) =>
          {
            if(!newEntElement.map[_key])
            {
              newEntElement.keys.push(_key);
              newEntElement.map[_key] = oldEntElement.map[_key];
              makeDirty();
            }
            else
            {
              // @ts-ignore
              deltaStudioArtifactInsert[key as KeyOfObject] = {
                ...val,
                keys: val.keys.filter((key: string) => key !== _key)
              };
            }
          });
        }
        if(artifactItemNew[key as KeyOfObject])
        {
          this.fnApplyDeltaInsert(
            artifactItemNew[key as KeyOfObject],
            artifactItemOld[key as KeyOfObject],
            deltaStudioArtifactInsert[key as KeyOfObject],
            makeDirty
          );
        }
      }
    });
  }

  private fnApplyDeltaUpdate(
    artifactItemNew: object,
    artifactItemOld: object,
    deltaStudioArtifactUpdate: object,
    makeDirty: () => void)
  {
    Object.entries(deltaStudioArtifactUpdate).forEach(([key, val]) =>
    {
      if(val && typeof val === "object")
      {
        const newEntElement = artifactItemNew[key as KeyOfObject] as TypeStepItemMap<object>;
        const oldEntElement = artifactItemOld[key as KeyOfObject] as TypeStepItemMap<object>;
        if(val.hasOwnProperty("keys")
          && Array.isArray(val["keys" as KeyOfObject])
          && hasKeysAndMap(newEntElement))
        {
          const _valKeys = [...val.keys];
          _valKeys.forEach((_key: string) =>
          {
            if(newEntElement.map[_key] && !isEqual(newEntElement.map[_key], oldEntElement.map[_key]))
            {
              newEntElement.map[_key] = mergeObjects(newEntElement.map[_key], oldEntElement.map[_key]);
              // @ts-ignore
              deltaStudioArtifactUpdate[key as KeyOfObject] = {
                ...val,
                keys: val.keys.filter((key: string) => key !== _key)
              };
              // makeDirty();
            }
          });
        }
        if(artifactItemNew[key as KeyOfObject])
        {
          this.fnApplyDeltaUpdate(
            artifactItemNew[key as KeyOfObject],
            artifactItemOld[key as KeyOfObject],
            deltaStudioArtifactUpdate[key as KeyOfObject],
            makeDirty
          );
        }
      }
    });
  }

  private fnApplyDeltaRemove(artifactItemNew: object, deltaStudioArtifactDelete: object, makeDirty: () => void)
  {
    Object.entries(deltaStudioArtifactDelete).forEach(([key, val]) =>
    {
      if(val && typeof val === "object")
      {
        const newEntElement = artifactItemNew[key as KeyOfObject] as TypeStepItemMap<object>;
        if(val.hasOwnProperty("keys")
          && Array.isArray(val["keys" as KeyOfObject])
          && hasKeysAndMap(newEntElement))
        {
          const _valKeys = [...val.keys];
          _valKeys.forEach((_key: string) =>
          {
            if(newEntElement.map[_key as KeyOfObject])
            {
              newEntElement.keys = newEntElement.keys.filter(key => key !== _key);
              delete newEntElement.map[_key as KeyOfObject];
              makeDirty();
            }
            // else
            // {
            //   // @ts-ignore
            //   deltaStudioArtifactDelete[key as KeyOfObject] = {
            //     ...val,
            //     keys: val.keys.filter((key: string) => key !== _key)
            //   };
            // }
          });
        }
        if(artifactItemNew[key as KeyOfObject])
        {
          this.fnApplyDeltaRemove(
            artifactItemNew[key as KeyOfObject],
            deltaStudioArtifactDelete[key as KeyOfObject],
            makeDirty
          );
        }
      }
    });
  }

  // endregion
}

export function dispatchEnt(entId: EntId, action: Action<string>)
{
  store.dispatch((dispatch, getState) =>
  {
    const rootState = getState();
    const oldEnt = selectCacheStudio(rootState)?.ent.entMap[entId]?.ent;
    Promise.resolve(dispatch(action)).then(() =>
    {
      if(oldEnt)
      {
        dispatch(addDeltaEntLocalState({
          entId,
          oldEnt: oldEnt
        }));
      }
    });
  });
}

export function dispatchPlugin(pluginBundleId: PluginBundleId, action: Action<string>)
{
  store.dispatch((dispatch, getState) =>
  {
    const rootState = getState();
    const studioPlugin = selectCacheStudio(rootState)?.plugin.pluginMap[pluginBundleId]?.studioPluginBundle.draft?.studioPlugin;
    Promise.resolve(dispatch(action)).then(() =>
    {
      if(studioPlugin)
      {
        dispatch(addDeltaPluginLocalState({
          pluginBundleId: pluginBundleId,
          oldStudioPlugin: studioPlugin
        }));
      }
    });
  });
}

function hasKeysAndMap(obj: unknown): obj is TypeStepItemMap<object>
{
  if(typeof obj === "object")
  {
    if(obj?.hasOwnProperty("keys") && obj?.hasOwnProperty("map"))
    {
      if(Array.isArray(obj["keys" as KeyOfObject]) && typeof obj["map" as KeyOfObject] === "object")
      {
        return true;
      }
    }
  }

  return false;
}

