import {PayloadAction} from "@reduxjs/toolkit";
import {SigSpreadsheetRowExpiry} from "../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {EnumReceiptStatus} from "../../../api/home/base/Types";
import {SigMessage} from "../../../api/home/main/sig/SigMessage";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {SigSpreadsheetRowCommentCount} from "../../../api/home/main/sig/SigSpreadsheetRowCommentCount";
import {SigTopicMessageProps} from "../../../api/home/session/sig/SigTopicMessageProps";
import {DefnDtoParagraph} from "../../../api/meta/base/dto/DefnDtoParagraph";
import {DefnDtoText} from "../../../api/meta/base/dto/DefnDtoText";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {RowId} from "../../../api/meta/base/Types";
import {MessageId} from "../../../api/meta/base/Types";
import {createListItemChat} from "../../plus/ListPlus";
import {IList} from "../../types/list/TypesList";
import {IListItemChat} from "../../types/list/TypesListChat";
import {IListGroup} from "../../types/list/TypesListGroup";
import {IBubbleReplyInfo} from "../../types/TypesBubble";
import {IBubbleHeader} from "../../types/TypesBubble";
import {TypeTextColor} from "../../types/TypesGlobal";
import {fnListSetDisplay} from "./SliceList";

export const sliceListChat =
  {
    listChatSetIfExistReceiptStatus: (state: IList, action: PayloadAction<{
      itemId: MessageId,
      receiptStatus?: EnumReceiptStatus,
    }>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.receiptStatus = payload.receiptStatus;
      }
    },
    listChatSetIfExistHeader: (state: IList, action: PayloadAction<{
      itemId: MessageId,
      header?: IBubbleHeader,
    }>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.header = payload.header;
      }
    },
    listChatSetIfExistDefnForm: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, defnForm: DefnForm}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.defnForm = payload.defnForm;
      }
    },
    listChatSetIfExistFormResult: (
      state: IList,
      action: PayloadAction<{itemId: MessageId | RowId, sigSpreadsheetRow: SigSpreadsheetRow}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.sigSpreadsheetRow = payload.sigSpreadsheetRow;
      }
    },
    listChatSetFormBubbleTitleColor: (
      state: IList,
      action: PayloadAction<{itemId: MessageId | RowId, formBubbleTitleColor?: TypeTextColor}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;

      if(sig)
      {
        sig.formBubbleTitleColor = payload.formBubbleTitleColor;
      }
    },
    listChatSetIfExistFormComment: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, sigSpreadsheetRowComment: SigSpreadsheetRowCommentCount}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig?.sigSpreadsheetRow)
      {
        sig.sigSpreadsheetRow = {
          ...sig.sigSpreadsheetRow,
          rowCommentCount: payload.sigSpreadsheetRowComment
        };
      }
      else if(sig?.payload.messageType === "report")
      {
        sig.reportRowCommentCount = payload.sigSpreadsheetRowComment;
      }
    },

    listChatSetIfExistSigSpreadsheetRowExpiry: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, sigSpreadsheetRowExpiry: SigSpreadsheetRowExpiry}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.sigSpreadsheetRowExpiry = payload.sigSpreadsheetRowExpiry;
      }
    },

    listChatSetIfExistChatPatternVar: (
      state: IList,
      action: PayloadAction<{
        itemId: MessageId,
        chatPatternVar?: DefnDtoParagraph,
        chatLabelPatternVar?: DefnDtoText
      }>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.chatPatternVar = payload.chatPatternVar;
        sig.chatLabelPatternVar = payload.chatLabelPatternVar;
      }
    },

    listChatSetIfExistMsgReply: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, replyInfo: IBubbleReplyInfo}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig?.replyPayload)
      {
        sig.replyInfo = payload.replyInfo;
      }
    },

    listChatSetIfExistIsVisibleSpreadsheetRow: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, isInvisibleSpreadsheetRow?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.isInvisibleSpreadsheetRow = payload.isInvisibleSpreadsheetRow;
      }
    },

    listChatSetIfExistIsStar: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, isStar?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.isStar = payload.isStar;
      }
    },
    listChatSetIfExistIsDownloading: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, isDownloading?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.isDownloading = payload.isDownloading;
      }
    },
    listChatSetIfExistIsCommentAble: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, isCommentAble?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.isCommentable = payload.isCommentAble;
      }
    },
    listSetIfExistIsFormWithMedia: (
      state: IList,
      action: PayloadAction<{itemId: MessageId, isFormWithMedia?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.itemsById[payload.itemId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.isFormWithMedia = payload.isFormWithMedia;
      }
    },

    listChatSetIfExistMessage: (
      state: IList,
      action: PayloadAction<SigMessage>) =>
    {
      const payload = action.payload;
      const msgId = action.payload.messageId;
      const listItem = (state.itemsById[msgId] as IListItemChat);
      const sig = (state.itemsById[msgId] as IListItemChat)?.sig;
      if(sig)
      {
        (state.itemsById[msgId] as IListItemChat) = createListItemChat(listItem.entId, listItem.chatId, payload);
      }
    },
    listChatHandleSigTopicMessageProps: (
      state: IList,
      action: PayloadAction<SigTopicMessageProps>) =>
    {
      if(state.loaded === undefined)
      {
        return;
      }
      const payload = action.payload;
      const sig = (state.itemsById[payload.messageId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.receiptStatus = payload.receiptStatus;
      }
    },
    listChatHandleSigTopicMessageRemoveForMe: (
      state: IList,
      action: PayloadAction<{messageId: MessageId}>) =>
    {
      if(state.loaded === undefined)
      {
        return;
      }
      const payload = action.payload;
      const messageId = payload.messageId;
      if(state.groupsById)
      {
        Object.entries(state.groupsById).forEach(([key, value]) =>
        {
          if(state.groupsById)
          {
            const groupItemIds = (state.groupsById[key] as IListGroup)?.itemIds;
            if(groupItemIds)
            {
              (state.groupsById[key] as IListGroup).itemIds = (value as IListGroup)?.itemIds.filter(itemId => itemId
                !== messageId);
            }
          }
        });
      }
      if(state.itemIds)
      {
        state.itemIds = state.itemIds?.filter(itemId => itemId !== messageId);
      }
      delete state.itemsById[messageId];
      fnListSetDisplay(state);
    },
    listChatHandleSigTopicFormRemove: (
      state: IList,
      action: PayloadAction<MessageId>
    ) =>
    {
      if(state.loaded === undefined)
      {
        return;
      }
      const msgId = action.payload;
      const sig = (state.itemsById[msgId] as IListItemChat)?.sig;
      if(sig)
      {
        sig.payload.messageType = "spreadsheetRowDeleted";
      }
    }
  };
