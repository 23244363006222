import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldParagraph} from "../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldKey = "key";
const fieldValue = "value";

export interface IPropertyMap
{
  key: string,
  value?: string
}

export default function DialogPropertyMap(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  values?: IPropertyMap,
  onClickOk: (values: IPropertyMap) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const values = props.values;
  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      title={"Key value"}
      formProps={{
        defnForm: getDefnForm(),
        store: props.formStore,
        formReadonly: props.isFormReadOnly,
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: values ? {...dtoToValue(values)} : undefined,
        cbRef: cbRef.current
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? "Add more key value"
        : undefined}
    />
  );
}

function valueToDto(values: FieldValues): IPropertyMap
{
  return {
    key: fnFieldValueToRawValue("text", values[fieldKey]),
    value: fnFieldValueToRawValue("paragraph", values[fieldValue])
  } as IPropertyMap;
}

function dtoToValue(dto?: IPropertyMap)
{
  if(dto)
  {
    return {
      [fieldKey]: fnRawValueToFieldValue("text", dto.key),
      [fieldValue]: fnRawValueToFieldValue("paragraph", dto.value)
    };
  }
}

function getDefnForm()
{
  return createDefaultDefnFormStudio({
    [fieldKey]: {
      type: "text",
      name: fieldKey,
      metaId: fieldKey,
      label: "Key",
      required: true
    } as DefnFieldText,
    [fieldValue]: {
      type: "paragraph",
      name: fieldValue,
      metaId: fieldValue,
      label: "Value",
      lineCountVar: 4
    } as DefnFieldParagraph,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldKey, fieldValue]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}


