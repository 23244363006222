import {useRef} from "react";
import React from "react";
import {DefnLayoutGridLocmap} from "../../../../api/meta/base/dto/DefnLayoutGridLocmap";
import {FormValue} from "../../../../api/meta/base/dto/FormValue";
import {RowId} from "../../../../api/meta/base/Types";
import {ILocationMapRef} from "../../../../base/types/TypeMap";
import helperTextData from "../../../atom/assets/PlaceholderTextHome.json";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import RawGoogleMap from "../../../googleMap/RawGoogleMap";
import {useFormCtx} from "../base/CtxForm";
import {IFieldGridRawProps} from "../composite/FieldGrid";

export default function FieldRawFieldGridMap(props: IFieldGridRawProps)
{
  const formCtx = useFormCtx();

  const gridMapLayout = props.layout as DefnLayoutGridLocmap;
  const defnForm = props.defnForm;
  const fieldValue = props.fieldValue;
  const defnGrid = props.defnGrid;
  const gridId = defnGrid.metaId;

  const getOnClick = formCtx.getOnClick();

  const cbMapRef = useRef<ILocationMapRef>({} as ILocationMapRef);

  if(!fieldValue?.keys.length || !gridMapLayout)
  {
    return (
      <LayoutFlexCol
        alignItems={"center"}
        justifyContent={"center"}
      >
        <RawNothingHere helperTextData={helperTextData.nothingToShow} />
      </LayoutFlexCol>
    );
  }

  return (
    <RawGoogleMap
      defnForm={defnForm}
      layout={gridMapLayout}
      cbRef={cbMapRef.current}
      initialDataMap={{
        keys: fieldValue.keys,
        map: fieldValue.map as Record<RowId, FormValue>
      }}
      cbOnClickMarker={(markerId, eventTarget) =>
      {
        getOnClick && getOnClick(gridId, "mapMarker", fieldValue?.map[markerId], eventTarget as Element);
      }}
    />
  );
}


