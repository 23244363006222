import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarPlacement} from "../../../api/meta/base/dto/StudioVarPlacement";
import {StudioVarRatingKind} from "../../../api/meta/base/dto/StudioVarRatingKind";

export function fnVariableBuilderPlacement(fieldValuePlacement: string)
{
  function getVarComp(filterOptionSet?: string[])
  {
    return {
      [fieldValuePlacement]: {
        type: "enumPlacement",
        metaId: fieldValuePlacement,
        label: "Value",
        required: true,
        filterOptionSet: filterOptionSet
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarPlacement
  )
  {
    valueMap[fieldValuePlacement] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValuePlacement];

    return {
      value: value
    } as StudioVarRatingKind;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
