import {FieldDtoDuration} from "../../api/meta/base/dto/FieldDtoDuration";
import {EnumDefnDurationUnit} from "../../api/meta/base/Types";
import {ISize} from "../types/TypesGlobal";

export function getPercentage(value: number, total: number)
{
  return Math.ceil((value * 100) / total);
}

export function getAspectRatio(width: number, height: number): ISize
{
  const gcd = (x: number, y: number) =>
  {
    x = Math.abs(x);
    y = Math.abs(y);
    while(y)
    {
      let t = y;
      y = x % y;
      x = t;
    }
    return x;
  };

  const GCD = gcd(height, width);

  return {
    width: width / GCD,
    height: height / GCD
  };
}

export function calcAspectHeight(actualWidth: number, aspectRatio: ISize): number
{
  return Math.floor((actualWidth / aspectRatio.width) * aspectRatio.height);
}

export function calcAspectWidth(actualHeight: number, aspectRatio: ISize): number
{
  return Math.floor((actualHeight / aspectRatio.height) * aspectRatio.width);
}

export function bytesToSize(bytes: number)
{
  if(bytes < 0)
  {
    throw new Error("Bytes cannot be negative");
  }
  if(bytes === 0)
  {
    return "0 bytes";
  }

  const k = 1024;
  const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
}

function countLeadingZeros(number: number)
{
  let strNum = number.toString();

  let decimalIndex = strNum.indexOf(".");

  if(decimalIndex === -1)
  {
    return 0;
  }

  let count = 0;
  for(let i = decimalIndex + 1; i < strNum.length; i++)
  {
    if(strNum[i] === "0")
    {
      count++;
    }
    else
    {
      break;
    }
  }

  return count;
}

export function KbToMb(size?: number)
{
  if(size === undefined)
  {
    return "";
  }
  const MB = (size / 1000024);
  const count = countLeadingZeros(MB);

  if(count < 2)
  {
    return MB.toFixed(2);
  }
  else
  {
    return MB.toFixed(3);
  }
}

export function mbToBytes(size: number): number
{
  return size * 1048576;
}

export function getDurationInSeconds(fieldValue: FieldDtoDuration): number
{
  const value = fieldValue?.value as number;
  const unit = fieldValue?.unit as EnumDefnDurationUnit;

  switch(unit)
  {
    case "seconds":
      return value;
    case "minutes":
      return value * 60;
    case "hours":
      return value * 60 * 60;
    case "days":
      return value * 60 * 60 * 24;
    case "weeks":
      return value * 60 * 60 * 24 * 7;
    case "months":
      return value * 60 * 60 * 24 * 30;
    case "quarters":
      return value * 60 * 60 * 24 * 90;
    case "years":
      return value * 60 * 60 * 24 * 365;
  }
}
