import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutCalendar} from "../../api/meta/base/dto/StudioDtoLayoutCalendar";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {EnumStudioCompType} from "../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {getFormSectionCompositeId} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldTabDetails} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription, fieldKeyLabel, fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {getCalendarTab} from "./base/LayoutBuilderPlus";
import {getCompMapSpreadSheetBrTabDetails} from "./base/LayoutBuilderPlus";
import {fieldTimeToField} from "./base/TypesLayoutBuilder";
import {fieldTimeFormField} from "./base/TypesLayoutBuilder";
import {fieldDateToField} from "./base/TypesLayoutBuilder";
import {fieldDateFormField} from "./base/TypesLayoutBuilder";
import {fieldColorField} from "./base/TypesLayoutBuilder";
import {fieldShowFieldIdSet} from "./base/TypesLayoutBuilder";
import {fieldTitleField} from "./base/TypesLayoutBuilder";
import {fieldTabCalendar} from "./base/TypesLayoutBuilder";
import {fieldSpreadsheetLayoutSwitcherIdSet} from "./base/TypesLayoutBuilder";
import {fieldLayoutDetailTooltipField} from "./base/TypesLayoutBuilder";

const dialogContentWidth = 900;
const dialogContentHeight = 600;

export default function DialogNewLayoutSpreadsheetCalendar(props: {
  formStore: FormStore,
  isFormReadOnly?: boolean,
  metaIdForm?: MetaIdForm,
  spreadsheetId: MetaIdSpreadsheet
  values?: StudioDtoLayoutCalendar,
  defaultValues?: StudioDtoLayoutCalendar,
  onClickOk: (values: StudioDtoLayoutCalendar) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const oldValues = props.values;
  const defaultValues = props.defaultValues;
  const formStore = props.formStore;
  const formMap = formStore.formMap;
  const formId = props.metaIdForm;

  const form = (formMap && formId)
    ? formMap.map[formId]
    : undefined;
  const initValues = oldValues || defaultValues;

  const [fieldMap, setFieldMap] = useState<Record<MetaIdField, EnumStudioCompType>>({});
  const [fromDatePickedFieldType, setFromDatePickedFieldType] = useState<EnumDefnCompType>();
  const [toDatePickedFieldType, setToDatePickedFieldType] = useState<EnumDefnCompType>();

  const excludeSpreadsheetLayoutIdSet = props.values?.metaId ? [props.values.metaId] : undefined;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const onWatch = useCallback((key: string, value: any) =>
  {
    const fieldType = fieldMap[value];
    if(key === fieldDateFormField)
    {
      setFromDatePickedFieldType(fieldType);

      if(fieldType === "dateTime")
      {
        cbRef.current.setValue(fieldTimeFormField, null);
        cbRef.current.setValue(fieldTimeToField, null);
      }
      else if(fieldType === "dateRange" || fieldType === "dateTimeRange")
      {
        cbRef.current.setValue(fieldTimeFormField, null);
        cbRef.current.setValue(fieldDateToField, null);
        cbRef.current.setValue(fieldTimeToField, null);
      }
    }
    else if(key === fieldDateToField)
    {
      setToDatePickedFieldType(fieldType);

      if(fieldType === "dateTime")
      {
        cbRef.current.setValue(fieldTimeToField, null);
      }
    }
  }, [fieldMap]);

  useEffect(() =>
  {
    if(form)
    {
      loopStudioForm(form, (_, field) =>
      {
        setFieldMap(prevState =>
        {
          return {
            ...prevState,
            [field.metaId]: field.type
          } as Record<MetaIdField, EnumStudioCompType>;
        });
      });
    }
  }, []);

  useEffect(() =>
  {
    Object.entries(dtoToValue(initValues)).forEach(([key, value]) =>
    {
      onWatch(key, value);
    });
  }, [fieldMap]);

  return (
    <DialogDefnForm
      title={`${oldValues ? "Update" : "New"} spreadsheet layout - Calendar`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(props.spreadsheetId,
          formId,
          excludeSpreadsheetLayoutIdSet,
          formStore,
          fromDatePickedFieldType,
          toDatePickedFieldType
        ),
        formReadonly: props.isFormReadOnly,
        store: formStore,
        initValues: dtoToValue(initValues),
        onSubmit: values => props.onClickOk(valueToDto(values, oldValues)),
        onWatch: onWatch
      }}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!oldValues
        ? "Add more spreadsheet layouts"
        : undefined}
      preventEnterKey={true}
    />
  );
}

function getDefnForm(
  spreadsheetId: MetaIdSpreadsheet,
  metaIdForm?: MetaIdForm,
  excludeSpreadsheetLayoutIdSet?: MetaIdLayoutGrid[],
  formStore?: FormStore,
  fromDatePickedFieldType?: EnumDefnCompType,
  ToDatePickedFieldType?: EnumDefnCompType
)
{
  const compositeIdSet = getFormSectionCompositeId(formStore, metaIdForm);

  return createDefaultDefnFormStudio({

    ...getCompMapSpreadSheetBrTabDetails(
      spreadsheetId,
      metaIdForm,
      excludeSpreadsheetLayoutIdSet,
      undefined,
      compositeIdSet,
      "calendar"
    ),

    ...getCalendarTab(metaIdForm, undefined, undefined, fromDatePickedFieldType, ToDatePickedFieldType),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: "standard",
      tabIdSet: [
        fieldTabDetails,
        fieldTabCalendar
      ]
    } as DefnTab

  } as Record<MetaIdField, DefnField>);
}

function dtoToValue(calendar?: StudioDtoLayoutCalendar)
{
  if(!calendar)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "Calendar")
    };
  }

  return {
    // tab details
    [fieldKeyName]: fnRawValueToFieldValue("symbol", calendar.name ?? "Calendar"),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", calendar.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", calendar.description),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", calendar.toolTipFieldId),
    [fieldSpreadsheetLayoutSwitcherIdSet]: fnRawValueToFieldValue("studioSetOfLayoutSpreadsheetId",
      calendar.allowToSwitchLayoutIdSet
    ),

    // tab calendar
    [fieldDateFormField]: fnRawValueToFieldValue("pickFieldId", calendar.fromDateFieldId),
    [fieldDateToField]: fnRawValueToFieldValue("pickFieldId", calendar.toDateFieldId),
    [fieldTimeFormField]: fnRawValueToFieldValue("pickFieldId", calendar.fromTimeFieldId),
    [fieldTimeToField]: fnRawValueToFieldValue("pickFieldId", calendar.toTimeFieldId),
    [fieldTitleField]: fnRawValueToFieldValue("pickFieldId", calendar?.titleFieldId),
    [fieldShowFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", calendar?.showFieldIdSet),
    [fieldColorField]: fnRawValueToFieldValue("pickFieldId", calendar?.colorFieldId)

  };
}

function valueToDto(
  values: FieldValues,
  dto?: StudioDtoLayoutCalendar
): StudioDtoLayoutCalendar
{
  return {
    metaId: dto?.metaId,
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    label: fnFieldValueToRawValue("text", values[fieldKeyLabel]),
    description: fnFieldValueToRawValue("text", values[fieldKeyDescription]),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLayoutDetailTooltipField]),
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioSetOfLayoutSpreadsheetId",
      values[fieldSpreadsheetLayoutSwitcherIdSet]
    ),
    kind: fnFieldValueToRawValue("enumLayoutGridKind", "calendar"),
    titleFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldTitleField]),
    showFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldShowFieldIdSet]),
    colorFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldColorField]),
    fromDateFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldDateFormField]),
    toDateFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldDateToField]),
    fromTimeFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldTimeFormField]),
    toTimeFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldTimeToField])
  } as StudioDtoLayoutCalendar;
}


