import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnStudioPickPluginCompId} from "../../../../api/meta/base/dto/DefnStudioPickPluginCompId";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {loopDefnForm} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

export default function FieldRawStudioPickPluginCompId(props: {
  defn: DefnStudioPickPluginCompId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean
  showChip?: boolean
})
{
  const defn = props.defn;
  const pluginId = defn.pluginId;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();

  const excludeSectionIdSet = defn.excludeCompositeIdSet;
  const filterCompTypeSet = defn.filterCompTypeSet;
  const pickSectionMetaIdForm = defn.formId;
  const plugin = pluginId
    ? formStore?.pluginMap?.map[pluginId]
    : undefined;
  const form = plugin?.pluginFormMap[pickSectionMetaIdForm] as DefnForm | undefined;

  const options = useMemo<DefnDtoOption[]>(() =>
      getOptions(form, excludeSectionIdSet, filterCompTypeSet)
    , [form, excludeSectionIdSet, filterCompTypeSet]);

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOptions(form?: DefnForm, excludeSectionIdSet?: string[], filterCompTypeSet?: EnumDefnCompType[])
{
  if(!form)
  {
    return [];
  }
  let options = [] as DefnDtoOption[];

  loopDefnForm(form, (_parent, comp) =>
  {
    if(comp)
    {
      if((comp.type === "section" || comp.type === "grid")
        && (!filterCompTypeSet || filterCompTypeSet?.includes(comp.type))
        && (!excludeSectionIdSet || !excludeSectionIdSet?.includes(comp.metaId)))
      {
        options.push({
          metaId: comp.metaId,
          value: comp.name
        });
      }
    }
  });
  return options;
}
