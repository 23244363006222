import {sliceFakeList} from "./SliceList";

export const {
  //region item
  listSetItem,
  listMergeItem,
  listClearItem,
  listResetItem,
  //endregion

  //region init
  listSetCanShowMenu,
  listSetIgnoreSelection,
  listSetTopItemCount,
  listSetError,
  listSetUserField,
  listSetUserFieldVar,
  listSetVersion,
  listSetLayoutType,
  listSetItemIds,
  listRefresh,
  listSetDefaultItemHeight,
  //endregion

  //region index
  listPushTop,
  listPushBottom,
  listMoveUp,
  listMoveDown,
  listMoveTop,
  listMoveBottom,
  listRemove,
  //endregion

  //region search
  listSetSearch,
  //endregion

  //region scroll and selection
  listSetSelectedItemId,
  listToggleIgnoreSelection,
  listSetScrollToItemId,
  listSetScrollToTop,
  //endregion

  //region pick
  listClearPickItemIds,
  listClearPickItemIdsAndSelectedItemId,
  listSetPickType,
  listSetPick,
  listSetShowPicksOnly,
  listToggleShowPicksOnly,
  //endregion

  //region set if exists
  listSetIfExistUserField,
  listSetItemIfExistUserFieldVar,
  listSetIfExistBgcolor,
  listSetIfExistVersion,
  listSetIfExistInfoSpot,
  listSetIfExistInfoSpots,

  //endregion
  listSetLoadingFooter

} = sliceFakeList.actions;
