import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {useCallback} from "react";
import React from "react";
import {getListItemHeight} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {getListItemIdFooter} from "../../../base/slices/list/SliceListFooter";
import {ListItemType} from "../../../base/types/list/TypesList";
import {IListItemFooter} from "../../../base/types/list/TypesListFooter";
import {useAppSelector} from "../../app/AppHooks";
import IconInputCustom from "../../atom/icon/IconInputCustom";
import LayoutFlexRow from "../../atom/layout/LayoutFlexRow";
import {CbOnClickListItem} from "../List";

export function ListItemFooter(props: {
  selectList: SelectList,
  onClickListItem?: CbOnClickListItem,
})
{
  const onClickListItem = props.onClickListItem;
  const theme = useTheme();
  const itemId = getListItemIdFooter();
  const defaultListItemType = useAppSelector(state => props.selectList(state).defaultListItemType as ListItemType);
  const item = useAppSelector(state => props.selectList(state).itemsById[itemId]) as IListItemFooter;
  const itemHeight = getListItemHeight(defaultListItemType) as number;

  const cbOnClickListItem = useCallback((e: React.MouseEvent<HTMLElement>) =>
  {
    onClickListItem && onClickListItem(e.currentTarget, itemId, item, "listItem");
  }, [onClickListItem, itemId, item]);

  return (
    <LayoutFlexRow
      width={"100%"}
      height={px(itemHeight)}
      bgcolor={theme.common.bgcolorContent}
      justifyContent={"left"}
      pr={px(22)}
      pl={px(22)}
      pt={px(16)}
      pb={px(16)}
      cursorPointer={true}
      onClick={cbOnClickListItem}
    >
      <IconInputCustom value={"expandMore"} />
      <Typography ml={px(22)}>{item.footer}</Typography>
    </LayoutFlexRow>
  );
}
