import {Box} from "@mui/material";
import {isArray} from "lodash";
import React from "react";
import {DefnDtoOption} from "../../../api/meta/base/dto/DefnDtoOption";
import {getDefnDtoColorToCssColor} from "../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../base/plus/JsPlus";
import {dispatchKanban} from "../../../base/plus/KanbanPlus";
import {SelectKanban} from "../../../base/plus/KanbanPlus";
import {px} from "../../../base/plus/StringPlus";
import theme from "../../../base/plus/ThemePlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {kanbanSetDisplaySelectedColumnsByItemId} from "../../../base/slices/kanban/SliceKanbanSharedActions";
import {TypeKanbanColId} from "../../../base/types/TypeKanban";
import {RootState} from "../../../Store";
import {useAppSelector} from "../../app/AppHooks";
import LineTextPrimary from "../../atom/line/LineTextPrimary";
import RawAutocomplete from "../../atom/raw/RawAutocomplete";

export const kanbanColHeaderHeight = 40;
export const kanbanColHeaderSwitcherHeight = 48;

export default function KanbanColHeader(props: {
  selectKanban: SelectKanban,
  maxWidth?: number,
  columnId: TypeKanbanColId,
  isColumSwitcher?: boolean
})
{
  const selectKanban = props.selectKanban;
  const columSwitcher = props.isColumSwitcher;
  const columnId = props.columnId;

  const kanbanName = useAppSelector((state: RootState) => selectKanban(state).kanbanName);
  const title = useAppSelector((state: RootState) => selectKanban(state).columnMap[columnId].title);
  const fieldName = useAppSelector((state: RootState) => selectKanban(state).columnMap[columnId].fieldName);
  const columnMap = useAppSelector((state: RootState) => selectKanban(state).columnMap);
  const color = useAppSelector(state => getDefnDtoColorToCssColor(selectKanban(state).columnMap[columnId]?.color));
  const bold = useAppSelector(state => selectKanban(state).columnMap[columnId].bold);
  const variant = useAppSelector(state => selectKanban(state).columnMap[columnId].variant);
  const searchWords = useAppSelector(state => selectKanban(state).searchWords);
  const displayColumnIds = useAppSelector(state => selectKanban(state).displayColumnIds);
  const displayColumnsByItemIds = useAppSelector(state => selectKanban(state).displayColumnsByItemIds);

  const bgcolor = getDefnDtoColorToCssColor(columnMap[columnId]?.bgcolor);
  const bgcolorDefault = theme.common.bgcolorSidePane;
  const filteredTitle = displayColumnsByItemIds[columnId]?.itemIds?.length
    ? `${title} (${displayColumnsByItemIds[columnId].itemIds.length})`
    : title;

  const options: DefnDtoOption[] = displayColumnIds.map((columnId) => ({
    metaId: columnId,
    value: columnMap[columnId].title
  } as DefnDtoOption));

  return (
    <Box
      width={"100%"}
      textAlign={"center"}
      height={px(columSwitcher ? kanbanColHeaderSwitcherHeight : kanbanColHeaderHeight)}
      mb={columSwitcher ? px(gapHalf) : undefined}
      bgcolor={bgcolor || bgcolorDefault}
      paddingTop={px(gapHalf)}
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 3
      }}
    >
      {!columSwitcher
        ? <LineTextPrimary
          value={filteredTitle}
          color={color}
          bold={bold}
          variant={variant}
          searchWords={searchWords}
        />
        : <RawAutocomplete
          label={fieldName}
          value={columnId}
          multiple={false}
          freeSolo={true}
          onChange={(value) =>
          {
            if(value && !isArray(value))
            {
              dispatchKanban(kanbanName, kanbanSetDisplaySelectedColumnsByItemId(value));
            }
          }}
          name={"sortField"}
          optionMap={optionsToMapOfOption(options)}
          fieldId={"sortField"}
        />
      }
    </Box>
  );
}
