import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../base/plus/ListPlus";

export function reducersEntAside()
{
  return combineReducers({
    deployPluginConfig: createListSlice("deployPluginConfig").reducer,
    deployVariableConfig: createListSlice("deployVariableConfig").reducer,
    groupActionRoleMapList: createListSlice("groupActionRoleMapList").reducer,
    groupActionRowRoleMapList: createListSlice("groupActionRowRoleMapList").reducer,
    dynamicRuleList: createListSlice("dynamicRuleList", {
      defaultListItemType: "ps"
    }).reducer,
    spreadSheetBrLayoutList: createListSlice("spreadSheetBrLayoutList").reducer,
    spreadSheetForwardRolePermission: createListSlice("spreadSheetForwardRolePermission").reducer,
    spreadSheetForwardGroupPermission: createListSlice("spreadSheetForwardGroupPermission").reducer,
    spreadSheetPartition: createListSlice("spreadSheetPartition", {defaultListItemType: "ps"}).reducer,
    pipeLineVariableList: createListSlice("pipeLineVariableList", {defaultListItemType: "p"}).reducer,
    driveSheetLayoutList: createListSlice("driveSheetLayoutList", {defaultListItemType: "p"}).reducer
  });
}
