// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {PluginBundleId} from '../../meta/base/Types';
import {ServiceName} from '../../meta/base/Types';
import {SigStudioDeployedArtifactList} from './sig/SigStudioDeployedArtifactList';
import {SigStudioEntAvatar} from './sig/SigStudioEntAvatar';
import {SigStudioPluginAvatar} from './sig/SigStudioPluginAvatar';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocStudioDrawer
{
  static readonly SN: ServiceName = "studioDrawer";

  static studioDeployedArtifactListGet(sigAcceptor: ISigAcceptor<SigStudioDeployedArtifactList>): void
  {
    wsocCall<SigStudioDeployedArtifactList>(ENT_ID_GLOBAL, WsocStudioDrawer.SN, "studioDeployedArtifactListGet")
      .get(undefined, sigAcceptor);
  }

  static studioEntAvatarGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigStudioEntAvatar>): void
  {
    wsocCall<SigStudioEntAvatar>(entId, WsocStudioDrawer.SN, "studioEntAvatarGet")
      .get(msg, sigAcceptor);
  }

  static studioPluginAvatarGet(pluginBundleId: PluginBundleId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigStudioPluginAvatar>): void
  {
    wsocCall<SigStudioPluginAvatar>(pluginBundleId, WsocStudioDrawer.SN, "studioPluginAvatarGet")
      .get(msg, sigAcceptor);
  }
}