import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {DtoLogItem} from "../../api/meta/base/dto/DtoLogItem";
import {DtoLogText} from "../../api/meta/base/dto/DtoLogText";
import {EntId} from "../../api/meta/base/Types";
import {ArtifactId} from "../../api/meta/base/Types";
import {ICliArtifactMap} from "./TypeCacheTerminal";
import {ICliCodeMap} from "./TypeCacheTerminal";
import {ICacheCliState} from "./TypeCacheTerminal";

function fnInitialEntCliCode(state: ICacheCliState, artifactId: ArtifactId)
{
  if(!state.entCliCodeMap[artifactId])
  {
    state.entCliCodeMap[artifactId] = {
      keys: [],
      map: {},
      starKeys: [],
      inputSet: []
    } as ICliCodeMap;
  }
}

export const sliceTerminal = createSlice({
  name: "cacheTerminalMain",
  initialState: {
    entCliCodeMap: {},
    suggestionMap: {},
    lastArtifactMap: {}
  } as ICacheCliState,
  reducers: {
    addLogItem: (state, action: PayloadAction<{artifactId: ArtifactId, logItem: DtoLogItem}>) =>
    {
      const {
        artifactId,
        logItem
      } = action.payload;

      fnInitialEntCliCode(state, artifactId);

      const entCliMap = state.entCliCodeMap[artifactId];

      entCliMap.keys.push(logItem.id);
      entCliMap.map[logItem.id] = logItem;
    },
    removeLogItem: (state, action: PayloadAction<{artifactId: ArtifactId, logItemId: string}>) =>
    {
      const {
        artifactId,
        logItemId
      } = action.payload;

      const entCliMap = state.entCliCodeMap[artifactId];

      if(entCliMap)
      {
        const index = entCliMap.keys.indexOf(logItemId);
        if(index !== -1)
        {
          entCliMap.keys.splice(index, 1);
          delete entCliMap.map[logItemId];
        }
      }
    },
    updateLogItem: (
      state,
      action: PayloadAction<{artifactId: ArtifactId, logItemId: string, newLogItem: DtoLogItem}>) =>
    {
      const {
        artifactId,
        logItemId,
        newLogItem
      } = action.payload;

      const entCliMap = state.entCliCodeMap[artifactId];

      if(entCliMap)
      {
        entCliMap.map[logItemId] = newLogItem;
      }
    },
    updateLogItemText: (state, action: PayloadAction<{artifactId: ArtifactId, logItemId: string, newText: string}>) =>
    {
      const {
        artifactId,
        logItemId,
        newText
      } = action.payload;

      const entCliMap = state.entCliCodeMap[artifactId];

      if(entCliMap)
      {
        const logItem = entCliMap.map[logItemId] as DtoLogText;
        if(logItem)
        {
          logItem.text = newText;
        }
      }
    },
    updateLogItemBgColor: (
      state,
      action: PayloadAction<{artifactId: ArtifactId, logItemId: string, newBgColor: string}>) =>
    {
      const {
        artifactId,
        logItemId,
        newBgColor
      } = action.payload;

      const entCliMap = state.entCliCodeMap[artifactId];

      if(entCliMap)
      {
        const logItem = entCliMap.map[logItemId] as DtoLogText;
        if(logItem)
        {
          logItem.bgColor = newBgColor;
        }
      }
    },
    addLogItemList: (state, action: PayloadAction<{artifactId: ArtifactId, logItemList: DtoLogItem[]}>) =>
    {
      const {
        artifactId,
        logItemList
      } = action.payload;

      fnInitialEntCliCode(state, artifactId);

      const {
        keys,
        map
      } = state.entCliCodeMap[artifactId];

      for(const logItem of logItemList)
      {
        const index = keys.indexOf(logItem.id);
        if(index === -1)
        {
          keys.push(logItem.id);
        }
        map[logItem.id] = logItem;
      }
    },
    removeLogItemList: (state, action: PayloadAction<{artifactId: ArtifactId}>) =>
    {
      const {
        artifactId
      } = action.payload;

      if(state.entCliCodeMap[artifactId])
      {
        state.entCliCodeMap[artifactId].keys = [];
        state.entCliCodeMap[artifactId].map = {};
      }
    },

    addInput: (state, action: PayloadAction<{artifactId: ArtifactId, input: string}>) =>
    {
      const {
        artifactId,
        input
      } = action.payload;

      fnInitialEntCliCode(state, artifactId);

      const entCliMap = state.entCliCodeMap[artifactId];

      entCliMap.inputSet = [
        ...entCliMap.inputSet,
        input
      ];
    },

    updateLastArtifact: (state, action: PayloadAction<{artifactId: ArtifactId, artifactName: string}>) =>
    {
      const {
        artifactId,
        artifactName
      } = action.payload;

      state.lastArtifactName = artifactName;
      state.lastArtifactId = artifactId;
    },

    updateLastArtifactMap: (state, action: PayloadAction<{entId: EntId, value: ICliArtifactMap}>) =>
    {
      const {
        entId,
        value
      } = action.payload;

      state.lastArtifactMap[entId] = value;
    },

    updateSuggestionMap: (state, action: PayloadAction<Record<string, string>>) =>
    {
      state.suggestionMap = action.payload;
    },

    updateStartErrorLineNumber: (state, action: PayloadAction<number | undefined>) =>
    {
      state.startErrorLineNumber = action.payload;
    }
  }
});

export const {
  addLogItem,
  removeLogItem,
  addLogItemList,
  removeLogItemList,
  updateLogItem,
  updateLogItemText,
  updateLogItemBgColor,
  addInput,
  updateLastArtifact,
  updateLastArtifactMap,
  updateSuggestionMap,
  updateStartErrorLineNumber
} = sliceTerminal.actions;
