import {useTheme} from "@mui/material";
import React from "react";
import {DtoMessagePayloadGroup} from "../../../../api/home/base/dto/DtoMessagePayloadGroup";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import {BoxAPS} from "../../box/BoxAPS";
import LayoutFlexCol from "../../layout/LayoutFlexCol";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import BubbleLineActions from "../line/BubbleLineActions";
import BubbleLineFooter from "../line/BubbleLineFooter";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleShell from "./BubbleShell";

export default function BubbleGroup(props: {
  payload: DtoMessagePayloadGroup,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  header?: IBubbleHeader,
  isStar?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  onClickJoinGroup?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor
})
{
  const theme = useTheme();
  const heightLine = theme.common.bubbleHeightLine;
  const payload = props.payload;
  const onClickJoinGroup = props.onClickJoinGroup;
  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));
  const actionList = ["Join"];
  const width = theme.common.calcBubbleFixedWidth(props.maxWidth);

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={props.maxWidth}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleBlinking={props.isBubbleBlinking}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      <LayoutFlexCol
        width={px(width)}
        height={"auto"}
      >
        {
          props.header &&
          <BubbleRawHeader
            header={props.header}
            onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
          />
        }

        {
          props.replyPayload &&
          <BubbleRawReplyPreview
            replyPayload={props.replyPayload}
            replyInfo={props.replyInfo}
            onClickPreview={props.cbOnClickReplyPreview}
          />
        }

        <LayoutFlexRow
          pr={px(theme.common.gapHalf)}
          pl={px(theme.common.gapHalf)}
          width={"100%"}
        >
          <BoxAPS
            avatar={{
              src: getMediaSrc(payload.mediaIdAvatar),
              icon: "group"
            }}
            primary={{
              text: payload.name
            }}
            secondary={{
              text: payload.about
            }}
            searchWords={props.searchWords}
            flexGrow={1}
          />
        </LayoutFlexRow>

        <BubbleLineActions
          actionList={actionList}
          onClick={onClickJoinGroup
            ? (actionName, menuAnchor) =>
            {
              onClickJoinGroup(menuAnchor);
            }
            : undefined}
          heightLine={heightLine}
        />

        <BubbleLineFooter
          isStar={props.isStar}
          heightLine={heightLine}
          textRight={{
            iconRight: props.receiptStatus,
            text: captionTime,
            onClick: props.cbOnClickFooterCaption
          }}
        />

      </LayoutFlexCol>
    </BubbleShell>
  );
}
