import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigRecentList} from "../../../../api/home/drawer/sig/SigRecentList";
import {createSet} from "../../../../base/plus/JsPlus";
import {random} from "../../../../base/plus/StringPlus";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import {toComboId} from "../../../../base/types/TypesComboId";
import {ICacheRecentListState} from "./TypesCacheHomeDrawerRecentList";

export const sliceCacheHomeDrawerRecentList = createSlice({
  name: "recentList",
  initialState: {
    recentList: [],
    recentMap: {}
  } as ICacheRecentListState,
  reducers: {
    setRecentList: (state, action: PayloadAction<SigRecentList>) =>
    {
      const sig = action.payload;
      state.version = sig.version;
      if(!sig.version && sig.recentList.length === 0)
      {
        state.version = random(); // for handling loading in home recentList
      }

      const newRecentList = [] as TypeComboId[];

      const deleteSet = createSet(state.recentList);
      sig.recentList.forEach(dtoRecentItem =>
      {
        const entChatId = toComboId(dtoRecentItem.entId, dtoRecentItem.chatId);

        newRecentList.push(entChatId);
        deleteSet.delete(entChatId);

        const currItem = state.recentMap[entChatId];
        if(!currItem || currItem.isPinned !== dtoRecentItem.isPinned)
        {
          state.recentMap[entChatId] = dtoRecentItem;
        }
      });

      deleteSet.forEach((entChatId =>
      {
        delete state.recentMap[entChatId];
      }));

      // change the recent list
      state.recentList = newRecentList;
    }
  }
});

export const {
  setRecentList
} = sliceCacheHomeDrawerRecentList.actions;
