import {NotificationsOff} from "@mui/icons-material";
import {ManageAccountsRounded} from "@mui/icons-material";
import {QueueRounded} from "@mui/icons-material";
import {PinRounded} from "@mui/icons-material";
import {Lock} from "@mui/icons-material";
import {LockOpen} from "@mui/icons-material";
import React from "react";
import {textIconSx} from "../../../base/plus/ThemePlus";
import {EnumIconListItemSecondary4} from "../../../base/types/TypesIcon";

export default function IconListItemSecondary4(props: {
  value?: EnumIconListItemSecondary4
})
{
  const icon = props.value;
  if(icon === undefined)
  {
    return null;
  }

  const sx = textIconSx();
  switch(icon)
  {
    case "pin":
      return <PinRounded sx={sx} />;
    case "lock":
      return <Lock sx={sx} />;
    case "unlock":
      return <LockOpen sx={sx} />;
    case "queue":
      return <QueueRounded sx={sx} />;
    case "admin":
      return <ManageAccountsRounded sx={sx} />;
    case "mute":
      return <NotificationsOff sx={sx} />;
  }
}
