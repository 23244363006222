import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {StudioVarCurrency} from "../../../api/meta/base/dto/StudioVarCurrency";

export function fnVariableBuilderCurrency(fieldValueCurrency: string)
{
  function getVarComp()
  {
    return {
      [fieldValueCurrency]: {
        type: "currency",
        metaId: fieldValueCurrency,
        label: "Value",
        required: true
      } as DefnFieldEditable
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarCurrency
  )
  {
    valueMap[fieldValueCurrency] = variable.value?.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueCurrency] as string;

    return {
      value: {
        value: value
      }
    } as StudioVarCurrency;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
