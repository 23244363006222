import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldNumber} from "../../../api/meta/base/dto/DefnFieldNumber";
import {FieldValueNumber} from "../../../api/meta/base/dto/FieldValueNumber";
import {StudioVarNumber} from "../../../api/meta/base/dto/StudioVarNumber";

export function fnVariableBuilderNumber(fieldValueNumber: string)
{
  function getVarComp()
  {
    return {
      [fieldValueNumber]: {
        type: "number",
        metaId: fieldValueNumber,
        label: "Value",
        required: true
      } as DefnFieldNumber
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarNumber
  )
  {
    valueMap[fieldValueNumber] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueNumber] as FieldValueNumber;
    if(value?.value === undefined || value?.value === null || isNaN(value.value))
    {
      return;
    }

    return {
      value: value
    } as StudioVarNumber;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
