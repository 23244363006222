import {createSlice} from "@reduxjs/toolkit";
import {IChat} from "../../types/TypesChat";
import {sliceChatShared} from "./SliceChatShared";

export function getChatNameFake()
{
  return `Fake~~Chat~~`;
}

export const sliceFakeChat = fnCreateChatSlice(getChatNameFake());

export const {
  chatReset
} = sliceFakeChat.actions;

export function fnCreateChatSlice(name: string)
{
  return createSlice({
    name: name,
    initialState: fnChatReset({
      chatName: name
    } as IChat),
    reducers: {
      ...sliceChatShared,
      chatReset: (state: IChat) =>
      {
        fnChatReset(state);
      }
    }
  });
}

//region private

export function fnChatReset(state: IChat): IChat
{
  state.loadedVersion = undefined;
  state.jumpVersion = undefined;
  state.displayDateFormat = undefined;

  state.sigChatId = undefined;
  state.sigEntId = undefined;

  state.sigTopOffset = 0;
  state.sigBottomOffset = 0;

  state.topOffset = 0;
  state.bottomOffset = 0;

  state.pickItemIds = undefined;
  state.pickMode = false;
  state.pickCount = 0;

  state.displayItemIds = [];
  state.displayItemMap = {};
  state.displayOffsetMap = {};
  state.displayMsgIdMap = {};
  state.displayRowIdMap = {};
  state.displayPartitionIdMap = {};
  state.displayFirstItemId = undefined;
  state.displayLastItemId = undefined;
  state.displayItemCount = undefined;
  state.displayInitialTopMostItemId = undefined;
  state.displayLastItemIsSender = false;
  state.displayBlinkItemId = undefined;
  state.selectedItemId = undefined;
  state.displayScrollToLast = undefined;

  state.uiAtBottom = false;
  state.uiScrolling = false;
  state.uiImageMode = "original";

  state.appending = "skip";
  state.prepending = "skip";
  state.appendingNewMsg = undefined;
  return state;
}

//endregion
