import {StudioPluginDraft} from "../../../api/meta/base/dto/StudioPluginDraft";
import {StudioPluginMap} from "../../../api/meta/base/dto/StudioPluginMap";
import {PluginId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {MsgPluginId} from "../../../api/studio/studioMain/msg/MsgPluginId";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import ISrvc from "../../../base/ISrvc";
import {formatCaptionDateTime} from "../../../base/plus/DatePlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {random} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListData} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {Srvc} from "../../Srvc";

export default class SrvcStudioPluginVersions extends ISrvc
{
  loadVersions(
    listName: string,
    deployMap?: StudioPluginMap,
    draft?: StudioPluginDraft,
    readOnly?: boolean)
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(deployMap)
    {
      deployMap.keys.forEach((itemId) =>
      {
        const selectedVersion = deployMap.map[itemId];
        uiItemIds.push(itemId);

        uiItemsById[itemId] = {
          type: "p",
          primary: {
            text: selectedVersion.deploy.pluginVersion,
            caption: {
              text: selectedVersion.deprecate
                ? "Obsolete"
                : selectedVersion.lastUpdateTime
                  ? formatCaptionDateTime(new Date(selectedVersion.lastUpdateTime))
                  : undefined,
              color: selectedVersion.deprecate
                ? "warningLight"
                : undefined,
              ignoreSelection: true
            }
          },
          hideMenu: readOnly,
          version: random()
        } as IListItemAPSA;
      });
    }

    if(draft && draft.studioPlugin && draft.studioPlugin.metaId)
    {
      const studioPlugin = draft.studioPlugin;
      const itemId = studioPlugin.metaId;
      uiItemIds.push(itemId);

      uiItemsById[itemId] = {
        type: "p",
        primary: {
          text: studioPlugin.deploy.pluginVersion,
          caption: {
            text: "Draft",
            color: "successLight"
          },
          ignoreSelection: true
        },
        hideMenu: readOnly
      } as IListItemAPSA;
    }

    const listData = {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    } as IListData;

    dispatchList(listName, listRefresh(listData));
  }

  rpcPluginRemove(pluginBundleId: PluginBundleId, pluginId: PluginId)
  {
    const msg: MsgPluginId = {
      pluginId: pluginId
    };

    RpcStudioMain.studioPluginRemove(pluginBundleId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  rpcPluginMarkDeprecate(pluginBundleId: PluginBundleId, pluginId: PluginId)
  {
    const msg: MsgPluginId = {
      pluginId: pluginId
    };

    RpcStudioMain.studioPluginMarkObsolete(pluginBundleId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }
}
