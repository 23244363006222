import {FieldValues} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldSetOfRole} from "../../../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfForwardGroupPermission} from "../../../../api/meta/base/dto/DefnStudioMapOfForwardGroupPermission";
import {DefnStudioMapOfForwardRolePermission} from "../../../../api/meta/base/dto/DefnStudioMapOfForwardRolePermission";
import {DefnStudioMapOfLayoutSpreadsheet} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutSpreadsheet";
import {DefnStudioMapOfPartition} from "../../../../api/meta/base/dto/DefnStudioMapOfPartition";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {StudioEntSpreadsheet} from "../../../../api/meta/base/dto/StudioEntSpreadsheet";
import {StudioEntSpreadsheetMap} from "../../../../api/meta/base/dto/StudioEntSpreadsheetMap";
import {Unique} from "../../../../api/meta/base/StudioSetsFieldType";
import {Queryable, Searchable} from "../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {spreadsheetAlias} from "../../../../base/types/TypesStudio";
import {fieldSelectForm} from "../../../../nucleus/dialog/DialogNewSpreadsheet";
import {fieldGap4} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fieldGap3} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {getStudioDetailsToValue} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fieldGap2} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fieldGap1, fieldPermission, fieldTabDetails, getFieldGap, getStudioDetailsCompMap} from "../../../../nucleus/form/builder/base/TypesFormBuilder";

const fieldSpreadsheetAccessibilityLabel = "accessibility";
const fieldSpreadsheetReadRoles = "readRoleSet";
const fieldSpreadsheetUpdateRoles = "updateRoleSet";
const fieldSpreadsheetRemoveRoles = "removeRoleSet";
const fieldSpreadsheetClearRoleSet = "clearRoleSet";
const fieldSpreadsheetInsertRoles = "insertRoleSet";
const fieldReadRoleVarId = "readRoleVarId";
const fieldSpreadsheetAuditTrailRoles = "Audit trail form roles";

const fieldSpreadsheetDurationLabel = "duration";
const fieldReadAfterDurationVarId = "readAfterDurationVarId";
const fieldInvisibleAfterDurationVarId = "invisibleAfterDurationVarId";
const fieldBypassDurationRoles = "bypassDurationRoleSet";
const fieldSpreadsheetForwardPermissionLabel = "allowForwardPermissions";
export const fieldSpreadsheetForwardRolePermission = "userForwardRolePermissionMap";
export const fieldSpreadsheetForwardGroupPermission = "groupForwardRolePermissionMap";
const fieldSpreadsheetForwardPermissionTab = "forwardPermissionTab";

const fieldProperties = "properties";
const fieldSearchable = "searchableIdSet";
export const fieldQueryable = "queryableIdSet";
export const fieldUnique = "uniqueIdSet";
export const fieldHistory = "historyIdSet";
export const fieldSupportOffline = "supportOffline";

export const fieldSpreadsheetLayout = "layoutSpreadsheetMapSection";
export const fieldBrowserLayoutList = "layoutSpreadsheetMap";

const fieldTabPartition = "partitionMapSection";
export const fieldSpreadsheetPartitionMap = "partitionMap";

export function getSpreadsheetAlias(spreadsheetMap?: StudioEntSpreadsheetMap, returnOnlyNumber?: boolean)
{
  let greatestAliasNumber = 1;
  spreadsheetMap && spreadsheetMap.keys.forEach((itemId) =>
  {
    const spreadsheet = spreadsheetMap.map[itemId];

    const alias = spreadsheet.alias || "";
    const index = alias.indexOf(spreadsheetAlias);
    const aliasNumber = index !== -1
      ? alias.substring(index + 1)
      : "0";
    greatestAliasNumber = Math.max(greatestAliasNumber, parseInt(aliasNumber) + 1);

  });
  if(returnOnlyNumber)
  {
    return greatestAliasNumber;
  }
  return toSpreadsheetAlias(greatestAliasNumber);
}

export function toSpreadsheetAlias(spreadsheetAliasNumber: number)
{
  return spreadsheetAlias + spreadsheetAliasNumber;
}

export function getDefnSpreadsheet(
  selectedFormId?: MetaIdForm,
  spreadsheetId?: MetaIdSpreadsheet,
  labelHelperTextName?: string,
  showLabelHelperTextName?: boolean)
{
  return createDefaultDefnFormStudio({
    ...getDefnTabDetail(selectedFormId, labelHelperTextName, showLabelHelperTextName),

    [fieldSpreadsheetPartitionMap]: {
      type: "studioMapOfPartition",
      name: fieldSpreadsheetPartitionMap,
      metaId: fieldSpreadsheetPartitionMap,
      formId: selectedFormId
    } as DefnStudioMapOfPartition,
    [fieldTabPartition]: {
      type: "section",
      name: fieldTabPartition,
      metaId: fieldTabPartition,
      label: "Partition",
      fieldIdSet: [fieldSpreadsheetPartitionMap]
    } as DefnSection,

    ...getDefnTabBrowserLayout(spreadsheetId),

    ...getDefnTabPermission(selectedFormId),

    [defaultSectionKey]: {
      type: "tab",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      tabIdSet: [fieldTabDetails, fieldTabPartition, fieldSpreadsheetLayout, fieldPermission]
    } as DefnTab
  } as Record<MetaIdField, DefnField>);
}

function getDefnTabDetail(
  selectedFormId?: MetaIdForm,
  labelHelperTextName?: string,
  showLabelHelperTextName?: boolean)
{
  const fieldDefaultProperties = getStudioDetailsCompMap(labelHelperTextName, showLabelHelperTextName);

  return {
    ...fieldDefaultProperties,

    ...getFieldGap(fieldGap1),

    [fieldProperties]: {
      type: "label",
      name: fieldProperties,
      label: "Properties",
      disabled: true
    } as DefnFieldLabel,

    [fieldSearchable]: {
      type: "studioSetOfFieldId",
      name: fieldSearchable,
      label: "Searchable fields",
      metaId: fieldSearchable,
      formId: selectedFormId,
      filterFieldTypeSet: Searchable,
      includeOptionMap: arrayToMapOfOption(["$CreatedBy", "$UpdatedBy"]),
      showCompositeName: true
    } as DefnStudioPickFieldId,

    [fieldQueryable]: {
      type: "studioSetOfFieldId",
      name: fieldQueryable,
      label: "Queryable fields",
      metaId: fieldQueryable,
      formId: selectedFormId,
      filterFieldTypeSet: Queryable,
      showCompositeName: true
    } as DefnStudioPickFieldId,

    [fieldUnique]: {
      type: "studioSetOfFieldId",
      name: fieldUnique,
      label: "Unique fields",
      metaId: fieldUnique,
      formId: selectedFormId,
      filterFieldTypeSet: Unique,
      showCompositeName: true
    } as DefnStudioPickFieldId,

    [fieldHistory]: {
      type: "studioSetOfFieldId",
      name: fieldHistory,
      label: "History fields",
      metaId: fieldHistory,
      formId: selectedFormId,
      showCompositeName: true
    } as DefnStudioPickFieldId,

    ...getFieldGap(fieldGap2),

    [fieldSupportOffline]: {
      type: "bool",
      metaId: fieldSupportOffline,
      name: fieldSupportOffline,
      label: "Support offline",
      helperText: "It is only applicable to mobile app",
      showAsCheckbox: true
    } as DefnFieldSwitch,

    [fieldTabDetails]: {
      type: "section",
      name: fieldTabDetails,
      metaId: fieldTabDetails,
      fieldIdSet: [
        ...Object.keys(fieldDefaultProperties),
        fieldGap1,
        fieldProperties,
        fieldSearchable,
        fieldQueryable,
        fieldUnique,
        fieldHistory,
        fieldGap2,
        fieldSupportOffline
      ]
    } as DefnSection

  };
}

function getDefnTabBrowserLayout(spreadsheetId?: MetaIdSpreadsheet)
{
  return {
    [fieldBrowserLayoutList]: {
      type: "studioMapOfLayoutSpreadsheet",
      name: fieldBrowserLayoutList,
      metaId: fieldBrowserLayoutList,
      spreadsheetId: spreadsheetId
    } as DefnStudioMapOfLayoutSpreadsheet,
    [fieldSpreadsheetLayout]: {
      type: "section",
      label: "Layout",
      name: fieldSpreadsheetLayout,
      metaId: fieldSpreadsheetLayout,
      fieldIdSet: [fieldBrowserLayoutList]
    } as DefnSection
  };
}

function getDefnTabPermission(metaIdForm?: MetaIdForm)
{
  return {
    [fieldSpreadsheetAccessibilityLabel]: {
      type: "label",
      name: fieldSpreadsheetAccessibilityLabel,
      metaId: fieldSpreadsheetAccessibilityLabel,
      label: "Accessibility",
      disabled: true
    } as DefnFieldLabel,

    [fieldSpreadsheetInsertRoles]: {
      type: "setOfRole",
      name: fieldSpreadsheetInsertRoles,
      label: "Insert roles",
      metaId: fieldSpreadsheetInsertRoles,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    [fieldSpreadsheetUpdateRoles]: {
      type: "setOfRole",
      name: fieldSpreadsheetUpdateRoles,
      label: "Update roles",
      metaId: fieldSpreadsheetUpdateRoles,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    ...getFieldGap(fieldGap1),

    [fieldSpreadsheetReadRoles]: {
      type: "setOfRole",
      name: fieldSpreadsheetReadRoles,
      label: "Read roles",
      metaId: fieldSpreadsheetReadRoles,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,
    [fieldReadRoleVarId]: {
      type: "pickVarId",
      name: fieldReadRoleVarId,
      metaId: fieldReadRoleVarId,
      label: "Read roles variable",
      formId: metaIdForm,
      varKind: "setOfUser",
      showAsEdit: true
    } as DefnStudioPickVarId,

    ...getFieldGap(fieldGap2),

    [fieldSpreadsheetRemoveRoles]: {
      type: "setOfRole",
      name: fieldSpreadsheetRemoveRoles,
      label: "Remove roles",
      metaId: fieldSpreadsheetRemoveRoles,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    [fieldSpreadsheetClearRoleSet]: {
      type: "setOfRole",
      name: fieldSpreadsheetClearRoleSet,
      label: "Clear roles",
      metaId: fieldSpreadsheetClearRoleSet,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    [fieldSpreadsheetAuditTrailRoles]: {
      type: "setOfRole",
      name: fieldSpreadsheetAuditTrailRoles,
      label: "Audit trail form roles",
      metaId: fieldSpreadsheetAuditTrailRoles,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    ...getFieldGap(fieldGap3, "thick"),

    [fieldSpreadsheetDurationLabel]: {
      type: "label",
      name: fieldSpreadsheetDurationLabel,
      metaId: fieldSpreadsheetDurationLabel,
      label: "Duration",
      disabled: true
    } as DefnFieldLabel,

    [fieldReadAfterDurationVarId]: {
      type: "pickVarId",
      name: fieldReadAfterDurationVarId,
      metaId: fieldReadAfterDurationVarId,
      label: "Read after duration",
      varKind: "duration",
      showAsEdit: true
    } as DefnStudioPickVarId,

    [fieldInvisibleAfterDurationVarId]: {
      type: "pickVarId",
      name: fieldInvisibleAfterDurationVarId,
      metaId: fieldInvisibleAfterDurationVarId,
      label: "Invisible after duration",
      varKind: "duration",
      showAsEdit: true
    } as DefnStudioPickVarId,

    [fieldBypassDurationRoles]: {
      type: "setOfRole",
      name: fieldBypassDurationRoles,
      label: "Bypass duration roles",
      metaId: fieldBypassDurationRoles,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    ...getFieldGap(fieldGap4, "thick"),

    [fieldSpreadsheetForwardPermissionLabel]: {
      type: "label",
      name: fieldSpreadsheetForwardPermissionLabel,
      metaId: fieldSpreadsheetForwardPermissionLabel,
      label: "Forward candidates",
      disabled: true
    } as DefnFieldLabel,

    [fieldSpreadsheetForwardRolePermission]: {
      type: "studioMapOfForwardRolePermission",
      name: fieldSpreadsheetForwardRolePermission,
      metaId: fieldSpreadsheetForwardRolePermission,
      label: "Roles"
    } as DefnStudioMapOfForwardRolePermission,

    [fieldSpreadsheetForwardGroupPermission]: {
      type: "studioMapOfForwardGroupPermission",
      name: fieldSpreadsheetForwardGroupPermission,
      metaId: fieldSpreadsheetForwardGroupPermission,
      label: "Groups"
    } as DefnStudioMapOfForwardGroupPermission,

    [fieldSpreadsheetForwardPermissionTab]: {
      type: "tab",
      name: fieldSpreadsheetForwardPermissionTab,
      metaId: fieldSpreadsheetForwardPermissionTab,
      tabIdSet: [fieldSpreadsheetForwardRolePermission, fieldSpreadsheetForwardGroupPermission]
    } as DefnTab,

    [fieldPermission]: {
      type: "section",
      name: fieldPermission,
      metaId: fieldPermission,
      fieldIdSet: [
        fieldSpreadsheetAccessibilityLabel,
        fieldSpreadsheetInsertRoles,
        fieldSpreadsheetUpdateRoles,
        fieldGap1,
        fieldSpreadsheetReadRoles,
        fieldReadRoleVarId,
        fieldGap2,
        fieldSpreadsheetRemoveRoles,
        fieldSpreadsheetClearRoleSet,
        fieldSpreadsheetAuditTrailRoles,
        fieldGap3,
        fieldSpreadsheetDurationLabel,
        fieldReadAfterDurationVarId,
        fieldInvisibleAfterDurationVarId,
        fieldBypassDurationRoles,
        fieldGap4,
        fieldSpreadsheetForwardPermissionLabel,
        fieldSpreadsheetForwardPermissionTab
      ]
    } as DefnSection
  };
}

export const setSpreadsheetValues = (spreadsheet: StudioEntSpreadsheet) =>
{
  return {
    ...getStudioDetailsToValue(spreadsheet.details),
    [fieldSearchable]: fnRawValueToFieldValue("studioSetOfFieldId", spreadsheet.searchableIdSet),
    [fieldQueryable]: fnRawValueToFieldValue("studioSetOfFieldId", spreadsheet.queryableIdSet),
    [fieldUnique]: fnRawValueToFieldValue("studioSetOfFieldId", spreadsheet.uniqueIdSet),
    [fieldHistory]: fnRawValueToFieldValue("studioSetOfFieldId", spreadsheet.historyIdSet),
    [fieldSupportOffline]: fnRawValueToFieldValue("bool", spreadsheet.supportOffline),
    [fieldSelectForm]: fnRawValueToFieldValue("pickFormId", spreadsheet.formId),
    [fieldSpreadsheetReadRoles]: fnRawValueToFieldValue("setOfRole", spreadsheet.readRoleSet),
    [fieldSpreadsheetUpdateRoles]: fnRawValueToFieldValue("setOfRole", spreadsheet.updateRoleSet),
    [fieldSpreadsheetRemoveRoles]: fnRawValueToFieldValue("setOfRole", spreadsheet.removeRoleSet),
    [fieldSpreadsheetClearRoleSet]: fnRawValueToFieldValue("setOfRole", spreadsheet.clearRoleSet),
    [fieldReadRoleVarId]: fnRawValueToFieldValue("pickVarId", spreadsheet.readRoleVarId),
    [fieldSpreadsheetInsertRoles]: fnRawValueToFieldValue("setOfRole", spreadsheet.insertRoleSet),
    [fieldSpreadsheetAuditTrailRoles]: fnRawValueToFieldValue("setOfRole", spreadsheet.auditTrailFormRoleSet),
    [fieldBrowserLayoutList]: fnRawValueToFieldValue("studioMapOfLayoutSpreadsheet",
      spreadsheet.layoutSpreadsheetMap
    ),
    [fieldSpreadsheetForwardRolePermission]: fnRawValueToFieldValue("studioMapOfForwardRolePermission",
      spreadsheet.userForwardRolePermissionMap
    ),
    [fieldSpreadsheetForwardGroupPermission]: fnRawValueToFieldValue("studioMapOfForwardGroupPermission",
      spreadsheet.groupForwardRolePermissionMap
    ),
    [fieldSpreadsheetPartitionMap]: fnRawValueToFieldValue("studioMapOfPartition", spreadsheet.partitionMap),
    [fieldReadAfterDurationVarId]: fnRawValueToFieldValue("pickVarId", spreadsheet.readAfterDurationVarId),
    [fieldInvisibleAfterDurationVarId]: fnRawValueToFieldValue("pickVarId", spreadsheet.invisibleAfterDurationVarId),
    [fieldBypassDurationRoles]: fnRawValueToFieldValue("setOfRole", spreadsheet.bypassDurationRoleSet)
  };
};

export const getSpreadSheetValue = (values: FieldValues) =>
{
  return {
    searchableIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldSearchable]),
    queryableIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldQueryable]),
    uniqueIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldUnique]),
    historyIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldHistory]),
    supportOffline: fnFieldValueToRawValue("bool", values[fieldSupportOffline]),
    readRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldSpreadsheetReadRoles]),
    updateRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldSpreadsheetUpdateRoles]),
    removeRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldSpreadsheetRemoveRoles]),
    clearRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldSpreadsheetClearRoleSet]),
    insertRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldSpreadsheetInsertRoles]),
    readRoleVarId: fnFieldValueToRawValue("pickVarId", values[fieldReadRoleVarId]),
    auditTrailFormRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldSpreadsheetAuditTrailRoles]),
    layoutSpreadsheetMap: fnFieldValueToRawValue("studioMapOfLayoutSpreadsheet", values[fieldBrowserLayoutList]),
    userForwardRolePermissionMap: fnFieldValueToRawValue("studioMapOfForwardRolePermission",
      values[fieldSpreadsheetForwardRolePermission]
    ),
    groupForwardRolePermissionMap: fnFieldValueToRawValue("studioMapOfForwardGroupPermission",
      values[fieldSpreadsheetForwardGroupPermission]
    ),
    partitionMap: fnFieldValueToRawValue("studioMapOfPartition", values[fieldSpreadsheetPartitionMap]),
    readAfterDurationVarId: fnFieldValueToRawValue("pickVarId", values[fieldReadAfterDurationVarId]),
    invisibleAfterDurationVarId: fnFieldValueToRawValue("pickVarId", values[fieldInvisibleAfterDurationVarId]),
    bypassDurationRoleSet: fnFieldValueToRawValue("setOfRole", values[fieldBypassDurationRoles])
  } as StudioEntSpreadsheet;
};

