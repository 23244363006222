// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {customAlphabet} from "nanoid";

const nanoIdAlphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const nanoId25 = customAlphabet(nanoIdAlphabet, 25);
const nanoId32 = customAlphabet(nanoIdAlphabet, 32);

export function newGuid(): string
{
  return nanoId25();
}

export function newGuidBig(): string
{
  return nanoId32();
}