import {WsocDrawer} from "../../../api/home/drawer/WsocDrawer";
import {createMsgVersion} from "../../../api/nucleus/base/Protocol";
import ISrvc from "../../../base/ISrvc";
import {store} from "../../../Store";
import {setBadgeMap} from "./SliceCacheBadge";

export default class SrvcCacheBadge extends ISrvc
{
  wsocBadgeMapGet()
  {
    const msg = createMsgVersion(store.getState().cache.app.badge.badgeMapVersion);
    WsocDrawer.badgeMapGet(msg, envSig =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setBadgeMap(sig));
      }
    });
  }
}
