import {FormHelperText} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {useState} from "react";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnLayoutGrid} from "../../../../api/meta/base/dto/DefnLayoutGrid";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {calcBorderFromEnum} from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import helperTextData from "../../../atom/assets/PlaceholderTextHome.json";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import {useFormCtx} from "../base/CtxForm";
import FieldBase from "../raw/FieldBase";
import FieldRawFieldGridCalendar from "../raw/FieldRawFieldGridCalendar";
import FieldRawFieldGridChart from "../raw/FieldRawFieldGridChart";
import FieldRawFieldGridKanban from "../raw/FieldRawFieldGridKanban";
import RawFieldGridList from "../raw/FieldRawFieldGridList";
import FieldRawFieldGridMap from "../raw/FieldRawFieldGridMap";
import FieldRawFieldGridTable from "../raw/FieldRawFieldGridTable";

export interface IFieldGridProps
{
  defnGrid: DefnGrid,
  defnForm: DefnFormUi,
  defaultLayoutId?: string,
  fieldValue?: FieldValueGrid,
  onChange: (value: FieldValueGrid | null) => void,
}

export interface IFieldGridRawProps extends IFieldGridProps
{
  layout: DefnLayoutGrid;
}

export default function FieldGrid(props: {
  defnForm: DefnFormUi,
  defn: DefnGrid
})
{
  const formCtx = useFormCtx();
  const theme = useTheme();

  const defnGrid = props.defn;
  const defnForm = props.defnForm;

  const layoutGridMap = defnGrid.layoutGridMap;
  const getOnClick = formCtx.getOnClick();
  const fieldId = getFieldKey(defnGrid);
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const gapStd = theme.common.gapStd;
  const labelKey = "label-" + fieldId;
  const visibilityOption = formCtx.getFieldVisibilityOption(defnGrid.metaId);
  const isHidden = visibilityOption?.hidden
    || visibilityOption?.invisible
    || defnGrid?.hidden
    || defnGrid?.invisible;

  const [defaultLayoutId, setDefaultLayoutId] = useState(layoutGridMap?.asideDefaultLayoutId);

  return (
    <Controller
      name={defnGrid.metaId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const fieldValue = field.value;
        const onChange: IFieldGridProps["onChange"] = field.onChange;

        const {
          error
        } = fieldState;

        const isError = Boolean(error);

        const onClickGridActions = (menuAnchor: Element, menuProps?: IMenuProps) =>
          getOnClick && getOnClick(fieldId, "fieldGridAction", fieldValue, menuAnchor, menuProps);

        return (
          <>
            {(defnGrid.propertyEditorLabel && !isHidden) &&
              <FieldBase
                fieldId={labelKey}
                pr={FORM_FIELD_MEDIUM_PADDING}
                pl={FORM_FIELD_MEDIUM_PADDING}
              >
                <RawHighlighter
                  key={labelKey}
                  variant={"body2"}
                  value={defnGrid.propertyEditorLabel}
                  color={theme.common.color("textPrimary")}
                  bold={true}
                />
              </FieldBase>
            }
            <Box
              width={"100%"}
              flexGrow={1}
              minHeight={px(theme.common.reportMinGridHeight)}
              mt={!isReport ? `-${px(gapStd)}` : undefined}
              sx={{
                ...isReport && {
                  maxHeight: "100%"
                },
                display: "flex",
                position: "relative",
                flexDirection: "column",
                flexShrink: 0,
                overflowX: "hidden",
                overflowY: "hidden",
                alignItems: "center",
                justifyContent: "center",
                "& > :nth-of-type(2)": {
                  height: "100%",
                  "& > :first-of-type": {
                    height: "100%",
                    ...calcBorderFromEnum(layoutGridMap?.showBorderSet)
                  }
                },
                ...isHidden && {
                  display: "none"
                }
              }}
            >
              <GridMenu
                defnGrid={defnGrid}
                defaultLayoutId={defaultLayoutId}
                cbOnClick={onClickGridActions}
                onChangeLayout={setDefaultLayoutId}
              />
              <FieldBase
                fieldId={fieldId}
                flexGrow={1}
                pl={defnGrid.pl !== undefined ? px(defnGrid.pl) : 0}
                pr={defnGrid.pr !== undefined ? px(defnGrid.pr) : 0}
                pt={defnGrid.pt !== undefined ? px(defnGrid.pt) : 0}
                pb={defnGrid.pb !== undefined ? px(defnGrid.pb) : 0}
              >
                <RealFieldGrid
                  defnGrid={defnGrid}
                  defnForm={defnForm}
                  fieldValue={fieldValue}
                  onChange={onChange}
                  defaultLayoutId={defaultLayoutId}
                />
              </FieldBase>
              {(isError && error?.message) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                  error={isError}
                >
                  {error?.message}
                </FormHelperText>
              }
            </Box>
          </>
        );
      }}
    />
  );
}

function RealFieldGrid(props: IFieldGridProps)
{
  const defnGrid = props.defnGrid;
  const defaultLayoutId = props.defaultLayoutId;

  if(!defaultLayoutId)
  {
    return <LayoutFlexCol
      width={"100%"}
      height={"100%"}
    >
      <RawNothingHere helperTextData={helperTextData.noDefaultLayoutFound} />
    </LayoutFlexCol>;
  }

  const layout = defnGrid.layoutGridMap?.map[defaultLayoutId];

  switch(layout?.kind)
  {
    case "list":
    case "card":
      return <RawFieldGridList {...props} layout={layout} />;
    case "table":
      return <FieldRawFieldGridTable {...props} layout={layout} />;
    case "map":
      return <FieldRawFieldGridMap {...props} layout={layout} />;
    case "xyChartBarGraph":
    case "xyChartLineChart":
    case "xyChartPieChart":
    case "xyChartDoughnut":
    case "xyChartScatterPlot":
      return <FieldRawFieldGridChart {...props} layout={layout} />;
    case "kanban":
      return <FieldRawFieldGridKanban {...props} layout={layout} />;
    case "calendar":
      return <FieldRawFieldGridCalendar {...props} layout={layout} />;
    default:
      return null;
  }
}

function GridMenu(props: {
  defnGrid: DefnGrid,
  defaultLayoutId?: string,
  onChangeLayout: (layoutId: string) => void,
  cbOnClick?: (menuAnchor: Element, menuProps?: IMenuProps) => void
})
{
  const formCtx = useFormCtx();
  const onChangeLayout = props.onChangeLayout;
  const defnGrid = props.defnGrid;
  const layoutId = props.defaultLayoutId;
  const cbOnClick = props.cbOnClick;

  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";

  const isActionPermission = (isReport && defnGrid.actionPermissionMap) &&
    defnGrid.actionPermissionMap?.keys.length !== 0;
  const layoutMap = defnGrid.layoutGridMap?.map;
  const layout = (layoutId && layoutMap) ? layoutMap[layoutId] : undefined;
  const allowToSwitchLayoutIdSet = layout?.allowToSwitchLayoutIdSet;

  const iconStrip = useMemo<EnumIconStrip[] | undefined>(() =>
  {
    if(isActionPermission || allowToSwitchLayoutIdSet)
    {
      return ["moreHorizontal"] as EnumIconStrip[];
    }
  }, [isActionPermission, allowToSwitchLayoutIdSet]);

  const onClick = (id: EnumIconStrip, menuAnchor: Element) =>
  {
    if(id === "moreHorizontal")
    {
      let menuProps = undefined as IMenuProps | undefined;
      if(allowToSwitchLayoutIdSet)
      {
        menuProps = {} as IMenuProps;
        allowToSwitchLayoutIdSet.forEach(layoutId =>
        {
          const layout = layoutMap ? layoutMap[layoutId] : undefined;
          if(layout)
          {
            const menuName = layout.label || toLabel(layout.name);

            (menuProps as IMenuProps)[menuName] = {
              onClick: () =>
              {
                onChangeLayout(layout.metaId);
              },
              disabled: false
            };
          }
        });
      }
      cbOnClick && cbOnClick(menuAnchor, menuProps);
    }
  };

  if(!isReport)
  {
    return <Box />;
  }

  return iconStrip?.length
    ? <Box
      width={"100%"}
      justifyContent={"end"}
      overflow={"visible"}
      pr={px(gapQuarter)}
      sx={{
        "@media print": {
          visibility: "hidden"
        }
      }}
    >
      <RawIconStrip
        iconStrip={iconStrip}
        onClick={onClick}
      />
    </Box>
    : <Box />;

}

