// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgAsideSearch} from './msg/MsgAsideSearch';
import {MsgCallerChatNotificationSettingPut} from './msg/MsgCallerChatNotificationSettingPut';
import {MsgChatId} from '../base/msg/MsgChatId';
import {MsgEntUserIdNoVersion} from '../../core/base/msg/MsgEntUserIdNoVersion';
import {MsgGroupInfoGet} from './msg/MsgGroupInfoGet';
import {MsgGroupInviteLink} from './msg/MsgGroupInviteLink';
import {MsgGroupMembersAdd} from './msg/MsgGroupMembersAdd';
import {MsgGroupMembersRemove} from './msg/MsgGroupMembersRemove';
import {MsgGroupPatch} from './msg/MsgGroupPatch';
import {MsgOffset} from '../main/msg/MsgOffset';
import {ServiceName} from '../../meta/base/Types';
import {SigAsideSearch} from '../drawer/sig/SigAsideSearch';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigGroupIdList} from './sig/SigGroupIdList';
import {SigGroupInfo} from './sig/SigGroupInfo';
import {SigMediaList} from '../main/sig/SigMediaList';
import {SigMessageReceiptMap} from './sig/SigMessageReceiptMap';
import {SigUrl} from '../../core/deeplink/sig/SigUrl';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcAside
{
  static readonly SN: ServiceName = "aside";

  static asideSearch(entId: EntId, msg: MsgAsideSearch, sigAcceptor: ISigAcceptor<SigAsideSearch>): void
  {
    rpcCall<SigAsideSearch>(entId, RpcAside.SN, "asideSearch")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static callerChatNotificationSettingPut(entId: EntId, msg: MsgCallerChatNotificationSettingPut, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcAside.SN, "callerChatNotificationSettingPut")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static groupCommonListGet(msg: MsgEntUserIdNoVersion, sigAcceptor: ISigAcceptor<SigGroupIdList>): void
  {
    rpcCall<SigGroupIdList>(ENT_ID_GLOBAL, RpcAside.SN, "groupCommonListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static groupInfoGet(entId: EntId, msg: MsgGroupInfoGet, sigAcceptor: ISigAcceptor<SigGroupInfo>): void
  {
    rpcCall<SigGroupInfo>(entId, RpcAside.SN, "groupInfoGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static groupInviteLinkGet(msg: MsgGroupInviteLink, sigAcceptor: ISigAcceptor<SigUrl>): void
  {
    rpcCall<SigUrl>(ENT_ID_GLOBAL, RpcAside.SN, "groupInviteLinkGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static groupMembersAdd(msg: MsgGroupMembersAdd, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcAside.SN, "groupMembersAdd")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static groupMembersRemove(msg: MsgGroupMembersRemove, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcAside.SN, "groupMembersRemove")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static groupPatch(msg: MsgGroupPatch, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcAside.SN, "groupPatch")
      .sendBearerToken()
      .patch(msg, sigAcceptor);
  }

  static mediaListGet(entId: EntId, msg: MsgChatId, sigAcceptor: ISigAcceptor<SigMediaList>): void
  {
    rpcCall<SigMediaList>(entId, RpcAside.SN, "mediaListGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static messageReceiptGet(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigMessageReceiptMap>): void
  {
    rpcCall<SigMessageReceiptMap>(entId, RpcAside.SN, "messageReceiptGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }
}