import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {Tooltip} from "@mui/material";
import {isArray} from "lodash";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {ILineBadge} from "../../../base/types/TypesGlobal";
import {IPopoverMsg} from "../raw/RawPopover";
import RawPopoverText from "../raw/RawPopoverText";

const popoverMaxWidth = 600;
const popoverMaxHeight = 900;

export default function LineBadge(props: {
  badge?: ILineBadge,
  size?: number,
  maxVar?: number,
  ml?: number,
  mr?: number,
  mb?: number,
  errorMessage?: string | string[] | IPopoverMsg[] | undefined,
  onClickBadge?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
})
{
  const theme = useTheme();
  const badge = props.badge;

  if(!badge)
  {
    return null;
  }

  const value = badge.value;
  if(value === undefined || value === null)
  {
    return null;
  }

  const bgColor = badge.color ?? "successLight";

  const max = props.maxVar;

  let size = props.size;
  if(!size)
  {
    size = theme.common.heightLine;
  }
  let displayedErrorMessage: string | string[] | IPopoverMsg[] = [""];
  if(isArray(props.errorMessage))
  {
    displayedErrorMessage = props.errorMessage.slice(0, value);
  }

  return (
    <Tooltip
      title={
        <Box
          sx={{
            bgcolor: theme.common.bgcolorError,
            maxWidth: px(popoverMaxWidth),
            maxHeight: px(popoverMaxHeight),
            borderRadius: px(theme.common.gapQuarter),
            boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
            padding: "0px 1px",
            letterSpacing: 0
          }}
        >
          {props.errorMessage &&
            <RawPopoverText msg={isArray(props.errorMessage) ? displayedErrorMessage : props.errorMessage} />}
        </Box>

      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
            color: "inherit",
            boxShadow: "none"
          }
        }
      }}
    >
      <Box
        onMouseEnter={e => props.onClickBadge && props.onClickBadge(e)}
        sx={{
          height: px(size),
          width: "fit-content",
          color: theme.common.bgcolorContent,
          bgcolor: theme.common.color(bgColor),
          borderRadius: "10px",
          padding: "0px 4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          minWidth: px(size),
          fontWeight: 500,
          fontSize: "0.75rem",
          ml: px(props.ml),
          mr: px(props.mr),
          mb: px(props.mb)
        }}
      >
        {max && max <= value ? (max - 1 + "+") : value}
      </Box>
    </Tooltip>
  );
}
