import React from "react";
import {StudioDtoLayoutGrid} from "../../api/meta/base/dto/StudioDtoLayoutGrid";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {EnumDefnLayoutGridKind, MetaIdForm} from "../../api/meta/base/Types";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import DialogNewLayoutGridBase from "./base/impl/DialogNewLayoutGridBase";

export default function DialogNewLayoutGridCard(props: {
  metaIdForm: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  isFormReadOnly?: boolean,
  formStore: FormStore,
  layoutType?: EnumDefnLayoutGridKind,
  values?: StudioDtoLayoutGrid,
  onClickOk: (values: StudioDtoLayoutGrid) => void,
  validationError?: IFormFieldError[],
  isOverride?: boolean,
  overrideCompositeIdSet?: MetaIdComposite[],
  onClose?: () => void,
})
{
  return <DialogNewLayoutGridBase {...props} layoutType={"card"} />;
}
