import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import {EnumDefnThemeColor} from "../../../api/meta/base/Types";
import {px} from "../../../base/plus/StringPlus";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {usePageCtx} from "../../ctx/CtxPage";

export function BoxInfoSpot(props: {
  infoSpot: EnumDefnThemeColor,
  onClick: CbMenuAnchor,
})
{
  const {
    infoSpot,
    onClick
  } = props;

  const theme = useTheme();
  const gap = px(theme.common.gapStd);
  const pageCtx = usePageCtx();

  const color = theme.common.color(infoSpot);

  return (
    <Box
      onMouseEnter={(e) =>
      {
        const currentTarget = e.currentTarget;
        onClick && onClick(currentTarget);
      }}
      sx={{
        width: gap,
        borderTop: (gap + " solid " + color),
        borderLeft: (gap + " solid transparent")
      }}
    />
  );
}
