import {toNumber} from "lodash";
import {isArray} from "lodash";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioSetOfNumber} from "../../../../api/meta/base/dto/DefnStudioSetOfNumber";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {nextGenericId} from "../../../../base/plus/SysPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import {DialogImportOrExportXML} from "../../../dialog/DialogImportOrExportXML";
import {DialogVariableNumberSetNumber} from "../../../dialog/DialogVariableNumberSetNumber";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

type TypeFieldValue = number

export default function FieldStudioSetOfNumber(props: {
  defn: DefnStudioSetOfNumber,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnFieldBuilderSetOfNumber = props.defn;
  const fieldId = getFieldKey(defnFieldBuilderSetOfNumber);

  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const label = defnFieldBuilderSetOfNumber.name ?? defnFieldBuilderSetOfNumber.label;
  const readOnly = formCtx.isReadonly();

  return (
    <Controller
      name={defnFieldBuilderSetOfNumber.metaId}
      defaultValue={[]}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {

        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfNumber
                  fieldId={fieldId}
                  fieldValue={field.value}
                  onChange={onChange}
                  isFormReadOnly={readOnly}
                />
              }
            />
          );
        }

        return (
          <RawSetOfNumber
            fieldId={fieldId}
            fieldValue={field.value}
            onChange={onChange}
            isFormReadOnly={readOnly}
          />
        );
      }
      }
    />
  );
}

function RawSetOfNumber(props: {
  fieldId: MetaIdField,
  fieldValue?: TypeFieldValue[],
  isFormReadOnly?: boolean,
  onChange: (value: TypeFieldValue[]) => void
})
{
  const {
    fieldId,
    isFormReadOnly
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<TypeFieldValue>;

  const fieldValue = useMemo(() => isArray(props.fieldValue)
    ? props.fieldValue
    : [], [props.fieldValue]);

  const doLoad = (selectList: SelectList, fieldValue: TypeFieldValue[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = nextGenericId();
        uiItemIds.push(listId);
        uiItemsById[listId] = {
          type: "p",
          primary: {
            text: value.toString()
          },
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemAPSA;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    switch(iconName)
    {
      case "add":
        pageCtx.showDialog(
          <DialogVariableNumberSetNumber
            isFormReadOnly={isFormReadOnly}
            onClickOk={(newValue) => cbRefList.addItem(newValue)}
          />);
        break;
      case "importXml":
        pageCtx.showDialog(<DialogImportOrExportXML
          onClickOk={(xml) =>
          {
            const convertedDto = xmlToDto(xml);
            const updatedDto = convertedDto.filter(item => !isNaN(item));

            if(!convertedDto.length || !updatedDto.length)
            {
              pageCtx.showErrorToast("Invalid XML");
            }

            if(updatedDto.length < convertedDto.length)
            {
              pageCtx.showErrorToast("Some values skipped");
            }

            cbRefList.setItems(updatedDto);
          }}
        />);
        break;
      case "exportXml":
        pageCtx.showDialog(
          <DialogImportOrExportXML
            xml={dtoToXml(fieldValue)}
          />);
        break;
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as TypeFieldValue
        : undefined;

      pageCtx.showDialog(
        <DialogVariableNumberSetNumber
          values={oldValue}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) => cbRefList.updateItem(newValue, key)}
          validationError={validationError}
        />
      );
    }
  };

  return (
    <FieldRawList
      fieldId={fieldId}
      cbRef={cbRefList}
      fieldValue={fieldValue}
      onChange={props.onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <LayoutFlexRow
          width={"100%"}
          justifyContent={"flex-end"}
        >
          <RawButtonStrip
            iconButtonList={["importXml", "exportXml", "add"]}
            labelMap={{
              importXml: "IMPORT XML",
              exportXml: "EXPORT XML",
              add: "ADD..."
            } as Record<EnumIconButton, string>}
            onClick={onClickIconBtn}
            iconButtonDisable={isFormReadOnly ? ["importXml", "exportXml", "add"] : []}
          />
        </LayoutFlexRow>
      }
      doLoad={doLoad}
    />
  );
}

function dtoToXml(dto: number[])
{
  let xml = "";
  const nodeName = "node";

  dto.forEach((node) =>
  {
    xml += `<${nodeName}>${node}</${nodeName}>\n`;
  });
  return xml;
}

function xmlToDto(xml: string): number[]
{
  const dto = [] as number[];

  const parser = new DOMParser();
  const rootXml = `<node key="value">${xml}</node>`.trim(); //  DOMParser requires a root element
  const xmlDoc = parser.parseFromString(rootXml, "application/xhtml+xml");

  const root = xmlDoc.getElementsByTagName("node")[0];
  const nodes = root.children;
  if(nodes && nodes.length > 0)
  {
    for(let i = 0; i < nodes.length; i++)
    {
      const element = nodes[i];

      if(element.nodeName === "node")
      {
        if(element.textContent)
        {
          dto.push(toNumber(element.textContent));
        }
      }
    }
  }
  return dto;
}
