import _ from "lodash";
import React from "react";
import {SelectList} from "../../base/plus/ListPlus";
import {IListBinderAll} from "../../base/types/list/TypesList";
import {IListItem} from "../../base/types/list/TypesList";
import {TypeListItemId} from "../../base/types/list/TypesList";
import {EnumBubbleMenu} from "../../base/types/TypesBubble";
import {useAppSelector} from "../app/AppHooks";
import helperTextJsonData from "../atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../atom/raw/RawNothingHere";
import GridVirtuoso from "./impl/GridVirtuoso";
import {ListVirtuoso} from "./impl/ListVirtuoso";

export type TypeEnumCaptionButton =
  | "apsaPrimaryCaptionButtonAdd"
  | "apsaPrimaryCaptionButtonEdit"

export type ListClickVariant =
  | "apsaPrimaryCaption"
  | "apsaSecondaryCaption"
  | "apsaSecondaryCaptionCounter"
  | "apsaSecondaryMiddleCounter"
  | "apsaPrimaryCounter"
  | "apsaPrimaryCaptionCounter"
  | "apsaPrimaryMiddleCounter"
  | "listItem"
  | "spotMenu"
  | "spotInfo"
  | "gridHyperlink"
  | EnumBubbleMenu
  | TypeEnumCaptionButton

export type TypeListCounterPositionVariant =
  | "text"
  | "caption"
  | "middle"

export type CbOnClickListItem = (
  menuAnchor: Element,
  itemId: TypeListItemId,
  item: IListItem,
  variant: ListClickVariant,
  pickValue?: boolean,
  selected?: boolean,
  targetId?: string
) => void

export type CbOnDragListItem = (
  itemId: TypeListItemId,
  item: IListItem,
  dragStartIndex: number,
  dragEndIndex: number
) => void

export function List<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  selectList: SelectList,
  onClickListItem?: CbOnClickListItem,
  onDragListItem?: CbOnDragListItem,
  onItemSubscribe?: (itemId: TypeListItemId) => void,
  onItemUnsubscribe?: (itemId: TypeListItemId) => void,
  onBottomReached?: () => void,
  emptyComp?: React.ReactNode | string,
  emptySearch?: React.ReactNode | string,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
})
{
  const displayItemCount = useAppSelector(state => props.selectList(state).displayItemCount);
  const loaded = useAppSelector(state => props.selectList(state).loaded);
  const listLayoutType = useAppSelector(state => props.selectList(state).listLayoutType);

  if(displayItemCount === 0 && loaded)
  {
    return (
      <EmptyListComp
        selectList={props.selectList}
        emptyComp={props.emptyComp}
        emptySearch={props.emptySearch}
      />
    );
  }
  else
  {
    return listLayoutType === "card"
      ? <GridVirtuoso {...props} />
      : <ListVirtuoso {...props} />;

  }
}

function EmptyListComp(props: {
  selectList: SelectList,
  emptyComp?: React.ReactNode | string,
  emptySearch?: React.ReactNode | string
})
{
  const searchWords = useAppSelector(state => props.selectList(state).searchWords);
  const itemsById = useAppSelector(state => props.selectList(state).itemsById);
  const groupsById = useAppSelector(state => props.selectList(state).groupsById);

  if(searchWords && searchWords.length > 0)
  {
    if(typeof props.emptySearch === "string")
    {
      return <RawNothingHere
        helperTextData={{
          title: props.emptySearch
        }}
      />;
    }
    else
    {
      return <>{props.emptySearch}</>;
    }
  }
  else if(_.isEmpty(itemsById) && _.isEmpty(groupsById))
  {
    if(typeof props.emptyComp === "string")
    {
      return <RawNothingHere
        helperTextData={{
          title: props.emptyComp
        }}
      />;
    }
    else if(props.emptyComp)
    {
      return <>{props.emptyComp}</>;
    }
    else
    {
      return <RawNothingHere
        helperTextData={helperTextJsonData.nothingToShow}
      />;
    }
  }

  return null;
}
