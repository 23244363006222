import {Box} from "@mui/material";
import React from "react";
import {DateHeaderProps} from "react-big-calendar";
import {minCalendarShellHeaderHeight} from "../../../../base/plus/CalendarPlus";
import {calendarShellTodayDateTextColor} from "../../../../base/plus/CalendarPlus";
import {calendarShellTodayDateColor} from "../../../../base/plus/CalendarPlus";
import {checkIsDateToday} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import RawHighlighter from "../../../atom/raw/RawHighlighter";

export default function CalendarCellDate(props: {
  dateHeaderProps: DateHeaderProps
})
{
  const date = props.dateHeaderProps.date;
  const todayDate = checkIsDateToday(date);
  const defaultTextColor = theme.common.color("textPrimary");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        minHeight: px(minCalendarShellHeaderHeight),
        paddingTop: px(gapQuarter),
        justifyContent: "end",
        alignItems: "center",
        marginBottom: px(gapQuarter),
        marginRight: px(gapQuarter)
      }}
    >
      <Box
        sx={{
          width: px(gapStd * 2),
          height: px(gapStd * 2),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: todayDate ? calendarShellTodayDateColor : "transparent",
          borderRadius: "100%"
        }}
      >
        <RawHighlighter
          variant={"body1"}
          color={todayDate ? calendarShellTodayDateTextColor : defaultTextColor}
          value={date.getDate().toString()}
        />
      </Box>
    </Box>
  );
};
