import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigSpreadsheetRowExpiry} from "../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {TypeCalendarItemId} from "../../types/TypeCalendar";
import {ICalendarData} from "../../types/TypeCalendar";
import {ICalendar} from "../../types/TypeCalendar";
import {TypeUserFieldValue} from "../../types/TypesGlobal";

interface ActionSetIfExistSigSpreadsheetRowExpiry
{
  itemId: TypeCalendarItemId;
  sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry;
}

export function getCalendarNameFake()
{
  return `Fake~~Calendar~~`;
}

export const sliceFakeTable = fnCreateCalendarSlice(getCalendarNameFake());

export const {
  calendarReset,
  calendarRefresh,
  calendarSetCacheMonthlyData,
  calendarResetCacheMonthlyData,
  calendarSetItem,
  calendarResetItem,
  calendarSetSearch,
  calendarSetIsReadOnly,
  calendarSetUserFieldVar
} = sliceFakeTable.actions;

export function fnCreateCalendarSlice(name: string)
{
  return createSlice({
    name: name,
    initialState: fnCalendarReset({} as ICalendar, name),
    reducers: {
      calendarReset: (state: ICalendar) =>
      {
        fnCalendarReset(state, name);
      },

      calendarRefresh: (state: ICalendar, action: PayloadAction<ICalendarData>) =>
      {
        fnCalendarRefresh(state, action);
      },

      calendarSetSearch: (state: ICalendar, action: PayloadAction<string | undefined>) =>
      {
        const searchString = action.payload;

        let searchWords: string[] | undefined = undefined;
        if(searchString && searchString.length > 0)
        {
          searchString.split(" ").forEach(value =>
          {
            value = value.trim();
            if(value.length > 0)
            {
              if(searchWords === undefined)
              {
                searchWords = [];
              }
              searchWords.push(value);
            }
          });
        }
      },

      calendarSetItem: (state: ICalendar, action: PayloadAction<{
        itemId: TypeCalendarItemId;
        item: FormValueRaw;
      }>) =>
      {
        const payload = action.payload;
        const itemId = payload.itemId;
        state.itemsById[itemId] = payload.item;
      },

      calendarSetCacheMonthlyData: (state: ICalendar, action: PayloadAction<{
        dateValue: string
        dateValueItem: Record<TypeCalendarItemId, FormValueRaw>,
      }>) =>
      {
        const dateValue = action.payload.dateValue;
        const dateValueItem = action.payload.dateValueItem;

        if(!state.cachedMonthlyEvents)
        {
          state.cachedMonthlyEvents = {};
        }

        state.cachedMonthlyEvents[dateValue] = dateValueItem;
      },

      calendarResetCacheMonthlyData: (state: ICalendar) =>
      {
        state.cachedMonthlyEvents = undefined;
      },

      calendarSetIsReadOnly: (state: ICalendar, action: PayloadAction<boolean>) =>
      {
        state.readOnly = action.payload;
      },

      calendarResetItem: (state: ICalendar, action: PayloadAction<TypeCalendarItemId>) =>
      {
        const itemId = action.payload;
        delete state.itemsById[itemId];
      },

      calendarSetUserFieldVar: (
        state: ICalendar,
        action: PayloadAction<{varName: string, varValue?: TypeUserFieldValue}>) =>
      {
        // noinspection DuplicatedCode
        const payload = action.payload;

        let userField = state.userField;
        if(userField === undefined)
        {
          userField = {};
        }

        const varName = payload.varName;
        const varValue = payload.varValue;
        if(varValue !== undefined)
        {
          userField = {
            ...userField,
            [varName]: varValue
          };
        }
        else if(userField)
        {
          delete userField[varName];
        }

        state.userField = userField;
      }

    }
  });
}

//region private

function fnCalendarReset(state: ICalendar, name: string): ICalendar
{
  state.version = undefined;
  state.userField = {};
  state.calendarName = name;
  state.itemsById = {};
  state.defnForm = undefined;

  return state;
}

function fnCalendarRefresh(state: ICalendar, action: PayloadAction<ICalendarData>)
{
  const calendarData = action.payload;

  state.itemsById = calendarData.itemsById;
  state.defnForm = calendarData.defnForm;
  state.version = calendarData.version;
  state.userField = calendarData.userField;
}
