import {combineReducers} from "redux";
import {reducersStoreDrawer} from "./drawer/StoreStoreDrawer";
import {reducersStoreItem} from "./storeItem/StoreStoreItem";

export function reducersStore()
{
  //combine reducers inside object can't be empty, give key as name and optionId as reducer name
  return combineReducers({
    drawer: reducersStoreDrawer(),
    storeItem: reducersStoreItem()
  });
}
