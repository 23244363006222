import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {PluginId} from "../../../api/meta/base/Types";
import {StoreItemId} from "../../../api/meta/base/Types";
import {SigStoreAdminList} from "../../../api/store/store/sig/SigStoreAdminList";
import ISrvc from "../../../base/ISrvc";
import {store} from "../../../Store";
import {ActionStoreItemStateMap} from "./SliceCacheStoreItem";
import {removeStoreItemAdminList} from "./SliceCacheStoreItem";
import {setStoreItemAdminList} from "./SliceCacheStoreItem";
import {setSearchStoreItemId} from "./SliceCacheStoreItem";
import {setStoreItemSelectedModules} from "./SliceCacheStoreItem";
import {setStoreItemCurrentTabs} from "./SliceCacheStoreItem";
import {setStoreItemPluginCurrentVersion} from "./SliceCacheStoreItem";

export default class SrvcCacheStoreItem extends ISrvc
{
  setStoreItemSelectedModules(storeItemId: StoreItemId, payload: StudioModuleSelection)
  {
    store.dispatch(setStoreItemSelectedModules({
      storeItemId: storeItemId,
      selectedModuleSet: payload
    }));
  }

  setStoreItemCurrentTabs(payload: ActionStoreItemStateMap)
  {
    store.dispatch(setStoreItemCurrentTabs(payload));
  }

  setSearchStoreItemId(searchStoreItemId?: StoreItemId)
  {
    store.dispatch(setSearchStoreItemId(searchStoreItemId));
  }

  setStoreItemAdminList(storeItemId: StoreItemId, sig: SigStoreAdminList)
  {
    store.dispatch(setStoreItemAdminList({
      storeItemId: storeItemId,
      sigStoreAdminList: sig
    }));
  }

  removeStoreItemAdminList(storeItemId: StoreItemId)
  {
    store.dispatch(removeStoreItemAdminList(storeItemId));
  }

  setStoreItemPluginCurrentVersion(pluginVersionId?: PluginId)
  {
    store.dispatch(setStoreItemPluginCurrentVersion({pluginVersionId}));
  }
}
