import ISrvc from "../../../../base/ISrvc";
import SrvcHomeAsideGroupInfoAddMembers from "./SrvcHomeAsideGroupInfoAddMembers";
import SrvcHomeAsideGroupInfoMemberList from "./SrvcHomeAsideGroupInfoMemberList";
import SrvcHomeAsideGroupInfoMemberListActions from "./SrvcHomeAsideGroupInfoMemberListActions";
import SrvcHomeAsideGroupInfoPatch from "./SrvcHomeAsideGroupInfoPatch";
import SrvcHomeAsideGroupInfoSearchMembers from "./SrvcHomeAsideGroupInfoSearchMembers";
import SrvcHomeAsideGroupInvite from "./SrvcHomeAsideGroupInvite";

export default class SrvcHomeAsideGroupInfo extends ISrvc
{
  public readonly patch = new SrvcHomeAsideGroupInfoPatch();
  public readonly memberListActions = new SrvcHomeAsideGroupInfoMemberListActions();
  public readonly memberList = new SrvcHomeAsideGroupInfoMemberList();
  public readonly searchMembers = new SrvcHomeAsideGroupInfoSearchMembers();
  public readonly addMembers = new SrvcHomeAsideGroupInfoAddMembers();
  public readonly inviteToGroup = new SrvcHomeAsideGroupInvite();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.patch,
      this.memberListActions,
      this.memberList,
      this.searchMembers,
      this.addMembers,
      this.inviteToGroup
    );
  }
}

