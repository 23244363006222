import {useMemo} from "react";
import {useCallback} from "react";
import {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {FieldValues} from "react-hook-form";
import {nextMetaIdSwimLane} from "../../api/meta/base/ApiPlus";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutKanban} from "../../api/meta/base/dto/StudioDtoLayoutKanban";
import {StudioFieldEditable} from "../../api/meta/base/dto/StudioFieldEditable";
import {StudioFieldPickText} from "../../api/meta/base/dto/StudioFieldPickText";
import {StudioMapOfSwimlane} from "../../api/meta/base/dto/StudioMapOfSwimlane";
import {StudioVarSetOfText} from "../../api/meta/base/dto/StudioVarSetOfText";
import {MetaIdVar} from "../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {getFormSectionCompositeId} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {getEmptyKeysAndMap} from "../../base/plus/StudioPlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldKeyDescription, fieldKeyLabel, fieldKeyName, fieldTabDetails} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import {getSwimLaneMapDtoToValue} from "./base/DialogPlus";
import {getSwimLaneMapValueToDto} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {getLayoutTabKanban} from "./base/LayoutBuilderPlus";
import {getCompMapSpreadSheetBrTabDetails} from "./base/LayoutBuilderPlus";
import {fieldLayoutSortOrder} from "./base/TypesLayoutBuilder";
import {fieldLayoutSortByFields} from "./base/TypesLayoutBuilder";
import {fieldFontSize} from "./base/TypesLayoutBuilder";
import {fieldShowFooter} from "./base/TypesLayoutBuilder";
import {fieldShowSectionName} from "./base/TypesLayoutBuilder";
import {fieldShowFields} from "./base/TypesLayoutBuilder";
import {fieldShowComments} from "./base/TypesLayoutBuilder";
import {fieldKanbanField} from "./base/TypesLayoutBuilder";
import {fieldTabKanban} from "./base/TypesLayoutBuilder";
import {fieldSpreadsheetLayoutSwitcherIdSet} from "./base/TypesLayoutBuilder";
import {fieldLayoutDetailBgColorField, fieldLayoutDetailTooltipField} from "./base/TypesLayoutBuilder";

const dialogContentWidth = 1200;
const dialogContentHeight = 716;

export default function DialogNewLayoutSpreadsheetKanban(props: {
  formStore: FormStore,
  isFormReadOnly?: boolean,
  metaIdForm?: MetaIdForm,
  spreadsheetId: MetaIdSpreadsheet
  values?: StudioDtoLayoutKanban,
  defaultValues?: StudioDtoLayoutKanban,
  onClickOk: (values: StudioDtoLayoutKanban) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[],
})
{
  const values = props.values;
  const defaultValues = props.defaultValues;
  const formStore = props.formStore;
  const spreadsheetId = props.spreadsheetId;
  const metaIdForm = props.metaIdForm;

  const excludeSpreadsheetLayoutIdSet = useMemo(() => values?.metaId ? [values.metaId] : undefined, [values]);
  const form = metaIdForm
    ? formStore.formMap?.map[metaIdForm]
    : undefined;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });
  const spreadSheetMap = formStore.spreadsheetMap?.map;

  const [includeFieldIdSet, setIncludeFieldIdSet] = useState<MetaIdField[]>([]);
  const [selectedKanbanFieldId, setSelectedKanbanFieldId] = useState<MetaIdField>();
  const [textSetVarId, setTextSetVarId] = useState<MetaIdVar>();
  const [swimlane, setSwimlane] = useState<StudioMapOfSwimlane>(getEmptyKeysAndMap());
  const [queryableFields, setQueryableFields] = useState<MetaIdField[]>([]);
  const [showFooter, setShowFooter] = useState<boolean>(Boolean(values?.showFooter));

  const defnForm = useMemo(() => getDefnForm(spreadsheetId,
      metaIdForm,
      includeFieldIdSet,
      excludeSpreadsheetLayoutIdSet,
      swimlane,
      queryableFields,
      formStore,
      showFooter
    ),
    [
      excludeSpreadsheetLayoutIdSet,
      formStore,
      includeFieldIdSet,
      metaIdForm,
      queryableFields,
      showFooter,
      spreadsheetId,
      swimlane
    ]
  );

  const onWatch = useCallback((key: string, value: any) =>
  {
    switch(key)
    {
      case fieldKanbanField:
        setSelectedKanbanFieldId(fnFieldValueToRawValue("pickFieldId", value) as MetaIdField);
        break;
      case fieldShowFooter:
        setShowFooter(Boolean(fnFieldValueToRawValue("bool", value)));
        break;
    }

  }, [form]);

  useEffect(() =>
  {
    if(values)
    {
      setSelectedKanbanFieldId(values.kanbanFieldId);
    }
    else if(defaultValues)
    {
      setSelectedKanbanFieldId(defaultValues.kanbanFieldId);
    }

  }, []);

  useEffect(() =>
  {
    let _textSetVarId: MetaIdVar | undefined = undefined;

    if(form && selectedKanbanFieldId)
    {
      loopStudioForm(form, (_, field) =>
      {
        if(field.type === "pickText" && field.metaId === selectedKanbanFieldId)
        {
          _textSetVarId = (field as StudioFieldPickText).sourceVarId;
        }
      });
    }

    setTextSetVarId(_textSetVarId);

  }, [form, selectedKanbanFieldId]);

  useEffect(() =>
  {
    if(textSetVarId)
    {
      let _swimlane = {
        keys: [],
        map: {}
      } as StudioMapOfSwimlane;
      
      const variable = formStore.varMap?.map[textSetVarId];
      const filteredDefaultValue = values || defaultValues;
      const defaultSwimlaneMap = filteredDefaultValue?.swimlaneMap;

      if(variable && variable.kind === "setOfText")
      {
        const _variable = variable as StudioVarSetOfText;
        const variableValueMap = _variable.value?.map;
        let unassignedSwimlaneId = nextMetaIdSwimLane();

        if(variableValueMap)
        {
          Object.values(variableValueMap).forEach(value =>
          {
            let swimlaneId = nextMetaIdSwimLane();

            if(defaultSwimlaneMap?.map)
            {
              Object.values(defaultSwimlaneMap?.map).forEach(swimlane =>
              {
                if(swimlane.valueOptionId === value.metaId)
                {
                  swimlaneId = swimlane.metaId;
                }
                if(!swimlane.valueOptionId)
                {
                  unassignedSwimlaneId = swimlane.metaId;
                }
              });
            }

            _swimlane.keys?.push(swimlaneId);
            _swimlane.map[swimlaneId] = {
              metaId: swimlaneId,
              label: value.value,
              valueOptionId: value.metaId
            };
          });

          _swimlane.keys?.push(unassignedSwimlaneId);
          _swimlane.map[unassignedSwimlaneId] = {
            metaId: unassignedSwimlaneId,
            label: "Unassigned"
          };
        }
      }
      setSwimlane(_swimlane);
    }
    else
    {
      setSwimlane(getEmptyKeysAndMap());
    }
  }, [textSetVarId]);

  useEffect(() =>
  {

    if(form)
    {
      const spreadSheet = spreadSheetMap
        ? spreadSheetMap[spreadsheetId]
        : undefined;

      const queryableIdSet = spreadSheet?.queryableIdSet;
      const _includeFieldIdSet = [] as MetaIdField[];

      loopStudioForm(form, (composite, field) =>
      {
        if(composite.type === "section")
        {
          _includeFieldIdSet.push(...composite.fieldMap.keys);
        }

        const _field = (field as StudioFieldEditable);
        const refParentFieldId = _field?.refFieldId;

        if(queryableIdSet?.includes(_field.metaId) && !refParentFieldId)
        {
          setQueryableFields(queryable => [...queryable, _field.metaId]);
        }
      });

      setIncludeFieldIdSet(_includeFieldIdSet);
    }

  }, [form, spreadSheetMap]);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} spreadsheet layout - kanban`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: defnForm,
        formReadonly: props.isFormReadOnly,
        store: formStore,
        initValues: dtoToValue(values || defaultValues),
        onSubmit: value => props.onClickOk(valueToDto(value, values, swimlane)),
        onWatch: onWatch
      }}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? "Add more spreadsheet layouts"
        : undefined}
    />
  );
}

function getDefnForm(
  spreadsheetId: MetaIdSpreadsheet,
  metaIdForm?: MetaIdForm,
  includeFieldIdSet?: MetaIdField[],
  excludeSpreadsheetLayoutIdSet?: MetaIdLayoutGrid[],
  swimLane?: StudioMapOfSwimlane,
  queryableFields?: MetaIdField[],
  formStore?: FormStore,
  showFooter?: boolean)
{
  const compositeIdSet = getFormSectionCompositeId(formStore, metaIdForm);

  return createDefaultDefnFormStudio({
    ...getCompMapSpreadSheetBrTabDetails(
      spreadsheetId,
      metaIdForm,
      excludeSpreadsheetLayoutIdSet,
      undefined,
      compositeIdSet,
      "kanban"
    ),
    ...getLayoutTabKanban(metaIdForm, compositeIdSet, swimLane, includeFieldIdSet, queryableFields, true, showFooter),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      showDivider: true,
      tabVariant: "standard",
      tabIdSet: [fieldTabDetails, fieldTabKanban]
    } as DefnTab

  } as Record<MetaIdField, DefnField>);
}

function dtoToValue(kanban?: StudioDtoLayoutKanban)
{
  if(!kanban)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "Kanban")
    };
  }

  const swimlaneMap = getSwimLaneMapDtoToValue(kanban?.swimlaneMap);

  return {
    // tab details
    [fieldKeyName]: fnRawValueToFieldValue("symbol", kanban.name ?? "Kanban"),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", kanban.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", kanban.description),
    [fieldLayoutDetailBgColorField]: fnRawValueToFieldValue("pickFieldId", kanban.bgColorFieldId),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", kanban.toolTipFieldId),
    [fieldSpreadsheetLayoutSwitcherIdSet]: fnRawValueToFieldValue("studioSetOfLayoutSpreadsheetId",
      kanban.allowToSwitchLayoutIdSet
    ),

    // tab kanban
    [fieldShowFields]: fnRawValueToFieldValue("studioSetOfFieldId", kanban?.showFieldIdSet),
    [fieldShowComments]: fnRawValueToFieldValue("bool", kanban?.showCommentCount),
    [fieldKanbanField]: fnRawValueToFieldValue("pickFieldId", kanban?.kanbanFieldId),
    [fieldShowSectionName]: fnRawValueToFieldValue("bool", kanban?.showSectionName),
    [fieldShowFooter]: fnRawValueToFieldValue("bool", kanban?.showFooter),
    [fieldLayoutSortByFields]: fnRawValueToFieldValue("studioSetOfFieldId", kanban?.sortByFieldIdSet),
    [fieldLayoutSortOrder]: fnRawValueToFieldValue("enumSortOrder", kanban?.sortOrder),
    [fieldFontSize]: fnRawValueToFieldValue("textSize", kanban?.textSize),
    ...swimlaneMap
  };
}

function valueToDto(
  value: FieldValues,
  dto?: StudioDtoLayoutKanban,
  swimlane?: StudioMapOfSwimlane
): StudioDtoLayoutKanban
{
  const swimlaneMap = getSwimLaneMapValueToDto(value, swimlane);

  const showFooter = fnFieldValueToRawValue("bool", value[fieldShowFooter]);
  return {
    metaId: dto?.metaId,
    name: fnFieldValueToRawValue("symbol", value[fieldKeyName]),
    label: fnFieldValueToRawValue("text", value[fieldKeyLabel]),
    description: fnFieldValueToRawValue("text", value[fieldKeyDescription]),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutDetailTooltipField]),
    bgColorFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutDetailBgColorField]),
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioSetOfLayoutSpreadsheetId",
      value[fieldSpreadsheetLayoutSwitcherIdSet]
    ),
    kind: fnFieldValueToRawValue("enumLayoutGridKind", "kanban"),
    kanbanFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldKanbanField]),
    showFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", value[fieldShowFields]),
    swimlaneMap: swimlaneMap,
    showSectionName: fnFieldValueToRawValue("bool", value[fieldShowSectionName]),
    showFooter: showFooter,
    showCommentCount: showFooter && fnFieldValueToRawValue("bool", value[fieldShowComments]),
    sortByFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", value[fieldLayoutSortByFields]),
    sortOrder: fnFieldValueToRawValue("enumSortOrder", value[fieldLayoutSortOrder]),
    fontSize: fnFieldValueToRawValue("textSize", value[fieldFontSize])
  } as StudioDtoLayoutKanban;
}


