import {PayloadAction} from "@reduxjs/toolkit";
import {IList} from "../../types/list/TypesList";
import {IListItemFooter} from "../../types/list/TypesListFooter";
import {fnListSetDisplay} from "./SliceList";

// hack: const causes TypeError
export function getListItemIdLoadMore()
{
  return `Load~~More~~Item~~Id`;
}

// hack: const causes TypeError
export function getListItemIdFooter()
{
  return `Footer~~Item~~Id`;
}

export const sliceListFooter =
  {
    listSetLoadMoreFlag: (state: IList) =>
    {
      if(!state.loadMoreItemLimit || state.loadMoreItemLimit <= 0)
      {
        throw new Error("Cannot load more if load more limit is not configured");
      }

      state.loadMoreFlag = true;
      fnListSetDisplay(state);
    },
    listSetFooter: (state: IList, action: PayloadAction<string | undefined>) =>
    {
      const footer = action.payload;
      state.itemsById[getListItemIdFooter()] = {
        type: "footer",
        footer: footer
      } as IListItemFooter;

      fnListSetDisplay(state);
    }
  };
