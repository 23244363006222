import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioCodeEditor} from "../../../api/meta/base/dto/DefnStudioCodeEditor";
import {DefnStudioMapOfFuncArg} from "../../../api/meta/base/dto/DefnStudioMapOfFuncArg";
import {StudioMapOfFuncArg} from "../../../api/meta/base/dto/StudioMapOfFuncArg";
import {StudioValueCode} from "../../../api/meta/base/dto/StudioValueCode";
import {StudioValueCodeJavascript} from "../../../api/meta/base/dto/StudioValueCodeJavascript";
import {StudioVarFunc} from "../../../api/meta/base/dto/StudioVarFunc";
import {MetaIdField} from "../../../api/meta/base/Types";

import {fieldGap1} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap2} from "../../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../form/builder/base/TypesFormBuilder";
import {fieldVariableFunctionInputList} from "../base/VariablePlus";

const fieldInputListLabel = "fieldInputListLabel";
const fieldOutputKind = "outputKind";
const fieldFunctionDefinitionLabel = "fieldFunctionDefinitionLabel";

export function fnVariableBuilderFunction(fieldValueFunction: string)
{
  function getVarComp(variableFunctionNameAndParams: string)
  {
    return {
      [fieldInputListLabel]: {
        type: "label",
        metaId: fieldInputListLabel,
        name: fieldInputListLabel,
        label: "Input arguments",
        colorVar: {
          value: "textSecondary"
        }
      } as DefnFieldLabel,
      [fieldVariableFunctionInputList]: {
        type: "studioMapOfFuncArg",
        metaId: fieldVariableFunctionInputList,
        name: fieldVariableFunctionInputList
      } as DefnStudioMapOfFuncArg,
      ...getFieldGap(fieldGap1, "thick"),
      [fieldOutputKind]: {
        type: "enumFuncArgs",
        metaId: fieldOutputKind,
        label: "Output kind",
        required: true
      } as DefnFieldEditable,
      ...getFieldGap(fieldGap2, "thick"),
      [fieldFunctionDefinitionLabel]: {
        type: "label",
        metaId: fieldFunctionDefinitionLabel,
        label: variableFunctionNameAndParams,
        textSizeVar: "body2",
        colorVar: {
          value: "textSecondary"
        }
      } as DefnFieldLabel,
      [fieldValueFunction]: {
        type: "studioCodeEditor",
        label: "Javascript",
        language: "javascript",
        required: true,
        showExpandBtn: true,
        metaId: fieldValueFunction
      } as DefnStudioCodeEditor
    } as Record<MetaIdField, DefnField>;
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarFunc
  )
  {
    const varValue = variable.value;
    const inputFuncArgMap = varValue?.inputFuncArgMap;

    if(inputFuncArgMap && inputFuncArgMap.keys && inputFuncArgMap.map)
    {
      valueMap[fieldVariableFunctionInputList] = inputFuncArgMap;
    }

    valueMap[fieldOutputKind] = varValue?.outputKind;

    const codeValue = varValue?.javascript?.value;
    if(codeValue)
    {
      valueMap[fieldValueFunction] = {
        ...varValue?.javascript,
        value: codeValue
      } as StudioValueCode;
    }
  }

  function defnToDto(
    valueMap: FieldValues
  )
  {
    const studioVarFunc = {
      value: {}
    } as StudioVarFunc;

    const inputFuncArgMap = valueMap[fieldVariableFunctionInputList] as StudioMapOfFuncArg;
    const outputKind = valueMap[fieldOutputKind];
    if(studioVarFunc.value)
    {
      const javascript = valueMap[fieldValueFunction] as StudioValueCodeJavascript;

      if(inputFuncArgMap && inputFuncArgMap.keys && inputFuncArgMap.map)
      {
        studioVarFunc.value.inputFuncArgMap = inputFuncArgMap;
      }

      if(javascript)
      {
        if(studioVarFunc.value)
        {
          studioVarFunc.value = {
            ...studioVarFunc.value,
            javascript: {
              ...javascript,
              value: javascript.value
            }
          };
        }
      }

      if(outputKind)
      {
        studioVarFunc.value.outputKind = outputKind;
      }

      return studioVarFunc;
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
