import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {EnumTopicType} from "../../../api/core/base/Types";
import {WsocSession} from "../../../api/core/session/WsocSession";
import {MsgTopic} from "../../../api/home/main/msg/MsgTopic";
import {MsgTopicList} from "../../../api/home/main/msg/MsgTopicList";
import {WsocMain} from "../../../api/home/main/WsocMain";
import {SysId} from "../../../api/meta/base/SysId";
import {ArtifactId} from "../../../api/meta/base/Types";
import {EnvError} from "../../../api/nucleus/base/dto/EnvError";
import {IDebugServiceReport} from "../../../base/ISrvc";
import ISrvc from "../../../base/ISrvc";
import {formatDetailedNow} from "../../../base/plus/DatePlus";
import {isDateWithinRange} from "../../../base/plus/DatePlus";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {LOG_WTF} from "../../../base/util/AppLog";
import {LOG_RECV} from "../../../base/util/AppLog";
import {LOG_SEND} from "../../../base/util/AppLog";
import {logClass} from "../../../base/util/AppLog";
import {logNow} from "../../../base/util/AppLog";
import {logError} from "../../../base/util/AppLog";
import {LOG_UN_SUB_COLOR} from "../../../base/util/AppLog";
import {LOG_SUB_COLOR} from "../../../base/util/AppLog";
import {isTraceLog} from "../../../base/util/AppLog";
import {isDebugLog} from "../../../base/util/AppLog";
import {logTrace} from "../../../base/util/AppLog";
import {logDebug} from "../../../base/util/AppLog";
import {fnCanStoreLog} from "../../../routes/app/logBr/impl/UiLogPlus";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export const TempSubscriberId = "~~Temp~~Subscriber~~Id" as TypeSubscriberId;
export const TimeToLingerAfterUnsubscribe = 15000; // 15 secs

type cbOnSubscription = undefined | ((topic: DtoTopic) => void);
type cbOnSubscriptionBulk = undefined | ((dtoTopicList: DtoTopic[]) => void);

export interface ISubscription
{
  subscriberSet: Set<TypeSubscriberId>;
}

export type ISubscriptionLog = {
  subCount?: number,
  unSubCount?: number,
  history: string[];
}
export default class SrvcSession extends ISrvc
{
  private subscribedTopicMap = new Map<string, ISubscription>(); // topic -> ISubscription
  private unsubscribeTopicMap = new Map<string, string>(); // topic -> unsubscribeDate
  private wsocSubLogMap = new Map<string, ISubscriptionLog>();  // topic -> ISubscriptionLog

  private readonly bulkCallTopicLimit = 200;
  private authorizedToSend = false;
  private readonly maxTicks = 5;
  private currTick = 0;
  private logSubColor = LOG_SUB_COLOR;
  private logUnsubColor = LOG_UN_SUB_COLOR;
  private detailLog: boolean = true;

  subscribe(subscriberId: TypeSubscriberId, dtoTopic: DtoTopic, onSubscription?: cbOnSubscription): void
  {
    if(isTraceLog())
    {
      const topic = JSON.stringify(dtoTopic);
      trace(`## subscribe, subscriberId = ${subscriberId}, topic = ${topic}`, this.logSubColor);
    }

    const topic = topicToString(dtoTopic);
    this.unsubscribeTopicMap.delete(topic);

    const subscription = this.subscribedTopicMap.get(topic);
    if(subscription)
    {
      const subscriberSet = subscription.subscriberSet;
      subscriberSet.delete(TempSubscriberId);
      subscriberSet.add(subscriberId);
    }
    else
    {
      const subscriberSet = new Set<TypeSubscriberId>();
      subscriberSet.add(subscriberId);
      this.wsocSubscribe(dtoTopic, {
        subscriberSet: subscriberSet
      }, onSubscription);
    }
  }

  unsubscribe(subscriberId: TypeSubscriberId, dtoTopic: DtoTopic): void
  {
    if(isTraceLog())
    {
      const topic = JSON.stringify(dtoTopic);
      trace(`## unsubscribe, subscriberId = ${subscriberId}, topic = ${topic}`, this.logUnsubColor);
    }

    const topic = topicToString(dtoTopic);
    let subscription = this.subscribedTopicMap.get(topic);
    if(subscription)
    {
      const subscriberSet = subscription.subscriberSet;
      subscriberSet.delete(subscriberId);
      if(subscriberSet.size === 0)
      {
        this.unsubscribeTopicMap.set(topic, formatDetailedNow());
      }
    }
  }

  // TODO: use unsubscribeForce after remove a resource
  unsubscribeForce(subscriberId: TypeSubscriberId, dtoTopic: DtoTopic): void
  {
    if(isTraceLog())
    {
      const topic = JSON.stringify(dtoTopic);
      trace(`## unsubscribeForce, subscriberId = ${subscriberId}, topic = ${topic}`, this.logUnsubColor);
    }

    const topic = topicToString(dtoTopic);
    let subscription = this.subscribedTopicMap.get(topic);
    if(subscription)
    {
      const subscriberSet = subscription.subscriberSet;
      subscriberSet.delete(subscriberId);
      if(subscriberSet.size === 0)
      {
        this.wsocUnsubscribe(dtoTopic, subscription);
      }
    }
  }

  subscribeBulk(subscriberId: TypeSubscriberId,
    dtoTopicList: DtoTopic[],
    onSubscriptionBulk?: cbOnSubscriptionBulk): void
  {
    const newDtoTopicList = [] as DtoTopic[];
    dtoTopicList.forEach(dtoTopic =>
    {
      const topic = topicToString(dtoTopic);
      this.unsubscribeTopicMap.delete(topic);

      let subscription = this.subscribedTopicMap.get(topic);
      if(subscription)
      {
        const subscriberSet = subscription.subscriberSet;
        subscriberSet.delete(TempSubscriberId);
        subscriberSet.add(subscriberId);
      }
      else
      {
        const subscriberSet = new Set<TypeSubscriberId>();
        subscriberSet.add(subscriberId);
        subscription = {
          subscriberSet: subscriberSet
        };
        this.subscribedTopicMap.set(topic, subscription);
        newDtoTopicList.push(dtoTopic);
      }
    });

    if(isTraceLog())
    {
      const topicList = JSON.stringify(newDtoTopicList);
      trace(`## subscribeBulk, subscriberId = ${subscriberId}, topicList = ${topicList}`, this.logSubColor);
    }
    if(newDtoTopicList.length > 0)
    {
      this.doBulkCallWithLimit(newDtoTopicList, (dtoTopicList) =>
      {
        this.wsocTopicSubscribe(dtoTopicList, onSubscriptionBulk);
      });
    }
    else
    {
      onSubscriptionBulk?.([]);
    }
  }

  unSubscribeBulk(subscriberId: TypeSubscriberId, dtoTopicList: DtoTopic[]): void
  {
    if(isTraceLog())
    {
      const topicList = JSON.stringify(dtoTopicList);
      trace(`## unSubscribeBulk, subscriberId = ${subscriberId}, topicList = ${topicList}`, this.logUnsubColor);
    }

    dtoTopicList.forEach(dtoTopic =>
    {
      const topic = topicToString(dtoTopic);
      let subscription = this.subscribedTopicMap.get(topic);
      if(subscription)
      {
        const subscriberSet = subscription.subscriberSet;
        subscriberSet.delete(subscriberId);
        if(subscriberSet.size === 0)
        {
          this.unsubscribeTopicMap.set(topic, formatDetailedNow());
        }
      }
    });
  }

  unsubscribeForceBulk(subscriberId: TypeSubscriberId, dtoTopicList: DtoTopic[]): void
  {
    const newDtoTopicList = [] as DtoTopic[];
    const subscribedTopicMap = new Map<string, ISubscription>();

    dtoTopicList.forEach(dtoTopic =>
    {
      const topic = topicToString(dtoTopic);
      let subscription = this.subscribedTopicMap.get(topic);
      if(subscription)
      {
        const subscriberSet = subscription.subscriberSet;
        subscriberSet.delete(subscriberId);
        subscribedTopicMap.set(topic, subscription);
        if(subscriberSet.size === 0)
        {
          newDtoTopicList.push(dtoTopic);
        }
      }
    });

    if(isTraceLog())
    {
      const topicList = JSON.stringify(dtoTopicList);
      trace(`## unsubscribeForceBulk, subscriberId = ${subscriberId}, topic = ${topicList}`, this.logUnsubColor);
    }
    if(newDtoTopicList.length > 0)
    {
      this.wsocTopicUnsubscribe(newDtoTopicList, subscribedTopicMap);
    }
  }

  getLogs()
  {
    return {
      wsocSubLogMap: this.wsocSubLogMap,
      subscribedTopicMap: this.subscribedTopicMap,
      unsubscribeTopicMap: this.unsubscribeTopicMap
    };
  }

  wsocTopicSubscriptionCheck(topic: DtoTopic, cb: (subscribed: boolean) => void)
  {
    const msg = {
      aboutId: topic.aboutId,
      type: topic.type
    } as MsgTopic;
    WsocMain.topicSubscriptionCheck(topic.artifactId, msg, envSig =>
    {
      const subscribed = !envSig.error;

      const historyLog = logHistoryMsg(`check`, undefined, undefined, false, `subscribed = ${subscribed}`);

      const topicString = topicToString(topic);
      const old = this.wsocSubLogMap.get(topicString);
      this.wsocSubLogMap.set(topicString, {
        ...old,
        history: [...old?.history || [], historyLog]
      });
      cb(subscribed);
    });
  }

  insertPubSubLog(topic: DtoTopic)
  {
    const topicStr = topicToString(topic);
    const subLog = this.wsocSubLogMap.get(topicStr);
    if(subLog)
    {
      const historyLog = logHistoryMsg(`sigTopic`);
      this.wsocSubLogMap.set(topicStr, {
        ...subLog,
        history: [...subLog.history, historyLog]
      });
    }
  }

  protected doSignOut()
  {
    // no need to unsubscribe because server on broken connection would unsubscribe automatically
    this.subscribedTopicMap = new Map<string, ISubscription>();
    this.unsubscribeTopicMap = new Map<string, string>();
    this.wsocSubLogMap = new Map<string, ISubscriptionLog>();
    this.authorizedToSend = false;
  }

  protected onWsocAuth()
  {
    debug("## sigAuth");
    Srvc.app.status.setFlagNetworkOn(true);
    this.detailLog = fnCanStoreLog(store.getState());

    WsocSession.sessionPut(() =>
    {
      this.authorizedToSend = true;
      const dtoTopicList = [] as DtoTopic[];
      const subscribedTopicMap = new Map<string, ISubscription>();
      this.subscribedTopicMap.forEach((subscription: ISubscription, topicStr: string) =>
      {
        subscribedTopicMap.set(topicStr, subscription);
        dtoTopicList.push({
          ...stringToTopic(topicStr)
        });
      });

      this.doBulkCallWithLimit(dtoTopicList, (dtoTopicList) =>
      {
        this.wsocTopicSubscribe(dtoTopicList);
      });
    });
    super.onWsocAuth();
  }

  protected onWsocClose()
  {
    debug("## onWsocClose");

    Srvc.app.status.setFlagNetworkOn(false);
    this.authorizedToSend = false;
  }

  protected doTick(dateNow: Date)
  {
    if(this.currTick === this.maxTicks)
    {
      this.currTick = 0;

      const expiredDtoTopicList = [] as DtoTopic[];
      this.unsubscribeTopicMap.forEach((unsubscribeDate, topic) =>
      {
        if(!isDateWithinRange(dateNow, unsubscribeDate, TimeToLingerAfterUnsubscribe))
        {
          let subscription = this.subscribedTopicMap.get(topic);
          if(subscription)
          {
            const dtoTopic = stringToTopic(topic);
            expiredDtoTopicList.push(dtoTopic);
          }
        }
      });
      this.doBulkCallWithLimit(expiredDtoTopicList, (expiredDtoTopicList) =>
      {
        const topicList = JSON.stringify(expiredDtoTopicList);
        debug(`## doTick, expired topics = ${topicList}`, this.logUnsubColor);
        this.wsocTopicUnsubscribe(expiredDtoTopicList, undefined);
      });
    }
    else
    {
      this.currTick++;
    }

    super.doTick(dateNow);
  }

  protected doDebug(report: IDebugServiceReport): void
  {
    const subscribedTopicMap = {} as Record<string, {subscriberSet: TypeSubscriberId[]}>;
    this.subscribedTopicMap.forEach((subscription: ISubscription, topic: string) =>
    {
      subscribedTopicMap[topic] = {
        subscriberSet: Array.from(subscription.subscriberSet)
      };
    });

    const unsubscribeTopicMap = {} as Record<string, string>;
    this.unsubscribeTopicMap.forEach((unsubscribeDate, topic) =>
    {
      unsubscribeTopicMap[topic] = unsubscribeDate;
    });

    const wsocSubLogMap = {} as Record<string, ISubscriptionLog>;
    this.wsocSubLogMap.forEach((value, key) =>
    {
      wsocSubLogMap[key] = value;
    });

    report.serviceMap["SrvcSession"] = {
      subscribedTopicMap: subscribedTopicMap,
      unsubscribeTopicMap: unsubscribeTopicMap,
      wsocSubLogMap: wsocSubLogMap
    };

    super.doDebug(report);
  }

  private doBulkCallWithLimit(dtoTopics: DtoTopic[], cb: (partialDtoTopics: DtoTopic[]) => void)
  {
    if(dtoTopics.length > this.bulkCallTopicLimit)
    {
      while(dtoTopics.length > 0)
      {
        const partialDtoTopics = dtoTopics.splice(0, this.bulkCallTopicLimit);
        cb(partialDtoTopics);
      }
    }
    else if(dtoTopics.length > 0)
    {
      cb(dtoTopics);
    }
  }

  private wsocTopicSubscribe(dtoTopicList: DtoTopic[], onSubscriptionBulk?: cbOnSubscriptionBulk)
  {
    if(this.authorizedToSend)
    {
      if(isDebugLog())
      {
        const topicList = JSON.stringify(dtoTopicList);
        debug(`## wsocTopicSubscribe, topicList = ${topicList}`, this.logSubColor);
      }

      const msg = {
        topicList: dtoTopicList
      } as MsgTopicList;

      if(this.detailLog)
      {
        dtoTopicList.forEach(value =>
        {
          const topic = topicToString(value);
          this.addSubLog(topic, true);
        });
      }

      WsocMain.topicSubscribe(msg, (envSig) =>
      {
        if(envSig.error)
        {
          return;
        }
        dtoTopicList.forEach(value =>
        {
          if(this.detailLog)
          {
            const topic = topicToString(value);
            this.addSubLog(topic, false, envSig.error);
          }
          Srvc.app.pubsub.handleSigTopic(value, true);
        });
        onSubscriptionBulk?.(dtoTopicList);
      });
    }
    else
    {
      logError(`## wsocTopicSubscribe, not authorized to send ${JSON.stringify(dtoTopicList)}`);
    }
  }

  private wsocTopicUnsubscribe(dtoTopicList: DtoTopic[], subscribedTopicMap?: Map<string, ISubscription>)
  {
    const _subscribedTopicMap = new Map<string, ISubscription>();
    dtoTopicList.forEach(dtoTopic =>
    {
      const subscription = this.subscribedTopicMap.get(topicToString(dtoTopic)) as ISubscription;
      if(!subscribedTopicMap)
      {
        _subscribedTopicMap.set(topicToString(dtoTopic), subscription);
      }

      const topic = topicToString(dtoTopic);
      this.subscribedTopicMap.delete(topic);
      this.unsubscribeTopicMap.delete(topic);
      if(this.authorizedToSend)
      {
        if(!this.wsocSubLogMap.has(topic))
        {
          error(`## wsocTopicUnsubscribe, topic not found in wsocPubSubLogMap, topic = ${topic}`);
        }
        if(this.detailLog)
        {
          this.addUnSubLog(topic, true);
        }
      }
    });

    if(this.authorizedToSend)
    {
      if(isDebugLog())
      {
        const topicList = JSON.stringify(dtoTopicList);
        debug(`## wsocTopicUnsubscribe, topic = ${topicList}`, this.logUnsubColor);
      }

      const msg = {
        topicList: dtoTopicList
      } as MsgTopicList;

      WsocMain.topicUnsubscribe(msg, (envSig) =>
      {
        if(envSig.error)
        {
          return;
        }
        dtoTopicList.forEach(dtoTopic =>
        {
          const topicString = topicToString(dtoTopic);
          if(this.detailLog)
          {
            this.addUnSubLog(topicString, false, envSig.error);
          }
          // if someone concurrently subscribe to this topic, then resubscribe
          const subscription = this.subscribedTopicMap.get(topicString);
          if(subscription)
          {
            this.wsocSubscribe(dtoTopic, subscription);
          }
          else
          {
            Srvc.app.pubsub.handleTopicUnSub(dtoTopic);
          }
        });
      });
    }
    else
    {
      logError(`## wsocTopicUnsubscribe, not authorized to send ${JSON.stringify(dtoTopicList)}`);
    }
  }

  private wsocSubscribe(dtoTopic: DtoTopic,
    subscription: ISubscription,
    onSubscription?: (dtoTopic: DtoTopic) => void): void
  {
    const topic = topicToString(dtoTopic);
    this.subscribedTopicMap.set(topic, subscription);

    if(this.authorizedToSend)
    {
      if(isDebugLog())
      {
        const subscriberSet = JSON.stringify(subscription.subscriberSet);
        const topic = JSON.stringify(dtoTopic);
        debug(`## wsocSubscribe, topic = ${topic}, subscriberSet: ${subscriberSet}`, this.logSubColor);
      }
      if(this.detailLog)
      {
        this.addSubLog(topic, true);
      }

      const msgTopic = {
        aboutId: dtoTopic.aboutId,
        type: dtoTopic.type
      } as MsgTopic;
      WsocMain.subscribe(dtoTopic.artifactId, msgTopic, (envSig) =>
      {
        if(this.detailLog)
        {
          this.addSubLog(topic, false, envSig.error);
        }
        if(envSig.error)
        {
          return;
        }

        onSubscription?.(dtoTopic);
        Srvc.app.pubsub.handleSigTopic(dtoTopic, true);
      });
    }
    else
    {
      logError(`## wsocSubscribe, not authorized to send ${topic}`);
    }
  }

  private wsocUnsubscribe(dtoTopic: DtoTopic, subscription: ISubscription): void
  {
    const topic = topicToString(dtoTopic);
    this.subscribedTopicMap.delete(topic);
    this.unsubscribeTopicMap.delete(topic);

    if(this.authorizedToSend)
    {
      if(isDebugLog())
      {
        const subscriberSet = JSON.stringify(subscription.subscriberSet);
        const topic = JSON.stringify(dtoTopic);
        debug(`## wsocUnsubscribe, topic = ${topic}, subscriberSet: ${subscriberSet}`, this.logUnsubColor);
      }
      if(!this.wsocSubLogMap.has(topic))
      {
        error(`## wsocUnsubscribe, topic not found in wsocPubSubLogMap, topic = ${topic}`);
      }
      if(this.detailLog)
      {
        this.addUnSubLog(topic, true);
      }

      const msgTopic = {
        aboutId: dtoTopic.aboutId,
        type: dtoTopic.type
      } as MsgTopic;
      WsocMain.unsubscribe(dtoTopic.artifactId, msgTopic, (envSig) =>
      {
        // if someone concurrently subscribe to this topic, then resubscribe
        if(this.detailLog)
        {
          this.addUnSubLog(topic, false, envSig.error);
        }

        const _subscription = this.subscribedTopicMap.get(topic);
        if(_subscription)
        {
          this.wsocSubscribe(dtoTopic, _subscription);
        }
        else
        {
          Srvc.app.pubsub.handleTopicUnSub(dtoTopic);
        }
      });
    }
    else
    {
      error(`## wsocUnsubscribe, not authorized to send ${topic}`);
    }
  }

  private addSubLog(topic: string, sent?: boolean, error?: EnvError)
  {
    const old = this.wsocSubLogMap.get(topic);
    const history = old?.history || [];
    const count = sent ? (old?.subCount || 0) + 1 : old?.subCount;
    const errorMsg = error ? JSON.stringify(error) : undefined;
    const historyLog = logHistoryMsg("subscribe", sent, errorMsg, true);
    this.wsocSubLogMap.set(topic, {
      subCount: count,
      unSubCount: old?.unSubCount,
      history: [...history, historyLog]
    });
  }

  private addUnSubLog(topic: string, sent?: boolean, error?: EnvError)
  {
    const old = this.wsocSubLogMap.get(topic);
    const history = old?.history || [];
    const count = sent ? (old?.unSubCount || 0) + 1 : old?.unSubCount;
    const errorMsg = error ? JSON.stringify(error) : undefined;
    const historyLog = logHistoryMsg("unsubscribe", sent, errorMsg);
    this.wsocSubLogMap.set(topic, {
      subCount: old?.subCount,
      unSubCount: count,
      history: [...history, historyLog]
    });
  }

  //endregion
}

export function stringToTopic(str: string): DtoTopic
{
  const array = str.split("/");
  return {
    artifactId: array[0] as ArtifactId,
    aboutId: array[1] as SysId,
    type: array[2] as EnumTopicType
  } as DtoTopic;
}

export function topicToString(dtoTopic: DtoTopic): string
{
  return `${dtoTopic.artifactId}/${dtoTopic.aboutId}/${dtoTopic.type}`;
}

function debug(message: any, color?: string)
{
  logDebug("SrvcSession", message, color);
}

function trace(message: any, color?: string)
{
  logTrace("SrvcSession", message, color);
}

function error(message: any)
{
  logError("SrvcSessionError", message);
}

function logHistoryMsg(kind: string, sent?: boolean, errorMsg?: string, storePath?: boolean, msg?: string)
{
  const err = new Error();
  const stack = createPathStack(err.stack)?.slice(1).join(" -> ");
  const stackStr = storePath ? `stack = ${stack}` : "";
  const _errorMsg = errorMsg ? "error = " + errorMsg : "";
  const message = `${msg || ""}${stackStr}${_errorMsg}`;

  return `${logNow()} | S | ${errorMsg ? LOG_WTF : sent ? LOG_SEND : LOG_RECV} | ${logClass(kind)} | ${message || ""}`;
}

function createPathStack(stack?: string)
{
  return stack?.split("\n").slice(1).map(line =>
  {
    const match = line.match(/at (.+?) \(/) || line.match(/at (.+?):/);
    return match ? match[1] : "<anonymous>";
  });
}
