import {Typography} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {clone} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldDynamic} from "../../../../api/meta/base/dto/DefnFieldDynamic";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueDynamic} from "../../../../api/meta/base/dto/FieldValueDynamic";
import {DynamicTypeRule} from "../../../../api/meta/base/StudioSetsFieldType";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {getFormFieldValueAsText} from "../../../../base/plus/FieldValuePlus";
import {getDynamicValueFieldId} from "../../../../base/plus/FormPlus";
import {fieldReportLeftLabel} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldFactory from "../base/FieldFactory";
import FieldBase from "../raw/FieldBase";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";

export default function FieldDynamic(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnFieldDynamic,
})
{
  const defn = props.defn;

  const formCtx = useFormCtx();
  const hookFormCtx = useFormContext();
  const formSectionCtx = useFormSectionCtx();
  const [fieldCompType, setFieldCompType] = useState<EnumDefnCompType>();

  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldValueId = getDynamicValueFieldId(fieldId);

  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const fieldVariant = defnTheme.fieldVariant;
  const isReport = defnTheme.formVariant === "report";

  const padding = {
    pt: px(defn.pt ?? 0),
    pb: px(defn.pb ?? 0),
    pl: px(defn.pl ?? 0),
    pr: px(defn.pr ?? 0)
  };

  const watch = hookFormCtx.watch(fieldValueId);
  const fieldDynamicArgs = formCtx.getFieldDynamicFieldArgs(fieldId);
  const fieldType = fieldDynamicArgs?.fieldType;
  const optionMap = fieldDynamicArgs?.optionMap;

  useEffect(() =>
  {
    if(fieldType && fieldType !== fieldCompType)
    {
      setFieldCompType(fieldType);
    }
  }, [fieldType]);

  useEffect(() =>
  {
    if(fieldCompType)
    {
      hookFormCtx.resetField(fieldId);
      hookFormCtx.resetField(fieldValueId);
    }

  }, [fieldCompType]);

  useEffect(() =>
  {
    switch(fieldCompType)
    {
      case "bool":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("bool", watch) ? "Yes" : "No",
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;
      case "date":
      case "dateTime":
      case "text":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("text", watch),
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;
      case "decimal":
      case "number":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("number", watch)?.toString(),
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;
      case "pickText":
        hookFormCtx.setValue(fieldId, {
          value: fnFieldValueToRawValue("pickText", watch),
          fieldType: fieldCompType
        } as FieldValueDynamic);
        break;

      default:
        hookFormCtx.resetField(fieldId);
        break;
    }

  }, [watch]);

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueDynamic;

        if(isReport)
        {
          return (
            <FieldBase fieldId={fieldId}>
              <FieldRawKeyValuePair
                leftHeight={undefined}
                left={
                  <RawHighlighter
                    variant={"caption"}
                    value={fieldReportLeftLabel(props.defn)}
                  />
                }
                right={
                  <Typography
                    component={"span"}
                    variant={"caption"}
                    color={"textSecondary"}
                  >
                    {getFormFieldValueAsText(defn, fieldValue)}
                  </Typography>
                }
              />
            </FieldBase>
          );
        }
        else if(sectionVariant === "propertyEditor")
        {
          return (
            <FieldBase
              fieldId={fieldId}
              {...padding}
            >
              <FormControl
                fullWidth
                variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
                error={isError}
              >
                <FieldRawDynamicField
                  {...props}
                  fieldId={fieldId}
                  dynamicFieldType={fieldCompType}
                  optionMap={optionMap}
                />

                {isError &&
                  <FormHelperText
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flexGrow: 1
                    }}
                  >
                    {error?.message}
                  </FormHelperText>
                }
              </FormControl>
            </FieldBase>
          );
        }

        return (
          <FieldBase
            fieldId={fieldId}
            {...padding}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <FieldRawDynamicField
                {...props}
                fieldId={fieldId}
                dynamicFieldType={fieldCompType}
                optionMap={optionMap}
              />

              {isError &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message}
                </FormHelperText>
              }
            </FormControl>
          </FieldBase>
        );
      }}
    />
  );
};

function FieldRawDynamicField(props: {
  fieldId: string,
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnFieldDynamic,
  dynamicFieldType?: EnumDefnCompType,
  optionMap?: DefnStudioMapOfDtoOption
})
{
  const defn = props.defn;
  const fieldId = getDynamicValueFieldId(props.fieldId);
  const defnForm = props.defnForm;
  const defnTheme = props.defnTheme;
  const optionMap = props.optionMap;
  let dynamicFieldType = props.dynamicFieldType;

  const defnComp = clone(defn);

  if(!dynamicFieldType || !DynamicTypeRule.includes(dynamicFieldType))
  {
    defnComp.disabled = true;
    dynamicFieldType = "text";
  }

  defnComp.type = dynamicFieldType;
  (defnComp as DefnFieldUi)._key = fieldId;
  (defnComp as DefnFieldUi).metaId = fieldId;
  defnForm.compMap[fieldId] = defnComp;

  if(dynamicFieldType === "pickText")
  {
    (defnComp as DefnFieldPickText).optionMap = optionMap;
  }

  return (
    <FieldFactory
      defnForm={defnForm}
      defnTheme={defnTheme}
      defnComp={defnComp}
    />
  );
}


