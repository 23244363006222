import {FormControl} from "@mui/material";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldIdentifier} from "../../../../api/meta/base/dto/DefnFieldIdentifier";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldIdentifier(props: {
  defn: DefnFieldIdentifier,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;

  const newDefn = useMemo(() =>
  {
    const isRefChild = Boolean(formCtx.getRefChildParentId(fieldId));

    return {
      ...defn,
      disabled: isRefChild ? defn.disabled : true
    } as DefnFieldIdentifier;
  }, [defn, fieldId]);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueText | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawTextField
                {...props}
                defn={newDefn}
                value={fieldValue}
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                error={error}
              />

            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
