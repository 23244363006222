import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldChipSetTime} from "../../../../api/meta/base/dto/DefnFieldChipSetTime";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldChipSetTime} from "../../../../api/meta/base/dto/FieldChipSetTime";
import {is12HourFormat} from "../../../../base/plus/DatePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawTimeSet from "../../../atom/raw/RawTimeSet";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldTimeSet(props: {
  defn: DefnFieldChipSetTime
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();

  const defn = props.defn;

  const {
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon,
    getFieldPlaceHolder
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const labelText = getCompLabel(defn);

  const fieldVariant = defnTheme.fieldVariant;

  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const required = getFieldRequired();
  const icon = getFieldIcon();
  const label = Boolean(required)
    ? labelText + " *"
    : labelText;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const fieldValue = field.value as FieldChipSetTime | undefined;

        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);
        const readonly = formCtx.isReadonly();
        const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
        const defaultTimeFormat = defn.displayDateFormat;
        const is12hrFormat = is12HourFormat(defaultTimeFormat);

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
              >
                <RawTimeSet
                  onChange={field.onChange}
                  readonly={readonly}
                  disabled={disabled}
                  value={fieldValue}
                  placeHolder={placeHolder}
                  helperText={helperText}
                  error={error}
                  name={defn.name}
                  label={label}
                  allowDuplicate={false}
                  inputFieldSize={defnTheme.fieldSize}
                  formCtx={formCtx}
                  fieldId={fieldId}
                  icon={icon}
                  hideLabel={defn.hideLabel}
                  is12hrFormat={is12hrFormat}
                  timeFormat={defaultTimeFormat}
                />
                <FieldRawRefButton defn={defn} />
              </LayoutFlexRow>
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
