import {Box} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import {useContext} from "react";
import {useRef} from "react";
import {createContext} from "react";
import React from "react";
import {DropEvent} from "react-dropzone";
import {FileRejection} from "react-dropzone";
import {Accept} from "react-dropzone";
import {useDropzone} from "react-dropzone";
import {STR_MAX_FILE_UPLOAD_LIMIT} from "../../../base/plus/ConstantsPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {FILE_UPLOAD_LIMIT_MAX} from "../../../base/types/TypesGlobal";
import {useAppCtx} from "../../ctx/CtxApp";
import {usePageCtx} from "../../ctx/CtxPage";
import RawHighlighter from "./RawHighlighter";

const DROP_FILE_TEXT = "Drop file here";

export interface ICtxFileSelector
{
  showFileDialog: (props: {accept?: Accept, onFileSelect: (files: File[]) => void}) => void;
  setAccept: (accept: Accept) => void;
}

const ctxFileSelector = createContext({} as ICtxFileSelector);

export const useFileSelectorCtx = () =>
{
  return useContext(ctxFileSelector);
};

export default function RawFileSelector(props: {
  accept?: Accept,
  multiple?: boolean,
  maxSize?: number,
  droppable?: boolean,
  children: React.ReactNode,
  onDrop?: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
})
{
  const [accept, setAccept] = useState<Accept>(props?.accept ?? {});
  const openFiles = useRef(false);
  const onFileSelectRef = useRef<(files: File[]) => void | undefined>();
  const ctxFileSelectorValue = {} as ICtxFileSelector;
  const [show, setShow] = useState(false);
  let lastTarget: EventTarget | null = null;
  const droppable = props.droppable;
  const pageCtx = usePageCtx();

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragAccept,
    isDragActive,
    isFocused,
    isDragReject
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: accept,
    multiple: props?.multiple || false,
    maxSize: props.maxSize,
    maxFiles: FILE_UPLOAD_LIMIT_MAX,
    onDrop: (acceptedFiles, rejectedFiles, event) =>
    {
      const files: File[] = [];
      const length = Math.min(acceptedFiles.length, FILE_UPLOAD_LIMIT_MAX);

      if(acceptedFiles.length > FILE_UPLOAD_LIMIT_MAX || rejectedFiles.length > FILE_UPLOAD_LIMIT_MAX)
      {
        return pageCtx.showErrorToast(STR_MAX_FILE_UPLOAD_LIMIT);
      }
      else
      {
        for(let i = 0; i < length; i++)
        {
          files.push(acceptedFiles[i]);
        }

        if(isDragActive)
        {
          props.onDrop && props.onDrop(files, rejectedFiles, event);
        }
      }
    }
  });

  useEffect(() =>
  {
    if(openFiles.current)
    {
      open();
      setAccept({});
      openFiles.current = false;
    }
  }, [accept]);

  useEffect(() =>
  {
    if(acceptedFiles.length)
    {
      const onFileSelect = onFileSelectRef.current;
      if(onFileSelect)
      {
        onFileSelectRef.current = undefined;
        onFileSelect(acceptedFiles);
      }
    }
  }, [acceptedFiles]);

  ctxFileSelectorValue.showFileDialog = ({
    onFileSelect,
    accept
  }) =>
  {
    onFileSelectRef.current = onFileSelect;
    if(!accept)
    {
      open();
      return;
    }
    openFiles.current = true;
    setAccept(accept);
  };

  ctxFileSelectorValue.setAccept = (accept: Accept) =>
  {
    setAccept(accept);
  };

  useEffect(() =>
  {
    const handleDragEnter = (e: DragEvent) =>
    {
      lastTarget = e.target;
      if(e && e.dataTransfer && (e.dataTransfer.types.indexOf("Files") !== -1))
      {
        setShow(true);
      }
    };

    const handleDragLeave = (e: DragEvent) =>
    {
      if(e.target === lastTarget || e.target === document)
      {
        setShow(false);
      }
    };

    const handleDragOver = (e: DragEvent) =>
    {
      e.preventDefault();
    };

    const handleDrop = (e: DragEvent) =>
    {
      e.preventDefault();
      setShow(false);
    };

    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("drop", handleDrop);

    return () =>
    {
      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("drop", handleDrop);
    };
  }, [show]);

  const appCtx = useAppCtx();
  const isMobile = appCtx?.isMobile && appCtx?.isMobile();

  return (
    <ctxFileSelector.Provider value={ctxFileSelectorValue}>
      <Box
        className={"dropzone"}
        {...getRootProps({isDragAccept, isFocused, isDragReject})}
        sx={{
          ...droppable && show && {
            position: "absolute",
            margin: "auto",
            padding: px(gapStd * 2),
            left: 0,
            right: 0,
            top: 60,
            bottom: 0,
            textAlign: "center",
            zIndex: 9999,
            width: "100%",
            height: "calc(100% - 58px)",
            flexGrow: 1,
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            bgcolor: theme.common.bgcolorDefault
          }
        }}
      >
        {
          droppable && show &&
          <Box
            sx={{
              borderWidth: px(gapQuarter),
              height: "100%",
              alignItems: "center",
              display: "flex",
              borderStyle: "dashed",
              borderColor: theme.common.bubbleBlinkColorLeft,
              borderRadius: gapQuarter
            }}
          >
            <RawHighlighter
              height={"100%"}
              width={"100%"}
              variant={isMobile ? "h5" : "h3"}
              value={DROP_FILE_TEXT}
              flexGrow={1}
              textAlign={"center"}
              color={theme.common.bubbleBlinkColorLeft}
            />
          </Box>
        }
        <input {...getInputProps()} />
      </Box>
      {props.children}
    </ctxFileSelector.Provider>
  );
}
