import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {StudioVarSetOfText} from "../../../api/meta/base/dto/StudioVarSetOfText";
import {MetaIdField} from "../../../api/meta/base/Types";

export function fnVariableBuilderSetOfText(fieldValueSetOfText: string)
{
  function getVarComp()
  {
    return {
      [fieldValueSetOfText]: {
        type: "studioMapOfText",
        metaId: fieldValueSetOfText,
        label: "Value",
        required: true
      } as DefnFieldEditable
    } as Record<MetaIdField, DefnField>;
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarSetOfText
  )
  {
    valueMap[fieldValueSetOfText] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueSetOfText];

    return {
      value: value
    } as StudioVarSetOfText;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
