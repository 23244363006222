import React from "react";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {IDataGridCellRendererParams} from "../../../../base/types/TypeDataGrid";
import {useAppSelector} from "../../../app/AppHooks";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {GRID_FONT_VARIANT} from "../DataGridPlus";

export default function GridCellComments<SR1, SR2, SR3, SR4, SR5, SR6>(props: IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const value = props.data?.commentCount;
  const senderId = props.data?.createdBy;
  const gridBinder = props.gridBinder;
  const formId = (props.context as AgGridContext)?.defnForm?.metaId;
  const selectIsCommentable = gridBinder?.selectIsCommentable;
  const rowId = props.data?.rowId;
  const canComment = useAppSelector(state => (selectIsCommentable && rowId && formId && senderId)
    ? selectIsCommentable(state, rowId, formId, senderId)
    : false);

  if(!canComment)
  {
    return null;
  }

  return (
    <RawHighlighter
      textAlign={"center"}
      variant={GRID_FONT_VARIANT}
      bold={!!value?.unreadCount}
      color={value?.unreadCount ? "success" : "primary"}
      value={value?.count.toString() || "0"}
      width={"100%"}
      cursor={"pointer"}
    />
  );
}
