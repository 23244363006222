import {useTheme} from "@mui/material";
import {Box} from "@mui/system";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {EnumDefnPlacement} from "../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../base/plus/ConstantsPlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LineTextPrimary from "../line/LineTextPrimary";
import LineTextSecondary from "../line/LineTextSecondary";

export interface HelperTextData
{
  title?: string;
  subtitle?: string;
  bulletPoints?: string[];
  note?: string;
  textAlignTitle?: StandardCSSProperties["textAlign"],
}

export default function RawNothingHere(props: {
  helperTextData?: HelperTextData
})
{
  const theme = useTheme();
  const gapVertInner = theme.common.vertInner;

  const {
    title,
    subtitle,
    bulletPoints,
    note,
    textAlignTitle
  } = props.helperTextData || {};

  return (
    <LayoutFlexCol
      width="100%"
      height="100%"
      flexGrow={1}
      pl="32px"
      pr="32px"
    >
      <Box
        sx={{
          height: "auto",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <LineTextPrimary
          value={title || STR_NOTHING_HERE}
          color={"textDisabled"}
          breakWords={true}
          textAlign={(textAlignTitle) as EnumDefnPlacement}
        />
        {subtitle && (
          <LineTextSecondary
            value={subtitle}
            color={"textDisabled"}
            breakWords={true}
          />
        )}
        {bulletPoints && (
          <LayoutFlexCol
            alignItems="flex-start"
            flexGrow={1}
            maxWidth="100%"
            mt={gapVertInner}
          >
            {bulletPoints.map((bulletPoint, index) => (
              <LineTextSecondary
                value={"• " + bulletPoint}
                key={index}
                height={"100%"}
                width={"100%"}
                color={"textDisabled"}
                breakWords={true}
              />
            ))}
          </LayoutFlexCol>
        )}
        {note && (
          <LineTextPrimary
            value={`Note: ${note}`}
            color="textDisabled"
            breakWords
            variant="caption"
            textAlign="center"
            mt={gapVertInner}
            italic
          />
        )}
      </Box>
    </LayoutFlexCol>
  );
}
