import {sliceFakeList} from "./SliceList";

export const {
  listChatSetIfExistReceiptStatus,
  listChatSetIfExistHeader,
  listChatSetIfExistDefnForm,
  listChatSetIfExistFormResult,
  listChatSetIfExistFormComment,
  listChatSetIfExistIsStar,
  listChatSetIfExistIsDownloading,
  listChatSetIfExistIsCommentAble,
  listChatSetIfExistMsgReply,
  listSetIfExistIsFormWithMedia,
  listChatSetIfExistSigSpreadsheetRowExpiry,
  listChatSetIfExistChatPatternVar,
  listChatSetIfExistIsVisibleSpreadsheetRow,
  listChatSetIfExistMessage,
  listChatSetFormBubbleTitleColor,

  listChatHandleSigTopicMessageProps,
  listChatHandleSigTopicMessageRemoveForMe,
  listChatHandleSigTopicFormRemove
} = sliceFakeList.actions;
