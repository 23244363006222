import ISrvc from "../../../base/ISrvc";
import SrvcCacheHomeDrawerMsgLast from "./msgLast/SrvcCacheHomeDrawerMsgLast";
import SrvcCacheHomeDrawerRecentList from "./recent/SrvcCacheHomeDrawerRecentList";

export default class SrvcCacheHomeDrawer extends ISrvc
{
  public readonly msgLast = new SrvcCacheHomeDrawerMsgLast();
  public readonly recent = new SrvcCacheHomeDrawerRecentList();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.msgLast,
      this.recent
    );
  }
}
