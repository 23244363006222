import {Box} from "@mui/material";
import React from "react";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../base/plus/ConstantsPlus";
import {px} from "../../../base/plus/StringPlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";

export type TypeGradient =
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right"

export default function RawRadialGradient(props: {
  type: TypeGradient,
  children?: React.ReactNode,
  visibilityHidden?: boolean,
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
})
{
  const {
    type,
    children,
    visibilityHidden
  } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 2,
        borderRadius: px(CHAT_ITEM_IMAGE_BORDER_RADIUS),
        ...visibilityHidden &&
        {
          visibility: "hidden"
        },
        ...getPositionSx(type),
        mt: props.mt ? px(props.mt) : undefined,
        mr: props.mr ? px(props.mr) : undefined,
        mb: props.mb ? px(props.mb) : undefined,
        ml: props.ml ? px(props.ml) : undefined
      }}
    >
      <LayoutFlexCol
        justifyContent={"end"}
        alignItems={"end"}
      >
        {children}
      </LayoutFlexCol>
    </Box>
  );
}

function getPositionSx(type: TypeGradient)
{
  switch(type)
  {
    case "top-left":
      return {
        top: 0,
        left: 0,
        boxShadow: getBoxShadow()
      };
    case "top-right":
      return {
        top: 0,
        right: 0,
        boxShadow: getBoxShadow()
      };
    case "bottom-left":
      return {
        bottom: 0,
        left: 0,
        boxShadow: getBoxShadow()
      };
    case "bottom-right":
      return {
        bottom: 0,
        right: 0,
        boxShadow: getBoxShadow()
      };
  }
}

function getBoxShadow()
{
  return "rgba(0, 0, 0, 0.2) 0px 1px 2px";
}
