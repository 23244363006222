import ISrvc from "../../base/ISrvc";
import SrvcAuth from "./auth/SrvcAuth";
import SrvcBoot from "./boot/SrvcBoot";
import SrvcDebug from "./debug/SrvcDebug";
import SrvcFirebase from "./firebase/SrvcFirebase";
import SrvcForm from "./form/SrvcForm";
import SrvcPayment from "./payment/SrvcPayment";
import SrvcPubSub from "./pubsub/SrvcPubSub";
import SrvcSession from "./session/SrvcSession";
import SrvcSpreadsheet from "./spreadsheet/SrvcSpreadsheet";
import SrvcStatus from "./status/SrvcStatus";
import SrvcToast from "./toast/SrvcToast";
import SrvcWidget from "./widget/SrvcWidget";

export default class SrvcApp extends ISrvc
{
  public readonly auth = new SrvcAuth();
  public readonly boot = new SrvcBoot();
  public readonly debug = new SrvcDebug();
  public readonly firebase = new SrvcFirebase();
  public readonly form = new SrvcForm();
  public readonly payment = new SrvcPayment();
  public readonly pubsub = new SrvcPubSub();
  public readonly session = new SrvcSession();
  public readonly spreadsheet = new SrvcSpreadsheet();
  public readonly status = new SrvcStatus();
  public readonly toast = new SrvcToast();
  public readonly widget = new SrvcWidget();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.auth,
      this.boot,
      this.debug,
      this.firebase,
      this.form,
      this.payment,
      this.pubsub,
      this.session,
      this.spreadsheet,
      this.status,
      this.toast,
      this.widget
    );
  }
}
