import {combineReducers} from "@reduxjs/toolkit";
import {sliceCacheStudioFilter} from "./filter/SliceCacheStoreFilter";
import {sliceCacheStoreItem} from "./storeItem/SliceCacheStoreItem";

export function reducersCacheStore()
{
  return combineReducers({
    filter: sliceCacheStudioFilter.reducer,
    storeItem: sliceCacheStoreItem.reducer
  });
}
