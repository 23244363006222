import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {CSSProperties} from "react";
import {useMemo} from "react";
import React from "react";
import {SigSpreadsheetRowExpiry} from "../../../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {DtoChatBubbleHeader} from "../../../../../api/home/base/dto/DtoChatBubbleHeader";
import {DtoMessageReplyPayload} from "../../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../../api/home/base/Types";
import {SigUserAvatar} from "../../../../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRowCommentCount} from "../../../../../api/home/main/sig/SigSpreadsheetRowCommentCount";
import {DefnDtoParagraph} from "../../../../../api/meta/base/dto/DefnDtoParagraph";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {FormValue} from "../../../../../api/meta/base/dto/FormValue";
import {EntUserId} from "../../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../../api/meta/base/Types";
import {MetaIdComp} from "../../../../../api/meta/base/Types";
import {px} from "../../../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../../../base/plus/ThemePlus";
import {IBubbleReplyInfo} from "../../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../../base/types/TypesBubble";
import {TypeTextColor} from "../../../../../base/types/TypesGlobal";
import {CbMenuAnchor} from "../../../../../base/types/TypesGlobal";
import {RootState} from "../../../../../Store";
import LayoutFlexCol from "../../../layout/LayoutFlexCol";
import {LayoutGap} from "../../../layout/LayoutGap";
import BubbleShell from "../../leftright/BubbleShell";
import BubbleRawHeader from "../BubbleRawHeader";
import BubbleRawReplyPreview from "../BubbleRawReplyPreview";
import BubbleRawFormContent from "./BubbleRawFormContent";
import BubbleRawFormFooter from "./BubbleRawFormFooter";
import BubbleRawFormMedia from "./BubbleRawFormMedia";
import BubbleRawFormShowGridAsHyperlink from "./BubbleRawFormShowGridAsHyperLink";
import BubbleRawFormTitle from "./BubbleRawFormTitle";

export default function BubbleRawForm(props: {
  //data
  title?: string,
  titleColor?: TypeTextColor;
  chatBubbleHeader?: DtoChatBubbleHeader;
  receiptStatus?: EnumReceiptStatus,
  isCallerSender: boolean,
  creationTime: string,
  searchWords?: string[],
  sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry
  defnForm?: DefnForm,
  formValue?: FormValue;
  rowCommentCount?: SigSpreadsheetRowCommentCount;
  updatedKeySet?: MetaIdComp[];
  chatPatternVar?: DefnDtoParagraph;
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined

  // ui
  maxWidth?: number,
  variant?: Variant;
  header?: IBubbleHeader,
  showGridAsHyperlink?: boolean,
  disableMenuHover?: boolean,
  isStar?: boolean,
  isBubbleBlinking?: boolean,
  isFormWithMedia?: boolean,
  showComments?: boolean,
  canExpire?: boolean,
  hideTitle?: boolean,
  hideSectionName?: boolean,
  hideFooter?: boolean,
  isInvisibleSpreadsheetRow?: boolean,
  isMsgForwardable?: boolean;
  isBubbleHover?: boolean,
  showShadow?: boolean,
  fullWidth?: boolean;
  bgColorShell?: CssBackgroundColor;
  justifyContent?: CSSProperties["justifyContent"];
  isSelected?: boolean,

  // callbacks
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickFooterComments?: CbMenuAnchor,
  cbOnClickFooter?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
  cbOnClickGridHyperlink?: (menuAnchor: Element, gridId: MetaIdGrid) => void
})
{
  const fullWidth = props.fullWidth;
  const formValue = props.formValue;
  const updatedKeySet = props.updatedKeySet;
  const maxWidth = props.maxWidth;

  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const calcBubbleFixedWidth = theme.common.calcBubbleFixedWidth(maxWidth);
  const width = useMemo(() =>
  {
    return (fullWidth && maxWidth)
      ? (maxWidth - gapHalf)
      : calcBubbleFixedWidth;
  }, [calcBubbleFixedWidth, fullWidth, gapHalf, maxWidth]);

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={maxWidth}
      onClickMenu={props.cbOnClickMenu}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      isBubbleBlinking={props.isBubbleBlinking}
      bgColorShell={props.bgColorShell}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
      disableMenuHover={props.disableMenuHover}
      fullWidth={fullWidth}
      isSelected={props.isSelected}
      justifyContent={props.justifyContent}
    >
      <LayoutFlexCol
        width={px(width)}
        height={"auto"}
        overflowX={"visible"}
        overflowY={"visible"}
      >
        {/*Header*/}
        {
          props.header !== undefined &&
          <BubbleRawHeader
            header={props.header}
            onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
          />
        }

        {
          props.replyPayload &&
          <BubbleRawReplyPreview
            replyPayload={props.replyPayload}
            replyInfo={props.replyInfo}
            onClickPreview={props.cbOnClickReplyPreview}
          />
        }

        {/*Media Carousel*/}
        <BubbleRawFormMedia
          defnForm={props.defnForm}
          formValue={formValue}
          maxWidth={width}
          isFormWithMedia={props.isFormWithMedia}
        />

        {/*Title */}
        <BubbleRawFormTitle
          searchWords={props.searchWords}
          hideTitle={props.hideTitle}
          chatBubbleHeader={props.chatBubbleHeader}
          title={props.title}
          titleColor={props.titleColor}
        />

        {/*Body*/}
        <BubbleRawFormContent
          defnForm={props.defnForm}
          searchWords={props.searchWords}
          hideSectionName={props.hideSectionName}
          canExpire={props.canExpire}
          chatPatternVar={props.chatPatternVar}
          isInvisibleSpreadsheetRow={props.isInvisibleSpreadsheetRow}
          maxWidth={width}
          valueMap={formValue?.valueMap}
          updatedKeySet={updatedKeySet}
          selectUserAvatar={props.selectUserAvatar}
        />

        <LayoutGap height={px(gapHalf)} />

        {/*ShowGridAsHyperlink*/}
        {
          Boolean(props.showGridAsHyperlink) &&
          <BubbleRawFormShowGridAsHyperlink {...props} />
        }

        {/*Footer*/}
        <BubbleRawFormFooter
          creationTime={props.creationTime}
          rowCommentCount={props.rowCommentCount}
          receiptStatus={props.receiptStatus}
          sigSpreadsheetRowExpiry={props.sigSpreadsheetRowExpiry}
          hideFooter={props.hideFooter}
          isStar={props.isStar}
          showComments={props.showComments}
          cbOnClickFooterComments={props.cbOnClickFooterComments}
          cbOnClickFooterCaption={props.cbOnClickFooterCaption}
        />

      </LayoutFlexCol>
    </BubbleShell>
  );
}

