import {isEmpty} from "lodash";
import {nextMetaIdVar} from "../../../../api/meta/base/ApiPlus";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {MetaIdVar, PluginBundleId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioPluginBundle} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchPlugin} from "../../SrvcCacheStudio";
import {addPluginVariable, movePluginItem, removePluginVariable} from "../SliceCacheStudioPlugin";
import {IDtoPluginCopy} from "../TypesCacheStudioPlugin";

export default class SrvcCacheStudioPluginVariables extends ISrvc
{
  addPluginVariable(pluginBundleId: PluginBundleId, variable: StudioVar)
  {
    dispatchPlugin(pluginBundleId, addPluginVariable({
      pluginBundleId: pluginBundleId,
      sig: {
        ...variable,
        metaId: variable.metaId || nextMetaIdVar()
      }
    }));
  }

  removePluginVariable(pluginBundleId: PluginBundleId, metaIdVar: MetaIdVar)
  {
    dispatchPlugin(pluginBundleId, removePluginVariable({
      pluginBundleId: pluginBundleId,
      metaId: metaIdVar
    }));
  }

  copyPluginVariable(pluginBundleId: PluginBundleId, metaIdVar: MetaIdVar)
  {
    const selectedVersion = selectCacheStudioPluginBundle(store.getState(),
      pluginBundleId
    )?.draft?.studioPlugin;

    if(selectedVersion)
    {
      const varMap = selectedVersion.varMap;
      const variable = varMap?.map[metaIdVar];

      if(!isEmpty(variable))
      {
        const payloadCopy: IDtoPluginCopy = {
          type: "variable",
          payload: variable
        };
        copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
      }
    }
  }

  duplicatePluginVariable(pluginBundleId: PluginBundleId, metaIdVar: MetaIdVar)
  {
    const selectedVersion = selectCacheStudioPluginBundle(store.getState(),
      pluginBundleId
    )?.draft?.studioPlugin;

    if(selectedVersion)
    {
      const pluginVarMap = selectedVersion.varMap;
      if(!pluginVarMap)
      {
        return;
      }

      const index = pluginVarMap.keys.indexOf(metaIdVar);
      if(index === -1)
      {
        return;
      }

      const variable = pluginVarMap.map[metaIdVar]
        ? JSON.parse(updateAllMetaIds(JSON.stringify(pluginVarMap.map[metaIdVar])))
        : undefined;

      if(variable)
      {
        dispatchPlugin(pluginBundleId, addPluginVariable({
          pluginBundleId: pluginBundleId,
          sig: variable,
          insertIndex: index + 1
        }));
      }
    }
  }

  moveItem(
    pluginBundleId: PluginBundleId,
    metaId: MetaIdVar,
    moveDirection: EnumListItemDirection)
  {
    dispatchPlugin(pluginBundleId, movePluginItem({
      pluginBundleId,
      metaId,
      setKey: "variableList",
      moveDirection
    }));
  }
}
