import {DefnDtoLayoutCardItemLine} from "../../../api/meta/base/dto/DefnDtoLayoutCardItemLine";
import {getCombinedFieldId} from "../../../base/plus/FormPlus";

export type TypeLayoutItemLine = keyof DefnDtoLayoutCardItemLine;
export type TypeLineNumber = "firstLine" | "secondLine" | "thirdLine" | "fourthLine" | "fifthLine"
export type TypeLayoutItemLineSegment = "texts" | "colors" | "fonts" | "labels";
export type TypeLayoutItemLineSegmentFields = "lineFieldIdSet" | "colorFieldId" | "fontFieldId" | "showLabelsFieldId";
export type TypeLayoutItemLineSegmentVariables = "lineVarId" | "colorVarId" | "fontVarId" | "showLabelsVarId";
export type TypeLayoutItemLineSegmentConstants = "line" | "color" | "font" | "showLabels";

export type TypeLayoutItemLineSegmentType = "constants" | "variables" | "fields";
// Tab-detail
export const fieldLayoutDetailsSection = "fieldLayoutDetailsSection";
export const fieldLayoutDetailTooltipField = "toolTipFieldId";
export const fieldLayoutDetailBgColorField = "bgColorFieldId";
export const fieldSpreadsheetLayoutSwitcherIdSet = "excludeLayoutSpreadsheetIdSet";
export const fieldGridLayoutSwitcherIdSet = "allowToSwitchLayoutIdSet";
export const fieldLayoutGroupByField = "groupByFieldId";
export const fieldLayoutRenderingMode = "renderingMode";
//Tab-filter
export const fieldLayoutFilterSection = "fieldLayoutFilterSection";
export const fieldLayoutFilterLayoutMode = "filter";
export const fieldLayoutFilterShowSearchBar = "showSearchBar";
export const fieldLayoutFilterCatFields = "categoryFieldIdSet";
export const fieldLayoutFilterAdvanceFilterFields = "advanceFilterFieldIdSet";
export const fieldLayoutSortByFields = "sortByFieldIdSet";
export const fieldLayoutSortOrder = "sortOrder";
//Tab-item
export const fieldLayoutItem = "item";
export const fieldLayoutItemTabTexts: TypeLayoutItemLineSegment = "texts";
export const fieldLayoutItemTabColors: TypeLayoutItemLineSegment = `colors`;
export const fieldLayoutItemTabFonts: TypeLayoutItemLineSegment = `fonts`;
export const fieldLayoutItemTabLabels: TypeLayoutItemLineSegment = `labels`;
export const fieldLayoutItemTypeConstants: TypeLayoutItemLineSegmentType = `constants`;
export const fieldLayoutItemTypeVariables: TypeLayoutItemLineSegmentType = `variables`;
export const fieldLayoutItemTypeFields: TypeLayoutItemLineSegmentType = `fields`;
export const fieldLayoutItemLine: TypeLayoutItemLine = `first`;
export const fieldLayoutItemMiddle: TypeLayoutItemLine = `middle`;
export const fieldLayoutItemCaption: TypeLayoutItemLine = `caption`;
export const fieldLayoutItemMedia = `fieldLayoutItemMedia`;
export const fieldLayoutItemMediaConstant = getDefnIdLayoutItemMediaId("constants");
export const fieldLayoutItemMediaVariable = getDefnIdLayoutItemMediaId("variables");
export const fieldLayoutItemMediaField = getDefnIdLayoutItemMediaId("fields");
export const fieldLayoutItemMediaConstantImageType = getCombinedFieldId([fieldLayoutItemMediaConstant, "ImageType"]);
export const fieldLayoutItemMediaVariableImageType = getCombinedFieldId([fieldLayoutItemMediaVariable, "ImageType"]);
export const fieldLayoutItemMediaFieldImageType = getCombinedFieldId([fieldLayoutItemMediaField, "ImageType"]);
export const fieldLayoutItemMediaConstantsDisable = getCombinedFieldId([fieldLayoutItemMediaConstant, "disable"]);
export const fieldLayoutItemMediaVariablesDisable = getCombinedFieldId([fieldLayoutItemMediaVariable, "disable"]);
export const fieldLayoutItemMediaFieldsDisable = getCombinedFieldId([fieldLayoutItemMediaField, "disable"]);
export const fieldLayoutItemMediaCornerTypeConstant = "cornerTypeConstant";
export const fieldLayoutItemMediaCornerTypeVariable = "cornerTypeVariable";
export const fieldLayoutItemMediaCornerTypeField = "cornerTypeField";

export const fieldLayoutItemMediaCornerTypeDisableConstant = getCombinedFieldId([
  fieldLayoutItemMediaCornerTypeConstant,
  "disable"
]);
export const fieldLayoutItemMediaCornerTypeDisableVariable = getCombinedFieldId([
  fieldLayoutItemMediaCornerTypeVariable,
  "disable"
]);

export const fieldLayoutItemMediaCornerTypeDisableField = getCombinedFieldId([
  fieldLayoutItemMediaCornerTypeField,
  "disable"
]);
export const allowToSwitchLayoutsLabel = "Allow to switch layouts";

export const fieldDateField = "dateFieldId";
export const fieldTitleField = "titleFieldId";
export const fieldShowFieldIdSet = "showFieldIdSet";
export const fieldColorField = "colorFieldId";
export const fieldTabCalendar = "calendar";
export const fieldTimeFormField = "timeFieldFromId";
export const fieldTimeToField = "timeFieldToId";
export const fieldDateFormField = "fieldDateFromId";
export const fieldDateToField = "fieldDateToId";
export const fieldFormLabel = "fieldFormLabel";
export const fieldToLabel = "fieldToLabel";
export const fieldFromDateTimeSection = "fieldFromDateTimeSectionId";
export const fieldToDateTimeSection = "fieldToDateTimeSectionId";
export const fieldGap = "fieldGap";

export const fieldTabKanban = "kanban";
export const fieldShowFields = "showFieldIdSet";
export const fieldShowSectionName = "showSectionName";
export const fieldShowFooter = "showFooter";
export const fieldShowComments = "showCommentCount";
export const fieldFontSize = "fontSize";
export const fieldKanbanField = "kanbanFieldId";
export const fieldSwimlaneLabel = "swimLaneLabel";
export const fieldSwimlaneNote = "fieldSwimlaneNote";

export function getLayoutItemSectionId(str1: string)
{
  return getCombinedFieldId([str1, "section"]);
}

export function getLayoutItemTabId(str1: string)
{
  return getCombinedFieldId([str1, "tab"]);
}

function getDefnIdLayoutItemMediaId(tabItemTabName: TypeLayoutItemLineSegmentType, disable?: boolean)
{
  return getCombinedFieldId([tabItemTabName, fieldLayoutItemMedia, ...disable ? ["disable"] : []]);
}
