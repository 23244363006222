import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import DateTimeFormat from "../../../atom/assets/DateFormat.json";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickDateTimeFormat(props: {
  defn: DefnFieldEditable;
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const options = getOption() as DefnDtoOption[];

  function getOption(): DefnDtoOption[]
  {
    const dateTimeFormatKeys = Object.keys(DateTimeFormat);
    return dateTimeFormatKeys.map((value) =>
    {
      return {
        metaId: value,
        value: value
      } as DefnDtoOption;
    });
  }

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
      {
        return <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={props.defn}
          optionMap={optionsToMapOfOption(options)}
        />;
      }}
    />
  );
}
