// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgAsideSearch} from './msg/MsgAsideSearch';
import {MsgCallerChatNotificationSettingPut} from './msg/MsgCallerChatNotificationSettingPut';
import {MsgEntUserIdNoVersion} from '../../core/base/msg/MsgEntUserIdNoVersion';
import {MsgGroupInfoGet} from './msg/MsgGroupInfoGet';
import {MsgGroupInviteLink} from './msg/MsgGroupInviteLink';
import {MsgGroupMembersAdd} from './msg/MsgGroupMembersAdd';
import {MsgGroupMembersRemove} from './msg/MsgGroupMembersRemove';
import {MsgGroupPatch} from './msg/MsgGroupPatch';
import {MsgOffset} from '../main/msg/MsgOffset';
import {ServiceName} from '../../meta/base/Types';
import {SigAsideSearch} from '../drawer/sig/SigAsideSearch';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigGroupIdList} from './sig/SigGroupIdList';
import {SigGroupInfo} from './sig/SigGroupInfo';
import {SigMessageReceiptMap} from './sig/SigMessageReceiptMap';
import {SigUrl} from '../../core/deeplink/sig/SigUrl';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocAside
{
  static readonly SN: ServiceName = "aside";

  static asideSearch(entId: EntId, msg: MsgAsideSearch, sigAcceptor: ISigAcceptor<SigAsideSearch>): void
  {
    wsocCall<SigAsideSearch>(entId, WsocAside.SN, "asideSearch")
      .post(msg, sigAcceptor);
  }

  static callerChatNotificationSettingPut(entId: EntId, msg: MsgCallerChatNotificationSettingPut, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocAside.SN, "callerChatNotificationSettingPut")
      .put(msg, sigAcceptor);
  }

  static groupCommonListGet(msg: MsgEntUserIdNoVersion, sigAcceptor: ISigAcceptor<SigGroupIdList>): void
  {
    wsocCall<SigGroupIdList>(ENT_ID_GLOBAL, WsocAside.SN, "groupCommonListGet")
      .get(msg, sigAcceptor);
  }

  static groupInfoGet(entId: EntId, msg: MsgGroupInfoGet, sigAcceptor: ISigAcceptor<SigGroupInfo>): void
  {
    wsocCall<SigGroupInfo>(entId, WsocAside.SN, "groupInfoGet")
      .get(msg, sigAcceptor);
  }

  static groupInviteLinkGet(msg: MsgGroupInviteLink, sigAcceptor: ISigAcceptor<SigUrl>): void
  {
    wsocCall<SigUrl>(ENT_ID_GLOBAL, WsocAside.SN, "groupInviteLinkGet")
      .get(msg, sigAcceptor);
  }

  static groupMembersAdd(msg: MsgGroupMembersAdd, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocAside.SN, "groupMembersAdd")
      .put(msg, sigAcceptor);
  }

  static groupMembersRemove(msg: MsgGroupMembersRemove, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocAside.SN, "groupMembersRemove")
      .put(msg, sigAcceptor);
  }

  static groupPatch(msg: MsgGroupPatch, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocAside.SN, "groupPatch")
      .patch(msg, sigAcceptor);
  }

  static messageReceiptGet(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigMessageReceiptMap>): void
  {
    wsocCall<SigMessageReceiptMap>(entId, WsocAside.SN, "messageReceiptGet")
      .get(msg, sigAcceptor);
  }
}