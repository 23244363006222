import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../../base/plus/StringPlus";
import {DividerHorizontal} from "../../layout/DividerHorizontal";
import LayoutFlexRow from "../../layout/LayoutFlexRow";

export default function BubbleRawDivider()
{
  const theme = useTheme();
  const bubbleShellGap = theme.common.bubbleShellGap * 2;
  const bubbleBorderColor = theme.common.bubbleBorderColor;

  return (
    <LayoutFlexRow
      height={"100%"}
      width={`calc(100% + ${px(bubbleShellGap)})`}
    >
      <DividerHorizontal borderColor={bubbleBorderColor} />
    </LayoutFlexRow>
  );
}
