import ISrvc from "../../../base/ISrvc";
import SrvcHomeMainFooter from "./footer/SrvcHomeMainFooter";
import SrvcHomeMainHeader from "./header/SrvcHomeMainHeader";
import SrvcHomeUserAndGroupSelection from "./SrvcHomeUserAndGroupSelection";

export default class SrvcHomeApp extends ISrvc
{
  public readonly userAndGroupSelection = new SrvcHomeUserAndGroupSelection();
  public readonly footer = new SrvcHomeMainFooter();
  public readonly header = new SrvcHomeMainHeader();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.userAndGroupSelection,
      this.footer,
      this.header
    );
  }

}
