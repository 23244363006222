import {FieldValues} from "react-hook-form/dist/types/fields";
import {StudioFieldPropertyMap} from "../../../../../api/meta/base/dto/StudioFieldPropertyMap";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {getDefaultValueVariableStudioField} from "../../base/FieldBuilderFactory";
import {propKeyDefaultValueTextVar} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";

export function getDefnFieldPropertyMap(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyDefaultValueTextVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyPlaceHolderValue
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "propertyMap",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    ...getDefaultValueVariableStudioField("mapOfText", formId)

  };
}

export function defnValueToStudioFieldPropertyMap(values: FieldValues): StudioFieldPropertyMap
{
  return {
    type: "propertyMap",
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar])
  } as StudioFieldPropertyMap;
}

export function studioFieldPropertyMapToDefnValue(studioField: StudioFieldPropertyMap): FieldValues
{
  return {
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId)
  };
}
