import {useMemo} from "react";
import {useCallback} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoPermissionMatrix} from "../../../../api/meta/base/dto/DefnDtoPermissionMatrix";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioBuildPermissionMatrix} from "../../../../api/meta/base/dto/DefnStudioBuildPermissionMatrix";
import {StudioEntRoleMap} from "../../../../api/meta/base/dto/StudioEntRoleMap";
import {MetaIdRole} from "../../../../api/meta/base/Types";
import {EnumDefnPermission} from "../../../../api/meta/base/Types";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {insertSystemRolesStudio} from "../../../../base/plus/StudioPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewPermission from "../../../dialog/DialogNewPermission";
import {IRolePermission} from "../../builder/base/TypesFormBuilder";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioBuildPermissionMatrix(props: {
  defn: DefnStudioBuildPermissionMatrix,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();
  const roleMap = insertSystemRolesStudio(formStore?.roleMap);

  const label = defn.label;
  const filterOptionSet = defn.filterOptionSet;

  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as DefnDtoPermissionMatrix;

        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfPermissions
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  isFormReadOnly={formReadOnly}
                  onChange={onChange}
                  formStore={formStore}
                  roleMap={roleMap}
                  filterOptionSet={filterOptionSet}
                />
              }
            />
          );
        }

        return (
          <RawSetOfPermissions
            fieldId={fieldId}
            fieldValue={fieldValue}
            isFormReadOnly={formReadOnly}
            onChange={onChange}
            formStore={formStore}
            roleMap={roleMap}
            filterOptionSet={filterOptionSet}
          />
        );
      }
      }
    />
  );
}

function RawSetOfPermissions(props: {
  fieldId: MetaIdField,
  fieldValue: DefnDtoPermissionMatrix,
  isFormReadOnly?: boolean,
  roleMap?: StudioEntRoleMap,
  formStore?: FormStore,
  filterOptionSet?: EnumDefnPermission[],
  onChange: (value: DefnDtoPermissionMatrix) => void
})
{
  const {
    formStore,
    fieldId,
    roleMap,
    isFormReadOnly,
    filterOptionSet
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<IRolePermission>;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: IRolePermission[]) => props.onChange(convertArrayToMap(fieldValue));
  const trashRoleMap = formStore?.trash?.roleMap;

  const doLoad = useCallback((selectList: SelectList, fieldValue: IRolePermission[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.metaIdRole;
        uiItemIds.push(listId);

        let primaryText = "";
        let color = undefined as TypeTextColor | undefined;
        if(value.metaIdRole)
        {
          const roleName = roleMap?.map[value.metaIdRole];
          if(roleName)
          {
            primaryText = roleName.details.name ?? "";

          }
          else if(trashRoleMap)
          {
            const trashRoleName = trashRoleMap[value.metaIdRole];
            if(trashRoleName)
            {
              primaryText = trashRoleName.details.name ?? "";
              color = "errorLight";
            }
          }
        }

        uiItemsById[listId] = {
          type: "p",
          primary: {
            text: primaryText,
            color: color,
            caption: {
              text: toLabel(value.permission),
              ignoreSelection: true
            }
          },
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemMPSL;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  }, [roleMap]);

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogNewPermission
          formStore={formStore}
          filterOptionSet={filterOptionSet}
          isFormReadonly={isFormReadOnly}
          onClickOk={(newValue) => cbRefList.addItem(newValue)}
          excludeRolesIdSet={fieldValue
            ? fieldValue.map((value) => value.metaIdRole)
            : undefined}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as IRolePermission
        : undefined;

      let excludeRolesIdSet: MetaIdRole[] = [];

      fieldValue.forEach(value =>
      {
        if(value.metaIdRole !== oldValue?.metaIdRole)
        {
          excludeRolesIdSet.push(value.metaIdRole);
        }
      });

      pageCtx.showDialog(
        <DialogNewPermission
          values={oldValue}
          formStore={formStore}
          filterOptionSet={filterOptionSet}
          isFormReadonly={isFormReadOnly}
          onClickOk={(newValue) => cbRefList.updateItem(newValue, key)}
          excludeRolesIdSet={excludeRolesIdSet.length > 0 ? excludeRolesIdSet : undefined}
          validationError={validationError}
        />
      );
    }
  };

  return (
    <FieldRawList
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          toolTipMap={{
            add: "Add permission"
          } as Record<EnumIconButton, string>}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );

}

function convertMapToArray(map?: DefnDtoPermissionMatrix): IRolePermission[]
{
  if(!map?.keys || !map?.map)
  {
    return [];
  }
  else
  {
    return map.keys.map((key) =>
    {
      return {
        metaIdRole: key,
        permission: map.map?.[key] ?? "read"
      };
    });
  }
}

function convertArrayToMap(arr: IRolePermission[]): DefnDtoPermissionMatrix
{
  const keys = [] as MetaIdOption[];
  const map = {} as Record<MetaIdRole, EnumDefnPermission>;

  arr.forEach((value) =>
  {
    const key = value.metaIdRole;
    keys.push(key);
    map[key] = value.permission;
  });

  return {

    keys: keys,
    map: map
  } as DefnDtoPermissionMatrix;
}
