import ISrvc from "../../../base/ISrvc";
import SrvcCacheControlPanelAutomation from "./automation/SrvcCacheControlPanelAutomation";
import SrvcCacheControlPanelLog from "./log/SrvcCacheControlPanelLog";
import SrvcCacheControlPanelQuery from "./query/SrvcCacheControlPanelQuery";

export default class SrvcCacheControlPanelEnt extends ISrvc
{
  public readonly query = new SrvcCacheControlPanelQuery();
  public readonly automation = new SrvcCacheControlPanelAutomation();
  public readonly log = new SrvcCacheControlPanelLog();

  constructor()
  {
    super();
    this.setSrvcArray(
      this.query,
      this.automation
    );
  }
}
