import {dispatchList} from "../../../../base/plus/ListPlus";
import {listSetFooter} from "../../../../base/slices/list/SliceListFooterActions";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import SrvcHomeAsideGroupInfoSearchMembers from "./SrvcHomeAsideGroupInfoSearchMembers";

export default class SrvcHomeAsideGroupInfoMemberList extends SrvcHomeAsideGroupInfoSearchMembers
{
  protected onPostLoad(listName: string, itemIds: TypeComboId[], itemsById: IListItemsById)
  {
    if(itemIds.length <= 10)
    {
      dispatchList(listName, listSetFooter(undefined));
    }
    else
    {
      // load more group member list footer item
      dispatchList(listName, listSetFooter(`${itemIds.length - 10} more`));
    }
  }

  protected getSubscriberId(): string
  {
    return "SrvcHomeAsideGroupInfoMemberList";
  }
}

