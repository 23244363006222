import {EntId} from "../../api/meta/base/Types";
import {ArtifactId} from "../../api/meta/base/Types";
import {removeItem} from "../plus/JsPlus";
import LocalSessionStorage from "./LocalSessionStorage";

const SEP = ",";

export type FnKeyProvider = (callerIdHash: string) => string;

export default class LocalFilterPickMany
{
  constructor(readonly keyProvider: FnKeyProvider)
  {
  }

  clear(callerIdHash: string)
  {
    const key = this.getKey(callerIdHash);
    this.getStorage().removeItem(key);
  }

  ensure(callerIdHash: string, artifactId: ArtifactId)
  {
    const key = this.getKey(callerIdHash);
    const storage = this.getStorage();
    const value = storage.getItem(key);
    if(value)
    {
      const splits = value.split(SEP);
      if(!splits.includes(artifactId))
      {
        storage.setItem(key, value + SEP + artifactId);
      }
    }
    else
    {
      storage.setItem(key, artifactId);
    }
  }

  remove(callerIdHash: string, endId: EntId)
  {
    const key = this.getKey(callerIdHash);
    const storage = this.getStorage();
    const value = storage.getItem(key);
    if(value)
    {
      if(value === endId)
      {
        storage.removeItem(key);
      }
      else
      {
        const splits = value.split(SEP);
        removeItem(splits, endId);
        storage.setItem(key, splits.join(SEP));
      }
    }
  }

  get(callerIdHash: string): EntId[]
  {
    const key = this.getKey(callerIdHash);
    const storage = this.getStorage();
    const value = storage.getItem(key);
    if(value && value.length > 0)
    {
      const splits = value.split(SEP);
      splits.sort();
      return splits;
    }
    else
    {
      return [];
    }
  }

  private getStorage(): Storage
  {
    return LocalSessionStorage;
  }

  private getKey(callerIdHash: string)
  {
    return this.keyProvider(callerIdHash);
  }
}
