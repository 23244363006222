import React from "react";
import {stripIconButtonSx} from "../../../base/plus/ThemePlus";
import {ILineCaptionIconButton} from "../../../base/types/TypesGlobal";
import RawIconButton from "../raw/RawIconButton";

export default function LineCaptionIconButton(props: {
  captionIconButton: ILineCaptionIconButton;
  onClickIconButton?: (e: React.MouseEvent<HTMLButtonElement>) => void,
})
{
  const captionIconButton = props.captionIconButton;
  const onClickIconCaption = props.onClickIconButton;

  const icon = captionIconButton.icon;
  const color = captionIconButton.color;
  const tooltip = captionIconButton.tooltip;

  const width = stripIconButtonSx().width;

  return (
    <RawIconButton
      name={icon}
      icon={icon}
      color={color}
      width={width}
      tooltip={tooltip}
      onClick={(_, e) =>
      {
        e.stopPropagation();
        e.preventDefault();
        onClickIconCaption && onClickIconCaption(e);
      }}
    />
  );
}
