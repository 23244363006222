import {Box} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {isEmpty} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {DefnFieldDateTimeRange} from "../../../../api/meta/base/dto/DefnFieldDateTimeRange";
import {FieldValueDateRange} from "../../../../api/meta/base/dto/FieldValueDateRange";
import {is12HourFormat} from "../../../../base/plus/DatePlus";
import {getLocalDateFormat} from "../../../../base/plus/DatePlus";
import {toISOString} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import {getRawIcon} from "../../../atom/raw/RawIcon";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import {useFormCtx} from "../base/CtxForm";
import FieldRawRefButton from "./FieldRawRefButton";

export default function FieldRawDateTimeRange(props: {
  defn: DefnFieldDateTimeRange
  onBlur: () => void
  onChange: (value?: FieldValueDateRange | null) => void
  value: FieldValueDateRange
  readOnly?: boolean
  disabled?: boolean,
  error?: FieldError,
  isTouched?: boolean,
  required?: boolean,
  placeHolder?: string,
  icon?: string,
  minDateTime?: string,
  maxDateTime?: string,
  helperText?: string,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const defn = props.defn;
  const error = props.error;
  const isError = props.isTouched && Boolean(error);
  const value = props.value;
  const onBlur = props.onBlur;
  const readOnly = props.readOnly;
  const disabled = props.disabled;
  const onChange = props.onChange;

  const required = props.required;
  const placeHolder = props.placeHolder;
  const icon = props.icon;
  const minDateTime = props.minDateTime ? new Date(props.minDateTime) : undefined;
  const maxDateTime = props.maxDateTime ? new Date(props.maxDateTime) : undefined;
  const is12hrFormat = is12HourFormat(defn.displayDateFormat);

  const fieldId = defn.metaId;
  const autoFill = defn.autoFill;
  const autoFocus = defn.autoFocus;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const [startDate, setStartDate] = useState<string>();

  const dateTime = defn?.displayDateFormat
    ? defn.displayDateFormat
    : getLocalDateFormat();

  useEffect(() =>
  {
    if(value && !isEmpty(value.from))
    {
      setStartDate(value.from);
    }
    else
    {
      setStartDate(undefined);
    }
  }, [value]);

  return (
    <LayoutFlexCol
      width={"100%"}
      height={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
    >
      <RawLocalizationProvider>
        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DateTimePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            format={dateTime}
            value={(value && !isEmpty(value.from) && value.from) ? new Date(value.from) : null}
            label={"Date time from"}
            disabled={disabled}
            readOnly={readOnly}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}
            autoFocus={autoFocus}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            onClose={onBlur}
            ampm={is12hrFormat}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const date = toISOString(event);
                onChange({
                  to: value?.to ?? undefined,
                  from: date
                });
                setStartDate(date);
              }
            }}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            slotProps={{
              textField: {
                fullWidth: true,
                type: "text",
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: fieldVariant,
                autoComplete: autoFill ? "on" : "off",
                required: required,
                onClick: onClick,
                onBlur: onBlur,
                error: isError,
                placeholder: placeHolder
              }
            }}
          />
          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                iconStrip={["delete"]}
                onClick={(icon) =>
                {
                  if(icon === "delete")
                  {
                    props.onChange(null);
                  }
                }}
                iconStripDisable={(!value || readOnly || disabled) ? ["delete"] : []}
              />
            </LayoutFlexRow>
          }
          <FieldRawRefButton
            defn={defn}
          />
        </LayoutFlexRow>

        <LayoutGap height={gapStd} />

        <LayoutFlexRow
          width={"100%"}
          overflowY={"visible"}
          overflowX={"visible"}
        >
          <DateTimePicker
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            format={dateTime}
            value={(value && !isEmpty(value.to) && value.to) ? new Date(value.to) : null}
            label={"Date time to"}
            disabled={(!startDate || disabled) && !readOnly}
            minDateTime={startDate ? new Date(startDate) : undefined}
            maxDateTime={maxDateTime}
            readOnly={readOnly}
            autoFocus={autoFocus}
            slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            timeSteps={{
              hours: 1,
              minutes: 1,
              seconds: 1
            }}
            ampm={is12hrFormat}
            onOpen={onBlur}
            onChange={(event, context) =>
            {
              if(event && !context.validationError)
              {
                const date = new Date(event).toISOString();
                onChange({
                  from: value.from ?? undefined,
                  to: date
                });
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                type: "text",
                size: defnTheme.fieldSize,
                margin: defnTheme.fieldMargin,
                variant: fieldVariant,
                autoComplete: autoFill ? "on" : "off",
                required: required,
                onClick: onClick,
                onBlur: onBlur,
                error: isError,
                placeholder: placeHolder
              }
            }}
          />
          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                iconStrip={["delete"]}
                onClick={(icon) =>
                {
                  if(icon === "delete")
                  {
                    props.onChange(null);
                  }
                }}
                iconStripDisable={(!value || readOnly || disabled) ? ["delete"] : []}
              />
            </LayoutFlexRow>
          }
          <Box sx={{visibility: "hidden"}}>
            <FieldRawRefButton
              defn={defn}
            />
          </Box>

        </LayoutFlexRow>
      </RawLocalizationProvider>
    </LayoutFlexCol>
  );
}

