import {isEmpty} from "lodash";
import {nextMetaIdAction} from "../../../../api/meta/base/ApiPlus";
import {StudioEntAction} from "../../../../api/meta/base/dto/StudioEntAction";
import {EntId, MetaIdAction} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntAction, moveEntItem, removeEntAction} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntActions extends ISrvc
{
  addEntAction(entId: EntId, entAction: StudioEntAction)
  {

    dispatchEnt(entId, addEntAction({
      entId: entId,
      sig: {
        ...entAction,
        metaId: entAction.metaId ?? nextMetaIdAction()
      }
    }));

  }

  removeEntAction(entId: EntId, metaIdAction: MetaIdAction)
  {
    dispatchEnt(entId, removeEntAction({
      entId: entId,
      metaId: metaIdAction
    }));
  }

  copyEntAction(entId: EntId, metaIdAction: MetaIdAction)
  {
    const rootState = store.getState();
    const actionMap = selectCacheStudioEnt(rootState, entId)?.ent.actionMap;
    const action = actionMap?.map[metaIdAction];

    if(!isEmpty(action))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "action",
        payload: action
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntAction(entId: EntId, metaIdAction: MetaIdAction)
  {
    const rootState = store.getState();
    const actionMap = rootState.cache.studio.ent.entMap[entId]?.ent?.actionMap;

    if(!actionMap)
    {
      return;
    }
    const index = actionMap.keys.indexOf(metaIdAction);
    if(index === -1)
    {
      return;
    }
    const entAction = actionMap.map[metaIdAction]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(actionMap.map[metaIdAction])))
      : undefined;

    if(entAction)
    {
      dispatchEnt(entId, addEntAction({
        entId: entId,
        sig: entAction,
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdAction,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "actionList",
      moveDirection
    }));
  }

}
