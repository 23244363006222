import {isEqual} from "lodash";
import React from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {useState} from "react";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioMapOfArgBinder} from "../../../../api/meta/base/dto/StudioMapOfArgBinder";
import {StudioVarHtml} from "../../../../api/meta/base/dto/StudioVarHtml";
import {StudioVarParagraph} from "../../../../api/meta/base/dto/StudioVarParagraph";
import {StudioVarText} from "../../../../api/meta/base/dto/StudioVarText";
import {EnumDefnArgBinder} from "../../../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {getResolveStatus} from "../../../../base/plus/ArgBinderPlus";
import theme from "../../../../base/plus/ThemePlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogLinkVariable from "../../../dialog/DialogLinkVariable";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickVarId from "./FieldRawStudioPickVarId";

export interface IFieldValueVarArgResolve
{
  argBinderMap?: StudioMapOfArgBinder;
  argVarId: MetaIdVar;
}

export function FieldRawStudioVarIdTextEditor(props: {
  defn: DefnField,
  argBinderFormId?: MetaIdForm;
  inputFormId?: MetaIdForm;
  excludeFieldIdSet?: MetaIdField[];
  filterKindSet?: EnumDefnArgBinder[];
  filterVarKindSet?: EnumStudioVarKind[];
  argBinderFilterVarKindSet?: EnumStudioVarKind[];
  showAsEdit?: boolean;
  fieldProps: IControllerFieldProps,
  fieldKey: string,
  fieldValue?: IFieldValueVarArgResolve | null,
  onChange: (value?: IFieldValueVarArgResolve | null) => void
})
{
  const defnComp = props.defn;
  const onChange = props.onChange;
  const fieldValue = props.fieldValue;
  const formId = props.argBinderFormId;
  const inputFormId = props.inputFormId;
  const excludeFieldIdSet = props.excludeFieldIdSet;
  const filterVarKindSet = props.filterVarKindSet;
  const filterKindSet = props.filterKindSet;
  const argBinderFilterVarKindSet = props.argBinderFilterVarKindSet;
  const fieldKey = props.fieldKey;

  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();

  const formStore = formCtx.getStore();
  const focusField = formCtx.getFocusField();

  const [argVarId, setArgVarId] = useState<MetaIdVar | undefined>(fieldValue?.argVarId);
  const [paramSet, setParamSet] = useState<string[]>();
  const [resolveStatus, setResolveStatus] = useState(false);
  const [argBinderMap, setArgBinderMap] = useState<StudioMapOfArgBinder | undefined>(fieldValue?.argBinderMap);

  const onChangeVarId = (varId: MetaIdVar) => setArgVarId(varId);

  const variable = useMemo(() =>
  {
    return argVarId ? formStore?.varMap?.map[argVarId] : undefined;
  }, [formStore?.varMap?.map, argVarId]);

  const onClickIcon = useCallback((paramSet: string[], formStore: FormStore) =>
  {
    pageCtx.showDialog(<DialogLinkVariable
      paramsSet={paramSet}
      values={fieldValue?.argBinderMap}
      formId={formId}
      inputFormId={inputFormId}
      formStore={formStore}
      onClickOk={setArgBinderMap}
      excludeVarIdSet={fieldValue?.argVarId
        ? [fieldValue.argVarId]
        : undefined}
      excludeFieldIdSet={excludeFieldIdSet}
      argBinderFilterVarKindSet={argBinderFilterVarKindSet}
      filterKindSet={filterKindSet}
    />);
  }, [fieldValue?.argBinderMap, fieldValue?.argVarId, formId, argBinderFilterVarKindSet, filterKindSet]);

  useEffect(() =>
  {
    if(!fieldValue || !fieldValue.argVarId)
    {
      setArgVarId(undefined);
      setArgBinderMap(undefined);
    }
  }, [fieldValue]);

  useEffect(() =>
  {
    if(!fieldValue?.argBinderMap)
    {
      setArgBinderMap(undefined);
    }
  }, [fieldValue?.argBinderMap]);

  useEffect(() =>
  {
    if(argVarId)
    {
      if(argBinderMap)
      {
        onChange({
          argVarId: argVarId,
          argBinderMap: argBinderMap
        });
      }
      else
      {
        onChange({
          argVarId: argVarId
        });
      }
    }
    else
    {
      onChange(null);
    }
  }, [argVarId, argBinderMap]);

  useEffect(() =>
  {
    let timer = setTimeout(() =>
    {
      if(paramSet && argBinderMap)
      {
        if(!isEqual(paramSet, argBinderMap.keys))
        {
          setArgBinderMap(undefined);
        }
      }
    }, theme.common.durationTransition);

    return () => clearTimeout(timer);

  }, [argBinderMap, paramSet]);

  useEffect(() =>
  {
    const variableKind = variable?.kind;
    if(variableKind === "text" || variableKind === "paragraph" || variableKind === "html")
    {
      const variableText = variable as StudioVarText | StudioVarParagraph | StudioVarHtml;
      setParamSet(variableText.value?.paramSet);
    }
    else
    {
      setParamSet(undefined);
    }
  }, [variable]);

  useEffect(() =>
  {
    if(argBinderMap)
    {
      const _resolveStatus = getResolveStatus(argBinderMap, paramSet);
      setResolveStatus(_resolveStatus);
    }
    else
    {
      setResolveStatus(false);
    }
  }, [argBinderMap, paramSet]);

  useEffect(() =>
  {
    if(focusField?.key === fieldKey)
    {
      formCtx.setFocusField({
        ...focusField,
        key: fieldKey
      });
    }
  }, [fieldKey, focusField, formCtx]);

  return (
    <LayoutFlexRow
      width={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
      alignItems={"start"}
    >
      <FieldRawStudioPickVarId
        fieldProps={{
          field: {
            ...props.fieldProps.field,
            onChange: onChangeVarId,
            value: fieldValue?.argVarId
          },
          fieldState: props.fieldProps.fieldState
        }}
        defn={{
          ...defnComp,
          type: "pickVarId",
          varKindSet: filterVarKindSet
        } as DefnStudioPickVarId}
        key={fieldKey}
        rightIcon={
          (paramSet && paramSet.length > 0 && formStore)
            ? {
              color: resolveStatus ? "success" : "warning",
              cbOnClick: () => onClickIcon(paramSet, formStore)
            }
            : undefined
        }
        formId={formId}
      />

    </LayoutFlexRow>
  );
}
