import {useState} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdFieldDynamicRule} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDynamicCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfDynamicCondition";
import {DefnStudioMapOfDynamicRule} from "../../../../api/meta/base/dto/DefnStudioMapOfDynamicRule";
import {StudioDtoDynamicRule} from "../../../../api/meta/base/dto/StudioDtoDynamicRule";
import {StudioMapOfDynamicRule} from "../../../../api/meta/base/dto/StudioMapOfDynamicRule";
import {DynamicTypeRule} from "../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdFieldDynamicRule} from "../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {MetaIdVisibilityCondition} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnResolveConditionNode} from "../../../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IFormRef} from "../../../../base/types/TypesForm";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import PaneFooter from "../../../atom/pane/PaneFooter";
import PaneSide from "../../../atom/pane/PaneSide";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {fieldGap} from "../../builder/base/TypesFormBuilder";
import {getFieldGap} from "../../builder/base/TypesFormBuilder";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import Form from "../Form";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfDynamicRule(props: {
  defn: DefnStudioMapOfDynamicRule,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const formId = defn.formId;
  const compositeIdSet = defn.compositeIdSet;
  const formStore = formCtx.getStore();
  const readonly = formCtx.isReadonly();

  const label = defn.label;

  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfDynamicRule;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfDynamicRule
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  onChange={onChange}
                  isFormReadOnly={readonly}
                  formId={formId}
                  compositeIdSet={compositeIdSet}
                  formStore={formStore}
                />
              }
            />
          );
        }

        return (
          <RawSetOfDynamicRule
            fieldId={fieldId}
            fieldValue={fieldValue}
            onChange={onChange}
            isFormReadOnly={readonly}
            formId={formId}
            compositeIdSet={compositeIdSet}
            formStore={formStore}
          />
        );
      }
      }
    />
  );
}

function RawSetOfDynamicRule(props: {
  fieldId: MetaIdField,
  formId?: MetaIdForm,
  compositeIdSet?: MetaIdComposite[],
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  fieldValue?: StudioMapOfDynamicRule,
  onChange: (value: StudioMapOfDynamicRule) => void
})
{
  const {
    formStore,
    fieldId,
    formId,
    isFormReadOnly
  } = props;

  const formCtx = useFormCtx();
  const cbRefList = {} as IFieldRawListRef<StudioDtoDynamicRule>;
  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);

  const fnResolve = formStore ? fnUseStudioResolver(formStore) : undefined;

  const onClickForm = formCtx.getOnClick();

  const onChange = (fieldValue: StudioDtoDynamicRule[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = useCallback((selectList: SelectList, fieldValue: StudioDtoDynamicRule[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const secondary = value.conditionNode && formId && fnResolve
          ? fnResolveConditionNode(value.conditionNode, formId, fnResolve)
          : undefined;

        const listId = value.metaId;

        if(listId)
        {
          uiItemIds.push(listId);
          uiItemsById[listId] = {
            type: "ps",
            primary: {
              text: value.name,
              caption: {
                text: toLabel(value.fieldType),
                ignoreSelection: true
              }
            },
            secondary: {
              text: secondary
            },
            hideMenu: isFormReadOnly,
            userField: {
              [fieldListItemVal]: value
            }
          } as IListItemAPSA;
        }
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  }, [fnResolve, formId]);

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      onClickForm && onClickForm(fieldId, "overlay", undefined, undefined, undefined,
        <DynamicRuleOverlay
          onClickSubmit={(value) =>
          {
            cbRefList.addItem({
              ...value,
              metaId: nextMetaIdFieldDynamicRule()
            });
          }}
          onClickBack={() => onClickForm(fieldId, "overlay", undefined, undefined, undefined, undefined)}
          formId={formId}
          formStore={formStore}
        />);
    }
  };

  const onClickList: CbOnClickFormList = (
    metaUd: MetaIdFieldDynamicRule,
    action: FormClickVariant,
    value: any
  ) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioDtoDynamicRule
        : undefined;

      if(oldValue)
      {
        onClickForm &&
        onClickForm(fieldId, "overlay", undefined, undefined, undefined,
          <DynamicRuleOverlay
            onClickSubmit={(value) =>
            {
              cbRefList.updateItem({
                ...value,
                metaId: metaUd
              }, metaUd);
            }}
            onClickBack={() => onClickForm(fieldId, "overlay", undefined, undefined, undefined, undefined)}
            formId={formId}
            value={oldValue}
            formStore={formStore}
            isFormReadOnly={isFormReadOnly}
          />);
      }
    }
  };

  return (
    <FieldRawList
      isLastField={false}
      fieldId={fieldId}
      cbRef={cbRefList}
      fieldValue={fieldValue ?? []}
      disableSpotMenu={isFormReadOnly}
      onChange={onChange}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(map?: StudioMapOfDynamicRule): StudioDtoDynamicRule[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoDynamicRule[]): StudioMapOfDynamicRule
{
  const keys = [] as MetaIdVisibilityCondition[];
  const map = {} as Record<MetaIdVisibilityCondition, StudioDtoDynamicRule>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfDynamicRule;
}

const fieldRuleName = "ruleName";
const fieldFieldType = "fieldType";
const fieldDynamicCondition = "conditionNode";

function DynamicRuleOverlay(props: {
  value?: StudioDtoDynamicRule,
  formId?: MetaIdForm,
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  onClickSubmit: (value: StudioDtoDynamicRule) => void
  onClickBack: () => void,
})
{
  const onClickBack = props.onClickBack;
  const onClickSubmit = props.onClickSubmit;
  const isFormReadOnly = props.isFormReadOnly;

  const formId = props.formId;
  const cbRef = {} as IFormRef;

  const [submitEnable, setSubmitEnable] = useState(false);

  const onSubmit = useCallback((values: FieldValues) =>
  {
    const dto = valueToDto(values);
    dto && onClickSubmit(dto);
  }, [onClickSubmit]);

  return <PaneSide
    primaryText={""}
    height={"100%"}
    actionIcon={"backIos"}
    onClickAction={() => onClickBack()}
  >
    <Form
      cbRef={cbRef}
      defnForm={getDynamicRuleDefn(formId)}
      initValues={dtoToValue(props.value)}
      onSubmitEnable={setSubmitEnable}
      store={props.formStore}
      onSubmit={onSubmit}
      formReadonly={isFormReadOnly}
    />

    <PaneFooter
      fabButton={{
        position: "right",
        icon: "check",
        disable: !submitEnable || isFormReadOnly
      }}
      onFabClick={() =>
      {
        cbRef.remoteSubmit();
        onClickBack();
      }}
    />
  </PaneSide>;
}

function getDynamicRuleDefn(formId?: MetaIdForm)
{
  return createDefaultDefnFormStudio({
    [fieldRuleName]: {
      type: "symbol",
      name: fieldRuleName,
      metaId: fieldRuleName,
      label: "Rule name",
      required: true
    } as DefnFieldText,
    [fieldFieldType]: {
      type: "pickText",
      name: fieldFieldType,
      metaId: fieldFieldType,
      label: "Field type",
      required: true,
      optionMap: arrayToMapOfOption(DynamicTypeRule)
    } as DefnFieldPickText,

    ...getFieldGap(fieldGap, "thick"),

    [fieldDynamicCondition]: {
      type: "studioMapOfDynamicCondition",
      formId: formId,
      name: fieldDynamicCondition,
      label: "Condition",
      metaId: fieldDynamicCondition
    } as DefnStudioMapOfDynamicCondition,
    [defaultSectionKey]: {
      type: "section",
      name: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldRuleName, fieldFieldType, fieldGap, fieldDynamicCondition]
    } as DefnSection
  });
}

function valueToDto(values: FieldValues)
{
  if(!values)
  {
    return;
  }

  return {
    name: fnFieldValueToRawValue("symbol", values[fieldRuleName]),
    fieldType: fnFieldValueToRawValue("pickText", values[fieldFieldType]),
    conditionNode: fnFieldValueToRawValue("studioMapOfDynamicCondition", values[fieldDynamicCondition])
  } as StudioDtoDynamicRule;
}

function dtoToValue(dto?: StudioDtoDynamicRule)
{
  if(!dto)
  {
    return;
  }

  return {
    [fieldRuleName]: fnRawValueToFieldValue("symbol", dto.name),
    [fieldFieldType]: fnRawValueToFieldValue("pickText", dto.fieldType),
    [fieldDynamicCondition]: fnRawValueToFieldValue("studioMapOfDynamicCondition", dto.conditionNode)
  };
}
