import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdPipelineVar} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfPipelineVariable} from "../../../../api/meta/base/dto/DefnStudioMapOfPipelineVariable";
import {StudioEntPipelineVar} from "../../../../api/meta/base/dto/StudioEntPipelineVar";
import {StudioEntPipelineVarMap} from "../../../../api/meta/base/dto/StudioEntPipelineVarMap";
import {MetaIdPipelineVar} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogMapOfPipelineVariable from "../../../dialog/DialogMapOfPipelineVariable";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfPipelineVariable(props: {
  defn: DefnStudioMapOfPipelineVariable
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();

  const isLastField = false;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const label = defn.label;

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioEntPipelineVarMap;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawStudioMapOfPipelineVariable
                  fieldId={fieldId}
                  formStore={formStore}
                  isFormReadOnly={formReadOnly}
                  onChange={onChange}
                  isLastField={isLastField}
                  fieldValue={fieldValue}
                />
              }
            />
          );
        }

        return (
          <RawStudioMapOfPipelineVariable
            fieldId={fieldId}
            formStore={formStore}
            isFormReadOnly={formReadOnly}
            onChange={onChange}
            isLastField={isLastField}
            fieldValue={fieldValue}
          />
        );
      }
      }
    />
  );
}

function RawStudioMapOfPipelineVariable(props: {
  fieldId: MetaIdField,
  fieldValue: StudioEntPipelineVarMap,
  isFormReadOnly?: boolean,
  formStore?: FormStore,
  isLastField?: boolean,
  onChange: (value: StudioEntPipelineVarMap) => void
})
{
  const {
    fieldId,
    formStore,
    isLastField,
    isFormReadOnly
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<StudioEntPipelineVar>;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: StudioEntPipelineVar[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = (selectList: SelectList, fieldValue: StudioEntPipelineVar[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.metaId;
        uiItemIds.push(listId);

        const name = value.name;
        const formName = value.formId && formStore?.formMap?.map[value.formId]?.details.name;

        uiItemsById[listId] = {
          type: "p",
          primary: {
            text: name,
            caption: {
              text: formName
            }
          },
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemAPSA;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogMapOfPipelineVariable
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) =>
          {
            cbRefList.addItem({
                ...newValue,
                metaId: nextMetaIdPipelineVar()
              }
            );
          }}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioEntPipelineVar
        : undefined;

      oldValue && pageCtx.showDialog(
        <DialogMapOfPipelineVariable
          values={oldValue}
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) =>
            cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)}
          validationError={validationError}
        />
      );
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(pipelineVarMap?: StudioEntPipelineVarMap): StudioEntPipelineVar[]
{
  if(!pipelineVarMap?.keys)
  {
    return [];
  }

  return pipelineVarMap.keys.map((key) =>
  {
    return {
      name: pipelineVarMap.map[key].name,
      formId: pipelineVarMap.map[key].formId,
      metaId: pipelineVarMap.map[key].metaId
    };
  });
}

function convertArrayToMap(arr: StudioEntPipelineVar[]): StudioEntPipelineVarMap
{
  const keys = [] as string[];
  const map = {} as Record<MetaIdPipelineVar, StudioEntPipelineVar>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    if(key)
    {
      keys.push(key);
      map[key] = value;
    }
  });

  return {
    keys: keys,
    map: map
  } as StudioEntPipelineVarMap;
}
