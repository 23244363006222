import {SettingsRounded} from "@mui/icons-material";
import {RemoveRedEye} from "@mui/icons-material";
import {LockRounded} from "@mui/icons-material";
import {TableChartRounded} from "@mui/icons-material";
import {FunctionsRounded} from "@mui/icons-material";
import {CalculateRounded} from "@mui/icons-material";
import {EditRounded} from "@mui/icons-material";
import PaymentsIcon from "@mui/icons-material/Payments";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import React from "react";
import {stripIconButtonSx} from "../../../base/plus/ThemePlus";

export type EnumIconFormBuilder =
  | "edit"
  | "calculate"
  | "formula"
  | "lock"
  | "preview"
  | "visibility"
  | "contentLayout"
  | "pipe"
  | "payment"
  | "settings"

export default function IconForm(props: {
  iconButton: EnumIconFormBuilder,
  color?: string,
})
{
  const sx = stripIconButtonSx(props.color);
  const iconButton = props.iconButton;
  if(iconButton === undefined)
  {
    return null;
  }

  switch(iconButton)
  {
    case "edit":
      return <EditRounded sx={sx} />;
    case "calculate":
      return <CalculateRounded sx={sx} />;
    case "formula":
      return <FunctionsRounded sx={sx} />;
    case "contentLayout":
      return <TableChartRounded sx={sx} />;
    case "lock":
      return <LockRounded sx={sx} />;
    case "visibility":
      return <RemoveRedEye sx={sx} />;
    case "preview":
      return <PlayCircleFilledIcon sx={sx} />;
    case "payment":
      return <PaymentsIcon sx={sx} />;
    case "settings":
      return <SettingsRounded sx={sx} />;
  }
}
