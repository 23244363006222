import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {MuiAvatarFontSize} from "../../../base/plus/ThemePlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import {TypeTooltip} from "../../../base/types/TypesGlobal";
import {EnumIconAvatar} from "../../../base/types/TypesIcon";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawDot from "../raw/RawDot";
import AvatarAtom from "./AvatarAtom";

export default function AvatarDot(props: {
  icon?: EnumIconAvatar,
  text?: string,
  tooltip?: TypeTooltip,
  src?: string,
  bgcolor?: CssBackgroundColor,
  color?: string,
  isScrolling?: boolean,
  onClick?: () => void,
  ml?: number,
  mr?: number,
  avatarSize?: StandardCSSProperties["width"],
  iconSize?: StandardCSSProperties["width"],
  iconFontSize?: MuiAvatarFontSize;
  dotColor: TypeTextColor
})
{

  return (
    <Box>
      <AvatarAtom {...props} />
      <LayoutFlexRow
        mt={"4px"}
      >
        <RawDot
          ml={props.ml}
          mr={props.mr}
          color={props.dotColor}
        />
      </LayoutFlexRow>
    </Box>
  );
}
