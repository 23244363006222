import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdVisibilityRule} from "../../api/meta/base/ApiPlus";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {StudioVisibilityRule} from "../../api/meta/base/dto/StudioVisibilityRule";
import {MetaIdField} from "../../api/meta/base/Types";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {fieldGap1, fieldKeyName, getFieldGap} from "../form/builder/base/TypesFormBuilder";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export default function DialogNewFormVisibilityRule(props: {
  onClickOk: (value: StudioVisibilityRule) => void,
  values?: StudioVisibilityRule,
  onClose?: () => void,
})
{

  const onClickSubmit = props.onClickOk;
  const value = props.values;

  return (
    <DialogDefnForm
      title={`${value ? "Update" : "New"} form visibility rule`}
      formProps={{
        defnForm: getDefnForm(),
        initValues: dtoToValue(value),
        onSubmit: values => onClickSubmit(valueToDto(values))
      }}
      addMoreCheckBoxLabel={!value
        ? "Add more form visibility rules"
        : undefined}
      onClose={props.onClose}
      contentHeight={225}
    />
  );
}

function valueToDto(values: FieldValues)
{
  return {
    name: values[fieldKeyName],
    metaId: nextMetaIdVisibilityRule(),
    actionMapIfTrue: {
      map: {},
      keys: []
    },
    actionMapIfFalse: {
      map: {},
      keys: []
    }
  } as StudioVisibilityRule;
}

function dtoToValue(dto?: StudioVisibilityRule)
{
  if(dto)
  {
    return {
      [fieldKeyName]: dto?.name
    };
  }
}

function getDefnForm()
{
  return createDefaultDefnFormStudio({
    ...getDefnDescriptionMap(),

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: [fieldKeyName]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}

function getDefnDescriptionMap()
{
  return {
    [fieldKeyName]: {
      type: "symbol",
      name: "Name",
      label: "Name",
      metaId: fieldKeyName,
      required: true
    } as DefnFieldText,
    ...getFieldGap(fieldGap1)
  };
}
