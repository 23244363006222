import {useMemo} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {FieldDtoGridRow} from "../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueGrid} from "../../../api/meta/base/dto/FieldValueGrid";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {publicRole} from "../../../base/plus/FormPlus";
import {filterForm} from "../../../base/plus/FormPlus";
import {isProd} from "../../../base/plus/SysPlus";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {ICallerEnt} from "../../../cache/app/callerEnt/TypesCacheCallerEnt";
import {useAppSelector} from "../../../nucleus/app/AppHooks";
import helperTextData from "../../../nucleus/atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../../../nucleus/atom/raw/RawNothingHere";
import UiReportBr from "./UiReportBr";
import UiSsBr from "./UiSsBr";

export default function UiRefBr(props: {
  entId: EntId,
  defnForm: DefnForm,
  refFieldId: MetaIdField;
  compositeId: MetaIdComposite;
  isMultiPick: boolean;
  formValue?: FormValue,        // for single select
  gridValue?: FieldValueGrid,     // for multi select
  enableSelection?: boolean,
  gridRow?: FieldDtoGridRow,
  cbOnSubmit?: (value: FieldValues) => void,
  cbOnClickListItem?: (rowId: RowId, spreadSheetId: MetaIdSpreadsheet, listName: string, selected?: boolean) => void,
})
{
  const entId = props.entId;
  const refFieldId = props.refFieldId;
  const defnForm = props.defnForm;
  const refField = useMemo(() => defnForm?.compMap[refFieldId], [defnForm]);
  const callerEnt = useAppSelector(state => selectCallerEnt(state, entId)) as ICallerEnt | undefined;

  const _filterForm = useMemo(() =>
  {
    return filterForm(defnForm, callerEnt?.roleIdSet || [publicRole], callerEnt);
  }, [callerEnt, defnForm]);

  if(refField?.type === "refReport" || refField?.type === "pickReportRow")
  {
    return <UiReportBr
      {...props}
      defnForm={_filterForm}
    />;
  }
  if(refField?.type === "ref")
  {
    return <UiSsBr
      {...props}
      defnForm={_filterForm}
    />;
  }

  if(!isProd())
  {
    return <RawNothingHere
      helperTextData={{
        title: "UiRefBr: unknown refField type",
        subtitle: `entId: ${entId}, refFieldId: ${refFieldId}, refField: ${JSON.stringify(refField)}`
      }}
    />;
  }

  return <RawNothingHere helperTextData={helperTextData.nothingToShow} />;
}
