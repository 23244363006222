import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

interface ICurrency
{
  code: string;
  decimal_digits: number;
  name: string;
  name_plural: string;
  rounding: number;
  symbol: string;
  symbol_native: string;
}

export default function FieldPickCurrency(props: {
  defn: DefnFieldPickEnum
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const filterOptionSet = defn.filterOptionSet;
  const fieldId = getFieldKey(defn);

  const currencies = require("../../../atom/assets/Currencies.json");

  let options = [] as DefnDtoOption[];

  if(currencies.length > 0)
  {
    currencies.forEach((currency: ICurrency) =>
    {
      options.push({
        metaId: currency.code,
        value: `${currency.code} - ${currency.name}`
      });
    });
  }

  if(filterOptionSet)
  {
    options = options.filter(option => filterOptionSet.includes(option.metaId));
  }

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
        <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={props.defn}
          optionMap={optionsToMapOfOption(options)}
        />
      }
    />
  );
}
