import {useTheme} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {useAppSelector} from "../../app/AppHooks";
import RawToast from "../raw/RawToast";

export function ToastNetworkOn()
{
  const flagNetworkOn = useAppSelector(state => state.app.status.flagNetworkOn);
  const flagBearerToken = useAppSelector(state => state.app.auth.flagBearerToken);
  const [canShowToast, setCanShowToast] = useState(false);
  const theme = useTheme();

  useEffect(() =>
  {
    const timer = setTimeout(() => setCanShowToast(!flagNetworkOn), theme.common.durationLoader);
    return () => clearTimeout(timer);
  }, [flagNetworkOn]);

  if(flagNetworkOn || !canShowToast || !flagBearerToken)
  {
    return null;
  }
  else
  {
    return (
      <RawToast value={"Computer not connected"} bgcolor={"warning"} />
    );
  }
}
