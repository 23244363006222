// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgCliGetCode} from './msg/MsgCliGetCode';
import {ServiceName} from '../../meta/base/Types';
import {SigCliGetCode} from './sig/SigCliGetCode';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcCli
{
  static readonly SN: ServiceName = "cli";

  static getCliCode(msg: MsgCliGetCode, sigAcceptor: ISigAcceptor<SigCliGetCode>): void
  {
    rpcCall<SigCliGetCode>(ENT_ID_GLOBAL, RpcCli.SN, "getCliCode")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }
}