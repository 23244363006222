import {ArcElement} from "chart.js";
import {ChartDataset} from "chart.js";
import {ChartOptions} from "chart.js";
import {Legend} from "chart.js";
import {Tooltip} from "chart.js";
import {Chart as ChartJS} from "chart.js";
import {useMemo} from "react";
import {Pie} from "react-chartjs-2";
import {IChart} from "../../../base/types/TypeCharts";
import useXYChart from "./ChartPlus";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ChartPie(props: IChart)
{
  const {
    getOptions,
    labels,
    getDataPieAndDoughnut
  } = useXYChart(props);

  const dataSet = useMemo(() => getDataPieAndDoughnut(), [getDataPieAndDoughnut]);
  const layout = props.layout;

  return <Pie
    options={getOptions(props.allowAnimation, layout) as ChartOptions<"pie">}
    data={{
      labels: labels,
      datasets: dataSet as ChartDataset<"pie">[]
    }}
  />;
}

