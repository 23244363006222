import {FieldValues} from "react-hook-form/dist/types/fields";
import {StudioFieldPaymentStatus} from "../../../../../api/meta/base/dto/StudioFieldPaymentStatus";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";

export function getDefnFieldPaymentStatus(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "paymentStatus",
      formId,
      undefined,
      fieldId,
      fieldIds,
      undefined,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    )
  };
}

export function defnValueToStudioFieldPickPaymentStatus(values: FieldValues): StudioFieldPaymentStatus
{
  return {
    type: "paymentStatus",
    defaultValue: fnFieldValueToRawValue("paymentStatus", values[propKeyDefaultValue]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId])
  } as StudioFieldPaymentStatus;
}

export function studioFieldPickPaymentStatusToDefnValue(studioField: StudioFieldPaymentStatus): FieldValues
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("paymentStatus", studioField.defaultValue),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId)
  };
}
