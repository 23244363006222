import {useTheme} from "@mui/material";
import React from "react";
import {DefnSection} from "../../../../../api/meta/base/dto/DefnSection";
import {getDefnFieldLabel} from "../../../../../base/plus/FormPlus";
import {px} from "../../../../../base/plus/StringPlus";
import LayoutFlexRow from "../../../layout/LayoutFlexRow";
import RawHighlighter from "../../../raw/RawHighlighter";

export function BubbleRawFormSection(props: {
  section: DefnSection
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const section = props.section;
  const label = getDefnFieldLabel(section);

  if(section.hidden || section.invisible)
  {
    return null;
  }

  return (
    <LayoutFlexRow
      width={"100%"}
      justifyContent={"flex-start"}
      mt={px(gapHalf)}
    >
      <RawHighlighter
        variant={"body2"}
        color={"textPrimary"}
        value={label}
      />
    </LayoutFlexRow>
  );
}
