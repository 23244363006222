import ISrvc from "../../../../base/ISrvc";
import SrvcHomeAsideUserInfoActions from "./SrvcHomeAsideUserInfoActions";
import SrvcHomeAsideUserInfoCommonGroup from "./SrvcHomeAsideUserInfoCommonGroup";

export default class SrvcHomeAsideUserInfo extends ISrvc
{
  public readonly commonGroup = new SrvcHomeAsideUserInfoCommonGroup();
  public readonly actions = new SrvcHomeAsideUserInfoActions();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.commonGroup,
      this.actions
    );
  }
}

