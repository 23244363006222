import {DtoMessagePayloadSpreadsheetPartition} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetPartition";
import {DtoMessagePayloadSpreadsheetRow} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {EnumMessageType} from "../../../api/home/base/Types";
import {MsgEntFilter} from "../../../api/home/drawer/msg/MsgEntFilter";
import {WsocDrawer} from "../../../api/home/drawer/WsocDrawer";
import {MsgOffset} from "../../../api/home/main/msg/MsgOffset";
import {SigMessage} from "../../../api/home/main/sig/SigMessage";
import {WsocMain} from "../../../api/home/main/WsocMain";
import {EntUserId} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {MessageId} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {getMsgPayloadSpreadsheetId} from "../../../base/plus/BubblePlus";
import {getMsgPayloadRowId} from "../../../base/plus/BubblePlus";
import {formatCaptionDateTime} from "../../../base/plus/DatePlus";
import {isCommentableForm} from "../../../srvc/app/form/SrvcForm";
import {Srvc} from "../../../srvc/Srvc";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {selectCallerEnt} from "../callerEnt/SrvcCacheCallerEnt";
import {setStarMsgRowDeleted} from "./SliceCacheStarMsgList";
import {clearStarMsgList} from "./SliceCacheStarMsgList";
import {setStarMsgListBadgeCount} from "./SliceCacheStarMsgList";
import {setStarMsgFormResult} from "./SliceCacheStarMsgList";
import {setStarMsgList} from "./SliceCacheStarMsgList";
import {ICacheStarMessage} from "./TypesCacheStarMsgList";

const subscriberId = "SrvcCacheStarMsgList";

interface ISubscribeQueueItem
{
  entId: EntId,
  rowId: RowId,
  senderId: EntUserId,
  formId?: MetaIdForm
}

export default class SrvcCacheStarMsgList extends ISrvc
{
  private subscribeQueue = [] as ISubscribeQueueItem[];
  private currentTick = 0;
  private readonly maxTick = 3;

  wsocStarMessageListGet()
  {
    const rootState = store.getState();
    const version = rootState.cache.app.starMsg.version;

    const msg: MsgEntFilter = {
      version: version
    };

    WsocDrawer.starMessageListGet(msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        if(sig.version !== version)
        {
          const newMsgMap = {} as Record<MessageId, ICacheStarMessage>;
          const msgIdList = [] as MessageId[];
          const rootState = store.getState();
          const starMsgMap = rootState.cache.app.starMsg.starMsgMap;

          Promise.all(sig.starMessageList.map((msg) =>
          {
            return new Promise<void>((resolve) =>
            {
              msgIdList.push(msg.messageId);
              if(starMsgMap[msg.messageId] === undefined)
              {
                wsocMessageGet(msg.entId, msg.chatId, msg.messageOffset, (sigMessage) =>
                {
                  if(sigMessage)
                  {
                    newMsgMap[msg.messageId] = {
                      ...sigMessage,
                      entId: msg.entId,
                      chatId: msg.chatId,
                      creationDate: formatCaptionDateTime(new Date(msg.creationDate))
                    };
                    const senderId = sigMessage.senderId;
                    const messageType = sigMessage.payload.messageType;
                    if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition")
                    {
                      const callerEnt = selectCallerEnt(store.getState(), msg.entId);
                      if(!callerEnt)
                      {
                        Srvc.app.pubsub.caller.subCallerEnt(msg.entId);
                      }
                      const rowId = getMsgPayloadRowId(sigMessage.payload);
                      const formId = (sigMessage.payload as DtoMessagePayloadSpreadsheetRow | DtoMessagePayloadSpreadsheetPartition).formId;
                      if(rowId)
                      {
                        this.subStarMsgRowId(msg.entId, rowId, senderId, formId);
                      }
                    }
                  }
                  resolve();
                });
              }
              else
              {
                newMsgMap[msg.messageId] = starMsgMap[msg.messageId];
                resolve();
              }
            });
          })).then(() =>
          {
            store.dispatch(setStarMsgList({
              starMsgMap: newMsgMap,
              msgIdList: msgIdList,
              version: sig.version
            }));
          });
        }
      }
    });
  }

  clearStarMessageListGet()
  {
    store.dispatch(clearStarMsgList());
  }

  getStarMsgListBadgeCount()
  {
    store.dispatch((dispatch, getState) =>
    {
      const rootState = getState();
      let badgeCount = 0;
      const msgIdList = rootState.cache.app.starMsg.starMsgList;
      const filter = rootState.cache.home.drawer.filter.entIdSet;
      msgIdList.forEach((msgId) =>
      {
        const msg = rootState.cache.app.starMsg.starMsgMap[msgId];
        if(filter.length === 0 || filter.includes(msg.entId))
        {
          const messageType = msg.payload.messageType;
          if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition")
          {
            const formResult = (msg.payload as DtoMessagePayloadSpreadsheetRow | DtoMessagePayloadSpreadsheetPartition).spreadsheetRow;
            if(formResult)
            {
              const fieldCount = formResult.updatedKeySet?.length;
              if(fieldCount && fieldCount > 0)
              {
                badgeCount++;
              }
              badgeCount +=
                formResult.rowCommentCount?.unreadCommentCount ? formResult.rowCommentCount?.unreadCommentCount : 0;
            }
          }
        }
      });
      dispatch(setStarMsgListBadgeCount(badgeCount));
    });
  }

  setStarMsgSpreadsheetRow(entId: EntId, rowId: RowId, forced?: boolean)
  {
    const rootState = store.getState();
    const msgId = rootState.cache.app.starMsg.rowIdMap[rowId];
    const starFormMsg = rootState.cache.app.starMsg.starMsgMap[msgId];
    if(starFormMsg)
    {
      const messageType = starFormMsg.payload.messageType as EnumMessageType;
      if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition")
      {
        const spreadsheetId = getMsgPayloadSpreadsheetId(starFormMsg.payload);
        if(spreadsheetId)
        {
          const version = forced ? undefined :
            (starFormMsg.payload as DtoMessagePayloadSpreadsheetRow | DtoMessagePayloadSpreadsheetPartition).spreadsheetRow?.version;
          Srvc.app.spreadsheet.wsocSpreadsheetRowGet(entId, rowId, spreadsheetId, version, sig =>
          {
            Promise.resolve(store.dispatch(setStarMsgFormResult({
              msgId: msgId as MessageId,
              spreadsheetRow: sig
            }))).then(() =>
            {
              this.getStarMsgListBadgeCount();
            });
          }, error =>
          {
            const errorNotFound = error.validationErrors?.find((error) =>
              error.errorCode === "notFound");
            store.dispatch((dispatch, getState) =>
            {
              const rootState = getState();
              const messageType = rootState.cache.app.starMsg.starMsgMap[msgId as MessageId].payload.messageType;
              if(errorNotFound && messageType !== "spreadsheetPartition")
              {
                Promise.resolve(dispatch(setStarMsgRowDeleted({
                  msgId: msgId as MessageId
                }))).then(() =>
                {
                  this.getStarMsgListBadgeCount();
                });
              }
            });
          });
        }
      }
    }
  }

  subStarMsgRowId(entId: EntId, rowId: RowId, senderId: EntUserId, formId?: MetaIdForm)
  {
    const rootState = store.getState();
    const callerEnt = selectCallerEnt(rootState, entId);
    if(callerEnt)
    {
      subStarMsgRowId(rootState, entId, rowId, senderId, formId);
    }
    else
    {
      this.subscribeQueue.push({
        entId: entId,
        rowId: rowId,
        senderId: senderId,
        formId: formId
      });
    }

  }

  protected doTick(dateNow: Date)
  {
    if(this.currentTick >= this.maxTick)
    {
      const length = this.subscribeQueue.length;
      if(length > 0)
      {
        this.subscribeQueue.forEach((value) =>
        {
          this.subStarMsgRowId(value.entId, value.rowId, value.senderId, value.formId);
        });
        this.subscribeQueue = [];
      }
    }
    else
    {
      this.currentTick++;
    }
  }

}

function subStarMsgRowId(rootState: RootState, entId: EntId, rowId: RowId, senderId: EntUserId, formId?: MetaIdForm)
{
  Srvc.app.pubsub.msg.formResult(subscriberId, entId, rowId);
  const canComment = isCommentableForm(rootState, entId, formId, senderId);
  if(canComment)
  {
    Srvc.app.pubsub.msg.formComment(subscriberId, entId, rowId);
  }
}

function wsocMessageGet(entId: EntId, chatId: ChatId, offset: number, cbSuccess?: (sigMessage?: SigMessage) => void)
{
  const msg = {
    chatId: chatId,
    offset: offset
  } as MsgOffset;

  WsocMain.messageGet(entId, msg, (envSig) =>
  {
    const sig = envSig?.sig;
    cbSuccess && cbSuccess(sig);
  });
}
