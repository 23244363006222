import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {isEmpty} from "lodash";
import {useMemo} from "react";
import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import Highlighter from "react-highlight-words";
import {fnRemovedMarkDownSymbols} from "../../../../base/plus/StringPlus";
import {removeSpecialChars} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {parseLinkText} from "../../../../base/plus/UrlPlus";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import RawQuickLink from "../../raw/RawQuickLink";

export default function BubbleRawLinkText(props: {
  value: string,
  searchWords?: string[],
  mentionMap?: Record<string, string> | undefined,
  maxCharLimit?: number,
  pt?: StandardCSSProperties["paddingTop"],
  pb?: StandardCSSProperties["paddingBottom"],
  fakeSpace?: React.ReactNode,
  caption?: React.ReactNode,
  onClickSendMessageToUser?: (mentionUser: string, menuAnchor: Element) => void,
  maxWidth?: number
})
{
  const maxCharLimit = props.maxCharLimit;
  const value = props.value;
  const [currText, setCurrText] = useState(value);
  const [isReadMore, setIsReadMore] = useState(false);
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const gapLeft = gapHalf - (theme.common.bubbleShellGap + theme.common.bubbleShellBorder);
  const gapRight = theme.common.gapHalf + theme.common.gapQuarter;
  const pt = props.pt;
  const pb = props.pb;
  const vertInner = theme.common.vertInner;
  const mentionMap = props.mentionMap;
  const mentionWords = useMemo(() =>
  {
    if(!isEmpty(mentionMap))
    {
      return [...Object.keys(mentionMap)] as string[];
    }
    else
    {
      return undefined;
    }
  }, [mentionMap]);

  const searchWords = props.searchWords;

  const onClickReadMore = () =>
  {
    setCurrText(value);
    setIsReadMore(false);
  };

  useEffect(() =>
  {
    if(maxCharLimit && currText.length > maxCharLimit)
    {
      setCurrText(currText.slice(0, maxCharLimit) + "...");
      setIsReadMore(true);
      return;
    }
  }, [value, maxCharLimit]);

  if(isReadMore === undefined && maxCharLimit && value.length > maxCharLimit)
  {
    setCurrText(value.slice(0, maxCharLimit) + "...");
    setIsReadMore(true);
  }

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        pt: pt ?? px(gapQuarter),
        pr: px(gapRight),
        pb: pb,
        pl: px(gapLeft),
        userSelect: "text",
        webkitUserSelect: "text",
        maxWidth: props.maxWidth
      }}
    >
      <LayoutFlexRow justifyContent={"start"}>
        <RealText
          textValue={currText}
          searchWords={searchWords}
          mentionWords={mentionWords}
          fakeSpace={props.fakeSpace}
          onClickSendMessageToUser={props.onClickSendMessageToUser}
        />
      </LayoutFlexRow>

      {isReadMore &&
        <RawQuickLink
          name={"Read more"}
          variant={"body2"}
          color={"success"}
          onClick={onClickReadMore}
        />}
      <Box
        component={"span"}
        justifyContent={"end"}
        sx={{
          position: "absolute",
          right: 0,
          bottom: -vertInner
        }}
      >
        {props.caption}
      </Box>
    </Box>
  );
};

function RealText(props: {
  textValue: string,
  searchWords?: string[],
  mentionWords?: string[],
  fakeSpace?: React.ReactNode,
  onClickSendMessageToUser?: (mentionUser: string, menuAnchor: Element) => void
})
{
  const textValue = props.textValue;
  const searchWords = props.searchWords;
  const mentionWords = props.mentionWords;
  const fakeSpace = props.fakeSpace;
  const onClickSendMessageToUser = props.onClickSendMessageToUser;
  const parseLink = true;

  return (
    <Typography
      component={"span"}
      variant={"body2"}
      noWrap={false}
      sx={{
        color: theme.common.color("textPrimary"),
        overflow: "hidden",
        overflowWrap: "anywhere",
        whiteSpace: "pre-wrap",
        WebkitUserSelect: "text"
      }}
    >
      <Box
        component={"span"}
        sx={{
          userSelect: "text",
          wordBreak: "break-word"
        }}
      >
        {
          (searchWords === undefined
            ? parseLinkText(textValue, mentionWords, onClickSendMessageToUser, parseLink)
            : (<Highlighter
              searchWords={searchWords.map(str => removeSpecialChars(str))}
              textToHighlight={fnRemovedMarkDownSymbols(textValue)}
              highlightStyle={{background: theme.common.bgcolorHighlight}}
            />))
        }
      </Box>

      <Box
        component={"span"}
        visibility={"hidden"}
      >
        {fakeSpace}
      </Box>
    </Typography>
  );
}

