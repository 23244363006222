import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import TimezoneArray from "../../../atom/assets/TimeZone.json";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickTimeZone(props: {
  defn: DefnFieldEditable;
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const options = getOption() as DefnDtoOption[];

  function getOption(): DefnDtoOption[]
  {
    return TimezoneArray.map((timeZone, index) =>
    {
      if(timeZone && timeZone.text && timeZone.utc && timeZone.utc.length)
      {
        return {
          metaId: `${timeZone.utc[0]}`,
          value: `${timeZone.text}`
        } as DefnDtoOption;
      }
      return {
        metaId: `${index}`,
        disabled: true,
        value: `Not found`
      } as DefnDtoOption;

    });

  }

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => (
        <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={props.defn}
          optionMap={optionsToMapOfOption(options)}
        />
      )}
    />
  );
}
