import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {random} from "../../../base/plus/StringPlus";
import {WidgetConfig} from "./TypesWidget";
import {IWidgetState} from "./TypesWidget";

export const sliceWidget = createSlice({
  name: "widget",
  initialState: {
    config: undefined,
    version: undefined
  } as IWidgetState,
  reducers: {
    setWidgetConfig: (state, action: PayloadAction<WidgetConfig>) =>
    {
      state.config = action.payload;
      state.version = random();
    }
  }
});

export const {
  setWidgetConfig
} = sliceWidget.actions;
