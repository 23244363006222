import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {SpreadsheetPartitionId} from "../../../api/meta/base/Types";
import {ChatId, EntId, MessageId, RowId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {serverPush} from "../../../base/plus/SrvcPlus";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcPubSubMsg extends ISrvc
{
  private formResultTopicQueue = new DebounceSigTopic(100);

  getMsgLastTopic(entId: EntId, chatId: ChatId)
  {
    return {
      aboutId: chatId,
      artifactId: entId,
      type: "messageLast"
    } as DtoTopic;
  }

  getMsgPropsTopic(entId: EntId, chatId: ChatId)
  {
    return {
      aboutId: chatId,
      artifactId: entId,
      type: "messageProps"
    } as DtoTopic;
  }

  // not calling subscription handler because we don't have message offset
  messageReceiptsChanged(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    messageId: MessageId,
    unsubscribe?: boolean)
  {
    const dtoTopic = {
      type: "messageReceiptsChanged",
      artifactId: entId,
      aboutId: messageId
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  messageLast(subscriberId: TypeSubscriberId, entId: EntId, chatId: ChatId, unsubscribe?: boolean): void
  {
    const dtoTopic = this.getMsgLastTopic(entId, chatId);

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  messageNew(subscriberId: TypeSubscriberId, entId: EntId, chatId: ChatId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: chatId,
      artifactId: entId,
      type: "messageNew"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  messageProps(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    chatId: ChatId,
    unsubscribe?: boolean): void
  {
    const dtoTopic = this.getMsgPropsTopic(entId, chatId);

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  message(subscriberId: TypeSubscriberId, entId: EntId, messageId: MessageId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      artifactId: entId,
      aboutId: messageId,
      type: "message"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  messageClearChat(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    chatId: ChatId,
    unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: chatId,
      artifactId: entId,
      type: "messageClearChat"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  formResult(
    subscriberId: string,
    entId: EntId,
    rowId: RowId,
    unsubscribeForce?: boolean,
    unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: rowId,
      artifactId: entId,
      type: "formResult"
    } as DtoTopic;

    if(Boolean(unsubscribe || unsubscribeForce))
    {
      !unsubscribeForce
        ? Srvc.app.session.unsubscribe(subscriberId, dtoTopic)
        : Srvc.app.session.unsubscribeForce(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  formComment(subscriberId: TypeSubscriberId, entId: EntId, rowId: RowId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: rowId,
      artifactId: entId,
      type: "formComment"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  spreadsheetRowExpiry(
    subscriberId: string,
    entId: EntId,
    spreadsheetPartitionId: SpreadsheetPartitionId,
    unsubscribeForce?: boolean,
    unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: spreadsheetPartitionId,
      artifactId: entId,
      type: "spreadsheetRowExpiry"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      !unsubscribeForce
        ? Srvc.app.session.unsubscribe(subscriberId, dtoTopic)
        : Srvc.app.session.unsubscribeForce(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  spreadsheet(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: spreadsheetId,
      artifactId: entId,
      type: "spreadsheet"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  handleSigTopic(sig: SigTopic, isOnSub?: boolean): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "messageReceiptsChanged":
        if(!isOnSub)
        {
          serverPush(sig, rootState => rootState.home.aside.messageInfoList);
        }
        return true;
      case "messageLast" :
        Srvc.cache.home.drawer.msgLast.wsocLastMessageGet(sig.artifactId, sig.aboutId as ChatId);
        return true;
      case "messageNew":
        //setCbSessionSigTopicMessageNew received in ent onSignal handler
        return true;
      case "messageProps":
        // setCbSessionSigTopicMessageProps received in ent onSignal handler
        return true;
      case "message":
        if(!isOnSub)
        {
          store.dispatch((_, getState) =>
            Srvc.getSrvcChatArray().forEach(srvc => srvc.handleSigTopicMessage(getState(), sig)));
          Srvc.cache.home.drawer.msgLast.handleSigTopicMessage(sig);
        }
        return true;
      case "messageClearChat":
        if(!isOnSub)
        {
          store.dispatch((_, getState) =>
            Srvc.getSrvcChatArray().forEach(srvc => srvc.handleSigTopicMessageClearChat(getState(), sig)));
        }
        return true;
      case "formResult":
        if(!isOnSub)
        {
          store.dispatch((_, getState) =>
            Srvc.getSrvcChatArray().forEach(srvc => srvc.handleSigTopicFormResult(getState(), sig)));
          Srvc.cache.app.spreadsheet.ssRow.setCacheSpreadsheetRow(sig.artifactId as EntId, sig.aboutId as RowId);
          Srvc.cache.app.startMsg.setStarMsgSpreadsheetRow(sig.artifactId, sig.aboutId as RowId);
        }
        return true;
      case "formComment":
        if(!isOnSub)
        {
          store.dispatch((_, getState) =>
            Srvc.getSrvcChatArray().forEach(srvc => srvc.handleSigTopicFormComment(getState(), sig)));
          Srvc.cache.app.startMsg.setStarMsgSpreadsheetRow(sig.artifactId, sig.aboutId as RowId, true);
          Srvc.cache.app.spreadsheet.ssRow.setCacheSpreadsheetRowComments(sig.artifactId as EntId,
            sig.aboutId as RowId
          );
        }
        return true;
      case "spreadsheetRowExpiry":
        if(!isOnSub)
        {
          store.dispatch((_, getState) =>
            Srvc.getSrvcChatArray().forEach(srvc => srvc.handleSigTopicSpreadsheetRowDurationExpiry(getState(), sig)));
          Srvc.cache.app.spreadsheet.ssRow
          .setCacheSpreadsheetRowExpiry(sig.artifactId as EntId, sig.aboutId as SpreadsheetPartitionId);
        }
        return true;
      case "spreadsheet":
        Srvc.cache.app.spreadsheet.ssEditor.setSsEditorRowMutation(sig.aboutId as MetaIdSpreadsheet);
        return true;
    }
    return false;
  }

  handleTopicUnSub(sig: SigTopic): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "messageReceiptsChanged":
        //ignore
        return true;
      case "messageLast" :
        Srvc.cache.home.drawer.msgLast.unsubscribe(sig.artifactId, sig.aboutId);
        return true;
      case "messageNew":
      case "messageProps":
      case "message":
      case "messageClearChat":
        //ignore
        return true;
      case "formResult":
        this.formResultTopicQueue.enqueue(sig);
        return true;
      case "formComment":
        //ignore
        return true;
      case "spreadsheetRowExpiry":
        Srvc.cache.app.spreadsheet.ssRow.removeCacheSpreadsheetRowExpiry(sig.artifactId as EntId,
          sig.aboutId as SpreadsheetPartitionId
        );
        return true;
      case "spreadsheet":
        Srvc.cache.app.spreadsheet.ssEditor.clearSsState(sig.aboutId as MetaIdSpreadsheet);
        return true;
    }
    return false;
  }

}

class DebounceSigTopic
{
  private queue: Set<SigTopic>;
  private isProcessing: boolean;
  private readonly throttleInterval: number;
  private timerId: ReturnType<typeof setTimeout> | null;

  constructor(throttleInterval: number)
  {
    this.queue = new Set<SigTopic>();
    this.isProcessing = false;
    this.throttleInterval = throttleInterval;
    this.timerId = null;
  }

  enqueue(dotTopic: SigTopic): void
  {
    this.queue.add(dotTopic);
    if(!this.isProcessing)
    {
      if(this.timerId)
      {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() =>
      {
        this.processQueue();
      }, this.throttleInterval);
    }
  }

  private processQueue(): void
  {
    if(this.queue.size === 0)
    {
      this.isProcessing = false;
      return;
    }
    const queue = this.queue;
    this.queue = new Set<SigTopic>();
    const rowMap = {} as Record<EntId, RowId[]>;
    queue.forEach(value =>
    {
      if(!rowMap[value.artifactId])
      {
        rowMap[value.artifactId] = [value.aboutId];
      }
      else
      {
        rowMap[value.artifactId].push(value.aboutId);
      }
    });
    Object.entries(rowMap).forEach(([entId, rowIds]) =>
    {
      Srvc.cache.app.spreadsheet.ssRow.removeCacheSpreadsheetRows(entId, rowIds);
    });
    this.isProcessing = false;
  }
}
