// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgEntVariableUpdate} from './msg/MsgEntVariableUpdate';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {ServiceName} from '../../meta/base/Types';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigEntAvatarUser} from './sig/SigEntAvatarUser';
import {SigEntCaller} from './sig/SigEntCaller';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocEntDrawer
{
  static readonly SN: ServiceName = "entDrawer";

  static entAvatarUserGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigEntAvatarUser>): void
  {
    wsocCall<SigEntAvatarUser>(entId, WsocEntDrawer.SN, "entAvatarUserGet")
      .get(msg, sigAcceptor);
  }

  static entCallerGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigEntCaller>): void
  {
    wsocCall<SigEntCaller>(entId, WsocEntDrawer.SN, "entCallerGet")
      .get(msg, sigAcceptor);
  }

  static entCallerVariableUpdate(entId: EntId, msg: MsgEntVariableUpdate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocEntDrawer.SN, "entCallerVariableUpdate")
      .put(msg, sigAcceptor);
  }
}