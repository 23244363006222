import {useCallback} from "react";
import React from "react";
import {FieldErrors} from "react-hook-form";
import {Controller} from "react-hook-form";
import {nextMetaIdSetOfUserCondition} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfUserCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfUserCondition";
import {DefnStudioMapOfVisibilityCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfVisibilityCondition";
import {StudioDtoUserConditionStatement} from "../../../../api/meta/base/dto/StudioDtoUserConditionStatement";
import {StudioMapOfUserCondition} from "../../../../api/meta/base/dto/StudioMapOfUserCondition";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewSetOfUsers from "../../../dialog/DialogNewSetOfUsers";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {IResolvedStatement} from "../raw/FieldRawTreeCondition";
import FieldRawTreeCondition from "../raw/FieldRawTreeCondition";

export default function FieldStudioMapOfUser(props: {
  defn: DefnStudioMapOfVisibilityCondition,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnFieldBuilderSetOfUsers = props.defn;
  const fieldId = getFieldKey(defnFieldBuilderSetOfUsers);
  const metaIdForm = defnFieldBuilderSetOfUsers.formId;
  const formStore = formCtx.getStore();
  const readonly = formCtx.isReadonly();

  const isLastField = false;
  const label = defnFieldBuilderSetOfUsers.label;

  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={defnFieldBuilderSetOfUsers.metaId}
      control={formCtx.control()}
      render={({
        field,
        formState: {errors}
      }) =>
      {
        const fieldValue = field.value as StudioMapOfUserCondition;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfUsers
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  onChange={onChange}
                  isLastField={isLastField}
                  isFormReadOnly={readonly}
                  metaIdForm={metaIdForm}
                  formStore={formStore}
                  defn={defnFieldBuilderSetOfUsers}
                  errors={errors}
                />
              }
            />
          );
        }

        return (
          <RawSetOfUsers
            fieldId={fieldId}
            fieldValue={fieldValue}
            onChange={onChange}
            isLastField={isLastField}
            isFormReadOnly={readonly}
            metaIdForm={metaIdForm}
            formStore={formStore}
            defn={defnFieldBuilderSetOfUsers}
            errors={errors}
          />
        );
      }}
    />
  );
}

function RawSetOfUsers(props: {
  defn: DefnStudioMapOfUserCondition,
  fieldId: MetaIdField,
  metaIdForm?: MetaIdForm,
  formStore?: FormStore,
  isLastField?: boolean,
  isFormReadOnly?: boolean,
  fieldValue?: StudioMapOfUserCondition,
  errors?: FieldErrors,
  onChange: (value: StudioMapOfUserCondition | null) => void
})
{
  const {
    formStore,
    defn,
    metaIdForm
  } = props;

  const readOnly = props.isFormReadOnly;

  const pageCtx = usePageCtx();
  const metaId = defn.metaId;

  const errors = props.errors?.[metaId] ? props.errors : undefined;
  const validationResult = formStore?.validationResult;

  const cbResolveStatement = useCallback((statement: StudioDtoUserConditionStatement) =>
  {
    const {kind, roleIdSet, fieldIdSet, userContextSet, derivedRoleIdSet} = statement;

    const fnResolver = formStore && fnUseStudioResolver(formStore);

    const roleSet = roleIdSet?.map(value => fnResolver?.getRoleName(value)) || [];
    const fieldSet = metaIdForm
      ? (fieldIdSet?.map(value => fnResolver?.getFormFieldName(metaIdForm, value)) || [])
      : [];

    const list = {} as IResolvedStatement;

    switch(kind)
    {
      case "roles":
        list.primary = roleSet.join(", ");
        break;
      case "fields":
        list.primary = fieldSet?.join(", ");
        break;
      case "context":
        list.primary = userContextSet?.join(", ") || "";
        break;
      default:
        return {primary: "", caption: "", secondary: ""};
    }

    list.caption = toLabel(kind);
    list.secondary = derivedRoleIdSet?.join(", ") || "";

    return list;

  }, [formStore, metaIdForm]);

  const cbCreateStatementNode = (
    item?: StudioMapOfUserCondition,
    cb?: (newItem: StudioDtoUserConditionStatement) => void) =>
  {
    if(formStore)
    {
      pageCtx.showDialog(<DialogNewSetOfUsers
        formStore={formStore}
        sourceFormId={metaIdForm}
        isFormReadOnly={readOnly}
        onClickOk={(values) =>
        {
          cb && cb(values);
        }}
        nodeId={item?.metaId}
        metaId={metaId}
        values={item?.statement as StudioDtoUserConditionStatement}
        validationResult={validationResult}
      />);
    }
  };

  return <FieldRawTreeCondition
    fieldValue={props.fieldValue}
    label={defn.label}
    isLastField={props.isLastField}
    isFormReadOnly={readOnly}
    errors={errors}
    cbCreateStatementNode={cbCreateStatementNode}
    cbResolveStatement={cbResolveStatement}
    onChange={props.onChange}
    cbGetMetaId={() => nextMetaIdSetOfUserCondition()}
  />;
}
