import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {useEffect} from "react";
import {useMemo} from "react";
import React from "react";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {px} from "../../../../base/plus/StringPlus";
import {IDataGridCellRendererParams} from "../../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import helperTextJsonData from "../../../atom/assets/PlaceholderTextHome.json";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import DataGridMasterDetailGrid from "../DataGridMasterDetail";

export default function GridCellMasterDetail<SR1, SR2, SR3, SR4, SR5, SR6>(props: IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const theme = useTheme();
  const api = props.api;
  const rowId = props.data?.rowId;
  const gridBinder = props.gridBinder;
  const valueMap = props.data?.valueMap;
  const layout = (props.context as AgGridContext).layout;
  const defnForm = (props.context as AgGridContext).defnForm;
  const searchWords = (props.context as AgGridContext).searchWords;
  const masterDetailGridIdSet = useMemo(() => layout?.masterDetailGridLayoutMap
    ? Object.keys(layout.masterDetailGridLayoutMap)
    : [], [layout?.masterDetailGridLayoutMap]);

  const isAnyGridExists = useMemo(() => masterDetailGridIdSet.some((gridId) =>
  {
    return Boolean((valueMap?.[gridId] as FieldValueGrid | undefined)?.keys.length);
  }), [masterDetailGridIdSet, valueMap]);

  useEffect(() =>
  {
    return () =>
    {
      if(!api.isDestroyed())
      {
        if(rowId)
        {
          api.removeDetailGridInfo(rowId);
        }
      }
    };
  }, []);

  if(!rowId || !isAnyGridExists || !defnForm || !layout || !valueMap || props.data?.expired)
  {
    return (
      <Box
        sx={{
          padding: px(theme.common.gapStd),
          pb: px(theme.common.gapStd),
          pt: px(theme.common.gapHalf),
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: px(theme.common.gapHalf)
        }}
      >
        <LayoutFlexCol
          height={px(100)}
          alignItems={"start"}
        >
          <RawNothingHere helperTextData={helperTextJsonData.nothingToShow} />
        </LayoutFlexCol>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: px(theme.common.gapStd),
        pb: px(theme.common.gapStd),
        pt: px(theme.common.gapHalf),
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        gap: px(theme.common.gapHalf)
      }}
    >
      {
        (masterDetailGridIdSet?.map((gridId) =>
        {
          const fieldValueGrid = (valueMap?.[gridId] as FieldValueGrid | undefined);
          const layoutId = layout?.masterDetailGridLayoutMap?.[gridId];
          if(!fieldValueGrid?.keys.length || !layoutId)
          {
            return null;
          }

          return <DataGridMasterDetailGrid
            key={gridId}
            defnForm={defnForm}
            gridId={gridId}
            value={fieldValueGrid}
            layoutId={layoutId}
            searchWords={searchWords}
            valueMap={valueMap}
            gridBinder={gridBinder}
          />;
        }))
      }
    </Box>
  );

}
