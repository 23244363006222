import {isEqual} from "lodash";
import {useMemo} from "react";
import {FieldSetOfEntUserId} from "../../../../api/meta/base/dto/FieldSetOfEntUserId";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {EnumDefnFields} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {textUser} from "../../../../base/plus/SrvcPlus";
import {getSystemFieldComp} from "../../../../base/plus/StudioFormPlus";
import {isSystemField} from "../../../../base/plus/StudioFormPlus";
import {CssColor} from "../../../../base/plus/ThemePlus";
import {IDataGridCellStyle} from "../../../../base/types/TypeDataGrid";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import {IDataGridCellRendererParams} from "../../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {isComboId} from "../../../../base/types/TypesComboId";
import {useAppSelector} from "../../../app/AppHooks";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {getResolvedCellStyle} from "../DataGridPlus";
import {GRID_FONT_VARIANT} from "../DataGridPlus";
import {TypeCapturedFieldValue} from "../DataGridPlus";
import {TypeCapturedValues} from "../DataGridPlus";

export default function GridCellUser<SR1, SR2, SR3, SR4, SR5, SR6>(props:
  IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6> & {
  color?: CssColor
})
{
  const colId = props.colDef?.colId;
  const searchWords = (props.context as AgGridContext).searchWords;
  const defnForm = (props.context as AgGridContext).defnForm;
  const field = useMemo(() => colId
    ? isSystemField(colId)
      ? getSystemFieldComp(colId as EnumDefnFields)
      : defnForm?.compMap[colId]
    : undefined, [colId]);
  const fieldType = field?.type;
  const rowId = props.data?.rowId;
  const value = useMemo(() => colId
    ? isSystemField(colId)
      ? getSystemFieldValue(colId as EnumDefnFields, props.data)
      : props.data?.valueMap[colId]
    : undefined, [colId, props.data?.valueMap]);

  const cellValue = props.valueFormatted
    ? props.valueFormatted
    : props.value;

  const isHyperLink = Boolean(colId
    ? (props.context as AgGridContext).hyperLinkColIdMap?.[colId]
    : undefined);

  const style = useMemo(() => getResolvedCellStyle(
    colId,
    (props.context as AgGridContext).layout?.styleMap,
    (props.context as AgGridContext).colIdToStyleIdMap,
    (props.context as AgGridContext).defnForm,
    props.data as IDataGridCell
  ), [colId, props.context, props.data]);

  if(rowId && colId)
  {
    if(colId && isComboId(colId))
    {
      const [fieldId, capturedValue] = colId.split("<>");
      const _capturedValue = capturedValue as TypeCapturedValues;
      if(_capturedValue === "captureUser")
      {
        const value = (props.data?.valueMap[fieldId] as TypeCapturedFieldValue)?.captureUser as FieldValueEntUserId | undefined;
        return <GridCellUserText
          {...props}
          isHyperLink={isHyperLink}
          entUserId={value?.value}
          rowId={rowId}
          text={value?.displayField || value?.value || ""}
          cellStyle={style}
        />;

      }
    }

    switch(fieldType)
    {
      case "pickUser":
      case "refUser":
      case "userId":
        const fieldValueUser = value as FieldValueEntUserId | undefined;
        return (
          <GridCellUserText
            {...props}
            isHyperLink={isHyperLink}
            entUserId={fieldValueUser?.value}
            rowId={rowId}
            text={fieldValueUser?.displayField || cellValue}
            cellStyle={style}
          />
        );
      case "setOfUser":
        const fieldValueSetOfUser = value as FieldSetOfEntUserId | undefined;
        return (
          <GridCellSetOfUserText
            {...props}
            isHyperLink={isHyperLink}
            fieldValue={fieldValueSetOfUser}
            rowId={rowId}
            text={cellValue}
            cellStyle={style}
          />
        );
    }
  }

  return (
    <RawHighlighter
      value={typeof cellValue === "string" ? cellValue : `${cellValue ?? ""}`}
      width={"100%"}
      searchWords={searchWords}
      cursor={isHyperLink ? "pointer" : undefined}

      //style
      variant={style?.variant || GRID_FONT_VARIANT}
      color={style?.color || props.color || "textSecondary"}
      textDecoration={isHyperLink ? "underline" : style?.textDecoration}
      bold={style?.isBold}
      italic={style?.isItalic}
    />
  );
}

function GridCellUserText<SR1, SR2, SR3, SR4, SR5, SR6>(props:
  IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>
  & {
  entUserId?: EntUserId,
  rowId: RowId,
  text: string,
  isHyperLink: boolean,
  cellStyle?: IDataGridCellStyle
})
{
  const rowId = props.rowId;
  const gridBinder = props.gridBinder;
  const text = props.text;
  const entUserId = props.entUserId;
  const isHyperLink = props.isHyperLink;
  const style = props.cellStyle;
  const searchWords = (props.context as AgGridContext).searchWords;
  const selectUserAvatar = gridBinder?.selectUserAvatar;
  const userAvatar = useAppSelector(state => (selectUserAvatar && entUserId)
    ? selectUserAvatar(state, rowId, entUserId)
    : undefined);
  const user = useMemo(() => userAvatar ? textUser(userAvatar) : text, [userAvatar]);

  return (
    <RawHighlighter
      value={user}
      width={"100%"}
      searchWords={searchWords}
      cursor={isHyperLink ? "pointer" : undefined}

      //style
      variant={style?.variant || GRID_FONT_VARIANT}
      color={(style?.color || "textSecondary")}
      textDecoration={isHyperLink ? "underline" : style?.textDecoration}
      bold={style?.isBold}
      italic={style?.isItalic}
    />
  );
}

function GridCellSetOfUserText<SR1, SR2, SR3, SR4, SR5, SR6>(props:
  IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>
  & {
  fieldValue?: FieldSetOfEntUserId,
  rowId: RowId,
  text: string,
  isHyperLink: boolean,
  cellStyle?: IDataGridCellStyle
})
{
  const rowId = props.rowId;
  const gridBinder = props.gridBinder;
  const text = props.text;
  const fieldValue = props.fieldValue;
  const isHyperLink = props.isHyperLink;
  const style = props.cellStyle;
  const searchWords = (props.context as AgGridContext).searchWords;
  const selectUserAvatar = gridBinder?.selectUserAvatar;

  const usersText = useAppSelector(state =>
  {
    return fieldValue?.valueSet.map((userId, index) =>
    {
      const user = selectUserAvatar?.(state, rowId, userId);
      return user ? textUser(user) : fieldValue?.displaySet?.[index];
    });
  }, isEqual);

  const value = useMemo(() => usersText?.join(", ") || text, [usersText]);

  return (
    <RawHighlighter
      value={value}
      width={"100%"}
      searchWords={searchWords}
      cursor={isHyperLink ? "pointer" : undefined}

      //style
      variant={style?.variant || GRID_FONT_VARIANT}
      color={(style?.color || "textSecondary")}
      textDecoration={isHyperLink ? "underline" : style?.textDecoration}
      bold={style?.isBold}
      italic={style?.isItalic}
    />
  );
}

function getSystemFieldValue(systemFieldId: EnumDefnFields, data?: IDataGridCell): FieldValueEntUserId | undefined
{
  if(systemFieldId === "$CreatedBy")
  {
    if(data?.createdBy)
    {
      return {
        value: data?.createdBy,
        displayField: ""
      };
    }
  }
  else if(systemFieldId === "$UpdatedBy")
  {
    if(data?.updatedBy)
    {
      return {
        value: data?.updatedBy,
        displayField: ""
      };
    }
  }
}
