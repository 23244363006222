// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgStoreItemId} from './msg/MsgStoreItemId';
import {ServiceName} from '../../meta/base/Types';
import {SigStoreItemAvatar} from './sig/SigStoreItemAvatar';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocStore
{
  static readonly SN: ServiceName = "store";

  static storeItemAvatarGet(msg: MsgStoreItemId, sigAcceptor: ISigAcceptor<SigStoreItemAvatar>): void
  {
    wsocCall<SigStoreItemAvatar>(ENT_ID_GLOBAL, WsocStore.SN, "storeItemAvatarGet")
      .get(msg, sigAcceptor);
  }
}