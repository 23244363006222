import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldTime} from "../../../api/meta/base/dto/DefnFieldTime";
import {StudioBuildTime} from "../../../api/meta/base/dto/StudioBuildTime";
import {StudioVarTime} from "../../../api/meta/base/dto/StudioVarTime";
import {EnumArrayDefnTime} from "../../../api/meta/base/Types";
import {EnumDefnTime} from "../../../api/meta/base/Types";
import {AnyTime} from "../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {arrayToMapOfOption} from "../../../base/plus/JsPlus";
import {fieldValueTimeEnum} from "../base/VariablePlus";

type timeType =
  | EnumDefnTime
  | "custom"

const fieldCustomValue = "customValue";

export function fnVariableBuilderTime(fieldValueTime: string)
{
  const timeOptions: timeType[] = [
    "custom",
    ...EnumArrayDefnTime as EnumDefnTime[]
  ];

  function getVarComp(variableTimeType?: string)
  {
    return {
      [fieldValueTime]: {
        type: "pickText",
        metaId: fieldValueTime,
        name: "Value",
        optionMap: arrayToMapOfOption(timeOptions),
        required: true
      } as DefnFieldPickText,

      ...variableTimeType === "custom" && {
        [fieldCustomValue]: {
          type: "time",
          metaId: fieldCustomValue,
          name: "time",
          required: true
        } as DefnFieldTime
      }
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarTime
  )
  {
    const varValue = variable.value;

    if(varValue)
    {
      const timeEnumValue = varValue.value;
      if(timeEnumValue && EnumArrayDefnTime.includes(timeEnumValue))
      {
        valueMap[fieldValueTime] = fnRawValueToFieldValue("pickText", timeEnumValue);
        valueMap[fieldValueTimeEnum] = null;
      }
      else if(varValue.customValue)
      {
        valueMap[fieldValueTime] = fnRawValueToFieldValue("pickText", "custom");
        valueMap[fieldCustomValue] = fnRawValueToFieldValue("time", varValue.customValue);
      }
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const timeValue = fnFieldValueToRawValue("pickText", valueMap[fieldValueTime]) as timeType;
    const customValue = valueMap[fieldCustomValue] as AnyTime;

    const studioBuildTimeDto = {} as StudioBuildTime;

    if(!timeValue)
    {
      return;
    }

    if(timeValue && EnumArrayDefnTime.includes(timeValue))
    {
      studioBuildTimeDto.value = timeValue as EnumDefnTime;
      return {
        value: studioBuildTimeDto
      };
    }
    else if(timeValue === "custom" && customValue)
    {
      studioBuildTimeDto.customValue = customValue;
      return {
        value: studioBuildTimeDto
      };
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
