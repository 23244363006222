import React from "react";
import {ALERT_DIALOG_CONTENT_WIDTH} from "../../../base/plus/ConstantsPlus";
import {ALERT_DIALOG_CONTENT_HEIGHT} from "../../../base/plus/ConstantsPlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import DialogAtom from "./DialogAtom";
import {OK} from "./DialogConfirm";
import DialogTypography from "./DialogTypography";
import {IDialogMsg} from "./TypesDialog";

export default function DialogAlert(props: {
  title?: string,
  msg: IDialogMsg | string
  fullScreen?: boolean
})
{
  return (
    <DialogAtom
      title={props.title ?? "Alert"}
      fullScreen={props.fullScreen}
      contentWidth={ALERT_DIALOG_CONTENT_WIDTH}
      contentHeight={ALERT_DIALOG_CONTENT_HEIGHT}
      content={
        <LayoutFlexCol
          width={"100%"}
          height={"100%"}
        >
          <LayoutFlexCol
            width={"100%"}
            flexGrow={1}
          >
            <DialogTypography msg={props.msg} />
          </LayoutFlexCol>
        </LayoutFlexCol>
      }
      rightFooterActions={[OK]}
    />
  );
}
