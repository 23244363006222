import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useCallback} from "react";
import {useEffect} from "react";
import React from "react";
import {nextMetaIdVar} from "../../../../api/meta/base/ApiPlus";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {StudioVarTimeZone} from "../../../../api/meta/base/dto/StudioVarTimeZone";
import {EnumArrayStudioVarKind} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../../base/plus/ConstantsPlus";
import {getPasteFailMsg} from "../../../../base/plus/SrvcPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {TypeStudioFilterType} from "../../../../base/plus/StudioPlus";
import {getSelectedUsageFilterLabel} from "../../../../base/plus/StudioPlus";
import {selectCacheStudioEntState} from "../../../../base/plus/StudioPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {selectCacheStudioEntValidationResult} from "../../../../base/plus/StudioPlus";
import {validateAdminEditLockPermission} from "../../../../base/plus/StudioPlus";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItem} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IAsidePropsStudio} from "../../../../base/types/TypesAside";
import {IDrawerOverlayPropsFindUsages} from "../../../../base/types/TypesDrawer";
import {IMainPropsStudio} from "../../../../base/types/TypesMain";
import {TypeListSortType} from "../../../../base/types/TypesStudio";
import {IStudioJumpPath} from "../../../../base/types/TypesStudio";
import {IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {useAppSelector} from "../../../../nucleus/app/AppHooks";
import helperTextJsonData from "../../../../nucleus/atom/assets/PlaceholderTextStudio.json";
import DialogPaste from "../../../../nucleus/atom/dialog/DialogPaste";
import {EnumIconButton} from "../../../../nucleus/atom/icon/IconButtonStrip";
import LayoutFlexCol from "../../../../nucleus/atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../../nucleus/atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../../nucleus/atom/raw/RawButtonStrip";
import RawHighlighter from "../../../../nucleus/atom/raw/RawHighlighter";
import {IMenuProps} from "../../../../nucleus/atom/raw/RawMenu";
import RawNothingHere from "../../../../nucleus/atom/raw/RawNothingHere";
import RawSearch from "../../../../nucleus/atom/raw/RawSearch";
import {useAppCtx} from "../../../../nucleus/ctx/CtxApp";
import {usePageCtx} from "../../../../nucleus/ctx/CtxPage";
import DialogNewVariable from "../../../../nucleus/dialog/DialogNewVariable";
import DialogStudioEntElementPicker from "../../../../nucleus/dialog/DialogStudioEntElementPicker";
import {CbOnDragListItem} from "../../../../nucleus/list/List";
import {ListClickVariant} from "../../../../nucleus/list/List";
import {List} from "../../../../nucleus/list/List";
import {useValidationErrorList} from "../../../../nucleus/list/validation/ListValidation";
import {Srvc} from "../../../../srvc/Srvc";
import {useGetCliCode} from "../../app/UiStudioPlus";
import {useStepListDefaultConfig} from "../../app/UiStudioPlus";

export default function UiStudioEntVariables(props: {
  entId: EntId,
  width: number,
})
{
  const entId = props.entId;
  const width = props.width;
  const srvc = Srvc.studio.ent;
  const selectList = srvc.variables.selectList;

  const theme = useTheme();
  const pageCtx = usePageCtx();
  const gapStd = theme.common.gapStd;
  const appCtx = useAppCtx();
  const mainProps = appCtx.getMainProps() as IMainPropsStudio;
  const selectStudioEnt = mainProps.studioSelector.ent;
  const selectedModules = mainProps.studioSelector.selectedModules;
  const entFormStore = mainProps.studioSelector.formStore;

  const listName = useAppSelector(state => selectList(state).listName);
  const entVersion = useAppSelector(state => selectCacheStudioEnt(state, entId)?.version);
  const lastItemId = useAppSelector(state => selectList(state).displayLastItemId);
  const firstItemId = useAppSelector(state => selectList(state).displayFirstItemId);
  const varMap = useAppSelector(state => selectStudioEnt(state)?.varMap);
  const variableUsageFilter = useAppSelector(state =>
    selectCacheStudioEntState(state, entId)?.usageFilter?.variableUsageFilter || "All");
  const selectedFilter = useAppSelector(state => selectedModules(state));
  const validationResult = useAppSelector(state => selectCacheStudioEntValidationResult(state, entId));
  const onClickItemInfo = useValidationErrorList(selectList, "varMap", undefined, validationResult);
  const formStore = useAppSelector(state => entFormStore(state));
  const variableUsageLabel = getSelectedUsageFilterLabel(variableUsageFilter);

  const timeZone = useAppSelector(state => selectStudioEnt(state)?.details.timeZone);

  const fnResolver = fnUseStudioResolver(formStore);

  const getCliCode = useGetCliCode();

  const isCallerHasPermission = useAppSelector(state =>
    validateAdminEditLockPermission(state, entId, "variables"));

  const readOnly = mainProps.type === "storeItemEnt" ? true : !isCallerHasPermission;

  const onClickListItem = useCallback((metaIdVar: MetaIdVar, jumpPath?: IStudioJumpPath[]) =>
  {
    appCtx.setAsideProps({
      type: "Variables",
      metaId: metaIdVar,
      readOnly: readOnly,
      jumpPath: jumpPath,
      reset: true
    } as IAsidePropsStudio);
  }, [appCtx, readOnly]);

  const {remoteClickListItem} = useStepListDefaultConfig(selectList, "varMap", onClickListItem);

  const onClickApply = useCallback((stringifyVariable: string) =>
  {
    const variablePayload = JSON.parse(stringifyVariable) as IDtoEntCopy;

    if(variablePayload.type === "variable")
    {
      const variable = variablePayload.payload as StudioVar;

      if(isValidVariable(variable))
      {
        Srvc.studio.ent.variables.addVariable(entId, variable);
      }
      else
      {
        pageCtx.showErrorToast("Invalid variable");
      }
    }
    else
    {
      pageCtx.showErrorToast(getPasteFailMsg(variablePayload.type, "variable"));
    }
  }, [entId, pageCtx]);

  const onClickPaste = useCallback(() =>
  {
    pageCtx.showDialog(
      <DialogPaste
        title={"Paste variable"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={(str) => onClickApply(str)}
      />
    );
  }, [onClickApply, pageCtx]);

  const cbOnMerge = useCallback((metaVarId: MetaIdVar) =>
  {
    appCtx.setAsideProps(undefined);

    const doLoad = () =>
    {
      const uiItemIds = [] as TypeListItemId[];
      const uiItemsById = {} as IListItemsById;

      if(varMap)
      {
        const currVar = varMap.map[metaVarId];

        varMap.keys.forEach(varId =>
        {
          const studioVar = varMap.map[varId];

          if(varId !== metaVarId && studioVar.kind === currVar.kind)
          {
            uiItemIds.push(varId);
            uiItemsById[varId] = {
              type: "p",
              primary: {
                text: studioVar.details.name
              },
              hideMenu: true
            } as IListItemAPSA;
          }
        });
      }

      return {
        itemIds: uiItemIds,
        itemsById: uiItemsById
      };
    };

    pageCtx.showDialog(
      <DialogStudioEntElementPicker
        title={"Pick variable"}
        searchPlaceHolder={"Search variables"}
        doLoad={doLoad}
        onClickOk={(varId) =>
        {
          Srvc.studio.ent.rpcStudioEntPartMerge(entId, metaVarId, varId, () =>
          {
            Srvc.app.toast.showSuccessToast("Variables successfully merged");
          });
        }}
      />
    );

  }, [appCtx, pageCtx, varMap, entId]);

  const onClickMenuProps = useCallback((itemId: TypeListItemId, item: IListItemAPSA, menuAnchor: Element) =>
  {
    pageCtx.showMenu(menuAnchor,
      {
        "Edit": () =>
        {
          remoteClickListItem(itemId);
        },
        "gap1": undefined,
        "Copy": () => srvc.variables.copyEntVariable(entId, itemId),
        "Get CLI code": () => getCliCode(entId, itemId),
        "Remove": () => srvc.variables.removeEntVariable(entId, itemId),
        "Duplicate": () => srvc.variables.duplicateEntVariable(entId, itemId),
        "gap2": undefined,
        "Move up": {
          onClick: () => srvc.moveItem(entId, itemId, "up", "variableList"),
          disabled: !(itemId !== firstItemId)
        },
        "Move down": {
          onClick: () => srvc.moveItem(entId, itemId, "down", "variableList"),
          disabled: !(itemId !== lastItemId)
        },
        "Move top": {
          onClick: () => srvc.moveItem(entId, itemId, "top", "variableList"),
          disabled: !(itemId !== firstItemId)
        },
        "Move bottom": {
          onClick: () => srvc.moveItem(entId, itemId, "bottom", "variableList"),
          disabled: !(itemId !== lastItemId)
        },
        "gap3": undefined,
        "Merge with this": () => cbOnMerge(itemId),
        "gap4": undefined,
        "Find usages": {
          onClick: () =>
          {
            appCtx.setDrawerOverlayProps({
              type: "Find usages",
              findUsageName: item.primary?.text,
              findUsageMetaId: itemId,
              artifactId: entId
            } as IDrawerOverlayPropsFindUsages);
          }
        }
      }
    );
  }, [cbOnMerge, entId, firstItemId, getCliCode, lastItemId, remoteClickListItem]);

  const onClickAdd = useCallback(() =>
  {
    pageCtx.showDialog(<DialogNewVariable
      formStore={formStore}
      onClickOk={(values) =>
      {
        const metaId = nextMetaIdVar();
        if(values.name && values.selectedVariable && values.deployType)
        {
          const newStudioVariable = {
            metaId: metaId,
            kind: values.selectedVariable,
            deploy: values.deployType,
            details: {
              name: values.name,
              modules: values.modules ?? selectedFilter
            },
            ...values.selectedVariable === "timeZone" && {
              value: {
                value: timeZone
              }
            } as StudioVarTimeZone
          } as StudioVar;

          Srvc.studio.ent.variables.addVariable(entId, newStudioVariable);
        }
      }}
    />);
  }, [pageCtx, entId, lastItemId, onClickListItem, listName, remoteClickListItem, selectedFilter]);

  const cbOnClick = useCallback((
    menuAnchor: Element,
    itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant,
    _?: boolean,
    selected?: boolean) =>
  {
    const variable = varMap ? varMap.map[itemId] : undefined;

    switch(variant)
    {
      case "listItem":
        (variable && selected) && onClickListItem(itemId);
        break;
      case "spotMenu":
        onClickMenuProps(itemId, item, menuAnchor);
        break;
      case "spotInfo":
        onClickItemInfo(menuAnchor, itemId);
    }
  }, [onClickListItem, onClickMenuProps, varMap]);

  const cbOnClickSort = (type: TypeListSortType) =>
  {
    srvc.sortList(entId, "variableList", type);
  };

  const onClickVariableFilter = useCallback((menuAnchor: Element, usageFilter: TypeStudioFilterType) =>
  {
    const setFilter = (filter: TypeStudioFilterType) => () => srvc.variables.setUsageFilter(entId, filter);
    const menuProps: IMenuProps = {};

    menuProps["Single use"] = {
      selected: usageFilter === "SingleUse",
      onClick: setFilter("SingleUse")
    };

    menuProps["Multi use"] = {
      selected: usageFilter === "MultiUse",
      onClick: setFilter("MultiUse")
    };

    menuProps["Unused"] = {
      selected: usageFilter === "Unused",
      onClick: setFilter("Unused")
    };
    menuProps["line1"] = undefined;
    menuProps["All"] = {
      selected: usageFilter === "All",
      onClick: setFilter("All")
    };
    pageCtx.showMenu(menuAnchor, menuProps);

  }, [entId, pageCtx, srvc.variables]);

  const onDragListItem: CbOnDragListItem = useCallback((itemId, _item, dragStartIndex, dragEndIndex) =>
  {
    Srvc.studio.ent.moveItemIndex(entId, itemId, "variableList", dragStartIndex, dragEndIndex);
  }, [entId]);

  const getFilterIconColor = (usageFilter: TypeStudioFilterType): string | undefined =>
    (usageFilter === "All") ? undefined : theme.palette.primary.dark;

  useEffect(() =>
  {
    if(varMap)
    {
      Srvc.studio.ent.variables.loadVariableList(entId, listName, varMap, readOnly, variableUsageFilter, fnResolver);
    }
  }, [entId, listName, entVersion, varMap, selectedFilter, readOnly, variableUsageFilter]);

  useStepListDefaultConfig(selectList, "varMap", onClickListItem);

  return (
    <LayoutFlexCol
      width={px(width)}
      height={"100%"}
      pl={px(gapStd)}
      pr={px(gapStd)}
      ml={"auto"}
      mr={"auto"}
      bgcolor={theme.common.bgcolorSidePane}
    >
      <LayoutFlexRow
        width={px(width)}
        alignItems={"center"}
      >
        <RawSearch
          placeHolder={"Search variables"}
          onSearchBindListName={listName}
          showSorting={!readOnly}
          showFilter={true}
          onClickFilter={(menuAnchor) => onClickVariableFilter(menuAnchor, variableUsageFilter)}
          onClickSorting={cbOnClickSort}
          filterToolTipTitle={toLabel(variableUsageFilter)}
          filterIconColor={getFilterIconColor(variableUsageFilter)}
        />
      </LayoutFlexRow>

      <Box
        width={"100%"}
        height={"100%"}
        bgcolor={theme.common.bgcolorContent}
        border={`1px solid ${theme.common.borderColor}`}
      >
        <List
          selectList={selectList}
          onClickListItem={cbOnClick}
          onDragListItem={onDragListItem}
          emptyComp={
            <RawNothingHere
              helperTextData={helperTextJsonData.variables}
            />
          }
          emptySearch={STR_NOTHING_HERE}
        />
      </Box>

      <LayoutFlexRow
        width={"100%"}
        height={px(theme.common.heightFooter)}
        pt={px(gapStd)}
        pb={px(gapStd)}
        justifyContent={variableUsageLabel
          ? "space-between"
          : "flex-end"}
      >
        {
          variableUsageLabel &&
          <RawHighlighter
            value={`Showing ${variableUsageLabel} variables`}
            color={theme.palette.text.disabled}
            variant={"body2"}
          />
        }
        <RawButtonStrip
          iconButtonList={["paste", "add"]}
          labelMap={{add: "ADD..."} as Record<EnumIconButton, string>}
          iconButtonDisable={readOnly ? ["paste", "add"] : []}
          toolTipMap={{
            add: "New Variable",
            paste: "Paste Variable"
          } as Record<EnumIconButton, string>}
          onClick={(iconName) =>
          {
            switch(iconName)
            {
              case "add":
                onClickAdd();
                break;
              case "paste":
                onClickPaste();
                break;
            }
          }}
        />
      </LayoutFlexRow>
    </LayoutFlexCol>
  );
}

export function isValidVariable(variable: StudioVar)
{
  return Boolean(variable
    && variable.metaId
    && variable.details.name
    && EnumArrayStudioVarKind.includes(variable.kind)
  );
}
