import {isArray} from "lodash";
import {useEffect} from "react";
import {useRef} from "react";
import {useState} from "react";
import {useCallback} from "react";
import {isPluginId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {SysId} from "../../../api/meta/base/SysId";
import {ArtifactId} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {MsgStudioEnt} from "../../../api/studio/studioMain/msg/MsgStudioEnt";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {fnDeltaItemModules} from "../../../base/plus/StudioPlus";
import {getJumpStepFromStudioEntStep} from "../../../base/plus/StudioPlus";
import {listReset} from "../../../base/slices/list/SliceList";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IAsidePropsStudio} from "../../../base/types/TypesAside";
import {IMainPropsStudio} from "../../../base/types/TypesMain";
import {IStudioJumpPath} from "../../../base/types/TypesStudio";
import {EnumStudioSearchPathKeys} from "../../../base/types/TypesStudio";
import {ICacheStudioEntState} from "../../../cache/studio/ent/TypesCacheStudioEnt";
import {ICacheStudioStateEnt} from "../../../cache/studio/ent/TypesCacheStudioEnt";
import {ICacheStudioPluginStateMap} from "../../../cache/studio/plugin/TypesCacheStudioPlugin";
import {ICacheStudioStatePlugin} from "../../../cache/studio/plugin/TypesCacheStudioPlugin";
import {useAppSelector} from "../../../nucleus/app/AppHooks";
import {useAppCtx} from "../../../nucleus/ctx/CtxApp";
import {usePageCtx} from "../../../nucleus/ctx/CtxPage";
import DialogGetCliCode from "../../../nucleus/dialog/DialogGetCliCode";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {isValidVariable} from "../ent/variables/UiStudioEntVariables";

export function validateEnt(ent: MsgStudioEnt)
{
  return !(!ent.studioEnt
    || !ent.studioEnt.entId
    || !ent.studioEnt.details
    || !ent.studioEnt.details.name
    || !fnHasKeysAndMap(ent.studioEnt.actionMap)
    || !fnHasKeysAndMap(ent.studioEnt.automationMap)
    || !fnHasKeysAndMap(ent.studioEnt.driveSheetMap)
    || !fnHasKeysAndMap(ent.studioEnt.translationMap)
    || !fnHasKeysAndMap(ent.studioEnt.formMap)
    || !fnHasKeysAndMap(ent.studioEnt.varMap)
    || !fnHasKeysAndMap(ent.studioEnt.groupMap)
    || !fnHasKeysAndMap(ent.studioEnt.pluginMap)
    || !fnHasKeysAndMap(ent.studioEnt.promptMap)
    || !fnHasKeysAndMap(ent.studioEnt.roleMap)
    || !fnHasKeysAndMap(ent.studioEnt.reportMap)
    || !fnHasKeysAndMap(ent.studioEnt.deeplinkMap)
    || !fnHasKeysAndMap(ent.studioEnt.spreadsheetMap)
    || !fnHasKeysAndMap(ent.studioEnt.deployPluginMap)
    || !fnHasKeysAndMap(ent.studioEnt.deployVarMap));
}

function fnHasKeysAndMap(dto?: unknown)
{
  if(!dto)
  {
    return false;
  }
  if(typeof dto === "object"
    && "keys" in dto
    && "map" in dto)
  {
    if(!isArray(dto.keys))
    {
      return false;
    }
    return typeof dto.map === "object";
  }
  return false;
}

function useAutoSaveArtifacts(props: {
  isDirty: boolean,
  cbRemoveDelta: () => void,
  cbRpcPut: () => void,
  selectCacheStudioLocalState: (state: RootState) => ICacheStudioEntState | ICacheStudioPluginStateMap
})
{
  const autoSaveMs = 0;
  const retryMs = 5000;
  const clearDeltaMs = 10000;
  const selectCacheStudioLocalState = props.selectCacheStudioLocalState;
  const isDirtyEnt = props.isDirty;
  const cbRpcPut = props.cbRpcPut;
  const cbRemoveDelta = props.cbRemoveDelta;

  const rpcEntPutTimeOutId = useRef<NodeJS.Timeout | undefined>();
  const isSavingTimeOutId = useRef<NodeJS.Timeout | undefined>();
  const cleanDeltaTimeOutId = useRef<NodeJS.Timeout | undefined>();

  const isSaving = useAppSelector(state => selectCacheStudioLocalState(state)?.isSaving);
  const deltaStudioEnt = useAppSelector(state => (selectCacheStudioLocalState(state) as ICacheStudioEntState | ICacheStudioPluginStateMap)?.deltaStudio);
  const dirtyVersion = useAppSelector(state => selectCacheStudioLocalState(state)?.dirtyVersion);
  const [previousDirtyVersion, setPreviousDirtyVersion] = useState<string>();

  useEffect(() =>
  {
    rpcEntPutTimeOutId.current = setTimeout(() =>
    {
      if(isDirtyEnt && dirtyVersion !== previousDirtyVersion)
      {
        cbRpcPut();
      }
    }, autoSaveMs);

    return () =>
    {
      if(rpcEntPutTimeOutId.current)
      {
        clearTimeout(rpcEntPutTimeOutId.current);
      }
    };
  }, [isDirtyEnt, dirtyVersion, cbRpcPut]);

  useEffect(() =>
  {
    if(!isSavingTimeOutId.current)
    {
      isSavingTimeOutId.current = setInterval(() =>
      {
        if(isSaving)
        {
          cbRpcPut();
        }
      }, retryMs);
    }
    if(!isSaving && isSavingTimeOutId.current)
    {
      clearInterval(isSavingTimeOutId.current);
    }

    return () =>
    {
      if(isSavingTimeOutId.current)
      {
        clearInterval(isSavingTimeOutId.current);
      }
    };

  }, [isSaving, isDirtyEnt, dirtyVersion, cbRpcPut]);

  useEffect(() =>
  {
    if(!isSaving && !isDirtyEnt)
    {
      if(deltaStudioEnt?.insert || deltaStudioEnt?.remove)
      {
        cleanDeltaTimeOutId.current = setTimeout(() =>
        {
          cbRemoveDelta();
        }, clearDeltaMs);
      }
    }
    return () =>
    {
      if(cleanDeltaTimeOutId.current)
      {
        clearTimeout(cleanDeltaTimeOutId.current);
      }
    };

  }, [isSaving, isDirtyEnt, deltaStudioEnt, cbRemoveDelta]);

  useEffect(() =>
  {
    if(previousDirtyVersion !== dirtyVersion)
    {
      setPreviousDirtyVersion(dirtyVersion);
    }
  }, [dirtyVersion]);

  useEffect(() =>
  {
    return () =>
    {
      if(isSavingTimeOutId.current)
      {
        clearInterval(isSavingTimeOutId.current);
      }
      if(rpcEntPutTimeOutId.current)
      {
        clearTimeout(rpcEntPutTimeOutId.current);
      }
    };
  }, []);
}

export function useAutoSaveEnt(props: {
  entId: EntId,
  selectCacheStudio: (state: RootState) => ICacheStudioStateEnt
})
{
  const entId = props.entId;
  const selectCacheStudio = props.selectCacheStudio;
  const isDirtyEnt = useAppSelector(state => selectCacheStudio(state).dirtyEntIdList?.includes(entId));

  const cbCleanDelta = useCallback(() =>
  {
    Srvc.cache.studio.ent.removeDeltaLocalState(entId);
  }, [entId]);

  const cbRpcPut = useCallback(() =>
  {
    Srvc.studio.ent.rpcEntPut(entId);
  }, [entId]);

  useAutoSaveArtifacts({
    isDirty: isDirtyEnt,
    selectCacheStudioLocalState: (state) => selectCacheStudio(state).entStateMap[entId],
    cbRemoveDelta: cbCleanDelta,
    cbRpcPut: cbRpcPut
  });

  useEffect(() =>
  {
    return () =>
    {
      const rootState = store.getState();
      const isDirtyEnt = selectCacheStudio(rootState).dirtyEntIdList?.includes(entId);
      if(isDirtyEnt)
      {
        cbRpcPut();
      }
    };
  }, []);
}

export function useAutoSavePlugin(props: {
  pluginBundleId: PluginBundleId,
  selectCacheStudio: (state: RootState) => ICacheStudioStatePlugin
})
{
  const pluginBundleId = props.pluginBundleId;
  const selectCacheStudio = props.selectCacheStudio;
  const isDirtyEnt = useAppSelector(state => selectCacheStudio(state).dirtyPluginIdList?.includes(pluginBundleId));

  const cbCleanDelta = useCallback(() =>
  {
    Srvc.cache.studio.plugin.removeDeltaLocalState(pluginBundleId);
  }, [pluginBundleId]);

  const cbRpcPut = useCallback(() =>
  {
    Srvc.studio.plugin.rpcPluginPut(pluginBundleId);
  }, [pluginBundleId]);

  useAutoSaveArtifacts({
    isDirty: isDirtyEnt,
    selectCacheStudioLocalState: (state) => selectCacheStudio(state).pluginStateMap[pluginBundleId],
    cbRemoveDelta: cbCleanDelta,
    cbRpcPut: cbRpcPut
  });

  useEffect(() =>
  {
    return () =>
    {
      const rootState = store.getState();
      const isDirtyEnt = selectCacheStudio(rootState).dirtyPluginIdList?.includes(pluginBundleId);
      if(isDirtyEnt)
      {
        cbRpcPut();
      }
    };
  }, []);
}

export function useStepListDefaultConfig(
  selectList: SelectList,
  stepName: EnumStudioSearchPathKeys,
  onClickListItem: (itemId: TypeListItemId, jumpPath?: IStudioJumpPath[]) => void,
  preventJumpUseEffect?: boolean,
  preventUnMountUseEffect?: boolean,
  preventCloseAsideUseEffect?: boolean
)
{
  const appCtx = useAppCtx();
  const mainProps = appCtx.getMainProps() as IMainPropsStudio;
  const asideProps = appCtx.getAsideProps() as IAsidePropsStudio;
  const jumpListItemId = mainProps?.jumpListItemId;

  const [remoteClickItemId, setRemoteClickItemId] = useState<TypeListItemId>();

  const listName = useAppSelector(state => selectList(state).listName);
  const loaded = useAppSelector(state => selectList(state).loaded);
  const selectedItemId = useAppSelector(state => selectList(state).selectedItemId);

  const isItemExist = useAppSelector(state => Boolean(jumpListItemId && selectList(state).itemsById[jumpListItemId]));
  const isItemExistJump = useAppSelector(state => Boolean(remoteClickItemId
    && selectList(state).itemsById[remoteClickItemId]));

  const remoteClickListItem = useCallback((itemId: TypeListItemId) =>
  {
    dispatchList(listName, listSetSelectedItemId(itemId));
    onClickListItem(itemId, mainProps.jumpPath);
    appCtx.setMainProps({
      ...mainProps,
      jumpListItemId: itemId
    } as IMainPropsStudio);

  }, [listName, mainProps]);

  useEffect(() =>
  {
    if(remoteClickItemId)
    {
      dispatchList(listName, listSetSelectedItemId(remoteClickItemId));
      onClickListItem(remoteClickItemId, mainProps.jumpPath);
      setRemoteClickItemId(undefined);
    }
  }, [remoteClickItemId, isItemExistJump]);

  useEffect(() =>
  {
    if(preventJumpUseEffect)
    {
      return;
    }
    if(jumpListItemId && loaded && isItemExist)
    {
      dispatchList(listName, listSetSelectedItemId(jumpListItemId));
      appCtx.setMainProps({
        ...mainProps,
        jumpListItemId: undefined
      } as IMainPropsStudio);
      onClickListItem(jumpListItemId, mainProps.jumpPath);
    }

  }, [jumpListItemId, loaded, mainProps, onClickListItem, listName, appCtx]);

  useEffect(() =>
  {
    if(preventCloseAsideUseEffect)
    {
      return;
    }
    if(!mainProps)
    {
      appCtx.setAsideProps(undefined);
      return;
    }

    const entStep = getJumpStepFromStudioEntStep(stepName);

    if(!selectedItemId && !jumpListItemId)
    {
      if(stepName === "visibilityRuleMap"
        || stepName === "formulaMap"
        || stepName === "layoutMap")
      {
        if(mainProps.jumpStepItem === entStep)
        {
          appCtx.setAsideProps(undefined);
        }
      }
      else if((mainProps.jumpTab === "Builder") && mainProps.jumpStep === entStep)
      {
        appCtx.setAsideProps(undefined);
      }
      else if(mainProps.jumpTab === entStep)
      {
        appCtx.setAsideProps(undefined);
      }
    }
  }, [appCtx, selectedItemId, jumpListItemId, mainProps, stepName]);

  useEffect(() =>
  {
    if(!asideProps && !jumpListItemId)
    {
      dispatchList(listName, listSetSelectedItemId(undefined));
    }
  }, [asideProps, jumpListItemId, listName, selectedItemId]);

  useEffect(() =>
  {
    return () =>
    {
      if(!preventUnMountUseEffect)
      {
        appCtx.setAsideProps(undefined);
      }
      dispatchList(listName, listReset());
    };
  }, []);

  return {remoteClickListItem: remoteClickListItem};

}

//endregion

// region verify jump path

function traverseModules(obj: Record<any, any>, arr: string[], selectedModules: StudioModuleSelection)
{
  let allow = false;

  arr.reduce((acc, curr) =>
  {
    const currentItem = acc[curr];

    if(currentItem === undefined)
    {
      return acc;
    }

    if(Object.hasOwn(currentItem, "details"))
    {
      Object.hasOwn(currentItem["details"], "modules");
      const modules = currentItem["details"]["modules"];
      if(modules)
      {
        allow = fnDeltaItemModules(selectedModules, modules);
      }
    }
    else if(currentItem["modules"])
    {
      const modules = currentItem["modules"];
      if(modules)
      {
        allow = fnDeltaItemModules(selectedModules, modules);
      }
    }
    else
    {
      allow = fnDeltaItemModules(selectedModules, {moduleIdSet: []});
    }

    return acc ? currentItem : undefined;
  }, obj);

  return allow;
}

export function fnVerifyJumpPath(
  dto: object,
  selectedModules: StudioModuleSelection,
  searchPath?: string
)
{
  const pathArr = searchPath?.split("/") as EnumStudioSearchPathKeys[];
  const steps = [] as EnumStudioSearchPathKeys[];
  for(let i = 1; i < pathArr.length; i++)
  {
    const step = pathArr[i];
    if(step === "map" || pathArr[i + 1] === "map" || pathArr[i - 1] === "map")
    {
      steps.push(step);
    }
  }
  return traverseModules(dto, steps, selectedModules);
}

export function getStudioSize(
  mainWidth: number
)
{
  const stepperPortion = 1;
  const gapPortion = .25;
  const mainContentPortion = 2;

  const stepperMinWidth = 200;
  const stepperMaxWidth = 300;
  const mainContentMinWidth = 400;

  const minWidth = stepperMinWidth + mainContentMinWidth;
  const delta = (mainWidth - minWidth) / (stepperPortion + 2 * gapPortion + mainContentPortion);

  const stepperWidth = stepperMinWidth + delta * stepperPortion;
  const mainContentWidth = mainContentMinWidth + delta * mainContentPortion;

  return {
    stepperWidth: stepperWidth > stepperMaxWidth ? stepperMaxWidth : stepperWidth,
    mainContentWidth
  };
}

export function useArtifactVarAsideFns(artifactId: ArtifactId)
{
  const appCtx = useAppCtx();
  const isEnt = isEntId(artifactId);
  const isPlugin = isPluginId(artifactId);

  const cbAddVar = useCallback((studioVar: StudioVar) =>
  {
    if(studioVar && isValidVariable(studioVar))
    {
      if(isEnt)
      {
        Srvc.studio.ent.variables.addVariable(artifactId, studioVar);
      }
      else if(isPlugin)
      {
        Srvc.studio.plugin.variables.addPluginVariable(artifactId, studioVar);
      }
    }
  }, [artifactId, isEnt, isPlugin]);

  const cbShowVar = useCallback((varId: MetaIdVar) =>
  {
    if(isEnt || isPlugin)
    {
      appCtx.setMainProps({
        type: isEnt ? "ent" : "plugin",
        jumpListItemId: varId,
        jumpStep: "Variables",
        jumpTab: "Builder",
        jumpStepItem: undefined,
        jumpStepTab: undefined,
        itemId: artifactId,
        studioSelector: (appCtx.getMainProps() as IMainPropsStudio)?.studioSelector
      } as IMainPropsStudio);
    }

  }, [isEnt, isPlugin, appCtx, artifactId]);

  return {
    cbAddVar: cbAddVar,
    cbShowVar: cbShowVar
  };
}

export function useGetCliCode()
{
  const pageCtx = usePageCtx();

  return (artifactId: ArtifactId, metaId?: SysId) =>
  {
    Srvc.terminal.getCliCode(artifactId, metaId,
      (sig) =>
      {
        pageCtx.showDialog(
          <DialogGetCliCode
            code={sig.code}
          />
        );
      }
    );
  };
}
