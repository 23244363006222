import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {store} from "../../../../Store";
import {setCacheSsEditorBulkImportStatus} from "./StoreCacheSpreadsheetEditor";
import {setCacheSsEditorRowsState} from "./StoreCacheSpreadsheetEditor";
import {setCacheSsEditorRowMutation} from "./StoreCacheSpreadsheetEditor";
import {setCacheSsEditorRowIdMap} from "./StoreCacheSpreadsheetEditor";
import {clearCacheSsEditorState} from "./StoreCacheSpreadsheetEditor";
import {setCacheSsEditorRowState} from "./StoreCacheSpreadsheetEditor";
import {TypeBulkImportStatusType} from "./TypesCacheSpreadsheetEditor";
import {ICacheSsEditorRow} from "./TypesCacheSpreadsheetEditor";

export default class SrvcCacheSpreadsheetEditor extends ISrvc
{
  private cacheSsEditorRowMutation: boolean = false;
  private currentTick = 0;
  private readonly maxTick = 3;
  private doSsEditorRowMutation: MetaIdSpreadsheet | undefined = undefined;

  initSsEditorState(spreadsheetId: MetaIdSpreadsheet): void
  {
    store.dispatch(setCacheSsEditorRowIdMap({
      spreadsheetId: spreadsheetId
    }));
  }

  setSsEditorRowState(rowId: RowId, spreadsheetId: MetaIdSpreadsheet, sig: ICacheSsEditorRow): void
  {
    store.dispatch(setCacheSsEditorRowState({
      rowId: rowId,
      spreadsheetId: spreadsheetId,
      sig: sig
    }));
  }

  setSsEditorRowsState(rowIds: RowId[], spreadsheetId: MetaIdSpreadsheet, sig: ICacheSsEditorRow): void
  {
    store.dispatch(setCacheSsEditorRowsState({
      rowIds: rowIds,
      spreadsheetId: spreadsheetId,
      error: sig.error,
      removed: sig.removed
    }));
  }

  setSsEditorRowMutation(spreadsheetId: MetaIdSpreadsheet): void
  {
    if(!this.cacheSsEditorRowMutation)
    {
      store.dispatch(setCacheSsEditorRowMutation(spreadsheetId));
      this.doSsEditorRowMutation = undefined;
      this.cacheSsEditorRowMutation = true;
    }
    else
    {
      this.doSsEditorRowMutation = spreadsheetId;
    }
  }

  setCacheSsEditorBulkImportStatus(
    spreadsheetId: MetaIdSpreadsheet,
    bulkImportStatus: TypeBulkImportStatusType): void
  {
    store.dispatch(setCacheSsEditorBulkImportStatus({
      spreadsheetId: spreadsheetId,
      bulkImportStatus: bulkImportStatus
    }));
  }

  clearSsState(spreadsheetId: MetaIdSpreadsheet): void
  {
    store.dispatch(clearCacheSsEditorState(spreadsheetId));
  }

  protected doTick(dateNow: Date)
  {
    if(this.currentTick >= this.maxTick)
    {
      this.currentTick = 0;
      if(this.cacheSsEditorRowMutation)
      {
        this.cacheSsEditorRowMutation = false;
        if(this.doSsEditorRowMutation)
        {
          this.setSsEditorRowMutation(this.doSsEditorRowMutation);
        }
      }
    }
    else
    {
      this.currentTick++;
    }
    super.doTick(dateNow);
  }
}
