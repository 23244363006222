import _ from "lodash";
import {UrlProvider} from "reconnecting-websocket";
import {nextRequestId} from "../../api/meta/base/ApiPlus";
import ISrvcAuth from "../ISrvcAuth";
import {logAssert} from "../util/AppLog";
import {isSslEnabled} from "./SysPlus";

export class HostPortProvider
{
  private readonly hostPorts: string[];
  private hostPortIndex: number;
  private hostPort: string;

  constructor()
  {
    //TODO for server team to test with localHost
    const localStorageServerHostPorts = localStorage.getItem("REACT_APP_SERVER_HOST_PORTS");
    // @ts-ignore
    let hostPorts = (localStorageServerHostPorts || process.env.REACT_APP_SERVER_HOST_PORTS).split(",");
    logAssert("HostPort", hostPorts.length >= 1, "Server hosts and ports are required");

    this.hostPorts = _.shuffle(hostPorts);
    this.hostPortIndex = 0;
    this.hostPort = this.hostPorts[0];
  }

  getHostPort(): string
  {
    return this.hostPort;
  }

  wsocUrlProvider(srvcAuth: ISrvcAuth): UrlProvider
  {
    const self = this;
    return function(): string
    {
      const hostPort = self.hostPort;
      const bearerToken = srvcAuth.getBearerToken();

      if(!bearerToken)
      {
        return `${isSslEnabled() ? "wss" : "ws"}://${hostPort}/wsoc?requestId=${nextRequestId()}`;
      }

      self.nextHostPort();
      return `${isSslEnabled() ? "wss" : "ws"}://${hostPort}/wsoc?requestId=${nextRequestId()}&bearer=${bearerToken}`;
    };
  }

  private nextHostPort(): void
  {
    let index = this.hostPortIndex + 1;
    if(index >= this.hostPorts.length)
    {
      index = 0;
    }

    this.hostPort = this.hostPorts[index];
    this.hostPortIndex = index;
  }
}

export const hostPortProvider = new HostPortProvider();
