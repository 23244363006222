import {CameraAltRounded} from "@mui/icons-material";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import {Button} from "@mui/material";
import {Typography} from "@mui/material";
import {Fab} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import {useMemo} from "react";
import React, {useCallback, useRef} from "react";
import Webcam from "react-webcam";
import {px} from "../../../base/plus/StringPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import DialogAtom, {IDialogAtomRef} from "./DialogAtom";

export default function DialogCamera(props: {
  onClose: () => void
  onCaptureFile: (file: File) => void
  cropQuality?: number
  fullScreen?: boolean
  isMobile?: boolean
  switchCam?: boolean
})
{
  const cbDialogRef = useMemo(() => ({} as IDialogAtomRef), []);
  const isMobile = props.isMobile;
  const switchCam = props.switchCam;
  return (
    <DialogAtom
      cbRef={cbDialogRef}
      title="Take photo"
      contentWidth={640}
      contentHeight={540}
      fullScreen={props.fullScreen}
      onClose={props.onClose}
      content={<DialogCameraContent
        onCaptureFile={props.onCaptureFile}
        cropQuality={props.cropQuality}
        cbDialogRef={cbDialogRef}
        isMobile={isMobile}
        switchCam={switchCam}
      />}
    />
  );
}

function DialogCameraContent(props: {
  onCaptureFile: (file: File) => void
  cropQuality?: number
  cbDialogRef: IDialogAtomRef
  switchCam?: boolean
  isMobile?: boolean
})
{
  type TypeFacingMode = "user" | "environment"

  const [facingMode, setFacingMode] = useState<TypeFacingMode>();

  const switchCamera = useCallback(() =>
  {
    setFacingMode((prevState) =>
      prevState === "user"
        ? "environment"
        : "user"
    );
  }, []);

  const webcamRef = useRef<Webcam>(null);
  const onCaptureFile = props.onCaptureFile;
  const cropQuality = props.cropQuality ?? 1;
  const cbDialogRef = props.cbDialogRef;
  const isMobile = props.isMobile;
  const switchCam = props.switchCam;
  const [isPermissionGranted, setIsPermissionGranted] = useState<Boolean>(true);
  var stream: MediaStream;
  const capture = useCallback(
    () =>
    {
      cbDialogRef.setSpinner(true);
      const canvas = webcamRef?.current?.getCanvas();
      canvas && canvas.toBlob((blob: Blob | null) =>
      {
        if(blob)
        {
          onCaptureFile(blob as File);
        }
        else
        {
          cbDialogRef.setAlert("Could not read captured file");
        }
      }, "image/jpeg", cropQuality);
    }, [cbDialogRef, cropQuality, onCaptureFile]);

  const videoConstraints = useMemo(() => ({
    height: isMobile ? "80%" : 420,
    width: isMobile ? "100%" : 640,
    facingMode: facingMode as TypeFacingMode
  } as MediaTrackConstraints), [facingMode, isMobile]);

  const checkCameraPermissions = async() =>
  {
    try
    {
      stream = await navigator.mediaDevices.getUserMedia({video: true});
      setIsPermissionGranted(true);
    }
    catch(error)
    {
      setIsPermissionGranted(false);
    }
  };
  useEffect(() =>
  {
    checkCameraPermissions();
  }, [isPermissionGranted]);

  useEffect(() =>
  {
    return () =>
    {

      if(webcamRef && webcamRef.current)
      {
        webcamRef?.current?.stream?.getTracks().forEach((track) =>
        {
          track.stop();
        });
      }
      if(stream)
      {
        stream.getVideoTracks().forEach((track) =>
        {
          track.stop();
        });
      }
    };
  }, []);

  return (
    <LayoutFlexCol
      width={"100%"}
      flexGrow={1}
    >
      {isPermissionGranted
        ?
        <Webcam
          audio={false}
          ref={webcamRef}
          height={"100%"}
          screenshotFormat="image/jpeg"
          width={"100%"}
          videoConstraints={videoConstraints}
          mirrored={true}

          style={{
            objectFit: "cover",
            maxHeight: "800px",
            maxWidth: "640px"
          }}
        />
        :
        <LayoutFlexCol
          height={"100%"}
        >
          <Typography>Please enable the camera permission</Typography>
          <Button onClick={checkCameraPermissions}>retry</Button>
        </LayoutFlexCol>
      }
      <LayoutFlexRow
        width={"100%"}
        flexGrow={1}
      >{
        isPermissionGranted &&
        <Fab
          aria-label="arrow"
          size="medium"
          color="primary"
          onClick={capture}
          sx={{
            marginRight: px(gapStd),
            marginBottom: isMobile ? "30px" : "10px",
            marginTop: "10px",
            position: "sticky"
          }}
        >
          <CameraAltRounded />
        </Fab>
      }
        {switchCam && isMobile && isPermissionGranted &&
          <Fab
            aria-label="arrow"
            size="medium"
            color="primary"
            onClick={switchCamera}
            sx={{
              marginLeft: px(gapStd),
              marginBottom: "30px",
              marginTop: "10px",
              position: "sticky"
            }}
          >
            <CameraswitchIcon />
          </Fab>
        }

      </LayoutFlexRow>
    </LayoutFlexCol>
  );
}

