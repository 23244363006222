import React from "react";
import {getListItemHeight} from "../../../../base/plus/ListPlus";
import {getListItemHeightMPSL} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {useAppSelector} from "../../../app/AppHooks";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";

export default function FieldRawListShell(props: {
  list: React.ReactNode,
  selectList: SelectList,
  buttonStrip?: React.ReactNode,
  showButtonStripAtBottom?: boolean,
  autoHeight?: boolean
})
{
  const showButtonStripAtBottom = props.showButtonStripAtBottom;
  const totalHeight = useCalcTotalListItemsHeightMPSL(props.selectList);
  const defaultListItemType = useAppSelector(state => props.selectList(state).defaultListItemType);

  return (
    <LayoutFlexCol
      width={"100%"}
      flexGrow={1}
      height={defaultListItemType === "form" ? "100%" : undefined}
      justifyContent={"start"}
    >
      <LayoutFlexCol
        {
          ...showButtonStripAtBottom
            ? {
              flexGrow: 1,
              width: "100%"
            }
            : {
              width: "100%",
              height: totalHeight ? px(totalHeight) : "inherit"
            }}
        {
          ...props.autoHeight && {height: px(totalHeight)}
        }
      >
        {props.list}
      </LayoutFlexCol>

      {
        props.buttonStrip &&

        <LayoutFlexRow
          width={"100%"}
          justifyContent={"flex-end"}
          pr={px(gapStd)}
          pt={px(gapStd)}
        >
          {props.buttonStrip}
        </LayoutFlexRow>
      }
    </LayoutFlexCol>
  );
}

function useCalcTotalListItemsHeightMPSL(selectList: SelectList)
{
  let height = 0;
  const defaultListItemType = useAppSelector(state => selectList(state).defaultListItemType);
  const itemsById = useAppSelector(state => selectList(state).itemsById);
  const displayItemIds = useAppSelector(state => selectList(state).itemIds);
  if(defaultListItemType === "mpsl" || defaultListItemType === undefined)
  {
    const length = displayItemIds?.length || 0;
    height += length > 1 ? length - 1 : 0;  // this is for borders
    if(itemsById)
    {
      displayItemIds && displayItemIds.forEach(itemId =>
      {
        const item = itemsById[itemId] as IListItemMPSL;
        if(item)
        {
          height += getListItemHeightMPSL((item.secondaryList?.length || 0),
            Boolean(item.showMediaPlaceholder),
            "list"
          );
        }
      });
    }
  }
  else if(defaultListItemType === "form")
  {
    return undefined;
  }
  else
  {
    const length = displayItemIds?.length || 0;
    const defaultItemHeight = getListItemHeight(defaultListItemType);
    height = (defaultItemHeight || 0) * length;
    height += length > 1 ? length - 1 : 0;  // this is for borders
  }
  return height;
}
