import {sliceFakeChat} from "./SliceChat";

export const {
  chatInit,
  chatLoad,
  chatAppend,
  chatPrepend,
  chatAppendMsg,

  chatSetAppending,
  chatSetPrepending,
  chatSetPageOffset,
  chatSetPickMode,
  chatTogglePick,
  chatSetSelectedItemId,
  chatItemHover,
  chatSetScrollToLast,
  chatSetScrollToItemId,
  chatSetJumpToLast,

  chatSetUiAtBottom,
  chatSetUiScrolling,
  chatSetUiFastScrolling,
  chatSetUiBlinkItemId,

  chatSetIfExistDefnForm,
  chatSetIfExistSpreadsheetRow,
  chatSetFormBubbleTitleColor,
  chatSetIfExistSpreadsheetRowComment,
  chatSetIfExistDefnFormInputValue,
  chatSetIfExistSigSpreadsheetRowExpiry,

  chatSetIfExistIsDownloading,
  chatSetIfExistIsStar,
  chatSetIfExistMarkAsRead,
  chatSetIfExistHeader,
  chatSetIfExistInitiatorUserAvatarName,
  chatSetIfExistTargetUserAvatarName,
  chatSetIfExistIsCommentable,
  chatSetIfExistIsFormWithMedia,
  chatSetIfExistIsVisibleSpreadsheetRow,
  chatSetIfExistChatPatternVar,
  chatSetIfExistIsMsgForwardable,
  chatSetIfExistMsgReply,
  chatSetIfExistIsCanExpire,
  chatSetIfExistMessage,

  chatHandleSigTopicMessageProps,
  chatHandleSigTopicMessageRemoveForMe,
  chatHandleSigTopicFormRemove,

  chatSetCallerEntUserId,
  chatSetCallerEntMediaId,
  chatSetDisplayShakeFieldIdMap,
  chatSetDisplayBlinkFieldIdMap,
  chatSetDisplayHiddenFieldIdMap,
  chatSetHighlightFieldIdMap,
  chatSetDisplayDateFormat,
  chatSetAppendingNewMsg
} = sliceFakeChat.actions;
