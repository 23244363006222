import ISrvc from "../../../base/ISrvc";
import SrvcControlPanelDrawerFilter from "./SrvcControlPanelDrawerFilter";
import SrvcControlPanelDrawerRecentList from "./SrvcControlPanelDrawerRecentList";
import SrvcControlPanelDrawerSearch from "./SrvcControlPanelDrawerSearch";

export class SrvcControlPanelDrawer extends ISrvc
{
  public readonly recentList = new SrvcControlPanelDrawerRecentList();
  public readonly search = new SrvcControlPanelDrawerSearch(state => state.production.drawer.productionSearch);
  public readonly filter = new SrvcControlPanelDrawerFilter();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.recentList,
      this.search,
      this.filter
    );
  }
}
