import React from "react";
import {DefnFieldRefUser} from "../../../../api/meta/base/dto/DefnFieldRefUser";
import FieldRawUser from "../pro/FieldRawUser";

export default function FieldRefUser(props: {
  defn: DefnFieldRefUser
})
{
  return (
    <FieldRawUser
      defn={props.defn}
    />
  );
}
