import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEmail} from "../../../api/meta/base/dto/DefnFieldEmail";
import {FieldValueText} from "../../../api/meta/base/dto/FieldValueText";
import {StudioVarEmail} from "../../../api/meta/base/dto/StudioVarEmail";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";

export function fnVariableBuilderEmail(fieldValueEmail: string)
{
  function getVarComp()
  {
    return {
      [fieldValueEmail]: {
        type: "email",
        metaId: fieldValueEmail,
        required: true,
        label: "Value"
      } as DefnFieldEmail
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarEmail
  )
  {
    valueMap[fieldValueEmail] = fnRawValueToFieldValue("text", variable.value?.value);
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = fnFieldValueToRawValue("text", valueMap[fieldValueEmail] as FieldValueText);

    return {
      value: {
        value: value
      }
    } as StudioVarEmail;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
