import {useEffect} from "react";
import React from "react";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {FormValueRaw} from "../../../../api/meta/base/dto/FormValueRaw";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {createCalendarBubble} from "../../../../base/plus/CalendarPlus";
import {STR_NOTHING_HERE} from "../../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {listReset} from "../../../../base/slices/list/SliceList";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeCalendarItemId} from "../../../../base/types/TypeCalendar";
import {ICalendarEvent} from "../../../../base/types/TypeCalendar";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import {useAppSelector} from "../../../app/AppHooks";
import helperTextData from "../../../atom/assets/PlaceholderTextHome.json";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import PaneSide from "../../../atom/pane/PaneSide";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import {List} from "../../../list/List";

export default function CalendarItemAside(props: {
  selectCalendar: SelectCalendar,
  events: ICalendarEvent[],
  defnForm?: DefnForm,
  selectList: SelectList,
  cbOnClickBack: () => void
})
{
  const selectList = props.selectList;
  const defnForm = props.defnForm;
  const events = props.events;
  const selectCalendar = props.selectCalendar;
  const cbOnClickBack = props.cbOnClickBack;
  const listName = useAppSelector(state => selectList(state).listName);
  const calendarItem = useAppSelector(state => selectCalendar(state).itemsById);

  useEffect(() =>
  {
    if(defnForm)
    {
      doLoad(listName, defnForm, events, calendarItem);
    }

    return () =>
    {
      dispatchList(listName, listReset());
    };
  }, []);

  return (
    <PaneSide
      primaryText={"calendar item"}
      onClickAction={cbOnClickBack}
    >
      <LayoutFlexCol
        width={"100%"}
        height={"100%"}
        overflowY={"auto"}
      >
        <List
          selectList={state => selectList(state)}
          emptyComp={<RawNothingHere helperTextData={helperTextData.nothingHere} />}
          emptySearch={STR_NOTHING_HERE}
        />
      </LayoutFlexCol>
    </PaneSide>
  );
}

function doLoad(
  listName: string,
  defnForm: DefnForm,
  events?: ICalendarEvent[],
  values?: Record<TypeCalendarItemId, FormValueRaw>)
{
  const uiItemsById = {} as IListItemsById;
  const uiItemIds = [] as TypeComboId[];

  if(events?.length !== 0 && values)
  {
    events?.forEach((event) =>
    {
      const formValue = values[event.itemId];
      const bgcolor = event.bgColor;

      if(formValue && defnForm)
      {
        uiItemIds.push(event.itemId);
        uiItemsById[event.itemId] = createCalendarBubble(defnForm, formValue, bgcolor);
      }
    });
  }

  dispatchList(listName, listRefresh({
    itemIds: uiItemIds,
    itemsById: uiItemsById
  }));
}
