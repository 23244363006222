import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickLayoutSpreadsheetId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutSpreadsheetId";
import {StudioDtoLayoutGrid} from "../../../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioEntSpreadsheet} from "../../../../api/meta/base/dto/StudioEntSpreadsheet";
import {EnumDefnLayoutGridKind} from "../../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldRawStudioPickLayoutSpreadsheetId(props: {
  defn: DefnStudioPickLayoutSpreadsheetId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const spreadsheetMetaId = defn.spreadsheetId;
  const filterLayoutKindSet = defn.filterLayoutKindSet;
  const showAlias = defn.showAlias;

  const formStore = formCtx.getStore();
  const studioSpreadsheet = formStore?.spreadsheetMap?.map[spreadsheetMetaId];

  const fieldValue = props.fieldProps.field.value;

  const excludeSpreadsheetLayoutIdSet = props.defn.excludeLayoutSpreadsheetIdSet;

  const dtoOptions = useMemo(() => studioSpreadsheet
    ? getPickLayoutGridOptions(studioSpreadsheet, filterLayoutKindSet, excludeSpreadsheetLayoutIdSet, showAlias)
    : [], [filterLayoutKindSet, excludeSpreadsheetLayoutIdSet, showAlias, studioSpreadsheet]);

  const [options] = useInsertTrashOptions({
    type: "layoutSpreadsheetMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    multiSelect={props.multiSelect}
    showChip={props.showChip}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getPickLayoutGridOptions(
  studioSpreadsheet: StudioEntSpreadsheet,
  filterLayoutKindSet?: EnumDefnLayoutGridKind[],
  excludeSpreadsheetLayoutIdSet?: MetaIdLayoutGrid[],
  showAlias?: boolean
): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];
  const layoutMap = studioSpreadsheet.layoutSpreadsheetMap;
  const spreadsheetName = studioSpreadsheet.details.name;

  if(!layoutMap)
  {
    return options;
  }

  layoutMap.keys.forEach((key) =>
  {
    const studioLayoutSpreadsheet = layoutMap.map[key] as StudioDtoLayoutGrid;

    const label = showAlias
      ? `${spreadsheetName}.${studioLayoutSpreadsheet.name}`
      : `${studioLayoutSpreadsheet.name}`;
    const optionId = studioLayoutSpreadsheet.metaId;
    const layoutMode = studioLayoutSpreadsheet.kind;

    if(filterLayoutKindSet && !filterLayoutKindSet.includes(layoutMode))
    {
      return;
    }

    if(excludeSpreadsheetLayoutIdSet && excludeSpreadsheetLayoutIdSet.includes(optionId))
    {
      return;
    }

    options.push({
      value: label || optionId,
      metaId: optionId
    });
  });

  return options;
}


