import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../../base/plus/ListPlus";

export function reducersHomeAsideUserInfo()
{
  return combineReducers({
    commonGroupList: createListSlice("homeAsideUserInfoCommonGroupList",
      {
        defaultListItemType: "loadMore",
        loadMoreItemLimit: 10
      }
    ).reducer
  });
}
