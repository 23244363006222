import React from "react";
import {StudioDtoLayoutCard} from "../../api/meta/base/dto/StudioDtoLayoutCard";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {EnumDefnLayoutGridKind, MetaIdForm} from "../../api/meta/base/Types";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import DialogNewLayoutSpreadsheetBase from "./base/impl/DialogNewLayoutSpreadsheetBase";

export default function DialogNewLayoutSpreadsheetCard(props: {
  formStore: FormStore,
  spreadsheetId: MetaIdSpreadsheet;
  isFormReadOnly?: boolean,
  metaIdForm?: MetaIdForm,
  layoutType?: EnumDefnLayoutGridKind,
  values?: StudioDtoLayoutCard,
  defaultValues?: StudioDtoLayoutCard,
  onClickOk: (values: StudioDtoLayoutCard) => void,
  onClose?: () => void,
  isOverride?: boolean,
  excludeFieldIdSet?: MetaIdField[],
  overRideCompositeIdSet?: MetaIdComposite[],
  validationError?: IFormFieldError[]
})
{
  const excludeSpreadsheetLayoutIdSet = props.values?.metaId ? [props.values.metaId] : undefined;
  return <DialogNewLayoutSpreadsheetBase
    {...props}
    layoutType={"card"}
    excludeSpreadsheetLayoutIdSet={excludeSpreadsheetLayoutIdSet}
  />;
}
