import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {gapStd} from "../../../base/plus/ThemePlus";

export function AudioBarDurationTimer(props: {
  durationMs?: number,
  showDuration?: boolean,
  caption?: React.ReactNode,
})
{
  const showDuration = props.showDuration || true;
  const durationMs = props.durationMs ? (props.durationMs / 1000) : 0;
  const setAudioDuration = (num: number) =>
  {
    const time = parseInt(num.toString().padStart(2, "0"));
    const minute = Math.round(time / 60);
    const seconds = Math.round(time % 60);

    return `${minute <= 9 ? "0" + minute : minute}:${seconds <= 9 ? "0" + seconds : seconds}`;
  };

  const [timer, setTimer] = useState(setAudioDuration(durationMs));

  useEffect(() =>
  {
    setTimer(setAudioDuration(durationMs));
  }, [durationMs]);

  if(showDuration)
  {
    return (
      <Box pr={px(gapStd)} pl={px(gapQuarter)}>
        {showDuration && (
          <Typography
            color={"black"}
            variant={"caption"}
            display="block"
          >
            {timer}
          </Typography>
        )}
        {props.caption && props.caption}
      </Box>
    );
  }
}
