import {combineReducers} from "@reduxjs/toolkit";
import {sliceCacheHomeDrawerFilter} from "./filter/SliceCacheHomeDrawerFilter";
import {sliceCacheHomeDrawerMsgLast} from "./msgLast/SliceCacheHomeDrawerMsgLast";
import {sliceCacheHomeDrawerRecentList} from "./recent/SliceCacheHomeDrawerRecentList";

export function reducersCacheHomeDrawer()
{
  return combineReducers({
    filter: sliceCacheHomeDrawerFilter.reducer,
    msgLast: sliceCacheHomeDrawerMsgLast.reducer,
    recent: sliceCacheHomeDrawerRecentList.reducer
  });
}
