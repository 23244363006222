import theme from "../../plus/ThemePlus";
import {IListItem} from "./TypesList";

export type ListItemTypeGap =
  | "gapQuarter"
  | "gapHalf"
  | "gapStd";

export function getListItemHeightGap(typeListItem: ListItemTypeGap): number
{
  switch(typeListItem)
  {
    case "gapQuarter":
      return theme.common.gapQuarter;
    case "gapHalf":
      return theme.common.gapHalf;
    case "gapStd":
      return theme.common.gapStd;
  }

  throw new Error("Gap not configured");
}

export function createListItemGapQuarter()
{
  return {
    type: "gapQuarter"
  } as IListItem;
}

export function createListItemGapHalf()
{
  return {
    type: "gapHalf"
  } as IListItem;
}

export function createListItemGapStd()
{
  return {
    type: "gapStd"
  } as IListItem;
}
