import {AxiosRequestConfig} from "axios";
import {ISig} from "../../api/meta/base/sig/ISig";
import {MediaId} from "../../api/meta/base/Types";
import {ServiceName} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {IMediaCall} from "../../api/nucleus/base/IMediaCall";
import {ISigAcceptor} from "../../api/nucleus/base/ISigAcceptor";
import ISrvcAuth from "../ISrvcAuth";
import MediaCall from "./MediaCall";

export default class MediaCallWithRetry extends MediaCall
{
  constructor(
    public readonly srvcAuth: ISrvcAuth,
    public readonly entId: EntId,
    public readonly serviceName: ServiceName,
    public readonly mediaId: MediaId
  )
  {
    super(srvcAuth, entId, serviceName, mediaId);
  }

  execute(config: AxiosRequestConfig, sigAcceptor: ISigAcceptor<ISig>): IMediaCall
  {
    const self = this;
    return super.execute(config, (envSig: EnvSignal<ISig>) =>
    {
      const envError = envSig.error;
      if(envError)
      {
        const errorCode = envError.errorCode;
        if(errorCode === "unauthorizedRefreshToken")
        {
          self.srvcAuth.rpcSignOut();
        }
        else if(errorCode === "unauthorizedBearerToken")
        {
          // do not dispatch flag bearer token since we are retrying the message
          //store.dispatch(setFlagBearerToken(false));

          // does not throw UnauthorizedBearerToken
          self.srvcAuth.rpcGrantBearerToken(true, rpcEnvSig =>
          {
            if(rpcEnvSig.error)
            {
              if(sigAcceptor)
              {
                sigAcceptor(errorSig<ISig>(rpcEnvSig));
              }
            }
            else
            {
              self.execute(config, sigAcceptor);
            }
          });

          return;
        }
      }

      if(sigAcceptor)
      {
        sigAcceptor(envSig);
      }
    });
  }
}

function errorSig<T extends ISig>(envSig: EnvSignal<ISig>): EnvSignal<T>
{
  envSig.sig = undefined;
  return envSig as EnvSignal<T>;
}
