import {Variant} from "@mui/material/styles/createTypography";
import {isEqual} from "lodash";
import React from "react";
import {useMemo} from "react";
import {SigUserAvatar} from "../../../../../../api/home/drawer/sig/SigUserAvatar";
import {DefnFieldSetOfUser} from "../../../../../../api/meta/base/dto/DefnFieldSetOfUser";
import {FieldSetOfEntUserId} from "../../../../../../api/meta/base/dto/FieldSetOfEntUserId";
import {EntUserId} from "../../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../../api/meta/base/Types";
import {textUser} from "../../../../../../base/plus/SrvcPlus";
import {RootState} from "../../../../../../Store";
import {useAppSelector} from "../../../../../app/AppHooks";
import RawHighlighter from "../../../../raw/RawHighlighter";

export default function BubbleFieldSetOfUser(props: {
  field: DefnFieldSetOfUser,
  variant: Variant,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const field = props.field;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;

  const valueSet = useMemo(() =>
  {
    return valueMap?.[fieldId] as FieldSetOfEntUserId | undefined;

  }, [fieldId, valueMap]);

  const usersText = useAppSelector(state =>
  {
    return valueSet?.valueSet.map((userId, index) =>
    {
      const user = props.selectUserAvatar?.(state, userId);
      return (user ? textUser(user) : valueSet?.displaySet?.[index]);
    });

  }, isEqual);

  const value = useMemo(() => usersText?.join(", "), [usersText]);

  return (
    <RawHighlighter
      variant={props.variant}
      value={value}
      color={"textSecondary"}
      searchWords={props.searchWords}
      isUserSelect={true}
      flex={1}
    />
  );
}
