import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdModule} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {StudioModuleMap} from "../../../../api/meta/base/dto/StudioModuleMap";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {fieldReportLeftLabel} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import RawChipsInput from "../../../atom/raw/RawChipsInput";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldBase from "../raw/FieldBase";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";

export default function FieldStudioBuildAllModule(props: {
  defn: DefnFieldEditable,
})
{
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnTheme = formCtx.getDefnTheme();
  const label = Boolean(defn.required)
    ? getCompLabel(defn) + " *"
    : getCompLabel(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
  const padding = getDefnFieldPadding(defn);

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as StudioModuleMap | undefined;
        const onChange = ((value?: DefnDtoOption[]) =>
        {
          field.onChange(onChangeValueToFieldValue(value, fieldValue));
        });

        if(sectionVariant === "propertyEditor")
        {
          return (
            <FieldBase fieldId={fieldId} {...padding}>
              <FieldRawKeyValuePair

                leftHeight={labelHeight}
                left={
                  <RawHighlighter
                    variant={"caption"}
                    value={fieldReportLeftLabel(props.defn)}
                  />
                }
                right={
                  <FormControl
                    fullWidth
                    variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
                    error={isError}
                  >
                    <RawChipsInput
                      chipList={fieldValueToString(fieldValue)}
                      placeHolder={defnDtoTextToString(defn.placeHolderVar)}
                      label={label}
                      required={defn.required}
                      name={defn.name}
                      onChange={onChange}
                      readOnly={formCtx.isReadonly()}
                      disabled={formCtx.isFieldDisable(defn as DefnFieldEditable)
                        || defn.disabled}
                      onBlur={field.onBlur}
                      ref={field.ref}
                      isError={isError}
                      autoFocus={Boolean(defn.autoFocus)}
                      fieldId={fieldId}
                      formCtx={formCtx}
                    />

                    {
                      isError &&
                      <FormHelperText
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          flexGrow: 1
                        }}
                      >
                        {error?.message}
                      </FormHelperText>
                    }
                  </FormControl>
                }
              />
            </FieldBase>
          );
        }

        return (
          <FieldBase fieldId={fieldId} {...padding}>
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <RawChipsInput
                chipList={fieldValueToString(fieldValue)}
                placeHolder={defnDtoTextToString(defn.placeHolderVar)}
                label={label}
                required={defn.required}
                name={defn.name}
                onChange={onChange}
                readOnly={formCtx.isReadonly()}
                disabled={formCtx.isFieldDisable(defn as DefnFieldEditable)
                  || defn.disabled}
                onBlur={field.onBlur}
                ref={field.ref}
                isError={isError}
                autoFocus={Boolean(defn.autoFocus)}
                fieldId={fieldId}
                formCtx={formCtx}
              />

              {
                isError &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message}
                </FormHelperText>
              }
            </FormControl>
          </FieldBase>
        );
      }}
    />
  );
}

function fieldValueToString(dto?: StudioModuleMap): DefnDtoOption[] | undefined
{
  const moduleOptions = [] as DefnDtoOption[];

  if(dto && dto.keys.length > 0)
  {
    dto.keys.forEach(moduleId =>
    {
      moduleOptions.push({
        metaId: moduleId,
        value: dto.map[moduleId].name
      });
    });
  }

  return moduleOptions;
}

function onChangeValueToFieldValue(value?: DefnDtoOption[], fieldValue?: StudioModuleMap): StudioModuleMap
{
  const dto = {
    map: {},
    keys: []
  } as StudioModuleMap;

  const allModuleKeys = fieldValue?.keys;

  if(value)
  {
    value.forEach(val =>
    {
      const moduleId = allModuleKeys?.includes(val.metaId)
        ? val.metaId
        : nextMetaIdModule();

      dto.keys.push(moduleId);
      dto.map[moduleId] = {
        metaId: moduleId,
        name: val.value
      };
    });
  }

  return dto;
}
