import ISrvc from "../../../base/ISrvc";
import SrvcHomeMainChat from "./SrvcHomeMainChat";
import SrvcHomeMainHeader from "./SrvcHomeMainHeader";

export default class SrvcHomeMain extends ISrvc
{
  public readonly chat = new SrvcHomeMainChat();
  public readonly header = new SrvcHomeMainHeader();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.chat,
      this.header
    );
  }
}


