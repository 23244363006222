import {FieldValues} from "react-hook-form";
import {DefnFieldNumber} from "../../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldParagraph} from "../../../../../api/meta/base/dto/StudioFieldParagraph";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyLineCount} from "../../base/TypesFormBuilder";
import {propKeyLineCountFieldId} from "../../base/TypesFormBuilder";
import {propKeyMaxCharCount} from "../../base/TypesFormBuilder";
import {propKeyMinCharCount} from "../../base/TypesFormBuilder";
import {propKeyMaxCharCountFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinCharCountFieldId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyFlexHeight} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueTextVar} from "../../base/TypesFormBuilder";
import {propKeyLineCountVar} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyMinCharacterCount} from "../../base/TypesFormBuilder";
import {propKeyMaxCharacterCount} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldParagraph(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinCharCountFieldId,
    propKeyMaxCharCountFieldId,
    propKeyLineCountFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueTextVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinCharacterCount,
    propKeyMaxCharacterCount,
    propKeyLineCountVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    propKeyFlexHeight,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMinCharCount,
    propKeyMaxCharCount,
    propKeyLineCount
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "paragraph",
      formId,
      "paragraph",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyLineCount]: {
      type: "number",
      name: propKeyLineCount,
      metaId: propKeyLineCount,
      label: "Line count"
    } as DefnFieldNumber,

    [propKeyLineCountVar]: {
      type: "pickVarId",
      name: propKeyLineCountVar,
      metaId: propKeyLineCountVar,
      label: "Line count",
      varKind: "number",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyLineCountFieldId]: {
      type: "pickFieldId",
      name: propKeyLineCountFieldId,
      metaId: propKeyLineCountFieldId,
      label: "Line count",
      formId: formId,
      filterFieldTypeSet: ["number"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyFlexHeight]: {
      type: "bool",
      name: propKeyFlexHeight,
      metaId: propKeyFlexHeight,
      label: "Flex grow",
      showAsCheckboxVar: true
    } as DefnFieldSwitch
  };
}

export function defnValueToStudioFieldParagraph(values: FieldValues): StudioFieldParagraph
{
  return {
    type: "paragraph",
    defaultValue: fnFieldValueToRawValue("paragraph", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("studioVarIdParagraphEditor", values[propKeyDefaultValueTextVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    minCharCount: fnFieldValueToRawValue("number", values[propKeyMinCharCount]),
    minCharCountVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinCharacterCount]),
    minCharCountFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinCharCountFieldId]),
    maxCharCount: fnFieldValueToRawValue("number", values[propKeyMaxCharCount]),
    maxCharCountVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxCharacterCount]),
    maxCharCountFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxCharCountFieldId]),
    lineCount: fnFieldValueToRawValue("number", values[propKeyLineCount]),
    lineCountVarId: fnFieldValueToRawValue("pickVarId", values[propKeyLineCountVar]),
    lineCountFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyLineCountFieldId]),
    flexHeight: fnFieldValueToRawValue("bool", values[propKeyFlexHeight])
  } as StudioFieldParagraph;
}

export function studioFieldParagraphToDefnValue(studioField: StudioFieldParagraph): FieldValues
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("paragraph", studioField.defaultValue),
    [propKeyDefaultValueTextVar]: fnRawValueToFieldValue("studioVarIdParagraphEditor", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMinCharCount]: fnRawValueToFieldValue("number", studioField.minCharCount),
    [propKeyMinCharacterCount]: fnRawValueToFieldValue("pickVarId", studioField.minCharCountVarId),
    [propKeyMinCharCountFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minCharCountFieldId),
    [propKeyMaxCharCount]: fnRawValueToFieldValue("number", studioField.maxCharCount),
    [propKeyMaxCharacterCount]: fnRawValueToFieldValue("pickVarId", studioField.maxCharCountVarId),
    [propKeyMaxCharCountFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxCharCountFieldId),
    [propKeyLineCount]: fnRawValueToFieldValue("number", studioField.lineCount),
    [propKeyLineCountVar]: fnRawValueToFieldValue("pickVarId", studioField.lineCountVarId),
    [propKeyLineCountFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.lineCountFieldId),
    [propKeyFlexHeight]: fnRawValueToFieldValue("bool", studioField.flexHeight)
  };
}
