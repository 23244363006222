import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickFieldId from "../raw/FieldRawStudioPickFieldId";

export default function FieldStudioPickFieldId(props: {
  defn: DefnStudioPickFieldId
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickFieldId
        fieldProps={fieldProps}
        defn={props.defn}
        multiSelect={false}
      />}
    />
  );
}
