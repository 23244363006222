import React from "react";
import {AnyString} from "../../../../api/meta/base/AnyString";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueLocation} from "../../../../api/meta/base/dto/FieldValueLocation";
import {EnumDefnCaptureValueKind} from "../../../../api/meta/base/Types";
import {GeoPoint} from "../../../../api/meta/base/Types";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {openSelectedGeoPoint} from "../../../../base/plus/LocationPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawTooltip from "../../../atom/raw/RawTooltip";

type CaptureValueType = "Time"
  | "Loc"
  | "User";

interface ICaptureValue
{
  captureLocation?: FieldValueLocation;
  captureTime?: string;
  captureUser?: FieldValueEntUserId;
  icon?: string,
  type?: CaptureValueType
}

export default function RawCaptureExtraProperties(props: {
  captureTime?: string;
  captureUser?: FieldValueEntUserId;
  captureLocation?: FieldValueLocation;
  showCapturedAsHelperText?: EnumDefnCaptureValueKind[]
})
{
  const systemCaptureValues = getCaptureHelperText(
    props.captureTime,
    props.captureUser,
    props.captureLocation,
    props.showCapturedAsHelperText
  );
  const onClickIcon = (latLng: AnyString) =>
  {
    const lat = parseFloat(latLng?.split(",")[0]);
    const lng = parseFloat(latLng?.split(",")[1]);

    if(lat && lng)
    {
      openSelectedGeoPoint(lat, lng);
    }
  };

  if(!systemCaptureValues)
  {
    return null;
  }

  return (
    <LayoutFlexCol
      pt={px(gapQuarter)}
      pb={px(gapQuarter)}
    >
      {
        Object.entries(systemCaptureValues).map(([key, value]) => (
          value
            ?
            <LayoutFlexRow
              key={key}
              width={"100%"}
              justifyContent={"flex-start"}
            >
              <RawHighlighter
                variant={"caption"}
                value={value?.type + ": "}
                breakWords={true}
              />
              <PropertyValue
                systemCaptureValue={value}
                onClickIcon={onClickIcon}
              />
            </LayoutFlexRow>
            : null
        ))
      }
    </LayoutFlexCol>
  );
}

function PropertyValue(props: {
  systemCaptureValue: ICaptureValue,
  onClickIcon?: (geoPoint: GeoPoint) => void
})
{
  const systemCaptureValue = props.systemCaptureValue;

  const getLabel = (value: ICaptureValue) =>
  {
    if(value.type === "Time")
    {
      return value?.captureTime
        ? formatCaptionOnlyTime(new Date(value?.captureTime))
        : undefined;
    }
    else if(value.type === "Loc")
    {
      return value.captureLocation?.value.address;
    }
    else if(value.type === "User")
    {
      return value.captureUser?.displayField;
    }
  };

  if(systemCaptureValue.icon)
  {
    return (
      <RawTooltip
        title={{
          primary: getLabel(systemCaptureValue) ?? "",
          secondary: ""
        }}
      >
        <span>
          <RawIconButton
            name={systemCaptureValue?.type ? systemCaptureValue.type : ""}
            icon={systemCaptureValue.icon}
            onClick={() =>
            {
              const geoPoint = systemCaptureValue.captureLocation?.value.geoPoint;
              if(geoPoint)
              {
                props.onClickIcon && props.onClickIcon(geoPoint);
              }
            }}
          />
        </span>
      </RawTooltip>
    );
  }
  else
  {
    return (
      <RawHighlighter
        ml={gapQuarter}
        variant={"caption"}
        value={getLabel(systemCaptureValue)}
        breakWords={true}
      />
    );
  }
}

function getCaptureHelperText(
  captureTime?: string,
  captureUser?: FieldValueEntUserId,
  captureLocation?: FieldValueLocation,
  showCapturedAsHelperText?: EnumDefnCaptureValueKind[]
)
{
  const showCaptureTime = showCapturedAsHelperText?.includes("captureTime");
  const showCaptureLocation = showCapturedAsHelperText?.includes("captureLocation");
  const showCaptureUser = showCapturedAsHelperText?.includes("captureUser");

  const captureValue: ICaptureValue[] = [];

  if(captureTime && showCaptureTime)
  {
    captureValue.push({
      captureTime: captureTime,
      icon: undefined,
      type: "Time"
    });
  }
  if(captureLocation && showCaptureLocation)
  {
    captureValue.push(
      {
        captureLocation: captureLocation,
        type: "Loc",
        icon: "LocationOnRounded"
      });
  }
  if(captureUser && showCaptureUser)
  {
    captureValue.push(
      {
        captureUser: captureUser,
        type: "User",
        icon: undefined
      }
    );
  }

  return captureValue;

}
