import {MetaIdForm} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {MsgFormId} from "../../../api/studio/studioMain/msg/MsgFormId";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigDefnForm} from "../../../api/studio/studioMain/sig/SigDefnForm";
import {SigSysDefnFormMapGet} from "../../../api/studio/studioMain/sig/SigSysDefnFormMapGet";
import ISrvc from "../../../base/ISrvc";
import {setEntSysFormMap} from "../../../cache/studio/ent/SliceCacheStudioEnt";
import {store} from "../../../Store";

export type CbDefnFormGetSuccess = (pluginMap: SigDefnForm) => void;
export type CbDefnSysFormMapGetSuccess = (pluginMap: SigSysDefnFormMapGet) => void;

export default class SrvcStudioEntForms extends ISrvc
{
  rpcDefnFormGet(entId: EntId, formId: MetaIdForm, cbSuccess: CbDefnFormGetSuccess)
  {
    const msg: MsgFormId = {
      formId: formId
    };

    RpcStudioMain.defnFormGet(entId, msg, sigDefn =>
    {
      if(sigDefn.error)
      {
        return;
      }

      if(sigDefn.sig)
      {
        cbSuccess && cbSuccess(sigDefn.sig);
      }
    });
  }

  rpcSysDefnFormMapGet(cbSuccess?: CbDefnSysFormMapGetSuccess)
  {
    RpcStudioMain.sysDefnFormMapGet(sigFomMap =>
    {
      if(sigFomMap.error)
      {
        return;
      }

      if(sigFomMap.sig)
      {
        store.dispatch(setEntSysFormMap(sigFomMap.sig));

        cbSuccess && cbSuccess(sigFomMap.sig);
      }
    });
  }
}
