import {useCallback} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnLayoutGridLocmap} from "../../api/meta/base/dto/DefnLayoutGridLocmap";
import {FieldValueColor} from "../../api/meta/base/dto/FieldValueColor";
import {FieldValueEntUserId} from "../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueGeoPoint} from "../../api/meta/base/dto/FieldValueGeoPoint";
import {FieldValueLocation} from "../../api/meta/base/dto/FieldValueLocation";
import {FieldValueRole} from "../../api/meta/base/dto/FieldValueRole";
import {FieldValueRowId} from "../../api/meta/base/dto/FieldValueRowId";
import {EnumDefnThemeStroke} from "../../api/meta/base/Types";
import {EnumDefnMapPinShape} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {getFormFieldValueAsText} from "./FieldValuePlus";
import {getLatLngFromGeoPoint} from "./LocationPlus";
import theme from "./ThemePlus";

export function useLocationMapResolver(
  defnForm?: DefnForm,
  locmap?: DefnLayoutGridLocmap
)
{
  const compMap = defnForm?.compMap;

  const locationFieldId = locmap?.locationFieldId;
  const colorFieldId = locmap?.colorFieldId;
  const shapeFieldId = locmap?.shapeFieldId;
  const strokeFieldId = locmap?.strokeFieldId;
  const groupByFieldId = locmap?.groupByFieldId;
  const toolTipFieldId = locmap?.toolTipFieldId;

  const getLntLng = useCallback((valueMap?: FieldValues) =>
  {
    const fieldType = (locationFieldId && compMap)
      ? compMap[locationFieldId].type
      : undefined;
    const locationFieldValue = (valueMap && locationFieldId && fieldType === "location")
      ? (valueMap[locationFieldId] as FieldValueLocation)?.value
      : undefined;
    const geoPointFieldValue = (valueMap && locationFieldId && fieldType === "geoPoint")
      ? (valueMap[locationFieldId] as FieldValueGeoPoint)
      : undefined;

    const geoPoint = locationFieldValue?.geoPoint || geoPointFieldValue?.value;

    return geoPoint
      ? getLatLngFromGeoPoint(geoPoint)
      : undefined;

  }, [locationFieldId]);

  const getColorValue = useCallback((valueMap?: FieldValues) =>
  {
    const defaultMarkerColor = theme.common.color("primary");

    return (valueMap && colorFieldId)
      ? (valueMap[colorFieldId] as FieldValueColor | undefined)?.value || defaultMarkerColor
      : defaultMarkerColor;

  }, [colorFieldId]);

  const getShapeValue = useCallback((valueMap?: FieldValues) =>
  {
    return (valueMap && shapeFieldId)
      ? valueMap[shapeFieldId] as EnumDefnMapPinShape
      : undefined;

  }, [shapeFieldId]);

  const getStrokeValue = useCallback((valueMap?: FieldValues) =>
  {
    return (valueMap && strokeFieldId)
      ? valueMap[strokeFieldId] as EnumDefnThemeStroke
      : undefined;

  }, [strokeFieldId]);

  const getGroupByFieldValue = useCallback((valueMap?: FieldValues) =>
  {
    return (valueMap && groupByFieldId && compMap)
      ? getGroupByIdFieldValue(groupByFieldId, valueMap, compMap)
      : undefined;

  }, [groupByFieldId]);

  const getTooltipValue = useCallback((valueMap?: FieldValues) =>
  {
    return (valueMap && toolTipFieldId && compMap)
      ? getToolTipFieldValue(toolTipFieldId, valueMap, compMap)
      : undefined;

  }, [toolTipFieldId]);

  return {
    getLntLng,
    getColorValue,
    getShapeValue,
    getStrokeValue,
    getGroupByFieldValue,
    getTooltipValue
  };
}

function getGroupByIdFieldValue(
  groupByFieldId: MetaIdField,
  valueMap: Record<MetaIdField, any>,
  compMap: Record<MetaIdField, DefnComp>)
{
  const comp = compMap[groupByFieldId];
  const value = valueMap[groupByFieldId];

  if(comp && value)
  {
    switch(comp.type)
    {
      case "pickUser":
      case "userId":
        const userValue = value as FieldValueEntUserId | undefined;
        return userValue?.displayField || userValue?.value;
      case "pickRole":
        const pickRoleValue = value as FieldValueRole | undefined;
        return pickRoleValue?.displayValue || pickRoleValue?.value;
      case "pickGridRow":
        const pickGridValue = value as FieldValueRowId | undefined;
        return pickGridValue?.displayField || pickGridValue?.value;

      default:
        return getFormFieldValueAsText(comp, value);
    }
  }
}

function getToolTipFieldValue(
  tooltipFieldId: MetaIdField,
  valueMap: Record<MetaIdField, any>,
  compMap: Record<MetaIdField, DefnComp>)
{
  const comp = compMap[tooltipFieldId];
  const value = valueMap[tooltipFieldId];

  if(comp && value)
  {
    return getFormFieldValueAsText(comp, value);
  }
}


