// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgOtpVerify} from './msg/MsgOtpVerify';
import {ServiceName} from '../../meta/base/Types';
import {SigCallback} from '../../nucleus/base/sig/SigCallback';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcOtp
{
  static readonly SN: ServiceName = "otp";

  static otpVerify(msg: MsgOtpVerify, sigAcceptor: ISigAcceptor<SigCallback>): void
  {
    rpcCall<SigCallback>(ENT_ID_GLOBAL, RpcOtp.SN, "otpVerify")
      .post(msg, sigAcceptor);
  }
}