import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import RawIconButton from "../../raw/RawIconButton";

export default function BubbleRawIcons(props: {
  iconSet: string[],
  nameSet: string[],
  disabled?: boolean,
  color?: TypeTextColor,
  enableRipple?: boolean,
  width?: string,
  reverseIcon?: boolean,
  onClick?: (name: string, menuAnchor: Element) => void,
  padding?: string,
  ml?: string,
  mr?: string,
})
{
  const iconSet = props.iconSet;
  const nameSet = props.nameSet;

  if(!iconSet || iconSet.length === 0)
  {
    return null;
  }

  return (
    <Box
      sx={{
        width: "auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        overflowX: "visible",
        overflowY: "visible"
      }}
    >
      {
        iconSet.map((icon, index) =>
        {
          return (
            <BubbleRawIcon
              key={index}
              icon={icon}
              name={nameSet[index]}
              {...props}
            />
          );
        })
      }
    </Box>
  );

}

function BubbleRawIcon(props: {
  icon: string,
  name: string,
  disabled?: boolean,
  color?: TypeTextColor,
  enableRipple?: boolean,
  width?: string,
  reverseIcon?: boolean,
  onClick?: (name: string, menuAnchor: Element) => void,
  padding?: string,
  ml?: string,
  mr?: string,
})
{
  const theme = useTheme();
  const icon = props.icon;
  const name = props.name;
  const color = props.color;
  const width = props.width;
  const disabled = props.disabled;
  const enableRipple = props.enableRipple;
  const onClick = props.onClick;
  const reverseIcon = props.reverseIcon;
  const padding = props.padding;
  const mr = props.mr;
  const ml = props.ml;

  const rightHeadedIcon = "scaleX(-1)";
  const leftHeadedIcon = "scaleX(1)";

  return (
    <Box
      key={name}
      sx={{
        transform: reverseIcon ? leftHeadedIcon : rightHeadedIcon,
        bgcolor: theme.common.bubbleBorderColor,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: padding,
        mr: mr,
        ml: ml
      }}
    >
      <RawIconButton
        icon={icon}
        name={name}
        color={color}
        width={width}
        disabled={disabled}
        padding={0}
        onClick={(name, e) => onClick && onClick(name, e.currentTarget)}
        enableRipple={enableRipple}
      />
    </Box>
  );
}

