import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import {useCallback} from "react";
import React from "react";
import {Draggable} from "react-beautiful-dnd";
import {Droppable} from "react-beautiful-dnd";
import {DragDropContext} from "react-beautiful-dnd";
import {DroppableStateSnapshot} from "react-beautiful-dnd";
import {DroppableProvided} from "react-beautiful-dnd";
import {DraggableProvided} from "react-beautiful-dnd";
import {OnDragEndResponder} from "react-beautiful-dnd";
import {ItemProps} from "react-virtuoso";
import {SelectList} from "../../../base/plus/ListPlus";
import {useAppSelector} from "../../app/AppHooks";
import {CbOnDragListItem} from "../List";

export function useDraggable(props: {
  selectList: SelectList,
  onDragListItem?: CbOnDragListItem,
  displayItemIds: string[],
  renderItem: (index: number, width?: number) => React.ReactNode
})
{
  const theme = useTheme();
  const displayItemIds = props.displayItemIds;
  const selectList = props.selectList;
  const onDragListItem = props.onDragListItem;
  const renderItem = props.renderItem;
  const isDraggable = useAppSelector(state => selectList(state).draggable);
  const itemsById = useAppSelector(state => selectList(state).itemsById);

  const onDragEnd: OnDragEndResponder = useCallback((result) =>
  {
    if(!result.destination)
    {
      return;
    }
    if(result.source.index === result.destination.index)
    {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const itemId = displayItemIds[sourceIndex];
    const item = itemsById[displayItemIds[sourceIndex]];
    onDragListItem && onDragListItem(itemId, item, sourceIndex, destinationIndex);
  }, [displayItemIds, itemsById, onDragListItem]);

  const Item = useCallback((props: {
    provided: DraggableProvided,
    isDragging: boolean,
    item: React.ReactNode
  }) =>
  {
    const {provided, isDragging, item} = props;
    return (
      <Box
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={provided.draggableProps.style}
        sx={{
          background: isDragging ? theme.common.bgcolorActionBar : undefined
        }}
        className={`item ${isDragging ? "is-dragging" : ""}`}
      >
        {item}
      </Box>
    );
  }, [theme.common.bgcolorActionBar]);

  const RealDragDropContext = useCallback((children: (
    provided?: DroppableProvided,
    snapshot?: DroppableStateSnapshot) => React.ReactElement<HTMLElement>
  ) =>
  {
    if(!isDraggable)
    {
      return <>
        {children()}
      </>;
    }

    return (
      <>
        <style>
          {
            `.height-preserving-container:empty {
                min-height: calc(var(--child-height));
                box-sizing: border-box;
             }`
          }
        </style>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            mode="virtual"
            ignoreContainerClipping={true}
            renderClone={(provided, snapshot, rubric) => (
              <Item
                provided={provided}
                isDragging={snapshot.isDragging}
                item={renderItem(rubric.source.index)}
              />
            )}
          >
            {children}
          </Droppable>
        </DragDropContext>
      </>
    );
  }, [Item, isDraggable, onDragEnd, renderItem]);

  const ItemDragContext = useCallback((itemId: string, index: number, item: React.ReactNode) =>
  {
    const listItem = itemsById[displayItemIds[index]];

    if(!isDraggable)
    {
      return <>
        {item}
      </>;
    }

    return <Draggable
      draggableId={itemId}
      index={index}
      isDragDisabled={listItem?.disableDrag}
    >
      {
        (provided) => (
          <Item
            provided={provided}
            isDragging={false}
            item={item}
          />
        )
      }
    </Draggable>;
  }, [Item, displayItemIds, isDraggable, itemsById]);

  return {
    RealDragDropContext,
    ItemDragContext
  };
}

export function HeightPreservingItem(props: ItemProps<any>)
{
  const [size, setSize] = useState(0);
  const children = props.children;
  const knownSize = props["data-known-size"];

  useEffect(() =>
  {
    setSize((prevSize) =>
    {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);

  return (
    <Box
      {...props}
      className="height-preserving-container"
      sx={{
        "--child-height": `${size}px`
      }}
    >
      {children}
    </Box>
  );
}
