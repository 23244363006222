import {createSlice} from "@reduxjs/toolkit";
import {IKanban} from "../../types/TypeKanban";
import {IKanbanConfig} from "../../types/TypeKanban";
import {sliceKanbanShared} from "./SliceKanbanShared";

export function getKanbanNameFake()
{
  return `Fake~~Kanban~~`;
}

export const sliceFakeTable = fnCreateKanbanSlice(getKanbanNameFake());

export const {
  kanbanReset
} = sliceFakeTable.actions;

export function fnCreateKanbanSlice(name: string, config?: IKanbanConfig)
{
  return createSlice({
    name: name,
    initialState: fnKanbanReset({} as IKanban, name),
    reducers: {
      ...sliceKanbanShared,
      kanbanReset: (state: IKanban) =>
      {
        fnKanbanReset(state, name, config);
      }
    }
  });
}

//region private

function fnKanbanReset(state: IKanban, name: string, config?: IKanbanConfig): IKanban
{
  state.version = undefined;
  state.userField = {};
  state.searchWords = undefined;
  state.error = undefined;
  state.kanbanName = name;
  state.defnForm = undefined;
  state.sortFieldId = undefined;
  state.sortOrder = undefined;
  state.filter = undefined;
  state.hideItemFooter = undefined;
  state.hideItemTitle = undefined;
  state.hideItemSectionName = undefined;
  state.showCommentCount = undefined;
  state.isItemWithMedia = undefined;
  state.selectedItemId = undefined;

  state.ignoreSelection = config?.ignoreSelection;
  state.showMenu = config?.showMenu ?? false;
  state.externalSearch = config?.externalSearch;
  state.columnIdList = [];
  state.columnsByItemIds = {};
  state.displayColumnsByItemIds = {};
  state.displayColumnIds = [];
  state.displaySelectedColumnsByItemId = undefined;

  state.columnMap = {};
  state.itemsById = {};

  state.loaded = undefined;

  return state;
}

//endregion
