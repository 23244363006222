import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {useMemo} from "react";
import {DefnFieldPickText} from "../../../../../../api/meta/base/dto/DefnFieldPickText";
import {FieldValueOptionId} from "../../../../../../api/meta/base/dto/FieldValueOptionId";
import {MetaIdField} from "../../../../../../api/meta/base/Types";
import {getDefnDtoColorToCssColor} from "../../../../../../base/plus/FormPlus";
import RawHighlighter from "../../../../raw/RawHighlighter";

export function BubbleFieldPickText(props: {
  field: DefnFieldPickText,
  text: string,
  variant: Variant,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const field = props.field;
  const variant = props.variant;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;

  const pickTextColor = useMemo(() =>
  {
    const value = valueMap?.[fieldId] as FieldValueOptionId | undefined;
    const optionId = value?.optionId;
    const dtoColor = optionId ? field.optionMap?.map[optionId]?.color : undefined;

    return getDefnDtoColorToCssColor(dtoColor);

  }, [field, valueMap?.[fieldId]]);

  return <RawHighlighter
    variant={variant}
    value={props.text}
    color={pickTextColor || "textSecondary"}
    searchWords={props.searchWords}
    isUserSelect={true}
    flex={1}
  />;
}
