import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnStudioMapOfUserCondition} from "../../../api/meta/base/dto/DefnStudioMapOfUserCondition";
import {DefnStudioPickFormId} from "../../../api/meta/base/dto/DefnStudioPickFormId";
import {StudioVarSetOfUser} from "../../../api/meta/base/dto/StudioVarSetOfUser";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fieldSetOfUsersFormId} from "../../form/builder/base/TypesFormBuilder";
import {fieldSetOfUsersCondition} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap1} from "../../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../form/builder/base/TypesFormBuilder";

export function fnVariableBuilderSetOfUsers(fieldValueCondition: string)
{
  function getVarComp(
    formId?: MetaIdForm
  )
  {
    return {
      [fieldSetOfUsersFormId]: {
        type: "pickFormId",
        name: fieldSetOfUsersFormId,
        metaId: fieldSetOfUsersFormId,
        label: "Use form"
      } as DefnStudioPickFormId,

      ...getFieldGap(fieldGap1, "thick"),

      [fieldSetOfUsersCondition]: {
        type: "studioMapOfUserCondition",
        formId: formId,
        name: fieldSetOfUsersCondition,
        label: "Set of user condition",
        metaId: fieldSetOfUsersCondition
      } as DefnStudioMapOfUserCondition

    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarSetOfUser
  )
  {
    const varValue = entVariable.value;
    valueMap[fieldSetOfUsersFormId] = fnRawValueToFieldValue("pickFormId", varValue?.sourceFormId);
    valueMap[fieldSetOfUsersCondition] = fnRawValueToFieldValue("pickFormId", varValue?.node);

  }

  function defnToDto(valueMap: FieldValues)
  {
    return {
      value: {
        node: fnFieldValueToRawValue("studioMapOfUserCondition", valueMap[fieldSetOfUsersCondition]),
        sourceFormId: fnFieldValueToRawValue("pickFormId", valueMap[fieldSetOfUsersFormId])
      }
    } as StudioVarSetOfUser;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
