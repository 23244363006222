import {useContext} from "react";
import React from "react";
import {createContext} from "react";
import {ICtxApp} from "./ICtxApp";

const ctxApp = createContext({} as ICtxApp);

export function useAppCtx()
{
  return useContext(ctxApp);
}

export default function AppCtxProvider(props: {
  appCtx: ICtxApp,
  children: React.ReactNode
})
{
  return (
    <ctxApp.Provider value={props.appCtx}>
      {props.children}
    </ctxApp.Provider>
  );
}
