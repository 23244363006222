import {Variant} from "@mui/material/styles/createTypography";
import {useState} from "react";
import React from "react";
import {DefnFieldError} from "../../../../../../api/meta/base/dto/DefnFieldError";
import {DefnFieldParagraph} from "../../../../../../api/meta/base/dto/DefnFieldParagraph";
import {FieldValueNumber} from "../../../../../../api/meta/base/dto/FieldValueNumber";
import {MetaIdField} from "../../../../../../api/meta/base/Types";
import {DEFAULT_PARAGRAPH_LINE_COUNT} from "../../../../../../base/plus/ConstantsPlus";
import {fnFieldValueToRawValue} from "../../../../../../base/plus/FieldValuePlus";
import RawMarkDown from "../../../../raw/RawMarkDown";

export function BubbleFieldParagraphAndInfo(props: {
  field: DefnFieldError,
  text: string,
  variant: Variant,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const field = props.field;
  const text = props.text;
  const valueMap = props.valueMap;
  const variant = props.variant;
  const isParagraph = field.type === "paragraph";
  const [lineCount] = useState(getLineCount(field, valueMap));

  return (
    <RawMarkDown
      value={text}
      variant={variant}
      color={"textSecondary"}
      includeColor={!isParagraph}
      includeNewLine={true}
      maxNumberOfLine={isParagraph ? lineCount : DEFAULT_PARAGRAPH_LINE_COUNT}
      breakWords={true}
      flex={1}
    />
  );
}

function getLineCount(field: DefnFieldParagraph, valueMap?: Record<MetaIdField, any>)
{
  if(field.type !== "paragraph")
  {
    return;
  }

  const lineCountFieldVal = field.lineCountFieldId ? valueMap?.[field.lineCountFieldId] as FieldValueNumber : undefined;
  const fieldLineCount = fnFieldValueToRawValue("number", lineCountFieldVal) as number | undefined;

  return Number(
    field.lineCount
    ?? field.lineCountVar
    ?? fieldLineCount
    ?? DEFAULT_PARAGRAPH_LINE_COUNT);
}
