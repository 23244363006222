import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioBuildPropertyMap} from "../../../../api/meta/base/dto/DefnStudioBuildPropertyMap";
import {StudioBuildArgBinderHolder} from "../../../../api/meta/base/dto/StudioBuildArgBinderHolder";
import {StudioMapOfText} from "../../../../api/meta/base/dto/StudioMapOfText";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnResolveArgBinderValue} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogMapOfText from "../../../dialog/DialogMapOfText";
import {IStudioMapOfText} from "../../../dialog/DialogMapOfText";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioBuildPropertyMap(props: {
  defn: DefnStudioBuildPropertyMap
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();

  const isLastField = false;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const label = defn.label;
  const sourceFormId = defn?.sourceFormId;

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfText;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawStudioBuildPropertyMap
                  fieldId={fieldId}
                  formStore={formStore}
                  isFormReadOnly={formReadOnly}
                  onChange={onChange}
                  isLastField={isLastField}
                  fieldValue={fieldValue}
                  sourceFormId={sourceFormId}
                />
              }
            />
          );
        }

        return (
          <RawStudioBuildPropertyMap
            fieldId={fieldId}
            formStore={formStore}
            isFormReadOnly={formReadOnly}
            onChange={onChange}
            isLastField={isLastField}
            fieldValue={fieldValue}
            sourceFormId={sourceFormId}
          />
        );
      }
      }
    />
  );
}

function RawStudioBuildPropertyMap(props: {
  fieldId: MetaIdField,
  fieldValue: StudioMapOfText,
  isFormReadOnly?: boolean,
  formStore?: FormStore,
  isLastField?: boolean,
  sourceFormId?: MetaIdForm,
  onChange: (value: StudioMapOfText) => void
})
{
  const {
    fieldId,
    formStore,
    isLastField,
    isFormReadOnly,
    sourceFormId
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<IStudioMapOfText>;
  const fnResolver = useMemo(() => formStore
    ? fnUseStudioResolver(formStore)
    : undefined, [formStore]);

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: IStudioMapOfText[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = (selectList: SelectList, fieldValue: IStudioMapOfText[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.key;
        uiItemIds.push(listId);

        const argValue = fnResolver && value.value.argBinder
          ? fnResolveArgBinderValue(fnResolver, value.value.argBinder, sourceFormId)
          : undefined;

        uiItemsById[listId] = {
          type: "ps",
          primary: {
            text: listId
          },
          secondary: {
            text: argValue
          },
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemAPSA;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogMapOfText
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) =>
          {
            cbRefList.addItem(newValue);
          }}
          sourceFormId={sourceFormId}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as IStudioMapOfText
        : undefined;

      pageCtx.showDialog(
        <DialogMapOfText
          values={oldValue}
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) => cbRefList.updateItem(newValue, key)}
          sourceFormId={sourceFormId}
          validationError={validationError}
        />
      );
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(map?: StudioMapOfText): IStudioMapOfText[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return {
      key: key,
      value: map.map[key]
    };
  });
}

function convertArrayToMap(arr: IStudioMapOfText[]): StudioMapOfText
{
  const keys = [] as string[];
  const map = {} as Record<string, StudioBuildArgBinderHolder>;

  arr.forEach((value) =>
  {
    const key = value.key;
    keys.push(key);
    map[key] = value.value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfText;
}
