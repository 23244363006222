import {FieldValues} from "react-hook-form";
import {StudioFieldIdentifier} from "../../../../../api/meta/base/dto/StudioFieldIdentifier";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeySetOnSend} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFill} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyTextPatternVar} from "../../base/TypesFormBuilder";

export function getDefnFieldIdentifier(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{

  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    propKeyAutoFill,
    fieldGap1,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "identifier",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    )
  };
}

export function defnValueToStudioFieldIdentifier(values: FieldValues): StudioFieldIdentifier
{
  return {
    type: "identifier",
    textPatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[propKeyTextPatternVar]),
    setOnSend: fnFieldValueToRawValue("bool", values[propKeySetOnSend])
  } as StudioFieldIdentifier;
}

export function studioFieldIdentifierToDefnValue(studioField: StudioFieldIdentifier)
{
  return {
    [propKeyTextPatternVar]: fnRawValueToFieldValue("studioVarIdTextEditor", studioField.textPatternVarId),
    [propKeySetOnSend]: fnRawValueToFieldValue("bool", studioField.setOnSend)

  };
}
