import {Variant} from "@mui/material/styles/createTypography";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {Property} from "csstype";
import {Location} from "react-router-dom";
import {SigTopic} from "../../api/core/session/sig/SigTopic";
import {SysId} from "../../api/meta/base/SysId";
import {EnumDefnThemeColor} from "../../api/meta/base/Types";
import {TypeEnumCaptionButton} from "../../nucleus/list/List";
import {MuiAvatarFontSize} from "../plus/ThemePlus";
import {CssBackgroundColor} from "../plus/ThemePlus";
import {EnumIconListItemSecondary3} from "./TypesIcon";
import {EnumIconListItemPrimary1} from "./TypesIcon";
import {EnumIconListItemPrimary2} from "./TypesIcon";
import {EnumIconListItemSecondary4} from "./TypesIcon";
import {EnumIconListItemSecondary2} from "./TypesIcon";
import {EnumIconListItemSecondary1} from "./TypesIcon";
import {EnumIconAvatar} from "./TypesIcon";

export const FILE_UPLOAD_LIMIT_MAX = 10;
export const FILE_MB_SIZE_LIMIT_MAX = 18;

export type CbProgress = (error?: string) => void;
export type CbImageCropperUploadHandler = (blob: Blob, size: ISize) => void;
export type CbSuccess = () => void;
export type CbUpload = (mediaId: string) => void;

export type CbMenuAnchor = (menuAnchor: Element) => void;

export type TypeDeviceType =
  | "desktop"
  | "mobile"
  | "tablet";

export type TypeSubscriberId = string;

export type TypeTextColor =
  | EnumDefnThemeColor
  | ThemeColorLight
  | ThemeColorDark
  | "textPrimary"
  | "textSecondary"
  | "textDisabled"
  | "textInverse";

export type TypePick = "pickOne" | "pickMany";
export type TypeNumber = "counter";
export type TypeUserFieldValue = object | boolean | string | number | undefined;
export type TypeUserField = Record<string, TypeUserFieldValue>;

export type ThemeColorLight =
  | "primaryLight"
  | "secondaryLight"
  | "successLight"
  | "errorLight"
  | "infoLight"
  | "warningLight";

export type ThemeColorDark =
  | "primaryDark"
  | "secondaryDark"
  | "successDark"
  | "errorDark"
  | "infoDark"
  | "warningDark";

export interface IResultCompressImage
{
  file?: File;
  error?: string;
  size?: ISize;
}

export interface ISize
{
  width: number;
  height: number;
}

export type LocationState = {
  from: Location;
};

export interface ILineBadge
{
  value?: number;
  color?: TypeTextColor; // default primary
}

export interface ILineCaptionIconButton
{
  icon: string,
  color?: TypeTextColor,
  tooltip?: string,
  type: TypeEnumCaptionButton
}

export interface ILineCaption
{
  type?: "text" | "counter";  // default text
  value?: number;
  text?: string;
  color?: string;
  variant?: Variant;
  ignoreSelection?: boolean;
  hasMarkDownText?: boolean;
  iconButtons?: ILineCaptionIconButton[];
}

export interface ILineNumber
{
  value?: number;
  color?: TypeTextColor;
  valueType?: TypeNumber; // default counter
  variant?: Variant;
}

export interface ILineSecondaryText
{
  text?: string;
  color?: string;
  variant?: Variant;
  textDecoration?: StandardCSSProperties["textDecoration"];
  ignoreSelection?: boolean;
  bold?: boolean;
  hasMarkDownText?: boolean;
}

export interface ILineSecondaryAttributedText extends ILineSecondary
{
  attributedText: ILineSecondaryText[];
}

export interface ILinePrimary
{
  text?: string;
  optional?: string;
  color?: string;
  variant?: Variant;
  bold?: boolean;
  pl?: number;
  showTooltip?: boolean;

  // adornments
  number?: ILineNumber;
  icon1?: EnumIconListItemPrimary1;
  middle?: ILineCaption,
  caption?: ILineCaption;
  icon2?: EnumIconListItemPrimary2;
  hasMarkDownText?: boolean
}

export interface ILineSecondary
{
  overwriteIcon1Icon2AndText?: string,
  overwriteIcon1Icon2AndTextColor?: TypeTextColor;
  overwriteIcon1Icon2AndTextVariant?: Variant;

  icon1?: EnumIconListItemSecondary1;
  icon2?: EnumIconListItemSecondary2;
  text?: string;

  optional?: string;
  color?: TypeTextColor | Property.Color;
  variant?: Variant;
  pl?: number;

  // adornments
  number?: ILineNumber;
  middle?: ILineCaption,
  caption?: ILineCaption;
  icon3?: EnumIconListItemSecondary3;
  icon4?: EnumIconListItemSecondary4;
  badge?: ILineBadge;
  badge2?: ILineBadge;
  hasMarkDownText?: boolean
}

export interface ILineHeader
{
  text?: string;
  optional?: string;
  color?: string;
  variant?: Variant;
  expand?: boolean; // expand | collapse | none
  expandIconColor?: TypeTextColor

  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,

  // adornments
  caption?: ILineCaption;
  middle?: ILineCaption,

  // secondary
  secondary?: ILineSecondary;
}

export interface IAvatar
{
  icon?: EnumIconAvatar;
  text?: string;
  dotColor?: TypeTextColor;
  src?: string;
  bgcolor?: CssBackgroundColor;
  color?: string;
  tooltip?: TypeTooltip;
  iconSize?: StandardCSSProperties["width"];
  iconFontSize?: MuiAvatarFontSize;
}

export type TypeTooltip = string | {
  primary: string,
  secondary: string
}

export interface IServerPushListTopicValue
{
  version: string,
  topic: SigTopic
}

export interface IServerPushListTopic
{
  itemId: SysId,
  varName: string,
  varValue: IServerPushListTopicValue
}
