import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {AdminId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {DtoEntAdmin} from "../../../api/studio/base/dto/DtoEntAdmin";
import {MsgAdminId} from "../../../api/studio/studioMain/msg/MsgAdminId";
import {MsgAdminInvite} from "../../../api/studio/studioMain/msg/MsgAdminInvite";
import {MsgEntAdminAdd} from "../../../api/studio/studioMain/msg/MsgEntAdminAdd";
import {MsgEntAdminInviteDeeplinkGet} from "../../../api/studio/studioMain/msg/MsgEntAdminInviteDeeplinkGet";
import {MsgEntAdminUpdate} from "../../../api/studio/studioMain/msg/MsgEntAdminUpdate";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import ISrvc from "../../../base/ISrvc";
import {STR_YOU} from "../../../base/plus/ConstantsPlus";
import {STR_LINK_COPY_TO_CLIPBOARD} from "../../../base/plus/ConstantsPlus";
import {STR_INVITE_SUBMIT_SUCCESSFULLY} from "../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntAdmins extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entAdminList;
  }

  rpcEntAdminListGet(entId: EntId, version?: string)
  {
    const msg = {
      version: version
    } as MsgVersion;

    RpcStudioMain.entAdminListGet(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;

      if(sig)
      {
        Srvc.cache.studio.ent.admins.setEntAdminList(entId, sig);
      }
    });
  }

  rpcEntAdminAdd(entId: EntId, msg: MsgEntAdminAdd, cbSuccess?: CbSuccess)
  {
    RpcStudioMain.entAdminAdd(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        Srvc.app.toast.showSuccessToast("Admin added successfully");
      }

      cbSuccess && cbSuccess();
    });
  }

  rpcEntAdminUpdate(entId: EntId, msg: MsgEntAdminUpdate, cbSuccess?: CbSuccess)
  {
    RpcStudioMain.entAdminUpdate(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }

      cbSuccess && cbSuccess();
    });
  }

  rpcEntAdminRemove(entId: EntId, msg: MsgAdminId)
  {
    RpcStudioMain.entAdminRemove(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  rpcEntAdminInvite(entId: EntId, msg: MsgAdminInvite)
  {
    RpcStudioMain.entAdminInvite(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        Srvc.app.toast.showSuccessToast(STR_INVITE_SUBMIT_SUCCESSFULLY);
      }
    });
  }

  rpcEntAdminTransferLock(entId: EntId, msg: MsgAdminId)
  {
    RpcStudioMain.entAdminEditLockTransfer(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  rpcAdminInviteDeeplinkGet(entId: EntId, adminId?: AdminId)
  {
    const msg = {
      adminId: adminId
    } as MsgEntAdminInviteDeeplinkGet;

    if(typeof ClipboardItem && navigator.clipboard.write !== undefined)
    {
      const clipboardItem = new ClipboardItem({
        "text/plain": new Promise((resolve, reject) =>
        {
          RpcStudioMain.entAdminInviteDeeplinkGet(entId, msg, envSig =>
          {
            if(envSig.error)
            {
              const errorMsg = getErrorMessage(envSig.error);
              errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
              reject();
              return;
            }
            else if(envSig.sig)
            {
              const text = envSig.sig?.url;
              text && resolve(new Blob([text], {type: "text/plain"}));
              Srvc.app.toast.showSuccessToast(STR_LINK_COPY_TO_CLIPBOARD);
            }
          });
        })
      });
      copyToClipboard("", clipboardItem);
    }
    else
    {
      RpcStudioMain.entAdminInviteDeeplinkGet(entId, msg, envSig =>
      {
        if(envSig.error)
        {
          const errorMsg = getErrorMessage(envSig.error);
          errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
          return;
        }
        else if(envSig.sig)
        {
          const text = envSig.sig?.url;
          copyToClipboard(text);
          Srvc.app.toast.showSuccessToast(STR_LINK_COPY_TO_CLIPBOARD);
        }
      });
    }
  }

  resetEntAdminBook(entId: EntId)
  {
    Srvc.cache.studio.ent.admins.removeEntAdminList(entId);
  }

  loadAdminList(
    listName: string,
    entAdminList?: DtoEntAdmin[],
    readOnly?: boolean
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    entAdminList?.forEach(admin =>
    {
      const itemId = admin.adminId;
      uiItemIds.push(itemId);

      uiItemsById[itemId] = {
        type: "ps",
        userField: {
          value: admin
        },
        primary: {
          text: admin.nickName,
          middle: admin.hasLock
            ? {
              text: "Edit lock",
              color: "error"
            }
            : undefined,
          caption: {
            text: admin.handle,
            ignoreSelection: true
          }
        },
        secondary: this.getAdminSecondaryLine(admin, readOnly),
        hideMenu: readOnly
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  private getAdminSecondaryLine(admin: DtoEntAdmin, readOnly?: boolean)
  {
    let secondaryLine = {} as ILineSecondary;

    let secondaryCaptionText: string | undefined;
    if(admin.isCurrentUser)
    {
      secondaryCaptionText = STR_YOU;
    }
    else if(admin.handle !== undefined && !admin.isMember)
    {
      secondaryCaptionText = "INVITE";
    }

    let fullAccess = true;
    if((admin?.doNotEditOptionSet && admin.doNotEditOptionSet.length)
      || (admin?.doNotShowOptionSet && admin.doNotShowOptionSet.length))
    {
      fullAccess = false;
    }

    secondaryLine.text = fullAccess ? "Full access" : "Restricted access";
    secondaryLine.color = "textDisabled";
    secondaryLine.caption = {
      text: secondaryCaptionText,
      color: readOnly ? "textDisabled" : "successLight",
      ignoreSelection: !!(admin.isMember || admin.isCurrentUser)
    };

    return secondaryLine;
  }

}
