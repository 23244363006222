import {cloneDeep} from "lodash";
import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {StudioPluginBundle} from "../../../api/meta/base/dto/StudioPluginBundle";
import {PluginId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {MsgStudioPlugin} from "../../../api/studio/studioMain/msg/MsgStudioPlugin";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigAdminEditLockDetail} from "../../../api/studio/studioMain/sig/SigAdminEditLockDetail";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listSetIfExistInfoSpot} from "../../../base/slices/list/SliceListSharedActions";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {setPluginEditLockDetail} from "../../../cache/studio/plugin/SliceCacheStudioPlugin";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";
import SrvcStudioPluginAdmins from "./SrvcStudioPluginAdmins";
import SrvcStudioPluginApis from "./SrvcStudioPluginApis";
import SrvcStudioPluginResources from "./SrvcStudioPluginResources";
import SrvcStudioPluginVariables from "./SrvcStudioPluginVariables";
import SrvcStudioPluginVersions from "./SrvcStudioPluginVersions";

export default class SrvcStudioPlugin extends ISrvc
{
  public readonly admins = new SrvcStudioPluginAdmins();
  public readonly variables = new SrvcStudioPluginVariables();
  public readonly apis = new SrvcStudioPluginApis();
  public readonly resources = new SrvcStudioPluginResources();
  public readonly versions = new SrvcStudioPluginVersions();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.admins,
      this.variables,
      this.apis,
      this.resources,
      this.versions
    );
  }

  selectList(state: RootState)
  {
    return state.studio.plugin.pluginVersionList;
  }

  //region RPC

  rpcPluginGet(pluginBundleId: PluginBundleId, replace?: boolean, onSuccess?: (plugin: StudioPluginBundle) => void)
  {
    Srvc.cache.studio.plugin.rpcPluginGet(pluginBundleId, replace, onSuccess);
  }

  rpcPluginPut(pluginBundleId: PluginBundleId)
  {
    const srvcCacheStudioPlugin = Srvc.cache.studio.plugin;
    srvcCacheStudioPlugin.setIsSavingPlugin(pluginBundleId, true);

    const sigPlugin = store.getState().cache.studio.plugin.pluginMap[pluginBundleId];
    const msg = cloneDeep(sigPlugin.studioPluginBundle.draft) as MsgStudioPlugin;

    RpcStudioMain.studioPluginPut(pluginBundleId, msg, envSig =>
    {
      srvcCacheStudioPlugin.setIsSavingPlugin(pluginBundleId, false);
      if(envSig.error)
      {
        const notChanged = envSig.error.validationErrors?.find(error => error.errorCode === "notChanged");
        if(notChanged)
        {
          return;
        }
        srvcCacheStudioPlugin.setPluginDirty(pluginBundleId, true);

        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      srvcCacheStudioPlugin.setPluginDirty(pluginBundleId, false);
    });
  }

  rpcPluginDeploy(pluginBundleId: PluginBundleId, sendInvites: boolean, cbSuccess: CbSuccess)
  {
    RpcStudioDrawer.studioPluginDeploy(pluginBundleId, pluginSig =>
    {
      if(pluginSig.error)
      {
        const errorMsg = getErrorMessage(pluginSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }

      cbSuccess();
    });
  }

  rpcPluginAdminEditLockDetailGet(
    pluginBundleId: PluginBundleId,
    version?: string,
    cbResult?: (sig: SigAdminEditLockDetail) => void)
  {
    const msg = {
      version
    } as MsgVersion;

    RpcStudioMain.pluginAdminEditLockDetailGet(pluginBundleId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
      }
      else if(envSig.sig)
      {
        store.dispatch(
          setPluginEditLockDetail({
            pluginBundleId: pluginBundleId,
            detail: envSig.sig
          })
        );

        cbResult && cbResult(envSig.sig);
      }
      return;
    });
  }

  //region end

  //TODO:Neel (update DTO of 'validationErrorList')
  loadValidationErrors(listName: string, validationErrorList: any)
  {
    validationErrorList?.forEach((validationError: any) =>
    {
      const childErrorList = validationError.childErrorList;

      dispatchList(listName, listSetIfExistInfoSpot({
        itemId: validationError.elementId,
        infoSpot: "error"
      }));

      if(childErrorList && childErrorList.length > 0)
      {
        this.loadValidationErrors(listName, childErrorList);
      }

    });
  }

  filterListByTag(pluginBundleId: PluginBundleId, pluginId: PluginId, itemModules?: StudioModuleSelection)
  {
    let allow = false;
    const rootState = store.getState();
    const pluginModulesMap = rootState.cache.studio.plugin.selectedModulesMap;
    const pluginBundleModulesMap = pluginModulesMap
      ? pluginModulesMap[pluginBundleId]
      : undefined;
    const pluginSelectedModules = pluginBundleModulesMap
      ? pluginBundleModulesMap[pluginId]
      : undefined;

    if(pluginSelectedModules && pluginSelectedModules.moduleIdSet
      && pluginSelectedModules.moduleIdSet.length > 0)
    {
      if(itemModules && itemModules.moduleIdSet
        && itemModules.moduleIdSet.find(value => pluginSelectedModules.moduleIdSet.includes(
          value)))
      {
        allow = true;
      }
    }
    else if(itemModules && itemModules.moduleIdSet && itemModules.moduleIdSet.length > 0)
    {
      allow = true;
    }
    else
    {
      allow = true;
    }

    return allow;
  }
}
