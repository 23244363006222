import {useRef} from "react";
import {useState} from "react";
import {useEffect} from "react";
import React from "react";
import {FieldValues} from "react-hook-form";
import {nextMetaIdGrid} from "../../../../api/meta/base/ApiPlus";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutCard} from "../../../../api/meta/base/dto/StudioDtoLayoutCard";
import {StudioDtoLayoutCardFilter} from "../../../../api/meta/base/dto/StudioDtoLayoutCardFilter";
import {StudioDtoLayoutGrid} from "../../../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioFieldEditable} from "../../../../api/meta/base/dto/StudioFieldEditable";
import {StudioFieldRef} from "../../../../api/meta/base/dto/StudioFieldRef";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../../api/meta/base/Types";
import {EnumDefnFields} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {EnumDefnLayoutGridKind, MetaIdField, MetaIdForm} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFormSectionCompositeId} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../../../base/plus/FormPlus";
import {getCombinedFieldId} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {loopStudioForm} from "../../../../base/plus/StudioFormPlus";
import {getFormFieldsErrorList} from "../../../../base/plus/StudioPlus";
import {IFormFieldError} from "../../../../base/types/TypesForm";
import {IFormRef} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {
  fieldKeyDescription,
  fieldKeyLabel,
  fieldKeyName,
  fieldTabDetails
} from "../../../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "../DialogPlus";
import {
  defnToDtoStudioDtoLayoutCard,
  dtoToValueStudioDtoLayoutCard,
  getCompMapSpreadSheetBrTabDetails,
  getCompMapSpreadSheetBrTabFilter,
  getLayoutItemLineSegmentCard,
  getLayoutItemLineSegmentList
} from "../LayoutBuilderPlus";
import {fieldLayoutSortOrder} from "../TypesLayoutBuilder";
import {fieldSpreadsheetLayoutSwitcherIdSet} from "../TypesLayoutBuilder";
import {
  fieldLayoutDetailBgColorField,
  fieldLayoutDetailTooltipField,
  fieldLayoutFilterAdvanceFilterFields,
  fieldLayoutFilterCatFields,
  fieldLayoutFilterLayoutMode,
  fieldLayoutFilterSection,
  fieldLayoutFilterShowSearchBar,
  fieldLayoutGroupByField,
  fieldLayoutItem,
  fieldLayoutItemTabColors,
  fieldLayoutItemTabFonts,
  fieldLayoutItemTabLabels,
  fieldLayoutItemTabTexts,
  fieldLayoutSortByFields,
  getLayoutItemTabId
} from "../TypesLayoutBuilder";
import DialogDefnForm from "./DialogDefnForm";

const dialogContentHeight = 700;
const dialogContentWidth = 1350;
const tabShowDivider = true;
const tabVariant = "standard";

const itemTabId = getLayoutItemTabId(fieldLayoutItem);

const fieldKeyTabTexts = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabTexts
]));

const fieldKeyTabColors = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabColors
]));

const fieldKeyTabFonts = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabFonts
]));

const fieldKeyTabLabels = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabLabels
]));

export default function DialogNewLayoutSpreadsheetBase(props: {
  formStore: FormStore,
  spreadsheetId: MetaIdSpreadsheet;
  isFormReadOnly?: boolean,
  layoutType: EnumDefnLayoutGridKind,
  excludeSpreadsheetLayoutIdSet?: MetaIdLayoutGrid[]
  metaIdForm?: MetaIdForm,
  values?: StudioDtoLayoutCard,
  defaultValues?: StudioDtoLayoutCard,
  onClickOk: (values: StudioDtoLayoutCard) => void,
  onClose?: () => void,
  isOverride?: boolean,
  excludeFieldIdSet?: MetaIdField[],
  overRideCompositeIdSet?: MetaIdComposite[],
  validationError?: IFormFieldError[]
})
{
  const oldValues = props.values;
  const defaultValues = props.defaultValues;
  const layoutType = props.layoutType;
  const formStore = props.formStore;
  const spreadsheetId = props.spreadsheetId;
  const isOverride = props.isOverride;
  const spreadSheetMap = formStore.spreadsheetMap?.map;
  const validationResult = formStore?.validationResult;
  const excludeSpreadsheetLayoutIdSet = props.excludeSpreadsheetLayoutIdSet;
  const excludeFieldIdSet = props.excludeFieldIdSet;
  const overRideCompositeIdSet = props.overRideCompositeIdSet;

  const [queryableRefFields, setQueryableRefFields] = useState<MetaIdField[]>([]);
  const [queryableFields, setQueryableFields] = useState<MetaIdField[]>([]);
  const errorList = getFormFieldsErrorList("layoutMap", undefined, validationResult);

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const form = props.metaIdForm
    ? formStore.formMap?.map[props.metaIdForm]
    : undefined;

  useEffect(() =>
  {
    if(!form || !spreadSheetMap)
    {
      return;
    }
    const spreadSheet = spreadSheetMap[spreadsheetId];
    const queryableIdSet = spreadSheet.queryableIdSet;

    loopStudioForm(form, (composite, field) =>
    {
      const _field = (field as StudioFieldEditable);
      const refParentFieldId = _field?.refFieldId;
      const refParentFieldCopyFieldMap = (composite && refParentFieldId)
        ? (composite.fieldMap.map[refParentFieldId] as StudioFieldRef)?.copyFieldMap || {}
        : {};
      const systemRowIdEnum: EnumDefnFields = "$RowId";
      const systemFieldRowId = Object.entries(refParentFieldCopyFieldMap)
      .find(([_, value]) => value === systemRowIdEnum)?.[0];

      if(systemFieldRowId
        && systemFieldRowId === _field.metaId
        && refParentFieldId
        && queryableIdSet?.includes(systemFieldRowId))
      {
        setQueryableRefFields(queryable => [...queryable, refParentFieldId]);
      }

      if(queryableIdSet && queryableIdSet?.includes(_field.metaId) && !refParentFieldId)
      {
        setQueryableFields(queryable => [...queryable, _field.metaId]);
      }
    });
  }, [form, spreadSheetMap, spreadsheetId]);

  useEffect(() =>
  {
    setTimeout(() => cbRef.current.setErrors && cbRef.current.setErrors(errorList));
  }, [cbRef, errorList]);

  return (
    <DialogDefnForm
      title={`${oldValues ? "Update" : isOverride ? "Override" : "New"} spreadsheet layout - ${toLabel(layoutType)}`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(layoutType,
          queryableRefFields,
          queryableFields,
          spreadsheetId,
          props.metaIdForm,
          excludeSpreadsheetLayoutIdSet,
          isOverride,
          excludeFieldIdSet,
          formStore,
          overRideCompositeIdSet
        ),
        formReadonly: props.isFormReadOnly,
        store: props.formStore,
        initValues: dtoToValue(layoutType, oldValues || defaultValues),
        onSubmit: values => props.onClickOk(valueToDto(values, layoutType, oldValues, isOverride))
      }}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!(isOverride || oldValues) ? "Add more spreadsheet layouts" : undefined}
    />
  );
}

function getDefnForm(
  layoutType: EnumDefnLayoutGridKind,
  queryableRefFields: MetaIdField[],
  queryableFields: MetaIdField[],
  spreadsheetId: MetaIdSpreadsheet,
  metaIdForm?: MetaIdForm,
  excludeSpreadsheetLayoutIdSet?: MetaIdLayoutGrid[],
  isOverride?: boolean,
  excludeFieldIdSet?: MetaIdField[],
  formStore?: FormStore,
  overRideCompositeIdSet?: MetaIdComposite[]
)
{

  const compositeIdSet = overRideCompositeIdSet || getFormSectionCompositeId(formStore, metaIdForm);
  const includeOptions = [
    "$CreatedBy",
    "$CreatedOn",
    "$UpdatedBy",
    "$UpdatedOn"
  ] as EnumDefnFields[];
  const includeOptionMap = arrayToMapOfOption(includeOptions);

  return createDefaultDefnFormStudio({
    ...!isOverride && getCompMapSpreadSheetBrTabDetails(spreadsheetId,
      metaIdForm,
      excludeSpreadsheetLayoutIdSet,
      excludeFieldIdSet,
      compositeIdSet
    ),
    ...metaIdForm && getCompMapSpreadSheetBrTabFilter(metaIdForm, queryableRefFields, queryableFields),

    ...layoutType === "card"
      ? getCompMapSpreadSheetBrTabCard(metaIdForm, excludeFieldIdSet, compositeIdSet, isOverride, includeOptionMap)
      : getCompMapSpreadSheetBrTabList(metaIdForm, excludeFieldIdSet, compositeIdSet, isOverride, includeOptionMap),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      showDivider: tabShowDivider,
      tabVariant: tabVariant,
      tabIdSet: [
        ...!isOverride
          ? [fieldTabDetails]
          : [],
        ...!isOverride
          ? [fieldLayoutFilterSection]
          : [],
        itemTabId
      ]
    } as DefnTab

  } as Record<MetaIdField, DefnField>);
}

function getCompMapSpreadSheetBrTabCard(
  metaIdForm?: MetaIdForm,
  excludeFieldIdSet?: MetaIdField[],
  compositeIdSet?: MetaIdComposite[],
  isOverride?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption)
{
  let texts = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabTexts,
    metaIdForm, undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet,
    isOverride,
    includeOptionMap
  );
  let colors = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabColors,
    metaIdForm,
    undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet
  );
  let fonts = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabFonts,
    metaIdForm,
    undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet
  );
  let labels = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabLabels,
    metaIdForm,
    undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet
  );

  return {
    ...texts,
    ...colors,
    ...fonts,
    ...labels,
    [itemTabId]: {
      type: "tab",
      metaId: itemTabId,
      showDivider: tabShowDivider,
      tabVariant: tabVariant,
      label: "Card item",
      tabIdSet: [
        fieldKeyTabTexts,
        fieldKeyTabColors,
        fieldKeyTabFonts,
        fieldKeyTabLabels
      ]
    } as DefnTab
  };
}

function getCompMapSpreadSheetBrTabList(
  metaIdForm?: MetaIdForm,
  excludeFieldIdSet?: MetaIdField[],
  compositeIdSet?: MetaIdComposite[],
  isOverride?: boolean,
  includeOptionMap?: DefnStudioMapOfDtoOption)
{
  const texts = getLayoutItemLineSegmentList(
    fieldLayoutItemTabTexts,
    metaIdForm,
    undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet,
    isOverride,
    includeOptionMap
  );
  const colors = getLayoutItemLineSegmentList(
    fieldLayoutItemTabColors,
    metaIdForm,
    undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet
  );
  const fonts = getLayoutItemLineSegmentList(
    fieldLayoutItemTabFonts,
    metaIdForm,
    undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet
  );
  const labels = getLayoutItemLineSegmentList(
    fieldLayoutItemTabLabels,
    metaIdForm,
    undefined,
    excludeFieldIdSet,
    "spreadsheetLayout",
    compositeIdSet
  );

  return {
    ...texts,
    ...colors,
    ...fonts,
    ...labels,
    [itemTabId]: {
      type: "tab",
      metaId: itemTabId,
      label: "List item",
      showDivider: tabShowDivider,
      tabVariant: tabVariant,
      tabIdSet: [
        fieldKeyTabTexts,
        fieldKeyTabColors,
        fieldKeyTabFonts,
        fieldKeyTabLabels
      ]
    } as DefnTab
  };
}

function dtoToValue(layoutType: EnumDefnLayoutGridKind, layout?: StudioDtoLayoutCard)
{
  if(!layout)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", layoutType)
    };
  }

  const filter = layout?.filter;

  return {
    // tab details
    [fieldKeyName]: fnRawValueToFieldValue("symbol", layout.name ?? layoutType),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", layout.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", layout.description),
    [fieldLayoutDetailBgColorField]: fnRawValueToFieldValue("pickFieldId", layout.bgColorFieldId),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", layout.toolTipFieldId),
    [fieldLayoutGroupByField]: fnRawValueToFieldValue("pickFieldId", layout?.groupByFieldId),
    // tab filter
    [fieldLayoutFilterAdvanceFilterFields]: fnRawValueToFieldValue("studioSetOfFieldId",
      filter?.advanceFilterFieldIdSet
    ),
    [fieldLayoutFilterLayoutMode]: fnRawValueToFieldValue("enumLayoutCardFilterKind", filter?.kind),
    [fieldLayoutFilterCatFields]: fnRawValueToFieldValue("studioSetOfFieldId", filter?.categoryFieldIdSet),
    [fieldLayoutFilterShowSearchBar]: fnRawValueToFieldValue("bool", filter?.showSearchBar),
    [fieldLayoutSortByFields]: fnRawValueToFieldValue("studioSetOfFieldId", filter?.sortByFieldIdSet),
    [fieldLayoutSortOrder]: fnRawValueToFieldValue("enumSortOrder", filter?.sortOrder),
    [fieldSpreadsheetLayoutSwitcherIdSet]: fnRawValueToFieldValue("studioSetOfLayoutSpreadsheetId",
      layout.allowToSwitchLayoutIdSet
    ),
    // tab item
    ...dtoToValueStudioDtoLayoutCard(layout)
  };
}

function valueToDto(
  value: FieldValues,
  layoutType: EnumDefnLayoutGridKind,
  dto?: StudioDtoLayoutGrid,
  isOverride?: boolean): StudioDtoLayoutGrid
{
  const filterValue = {
    advanceFilterFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", value[fieldLayoutFilterAdvanceFilterFields]),
    kind: fnFieldValueToRawValue("enumLayoutCardFilterKind", value[fieldLayoutFilterLayoutMode]),
    categoryFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", value[fieldLayoutFilterCatFields]),
    showSearchBar: fnFieldValueToRawValue("bool", value[fieldLayoutFilterShowSearchBar]),
    sortByFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", value[fieldLayoutSortByFields]),
    sortOrder: fnFieldValueToRawValue("enumSortOrder", value[fieldLayoutSortOrder])
  } as StudioDtoLayoutCardFilter;

  const layoutItem = {
    item: defnToDtoStudioDtoLayoutCard(value),
    filter: filterValue,
    groupByFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutGroupByField])
  } as StudioDtoLayoutCard;

  return {
    metaId: dto?.metaId || nextMetaIdGrid(),
    name: fnFieldValueToRawValue("symbol", !isOverride
      ? value[fieldKeyName]
      : "OverrideSpreadsheetLayoutName"),
    label: fnFieldValueToRawValue("text", value[fieldKeyLabel]),
    description: fnFieldValueToRawValue("text", value[fieldKeyDescription]),
    kind: fnFieldValueToRawValue("enumLayoutGridKind", layoutType),
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioSetOfLayoutSpreadsheetId",
      value[fieldSpreadsheetLayoutSwitcherIdSet]
    ),
    bgColorFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutDetailBgColorField]),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutDetailTooltipField]),
    ...(layoutType === "card" || layoutType === "list") && {
      ...layoutItem
    }
  } as StudioDtoLayoutGrid;
}
