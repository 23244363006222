import {cloneDeep} from "lodash";
import {isEmpty} from "lodash";
import {DefnDtoOption} from "../../../../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../../../../api/meta/base/dto/DefnField";
import {DefnFieldButton} from "../../../../../api/meta/base/dto/DefnFieldButton";
import {DefnFieldInfo} from "../../../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../../../api/meta/base/dto/DefnGrid";
import {DefnSection} from "../../../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnTab} from "../../../../../api/meta/base/dto/DefnTab";
import {StudioEntImport} from "../../../../../api/meta/base/dto/StudioEntImport";
import {StudioEntPlugin} from "../../../../../api/meta/base/dto/StudioEntPlugin";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../../api/meta/base/Types";
import {EnumDefnForms} from "../../../../../api/meta/base/Types";
import {EnumArrayDefnForms} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {EnumDefnPluginResources} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {DtoEntAdmin} from "../../../../../api/studio/base/dto/DtoEntAdmin";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {loopDefnForm} from "../../../../../base/plus/FormPlus";
import {defaultSectionKey} from "../../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../../base/plus/FormPlus";
import {TypeEntSysFormMap} from "../../../../../cache/studio/ent/TypesCacheStudioEnt";
import {DEPLOY_ADMIN_FOR_SINGLETON_PLUGINS} from "../../../../../nucleus/atom/assets/HelperTextStudio";
import {fieldGap2} from "../../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fieldGap1} from "../../../../../nucleus/form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../../../../nucleus/form/builder/base/TypesFormBuilder";

export const fieldDeployPluginType = "pluginType";
export const fieldPluginAdminHandle = "singletonPluginsAdminId";
export const fieldCallbackURL = "callbackURL";
export const fieldCopyButton = "copyButton";
export const fieldCallBackURLSection = "callbackURLSection";
export const CALLBACK_URL_HOST = "http[s]://AGENT_HOST_NAME/global/hook/";

const fieldDeployPluginParamsLabel = "deployPluginParamsLabel";
const fieldPluginsLabel = "pluginsLabel";
const fieldCallbackURLLabel = "callbackURLLabel";

export function getDefnDeployConfigPlugins(
  entSysFormMap: TypeEntSysFormMap,
  studioEntImport?: StudioEntImport,
  pluginResourceType?: EnumDefnPluginResources,
  isResourceTypeDevAvailable?: boolean
)
{
  const studioEntImportPlugin = studioEntImport as StudioEntPlugin;
  const pluginFormMap = studioEntImportPlugin.pluginFormMap;
  const pluginResourceMap = studioEntImportPlugin.pluginResourceMap;
  const pluginApiMap = studioEntImportPlugin.pluginApiIdMap;

  const isWebhook = Object.values(pluginApiMap).some(api => api.apiType === "webhook");

  let pluginTypeOptions = {
    keys: [] as MetaIdVar[],
    map: {} as Record<MetaIdVar, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  if(pluginResourceMap?.jar && !isEmpty(pluginResourceMap.jar))
  {
    const metaId = "jar";
    pluginTypeOptions.keys.push(metaId);
    pluginTypeOptions.map[metaId] = {
      metaId: metaId,
      value: "Jar"
    };
  }
  if(pluginResourceMap?.rpc && !isEmpty(pluginResourceMap.rpc))
  {
    const metaId = "rpc";
    pluginTypeOptions.keys.push(metaId);
    pluginTypeOptions.map[metaId] = {
      metaId: metaId,
      value: "RPC"
    };
  }
  if(pluginResourceMap?.dev && !isEmpty(pluginResourceMap.dev) && !isResourceTypeDevAvailable)
  {
    const metaId = "dev";
    pluginTypeOptions.keys.push(metaId);
    pluginTypeOptions.map[metaId] = {
      metaId: metaId,
      value: "Dev"
    };
  }

  let pluginConfigFormId: MetaIdForm | undefined;

  switch(pluginResourceType)
  {
    case "jar":
      pluginConfigFormId = pluginResourceMap?.jar?.pluginConfigFormId;
      break;
    case "rpc":
      pluginConfigFormId = pluginResourceMap?.rpc?.pluginConfigFormId;
      break;
    case "dev":
      pluginConfigFormId = pluginResourceMap?.dev?.pluginConfigFormId;
      break;
  }

  const pluginParamsDefnForm: DefnForm | undefined = pluginConfigFormId
    ? isSystemForm(pluginConfigFormId)
      ? cloneDeep(entSysFormMap[pluginConfigFormId])
      : cloneDeep(pluginFormMap[pluginConfigFormId])
    : undefined;
  const displayCompositeId: MetaIdComposite | undefined = pluginParamsDefnForm?.displayCompositeId;

  let isFieldPluginParamsVisible: Boolean = false;

  if(pluginParamsDefnForm)
  {
    const tab = displayCompositeId ? pluginParamsDefnForm.compMap[displayCompositeId] as DefnTab : undefined;
    if(tab)
    {
      const tabIdSet = tab.tabIdSet;
      if(tabIdSet?.length && tabIdSet.length === 1)
      {
        tab.pt = 5;
        tab.pb = 0;
      }
    }
  }

  if(pluginParamsDefnForm)
  {
    loopDefnForm(pluginParamsDefnForm, (parent, comp) =>
    {
      if(comp?.type === "section")
      {
        const section = comp as DefnSection;
        if(section.fieldIdSet?.length)
        {
          isFieldPluginParamsVisible = true;
        }
      }
      if(comp?.type === "grid")
      {
        const grid = comp as DefnGrid;
        if(grid.fieldIdSet?.length)
        {
          isFieldPluginParamsVisible = true;
        }
      }
    });
  }

  const studioForm = createDefaultDefnFormStudio({
    [fieldDeployPluginType]: {
      type: "pickText",
      metaId: fieldDeployPluginType,
      name: fieldDeployPluginType,
      label: "Plugin type",
      optionMap: pluginTypeOptions,
      required: true
    } as DefnFieldPickText,

    ...isWebhook && {
      ...getFieldGap(fieldGap1, "thick"),

      [fieldCallbackURLLabel]: {
        type: "label",
        metaId: fieldCallbackURLLabel,
        name: fieldCallbackURLLabel,
        label: "Callback url",
        disabled: true
      } as DefnFieldLabel,

      [fieldCallbackURL]: {
        type: "info",
        metaId: fieldCallbackURL,
        name: fieldCallbackURL,
        textSizeVar: "body2"
      } as DefnFieldInfo,

      [fieldCopyButton]: {
        buttonVariantVar: "icon",
        iconVar: "ContentCopyRounded",
        type: "button",
        metaId: fieldCopyButton,
        name: fieldCopyButton
      } as DefnFieldButton,

      [fieldCallBackURLSection]: {
        type: "section",
        metaId: fieldCallBackURLSection,
        sectionDirection: "horizontal",
        flexGrow: false,
        pb: 12,
        fieldSpanMap: {
          [fieldCallbackURL]: 10,
          [fieldCopyButton]: 1
        } as Record<MetaIdField, number>,
        fieldIdSet: [fieldCallbackURL, fieldCopyButton]
      } as DefnSection

    },

    ...isFieldPluginParamsVisible && {

      ...getFieldGap(fieldGap2, "thick"),

      [fieldDeployPluginParamsLabel]: {
        type: "label",
        metaId: fieldDeployPluginParamsLabel,
        label: "Plugin parameter form",
        disabled: true
      } as DefnFieldLabel,

      ...pluginParamsDefnForm?.compMap
    },

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [
        fieldDeployPluginType,
        ...(isWebhook
          ? [fieldGap1, fieldCallbackURLLabel, fieldCallBackURLSection]
          : []),
        ...(isFieldPluginParamsVisible
          ? [fieldGap2, fieldDeployPluginParamsLabel, displayCompositeId]
          : [])
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);

  if(!studioForm.visibilityRuleMap && pluginParamsDefnForm)
  {
    studioForm.visibilityRuleMap = pluginParamsDefnForm.visibilityRuleMap;
  }

  return studioForm;
}

export function isSystemForm(formId: string)
{
  return EnumArrayDefnForms.includes(formId as EnumDefnForms);
}

export function getDefnDeployConfigPlugin(entUserList?: DtoEntAdmin[])
{
  const userOptions = {
    keys: [] as MetaIdVar[],
    map: {} as Record<MetaIdVar, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  entUserList?.forEach(user =>
  {
    userOptions.keys.push(user.adminId);
    userOptions.map[user.adminId] = {
      metaId: user.adminId,
      value: user.handle
    };
  });

  return createDefaultDefnFormStudio({
    [fieldPluginAdminHandle]: {
      type: "pickText",
      label: "Admin for singleton plugins",
      metaId: fieldPluginAdminHandle,
      optionMap: userOptions,
      name: fieldPluginAdminHandle,
      helperTextVar: stringToDefnDtoText(DEPLOY_ADMIN_FOR_SINGLETON_PLUGINS)
    } as DefnFieldPickText,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldPluginsLabel]: {
      type: "label",
      metaId: fieldPluginsLabel,
      name: fieldPluginsLabel,
      label: "Plugins",
      disabled: true
    } as DefnFieldLabel,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      name: defaultSectionKey,
      fieldIdSet: [fieldPluginAdminHandle, fieldGap1, fieldPluginsLabel]
    } as DefnSection

  } as Record<MetaIdField, DefnField>);
}
