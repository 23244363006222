import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigBadgeMap} from "../../../api/home/drawer/sig/SigBadgeMap";
import {EntId} from "../../../api/meta/base/Types";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {ICacheBadgeState} from "./TypesCacheBadge";

export const sliceCacheBadge = createSlice({
  name: "cacheBadge",
  initialState: {
    badgeMap: {},
    aggBadgeMap: {},
    aggBadgeCount: 0
  } as ICacheBadgeState,
  reducers: {
    setBadgeMap: (state, action: PayloadAction<SigBadgeMap>) =>
    {
      const sig = action.payload;
      const sigBadgeMap = sig.entChatBadgeMap;

      const newBadgeMap = {} as Record<TypeComboId, number>;
      const newAggBadgeMap = {} as Record<EntId, number>;
      let newAggBadgeCount = 0;
      Object.keys(sigBadgeMap).forEach(entId =>
      {
        let totalBadge = 0;
        const chatRecord = sigBadgeMap[entId].chatBadgeMap;
        if(chatRecord)
        {
          Object.keys(chatRecord).forEach(chatId =>
          {
            const badge = chatRecord[chatId];

            if(badge > 0)
            {
              totalBadge += badge;
              const entChatId = toComboId(entId, chatId);
              newBadgeMap[entChatId] = badge;
            }
          });
        }

        if(totalBadge > 0)
        {
          newAggBadgeMap[entId] = totalBadge;
          newAggBadgeCount += totalBadge;
        }
      });

      state.badgeMapVersion = sig.version;
      state.badgeMap = newBadgeMap;
      state.aggBadgeMap = newAggBadgeMap;
      state.aggBadgeCount = newAggBadgeCount;
    }
  }
});

export const {
  setBadgeMap
} = sliceCacheBadge.actions;
