import {useTheme} from "@mui/material";
import {Typography} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {Box} from "@mui/system";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import RawIconButton from "./RawIconButton";
import RawTooltip from "./RawTooltip";

export default function RawQuickLink(props: {
  name: string,
  label?: string,
  variant?: Variant,
  color?: TypeTextColor,
  tooltip?: string,
  showUnderline?: boolean,
  bold?: boolean,
  Icon?: any
  onClick?: (name: string, menuAnchor: Element) => void,
  isMobile?: boolean
})
{
  const name = props.name;
  const label = props.label;
  const variant = props.variant;
  const color = props.color;
  const tooltip = props.tooltip;
  const showUnderline = props.showUnderline;
  const bold = props.bold;
  const Icon = props.Icon;
  const onClick = props.onClick;
  const theme = useTheme();
  const textColor = color ? theme.common.color(color) : theme.common.color("textPrimary");
  const isMobile = props.isMobile;

  if(onClick)
  {
    return (
      <RawTooltip title={tooltip}>
        <Box
          display={"flex"}
          alignItems={"center"}
          component={"span"}
        >
          {Icon &&
            <RawIconButton
              name={label || name}
              icon={Icon}
              onClick={(name, e) => onClick(name, e.currentTarget)}
              color={color}
              width={"18px"}
              padding={gapQuarter}
            />
          }
          <Typography
            style={{
              textDecoration: showUnderline ? "underline" : "none"
            }}
            variant={variant}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            sx={isMobile
              ? {
                fontSize: px(10),
                color: textColor,
                ...bold && {fontWeight: 500},
                "&:hover": {
                  cursor: "pointer"
                }
              }
              : {
                color: textColor,
                ...bold && {fontWeight: 500},
                "&:hover": {
                  cursor: "pointer"
                }
              }}
            onClick={(e: React.MouseEvent<HTMLElement>) =>
            {
              e.stopPropagation();
              onClick(label || name, e.currentTarget);
            }}
          >
            {label || toLabel(name)}
          </Typography>
        </Box>
      </RawTooltip>
    );
  }
  else
  {
    return (
      <Typography
        variant={variant}
        sx={{
          color: textColor
        }}
      >{toLabel(name)}</Typography>
    );
  }
}
