import {Stack} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import {EnumDefnPlacement} from "../../../../api/meta/base/Types";
import {getFormFieldValueAsText} from "../../../../base/plus/FieldValuePlus";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {getCompLabel} from "../../../form/viewer/base/FormViewerPlus";
import {GRID_FONT_VARIANT} from "../DataGridPlus";

export default function GridCellFooter(props: ICellRendererParams<IDataGridCell>)
{
  const colId = props.colDef?.colId;
  const searchWords = (props.context as AgGridContext).searchWords;
  const defnForm = (props.context as AgGridContext).defnForm;
  const footerValue = (props.context as AgGridContext).footerValue;
  const dtoFooter = (props.data && colId)
    ? props.data.footerValueMap?.[colId]?.dtoFooter
    : undefined;
  const displayFieldId = dtoFooter?.displayFieldId;
  const field = displayFieldId ? defnForm?.compMap[displayFieldId] : undefined;
  const value = displayFieldId ? footerValue?.[displayFieldId] : undefined;

  const showLabel = dtoFooter?.showLabel;
  const cellValue = getFormFieldValueAsText(field, value);
  const label = field ? getCompLabel(field) : undefined;
  const textAlign = dtoFooter?.alignment;

  if(showLabel && label)
  {
    return (
      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={getJustifyContent(textAlign)}
        height={"100%"}
        alignItems={"center"}
      >
        <RawHighlighter
          searchWords={searchWords}
          variant={GRID_FONT_VARIANT}
          color={"textSecondary"}
          value={label + ": "}
          height={"100%"}
        />

        <RawHighlighter
          searchWords={searchWords}
          variant={GRID_FONT_VARIANT}
          color={"textSecondary"}
          value={cellValue}
          height={"100%"}
        />
      </Stack>
    );
  }

  return (
    <RawHighlighter
      searchWords={searchWords}
      variant={GRID_FONT_VARIANT}
      color={"textSecondary"}
      value={cellValue}
      textAlign={textAlign}
    />
  );
}

function getJustifyContent(justifyContent?: EnumDefnPlacement)
{
  switch(justifyContent)
  {
    case "center":
    case "end":
    case "start":
      return justifyContent;
    case "spaceBetween":
      return "space-between";
    default:
      return undefined;
  }
}
