import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarDividerKind} from "../../../api/meta/base/dto/StudioVarDividerKind";

export function fnVariableBuilderDividerType(fieldValueDividerType: string)
{
  function getVarComp()
  {
    return {
      [fieldValueDividerType]: {
        type: "enumThemeDividerThickness",
        metaId: fieldValueDividerType,
        label: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarDividerKind
  )
  {
    valueMap[fieldValueDividerType] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueDividerType];

    return {
      value: value
    } as StudioVarDividerKind;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
