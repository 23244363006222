import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {DtoMessagePayloadSpreadsheetRow} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {MessageId} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {ICacheStarMessage} from "./TypesCacheStarMsgList";
import {ICacheStarMsgListState} from "./TypesCacheStarMsgList";

export const sliceCacheStarMsgList = createSlice({
  name: "sliceCacheStarMsgList",
  initialState: {
    starMsgMap: {},
    rowIdMap: {},
    starMsgList: [],
    badgeCount: 0,
    version: ""
  } as ICacheStarMsgListState,
  reducers: {
    setStarMsgList: (
      state,
      action: PayloadAction<{
        starMsgMap: Record<MessageId, ICacheStarMessage>,
        msgIdList: MessageId[],
        version: string
      }>) =>
    {
      const sig = action.payload;
      const newMsgMap = {} as Record<MessageId, ICacheStarMessage>;
      const rowIdMap = {} as Record<RowId, MessageId>;
      const newMsgList = [] as MessageId[];

      sig.msgIdList.map((msgId) =>
      {
        const msg = sig.starMsgMap[msgId];
        if(msg)
        {
          if(msg.payload.messageType === "spreadsheetRow")
          {
            rowIdMap[(msg.payload as DtoMessagePayloadSpreadsheetRow).rowId] = msg.messageId;
          }
          newMsgList.push(msg.messageId);
          newMsgMap[msg.messageId] = msg;
        }
      });

      state.rowIdMap = rowIdMap;
      state.starMsgMap = newMsgMap;
      state.starMsgList = newMsgList;
      state.version = sig.version;
    },
    clearStarMsgList: (state) =>
    {
      state.rowIdMap = {};
      state.starMsgMap = {};
      state.starMsgList = [];
      state.version = "";
      state.badgeCount = 0;
    },
    setStarMsgListBadgeCount: (state, payload: PayloadAction<number>) =>
    {
      state.badgeCount = payload.payload;
    },

    setStarMsgFormResult: (state, action: PayloadAction<{msgId: MessageId, spreadsheetRow: SigSpreadsheetRow}>) =>
    {
      const payload = action.payload;
      if(payload)
      {
        (state.starMsgMap[payload.msgId]?.payload as DtoMessagePayloadSpreadsheetRow).spreadsheetRow = payload.spreadsheetRow;
      }
    },
    setStarMsgRowDeleted: (state, action: PayloadAction<{msgId: MessageId}>) =>
    {
      const sig = action.payload;
      if(sig)
      {
        (state.starMsgMap[sig.msgId]?.payload as DtoMessagePayloadSpreadsheetRow).messageType = "spreadsheetRowDeleted";
      }
    }
  }
});
export const {
  setStarMsgList,
  clearStarMsgList,
  setStarMsgListBadgeCount,
  setStarMsgFormResult,
  setStarMsgRowDeleted
} = sliceCacheStarMsgList.actions;
