import {PolylineF} from "@react-google-maps/api";
import React from "react";
import {EnumDefnThemeStroke} from "../../api/meta/base/Types";
import {px} from "../../base/plus/StringPlus";
import {ILatLng} from "../../base/types/TypesStudio";

export function MapPolyLine(props: {
  polyLineArray?: ILatLng[],
  polyLineStroke?: EnumDefnThemeStroke,
  polyLineColor?: string
})
{
  const polyLineArray = props.polyLineArray;
  const polyLineStroke = props.polyLineStroke;
  const polyLineColor = props.polyLineColor;

  // PolyLine Style
  const polyLineStrokeStyle = getPolyLineStrokeStyle(polyLineStroke);

  const polyLineStrokeIcon =
    {
      path: polyLineStrokeStyle.path,
      strokeOpacity: 1,
      scale: polyLineStrokeStyle.scale
    };
  const polyLineStrokeRepeat = polyLineStrokeStyle.repeat;

  return (
    <PolylineF
      visible={true}
      path={polyLineArray}
      options={{
        geodesic: true,
        strokeColor: polyLineColor,
        strokeOpacity: 1,
        strokeWeight: 6,
        icons: [
          {
            icon: polyLineStrokeIcon,
            offset: "1",
            repeat: px(polyLineStrokeRepeat)
          }
        ]
      }}
    />
  );
}

interface IPolyLineStrokeStyle
{
  path: string;
  scale: number;
  repeat: number;
}

function getPolyLineStrokeStyle(value?: EnumDefnThemeStroke): IPolyLineStrokeStyle
{
  switch(value)
  {
    case "solid":
      return {
        path: "M 0,-1 0,1",
        scale: 2,
        repeat: 1
      };
    case "dash":
      return {
        path: "M 0,-1 0,1",
        scale: 2,
        repeat: 12
      };
    case "dotted":
      return {
        path: "M 0,0 l 0,0",
        scale: 4,
        repeat: 10
      };
    default:
      return {
        path: "M 0,-1 0,1",
        scale: 2,
        repeat: 1
      };
  }
}
