import {EntId} from "../../../../api/meta/base/Types";
import {SigEntUserList} from "../../../../api/studio/studioMain/sig/SigEntUserList";
import ISrvc from "../../../../base/ISrvc";
import {store} from "../../../../Store";
import {setProductionUserFilter} from "../../../controlPanel/SliceCacheControlPanel";
import {TypeProductionUserFilterType} from "../../../controlPanel/TypeCacheControlPanel";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {removeEntUserList} from "../SliceCacheStudioEnt";
import {setEntUserList} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntUsers extends ISrvc
{
  setEntUserList(entId: EntId, sig: SigEntUserList)
  {
    dispatchEnt(entId, setEntUserList({
      entId: entId,
      sigEntUserList: sig
    }));
  }

  removeEntUsersList(entId: EntId)
  {
    dispatchEnt(entId, removeEntUserList(entId));
  }

  setUserFilter(entId: EntId, filterType: TypeProductionUserFilterType)
  {
    store.dispatch(setProductionUserFilter({
      artifactId: entId,
      userFilterType: filterType
    }));
  }
}
