import {isEmpty} from "lodash";
import {nextMetaIdDriveSheet} from "../../../../api/meta/base/ApiPlus";
import {StudioEntDriveSheet} from "../../../../api/meta/base/dto/StudioEntDriveSheet";
import {StudioEntDriveSheetMap} from "../../../../api/meta/base/dto/StudioEntDriveSheetMap";
import {EntId, MetaIdDriveSheet} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntDriveSheet, moveEntItem, removeEntDriveSheet, updateEntDriveSpreadsheetSettings} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntDrives extends ISrvc
{
  addEntDriveSheet(entId: EntId, driveSheet: StudioEntDriveSheet)
  {
    dispatchEnt(entId, addEntDriveSheet({
      entId: entId,
      sig: {
        ...driveSheet,
        metaId: driveSheet.metaId || nextMetaIdDriveSheet()
      }
    }));
  }

  removeEntDriveSheet(entId: EntId, metaIdDriveSheet: MetaIdDriveSheet)
  {
    dispatchEnt(entId, removeEntDriveSheet({
      entId: entId,
      metaId: metaIdDriveSheet
    }));
  }

  copyEntDriveSheet(entId: EntId, metaIdDriveSheet: MetaIdDriveSheet)
  {
    const rootState = store.getState();
    const driveSheetMap = selectCacheStudioEnt(rootState, entId)?.ent.driveSheetMap;
    const driveData = driveSheetMap?.map[metaIdDriveSheet];

    if(!isEmpty(driveData))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "drive",
        payload: driveData
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  updateEntDriveSpreadsheetSettings(entId: EntId, driveSheetSettings: StudioEntDriveSheetMap)
  {
    dispatchEnt(entId, updateEntDriveSpreadsheetSettings({
      entId,
      ...driveSheetSettings
    }));
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdDriveSheet,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "driveSheetList",
      moveDirection
    }));
  }
}
