// These must be the first lines
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import React from "react";
import "react-app-polyfill/ie9"; // includes IE10 and IE11 support
import "react-app-polyfill/stable";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import App from "./App";
import theme from "./base/plus/ThemePlus";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {store} from "./Store";

// StyledEngineProvider changes CSS injection order to the top of HTML <head> and helps
// removes the need for !Important

const root = createRoot((document.getElementById("root"))!);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
