import React, {useCallback, useEffect, useMemo} from "react";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {EntId, EnumDefnAdminDoNotOptionEnt, MetaIdField} from "../../../../api/meta/base/Types";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {filterStudioFormByModules} from "../../../../base/plus/StudioFormPlus";
import {validateAdminDoNotShowPermission, validateAdminEditLockPermission} from "../../../../base/plus/StudioPlus";
import {listReset} from "../../../../base/slices/list/SliceList";
import {listSetSelectedItemId} from "../../../../base/slices/list/SliceListSharedActions";
import {IMainPropsStudioFormBuilder} from "../../../../base/types/TypesMain";
import {IStudioSize} from "../../../../base/types/TypesStudio";
import {useAppSelector} from "../../../../nucleus/app/AppHooks";
import LayoutFlexCol from "../../../../nucleus/atom/layout/LayoutFlexCol";
import {useAppCtx} from "../../../../nucleus/ctx/CtxApp";
import {fieldGridRowActionPermission} from "../../../../nucleus/form/builder/aside/FormBuilderEditGrid";
import {fieldPermissionMatrixMap} from "../../../../nucleus/form/builder/base/FormBuilderPlus";
import {CbOnDragBuilderItem} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fieldVisibilityActionsIfFalse} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {propKeyDynamicRuleMap} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fieldContentLayoutList} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {IFormBuilderMoveItem} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fieldMapOfFormula, fieldMapOfLayoutGrid, fieldVisibilityActionsIfTrue, fieldVisibilityCondMap, fieldVisibilityList, FormBuilderItemVariant, ICallerPermission, IFormBuilderRemoveItem, IFormBuilderSubmitItem} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {fnIsCallerPermissionForm} from "../../../../nucleus/form/builder/FormBuilder";
import FormBuilder from "../../../../nucleus/form/builder/FormBuilder";
import {Srvc} from "../../../../srvc/Srvc";
import {RootState} from "../../../../Store";
import {useGetCliCode} from "../../app/UiStudioPlus";
import {useArtifactVarAsideFns} from "../../app/UiStudioPlus";
import {useArtifactStudioVariableEditor} from "../variables/UiStudioEntVariable";

export default function UiStudioEntForm(props: {
  entId: EntId,
  studioSize: IStudioSize,
})
{
  const entId = props.entId;
  const studioSize = props.studioSize;

  const appCtx = useAppCtx();
  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const formId = mainProps.formId;
  const selectStudioEnt = mainProps.studioSelector.ent;
  const selectedModules = mainProps.studioSelector.selectedModules;
  const entFormStore = mainProps.studioSelector.formStore;
  const entLocalState = mainProps.studioSelector.studioLocalState;

  const listName = useAppSelector(state => selectList(state).listName);
  const formStore = useAppSelector(state => entFormStore(state));
  const formMap = useAppSelector(state => selectStudioEnt(state)?.formMap);
  const spreadsheetMap = useAppSelector(state => selectStudioEnt(state)?.spreadsheetMap);
  const reportMap = useAppSelector(state => selectStudioEnt(state)?.reportMap);
  const varMap = useAppSelector(state => selectStudioEnt(state)?.varMap);
  const setupMode = useAppSelector(state => entLocalState(state)?.setupMode || "advanced");
  const selectedFilter = useAppSelector(state => selectedModules(state));
  const displayDateFormat = useAppSelector(state => selectStudioEnt(state)?.details?.displayDateFormat);

  const callerManageTabPermission = useAppSelector(state => getCallerManageTabPermissionForm(state, entId));
  const readOnlyForm = fnIsCallerPermissionForm(callerManageTabPermission, "Forms");

  const {
    cbAddVar,
    cbShowVar
  } = useArtifactVarAsideFns(entId);

  const getCliCode = useGetCliCode();

  const getVariableAside = useArtifactStudioVariableEditor({
    artifactId: entId,
    readonly: readOnlyForm,
    entFormStore: entFormStore,
    displayDateFormat: displayDateFormat
  });

  const form = useMemo(() => (formMap?.map && formId)
    ? formMap.map[formId]
    : undefined, [formMap?.map, formId]);

  const filteredForm = useMemo(() => form
    ? filterStudioFormByModules(form, (itemModules) => Srvc.studio.ent.filterListByTag(entId, itemModules))
    : undefined, [form, selectedFilter]);

  const cbOnSubmit = useCallback((variant: FormBuilderItemVariant, item: IFormBuilderSubmitItem) =>
  {
    const formId = item.formId;

    switch(variant)
    {
      case "form":

        if(item.form)
        {
          !item.isDuplicate
            ? Srvc.cache.studio.ent.forms.addEntForm(entId, item.form)
            : Srvc.cache.studio.ent.forms.duplicateEntForm(entId, formId);
        }
        break;
      case "visibilityRule":
        if(item.visibilityRule)
        {
          !item.isDuplicate
            ? Srvc.cache.studio.ent.forms.addEntFormVisibilityRule(entId, formId, item.visibilityRule)
            : Srvc.cache.studio.ent.forms.duplicateEntFormVisibilityRule(entId, formId, item.visibilityRule.metaId);
        }
        break;
      case "contentLayout":
        if(item.contentLayout)
        {
          !item.isDuplicate
            ? Srvc.cache.studio.ent.forms.addEntFormContentLayout(entId, formId, item.contentLayout)
            : Srvc.cache.studio.ent.forms.duplicateEntFormContentLayout(entId, formId, item.contentLayout.metaId);
        }
        break;
      case "formula":
        if(item.formula)
        {
          !item.isDuplicate
            ? Srvc.cache.studio.ent.forms.addEntFormFormula(entId, formId, item.formula)
            : Srvc.cache.studio.ent.forms.duplicateEntFormFormula(entId, formId, item.formula.metaId);
        }
        break;
      case "composite":
        if(item.composite)
        {
          !item.isDuplicate
            ? Srvc.cache.studio.ent.forms.addEntFormComposite(entId, formId, item.composite)
            : Srvc.cache.studio.ent.forms.duplicateEntFormComposite(entId,
              formId,
              (item.composite as StudioSection | StudioGrid)?.metaId
            );
        }
        break;

      case "defaultAsideLayout":
        Srvc.cache.studio.ent.forms.addEntDefaultAsideLayout(entId, formId, item.defaultContentLayout);
        break;

      case "field":

        if(item.field)
        {
          !item.isDuplicate
            ? Srvc.cache.studio.ent.forms.addEntFormField(entId, formId, item.field.compositeId, item.field.field)
            : Srvc.cache.studio.ent.forms.duplicateEntFormField(entId,
              formId,
              item.field.compositeId,
              item.field.field.metaId
            );
        }
        break;
      case "studioVar":
        item.studioVar && cbAddVar(item.studioVar);
        break;
    }
  }, [entId]);

  const cbOnRemove = useCallback((
    variant: FormBuilderItemVariant,
    item: IFormBuilderRemoveItem,
    preventTrashRemove?: boolean
  ) =>
  {
    const compositeId = item.compositeId;
    const fieldId = item.fieldId;
    const visibilityRuleId = item.visibilityRuleId;
    const contentLayoutId = item.contentLayoutId;
    const formulaId = item.formulaId;

    switch(variant)
    {
      case "composite":
        compositeId &&
        Srvc.cache.studio.ent.forms.removeEntFormComposite(entId, formId, compositeId);
        break;
      case "visibilityRule":
        visibilityRuleId &&
        Srvc.cache.studio.ent.forms.removeEntFormVisibilityRule(entId, formId, visibilityRuleId);
        break;
      case "contentLayout":
        contentLayoutId &&
        Srvc.cache.studio.ent.forms.removeEntFormContentLayout(entId, formId, contentLayoutId);
        break;
      case "formula":
        formulaId &&
        Srvc.cache.studio.ent.forms.removeEntFormFormula(entId, formId, formulaId);
        break;
      case "field":
        compositeId &&
        fieldId &&
        Srvc.cache.studio.ent.forms.removeEntFormField(entId, formId, compositeId, fieldId, preventTrashRemove);
        break;
    }
  }, [entId, formId]);

  const cbOnMoveItem = useCallback((
    variant: FormBuilderItemVariant,
    item: IFormBuilderMoveItem) =>
  {
    if(variant === "composite"
      || variant === "field")
    {
      if(item.compositeId)
      {
        Srvc.cache.studio.ent.forms.moveFormItem(entId,
          formId,
          item.direction,
          variant,
          item.compositeId,
          item.headerFooterId,
          item.fieldId
        );
      }
    }
    else if(variant === "formula")
    {
      if(item.formulaId)
      {
        Srvc.cache.studio.ent.forms.moveFormItem(
          entId,
          formId,
          item.direction,
          variant,
          undefined,
          undefined,
          undefined,
          item.formulaId
        );
      }
    }
    else if(variant === "visibilityRule")
    {
      if(item.visibilityRuleId)
      {
        Srvc.cache.studio.ent.forms.moveFormItem(
          entId,
          formId,
          item.direction,
          variant,
          undefined,
          undefined,
          undefined,
          undefined,
          item.visibilityRuleId
        );
      }
    }
    else if(variant === "contentLayout")
    {
      if(item.contentLayoutId)
      {
        Srvc.cache.studio.ent.forms.moveFormItem(
          entId,
          formId,
          item.direction,
          variant,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          item.contentLayoutId
        );
      }
    }
  }, [entId, formId]);

  const cbOnDragItem: CbOnDragBuilderItem = useCallback((
    variant,
    itemId,
    dragStartIndex,
    dragEndIndex,
    fromCompId,
    fromCompIndex,
    toCompId,
    toCompIndex
  ) =>
  {
    Srvc.cache.studio.ent.forms.dragFormItem(entId,
      formId,
      variant,
      itemId,
      dragStartIndex,
      dragEndIndex,
      fromCompId,
      fromCompIndex,
      toCompId,
      toCompIndex
    );
  }, [entId, formId]);

  const cbOnEditVar = useCallback((
    variable: StudioVar,
    cbOnClose: () => void
  ) =>
  {
    return getVariableAside({
      variable: variable,
      onClickClose: cbOnClose,
      submitOnClose: true
    });
  }, [getVariableAside]);

  useEffect(() =>
  {
    if(filteredForm)
    {
      Srvc.app.form.formBuilder.loadFormDetailsList(listName,
        filteredForm,
        Boolean(readOnlyForm),
        spreadsheetMap,
        formMap,
        reportMap,
        varMap
      );
    }
    else
    {
      appCtx.setMainProps({
        ...mainProps,
        jumpStep: "Forms"
      } as IMainPropsStudioFormBuilder);
    }

  }, [filteredForm, spreadsheetMap, formMap, readOnlyForm, reportMap, varMap]);

  useEffect(() =>
  {
    return () =>
    {
      appCtx.setAsideProps(undefined);
      dispatchList(listName, listReset());
      dispatchList(listName, listSetSelectedItemId(undefined));
    };
  }, [listName]);

  return (
    <LayoutFlexCol
      width={"100%"}
      height={"100%"}
      justifyContent={"flex-start"}
    >
      <FormBuilder
        selectList={selectList}
        selectListMap={selectListFormMap()}
        studioSize={studioSize}
        formStore={formStore}
        setupMode={setupMode}
        cbOnSubmit={cbOnSubmit}
        cbOnRemove={cbOnRemove}
        cbOnMoveItem={cbOnMoveItem}
        callerPermission={callerManageTabPermission}
        cbOnShowVar={cbShowVar}
        cbOnAddNewVar={cbAddVar}
        cbOnEditVar={cbOnEditVar}
        cbOnDragItem={cbOnDragItem}
        cbGetCliCode={getCliCode}
      />
    </LayoutFlexCol>
  );
}

function selectList(state: RootState)
{
  return state.studio.ent.entFormSectionList;
}

export const fieldMenuActionPermission = "actionPermissionMap";

export function selectListFormMap()
{
  return {
    [fieldPermissionMatrixMap]: state => state.studio.app.fieldPermissionMatrixList,
    [fieldVisibilityActionsIfTrue]: state => state.studio.app.formVisibilityActionListIfTrue,
    [fieldVisibilityActionsIfFalse]: state => state.studio.app.formVisibilityActionListIfFalse,
    [fieldVisibilityCondMap]: state => state.studio.app.conditionList,
    [fieldMapOfLayoutGrid]: state => state.studio.app.formLayoutGridList,
    [fieldContentLayoutList]: state => state.studio.app.formContentLayoutList,
    [fieldMapOfFormula]: state => state.studio.app.formFormulaSetList,
    [fieldVisibilityList]: state => state.studio.app.formVisibilityList,
    [fieldMenuActionPermission]: state => state.studio.ent.aside.groupActionRoleMapList,
    [fieldGridRowActionPermission]: state => state.studio.ent.aside.groupActionRowRoleMapList,
    [propKeyDynamicRuleMap]: state => state.studio.ent.aside.dynamicRuleList
  } as Record<MetaIdField, SelectList>;
}

export function getCallerManageTabPermissionForm(state: RootState, entId: EntId): ICallerPermission
{
  const getCanEdit = (
    tabName: EnumDefnAdminDoNotOptionEnt,
    parentTabName?: EnumDefnAdminDoNotOptionEnt) => validateAdminEditLockPermission(state,
    entId,
    tabName,
    parentTabName
  );

  const getCanShow = (
    tabName: EnumDefnAdminDoNotOptionEnt,
    parentTabName?: EnumDefnAdminDoNotOptionEnt) => validateAdminDoNotShowPermission(state,
    entId,
    tabName,
    parentTabName
  );

  return {
    formEditDetails: {
      edit: getCanEdit("formEditDetails", "forms"),
      show: getCanShow("formEditDetails", "forms")
    },
    formEditVisibility: {
      edit: getCanEdit("formEditVisibility", "forms"),
      show: getCanShow("formEditVisibility", "forms")
    },
    formEditContentLayout: {
      edit: getCanEdit("formEditContentLayout", "forms"),
      show: getCanShow("formEditContentLayout", "forms")
    },
    formEditFormLayouts: {
      edit: getCanEdit("formEditFormLayouts", "forms"),
      show: getCanShow("formEditFormLayouts", "forms")
    },
    formEditFormulas: {
      edit: getCanEdit("formEditFormulas", "forms"),
      show: getCanShow("formEditFormulas", "forms")
    },
    forms: {
      edit: getCanEdit("forms"),
      show: getCanShow("forms")
    },
    formEditPaymentConfig: {
      edit: getCanEdit("payment", "forms"),
      show: getCanShow("payment", "forms")
    }
  } as ICallerPermission;
}
