import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import _ from "lodash";
import {EnumArrayStoreLabel} from "../../../api/meta/base/Types";
import {EnumStoreLabel} from "../../../api/meta/base/Types";
import LocalFilterPickMany from "../../../base/local/LocalFilterPickMany";
import {putItem} from "../../../base/plus/JsPlus";
import {removeItem} from "../../../base/plus/JsPlus";

const localFilter = new LocalFilterPickMany((callerIdHash) => `store_filter_${callerIdHash}`);

export type ActionLoadStoreFilter = {
  callerIdHash: string,
};

export type ActionUpdateStoreFilter = {
  callerIdHash: string,
  storeItemIdCategory: EnumStoreLabel,
  pickValue: boolean
};

export interface IStudioFilterState
{
  storeItemIdCategorySet: EnumStoreLabel[];
}

export const sliceCacheStudioFilter = createSlice({
  name: "studioFilter",
  initialState: {
    storeItemIdCategorySet: [] as EnumStoreLabel[]
  } as IStudioFilterState,
  reducers: {
    loadStoreFilter: (state, action: PayloadAction<ActionLoadStoreFilter>) =>
    {
      const payload = action.payload;
      const callerIdHash = payload.callerIdHash;

      let storeItemCategoryArray = localFilter.get(callerIdHash);
      state.storeItemIdCategorySet = storeItemCategoryArray.length > 0
        ? _.intersection(storeItemCategoryArray, EnumArrayStoreLabel) as EnumStoreLabel[]
        : [];
    },
    updateStoreFilter: (state, action: PayloadAction<ActionUpdateStoreFilter>) =>
    {
      const payload = action.payload;

      let storeItemIdCategorySet = state.storeItemIdCategorySet;
      const callerIdHash = payload.callerIdHash;
      const artifactId = payload.storeItemIdCategory;
      const pickValue = payload.pickValue;

      if(pickValue)
      {
        if(!storeItemIdCategorySet)
        {
          storeItemIdCategorySet = [] as EnumStoreLabel[];
          state.storeItemIdCategorySet = storeItemIdCategorySet;
        }
        putItem(storeItemIdCategorySet, artifactId);
        localFilter.ensure(callerIdHash, artifactId);
      }
      else
      {
        if(storeItemIdCategorySet)
        {
          removeItem(storeItemIdCategorySet, artifactId);
          if(storeItemIdCategorySet.length === 0)
          {
            state.storeItemIdCategorySet = [];
            localFilter.clear(callerIdHash);
          }
          else
          {
            localFilter.remove(callerIdHash, artifactId);
          }
        }
      }
    },
    clearStoreFilter: (state, action: PayloadAction<string>) =>
    {
      const callerIdHash = action.payload;
      state.storeItemIdCategorySet = [];
      localFilter.clear(callerIdHash);
    }
  }
});

export const {
  loadStoreFilter,
  updateStoreFilter,
  clearStoreFilter

} = sliceCacheStudioFilter.actions;

