import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldPickRole} from "../../api/meta/base/dto/DefnFieldPickRole";
import {DefnFieldSetOfRole} from "../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {MetaIdRole} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldRole = "role";
const fieldRoleSet = "roleSet";

export interface IStudioForwardRolePermission
{
  roleId: MetaIdRole,
  roleIdSet: MetaIdRole[]
}

export default function DialogForwardRolePermission(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  values?: IStudioForwardRolePermission,
  onClickOk: (values: IStudioForwardRolePermission) => void,
  onClose?: () => void,
  excludeRoleIdSet?: MetaIdRole[],
  validationError?: IFormFieldError[]
})
{
  const values = props.values;
  const excludeRoleIdSet = props.excludeRoleIdSet;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} forward role permission`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(excludeRoleIdSet),
        store: props.formStore,
        formReadonly: props.isFormReadOnly,
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: values ? {...dtoToValue(values)} : undefined
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!values
        ? "Add more forward role permission"
        : undefined}
    />
  );
}

function valueToDto(values: FieldValues): IStudioForwardRolePermission
{
  return {
    roleId: fnFieldValueToRawValue("pickRole", values[fieldRole]),
    roleIdSet: fnFieldValueToRawValue("setOfRole", values[fieldRoleSet])
  } as IStudioForwardRolePermission;
}

function dtoToValue(dto?: IStudioForwardRolePermission)
{
  if(dto)
  {
    return {
      [fieldRole]: fnRawValueToFieldValue("pickRole", dto.roleId),
      [fieldRoleSet]: fnRawValueToFieldValue("setOfRole", dto.roleIdSet)
    };
  }
}

function getDefnForm(excludeRoleIdSet?: MetaIdRole[])
{
  return createDefaultDefnFormStudio({
    [fieldRole]: {
      type: "pickRole",
      name: fieldRole,
      metaId: fieldRole,
      label: "Role",
      required: true,
      excludeRoleIdSet: excludeRoleIdSet
    } as DefnFieldPickRole,
    [fieldRoleSet]: {
      type: "setOfRole",
      name: fieldRoleSet,
      metaId: fieldRoleSet,
      label: "Role set",
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldRole, fieldRoleSet]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);

}


