import {RpcApi} from "../../api/nucleus/api/RpcApi";
import ISrvc from "../../base/ISrvc";
import {Srvc} from "../Srvc";
import {CbSuccessGetAll} from "./SrvcApiBrFilter";

export default class SrvcApibrTree extends ISrvc
{
  // this is tree, not ready for recursiveCalls
  rpcApiListGet(cbSuccess: CbSuccessGetAll)
  {
    RpcApi.apiListGet(envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        const sig = envSig.sig;
        if(sig)
        {
          cbSuccess(sig);
        }
      }
    });
  }
}
