import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioPickFormId} from "../../../../api/meta/base/dto/DefnStudioPickFormId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickFormId from "../raw/FieldRawStudioPickFormId";

export default function FieldStudioSetOfFormId(props: {
  defn: DefnStudioPickFormId,
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickFormId
        fieldProps={fieldProps}
        defn={props.defn}
        multiSelect={true}
        showChip={true}
      />}
    />
  );
}

