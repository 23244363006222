import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import {DtoMessagePayloadVideo} from "../../../../api/home/base/dto/DtoMessagePayloadVideo";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../base/plus/ConstantsPlus";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {getDurationFromMs} from "../../../../base/plus/StringPlus";
import {removeFileNameExt} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import {usePageCtx} from "../../../ctx/CtxPage";
import {DialogVideo} from "../../dialog/DialogVideo";
import IconBubbleCaption from "../../icon/IconBubbleCaption";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import RawLazyImage from "../../raw/RawLazyImage";
import RawLinerGradient from "../../raw/RawLinerGradient";
import BubbleRawCaption from "../raw/BubbleRawCaption";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawLinkText from "../raw/BubbleRawLinkText";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleShell from "./BubbleShell";

export default function BubbleVideo(props: {
  payload: DtoMessagePayloadVideo,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  header?: IBubbleHeader,
  isStar?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
  onClickSendMessageToUser?: (entUserId: EntUserId, menuAnchor: Element) => void
})
{
  const theme = useTheme();
  const pageCtx = usePageCtx();
  const onClickCaption = props.cbOnClickFooterCaption;

  const captionIcon = props.receiptStatus;
  const payload = props.payload;
  const header = props.header;
  const imgWidth = theme.common.calcBubbleFixedWidth(props.maxWidth);
  const footerOverlay = Boolean(!(payload.text.trim().length > 0)); // show footer overlay is no image caption

  const bubbleSize = theme.common.calcBubbleSize(payload?.width as number, payload?.height as number, imgWidth);
  const fileName = payload?.fileName ? removeFileNameExt(payload.fileName) : "";

  const video = {
    ...payload,
    src: getMediaSrc(payload.mediaIdVideo),
    thumbSrc: getMediaSrc(payload.mediaId),
    srcBlur: getMediaSrc(payload.mediaIdBlurImage),
    width: bubbleSize.width,
    height: bubbleSize.height
  };

  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));

  const mentionMap = payload.mentionMap;
  const onClickSendMessageToUser = props.onClickSendMessageToUser;

  const onClickMessageToUser = (mentionUser: string, menuAnchor: Element) =>
  {
    if(mentionMap)
    {
      const entUserId = mentionMap[mentionUser];
      onClickSendMessageToUser && onClickSendMessageToUser(entUserId, menuAnchor);
    }
  };

  const onClickVideo = () => pageCtx.showDialog(
    <DialogVideo
      onClose={() => pageCtx.showDialog(undefined)}
      url={video.src ? video.src as string : ""}
      title={fileName}
      thumbSrc={video.thumbSrc}
      blurImg={video.mediaIdBlurImage}
      primaryColor={video.primaryColor}
      height={video.height}
      width={video.width}
    />
  );

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={props.maxWidth}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleBlinking={props.isBubbleBlinking}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      {
        header !== undefined &&
        <BubbleRawHeader
          header={header}
          onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
        />
      }

      {
        props.replyPayload &&
        <BubbleRawReplyPreview
          replyPayload={props.replyPayload}
          replyInfo={props.replyInfo}
          onClickPreview={props.cbOnClickReplyPreview}
        />
      }
      <Box position={"relative"}>
        <RawLazyImage
          borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
          src={video.thumbSrc}
          srcBlur={video.srcBlur}
          height={video.height ? px((video.height)) : "auto"}
          width={video.width ? px((video.width)) : "100%"}
          primaryColor={video.primaryColor}
        />

        {
          footerOverlay &&
          <RawLinerGradient
            type={"bottom"}
          >
            <LayoutFlexRow
              pr={px(theme.common.gapQuarter)}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <BubbleRawCaption
                textLeft={getDurationFromMs(payload.durationMs)}
                textColor={"textInverse"}
              />

              <BubbleRawCaption
                isStar={props.isStar}
                textRight={captionTime}
                textColor={"textInverse"}
                icon={captionIcon}
                onClickCaption={onClickCaption}
              />
            </LayoutFlexRow>
          </RawLinerGradient>
        }

        {/* Video Play Button */}
        <Box
          width={bubbleSize.width}
          position={"absolute"}
          left={0}
          right={0}
          top={0}
          bottom={0}
          margin={"auto"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "rgba(0, 0, 0, 0.1)",
            borderRadius: px(CHAT_ITEM_IMAGE_BORDER_RADIUS)
          }}

        >
          <LayoutFlexRow
            onClick={onClickVideo}
          >
            <PlayCircleFilledIcon
              fontSize={"large"}
              sx={{
                color: "rgba(255, 255, 255, 0.4)",
                width: px(55),
                height: px(48),
                position: "relative",
                cursor: "pointer"

              }}
            />
            <PlayArrowIcon
              fontSize={"large"}
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                margin: "auto",
                color: "white",
                cursor: "pointer"
              }}
            />
          </LayoutFlexRow>
        </Box>
      </Box>

      {
        !footerOverlay && (
          <BubbleRawLinkText
            value={payload.text}
            maxCharLimit={300}
            maxWidth={video.width}
            mentionMap={mentionMap}
            onClickSendMessageToUser={onClickMessageToUser}
            fakeSpace={
              <>
                {Boolean(props.isStar) &&
                  <IconBubbleCaption
                    value={"star"}
                    color={"dark"}
                  />
                }
                <Typography
                  ml={px(theme.common.gapQuarter)}
                  variant={"caption"}
                >
                  {captionTime}
                </Typography>
                <IconBubbleCaption
                  value={captionIcon}
                  color={"dark"}
                />
              </>
            }
            caption={
              <BubbleRawCaption
                isStar={Boolean(props.isStar)}
                textRight={captionTime}
                icon={captionIcon}
                iconColor={"dark"}
                onClickCaption={onClickCaption}
              />
            }
          />
        )
      }
    </BubbleShell>
  );
}
