import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioBuildPropertyMap} from "../../../api/meta/base/dto/DefnStudioBuildPropertyMap";
import {DefnStudioPickFormId} from "../../../api/meta/base/dto/DefnStudioPickFormId";
import {StudioBuildArgBinderHolder} from "../../../api/meta/base/dto/StudioBuildArgBinderHolder";
import {StudioVarMapOfText} from "../../../api/meta/base/dto/StudioVarMapOfText";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fieldGap1} from "../../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../form/builder/base/TypesFormBuilder";

export const fieldMapOfTextSourceFormId = "formId";
const fieldKeyValueLabel = "keyValueLabel";

export function fnVariableBuilderMapOfText(fieldValueMapOfText: string)
{
  function getVarComp(sourceFormId?: MetaIdForm): Record<MetaIdField, DefnField>
  {
    return {
      [fieldMapOfTextSourceFormId]: {
        type: "pickFormId",
        metaId: fieldMapOfTextSourceFormId,
        name: fieldMapOfTextSourceFormId,
        label: "Form"
      } as DefnStudioPickFormId,

      ...getFieldGap(fieldGap1, "thick"),

      [fieldKeyValueLabel]: {
        type: "label",
        metaId: fieldKeyValueLabel,
        name: fieldKeyValueLabel,
        label: "Key value",
        disabled: true
      } as DefnFieldLabel,

      [fieldValueMapOfText]: {
        type: "studioBuildPropertyMap",
        metaId: fieldValueMapOfText,
        name: fieldValueMapOfText,
        label: "Value",
        sourceFormId: sourceFormId
      } as DefnStudioBuildPropertyMap
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarMapOfText
  )
  {
    valueMap[fieldMapOfTextSourceFormId] = fnRawValueToFieldValue("pickFormId", variable.value?.sourceFormId);
    valueMap[fieldValueMapOfText] = fnRawValueToFieldValue("studioBuildPropertyMap", {
      keys: variable.value?.keys,
      map: variable.value?.map
    } as {
      keys: string[];
      map: Record<string, StudioBuildArgBinderHolder>
    });
  }

  function defnToDto(valueMap: FieldValues)
  {
    const formId = fnFieldValueToRawValue("pickFormId", valueMap[fieldMapOfTextSourceFormId]);
    const mapOfTextValue = fnFieldValueToRawValue("studioBuildPropertyMap",
      valueMap[fieldValueMapOfText]
    ) as {
      keys: string[];
      map: Record<string, StudioBuildArgBinderHolder>
    };

    return {
      value: {
        ...mapOfTextValue,
        sourceFormId: formId
      }
    } as StudioVarMapOfText;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
