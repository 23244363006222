// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ListItemTypeSsRowBubble} from "../../../base/types/list/TypeListSsRowBubble";
import {MsgVersion} from "../../core/base/msg/MsgVersion";
import {EnumMessageType} from "../../home/base/Types";
import {EnvValidationError} from "../../meta/base/dto/EnvValidationError";
import {ISig} from "../../meta/base/sig/ISig";
import {EnumMediaType} from "../../meta/base/Types";
import {EnvError} from "./dto/EnvError";
import {EnvSignal} from "./dto/EnvSignal";

export const ErrComputerNotConnected = "Computer not connected";

export function getSig<T extends ISig>(envSig: EnvSignal<T>): T
{
  return envSig.sig as T;
}

export function getParamNameSet(validationError: EnvValidationError): string[]
{
  if(validationError.paramName === undefined)
  {
    return validationError.paramNameSet === undefined ? [] : validationError.paramNameSet;
  }
  else
  {
    return [validationError.paramName];
  }
}

export function getValidationErrorMessage(validationError: EnvValidationError): string
{
  return validationError.errorParams === undefined
    ? validationError.errorMessage as string
    : validationError.errorParams.length
      ? sprintf(validationError.errorMessage as string, validationError.errorParams)
      : sprintf(validationError.errorMessage as string, getParamNameSet(validationError));
}

export function getValidationErrorMessages(error?: EnvError): string | undefined
{
  const errorMsg = [] as string[];

  if(error?.errorCode === "validationError")
  {
    _getValidationErrorMessages(errorMsg, error.validationErrors);
  }
  return errorMsg.filter(Boolean).join(" > ");
}

function _getValidationErrorMessages(errorMsg: string[], validationErrors?: EnvValidationError[])
{
  const validationError = validationErrors && validationErrors[0];
  if(validationError)
  {
    const msg = getValidationErrorMessage(validationError);
    if(errorMsg)
    {
      errorMsg.push(msg || validationError.paramName || "");
    }
    _getValidationErrorMessages(errorMsg, validationError.children);
  }
}

export function getErrorMessage(envError?: EnvError): string | undefined
{
  if(envError === undefined)
  {
    return undefined;
  }
  const errorCode = envError.errorCode;
  if(errorCode === "networkError")
  {
    if(envError.errorMessage)
    {
      return ErrComputerNotConnected;
    }
    else
    {
      return undefined;
    }
  }

  const errorMessage = envError.errorMessage;
  if(errorMessage)
  {
    return envError.errorParams === undefined
      ? errorMessage as string
      : sprintf(errorMessage as string, envError.errorParams);
  }

  if(errorCode === "validationError")
  {
    return getValidationErrorMessages(envError);
  }

  // this should not happen, just a fallback
  return envError.errorCode;
}

export function getErrorText(envSig: EnvSignal<any>): string | undefined
{
  const envError = envSig.error;
  if(envError === undefined)
  {
    return undefined;
  }

  const errorCode = envError.errorCode;
  if(errorCode === "networkError")
  {
    if(envError.errorMessage)
    {
      return envError.errorMessage;
    }
    else
    {
      return ErrComputerNotConnected;
    }
  }

  return getErrorMessage(envError);
}

export function getValidationErrorsCode(param: EnvSignal<any> | EnvError): string[] | undefined
{
  if(param === undefined)
  {
    return undefined;
  }

  const envError = param.hasOwnProperty("error")
    ? (param as EnvSignal<any>).error
    : param as EnvError;
  if(envError && envError.errorCode === "validationError")
  {
    const validationErrors = envError.validationErrors;
    if(validationErrors && validationErrors.length > 0)
    {
      return validationErrors.map(error => error.errorCode) as string[];
    }
  }

  return undefined;
}

export function isNotChangedSignal(param: EnvSignal<any> | EnvError): boolean
{
  const errorCodes = getValidationErrorsCode(param);
  return Boolean(errorCodes?.includes("notChanged"));
}

export function isNotAccessibleSignal(param: EnvSignal<any> | EnvError): boolean
{
  const errorCodes = getValidationErrorsCode(param);
  return Boolean(errorCodes?.includes("notAccessible"));
}

export function isNotFoundSignal(param: EnvSignal<any> | EnvError): boolean
{
  const errorCodes = getValidationErrorsCode(param);
  return Boolean(errorCodes?.includes("notFound"));
}

export function getMaxFileSize(mediaType: EnumMediaType): number
{
  switch(mediaType)
  {
    case "audio":
    case "voice":
      return 9437184;
    case "video":
      return 18874368;
    case "image":
      return 9437184;
    case "jar":
      return 18874368;
    case "avatar":
      return 9437184;
    case "icon":
    case "sticker":
      return 9437184;
    default:
      return 9437184;
  }
}

export function sprintf(template: string, values: string[]): string
{
  return template.replace(/%s/g, () => values.shift() as string);
}

export function createMsgVersion(version?: string): MsgVersion
{
  return version
    ? {version: version} as MsgVersion
    : {} as MsgVersion;
}

export type MessageTypeStar =
  | "audio"
  | "camera"
  | "document"
  | "group"
  | "image"
  | "linkText"
  | "location"
  | "report"
  | "spreadsheetPartition"
  | "spreadsheetRow"
  | "spreadsheetRowDeleted"
  | "text"
  | "user"
  | "video"
  | "voice"
  | ListItemTypeSsRowBubble;

export type OnlyPushTopicType =
  | "callerStarMessageList"
  | "messageRemoveForMe";

export function isDownloadableMessage(messageType: EnumMessageType)
{
  switch(messageType)
  {
    case "audio":
    case "camera":
    case "document":
    case "image":
    case "video":
    case "voice":
      return true;
  }
  return false;
}
