import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDate} from "../../../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldDateTime} from "../../../../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldTime} from "../../../../../api/meta/base/dto/DefnFieldTime";
import {DefnStudioBuildDateTime} from "../../../../../api/meta/base/dto/DefnStudioBuildDateTime";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldDateTimeRange} from "../../../../../api/meta/base/dto/StudioFieldDateTimeRange";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {getDateTimeValue} from "../../base/FieldBuilderPlus";
import {propKeyMaxCustomDateTimeRangeTime} from "../../base/TypesFormBuilder";
import {propKeyMinCustomDateTimeRangeTime} from "../../base/TypesFormBuilder";
import {propKeyMaxCustomDateTimeRange} from "../../base/TypesFormBuilder";
import {propKeyMinCustomDateTimeRange} from "../../base/TypesFormBuilder";
import {propKeyToDefaultDateTimeRangeCustom} from "../../base/TypesFormBuilder";
import {propKeyFromDefaultDateTimeRangeCustom} from "../../base/TypesFormBuilder";
import {getFieldGap} from "../../base/TypesFormBuilder";
import {propKeyToDefaultDateTimeRangeEnum} from "../../base/TypesFormBuilder";
import {propKeyFromDefaultDateTimeRangeEnum} from "../../base/TypesFormBuilder";
import {fieldGap} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyToDefault} from "../../base/TypesFormBuilder";
import {propKeyFromDefault} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyToDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyFromDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyToDefaultVarId} from "../../base/TypesFormBuilder";
import {propKeyFromDefaultVarId} from "../../base/TypesFormBuilder";
import {propKeyDisplayDateFormat} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

const defaultValueLabel = "defaultValueLabel";

export function getDefnFieldDateTimeRange(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  showCustomDateFrom?: boolean,
  showCustomDateTo?: boolean,
  showCustomMax?: boolean,
  showCustomMin?: boolean,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    defaultValueLabel,
    propKeyFromDefaultFieldId,
    propKeyToDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinFieldId,
    propKeyMaxFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    defaultValueLabel,
    propKeyFromDefaultVarId,
    propKeyToDefaultVarId,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinVar,
    propKeyMaxVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    defaultValueLabel,
    propKeyFromDefault,
    ...showCustomDateFrom ? [propKeyFromDefaultDateTimeRangeEnum] : [propKeyFromDefaultDateTimeRangeCustom],
    fieldGap,
    propKeyToDefault,
    ...showCustomDateTo ? [propKeyToDefaultDateTimeRangeEnum] : [propKeyToDefaultDateTimeRangeCustom],
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMin,
    ...showCustomMin ? [propKeyMinCustomDateTimeRange] : [propKeyMinCustomDateTimeRangeTime],
    propKeyMax,
    ...showCustomMax ? [propKeyMaxCustomDateTimeRange] : [propKeyMaxCustomDateTimeRangeTime],
    fieldGap4,
    propKeyDisplayDateFormat
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "dateTimeRange",
      formId,
      "dateTime",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [defaultValueLabel]: {
      type: "label",
      metaId: defaultValueLabel,
      name: defaultValueLabel,
      label: "Default value",
      disabled: true
    } as DefnFieldLabel,

    ...getFieldGap(fieldGap),

    [propKeyFromDefault]: {
      type: "studioBuildDateTime",
      metaId: propKeyFromDefault,
      name: propKeyFromDefault,
      label: "From date"
    } as DefnStudioBuildDateTime,

    ...showCustomDateFrom && {
      [propKeyFromDefaultDateTimeRangeEnum]: {
        type: "dateTime",
        metaId: propKeyFromDefaultDateTimeRangeEnum,
        name: propKeyFromDefaultDateTimeRangeEnum,
        label: "From custom date time"
      } as DefnFieldDateTime
    },

    ...!showCustomDateFrom && {
      [propKeyFromDefaultDateTimeRangeCustom]: {
        type: "time",
        metaId: propKeyFromDefaultDateTimeRangeCustom,
        name: propKeyFromDefaultDateTimeRangeCustom,
        label: "From time"
      } as DefnFieldTime
    },

    [propKeyFromDefaultVarId]: {
      type: "pickVarId",
      name: propKeyFromDefaultVarId,
      metaId: propKeyFromDefaultVarId,
      label: "From",
      varKind: "dateTime",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyFromDefaultFieldId]: {
      type: "pickFieldId",
      metaId: propKeyFromDefaultFieldId,
      name: propKeyFromDefaultFieldId,
      label: "From",
      formId: formId,
      filterFieldTypeSet: ["dateTime"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyToDefault]: {
      type: "studioBuildDateTime",
      metaId: propKeyToDefault,
      name: propKeyToDefault,
      label: "To date"
    } as DefnStudioBuildDateTime,

    ...showCustomDateTo && {
      [propKeyToDefaultDateTimeRangeEnum]: {
        type: "dateTime",
        metaId: propKeyToDefaultDateTimeRangeEnum,
        name: propKeyToDefaultDateTimeRangeEnum,
        label: "To custom date time"
      } as DefnFieldDateTime
    },

    ...!showCustomDateTo && {
      [propKeyToDefaultDateTimeRangeCustom]: {
        type: "time",
        metaId: propKeyToDefaultDateTimeRangeCustom,
        name: propKeyToDefaultDateTimeRangeCustom,
        label: "To time"
      } as DefnFieldTime
    },

    [propKeyToDefaultVarId]: {
      type: "pickVarId",
      name: propKeyToDefaultVarId,
      metaId: propKeyToDefaultVarId,
      label: "To",
      varKind: "dateTime",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyToDefaultFieldId]: {
      type: "pickFieldId",
      metaId: propKeyToDefaultFieldId,
      name: propKeyToDefaultFieldId,
      label: "To",
      formId: formId,
      filterFieldTypeSet: ["dateTime"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyDisplayDateFormat]: {
      type: "dateFormat",
      name: propKeyDisplayDateFormat,
      metaId: propKeyDisplayDateFormat,
      label: "Display date format"
    } as DefnFieldPickEnum,

    ...showCustomMax && {
      [propKeyMaxCustomDateTimeRange]: {
        type: "dateTime",
        metaId: propKeyMaxCustomDateTimeRange,
        name: propKeyMaxCustomDateTimeRange,
        label: "Custom max date time"
      } as DefnFieldDate
    },

    ...!showCustomMax && {
      [propKeyMaxCustomDateTimeRangeTime]: {
        type: "time",
        metaId: propKeyMaxCustomDateTimeRangeTime,
        name: propKeyMaxCustomDateTimeRangeTime,
        label: "Custom max time"
      } as DefnFieldTime
    },

    ...showCustomMin && {
      [propKeyMinCustomDateTimeRange]: {
        type: "dateTime",
        metaId: propKeyMinCustomDateTimeRange,
        name: propKeyMinCustomDateTimeRange,
        label: "Custom min date time"
      } as DefnFieldDate
    },

    ...!showCustomMin && {
      [propKeyMinCustomDateTimeRangeTime]: {
        type: "time",
        metaId: propKeyMinCustomDateTimeRangeTime,
        name: propKeyMinCustomDateTimeRangeTime,
        label: "Custom min time"
      } as DefnFieldTime
    }
  };

}

export function defnValueToStudioFieldDateTimeRange(values: FieldValues): StudioFieldDateTimeRange
{
  return {
    type: "dateTimeRange",
    fromDefault: getDateTimeValue(values,
      propKeyFromDefault,
      propKeyFromDefaultDateTimeRangeEnum,
      propKeyFromDefaultDateTimeRangeCustom
    ),
    toDefault: getDateTimeValue(values,
      propKeyToDefault,
      propKeyToDefaultDateTimeRangeEnum,
      propKeyToDefaultDateTimeRangeCustom
    ),
    min: getDateTimeValue(values,
      propKeyMin,
      propKeyMinCustomDateTimeRange,
      propKeyMinCustomDateTimeRangeTime
    ),
    max: getDateTimeValue(values,
      propKeyMax,
      propKeyMaxCustomDateTimeRange,
      propKeyMaxCustomDateTimeRangeTime
    ),

    fromDefaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyFromDefaultVarId]),
    fromDefaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyFromDefaultFieldId]),

    toDefaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyToDefaultVarId]),
    toDefaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyToDefaultFieldId]),
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    displayDateFormat: fnFieldValueToRawValue("dateFormat", values[propKeyDisplayDateFormat])
  } as StudioFieldDateTimeRange;
}

export function studioFieldToDefnValueDateTimeRange(studioField: StudioFieldDateTimeRange)
{
  const defaultValueFrom = studioField.fromDefault;
  const customValueFrom = fnRawValueToFieldValue("date", defaultValueFrom?.customValue);
  const timeFrom = fnRawValueToFieldValue("time", defaultValueFrom?.time);

  const defaultValueTo = studioField.toDefault;
  const customValueTo = fnRawValueToFieldValue("date", defaultValueTo?.customValue);
  const timeTo = fnRawValueToFieldValue("time", defaultValueTo?.time);

  const defaultValueMin = studioField.min;
  const customValueMin = fnRawValueToFieldValue("date", defaultValueMin?.customValue);
  const timeMin = fnRawValueToFieldValue("time", defaultValueMin?.time);

  const defaultValueMax = studioField.max;
  const customValueMax = fnRawValueToFieldValue("date", defaultValueMax?.customValue);
  const timeMax = fnRawValueToFieldValue("time", defaultValueMax?.time);

  return {
    [propKeyFromDefault]: customValueFrom ? "custom" : defaultValueFrom?.value,
    [propKeyFromDefaultDateTimeRangeEnum]: customValueFrom,
    [propKeyFromDefaultDateTimeRangeCustom]: timeFrom,

    [propKeyToDefault]: customValueTo ? "custom" : defaultValueTo?.value,
    [propKeyToDefaultDateTimeRangeEnum]: customValueTo,
    [propKeyToDefaultDateTimeRangeCustom]: timeTo,

    [propKeyMin]: customValueMin ? "custom" : defaultValueMin?.value,
    [propKeyMinCustomDateTimeRange]: customValueMin,
    [propKeyMinCustomDateTimeRangeTime]: timeMin,

    [propKeyMax]: customValueMax ? "custom" : defaultValueMax?.value,
    [propKeyMaxCustomDateTimeRange]: customValueMax,
    [propKeyMaxCustomDateTimeRangeTime]: timeMax,

    [propKeyFromDefaultVarId]: fnRawValueToFieldValue("pickVarId", studioField.fromDefaultVarId),
    [propKeyFromDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.fromDefaultFieldId),

    [propKeyToDefaultVarId]: fnRawValueToFieldValue("pickVarId", studioField.toDefaultVarId),
    [propKeyToDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.toDefaultFieldId),
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyDisplayDateFormat]: fnRawValueToFieldValue("dateFormat", studioField.displayDateFormat)
  };
}
