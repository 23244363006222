import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {GroupId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {removeGroupAvatar} from "../../../cache/app/group/SliceCacheGroup";
import {removeGroupTyping} from "../../../cache/app/group/SliceCacheGroup";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcPubSubGroup extends ISrvc
{

  getGroupAvatarTopic(entId: EntId, groupId: GroupId)
  {
    return {
      aboutId: groupId,
      artifactId: entId,
      type: "groupAvatar"
    } as DtoTopic;
  }

  getGroupTypingTopic(entId: EntId, groupId: GroupId)
  {
    return {
      aboutId: groupId,
      artifactId: entId,
      type: "groupTyping"
    } as DtoTopic;
  }

  groupTyping(subscriberId: TypeSubscriberId, entId: EntId, groupId: GroupId, unsubscribe?: boolean): void
  {
    const dtoTopic = this.getGroupTypingTopic(entId, groupId);

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  groupAvatar(subscriberId: TypeSubscriberId, entId: EntId, groupId: GroupId, unsubscribe?: boolean): void
  {
    const dtoTopic = this.getGroupAvatarTopic(entId, groupId);

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  groupInfo(subscriberId: TypeSubscriberId, entId: EntId, groupId: GroupId, unsubscribe?: boolean)
  {
    const dtoTopic = {
      aboutId: groupId,
      artifactId: entId,
      type: "groupInfo"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  handleSigTopic(sig: SigTopic, isOnSub?: boolean): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "groupAvatar":
        Srvc.cache.app.group.wsocGroupAvatarGet(sig.artifactId, sig.aboutId as GroupId);
        return true;
      case "groupInfo":
        Srvc.cache.app.group.wsocGroupInfoGet(sig.artifactId, sig.aboutId as GroupId);
        return true;
      case "groupActionList":
        return true;
    }

    return false;
  }

  handleTopicUnSub(topic: DtoTopic): boolean
  {
    const type = topic.type;
    switch(type)
    {
      case "groupAvatar":
        store.dispatch(removeGroupAvatar({
          entId: topic.artifactId as EntId,
          groupId: topic.aboutId as GroupId
        }));
        return true;
      case "groupInfo":
        Srvc.cache.app.group.clearGroupInfo(topic.artifactId as EntId, topic.artifactId as GroupId);
        return true;
      case "groupActionList":
        return true;
      case "groupTyping":
        store.dispatch(removeGroupTyping({
          entId: topic.artifactId,
          groupId: topic.aboutId as GroupId
        }));
    }

    return false;
  }
}
