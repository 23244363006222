import {Box} from "@mui/system";
import {useEffect} from "react";
import {useCallback, useMemo, useState} from "react";
import React from "react";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {px} from "../../../../base/plus/StringPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {listReset} from "../../../../base/slices/list/SliceList";
import {listRefresh, listSetSelectedItemId} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItem, IListItemsById, TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IFormRef} from "../../../../base/types/TypesForm";
import {RootState} from "../../../../Store";
import {store} from "../../../../Store";
import {useAppSelector} from "../../../app/AppHooks";
import {IDialogAtomFooterActionCheckbox, IDialogAtomRef} from "../../../atom/dialog/DialogAtom";
import DialogAtom from "../../../atom/dialog/DialogAtom";
import {Cancel, OK} from "../../../atom/dialog/DialogConfirm";
import {DividerHorizontal} from "../../../atom/layout/DividerHorizontal";
import {DividerVertical} from "../../../atom/layout/DividerVertical";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import RawSearch from "../../../atom/raw/RawSearch";
import {IFormProps} from "../../../form/viewer/Form";
import Form from "../../../form/viewer/Form";
import {CbOnClickListItem, List, ListClickVariant} from "../../../list/List";
import {dialogGetFieldValueMap} from "../DialogPlus";

export type TypeListMap = {
  keys: string[];
  map: Record<string, IListItemAPSA[]>;
};

export default function DialogList<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  formProps: IFormProps<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>;
  listMap: TypeListMap;
  title?: string;
  contentHeight?: number;
  onSearch?: (value: string) => void;
  onClickList2?: CbOnClickListItem;
  dialogRef?: IDialogAtomRef;
  onClickFooterAction?: (action: string) => void;
  doNotShowList1?: boolean;
  onClickList1?: CbOnClickListItem;
  onChangeAddMore?: (enable: boolean) => void;
  doNotCloseActions?: string[];
  leftFooterCheckBoxActions?: IDialogAtomFooterActionCheckbox[];
  disabledButtons?: string[];
})
{
  const dialogRef = useMemo(() => props.dialogRef || ({} as IDialogAtomRef), [props.dialogRef]);
  const formRef = useMemo(() => props.formProps.cbRef || ({} as IFormRef), [props.formProps.cbRef]);

  const formReadonly = props.formProps.formReadonly;

  const formProps = useMemo(() => ({
    ...props.formProps,
    cbRef: formRef
  }), [props.formProps, formRef]);

  const contentWidth = !props.doNotShowList1 ? 900 : undefined;

  const list1 = useAppSelector((state) => selectList1(state).listName);
  const itemIds1 = useAppSelector((state) => selectList1(state).itemIds);
  const selectedItemId1 = useAppSelector((state) => selectList1(state).selectedItemId);
  const displayItemIds1 = useAppSelector((state) => selectList1(state).displayItemIds);
  const list2 = useAppSelector((state) => selectList2(state).listName);

  const [currentKey, setCurrentKey] = useState<string>();
  const [submitEnable, setSubmitEnable] = useState(false);
  const [isAddMoreEnable, setIsAddMoreEnable] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const onClickList1Handler = useCallback(
    (_menuAnchor: Element, itemId: TypeListItemId, item: IListItem, variant: ListClickVariant) =>
    {
      if(variant === "listItem")
      {
        setCurrentKey(itemId);
      }
      props.onClickList1 && props.onClickList1(_menuAnchor, itemId, item, variant);
    }, [props.listMap.keys]);

  const close = useCallback(() =>
  {
    dialogRef.close();
  }, [dialogRef]);

  const onClickFooterActionHandler = useCallback((action: string) =>
  {
    if(action === OK)
    {
      formRef.remoteSubmit();

      const resetKeys = dialogGetFieldValueMap(props.formProps.defnForm, undefined);
      formRef.remoteReset(resetKeys);

      setTimeout(() =>
      {
        formRef.checkSubmit();
        !isAddMoreEnable && close();
      });
    }
    else if(action === Cancel)
    {
      close();
    }
    if(action !== OK && action !== Cancel)
    {
      props.onClickFooterAction && props.onClickFooterAction(action);
    }
  }, [close, formProps.defnForm, isAddMoreEnable, props.onClickFooterAction]);

  useEffect(() =>
  {
    props.onChangeAddMore && props.onChangeAddMore(isAddMoreEnable);
  }, [isAddMoreEnable, props.onChangeAddMore]);

  useEffect(() =>
  {
    doLoadList1(list1, props.listMap.keys);
  }, [list1, props.listMap.keys]);

  useEffect(() =>
  {
    if(currentKey)
    {
      doLoadList2(list2, props.listMap.map[currentKey]);
    }
  }, [currentKey, list2, props.listMap.map]);

  useEffect(() =>
  {
    if(itemIds1 && itemIds1.length && !isLoaded)
    {
      dispatchList(list1, listSetSelectedItemId(itemIds1[0]));
      setCurrentKey(itemIds1[0]);
      setIsLoaded(true);
    }
  }, [itemIds1, isLoaded, list1]);

  useEffect(() =>
  {
    return () =>
    {
      dispatchList(list1, listReset());
      dispatchList(list2, listReset());
    };
  }, [list1, list2]);

  useEffect(() =>
  {
    dialogRef.setContent(
      <RealDialogContent
        {...props}
        doNotShowList1={props.doNotShowList1}
        onClickList1={onClickList1Handler}
        onSubmitEnable={setSubmitEnable}
      />
    );
  }, [formRef, props.doNotShowList1, onClickList1Handler, dialogRef, props]);

  useEffect(() =>
  {
    const onClick = (e: KeyboardEvent) =>
    {
      if(e.key === "Enter" && submitEnable)
      {
        e.preventDefault();
        e.stopPropagation();
        onClickFooterActionHandler(OK);
      }
    };

    window.addEventListener("keydown", onClick);

    return () => window.removeEventListener("keydown", onClick);
  }, [onClickFooterActionHandler, submitEnable]);

  useEffect(() =>
  {
    if(displayItemIds1
      && displayItemIds1.length
      && selectedItemId1
      && !displayItemIds1.includes(selectedItemId1))
    {
      dispatchList(list1, listSetSelectedItemId(displayItemIds1[0]));
      setCurrentKey(displayItemIds1[0]);
    }
  }, [displayItemIds1, list1, selectedItemId1]);

  return (
    <DialogAtom
      cbRef={dialogRef}
      title={props.title}
      contentHeight={props.contentHeight ?? 600}
      contentWidth={contentWidth}
      rightFooterActions={[Cancel, OK]}
      onClickFooterAction={onClickFooterActionHandler}
      leftFooterCheckBoxActions={props.leftFooterCheckBoxActions}
      onClickFooterCheckBoxAction={(action, checked) =>
      {
        if(action.value === "addMore")
        {
          setIsAddMoreEnable(checked);
        }
      }}
      doNotCloseActions={
        props.doNotCloseActions && props.doNotCloseActions.length > 0 ? [OK, ...props.doNotCloseActions] : [OK]
      }
      disableRightFooterActions={
        (!submitEnable || formReadonly)
          ? [OK, ...(props.disabledButtons ?? [])]
          : [...(props.disabledButtons ?? [])]
      }
    />
  );
}

function RealDialogContent<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  formProps: IFormProps<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>;
  listMap: TypeListMap;
  title?: string;
  contentHeight?: number;
  onSearch?: (value: string) => void;
  onClickList2?: CbOnClickListItem;
  dialogRef?: IDialogAtomRef;
  onClickFooterAction?: (action: string) => void;
  doNotShowList1?: boolean;
  onClickList1?: CbOnClickListItem;
  onChangeAddMore?: (enable: boolean) => void;
  doNotCloseActions?: string[];
  leftFooterCheckBoxActions?: IDialogAtomFooterActionCheckbox[];
  onSubmitEnable?: (enable: boolean) => void;
})
{
  const listName2 = useAppSelector((state) => selectList2(state).listName);

  return (
    <LayoutFlexCol
      width="100%"
      height="100%"
      justifyContent="start"
    >
      <RawSearch
        placeHolder="Search"
        bgColor="white"
        onSearch={props.onSearch}
        onSearchBindListName={listName2}
      />

      <DividerHorizontal />

      <LayoutFlexRow
        height="325px"
        width="100%"
      >
        {!props.doNotShowList1 && (
          <Box
            width="25%"
            height="100%"
          >
            <List
              selectList={selectList1}
              onClickListItem={props.onClickList1}
            />
          </Box>
        )}
        {!props.doNotShowList1 && <DividerVertical />}
        <Box
          width={!props.doNotShowList1 ? "75%" : "100%"}
          height="100%"
        >
          <List
            selectList={selectList2}
            onClickListItem={props.onClickList2}
          />
        </Box>
      </LayoutFlexRow>

      <DividerHorizontal />

      <LayoutGap height={px(35)} />

      <Form
        store={props.formProps.store}
        defnForm={props.formProps.defnForm}
        cbRef={props.formProps.cbRef}
        initValues={props.formProps.initValues}
        onSubmit={props.formProps.onSubmit}
        onSubmitEnable={props.onSubmitEnable}
        onWatch={props.formProps.onWatch}
      />
    </LayoutFlexCol>
  );
}

function selectList2(state: RootState)
{
  return state.app.form.dialogList2;
}

function selectList1(state: RootState)
{
  return state.app.form.dialogList1;
}

function doLoadList2(listName: string, list: IListItemAPSA[])
{
  const {
    uiItemIds,
    uiItemsById
  } = getList2ItemById(list);

  const rootState = store.getState();
  const searchWords = selectList2(rootState).searchWords;
  dispatchList(listName,
    listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds,
      searchWords
    })
  );
}

function doLoadList1(listName: string, list: string[])
{
  const {
    uiItemIds,
    uiItemsById
  } = getList1ItemById(list);

  dispatchList(listName,
    listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    })
  );
}

function getList1ItemById(list: string[])
{
  const uiItemIds: TypeListItemId[] = [];
  const uiItemsById: IListItemsById = {};

  list.forEach((listItem) =>
  {
    uiItemIds.push(listItem);

    uiItemsById[listItem] = {
      type: "p",
      primary: {
        text: toLabel(listItem)
      },
      hideMenu: true
    } as IListItemAPSA;
  });

  return {
    uiItemIds,
    uiItemsById
  };
}

function getList2ItemById(list: IListItemAPSA[])
{
  const uiItemIds: TypeListItemId[] = [];
  const uiItemsById: IListItemsById = {};

  list?.forEach((listItem) =>
  {
    listItem.primary?.text && uiItemIds.push(listItem.primary?.text);
    if(listItem.primary?.text)
    {
      uiItemsById[listItem.primary.text] = {
        type: "aps",
        primary: {
          text: toLabel(listItem.primary.text)
        },
        avatarLeft: {
          icon: "dashboard"
        },
        secondary: listItem.secondary,
        hideMenu: true
      } as IListItemAPSA;
    }
  });

  return {
    uiItemIds,
    uiItemsById
  };
}
