import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioFieldAudio} from "../../../../../api/meta/base/dto/StudioFieldAudio";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeyMaxSize} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeVar} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyAudioFormat} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldAudio(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap2,
    propKeyMaxSizeFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap2,
    propKeyMaxSizeVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap2,
    propKeyMaxSize,
    propKeyAudioFormat
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "audio",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyAudioFormat]: {
      type: "enumAudioFormat",
      metaId: propKeyAudioFormat,
      name: "audio format",
      label: "Audio format",
      formId: formId
    } as DefnFieldPickEnum
  };
}

export function defnValueToStudioFieldAudio(values: FieldValues): StudioFieldAudio
{
  return {
    type: "audio",
    maxSize: fnFieldValueToRawValue("number", values[propKeyMaxSize]),
    maxSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxSizeVar]),
    maxSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxSizeFieldId]),
    audioFormatType: fnFieldValueToRawValue("enumAudioFormat", values[propKeyAudioFormat])
  } as StudioFieldAudio;
}

export function studioFieldToDefnValueAudio(studioField: StudioFieldAudio)
{
  return {
    [propKeyMaxSize]: fnRawValueToFieldValue("number", studioField.maxSize),
    [propKeyMaxSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.maxSizeVarId),
    [propKeyMaxSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxSizeFieldId),
    [propKeyAudioFormat]: fnRawValueToFieldValue("enumAudioFormat", studioField.audioFormatType)
  };
}
