import {Box, useTheme} from "@mui/material";
import {cloneDeep} from "lodash";
import React, {useCallback, useEffect, useMemo} from "react";
import {isFieldId} from "../../../api/meta/base/ApiPlus";
import {isSectionId} from "../../../api/meta/base/ApiPlus";
import {StudioComposite} from "../../../api/meta/base/dto/StudioComposite";
import {StudioDtoPermissionMatrix} from "../../../api/meta/base/dto/StudioDtoPermissionMatrix";
import {StudioField} from "../../../api/meta/base/dto/StudioField";
import {StudioFieldButton} from "../../../api/meta/base/dto/StudioFieldButton";
import {StudioFieldDocument} from "../../../api/meta/base/dto/StudioFieldDocument";
import {StudioFieldEditable} from "../../../api/meta/base/dto/StudioFieldEditable";
import {StudioFieldImage} from "../../../api/meta/base/dto/StudioFieldImage";
import {StudioFieldPickGridRow} from "../../../api/meta/base/dto/StudioFieldPickGridRow";
import {StudioFieldPickReportRow} from "../../../api/meta/base/dto/StudioFieldPickReportRow";
import {StudioFieldRating} from "../../../api/meta/base/dto/StudioFieldRating";
import {StudioFieldRef} from "../../../api/meta/base/dto/StudioFieldRef";
import {StudioFieldRefReport} from "../../../api/meta/base/dto/StudioFieldRefReport";
import {StudioFieldRefUser} from "../../../api/meta/base/dto/StudioFieldRefUser";
import {StudioFieldScanCode} from "../../../api/meta/base/dto/StudioFieldScanCode";
import {StudioFieldShowCode} from "../../../api/meta/base/dto/StudioFieldShowCode";
import {StudioGrid} from "../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../api/meta/base/dto/StudioSection";
import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {EnumDefnPermission} from "../../../api/meta/base/Types";
import {MetaId, MetaIdComposite, MetaIdField, MetaIdSection} from "../../../api/meta/base/Types";
import {dispatchList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {getStudioFormParentMap} from "../../../base/plus/StudioFormPlus";
import {calcDragDropLocation} from "../../../base/plus/StudioPlus";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItem, TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {
  IAsidePropsStudioForm,
  IAsidePropsStudioFormComposite,
  IAsidePropsStudioFormField
} from "../../../base/types/TypesAside";
import {IDrawerOverlayPropsFindUsages} from "../../../base/types/TypesDrawer";
import {IMainPropsStudio} from "../../../base/types/TypesMain";
import {IMainPropsStudioFormBuilder} from "../../../base/types/TypesMain";
import {TypeListSortType} from "../../../base/types/TypesStudio";
import {EnumListItemDirection, IStudioJumpPath} from "../../../base/types/TypesStudio";
import {ArrayPaymentFields} from "../../../srvc/app/payment/TypesPayment";
import {useAppSelector} from "../../app/AppHooks";
import helperTextJsonData from "../../atom/assets/PlaceholderTextStudio.json";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import {IMenuItem, IMenuProps} from "../../atom/raw/RawMenu";
import RawNothingHere from "../../atom/raw/RawNothingHere";
import RawSearch from "../../atom/raw/RawSearch";
import {useAppCtx} from "../../ctx/CtxApp";
import {usePageCtx} from "../../ctx/CtxPage";
import DialogNewField from "../../dialog/DialogNewField";
import DialogStudioEntElementPicker from "../../dialog/DialogStudioEntElementPicker";
import {CbOnDragListItem} from "../../list/List";
import {List, ListClickVariant} from "../../list/List";
import {useValidationErrorList} from "../../list/validation/ListValidation";
import {sortFormItemList} from "./base/FormBuilderPlus";
import {createMenuPropsStudioForm, useJumpConfigFormBuilderMain} from "./base/FormBuilderPlus";
import useCopyPasteStudioFormItem from "./base/StudioFormCopyPaste";
import {TypeActionSortFormList} from "./base/TypesFormBuilder";
import {FormBuilderItemVariant, IFormBuilderItemUserField, IFormBuilderProps} from "./base/TypesFormBuilder";

export default function FormBuilderMain(props: IFormBuilderProps)
{
  const appCtx = useAppCtx();
  const pageCtx = usePageCtx();
  const theme = useTheme();

  const gapStd = theme.common.gapStd;
  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const asideProps = appCtx.getAsideProps() as IAsidePropsStudioForm;
  const asidePropsType = asideProps ? asideProps.type : undefined;
  const entId = mainProps.itemId;
  const formId = mainProps.formId;
  const jumpPath = mainProps.jumpPath;

  const readOnly = props.readOnly;
  const formStore = props.formStore;
  const selectList = props.selectList;
  const selectListMap = props.selectListMap;
  const setupMode = props.setupMode;
  const onMoveItem = props.cbOnMoveItem;
  const cbOnRemove = props.cbOnRemove;
  const cbOnSubmit = props.cbOnSubmit;
  const cbOnShowVar = props.cbOnShowVar;
  const cbOnEditVar = props.cbOnEditVar;
  const cbOnDragItem = props.cbOnDragItem;
  const cbGetCliCode = props.cbGetCliCode;

  const formMap = formStore.formMap;
  const form = formMap?.map[formId];
  const parentMap = useMemo(() => getStudioFormParentMap(form), [form]);
  const listName = useAppSelector(state => selectList(state).listName);
  const loaded = useAppSelector(state => selectList(state).userField?.loaded);
  const selectedItemId = useAppSelector(state => selectList(state).selectedItemId);
  const onClickItemInfo = useValidationErrorList(selectList, "formMap", formId, formStore.validationResult);
  const itemsById = useAppSelector(state => selectList(state).itemsById);

  const {
    copyStudioField,
    copyStudioComposite,
    pasteStudioField,
    pasteStudioColumn
  } = useCopyPasteStudioFormItem();

  const getParentId = useCallback((childId: MetaId) =>
  {
    const parent = parentMap ? parentMap[childId] : undefined;
    if(parent?.type === "section")
    {
      return (parent as StudioSection)?.metaId;
    }
    else
    {
      return (parent as StudioGrid)?.metaId;
    }
  }, [parentMap]);

  const cbOnClickFindUsage = useCallback((itemId: MetaIdField, item: IListItemAPSA) =>
  {
    appCtx.setDrawerOverlayProps({
      type: "Find usages",
      findUsageName: item?.primary?.text || "",
      findUsageMetaId: itemId,
      artifactId: mainProps.itemId
    } as IDrawerOverlayPropsFindUsages);
  }, [appCtx, mainProps.itemId]);

  const closeAside = useCallback(() =>
  {
    appCtx.setAsideProps(undefined);
  }, [appCtx]);

  //region callback handlers

  const cbOnSubmitComposite = useCallback((composite: StudioComposite, isDuplicate?: boolean) =>
  {
    cbOnSubmit && cbOnSubmit("composite", {
      formId,
      composite: composite,
      isDuplicate: Boolean(isDuplicate)
    });
  }, [cbOnSubmit, formId]);

  const cbOnSubmitField = useCallback((
    parentCompositeId: MetaId,
    field: StudioField,
    variant: FormBuilderItemVariant,
    isDuplicate?: boolean) =>
  {
    cbOnSubmit && cbOnSubmit(variant, {
      formId,
      field: {
        field: field,
        compositeId: parentCompositeId
      },
      isDuplicate: Boolean(isDuplicate)
    });

  }, [cbOnSubmit, formId]);

  const cbOnSubmitStudioVar = useCallback((studioVar: StudioVar) =>
  {
    cbOnSubmit && cbOnSubmit("studioVar", {
      formId,
      studioVar: studioVar,
      isDuplicate: false
    });

  }, [cbOnSubmit, formId]);

  const cbOnRemoveComposite = useCallback((compositeId: MetaIdComposite) =>
  {
    cbOnRemove && cbOnRemove(
      "composite",
      {
        compositeId: compositeId
      }
    );

  }, [cbOnRemove]);

  const cbOnRemoveField = useCallback((
    parentCompositeId: MetaIdSection,
    fieldId: MetaIdField,
    variant: FormBuilderItemVariant,
    preventTrashRemove?: boolean
  ) =>
  {
    cbOnRemove && cbOnRemove(
      variant,
      {
        compositeId: parentCompositeId,
        fieldId: fieldId
      },
      preventTrashRemove
    );

  }, [cbOnRemove]);

  const cbOnRemoveItem = useCallback((itemId: TypeListItemId, variant: FormBuilderItemVariant) =>
  {
    if(variant === "composite")
    {
      cbOnRemoveComposite(itemId);
    }
    else if(variant === "field")
    {
      cbOnRemoveField(getParentId(itemId), itemId, variant);
    }
  }, [getParentId, cbOnRemoveField, cbOnRemoveComposite]);

  const cbOnCopyItem = useCallback((item: IListItem) =>
  {
    const userField = item.userField as unknown as IFormBuilderItemUserField;
    const variant = userField.variant as FormBuilderItemVariant;

    if(variant === "composite")
    {
      copyStudioComposite(userField.item as StudioComposite);
    }
    else if(variant === "field")
    {
      copyStudioField(userField.item as StudioField);
    }
  }, [copyStudioComposite, copyStudioField]);

  const cbOnPasteItem = useCallback((itemId: MetaIdField, item: IListItem) =>
  {
    const userField = item.userField as unknown as IFormBuilderItemUserField;
    const variant = userField.variant as FormBuilderItemVariant;

    if(variant === "composite")
    {
      const item = userField.item as StudioComposite;

      if(item && item.type === "section")
      {
        pasteStudioField((studioField) =>
        {
          cbOnSubmitField(itemId, studioField, "field", false);
        });
      }
      else
      {
        pasteStudioColumn((studioField) =>
        {
          if(studioField)
          {
            cbOnSubmitField(itemId, studioField, "field", false);
          }
        });
      }
    }

  }, [cbOnSubmitField, pasteStudioField, pasteStudioColumn]);

  const cbOnMoveItem = useCallback((itemId: MetaIdField, item: IListItem, direction: EnumListItemDirection) =>
  {
    const userField = item.userField as unknown as IFormBuilderItemUserField;
    const variant = userField.variant as FormBuilderItemVariant;
    if(variant === "composite")
    {
      onMoveItem && onMoveItem(variant,
        {
          direction,
          compositeId: itemId
        }
      );
    }
    else if(variant === "field")
    {
      onMoveItem && onMoveItem(variant,
        {
          direction,
          compositeId: getParentId(itemId),
          fieldId: itemId
        }
      );
    }
  }, [getParentId, onMoveItem]);

  const cbOnMoveFieldItemTo = useCallback((
    itemId: MetaIdField,
    item: IListItem,
    variant: FormBuilderItemVariant
  ) =>
  {
    const parentId = getParentId(itemId);

    const doLoad = () =>
    {
      const uiItemIds = [] as TypeListItemId[];
      const uiItemsById = {} as IListItemsById;

      const compMap = form?.compositeMap;

      if(compMap)
      {
        compMap.keys.forEach(compId =>
        {
          const comp = compMap.map[compId];

          if(comp.type === "section" && compId !== parentId)
          {
            uiItemIds.push(compId);
            uiItemsById[compId] = {
              type: "p",
              primary: {
                text: comp.details.name
              },
              hideMenu: true
            } as IListItemAPSA;
          }
        });
      }

      return {
        itemIds: uiItemIds,
        itemsById: uiItemsById
      };
    };

    pageCtx.showDialog(
      <DialogStudioEntElementPicker
        title={"Sections"}
        searchPlaceHolder={"Search sections"}
        doLoad={doLoad}
        onClickOk={(compositeId) =>
        {
          cbOnRemoveField(parentId, itemId, variant, true);
          cbOnSubmitField(compositeId, item.userField?.item as StudioField, variant);
        }}
      />
    );

  }, [getParentId, pageCtx, form?.compositeMap, cbOnRemoveField, cbOnSubmitField]);

  const cbOnDuplicateItem = useCallback((itemId: MetaIdField, item: IListItem) =>
  {
    const userField = item.userField as unknown as IFormBuilderItemUserField;
    const variant = userField.variant as FormBuilderItemVariant;
    if(variant === "composite")
    {
      const composite = userField.item as StudioComposite;
      cbOnSubmitComposite(cloneDeep(composite), true);
    }
    else if(variant === "field")
    {
      const field = userField.item as StudioField;
      cbOnSubmitField(getParentId(itemId), cloneDeep(field), variant, true);
    }
  }, [cbOnSubmitComposite, getParentId, cbOnSubmitField]);

  //endregion

  //region callback

  const onClickListItemComposite = useCallback((itemId: TypeListItemId, jumpPath?: IStudioJumpPath[]) =>
  {
    appCtx.setAsideProps({
      type: isSectionId(itemId) ? "Section" : "Grid",
      setupMode: setupMode,
      formStore: formStore,
      formId: formId,
      compositeId: itemId,
      metaId: itemId,
      cbOnCompositeSubmit: cbOnSubmitComposite,
      cbOnCompositeRemove: cbOnRemoveComposite,
      cbOnAddNewVar: cbOnSubmitStudioVar,
      cbOnShowVar: cbOnShowVar,
      cbOnEditVar: cbOnEditVar,
      selectListMap: selectListMap,
      jumpPath: jumpPath,
      readOnly: readOnly,
      reset: true
    } as IAsidePropsStudioFormComposite);
  }, [
    appCtx,
    setupMode,
    formStore,
    formId,
    cbOnSubmitComposite,
    cbOnRemoveComposite,
    cbOnSubmitStudioVar,
    selectListMap,
    readOnly,
    cbOnShowVar,
    cbOnEditVar
  ]);

  const onClickListItemField = useCallback((item: IListItem, jumpPath?: IStudioJumpPath[]) =>
  {
    const userField = item.userField as unknown as IFormBuilderItemUserField;
    const field = userField.item as StudioFieldEditable;
    const variant = userField.variant;
    const parentCompositeId = getParentId(field.metaId);

    appCtx.setAsideProps({
      metaId: field.metaId,
      compositeId: parentCompositeId,
      formId: formId,
      formStore: formStore,
      type: "Field",
      setupMode: setupMode,
      readOnly: readOnly,
      cbOnFieldSubmit: (studioField, parentCompositeId) => cbOnSubmitField(parentCompositeId,
        studioField,
        variant
      ),
      cbOnFieldRemove: (metaIdField, parentCompositeId) => cbOnRemoveField(parentCompositeId,
        metaIdField,
        variant
      ),
      cbOnAddNewVar: cbOnSubmitStudioVar,
      cbOnShowVar: cbOnShowVar,
      cbOnEditVar: cbOnEditVar,
      selectListMap: selectListMap,
      jumpPath: jumpPath,
      reset: true
    } as IAsidePropsStudioFormField);
  }, [
    appCtx,
    setupMode,
    formStore,
    formId,
    selectListMap,
    readOnly,
    getParentId,
    cbOnSubmitField,
    cbOnRemoveField,
    cbOnSubmitStudioVar,
    cbOnShowVar,
    cbOnEditVar
  ]);

  const onClickListItem = useCallback((itemId: TypeListItemId, item: IListItem, jumpPath?: IStudioJumpPath[]) =>
  {
    const userField = item.userField as unknown as IFormBuilderItemUserField;
    if(userField)
    {
      const variant = userField.variant;

      dispatchList(listName, listSetSelectedItemId(itemId));

      switch(variant)
      {
        case "composite":
          onClickListItemComposite(itemId, jumpPath);
          break;
        case "field":
          onClickListItemField(item, jumpPath);
          break;
      }
    }
  }, [listName, onClickListItemComposite, onClickListItemField]);

  const cbOnClickAddField = useCallback((parentCompositeId: MetaIdComposite) =>
  {
    pageCtx.showDialog(
      <DialogNewField
        formStore={formStore}
        formId={formId}
        isPluginForm={props.isPluginForm}
        onClickOk={(values) =>
        {
          const fieldType = values.fieldType;

          let defaultPermission: EnumDefnPermission | undefined;

          switch(fieldType)
          {
            case "ref":
            case "refReport":
              defaultPermission = "hide";
              break;
          }

          const field = {
            type: fieldType,
            details: {
              name: values.name
            },
            permissionMatrix: {
              defaultPermission: defaultPermission,
              map: {},
              keys: []
            } as StudioDtoPermissionMatrix
          } as StudioField;

          if(fieldType === "scanCode")
          {
            (field as StudioFieldScanCode).qrCode = true;
          }

          if(fieldType === "ref" && values.refFieldSpreadsheet)
          {
            (field as StudioFieldRef).spreadsheetId = values.refFieldSpreadsheet;
            (field as StudioFieldRef).copyFieldMap = {};
          }

          if((fieldType === "pickReportRow" || fieldType === "refReport")
            && values.refFieldReport)
          {
            (field as StudioFieldPickReportRow | StudioFieldRefReport).reportId = values.refFieldReport;
            (field as StudioFieldPickReportRow | StudioFieldRefReport).copyFieldMap = {};
          }

          if(fieldType === "pickGridRow")
          {
            (field as StudioFieldPickGridRow).copyFieldMap = {};
          }

          if(fieldType === "refUser")
          {
            (field as StudioFieldRefUser).copyFieldVarMap = {};
          }

          if(fieldType === "image" || fieldType === "camera" || fieldType === "showCode")
          {
            (field as StudioFieldImage | StudioFieldShowCode).showLabel = true;
          }

          if(fieldType === "rating")
          {
            (field as StudioFieldRating).ratingKind = "star_5";
          }

          if(fieldType === "button")
          {
            (field as StudioFieldButton).buttonKind = "normal";
          }

          if(fieldType === "document")
          {
            (field as StudioFieldDocument).fileTypeSet = ["any"];
          }

          cbOnSubmitField(parentCompositeId, field, "field");

          if(itemsById[values.metaIdField])
          {
            onClickListItemField(itemsById[values.metaIdField]);
          }
        }}
      />);
  }, [cbOnSubmitField, formId, formStore, itemsById, onClickListItemField, pageCtx, props.isPluginForm]);

  const onClickListItemMenu = useCallback((menuAnchor: Element, itemId: TypeListItemId, item: IListItem) =>
    {
      const userField = item.userField as unknown as IFormBuilderItemUserField;
      const variant = userField.variant as FormBuilderItemVariant;
      const userFieldItem = userField.item as StudioFieldEditable;

      const isRefChildField = Boolean(userFieldItem?.refFieldId);
      const isPaymentSys = form?.payment?.enablePayment && ArrayPaymentFields.includes(itemId);

      const menuProps = createMenuPropsStudioForm(item) as IMenuProps;

      Object.entries(menuProps).forEach(([key, value]) =>
      {
        const menuItem = value as IMenuItem;

        switch(key)
        {
          case "Edit":
            menuItem["onClick"] = () => onClickListItem(itemId, item);
            break;
          case "Copy":
            menuItem["onClick"] = () => cbOnCopyItem(item);
            break;
          case "Paste":
            menuItem["onClick"] = () => cbOnPasteItem(itemId, item);
            if(isFieldId(itemId))
            {
              menuItem["disabled"] = true;
            }
            break;
          case "Get CLI code":
            menuItem["onClick"] = () => cbGetCliCode && cbGetCliCode(entId, itemId);
            break;
          case "Copy fields":
          case "Duplicate":
            menuItem["onClick"] = () => cbOnDuplicateItem(itemId, item);
            break;
          case "Paste fields":
            break;
          case "Remove":
            if(isRefChildField || isPaymentSys)
            {
              menuItem["disabled"] = true;
            }
            menuItem["onClick"] = () => cbOnRemoveItem(itemId, variant);
            break;
          case "Move up":
            menuItem["onClick"] = () => cbOnMoveItem(itemId, item, "up");
            break;
          case "Move down":
            menuItem["onClick"] = () => cbOnMoveItem(itemId, item, "down");
            break;
          case "Move top":
            menuItem["onClick"] = () => cbOnMoveItem(itemId, item, "top");
            break;
          case "Move bottom":
            menuItem["onClick"] = () => cbOnMoveItem(itemId, item, "bottom");
            break;
          case "Move to":
            if(isSectionId(getParentId(itemId)))
            {
              if(isRefChildField)
              {
                menuItem["disabled"] = true;
              }
              menuItem["onClick"] = () => cbOnMoveFieldItemTo(itemId, item, variant);
            }
            else
            {
              delete menuProps[key];
            }
            break;
          case "Add field":
          case "Add column":
            menuItem["onClick"] = () => cbOnClickAddField(itemId);
            break;
          case "Find usages":
            menuItem["onClick"] = () => cbOnClickFindUsage(itemId, item);
            break;
        }
      });

      pageCtx.showMenu(menuAnchor, menuProps);
    },
    [
      form?.payment?.enablePayment,
      getParentId,
      onClickListItem,
      cbOnCopyItem,
      cbOnPasteItem,
      cbGetCliCode,
      entId,
      cbOnDuplicateItem,
      cbOnRemoveItem,
      cbOnMoveItem,
      cbOnMoveFieldItemTo,
      cbOnClickAddField,
      cbOnClickFindUsage
    ]);

  const cbOnClickListItem = useCallback((
    menuAnchor: Element,
    itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant,
    _pickValue?: boolean,
    selected?: boolean
  ) =>
  {
    switch(variant)
    {
      case "listItem":
        selected && onClickListItem(itemId, item);
        break;
      case "spotMenu":
        !readOnly && onClickListItemMenu(menuAnchor, itemId, item);
        break;
      case "spotInfo":
        onClickItemInfo(menuAnchor, itemId);
        break;
      case "apsaPrimaryCaptionButtonAdd":
        cbOnClickAddField(itemId);
        break;
    }
  }, [cbOnClickAddField, onClickItemInfo, onClickListItem, onClickListItemMenu, readOnly]);

  const cbOnClickSort = useCallback((type: TypeListSortType) =>
  {
    const actionSort = {
      sortType: type,
      setKey: "compositeList"
    } as TypeActionSortFormList;
    const studioForm = sortFormItemList(actionSort, form);

    if(studioForm)
    {
      cbOnSubmit && cbOnSubmit("form", {
        formId,
        form: studioForm
      });
    }
  }, [form, formId]);

  const cbOnDragFieldItem: CbOnDragListItem = useCallback((itemId, item, dragStartIndex, dragEndIndex) =>
  {
    const userField = item.userField as unknown as IFormBuilderItemUserField;
    const variant = userField.variant as FormBuilderItemVariant;
    if(variant === "field")
    {
      const fieldDragDropLocation = calcDragDropLocation(selectList, dragStartIndex, dragEndIndex);
      if(fieldDragDropLocation)
      {
        cbOnDragItem && cbOnDragItem(
          "field",
          itemId,
          dragStartIndex,
          dragEndIndex,
          fieldDragDropLocation.fromId,
          fieldDragDropLocation.fromIndex,
          fieldDragDropLocation.toId,
          fieldDragDropLocation.toIndex
        );
      }
    }
  }, [cbOnDragItem, selectList]);

  //endregion

  useEffect(() =>
  {
    if(loaded
      && !selectedItemId
      && !jumpPath
      && asidePropsType
    )
    {
      if(asidePropsType === "Field"
        || asidePropsType === "Section"
        || asidePropsType === "Grid"
        || asidePropsType === "FormComposite")
      {
        closeAside();
      }
    }

    if(asidePropsType
      && asidePropsType !== "Forms"
      && asidePropsType !== "Field"
      && asidePropsType !== "Section"
      && asidePropsType !== "Grid"
      && asidePropsType !== "FormComposite"
      && asidePropsType !== "PaymentConfig")
    {
      closeAside();
    }

  }, [loaded, selectedItemId, jumpPath, asidePropsType, closeAside]);

  useEffect(() =>
  {
    if(asideProps === undefined && selectedItemId && !jumpPath)
    {
      dispatchList(listName, listSetSelectedItemId(undefined));
    }
    else if(asidePropsType
      && selectedItemId
      && asidePropsType !== "Field"
      && asidePropsType !== "Section"
      && asidePropsType !== "Grid"
      && asidePropsType !== "FormComposite"
      && !jumpPath)
    {
      dispatchList(listName, listSetSelectedItemId(undefined));
    }
  }, [asideProps, asidePropsType]);

  useJumpConfigFormBuilderMain(selectList, onClickListItem);

  useEffect(() =>
  {
    if(!form)
    {
      appCtx.setMainProps({
        ...mainProps,
        jumpStepItem: undefined,
        formId: undefined
      } as IMainPropsStudio);
    }
  }, [appCtx, form, mainProps]);

  return (
    <>
      <RawSearch
        placeHolder={"Search sections or grids"}
        onSearchBindListName={listName}
        showSorting={!readOnly}
        onClickSorting={cbOnClickSort}
      />
      <LayoutFlexCol
        flexGrow={1}
        width={"100%"}
        pl={px(gapStd)}
        pr={px(gapStd)}
      >
        <Box
          width={"100%"}
          height={"100%"}
          bgcolor={theme.common.bgcolorContent}
          border={`1px solid ${theme.common.borderColor}`}
        >
          <List
            selectList={selectList}
            onClickListItem={cbOnClickListItem}
            onDragListItem={cbOnDragFieldItem}
            emptyComp={
              <RawNothingHere
                helperTextData={helperTextJsonData.formBuilderMain}
              />
            }
          />
        </Box>

      </LayoutFlexCol>
    </>
  );
}


