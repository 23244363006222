import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {StudioValueVarIdText} from "../../../../api/meta/base/dto/StudioValueVarIdText";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import {IFieldValueVarArgResolve} from "../raw/FieldRawStudioVarIdTextEditor";
import {FieldRawStudioVarIdTextEditor} from "../raw/FieldRawStudioVarIdTextEditor";

export default function FieldStudioVarIdTextEditor(props: {
  defn: DefnStudioVarIdTextEditor
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(props.defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
      {
        const fieldValue = fieldProps.field.value as StudioValueVarIdText | null;
        const onChange = (value?: IFieldValueVarArgResolve | null) =>
        {
          if(value)
          {
            fieldProps.field.onChange({
              argVarId: value.argVarId,
              argBinderMap: value.argBinderMap
            } as StudioValueVarIdText);
          }
          else
          {
            fieldProps.field.onChange(null);
          }
        };

        return (
          <FieldRawStudioVarIdTextEditor
            defn={defn}
            argBinderFormId={defn.argBinderFormId}
            inputFormId={defn.inputForm}
            excludeFieldIdSet={defn.excludeFieldIdSet}
            filterKindSet={defn.filterKindSet}
            filterVarKindSet={defn.filterVarKindSet}
            argBinderFilterVarKindSet={defn.argBinderFilterVarKindSet}
            showAsEdit={defn.showAsEdit}
            fieldProps={fieldProps}
            fieldKey={fieldId}
            fieldValue={fieldValue
              ? {
                argVarId: fieldValue.argVarId,
                argBinderMap: fieldValue.argBinderMap
              }
              : null}
            onChange={onChange}
          />
        );
      }}
    />
  );
}

