import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldNumber} from "../../../../api/meta/base/dto/DefnFieldNumber";
import {FieldValueNumber} from "../../../../api/meta/base/dto/FieldValueNumber";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawNumber from "../raw/FieldRawNumber";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldNumber(props: {
  defn: DefnFieldNumber,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const {
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const label = getCompLabel(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const isError = Boolean(error);
        const helperText = getFieldHelperText();

        const fieldValue = field.value as FieldValueNumber | undefined;
        const onChange = (value?: number) => field.onChange(fnRawValueToFieldValue("number", value));

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawNumber
                defn={defn}
                fieldId={fieldId}
                onChange={onChange}
                error={error}
                onBlur={field.onBlur}
                value={fieldValue?.value}
                minVar={defn.minVar}
                maxVar={defn.maxVar}
                label={label}
                allowDecimal={false}
              />
              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
