import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {ICacheStudioStateLocalState} from "./TypesCacheStudioLocalState";

export const sliceCacheStudioLocalState = createSlice({
  name: "cacheStudioLocalState",
  initialState: {
    enableScriptEditor: false
  } as ICacheStudioStateLocalState,
  reducers: {
    setScriptEditorState: (state: ICacheStudioStateLocalState, action: PayloadAction<boolean>) =>
    {
      state.enableScriptEditor = action.payload;
    }
  }
});

export const {
  setScriptEditorState
} = sliceCacheStudioLocalState.actions;
