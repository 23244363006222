import {nextTabId} from "../../api/meta/base/ApiPlus";
import {TabId} from "../../api/meta/base/Types";
import LocalSessionStorage from "./LocalSessionStorage";

const KEY_TAB_ID = "tabId";

export default class LocalTabId
{
  private static TAB_ID?: TabId;

  private static getStorage(): Storage
  {
    return LocalSessionStorage;
  }

  static get(): TabId
  {
    if(!this.TAB_ID)
    {
      let tabId = this.getStorage().getItem(KEY_TAB_ID);
      if(tabId === null)
      {
        tabId = nextTabId();
        this.getStorage().setItem(KEY_TAB_ID, tabId);
      }

      this.TAB_ID = tabId as TabId;
    }

    return this.TAB_ID;
  }

  static update(): void
  {
    const tabId = nextTabId();
    this.getStorage().setItem(KEY_TAB_ID, tabId);
    this.TAB_ID = tabId as TabId;
  }

  static remove(): void
  {
    LocalTabId.TAB_ID = undefined;
    this.getStorage().removeItem(KEY_TAB_ID);
  }
}
