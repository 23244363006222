import {AddLinkRounded} from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {Box} from "@mui/material";
import {IconButton} from "@mui/material";
import {isArray} from "lodash";
import {isEmpty} from "lodash";
import {useCallback} from "react";
import {useLayoutEffect} from "react";
import {useMemo} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioVarMap} from "../../../../api/meta/base/dto/StudioVarMap";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../../../api/meta/base/Types";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {toSymbolCase} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {resolveStudioVarValue} from "../../../../base/plus/StudioPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {TypeMuiSupportedColor} from "../../../../base/plus/ThemePlus";
import {stripIconSx} from "../../../../base/plus/ThemePlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {isSystemForm} from "../../../../routes/studio/ent/deploy/plugins/StudioEntDeployPluginPlus";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogAddNewVariable from "../../../dialog/DialogAddNewVariable";
import {fieldKeyName} from "../../builder/base/TypesFormBuilder";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdVar | MetaIdVar[] | undefined;

interface RightIconProps
{
  color: TypeMuiSupportedColor;
  cbOnClick: () => void;
}

const buttonWidth = 42;

export default function FieldRawStudioPickVarId(props: {
  defn: DefnStudioPickVarId,
  fieldProps: IControllerFieldProps,
  rightIcon?: RightIconProps,
  multiSelect?: boolean,
  showChip?: boolean,
  formId?: MetaIdForm
})
{
  const defn = props.defn;
  const fieldProps = props.fieldProps;
  const multiSelect = props.multiSelect;
  const formId = props.formId;
  const fieldId = defn.metaId;

  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();
  const formSectionCtx = useFormSectionCtx();
  const formHook = useFormContext();
  const formStore = formCtx.getStore();
  const readOnly = formCtx.isReadonly();

  const fieldValue = fieldProps.field.value as TypeFieldValue;
  const parentSection = formSectionCtx.getParent && formSectionCtx.getParent();

  const parentKey = parentSection?.metaId;
  const isFirst = formCtx.isFirstField(fieldId, parentKey);
  const isLast = formCtx.isLastField(fieldId, parentKey);
  const varMap = formStore?.varMap;
  const studioVar = useMemo(() => (varMap?.map && !multiSelect)
    ? varMap.map[fieldValue as MetaIdVar]
    : undefined, [varMap?.map, multiSelect, fieldValue]);

  const defnVarKind = defn.varKind;
  const defnVarKindSet = defn.varKindSet;
  const excludeVarIdSet = defn.excludeVarIdSet;
  const filterOptionSet = defn.filterOptionSet;

  const dtoOptions = useMemo(() => getOptions(
    varMap,
    defnVarKind,
    defnVarKindSet,
    excludeVarIdSet,
    fieldValue
  ), [defnVarKind, defnVarKindSet, excludeVarIdSet, varMap, fieldValue]);

  const [options] = useInsertTrashOptions({
    type: "varMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  const resolver = useMemo(() => formStore
    ? fnUseStudioResolver(formStore)
    : undefined, [formStore]);

  const studioVarValueString = resolveStudioVarValue(studioVar, resolver)?.trim();

  const helperText = useMemo(() =>
  {
    const _helperText = defnDtoTextToString(defn.helperTextVar);
    return !isEmpty(studioVarValueString)
      ? studioVar?.kind === "mapping"
        ? studioVarValueString
        : `Value: ${studioVarValueString}`
      : !isEmpty(_helperText)
        ? _helperText
        : undefined;
  }, [defn.helperTextVar, studioVar?.kind, studioVarValueString]);

  const onClick = formCtx.getOnClick();
  const disabled = defn?.disabled;

  const sx = stripIconSx(disabled
    ? theme.common.color("textDisabled")
    : undefined);

  const cbOnClickShowVar = useCallback((metaIdVar: MetaIdVar) =>
  {
    onClick && onClick(fieldId, "varShow", metaIdVar);
  }, [fieldId, onClick]);

  const cbOnClickEditVar = useCallback((metaIdVar: MetaIdVar) =>
  {
    const variable = varMap?.map[metaIdVar];
    variable && onClick && onClick(fieldId, "varEdit", variable);
  }, [fieldId, onClick, varMap?.map]);

  const onClickAddEditVar = useCallback(() =>
  {
    if(studioVar)
    {
      cbOnClickEditVar(studioVar.metaId);
      return;
    }

    const formValues = formHook.getValues();
    const fieldName: string = formValues[fieldKeyName] || "";
    const fieldPropertyName: string = toSymbolCase(getLabel(defn));
    const formMap = formStore?.formMap;
    const sysFormMap = formStore?.sysFormMap;

    const formName = formId
      ? isSystemForm(formId)
        ? sysFormMap?.map[formId].details.name
        : formMap?.map[formId].details.name
      : "";

    const allowedVarKind = defnVarKindSet || (defnVarKind
      ? [defnVarKind]
      : undefined);

    const varKindSet = allowedVarKind || [];

    pageCtx.showDialog(<DialogAddNewVariable
        formStore={formStore}
        varKindSet={varKindSet}
        filterOptionSet={filterOptionSet}
        onClickOk={(studioVar) =>
        {
          onClick && onClick(fieldId, "varAddNew", studioVar);

          if(!excludeVarIdSet?.includes(studioVar.metaId))
          {
            if(props.multiSelect)
            {
              if(!fieldValue)
              {
                fieldProps.field.onChange([studioVar.metaId]);
              }
              else if(isArray(fieldValue))
              {
                fieldProps.field.onChange([...fieldValue, studioVar.metaId]);
              }
            }
            else
            {
              fieldProps.field.onChange(studioVar.metaId);
            }
          }
        }}
        fieldName={fieldName}
        fieldPropertyName={fieldPropertyName}
        formName={formName}
      />
    );
  }, [
    studioVar,
    formHook,
    defn,
    formId,
    formStore,
    defnVarKindSet,
    defnVarKind,
    pageCtx,
    filterOptionSet,
    cbOnClickEditVar,
    onClick,
    fieldId,
    excludeVarIdSet,
    formCtx,
    props.multiSelect,
    fieldValue,
    fieldProps.field
  ]);

  useLayoutEffect(() =>
  {
    const error = fieldProps.fieldState.error;
    if(error)
    {
      setTimeout(() => formHook.setError(fieldId, error));
    }
  }, [fieldId]);

  let totalWithMinus = 0;
  if(Boolean(defn.showAsEdit && !readOnly))
  {
    totalWithMinus += buttonWidth;
  }

  if(props.rightIcon)
  {
    totalWithMinus += buttonWidth;
  }

  return (
    <Box
      sx={{
        "& > :first-child": {
          width: `calc(100% - ${totalWithMinus}px)  !important`
        },
        width: "100%",
        overflow: "visible",
        display: "flex",
        alignItems: "start",
        ...parentSection?.sectionDirection === "horizontal"
          ? {
            pl: defn.pl ?? isFirst ? FORM_FIELD_MEDIUM_PADDING : 0,
            pr: defn.pl ?? isLast ? FORM_FIELD_MEDIUM_PADDING : 0
          }
          : {
            pl: defn.pl ?? FORM_FIELD_MEDIUM_PADDING,
            pr: defn.pr ?? FORM_FIELD_MEDIUM_PADDING
          }
      }}
    >
      <FieldRawStudioPick
        {...props}
        defn={{
          ...defn,
          pr: 0,
          pl: 0
        }}
        multiSelect={multiSelect}
        optionMap={optionsToMapOfOption(options)}
        helperText={helperText}
      />

      {
        Boolean(defn.showAsEdit && !readOnly) &&
        <IconButton
          size={"small"}
          sx={{
            margin: 0,
            mt: px(3),
            ml: px(gapHalf)
          }}
          onClick={onClickAddEditVar}
          disabled={disabled}
          onContextMenu={(e) =>
          {
            if(studioVar)
            {
              e.preventDefault();
              e.stopPropagation();
              const menuProps = {
                "Edit": () =>
                {
                  cbOnClickEditVar(studioVar.metaId);
                },
                "Go to": () =>
                {
                  cbOnClickShowVar(studioVar.metaId);
                }
              } as IMenuProps;
              pageCtx.showMenu(e.currentTarget, menuProps);
              return false;
            }
          }}
        >
          {
            !studioVar || props.multiSelect
              ? <AddOutlinedIcon sx={sx} />
              : <EditRoundedIcon sx={sx} />
          }
        </IconButton>
      }
      {
        props.rightIcon &&
        <IconButton
          size={"small"}
          color={props.rightIcon.color}
          sx={{
            margin: 0,
            mt: px(3),
            ml: px(gapHalf)
          }}
          onClick={props.rightIcon.cbOnClick}
          disabled={disabled}
        >
          <AddLinkRounded
            color={props.rightIcon.color}
          />
        </IconButton>
      }

    </Box>
  );
}

function getOptions(
  varMap?: StudioVarMap,
  defnVarKind?: EnumStudioVarKind,
  defnVarKindSet?: EnumStudioVarKind[],
  excludeVarIdSet?: MetaIdVar[],
  fieldValue?: TypeFieldValue
): DefnDtoOption[]
{
  const options = [] as DefnDtoOption[];

  if(varMap)
  {
    const keys = varMap.keys;
    const map = varMap.map;

    if(!keys || !map)
    {
      return options;
    }

    const allowedVarKind = defnVarKindSet || (defnVarKind
      ? [defnVarKind]
      : undefined);

    keys.forEach(metaIdVar =>
    {
      const variable = map[metaIdVar];
      const variableKind = variable?.kind;
      const varName = variable?.details.name;
      if(!allowedVarKind || allowedVarKind.includes(variableKind))
      {
        if(excludeVarIdSet && excludeVarIdSet.includes(metaIdVar))
        {
          return;
        }
        options.push({
          value: varName,
          metaId: metaIdVar
        });
      }
    });

    const optionsIds = options.map(option => option.metaId);
    const metaIdVars = fieldValue
      ? (isArray(fieldValue)
        ? fieldValue
        : [fieldValue])
      : [];

    metaIdVars.forEach(varId =>
    {
      if(optionsIds.includes(varId))
      {
        return;
      }

      const variable = map[varId];
      if(variable)
      {
        options.push({
          value: variable.details.name,
          metaId: varId
        });
      }
    });
  }

  return options;
}
