// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgButtonFieldReportDataGet} from './msg/MsgButtonFieldReportDataGet';
import {MsgEntLogNumberFieldDataGet} from './msg/MsgEntLogNumberFieldDataGet';
import {MsgEntUserPickerCandidateListGet} from './msg/MsgEntUserPickerCandidateListGet';
import {MsgPluginApiOutput} from './msg/MsgPluginApiOutput';
import {MsgRefFieldDataPaginatedGet} from './msg/MsgRefFieldDataPaginatedGet';
import {MsgReportFieldDataGet} from './msg/MsgReportFieldDataGet';
import {ServiceName} from '../../meta/base/Types';
import {SigEntLogNumberFieldDataGet} from './sig/SigEntLogNumberFieldDataGet';
import {SigEntUserPickerCandidateListGet} from './sig/SigEntUserPickerCandidateListGet';
import {SigFormValue} from '../entMain/sig/SigFormValue';
import {SigPluginApiOutput} from './sig/SigPluginApiOutput';
import {SigRefFieldPaginatedData} from './sig/SigRefFieldPaginatedData';
import {SigReportFieldData} from './sig/SigReportFieldData';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocEntAside
{
  static readonly SN: ServiceName = "entAside";

  static entButtonFieldReportDataGet(entId: EntId, msg: MsgButtonFieldReportDataGet, sigAcceptor: ISigAcceptor<SigFormValue>): void
  {
    wsocCall<SigFormValue>(entId, WsocEntAside.SN, "entButtonFieldReportDataGet")
      .post(msg, sigAcceptor);
  }

  static entLogNumberFieldDataGet(entId: EntId, msg: MsgEntLogNumberFieldDataGet, sigAcceptor: ISigAcceptor<SigEntLogNumberFieldDataGet>): void
  {
    wsocCall<SigEntLogNumberFieldDataGet>(entId, WsocEntAside.SN, "entLogNumberFieldDataGet")
      .get(msg, sigAcceptor);
  }

  static entRefFieldPaginatedDataGet(entId: EntId, msg: MsgRefFieldDataPaginatedGet, sigAcceptor: ISigAcceptor<SigRefFieldPaginatedData>): void
  {
    wsocCall<SigRefFieldPaginatedData>(entId, WsocEntAside.SN, "entRefFieldPaginatedDataGet")
      .post(msg, sigAcceptor);
  }

  static entReportFieldDataGet(entId: EntId, msg: MsgReportFieldDataGet, sigAcceptor: ISigAcceptor<SigReportFieldData>): void
  {
    wsocCall<SigReportFieldData>(entId, WsocEntAside.SN, "entReportFieldDataGet")
      .post(msg, sigAcceptor);
  }

  static entUserPickerCandidateListGet(entId: EntId, msg: MsgEntUserPickerCandidateListGet, sigAcceptor: ISigAcceptor<SigEntUserPickerCandidateListGet>): void
  {
    wsocCall<SigEntUserPickerCandidateListGet>(entId, WsocEntAside.SN, "entUserPickerCandidateListGet")
      .post(msg, sigAcceptor);
  }

  static pluginApiOutputGet(entId: EntId, msg: MsgPluginApiOutput, sigAcceptor: ISigAcceptor<SigPluginApiOutput>): void
  {
    wsocCall<SigPluginApiOutput>(entId, WsocEntAside.SN, "pluginApiOutputGet")
      .post(msg, sigAcceptor);
  }
}