import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {StudioEntDeployPaymentProviderMap} from "../../../../api/meta/base/dto/StudioEntDeployPaymentProviderMap";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldRawStudioPickDeployPaymentProviderId(props: {
  defn: DefnFieldEditable,
  fieldProps: IControllerFieldProps,
  showChip?: boolean,
  multiSelect?: boolean,
})
{
  const formCtx = useFormCtx();

  const formStore = formCtx.getStore();
  const deployPaymentProviderMap = formStore?.deployPaymentProviderMap;

  const dtoOptions = useMemo(() => deployPaymentProviderMap
    ? getPickLayoutGridOptions(deployPaymentProviderMap)
    : [], [deployPaymentProviderMap]);

  return <FieldRawStudioPick
    {...props}
    multiSelect={props.multiSelect}
    showChip={props.showChip}
    optionMap={optionsToMapOfOption(dtoOptions)}
  />;
}

function getPickLayoutGridOptions(
  deployPaymentProviderMap: StudioEntDeployPaymentProviderMap
): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];

  deployPaymentProviderMap.keys.forEach(paymentProviderId =>
  {
    const paymentProvider = deployPaymentProviderMap.map[paymentProviderId];

    options.push({
      value: paymentProvider.name
        ? paymentProvider.name.toString()
        : paymentProviderId,
      metaId: paymentProviderId
    });
  });

  return options;
}


