import {useCallback} from "react";
import {useRef} from "react";
import {useEffect} from "react";
import {calcDeltaArray} from "../../../../base/plus/JsPlus";
import {SelectKanban} from "../../../../base/plus/KanbanPlus";
import {TypeKanbanItemId} from "../../../../base/types/TypeKanban";
import {CbOnClickKanban} from "../../../../base/types/TypeKanban";
import {IKanbanBinderAll} from "../../../../base/types/TypeKanban";
import {useAppSelector} from "../../../app/AppHooks";

export const KANBAN_INITIAL_COL_ROWS_BATCH = 25;

export default function useKanbanPubSub<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  itemId: TypeKanbanItemId,
  selectKanban: SelectKanban
  kanbanBinder?: IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  cbOnClick?: CbOnClickKanban
  onItemSubscribe?: (itemId: TypeKanbanItemId) => void,
  onItemUnsubscribe?: (itemId: TypeKanbanItemId) => void,
})
{
  const onItemSubscribe = props.onItemSubscribe;
  const onItemUnsubscribe = props.onItemUnsubscribe;
  const kanbanBinder = props.kanbanBinder;
  const selectKanban = props.selectKanban;
  const itemId = props.itemId;
  const kanbanName = useAppSelector(state => selectKanban(state).kanbanName);

  const selectSourceItem1 = kanbanBinder?.selectSourceItem1;
  const selectSourceItem2 = kanbanBinder?.selectSourceItem2;
  const selectSourceItem3 = kanbanBinder?.selectSourceItem3;
  const selectSourceItem4 = kanbanBinder?.selectSourceItem4;
  const selectSourceItem5 = kanbanBinder?.selectSourceItem5;
  const selectSourceItem6 = kanbanBinder?.selectSourceItem6;
  const onBindSourceItem1 = kanbanBinder?.onBindSourceItem1;
  const onBindSourceItem2 = kanbanBinder?.onBindSourceItem2;
  const onBindSourceItem3 = kanbanBinder?.onBindSourceItem3;
  const onBindSourceItem4 = kanbanBinder?.onBindSourceItem4;
  const onBindSourceItem5 = kanbanBinder?.onBindSourceItem5;
  const onBindSourceItem6 = kanbanBinder?.onBindSourceItem6;

  const sourceItem1 = useAppSelector(state => (selectSourceItem1 && itemId)
    ? selectSourceItem1(state, itemId)
    : undefined);
  const sourceItem2 = useAppSelector(state => (selectSourceItem2 && itemId)
    ? selectSourceItem2(state, itemId)
    : undefined);
  const sourceItem3 = useAppSelector(state => (selectSourceItem3 && itemId)
    ? selectSourceItem3(state, itemId)
    : undefined);
  const sourceItem4 = useAppSelector(state => (selectSourceItem4 && itemId)
    ? selectSourceItem4(state, itemId)
    : undefined);
  const sourceItem5 = useAppSelector(state => (selectSourceItem5 && itemId)
    ? selectSourceItem5(state, itemId)
    : undefined);
  const sourceItem6 = useAppSelector(state => (selectSourceItem6 && itemId)
    ? selectSourceItem6(state, itemId)
    : undefined);

  useEffect(() =>
  {
    if(onBindSourceItem1 && itemId)
    {
      onBindSourceItem1(kanbanName, itemId, sourceItem1);
    }
  }, [sourceItem1, onBindSourceItem1, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem2 && itemId)
    {
      onBindSourceItem2(kanbanName, itemId, sourceItem2);
    }
  }, [sourceItem2, onBindSourceItem2, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem3 && itemId)
    {
      onBindSourceItem3(kanbanName, itemId, sourceItem3);
    }
  }, [sourceItem3, onBindSourceItem3, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem4 && itemId)
    {
      onBindSourceItem4(kanbanName, itemId, sourceItem4);
    }
  }, [sourceItem4, onBindSourceItem4, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem5 && itemId)
    {
      onBindSourceItem5(kanbanName, itemId, sourceItem5);
    }
  }, [sourceItem5, onBindSourceItem5, itemId]);

  useEffect(() =>
  {
    if(onBindSourceItem6 && itemId)
    {
      onBindSourceItem6(kanbanName, itemId, sourceItem6);
    }
  }, [sourceItem6, onBindSourceItem6, itemId]);

  useEffect(() =>
  {
    if(onItemSubscribe && itemId)
    {
      onItemSubscribe(itemId);
    }

    return () =>
    {
      (onItemUnsubscribe && itemId) && onItemUnsubscribe(itemId);
    };
  }, [onItemSubscribe, onItemUnsubscribe, itemId]);

  return null;
}

export function useKanbanColSubscription(props: {
  displayColumnsByItemIds: TypeKanbanItemId[],
  onRowsSubscribe?: (rowIds: TypeKanbanItemId[]) => void,
  onRowsUnsubscribe?: (rowIds: TypeKanbanItemId[]) => void,
})
{
  const initRows = props.displayColumnsByItemIds.slice(0, KANBAN_INITIAL_COL_ROWS_BATCH);
  const onRowsUnsubscribe = props.onRowsUnsubscribe;
  const onRowsSubscribe = props.onRowsSubscribe;

  const previousSubscription = useRef<TypeKanbanItemId[]>([]);
  const subscription = useRef<Set<TypeKanbanItemId>>(new Set<TypeKanbanItemId>([...initRows]));

  useEffect(() =>
  {
    return () =>
    {
      if(onRowsUnsubscribe)
      {
        onRowsUnsubscribe([...subscription.current]);
      }
    };
  }, []);

  return useCallback((renderedRowIdList: TypeKanbanItemId[]) =>
  {
    if(!onRowsSubscribe || !onRowsUnsubscribe)
    {
      return;
    }

    const delta = calcDeltaArray(previousSubscription.current, renderedRowIdList);
    const unsubscribedRows = delta.deletes;
    const subscribedRows = delta.inserts;
    const _previousSubscription = new Set([...previousSubscription.current, ...subscribedRows]);

    delta.deletes.forEach(rowId =>
    {
      _previousSubscription.delete(rowId);
    });
    previousSubscription.current = [..._previousSubscription];

    if(subscribedRows.length > 0)
    {
      subscription.current = new Set([...subscription.current, ...subscribedRows]);
      onRowsSubscribe(subscribedRows);
    }
    if(unsubscribedRows.length > 0)
    {
      onRowsUnsubscribe(unsubscribedRows);
    }
  }, [onRowsSubscribe, onRowsUnsubscribe]);
}
