import React from "react";
import {px} from "../../../../base/plus/StringPlus";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import RawQuickLink from "../../raw/RawQuickLink";

export default function BubbleLineActions(props: {
  actionList: string[],
  heightLine?: number,
  onClick?: (actionName: string, menuAnchor: Element) => void
})
{
  const {
    actionList,
    heightLine,
    onClick
  } = props;

  return (
    <LayoutFlexRow
      width={"100%"}
      justifyContent={"space-around"}
      height={px(heightLine)}
    >
      {
        actionList.map((actionName) =>
          <RawQuickLink
            key={actionName}
            name={actionName}
            onClick={onClick
              ? (actionName, menuAnchor) => onClick(actionName, menuAnchor)
              : undefined}
            color={"primary"}
            bold
          />)
      }
    </LayoutFlexRow>

  );
}
