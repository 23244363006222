import {PayloadAction} from "@reduxjs/toolkit";
import {TypeListItemId} from "../../types/list/TypesList";
import {IList} from "../../types/list/TypesList";
import {createListItemGapQuarter} from "../../types/list/TypesListGap";
import {createListItemGapStd} from "../../types/list/TypesListGap";
import {createListItemGapHalf} from "../../types/list/TypesListGap";

export const sliceListGap =
  {
    listSetGapQuarter: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      state.itemsById[action.payload] = createListItemGapQuarter();
    },
    listSetGapHalf: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      state.itemsById[action.payload] = createListItemGapHalf();
    },
    listSetGapStd: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      state.itemsById[action.payload] = createListItemGapStd();
    }
  };
