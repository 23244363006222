import {FieldValues} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldDivider} from "../../../../../api/meta/base/dto/StudioFieldDivider";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {FORMS_FIELD_DIVIDER_KIND} from "../../../../atom/assets/HelperTextStudio";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldGap} from "../../base/TypesFormBuilder";
import {getFieldGap} from "../../base/TypesFormBuilder";
import {propKeyDividerKind} from "../../base/TypesFormBuilder";
import {propKeyColor} from "../../base/TypesFormBuilder";
import {propKeyDividerKindVar} from "../../base/TypesFormBuilder";

export function getDefnFieldDivider(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{

  const varIds = [
    propKeyDividerKindVar
  ];

  const constIds = [
    propKeyColor,
    fieldGap,
    propKeyDividerKind
  ];
  return {
    ...getFieldBuilderPropertySubTabs(
      "currency",
      formId,
      undefined,
      fieldId,
      undefined,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDividerKindVar]: {
      type: "pickVarId",
      metaId: propKeyDividerKindVar,
      varKind: "dividerKind",
      showAsEdit: true,
      formId: formId,
      label: "Divider kind",
      helperTextVar: stringToDefnDtoText(FORMS_FIELD_DIVIDER_KIND)
    } as DefnStudioPickVarId,

    [propKeyDividerKind]: {
      type: "enumThemeDividerThickness",
      metaId: propKeyDividerKind,
      name: propKeyDividerKind,
      label: "Divider kind",
      helperTextVar: stringToDefnDtoText(FORMS_FIELD_DIVIDER_KIND)
    } as DefnFieldPickEnum,

    [propKeyColor]: {
      type: "studioBuildColor",
      metaId: propKeyColor,
      name: propKeyColor,
      label: "Color",
      allowShades: true
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap, "thin")

  };
}

export function defnValueToStudioFieldDivider(values: FieldValues): StudioFieldDivider
{
  return {
    dividerKindVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDividerKindVar]),
    color: fnFieldValueToRawValue("studioBuildColor", values[propKeyColor]),
    dividerKind: fnFieldValueToRawValue("enumThemeDividerThickness", values[propKeyDividerKind])
  } as StudioFieldDivider;
}

export function studioFieldDividerToDefnValue(studioField: StudioFieldDivider): FieldValues
{
  return {
    [propKeyDividerKind]: fnRawValueToFieldValue("enumThemeDividerThickness", studioField.dividerKind),
    [propKeyColor]: fnRawValueToFieldValue("studioBuildColor", studioField.color),
    [propKeyDividerKindVar]: fnRawValueToFieldValue("pickVarId", studioField.dividerKindVarId)
  };
}
