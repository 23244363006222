import ISrvc from "../../../../base/ISrvc";
import {dispatchPlugin} from "../../SrvcCacheStudio";
import {ActionUpdatePluginDeploy} from "../SliceCacheStudioPlugin";
import {updatePluginDeploy} from "../SliceCacheStudioPlugin";

export default class SrvcCacheStudioPluginDeploy extends ISrvc
{
  updatePluginDeploy(payload: ActionUpdatePluginDeploy)
  {
    dispatchPlugin(payload.pluginBundleId, updatePluginDeploy(payload));
  }
}
