import {useMemo} from "react";
import {useCallback, useState} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdGrid} from "../../api/meta/base/ApiPlus";
import {nextMetaIdSection} from "../../api/meta/base/ApiPlus";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {EnumArrayDefnKindFormComposite, EnumDefnCompType} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, createRadioOptions, defaultSectionKey} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {fieldGap, fieldKeyName, getFieldGap} from "../form/builder/base/TypesFormBuilder";
import DialogDefnForm from "./base/impl/DialogDefnForm";

interface IDialogNewSectionOrGrid
{
  sectionType: EnumDefnCompType,
  metaId: MetaIdComposite
  name: string
}

export type TypeDefaultCompositeType = "section" | "grid";

export default function DialogNewSectionOrGrid(props: {
  onClickOk: (values: IDialogNewSectionOrGrid, isAddMoreEnable: boolean) => void,
  onClose?: () => void,
  defaultCompositeType?: TypeDefaultCompositeType
})
{
  const DEFAULT_SECTION_NAME = "Details";
  const DEFAULT_GRID_NAME = "NewGrid";

  const defaultCompositeType = props.defaultCompositeType;

  const [isAddMoreEnable, setIsAddMoreEnable] = useState(false);

  const formRef = useMemo(() => ({} as IFormRef), []);

  const onSubmit = useCallback((values: FieldValues) =>
  {
    const dto = valueToDto(values);
    if(dto)
    {
      props.onClickOk(dto, isAddMoreEnable);
    }

  }, [isAddMoreEnable]);

  const onWatch = useCallback((key: MetaIdField, value: FieldValues) =>
  {

    if(key === fieldSectionType && value)
    {
      if(value.optionId && value.optionId === "section")
      {
        formRef.setValue(fieldKeyName, DEFAULT_SECTION_NAME);
      }
      else if(value.optionId && value.optionId === "grid")
      {
        formRef.setValue(fieldKeyName, DEFAULT_GRID_NAME);
      }
    }
  }, []);

  return (
    <DialogDefnForm
      formProps={{
        cbRef: formRef,
        defnForm: getDefnForm(),
        onSubmit: (values) =>
        {
          onSubmit(values);
        },
        onWatch: (key, value) =>
        {
          onWatch(key, value);
        },
        initValues: {
          [fieldSectionType]: fnRawValueToFieldValue("pickText", defaultCompositeType ?? "section"),
          [fieldKeyName]: fnRawValueToFieldValue("symbol",
            defaultCompositeType === "grid"
              ? DEFAULT_GRID_NAME
              : DEFAULT_SECTION_NAME
          )
        }
      }}
      title={"New section or grid"}
      addMoreCheckBoxLabel={"Add more sections or grids"}
      onClose={props.onClose}
      onChangeAddMore={setIsAddMoreEnable}
    />
  );
}

const fieldSectionType = "sectionType";

const sectionTypeOptionMap = {
  section: "New section",
  grid: "New grid"
} as Record<EnumDefnCompType, string>;

function getDefnForm()
{
  return createDefaultDefnFormStudio({

    [fieldSectionType]: {
      type: "pickText",
      metaId: fieldSectionType,
      name: fieldSectionType,
      required: true,
      optionMap: createRadioOptions(EnumArrayDefnKindFormComposite, sectionTypeOptionMap),
      showAs: "radioButtonVertical"
    } as DefnFieldPickText,

    ...getFieldGap(fieldGap, "thick"),

    [fieldKeyName]: {
      type: "symbol",
      metaId: fieldKeyName,
      name: fieldKeyName,
      label: "Name",
      required: true
    } as DefnFieldText,
    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldSectionType, fieldGap, fieldKeyName]
    } as DefnSection
  });
}

function valueToDto(values: FieldValues): IDialogNewSectionOrGrid | undefined
{
  const type = fnFieldValueToRawValue("pickText", values[fieldSectionType]) as EnumDefnCompType;
  if(type)
  {
    return {
      name: values[fieldKeyName],
      sectionType: type,
      metaId: type === "grid" ? nextMetaIdGrid() : nextMetaIdSection()
    };
  }
}
