import {useTheme} from "@mui/material";
import {Typography} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {Property} from "csstype";
import {useState} from "react";
import React from "react";
import Highlighter from "react-highlight-words";
import {removeSpecialChars} from "../../../base/plus/StringPlus";
import {px} from "../../../base/plus/StringPlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import {ILineCaption} from "../../../base/types/TypesGlobal";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawCounter from "../raw/RawCounter";
import RawMarkDown from "../raw/RawMarkDown";

export default function LineCaption(props: {
  caption?: ILineCaption,
  searchWords?: string[],
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  onChangeCounterValue?: (e: Element, countValue: number) => void,
  width?: StandardCSSProperties["width"],
  height?: StandardCSSProperties["height"],
  textAlign?: StandardCSSProperties["textAlign"],
  ml?: number,
  mr?: number,
  overflow?: Property.Overflow,
  hasMarkDownText?: boolean
})
{
  const caption = props.caption;
  const theme = useTheme();
  if(!caption)
  {
    return null;
  }

  if(caption.type === undefined || caption.type === "text")
  {
    if(caption.hasMarkDownText)
    {
      return <RawMarkDown
        color={caption.color}
        variant={caption.variant ?? "caption"}
        value={caption.text}
        width={props.width}
        height={props.height}
        ml={props.ml}
        mr={props.mr}
        noWrap={true}
      />;
    }

    return (
      <LineCaptionText
        {...props}
        caption={caption}
      />
    );
  }

  return <LayoutFlexRow>
    <RawCounter
      position={"right"}
      value={caption.value}
      onChange={props.onChangeCounterValue}
      buttonSize={px(theme.common.heightLine)}
      minVar={0}
      maxVar={10000}
    />
  </LayoutFlexRow>;

}

function LineCaptionText(props: {
  caption: ILineCaption,
  searchWords?: string[],
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  width?: StandardCSSProperties["width"],
  height?: StandardCSSProperties["height"],
  textAlign?: StandardCSSProperties["textAlign"],
  ml?: number,
  mr?: number,
  overflow?: Property.Overflow
})
{
  const onClick = props.onClick;
  const theme = useTheme();
  const [bold, setBold] = useState(false);

  const caption = props.caption;
  if(caption.text === undefined)
  {
    return null;
  }

  return (
    <Typography
      flexGrow={1}
      noWrap={true}
      variant={caption.variant ?? "caption"}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
      {
        if(onClick && !caption?.ignoreSelection)
        {
          e.stopPropagation();
          e.preventDefault();
          setBold(true);

          setTimeout(() =>
          {
            setBold(false);
            onClick(e);
          }, theme.common.durationTransition);
        }
      }}
      sx={{
        color: theme.common.color(caption.color as TypeTextColor ?? "textDisabled") || caption.color,
        width: props.width,
        height: props.height,
        ml: px(props.ml),
        mr: px(props.mr),
        overflow: props.overflow ?? "visible", //show full caption text
        fontWeight: bold ? "bold" : undefined,
        cursor: onClick ? "pointer" : undefined,
        textAlign: props.textAlign ?? "center"
      }}
    >
      {props.searchWords === undefined
        ? caption.text
        : <Highlighter
          searchWords={props.searchWords.map(str => removeSpecialChars(str))}
          textToHighlight={caption.text}
          highlightStyle={{background: theme.common.bgcolorHighlight}}
        />
      }
    </Typography>
  );
}
