import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickOption} from "../../api/meta/base/dto/DefnFieldPickOption";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutGridId} from "../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnStudioPickLayoutSpreadsheetId} from "../../api/meta/base/dto/DefnStudioPickLayoutSpreadsheetId";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutGrid} from "../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioDtoLayoutLocmap} from "../../api/meta/base/dto/StudioDtoLayoutLocmap";
import {GeoPointType} from "../../api/meta/base/StudioSetsFieldType";
import {ToolTip} from "../../api/meta/base/StudioSetsFieldType";
import {Color, GroupBy, Shape, Stroke} from "../../api/meta/base/StudioSetsFieldType";
import {EnumArrayDefnMapRenderingMode} from "../../api/meta/base/Types";
import {MetaId} from "../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {px} from "../../base/plus/StringPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldGap2} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyLabel} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription, fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {getCommonDetailsTab} from "./base/LayoutBuilderPlus";
import {fieldLayoutDetailTooltipField} from "./base/TypesLayoutBuilder";
import {allowToSwitchLayoutsLabel} from "./base/TypesLayoutBuilder";

//grid layout map
const fieldLocationField = "locationFieldId";
const fieldColorField = "colorFieldId";
const fieldShapeField = "shapeFieldId";
const fieldGroupByField = "groupByFieldId";
const fieldGridLayoutSwitcherIdSet = "allowToSwitchLayoutIdSet";
const fieldStrokeField = "strokeFieldId";
const fieldRenderingMode = "renderingMode";

const dialogContentHeight = 600;
const dialogContentWidth = 900;

type TypeMapType = "grid" | "spreadsheet"

export default function DialogNewLayoutMap(props: {
  formStore?: FormStore,
  metaIdForm?: MetaIdForm,
  mapType: TypeMapType,
  metaIdGrid?: MetaIdGrid,
  spreadsheetId?: MetaIdSpreadsheet,
  isFormReadOnly?: boolean,
  validationError?: IFormFieldError[]
  values?: StudioDtoLayoutLocmap,
  onClickOk: (values: StudioDtoLayoutLocmap) => void,
  onClose?: () => void
})
{
  const values = props.values;
  const mapType = props.mapType;
  const validationError = props.validationError;

  const excludeLayoutIdSet = values?.metaId
    ? [values?.metaId]
    : undefined;
  const formStore = props.formStore;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      formProps={{
        store: formStore,
        cbRef: cbRef.current,
        formReadonly: props.isFormReadOnly,
        defnForm: getDefnForm(mapType, props.metaIdForm, props.metaIdGrid, excludeLayoutIdSet, props.spreadsheetId),
        initValues: dtoToValue(mapType, values),
        onSubmit: value => props.onClickOk(valueToDto(value, mapType))
      }}
      title={`${values ? "Update" : "New"} ${mapType} layout - Map`}
      addMoreCheckBoxLabel={!values
        ? `Add more ${mapType} layouts`
        : undefined}
      onClose={props.onClose}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
    />
  );
}
const fieldLayoutMapTab = "Map";
const fieldLayoutMapDetails = "mapDetails";

function getDefnForm(
  mapType: TypeMapType,
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeLayoutIdSet?: MetaId[],
  spreadsheetId?: MetaIdSpreadsheet
)
{
  return createDefaultDefnFormStudio({

    ...getLayoutMapDetails(mapType, metaIdForm, metaIdGrid, excludeLayoutIdSet, spreadsheetId),

    ...getLayoutMapTab(metaIdForm, metaIdGrid),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: "standard",
      tabIdSet: [
        fieldLayoutMapDetails,
        fieldLayoutMapTab
      ]
    } as DefnTab
  });
}

function getLayoutMapDetails(
  mapType: TypeMapType,
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeLayoutIdSet?: MetaId[],
  spreadsheetId?: MetaIdSpreadsheet
)
{
  return {
    ...getCommonDetailsTab(),

    [fieldLayoutDetailTooltipField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailTooltipField,
      name: fieldLayoutDetailTooltipField,
      label: "Tooltip field",
      filterFieldTypeSet: ToolTip,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      formId: metaIdForm
    } as DefnStudioPickFieldId,

    ...getFieldGap(fieldGap2, "thick"),

    ...mapType === "grid" ? {
        [fieldGridLayoutSwitcherIdSet]: {
          type: "studioSetOfLayoutGridId",
          metaId: fieldGridLayoutSwitcherIdSet,
          name: fieldGridLayoutSwitcherIdSet,
          label: allowToSwitchLayoutsLabel,
          excludeLayoutGridIdSet: excludeLayoutIdSet,
          formId: metaIdForm,
          pb: px(gapStd)
        } as DefnStudioPickLayoutGridId
      }
      : {
        [fieldGridLayoutSwitcherIdSet]: {
          type: "studioSetOfLayoutSpreadsheetId",
          metaId: fieldGridLayoutSwitcherIdSet,
          name: fieldGridLayoutSwitcherIdSet,
          label: allowToSwitchLayoutsLabel,
          spreadsheetId: spreadsheetId,
          excludeLayoutSpreadsheetIdSet: excludeLayoutIdSet,
          pb: px(gapStd)
        } as DefnStudioPickLayoutSpreadsheetId
      },

    [fieldRenderingMode]: {
      type: "pickOption",
      metaId: fieldRenderingMode,
      name: fieldRenderingMode,
      label: "Rendering mode",
      optionSet: EnumArrayDefnMapRenderingMode
    } as DefnFieldPickOption,

    [fieldLayoutMapDetails]: {
      type: "section",
      metaId: fieldLayoutMapDetails,
      name: fieldLayoutMapDetails,
      label: "Details",
      fieldIdSet: [
        fieldKeyName,
        fieldKeyLabel,
        fieldKeyDescription,
        fieldLayoutDetailTooltipField,
        fieldGap2,
        fieldRenderingMode,
        fieldGridLayoutSwitcherIdSet
      ]
    } as DefnSection
  };
}

function getLayoutMapTab(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid)
{
  return {
    [fieldLocationField]: {
      type: "pickFieldId",
      metaId: fieldLocationField,
      name: fieldLocationField,
      label: "Location field",
      filterFieldTypeSet: GeoPointType,
      formId: metaIdForm,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      required: true
    } as DefnStudioPickFieldId,
    [fieldColorField]: {
      type: "pickFieldId",
      metaId: fieldColorField,
      name: fieldColorField,
      label: "Color field",
      filterFieldTypeSet: Color,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      formId: metaIdForm
    } as DefnStudioPickFieldId,
    [fieldShapeField]: {
      type: "pickFieldId",
      metaId: fieldShapeField,
      name: fieldShapeField,
      label: "Shape field",
      filterFieldTypeSet: Shape,
      formId: metaIdForm,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,
    [fieldGroupByField]: {
      type: "pickFieldId",
      metaId: fieldGroupByField,
      name: fieldGroupByField,
      label: "Group by field",
      formId: metaIdForm,
      filterFieldTypeSet: GroupBy,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,
    [fieldStrokeField]: {
      type: "pickFieldId",
      metaId: fieldStrokeField,
      name: fieldStrokeField,
      label: "Stroke field",
      formId: metaIdForm,
      filterFieldTypeSet: Stroke,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,

    [fieldLayoutMapTab]: {
      type: "section",
      name: fieldLayoutMapTab,
      metaId: fieldLayoutMapTab,
      fieldIdSet: [
        fieldLocationField,
        fieldColorField,
        fieldShapeField,
        fieldGroupByField,
        fieldStrokeField
      ]
    } as DefnSection
  };
}

function dtoToValue(
  mapType: TypeMapType,
  locmap?: StudioDtoLayoutLocmap
)
{
  if(!locmap)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "Map")
    };

  }
  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", locmap.name ?? "Map"),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", locmap.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", locmap.description),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", locmap?.toolTipFieldId),
    [fieldColorField]: fnRawValueToFieldValue("pickFieldId", locmap?.colorFieldId),
    [fieldGroupByField]: fnRawValueToFieldValue("pickFieldId", locmap?.groupByFieldId),
    [fieldLocationField]: fnRawValueToFieldValue("pickFieldId", locmap?.locationFieldId),
    [fieldShapeField]: fnRawValueToFieldValue("pickFieldId", locmap?.shapeFieldId),
    [fieldStrokeField]: fnRawValueToFieldValue("pickFieldId", locmap?.strokeFieldId),
    [fieldRenderingMode]: fnRawValueToFieldValue("pickOption", locmap?.renderingMode),
    [fieldGridLayoutSwitcherIdSet]: mapType === "spreadsheet"
      ? fnRawValueToFieldValue("studioSetOfLayoutSpreadsheetId",
        (locmap as StudioDtoLayoutGrid)?.allowToSwitchLayoutIdSet
      )
      : fnRawValueToFieldValue("studioSetOfLayoutGridId", (locmap as StudioDtoLayoutGrid)?.allowToSwitchLayoutIdSet)
  };
}

function valueToDto(
  values: FieldValues,
  mapType: TypeMapType): StudioDtoLayoutLocmap
{
  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    label: fnFieldValueToRawValue("text", values[fieldKeyLabel]),
    kind: "map",
    description: fnFieldValueToRawValue("text", values[fieldKeyDescription]),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLayoutDetailTooltipField]),
    colorFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldColorField]),
    groupByFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldGroupByField]),
    locationFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLocationField]),
    shapeFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldShapeField]),
    strokeFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldStrokeField]),
    renderingMode: fnFieldValueToRawValue("pickOption", values[fieldRenderingMode]),
    allowToSwitchLayoutIdSet: mapType === "spreadsheet"
      ? fnFieldValueToRawValue("studioSetOfLayoutSpreadsheetId", values[fieldGridLayoutSwitcherIdSet])
      : fnFieldValueToRawValue("studioSetOfLayoutGridId", values[fieldGridLayoutSwitcherIdSet])
  } as StudioDtoLayoutLocmap;
}
