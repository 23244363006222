import {MsgSignIn} from "../../../api/core/user/msg/MsgSignIn";
import {RpcUser} from "../../../api/core/user/RpcUser";
import {EntId} from "../../../api/meta/base/Types";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {ISigAcceptor} from "../../../api/nucleus/base/ISigAcceptor";
import {SigRefreshToken} from "../../../api/nucleus/stem/sig/SigRefreshToken";
import ISrvc from "../../../base/ISrvc";
import LocalLocalStorage from "../../../base/local/LocalLocalStorage";
import LocalSessionStorage from "../../../base/local/LocalSessionStorage";
import {toComboId} from "../../../base/types/TypesComboId";
import {myWorldItemId} from "../../../base/types/TypesDrawer";
import {updateHomeFilter} from "../../../cache/home/drawer/filter/SliceCacheHomeDrawerFilter";
import {clearHomeFilter} from "../../../cache/home/drawer/filter/SliceCacheHomeDrawerFilter";
import {ICtxHome} from "../../../routes/home/ctx/ICtxHome";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";
import {setWidgetConfig} from "./SliceWidget";
import {NeomeWidgetFloating} from "./TypesWidget";
import {NeomeWidgetEmbed} from "./TypesWidget";

type IPostMsgType =
  | "connected"
  | "getConfig"
  | "disconnected"
  | "badge";

type IGetMsgType = "init" | "terminate";

interface IGetMsgPayload
{
  type: IGetMsgType,
  payload: any
}

interface IPostMsgResponse
{
  type: IPostMsgType,
  payload: any
}

const unHostedUrl = "null";

type TypeWidgetConfig = NeomeWidgetEmbed & NeomeWidgetFloating;

export default class SrvcWidget extends ISrvc
{
  private widgetId: string | undefined = undefined;
  private targetUrl?: string;
  private config?: TypeWidgetConfig;
  private forceSignIn?: boolean;
  private filtered = false;

  getIsForceSignIn(): boolean | undefined
  {
    return this.forceSignIn;
  }

  isWidget()
  {
    return Boolean(this.widgetId);
  }

  isWidgetAllowNotifications()
  {
    return Boolean(this.widgetId && this.config?.allowPushNotifications);
  }

  sendBadgeCount(badgeCount: number)
  {
    if(this.targetUrl && this.targetUrl !== unHostedUrl && !this.config?.disableBadgeCount)
    {
      window.top?.postMessage({
        type: "badge",
        payload: badgeCount
      } as IPostMsgResponse, this.targetUrl);
    }
  }

  initWidget()
  {
    const url = new URL(window.location.href);
    const widgetId = url.searchParams.get("widgetId");
    const forceSignIn = url.searchParams.get("forceSignIn");
    if(widgetId)
    {
      LocalLocalStorage.setPrefix(widgetId);
      LocalSessionStorage.setPrefix(widgetId);
      this.widgetId = widgetId;
    }
    if(forceSignIn && forceSignIn === "true")
    {
      this.forceSignIn = true;
    }
  }

  forceUserSignIn(msg: MsgSignIn, sigAcceptor: ISigAcceptor<SigRefreshToken>)
  {
    RpcUser.signIn(msg, (envSig) => this.onSignInEnvSig(envSig, sigAcceptor));
  }

  selectGroupId(homeCtx: ICtxHome, chatIds?: string[])
  {
    const rootState = store.getState();
    const config = rootState.app.widget.config;
    const selectGroupId = config?.selectGroupId;
    const filterEntId = config?.filterEntId;
    if(selectGroupId && filterEntId)
    {
      const chatSelectId = toComboId(filterEntId, selectGroupId);
      if(chatIds?.includes(chatSelectId))
      {
        homeCtx.chatListSelect(chatSelectId, true);
      }
    }
  }

  protected doTick(dateNow: Date)
  {
    super.doTick(dateNow);
    if(!this.config && this.targetUrl)
    {
      window.top?.postMessage({
        type: "getConfig"
      } as IPostMsgResponse, this.targetUrl);
    }

    if(!this.filtered)
    {
      const rootState = store.getState();
      const flagBearerToken = rootState.app.auth.flagBearerToken;
      const userIdHash = rootState.cache.app.caller.callerUserIdHash;
      const entIdUserSet = rootState.cache.app.caller.entIdUserSet;
      if(flagBearerToken && userIdHash && entIdUserSet)
      {
        if(this.config)
        {
          this.filterEntId(this.config, userIdHash, entIdUserSet);
        }
      }
    }
  }

  protected doInit()
  {
    window.addEventListener("message", (msg: MessageEvent<IGetMsgPayload>) =>
    {
      const origin = msg.origin;
      const data = msg.data as IGetMsgPayload;
      if(data?.type === "init" && origin && origin !== unHostedUrl)
      {
        this.targetUrl = origin;
        if(data.payload)
        {
          const config = data.payload as TypeWidgetConfig;
          if(this.widgetId && this.widgetId === config.id)
          {
            this.config = config;
            store.dispatch(setWidgetConfig(config));

            msg.source?.postMessage({
              type: "connected",
              payload: this.widgetId
            } as IPostMsgResponse, {
              targetOrigin: origin
            });
          }
        }
      }
    });
  }

  protected doSignOut()
  {
    super.doSignOut();
    this.filtered = false;
    this.sendBadgeCount(0);
    if(this.config)
    {
      store.dispatch(setWidgetConfig(this.config));
    }
  }

  private onSignInEnvSig(envSig: EnvSignal<SigRefreshToken>, sigAcceptor: ISigAcceptor<SigRefreshToken>)
  {
    if(envSig.error)
    {
      Srvc.app.toast.showErrorToast(envSig);
      sigAcceptor(envSig);
      return;
    }
    Srvc.app.auth.rpcGrantBearerToken(true, (envSig) =>
    {
      sigAcceptor(envSig);
      if(envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }
      else if(!envSig.error)
      {
        if(this.config)
        {
          const rootState = store.getState();
          const caller = envSig.sig?.caller;
          const userIdHash = caller?.userIdHash || rootState.cache.app.caller.callerUserIdHash;
          const entIdUserSet = caller?.entUserIdMap
            ? Object.keys(caller?.entUserIdMap)
            : rootState.cache.app.caller.entIdUserSet;
          if(userIdHash && entIdUserSet)
          {
            this.filterEntId(this.config, userIdHash, entIdUserSet);
          }
          else
          {
            this.filtered = false;
          }
        }
      }
    });
  }

  private filterEntId(config: TypeWidgetConfig, userIdHash: string, callerEntIdSet: EntId[])
  {
    if(!config.filterEntId && !config.allowPersonalChat)
    {
      this.filtered = true;
      return;
    }

    Promise.resolve(store.dispatch(clearHomeFilter(userIdHash)))
    .then(() =>
    {
      if(config.filterEntId && callerEntIdSet?.includes(config.filterEntId))
      {
        this.setFilter(config.filterEntId, userIdHash);
      }
      if(config.allowPersonalChat)
      {
        this.setFilter(myWorldItemId, userIdHash);
      }
      this.filtered = true;
    });
  }

  private setFilter(entId: EntId, userIdHash: string)
  {
    const action = {
      home: true,
      callerIdHash: userIdHash,
      entId: entId,
      pickValue: true
    };

    store.dispatch(updateHomeFilter(action));
  }
}
