import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import {IconButton} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";

export default function AudioBarPlayButton(props: {
  isPlaying: boolean,
  cbOnPause?: () => void,
  cbOnPlay?: () => void,
})
{
  const theme = useTheme();
  const isPlaying = props.isPlaying;
  const cbOnPlay = props.cbOnPlay;
  const cbOnPause = props.cbOnPause;

  return (
    <IconButton
      disableRipple={true}
      sx={{
        mr: px(theme.common.gapQuarter)
      }}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
      {
        e.stopPropagation();
        e.preventDefault();
        if(isPlaying)
        {
          cbOnPause && cbOnPause();
        }
        else
        {
          cbOnPlay && cbOnPlay();
        }
      }}
      type="button"
    >
      {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
    </IconButton>
  );
}
