import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarQuarter} from "../../../api/meta/base/dto/StudioVarQuarter";

export function fnVariableBuilderQuarter(fieldValueQuarter: string)
{
  function getVarComp()
  {
    return {
      [fieldValueQuarter]: {
        type: "enumQuarter",
        metaId: fieldValueQuarter,
        name: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarQuarter
  )
  {
    valueMap[fieldValueQuarter] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueQuarter];

    return {
      value: value
    } as StudioVarQuarter;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
