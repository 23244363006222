import ISrvc from "../../../base/ISrvc";
import SrvcHomeDrawerAddressBook from "./SrvcHomeDrawerAddressBook";
import SrvcHomeDrawerFilter from "./SrvcHomeDrawerFilter";
import {SrvcHomeDrawerNewChat} from "./SrvcHomeDrawerNewChat";
import {SrvcHomeDrawerNewGroup} from "./SrvcHomeDrawerNewGroup";
import SrvcHomeDrawerRecentList from "./SrvcHomeDrawerRecentList";
import SrvcHomeDrawerSearch from "./SrvcHomeDrawerSearch";
import SrvcHomeDrawerSettings from "./SrvcHomeDrawerSettings";
import SrvcHomeDrawerStarMessages from "./SrvcHomeDrawerStarMessages";
import SrvcHomeDrawerSubscriptions from "./SrvcHomeDrawerSubscriptions";

export class SrvcHomeDrawer extends ISrvc
{
  public readonly settings = new SrvcHomeDrawerSettings();
  public readonly addressBook = new SrvcHomeDrawerAddressBook(state => state.home.drawer.homeAddressBook);
  public readonly newChat = new SrvcHomeDrawerNewChat();
  public readonly newGroup = new SrvcHomeDrawerNewGroup();
  public readonly subscriptions = new SrvcHomeDrawerSubscriptions();
  public readonly recentList = new SrvcHomeDrawerRecentList(state => state.home.drawer.homeRecentList);
  public readonly search = new SrvcHomeDrawerSearch();
  public readonly starMessages = new SrvcHomeDrawerStarMessages();
  public readonly filter = new SrvcHomeDrawerFilter();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.settings,
      this.addressBook,
      this.newChat,
      this.newGroup,
      this.subscriptions,
      this.recentList,
      this.search,
      this.starMessages,
      this.filter
    );
  }
}


