import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldInfo} from "../../../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSetOfText} from "../../../../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickImportPluginApiId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginApiId";
import {DefnStudioPickImportPluginId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldSetOfText} from "../../../../../api/meta/base/dto/StudioFieldSetOfText";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {PluginApiId} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {optionsToMapOfOption} from "../../../../../base/plus/JsPlus";
import {IResolvedIOFormNames} from "../../../../../base/plus/StudioPlus";
import {IExcludeMappingVars} from "../../../../../base/plus/StudioPlus";
import {getFieldBuilderSourceSubTabs} from "../../base/FieldBuilderFactory";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyButtonPluginInputMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyPluginErrorField} from "../../base/TypesFormBuilder";
import {propKeyPickTextNote} from "../../base/TypesFormBuilder";
import {propKeyPickTextInfo} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeySourceVarId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyPluginApiId} from "../../base/TypesFormBuilder";
import {propKeyMetaIdPlugin} from "../../base/TypesFormBuilder";
import {propKeyShowAs} from "../../base/TypesFormBuilder";

export function getDefnFieldSetOfText(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  optionArray?: DefnDtoOption[],
  includeFieldIdSet?: MetaIdField[],
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{

  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    propKeyPickTextInfo,
    propKeyPickTextNote
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    propKeyPickTextInfo,
    propKeyPickTextNote
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    propKeyShowAs,
    propKeyPickTextInfo,
    propKeyPickTextNote
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "setOfText",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDefaultValue]: {
      type: "setOfText",
      name: propKeyDefaultValue,
      metaId: propKeyDefaultValue,
      optionMap: optionsToMapOfOption(optionArray),
      showAs: "dropdown",
      label: "Default value"
    } as DefnFieldSetOfText,

    [propKeyDefaultFieldId]: {
      type: "pickFieldId",
      metaId: propKeyDefaultFieldId,
      name: propKeyDefaultFieldId,
      label: "Default value",
      formId: formId,
      filterFieldTypeSet: ["setOfText"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      includeFieldIdSet: includeFieldIdSet
    } as DefnStudioPickFieldId,

    [propKeyShowAs]: {
      type: "enumThemePickMultiVariant",
      name: propKeyShowAs,
      metaId: propKeyShowAs
    } as DefnFieldPickEnum,
    [propKeyPickTextInfo]: {
      type: "info",
      metaId: propKeyPickTextInfo,
      name: "",
      flexGrow: true,
      label: ""
    } as DefnFieldInfo,
    [propKeyPickTextNote]: {
      type: "label",
      metaId: propKeyPickTextNote,
      textSizeVar: "caption",
      label: "Note: Will have layouts in future",
      disabled: true,
      italicVar: true
    } as DefnFieldLabel

  };
}

export function getDefnFieldPickSetOfTextSourceTab(
  formId: MetaIdForm,
  metaIdPlugin?: MetaIdPlugin,
  isPluginInputFormPresent?: boolean,
  helperTextPluginApiIOFormName?: IResolvedIOFormNames,
  isPluginApiHelperTextVisible?: boolean,
  excludePluginApiIdSet?: PluginApiId[],
  notValidPluginInputFormMappingVars?: IExcludeMappingVars,
  isPluginForm?: boolean)
{

  const variableFieldIdSet = [
    propKeySourceVarId
  ] as MetaIdField[];

  const pluginFieldIdSet: MetaIdField[] = !isPluginForm
    ? [
      propKeyMetaIdPlugin,
      propKeyPluginApiId,
      ...isPluginInputFormPresent ? [propKeyButtonPluginInputMappingVarId] : [],
      propKeyPluginErrorField
    ]
    : [];
  return {
    [propKeySourceVarId]: {
      type: "pickVarId",
      name: propKeySourceVarId,
      metaId: propKeySourceVarId,
      varKind: "setOfText",
      showAsEdit: true,
      formId: formId,
      label: "Default source variable",
      required: true
    } as DefnStudioPickVarId,

    ...!isPluginForm && {

      [propKeyMetaIdPlugin]: {
        type: "pickImportPluginId",
        name: propKeyMetaIdPlugin,
        metaId: propKeyMetaIdPlugin,
        label: "Plugin"
      } as DefnStudioPickImportPluginId,

      [propKeyPluginApiId]: {
        type: "pickImportPluginApiId",
        name: propKeyPluginApiId,
        metaId: propKeyPluginApiId,
        label: "Plugin API",
        metaIdPlugin: metaIdPlugin,
        filterApiMethodSet: ["get"],
        excludePluginApiIdSet: excludePluginApiIdSet,
        helperTextVar: isPluginApiHelperTextVisible
          ? stringToDefnDtoText(`Input form: ${helperTextPluginApiIOFormName?.inputFormName
          ?? "None"}, Output form: ${helperTextPluginApiIOFormName?.outputFormName ?? "None"}`)
          : undefined
      } as DefnStudioPickImportPluginApiId,

      ...isPluginInputFormPresent && {
        [propKeyButtonPluginInputMappingVarId]: {
          type: "pickVarId",
          metaId: propKeyButtonPluginInputMappingVarId,
          name: propKeyButtonPluginInputMappingVarId,
          label: "Plugin input mapping variable",
          disabled: !notValidPluginInputFormMappingVars?.src || !notValidPluginInputFormMappingVars?.target,
          excludeVarIdSet: notValidPluginInputFormMappingVars?.excludeIdSet,
          varKind: "mapping",
          showAsEdit: true
        } as DefnStudioPickVarId
      },

      [propKeyPluginErrorField]: {
        type: "pickFieldId",
        name: propKeyPluginErrorField,
        metaId: propKeyPluginErrorField,
        label: "Plugin error field",
        filterFieldTypeSet: ["error"],
        showCompositeName: true,
        formId: formId
      } as DefnStudioPickFieldId

    },
    ...getFieldBuilderSourceSubTabs(undefined, variableFieldIdSet, pluginFieldIdSet)
  };
}

export function defnValueToStudioFieldPickSetOfText(values: FieldValues): StudioFieldSetOfText
{
  return {
    type: "setOfText",
    defaultValue: fnFieldValueToRawValue("setOfText", values[propKeyDefaultValue]),
    defaultValueFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    sourceVarId: fnFieldValueToRawValue("pickVarId", values[propKeySourceVarId]),
    pluginApi: (values[propKeyMetaIdPlugin])
      ? {
        metaIdPlugin: fnFieldValueToRawValue("pickImportPluginId", values[propKeyMetaIdPlugin]),
        pluginApiId: fnFieldValueToRawValue("pickImportPluginApiId", values[propKeyPluginApiId])
      }
      : undefined,
    showAs: fnFieldValueToRawValue("pickVarId", values[propKeyShowAs]),
    pluginErrorFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyPluginErrorField]),
    pluginInputMappingVarId: fnFieldValueToRawValue("pickVarId", values[propKeyButtonPluginInputMappingVarId])
  } as StudioFieldSetOfText;
}

export function studioFieldToDefnPickSetOfText(studioField: StudioFieldSetOfText): FieldValues
{
  return {
    [propKeyMetaIdPlugin]: fnRawValueToFieldValue("pickImportPluginId", studioField.pluginApi?.metaIdPlugin),
    [propKeyPluginApiId]: fnRawValueToFieldValue("pickImportPluginApiId", studioField.pluginApi?.pluginApiId),
    [propKeyDefaultValue]: fnRawValueToFieldValue("setOfText", studioField.defaultValue),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultValueFieldId),
    [propKeySourceVarId]: fnRawValueToFieldValue("pickVarId", studioField.sourceVarId),
    [propKeyShowAs]: fnRawValueToFieldValue("pickVarId", studioField.showAs),
    [propKeyPluginErrorField]: fnRawValueToFieldValue("pickFieldId", studioField.pluginErrorFieldId),
    [propKeyButtonPluginInputMappingVarId]: fnRawValueToFieldValue("pickVarId", studioField.pluginInputMappingVarId)
  };
}
