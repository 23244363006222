import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {MsgMessageForwardCandidateList} from "../../../api/home/main/msg/MsgMessageForwardCandidateList";
import {RpcMain} from "../../../api/home/main/RpcMain";
import {isGlobal} from "../../../api/meta/base/ApiPlus";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {STR_YOU} from "../../../base/plus/ConstantsPlus";
import {STR_ADMIN} from "../../../base/plus/ConstantsPlus";
import {createListItemGroup} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../base/plus/MediaPlus";
import {textUser} from "../../../base/plus/SrvcPlus";
import {getLabel} from "../../../base/plus/StringPlus";
import {random} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPickType} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {toComboId} from "../../../base/types/TypesComboId";
import {TypeUserFieldSendGroupCandidate} from "../../../base/types/TypesHome";
import {TypeUserFieldSendUserCandidate} from "../../../base/types/TypesHome";
import {isYou} from "../../../Store";
import {RootState} from "../../../Store";
import {store} from "../../../Store";

export default class SrvcHomeUserAndGroupSelection extends ISrvc
{
  userAndGroupSelection(entId: EntId, listName: string, spreadsheetId?: MetaIdSpreadsheet)
  {
    const msg: MsgMessageForwardCandidateList = {
      spreadsheetId: spreadsheetId
    };

    RpcMain.messageForwardCandidateListGet(entId, msg, envSig =>
    {
      if(envSig.sig)
      {
        const sig = envSig.sig;
        const candidateMap = sig.candidateMap;
        const uiItemsById = {} as IListItemsById;
        const uiGroupsById = {} as IListGroupsById;
        dispatchList(listName, listSetPickType("pickMany"));

        const rootState = store.getState();
        Object.keys(candidateMap).forEach((prefix) =>
        {
          const itemIds = [] as TypeListItemId[];
          candidateMap[prefix].forEach((candidate) =>
          {
            if(candidate.userAvatar)
            {
              const userAvatar = candidate.userAvatar;
              const itemId = toComboId(userAvatar.entId, userAvatar.entUserId);

              uiItemsById[itemId] = this.createUserAvatar(rootState, userAvatar);
              itemIds.push(itemId);

            }
            if(candidate.groupAvatar)
            {
              const groupAvatar = candidate.groupAvatar;
              const itemId = toComboId(groupAvatar.entId, groupAvatar.groupId);
              uiItemsById[itemId] = this.createGroupAvatar(rootState, groupAvatar);
              itemIds.push(itemId);
            }
          });

          uiItemsById[prefix] = createListItemGroup(prefix);
          uiGroupsById[prefix] = {
            itemIds: itemIds
          };
        });

        dispatchList(listName, listRefresh({
          itemsById: uiItemsById,
          groupsById: uiGroupsById,
          version: random(16)
        }));
      }
    });
  }

  public createUserAvatar(rootState: RootState, sig: SigUserAvatar)
  {
    const entId = sig.entId as EntId;
    const entAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];

    const handle = sig.handle;
    const newItem = {
      type: "aps",
      hideMenu: true,
      avatarLeft: {
        src: isGlobal(entId)
          ? getImageThumbnail(sig.avatarId)
          : entAvatar ? getImageThumbnail(entAvatar.avatarId) : undefined,
        icon: "user"
      },
      primary: {
        text: textUser(sig)
      },
      secondary: {
        text: handle ? handle : "No handle",
        color: handle ? "textSecondary" : "textDisabled"
      },
      userField: {
        user: sig
      } as TypeUserFieldSendUserCandidate
    } as IListItemAPSA;

    if(isYou(rootState, sig) && newItem.primary)
    {
      newItem.primary.caption = {
        text: STR_YOU
      };
    }
    return newItem;
  }

  public createGroupAvatar(rootState: RootState, sig: SigGroupAvatar)
  {
    const entId = sig.entId;
    const entAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];

    const newItem = {
      type: "aps",
      hideMenu: true,
      avatarLeft: {
        src: isGlobal(entId)
          ? getImageThumbnail(sig.avatarId)
          : entAvatar ? getImageThumbnail(entAvatar.avatarId) : undefined,
        icon: "group"
      },
      primary: {
        text: getLabel(sig)
      },
      secondary: {
        text: sig.about
      },
      userField: {
        group: sig
      } as TypeUserFieldSendGroupCandidate
    } as IListItemAPSA;

    if(sig.isAdmin && newItem.primary)
    {
      newItem.primary.caption = {
        text: STR_ADMIN,
        color: "successLight"
      };
    }

    return newItem;
  }
}
