import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarButtonVariant} from "../../../api/meta/base/dto/StudioVarButtonVariant";
import {MetaIdField} from "../../../api/meta/base/Types";

export function fnVariableBuilderButtonVariant(fieldValueButtonVariant: string)
{
  function getVarComp(): Record<MetaIdField, DefnField>
  {
    return {
      [fieldValueButtonVariant]: {
        type: "enumThemeButtonVariant",
        metaId: fieldValueButtonVariant,
        label: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarButtonVariant
  )
  {
    valueMap[fieldValueButtonVariant] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueButtonVariant];

    return {
      value: value
    } as StudioVarButtonVariant;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
