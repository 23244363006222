import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdLayoutDriveSheet} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfLayoutDriveSheet} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutDriveSheet";
import {StudioDtoLayoutDriveSheet} from "../../../../api/meta/base/dto/StudioDtoLayoutDriveSheet";
import {StudioMapOfLayoutDriveSheet} from "../../../../api/meta/base/dto/StudioMapOfLayoutDriveSheet";
import {MetaIdLayoutDriveSheet} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdDriveSheet} from "../../../../api/meta/base/Types";
import {MetaIdFuncArg} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {IFormFieldError} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {IDtoEntCopy} from "../../../../base/types/TypesStudio";
import DialogPaste from "../../../atom/dialog/DialogPaste";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewDriveSheetLayout from "../../../dialog/DialogNewDriveSheetLayout";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {fieldListItemVal} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfLayoutDriveSpreadsheet(props: {
  defn: DefnStudioMapOfLayoutDriveSheet,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();
  const defnTheme = formCtx.getDefnTheme();

  const defnFieldBuilderBrowserLayout = props.defn;
  const fieldId = getFieldKey(defnFieldBuilderBrowserLayout);
  const driveSheetId = defnFieldBuilderBrowserLayout.driveSheetId;
  const driveSheet = driveSheetId
    ? formStore?.driveSheetMap?.map[driveSheetId]
    : undefined;
  const spreadsheetId = driveSheet?.spreadsheetId;
  const formId = spreadsheetId
    ? formStore?.spreadsheetMap?.map[spreadsheetId]?.formId
    : undefined;

  const isLastField = false;
  const label = defnFieldBuilderBrowserLayout.label;

  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={defnFieldBuilderBrowserLayout.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfLayoutDriveSheet;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfDriveSheetLayout
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  onChange={onChange}
                  isLastField={isLastField}
                  formStore={formStore}
                  isFormReadOnly={formReadOnly}
                  driveSheetId={driveSheetId}
                  formId={formId}
                />
              }
            />
          );
        }

        return (
          <RawSetOfDriveSheetLayout
            fieldId={fieldId}
            fieldValue={fieldValue}
            onChange={onChange}
            isLastField={isLastField}
            formStore={formStore}
            isFormReadOnly={formReadOnly}
            driveSheetId={driveSheetId}
            formId={formId}
          />
        );
      }
      }
    />
  );
}

function RawSetOfDriveSheetLayout(props: {
  fieldId: MetaIdField,
  fieldValue: StudioMapOfLayoutDriveSheet,
  driveSheetId?: MetaIdDriveSheet
  formId?: MetaIdForm,
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  isLastField?: boolean,
  onChange: (value: StudioMapOfLayoutDriveSheet) => void,
})
{
  const {
    formStore,
    fieldId,
    isLastField,
    isFormReadOnly,
    driveSheetId,
    formId
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = useMemo(() =>
  {
    return {} as IFieldRawListRef<StudioDtoLayoutDriveSheet>;
  }, []);
  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);

  const onChange = (fieldValue: StudioDtoLayoutDriveSheet[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = useCallback((selectList: SelectList, fieldValue: StudioDtoLayoutDriveSheet[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      const formFieldName = formStore
        ? fnUseStudioResolver(formStore).getFormFieldName
        : undefined;

      const formCompName = formStore
        ? fnUseStudioResolver(formStore).getCompName
        : undefined;

      fieldValue.forEach((value: StudioDtoLayoutDriveSheet) =>
      {
        let primaryName = "";
        const sectionId = value.compositeId;
        const fieldId = value?.fieldId;
        const layoutFor = toLabel(value?.layoutFor);

        if(formFieldName && formId && fieldId)
        {
          const fieldName = formFieldName(formId, fieldId);
          if(fieldName)
          {
            primaryName = fieldName;
          }
        }
        else if(formCompName && formId && sectionId)
        {
          const compName = formCompName(formId, sectionId);
          if(compName)
          {
            primaryName = compName;
          }
        }

        const listId = value.metaId;
        uiItemIds.push(listId);
        uiItemsById[listId] = {
          type: "mpsl",
          primary: {
            text: primaryName || layoutFor,
            caption: {
              text: layoutFor,
              ignoreSelection: true
            }
          },
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemMPSL;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  }, [formId]);

  const addItemToList = useCallback((values: StudioDtoLayoutDriveSheet, metaId?: MetaIdLayoutDriveSheet) =>
  {
    cbRefList.addItem({
      ...values,
      metaId: metaId ? metaId : nextMetaIdLayoutDriveSheet()
    });
  }, [cbRefList]);

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add" && formStore)
    {
      pageCtx.showDialog(
        <DialogNewDriveSheetLayout
          formStore={formStore}
          driveSheetId={driveSheetId}
          onClickOk={(newValue) =>
          {
            addItemToList(newValue);
          }}
          isFormReadOnly={isFormReadOnly}
        />
      );
    }
  };

  const editDriveSheetLayout = (oldValue: StudioDtoLayoutDriveSheet, key: MetaIdLayoutDriveSheet) =>
  {
    pageCtx.showDialog(
      <DialogNewDriveSheetLayout
        formStore={formStore}
        values={oldValue}
        driveSheetId={driveSheetId}
        onClickOk={(newValue) =>
        {
          const newLayoutValue: StudioDtoLayoutDriveSheet = {
            ...newValue,
            metaId: key
          };
          cbRefList.updateItem(newLayoutValue, key);
        }}
        isFormReadOnly={isFormReadOnly}
      />
    );
  };

  const duplicateDriveSheetLayout = (oldValue: StudioDtoLayoutDriveSheet) =>
  {
    addItemToList(oldValue);
  };

  const copyDriveSheetLayout = (oldValue: StudioDtoLayoutDriveSheet) =>
  {
    const payloadCopy: IDtoEntCopy = {
      type: "driveSheetLayout",
      payload: oldValue
    };
    copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
  };

  const pasteDriveSheetLayout = () =>
  {
    pageCtx.showDialog(
      <DialogPaste
        title={"Paste drive sheet layout"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={(stringifyDriveSheet) =>
        {
          const driveSheetPayload = JSON.parse(stringifyDriveSheet) as IDtoEntCopy;
          if(driveSheetPayload.type === "driveSheetLayout")
          {
            const driveSheetLayout = driveSheetPayload.payload as StudioDtoLayoutDriveSheet;
            if(!!driveSheetLayout.layoutFor)
            {
              addItemToList(driveSheetLayout);
            }
          }
        }}
      />
    );
  };

  const onClickList = useCallback((
    key: MetaIdField,
    action: FormClickVariant,
    value?: any,
    menuAnchor?: Element,
    menuProps?: IMenuProps,
    isFirstItem?: boolean,
    isLastItem?: boolean,
    validationError?: IFormFieldError[]
  ) =>
  {
    const oldValue = value?.userField
      ? value.userField[fieldListItemVal] as StudioDtoLayoutDriveSheet
      : undefined;

    if(action === "spotMenu" && menuProps)
    {
      if(oldValue)
      {
        menuProps = {
          "Edit": {
            onClick: () => editDriveSheetLayout(oldValue, key)
          },
          "Remove": menuProps["Remove"],
          "Duplicate": {
            onClick: () => duplicateDriveSheetLayout(oldValue)
          },
          "Copy": {
            onClick: () => copyDriveSheetLayout(oldValue)
          },
          "Paste": {
            onClick: pasteDriveSheetLayout
          }
        } as IMenuProps;
      }

      menuAnchor && pageCtx.showMenu(menuAnchor, menuProps);
    }
    else if(action === "listItem" && formStore)
    {
      if(oldValue)
      {
        editDriveSheetLayout(oldValue, key);
      }
    }
  }, [formStore]);

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(map?: StudioMapOfLayoutDriveSheet): StudioDtoLayoutDriveSheet[]
{
  if(!map?.keys || !map?.map)
  {
    return [];
  }
  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoLayoutDriveSheet[]): StudioMapOfLayoutDriveSheet
{
  const keys = [] as MetaIdFuncArg[];
  const map = {} as Record<MetaIdFuncArg, StudioDtoLayoutDriveSheet>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfLayoutDriveSheet;
}
