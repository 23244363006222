import {GroupId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {selectCallerEnt} from "../../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {store} from "../../../../Store";
import {Srvc} from "../../../Srvc";

export default class SrvcHomeMainHeader extends ISrvc
{
  hideHeaderIcon(entId: EntId, groupId: GroupId, cbResult: (isHideIcon: boolean) => void)
  {
    const rootState = store.getState();
    const canSendMsg = Srvc.home.app.footer.inputBar.canShowInputBar(entId, groupId);
    const callerEnt = selectCallerEnt(rootState, entId);
    const groupAssignActionPermission = callerEnt?.groupActionPermissionMap[groupId];
    const defaultAction = groupAssignActionPermission?.defaultActionId;
    const onlyDefaultAction = groupAssignActionPermission?.keys.length === 1;

    cbResult(!!(!canSendMsg && defaultAction && onlyDefaultAction));
  }

}
