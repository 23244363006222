import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickImportPluginApiId} from "../../../../api/meta/base/dto/DefnStudioPickImportPluginApiId";
import {StudioEntPlugin} from "../../../../api/meta/base/dto/StudioEntPlugin";
import {StudioEntPluginMap} from "../../../../api/meta/base/dto/StudioEntPluginMap";
import {EnumDefnPluginApiMethod} from "../../../../api/meta/base/Types";
import {PluginApiId} from "../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioPickImportPluginApiId(props: {
  defn: DefnStudioPickImportPluginApiId
})
{
  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const metaIdPlugin = defn.metaIdPlugin;
  const excludePluginApiId = defn.excludePluginApiIdSet;
  const filterApiTypeSet = defn.filterApiMethodSet;

  const importMap = formStore?.pluginMap;
  const options = getOptions(metaIdPlugin, importMap, excludePluginApiId, filterApiTypeSet);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={optionsToMapOfOption(options)}
      />}
    />
  );
}

function getOptions(
  metaIdPlugin: MetaIdPlugin,
  importMap?: StudioEntPluginMap,
  excludePluginApiId?: PluginApiId[],
  filterApiTypeSet?: EnumDefnPluginApiMethod[]
)
{
  const options = [] as DefnDtoOption[];

  if(importMap)
  {
    const keys = importMap.keys;
    const map = importMap.map;

    keys && map && keys.forEach(metaId =>
    {
      const importItem = map[metaId];

      if(importItem.kind === "plugin")
      {
        const importItemPlugin = importItem as StudioEntPlugin;

        if(importItemPlugin.metaId === metaIdPlugin)
        {
          const apiMap = importItemPlugin.pluginApiIdMap;
          if(apiMap)
          {
            Object.values(apiMap).forEach(api =>
            {
              if(api.pluginApiId && api.name)
              {
                if(excludePluginApiId && excludePluginApiId.includes(api.pluginApiId))
                {
                  return;
                }
                if(filterApiTypeSet && !filterApiTypeSet.includes(api.apiType))
                {
                  return;
                }

                options.push({
                  metaId: api.pluginApiId,
                  value: api.name
                });
              }
            });
          }
        }

      }
    });
  }

  return options;
}
