// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ISig} from "../../meta/base/sig/ISig";
import {EntUserId} from "../../meta/base/Types";
import {EntId, MediaId, ServiceName} from "../../meta/base/Types";
import {ISigAcceptor} from "./ISigAcceptor";

export interface IMediaCall
{
  upload(blob: Blob, sigAcceptor: ISigAcceptor<ISig>, fileName?: string, entUserId?: EntUserId): IMediaCall;
}

export interface IMediaCallFactory
{
  create(entId: EntId, serviceName: ServiceName, mediaId: MediaId): IMediaCall;
}

let cbMediaCallFactory: IMediaCallFactory;

export function mediaCall(entId: EntId, serviceName: ServiceName, mediaId: MediaId)
{
  return cbMediaCallFactory.create(entId, serviceName, mediaId);
}

export function setMediaCallFactory(mediaCallFactory: IMediaCallFactory)
{
  cbMediaCallFactory = mediaCallFactory;
}