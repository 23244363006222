import {FormControl} from "@mui/material";
import React from "react";
import Barcode from "react-barcode";
import {Controller} from "react-hook-form";
import QRCode from "react-qr-code";
import {DefnFieldShowCode} from "../../../../api/meta/base/dto/DefnFieldShowCode";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {fieldReportLeftLabel} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldBase from "../raw/FieldBase";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";

const COLOR_BG = "#ffffff";
const COLOR_FG = "#000000";
const SIZE_QR = 100;
const BARCODE_FORMAT = "CODE128";
const BARCODE_CHARACTER_LIMIT = 22;

export default function FieldShowCode(props: {
  defn: DefnFieldShowCode
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const defnTheme = formCtx.getDefnTheme();
  const control = formCtx.control();
  const codeType = defn.codeType;
  const isQrcode = codeType === "qrCode";
  const fieldId = getFieldKey(defn);
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isSectionVariant = sectionVariant === "propertyEditor";
  const fieldVariant = defnTheme.fieldVariant;
  const isReport = defnTheme.formVariant === "report";
  const showLabel = defn.showLabel;
  const isBarCodeVisible = defnDtoTextToString(defn.defaultVar).length <= BARCODE_CHARACTER_LIMIT;
  const padding = getDefnFieldPadding(defn);

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({
        field
      }) =>
      {
        const value = field.value as FieldValueText;
        if(isReport && showLabel)
        {
          return (
            <FieldBase fieldId={fieldId} {...padding}>
              <FieldRawKeyValuePair
                left={
                  <RawHighlighter
                    variant={"caption"}
                    value={fieldReportLeftLabel(defn)}
                  />
                }
                right={value &&
                  <RawShowCode
                    isQrcode={isQrcode}
                    value={value.value}
                    isBarCodeVisible={isBarCodeVisible}
                  />
                }
              />
            </FieldBase>
          );
        }
        else if(isSectionVariant)
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;

          return (
            <FieldBase fieldId={fieldId} {...padding}>
              <FieldRawKeyValuePair
                leftHeight={labelHeight}
                left={<FieldLabel defn={defn} />}
                right={
                  <FormControl
                    fullWidth
                    variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
                  >
                    {value &&
                      <RawShowCode
                        isQrcode={isQrcode}
                        value={value.value}
                        isBarCodeVisible={isBarCodeVisible}
                      />
                    }
                  </FormControl>
                }
              />
            </FieldBase>
          );
        }
        else
        {
          return (
            <FieldBase fieldId={fieldId} {...padding}>
              {(!isReport && showLabel) && <FieldLabel defn={defn} />}
              <FormControl
                fullWidth
                style={{
                  objectFit: "contain",
                  overflow: "hidden"
                }}
              >
                {value &&
                  <RawShowCode
                    isQrcode={isQrcode}
                    value={value.value}
                    isBarCodeVisible={isBarCodeVisible}
                  />
                }
              </FormControl>
            </FieldBase>
          );
        }
      }}
    />
  );
}

function RawShowCode(props: {
  value: string,
  isQrcode?: boolean,
  isBarCodeVisible: boolean,
})
{
  const isQrcode = props.isQrcode;
  const value = props.value;
  const isBarCodeVisible = props.isBarCodeVisible;

  return (
    <LayoutFlexCol
      maxWidth={"200px"}
      alignItems={"flex-start"}
      overflowX={"visible"}
      overflowY={"visible"}
    >
      {
        isQrcode
          ? <QRCode
            value={value}
            size={SIZE_QR}
            bgColor={COLOR_BG}
            fgColor={COLOR_FG}
            level={"H"}
            style={{margin: 0}}
          />
          : isBarCodeVisible
            ? <Barcode
              format={BARCODE_FORMAT}
              width={1}
              height={40}
              value={value}
              displayValue={false}
              margin={0}
            />
            : null
      }
    </LayoutFlexCol>
  );
}
