import {StoreItemId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {DtoStoreAdmin} from "../../../api/store/base/dto/DtoStoreAdmin";
import {MsgStoreAdminAdd} from "../../../api/store/store/msg/MsgStoreAdminAdd";
import {MsgStoreItemId} from "../../../api/store/store/msg/MsgStoreItemId";
import {RpcStore} from "../../../api/store/store/RpcStore";
import {MsgStoreAdminUpdate} from "../../../api/store/studioMain/msg/MsgStoreAdminUpdate";
import {MsgAdminId} from "../../../api/studio/studioMain/msg/MsgAdminId";
import {MsgAdminInvite} from "../../../api/studio/studioMain/msg/MsgAdminInvite";
import ISrvc from "../../../base/ISrvc";
import {STR_INVITE_SUBMIT_SUCCESSFULLY} from "../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStoreItemAdmins extends ISrvc
{

  selectStoreItemAdminList(state: RootState)
  {
    return state.store.storeItem.storeItemAdminList;
  }

  rpcStoreItemAdminInvite(storeItemId: StoreItemId, msg: MsgAdminInvite)
  {
    RpcStore.storeAdminInvite(storeItemId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        Srvc.app.toast.showSuccessToast(STR_INVITE_SUBMIT_SUCCESSFULLY);
      }
    });
  }

  rpcStoreItemAdminListGet(storeItemId: StoreItemId)
  {
    const msg = {
      storeItemId: storeItemId
    } as MsgStoreItemId;

    RpcStore.storeAdminListGet(msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;

      if(sig)
      {
        Srvc.cache.store.storeItem.setStoreItemAdminList(storeItemId, sig);
      }
    });
  }

  rpcStoreItemAdminAdd(storeItemId: StoreItemId, msg: MsgStoreAdminAdd, cbSuccess?: CbSuccess)
  {
    RpcStore.storeAdminAdd(storeItemId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      else
      {
        Srvc.app.toast.showSuccessToast("Admin added successfully");
      }

      cbSuccess && cbSuccess();
    });
  }

  rpcStoreItemAdminUpdate(entId: EntId, msg: MsgStoreAdminUpdate, cbSuccess?: CbSuccess)
  {
    RpcStore.storeAdminUpdate(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }

      cbSuccess && cbSuccess();
    });
  }

  rpcStoreItemAdminRemove(storeItemId: StoreItemId, msg: MsgAdminId)
  {
    RpcStore.storeAdminRemove(storeItemId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
    });
  }

  loadAdminList(listName: string, entAdminList?: DtoStoreAdmin[], isCallerAdmin?: boolean)
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    entAdminList?.forEach(admin =>
    {
      const itemId = admin.adminId;
      uiItemIds.push(itemId);

      uiItemsById[itemId] = {
        type: "ps",
        userField: {
          value: admin
        },
        primary: {
          text: admin.nickName,
          caption: {
            text: admin.handle,
            ignoreSelection: true
          }
        },
        secondary: {
          text: "No roles",
          color: "textDisabled",
          caption: {
            text: (admin.isMember || admin.isCurrentUser) ? undefined : "INVITE",
            color: "successLight"
          }
        },
        hideMenu: !isCallerAdmin
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  resetStoreItemAdminBook(storeItemId: StoreItemId)
  {
    Srvc.cache.store.storeItem.removeStoreItemAdminList(storeItemId);
  }
}
