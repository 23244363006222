import {isArray} from "lodash";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldChipSetDay} from "../../../../api/meta/base/dto/DefnFieldChipSetDay";
import {FieldChipSetDay} from "../../../../api/meta/base/dto/FieldChipSetDay";
import {EnumArrayDefnDay} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldDaySet(props: {
  defn: DefnFieldChipSetDay
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const fieldValue = field.value as FieldChipSetDay | undefined;
        const isReport = formCtx.getDefnTheme().formVariant === "report";

        const onChange = (values: string[]) =>
        {
          if(values && isArray(values))
          {
            field.onChange({
              valueSet: values
            } as FieldChipSetDay);
          }
        };

        return (
          <FieldRawStudioPick
            fieldProps={{
              field: {
                ...field,
                value: isReport ? fieldValue : fieldValue?.valueSet,
                onChange: onChange
              },
              fieldState: fieldState
            }}
            defn={props.defn}
            optionMap={arrayToMapOfOption(EnumArrayDefnDay)}
            multiSelect={true}
            showChip={true}
          />
        );
      }}
    />
  );
}
