// noinspection DuplicatedCode

import {useCallback} from "react";
import React from "react";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {IListItem} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {useAppSelector} from "../../app/AppHooks";
import {BoxShell} from "../../atom/box/BoxShell";
import {CbOnClickListItem} from "../List";

export default function ListItemShell(props: {
  itemHeight?: number,
  fullHeight?: boolean,
  itemId: TypeListItemId,
  selectList: SelectList,
  pl?: number,
  pr?: number,
  onClickListItem?: CbOnClickListItem,
  children: React.ReactNode,
})
{
  const itemId = props.itemId;
  const item = useAppSelector(state => props.selectList(state).itemsById[itemId]);
  return (
    <RealListItemShell item={item} {...props}>
      {props.children}
    </RealListItemShell>
  );
}

export function RealListItemShell(props: {
  itemHeight?: number,
  fullHeight?: boolean,
  itemId: TypeListItemId,
  item: IListItem,
  selectList: SelectList,
  pl?: number,
  pr?: number,
  onClickListItem?: CbOnClickListItem,
  children: React.ReactNode,
})
{
  const itemId = props.itemId;
  const item = props.item;
  const pl = props.pl;
  const pr = props.pr;

  const listIgnoreSelection = useAppSelector(state => props.selectList(state).ignoreSelection);
  const listName = useAppSelector(state => props.selectList(state).listName);
  const pickType = useAppSelector(state => props.selectList(state).pickType);
  const selectedItemId = useAppSelector(state => props.selectList(state).selectedItemId);
  const listLayoutType = useAppSelector(state => props.selectList(state).listLayoutType);
  const pickValue = useAppSelector(state =>
  {
    const pickItemIds = props.selectList(state).pickItemIds;
    return pickItemIds === undefined ? undefined : pickItemIds[itemId];
  });
  const canShowMenu = useAppSelector(state => props.selectList(state).canShowMenu);
  const displayLastItemId = useAppSelector(state => props.selectList(state).displayLastItemId);

  const onClickListItem = props.onClickListItem;
  const itemExists = item !== undefined;
  const ignoreSelection = listIgnoreSelection ? true : (item ? item.ignoreSelection : false);
  const bgcolor = item?.bgColor;
  const hideMenu = !canShowMenu || item?.hideMenu || !itemExists;
  const tooltip = item?.tooltip;

  const cbOnClick = useCallback((menuAnchor: Element, pickValue?: boolean) =>
  {
    if(ignoreSelection)
    {
      return;
    }

    const _selectedItemId = selectedItemId === itemId ? undefined : itemId;
    if(pickType)
    {
      dispatchList(listName,
        listSetPick({
          itemId: itemId,
          pickValue: Boolean(pickValue)
        })
      );
    }
    else
    {
      dispatchList(listName, listSetSelectedItemId(_selectedItemId));
    }

    onClickListItem && onClickListItem(menuAnchor, itemId, item, "listItem", pickValue, Boolean(_selectedItemId));
  }, [pickType, onClickListItem, itemId, selectedItemId, listName, ignoreSelection, item]);

  const cbOnClickMenu = useCallback((menuAnchor: Element) =>
  {
    onClickListItem && onClickListItem(menuAnchor, itemId, item, "spotMenu", pickValue, selectedItemId === itemId);
  }, [onClickListItem, itemId, item, pickValue, selectedItemId]);

  const cbOnClickInfoSpot = useCallback((menuAnchor: Element) =>
  {
    onClickListItem && onClickListItem(menuAnchor, itemId, item, "spotInfo", pickValue, selectedItemId === itemId);
  }, [onClickListItem, itemId, item, pickValue, selectedItemId]);

  return (
    <BoxShell
      height={props.itemHeight}
      fullHeight={props.fullHeight}
      bgcolor={bgcolor}
      pickType={pickType}
      pickValue={itemExists ? Boolean(pickValue) : undefined}
      showDivider={displayLastItemId !== itemId && listLayoutType !== "card"}
      ignoreSelection={ignoreSelection}
      tooltip={tooltip}
      hideMenu={hideMenu}
      selected={selectedItemId === itemId}
      infoSpot={item?.infoSpot}
      onClick={cbOnClick}
      onClickMenu={!hideMenu && onClickListItem ? cbOnClickMenu : undefined}
      onClickInfoSpot={onClickListItem ? cbOnClickInfoSpot : undefined}
      cursorPointer={!ignoreSelection}
      pl={pl}
      pr={pr}
      layoutType={listLayoutType}
    >
      {props.children}
    </BoxShell>
  );
}
