import React, {useMemo} from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdLayoutGrid} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfLayoutGrid} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutGrid";
import {StudioDtoLayoutGrid} from "../../../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioDtoLayoutXYChart} from "../../../../api/meta/base/dto/StudioDtoLayoutXYChart";
import {StudioMapOfLayoutGrid} from "../../../../api/meta/base/dto/StudioMapOfLayoutGrid";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {
  EnumDefnLayoutGridKind,
  MetaIdField,
  MetaIdForm,
  MetaIdFuncArg,
  MetaIdLayoutGrid
} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {IListData, IListItemsById, TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IFormFieldError} from "../../../../base/types/TypesForm";
import {FormClickVariant, FormStore} from "../../../../base/types/TypesForm";
import {ILineSecondary} from "../../../../base/types/TypesGlobal";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewLayoutGrid from "../../../dialog/DialogNewLayoutGrid";
import DialogNewLayoutGridCalendar from "../../../dialog/DialogNewLayoutGridCalendar";
import DialogNewLayoutGridCard from "../../../dialog/DialogNewLayoutGridCard";
import DialogNewLayoutGridKanban from "../../../dialog/DialogNewLayoutGridKanban";
import DialogNewLayoutGridList from "../../../dialog/DialogNewLayoutGridList";
import DialogNewLayoutGridTable from "../../../dialog/DialogNewLayoutGridTable";
import DialogNewLayoutMap from "../../../dialog/DialogNewLayoutMap";
import DialogNewLayoutXYChart from "../../../dialog/DialogNewLayoutXYChart";
import {getLayoutLabel} from "../../builder/base/FieldBuilderPlus";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import FieldRawList, {fieldListItemVal, IFieldRawListRef} from "../raw/FieldRawList";

export default function FieldStudioMapOfLayoutGrid(props: {
  defn: DefnStudioMapOfLayoutGrid
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();

  const isLastField = false;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const label = defn.label;
  const formId = defn.formId;
  const gridId = defn.gridId;
  const isPluginForm = defn.isPluginForm;

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfLayoutGrid;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfGridLayout
                  fieldId={fieldId}
                  formStore={formStore}
                  formId={formId}
                  gridId={gridId}
                  onChange={onChange}
                  isLastField={isLastField}
                  isFormReadOnly={formReadOnly}
                  fieldValue={fieldValue}
                  isPluginForm={isPluginForm}
                />
              }
            />
          );
        }

        return (
          <RawSetOfGridLayout
            fieldId={fieldId}
            formStore={formStore}
            formId={formId}
            gridId={gridId}
            onChange={onChange}
            isLastField={isLastField}
            isFormReadOnly={formReadOnly}
            fieldValue={fieldValue}
            isPluginForm={isPluginForm}
          />
        );
      }
      }
    />
  );
}

function RawSetOfGridLayout(props: {
  fieldId: MetaIdField,
  formStore?: FormStore,
  formId: MetaIdForm,
  gridId: MetaIdGrid,
  isLastField?: boolean,
  isFormReadOnly?: boolean,
  fieldValue?: StudioMapOfLayoutGrid,
  isPluginForm?: boolean,
  onChange: (value: StudioMapOfLayoutGrid) => void
})
{
  const {
    fieldId,
    formStore,
    formId,
    gridId,
    isLastField,
    isFormReadOnly,
    isPluginForm
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<StudioDtoLayoutGrid>;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);

  const onChange = (fieldValue: StudioDtoLayoutGrid[]) => props.onChange(convertArrayToMap(fieldValue) as StudioMapOfLayoutGrid);

  const doLoad = (selectList: SelectList, fieldValue: StudioDtoLayoutGrid[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.metaId;
        uiItemIds.push(listId);

        uiItemsById[listId] = {
          type: "ps",
          primary: {
            text: value.name,
            caption: {
              type: "text",
              text: getLayoutLabel(value.kind),
              ignoreSelection: true
            }
          },
          secondary: {
            text: value.description || "No description"
          } as ILineSecondary,
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemAPSA;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add" && formStore)
    {
      pageCtx.showDialog(
        <DialogNewLayoutGrid
          isFormReadonly={isFormReadOnly}
          isPluginForm={isPluginForm}
          onClickOk={(gridLayout) =>
          {
            pageCtx.showDialog(
              <LayoutGridDialog
                gridLayout={gridLayout}
                gridId={gridId}
                formId={formId}
                formStore={formStore}
                cbRefList={cbRefList}
                isFormReadOnly={isFormReadOnly}
              />
            );
          }}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioDtoLayoutGrid
        : undefined;

      if(oldValue?.kind && formStore)
      {
        pageCtx.showDialog(
          <LayoutGridDialog
            gridId={gridId}
            formId={formId}
            formStore={formStore}
            gridLayout={oldValue.kind}
            value={oldValue}
            updateKey={key}
            cbRefList={cbRefList}
            validationError={validationError}
            isFormReadOnly={isFormReadOnly}
          />
        );
      }
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          labelMap={{
            "add": "Add..."
          } as Record<EnumIconButton, string>}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
          onClick={onClickIconBtn}
        />
      }
      doLoad={doLoad}
    />
  );
}

function LayoutGridDialog(props: {
  gridLayout: EnumDefnLayoutGridKind,
  formStore: FormStore,
  formId: MetaIdForm,
  gridId: MetaIdGrid,
  validationError?: IFormFieldError[]
  isFormReadOnly?: boolean,
  value?: StudioDtoLayoutGrid,
  updateKey?: MetaIdField,
  cbRefList: IFieldRawListRef<StudioDtoLayoutGrid>
})
{
  const formStore = props.formStore;
  const formId = props.formId;
  const gridId = props.gridId;
  const cbRefList = props.cbRefList;
  const oldValue = props.value;
  const key = props.updateKey;
  const isFormReadOnly = props.isFormReadOnly;

  switch(props.gridLayout)
  {
    case "card":
      return <DialogNewLayoutGridCard
        isFormReadOnly={isFormReadOnly}
        formStore={formStore}
        metaIdForm={formId}
        metaIdGrid={gridId}
        values={oldValue}
        validationError={props.validationError}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    case "list":
      return <DialogNewLayoutGridList
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={formId}
        metaIdGrid={gridId}
        values={oldValue}
        validationError={props.validationError}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    case "table":
      return <DialogNewLayoutGridTable
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={formId}
        metaIdGrid={gridId}
        values={oldValue}
        validationError={props.validationError}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            :
            cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    case "map":
      return <DialogNewLayoutMap
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={formId}
        metaIdGrid={gridId}
        values={oldValue}
        validationError={props.validationError}
        mapType={"grid"}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    case "xyChartBarGraph":
    case "xyChartLineChart":
    case "xyChartPieChart":
    case "xyChartDoughnut":
    case "xyChartScatterPlot":
      return <DialogNewLayoutXYChart
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        formId={formId}
        gridId={gridId}
        values={oldValue as StudioDtoLayoutXYChart}
        validationError={props.validationError}
        xyChartType={"grid"}
        defaultGridLayout={props.gridLayout}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            :
            cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    case "kanban":
      return (<DialogNewLayoutGridKanban
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={formId}
        metaIdGrid={gridId}
        values={oldValue}
        validationError={props.validationError}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            :
            cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })}
      />);

    case "calendar":
      return <DialogNewLayoutGridCalendar
        formStore={formStore}
        isFormReadOnly={isFormReadOnly}
        metaIdForm={formId}
        metaIdGrid={gridId}
        values={oldValue}
        validationError={props.validationError}
        onClickOk={(newValue) =>
          (oldValue && key)
            ? cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)
            : cbRefList.addItem({
              ...newValue,
              metaId: nextMetaIdLayoutGrid()
            })
        }
      />;

    default:
      return undefined;
  }
}

function convertMapToArray(map?: StudioMapOfLayoutGrid): StudioDtoLayoutGrid[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoLayoutGrid[]): StudioMapOfLayoutGrid | null
{
  const keys = [] as MetaIdFuncArg[];
  const map = {} as Record<MetaIdLayoutGrid, StudioDtoLayoutGrid>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    if(key)
    {
      keys.push(key);
      map[key] = value;
    }
  });

  return keys.length > 0
    ? {
      keys: keys,
      map: map
    } as StudioMapOfLayoutGrid
    : null;
}
