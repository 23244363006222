import {EnumStoreLabel} from "../../../api/meta/base/Types";
import {StoreItemId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {random} from "../../../base/plus/StringPlus";
import {listSetUserFieldVar} from "../../../base/slices/list/SliceListSharedActions";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {listClearPickItemIds} from "../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {ActionUpdateStoreFilter} from "../../../cache/store/filter/SliceCacheStoreFilter";
import {clearStoreFilter} from "../../../cache/store/filter/SliceCacheStoreFilter";
import {updateStoreFilter} from "../../../cache/store/filter/SliceCacheStoreFilter";
import {store} from "../../../Store";

export type FnAcceptFilter = (filter?: StoreItemId[]) => void;

export default class SrvcStoreDrawerFilter extends ISrvc
{
  load(listName: string, srcStoreItemCategory: EnumStoreLabel[])
  {
    const listData = this.createListData(srcStoreItemCategory);

    Promise
    .resolve(dispatchList(listName, listRefresh(listData)))
    .then(() =>
    {
      this.calcShowFilter(listName);
    });
  }

  // TODO Neel/Aditya move this in app package

  loadPicker(listName: string, srcStoreItemCategory: EnumStoreLabel[])
  {
    const listData = this.createListData(srcStoreItemCategory);
    dispatchList(listName, listRefresh(listData));
  }

  setFilter(listName: string, itemId: EnumStoreLabel, pickValue: boolean)
  {
    dispatchList(listName, listSetSelectedItemId(undefined));

    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    const action = {
      callerIdHash: callerIdHash,
      storeItemIdCategory: itemId,
      pickValue: pickValue
    } as ActionUpdateStoreFilter;
    Promise
    .resolve(store.dispatch(updateStoreFilter(action)))
    .then(() => this.calcShowFilter(listName));
  }

  clearFilter(listName: string)
  {
    const callerIdHash = store.getState().cache.app.caller.callerUserIdHash as string;
    Promise
    .resolve(dispatchList(listName, listClearPickItemIds()))
    .then(() =>
    {
      Promise
      .resolve(store.dispatch(clearStoreFilter(callerIdHash)))
      .then(() => this.calcShowFilter(listName));
    });
  }

  filter(fn: FnAcceptFilter)
  {
    fn(store.getState().cache.store.filter.storeItemIdCategorySet);
  }

  protected createListData(srcStoreItemIdCategorySet: EnumStoreLabel[]): IListData
  {
    const itemById = {} as IListItemsById;
    srcStoreItemIdCategorySet.forEach((storeItemId) =>
    {
      itemById[storeItemId] = {
        type: "p",
        primary: {
          text: storeItemId
        },
        hideMenu: true
      } as IListItemAPSA;
    });
    return {
      itemIds: srcStoreItemIdCategorySet,
      itemsById: itemById,
      version: random()
    } as IListData;
  }

  private calcShowFilter(listName: string)
  {
    const filter = store.getState().cache.store.filter.storeItemIdCategorySet;

    dispatchList(listName,
      listSetUserFieldVar({
        varName: "value",
        varValue: filter && filter.length > 0
      })
    );

    if(filter && filter.length > 0)
    {
      filter.forEach((itemId) =>
      {
        dispatchList(listName, listSetPick({
          itemId: itemId,
          pickValue: Boolean(filter.includes(itemId))
        }));
      });
    }
  }
}
