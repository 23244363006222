import {isEmpty} from "lodash";
import {uniq} from "lodash";
import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {DtoChatMessageListMap} from "../../../api/home/base/dto/DtoChatMessageListMap";
import {DtoMessagePayloadSpreadsheetRow} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {MsgDrawerSearch} from "../../../api/home/drawer/msg/MsgDrawerSearch";
import {SigDrawerSearch} from "../../../api/home/drawer/sig/SigDrawerSearch";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {WsocDrawer} from "../../../api/home/drawer/WsocDrawer";
import {SigMessage} from "../../../api/home/main/sig/SigMessage";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {isGlobal} from "../../../api/meta/base/ApiPlus";
import {isGridId} from "../../../api/meta/base/ApiPlus";
import {nextMessageId} from "../../../api/meta/base/ApiPlus";
import {isRowId} from "../../../api/meta/base/ApiPlus";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {isNonGlobalEntId} from "../../../api/meta/base/ApiPlus";
import {isMessageId} from "../../../api/meta/base/ApiPlus";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {MetaIdComp} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {MessageId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {createBubble} from "../../../base/plus/BubblePlus";
import {STR_YOU} from "../../../base/plus/ConstantsPlus";
import {STR_NO_MESSAGE} from "../../../base/plus/ConstantsPlus";
import {isoDateTimeNow} from "../../../base/plus/DatePlus";
import {getFormNameColor} from "../../../base/plus/FormPlus";
import {createListItemChat} from "../../../base/plus/ListPlus";
import {createListItemGroup} from "../../../base/plus/ListPlus";
import {getChatItem} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../base/plus/MediaPlus";
import {getEntTooltip} from "../../../base/plus/SrvcPlus";
import {textUser} from "../../../base/plus/SrvcPlus";
import {getLabel} from "../../../base/plus/StringPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {listChatSetIfExistHeader} from "../../../base/slices/list/SliceListChatAction";
import {listSetUserFieldVar} from "../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinderNine} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {IBubbleHeader} from "../../../base/types/TypesBubble";
import {IBubbleMessage} from "../../../base/types/TypesBubble";
import {DtoComboId} from "../../../base/types/TypesComboId";
import {toComboIdDto} from "../../../base/types/TypesComboId";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {IAvatar} from "../../../base/types/TypesGlobal";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {ILastMessage} from "../../../cache/home/drawer/msgLast/TypesCacheHomeDrawerMsgLast";
import {textUserOrYou} from "../../../Store";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {isCommentableForm} from "../../app/form/SrvcForm";
import {selectCacheRow} from "../../app/spreadsheet/SrvcSpreadsheet";
import {Srvc} from "../../Srvc";
import SrvcHomeListChat from "../app/SrvcHomeListChat";

type TypeSigSearchAvatar = SigGroupAvatar | SigUserAvatar | SigEntAvatarUser | undefined;
type TypeLastMessage = ILastMessage | undefined;

interface SigMessageSearch extends SigMessage
{
  entId: EntId;
  chatId: ChatId;
}

interface SigSpreadSheetRowSearch extends SigMessage
{
  entId: EntId,
  spreadsheetId: MetaIdSpreadsheet,
  chatId: ChatId,
  rowId: RowId
}

export default class SrvcHomeDrawerSearch extends SrvcHomeListChat
{
  constructor()
  {
    super((state: RootState) => state.home.drawer.searchList);
  }

  subscribeItem(subscriberId: TypeSubscriberId, itemId: TypeListItemId, unSubscribe?: boolean)
  {
    if(isMessageId(itemId))
    {
      super.subscribeItem(subscriberId, itemId, unSubscribe);
    }
    else if(isRowId(itemId))
    {
      const rootState = store.getState();
      const searchList = rootState.home.drawer.searchList;
      const userField = searchList.itemsById[itemId]?.userField;
      const entId = userField?.entId;
      const rowId = userField?.rowId;
      const spreadsheetId = userField?.spreadsheetId;

      if(entId && rowId && spreadsheetId)
      {
        Srvc.app.spreadsheet.subscribeSpreadsheetRow(subscriberId, entId as EntId, rowId as RowId,
          spreadsheetId as MetaIdSpreadsheet, unSubscribe
        );
      }
    }
    else
    {
      const comboIdDto = toComboIdDto(itemId);
      const entId = comboIdDto.entId;
      const chatId = comboIdDto.chatId;
      if(entId !== undefined && chatId !== undefined)
      {
        Srvc.app.pubsub.homeAvatar(subscriberId, itemId, unSubscribe);
        Srvc.app.pubsub.msg.messageLast(subscriberId, entId, chatId, unSubscribe);
      }
    }
  }

  wsocSearch(listName: string, searchId: string, searchStr: string)
  {
    dispatchList(listName,
      listSetUserFieldVar({
        varName: "loaded",
        varValue: false
      })
    );
    const filter = store.getState().cache.home.drawer.filter.entIdSet;

    const msg: MsgDrawerSearch = {
      filterEntIdSet: filter,
      searchId: searchId,
      searchQuery: searchStr
    };
    WsocDrawer.drawerSearch(msg, (envSig) =>
    {
      if(envSig.error)
      {
        const errorCode = envSig.error.errorCode;
        if(errorCode !== "cancelledRequest")
        {
          Srvc.app.toast.showErrorToast(envSig);
        }
      }
      else if(envSig.sig)
      {
        this.doLoad(listName, envSig.sig);
      }
      else
      {
        dispatchList(listName,
          listSetUserFieldVar({
            varName: "loaded",
            varValue: true
          })
        );
      }
    });
  }

  //region binder
  getListBinder()
  {
    return {

      selectSourceItem1: this.selectGroupAvatar.bind(this),
      onBindSourceItem1: this.onBindGroupAvatar.bind(this),

      selectSourceItem2: this.selectAvatar.bind(this),
      onBindSourceItem2: this.onBindAvatar.bind(this),

      selectSourceItem3: this.selectDefnForm.bind(this),
      onBindSourceItem3: this.onBindDefnForm.bind(this),

      selectSourceItem4: this.selectIsStarMsg.bind(this),
      onBindSourceItem4: this.onBindIsStarMsg.bind(this),

      selectSourceItem5: this.selectLastMessage.bind(this),
      onBindSourceItem5: this.onBindLastMessage.bind(this),

      selectSourceItem6: this.selectCanShowComments.bind(this),
      onBindSourceItem6: this.onBindCanShowComments.bind(this),

      selectSourceItem7: this.selectChatPattern.bind(this),
      onBindSourceItem7: this.onBindChatPattern.bind(this),

      selectSourceItem8: this.selectSpreadsheetRow.bind(this),
      onBindSourceItem8: this.onBindSpreadsheetRow.bind(this),

      selectSourceItem9: this.selectReply.bind(this),
      onBindSourceItem9: this.onBindReply.bind(this),

      selectUserAvatar: this.selectEntUserAvatar.bind(this)

    } as IListBinderNine<
      TypeSigSearchAvatar,
      TypeSigSearchAvatar,
      DefnForm,
      boolean,
      TypeLastMessage,
      boolean,
      SigSpreadsheetRow,
      SigSpreadsheetRow,
      SigUserAvatar
    >;

  }

  removeSpreadsheetRow(
    listName: string,
    entId: EntId,
    formId: MetaIdForm,
    spreadsheetId: MetaIdSpreadsheet,
    rowId: RowId)
  {
    Srvc.app.spreadsheet.rpcSpreadsheetRowRemove(entId, formId, spreadsheetId, rowId, () =>
    {
      dispatchList(listName, listSetUserFieldVar({
        varName: "reload",
        varValue: true
      }));
    });
  }

  private doLoad(listName: string, sigDrawerSearch: SigDrawerSearch)
  {
    const rootState = store.getState();

    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;
    const userIds = [] as TypeListItemId[];
    const groupIds = [] as TypeListItemId[];
    const messageIds = [] as TypeListItemId[];
    const spreadsheetRowIds = [] as TypeListItemId[];

    // Users
    if(sigDrawerSearch.userAvatarList?.length)
    {
      sigDrawerSearch.userAvatarList?.forEach((user) =>
      {
        const itemId = toComboId(user.entId, user.entUserId);
        userIds.push(itemId);
        uiItemsById[itemId] = this.doLoadAvatar(itemId, user) as IListItemAPSA;
      });
      uiItemsById["Users"] = createListItemGroup("Users");
      uiGroupsById["Users"] = {
        itemIds: userIds
      };
    }

    // Groups
    if(sigDrawerSearch.groupAvatarList?.length)
    {
      sigDrawerSearch.groupAvatarList?.forEach((group) =>
      {
        const itemId = toComboId(group.entId, group.groupId);
        groupIds.push(itemId);
        uiItemsById[itemId] = this.doLoadAvatar(itemId, group) as IListItemAPSA;
      });
      uiItemsById["Groups"] = createListItemGroup("Groups");
      uiGroupsById["Groups"] = {
        itemIds: groupIds
      };
    }

    // Messages
    if(sigDrawerSearch.latestMessageMap)
    {
      latestMessageMapToList(sigDrawerSearch.latestMessageMap)?.forEach((sigMsg) =>
      {
        messageIds.push(sigMsg.messageId);
        const entId = sigMsg.entId;
        if(isNonGlobalEntId(entId))
        {
          const callerEnt = selectCallerEnt(store.getState(), entId);
          if(!callerEnt)
          {
            Srvc.app.pubsub.caller.subCallerEnt(entId);
          }
        }
        const item = createListItemChat(entId, sigMsg.chatId, sigMsg);

        const entAvatar = !isGlobal(entId)
          ? rootState.cache.app.caller.entIdUserAvatarMap[entId]
          : undefined;
        const entUserId = selectCallerEnt(rootState, entId)?.entUserId;
        const sigSpreadsheetRow = (sigMsg.payload as DtoMessagePayloadSpreadsheetRow)?.spreadsheetRow;

        item.sig.formBubbleTitleColor = getFormNameColor(
          entUserId,
          sigSpreadsheetRow?.formValue?.updatedBy,
          sigSpreadsheetRow?.updatedKeySet
        );

        if(entAvatar)
        {
          if(!item.sig.header)
          {
            item.sig.header = {};
          }
          item.sig.header = {
            ...item.sig.header,
            headerTextLeft1: (entAvatar as SigEntAvatarUser).name
          };
        }
        uiItemsById[sigMsg.messageId] = item;
      });
    }
    uiItemsById["Messages"] = createListItemGroup("Messages");
    uiGroupsById["Messages"] = {
      itemIds: uniq(messageIds)
    };

    // SpreadsheetRow
    if(!isEmpty(sigDrawerSearch.spreadsheetRowMap))
    {
      spreadSheetRowMapToList(sigDrawerSearch.spreadsheetRowMap)?.forEach((spreadsheetRow) =>
      {
        const rowId = spreadsheetRow.rowId;
        const spreadsheetId = spreadsheetRow.spreadsheetId;
        const entId = spreadsheetRow.entId;
        const chatId = spreadsheetRow.chatId;
        const sig = {
          ...spreadsheetRow
        } as SigMessage;
        const sigSpreadsheetRow = rootState.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.spreadsheetRowMap[rowId];
        const entUserId = selectCallerEnt(rootState, entId)?.entUserId;

        spreadsheetRowIds.push(spreadsheetRow.rowId);
        const listItemChat = this.doLoadSpreadSheetRow(entId, chatId, sig);
        uiItemsById[spreadsheetRow.rowId] = {
          type: "spreadsheetRow",
          userField: {
            entId: entId,
            rowId: rowId,
            chatId: chatId,
            spreadsheetId: spreadsheetId
          },
          ...listItemChat,
          sig: {
            ...listItemChat.sig,
            formBubbleTitleColor: getFormNameColor(
              entUserId,
              sigSpreadsheetRow?.formValue?.updatedBy,
              sigSpreadsheetRow?.updatedKeySet
            )
          }
        } as IListItemChat;

      });
    }
    uiItemsById["SpreadsheetRow"] = createListItemGroup("Spreadsheet row");
    uiGroupsById["SpreadsheetRow"] = {
      itemIds: spreadsheetRowIds
    };

    const searchWords = this.selectList(rootState).searchWords;

    dispatchList(listName, listRefresh({
      searchWords: searchWords,
      itemsById: uiItemsById,
      groupsById: uiGroupsById,
      userField: this.getHomeListChatUserField(Object.values(uiItemsById) as IListItemChat[])
    } as IListData));
  }

  private selectAvatar(rootState: RootState, itemId: TypeComboId | MessageId): TypeSigSearchAvatar
  {
    if(isMessageId(itemId))
    {
      const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
      const entId = chatItem?.entId;
      const senderId = chatItem?.sig.senderId;
      const entUserId = toComboId(entId, senderId);
      return rootState.cache.app.user.userAvatarMap[entUserId];
    }

    const split = toComboIdDto(itemId);
    const entId = split.entId;
    const chatId = split.chatId;
    const entChatId = toComboId(entId, chatId);
    return isGroupId(chatId)
      ? rootState.cache.app.group.groupAvatarMap[entChatId]
      : rootState.cache.app.user.userAvatarMap[entChatId];
  }

  private onBindAvatar(listName: string, itemId: TypeComboId | MessageId, avatar: TypeSigSearchAvatar): void
  {
    if(avatar)
    {
      if(!isMessageId(itemId))
      {
        const newItem = this.doLoadAvatar(itemId, avatar) as IListItemAPSA;
        dispatchList(listName, listSetItem({
          itemId: itemId,
          newItem: newItem
        }));
      }
      else
      {
        const rootState = store.getState();
        const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
        dispatchList(listName, listChatSetIfExistHeader({
          itemId: itemId,
          header: {
            ...chatItem.sig.header,
            headerTextLeft3: textUserOrYou(rootState, avatar as SigUserAvatar)
          }
        }));
      }
    }
  }

  private selectLastMessage(state: RootState, itemId: TypeComboId | MessageId): TypeLastMessage
  {
    if(!isMessageId(itemId))
    {
      return state.cache.home.drawer.msgLast.lastMessageMap[itemId];
    }
  }

  private onBindLastMessage(listName: string, itemId: TypeComboId | MessageId): void
  {
    if(!isMessageId(itemId))
    {
      const rootState = store.getState();
      const avatar = this.selectAvatar(rootState, itemId);
      if(avatar)
      {
        const newItem = this.doLoadAvatar(itemId, avatar) as IListItemAPSA;
        dispatchList(listName, listSetItem({
          itemId: itemId,
          newItem: newItem
        }));
      }
    }
  }

  //endregion

  private doLoadAvatar(entChatId: TypeComboId, avatar?: TypeSigSearchAvatar): IListItemAPSA
  {
    const sig = avatar as SigUserAvatar | SigGroupAvatar;
    const split = toComboIdDto(entChatId);
    const dtoComboId = toComboIdDto(entChatId);
    const entId = split.entId;
    const chatId = split.chatId;
    const isGroup = isGroupId(chatId);
    const isUser = !isGroup;

    const rootState = store.getState();
    const isPinned = Boolean(rootState.cache.home.drawer.recent.recentMap[entChatId]?.isPinned);
    const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
    const entAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];
    const badge = rootState.cache.app.badge.badgeMap[entChatId];
    const sender = this.getSender(rootState, dtoComboId, lastMessage);
    const overwriteIcon1Icon2AndText = isUser
      ? rootState.cache.app.user.userTypingText[entChatId]
      : undefined;

    return {
      version: sig?.version,
      type: "aps",
      avatarLeft: this.getAvatarLeft(entAvatar, sig, dtoComboId),
      primary: {
        text: isGroup
          ? getLabel(sig as SigGroupAvatar)
          : textUser(sig as SigUserAvatar),
        icon1: isPinned
          ? "pin"
          : undefined,
        caption: {
          text: lastMessage
            ? lastMessage.messageTime
            : undefined,
          ignoreSelection: true
        }
      },
      secondary: {
        overwriteIcon1Icon2AndText: overwriteIcon1Icon2AndText,
        overwriteIcon1Icon2AndTextColor: overwriteIcon1Icon2AndText
          ? "successLight"
          : undefined,
        icon1: lastMessage
          ? lastMessage.receiptStatus
          : undefined,
        icon2: lastMessage && lastMessage.messageType !== "text"
          ? lastMessage.messageType
          : undefined,
        text: lastMessage
          ? this.getSecondaryText(rootState, lastMessage, sender)
          : STR_NO_MESSAGE,
        badge: {
          value: badge
        }
      },
      userField: {value: isPinned}
    } as IListItemAPSA;
  }

  private getSecondaryText(rootState: RootState, lastMessage?: ILastMessage, sender?: SigUserAvatar)
  {
    let userText = "";
    if(sender)
    {
      userText = textUserOrYou(rootState, sender);
    }

    return lastMessage
      ? sender
        ? userText + ": " + lastMessage.messageSummary
        : lastMessage.messageSummary
      : undefined;
  }

  private getAvatarLeft(entAvatar: SigEntAvatarUser, avatar: SigUserAvatar | SigGroupAvatar, dtoComboId: DtoComboId)
  {
    const isGroup = isGroupId(dtoComboId.chatId);
    return {
      src: isNonGlobalEntId(dtoComboId.entId) ? getImageThumbnail(entAvatar?.avatarId) :
        getImageThumbnail(avatar.avatarId),
      icon: isGroup ? "group" : "user",
      tooltip: getEntTooltip(entAvatar)
    } as IAvatar;
  }

  private getSender(state: RootState, dtoComboId: DtoComboId, lastMessage?: ILastMessage)
  {
    return isGroupId(dtoComboId.chatId) && lastMessage
      ? state.cache.app.user.userAvatarMap[toComboId(dtoComboId.entId, lastMessage.senderId)]
      : undefined;
  }

  private doLoadSpreadSheetRow(entId: EntId, chatId: ChatId, sig: SigMessage): IListItemChat
  {
    return {
      type: "spreadsheetRow",
      entId: entId,
      chatId: chatId,
      sig: createBubble(sig)
    } as IListItemChat;
  }

  private selectSpreadsheetRow(rootState: RootState, rowId: RowId)
  {
    if(isRowId(rowId))
    {
      const searchItem = rootState.home.drawer.searchList.itemsById[rowId];
      const entId = searchItem?.userField?.entId as EntId;
      if(entId)
      {
        return selectCacheRow(rootState, entId, rowId);
      }
    }
  }

  private onBindSpreadsheetRow(listName: string, rowId: RowId, spreadsheetRow?: SigSpreadsheetRow)
  {
    if(!spreadsheetRow)
    {
      return;
    }
    const rootState = store.getState();
    const rowItem = rootState.home.drawer.searchList.itemsById[rowId];
    const entId = rowItem?.userField?.entId as EntId;

    const entUserId = selectCallerEnt(rootState, entId)?.entUserId;

    const valueMap = spreadsheetRow.formValue?.valueMap;
    const isGridPresentInSearch = valueMap ? getFormValueToGridName(valueMap) : false;

    const msgPayload = {
      messageType: "spreadsheetRow",
      formId: spreadsheetRow.formId,
      rowId: spreadsheetRow?.formValue?.rowId,
      spreadsheetId: spreadsheetRow.spreadsheetId,
      spreadsheetRow: spreadsheetRow,
      spreadsheetPartitionId: spreadsheetRow.spreadsheetPartitionId
    } as DtoMessagePayloadSpreadsheetRow;

    const rowCreatedBy = spreadsheetRow.formValue?.createdBy;
    const isCallerSender = entUserId === rowCreatedBy;

    const entName = rootState.cache.app.caller?.entIdUserAvatarMap[entId]?.name;
    const callerEnt = selectCallerEnt(rootState, entId);
    const spreadsheetMap = callerEnt?.spreadsheetMap;
    const entUserAvtarMap = rootState.cache.app.user.userAvatarMap;
    const spreadsheet = spreadsheetMap
      ? spreadsheetMap[spreadsheetRow.spreadsheetId]
      : undefined;

    const isCommentable = isCommentableForm(rootState,
      entId,
      spreadsheetRow.formId,
      spreadsheetRow.formValue?.createdBy
    );
    const canRemove = Srvc.app.spreadsheet.canRemove(rootState,
      entId,
      spreadsheetRow.spreadsheetId,
      spreadsheetRow.formValue?.createdBy
    );

    const spreadsheetName = spreadsheet
      ? spreadsheet?.label || (spreadsheet?.name && toLabel(spreadsheet.name))
      : undefined;

    const userIdEntIdCombo = rowCreatedBy
      ? toComboId(entId, rowCreatedBy)
      : undefined;
    const senderName = userIdEntIdCombo
      ? !isCallerSender
        ? entUserAvtarMap[userIdEntIdCombo]?.nickName
        : STR_YOU
      : undefined;

    const header = {
      headerTextLeft1: entName,
      headerTextLeft2: spreadsheetName ? toLabel(spreadsheetName) : undefined,
      headerTextLeft3: senderName
    } as IBubbleHeader;

    const bubbleMsg = {
      isCallerSender: isCallerSender,
      creationTime: spreadsheetRow?.formValue?.createdOn,
      sigSpreadsheetRow: spreadsheetRow,
      messageId: nextMessageId(),
      payload: msgPayload,
      messageOffset: -1, //spreadsheetRow don't have messageOffset
      senderId: spreadsheetRow?.formValue?.createdBy,
      header: header,
      showGridAsHyperlink: isGridPresentInSearch,
      isCommentable: isCommentable
    } as IBubbleMessage;

    if(bubbleMsg)
    {
      dispatchList(listName, listSetItem({
        itemId: rowId,
        newItem: {
          type: "spreadsheetRow",
          entId: entId,
          chatId: "",
          sig: bubbleMsg,
          ignoreSelection: true,
          hideMenu: !canRemove
        } as IListItemChat
      }));

    }
  }
}

function latestMessageMapToList(latestMessageMap?: Record<EntId, DtoChatMessageListMap>)
{
  if(latestMessageMap === undefined)
  {
    return undefined;
  }
  const latestMessageList = [] as SigMessageSearch[];
  Object.entries(latestMessageMap).forEach(([entId, value]) =>
  {
    if(value.chatMessageListMap)
    {
      Object.entries(value.chatMessageListMap).forEach(([chatId, sigMessages]) =>
      {
        sigMessages.forEach((sigMessage) =>
        {
          latestMessageList.push({
            ...sigMessage,
            entId,
            chatId
          });
        });
      });
    }
  });
  // creationTime sort
  latestMessageList.sort(function(a, b)
  {
    const dateA = new Date(a.creationTime).getTime();
    const dateB = new Date(b.creationTime).getTime();
    return dateA > dateB ? 1 : -1;
  });
  return latestMessageList;
}

function getFormValueToGridName(valueMap: Record<MetaIdComp, any>): boolean
{
  for(const key of Object.keys(valueMap))
  {
    if(isGridId(key))
    {
      return true;
    }
  }
  return false;
}

function spreadSheetRowMapToList(spreadsheetRowMap?: Record<EntId, Record<MetaIdSpreadsheet, RowId[]>>)
{
  if(spreadsheetRowMap === undefined)
  {
    return undefined;
  }
  const spreadSheetRowList = [] as SigSpreadSheetRowSearch[];
  Object.entries(spreadsheetRowMap).forEach(([entId, value]) =>
  {
    if(isNonGlobalEntId(entId))
    {
      const callerEnt = selectCallerEnt(store.getState(), entId);
      if(!callerEnt)
      {
        Srvc.app.pubsub.caller.subCallerEnt(entId);
      }
    }
    if(value)
    {
      Object.entries(value).forEach(([spreadSheetId, rowIdSet]) =>
      {
        rowIdSet.forEach((rowId) =>
        {
          const sigMessage = {
            creationTime: isoDateTimeNow(),
            messageId: nextMessageId(),
            messageOffset: -1, //spreadsheetRow don't have messageOffset
            payload: {},
            senderId: ""
          } as SigMessage;

          spreadSheetRowList.push({
            ...sigMessage,
            entId: entId,
            chatId: "",
            spreadsheetId: spreadSheetId,
            rowId: rowId
          });
        });
      });
    }
  });

  return spreadSheetRowList;

}
