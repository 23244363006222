import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldPickTree} from "../../../../api/meta/base/dto/DefnFieldPickTree";
import {FieldValueOptionId} from "../../../../api/meta/base/dto/FieldValueOptionId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawPickTree from "../raw/FieldRawPickTree";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldPickTree(props: {
  defn: DefnFieldPickTree,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();

  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);

  const fieldVariant = defnTheme.fieldVariant;
  const fieldSize = defnTheme.fieldSize ?? "medium";

  const helperText = getFieldHelperText();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;
        const isError = Boolean(error);

        const fieldValue = field.value as FieldValueOptionId;
        const onChange = field.onChange as (value?: FieldValueOptionId | null) => void;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowY={"visible"}
                overflowX={"visible"}
              >
                <FieldRawPickTree
                  defn={defn}
                  value={fieldValue}
                  onChange={onChange}
                  label={defn.hideLabel ? undefined : getLabel(defn)}
                  required={defn.required}
                  autoFocus={defn.autoFocus}
                  fieldSize={fieldSize}
                />
                <FieldRawRefButton defn={defn} />
              </LayoutFlexRow>
              {(isError || helperText) && <FormHelperText
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1
                }}
              >{error?.message || helperText}</FormHelperText>}
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
