import {PayloadAction} from "@reduxjs/toolkit";
import _ from "lodash";
import {EnumDefnThemeColor} from "../../../api/meta/base/Types";
import {CssBackgroundColor} from "../../plus/ThemePlus";
import {ListLayoutType} from "../../types/list/TypesList";
import {IList} from "../../types/list/TypesList";
import {TypeListItemId} from "../../types/list/TypesList";
import {IListItem} from "../../types/list/TypesList";
import {IListData} from "../../types/list/TypesList";
import {TypeUserFieldValue} from "../../types/TypesGlobal";
import {TypePick} from "../../types/TypesGlobal";
import {TypeUserField} from "../../types/TypesGlobal";
import {logAssert} from "../../util/AppLog";
import {fnListSetDisplay} from "./SliceList";

export type ActionSetListItem = {itemId: TypeListItemId, newItem: IListItem};
export type ActionMergeListItem = {itemId: TypeListItemId, item: IListItem};

export const sliceListShared =
  {
    //region item
    listSetItem: (state: IList, action: PayloadAction<ActionSetListItem>) =>
    {
      state.itemsById[action.payload.itemId] = action.payload.newItem;
    },
    listMergeItem: (state: IList, action: PayloadAction<ActionMergeListItem>) =>
    {
      const payload = action.payload;
      const itemId = payload.itemId;
      const newListItem = payload.item;
      const oldListItem = state.itemsById[itemId];
      if(oldListItem)
      {
        state.itemsById[itemId] = _.merge(oldListItem, newListItem);
      }
      else
      {
        state.itemsById[itemId] = newListItem;
      }
    },
    listClearItem: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      const itemId = action.payload;
      if(state.pickItemIds)
      {
        delete state.pickItemIds[itemId];
      }
      delete state.itemsById[itemId];
    },
    listResetItem: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      const itemId = action.payload;
      if(state.pickItemIds)
      {
        delete state.pickItemIds[itemId];
      }
      state.itemsById[itemId] = {} as IListItem;
    },

    //endregion

    //region init
    listSetCanShowMenu: (state: IList, action: PayloadAction<boolean>) =>
    {
      state.canShowMenu = action.payload;
    },
    listSetIgnoreSelection: (state: IList, action: PayloadAction<boolean>) =>
    {
      state.ignoreSelection = action.payload;
    },
    listSetTopItemCount: (state: IList, action: PayloadAction<number | undefined>) =>
    {
      state.topItemCount = action.payload;
    },
    listSetError: (state: IList, action: PayloadAction<string | undefined>) =>
    {
      state.error = action.payload;
      fnListSetDisplay(state);
    },
    listSetUserField: (state: IList, action: PayloadAction<TypeUserField | undefined>) =>
    {
      state.userField = action.payload;
    },
    listSetUserFieldVar: (state: IList, action: PayloadAction<{varName: string, varValue?: TypeUserFieldValue}>) =>
    {
      // noinspection DuplicatedCode
      const payload = action.payload;

      let userField = state.userField;
      if(userField === undefined)
      {
        userField = {};
      }

      const varName = payload.varName;
      const varValue = payload.varValue;
      if(varValue)
      {
        userField = {
          ...userField,
          [varName]: varValue
        };
      }
      else if(userField)
      {
        delete userField[varName];
      }

      state.userField = userField;
    },
    listSetVersion: (state: IList, action: PayloadAction<string>) =>
    {
      state.version = action.payload;
    },
    listSetLayoutType: (state: IList, action: PayloadAction<{listLayoutType: ListLayoutType}>) =>
    {
      state.listLayoutType = action.payload.listLayoutType;
    },
    listSetItemIds: (state: IList, action: PayloadAction<TypeListItemId[]>) =>
    {
      state.groupsById = undefined;
      state.itemIds = action.payload;
      state.loaded = true;
      fnListSetDisplay(state);
    },
    listRefresh: (state: IList, action: PayloadAction<IListData>) =>
    {
      const listData = action.payload;
      const itemIds = listData.itemIds;
      const groupsById = listData.groupsById;

      logAssert("SliceList",
        itemIds !== undefined || groupsById !== undefined,
        `List = ${state.listName}, Error = Any one of itemIds or groupsById is required`
      );
      logAssert("SliceList",
        itemIds === undefined || groupsById === undefined,
        `List = ${state.listName}, Error = Only one of itemIds or groupsById is required`
      );

      state.version = listData.version;
      state.itemIds = itemIds;
      state.itemsById = listData.itemsById;
      state.groupsById = groupsById;
      state.pickItemIds = listData.pickItemIds;
      state.searchWords = listData.searchWords;
      state.defnForm = listData.defnForm;
      state.layout = listData.layout;
      state.userField = listData.userField;
      state.error = listData.error;
      state.loaded = true;

      fnListSetDisplay(state);
    },
    listSetDefaultItemHeight: (state: IList, action: PayloadAction<number | undefined>) =>
    {
      state.defaultItemHeight = action.payload;
    },
    //endregion

    //region index
    listPushTop: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      checkGroupListNotAllowed(state);

      const itemId = action.payload;
      const itemIds = state.itemIds as TypeListItemId[];

      if(!itemIds.includes(itemId))
      {
        state.itemIds = [itemId, ...itemIds];
        fnListSetDisplay(state);
      }
    },
    listPushBottom: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      checkGroupListNotAllowed(state);

      const itemId = action.payload;
      const itemIds = state.itemIds as TypeListItemId[];

      if(!itemIds.includes(itemId))
      {
        state.itemIds = [...itemIds, itemId];
        fnListSetDisplay(state);
      }
    },
    listMoveUp: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      checkGroupListNotAllowed(state);

      const itemId = action.payload;
      const itemIds = state.itemIds as TypeListItemId[];

      const index = itemIds.indexOf(itemId);
      if(index > 0)
      {
        const newIndex = index - 1;
        const temp = itemIds[index];
        itemIds[index] = itemIds[newIndex];
        itemIds[newIndex] = temp;
        fnListSetDisplay(state);
      }
    },
    listMoveDown: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      checkGroupListNotAllowed(state);

      const itemId = action.payload;
      const itemIds = state.itemIds as TypeListItemId[];

      const index = itemIds.indexOf(itemId);
      if(index >= 0 && index < itemIds.length - 1)
      {
        const newIndex = index + 1;
        const temp = itemIds[index];
        itemIds[index] = itemIds[newIndex];
        itemIds[newIndex] = temp;
        fnListSetDisplay(state);
      }
    },
    listMoveTop: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      checkGroupListNotAllowed(state);

      const itemId = action.payload;
      const itemIds = state.itemIds as TypeListItemId[];

      const index = itemIds.indexOf(itemId);
      if(index > 0)
      {
        const temp = itemIds[index];
        itemIds.splice(index, 1);
        itemIds.unshift(temp);
        fnListSetDisplay(state);
      }
    },
    listMoveBottom: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      checkGroupListNotAllowed(state);

      const itemId = action.payload;
      const itemIds = state.itemIds as TypeListItemId[];

      const index = itemIds.indexOf(itemId);
      if(index >= 0 && index < itemIds.length - 1)
      {
        const temp = itemIds[index];
        itemIds.splice(index, 1);
        itemIds.push(temp);
        fnListSetDisplay(state);
      }
    },
    listRemove: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      checkGroupListNotAllowed(state);

      const itemId = action.payload;
      const itemIds = state.itemIds as TypeListItemId[];

      const index = itemIds.indexOf(itemId);
      if(index >= 0)
      {
        itemIds.splice(index, 1);
        fnListSetDisplay(state);
        delete state.itemsById[itemId];
      }
    },
    //endregion

    //region search
    listSetSearch: (state: IList, action: PayloadAction<string | undefined>) =>
    {
      const searchString = action.payload;

      let searchWords: string[] | undefined = undefined;
      if(searchString && searchString.length > 0)
      {
        searchString.split(" ").forEach(value =>
        {
          value = value.trim();
          if(value.length > 0)
          {
            if(searchWords === undefined)
            {
              searchWords = [];
            }

            searchWords.push(value);
          }
        });
      }
      state.searchWords = searchWords;
      fnListSetDisplay(state);
    },
    //endregion

    //region scroll and selection
    listSetSelectedItemId: (state: IList, action: PayloadAction<TypeListItemId | undefined>) =>
    {
      const selectedItemId = action.payload;
      if(selectedItemId)
      {
        if(state.ignoreSelection)
        {
          return;
        }

        const item = state.itemsById[selectedItemId];
        if(item && item.ignoreSelection)
        {
          return;
        }

        state.selectedItemId = selectedItemId;
      }
      else
      {
        state.selectedItemId = undefined;
      }
    },
    listToggleIgnoreSelection: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      const itemId = action.payload;
      const item = state.itemsById[itemId];
      if(item)
      {
        item.ignoreSelection = !Boolean(item.ignoreSelection);
      }
    },
    listSetScrollToItemId: (state: IList, action: PayloadAction<TypeListItemId | undefined>) =>
    {
      state.scrollToItemId = action.payload;
    },
    listSetScrollToTop: (state: IList) =>
    {
      state.scrollToItemId = state.displayFirstItemId;
    },
    //endregion

    //region pick
    listClearPickItemIds: (state: IList) =>
    {
      state.pickItemIds = {};
      state.pickCount = 0;
    },
    listClearPickItemIdsAndSelectedItemId: (state: IList) =>
    {
      state.pickItemIds = {};
      state.selectedItemId = undefined;
    },
    listSetPickType: (state: IList, action: PayloadAction<TypePick | undefined>) =>
    {
      state.pickType = action.payload;
      state.pickItemIds = {};
      state.selectedItemId = undefined;
      fnListSetDisplay(state);
    },
    listSetPick: (state: IList, action: PayloadAction<{itemId: TypeListItemId, pickValue: boolean}>) =>
    {
      const itemId = action.payload.itemId;
      const pickValue = action.payload.pickValue;

      const pickType = state.pickType;
      if(pickType === "pickOne")
      {
        if(pickValue)
        {
          state.pickItemIds = {};
          state.pickItemIds[itemId] = true;
        }
      }
      else
      {
        let pickItemIds = state.pickItemIds;
        if(!pickItemIds)
        {
          pickItemIds = {};
          state.pickItemIds = pickItemIds;
        }

        if(pickValue)
        {
          pickItemIds[itemId] = true;
        }
        else
        {
          delete pickItemIds[itemId];
        }
      }

      fnListSetDisplay(state);
    },
    listSetShowPicksOnly: (state: IList, action: PayloadAction<boolean>) =>
    {
      state.showPicksOnly = action.payload;
      fnListSetDisplay(state);
    },
    listToggleShowPicksOnly: (state: IList) =>
    {
      state.showPicksOnly = !state.showPicksOnly;
      fnListSetDisplay(state);
    },
    //endregion

    //region set if exist
    listSetIfExistUserField: (
      state: IList,
      action: PayloadAction<{itemId: TypeListItemId, userField: TypeUserField}>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId];
      if(item)
      {
        item.userField = params.userField;
      }
    },
    listSetItemIfExistUserFieldVar: (
      state: IList,
      action: PayloadAction<{itemId: TypeListItemId, varName: string, varValue?: TypeUserFieldValue}>) =>
    {
      const params = action.payload;

      const item = state.itemsById[params.itemId];
      if(item)
      {
        let userField = item.userField;
        if(userField === undefined)
        {
          userField = {};
        }

        const varName = params.varName;
        const varValue = params.varValue;
        if(varValue)
        {
          userField = {
            ...userField,
            [varName]: varValue
          };
        }
        else if(userField)
        {
          delete userField[varName];
        }

        item.userField = userField;
      }
    },
    listSetIfExistBgcolor: (
      state: IList,
      action: PayloadAction<{itemId: TypeListItemId, bgcolor?: CssBackgroundColor}>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId];
      if(item)
      {
        item.bgColor = params.bgcolor;
      }
    },
    listSetIfExistVersion: (state: IList, action: PayloadAction<{itemId: TypeListItemId, version: string}>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId];
      if(item)
      {
        item.version = params.version;
      }
    },
    listSetIfExistInfoSpot: (
      state: IList,
      action: PayloadAction<{itemId: TypeListItemId, infoSpot?: EnumDefnThemeColor}>) =>
    {
      const {
        itemId,
        infoSpot
      } = action.payload;
      const item = state.itemsById[itemId];
      if(item)
      {
        item.infoSpot = infoSpot;
      }
    },
    listSetIfExistInfoSpots: (
      state: IList,
      action: PayloadAction<{itemId: TypeListItemId, infoSpot?: EnumDefnThemeColor}[]>) =>
    {
      const payload = action.payload;
      payload.forEach(value =>
      {
        const {
          itemId,
          infoSpot
        } = value;
        const item = state.itemsById[itemId];
        if(item)
        {
          item.infoSpot = infoSpot;
        }
      });
    },

    listSetLoadingFooter: (state: IList, action: PayloadAction<boolean | undefined>) =>
    {
      state.loadingFooter = action.payload;
    }
    //endregion
  };

// region private

function checkGroupListNotAllowed(state: IList)
{
  logAssert("SliceList",
    !state.groupsById,
    `List = ${state.listName}, Error = This operation is not allowed on group list`
  );
}

//endregion
