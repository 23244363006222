import {DtoMessagePayload} from "../../../../api/home/base/dto/DtoMessagePayload";
import {MsgMessageSend} from "../../../../api/home/main/msg/MsgMessageSend";
import {WsocMain} from "../../../../api/home/main/WsocMain";
import {nextMessageId} from "../../../../api/meta/base/ApiPlus";
import {ChatId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {Srvc} from "../../../Srvc";

export default class SrvcHomeMainFooterMsgSelection extends ISrvc
{

  forwardMsg(entId: EntId, chatId: ChatId, payload: DtoMessagePayload)
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      payload: payload
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }
}
