import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickText} from "../../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {StudioFieldScanCode} from "../../../../../api/meta/base/dto/StudioFieldScanCode";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyShowCapturedValuesOnAside} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyCaptureUser} from "../../base/TypesFormBuilder";
import {propKeyCaptureTime} from "../../base/TypesFormBuilder";
import {propKeyQrCode} from "../../base/TypesFormBuilder";
import {propKeyCaptureLocation} from "../../base/TypesFormBuilder";
import {propKeyBarCode} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";

export function getDefnFieldScanCode(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap2,
    propKeyBarCode,
    propKeyQrCode,
    fieldGap3,
    propKeyCaptureLocation,
    propKeyCaptureTime,
    propKeyCaptureUser,
    fieldGap4,
    propKeyShowCapturedValuesOnAside
  ];

  return {

    ...getFieldBuilderPropertySubTabs(
      "scanCode",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyBarCode]: {
      type: "bool",
      metaId: propKeyBarCode,
      name: propKeyBarCode,
      label: "Bar code",
      showAsCheckboxVar: true
    } as DefnFieldPickText,
    [propKeyQrCode]: {
      type: "bool",
      metaId: propKeyQrCode,
      name: propKeyQrCode,
      label: "QR code",
      showAsCheckboxVar: true
    } as DefnFieldPickText,

    [propKeyCaptureLocation]: {
      type: "bool",
      metaId: propKeyCaptureLocation,
      name: propKeyCaptureLocation,
      label: "Capture location",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,
    [propKeyCaptureTime]: {
      type: "bool",
      metaId: propKeyCaptureTime,
      name: propKeyCaptureTime,
      label: "Capture time",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,
    [propKeyCaptureUser]: {
      type: "bool",
      metaId: propKeyCaptureUser,
      name: propKeyCaptureUser,
      label: "Capture user",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowCapturedValuesOnAside]: {
      type: "enumCaptureValueKind",
      name: propKeyShowCapturedValuesOnAside,
      metaId: propKeyShowCapturedValuesOnAside,
      label: "Show captured values on aside"
    } as DefnFieldPickEnum

  };
}

export function defnValueToStudioFieldScanCode(values: FieldValues): StudioFieldScanCode
{
  return {
    type: "scanCode",
    barCode: fnFieldValueToRawValue("bool", values[propKeyBarCode]),
    qrCode: fnFieldValueToRawValue("bool", values[propKeyQrCode]),
    captureTime: fnFieldValueToRawValue("bool", values[propKeyCaptureTime]),
    captureLocation: fnFieldValueToRawValue("bool", values[propKeyCaptureLocation]),
    captureUser: fnFieldValueToRawValue("bool", values[propKeyCaptureUser]),
    showCapturedValuesOnAside: fnFieldValueToRawValue("enumCaptureValueKind", values[propKeyShowCapturedValuesOnAside])
  } as StudioFieldScanCode;
}

export function studioFieldScanCodeToDefnValue(studioField: StudioFieldScanCode): FieldValues
{
  return {
    [propKeyBarCode]: fnRawValueToFieldValue("bool", studioField.barCode),
    [propKeyQrCode]: fnRawValueToFieldValue("bool", studioField.qrCode),
    [propKeyCaptureLocation]: fnRawValueToFieldValue("bool", studioField.captureLocation),
    [propKeyCaptureTime]: fnRawValueToFieldValue("bool", studioField.captureTime),
    [propKeyCaptureUser]: fnRawValueToFieldValue("bool", studioField.captureUser),
    [propKeyShowCapturedValuesOnAside]: fnRawValueToFieldValue("enumCaptureValueKind",
      studioField.showCapturedValuesOnAside)
  };
}
