import {isEmpty} from "lodash";
import React from "react";
import {FieldError} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawPickManyAutoComplete from "../../../atom/raw/RawPickManyAutoComplete";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "./FieldRawRefButton";
import FieldRawTemplate from "./FieldRawTemplate";

export default function FieldRawStudioPick(props: {
  defn: DefnFieldEditable,
  optionMap: DefnStudioMapOfDtoOption,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
  autoComplete?: boolean,
  helperText?: string,
  sortOption?: boolean,
})
{
  const formCtx = useFormCtx();
  const searchText = formCtx.getSearchText();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldRequired,
    getFieldHelperText,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const sortOption = props.sortOption;
  const optionMap = sortOption ? sortMapByValue(props.optionMap) : props.optionMap;
  const field = props.fieldProps.field;
  const fieldState = props.fieldProps.fieldState;

  const fieldId = getFieldKey(defn);
  const label = !isEmpty(getCompLabel(defn))
    ? getCompLabel(defn)
    : "Value";

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;
  const fieldVariant = defnTheme.fieldVariant;

  const {error} = fieldState;

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const helperText = props.helperText || getFieldHelperText();
  const icon = getFieldIcon();

  return (
    <FieldRawTemplate
      defn={defn}
      fieldValue={field.value}
    >
      {
        <LayoutFlexRow
          width={"100%"}
          overflowX={"visible"}
          overflowY={"visible"}
          onClick={onClick}
        >
          <FieldPick
            fieldId={fieldId}
            searchText={searchText}
            disabled={disabled}
            error={error}
            fieldVariant={fieldVariant}
            fieldSize={defnTheme.fieldSize}
            hideLabel={defn.hideLabel}
            helperText={helperText}
            label={defn.hideLabel ? undefined : label}
            optionMap={optionMap}
            field={field}
            required={required}
            autoFocus={Boolean(defn.autoFocus)}
            placeHolder={placeHolder}
            readOnly={readOnly}
            showChip={props.showChip}
            multiSelect={props.multiSelect}
            onClick={onClick}
            autoComplete={props.autoComplete}
            icon={icon}
          />
          <FieldRawRefButton defn={defn} />
        </LayoutFlexRow>
      }
    </FieldRawTemplate>
  );

}

function FieldPick(props: {
  multiSelect?: boolean,
  searchText?: string,
  fieldId: string,
  disabled?: boolean,
  error?: FieldError,
  fieldVariant?: EnumDefnThemeFieldVariant,
  fieldSize?: EnumDefnThemeFieldSize,
  hideLabel?: boolean,
  helperText?: string,
  label?: string,
  optionMap?: DefnStudioMapOfDtoOption,
  field: ControllerRenderProps<FieldValues, string>,
  onClick?: (() => void),
  required?: boolean,
  autoFocus?: boolean,
  placeHolder?: string,
  readOnly?: boolean,
  showChip?: boolean,
  autoComplete?: boolean,
  icon?: string
})
{
  const onChangePickMany = (event: MetaIdOption[] | null) =>
  {
    if(event === null)
    {
      field.onChange(null);
    }
    else
    {
      field.onChange(event);
    }
  };

  const onChangePickOne = (event: MetaIdOption | null) =>
  {
    if(event === null)
    {
      field.onChange(null);
    }
    else
    {
      field.onChange(event);
    }
  };
  const field = props.field;
  const fieldValue = field.value;
  const options = props.optionMap;
  const multiSelect = props.multiSelect;
  const fieldId = props.fieldId;
  const disabled = props.disabled;
  const error = props.error;
  const fieldVariant = props.fieldVariant;
  const fieldSize = props.fieldSize;
  const hideLabel = props.hideLabel;
  const helperText = props.helperText;
  const label = props.label;
  const required = props.required;
  const autoFocus = props.autoFocus;
  const placeHolder = props.placeHolder;
  const readOnly = props.readOnly;
  const showChip = props.showChip;
  const searchText = props.searchText;
  const onClick = props.onClick;
  const icon = props.icon;

  const pickManyValue: MetaIdOption[] = [];

  if(fieldValue && Array.isArray(fieldValue) && options)
  {
    fieldValue.forEach((val) =>
    {
      if(val)
      {
        pickManyValue.push(val);
      }
    });
  }

  if(multiSelect)
  {
    return (
      <RawPickManyAutoComplete
        name={field.name}
        fieldId={fieldId}
        disabled={disabled}
        error={error}
        optionMap={options}
        fieldVariant={fieldVariant}
        fieldSize={fieldSize}
        hideLabel={hideLabel}
        searchWords={searchText ? [searchText] : undefined}
        helperText={helperText}
        label={label}
        icon={icon}
        value={pickManyValue}
        onChange={onChangePickMany}
        required={required}
        autoFocus={autoFocus}
        readOnly={readOnly}
        showChip={showChip}
        ref={field.ref}
        onBlur={field.onBlur}
        onClick={onClick}
        placeHolder={placeHolder}
      />
    );
  }
  else
  {
    return (
      <RawPickOneAutoComplete
        name={field.name}
        fieldId={fieldId}
        disabled={disabled}
        error={error}
        optionMap={options}
        searchWords={searchText ? [searchText] : undefined}
        fieldVariant={fieldVariant}
        fieldSize={fieldSize}
        hideLabel={hideLabel}
        helperText={helperText}
        label={label}
        value={fieldValue}
        readOnly={readOnly}
        onChange={onChangePickOne}
        required={required}
        autoFocus={autoFocus}
        placeHolder={placeHolder}
        onClick={props.onClick}
        onBlur={field.onBlur}
        icon={icon}
      />
    );
  }
}

function sortMapByValue(optionMap: DefnStudioMapOfDtoOption)
{
  const sortedMap = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  const optionArray = Object.values(optionMap.map);

  optionArray.sort((a, b) =>
  {
    const valueA = a.value || "";
    const valueB = b.value || "";
    return valueA.localeCompare(valueB);
  });

  optionArray.forEach(option =>
  {
    sortedMap.keys.push(option.metaId);
    sortedMap.map[option.metaId] = option;
  });

  return sortedMap;
}
