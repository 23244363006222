import React from "react";
import {StudioDtoLayoutGrid} from "../../api/meta/base/dto/StudioDtoLayoutGrid";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {EnumDefnLayoutGridKind} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import DialogNewLayoutGridBase from "./base/impl/DialogNewLayoutGridBase";

export default function DialogNewLayoutGridList(props: {
  formStore: FormStore,
  isFormReadOnly?: boolean,
  metaIdForm: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  values?: StudioDtoLayoutGrid,
  validationError?: IFormFieldError[],
  layoutType?: EnumDefnLayoutGridKind,
  onClickOk: (values: StudioDtoLayoutGrid) => void,
  onClose?: () => void,
  overrideCompositeIdSet?: MetaIdComposite[],
  isOverride?: boolean
})
{
  return <DialogNewLayoutGridBase {...props} layoutType={"list"} />;
}
