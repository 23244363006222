import {EntId} from "../../api/meta/base/Types";
import ISrvc from "../../base/ISrvc";
import {EnumStudioPluginStep} from "../../base/types/TypesStudio";
import {EnumStudioEntStep} from "../../base/types/TypesStudio";
import {EnumProductionPluginFooterTab} from "../../base/types/TypesStudio";
import {EnumProductionEntFooterTab} from "../../base/types/TypesStudio";
import {Srvc} from "../Srvc";
import {SrvcControlPanelDrawer} from "./drawer/SrvcControlPanelDrawer";
import {SrvcControlPanelMain} from "./main/SrvcControlPanelMain";

export default class SrvcControlPanel extends ISrvc
{
  public readonly drawer = new SrvcControlPanelDrawer();
  public readonly main = new SrvcControlPanelMain();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.drawer,
      this.main
    );
  }

  setCurrentTab(
    entId: EntId,
    currentTab: EnumProductionEntFooterTab | EnumProductionPluginFooterTab,
    currentStep?: EnumStudioEntStep | EnumStudioPluginStep)
  {
    Srvc.cache.production.setProductionCurrentTab({
      artifactId: entId,
      currentTab: currentTab,
      currentStep: currentStep
    });
  }

}
