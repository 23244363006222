import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioSetOfModule} from "../../../../api/meta/base/dto/DefnStudioSetOfModule";
import {StudioModuleMap} from "../../../../api/meta/base/dto/StudioModuleMap";
import {MetaIdModule} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

type TypeFieldValue = MetaIdModule[] | undefined;

export default function FieldStudioSetOfModule(props: {
  defn: DefnStudioSetOfModule
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioSetOfModule
        fieldProps={fieldProps}
        defn={defn}
      />}
    />
  );
}

function FieldRawStudioSetOfModule(props: {
  defn: DefnStudioSetOfModule,
  fieldProps: IControllerFieldProps,
})
{
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const moduleMap = formStore?.moduleMap;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const dtoOptions = useMemo(() => getOptions(moduleMap), [moduleMap]);

  const [options] = useInsertTrashOptions({
    type: "moduleMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
    multiSelect={true}
    showChip={true}
  />;
}

function getOptions(moduleMap?: StudioModuleMap): DefnDtoOption[]
{
  if(!moduleMap)
  {
    return [];
  }

  const options: DefnDtoOption[] = [];
  moduleMap.keys.forEach(moduleId =>
  {
    const modules = moduleMap?.map[moduleId];
    options.push({
      metaId: modules?.metaId,
      value: modules?.name
    });
  });

  return options;
}

