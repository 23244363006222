import {useTheme} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import {useMemo} from "react";
import {CssColor} from "../../../../base/plus/ThemePlus";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {getResolvedCellStyle} from "../DataGridPlus";
import {GRID_FONT_VARIANT} from "../DataGridPlus";

export default function GridCellDefault(props: ICellRendererParams<IDataGridCell> & {color?: CssColor})
{
  const theme = useTheme();
  const searchWords = (props.context as AgGridContext).searchWords;
  const colId = props.colDef?.colId;
  const isHyperLink = colId ? (props.context as AgGridContext).hyperLinkColIdMap?.[colId] : undefined;
  const cellValue = props.valueFormatted
    ? props.valueFormatted
    : props.value;

  const style = useMemo(() => getResolvedCellStyle(
    colId,
    (props.context as AgGridContext).layout?.styleMap,
    (props.context as AgGridContext).colIdToStyleIdMap,
    (props.context as AgGridContext).defnForm,
    props.data as IDataGridCell
  ), [colId, props.context, props.data]);

  return (
    <RawHighlighter
      value={typeof cellValue === "string" ? cellValue : `${cellValue ?? ""}`}
      width={"100%"}
      searchWords={searchWords}
      cursor={isHyperLink ? "pointer" : undefined}

      //style
      color={isHyperLink ? theme.common.hyperLinkColor : (style?.color || props.color || "textSecondary")}
      variant={style?.variant || GRID_FONT_VARIANT}
      textDecoration={isHyperLink ? "underline" : style?.textDecoration}
      bold={style?.isBold}
      italic={style?.isItalic}
    />
  );
}
