import {Stack} from "@mui/material";
import {useTheme} from "@mui/material";
import {Fab} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {EnumIconButton} from "../icon/IconButtonStrip";
import {EnumIconFAB} from "../icon/IconFAB";
import IconFAB from "../icon/IconFAB";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import {LayoutGap} from "../layout/LayoutGap";
import RawButtonStrip from "../raw/RawButtonStrip";
import RawTooltip from "../raw/RawTooltip";

export type EnumIconPosition =
  | "left"
  | "right"
  | "center";

export interface IPaneFooterButton
{
  position?: EnumIconPosition
  icon?: EnumIconButton,
  tooltip?: string,
  label?: string,
  disable?: boolean,
  node?: React.ReactNode
}

export interface IPaneFooterFab
{
  icon?: EnumIconFAB,
  position?: EnumIconPosition
  disable?: boolean,
  verticallyCentered?: boolean,
  tooltip?: string,
}

export default function PaneFooter(props: {
  children?: React.ReactNode,
  fabButton?: IPaneFooterFab,
  onFabClick?: () => void,
  footerButtonSet?: IPaneFooterButton[],
  onIconButtonClick?: (btnName: EnumIconButton) => void,
})
{
  const {
    children,
    fabButton,
    onFabClick,
    onIconButtonClick,
    footerButtonSet
  } = props;

  const theme = useTheme();
  const gap = theme.common.gapStd;
  const footerColor = theme.common.bgcolorActionBar;

  const {
    leftFooterNodeButtons,
    leftFooterIconButtons,
    leftFooterIconButtonsDisable,
    rightFooterNodeButtons,
    rightFooterIconButtons,
    rightFooterIconButtonsDisable,
    toolTipMap,
    labelMap
  } = getFooterButtons(footerButtonSet);

  const isLeftButtonsAvailable = leftFooterNodeButtons.length > 0 || leftFooterIconButtons.length > 0;
  const isRightButtonsAvailable = rightFooterNodeButtons.length > 0 || rightFooterIconButtons.length > 0;
  const isFooterButtonsAvailable = Boolean(isLeftButtonsAvailable || isRightButtonsAvailable);

  return (
    <Box
      bgcolor={footerColor}
      sx={{
        height: theme.common.heightFooter,
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: px(gap)
      }}
    >
      {fabButton?.icon &&
        <Stack
          bgcolor={footerColor}
          sx={{
            position: "absolute",
            ...!fabButton?.verticallyCentered && {bottom: "55%"},
            ...getStyle(fabButton?.position),
            borderRadius: "50%",
            zIndex: "1"
          }}
        >
          <RawTooltip
            title={fabButton?.tooltip}
          >
            {/* has to wrap with box because tooltip don't allow to use disabled children */}
            <Box component={"span"}>
              <Fab
                sx={{
                  boxShadow: "none"
                }}
                size="medium"
                color="primary"
                aria-label={fabButton.icon}
                onClick={onFabClick}
                disabled={fabButton?.disable}
              >
                <IconFAB type={fabButton.icon} />
              </Fab>
            </Box>
          </RawTooltip>

        </Stack>

      }

      {children ? children : <LayoutGap flexGrow={1} />}

      {isFooterButtonsAvailable &&
        <LayoutFlexRow
          width={"100%"}
          justifyContent={(isLeftButtonsAvailable && isRightButtonsAvailable)
            ? "space-between"
            : isLeftButtonsAvailable
              ? "start"
              : isRightButtonsAvailable
                ? "end"
                : undefined
          }
        >
          <LayoutFlexRow
            justifyContent={"start"}
          >
            {leftFooterNodeButtons}
            {leftFooterIconButtons.length > 0 &&
              <RawButtonStrip
                iconButtonList={leftFooterIconButtons}
                iconButtonDisable={leftFooterIconButtonsDisable}
                onClick={onIconButtonClick}
                toolTipMap={toolTipMap}
                labelMap={labelMap}
              />
            }
          </LayoutFlexRow>

          <LayoutFlexRow>
            {rightFooterNodeButtons}
            {rightFooterIconButtons.length > 0 &&
              <RawButtonStrip
                iconButtonList={rightFooterIconButtons}
                iconButtonDisable={rightFooterIconButtonsDisable}
                onClick={onIconButtonClick}
                toolTipMap={toolTipMap}
                labelMap={labelMap}
              />
            }
          </LayoutFlexRow>
        </LayoutFlexRow>
      }

    </Box>
  );
}

function getFooterButtons(footerButtonSet?: IPaneFooterButton[])
{
  const leftFooterNodeButtons = [] as React.ReactNode[];
  const leftFooterIconButtons = [] as EnumIconButton[];
  const leftFooterIconButtonsDisable = [] as EnumIconButton[];
  const rightFooterNodeButtons = [] as React.ReactNode[];
  const rightFooterIconButtons = [] as EnumIconButton[];
  const rightFooterIconButtonsDisable = [] as EnumIconButton[];
  const toolTipMap = {} as Record<EnumIconButton, string>;
  const labelMap = {} as Record<EnumIconButton, string>;

  footerButtonSet?.forEach((button) =>
  {
    const position = button.position;
    const icon = button.icon;
    const tooltip = button.tooltip;
    const label = button.label;
    const node = button.node;
    const disable = button.disable;

    if(position === "left")
    {
      if(icon)
      {
        if(tooltip)
        {
          toolTipMap[icon] = tooltip;
        }
        if(label)
        {
          labelMap[icon] = label;
        }
        if(disable)
        {
          leftFooterIconButtonsDisable.push(icon);
        }
        leftFooterIconButtons.push(icon);
      }
      if(node)
      {
        leftFooterNodeButtons.push(node);
      }
    }
    else
    {
      if(icon)
      {
        if(tooltip)
        {
          toolTipMap[icon] = tooltip;
        }
        if(label)
        {
          labelMap[icon] = label;
        }
        if(disable)
        {
          rightFooterIconButtonsDisable.push(icon);
        }
        rightFooterIconButtons.push(icon);
      }
      if(node)
      {
        rightFooterNodeButtons.push(node);
      }
    }
  });

  return {
    leftFooterNodeButtons,
    leftFooterIconButtons,
    leftFooterIconButtonsDisable,
    rightFooterNodeButtons,
    rightFooterIconButtons,
    rightFooterIconButtonsDisable,
    toolTipMap,
    labelMap
  };
}

function getStyle(iconPosition?: EnumIconPosition)
{
  switch(iconPosition)
  {
    case "left":
      return {
        left: "5%",
        transform: "translateX(-5%)"
      };
    case "right":
      return {
        right: "5%",
        transform: "translateX(5%)"
      };
    default:
      return {
        left: "50%",
        transform: "translateX(-50%)"
      };
  }
}
