import {Typography} from "@mui/material";
import React from "react";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";

export default function FieldRawTreeShell(props: {
  rawTree: React.ReactNode,
  buttonStrip?: React.ReactNode,
  label?: string,
  showButtonStripAtBottom?: boolean,
})
{
  const showAddBtnAtBottom = props.showButtonStripAtBottom ?? false;
  const buttonStrip = props.buttonStrip;

  return (
    <>
      <LayoutFlexCol
        width={"100%"}
      >
        {
          props.label &&
          <LayoutFlexRow
            pl={FORM_FIELD_MEDIUM_PADDING}
            pr={FORM_FIELD_MEDIUM_PADDING}
            pb={px(gapHalf)}
            justifyContent={"start"}
            width={"100%"}
            alignItems={"start"}
          >
            <Typography color={theme.common.color("textDisabled")}>
              {props.label}
            </Typography>
          </LayoutFlexRow>
        }

        <LayoutFlexRow
          width={"100%"}
          flexGrow={1}
        >
          {props.rawTree}
        </LayoutFlexRow>
      </LayoutFlexCol>

      {
        buttonStrip &&
        <>
          {showAddBtnAtBottom && <LayoutGap flexGrow={1} />}
          <LayoutFlexRow
            width={"100%"}
            justifyContent={"flex-end"}
            pr={px(gapStd)}
          >
            {buttonStrip}
          </LayoutFlexRow>
        </>
      }
    </>
  );
}
