import {PhoneNumberUtil} from "google-libphonenumber";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldMobileNumber} from "../../../api/meta/base/dto/DefnFieldMobileNumber";
import {FieldValueMobile} from "../../../api/meta/base/dto/FieldValueMobile";
import {MobileNumber} from "../../../api/meta/base/dto/MobileNumber";
import {StudioVarMobileNumber} from "../../../api/meta/base/dto/StudioVarMobileNumber";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";

export function fnVariableBuilderMobileNumber(fieldValueMobileNumber: string)
{
  function getVarComp()
  {
    return {
      [fieldValueMobileNumber]: {
        type: "mobileNumber",
        metaId: fieldValueMobileNumber,
        name: "Value",
        required: true
      } as DefnFieldMobileNumber
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarMobileNumber
  )
  {
    const mobileNumberStr = getMobileNumberString(variable.value?.value);
    valueMap[fieldValueMobileNumber] = fnRawValueToFieldValue("text", mobileNumberStr) as FieldValueMobile;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const mobileNumber = fnFieldValueToRawValue("text", valueMap[fieldValueMobileNumber] as FieldValueMobile) as string;
    if(!mobileNumber)
    {
      return;
    }

    return {
      value: {
        value: toMobileNumber(mobileNumber)
      }
    } as StudioVarMobileNumber;
  }

  function getMobileNumberString(value?: MobileNumber)
  {
    if(!value)
    {
      return undefined;
    }

    let str = "";
    if(value.countryCode)
    {
      str += "+" + value.countryCode;
    }

    if(value.nationalNumber)
    {
      str += value.nationalNumber;
    }

    return str;
  }

  function toMobileNumber(str?: string): MobileNumber | undefined
  {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedPhoneNumber = str && phoneNumberUtil.parseAndKeepRawInput(str);

    const countryCode = parsedPhoneNumber && parsedPhoneNumber.getCountryCode();
    const nationalNumber = parsedPhoneNumber && parsedPhoneNumber.getNationalNumber();

    return {
      countryCode: Number(countryCode),
      nationalNumber: Number(nationalNumber)
    };
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
