import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {useMemo} from "react";
import {CSSProperties} from "react";
import {useCallback} from "react";
import React from "react";
import {Draggable} from "react-beautiful-dnd";
import {dispatchKanban} from "../../../base/plus/KanbanPlus";
import {SelectKanban} from "../../../base/plus/KanbanPlus";
import {px} from "../../../base/plus/StringPlus";
import {KanbanSetSelectedItemId} from "../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanMoveItem} from "../../../base/slices/kanban/SliceKanbanSharedActions";
import {CbOnChangeKanban} from "../../../base/types/TypeKanban";
import {IKanbanItem} from "../../../base/types/TypeKanban";
import {CbOnClickKanbanVariant} from "../../../base/types/TypeKanban";
import {TypeKanbanColId} from "../../../base/types/TypeKanban";
import {CbOnClickKanban} from "../../../base/types/TypeKanban";
import {IKanbanBinderAll} from "../../../base/types/TypeKanban";
import {TypeKanbanItemId} from "../../../base/types/TypeKanban";
import {useAppSelector} from "../../app/AppHooks";
import {BoxPS} from "../../atom/box/BoxPS";
import BubbleRawForm from "../../atom/bubble/raw/bubbleForm/BubbleRawForm";
import {IMenuProps} from "../../atom/raw/RawMenu";
import {usePageCtx} from "../../ctx/CtxPage";
import useKanbanPubSub from "./hooks/KanbanPubSub";

export default function KanbanColItem<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  selectKanban: SelectKanban
  isDraggable: boolean,
  index: number,
  itemId: TypeKanbanItemId,
  columnId: TypeKanbanColId,
  maxWidth?: number,
  width?: CSSProperties["width"],
  cbOnClick?: CbOnClickKanban,
  cbOnChange?: CbOnChangeKanban,
  kanbanBinder?: IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  onItemSubscribe?: (itemId: TypeKanbanItemId) => void,
  onItemUnsubscribe?: (itemId: TypeKanbanItemId) => void,
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;

  const itemId = props.itemId;
  const index = props.index;
  const selectKanban = props.selectKanban;
  const isDraggable = props.isDraggable;

  const item = useAppSelector(state => selectKanban(state).itemsById[itemId]);
  const readonly = useAppSelector(state => selectKanban(state).readonly);

  useKanbanPubSub({
    selectKanban: selectKanban,
    itemId: itemId,
    cbOnClick: props.cbOnClick,
    kanbanBinder: props.kanbanBinder,
    onItemSubscribe: props.onItemSubscribe,
    onItemUnsubscribe: props.onItemUnsubscribe
  });

  if(isDraggable)
  {
    return (
      <Draggable
        key={itemId}
        draggableId={itemId}
        index={index}
        isDragDisabled={readonly || item?.isInvisibleSpreadsheetRow}
      >
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              userSelect: "none",
              pb: px(gapHalf),
              ...provided.draggableProps.style
            }}
          >
            <UiKanbanColItem {...props} />
          </Box>
        )}
      </Draggable>
    );
  }

  return (
    <Box
      sx={{
        userSelect: "none",
        pb: px(gapHalf)
      }}
    >
      <UiKanbanColItem {...props} />
    </Box>
  );
}

export function UiKanbanColItem<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  selectKanban: SelectKanban
  index: number,
  itemId: TypeKanbanItemId,
  columnId: TypeKanbanColId,
  maxWidth?: number,
  width?: CSSProperties["width"],
  cbOnChange?: CbOnChangeKanban,
  cbOnClick?: CbOnClickKanban,
  kanbanBinder?: IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  onItemSubscribe?: (itemId: TypeKanbanItemId) => void,
  onItemUnsubscribe?: (itemId: TypeKanbanItemId) => void,
})
{
  const pageCtx = usePageCtx();
  const theme = useTheme();
  const maxWidth = props.maxWidth;
  const itemId = props.itemId;
  const columnId = props.columnId;
  const onClick = props.cbOnClick;
  const onChange = props.cbOnChange;
  const selectKanban = props.selectKanban;
  const width = props.width;
  const kanbanBinder = props.kanbanBinder;
  const selectIsCommentable = kanbanBinder?.selectIsCommentable;
  const selectUserAvatar = kanbanBinder?.selectUserAvatar;
  const durationTransition = theme.common.durationTransition;
  const gapHalf = theme.common.gapHalf;

  const kanbanName = useAppSelector(state => selectKanban(state).kanbanName);
  const item = useAppSelector(state => selectKanban(state).itemsById[itemId] as IKanbanItem | undefined);
  const hideItemFooter = useAppSelector(state => selectKanban(state).hideItemFooter);
  const columnsByItemIds = useAppSelector(state => selectKanban(state).columnsByItemIds);
  const columnMap = useAppSelector(state => selectKanban(state).columnMap);
  const hideItemTitle = useAppSelector(state => selectKanban(state).hideItemTitle);
  const hideItemSectionName = useAppSelector(state => selectKanban(state).hideItemSectionName);
  const searchWords = useAppSelector(state => selectKanban(state).searchWords);
  const defnForm = useAppSelector(state => selectKanban(state).defnForm);
  const showCommentCount = useAppSelector(state => (selectIsCommentable && defnForm && item?.formValue?.createdBy)
    ? selectIsCommentable(state, itemId, defnForm.metaId, item?.formValue?.createdBy)
    : false);
  const isItemWithImage = useAppSelector(state => selectKanban(state).isItemWithMedia);
  const selectedItemId = useAppSelector(state => selectKanban(state).selectedItemId);
  const uiModeMobile = useAppSelector(state => selectKanban(state).uiModeMobile);
  const showMenu = useAppSelector(state => selectKanban(state).showMenu);
  const readonly = useAppSelector(state => selectKanban(state).readonly);

  const isItemSelected = selectedItemId === itemId;
  const invisibleSpreadsheetRow = item?.isInvisibleSpreadsheetRow;

  const hideFooter = useMemo(() =>
  {
    if(item?.sigSpreadsheetRowExpiry?.showTimer)
    {
      const InvisiblePercentage = item?.sigSpreadsheetRowExpiry?.remainingInvisibleProgressPercentage;
      const ReadPercentage = item?.sigSpreadsheetRowExpiry?.remainingReadProgressPercentage;
      if(InvisiblePercentage || ReadPercentage)
      {
        return false;
      }
    }
    return hideItemFooter;
  }, [hideItemFooter, item?.sigSpreadsheetRowExpiry]);

  const cbClickMenuItem = useCallback((destinationColumnId: TypeKanbanColId, destinationIndex: number) =>
  {
    const onChangeKanbanPayload = {
      itemId: itemId,
      sourceColumnId: columnId,
      destinationColumnId: destinationColumnId,
      destinationIndex: destinationIndex
    };

    dispatchKanban(kanbanName, kanbanMoveItem(onChangeKanbanPayload));
    onChange && onChange("changeItemColumn", onChangeKanbanPayload);
  }, [itemId, columnId, onChange]);

  const handleClickMoveTo = useCallback((menuAnchor: Element) =>
  {
    const menuProps = {} as IMenuProps;
    Object.keys(columnsByItemIds).forEach((id, index) =>
    {
      if(columnId !== id)
      {
        const title = columnMap[id].title;
        if(title)
        {
          menuProps[title] = {
            onClick: () => cbClickMenuItem(id, index)
          };
        }
      }
    });
    setTimeout(() =>
    {
      pageCtx.showMenu(menuAnchor, menuProps);
    }, durationTransition);

  }, [columnsByItemIds, durationTransition, columnId, columnMap, cbClickMenuItem, pageCtx]);

  const createMenuProps = useCallback((menuAnchor: Element) =>
  {
    if(!readonly)
    {
      return {
        "Move to": {
          onClick: () => handleClickMoveTo(menuAnchor)
        }
      } as IMenuProps;
    }

  }, [handleClickMoveTo, readonly]);

  const cbOnClick = useCallback((menuAnchor: Element, variant: CbOnClickKanbanVariant) =>
  {
    if(onClick && item)
    {
      const menuProps = createMenuProps(menuAnchor);
      onClick(menuAnchor, variant, itemId, columnId, item, menuProps, !isItemSelected);

      if(!readonly && (variant === "bubbleShell" || variant === "bubbleFooterComments"))
      {
        if(!isItemSelected)
        {
          dispatchKanban(kanbanName, KanbanSetSelectedItemId(itemId));
        }
        else
        {
          if(variant !== "bubbleFooterComments")
          {
            dispatchKanban(kanbanName, KanbanSetSelectedItemId(undefined));
          }
        }
      }
    }
  }, [onClick, item, createMenuProps, itemId, columnId, isItemSelected, kanbanName]);

  if(!item?.formValue && !invisibleSpreadsheetRow)
  {
    return (
      <Box
        width={width}
        justifyContent={"center"}
        ml={px(gapHalf)}
      >
        <BoxPS />
      </Box>
    );
  }
  return (
    <MemoBubbleForm
      maxWidth={maxWidth}
      bgColorShell={theme.common.bubbleBgcolorLeft}
      isFormWithMedia={isItemWithImage}
      isCallerSender={Boolean(item?.isCallerSender)}
      showComments={showCommentCount}
      hideTitle={hideItemTitle}
      hideSectionName={hideItemSectionName}
      hideFooter={hideFooter}
      creationTime={item?.creationTime || ""}
      defnForm={defnForm}
      rowCommentCount={item?.rowCommentCount}
      updatedKeySet={item?.updatedKeySet}
      formValue={item?.formValue}
      variant={item?.variant}
      searchWords={searchWords}
      disableMenuHover={true}
      isSelected={isItemSelected}
      fullWidth={uiModeMobile}
      justifyContent={"center"}
      titleColor={item?.formBubbleTitleColor}
      sigSpreadsheetRowExpiry={item?.sigSpreadsheetRowExpiry}
      isInvisibleSpreadsheetRow={invisibleSpreadsheetRow}
      selectUserAvatar={selectUserAvatar}
      cbOnClickChatItemForward={(onClick && !invisibleSpreadsheetRow)
        ? (menuAnchor) => cbOnClick(menuAnchor, "changeItem")
        : undefined}
      cbOnClickFooterComments={(onClick && !invisibleSpreadsheetRow)
        ? (menuAnchor) => cbOnClick(menuAnchor, "bubbleFooterComments")
        : undefined}
      cbOnClickFooterCaption={(onClick && !invisibleSpreadsheetRow)
        ? (menuAnchor) => cbOnClick(menuAnchor, "bubbleFooterCaption")
        : undefined}
      cbOnClickFooter={(onClick && !invisibleSpreadsheetRow)
        ? (menuAnchor) => cbOnClick(menuAnchor, "bubbleFooter")
        : undefined}
      cbOnClickMenu={(onClick && !invisibleSpreadsheetRow && showMenu)
        ? (menuAnchor) => cbOnClick(menuAnchor, "bubbleMenu")
        : undefined}
      cbOnClickBubbleShell={(onClick && !invisibleSpreadsheetRow)
        ? (menuAnchor) => cbOnClick(menuAnchor, "bubbleShell")
        : undefined}
    />
  );
}

const MemoBubbleForm = React.memo(BubbleRawForm);
