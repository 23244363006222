import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {isEmpty} from "lodash";
import {useMemo} from "react";
import React from "react";
import {DefnFieldFormList} from "../../../api/meta/base/dto/DefnFieldFormList";
import {prepare} from "../../../base/plus/FormPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {IListItemForm} from "../../../base/types/list/TypeListForm";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {DefnFieldUi} from "../../../base/types/TypesForm";
import {useAppSelector} from "../../app/AppHooks";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import RawNothingHere from "../../atom/raw/RawNothingHere";
import {useFormCtx} from "../../form/viewer/base/CtxForm";
import FieldFactory from "../../form/viewer/base/FieldFactory";
import {prepareFormForListItem} from "../../form/viewer/base/FormViewerPlus";
import {CbOnClickListItemInternal} from "../impl/ListItem";
import ListItemShell from "../impl/ListItemShell";

export function ListItemForm(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItemInternal,
})
{

  const formCtx = useFormCtx();
  if(isEmpty(formCtx))
  {
    return <RawNothingHere
      helperTextData={{
        title: "ListItemForm used outside of FormCtx",
        subtitle: "This component is only meant to be used inside a Form"
      }}
    />;
  }

  return <RealListItemForm {...props} />;

}

function RealListItemForm(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItemInternal,
})
{
  const formCtx = useFormCtx();
  const theme = useTheme();
  const itemId = props.itemId;
  const selectList = props.selectList;
  const defnForm = useAppSelector(state => selectList(state).defnForm);
  const defnFieldFormListId = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemForm | undefined)?.defnFieldFormListId);
  const id = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemForm | undefined)?.id);
  const rowId = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemForm | undefined)?.rowId);

  const bgColor = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemForm | undefined)?.bgColor);
  const pickedBgColor = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemForm | undefined)?.pickedBgColor);
  const isPicked = useAppSelector(state => selectList(state).pickItemIds?.[itemId]);
  const selectedItemId = useAppSelector(state => props.selectList(state).selectedItemId);
  // const itemHeight = useAppSelector(state => (selectList(state).itemsById[itemId] as IListItemForm |
  // undefined)?.itemHeight); const defaultItemHeight = useAppSelector(state => selectList(state).defaultItemHeight);

  const listLayoutType = useAppSelector(state => selectList(state).listLayoutType);

  const uiDefnForm = useMemo(() =>
  {
    if(defnForm?.compMap && defnFieldFormListId && rowId)
    {
      const defn = defnForm.compMap[defnFieldFormListId] as DefnFieldFormList;
      const form = prepareFormForListItem(defnForm, defn, rowId);
      if(form)
      {
        return prepare(form);
      }
    }
  }, [defnForm?.compMap]);

  if(!uiDefnForm || !defnFieldFormListId)
  {
    return null;
  }
  const defnTheme = formCtx.getDefnTheme();
  const displayCompositeId = uiDefnForm?.displayCompositeId;

  const cbOnClickFrom = formCtx.getOnClick();

  if(displayCompositeId)
  {
    const defnComp = uiDefnForm.compMap[displayCompositeId] as DefnFieldUi;

    return (
      <LayoutFlexCol
        key={id}
        width={"100%"}
        height={"100%"}
        bgcolor={isPicked ? pickedBgColor : (bgColor || theme.common.bgcolorContent)}
        border={listLayoutType === "card" ? `1px solid ${theme.common.bgcolorActionBar}` : undefined}
        borderRadius={listLayoutType === "card" ? px(theme.common.gapHalf) : undefined}
        onClick={() =>
        {
          const _selectedItemId = selectedItemId === itemId ? undefined : itemId;
          cbOnClickFrom && cbOnClickFrom(itemId, "listItem", Boolean(_selectedItemId));
        }}
      >
        <ListItemShell
          {...props}
          fullHeight={true}
          pl={0}
          pr={0}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              "& > .MuiStack-root": {
                height: "100%"
              }
            }}
          >
            <FieldFactory
              defnForm={uiDefnForm}
              defnTheme={defnTheme}
              defnComp={defnComp}
            />
          </Box>
        </ListItemShell>
      </LayoutFlexCol>
    );
  }
}

