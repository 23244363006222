import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldImage} from "../../../api/meta/base/dto/DefnFieldImage";
import {FieldValueImage} from "../../../api/meta/base/dto/FieldValueImage";
import {StudioVarImage} from "../../../api/meta/base/dto/StudioVarImage";
import {fieldVariableValueImage} from "../base/VariablePlus";

export function fnVariableBuilderImage(fieldValueImage: string)
{
  function getVarComp()
  {
    return {
      [fieldVariableValueImage]: {
        type: "image",
        metaId: fieldVariableValueImage,
        required: true,
        name: "Value",
        showSizeVar: true,
        showPreviewVar: true
      } as DefnFieldImage
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarImage
  )
  {
    const value = variable.value;

    valueMap[fieldVariableValueImage] = value
      ? {
        value: value?.value
      } as FieldValueImage
      : null;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const fieldValueImage = valueMap[fieldVariableValueImage] as FieldValueImage;
    if(fieldValueImage && fieldValueImage.value)
    {
      return {
        value: {
          value: fieldValueImage.value
        }
      } as StudioVarImage;
    }
    else
    {
      return {
        value: undefined
      };
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
