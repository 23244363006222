import ISrvc from "../../../base/ISrvc";
import SrvcCacheSpreadsheetEditor from "./ssEditor/SrvcCacheSpreadsheetEditor";
import SrvcCacheSpreadsheetRow from "./ssRow/SrvcCacheSpreadsheetRow";

export default class SrvcCacheSpreadsheet extends ISrvc
{
  public readonly ssRow = new SrvcCacheSpreadsheetRow();
  public readonly ssEditor = new SrvcCacheSpreadsheetEditor();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.ssRow,
      this.ssEditor
    );
  }

}
