// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgAddressBookContact} from './msg/MsgAddressBookContact';
import {MsgCallerNotificationSettingPut} from './msg/MsgCallerNotificationSettingPut';
import {MsgCallerProfilePatch} from './msg/MsgCallerProfilePatch';
import {MsgChatId} from '../base/msg/MsgChatId';
import {MsgDeviceId} from '../base/msg/MsgDeviceId';
import {MsgDrawerSearch} from './msg/MsgDrawerSearch';
import {MsgEntFilter} from './msg/MsgEntFilter';
import {MsgEntUserId} from '../base/msg/MsgEntUserId';
import {MsgGroupCandidateListGet} from './msg/MsgGroupCandidateListGet';
import {MsgGroupCreate} from './msg/MsgGroupCreate';
import {MsgGroupId} from '../base/msg/MsgGroupId';
import {MsgHandle} from '../../core/base/msg/MsgHandle';
import {MsgLastMessageGet} from './msg/MsgLastMessageGet';
import {MsgOffset} from '../main/msg/MsgOffset';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {ServiceName} from '../../meta/base/Types';
import {SigAddressBook} from './sig/SigAddressBook';
import {SigBadgeMap} from './sig/SigBadgeMap';
import {SigCallerDeviceList} from './sig/SigCallerDeviceList';
import {SigChatCandidateMap} from '../main/sig/SigChatCandidateMap';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigDrawerSearch} from './sig/SigDrawerSearch';
import {SigGroupAvatar} from './sig/SigGroupAvatar';
import {SigGroupCandidateMap} from './sig/SigGroupCandidateMap';
import {SigLastMessage} from '../main/sig/SigLastMessage';
import {SigRecentList} from './sig/SigRecentList';
import {SigStarMessageList} from './sig/SigStarMessageList';
import {SigUserAvatar} from './sig/SigUserAvatar';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocDrawer
{
  static readonly SN: ServiceName = "drawer";

  static addressBookContactPatch(msg: MsgAddressBookContact, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "addressBookContactPatch")
      .patch(msg, sigAcceptor);
  }

  static addressBookContactPut(msg: MsgAddressBookContact, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "addressBookContactPut")
      .put(msg, sigAcceptor);
  }

  static addressBookContactRemove(msg: MsgHandle, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "addressBookContactRemove")
      .delete(msg, sigAcceptor);
  }

  static addressBookGet(msg: MsgVersion, sigAcceptor: ISigAcceptor<SigAddressBook>): void
  {
    wsocCall<SigAddressBook>(ENT_ID_GLOBAL, WsocDrawer.SN, "addressBookGet")
      .get(msg, sigAcceptor);
  }

  static addressBookInvite(msg: MsgHandle, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "addressBookInvite")
      .post(msg, sigAcceptor);
  }

  static badgeMapGet(msg: MsgVersion, sigAcceptor: ISigAcceptor<SigBadgeMap>): void
  {
    wsocCall<SigBadgeMap>(ENT_ID_GLOBAL, WsocDrawer.SN, "badgeMapGet")
      .get(msg, sigAcceptor);
  }

  static callerDeviceListGet(sigAcceptor: ISigAcceptor<SigCallerDeviceList>): void
  {
    wsocCall<SigCallerDeviceList>(ENT_ID_GLOBAL, WsocDrawer.SN, "callerDeviceListGet")
      .get(undefined, sigAcceptor);
  }

  static callerDeviceRemove(msg: MsgDeviceId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "callerDeviceRemove")
      .delete(msg, sigAcceptor);
  }

  static callerNotificationSettingPut(entId: EntId, msg: MsgCallerNotificationSettingPut, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocDrawer.SN, "callerNotificationSettingPut")
      .put(msg, sigAcceptor);
  }

  static callerProfilePatch(msg: MsgCallerProfilePatch, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "callerProfilePatch")
      .patch(msg, sigAcceptor);
  }

  static drawerSearch(msg: MsgDrawerSearch, sigAcceptor: ISigAcceptor<SigDrawerSearch>): void
  {
    wsocCall<SigDrawerSearch>(ENT_ID_GLOBAL, WsocDrawer.SN, "drawerSearch")
      .get(msg, sigAcceptor);
  }

  static groupAvatarGet(entId: EntId, msg: MsgGroupId, sigAcceptor: ISigAcceptor<SigGroupAvatar>): void
  {
    wsocCall<SigGroupAvatar>(entId, WsocDrawer.SN, "groupAvatarGet")
      .get(msg, sigAcceptor);
  }

  static groupCandidateListGet(msg: MsgGroupCandidateListGet, sigAcceptor: ISigAcceptor<SigGroupCandidateMap>): void
  {
    wsocCall<SigGroupCandidateMap>(ENT_ID_GLOBAL, WsocDrawer.SN, "groupCandidateListGet")
      .get(msg, sigAcceptor);
  }

  static groupCreate(msg: MsgGroupCreate, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "groupCreate")
      .post(msg, sigAcceptor);
  }

  static groupExit(msg: MsgGroupId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocDrawer.SN, "groupExit")
      .delete(msg, sigAcceptor);
  }

  static lastMessageGet(entId: EntId, msg: MsgLastMessageGet, sigAcceptor: ISigAcceptor<SigLastMessage>): void
  {
    wsocCall<SigLastMessage>(entId, WsocDrawer.SN, "lastMessageGet")
      .get(msg, sigAcceptor);
  }

  static messageStar(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocDrawer.SN, "messageStar")
      .put(msg, sigAcceptor);
  }

  static messageUnStar(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocDrawer.SN, "messageUnStar")
      .put(msg, sigAcceptor);
  }

  static newChatCandidateListGet(msg: MsgEntFilter, sigAcceptor: ISigAcceptor<SigChatCandidateMap>): void
  {
    wsocCall<SigChatCandidateMap>(ENT_ID_GLOBAL, WsocDrawer.SN, "newChatCandidateListGet")
      .get(msg, sigAcceptor);
  }

  static recentItemPin(entId: EntId, msg: MsgChatId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocDrawer.SN, "recentItemPin")
      .put(msg, sigAcceptor);
  }

  static recentItemUnPin(entId: EntId, msg: MsgChatId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocDrawer.SN, "recentItemUnPin")
      .put(msg, sigAcceptor);
  }

  static recentListGet(msg: MsgEntFilter, sigAcceptor: ISigAcceptor<SigRecentList>): void
  {
    wsocCall<SigRecentList>(ENT_ID_GLOBAL, WsocDrawer.SN, "recentListGet")
      .get(msg, sigAcceptor);
  }

  static starMessageListGet(msg: MsgEntFilter, sigAcceptor: ISigAcceptor<SigStarMessageList>): void
  {
    wsocCall<SigStarMessageList>(ENT_ID_GLOBAL, WsocDrawer.SN, "starMessageListGet")
      .get(msg, sigAcceptor);
  }

  static userAvatarGet(entId: EntId, msg: MsgEntUserId, sigAcceptor: ISigAcceptor<SigUserAvatar>): void
  {
    wsocCall<SigUserAvatar>(entId, WsocDrawer.SN, "userAvatarGet")
      .get(msg, sigAcceptor);
  }
}