import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Badge} from "@mui/material";
import {IconButton} from "@mui/material";
import {BadgePropsColorOverrides} from "@mui/material/Badge/Badge";
import {OverridableStringUnion} from "@mui/types";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {EnumIconStrip} from "../../../base/types/TypesIcon";
import IconStrip from "../icon/IconStrip";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawTooltip from "./RawTooltip";

export type TypeIconStripBadgeMap = {
  [key in EnumIconStrip]?: number;
};

export type TypeIconStripColorMap = {
  [key in EnumIconStrip]?: string;
};

export type TypeIconStripBadgeColorMap = {
  [key in EnumIconStrip]?: OverridableStringUnion<
    "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning",
    BadgePropsColorOverrides
  >;
}

export default function RawIconStrip(props: {
  iconStripDisable?: EnumIconStrip[],
  iconStripBadgeMap?: TypeIconStripBadgeMap,
  iconStripBadgeColorMap?: TypeIconStripBadgeColorMap,
  iconStripColorMap?: TypeIconStripColorMap,
  iconStrip: EnumIconStrip[],
  onClick?: (id: EnumIconStrip, menuAnchor: Element) => void,
  toolTipMap?: Record<EnumIconStrip, string>,
  iconStripDisableRipple?: EnumIconStrip[],
})
{
  const {
    iconStrip,
    iconStripDisable,
    iconStripBadgeMap,
    iconStripColorMap = {},
    iconStripBadgeColorMap = {},
    iconStripDisableRipple,
    onClick
  } = props;
  const theme = useTheme();
  const gap = theme.common.gapQuarter;

  const getBadge = (id: EnumIconStrip) =>
  {
    if(iconStripBadgeMap)
    {
      return iconStripBadgeMap[id] ?? null;
    }
    return null;
  };

  return (
    <LayoutFlexRow
      justifyContent={"flex-end"}
      alignItems={"center"}
      width={"auto"}
      height={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
    >
      {iconStrip.map(icon =>
      {
        const disabled = iconStripDisable && iconStripDisable.includes(icon);
        const disabledRipple = iconStripDisableRipple && iconStripDisableRipple.includes(icon);

        return (
          <RawTooltip
            title={props.toolTipMap?.[icon]
              ? props.toolTipMap[icon]
              : tooltipIconStrip(icon)}
            key={icon}
          >
            <Box
              component={"span"}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
            >
              <IconButton
                disabled={disabled}
                disableRipple={disabledRipple}
                size={"small"}
                edge={"end"}
                sx={{
                  mr: px(gap)
                }}
                onClick={(e) =>
                {
                  e.stopPropagation();
                  e.preventDefault();
                  onClick && onClick(icon, e.currentTarget);
                }}
                tabIndex={-1}
              >
                <Badge
                  badgeContent={getBadge(icon)}
                  color={iconStripBadgeColorMap[icon] || "success"}
                  sx={{
                    zIndex: 0,
                    cursor: "pointer"
                  }}
                  tabIndex={-1}
                >
                  <IconStrip
                    value={icon}
                    disabled={disabled}
                    color={iconStripColorMap[icon]}
                  />
                </Badge>
              </IconButton>
            </Box>
          </RawTooltip>
        );
      })}
    </LayoutFlexRow>
  );
}

function tooltipIconStrip(icon: EnumIconStrip)
{
  switch(icon)
  {
    case "share":
      return "Share";
    case "download":
      return "Download";
    case "upload":
      return "Upload";
    case "print":
      return "Print";
    case "close":
      return "Close";
    case "star":
      return "Star message";
    case "filter":
      return "Filter";
    case "filterClear":
      return "Clear filter";
    case "openInFull":
      return "Full screen";
    case "miniMize":
      return "Minimize";
    case "smile":
      return "Positive";
    case "send":
      return "Send";
    case "forward":
      return "Forward";
    case "delete":
      return "Remove";
    case "map":
      return "Map";
    case "more":
    case "moreHorizontal":
      return "Menu";
    case "copy":
      return "Copy";
    case "copyUserId":
      return "Copy user id";
    case "copyEntId":
      return "Copy ent id";
    case "save":
      return "Save";
    case "help":
      return "Help";
    case "error":
      return "Error";
    case "search":
      return "Search";
    case "table":
      return "Table";
    case "unStar":
      return "Unstar message";
    case "unStarAll":
      return "Unstar all";
    case "document":
      return "Document";
    case "currentLocation":
      return "Current location";
    case "edit":
      return "Edit";
    case "grid":
      return "Grid";
    case "list":
      return "List";
    case "expand":
      return "Expand";
    case "settings":
      return "Settings";
    case "hyperlink":
      return "Hyperlink";
    case "chat":
      return "Chat";
    case "terminal":
      return "Terminal";
    case "visible":
      return "Preview";
    case "refresh":
      return "Refresh";
    default:
      return "";
  }
}
