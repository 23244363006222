import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {MuiOtpInput} from "mui-one-time-password-input";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldOtp} from "../../../../api/meta/base/dto/DefnFieldOtp";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldOtp(props: {
  defn: DefnFieldOtp,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const otpDigitSize = defn.otpDigitSize;

  const {
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);

  const label = getCompLabel(defn);
  const helperText = getFieldHelperText();
  const autoFocus = defn.autoFocus;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueText;
        const onChange = (value?: string) => field.onChange(fnRawValueToFieldValue("text", value));

        const handleChange = (newValue: string) =>
        {
          onChange(newValue);
        };

        const onPaste = (e: React.ClipboardEvent) =>
        {
          e.preventDefault();
          const paste = e.clipboardData.getData("text");
          if(!isNaN(Number(paste)))
          {
            onChange(paste);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <MuiOtpInput
                justifyContent={"space-between"}
                sx={{
                  ".MuiOtpInput-TextField": {width: px(56)}
                }}
                gap={px(6)}
                length={otpDigitSize ?? 6}
                value={fieldValue?.value}
                onChange={handleChange}
                validateChar={(val) => !isNaN(Number(val))}
                onPaste={onPaste}
                autoFocus={autoFocus}
              />

              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
