import {useMemo} from "react";
import React from "react";
import {DefnStudioPickGridId} from "../../../../api/meta/base/dto/DefnStudioPickGridId";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {getPickCompositeOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {isSystemForm} from "../../../../routes/studio/ent/deploy/plugins/StudioEntDeployPluginPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdGrid | MetaIdGrid[] | undefined

export default function FieldRawStudioPickGridId(props: {
  defn: DefnStudioPickGridId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean
})
{
  const defnStudioPickGridId = props.defn;
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const formMap = formStore?.formMap;
  const sysFormMap = formStore?.sysFormMap;

  const excludeGridIdSet = defnStudioPickGridId.excludeGridIdSet;
  const formId = defnStudioPickGridId.formId;
  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const studioForm = formId
    ? isSystemForm(formId)
      ? sysFormMap?.map[formId]
      : formMap?.map[formId]
    : undefined;

  const dtoOptions = useMemo(() => getOptions(
    studioForm, excludeGridIdSet
  ), [excludeGridIdSet, studioForm]);

  const [options] = useInsertTrashOptions({
    type: "compositeMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOptions(form?: StudioForm, excludeGridIdSet?: string[])
{
  let options = form
    ? getPickCompositeOptions(form, "grid")
    : [];

  if(excludeGridIdSet?.length)
  {
    options = options.filter(option => !excludeGridIdSet.includes(option.metaId));
  }

  return options;
}
