import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioSetOfFieldRefId} from "../../../../api/meta/base/dto/DefnStudioSetOfFieldRefId";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {AllEditableType} from "../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {loopStudioForm} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioSetOfFieldRefId(props: {
  defn: DefnStudioSetOfFieldRefId
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <RealFieldStudioSetOfFieldRefId
        defn={props.defn}
        fieldProps={fieldProps}
      />}
    />
  );
}

function RealFieldStudioSetOfFieldRefId(props: {
  defn: DefnStudioSetOfFieldRefId
  fieldProps: IControllerFieldProps,
})
{

  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldProps = props.fieldProps;
  const fieldValue = fieldProps.field.value as MetaIdField[];

  const formStore = formCtx.getStore();
  const formMap = formStore?.formMap;
  const spreadsheetMap = formStore?.spreadsheetMap;
  const spreadsheetId = defn?.spreadsheetId;
  const showCompositeName = defn.showCompositeName;
  const spreadsheet = spreadsheetId
    ? spreadsheetMap?.map[spreadsheetId] || formStore?.trash?.spreadsheetMap?.[spreadsheetId]
    : undefined;
  const formId = spreadsheet?.formId;

  const studioForm = formId && formMap?.map[formId];
  const dtoOptions = useMemo(() => studioForm
    ? getPickFieldOptions(studioForm, AllEditableType, showCompositeName)
    : [], [showCompositeName, studioForm]);

  const [options] = useInsertTrashOptions({
    type: "fieldMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return (

    <FieldRawStudioPick
      fieldProps={fieldProps}
      defn={props.defn}
      optionMap={optionsToMapOfOption(options)}
      multiSelect={true}
      showChip={true}
    />
  );
}

function getPickFieldOptions(
  studioForm: StudioForm,
  includeFieldType?: string[],
  showCompositeName?: boolean): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];
  loopStudioForm(studioForm, (composite, field) =>
  {
    const label = (showCompositeName && composite.type === "grid")
      ? `${composite.details.name}.${field.details.name}`
      : field.details.name;

    const fieldMetaId = field.metaId;

    if(field && field.type && composite.type === "section")
    {
      if((includeFieldType && includeFieldType.includes(field.type)) || !includeFieldType)
      {
        options.push({
          value: label,
          metaId: fieldMetaId
        });
      }
    }

  });

  const systemFields = EnumArrayDefnFields.map((item) => ({
    value: item,
    metaId: item
  }) as DefnDtoOption);

  return [...options, ...systemFields];
}
