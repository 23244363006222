import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {useEffect} from "react";
import {useCallback} from "react";
import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {ValidationError} from "yup";
import {Schema} from "yup";
import {DefnDtoLayoutOverlaySpreadsheet} from "../../../../api/meta/base/dto/DefnDtoLayoutOverlaySpreadsheet";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnLayoutGrid} from "../../../../api/meta/base/dto/DefnLayoutGrid";
import {FieldDtoGridRow} from "../../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {calcSchemaField} from "../../../../base/plus/FormYupPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {IFormRef} from "../../../../base/types/TypesForm";
import helperTextData from "../../../../nucleus/atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../../../../nucleus/atom/raw/RawNothingHere";
import {CbOnClickForm} from "../../../../nucleus/form/viewer/base/CtxForm";
import Form from "../../../../nucleus/form/viewer/Form";
import {FieldValueFormListItem} from "../../../../nucleus/form/viewer/uiOnly/FieldFormList";
import {ISsBrItemUserField} from "../../../../nucleus/ssBr/TypesSsBr";
import SrvcRefBr from "../../../../srvc/app/form/SrvcRefBr";
import {Srvc} from "../../../../srvc/Srvc";

import {useRefBrCalcFormValue} from "./UiRefBrPlus";

export default function UiRefBrForm<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  cbRefForm?: IFormRef,
  entId: EntId,
  formId: MetaIdForm,
  compositeId: MetaIdForm,
  isMultiPick: boolean;
  displayForm?: DefnForm,
  defnForm: DefnForm,
  sourceDefnForm?: DefnForm,
  initValues?: FieldValues,
  fieldValueGrid?: FieldValueGrid,
  layout?: DefnLayoutGrid,
  overlayLayout?: DefnDtoLayoutOverlaySpreadsheet,
  selectList: SelectList,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
  cbOnClickFormItem?: CbOnClickForm,
  cbOnSubmit?: (value: FieldValues, fieldDtoGridRow?: FieldDtoGridRow) => void,
  cbOnSubmitEnable?: (enable: boolean) => void,
  cbOnSelectedItemsCountChange?: (count: number) => void,
  srvcRefBr: SrvcRefBr
})
{
  const theme = useTheme();
  const layout = props.layout;
  const listBinder = props.listBinder;
  const cbOnSubmit = props.cbOnSubmit;
  const selectList = props.selectList;
  const initValues = props.initValues;
  const fieldValueGrid = props.fieldValueGrid;
  const displayForm = props.displayForm;
  const defnForm = props.defnForm;
  const compositeId = props.compositeId;
  const isMultiPick = props.isMultiPick;
  const sourceDefnForm = props.sourceDefnForm;
  const overlayLayout = props.overlayLayout;
  const cbOnSubmitEnable = props.cbOnSubmitEnable;
  const cbOnClickFormItem = props.cbOnClickFormItem;
  const cbOnSelectedItemsCountChange = props.cbOnSelectedItemsCountChange;
  const srvc = props.srvcRefBr;
  const fieldFormList = srvc.fnGetRefBrListFieldName();
  const formRef = useRef<IFormRef>({} as IFormRef);

  const {
    calcSubmitGridValue,
    calcValuesSinglePick,
    selectedItemsCount,
    onWatch,
    submitEnable,
    editableFieldIdSet
  } = useRefBrCalcFormValue({
    defnForm: defnForm,
    sourceDefnForm: sourceDefnForm,
    selectList: selectList,
    // gridValue: props.gridValue,
    isMultiPick: isMultiPick,
    layout: layout,
    overlayLayout: overlayLayout,
    srvcRefBr: srvc
  });

  const onSubmit = useCallback(() =>
  {
    if(isMultiPick && compositeId)
    {
      const _fieldValueGrid = calcSubmitGridValue();
      if(_fieldValueGrid || fieldValueGrid)
      {
        if(!srvc.hasKeyFieldIdSet())
        {
          const keys = new Set([
            ...fieldValueGrid?.keys || [],
            ..._fieldValueGrid.keys
          ]);
          _fieldValueGrid.keys = [...keys];
          _fieldValueGrid.map = {
            ...fieldValueGrid?.map,
            ..._fieldValueGrid.map
          };
        }
      }

      const errors = validateEditableFields(defnForm, _fieldValueGrid, editableFieldIdSet);
      if(errors.length)
      {
        const errorString = errors.join(" and ");
        Srvc.app.toast.showErrorToast(errorString);
        formRef.current.setReadonly(false);
        formRef.current.checkSubmit();
      }
      else
      {
        formRef.current.checkSubmit();
        const valueMap = {
          [compositeId]: _fieldValueGrid || null
        };
        cbOnSubmit && cbOnSubmit(valueMap);
      }
    }
  }, [calcSubmitGridValue, cbOnSubmit, compositeId, isMultiPick, editableFieldIdSet]);

  const onClickFormItem = useCallback<CbOnClickForm>((metaId, variant, value) =>
  {
    if(variant === "listItem")
    {
      if(!isMultiPick)
      {
        const fieldValue = formRef.current.getValue(fieldFormList)?.map[metaId] as FieldValueFormListItem;
        const fieldDtoGridRow = (fieldValue?.userField as ISsBrItemUserField)?.fieldDtoGridRow;
        const selectedValue = calcValuesSinglePick(fieldValue);
        if(selectedValue)
        {
          cbOnSubmit && cbOnSubmit(selectedValue, fieldDtoGridRow);
        }
      }
    }
    cbOnClickFormItem && cbOnClickFormItem(metaId, variant, value);

  }, [calcValuesSinglePick, cbOnClickFormItem, cbOnSubmit, fieldFormList, isMultiPick]);

  useEffect(() =>
  {
    cbOnSelectedItemsCountChange && cbOnSelectedItemsCountChange(selectedItemsCount);
  }, [selectedItemsCount]);

  useEffect(() =>
  {
    cbOnSubmitEnable && cbOnSubmitEnable(submitEnable);
  }, [submitEnable]);

  if(!displayForm)
  {
    return <RawNothingHere helperTextData={helperTextData.nothingToShow} />;
  }

  if(props.cbRefForm)
  {
    formRef.current = props.cbRefForm;
  }

  return (
    <Box
      flex={1}
      width={"100%"}
      bgcolor={theme.common.bgcolorContent}
    >
      <Form
        cbRef={props.cbRefForm || formRef.current}
        defnForm={displayForm}
        initValues={initValues}
        selectListMap={{[fieldFormList]: selectList}}
        listBinderMap={listBinder
          ? {[fieldFormList]: listBinder}
          : undefined}
        onClickFormItem={onClickFormItem}
        onSubmit={onSubmit}
        onWatch={(key, value) =>
        {
          onWatch(key, value, formRef.current.getValues());
        }}
      />
    </Box>
  );
}

function validateEditableFields(defnForm: DefnForm, fieldValueGrid: FieldValueGrid, editableFieldIdSet: string[])
{
  let errors: string[] = [];
  if(!editableFieldIdSet.length)
  {
    return errors;
  }

  fieldValueGrid.keys.forEach(rowId =>
  {
    const gridValue = fieldValueGrid.map[rowId];
    gridValue.valueMap && Object.entries(gridValue.valueMap).forEach(([key, value]) =>
    {
      const comp = defnForm.compMap[key] as DefnFieldUi;
      if(!editableFieldIdSet.includes(key))
      {
        return;
      }

      const fieldSchema = calcSchemaField(defnForm, comp) as Schema;
      if(!fieldSchema)
      {
        return;
      }

      try
      {
        fieldSchema.validateSync(value);
      }
      catch(e)
      {
        const msg = comp.name + ": " + (e as ValidationError).message;
        errors.push(msg);
      }
    });
  });

  return errors;
}
