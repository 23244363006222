import {StudioEntActionMap} from "../../../api/meta/base/dto/StudioEntActionMap";
import {StudioEntActionReport} from "../../../api/meta/base/dto/StudioEntActionReport";
import {StudioEntActionRowInsert} from "../../../api/meta/base/dto/StudioEntActionRowInsert";
import {StudioEntReportMap} from "../../../api/meta/base/dto/StudioEntReportMap";
import {StudioEntSpreadsheetMap} from "../../../api/meta/base/dto/StudioEntSpreadsheetMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntActions extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entActionList;
  }

  loadEntActionList(
    entId: EntId,
    listName: string,
    actionMap?: StudioEntActionMap,
    reportMap?: StudioEntReportMap,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    readOnly?: boolean)
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    actionMap && actionMap.keys.forEach((itemId) =>
    {
      const action = actionMap.map[itemId];
      const actionDetails = action.details;
      const modules: StudioModuleSelection | undefined = actionDetails.modules;

      if(Srvc.studio.ent.filterListByTag(entId, modules))
      {
        uiItemIds.push(itemId);
      }

      const reportAction = action as StudioEntActionReport;
      const spreadsheetAction = action as StudioEntActionRowInsert;
      const reportName = reportMap && reportAction.reportId && reportMap.map[reportAction.reportId]?.details.name;
      const spreadsheetName = spreadsheetMap && spreadsheetAction.spreadsheetId
        && spreadsheetMap.map[spreadsheetAction.spreadsheetId]?.details.name;

      const description: ILineSecondary = {};

      if(action.details.description && action.details.description.length > 0)
      {
        description.text = action.details.description;
      }
      else
      {
        description.text = "No description";
        description.color = "textDisabled";
      }

      description.caption = {
        text: action.kind === "report" ? reportName : spreadsheetName,
        ignoreSelection: true
      };

      uiItemsById[itemId] = {
        type: "ps",
        hideMenu: readOnly,
        primary: {
          text: action.details.name,
          caption: {
            text: action.kind ? toLabel(action.kind) : undefined,
            ignoreSelection: true
          }
        },
        secondary: description,
        userField: {
          actionType: action.kind
        }
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }
}
