import ISrvc from "../../../../base/ISrvc";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {updateEntDeployPayment} from "../SliceCacheStudioEnt";

import {ActionUpdateEntDeployPayment} from "../TypesCacheStudioEnt";

export default class SrvcCacheStudioEntDeployPayment extends ISrvc
{
  updateEntDeployPayment(payload: ActionUpdateEntDeployPayment)
  {
    dispatchEnt(payload.entId, updateEntDeployPayment(payload));
  }
}
