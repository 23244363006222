import {MsgSpreadsheetRowCommentCountGet} from "../../../../api/ent/entMain/msg/MsgSpreadsheetRowCommentCountGet";
import {WsocEntMain} from "../../../../api/ent/entMain/WsocEntMain";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {SpreadsheetPartitionId} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {EnvError} from "../../../../api/nucleus/base/dto/EnvError";
import {isNotAccessibleSignal} from "../../../../api/nucleus/base/Protocol";
import {isNotFoundSignal} from "../../../../api/nucleus/base/Protocol";
import ISrvc from "../../../../base/ISrvc";
import helperTextData from "../../../../nucleus/atom/assets/PlaceholderTextHome.json";
import {selectCacheRow} from "../../../../srvc/app/spreadsheet/SrvcSpreadsheet";
import {selectCacheRowId} from "../../../../srvc/app/spreadsheet/SrvcSpreadsheet";
import {selectCacheSpreadsheetId} from "../../../../srvc/app/spreadsheet/SrvcSpreadsheet";
import {Srvc} from "../../../../srvc/Srvc";
import {store} from "../../../../Store";
import {selectCallerEnt} from "../../callerEnt/SrvcCacheCallerEnt";
import {removeCacheSsRow} from "./StoreCacheSpreadsheetRow";
import {setBulkCacheSsRow} from "./StoreCacheSpreadsheetRow";
import {setBulkCacheSsPartitionIdToRowIdMap} from "./StoreCacheSpreadsheetRow";
import {setBulkCacheSsRowIdToSsIdMap} from "./StoreCacheSpreadsheetRow";
import {removeBulkCacheSsRow} from "./StoreCacheSpreadsheetRow";
import {setCacheSsPartitionIdToRowIdMap} from "./StoreCacheSpreadsheetRow";
import {setIfExistCacheSsRowCommentCount} from "./StoreCacheSpreadsheetRow";
import {setCacheSsRowExpiry} from "./StoreCacheSpreadsheetRow";
import {removeCacheSsRowExpiry} from "./StoreCacheSpreadsheetRow";
import {setCacheSsRow} from "./StoreCacheSpreadsheetRow";
import {setCacheSsRowIdToSsIdMap} from "./StoreCacheSpreadsheetRow";

const expiredMsg = helperTextData.formDataVisibilityExpired.title;

export default class SrvcCacheSpreadsheetRow extends ISrvc
{
  setRowIdToSsIdMap(entId: EntId, rowId: RowId, spreadsheetId: MetaIdSpreadsheet): void
  {
    store.dispatch(setCacheSsRowIdToSsIdMap({
      entId: entId,
      rowId: rowId,
      spreadsheetId: spreadsheetId
    }));
  }

  setSsPartitionIdToRowIdMap(entId: EntId, spreadsheetPartitionId: SpreadsheetPartitionId, rowId: RowId): void
  {
    store.dispatch(setCacheSsPartitionIdToRowIdMap({
      entId: entId,
      rowId: rowId,
      spreadsheetPartitionId: spreadsheetPartitionId
    }));
  }

  setBulkRowIdToSsIdMap(entId: EntId, rowIds: RowId[], spreadsheetId: MetaIdSpreadsheet): void
  {
    store.dispatch(setBulkCacheSsRowIdToSsIdMap({
      entId: entId,
      rowIds: rowIds,
      spreadsheetId: spreadsheetId
    }));
  }

  setBulkSsPartitionIdToRowIdMap(entId: EntId, rowMap: Record<RowId, SigSpreadsheetRow>): void
  {
    store.dispatch(setBulkCacheSsPartitionIdToRowIdMap({
      entId: entId,
      rowMap: rowMap
    }));
  }

  setCacheSpreadsheetBulkRow(
    entId: EntId,
    rowIds: RowId[],
    spreadsheetId: MetaIdSpreadsheet,
    cbSuccess?: (rowMap: Record<RowId, SigSpreadsheetRow>) => void)
  {
    this.setBulkRowIdToSsIdMap(entId, rowIds, spreadsheetId);
    Srvc.app.spreadsheet.rpcSpreadsheetBulkRowGet(entId, rowIds, spreadsheetId, (sig) =>
    {
      const rowMap = sig.rowMap;
      if(rowMap)
      {
        store.dispatch((dispatch, getState) =>
        {
          const rootState = getState();
          const canExpire = Boolean(selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.canExpire);
          if(canExpire)
          {
            this.setBulkSsPartitionIdToRowIdMap(entId, rowMap);
          }
          dispatch(setBulkCacheSsRow({
            entId: entId,
            rowMap: rowMap
          }));
        });
        cbSuccess && cbSuccess(rowMap);
      }
      else
      {
        cbSuccess && cbSuccess({});
      }
      if(sig.expiredRowIdSet)
      {
        Srvc.cache.app.spreadsheet.ssEditor.setSsEditorRowsState(sig.expiredRowIdSet, spreadsheetId, {
          error: expiredMsg,
          removed: false
        });
      }
    }, () =>
    {
      cbSuccess && cbSuccess({});
    });
  }

  setCacheSpreadsheetRow(
    entId: EntId,
    rowId: RowId,
    cbSuccess?: (sig: SigSpreadsheetRow) => void,
    onError?: (error: EnvError) => void): void
  {
    const rootState = store.getState();
    const spreadsheetId = selectCacheSpreadsheetId(rootState, entId, rowId);
    const spreadsheetRow = selectCacheRow(rootState, entId, rowId);
    if(spreadsheetId)
    {
      Srvc.app.spreadsheet.wsocSpreadsheetRowGet(entId, rowId, spreadsheetId, spreadsheetRow?.version,
        (sig) =>
        {
          store.dispatch(setCacheSsRow({
            entId: entId,
            rowId: rowId,
            sig: sig
          }));
          cbSuccess && cbSuccess(sig);
        }, (envSignal) =>
        {
          const errorNotFound = isNotFoundSignal(envSignal);
          const errorNotAccessible = isNotAccessibleSignal(envSignal);
          if(errorNotFound || errorNotAccessible)
          {
            if(errorNotFound)
            {
              store.dispatch(removeCacheSsRow({
                entId: entId,
                rowId: rowId
              }));
            }

            Srvc.cache.app.spreadsheet.ssEditor.setSsEditorRowState(rowId, spreadsheetId, {
              removed: Boolean(errorNotFound),
              error: errorNotAccessible ? expiredMsg : undefined
            });
          }
          onError && onError(envSignal);
        }
      );
    }
  }

  setCacheSpreadsheetRowComments(
    entId: EntId,
    rowId: RowId): void
  {
    const rootState = store.getState();
    const spreadsheetId = selectCacheSpreadsheetId(rootState, entId, rowId);
    const spreadsheetRow = selectCacheRow(rootState, entId, rowId);

    if(spreadsheetId)
    {
      const msg: MsgSpreadsheetRowCommentCountGet = {
        rowId: rowId,
        version: spreadsheetRow?.version
      };
      WsocEntMain.spreadsheetRowCommentCountGet(entId, msg, (envSig) =>
      {
        if(envSig.sig)
        {
          store.dispatch(setIfExistCacheSsRowCommentCount({
            entId: entId,
            rowId: rowId,
            sig: envSig.sig
          }));
        }
      });
    }
  }

  removeCacheSpreadsheetRows(entId: EntId, rowIds: RowId[]): void
  {
    store.dispatch(removeBulkCacheSsRow({
      entId: entId,
      rowIds: rowIds
    }));
  }

  setCacheSpreadsheetRowExpiry(entId: EntId, spreadsheetPartitionId: SpreadsheetPartitionId)
  {
    const rootState = store.getState();
    const rowId = selectCacheRowId(rootState, entId, spreadsheetPartitionId);
    const spreadsheetId = rowId ? selectCacheSpreadsheetId(rootState, entId, rowId) : undefined;

    if(spreadsheetId && rowId)
    {
      Srvc.app.spreadsheet.wsocSpreadsheetRowExpiryGet(
        entId,
        spreadsheetPartitionId,
        spreadsheetId,
        undefined,
        (sig) =>
        {
          store.dispatch(setCacheSsRowExpiry({
            entId: entId,
            rowId: rowId,
            sig: sig
          }));
        }
      );
    }
  }

  removeCacheSpreadsheetRowExpiry(entId: EntId, spreadsheetPartitionId: SpreadsheetPartitionId): void
  {
    store.dispatch(removeCacheSsRowExpiry({
      entId: entId,
      spreadsheetPartitionId: spreadsheetPartitionId
    }));
  }
}
