import {PinRounded} from "@mui/icons-material";
import React from "react";
import {textIconSx} from "../../../base/plus/ThemePlus";
import {EnumIconListItemPrimary2} from "../../../base/types/TypesIcon";

export default function IconListItemPrimary2(props: {
  value?: EnumIconListItemPrimary2
})
{
  const icon = props.value;
  if(icon === undefined)
  {
    return null;
  }

  const sx = textIconSx();
  switch(icon)
  {
    case "pin":
      return <PinRounded sx={sx} />;
  }
}
