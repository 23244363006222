import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {ENT_ID_GLOBAL} from "../../../../api/meta/base/ApiPlus";
import {nextMediaIdJar} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfJarFile} from "../../../../api/meta/base/dto/DefnStudioMapOfJarFile";
import {StudioDtoJarFile} from "../../../../api/meta/base/dto/StudioDtoJarFile";
import {StudioMapOfJarFile} from "../../../../api/meta/base/dto/StudioMapOfJarFile";
import {MediaIdJar} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {uploadMedia} from "../../../../base/plus/MediaPlus";
import {generateMD5File} from "../../../../base/plus/StringPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {ILineSecondaryText} from "../../../../base/types/TypesGlobal";
import {ILineSecondaryAttributedText} from "../../../../base/types/TypesGlobal";
import {Srvc} from "../../../../srvc/Srvc";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {useFileSelectorCtx} from "../../../atom/raw/RawFileSelector";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {usePageCtx} from "../../../ctx/CtxPage";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfJarFile(props: {
  defn: DefnStudioMapOfJarFile
})
{
  const defnFieldResourcesInput = props.defn;

  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const fieldId = getFieldKey(defnFieldResourcesInput);
  const selectList = formCtx.getSelectList(fieldId);
  const formReadOnly = formCtx.isReadonly();

  // const isLastField = formCtx.isLastFieldInDisplay(defnFieldResourcesInput.metaId);
  const isLastField = false;
  const label = defnFieldResourcesInput.label;

  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const readOnly = formCtx.isFieldReadonly(defnFieldResourcesInput);
  const disable = formCtx.isFieldDisable(defnFieldResourcesInput) || defnFieldResourcesInput.disabled;

  return (
    <Controller
      name={defnFieldResourcesInput.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioMapOfJarFile;
        const onChange = field.onChange;
        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfResourceFile
                  fieldId={fieldId}
                  onChange={onChange}
                  fieldValue={fieldValue}
                  selectList={selectList}
                  isFormReadOnly={formReadOnly}
                  isLastField={isLastField}
                  readOnly={readOnly}
                  disable={disable}
                />
              }
            />
          );
        }

        return (
          <RawSetOfResourceFile
            fieldId={fieldId}
            onChange={onChange}
            fieldValue={fieldValue}
            selectList={selectList}
            isFormReadOnly={formReadOnly}
            isLastField={isLastField}
            readOnly={readOnly}
            disable={disable}
          />
        );
      }
      }
    />
  );
}

function RawSetOfResourceFile(props: {
  fieldId: MetaIdField,
  fieldValue: StudioMapOfJarFile,
  selectList?: SelectList,
  isLastField?: boolean,
  isFormReadOnly?: boolean,
  readOnly?: boolean,
  disable?: boolean,
  onChange: (value: StudioMapOfJarFile) => void,
})
{
  const fieldId = props.fieldId;
  const selectList = props.selectList;
  const isLastField = props.isLastField;
  const isFormReadOnly = props.isFormReadOnly;

  const pageCtx = usePageCtx();
  const fileSelectorCtx = useFileSelectorCtx();
  const cbRefList = {} as IFieldRawListRef<StudioDtoJarFile>;
  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: StudioDtoJarFile[]) => props.onChange(convertArrayToMap(fieldValue));

  const uploadFile = (file: File, cb: (value: StudioDtoJarFile) => void) =>
  {
    let newValue = {} as StudioDtoJarFile;
    const mediaIdJar = nextMediaIdJar(getFileExt(file));
    generateMD5File(file)
    .then(res =>
    {
      const mediaMd5Value = res as string;
      newValue = {
        fileName: file.name,
        metaId: mediaIdJar,
        md5: mediaMd5Value
      };

      uploadMedia(ENT_ID_GLOBAL, mediaIdJar, file);

      cb(newValue);
    })
    .catch(() => Srvc.app.toast.showErrorToast("Error while generating MD5 optionId"));
  };

  const doLoad = (selectList: SelectList, fieldValue: StudioDtoJarFile[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.metaId;
        uiItemIds.push(listId);

        const fileName = value.fileName;
        const jarMd5 = value.md5;

        const attributedText1 = [] as ILineSecondaryText[];
        const attributedText2 = [] as ILineSecondaryText[];

        attributedText1.push({
          text: "File: ",
          color: "primary"
        });
        attributedText1.push({
          text: fileName
        });

        attributedText2.push({
          text: "MD5: ",
          color: "primary"
        });
        attributedText2.push({
          text: jarMd5
        });

        const secondary1: ILineSecondaryAttributedText = {
          attributedText: attributedText1
        };
        const secondary2: ILineSecondaryAttributedText = {
          attributedText: attributedText2
        };

        uiItemsById[listId] = {
          type: "mpsl",
          primary: {
            text: fileName
          },
          secondaryList: [secondary1, secondary2],
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemMPSL;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      if(selectList)
      {
        fileSelectorCtx.showFileDialog(
          {
            accept: {"doc/jar": [".jar"]},
            onFileSelect: async(files) =>
            {
              const file = files[0];
              if(file)
              {
                uploadFile(file, (value) =>
                {
                  cbRefList.addItem(
                    {
                      ...value,
                      metaId: value.metaId
                    }
                  );
                });
              }
            }
          });
      }
    }
  };

  const onClickList = (
    key: MetaIdField,
    action: FormClickVariant,
    value?: any,
    menuAnchor?: Element,
    menuProps?: IMenuProps) =>
  {
    if(action === "spotMenu" && menuProps)
    {
      menuProps = {
        ["Choose file"]: () =>
        {
          if(selectList)
          {
            fileSelectorCtx.showFileDialog(
              {
                accept: {"doc/jar": [".jar"]},
                onFileSelect: async(files) =>
                {
                  const file = files[0];
                  if(file)
                  {
                    uploadFile(file, (value) =>
                    {
                      cbRefList.updateItem(value, key);
                    });
                  }
                }
              });
          }
        },
        ["Remove"]: menuProps["Remove"]
      } as IMenuProps;
      menuAnchor && pageCtx.showMenu(menuAnchor, menuProps);
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      onClickList={onClickList}
      disableSpotMenu={isFormReadOnly}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );

}

function convertMapToArray(map?: StudioMapOfJarFile): StudioDtoJarFile[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioDtoJarFile[]): StudioMapOfJarFile
{
  const keys = [] as MediaIdJar[];
  const map = {} as Record<MediaIdJar, StudioDtoJarFile>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    keys.push(key);
    map[key] = value;
  });

  return {
    keys: keys,
    map: map
  } as StudioMapOfJarFile;
}
