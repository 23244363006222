import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {IAvatar} from "../../../base/types/TypesGlobal";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import BoxAvatar from "./BoxAvatar";
import {BoxPS} from "./BoxPS";

export function BoxAPSA(props: {
  avatarLeft?: IAvatar,
  primary?: ILinePrimary,
  secondary?: ILineSecondary,
  avatarRight?: IAvatar,
  searchWords?: string[],
  isScrolling?: boolean,
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  flexGrow?: StandardCSSProperties["flexGrow"],
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
})
{
  const theme = useTheme();
  const itemHeight = getListItemHeightAPSA("apsa");

  return (
    <LayoutFlexRow
      mt={px(props.mt)}
      mb={px(props.mb)}
      ml={px(props.ml)}
      mr={px(props.mr)}
      flexGrow={props.flexGrow}
      height={px(itemHeight)}
      onClick={props.onClick}
    >

      <BoxAvatar avatar={props.avatarLeft} mr={theme.common.gapStd} isScrolling={props.isScrolling} />

      <BoxPS
        flexGrow={1}
        primary={props.primary}
        secondary={props.secondary}
        searchWords={props.searchWords}
        onClickCaption={props.onClickCaption}
      />

      <BoxAvatar avatar={props.avatarRight} ml={theme.common.gapStd} isScrolling={props.isScrolling} />

    </LayoutFlexRow>
  );
}
