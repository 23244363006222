import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {ILocationMarkerBinderAll} from "../../../../base/types/TypeMap";
import {ILocationMapRef} from "../../../../base/types/TypeMap";
import {toComboId} from "../../../../base/types/TypesComboId";
import {ICacheSsEditorRow} from "../../../../cache/app/spreadsheet/ssEditor/TypesCacheSpreadsheetEditor";
import {RootState} from "../../../../Store";
import {Srvc} from "../../../Srvc";
import {selectCacheRow} from "../SrvcSpreadsheet";

export default class SrvcSsEditorMap extends ISrvc
{
  subscriberId = "SrvcSsEditorMap";

  locationMarkerBinder(
    locationMapRef: ILocationMapRef,
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet
  )
  {

    const selectSsRow = (rootState: RootState, rowId: RowId) => selectCacheRow(rootState, entId, rowId);

    const selectSsRowId = (rootState: RootState, rowId: RowId) =>
    {
      return rootState.cache.app.spreadsheet.ssEditor.ssStateMap[spreadsheetId]?.rowIdMap[rowId];
    };

    const selectUserAvatar = (
      rootState: RootState,
      _: RowId,
      entUserId: EntUserId) =>
    {
      const userAvatar = rootState.cache.app.user.userAvatarMap[toComboId(entId, entUserId)];

      if(!userAvatar)
      {
        Srvc.app.spreadsheet.subscribeSsUser(this.subscriberId, entId, entUserId);
      }

      return userAvatar;
    };

    const selectEntAvatarUser = (rootState: RootState) =>
    {
      return rootState.cache.app.caller.entIdUserAvatarMap[entId];
    };

    return {
      selectSourceRow1: selectSsRow.bind(this),
      onBindSourceRow1: (markerId, sourceRow) => this.onBindSourceRow(markerId, sourceRow, locationMapRef),
      selectSourceRow2: selectSsRowId.bind(this),
      onBindSourceRow2: (markerId, rowId) => this.onBindSsRowId(markerId, rowId, locationMapRef),
      selectUserAvatar: selectUserAvatar.bind(this),
      selectEntAvatarUser: selectEntAvatarUser.bind(this)
    } as ILocationMarkerBinderAll<
      SigSpreadsheetRow | undefined,
      ICacheSsEditorRow | undefined,
      undefined>;
  }

  private onBindSourceRow(
    _: RowId,
    sourceRow?: SigSpreadsheetRow,
    locationMapRef?: ILocationMapRef
  )
  {
    if(sourceRow?.formValue && locationMapRef)
    {
      locationMapRef.addRow(sourceRow.formValue);
    }
  };

  private onBindSsRowId(
    markerId: RowId,
    source?: ICacheSsEditorRow,
    locationMapRef?: ILocationMapRef
  )
  {
    if((source?.error || source?.removed) && locationMapRef)
    {
      locationMapRef.removeRow(markerId);
    }
  }
}
