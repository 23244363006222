import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarMonth} from "../../../api/meta/base/dto/StudioVarMonth";

export function fnVariableBuilderMonth(fieldValueMonth: string)
{
  function getVarComp()
  {
    return {
      [fieldValueMonth]: {
        type: "enumMonth",
        metaId: fieldValueMonth,
        name: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarMonth
  )
  {
    valueMap[fieldValueMonth] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueMonth];

    return {
      value: value
    } as StudioVarMonth;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
