import {MsgGroupPatch} from "../../../../api/home/aside/msg/MsgGroupPatch";
import {RpcAside} from "../../../../api/home/aside/RpcAside";
import {SigGroupInfo} from "../../../../api/home/aside/sig/SigGroupInfo";
import {DtoGroupSettings} from "../../../../api/home/base/dto/DtoGroupSettings";
import {GroupId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import {store} from "../../../../Store";

export interface IGroupInfoSettingPatch
{
  setting?: {
    anyOneCanJoin?: boolean,
    onlyAdminCanUpdateGroupInfo?: boolean,
    onlyAdminCanSendMessages?: boolean
  };
}

export interface IGroupInfoAvtarPatch
{
  groupId: GroupId
  name?: string,
  mediaIdAvatar?: string,
}

export interface IGroupInfoAvtarAboutPatch
{
  groupId: GroupId
  about?: string,
}

export default class SrvcHomeAsideGroupInfoPatch extends ISrvc
{
  wsocGroupPatchAvatar(values: IGroupInfoAvtarPatch)
  {
    const groupInfoAvatarValue = {
      groupId: values.groupId,
      patchPropNameSet: ["name", "mediaIdAvatar"],
      mediaIdAvatar: values.mediaIdAvatar,
      name: values.name
    } as MsgGroupPatch;
    RpcAside.groupPatch(groupInfoAvatarValue, envSig => envSig);
  }

  wsocGroupPatchAbout(values: IGroupInfoAvtarAboutPatch)
  {
    const groupInfoAboutValue = {
      groupId: values.groupId,
      patchPropNameSet: ["about"],
      about: values.about
    } as MsgGroupPatch;
    RpcAside.groupPatch(groupInfoAboutValue, envSig => envSig);
  }

  wsocGroupPatchSettings(values: IGroupInfoSettingPatch, groupComboId: TypeComboId)
  {
    const groupInfo = store.getState().cache.app.group.groupInfo[groupComboId] as SigGroupInfo;
    const cacheGroupId = groupInfo?.groupId;
    const groupInfoSettingsValue = {
      groupId: cacheGroupId,
      patchPropNameSet: ["settings"],
      settings: {
        anyOneCanJoin: values?.setting?.anyOneCanJoin !== undefined ? values?.setting?.anyOneCanJoin :
          groupInfo?.settings?.anyOneCanJoin,
        onlyAdminCanUpdateGroupInfo: values?.setting?.onlyAdminCanUpdateGroupInfo !== undefined ?
          values?.setting?.onlyAdminCanUpdateGroupInfo :
          groupInfo?.settings?.onlyAdminCanUpdateGroupInfo,
        onlyAdminCanSendMessages: values?.setting?.onlyAdminCanSendMessages !== undefined ?
          values?.setting?.onlyAdminCanSendMessages :
          groupInfo?.settings?.onlyAdminCanSendMessages
      } as DtoGroupSettings
    } as MsgGroupPatch;
    RpcAside.groupPatch(groupInfoSettingsValue, envSig => envSig);
  }
}
