import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {FieldErrors} from "react-hook-form";
import {Controller} from "react-hook-form";
import {nextMetaIdCondition} from "../../../../api/meta/base/ApiPlus";
import {DefnStudioMapOfCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfCondition";
import {StudioDtoConditionStatement} from "../../../../api/meta/base/dto/StudioDtoConditionStatement";
import {StudioMapOfCondition} from "../../../../api/meta/base/dto/StudioMapOfCondition";
import {fnResolveConditionStatement} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewCondition from "../../../dialog/DialogNewCondition";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {IResolvedStatement} from "../raw/FieldRawTreeCondition";
import FieldRawTreeCondition from "../raw/FieldRawTreeCondition";

export default function FieldStudioMapOfCondition(props: {
  defn: DefnStudioMapOfCondition
})
{
  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();
  const defnFieldFilter = props.defn;
  const compId = getFieldKey(defnFieldFilter);
  const defnTheme = formCtx.getDefnTheme();
  const isLastField = false;

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={compId}
      control={formCtx.control()}
      render={({
        field,
        formState: {errors}
      }) =>
      {
        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          return <></>;
        }

        const fieldValue = field.value;
        return (
          <RawMapOfCondition
            isLastField={isLastField}
            isFormReadOnly={formReadOnly}
            fieldValue={fieldValue}
            formStore={formStore}
            defn={defnFieldFilter}
            errors={errors}
            onChange={field.onChange}
          />
        );
      }}
    />
  );
}

function RawMapOfCondition(props: {
  defn: DefnStudioMapOfCondition,
  fieldValue?: StudioMapOfCondition,
  isLastField?: boolean,
  isFormReadOnly?: boolean,
  showAddBtn?: boolean,
  formStore?: FormStore,
  errors?: FieldErrors,
  onChange: (value: StudioMapOfCondition | null) => void,
})
{
  const pageCtx = usePageCtx();

  const isLastField = props.isLastField;
  const formStore = props.formStore;
  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const readOnly = props.isFormReadOnly;
  const metaId = defn.metaId;
  const metaIdFormSource = defn.sourceFormId;
  const inputFormId = defn.inputFormId;
  const sourceGridId = defn.sourceGridId;
  const excludeVarIdSet = defn.excludeVarIdSet;
  const sourcePluginId = defn.sourcePluginId;
  const errors = props.errors?.[metaId] ? props.errors : undefined;
  const validationResult = formStore?.validationResult;

  const fnResolver = useMemo(() => formStore
    ? fnUseStudioResolver(formStore)
    : undefined, [formStore]);

  const cbResolveStatement = useCallback((statement: StudioDtoConditionStatement) =>
  {
    return {
      primary: fnResolver
        ? fnResolveConditionStatement(statement, fnResolver, metaIdFormSource, inputFormId, sourcePluginId)
        : ""
    } as IResolvedStatement;

  }, [fnResolver, inputFormId, metaIdFormSource, sourcePluginId]);

  const cbCreateStatementNode = (
    item?: StudioMapOfCondition,
    cb?: (newItem: StudioDtoConditionStatement) => void) =>
  {
    if(formStore)
    {
      pageCtx.showDialog(<DialogNewCondition
        sourceFormId={metaIdFormSource}
        sourceGridId={sourceGridId}
        inputFormId={inputFormId}
        formStore={formStore}
        excludeVarIdSet={excludeVarIdSet}
        sourcePluginId={sourcePluginId}
        onClickOk={values =>
        {
          cb && cb(values);
        }}
        fieldId={metaId}
        nodeId={item?.metaId}
        validationResult={validationResult}
        isFormReadOnly={readOnly}
        values={item?.statement}
      />);
    }
  };

  return <FieldRawTreeCondition
    fieldValue={fieldValue}
    label={defn.label}
    isLastField={isLastField}
    isFormReadOnly={readOnly}
    errors={errors}
    cbCreateStatementNode={cbCreateStatementNode}
    cbResolveStatement={cbResolveStatement}
    onChange={props.onChange}
    cbGetMetaId={() => nextMetaIdCondition()}
  />;
}
