import {Variant} from "@mui/material/styles/createTypography";
import {SigSpreadsheetRowExpiry} from "../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {SigUserAvatar} from "../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRowCommentCount} from "../../api/home/main/sig/SigSpreadsheetRowCommentCount";
import {DefnDtoColor} from "../../api/meta/base/dto/DefnDtoColor";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {FormValue} from "../../api/meta/base/dto/FormValue";
import {EntUserId} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdComp} from "../../api/meta/base/Types";
import {IMenuProps} from "../../nucleus/atom/raw/RawMenu";
import {RootState} from "../../Store";
import {TypeTextColor} from "./TypesGlobal";
import {TypeUserField} from "./TypesGlobal";

export const KANBAN_UNCATEGORIZED_COLUMN_TITLE = "Uncategorized";

export type TypeKanbanItemId = string
export type TypeKanbanColId = string
export type TypeKanbanSortType = "ASC" | "DESC"

export type CbOnClickKanbanVariant =
  | "bubbleMenu"
  | "bubbleShell"
  | "bubbleFooter"
  | "bubbleFooterCaption"
  | "bubbleFooterComments"
  | "changeItem"

type CbOnChangeKanbanVariant =
  | "dragStart"
  | "dragEnd"
  | "dragUpdate"
  | "changeItemColumn"

export interface IKanbanRef
{
  setColumnFilter(menuAnchor: Element): void;
}

export interface OnChangeKanbanPayload
{
  itemId: TypeKanbanItemId,
  sourceColumnId: TypeKanbanColId,
  destinationColumnId?: TypeKanbanColId,
  destinationIndex?: number,
}

export type CbOnClickKanban = (
  menuAnchor: Element,
  variant: CbOnClickKanbanVariant,
  itemId: TypeKanbanItemId,
  colId: TypeKanbanColId,
  item: IKanbanItem,
  menuProps?: IMenuProps,
  selected?: boolean
) => void;

export type CbOnChangeKanban = (
  variant: CbOnChangeKanbanVariant,
  payload: OnChangeKanbanPayload) => void;

export interface IKanbanItemGroup
{
  itemIds: TypeKanbanItemId[],
}

export type IKanbanColumnsByItemIds = Record<TypeKanbanColId, IKanbanItemGroup>

export type IKanbanItemById = Record<TypeKanbanItemId, IKanbanItem>

export type IKanbanColMap = Record<TypeKanbanColId, IKanbanItemCol>;

export interface IKanbanItemCol
{
  value: string;

  title?: string;
  iconName?: string;
  color?: DefnDtoColor;
  bgcolor?: DefnDtoColor;

  variant?: Variant;
  bold?: boolean;
  fieldName?: string;

  // user field
  userField?: TypeUserField;
}

export interface IKanbanItem
{

  creationTime?: string;

  version?: string;
  variant?: Variant;

  formBubbleTitleColor?: TypeTextColor,
  searchWords?: string[],
  formValue?: FormValue;
  rowCommentCount?: SigSpreadsheetRowCommentCount;
  updatedKeySet?: MetaIdComp[];
  sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry;

  ignoreSelection?: boolean;
  hideMenu?: boolean;
  isInvisibleSpreadsheetRow?: boolean;

  // properties that are add-on go here
  isCallerSender?: boolean,
  colId: TypeKanbanColId,

  // user field
  userField?: TypeUserField;
}

export interface IKanbanData
{
  version?: string;

  // search
  searchWords?: string[];
  externalSearch?: boolean;

  // sort & filter
  sortFieldId?: string;
  sortOrder?: TypeKanbanSortType; // default ASC
  filter?: TypeKanbanColId[];

  // indexes
  columnIdList: TypeKanbanColId[];
  columnsByItemIds: IKanbanColumnsByItemIds;

  // data
  columnMap: IKanbanColMap
  itemsById: IKanbanItemById;
  defnForm?: DefnForm

  // error
  error?: string;

  // config
  hideItemFooter?: boolean;
  hideItemTitle?: boolean;
  hideItemSectionName?: boolean;
  showCommentCount?: boolean;
  isItemWithMedia?: boolean;
  uiModeMobile?: boolean;
  readonly?: boolean;
  userField?: TypeUserField,
}

export interface IKanban extends IKanbanData
{
  kanbanName: string;
  loaded?: boolean;

  // config
  ignoreSelection?: boolean;
  showMenu?: boolean;
  showSearch?: boolean;

  // derived from IListData
  displayColumnsByItemIds: IKanbanColumnsByItemIds,
  displaySelectedColumnsByItemId?: TypeKanbanColId, // for mobile only

  // derived from IListData
  displayColumnIds: TypeKanbanColId[];

  // selected item id
  selectedItemId?: TypeKanbanItemId;
}

export interface IKanbanConfig
{
  showMenu?: boolean;
  ignoreSelection?: boolean;
  externalSearch?: boolean;
  readonly?: boolean;
}

export interface IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>
{
  selectSourceItem1: (state: RootState, itemId: TypeKanbanItemId) => SR1,
  onBindSourceItem1: (kanbanName: string, itemId: TypeKanbanItemId, sourceItem?: SR1) => void,

  selectSourceItem2?: (state: RootState, itemId: TypeKanbanItemId) => SR2,
  onBindSourceItem2?: (kanbanName: string, itemId: TypeKanbanItemId, sourceItem?: SR2) => void,

  selectSourceItem3?: (state: RootState, itemId: TypeKanbanItemId) => SR3,
  onBindSourceItem3?: (kanbanName: string, itemId: TypeKanbanItemId, sourceItem?: SR3) => void,

  selectSourceItem4?: (state: RootState, itemId: TypeKanbanItemId) => SR4,
  onBindSourceItem4?: (kanbanName: string, itemId: TypeKanbanItemId, sourceItem?: SR4) => void,

  selectSourceItem5?: (state: RootState, itemId: TypeKanbanItemId) => SR5,
  onBindSourceItem5?: (kanbanName: string, itemId: TypeKanbanItemId, sourceItem?: SR5) => void,

  selectSourceItem6?: (state: RootState, itemId: TypeKanbanItemId) => SR6,
  onBindSourceItem6?: (kanbanName: string, itemId: TypeKanbanItemId, sourceItem?: SR6) => void,

  selectIsCommentable?: (
    state: RootState,
    itemId: TypeKanbanItemId,
    formId: MetaIdForm,
    entUserId: EntUserId) => boolean,
  // no need to bind because we don't store userAvatar in redux
  selectUserAvatar: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
}

export type  IKanbanBinderFive<SR1, SR2, SR3, SR4, SR5> = IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, undefined>;
export type  IKanbanBinderFour<SR1, SR2, SR3, SR4> = IKanbanBinderFive<SR1, SR2, SR3, SR4, undefined>;
export type  IKanbanBinderThree<SR1, SR2, SR3> = IKanbanBinderFour<SR1, SR2, SR3, undefined>;
export type  IKanbanBinderTwo<SR1, SR2> = IKanbanBinderThree<SR1, SR2, undefined>;
export type  IKanbanBinder<SR> = IKanbanBinderTwo<SR, undefined>;


