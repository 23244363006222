import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldText(props: {
  defn: DefnFieldText,
  rows?: number,
  maxRows?: number
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const searchText = formCtx.getSearchText();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const secureTextEntry = defn.secureTextEntry;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const isError = Boolean(error);

        const fieldValue = field.value as FieldValueText | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth={true}
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawTextField
                {...props}
                value={fieldValue}
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                error={error}
                searchWords={searchText ? [searchText] : undefined}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
