import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldNumber} from "../../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {FieldValueSwitch} from "../../../../../api/meta/base/dto/FieldValueSwitch";
import {StudioFieldSlider} from "../../../../../api/meta/base/dto/StudioFieldSlider";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyStep} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyStepFieldId} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyShowAsInputBox} from "../../base/TypesFormBuilder";
import {propKeyAllowRangePicker} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {propKeyStepVar} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldSlider(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  allowRangePicker?: boolean,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    propKeyHelperTextFieldId,
    fieldGap2,
    propKeyMinFieldId,
    propKeyMaxFieldId,
    propKeyStepFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    propKeyVarHelperText,
    fieldGap2,
    propKeyMinVar,
    propKeyMaxVar,
    propKeyStepVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultValue,
    propKeyHelperTextValue,
    fieldGap2,
    propKeyMin,
    propKeyMax,
    propKeyStep,
    fieldGap3,
    propKeyAllowRangePicker,
    ...allowRangePicker ? [propKeyShowAsInputBox] : []
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "slider",
      formId,
      "number",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyStep]: {
      type: "number",
      metaId: propKeyStep,
      name: propKeyStep,
      label: "Step"
    } as DefnFieldNumber,

    [propKeyStepVar]: {
      type: "pickVarId",
      name: propKeyStepVar,
      metaId: propKeyStepVar,
      label: "Step",
      varKind: "number",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyStepFieldId]: {
      type: "pickFieldId",
      name: propKeyStepFieldId,
      metaId: propKeyStepFieldId,
      label: "Step",
      formId: formId,
      filterFieldTypeSet: ["number"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true
    } as DefnStudioPickFieldId,

    [propKeyAllowRangePicker]: {
      type: "bool",
      metaId: propKeyAllowRangePicker,
      name: propKeyAllowRangePicker,
      showAsCheckboxVar: true
    } as DefnFieldSwitch,
    ...allowRangePicker && {
      [propKeyShowAsInputBox]: {
        type: "bool",
        metaId: propKeyShowAsInputBox,
        name: propKeyShowAsInputBox,
        label: "Show as input box",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    }
  };
}

export function defnValueToStudioFieldSlider(values: FieldValues): StudioFieldSlider
{
  return {
    type: "slider",
    defaultValue: fnFieldValueToRawValue("number", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    min: fnFieldValueToRawValue("number", values[propKeyMin]),
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    max: fnFieldValueToRawValue("number", values[propKeyMax]),
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    step: fnFieldValueToRawValue("number", values[propKeyStep]),
    stepVarId: fnFieldValueToRawValue("pickVarId", values[propKeyStepVar]),
    stepFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyStepFieldId]),
    allowRangePicker: fnFieldValueToRawValue("bool", values[propKeyAllowRangePicker]),
    showAsInputBox: (values[propKeyAllowRangePicker] as FieldValueSwitch)?.value ? fnFieldValueToRawValue("bool",
      values[propKeyShowAsInputBox]
    ) : undefined
  } as StudioFieldSlider;
}

export function studioFieldSliderToDefnValue(studioField: StudioFieldSlider): FieldValues
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("number", studioField.defaultValue),
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMin]: fnRawValueToFieldValue("number", studioField.min),
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMax]: fnRawValueToFieldValue("number", studioField.max),
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyStep]: fnRawValueToFieldValue("number", studioField.step),
    [propKeyStepVar]: fnRawValueToFieldValue("pickVarId", studioField.stepVarId),
    [propKeyStepFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.stepFieldId),
    [propKeyAllowRangePicker]: fnRawValueToFieldValue("bool", studioField.allowRangePicker),
    [propKeyShowAsInputBox]: fnRawValueToFieldValue("bool", studioField.showAsInputBox)
  };
}
