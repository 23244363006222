import {EnumDefnThemeImageCorner} from "../../../api/meta/base/Types";
import {ILineSecondary} from "../TypesGlobal";
import {ILinePrimary} from "../TypesGlobal";
import {IAvatar} from "../TypesGlobal";
import {ListItemType} from "./TypesList";
import {IListItem} from "./TypesList";

export type ListItemTypeAPSA =
  | "p"
  | "ps"
  | "ap"
  | "aps"
  | "apsa"
  | "p3"
  | "p3ss"
  | "mpsl"

export type TypeListItemAPSAMenu =
  | "Pin chat"
  | "Unpin chat"
  | "Clear chat"
  | "Delete chat"
  | "Exit group"
  | "Copy id"
  | "Block user"
  | "Unblock user"
  | "Mute"
  | "Unmute"
  | undefined
  | null;

export type TypeListItemMedia =
  | "image"
  | "video"
  | "audio"
  | "voice"
  | "html"
  | "hyperlink";

export interface IListItemAPSA extends IListItem
{
  avatarLeft?: IAvatar;
  avatarRight?: IAvatar;
  primary?: ILinePrimary;
  secondary?: ILineSecondary;
  ignoreSelectionCaption?: boolean;
}

export interface IListItemP3 extends Omit<IListItemAPSA, "avatarLeft" | "avatarRight" | "secondary">
{
}

export interface IListItemP3SS extends Omit<IListItemAPSA, "avatarLeft" | "avatarRight" | "secondary">
{
  secondary1?: ILineSecondary;
  secondary2?: ILineSecondary;
}

export interface IListItemMPSL extends IListItem
{
  media?: IMedia[];
  primary?: ILinePrimary;
  secondaryList?: ILineSecondary[];
  showMediaPlaceholder?: boolean;
}

export interface IMedia
{
  type: TypeListItemMedia,
  src?: string,
  srcBlur?: string,
  thumbnail?: string,
  imageCorner?: EnumDefnThemeImageCorner
  alt?: string,
  primaryColor?: string,
  height?: number,
  width?: number
}

export function isListItemTypeAPSA(listItemType: ListItemType): boolean
{
  return listItemType === "p" || listItemType === "ps" || listItemType === "ap" || listItemType === "aps"
    || listItemType === "apsa" || listItemType === "p3" || listItemType === "p3ss" || listItemType === "mpsl";
}

