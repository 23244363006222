import {ISig} from "../../../api/meta/base/sig/ISig";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {getErrorText} from "../../../api/nucleus/base/Protocol";
import ISrvc from "../../../base/ISrvc";
import {store} from "../../../Store";
import {clearToast} from "./SliceToast";
import {setToast} from "./SliceToast";
import {IToastState} from "./TypesToast";

export default class SrvcToast extends ISrvc
{
  showSuccessToast(msg: string)
  {
    store.dispatch(setToast({
      toastSeverity: "success",
      toastMessage: msg
    } as IToastState));
  }

  showInfoToast(msg: string)
  {
    store.dispatch(setToast({
      toastSeverity: "info",
      toastMessage: msg
    } as IToastState));
  }

  showWarningToast(msg: string)
  {
    store.dispatch(setToast({
      toastSeverity: "warning",
      toastMessage: msg
    } as IToastState));
  }

  showErrorToast(msg: string | EnvSignal<ISig>): boolean
  {
    if(typeof msg === "string")
    {
      store.dispatch(setToast({
        toastSeverity: "error",
        toastMessage: msg
      } as IToastState));
      return true;
    }
    else
    {
      const envSig = msg as EnvSignal<any>;
      const errMsg = getErrorText(envSig);
      return errMsg ? this.showErrorToast(errMsg) : false;
    }
  }

  clearToast()
  {
    store.dispatch(clearToast());
  }
}
