import {combineReducers} from "@reduxjs/toolkit";
import {createCalendarSlice} from "../../../base/plus/CalendarPlus";
import {createKanbanSlice} from "../../../base/plus/KanbanPlus";
import {createListSlice} from "../../../base/plus/ListPlus";

export function reducersAppForm()
{
  return combineReducers({
    ssBrList: createListSlice("ssBrList",
      {
        defaultListItemType: "form",
        externalSearch: true
      }
    ).reducer,
    reportBrList: createListSlice("reportBrList",
      {
        defaultListItemType: "form"
      }
    ).reducer,
    formGridRowIdPickerList: createListSlice("formGridRowIdPickerList").reducer,
    formUserPickerList: createListSlice("formUserPickerList", {defaultListItemType: "p"}).reducer,

    reportLayoutList1: createListSlice("reportLayoutList1", {externalSearch: true}).reducer,
    reportLayoutList2: createListSlice("reportLayoutList2", {externalSearch: true}).reducer,
    reportLayoutList3: createListSlice("reportLayoutList3", {externalSearch: true}).reducer,
    reportLayoutList4: createListSlice("reportLayoutList4", {externalSearch: true}).reducer,
    reportLayoutList5: createListSlice("reportLayoutList5", {externalSearch: true}).reducer,
    reportLayoutList6: createListSlice("reportLayoutList6", {externalSearch: true}).reducer,
    reportLayoutList7: createListSlice("reportLayoutList7", {externalSearch: true}).reducer,
    reportLayoutList8: createListSlice("reportLayoutList8", {externalSearch: true}).reducer,
    reportLayoutList9: createListSlice("reportLayoutList9", {externalSearch: true}).reducer,
    reportLayoutList10: createListSlice("reportLayoutList10", {externalSearch: true}).reducer,

    formGridList1: createListSlice("formGridList1").reducer,
    formGridList2: createListSlice("formGridList2").reducer,
    formGridList3: createListSlice("formGridList3").reducer,
    formGridList4: createListSlice("formGridList4").reducer,
    formGridList5: createListSlice("formGridList5").reducer,
    formGridList6: createListSlice("formGridList6").reducer,
    formGridList7: createListSlice("formGridList7").reducer,
    formGridList8: createListSlice("formGridList8").reducer,
    formGridList9: createListSlice("formGridList9").reducer,
    formGridList10: createListSlice("formGridList10").reducer,

    reportLayoutKanban1: createKanbanSlice("reportLayoutKanban1", {readonly: true}).reducer,
    reportLayoutKanban2: createKanbanSlice("reportLayoutKanban2", {readonly: true}).reducer,
    reportLayoutKanban3: createKanbanSlice("reportLayoutKanban3", {readonly: true}).reducer,
    reportLayoutKanban4: createKanbanSlice("reportLayoutKanban4", {readonly: true}).reducer,
    reportLayoutKanban5: createKanbanSlice("reportLayoutKanban5", {readonly: true}).reducer,
    reportLayoutKanban6: createKanbanSlice("reportLayoutKanban6", {readonly: true}).reducer,
    reportLayoutKanban7: createKanbanSlice("reportLayoutKanban7", {readonly: true}).reducer,
    reportLayoutKanban8: createKanbanSlice("reportLayoutKanban8", {readonly: true}).reducer,
    reportLayoutKanban9: createKanbanSlice("reportLayoutKanban9", {readonly: true}).reducer,
    reportLayoutKanban10: createKanbanSlice("reportLayoutKanban10", {readonly: true}).reducer,

    reportLayoutCalendar1: createCalendarSlice("reportLayoutCalendar1").reducer,
    reportLayoutCalendar2: createCalendarSlice("reportLayoutCalendar2").reducer,
    reportLayoutCalendar3: createCalendarSlice("reportLayoutCalendar3").reducer,
    reportLayoutCalendar4: createCalendarSlice("reportLayoutCalendar4").reducer,
    reportLayoutCalendar5: createCalendarSlice("reportLayoutCalendar5").reducer,
    reportLayoutCalendar6: createCalendarSlice("reportLayoutCalendar6").reducer,
    reportLayoutCalendar7: createCalendarSlice("reportLayoutCalendar7").reducer,
    reportLayoutCalendar8: createCalendarSlice("reportLayoutCalendar8").reducer,
    reportLayoutCalendar9: createCalendarSlice("reportLayoutCalendar9").reducer,
    reportLayoutCalendar10: createCalendarSlice("reportLayoutCalendar10").reducer,

    dialogList1: createListSlice("dialogList1").reducer,
    dialogList2: createListSlice("dialogList2").reducer,

    formFieldPropertyMap1: createListSlice("formFieldPropertyMap1",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap2: createListSlice("formFieldPropertyMap2",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap3: createListSlice("formFieldPropertyMap3",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap4: createListSlice("formFieldPropertyMap4",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap5: createListSlice("formFieldPropertyMap5",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap6: createListSlice("formFieldPropertyMap6",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap7: createListSlice("formFieldPropertyMap7",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap8: createListSlice("formFieldPropertyMap8",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap9: createListSlice("formFieldPropertyMap9",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer,
    formFieldPropertyMap10: createListSlice("formFieldPropertyMap10",
      {
        defaultListItemType: "ps",
        externalSearch: true
      }
    ).reducer
  });
}
