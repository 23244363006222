import {useMemo} from "react";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnLayoutGridXYChart} from "../../../../api/meta/base/dto/DefnLayoutGridXYChart";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {getEmptyKeysAndMap} from "../../../../base/plus/StudioPlus";
import {IChartValues} from "../../../../base/types/TypeCharts";
import helperTextData from "../../../atom/assets/PlaceholderTextHome.json";
import ChartBar from "../../../atom/charts/ChartBar";
import ChartDoughnut from "../../../atom/charts/ChartDoughnut";
import ChartLine from "../../../atom/charts/ChartLine";
import ChartPie from "../../../atom/charts/ChartPie";
import ChartScatterPlot from "../../../atom/charts/ChartScatterPlot";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import {IFieldGridRawProps} from "../composite/FieldGrid";

export default function FieldRawFieldGridChart(props: IFieldGridRawProps)
{
  const fieldValue = useMemo(() => props.fieldValue
      ? props.fieldValue
      : getEmptyKeysAndMap() as FieldValueGrid
    , [props.fieldValue]);

  return (
    <AutoSizer>
      {({
        width,
        height
      }) => (
        <div
          style={{
            width: width,
            height: height
          }}
        >
          <ChartFactory
            defnForm={props.defnForm}
            values={fieldValue}
            layout={props.layout as DefnLayoutGridXYChart}
          />
        </div>
      )}
    </AutoSizer>

  );
}

export function ChartFactory(props: {
  defnForm?: DefnForm
  values?: IChartValues,
  layout?: DefnLayoutGridXYChart;
  allowAnimation?: boolean;
})
{
  const defnForm = props.defnForm;
  const layout = props.layout;
  const allowAnimation = props.allowAnimation;
  const fieldValue = props.values as FieldValueGrid;

  const chartTypes = layout?.kind;

  if(!layout || !fieldValue || !defnForm || fieldValue.keys.length === 0)
  {
    return <RawNothingHere helperTextData={helperTextData.nothingToShow} />;
  }

  switch(chartTypes)
  {
    case "xyChartLineChart":
      return <MemoChartLine
        defnForm={defnForm}
        allowAnimation={allowAnimation}
        values={fieldValue}
        layout={layout}
      />;
    case "xyChartBarGraph":
      return <MemoChartBar
        defnForm={defnForm}
        allowAnimation={allowAnimation}
        values={fieldValue}
        layout={layout}
      />;
    case "xyChartScatterPlot":
      return <MemoChartScatterPlot
        defnForm={defnForm}
        allowAnimation={allowAnimation}
        values={fieldValue}
        layout={layout}
      />;
    case "xyChartPieChart":
      return <MemoChartPie
        defnForm={defnForm}
        allowAnimation={allowAnimation}
        values={fieldValue}
        layout={layout}
      />;
    case "xyChartDoughnut":
      return <MemoChartChartDoughnut
        defnForm={defnForm}
        allowAnimation={allowAnimation}
        values={fieldValue}
        layout={layout}
      />;

  }
}

const MemoChartLine = React.memo(ChartLine);
const MemoChartBar = React.memo(ChartBar);
const MemoChartScatterPlot = React.memo(ChartScatterPlot);
const MemoChartPie = React.memo(ChartPie);
const MemoChartChartDoughnut = React.memo(ChartDoughnut);

