import {PayloadAction} from "@reduxjs/toolkit";
import {SigSpreadsheetRowExpiry} from "../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {DtoMessagePayloadReport} from "../../../api/home/base/dto/DtoMessagePayloadReport";
import {DtoMessagePayloadSpreadsheetPartition} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetPartition";
import {DtoMessagePayloadSpreadsheetRow} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {SigMessage} from "../../../api/home/main/sig/SigMessage";
import {SigMessageList} from "../../../api/home/main/sig/SigMessageList";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {SigSpreadsheetRowCommentCount} from "../../../api/home/main/sig/SigSpreadsheetRowCommentCount";
import {SigTopicMessageProps} from "../../../api/home/session/sig/SigTopicMessageProps";
import {isRowId} from "../../../api/meta/base/ApiPlus";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {DefnDtoParagraph} from "../../../api/meta/base/dto/DefnDtoParagraph";
import {DefnDtoText} from "../../../api/meta/base/dto/DefnDtoText";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {MediaIdAvatar} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {EntUserId} from "../../../api/meta/base/Types";
import {MessageId} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {getMsgPayloadRowId} from "../../plus/BubblePlus";
import {createBubble} from "../../plus/BubblePlus";
import {toDateFromDetailedDateTime} from "../../plus/DatePlus";
import {formatChatInfoDate} from "../../plus/DatePlus";
import {getFormNameColor} from "../../plus/FormPlus";
import {IBubbleReplyInfo} from "../../types/TypesBubble";
import {IBubbleHeader} from "../../types/TypesBubble";
import {getMessageId} from "../../types/TypesChat";
import {isChatItemDate} from "../../types/TypesChat";
import {IChatItemDate} from "../../types/TypesChat";
import {getMessageOffset} from "../../types/TypesChat";
import {TypeChatItemId} from "../../types/TypesChat";
import {TypeChatGap} from "../../types/TypesChat";
import {CHAT_START_ITEM_ID} from "../../types/TypesChat";
import {IChatItemSig} from "../../types/TypesChat";
import {TypeChatSkipInvokeBusy} from "../../types/TypesChat";
import {IChatItem} from "../../types/TypesChat";
import {isChatItemPickable} from "../../types/TypesChat";
import {IChat} from "../../types/TypesChat";
import {TypeTextColor} from "../../types/TypesGlobal";
import {fnChatReset} from "./SliceChat";

export const sliceChatShared =
  {
    chatInit: (state: IChat, action: PayloadAction<{entId: EntId, chatId: ChatId, loadedVersion: string}>) =>
    {
      fnChatReset(state);
      const payload = action.payload;
      state.sigEntId = payload.entId;
      state.sigChatId = payload.chatId;
      state.loadedVersion = payload.loadedVersion;
    },
    chatLoad: (
      state: IChat,
      action: PayloadAction<{
        sig: SigMessageList,
        loadedVersion: string,
        jumpOffset?: number,
        jumpMsgId?: MessageId,
        jumpVersion?: string
      }>) =>
    {
      const payload = action.payload;
      if(state.loadedVersion !== payload.loadedVersion)
      {
        return;
      }

      const sig = payload.sig;
      const jumpOffset = payload.jumpOffset;
      const jumpMsgId = payload.jumpMsgId;

      state.sigTopOffset = sig.topOffset;
      state.sigBottomOffset = sig.bottomOffset;
      state.topOffset = sig.pageTopOffset;
      state.bottomOffset = sig.pageBottomOffset;
      const lastMsgId = getLastSenderMsg(sig.messageList)?.messageId;
      const displayItems = msgListToDisplayList(state, sig.messageList);

      let jumpItemId: TypeChatItemId | undefined = undefined;
      let itemId = CHAT_START_ITEM_ID;
      const displayItemIds: TypeChatItemId[] = [];

      displayItems.forEach((displayItem, i) =>
      {
        insertDisplayItem(state, displayItem, itemId, displayItemIds);
        const messageOffset = getMessageOffset(displayItem);

        if(messageOffset !== undefined)
        {
          state.displayOffsetMap[messageOffset] = itemId;
          if(isChatItemPickable(displayItem))
          {
            if(jumpOffset === messageOffset || jumpMsgId === (displayItem as IChatItemSig).sig.messageId)
            {
              jumpItemId = itemId;
            }
          }
          if((displayItem as IChatItemSig).sig.messageId !== lastMsgId)
          {
            (displayItem as IChatItemSig).sig.isMarkAsRead = true;
          }
        }
        displayItem.gap = getMsgGap(displayItems, i);
        itemId++;
      });

      state.displayItemIds = displayItemIds;
      state.jumpVersion = payload.jumpVersion;

      fnChatSetDisplay(state, jumpItemId);
    },
    chatAppend: (state: IChat, action: PayloadAction<{sig: SigMessageList, loadedVersion: string}>) =>
    {
      const payload = action.payload;
      if(state.loadedVersion !== payload.loadedVersion || state.appending !== "busy")
      {
        return;
      }

      const sig = payload.sig;
      appendNewMessageList(state, sig);

      fnChatSetDisplay(state);
    },
    chatPrepend: (state: IChat, action: PayloadAction<{sig: SigMessageList, loadedVersion: string}>) =>
    {
      const payload = action.payload;
      if(state.loadedVersion !== payload.loadedVersion || state.prepending !== "busy")
      {
        return;
      }
      const sig = payload.sig;
      state.sigTopOffset = sig.topOffset;
      state.sigBottomOffset = sig.bottomOffset;

      const oldDisplayItemIds = state.displayItemIds;
      stitchChatItemOnPrepend(state, oldDisplayItemIds, sig.messageList);
      const firstItem = state.displayItemMap[state.displayFirstItemId as number] as IChatItemSig;

      const displayItems = msgListToDisplayList(state, sig.messageList);
      let itemId = (state.displayFirstItemId || CHAT_START_ITEM_ID) as number - displayItems.length;

      const displayItemIds = [] as TypeChatItemId[];
      displayItems.forEach((displayItem, i) =>
      {
        insertDisplayItem(state, displayItem, itemId, displayItemIds);
        const messageOffset = getMessageOffset(displayItem);
        if(messageOffset !== undefined)
        {
          state.displayOffsetMap[messageOffset] = itemId;
        }
        displayItem.gap = getMsgGap(displayItems, i, firstItem);
        if(!isChatItemDate(displayItem))
        {
          const itemSig = displayItem as IChatItemSig;
          if(!itemSig.sig.isCallerSender)
          {
            itemSig.sig.isMarkAsRead = true;
          }
        }
        itemId++;
      });

      if(displayItemIds.length > 0)
      {
        state.displayItemIds = [...displayItemIds, ...oldDisplayItemIds];
      }
      fnChatSetDisplay(state);

    },
    chatAppendMsg: (state: IChat, action: PayloadAction<{sig: SigMessageList, loadedVersion: string}>) =>
    {
      const payload = action.payload;
      if(state.loadedVersion !== payload.loadedVersion)
      {
        return;
      }
      const sig = payload.sig;
      state.topOffset = Math.max(state.topOffset, sig.pageTopOffset);
      appendNewMessageList(state, sig);
      fnChatSetDisplay(state);
    },
    chatSetAppending: (state: IChat, action: PayloadAction<{mode: TypeChatSkipInvokeBusy, loadedVersion: string}>) =>
    {
      const payload = action.payload;
      if(state.loadedVersion === payload.loadedVersion)
      {
        state.appending = payload.mode;
      }
    },
    chatSetPrepending: (state: IChat, action: PayloadAction<{mode: TypeChatSkipInvokeBusy, loadedVersion: string}>) =>
    {
      const payload = action.payload;
      if(state.loadedVersion === payload.loadedVersion)
      {
        state.prepending = payload.mode;
      }
    },
    chatSetPageOffset: (state: IChat, action: PayloadAction<{pageBottomOffset: number, pageTopOffset: number}>) =>
    {
      if(state.loadedVersion === undefined)
      {
        return;
      }
      const payload = action.payload;
      if(state.topOffset)
      {
        state.topOffset = Math.max(state.topOffset, payload.pageTopOffset);
      }
      if(state.bottomOffset)
      {
        state.bottomOffset = Math.min(state.bottomOffset, payload.pageBottomOffset);
      }
    },
    chatSetPickMode: (state: IChat, action: PayloadAction<boolean>) =>
    {
      state.pickMode = action.payload;
      state.pickCount = 0;
      if(state.pickMode)
      {
        state.pickItemIds = {};
      }
      else
      {
        state.pickItemIds = undefined;
        state.pickCount = 0;
      }
    },
    chatTogglePick: (state: IChat, action: PayloadAction<number>) =>
    {
      const itemId = action.payload;
      if(!isChatItemPickable(state.displayItemMap[itemId]))
      {
        return;
      }

      let pickItemIds = state.pickItemIds;
      if(pickItemIds)
      {
        if(pickItemIds[itemId])
        {
          delete pickItemIds[itemId];
          state.pickCount = state.pickCount - 1;
        }
        else
        {
          pickItemIds[itemId] = true;
          state.pickCount = state.pickCount + 1;
        }
      }
      else
      {
        pickItemIds = {};
        pickItemIds[itemId] = true;
        state.pickItemIds = pickItemIds;
        state.pickCount = 1;
      }
    },
    chatItemHover: (state: IChat, action: PayloadAction<number | undefined>) =>
    {
      if(action.payload)
      {
        state.hoverItemId = action.payload;
      }
      else
      {
        state.hoverItemId = undefined;
      }
    },
    chatSetSelectedItemId: (state: IChat, action: PayloadAction<TypeChatItemId | undefined>) =>
    {
      if(action.payload)
      {
        state.selectedItemId = action.payload;
      }
      else
      {
        state.selectedItemId = undefined;
      }
    },
    chatSetScrollToLast: (state: IChat, action: PayloadAction<boolean>) =>
    {
      state.displayScrollToLast = action.payload ? true : undefined;
    },
    chatSetScrollToItemId: (state: IChat, action: PayloadAction<TypeChatItemId | undefined>) =>
    {
      state.displayScrollToItemId = action.payload;
    },
    chatSetJumpToLast: (state: IChat, action: PayloadAction<boolean>) =>
    {
      state.displayJumpToLast = action.payload ? true : undefined;
    },
    chatSetUiAtBottom: (state: IChat, action: PayloadAction<boolean>) =>
    {
      state.uiAtBottom = action.payload;
    },
    chatSetUiScrolling: (state: IChat, action: PayloadAction<boolean>) =>
    {
      state.uiScrolling = action.payload;
      if(!action.payload)
      {
        state.uiImageMode = "original";
      }
    },
    chatSetUiFastScrolling: (state: IChat, action: PayloadAction<boolean>) =>
    {
      if(action.payload)
      {
        state.uiImageMode = "color";
      }
      else if(state.uiScrolling)
      {
        state.uiImageMode = "blur";
      }
      else
      {
        state.uiImageMode = "original";
      }
    },
    chatSetUiBlinkItemId: (state: IChat, action: PayloadAction<TypeChatItemId | undefined>) =>
    {
      if(action.payload)
      {
        state.displayBlinkItemId = action.payload;
      }
      else
      {
        state.displayBlinkItemId = undefined;
      }
    },
    chatSetIfExistDefnForm: (state: IChat, action: PayloadAction<{itemId: TypeChatItemId, defnForm: DefnForm}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.defnForm = payload.defnForm;
      }
    },
    chatSetFormBubbleTitleColor: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, formBubbleTitleColor?: TypeTextColor}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.formBubbleTitleColor = payload.formBubbleTitleColor;
      }
    },
    chatSetIfExistSpreadsheetRow: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, sigSpreadsheetRow: SigSpreadsheetRow}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.sigSpreadsheetRow = payload.sigSpreadsheetRow;
      }
    },
    chatSetIfExistSpreadsheetRowComment: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, sigSpreadsheetRowComment: SigSpreadsheetRowCommentCount}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig?.sigSpreadsheetRow)
      {
        sig.sigSpreadsheetRow = {
          ...sig.sigSpreadsheetRow,
          rowCommentCount: payload.sigSpreadsheetRowComment
        };
      }
      else if(sig?.payload.messageType === "report")
      {
        sig.reportRowCommentCount = payload.sigSpreadsheetRowComment;
      }
    },
    chatSetIfExistSigSpreadsheetRowExpiry: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, sigSpreadsheetRowExpiry: SigSpreadsheetRowExpiry}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.sigSpreadsheetRowExpiry = payload.sigSpreadsheetRowExpiry;
      }
    },
    chatSetIfExistDefnFormInputValue: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, inputValueMap: FormValueRaw}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;

      if(sig)
      {
        (sig.payload as DtoMessagePayloadReport).formValueRaw = payload.inputValueMap;
      }
    },
    chatSetIfExistIsDownloading: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, isDownloading?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.isDownloading = payload.isDownloading;
      }
    },
    chatSetIfExistIsStar: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, isStar?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.isStar = payload.isStar;
      }
    },
    chatSetIfExistMarkAsRead: (
      state: IChat,
      action: PayloadAction<TypeChatItemId>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.isMarkAsRead = true;
      }
    },
    chatSetIfExistHeader: (state: IChat, action: PayloadAction<{itemId: TypeChatItemId, header?: IBubbleHeader}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.header = payload.header;
      }
    },
    chatSetIfExistInitiatorUserAvatarName: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, initiatorMemberName?: string}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.initiatorMemberName = payload.initiatorMemberName;
      }
    },
    chatSetIfExistTargetUserAvatarName: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, targetMemberName?: string}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.targetMemberName = payload.targetMemberName;
      }
    },
    chatSetIfExistIsCommentable: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, isCommentAble?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.isCommentable = payload.isCommentAble;
      }
    },
    chatSetIfExistIsFormWithMedia: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, isFormWithMedia?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.isFormWithMedia = payload.isFormWithMedia;
      }
    },
    chatSetIfExistIsVisibleSpreadsheetRow: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, isInvisibleSpreadsheetRow?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.isInvisibleSpreadsheetRow = payload.isInvisibleSpreadsheetRow;
      }
    },
    chatSetIfExistChatPatternVar: (
      state: IChat,
      action: PayloadAction<{
        itemId: TypeChatItemId,
        chatPatternVar?: DefnDtoParagraph,
        chatLabelPatternVar?: DefnDtoText
      }>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.chatPatternVar = payload.chatPatternVar;
        sig.chatLabelPatternVar = payload.chatLabelPatternVar;
      }
    },
    chatSetIfExistIsMsgForwardable: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, isMsgForwardable?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        if(!isRowId(state.sigChatId))
        {
          sig.isMsgForwardable = payload.isMsgForwardable;
        }
      }
    },
    chatSetIfExistMsgReply: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, replyInfo: IBubbleReplyInfo}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig?.replyPayload)
      {
        sig.replyInfo = payload.replyInfo;
      }
    },
    chatSetIfExistIsCanExpire: (
      state: IChat,
      action: PayloadAction<{itemId: TypeChatItemId, canExpire?: boolean}>) =>
    {
      const payload = action.payload;
      const sig = (state.displayItemMap[payload.itemId] as IChatItemSig)?.sig;
      if(sig)
      {
        sig.canExpire = payload.canExpire;
      }
    },

    chatSetIfExistMessage: (
      state: IChat,
      action: PayloadAction<SigMessage>) =>
    {
      const payload = action.payload;
      const messageId = action.payload.messageId;
      const chatItem = getChatItemFromMsgId(state, messageId) as IChatItemSig;
      const sig = chatItem?.sig;
      if(sig)
      {
        const newSig = createBubble(payload);
        chatItem.sig = {
          ...sig,
          ...newSig,
          reactionMap: newSig.reactionMap,
          isCallerSender: newSig.isCallerSender,
          replyPayload: newSig.replyPayload
        };
      }
    },
    chatHandleSigTopicMessageProps: (state: IChat, action: PayloadAction<SigTopicMessageProps>) =>
    {
      if(state.loadedVersion === undefined)
      {
        return;
      }
      const payload = action.payload;
      const chatItem = getChatItemFromMsgId(state, payload.messageId) as IChatItemSig;
      if(chatItem)
      {
        chatItem.sig.receiptStatus = payload.receiptStatus;
      }
    },
    chatHandleSigTopicMessageRemoveForMe: (state: IChat, action: PayloadAction<{messageId: MessageId}>) =>
    {
      if(state.loadedVersion === undefined)
      {
        return;
      }
      const payload = action.payload;
      const messageId = payload.messageId;
      const displayItemIds = state.displayItemIds;
      const displayItemMap = state.displayItemMap;
      const removeItemId = state.displayMsgIdMap[messageId];
      const removeItemIndex = displayItemIds.findIndex(itemId => itemId === removeItemId);
      if(removeItemIndex !== -1)
      {
        const prevI = displayItemIds[removeItemIndex - 1];
        const nextI = displayItemIds[removeItemIndex + 1];
        const prevMsg = displayItemMap[prevI];
        const nextMsg = displayItemMap[nextI];

        if(isChatItemDate(prevMsg))
        {
          const nextMsgDateStr = (nextMsg as IChatItemSig)?.sig?.creationTime
            ? formatChatInfoDate(toDateFromDetailedDateTime((nextMsg as IChatItemSig).sig.creationTime),
              state.displayDateFormat
            )
            : undefined;
          const prevMsgDateStr = (prevMsg as IChatItemDate).date;
          if(nextMsgDateStr !== prevMsgDateStr)
          {
            displayItemIds.splice(removeItemIndex - 1, 2);
          }
          else
          {
            displayItemIds.splice(removeItemIndex, 1);
          }
        }
        else
        {
          displayItemIds.splice(removeItemIndex, 1);
        }
        const displayItemArr = [
          prevMsg
        ];
        nextMsg && displayItemArr.push(nextMsg);
        displayItemMap[prevI].gap = getMsgGap(displayItemArr, 0);
        state.displayItemIds = displayItemIds;
        state.displayItemMap = displayItemMap;
        const msgItem = displayItemMap[removeItemId];
        const messageOffset = getMessageOffset(msgItem);
        if(messageOffset !== undefined)
        {
          delete state.displayOffsetMap[messageOffset];
          delete state.displayMsgIdMap[messageId];
        }
        fnChatSetDisplay(state);
      }
    },
    chatHandleSigTopicFormRemove: (state: IChat, action: PayloadAction<MessageId>) =>
    {
      if(state.loadedVersion === undefined)
      {
        return;
      }
      const payload = action.payload;
      const chatItem = getChatItemFromMsgId(state, payload) as IChatItemSig;
      if(chatItem)
      {
        chatItem.sig.payload.messageType = "spreadsheetRowDeleted";
      }
    },

    chatSetCallerEntUserId: (state: IChat, action: PayloadAction<EntUserId | undefined>) =>
    {
      if(state.loadedVersion === undefined)
      {
        return;
      }
      state.sigEntUserId = action.payload;
    },

    chatSetCallerEntMediaId: (state: IChat, action: PayloadAction<MediaIdAvatar | undefined>) =>
    {
      if(state.loadedVersion === undefined)
      {
        return;
      }
      state.sigEntAvtarId = action.payload;
    },

    chatSetDisplayShakeFieldIdMap: (state: IChat,
      action: PayloadAction<{rowId: RowId, fieldId: MetaIdField, shakeDurationMs?: number}>) =>
    {
      const payload = action.payload;
      const fieldId = payload.fieldId;
      const shakeDurationMs = payload.shakeDurationMs;
      const rowId = payload.rowId;
      const chatItemIds = state.displayRowIdMap[rowId] as TypeChatItemId[] | undefined;
      chatItemIds?.forEach(chatItemId =>
      {
        const sig = (state.displayItemMap[chatItemId] as IChatItemSig)?.sig;
        if(sig)
        {
          if(!sig?.shakeFieldIdMap)
          {
            sig.shakeFieldIdMap = {};
          }
          if(sig.shakeFieldIdMap)
          {
            if(shakeDurationMs)
            {
              sig.shakeFieldIdMap[fieldId] = shakeDurationMs;
            }
            else
            {
              delete sig.shakeFieldIdMap[fieldId];
            }
          }
          (state.displayItemMap[chatItemId] as IChatItemSig).sig = sig;
        }
      });
    },
    chatSetDisplayBlinkFieldIdMap: (state: IChat,
      action: PayloadAction<{rowId: RowId, fieldId: MetaIdField, blinkDurationMs?: number}>) =>
    {
      const payload = action.payload;
      const fieldId = payload.fieldId;
      const blink = payload.blinkDurationMs;
      const rowId = payload.rowId;
      const chatItemIds = state.displayRowIdMap[rowId] as TypeChatItemId[] | undefined;
      chatItemIds?.forEach(chatItemId =>
      {
        const sig = (state.displayItemMap[chatItemId] as IChatItemSig)?.sig;
        if(sig)
        {
          if(!sig?.blinkFieldIdMap)
          {
            sig.blinkFieldIdMap = {};
          }
          if(sig.blinkFieldIdMap)
          {
            if(blink)
            {
              sig.blinkFieldIdMap[fieldId] = blink;
            }
            else
            {
              delete sig.blinkFieldIdMap[fieldId];
            }
          }
          (state.displayItemMap[chatItemId] as IChatItemSig).sig = sig;
        }
      });
    },

    chatSetDisplayHiddenFieldIdMap: (state: IChat,
      action: PayloadAction<{rowId: RowId, fieldId: MetaIdField, hidden?: boolean}>) =>
    {
      const payload = action.payload;
      const fieldId = payload.fieldId;
      const hidden = payload.hidden;
      const rowId = payload.rowId;
      const chatItemIds = state.displayRowIdMap[rowId] as TypeChatItemId[] | undefined;
      chatItemIds?.forEach(chatItemId =>
      {
        const sig = (state.displayItemMap[chatItemId] as IChatItemSig)?.sig;
        if(sig)
        {
          if(!sig?.hiddenFieldIdMap)
          {
            sig.hiddenFieldIdMap = {};
          }
          if(sig.hiddenFieldIdMap)
          {
            if(hidden)
            {
              sig.hiddenFieldIdMap[fieldId] = true;
            }
            else
            {
              delete sig.hiddenFieldIdMap[fieldId];
            }
          }
          (state.displayItemMap[chatItemId] as IChatItemSig).sig = sig;
        }
      });
    },

    chatSetHighlightFieldIdMap: (state: IChat,
      action: PayloadAction<{rowId: RowId, fieldId: MetaIdField, highlightDurationMs?: number}>) =>
    {
      const payload = action.payload;
      const fieldId = payload.fieldId;
      const highlightDurationMs = payload.highlightDurationMs;
      const rowId = payload.rowId;
      const chatItemIds = state.displayRowIdMap[rowId] as TypeChatItemId[] | undefined;
      chatItemIds?.forEach(chatItemId =>
      {
        const sig = (state.displayItemMap[chatItemId] as IChatItemSig)?.sig;
        if(sig)
        {
          if(!sig?.highlightFieldIdMap)
          {
            sig.highlightFieldIdMap = {};
          }
          if(sig.highlightFieldIdMap)
          {
            if(highlightDurationMs)
            {
              sig.highlightFieldIdMap[fieldId] = highlightDurationMs;
            }
            else
            {
              delete sig.highlightFieldIdMap[fieldId];
            }
          }
          (state.displayItemMap[chatItemId] as IChatItemSig).sig = sig;
        }
      });
    },

    chatSetDisplayDateFormat: (state: IChat, action: PayloadAction<{displayDateFormat?: string}>) =>
    {
      state.displayDateFormat = action.payload.displayDateFormat;
    },
    chatSetAppendingNewMsg: (state: IChat, action: PayloadAction<boolean | undefined>) =>
    {
      state.appendingNewMsg = action.payload;
    }
  };

export function fnChatSetDisplay(state: IChat, jumpItemId?: TypeChatItemId): void
{
  const displayItemIds = state.displayItemIds;
  if(displayItemIds.length === 0)
  {
    state.displayFirstItemId = undefined;
    state.displayLastItemId = undefined;
    state.displayItemCount = 0;
    state.displayInitialTopMostItemId = undefined;
    state.displayLastItemIsSender = false;
    state.displayBlinkItemId = undefined;
  }
  else
  {
    const itemCount = state.displayItemIds.length;

    state.displayFirstItemId = state.displayItemIds[0];
    state.displayLastItemId = state.displayItemIds[itemCount - 1];
    state.displayItemCount = itemCount;

    if(jumpItemId)
    {
      state.displayInitialTopMostItemId = state.displayItemIds.findIndex(itemId => itemId === jumpItemId);
      if(!state.displayJumpToLast)
      {
        state.displayBlinkItemId = jumpItemId;
      }
    }
    else
    {
      state.displayInitialTopMostItemId = state.displayLastItemId;
      // state.displayBlinkItemId = undefined;
    }
    state.displayJumpToLast = undefined;
    const lastItem = state.displayItemMap[state.displayLastItemId];
    state.displayLastItemIsSender = isChatItemPickable(lastItem)
      ? Boolean((lastItem as IChatItemSig).sig.isCallerSender)
      : false;
  }

  state.appending = "skip";
  state.prepending = "skip";
}

function appendNewMessageList(state: IChat, sig: SigMessageList)
{
  state.sigTopOffset = sig.topOffset;
  state.sigBottomOffset = sig.bottomOffset;

  const lastItem = state.displayItemMap[state.displayLastItemId as number] as IChatItemSig;
  const dateStr = formatChatInfoDate(toDateFromDetailedDateTime(lastItem.sig.creationTime),
    state.displayDateFormat
  );
  const displayItems = msgListToDisplayList(state, sig.messageList, dateStr);
  if(displayItems.length === 0)
  {
    return;
  }
  const displayItemIds = [] as TypeChatItemId[];
  let itemId = (state.displayLastItemId ?? CHAT_START_ITEM_ID) as number + 1;
  displayItems.forEach((displayItem, i) =>
  {
    insertDisplayItem(state, displayItem, itemId, displayItemIds);
    const messageOffset = getMessageOffset(displayItem);
    if(messageOffset !== undefined)
    {
      state.displayOffsetMap[messageOffset] = itemId;
      if(!isChatItemDate(displayItem))
      {
        const itemSig = displayItem as IChatItemSig;
        if(!itemSig.sig.isCallerSender && messageOffset <= sig.readOffset)
        {
          itemSig.sig.isMarkAsRead = true;
        }
      }
    }

    displayItem.gap = getMsgGap(displayItems, i, undefined, lastItem);

    itemId++;
  });

  if(displayItemIds.length > 0)
  {
    state.displayItemIds = [...state.displayItemIds, ...displayItemIds];
  }
}

function getDisplayItemIdsOnAppendNewMsg(state: IChat, payload: SigMessage, lastItem: IChatItemSig, newItemId: number)
{
  const displayItemIds = [] as TypeChatItemId[];
  const dateStr = formatChatInfoDate(toDateFromDetailedDateTime(lastItem.sig.creationTime),
    state.displayDateFormat
  );
  const displayItems = msgListToDisplayList(state, [payload], dateStr);
  displayItems.forEach((displayItem, i) =>
  {
    insertDisplayItem(state, displayItem, newItemId, displayItemIds);
    const messageOffset = getMessageOffset(displayItem);
    if(messageOffset !== undefined)
    {
      state.displayOffsetMap[messageOffset] = newItemId;
      if(!isChatItemDate(displayItem))
      {
        const itemSig = displayItem as IChatItemSig;
        if(itemSig.sig.isCallerSender)
        {
          itemSig.sig.isMarkAsRead = true;
        }
      }
    }

    displayItem.gap = getMsgGap(displayItems,
      i,
      undefined,
      lastItem
    );

    newItemId++;
  });

  return displayItemIds;
}

function insertRowIdMapItem(state: IChat, rowId: RowId, itemId: TypeChatItemId)
{
  if(!state.displayRowIdMap[rowId])
  {
    state.displayRowIdMap[rowId] = [];
  }
  if(!state.displayRowIdMap[rowId].includes(itemId))
  {
    state.displayRowIdMap[rowId].push(itemId);
  }
}

function insertRowPartitionIdMapItem(state: IChat, chatItem: IChatItem, itemId: TypeChatItemId)
{
  const payload = (chatItem as IChatItemSig)?.sig.payload;
  const messageType = payload?.messageType;

  if(messageType === "spreadsheetPartition")
  {
    const rowPartitionId = (payload as DtoMessagePayloadSpreadsheetPartition).spreadsheetPartitionId;
    if(rowPartitionId)
    {
      if(!state.displayPartitionIdMap[rowPartitionId])
      {
        state.displayPartitionIdMap[rowPartitionId] = [];
      }

      if(!state.displayPartitionIdMap[rowPartitionId].includes(itemId))
      {
        state.displayPartitionIdMap[rowPartitionId].push(itemId);
      }
    }
  }
  if(messageType === "spreadsheetRow")
  {
    const rowPartitionId = (payload as DtoMessagePayloadSpreadsheetRow).spreadsheetPartitionId;
    if(rowPartitionId)
    {
      if(!state.displayPartitionIdMap[rowPartitionId])
      {
        state.displayPartitionIdMap[rowPartitionId] = [];
      }

      if(!state.displayPartitionIdMap[rowPartitionId].includes(itemId))
      {
        state.displayPartitionIdMap[rowPartitionId].push(itemId);
      }
    }
  }
}

function msgListToDisplayList(
  state: IChat,
  msgList: SigMessage[],
  _prevDateStr?: string
): IChatItem[]
{
  const displayItems = [] as IChatItem[];

  let prevDateStr: string | undefined = _prevDateStr;
  const len = msgList.length;
  for(let i = 0; i < len; i++)
  {
    const msg = msgList[i];
    if(state.displayOffsetMap[msg.messageOffset] !== undefined) // ignore duplicate messages
    {
      continue;
    }
    const sig = createBubble(msg);

    if(isGroupId(state.sigChatId) && !sig.isCallerSender)
    {
      sig.header = {
        alwaysShowHeader: true
      };
    }

    sig.formBubbleTitleColor = getFormNameColor(
      state.sigEntUserId,
      sig?.sigSpreadsheetRow?.formValue?.updatedBy,
      sig?.sigSpreadsheetRow?.updatedKeySet
    );
    const dateStr = formatChatInfoDate(toDateFromDetailedDateTime(sig.creationTime), state.displayDateFormat);

    if(prevDateStr !== dateStr)
    {
      displayItems.push({
        date: dateStr,
        gap: "large"
      } as IChatItemDate);
      prevDateStr = dateStr;
    }
    displayItems.push({
      sig: sig,
      gap: "large"
    } as IChatItemSig);
  }

  return displayItems;
}

function getMsgGap(
  msgList: IChatItem[],
  index: number,
  displayFirstItem?: IChatItemSig,
  displayLastItem?: IChatItemSig): TypeChatGap
{
  let gap: TypeChatGap = "large";
  if(isChatItemPickable(msgList[index]))
  {
    const msg = (msgList[index] as IChatItemSig).sig;
    const len = msgList.length;
    const nextI = index + 1;
    const nextMsg = nextI < len ? (msgList[nextI] as IChatItemSig).sig : undefined;
    if(nextMsg && msg.senderId === nextMsg.senderId)
    {
      gap = "small";
    }
    if(len - 1 === index && msg.senderId === displayFirstItem?.sig?.senderId)
    {
      gap = "small";
    }
    if(index === 0 && msg.senderId === displayLastItem?.sig?.senderId)
    {
      displayLastItem.gap = "small";
    }
  }
  return gap;
}

function getChatItemFromMsgId(state: IChat, msgId: MessageId): IChatItemSig | undefined
{
  const displayItemIdsLength = state.displayItemIds.length;
  for(let i = 0; i < displayItemIdsLength; i++)
  {
    const displayItemId = state.displayItemIds[i];
    const chatItem = state.displayItemMap[displayItemId];
    if(isChatItemPickable(chatItem) && (chatItem as IChatItemSig).sig.messageId === msgId)
    {
      return chatItem as IChatItemSig;
    }
  }

  return undefined;
}

function stitchChatItemOnPrepend(state: IChat, displayItemIds: TypeChatItemId[], newMsgList: SigMessage[])
{
  if(newMsgList.length > 0)
  {
    const lastMsg = newMsgList.at(-1);
    if(lastMsg)
    {
      const dateStr = formatChatInfoDate(new Date(lastMsg.creationTime), state.displayDateFormat);
      const firstItemDateStr = state.displayItemMap[state.displayFirstItemId as number];
      if(isChatItemDate(firstItemDateStr) && dateStr === (firstItemDateStr as IChatItemDate).date)
      {
        displayItemIds.shift();
        fnChatSetDisplay(state);
      }
    }
  }
}

function getFormMessageRowId(item: IChatItem): string | undefined
{
  const sig = (item as IChatItemSig)?.sig;

  if(sig?.payload)
  {
    if(sig?.payload.messageType === "report")
    {
      const reportPayload = sig?.payload as DtoMessagePayloadReport;
      if(reportPayload.inputFormId)
      {
        return reportPayload.rowId;
      }
    }
    return getMsgPayloadRowId(sig.payload);
  }

  return undefined;
}

function insertDisplayItem(
  state: IChat,
  displayItem: IChatItem,
  itemId: TypeChatItemId,
  displayItemIds: TypeChatItemId[])
{
  state.displayItemMap[itemId] = displayItem;
  displayItemIds.push(itemId);
  const rowId = getFormMessageRowId(displayItem);
  if(rowId)
  {
    insertRowIdMapItem(state, rowId, itemId);
    insertRowPartitionIdMapItem(state, displayItem, itemId);
  }
  const messageId = getMessageId(displayItem);
  if(messageId)
  {
    state.displayMsgIdMap[messageId] = itemId;
  }
}

function getLastSenderMsg(messageList: SigMessage[])
{
  const len = messageList.length;
  for(let i = len - 1; i >= 0; i--)
  {
    const msg = messageList[i];
    if(!msg.isCallerSender)
    {
      return msg;
    }
  }
}
