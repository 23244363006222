import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {LayoutGap} from "../layout/LayoutGap";

export default function AvatarGap(props: {
  ml?: number,
  mr?: number,
})
{
  const theme = useTheme();
  const avatarSize = theme.common.sizeAvatar;

  return (
    <LayoutGap
      width={avatarSize}
      height={avatarSize}
      ml={px(props.ml)}
      mr={px(props.mr)}
    />
  );
}
