import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldHandle} from "../../../../api/meta/base/dto/DefnFieldHandle";
import {FieldValueHandle} from "../../../../api/meta/base/dto/FieldValueHandle";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {FIELD_HANDLE} from "../../../atom/assets/HelperTextStudio";
import IconStrip from "../../../atom/icon/IconStrip";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldHandle(props: {
  defn: DefnFieldHandle,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as FieldValueHandle | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawTextField
                {...props}
                helperText={FIELD_HANDLE}
                value={fieldValue}
                onBlur={field.onBlur}
                onChange={field.onChange}
                ref={field.ref}
                error={error}
                endAdornment={<IconStrip value={"handle"} />}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
