import {isEmpty} from "lodash";
import {nextMetaIdPrompt} from "../../../../api/meta/base/ApiPlus";
import {StudioEntPrompt} from "../../../../api/meta/base/dto/StudioEntPrompt";
import {EntId, MetaIdPrompt, MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntPrompt, moveEntItem, removeEntPrompt, updateEntPromptSettings} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntPrompts extends ISrvc
{
  addEntPrompt(entId: EntId, prompt: StudioEntPrompt)
  {
    dispatchEnt(entId, addEntPrompt({
      entId: entId,
      sig: {
        ...prompt,
        metaId: prompt.metaId || nextMetaIdPrompt()
      }
    }));
  }

  removeEntPrompt(entId: EntId, metaIdPrompt: MetaIdPrompt)
  {
    dispatchEnt(entId, removeEntPrompt({
      entId: entId,
      metaId: metaIdPrompt
    }));
  }

  updateEntPromptSettings(
    entId: EntId,
    fieldSeparatorSet?: string[],
    lineSeparator?: string,
    acceptPromptViaEmail?: boolean,
    acceptPromptViaWhatsapp?: boolean)
  {
    dispatchEnt(entId, updateEntPromptSettings({
      entId,
      fieldSeparatorSet,
      lineSeparator,
      acceptPromptViaEmail,
      acceptPromptViaWhatsapp
    }));
  }

  copyEntPrompt(entId: EntId, metaIdPrompt: MetaIdPrompt)
  {
    const rootState = store.getState();
    const promptMap = selectCacheStudioEnt(rootState, entId)?.ent.promptMap;
    const prompt = promptMap?.map[metaIdPrompt];

    if(!isEmpty(prompt))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "prompt",
        payload: prompt
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntPrompt(entId: EntId, metaIdPrompt: MetaIdPrompt)
  {
    const rootState = store.getState();
    const promptMap = rootState.cache.studio.ent.entMap[entId]?.ent.promptMap;

    if(!promptMap)
    {
      return;
    }
    const index = promptMap.keys.indexOf(metaIdPrompt);
    if(index === -1)
    {
      return;
    }
    const entPrompt = promptMap.map[metaIdPrompt]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(promptMap.map[metaIdPrompt])))
      : undefined;
    if(entPrompt)
    {
      dispatchEnt(entId, addEntPrompt({
        entId: entId,
        sig: entPrompt,
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdSpreadsheet,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "promptList",
      moveDirection
    }));
  }

}
