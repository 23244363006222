import ISrvc from "../../../../base/ISrvc";
import SrvcHomeMainFooterInputBar from "./SrvcHomeMainFooterInputBar";
import SrvcHomeMainFooterMsgSelection from "./SrvcHomeMainFooterMsgSelection";
import SrvcHomeMainFooterSpeedDial from "./SrvcHomeMainFooterSpeedDial";

export default class SrvcHomeMainFooter extends ISrvc
{
  public readonly inputBar = new SrvcHomeMainFooterInputBar(
    state => state.home.main.groupMemberMentionList,
    state => state.home.aside.groupMemberMentionList
  );
  public readonly mainFooterSpeedDial = new SrvcHomeMainFooterSpeedDial();
  public readonly msgSelectionBar = new SrvcHomeMainFooterMsgSelection();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.inputBar,
      this.mainFooterSpeedDial,
      this.msgSelectionBar
    );
  }
}
