import {useCallback} from "react";
import {useState} from "react";
import Draggable from "react-draggable";
import {EnumDefnCodeEditorLanguage} from "../../api/meta/base/Types";
import {copyToClipboard} from "../../base/plus/StringPlus";
import DialogAtom from "../atom/dialog/DialogAtom";
import {IDialogAtomRef} from "../atom/dialog/DialogAtom";
import {Cancel} from "../atom/dialog/DialogConfirm";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawCodeEditor from "../atom/raw/RawCodeEditor";

type EnumButtonType =
  | "Ok"
  | "Apply"

export default function DialogNeoQL(props: {
  buttonValue?: EnumButtonType
  title?: string,
  onLearn?: () => void,
  onClickButton?: (queryContent?: string) => void,
  onClose?: () => void,
  value?: string,
  defaultLanguage?: EnumDefnCodeEditorLanguage,
  editorTheme?: string,
  readonly?: boolean,
  enableMiniMap?: boolean,
  enableLineNumbers?: boolean,
  draggable?: boolean,
  dragAndDrop?: boolean,
  folding?: boolean,
  disableFooterButtons?: boolean,
  disableHeaderButtons?: boolean,
})
{
  const onClickOK = props.onClickButton;
  const rightFooterActions = props.buttonValue && [Cancel, props.buttonValue];

  const [queryContent, setQueryContent] = useState<string>(props.value ?? "");
  const cbDialogRef = {} as IDialogAtomRef;

  const onClickFooterAction = useCallback((action: string) =>
  {
    if(action === props.buttonValue)
    {
      onClickOK && onClickOK(queryContent);
      cbDialogRef.close();
    }
    else
    {
      cbDialogRef.close();
    }
  }, [cbDialogRef]);

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={"[class*=\"MuiDialogContent-root\"]"}
    >
      <DialogAtom
        cbRef={cbDialogRef}
        title={props.title ?? "Neo QL"}
        headerIcons={!props.disableHeaderButtons ? ["copy"] : []}
        onClose={props.onClose}
        draggable={props.draggable ?? true}
        onClickHeaderIcon={(iconName) =>
        {
          if(iconName === "copy")
          {
            props.value && copyToClipboard(props.value);
          }
        }}
        contentWidth={1200}
        contentHeight={750}
        content={
          <LayoutFlexCol
            flexGrow={1}
            width={"100%"}
          >
            <RawCodeEditor
              value={props.value}
              onChange={(value) => value ? setQueryContent(value) : setQueryContent("")}
              defaultLanguage={props.defaultLanguage ?? "sql"}
              editorTheme={"light"}
              enableMiniMap={props.enableMiniMap ?? true}
              enableLineNumbers={props.enableLineNumbers ?? true}
              readonly={props.readonly ?? false}
              dragAndDrop={props.dragAndDrop ?? true}
            />
          </LayoutFlexCol>
        }
        rightFooterActions={!props.disableFooterButtons ? rightFooterActions : undefined}
        onClickFooterAction={onClickFooterAction}
      />
    </Draggable>
  );
}


