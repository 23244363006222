import ISrvc from "../../base/ISrvc";
import {SrvcStoreDrawer} from "./drawer/SrvcStoreDrawer";
import {SrvcStoreItem} from "./storeItem/SrvcStoreItem";
import SrvcStoreItemAdmins from "./storeItem/SrvcStoreItemAdmins";

export default class SrvcStore extends ISrvc
{
  // TODO Neel/Aditya move this in app package
  public readonly drawer = new SrvcStoreDrawer(
    state => state.store.drawer.storePicker,
    state => state.store.drawer.storePickerFilter
  );
  public readonly storeItem = new SrvcStoreItem();
  public readonly admins = new SrvcStoreItemAdmins();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.drawer,
      this.storeItem,
      this.admins
    );
  }
}
