import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../base/plus/ListPlus";

export function reducersPluginAside()
{
  return combineReducers({
    pluginResourcesFileList: createListSlice("pluginResourcesFileList").reducer,
    pluginHeadersList: createListSlice("pluginHeadersList").reducer,
    pluginQueryParamsList: createListSlice("pluginQueryParamsList").reducer,
    pluginParserList: createListSlice("pluginParserList").reducer
  });
}
