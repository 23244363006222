import {ListItemText} from "@mui/material";
import {useTheme} from "@mui/material";
import {Tooltip} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import React from "react";
import {TypeTooltip} from "../../../base/types/TypesGlobal";

export type TypeTooltipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

export default function RawTooltip(props: {
  title?: TypeTooltip,
  placement?: TypeTooltipPlacement,
  bgcolor?: string,
  color?: string,
  children: React.ReactElement
})
{
  const {
    title,
    placement,
    bgcolor,
    color,
    children
  } = props;
  const theme = useTheme();
  const darkTheme = createTheme({
    palette: {
      mode: "dark"
    }
  });

  return (
    <Tooltip
      title={
        typeof title === "object" ?
          <ListItemText
            primary={title.primary}
            secondary={title.secondary}
            secondaryTypographyProps={{
              color: darkTheme.palette.text.secondary
            }}
          /> : title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: bgcolor ?? theme.common.bgcolorTooltip,
            color: color
          }
        }
      }}
    >
      {children}
    </Tooltip>
  );
}
