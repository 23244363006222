import {sliceFakeTable} from "./SliceKanban";

export const {

  //region init
  kanbanSetShowMenu,
  kanbanSetIfExistIsInvisibleSpreadsheetRow,
  kanbanSetIsReadOnly,
  kanbanSetIgnoreSelection,
  kanbanSetError,
  kanbanSetUserField,
  kanbanSetUserFieldVar,
  kanbanSetVersion,
  kanbanSetItemByIds,
  kanbanRefresh,
  //endregion
  kanbanSetItem,
  kanbanSetItemCol,
  kanbanSetIfExistUserField,
  kanbanSetItemIfExistUserFieldVar,
  kanbanResetItem,

  //region search
  kanbanSetSearch,
  kanbanSetFilter,
  kanbanSetSearchWords,
  //endregion
  kanbanSetSort,
  kanbanSetSortOrder,

  //selection
  KanbanSetSelectedItemId,

  kanbanMoveItem,
  kanbanSetUiModeMobile,
  kanbanSetDisplaySelectedColumnsByItemId,

  kanbanSetIfExistIsItemWithMedia,
  kanbanSetIfExistSigSpreadsheetRowExpiry

} = sliceFakeTable.actions;
