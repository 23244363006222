import {FormControl} from "@mui/material";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldChipSet} from "../../../../api/meta/base/dto/DefnFieldChipSet";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPropertyMap} from "../../../../api/meta/base/dto/DefnFieldPropertyMap";
import {FieldValuePropertyMap} from "../../../../api/meta/base/dto/FieldValuePropertyMap";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogPropertyMap from "../../../dialog/DialogPropertyMap";
import {IPropertyMap} from "../../../dialog/DialogPropertyMap";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldBase from "../raw/FieldBase";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";
import FieldRawRefButton from "../raw/FieldRawRefButton";

export default function FieldPropertyMap(props: {
  defn: DefnFieldPropertyMap
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldRequired
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder() as string;

  const fieldId = getFieldKey(defn);

  const formStore = formCtx.getStore();

  const isLastField = false;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const fieldVariant = defnTheme.fieldVariant;
  const label = getCompLabel(defn);

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldChipSet) || defn.disabled;
  const padding = getDefnFieldPadding(defn);

  const defnLabel = {
    label: Boolean(required)
      ? label + " *"
      : label
  } as DefnFieldLabel;

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as FieldValuePropertyMap;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <FieldBase fieldId={fieldId} {...padding}>
                  <FormControl
                    fullWidth
                    variant={fieldVariant}
                    error={isError}
                  >
                    <LayoutFlexRow
                      alignItems={"start"}
                      mr={gapQuarter / 2}
                      overflowY={"visible"}
                      overflowX={"visible"}
                    >
                      <RawStudioBuildPropertyMap
                        fieldId={fieldId}
                        formStore={formStore}
                        isFormReadOnly={readOnly || disabled}
                        onChange={onChange}
                        isLastField={isLastField}
                        fieldValue={fieldValue}
                        placeHolder={placeHolder}
                      />
                      <FieldRawRefButton
                        defn={defn}
                      />
                    </LayoutFlexRow>
                  </FormControl>
                </FieldBase>
              }
            />
          );
        }

        return (
          <FieldBase fieldId={fieldId} {...padding}>
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
              >
                <FieldLabel defn={defnLabel} />
                <FieldRawRefButton
                  defn={defn}
                />
              </LayoutFlexRow>

              <RawStudioBuildPropertyMap
                fieldId={fieldId}
                formStore={formStore}
                isFormReadOnly={readOnly || disabled}
                onChange={onChange}
                isLastField={isLastField}
                fieldValue={fieldValue}
                placeHolder={placeHolder}
              />
            </FormControl>
          </FieldBase>
        );
      }
      }
    />
  );
}

function RawStudioBuildPropertyMap(props: {
  fieldId: MetaIdField,
  fieldValue: FieldValuePropertyMap,
  isFormReadOnly?: boolean,
  formStore?: FormStore,
  isLastField?: boolean,
  placeHolder?: string,
  onChange: (value: FieldValuePropertyMap) => void
})
{
  const {
    fieldId,
    formStore,
    isLastField,
    isFormReadOnly,
    placeHolder
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<IPropertyMap>;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: IPropertyMap[]) => props.onChange(convertArrayToMap(fieldValue));

  const doLoad = (selectList: SelectList, fieldValue: IPropertyMap[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        const listId = value.key;
        uiItemIds.push(listId);

        uiItemsById[listId] = {
          type: "ps",
          primary: {
            text: listId
          },
          secondary: {
            text: value.value
          },
          hideMenu: isFormReadOnly,
          userField: {
            [fieldListItemVal]: value
          }
        } as IListItemAPSA;
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogPropertyMap
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) =>
          {
            cbRefList.addItem(newValue);
          }}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as IPropertyMap
        : undefined;

      pageCtx.showDialog(
        <DialogPropertyMap
          values={oldValue}
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          onClickOk={(newValue) => cbRefList.updateItem(newValue, key)}
          validationError={validationError}
        />
      );
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      onChange={onChange}
      disableSpotMenu={isFormReadOnly}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
      emptyComp={placeHolder}
    />
  );
}

function convertMapToArray(map?: FieldValuePropertyMap): IPropertyMap[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key, index) =>
  {
    return {
      key: key,
      value: map.values ? map.values[index] : ""
    };
  });
}

function convertArrayToMap(arr: IPropertyMap[]): FieldValuePropertyMap
{
  const keys = [] as string[];
  const values = [] as string[];

  arr.forEach((value, index) =>
  {
    const key = value.key;
    keys.push(key);
    values[index] = value.value ?? "";
  });

  return {
    keys: keys,
    values: values
  } as FieldValuePropertyMap;
}
