import {MsgGrantBearerToken} from "../../../api/core/user/msg/MsgGrantBearerToken";
import {RpcUser} from "../../../api/core/user/RpcUser";
import {SigBearerToken} from "../../../api/core/user/sig/SigBearerToken";
import {ISig} from "../../../api/meta/base/sig/ISig";
import {getSuffix} from "../../../api/meta/base/SysId";
import {MediaId} from "../../../api/meta/base/Types";
import {ServiceName} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {RequestId} from "../../../api/meta/base/Types";
import {IMediaCall} from "../../../api/nucleus/base/IMediaCall";
import {setMediaCallFactory} from "../../../api/nucleus/base/IMediaCall";
import {IRpcCall} from "../../../api/nucleus/base/IRpcCall";
import {setRpcCallFactory} from "../../../api/nucleus/base/IRpcCall";
import {ISigAcceptor} from "../../../api/nucleus/base/ISigAcceptor";
import {IWsocCall} from "../../../api/nucleus/base/IWsocCall";
import {setWsocCallFactory} from "../../../api/nucleus/base/IWsocCall";
import {getSig} from "../../../api/nucleus/base/Protocol";
import {SigRefreshToken} from "../../../api/nucleus/stem/sig/SigRefreshToken";
import ISrvc from "../../../base/ISrvc";
import ISrvcAuth from "../../../base/ISrvcAuth";
import LocalCookie from "../../../base/local/LocalCookie";
import LocalTabId from "../../../base/local/LocalTabId";
import LocalVerifyHandle from "../../../base/local/LocalVerifyHandle";
import MediaCallWithRetry from "../../../base/net/MediaCallWithRetry";
import RpcCallWithRetry from "../../../base/net/RpcCallWithRetry";
import WsocCall from "../../../base/net/WsocCall";
import WsocClient from "../../../base/net/WsocClient";
import {STR_APP_VERSION} from "../../../base/plus/ConstantsPlus";
import {PromiseQueue} from "../../../base/plus/JsPlus";
import {isSslEnabled} from "../../../base/plus/SysPlus";
import {setWindowName} from "../../../base/plus/SysPlus";
import {clearWindowName} from "../../../base/plus/SysPlus";
import {setFlagBearerToken} from "../../../base/slices/SliceAuth";
import {resetStore} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export default class SrvcAuth extends ISrvc implements ISrvcAuth
{
  private bearerToken?: string;
  private wsocClient?: WsocClient;
  private requireSignOut?: boolean;
  private firstBoot = true;
  private requireToStoreCookieInLocal = false;
  private grantRefreshTokenRpcCallQueue = new PromiseQueue();
  private doBoot: (() => void) | undefined;

  constructor()
  {
    super();
    this.setRequireToStoreCookieInLocal();

    const self = this;
    setRpcCallFactory({
      create<S extends ISig>(entId: EntId, serviceName: ServiceName, apiName: string):
        IRpcCall<S>
      {
        return new RpcCallWithRetry<S>(self, entId, serviceName, apiName);
      }
    });

    setWsocCallFactory({
      create<S extends ISig>(entId: EntId, serviceName: ServiceName, apiName: string): IWsocCall<S>
      {
        return new WsocCall<S>(entId, serviceName, apiName);
      }
    });

    setMediaCallFactory({
      create(entId: EntId, serviceName: ServiceName, mediaId: MediaId): IMediaCall
      {
        return new MediaCallWithRetry(self, entId, serviceName, mediaId);
      }
    });
  }

  //region public

  getBearerToken()
  {
    return this.bearerToken;
  }

  setBearerToken(bearerToken: string)
  {
    this.bearerToken = bearerToken;

    store.dispatch(setFlagBearerToken(true));
  }

  getWsocClient()
  {
    return this.wsocClient;
  }

  getRequireToStoreCookieInLocal(): boolean
  {
    return this.requireToStoreCookieInLocal;
  }

  doSignOut()
  {
    const wsocClient = this.wsocClient;
    if(wsocClient)
    {
      wsocClient.close();
    }

    this.bearerToken = undefined;
    this.wsocClient = undefined;
    this.firstBoot = true;
    this.requireSignOut = undefined;
    Srvc.app.status.setFlagFirstBoot(true);
    LocalCookie.remove();
    LocalVerifyHandle.remove();
    LocalTabId.remove();

    clearWindowName();
    resetStore();
  }

  //endregion

  //region rpc

  // will never throw UnauthorizedBearerToken
  // through signOutIfUnauthorizedRefreshToken all sign-in page to retry without logging out
  rpcGrantBearerToken(signOutIfUnauthorizedRefreshToken: boolean, sigAcceptor?: ISigAcceptor<SigBearerToken>)
  {
    store.dispatch((dispatch, getState) =>
    {
      const caller = getState().cache.app.caller;
      let tabId = LocalTabId.get();
      if(caller !== undefined && caller.callerUserId !== undefined)
      {
        // we do this to prevent copying of tabId from session storage and using it to establish
        // connection (protected through refresh token but why create a hole)
        tabId += getSuffix(caller.callerUserId);
      }

      const msg = {
        tabId: tabId,
        appVersion: STR_APP_VERSION,
        sendCaller: caller.callerVersion === undefined
      } as MsgGrantBearerToken;

      const self = this;

      RpcUser.grantBearerToken(msg, envSig =>
      {
        const envError = envSig.error;
        if(envError)
        {
          const errorCode = envError.errorCode;
          if(errorCode === "unauthorizedRefreshToken")
          {
            clearWindowName();

            if(signOutIfUnauthorizedRefreshToken)
            {
              this.rpcSignOut();
            }
          }
          else if(errorCode === "unauthorizedBearerToken")
          {
            clearWindowName();
          }
          dispatch(setFlagBearerToken(false));

        }
        else
        {
          const sig = getSig<SigBearerToken>(envSig);
          if(sig.updateRefreshToken)
          {
            this.grantRefreshToken(envSigRefreshToken =>
            {
              if(envSigRefreshToken.error)
              {
                Srvc.app.toast.showErrorToast("Could not acquire refresh token");
                Srvc.app.auth.rpcSignOut();
              }
            });
          }

          self.bearerToken = sig.bearerToken;
          dispatch(setFlagBearerToken(true));
          this.requireSignOut = true;

          let wsocClient = self.wsocClient;
          if(wsocClient)
          {
            wsocClient.close();
          }

          // re-send prior calls on authorization
          let callMap: Map<RequestId, WsocCall<ISig>>;
          if(wsocClient)
          {
            callMap = new Map<RequestId, WsocCall<ISig>>(wsocClient.callMap);
          }
          else
          {
            callMap = new Map<RequestId, WsocCall<ISig>>();
          }

          wsocClient = new WsocClient(this, callMap);
          self.wsocClient = wsocClient;
          wsocClient.open(() =>
          {
            this.doBoot = () =>
            {
              Srvc.app.pubsub.doInit();

              setWindowName();

              const sigCaller = sig.caller;
              if(sigCaller)
              {
                Srvc.app.boot.boot(sigCaller, this.firstBoot, () =>
                {
                  Srvc.app.status.setFlagFirstBoot(false);
                  this.firstBoot = false;
                });
              }
            };
          });
        }

        if(sigAcceptor)
        {
          sigAcceptor(envSig);
        }
      });
    });
  }

  rpcSignOut()
  {
    if(!Boolean(this.requireSignOut))
    {
      return;
    }

    this.requireSignOut = false;

    // this sends refresh token
    RpcUser.signOut(envSig =>
    {
      Srvc.signOut();

      const envError = envSig.error;
      if(envError)
      {
        const errorCode = envError.errorCode;
        if(errorCode !== "unauthorizedRefreshToken")
        {
          Srvc.app.toast.showErrorToast(envSig);
        }
      }
      else
      {
        store.dispatch(setFlagBearerToken(false));
      }
    });
  }

  fireWsocAuth(): void
  {
    this.doBoot?.();
    Srvc.onWsocAuth();
  }

  fireWsocClose(): void
  {
    Srvc.onWsocClose();
  }

  //endregion

  //region private

  private grantRefreshToken(sigAcceptor: ISigAcceptor<SigRefreshToken>): void
  {
    const cbRpcCall = () => new Promise<void>((resolve) =>
    {
      RpcUser.grantRefreshToken(envSigRefreshToken =>
      {
        sigAcceptor(envSigRefreshToken);
        resolve();
      });
    });

    this.grantRefreshTokenRpcCallQueue.add(cbRpcCall);
  }

  private setRequireToStoreCookieInLocal()
  {
    const url = new URL(window.location.href);
    const widgetId = url.searchParams.get("widgetId");
    const isWidget = Boolean(widgetId?.trim());
    const hostname = window.location.hostname;

    this.requireToStoreCookieInLocal = Boolean(isWidget || hostname === "localhost" || !isSslEnabled());
  }

  //endregion

}
