import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnStudioCodeEditor} from "../../../api/meta/base/dto/DefnStudioCodeEditor";
import {StudioValueCodeJavascript} from "../../../api/meta/base/dto/StudioValueCodeJavascript";
import {StudioVarHtml} from "../../../api/meta/base/dto/StudioVarHtml";
import {findWordsBetweenDollarBraces} from "../../../base/plus/StringPlus";

export function fnVariableBuilderHtml(fieldValueHtml: string)
{
  function getVarComp()
  {
    return {
      [fieldValueHtml]: {
        type: "studioCodeEditor",
        metaId: fieldValueHtml,
        name: "Value",
        language: "html",
        required: true
      } as DefnStudioCodeEditor
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarHtml
  )
  {
    const varValue = entVariable.value;
    if(varValue?.value)
    {
      const value = varValue.value;

      valueMap[fieldValueHtml] = {
        value: value
      } as StudioValueCodeJavascript;
    }
  }

  function defnToDto(
    valueMap: FieldValues
  )
  {
    const codeValue = valueMap[fieldValueHtml] as StudioValueCodeJavascript;

    if(!codeValue || !codeValue.value)
    {
      return;
    }

    const val = findWordsBetweenDollarBraces(codeValue.value);
    const paramSet = val.paramSet;

    return {
      value: {
        value: val.queryStr,
        paramSet: isEmpty(paramSet) ? undefined : paramSet
      }
    } as StudioVarHtml;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
