// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ISig} from './meta/base/sig/ISig';
import {ISigPushAcceptor} from './nucleus/base/ISigPushAcceptor';
import {ServiceName} from './meta/base/Types';
import {SigSignOut} from './core/session/sig/SigSignOut';
import {SigTopic} from './core/session/sig/SigTopic';
import {SigTopicGroupTyping} from './home/session/sig/SigTopicGroupTyping';
import {SigTopicMessageNew} from './home/session/sig/SigTopicMessageNew';
import {SigTopicMessageProps} from './home/session/sig/SigTopicMessageProps';
import {SigTopicPluginApiRequest} from './ent/agent/sig/SigTopicPluginApiRequest';

let cbSessionSigSignOut: ISigPushAcceptor<SigSignOut>;
let cbSessionSigTopic: ISigPushAcceptor<SigTopic>;
let cbSessionSigTopicGroupTyping: ISigPushAcceptor<SigTopicGroupTyping>;
let cbSessionSigTopicMessageNew: ISigPushAcceptor<SigTopicMessageNew>;
let cbSessionSigTopicMessageProps: ISigPushAcceptor<SigTopicMessageProps>;
let cbAgentSigTopicPluginApiRequest: ISigPushAcceptor<SigTopicPluginApiRequest>;

export function setCbSessionSigSignOut(fn: ISigPushAcceptor<SigSignOut>)
{
  cbSessionSigSignOut = fn;
}

export function setCbSessionSigTopic(fn: ISigPushAcceptor<SigTopic>)
{
  cbSessionSigTopic = fn;
}

export function setCbSessionSigTopicGroupTyping(fn: ISigPushAcceptor<SigTopicGroupTyping>)
{
  cbSessionSigTopicGroupTyping = fn;
}

export function setCbSessionSigTopicMessageNew(fn: ISigPushAcceptor<SigTopicMessageNew>)
{
  cbSessionSigTopicMessageNew = fn;
}

export function setCbSessionSigTopicMessageProps(fn: ISigPushAcceptor<SigTopicMessageProps>)
{
  cbSessionSigTopicMessageProps = fn;
}

export function setCbAgentSigTopicPluginApiRequest(fn: ISigPushAcceptor<SigTopicPluginApiRequest>)
{
  cbAgentSigTopicPluginApiRequest = fn;
}

export function onSignal(serviceName: ServiceName, sigName: string, sig: ISig): boolean
{
  if(serviceName === 'agent')
  {
    if(sigName === 'SigTopicPluginApiRequest')
    {
      if(cbAgentSigTopicPluginApiRequest)
      {
        cbAgentSigTopicPluginApiRequest(sig as SigTopicPluginApiRequest);
        return true;
      }
    }
  }
  else if(serviceName === 'session')
  {
    if(sigName === 'SigSignOut')
    {
      if(cbSessionSigSignOut)
      {
        cbSessionSigSignOut(sig as SigSignOut);
        return true;
      }
    }
    else if(sigName === 'SigTopic')
    {
      if(cbSessionSigTopic)
      {
        cbSessionSigTopic(sig as SigTopic);
        return true;
      }
    }
    else if(sigName === 'SigTopicGroupTyping')
    {
      if(cbSessionSigTopicGroupTyping)
      {
        cbSessionSigTopicGroupTyping(sig as SigTopicGroupTyping);
        return true;
      }
    }
    else if(sigName === 'SigTopicMessageNew')
    {
      if(cbSessionSigTopicMessageNew)
      {
        cbSessionSigTopicMessageNew(sig as SigTopicMessageNew);
        return true;
      }
    }
    else if(sigName === 'SigTopicMessageProps')
    {
      if(cbSessionSigTopicMessageProps)
      {
        cbSessionSigTopicMessageProps(sig as SigTopicMessageProps);
        return true;
      }
    }
  }

  return false;
}
