import {useRef} from "react";
import React, {useCallback} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export function DialogVariableNumberSetNumber(props: {
  values?: number,
  isFormReadOnly?: boolean,
  onClickOk: (value: number) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const value = props.values;
  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });
  const onSubmit = useCallback((values: FieldValues) =>
  {
    const value = valueToDto(values);
    if(value !== undefined)
    {
      props.onClickOk(value);
    }
  }, []);

  return (
    <DialogDefnForm
      title={value === undefined ? "New number set" : "Update number set"}
      formProps={{
        defnForm: getDefnForm(),
        onSubmit: onSubmit,
        formReadonly: props.isFormReadOnly,
        initValues: value
          ? {...dtoToValue(value)}
          : undefined,
        cbRef: cbRef.current
      }}
      addMoreCheckBoxLabel={!value
        ? "Add more number sets"
        : undefined}
      onClose={props.onClose}
      contentHeight={200}
    />
  );
}

const compVariableNumberSetNumber = "variableNumberSetNumber";

function valueToDto(values: FieldValues): number | undefined
{
  return fnFieldValueToRawValue("decimal", values[compVariableNumberSetNumber]) as number;

}

function dtoToValue(dto?: number)
{
  if(dto)
  {
    return {
      [compVariableNumberSetNumber]: fnRawValueToFieldValue("decimal", dto)
    };
  }
}

function getDefnForm()
{
  return createDefaultDefnFormStudio({
    [compVariableNumberSetNumber]: {
      type: "number",
      metaId: compVariableNumberSetNumber,
      name: "Number",
      required: true
    } as DefnFieldNumber,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [compVariableNumberSetNumber]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);

}
