import {useTheme} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {TextField} from "@mui/material";
import {Box} from "@mui/system";
import {forwardRef} from "react";
import {useRef} from "react";
import {Ref} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldColor} from "../../../../api/meta/base/dto/DefnFieldColor";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueColor} from "../../../../api/meta/base/dto/FieldValueColor";
import {defnTheme} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import BoxColor from "../../../atom/box/BoxColor";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldColor(props: {
  defn: DefnFieldColor
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;

  const {
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();

  const fieldVariant = defnTheme.fieldVariant;
  const helperText = getFieldHelperText();

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error,
          isTouched
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueColor | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
              onClick={onClick}
            >
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
              >
                <RawFieldColor
                  defn={defn}
                  fieldValue={fieldValue}
                  onChange={field.onChange}
                  onClickDelete={field.onChange}
                  isError={isError}
                />
              </LayoutFlexRow>

              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawFieldColor(props: {
  defn: DefnFieldColor,
  fieldValue?: FieldValueColor,
  onChange: (value?: FieldValueColor | null) => void,
  icon?: string,
  ref?: Ref<HTMLInputElement>,
  onClick?: () => void,
  onClickDelete?: (value: undefined) => void,
  isError?: boolean
})
{
  const formCtx = useFormCtx();
  const colorFieldRef = useRef<HTMLInputElement>(null);
  const fieldValue = props.fieldValue;
  const defn = props.defn;
  const isError = props.isError;
  const onClick = () =>
  {
    if(!readonly)
    {
      colorFieldRef.current?.click();
    }
  };
  const readonly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(getFieldKey(defn));

  return (
    <TextField
      required={defn.required}
      disabled={defn.disabled}
      error={isError}
      label={defn.label}
      fullWidth={true}
      onClick={onClick}
      value={fieldValue?.value?.toUpperCase() || ""}
      size={defnTheme.fieldSize}
      sx={{
        height: "100%",
        ".MuiInputBase-input": {
          textOverflow: "ellipsis",
          cursor: (!readonly || !disabled) ? "pointer" : undefined
        },
        ".MuiFilledInput-input": {
          pt: gapHalf,
          pb: gapHalf
        },
        ...borderColor && {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: borderColor
            }
          }
        }
      }}
      InputProps={{
        readOnly: true,
        endAdornment: <RawFieldColorPicker
          ref={colorFieldRef}
          fieldValue={fieldValue}
          onChange={props.onChange}
          disabled={disabled}
          readOnly={readonly}
        />
      }}
      onKeyDown={(event) =>
      {
        if(event.key === "Enter")
        {
          onClick();
        }
      }}
    />
  );
}

type FieldColorPickerProps = {
  fieldValue?: FieldValueColor;
  onChange: (value?: FieldValueColor | null) => void,
  disabled?: boolean,
  readOnly?: boolean,
};
const RawFieldColorPicker = forwardRef<HTMLInputElement, FieldColorPickerProps>((props: FieldColorPickerProps, ref
) =>
{
  const theme = useTheme();
  const gap = theme.common.gapHalf;
  const fieldValue = props.fieldValue;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
  {
    const value = e.target.value;
    if(value && value.length > 0)
    {
      props.onChange({
        value: value
      } as FieldValueColor);
    }
    else
    {
      props.onChange(null);
    }
  };

  const editable = (!props.disabled || !props.readOnly);

  return (
    <>
      <Box
        position={"relative"}
        flexGrow={1}
      >
        <BoxColor
          pl={gapHalf}
          bgcolor={fieldValue?.value}
        />

        {editable && <input
          ref={ref}
          value={fieldValue?.value || ""}
          onChange={onChange}
          style={{
            marginRight: px(gap),
            visibility: "hidden",
            position: "absolute"
          }}
          type={"color"}
        />}
      </Box>
      {fieldValue?.value && editable &&
        <Box
          ml={px(1)}
          mr={px(-gapStd)}
          onClick={(e) =>
          {
            e.stopPropagation();
          }}
        >
          <RawIconStrip
            iconStrip={["close"]}
            toolTipMap={{"close": "Clear"} as Record<EnumIconStrip, string>}
            iconStripDisableRipple={["close"]}
            onClick={(icon) =>
            {
              if(icon === "close")
              {
                props.onChange(null);
              }
            }}
          />
        </Box>
      }
    </>
  );
});
