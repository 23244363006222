import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {StudioFieldSignature} from "../../../../../api/meta/base/dto/StudioFieldSignature";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyShowCapturedValuesOnAside} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyCaptureTime} from "../../base/TypesFormBuilder";
import {propKeyCaptureUser} from "../../base/TypesFormBuilder";
import {propKeyCaptureLocation} from "../../base/TypesFormBuilder";

export function getDefnFieldSignature(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyIconValue,
    propKeyHelperTextValue,
    fieldGap2,
    propKeyCaptureLocation,
    propKeyCaptureTime,
    propKeyCaptureUser,
    fieldGap3,
    propKeyShowCapturedValuesOnAside
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "signature",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),
    [propKeyCaptureLocation]: {
      type: "bool",
      name: propKeyCaptureLocation,
      metaId: propKeyCaptureLocation,
      showAsCheckboxVar: true
    } as DefnFieldSwitch,
    [propKeyCaptureTime]: {
      type: "bool",
      name: propKeyCaptureTime,
      metaId: propKeyCaptureTime,
      showAsCheckboxVar: true
    } as DefnFieldSwitch,
    [propKeyCaptureUser]: {
      type: "bool",
      name: propKeyCaptureUser,
      metaId: propKeyCaptureUser,
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowCapturedValuesOnAside]: {
      type: "enumCaptureValueKind",
      name: propKeyShowCapturedValuesOnAside,
      metaId: propKeyShowCapturedValuesOnAside,
      label: "Show captured values on aside"
    } as DefnFieldPickEnum
  };
}

export function defnValueToStudioFieldSignature(value: FieldValues): StudioFieldSignature
{
  return {
    type: "signature",
    captureLocation: fnFieldValueToRawValue("bool", value[propKeyCaptureLocation]),
    captureTime: fnFieldValueToRawValue("bool", value[propKeyCaptureTime]),
    captureUser: fnFieldValueToRawValue("bool", value[propKeyCaptureUser]),
    showCapturedValuesOnAside: fnFieldValueToRawValue("enumCaptureValueKind",
      value[propKeyShowCapturedValuesOnAside])
  } as StudioFieldSignature;
}

export function studioFieldSignatureToDefnValue(studioField: StudioFieldSignature): FieldValues
{
  return {
    [propKeyCaptureLocation]: fnRawValueToFieldValue("bool", studioField.captureLocation),
    [propKeyCaptureTime]: fnRawValueToFieldValue("bool", studioField.captureTime),
    [propKeyCaptureUser]: fnRawValueToFieldValue("bool", studioField.captureUser),
    [propKeyShowCapturedValuesOnAside]: fnRawValueToFieldValue("enumCaptureValueKind",
      studioField.showCapturedValuesOnAside)
  };
}
