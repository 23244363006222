import {useTheme} from "@mui/material";
import {Divider} from "@mui/material";
import React from "react";

export function DividerHorizontalLight()
{
  const theme = useTheme();

  return (
    <Divider
      orientation={"horizontal"}
      sx={{
        width: "100%",
        borderColor: theme.common.bgcolorHover,
        borderWidth: 1
      }}
    />
  );
}
