import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioPickActionId} from "../../../../api/meta/base/dto/DefnStudioPickActionId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickActionId from "../raw/FieldRawStudioPickActionId";

export default function FieldStudioSetOfActionId(props: {
  defn: DefnStudioPickActionId
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickActionId
        fieldProps={fieldProps}
        defn={props.defn}
        multiSelect={true}
        showChip={true}
      />}
    />
  );
}
