import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickGridId} from "../../../../../api/meta/base/dto/DefnStudioPickGridId";
import {DefnStudioPickLayoutGridId} from "../../../../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnStudioPickReportId} from "../../../../../api/meta/base/dto/DefnStudioPickReportId";
import {StudioFieldPickReportRow} from "../../../../../api/meta/base/dto/StudioFieldPickReportRow";
import {AllEditableType} from "../../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {IResolvedIOFormNames} from "../../../../../base/plus/StudioPlus";
import {getResolvedIOFormNamesHelperText} from "../../../../../base/plus/StudioPlus";
import {FORMS_FIELD_ONLY_LIST_CARD_LAYOUT} from "../../../../atom/assets/HelperTextStudio";
import {FORMS_PICK_REPORT_ROW_COPY_FIELDS} from "../../../../atom/assets/HelperTextStudio";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyShowAsDropdown} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyGridDisplayField} from "../../base/TypesFormBuilder";
import {propKeyRefReportOutputFormGridLayoutId} from "../../base/TypesFormBuilder";
import {propKeyRefReportOutputFormGridId} from "../../base/TypesFormBuilder";
import {fieldReport} from "../../base/TypesFormBuilder";
import {propKeyRefCopyFields} from "../../base/TypesFormBuilder";
import {propKeyEditableFieldIdSet} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {getFieldGap} from "../../base/TypesFormBuilder";

export function getDefnFieldPickReportRow(
  formId: string,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{

  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    fieldGap2,
    propKeyShowAsDropdown
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "pickReportRow",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyShowAsDropdown]: {
      type: "bool",
      name: propKeyShowAsDropdown,
      metaId: propKeyShowAsDropdown,
      label: "Show as dropdown",
      showAsCheckboxVar: true
    } as DefnFieldSwitch
  };
}

export function getDefnFieldPickReportRowSourceTab(
  formId?: MetaIdForm,
  gridId?: MetaIdGrid,
  helperTextReportFormName?: IResolvedIOFormNames,
  selectedRefFieldIdSet?: MetaIdField[],
  disableCopyFields?: boolean
)
{
  return {
    [fieldReport]: {
      type: "pickReportId",
      label: "Report",
      name: fieldReport,
      metaId: fieldReport,
      disabled: true,
      helperTextVar: stringToDefnDtoText(getResolvedIOFormNamesHelperText(
        helperTextReportFormName?.inputFormName,
        helperTextReportFormName?.outputFormName
      ))
    } as DefnStudioPickReportId,

    [propKeyRefReportOutputFormGridId]: {
      type: "pickGridId",
      name: propKeyRefReportOutputFormGridId,
      metaId: propKeyRefReportOutputFormGridId,
      label: "Grid",
      formId: formId,
      required: true
    } as DefnStudioPickGridId,

    [propKeyRefReportOutputFormGridLayoutId]: {
      type: "pickLayoutGridId",
      name: propKeyRefReportOutputFormGridLayoutId,
      metaId: propKeyRefReportOutputFormGridLayoutId,
      label: "Grid layout",
      formId: formId,
      gridId: gridId,
      disabled: !gridId,
      filterLayoutKindSet: ["card", "list"],
      required: true,
      helperTextVar: stringToDefnDtoText(FORMS_FIELD_ONLY_LIST_CARD_LAYOUT)
    } as DefnStudioPickLayoutGridId,

    [propKeyGridDisplayField]: {
      type: "pickFieldId",
      formId: formId,
      disabled: !formId || !gridId,
      name: propKeyGridDisplayField,
      metaId: propKeyGridDisplayField,
      compositeIdSet: gridId
        ? [gridId]
        : [],
      label: "Grid display field",
      required: true
    } as DefnStudioPickFieldId,

    ...getFieldGap(fieldGap1, "thick"),

    [propKeyRefCopyFields]: {
      type: "studioSetOfFieldId",
      name: propKeyRefCopyFields,
      metaId: propKeyRefCopyFields,
      label: "Copy fields",
      disabled: disableCopyFields,
      formId: formId,
      compositeIdSet: gridId
        ? [gridId]
        : [],
      filterFieldTypeSet: AllEditableType,
      helperTextVar: disableCopyFields && stringToDefnDtoText(FORMS_PICK_REPORT_ROW_COPY_FIELDS)
    } as DefnStudioPickFieldId,

    [propKeyEditableFieldIdSet]: {
      type: "studioSetOfFieldId",
      name: propKeyEditableFieldIdSet,
      metaId: propKeyEditableFieldIdSet,
      label: "Editable fields",
      formId: formId,
      includeFieldIdSet: selectedRefFieldIdSet && selectedRefFieldIdSet?.length > 1
        ? selectedRefFieldIdSet
        : []
    } as DefnStudioPickFieldId
  };
}

export function defnValueToStudioFieldPickReportRow(values: FieldValues): StudioFieldPickReportRow
{
  const refFieldChildren = fnFieldValueToRawValue("studioSetOfFieldRefId",
    values[propKeyRefCopyFields]
  ) as MetaIdField[];
  const copyFieldMap = {} as Record<string, string>;
  if(refFieldChildren && Array.isArray(refFieldChildren))
  {
    refFieldChildren.forEach((refFieldChildId, index) =>
    {
      const newCopyFieldId = "copyField" + index;
      copyFieldMap[newCopyFieldId] = refFieldChildId;
    });
  }

  return {
    type: "pickReportRow",
    reportId: fnFieldValueToRawValue("pickReportId", values[fieldReport]),
    reportOutputFormGridId: fnFieldValueToRawValue("pickGridId", values[propKeyRefReportOutputFormGridId]),
    reportOutputFormGridLayoutId: fnFieldValueToRawValue("pickLayoutGridId",
      values[propKeyRefReportOutputFormGridLayoutId]
    ),
    copyFieldMap: copyFieldMap,
    editableFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[propKeyEditableFieldIdSet]),
    gridDisplayFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyGridDisplayField]),
    showAsDropdown: fnFieldValueToRawValue("bool", values[propKeyShowAsDropdown])
  } as StudioFieldPickReportRow;
}

export function studioFieldPickReportRowToDefnValue(studioField: StudioFieldPickReportRow)
{
  return {
    [fieldReport]: fnRawValueToFieldValue("pickReportId", studioField.reportId),
    [propKeyRefReportOutputFormGridId]: fnRawValueToFieldValue("pickGridId", studioField.reportOutputFormGridId),
    [propKeyRefReportOutputFormGridLayoutId]: fnRawValueToFieldValue("pickLayoutGridId",
      studioField.reportOutputFormGridLayoutId
    ),
    [propKeyRefCopyFields]: Object.values(studioField.copyFieldMap ?? {}),
    [propKeyEditableFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", studioField.editableFieldIdSet),
    [propKeyGridDisplayField]: fnRawValueToFieldValue("pickFieldId", studioField.gridDisplayFieldId),
    [propKeyShowAsDropdown]: fnRawValueToFieldValue("bool", studioField.showAsDropdown)
  };
}


