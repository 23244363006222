import {useCallback} from "react";
import {useState} from "react";
import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {toSymbolCase} from "../../base/plus/StringPlus";
import {nextTreeNodeId} from "../../base/plus/SysPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {ITreeNode} from "../../base/types/TypesTree";
import {UserFieldTreeBuilder} from "../form/viewer/raw/FieldRawTreeBuilder";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldTreeNodeKey = "key";
const fieldTreeNodeName = "name";

export default function DialogTreeNode(props: {
  values?: ITreeNode,
  onClickOk: (values: ITreeNode) => void,
  onClose?: () => void,
})
{
  const dto = props.values;
  const cbRef = useRef({} as IFormRef);

  const [autoGenerate, setAutoGenerate] = useState<boolean>(dto === undefined);

  function valueToDto(values: FieldValues)
  {
    const name = fnFieldValueToRawValue("text", values[fieldTreeNodeName]);
    const metaId = fnFieldValueToRawValue("text", values[fieldTreeNodeKey]);
    return {
      ...dto,
      userField: {
        metaId: metaId,
        name: name
      } as UserFieldTreeBuilder,
      id: nextTreeNodeId(),
      name: name
    } as ITreeNode;
  }

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(key === fieldTreeNodeName && autoGenerate)
    {
      cbRef.current.setValue(fieldTreeNodeKey,
        fnRawValueToFieldValue("text", "key" + toSymbolCase(fnFieldValueToRawValue("text", value) as string))
      );
    }

    if(key === fieldTreeNodeKey)
    {
      const _value = fnFieldValueToRawValue("text", cbRef.current.getValue(fieldTreeNodeName)) as string;

      if(`key${toSymbolCase(_value)}` !== fnFieldValueToRawValue("text", value))
      {
        setAutoGenerate(false);
      }
    }

  }, [cbRef, autoGenerate]);

  return (
    <DialogDefnForm
      title={`${dto === undefined ? "Add" : "Edit"} tree node`}
      addMoreCheckBoxLabel={!dto
        ? "Add more tree node"
        : undefined}
      onClose={props.onClose}
      formProps={{
        defnForm: getDefnForm(dto !== undefined),
        cbRef: cbRef.current,
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: dto
          ? {...dtoToValue(dto)}
          : undefined,
        onWatch: onWatch
      }}
    />
  );
}

function dtoToValue(dto?: ITreeNode)
{
  const userField = dto?.userField as UserFieldTreeBuilder;
  return {
    [fieldTreeNodeKey]: fnRawValueToFieldValue("text", userField?.metaId),
    [fieldTreeNodeName]: fnRawValueToFieldValue("text", userField?.name)
  };
}

function getDefnForm(disabledMetaId?: boolean)
{
  return createDefaultDefnFormStudio({
    [fieldTreeNodeName]: {
      type: "text",
      metaId: fieldTreeNodeName,
      name: fieldTreeNodeName,
      label: "Name",
      required: true
    } as DefnFieldText,
    [fieldTreeNodeKey]: {
      type: "text",
      metaId: fieldTreeNodeKey,
      name: "Key",
      required: true,
      disabled: disabledMetaId
    } as DefnFieldText,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldTreeNodeName, fieldTreeNodeKey]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}


