import {StudioEntDeeplinkMap} from "../../../api/meta/base/dto/StudioEntDeeplinkMap";
import {StudioEntDeeplinkReport} from "../../../api/meta/base/dto/StudioEntDeeplinkReport";
import {StudioEntDeeplinkSpreadsheetEditor} from "../../../api/meta/base/dto/StudioEntDeeplinkSpreadsheetEditor";
import {StudioEntDeeplinkSpreadsheetInsert} from "../../../api/meta/base/dto/StudioEntDeeplinkSpreadsheetInsert";
import {StudioEntDeeplinkSpreadsheetRow} from "../../../api/meta/base/dto/StudioEntDeeplinkSpreadsheetRow";
import {StudioEntRoleMap} from "../../../api/meta/base/dto/StudioEntRoleMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {MetaIdReport} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntDeeplinks extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entDeeplinkList;
  }

  loadDeeplinkList(
    entId: EntId,
    listName: string,
    deeplinkMap: StudioEntDeeplinkMap,
    roleMap: StudioEntRoleMap,
    getSpreadSheetName: (spreadSheetId: MetaIdSpreadsheet) => string | undefined,
    getReportName: (reportId: MetaIdReport) => string | undefined,
    readOnly?: boolean
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    deeplinkMap.keys.forEach((deeplinkId) =>
    {
      const deeplink = deeplinkMap.map[deeplinkId];

      const deeplinkFilter: StudioModuleSelection | undefined = deeplink.modules;

      if(Srvc.studio.ent.filterListByTag(entId, deeplinkFilter))
      {
        uiItemIds.push(deeplinkId);
      }

      const deeplinkRoleList: ILineSecondary = {};

      if(deeplink.creationRoles && deeplink.creationRoles.length > 0)
      {

        deeplinkRoleList.text = deeplink.creationRoles.map(roleId =>
        {
          return roleMap.map[roleId]?.details?.name;
        }).join(", ");
      }
      else
      {
        deeplinkRoleList.text = "No roles";
        deeplinkRoleList.color = "textDisabled";
      }

      let captionText;

      switch(deeplink.kind)
      {
        case "report":
          captionText = getReportName((deeplink as StudioEntDeeplinkReport).reportId ?? "");
          break;
        case "spreadsheetRow":
          captionText = getSpreadSheetName((deeplink as StudioEntDeeplinkSpreadsheetRow).spreadsheetId ?? "");
          break;
        case "spreadsheetEditor":
          captionText = getSpreadSheetName((deeplink as StudioEntDeeplinkSpreadsheetEditor).spreadsheetId ?? "");
          break;
        case "spreadsheetInsert":
          captionText = getSpreadSheetName((deeplink as StudioEntDeeplinkSpreadsheetInsert).spreadsheetId ?? "");
          break;
      }

      deeplinkRoleList.caption = {
        type: "text",
        text: captionText ?? "",
        ignoreSelection: true
      };

      uiItemsById[deeplinkId] = {
        type: "ps",
        hideMenu: readOnly,
        primary: {
          text: deeplink.name,
          caption: {
            type: "text",
            text: toLabel(deeplink?.kind),
            ignoreSelection: true
          }
        },
        secondary: deeplinkRoleList
      } as IListItemAPSA;

    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }
}
