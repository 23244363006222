import {CheckRounded} from "@mui/icons-material";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useMemo} from "react";
import {useEffect} from "react";
import {forwardRef} from "react";
import {useRef} from "react";
import {useState} from "react";
import React from "react";
import {EnumDefnThemeColor} from "../../../api/meta/base/Types";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {ListLayoutType} from "../../../base/types/list/TypesList";
import {TypeTooltip} from "../../../base/types/TypesGlobal";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {TypePick} from "../../../base/types/TypesGlobal";
import {DividerHorizontalLight} from "../layout/DividerHorizontalLight";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawTooltip from "../raw/RawTooltip";
import {BoxInfoSpot} from "./BoxInfoSpot";
import {BoxMenuButton} from "./BoxMenuButton";
import {BoxPick} from "./BoxPick";

export function BoxShell(props: {
  pickType?: TypePick,
  pickValue?: boolean,
  showDivider?: boolean,
  selected?: boolean,
  ignoreSelection?: boolean,
  tooltip?: TypeTooltip;
  hideMenu?: boolean,
  height?: number,
  fullHeight?: boolean,
  pl?: number,
  pr?: number,
  infoSpot?: EnumDefnThemeColor
  bgcolor?: CssBackgroundColor,
  onClick?: (menuAnchor: Element, pickValue?: boolean) => void,
  onClickMenu?: CbMenuAnchor,
  onClickInfoSpot?: CbMenuAnchor,
  cursorPointer?: boolean,
  layoutType?: ListLayoutType
  children: React.ReactNode
})
{
  const theme = useTheme();
  const [bgcolor, setBgcolor] = useState(props.bgcolor);
  const [hover, setHover] = useState<boolean>();
  const menuAnchorRef = useRef<Element>();

  const {
    pickType,
    pickValue,
    showDivider,
    selected,
    infoSpot,
    height,
    pl,
    pr,
    children,
    layoutType
  } = props;

  const gapStd = theme.common.gapStd;
  const gapHalf = theme.common.gapHalf;

  const {
    onClick,
    onClickMenu,
    onClickInfoSpot
  } = props;

  const bgcolorHoverSelection = theme.common.bgcolorHoverSelection;
  const bgcolorSelection = theme.common.bgcolorSelection;
  const bgcolorHover = theme.common.bgcolorHover;

  useEffect(() =>
  {
    if(selected)
    {
      setBgcolor(hover
        ? bgcolorHoverSelection
        : bgcolorSelection);
    }
    else
    {
      setBgcolor(hover
        ? bgcolorHover
        : props.bgcolor);
    }
  }, [selected, hover, bgcolorSelection, bgcolorHoverSelection, bgcolorHover, props.bgcolor]);

  return (
    <LayoutFlexCol
      width={"100%"}
      height={props.fullHeight ? "100%" : height === undefined
        ? "auto"
        : px(height + (showDivider
          ? theme.common.sizeDivider
          : 0))}
    >
      <LayoutFlexRow
        bgcolor={bgcolor}
        width={"100%"}
        pl={px(pl ?? gapStd)}
        pr={px(pr ?? gapStd)}
        height={props.fullHeight ? "100%" : height === undefined ? "auto" : px(height)}
        onContextMenu={(!props.hideMenu && onClickMenu)
          ? (e) =>
          {
            e.preventDefault();
            e.stopPropagation();
            menuAnchorRef.current && onClickMenu(menuAnchorRef.current);
            return false;
          }
          : undefined}
        onClick={(e: React.MouseEvent<HTMLElement>) =>
        {
          onClick && onClick(e.currentTarget, pickType ? !pickValue : undefined);
        }}
        cursorPointer={props.cursorPointer}
        {
          ...!props.ignoreSelection && {
            onMouseEnter: () => setHover(true),
            onMouseLeave: () => setHover(false)
          }
        }
      >
        {
          pickType && (
            layoutType === "card"
              ? <BoxCornerPick
                pickType={pickType}
                pickValue={pickValue}
              />
              : <BoxPick
                pickType={pickType}
                pickValue={pickValue}
                mr={gapHalf}
              />
          )
        }

        {
          props.tooltip
            ? <RawTooltip
              title={props.tooltip}
              placement={"right"}
            >
              <Box flexGrow={1}>
                {children}
              </Box>
            </RawTooltip>
            : children
        }

        {
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={px(gapStd)}
            position={"absolute"}
            right={0}
            height={"100%"}
            justifyContent={infoSpot && onClickInfoSpot ? "space-between" : "flex-end"}
          >
            {infoSpot && onClickInfoSpot &&
              <BoxInfoSpot
                infoSpot={infoSpot}
                onClick={onClickInfoSpot}
              />
            }

            {onClickMenu && !props.hideMenu &&
              <BoxMenuButton
                ref={menuAnchorRef}
                onClick={onClickMenu}
              />
            }

          </Box>

        }
      </LayoutFlexRow>

      {
        showDivider &&
        <DividerHorizontalLight />
      }
    </LayoutFlexCol>

  );
}

const BoxCornerPick = forwardRef((props: {
  pickType: TypePick,
  pickValue?: boolean,
  onClick?: (value: boolean) => void,
  mr?: number,
  ml?: number,
}, ref) =>
{
  const {
    pickType,
    pickValue
  } = props;

  const theme = useTheme();
  const menuClr = useMemo(() => pickValue
    ? theme.palette.success.main
    : theme.common.bgcolorActionBar, [pickValue]);

  const grey500Clr = theme.common.colorWithShade("grey", "s500");
  const checkClr = useMemo(() => pickValue
    ? theme.common.bgcolorContent
    : grey500Clr, [pickValue, grey500Clr]);

  const gap = px(theme.common.gapStd * 1.8);

  if(pickType)
  {
    return (
      <Box
        ref={ref}
        sx={{
          zIndex: 1000,
          height: gap,
          position: "absolute",
          borderTop: (gap + " solid " + menuClr),
          borderRight: (gap + " solid transparent"),
          top: 0,
          left: 0
        }}
      >
        <CheckRounded
          sx={{
            color: checkClr,
            position: "absolute",
            left: 0,
            fontSize: px(theme.common.gapStd),
            bottom: theme.common.gapHalf + theme.common.gapQuarter
          }}
        />
      </Box>
    );
  }
  else
  {
    return null;
  }
});

