import {useState} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickPluginCompId} from "../../api/meta/base/dto/DefnStudioPickPluginCompId";
import {DefnStudioPickPluginFieldId} from "../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {DefnStudioPickSectionId} from "../../api/meta/base/dto/DefnStudioPickSectionId";
import {EnumDefnEmptyFieldVariant} from "../../api/meta/base/Types";
import {EnumDefnRemoveVariant} from "../../api/meta/base/Types";
import {EnumDefnUpdateVariant} from "../../api/meta/base/Types";
import {EnumDefnInsertVariant} from "../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {MetaIdPlugin} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {FormStore} from "../../base/types/TypesForm";
import {fieldGap2} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import {fieldGap1} from "../form/builder/base/TypesFormBuilder";
import {fieldGridMappingVariantEmpty} from "../varBldr/base/VariablePlus";
import {fieldMappingCopyGridKeysLabel} from "../varBldr/base/VariablePlus";
import {fieldGridMappingVariantRemove} from "../varBldr/base/VariablePlus";
import {fieldGridMappingVariantUpdate} from "../varBldr/base/VariablePlus";
import {fieldGridMappingVariantInsert} from "../varBldr/base/VariablePlus";
import {fieldVariableMappingToGridKey} from "../varBldr/base/VariablePlus";
import {fieldVariableMappingFromGridKey} from "../varBldr/base/VariablePlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const compFrom = "fromCompositeId";
const compTo = "toCompositeId";

export interface IGridMapping
{
  fromGridId?: MetaIdGrid,
  toGridId?: MetaIdGrid,
  fromGridKey?: MetaIdField;
  toGridKey?: MetaIdField;
  insertVariant?: EnumDefnInsertVariant;
  updateVariant?: EnumDefnUpdateVariant;
  removeVariant?: EnumDefnRemoveVariant;
  emptyFieldVariant?: EnumDefnEmptyFieldVariant;
}

export default function DialogGridMapping(props: {
  formStore: FormStore,
  fromFormId?: MetaIdForm,
  toFormId: MetaIdForm,
  values?: IGridMapping,
  fromPluginId?: MetaIdPlugin;
  toPluginId?: MetaIdPlugin;
  readOnly?: boolean,
  onClickOk: (values: IGridMapping) => void,
  onClose?: () => void,
})
{
  const fromPluginId = props.fromPluginId;
  const toPluginId = props.toPluginId;
  const fromFormId = props.fromFormId;
  const toFormId = props.toFormId;
  const values = props.values;
  const readOnly = props.readOnly;

  const [fromGridId, setFromGridId] = useState<MetaIdGrid | undefined>(values?.fromGridId);
  const [toGridId, setToGridId] = useState<MetaIdGrid | undefined>(values?.toGridId);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} grid mapping`}
      formProps={{
        store: props.formStore,
        defnForm: getDefnForm(
          fromFormId,
          toFormId,
          fromPluginId,
          toPluginId,
          fromGridId,
          toGridId
        ),
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: dtoToValue(values),
        onWatch: (key, value) =>
        {
          if(key === compFrom)
          {
            setFromGridId(value);
          }
          else if(key === compTo)
          {
            setToGridId(value);
          }
        },
        formReadonly: readOnly
      }}
      addMoreCheckBoxLabel={!values
        ? "Add more grid mappings"
        : undefined}
      onClose={props.onClose}
      contentHeight={700}
    />
  );
}

function valueToDto(
  values: FieldValues,
  fromPluginId?: MetaIdPlugin,
  toPluginId?: MetaIdPlugin): IGridMapping
{
  return {
    fromGridId: fnFieldValueToRawValue("pickGridId", values[compFrom]),
    toGridId: fnFieldValueToRawValue("pickGridId", values[compTo]),
    fromGridKey: fromPluginId
      ? fnFieldValueToRawValue("pickPluginFieldId", values[fieldVariableMappingFromGridKey]) ?? "$RowId"
      : fnFieldValueToRawValue("pickFieldId", values[fieldVariableMappingFromGridKey]) ?? "$RowId",
    toGridKey: toPluginId
      ? fnFieldValueToRawValue("pickPluginFieldId", values[fieldVariableMappingToGridKey])
      : fnFieldValueToRawValue("pickFieldId", values[fieldVariableMappingToGridKey]),
    insertVariant: fnFieldValueToRawValue("enumInsertVariant", values[fieldGridMappingVariantInsert]) ?? "insertForced",
    updateVariant: fnFieldValueToRawValue("enumUpdateVariant", values[fieldGridMappingVariantUpdate]) ?? "updateForced",
    removeVariant: fnFieldValueToRawValue("enumRemoveVariant", values[fieldGridMappingVariantRemove]) ?? "removeForced",
    emptyFieldVariant: fnFieldValueToRawValue("enumEmptyFieldVariant",
      values[fieldGridMappingVariantEmpty] ?? "overrideEmptyField"
    )
  } as IGridMapping;
}

function dtoToValue(
  dto?: IGridMapping,
  fromPluginId?: MetaIdPlugin,
  toPluginId?: MetaIdPlugin)
{
  return {
    [compFrom]: fnRawValueToFieldValue("pickGridId", dto?.fromGridId),
    [compTo]: fnRawValueToFieldValue("pickGridId", dto?.toGridId),
    [fieldVariableMappingFromGridKey]: fromPluginId
      ? fnRawValueToFieldValue("pickPluginFieldId", dto?.fromGridKey ?? "$RowId")
      : fnRawValueToFieldValue("pickFieldId", dto?.fromGridKey ?? "$RowId"),
    [fieldVariableMappingToGridKey]: toPluginId
      ? fnRawValueToFieldValue("pickPluginFieldId", dto?.toGridKey)
      : fnRawValueToFieldValue("pickFieldId", dto?.toGridKey),
    [fieldGridMappingVariantInsert]: fnRawValueToFieldValue("enumInsertVariant", dto?.insertVariant ?? "insertForced"),
    [fieldGridMappingVariantUpdate]: fnRawValueToFieldValue("enumUpdateVariant", dto?.updateVariant ?? "updateForced"),
    [fieldGridMappingVariantRemove]: fnRawValueToFieldValue("enumRemoveVariant", dto?.removeVariant ?? "removeForced"),
    [fieldGridMappingVariantEmpty]: fnRawValueToFieldValue("enumEmptyFieldVariant",
      dto?.emptyFieldVariant ?? "overrideEmptyField"
    )
  } as FieldValues;
}

function getDefnForm(
  fromFormId?: MetaIdForm,
  toFormId?: MetaIdForm,
  fromPluginId?: MetaIdPlugin,
  toPluginId?: MetaIdPlugin,
  fromGridId?: MetaIdGrid,
  toGridId?: MetaIdGrid)
{
  const includeOptionMap = arrayToMapOfOption(EnumArrayDefnFields, true);

  return createDefaultDefnFormStudio({

    ...fromPluginId
      ? {
        [compFrom]: {
          type: "pickPluginCompId",
          metaId: compFrom,
          label: "From grid",
          required: true,
          formId: fromFormId,
          pluginId: fromPluginId,
          filterCompTypeSet: ["grid"]
        } as DefnStudioPickPluginCompId
      }
      :
      {
        [compFrom]: {
          type: "pickGridId",
          metaId: compFrom,
          label: "From grid",
          required: true,
          formId: fromFormId
        } as DefnStudioPickSectionId
      },

    ...toPluginId
      ? {
        [compTo]: {
          type: "pickPluginCompId",
          metaId: compTo,
          label: "To grid",
          required: true,
          formId: toFormId,
          pluginId: toPluginId,
          filterCompTypeSet: ["grid"]
        } as DefnStudioPickPluginCompId
      }
      : {
        [compTo]: {
          type: "pickGridId",
          metaId: compTo,
          label: "To grid",
          required: true,
          formId: toFormId
        } as DefnStudioPickSectionId
      },

    ...getFieldGap(fieldGap1, "thick"),

    [fieldMappingCopyGridKeysLabel]: {
      type: "label",
      metaId: fieldMappingCopyGridKeysLabel,
      name: fieldMappingCopyGridKeysLabel,
      label: "Copy grid keys",
      disabled: true
    } as DefnFieldLabel,

    ...fromPluginId
      ? {
        [fieldVariableMappingFromGridKey]: {
          type: "pickPluginFieldId",
          metaId: fieldVariableMappingFromGridKey,
          name: fieldVariableMappingFromGridKey,
          label: "From grid key",
          formId: fromFormId,
          pluginId: fromPluginId,
          compositeIdSet: fromGridId ? [fromGridId] : [],
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldVariableMappingFromGridKey]: {
          type: "pickFieldId",
          metaId: fieldVariableMappingFromGridKey,
          name: fieldVariableMappingFromGridKey,
          label: "From grid key",
          formId: fromFormId,
          compositeIdSet: fromGridId ? [fromGridId] : [],
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickFieldId
      },

    ...toPluginId
      ? {
        [fieldVariableMappingToGridKey]: {
          type: "pickPluginFieldId",
          metaId: fieldVariableMappingToGridKey,
          name: fieldVariableMappingToGridKey,
          label: "To grid key",
          formId: toFormId,
          pluginId: toPluginId,
          compositeIdSet: toGridId ? [toGridId] : [],
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldVariableMappingToGridKey]: {
          type: "pickFieldId",
          metaId: fieldVariableMappingToGridKey,
          name: fieldVariableMappingToGridKey,
          label: "To grid key",
          formId: toFormId,
          compositeIdSet: toGridId ? [toGridId] : [],
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickFieldId
      },

    ...getFieldGap(fieldGap2, "thick"),

    [fieldGridMappingVariantInsert]: {
      type: "enumInsertVariant",
      metaId: fieldGridMappingVariantInsert,
      name: fieldGridMappingVariantInsert,
      label: "Insert variant",
      required: true
    } as DefnFieldPickEnum,

    [fieldGridMappingVariantUpdate]: {
      type: "enumUpdateVariant",
      metaId: fieldGridMappingVariantUpdate,
      name: fieldGridMappingVariantUpdate,
      label: "Update variant",
      required: true
    } as DefnFieldPickEnum,

    [fieldGridMappingVariantRemove]: {
      type: "enumRemoveVariant",
      metaId: fieldGridMappingVariantRemove,
      name: fieldGridMappingVariantRemove,
      label: "Remove variant",
      required: true
    } as DefnFieldPickEnum,

    [fieldGridMappingVariantEmpty]: {
      type: "enumEmptyFieldVariant",
      metaId: fieldGridMappingVariantEmpty,
      name: fieldGridMappingVariantEmpty,
      label: "Empty field variant",
      required: true
    } as DefnFieldPickEnum,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [
        compFrom,
        compTo,
        fieldGap1,
        fieldMappingCopyGridKeysLabel,
        fieldVariableMappingFromGridKey,
        fieldVariableMappingToGridKey,
        fieldGap2,
        fieldGridMappingVariantInsert,
        fieldGridMappingVariantUpdate,
        fieldGridMappingVariantRemove,
        fieldGridMappingVariantEmpty
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}
