import {combineReducers} from "@reduxjs/toolkit";
import {reducersApp} from "./app/StoreHomeApp";
import {reducersAside} from "./aside/StoreHomeAside";
import {reducersDrawer} from "./drawer/StoreHomeDrawer";
import {reducersMain} from "./main/StoreHomeMain";

export function reducersHome()
{
  return combineReducers({
    app: reducersApp(),
    drawer: reducersDrawer(),
    main: reducersMain(),
    aside: reducersAside()
  });
}
