import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDecimal} from "../../../api/meta/base/dto/DefnFieldDecimal";
import {FieldValueDecimal} from "../../../api/meta/base/dto/FieldValueDecimal";
import {StudioVarDecimal} from "../../../api/meta/base/dto/StudioVarDecimal";

export function fnVariableBuilderDecimal(fieldValueDecimal: string)
{
  function getVarComp()
  {
    return {
      [fieldValueDecimal]: {
        type: "decimal",
        metaId: fieldValueDecimal,
        label: "Value",
        required: true
      } as DefnFieldDecimal
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarDecimal
  )
  {
    valueMap[fieldValueDecimal] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueDecimal] as FieldValueDecimal;
    if(value === undefined || value === null)
    {
      return;
    }

    return {
      value: value
    } as StudioVarDecimal;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
