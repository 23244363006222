import {ClearRounded} from "@mui/icons-material";
import {CircularProgress} from "@mui/material";
import {useTheme} from "@mui/material";
import {ListItem} from "@mui/material";
import {Chip} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawQuickLink from "./RawQuickLink";

export type TypeRawTag = Record<string, string>;

export default function RawTags(props: {
  tags: TypeRawTag,
  onClearAll?: () => void,
  onRemove?: (tagId: string) => void,
  showProgress?: boolean
})
{
  const theme = useTheme();
  const tags = props.tags;
  const onClearAll = props.onClearAll;
  const onRemove = props.onRemove;
  const gapQuarter = theme.common.gapQuarter;
  const gapHalf = theme.common.gapHalf;

  return (
    <LayoutFlexRow
      width={"100%"}
      justifyContent={"space-between"}
      alignItems={"center"}
      pt={px(gapQuarter)}
      pb={px(gapQuarter)}
      minHeight={px(40)}
      pl={px(theme.common.gapStd)}
      pr={px(theme.common.gapStd)}
    >
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-start"}
        flexWrap={"wrap"}
      >
        {
          Object.entries(tags).map(([tagId, tagLabel]) =>
            <ListItem
              sx={{
                p: px(gapQuarter),
                pl: 0,
                width: "auto"
              }}
              key={tagId}
            >
              <Chip
                label={tagLabel}
                size={"small"}
                deleteIcon={<ClearRounded />}
                onDelete={onRemove ? () => onRemove(tagId) : undefined}
                sx={{
                  borderRadius: px(gapQuarter)
                }}
              />
            </ListItem>
          )
        }
      </Box>

      {
        props.showProgress &&
        <CircularProgress
          size={theme.common.heightLine}
          sx={{
            mr: px(gapHalf + gapQuarter)
          }}
        />
      }

      <LayoutFlexRow>
        <RawQuickLink
          name={"Clear all"}
          onClick={onClearAll}
          color={"primary"}
          bold
        />
      </LayoutFlexRow>
    </LayoutFlexRow>
  );
}
