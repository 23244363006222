import {StudioEntRoleMap} from "../../../api/meta/base/dto/StudioEntRoleMap";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {selectCacheStudioEntState} from "../../../base/plus/StudioPlus";
import {TypeStudioFilterType} from "../../../base/plus/StudioPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntRoles extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entRoleList;
  }

  loadRoleList(
    entId: EntId,
    listName: string,
    readOnly: boolean,
    roleMap?: StudioEntRoleMap,
    usageFilter?: TypeStudioFilterType)
  {
    if(!roleMap)
    {
      return;
    }

    const {
      uiItemIds,
      uiItemsById
    } = this.getRolesUiItemById(roleMap, readOnly);

    const rootState = store.getState();

    const filterUiItemIds = uiItemIds.filter(roleId =>
    {
      const role = roleMap.map[roleId];
      if(role)
      {
        const modules = role.details.modules;

        if(!Srvc.studio.ent.filterListByTag(entId, modules))
        {
          return false;
        }

        //role usages filter
        const roleUsageCount = selectCacheStudioEntState(rootState, entId)?.usageMap?.roleUsageMap[roleId];
        if(!roleUsageCount)
        {
          const primary = (uiItemsById[roleId] as IListItemAPSA).primary;
          if(primary)
          {
            primary.color = "textDisabled";
          }
        }

        if(usageFilter)
        {
          switch(usageFilter)
          {
            case "Unused":
              return (!roleUsageCount);
            case "All":
              return true;
          }
        }
      }
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: filterUiItemIds
    }));
  }

  getRolesUiItemById(
    roleMap: StudioEntRoleMap,
    readOnly?: boolean
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    roleMap.keys.forEach((itemId) =>
    {
      const role = roleMap.map[itemId];
      const roleDetails = role.details;

      uiItemIds.push(itemId);

      const description: ILineSecondary = {};

      if(roleDetails.description && roleDetails.description.length > 0)
      {
        description.text = roleDetails.description;
      }
      else
      {
        description.text = "No description";
        description.color = "textDisabled";
      }

      uiItemsById[itemId] = {
        type: "ps",
        primary: {
          text: roleDetails.name
        },
        hideMenu: readOnly,
        secondary: description
      } as IListItemAPSA;

    });

    return {
      uiItemIds,
      uiItemsById
    };

  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    Srvc.cache.studio.ent.roles.setUsageFilter(entId, filterType);
  }
}
