import {Box, useTheme} from "@mui/material";
import {isEmpty} from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {StudioDtoFormula} from "../../../../api/meta/base/dto/StudioDtoFormula";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {StudioVarMap} from "../../../../api/meta/base/dto/StudioVarMap";
import {MetaIdFormula} from "../../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getStudioFormParentMap} from "../../../../base/plus/StudioFormPlus";
import {gapHalf, gapStd} from "../../../../base/plus/ThemePlus";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItem, IListItemsById, TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IAsidePropsStudioFormFormula} from "../../../../base/types/TypesAside";
import {IMainPropsStudioFormBuilderFormFormula} from "../../../../base/types/TypesMain";
import {IMainPropsStudio} from "../../../../base/types/TypesMain";
import {TypeListSortType} from "../../../../base/types/TypesStudio";
import {IStudioJumpPath} from "../../../../base/types/TypesStudio";
import {useStepListDefaultConfig} from "../../../../routes/studio/app/UiStudioPlus";
import {useAppSelector} from "../../../app/AppHooks";
import helperTextJsonData from "../../../atom/assets/PlaceholderTextStudio.json";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import RawSearch from "../../../atom/raw/RawSearch";
import {useAppCtx} from "../../../ctx/CtxApp";
import {usePageCtx} from "../../../ctx/CtxPage";
import {List, ListClickVariant} from "../../../list/List";
import {useValidationErrorList} from "../../../list/validation/ListValidation";
import {sortFormItemList} from "../base/FormBuilderPlus";
import useCopyPasteStudioFormItem from "../base/StudioFormCopyPaste";
import {TypeActionSortFormList} from "../base/TypesFormBuilder";
import {IFormBuilderProps} from "../base/TypesFormBuilder";

export default function FormBuilderFormFormula(props: IFormBuilderProps)
{
  const theme = useTheme();
  const appCtx = useAppCtx();
  const pageCtx = usePageCtx();
  const [selectedMetaIdFormula, setSelectedMetaIdFormula] = useState<MetaIdFormula>();
  const {
    copyStudioFormFormulas
  } = useCopyPasteStudioFormItem();

  const formStore = props.formStore;
  const setupMode = props.setupMode;
  const readOnly = props.readOnly;
  const selectListMap = props.selectListMap;
  const cbOnSubmit = props.cbOnSubmit;
  const cbOnRemove = props.cbOnRemove;
  const cbOnShowVar = props.cbOnShowVar;
  const cbOnEditVar = props.cbOnEditVar;
  const cbGetCliCode = props.cbGetCliCode;

  const formMap = formStore.formMap;
  const varMap = formStore.varMap;
  const validationResult = formStore.validationResult;

  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilderFormFormula;
  const selectList = mainProps.selectList;
  const entId = mainProps.itemId;
  const formId = mainProps.formId;

  const listName = useAppSelector(state => selectList(state).listName);

  const form = useMemo(() => (formMap?.map && formId)
    ? formMap.map[formId]
    : undefined, [formMap?.map, formId]);

  const onClickItemInfo = useValidationErrorList(selectList, "formulaMap", undefined, validationResult);

  const cbOnSubmitFormFormula = useCallback((studioFormFormula: StudioDtoFormula, isDuplicate?: boolean) =>
  {
    cbOnSubmit && cbOnSubmit(
      "formula",
      {
        formId,
        formula: studioFormFormula,
        isDuplicate: Boolean(isDuplicate)
      }
    );
  }, [cbOnSubmit, formId]);

  const cbOnRemoveFormFormula = useCallback((formFormulaId: MetaIdFormula) =>
  {
    cbOnRemove && cbOnRemove(
      "formula",
      {
        formulaId: formFormulaId
      }
    );
  }, [cbOnRemove]);

  const cbOnSubmitStudioVar = useCallback((studioVar: StudioVar) =>
  {
    props.cbOnSubmit && props.cbOnSubmit(
      "studioVar",
      {
        formId,
        studioVar: studioVar,
        isDuplicate: false
      }
    );
  }, [formId]);

  const onClickListItem = useCallback((metaIdFormFormula: TypeListItemId, jumpPath?: IStudioJumpPath[]) =>
  {
    appCtx.setAsideProps({
      type: "FormFormulas",
      formId: formId,
      formStore: formStore,
      setupMode: setupMode,
      readOnly: readOnly,
      selectListMap: selectListMap,
      cbOnFormFormulaSubmit: cbOnSubmitFormFormula,
      cbOnFormFormulaRemove: cbOnRemoveFormFormula,
      cbOnAddNewVar: cbOnSubmitStudioVar,
      cbOnShowVar: cbOnShowVar,
      cbOnEditVar: cbOnEditVar,
      metaId: metaIdFormFormula,
      jumpPath: jumpPath,
      reset: true
    } as IAsidePropsStudioFormFormula);

    setSelectedMetaIdFormula(metaIdFormFormula);
  }, [
    appCtx,
    formId,
    formStore,
    setupMode,
    readOnly,
    selectListMap,
    cbOnSubmitFormFormula,
    cbOnRemoveFormFormula,
    cbOnShowVar,
    cbOnEditVar,
    cbOnSubmitStudioVar
  ]);

  const onClickMenu = useCallback((menuAnchor: Element, itemId: TypeListItemId, item?: IListItem) =>
    {
      pageCtx.showMenu(menuAnchor,
        {
          "Copy": () =>
          {
            copyStudioFormFormulas(item?.userField?.value as StudioDtoFormula);
          },
          "Get CLI code": () => cbGetCliCode && cbGetCliCode(entId, itemId),
          "Duplicate": () =>
          {
            cbOnSubmitFormFormula(item?.userField?.value as StudioDtoFormula, true);
          },
          "Remove": () =>
          {
            cbOnRemoveFormFormula(itemId);
            if(selectedMetaIdFormula === itemId)
            {
              appCtx.setAsideProps(undefined);
            }
          },
          "gap": undefined,
          "Move up": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("formula", {
              formulaId: itemId,
              direction: "up"
            }),
            disabled: !item?.userField?.showMoveUp
          },
          "Move down": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("formula", {
              formulaId: itemId,
              direction: "down"
            }),
            disabled: !item?.userField?.showMoveDown
          },
          "Move top": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("formula", {
              formulaId: itemId,
              direction: "top"
            }),
            disabled: !item?.userField?.showMoveUp
          },
          "Move bottom": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("formula", {
              formulaId: itemId,
              direction: "bottom"
            }),
            disabled: !item?.userField?.showMoveDown
          }
        }
      );
    },
    [
      cbGetCliCode,
      cbOnRemoveFormFormula,
      cbOnSubmitFormFormula,
      copyStudioFormFormulas,
      entId,
      selectedMetaIdFormula
    ]);

  const cbOnClick = useCallback((
    menuAnchor: Element,
    itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant,
    pickValue?: boolean,
    selected?: boolean
  ) =>
  {
    switch(variant)
    {
      case "listItem":
        selected && onClickListItem(itemId);
        break;
      case "spotMenu":
        !readOnly && onClickMenu(menuAnchor, itemId, item);
        break;
      case "spotInfo":
        onClickItemInfo(menuAnchor, itemId);
        break;
    }
  }, [onClickItemInfo, onClickListItem, onClickMenu, readOnly]);

  const cbOnClickSort = useCallback((type: TypeListSortType) =>
  {
    const actionSort = {
      sortType: type,
      setKey: "formulaList"
    } as TypeActionSortFormList;
    const studioForm = sortFormItemList(actionSort, form);
    if(studioForm)
    {
      cbOnSubmit && cbOnSubmit("form", {
        formId,
        form: studioForm
      });
    }
  }, [form, formId]);

  useEffect(() =>
  {
    if(form)
    {
      loadFormulaList(listName, form, varMap);
    }
  }, [form?.formulaMap, listName, varMap]);

  useStepListDefaultConfig(selectList, "formulaMap", onClickListItem, false, true);

  return (
    <>
      <LayoutFlexRow
        width={"100%"}
        pl={px(gapStd)}
        pt={px(gapHalf)}
        justifyContent={"flex-start"}
      >
        <RawIconStrip
          iconStrip={["backIos"]}
          onClick={() =>
          {
            appCtx.setMainProps({
              ...mainProps,
              jumpStepItem: "FormDetails"
            } as IMainPropsStudio);
            appCtx.setAsideProps(undefined);
          }}
        />
        <RawHighlighter
          value={"Form formulas"}
          variant={"body1"}
        />
      </LayoutFlexRow>

      <RawSearch
        placeHolder={"Search form formula"}
        onSearchBindListName={listName}
        showSorting={!readOnly}
        onClickSorting={cbOnClickSort}
      />

      <LayoutFlexCol
        flexGrow={1}
        width={"100%"}
        pl={px(gapStd)}
        pr={px(gapStd)}
      >
        <Box
          width={"100%"}
          height={"100%"}
          bgcolor={theme.common.bgcolorContent}
          border={`1px solid ${theme.common.borderColor}`}
        >
          <List
            selectList={selectList}
            emptyComp={
              <RawNothingHere
                helperTextData={helperTextJsonData.formFormulas}
              />}
            onClickListItem={cbOnClick}
            emptySearch={STR_NOTHING_HERE}
          />
        </Box>
      </LayoutFlexCol>
    </>
  );
}

function loadFormulaList(
  listName: string,
  form: StudioForm,
  varMap?: StudioVarMap
)
{
  const uiItemIds = [] as TypeListItemId[];
  const uiItemsById = {} as IListItemsById;

  const formulaMap = form?.formulaMap;
  const parentMap = getStudioFormParentMap(form);

  const formulaKeys = formulaMap?.keys;
  if(formulaMap && formulaKeys)
  {
    formulaKeys.forEach((itemId, index) =>
    {
      const formula = formulaMap.map[itemId];
      const assignFormulaFieldId = formula.assignToFieldId;

      const showMoveUp = formulaKeys.length !== 1 && index !== 0;
      const showMoveDown = formulaKeys.length !== 1 && index !== formulaKeys.length - 1;

      const parentComp = parentMap[assignFormulaFieldId];
      const field = parentComp?.fieldMap.map[assignFormulaFieldId];

      const conditionVarId = formula.conditionVarId;
      const conditionVariable = conditionVarId
        ? varMap?.map[conditionVarId]
        : undefined;

      uiItemIds.push(itemId);

      const secondaryValue = formula.formula?.value;
      const fieldName = field?.details.name;

      uiItemsById[itemId] = {
        type: "ps",
        primary: {
          text: fieldName,
          caption: {
            text: conditionVariable
              ? conditionVariable.details.name
              : undefined,
            ignoreSelection: true
          }
        },
        secondary: {
          text: !isEmpty(secondaryValue)
            ? secondaryValue
            : "No formula"
        },
        userField: {
          value: formula,
          showMoveUp: showMoveUp,
          showMoveDown: showMoveDown
        }
      } as IListItemAPSA;
    });
  }

  dispatchList(listName, listRefresh({
    itemIds: uiItemIds,
    itemsById: uiItemsById
  }));
}
