import {ChartDataset} from "chart.js";
import {ChartOptions} from "chart.js";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {useMemo} from "react";
import {Bar} from "react-chartjs-2";
import {IChart} from "../../../base/types/TypeCharts";
import useXYChart from "./ChartPlus";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ChartBar(props: IChart)
{
  const {
    getOptions,
    labels,
    getDataLineAndBar
  } = useXYChart(props);

  const dataSet = useMemo(() => getDataLineAndBar(), [getDataLineAndBar]);
  const layout = props.layout;

  return (
    <Bar
      options={getOptions(props.allowAnimation, layout) as ChartOptions<"bar">}
      data={{
        labels: labels,
        datasets: dataSet as ChartDataset<"bar">[]
      }}
    />
  );
}

