import {ArrowBackRounded} from "@mui/icons-material";
import {ClearRounded} from "@mui/icons-material";
import {SearchRounded} from "@mui/icons-material";
import {CircularProgress} from "@mui/material";
import {InputBase} from "@mui/material";
import {IconButton} from "@mui/material";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {debounce} from "lodash";
import {useCallback} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import {useState} from "react";
import React from "react";
import {dispatchList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import theme from "../../../base/plus/ThemePlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {listSetSearch} from "../../../base/slices/list/SliceListSharedActions";
import {EnumIconStrip} from "../../../base/types/TypesIcon";
import {TypeListSortType} from "../../../base/types/TypesStudio";
import IconStrip from "../icon/IconStrip";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawTooltip from "./RawTooltip";

const EMPTY_STR = "";

export const SEARCH_BOX_HEIGHT = theme.common.heightFooter + gapHalf;

export interface IRawSearchRef
{
  setShowProgress(value: boolean): void;

  clearSearch(): void;
}

export default function RawSearch(props: {
  cbRef?: IRawSearchRef,
  placeHolder?: string,
  value?: string,
  bgColor?: CssBackgroundColor,
  onSearchBindListName?: string,
  showBack?: boolean,
  showSorting?: boolean,
  showFilter?: boolean,
  filterToolTipTitle?: string
  filterIconColor?: string
  debouncedMs?: number,
  onSearch?: (value: string) => void,
  onSearchState?: (search: boolean) => void,
  onClickBack?: () => void,
  onClickSorting?: (type: TypeListSortType) => void,
  onClickFilter?: (menuAnchor: Element) => void,
})
{
  const theme = useTheme();
  const [value, setValue] = useState(props.value?.trim() ?? EMPTY_STR);
  const [backBtn, setBackBtn] = useState(value.length > 0);
  const [clearBtn, setClearBtn] = useState(value.length > 0);
  const [showProgress, setShowProgress] = useState(false);
  const [sortType, setSortType] = useState<TypeListSortType>("sortAsc");

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  const gapHalf = theme.common.gapHalf;
  const gapStd = theme.common.gapStd;
  const gapQuarter = theme.common.gapQuarter;
  const inboxHeight = theme.common.heightInbox;
  const onSearchState = props.onSearchState;
  const debouncedMs = props.debouncedMs;
  const onSearch = props.onSearch;
  const onSearchBindListName = props.onSearchBindListName;
  const showSorting = props.showSorting;
  const showFilter = props.showFilter;
  const onClickSorting = props.onClickSorting;
  const onClickFilter = props.onClickFilter;
  const filterToolTipTitle = props.filterToolTipTitle;

  const filterIconColor = props.filterIconColor;

  const searchBarWithIcon = showSorting || showFilter;

  const debouncedSearch = useRef(
    debounce((value: string) =>
    {
      if(onSearch)
      {
        onSearch(value);
      }

      if(onSearchBindListName)
      {
        dispatchList(onSearchBindListName, listSetSearch(value));
      }
    }, debouncedMs || theme.common.durationTransition)
  ).current;

  const cbSetBackBtn = useCallback((showBackBtn: boolean) =>
  {
    if(showBackBtn)
    {
      if(!backBtn)
      {
        onSearchState && onSearchState(true);
      }
    }
    else
    {
      onSearchState && onSearchState(false);
    }

    setBackBtn(showBackBtn);
  }, [backBtn, onSearchState]);

  const cbOnSortClick = useCallback(() =>
  {
    if(sortType === "sortDesc")
    {
      setSortType("sortAsc");
    }
    else
    {
      setSortType("sortDesc");
    }

    onClickSorting && onClickSorting(sortType);
  }, [sortType]);

  const refShowProgress = useCallback((value: boolean) =>
  {
    setShowProgress(value);
  }, []);

  const refClearSearch = useCallback(() =>
  {
    cbSetBackBtn(false);
    setClearBtn(false);
    setValue(EMPTY_STR);

    inputRef.current?.blur();
  }, []);

  useEffect(() =>
  {
    return () =>
    {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() =>
  {
    if(props.showBack)
    {
      inputRef.current?.focus();
      setBackBtn(true);
      onSearchState && onSearchState(true);
    }
    else
    {
      setBackBtn(false);
      onSearchState && onSearchState(false);
    }
  }, [props.showBack]);

  const cbRef = props.cbRef;
  if(cbRef)
  {
    cbRef.setShowProgress = refShowProgress;
    cbRef.clearSearch = refClearSearch;
  }

  return (
    <LayoutFlexRow
      width="100%"
      height={px(SEARCH_BOX_HEIGHT)}
      bgcolor={props.bgColor ?? theme.common.bgcolorSidePane}
      pl={px(gapStd)}
      pr={px(gapStd)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          flexShrink: searchBarWithIcon ? 1 : 0,
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: px(inboxHeight),
          bgcolor: theme.common.bgcolorContent,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.common.borderColor,
          borderRadius: px(inboxHeight / 2)
        }}
      >
        <IconButton
          size="small"
          onClick={() =>
          {
            if(backBtn)
            {
              setValue(EMPTY_STR);
              setClearBtn(false);
              debouncedSearch(EMPTY_STR);

              props.onClickBack && props.onClickBack();
            }
            else
            {
              inputRef.current?.focus();
            }

            cbSetBackBtn(!backBtn);
          }}
          sx={{
            mr: px(gapQuarter),
            ml: px(gapQuarter)
          }}
        >
          {
            backBtn
              ? <ArrowBackRounded />
              : <SearchRounded />
          }
        </IconButton>

        <InputBase
          placeholder={props.placeHolder}
          size="small"
          inputRef={inputRef}
          inputProps={{
            style: {
              padding: 0
            }
          }}
          sx={{
            flexGrow: 1
          }}
          value={value}
          onKeyDown={event =>
          {
            if(event.key === "Escape")
            {
              event.preventDefault();
              event.stopPropagation();

              refClearSearch();
            }
          }}
          onChange={event =>
          {
            const value = event.target.value;
            if(value.length > 0)
            {
              cbSetBackBtn(true);
              setClearBtn(true);
            }
            else
            {
              setClearBtn(false);
            }

            setValue(value);
            debouncedSearch(value.trim());
          }}
        />

        {clearBtn && !showProgress &&
          <IconButton
            size="small"
            sx={{
              mr: px(gapQuarter)
            }}
            onClick={() =>
            {
              setValue(EMPTY_STR);
              setClearBtn(false);
              debouncedSearch(EMPTY_STR);
              inputRef.current?.focus();
            }}
          >
            <ClearRounded />
          </IconButton>
        }

        {
          showProgress &&
          <CircularProgress
            size={theme.common.heightLine}
            sx={{
              mr: px(gapHalf + gapQuarter)
            }}
          />
        }
      </Box>
      {
        showFilter &&
        <RawTooltip
          title={filterToolTipTitle}
          key={"filterIcon"}
        >
          <IconButton
            size={"small"}
            onClick={(event) => onClickFilter && onClickFilter(event.currentTarget)}
            sx={{
              ml: px(gapQuarter)
            }}
          >
            <IconStrip
              value={"filter"}
              color={filterIconColor}
            />
          </IconButton>
        </RawTooltip>
      }
      {
        showSorting &&
        <IconButton
          size={"small"}
          onClick={cbOnSortClick}
          sx={{
            ml: px(gapQuarter)
          }}
        >
          <IconStrip value={sortType as EnumIconStrip} />
        </IconButton>
      }
    </LayoutFlexRow>
  );
}
