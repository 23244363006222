import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../base/plus/ListPlus";

export function reducersApiBr()
{
  //combine reducers inside object can't be empty, give key as name and optionId as reducer name
  return combineReducers({
    apiBrFilter: createListSlice("apiBrFilter",
      {
        defaultListItemType: "aps",
        pickType: "pickOne"
      }
    ).reducer
  });
}

                          
