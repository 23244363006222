import {combineReducers} from "@reduxjs/toolkit";
import {sliceCacheControlPanelEnt} from "./ent/SliceCacheControlPanelEnt";
import {sliceCacheControlPanelFilter} from "./filter/SliceCacheControlPanelFilter";
import {sliceCacheControlPanel} from "./SliceCacheControlPanel";

export function reducersCacheProduction()
{
  return combineReducers({
    ent: sliceCacheControlPanelEnt.reducer,
    app: sliceCacheControlPanel.reducer,
    filter: sliceCacheControlPanelFilter.reducer
  });
}
