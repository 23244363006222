import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdCode} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldChipSet} from "../../../../api/meta/base/dto/DefnFieldChipSet";
import {DefnStudioCodeEditor} from "../../../../api/meta/base/dto/DefnStudioCodeEditor";
import {StudioValueCode} from "../../../../api/meta/base/dto/StudioValueCode";
import {StudioValueCodeJavascript} from "../../../../api/meta/base/dto/StudioValueCodeJavascript";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {Srvc} from "../../../../srvc/Srvc";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawCodeEditor from "../../../atom/raw/RawCodeEditor";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNeoQL from "../../../dialog/DialogNeoQL";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldStudioCodeEditor(props: {
  defn: DefnStudioCodeEditor,
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const showExpandBtn = (defn.showExpandBtn === true || undefined);
  const fieldId = getFieldKey(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldChipSet) || defn.disabled;
  const readOnly = formCtx.isReadonly() || !Boolean(defn.metaId);
  const helperText = defnDtoTextToString(defn.helperTextVar);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;

        const fieldValue = field.value as StudioValueCode;
        const dialogFieldValue = fieldValue;
        const label = defn.label;
        const isError = Boolean(error);

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            flexGrow={defn.flexHeight === false ? undefined : 1}
          >
            <RealRawCodeEditor
              defn={defn}
              onChange={field.onChange}
              label={label}
              value={fieldValue}
              showExpandBtn={showExpandBtn}
              dialogFieldValue={dialogFieldValue}
              readOnly={readOnly || disabled}
              error={isError ? error?.message : undefined}
              helperText={helperText}
            />
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealRawCodeEditor(props: {
  defn: DefnStudioCodeEditor,
  error?: string,
  showExpandBtn?: boolean,
  label?: string,
  value?: StudioValueCodeJavascript,
  dialogFieldValue?: StudioValueCodeJavascript,
  readOnly?: boolean,
  helperText?: string,
  onChange: (value?: StudioValueCodeJavascript) => void
})
{
  const {
    defn,
    onChange,
    label,
    showExpandBtn,
    value,
    dialogFieldValue,
    readOnly,
    error,
    helperText
  } = props;

  const theme = useTheme();
  const pageCtx = usePageCtx();

  const gapQuarter = theme.common.gapQuarter;
  const required = defn.required;
  const allowCopy = defn.allowCopy;
  const minHeight = defn.minHeight;

  return (
    <LayoutFlexCol
      height={"100%"}
      width={"100%"}
      alignItems={"flex-start"}
    >
      <LayoutFlexRow
        width={"100%"}
        justifyContent={(showExpandBtn || allowCopy) ? "space-between" : "flex-start"}
      >
        <RawHighlighter
          variant={"body2"}
          value={`${label} ${required ? "*" : ""}`}
          mb={gapQuarter}
        />
        <LayoutFlexRow>
          {allowCopy &&
            <RawIconStrip
              iconStrip={["copy"]}
              onClick={(iconName) =>
              {
                if(iconName === "copy")
                {
                  copyToClipboard(value?.value ?? "");
                  Srvc.app.toast.showSuccessToast("Copied to clipboard");
                }
              }}
            />
          }
          {
            showExpandBtn &&
            <RawIconStrip
              iconStrip={["copy", "expand"]}
              onClick={(icon) =>
              {
                if(icon === "expand")
                {
                  pageCtx.showDialog(
                    <DialogNeoQL
                      value={dialogFieldValue?.value}
                      defaultLanguage={defn.language}
                      editorTheme={"light"}
                      enableMiniMap={defn.enableMiniMap}
                      enableLineNumbers={defn.enableLineNumbers}
                      readonly={readOnly}
                      buttonValue={"Ok"}
                      dragAndDrop={true}
                      onClickButton={(newValue) =>
                      {
                        onChange({
                          metaId: value?.metaId ?? nextMetaIdCode(),
                          value: newValue
                        });
                      }}
                      title={label}
                    />
                  );
                }
                else if(icon === "copy")
                {
                  dialogFieldValue && dialogFieldValue.value && copyToClipboard(dialogFieldValue?.value);
                }
              }}
            />
          }
        </LayoutFlexRow>
      </LayoutFlexRow>
      <FormControl
        sx={{
          flexGrow: 1,
          width: "100%"
        }}
        error={Boolean(error)}
      >
        <RawCodeEditor
          isError={Boolean(error)}
          folding={true}
          value={value?.value}
          onChange={(newValue) => onChange({
            metaId: value?.metaId ?? nextMetaIdCode(),
            value: newValue
          })}
          defaultLanguage={defn.language}
          editorTheme={"light"}
          enableMiniMap={defn.enableMiniMap}
          enableLineNumbers={defn.enableLineNumbers}
          readonly={readOnly}
          dragAndDrop={true}
          minHeight={minHeight}
        />
        {(error || helperText) &&
          <FormHelperText
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {error ? error : helperText}
          </FormHelperText>
        }
      </FormControl>
    </LayoutFlexCol>
  );
}
