import {CircularProgress} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {useEffect} from "react";
import {useCallback} from "react";
import {useRef} from "react";
import {useState} from "react";
import React from "react";
import {Size} from "react-virtualized-auto-sizer";
import AutoSizer from "react-virtualized-auto-sizer";
import {Virtuoso} from "react-virtuoso";
import {VirtuosoHandle} from "react-virtuoso";
import {getListItemHeight} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {stripIconSx} from "../../../base/plus/ThemePlus";
import {listSetScrollToItemId} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinderAll} from "../../../base/types/list/TypesList";
import {useAppSelector} from "../../app/AppHooks";
import {CbOnDragListItem} from "../List";
import {CbOnClickListItem} from "../List";
import {useDraggable} from "./ListDraggable";
import {HeightPreservingItem} from "./ListDraggable";
import {ListItem} from "./ListItem";

// For animation example,
//
// https://stackblitz.com/edit/react-je5vjv?file=example.js
export function ListVirtuoso<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  selectList: SelectList,
  onClickListItem?: CbOnClickListItem,
  onDragListItem?: CbOnDragListItem,
  onItemSubscribe?: (itemId: TypeListItemId) => void,
  onItemUnsubscribe?: (itemId: TypeListItemId) => void,
  onBottomReached?: () => void,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>,
})
{
  const selectList = props.selectList;
  const loadMoreItemLimit = useAppSelector(state => selectList(state).loadMoreItemLimit);
  const defaultListItemType = useAppSelector(state => selectList(state).defaultListItemType);
  const displayItemIds = useAppSelector(state => selectList(state).displayItemIds);

  const defaultItemHeight = useAppSelector(state => getListItemHeight(defaultListItemType)
    || selectList(state).defaultItemHeight);
  const fixedItemHeight = useAppSelector(state =>
    defaultItemHeight !== undefined && selectList(state).groupsById === undefined
      ? defaultItemHeight
      : undefined);

  let listHeight: string | undefined;
  let listOverflowY: "auto" | "hidden";
  if(loadMoreItemLimit && fixedItemHeight)
  {
    const itemCount = Math.max(loadMoreItemLimit, displayItemIds.length);
    listHeight = px(itemCount * fixedItemHeight);
    listOverflowY = "hidden";
  }
  else
  {
    listHeight = "100%";
    listOverflowY = "auto";
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: listHeight
      }}
    >
      <RealListVirtuoso
        displayItemIds={displayItemIds}
        defaultItemHeight={defaultItemHeight}
        fixedItemHeight={fixedItemHeight}
        listOverflowY={listOverflowY}
        {...props}
      />
    </Box>
  );
}

function RealListVirtuoso<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  selectList: SelectList,
  onClickListItem?: CbOnClickListItem,
  onDragListItem?: CbOnDragListItem,
  onItemSubscribe?: (itemId: TypeListItemId) => void,
  onItemUnsubscribe?: (itemId: TypeListItemId) => void,
  onBottomReached?: () => void,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>,

  // additional props
  displayItemIds: string[],
  fixedItemHeight?: number,
  defaultItemHeight?: number,
  listOverflowY: "auto" | "hidden"
})
{
  const selectList = props.selectList;
  const displayItemIds = props.displayItemIds;
  const fixedItemHeight = props.fixedItemHeight;
  const defaultItemHeight = props.defaultItemHeight;
  const onBottomReached = props.onBottomReached;
  const [isScrolling, setIsScrolling] = useState(false);
  const listName = useAppSelector(state => selectList(state).listName);
  const scrollToItemId = useAppSelector(state => selectList(state).scrollToItemId);
  const topItemCount = useAppSelector(state => selectList(state).topItemCount);
  const isDraggable = useAppSelector(state => selectList(state).draggable);
  const refList = useRef<VirtuosoHandle | null>(null);

  const cbComputeItemKey = useCallback((index: number) => displayItemIds[index], [displayItemIds]);

  useEffect(() =>
  {
    if(scrollToItemId)
    {
      const realList = refList.current;
      if(realList)
      {
        const index = displayItemIds.indexOf(scrollToItemId);
        if(index >= 0)
        {
          realList.scrollToIndex({
            index: index,
            align: "center",
            behavior: "auto"
          });
        }
      }
      dispatchList(listName, listSetScrollToItemId(undefined));
    }
  }, [scrollToItemId, listName, displayItemIds]);

  const RenderListItem = useCallback((index: number, width?: number) =>
  {
    const itemId = displayItemIds[index];

    return <ListItem
      key={itemId}
      itemId={itemId}
      itemWidth={width as number}
      selectList={selectList}
      isScrolling={isScrolling}
      onClickListItem={props.onClickListItem}
      onItemSubscribe={props.onItemSubscribe}
      onItemUnsubscribe={props.onItemUnsubscribe}
      listBinder={props.listBinder}
      defaultItemHeight={defaultItemHeight}
    />;
  }, [
    defaultItemHeight,
    displayItemIds,
    isScrolling,
    props.listBinder,
    props.onClickListItem,
    props.onItemSubscribe,
    props.onItemUnsubscribe,
    selectList
  ]);

  const {RealDragDropContext, ItemDragContext} = useDraggable({
    selectList: selectList,
    displayItemIds: displayItemIds,
    onDragListItem: props.onDragListItem,
    renderItem: RenderListItem
  });

  return (
    <AutoSizer>
      {({
        height,
        width
      }: Size) =>
      {
        return RealDragDropContext((provided) => (
          <Virtuoso
            atBottomThreshold={1000}
            atBottomStateChange={atBottom => (onBottomReached && atBottom) && onBottomReached()}
            style={{
              height: height,
              width: width,
              overflowY: props.listOverflowY
            }}
            totalCount={displayItemIds.length}
            topItemCount={topItemCount}
            scrollerRef={provided?.innerRef as (ref: HTMLElement | Window | null) => any}
            // isScrolling={cbIsScrolling}
            ref={refList}
            fixedItemHeight={fixedItemHeight}
            defaultItemHeight={defaultItemHeight}
            computeItemKey={cbComputeItemKey}
            overscan={height / 2}
            increaseViewportBy={height * 2}
            scrollSeekConfiguration={{
              enter: (velocity) =>
              {
                if(Math.abs(velocity) > 50)
                {
                  setIsScrolling(true);
                }
                else
                {
                  setIsScrolling(false);
                }
                return false;
              },
              exit: (velocity) =>
              {
                if(Math.abs(velocity) < 10)
                {
                  setIsScrolling(false);
                }
                return false;
              }
            }}
            components={{
              Footer: () => <LoadingFooter selectList={selectList} />,
              Item: isDraggable ? HeightPreservingItem : undefined
            }}
            itemContent={(index) =>
            {
              const itemId = displayItemIds[index];
              return ItemDragContext(itemId, index, RenderListItem(index, width));
            }}
          />
        ));
      }}
    </AutoSizer>
  );
}

function LoadingFooter(props: {
  selectList: SelectList
})
{
  const loading = useAppSelector(state => props.selectList(state).loadingFooter);
  const theme = useTheme();
  const gapQuarter = theme.common.gapQuarter;
  const sx = stripIconSx(theme.common.color("primary"));

  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        position: "absolute",
        display: loading
          ? "flex"
          : "none"
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          zIndex: 2,
          p: px(gapQuarter)
        }}
      >
        <CircularProgress
          size={sx.width}
          sx={sx}
        />
      </Box>
    </Box>
  );
}

