import {useMemo} from "react";
import React, {useCallback, useEffect, useState} from "react";
import {StudioComposite} from "../../../api/meta/base/dto/StudioComposite";
import {StudioDtoFormula} from "../../../api/meta/base/dto/StudioDtoFormula";
import {StudioDtoLayoutFormContent} from "../../../api/meta/base/dto/StudioDtoLayoutFormContent";
import {StudioForm} from "../../../api/meta/base/dto/StudioForm";
import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {StudioVisibilityRule} from "../../../api/meta/base/dto/StudioVisibilityRule";
import {MetaIdLayoutForm} from "../../../api/meta/base/Types";
import {MetaIdFormula} from "../../../api/meta/base/Types";
import {MetaIdForm, MetaIdVisibilityRule} from "../../../api/meta/base/Types";
import {px} from "../../../base/plus/StringPlus";
import {IAsidePropsStudioFormEdit} from "../../../base/types/TypesAside";
import {IMainPropsStudioFormBuilderFormContentLayout} from "../../../base/types/TypesMain";
import {IMainPropsStudioFormBuilderFormFormula} from "../../../base/types/TypesMain";
import {IMainPropsStudioFormBuilder, IMainPropsStudioFormBuilderVisibilityRules} from "../../../base/types/TypesMain";
import {EnumStudioEntAside, IStudioJumpPath} from "../../../base/types/TypesStudio";
import {EnumIconFormBuilder} from "../../atom/icon/IconForm";
import {DividerHorizontal} from "../../atom/layout/DividerHorizontal";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import {useAppCtx} from "../../ctx/CtxApp";
import FormBuilderPickForm from "./base/FormBuilderPickForm";
import {useJumpConfigFormBuilder} from "./base/FormBuilderPlus";
import {fieldContentLayoutList} from "./base/TypesFormBuilder";
import {fieldMapOfFormula} from "./base/TypesFormBuilder";
import {fieldVisibilityList, ICallerPermission, IFormBuilderProps} from "./base/TypesFormBuilder";
import FormBuilderFooter from "./footer/FormBuilderFooter";
import FormBuilderFormContentLayout from "./footer/FormBuilderFormContentLayout";
import FormBuilderFormFormula from "./footer/FormBuilderFormFormula";
import FormBuilderFormVisibility from "./footer/FormBuilderFormVisibility";
import FormBuilderMain from "./FormBuilderMain";

export default function FormBuilder(props: IFormBuilderProps)
{
  const appCtx = useAppCtx();
  const [footerLeftIcons, setFooterLeftIcons] = useState<EnumIconFormBuilder[]>();

  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const formId = mainProps.formId as MetaIdForm;
  const asideProps = appCtx.getAsideProps() as IAsidePropsStudioFormEdit;

  const formStore = props.formStore;
  const studioSize = props.studioSize;
  const selectListMap = props.selectListMap;
  const callerPermission = props.callerPermission;
  const setupMode = props.setupMode;
  const cbOnSubmit = props.cbOnSubmit;

  const formMap = formStore.formMap;
  const isFormDetails = mainProps.jumpStepItem === "FormDetails";
  const readOnlyForm = fnIsCallerPermissionForm(callerPermission, "Forms");
  const readonlyVisibilityRule = fnIsCallerPermissionForm(callerPermission, "FormVisibilityRules");
  const readOnlyContentLayout = fnIsCallerPermissionForm(callerPermission, "FormContentLayout");
  const readonlyFormula = fnIsCallerPermissionForm(callerPermission, "FormFormulas");
  const readonlyPaymentConfig = fnIsCallerPermissionForm(callerPermission, "PaymentConfig");

  const form = useMemo(() => (formMap?.map && formId)
    ? formMap.map[formId]
    : undefined, [formMap?.map, formId]);

  const readOnly = props.readOnly
    || (isFormDetails && readOnlyForm)
    || (!isFormDetails && readonlyVisibilityRule)
    || (!isFormDetails && readOnlyContentLayout)
    || (!isFormDetails && readonlyFormula)
    || (isFormDetails && readonlyPaymentConfig);

  //region callback submit

  const cbOnSubmitForm = useCallback((form: StudioForm) =>
  {
    cbOnSubmit && cbOnSubmit("form",
      {
        formId,
        form: form
      }
    );
  }, [formId, cbOnSubmit]);

  const cbOnSubmitFormVisibilityRule = useCallback((studioVisibilityRule: StudioVisibilityRule) =>
  {
    cbOnSubmit && cbOnSubmit("visibilityRule", {
      formId,
      visibilityRule: studioVisibilityRule
    });
  }, [formId, cbOnSubmit]);

  const cbOnSubmitFormContentLayout = useCallback((studioVisibilityRule: StudioDtoLayoutFormContent) =>
  {
    cbOnSubmit && cbOnSubmit("contentLayout", {
      formId,
      contentLayout: studioVisibilityRule
    });
  }, [formId, cbOnSubmit]);

  const cbOnSubmitFormFormula = useCallback((studioFormula: StudioDtoFormula) =>
  {
    cbOnSubmit && cbOnSubmit("formula", {
      formId,
      formula: studioFormula
    });
  }, [formId, cbOnSubmit]);

  const cbOnSubmitComposite = useCallback((composite: StudioComposite) =>
  {
    cbOnSubmit && cbOnSubmit("composite", {
      formId,
      composite: composite
    });
  }, [formId, cbOnSubmit]);

  const cbOnSubmitStudioVar = useCallback((studioVar: StudioVar) =>
  {
    props.cbOnSubmit && props.cbOnSubmit("studioVar", {
      formId,
      studioVar: studioVar
    });
  }, [formId, cbOnSubmit]);

  //endregion

  //region callback edit

  const onClickEditFormVisibilityRules = useCallback((
    visibilityId?: MetaIdVisibilityRule,
    jumpPath?: IStudioJumpPath[]) =>
  {
    appCtx.setMainProps({
      ...mainProps,
      jumpStepItem: "FormVisibilityRules",
      jumpListItemId: visibilityId,
      selectList: selectListMap[fieldVisibilityList],
      formId: formId,
      jumpPath: jumpPath
    } as IMainPropsStudioFormBuilderVisibilityRules);

  }, [appCtx, mainProps, formId, selectListMap]);

  const onClickEditFormContentLayout = useCallback((
    contentLayoutId?: MetaIdLayoutForm,
    jumpPath?: IStudioJumpPath[]) =>
  {
    appCtx.setMainProps({
      ...mainProps,
      jumpStepItem: "FormContentLayout",
      jumpListItemId: contentLayoutId,
      selectList: selectListMap[fieldContentLayoutList],
      formId: formId,
      jumpPath: jumpPath
    } as IMainPropsStudioFormBuilderFormContentLayout);
  }, [appCtx, mainProps, formId, selectListMap]);

  const onClickEditFormFormula = useCallback((formulaId?: MetaIdFormula, jumpPath?: IStudioJumpPath[]) =>
  {
    appCtx.setMainProps({
      ...mainProps,
      jumpStepItem: "FormFormulas",
      jumpListItemId: formulaId,
      selectList: selectListMap[fieldMapOfFormula],
      formId: formId,
      jumpPath: jumpPath
    } as IMainPropsStudioFormBuilderFormFormula);

  }, [appCtx, mainProps, formId, selectListMap]);

  const onClickEditForm = useCallback((type: EnumStudioEntAside, jumpPath?: IStudioJumpPath[]) =>
  {
    if(mainProps.jumpStepItem !== "FormDetails")
    {
      appCtx.setMainProps({
        ...mainProps,
        jumpStepItem: "FormDetails"
      } as IMainPropsStudioFormBuilder);
    }

    appCtx.setAsideProps({
      type: type,
      formId: formId,
      metaId: formId,
      formStore: formStore,
      setupMode: setupMode,
      allowRefField: true,
      selectListMap: selectListMap,
      cbOnFormSubmit: cbOnSubmitForm,
      readOnly: props.readOnly || fnIsCallerPermissionForm(callerPermission, type),
      jumpPath: jumpPath,
      reset: true,
      cbOnAddNewVar: cbOnSubmitStudioVar,
      cbOnShowVar: props.cbOnShowVar,
      cbOnEditVar: props.cbOnEditVar
    } as IAsidePropsStudioFormEdit);
  }, [
    mainProps,
    appCtx,
    formId,
    formStore,
    setupMode,
    selectListMap,
    cbOnSubmitForm,
    cbOnSubmitStudioVar,
    props.readOnly,
    props.cbOnShowVar,
    props.cbOnEditVar,
    callerPermission
  ]);

  const onClickPaymentConfig = useCallback((jumpPath?: IStudioJumpPath[]) =>
    {
      if(mainProps.jumpStepItem !== "FormDetails")
      {
        appCtx.setMainProps({
          ...mainProps,
          jumpStepItem: "FormDetails"
        } as IMainPropsStudioFormBuilder);
      }

      appCtx.setAsideProps({
        type: "PaymentConfig",
        formId: formId,
        metaId: formId,
        formStore: formStore,
        cbOnFormSubmit: cbOnSubmitForm,
        readOnly: props.readOnly || fnIsCallerPermissionForm(callerPermission, "PaymentConfig"),
        jumpPath: jumpPath,
        reset: true,
        cbOnShowVar: props.cbOnShowVar,
        cbOnEditVar: props.cbOnEditVar
      } as IAsidePropsStudioFormEdit);
    },
    [
      appCtx,
      callerPermission,
      cbOnSubmitForm,
      formId,
      formStore,
      mainProps,
      props.cbOnEditVar,
      props.cbOnShowVar,
      props.readOnly
    ]);

  //endregion

  useEffect(() =>
  {
    if(callerPermission)
    {
      const _footerLeftIcons = [] as EnumIconFormBuilder[];
      if(callerPermission.formEditDetails.show)
      {
        _footerLeftIcons.push("edit");
      }
      if(callerPermission.formEditFormulas.show)
      {
        _footerLeftIcons.push("formula");
      }
      if(callerPermission.formEditVisibility.show)
      {
        _footerLeftIcons.push("visibility");
      }
      if(callerPermission.formEditContentLayout.show)
      {
        _footerLeftIcons.push("contentLayout");
      }
      if(callerPermission.formEditPaymentConfig.show)
      {
        _footerLeftIcons.push("payment");
      }
      setFooterLeftIcons(_footerLeftIcons);
    }
    else
    {
      setFooterLeftIcons(undefined);
    }

  }, [callerPermission]);

  useEffect(() =>
  {
    if(asideProps && formStore?.version !== asideProps?.formStore?.version)
    {
      appCtx.setAsideProps({
        ...asideProps,
        formStore: formStore
      });
    }
  }, [appCtx, formStore, formMap]);

  useEffect(() =>
  {
    if(!form)
    {
      appCtx.setMainProps({
        ...mainProps,
        jumpStep: "Forms"
      } as IMainPropsStudioFormBuilder);
    }
  }, [form]);

  useJumpConfigFormBuilder(onClickEditForm,
    onClickEditFormVisibilityRules,
    onClickEditFormFormula,
    onClickEditFormContentLayout,
    onClickPaymentConfig
  );

  return (
    <LayoutFlexCol
      width={"100%"}
      height={"100%"}
      justifyContent={"flex-start"}
    >
      {
        formMap &&
        <FormBuilderPickForm
          formMap={formMap}
          studioSize={studioSize}
        />
      }

      <DividerHorizontal />

      <LayoutFlexCol
        width={px(studioSize.mainContentWidth)}
        flexGrow={1}
      >
        <FormBuilderContent {...props} readOnly={readOnly} />

        <FormBuilderFooter
          footerLeftIcons={props.footerLeftIcons
            ? props.footerLeftIcons
            : callerPermission
              ? footerLeftIcons || []
              : undefined}
          validationResult={formStore.validationResult}
          footerRightIcons={props.footerRightIcons}
          cbOnClickAddStudioComposite={cbOnSubmitComposite}
          cbOnClickAddStudioVisibilityRule={cbOnSubmitFormVisibilityRule}
          cbOnClickAddStudioFormula={cbOnSubmitFormFormula}
          cbOnClickAddStudioContentLayout={cbOnSubmitFormContentLayout}
          cbOnClickVisibility={onClickEditFormVisibilityRules}
          cbOnClickContentLayout={onClickEditFormContentLayout}
          cbOnClickFormula={onClickEditFormFormula}
          cbOnClickEditForm={() => onClickEditForm("Forms")}
          cbOnClickPreview={() => onClickEditForm("FormPreview")}
          cbOnClickPaymentConfig={onClickPaymentConfig}
          readOnly={readOnly}
          formStore={formStore}
          form={form}
        />

      </LayoutFlexCol>
    </LayoutFlexCol>
  );
}

function FormBuilderContent(props: IFormBuilderProps)
{
  const appCtx = useAppCtx();
  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const readOnly = props.readOnly;

  switch(mainProps.jumpStepItem)
  {
    case "FormDetails":
      return <FormBuilderMain
        {...props}
        readOnly={readOnly}
      />;
    case "FormVisibilityRules":
      return <FormBuilderFormVisibility
        {...props}
        readOnly={readOnly}
      />;
    case "FormFormulas":
      return <FormBuilderFormFormula
        {...props}
        readOnly={readOnly}
      />;
    case "FormContentLayout":
      return <FormBuilderFormContentLayout
        {...props}
        readOnly={readOnly}
      />;
  }

  return null;
}

export function fnIsCallerPermissionForm(
  callerPermission?: ICallerPermission,
  type?: EnumStudioEntAside): boolean | undefined
{
  if(!callerPermission)
  {
    return false;
  }

  switch(type)
  {
    case "Forms":
      const formPermission = callerPermission?.formEditDetails.edit;
      return !formPermission;
    case "FormFormulas":
      const formulaPermission = callerPermission?.formEditFormulas.edit;
      return !formulaPermission;
    case "FormVisibilityRules":
      const visibilityPermission = callerPermission?.formEditVisibility.edit;
      return !visibilityPermission;
    case "FormContentLayout":
      const contentLayoutPermission = callerPermission?.formEditContentLayout.edit;
      return !contentLayoutPermission;
    case "PaymentConfig":
      const paymentConfig = callerPermission?.formEditPaymentConfig.edit;
      return !paymentConfig;
    default:
      return true;
  }
}

