import React from "react";
import {SelectList} from "../../../base/plus/ListPlus";
import {IListBinderAll} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {ListItemTypeNoBubble} from "../../../base/types/list/TypesList";
import {ListItemAP} from "../apsa/ListItemAP";
import {ListItemAPS} from "../apsa/ListItemAPS";
import {ListItemAPSA} from "../apsa/ListItemAPSA";
import {ListItemMPSL} from "../apsa/ListItemMPSL";
import {ListItemP} from "../apsa/ListItemP";
import {ListItemP3} from "../apsa/ListItemP3";
import {ListItemP3SS} from "../apsa/ListItemP3SS";
import {ListItemPS} from "../apsa/ListItemPS";
import {ListItemForm} from "../form/ListItemForm";
import {ListItemFooter} from "../gap/ListItemFooter";
import {ListItemGap} from "../gap/ListItemGap";
import {ListItemLoadMore} from "../gap/ListItemLoadMore";
import {ListItemGroup} from "../group/ListItemGroup";
import {CbOnClickListItem} from "../List";

export default function ListItemNoChat<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  itemType: ListItemTypeNoBubble,
  itemId: TypeListItemId,
  selectList: SelectList,
  isScrolling: boolean,
  onClickListItem?: CbOnClickListItem,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
  defaultItemHeight?: number
})
{
  const itemId = props.itemId;
  const isScrolling = props.isScrolling;
  const selectList = props.selectList;

  const childProps = {
    itemId: itemId,
    selectList: selectList,
    onClickListItem: props.onClickListItem,
    isScrolling: isScrolling,
    defaultItemHeight: props.defaultItemHeight
  };

  switch(props.itemType)
  {
    case "p":
      return <MemoizedListItemP {...childProps} />;
    case "ps":
      return <MemoizedListItemPS {...childProps} />;
    case "ap":
      return <MemoizedListItemAP{...childProps} />;
    case "aps":
      return <MemoizedListItemAPS{...childProps} />;
    case "apsa":
      return <MemoizedListItemAPSA{...childProps} />;
    case "listGroup":
      return <MemoizedListItemGroup {...childProps} />;
    case "gapQuarter":
    case "gapHalf":
    case "gapStd":
      return <MemoizedListItemGap itemType={props.itemType} />;
    case "loadMore":
      return <MemoizedListItemLoadMore {...childProps} />;
    case "footer":
      return <MemoizedListItemFooter {...childProps} />;
    case "p3ss":
      return <MemoizedListItemP3SS {...childProps} />;
    case "p3":
      return <MemoizedListItemP3 {...childProps} />;
    case "mpsl":
      return <MemoizedListItemMPSL {...childProps} />;
    case "form":
      return <MemoizedListItemForm {...childProps} />;
  }
}

const MemoizedListItemP = React.memo(ListItemP);
const MemoizedListItemPS = React.memo(ListItemPS);
const MemoizedListItemAP = React.memo(ListItemAP);
const MemoizedListItemAPS = React.memo(ListItemAPS);
const MemoizedListItemAPSA = React.memo(ListItemAPSA);
const MemoizedListItemP3SS = React.memo(ListItemP3SS);
const MemoizedListItemMPSL = React.memo(ListItemMPSL);
const MemoizedListItemForm = React.memo(ListItemForm);
const MemoizedListItemP3 = React.memo(ListItemP3);
const MemoizedListItemGroup = React.memo(ListItemGroup);
const MemoizedListItemGap = React.memo(ListItemGap);
const MemoizedListItemLoadMore = React.memo(ListItemLoadMore);
const MemoizedListItemFooter = React.memo(ListItemFooter);
