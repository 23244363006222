import {CircularProgress} from "@mui/material";
import {TextField} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {useMemo} from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {Noop} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLocation} from "../../../../api/meta/base/dto/DefnFieldLocation";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueLocation} from "../../../../api/meta/base/dto/FieldValueLocation";
import {getLocationAsString} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {browserLocationPermission} from "../../../../base/plus/LocationPlus";
import {getGoogleMapApiKey} from "../../../../base/plus/LocationPlus";
import {getLatLngFromGeoPoint} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {useDeviceType} from "../../../app/AppHooks";
import DialogLocationSearch from "../../../atom/dialog/DialogLocationSearch";
import IconStrip from "../../../atom/icon/IconStrip";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {CbOnClickForm} from "../base/CtxForm";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import RawCaptureExtraProperties from "../raw/RawCaptureExtraProperties";

export default function FieldLocation(props: {
  defn: DefnFieldLocation
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const label = getCompLabel(defn);
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const googleMapApiKey = getGoogleMapApiKey();
  const fieldVariant = defnTheme.fieldVariant;

  const [permission, setPermission] = useState(false);

  const helperTextPriority = useMemo(() =>
  {
    return !permission
      ? "Enable location access"
      : undefined;

  }, [permission]);

  const helperText = helperTextPriority ?? getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const required = getFieldRequired();
  const icon = getFieldIcon();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueLocation | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              {
                <RawFieldLocation
                  defn={defn}
                  fieldId={fieldId}
                  label={label}
                  googleMapsApiKey={googleMapApiKey}
                  onBlur={field.onBlur}
                  value={fieldValue}
                  onChange={field.onChange}
                  error={error}
                  required={required}
                  placeHolder={placeHolder}
                  helperText={helperText}
                  icon={icon}
                  setPermission={setPermission}
                />
              }

              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }

            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawFieldLocation(props: {
  defn: DefnFieldLocation,
  fieldId: string,
  label: string,
  required?: boolean,
  placeHolder?: string,
  helperText?: string,
  onBlur: Noop,
  googleMapsApiKey?: string,
  error?: FieldError,
  icon?: string,
  value?: FieldValueLocation,
  setPermission?: (permission: boolean) => void,
  onChange: (data?: FieldValueLocation | null) => void,
  getOnClick?: CbOnClickForm
})
{
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();
  const deviceType = useDeviceType();

  const defn = props.defn;
  const fieldId = props.fieldId;
  const label = props.label;
  const onBlur = props.onBlur;
  const googleMapsApiKey = props.googleMapsApiKey;
  const value = props.value;
  const error = props.error;
  const onChange = props.onChange;
  const required = props.required;
  const placeHolder = props.placeHolder;
  const icon = props.icon;
  const setPermission = props.setPermission;

  const isError = Boolean(error);
  const defnTheme = formCtx.getDefnTheme();
  const isMobile = Boolean(deviceType === "mobile");
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const fieldValue = getLocationAsString(value);
  const geoPoint = value?.value.geoPoint;
  const fieldValueGeoPoint = geoPoint ? getLatLngFromGeoPoint(geoPoint) : undefined;
  const getOnClick = formCtx.getOnClick();
  const showCapturedAsHelperText = defn.showCapturedValuesOnAside;
  const isLoading = formCtx.isFieldLoading(fieldId);

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const isDelete = !(readOnly || disabled || (defn.captureMode === "onInsert" || defn.captureMode === "onUpdate"));
  const showLocationDialog = !readOnly && !disabled && googleMapsApiKey
    && !((defn.captureMode === "onInsert" || defn.captureMode === "onUpdate"));

  const onClick = useCallback(() =>
  {
    if(showLocationDialog)
    {
      pageCtx.showDialog(
        <DialogLocationSearch
          googleMapApiKey={googleMapsApiKey}
          fullScreen={isMobile}
          defaultGeoPoint={fieldValueGeoPoint}
          onSave={onChange}
          onClose={() => pageCtx.showDialog(undefined)}
        />
      );
    }

    getOnClick && getOnClick(fieldId, "field");
  }, [fieldValueGeoPoint]);

  useEffect(() =>
  {
    browserLocationPermission(permission =>
      {
        setPermission && setPermission(permission);
      }
    );

  }, []);

  return (
    <LayoutFlexCol
      overflowY={"visible"}
      overflowX={"visible"}
    >
      <LayoutFlexRow
        width={"100%"}
        overflowY={"visible"}
        overflowX={"visible"}
      >
        <TextField
          fullWidth
          type="text"
          multiline={true}
          size={defnTheme.fieldSize}
          margin={defnTheme.fieldMargin}
          placeholder={placeHolder}
          value={fieldValue}
          sx={{
            ".MuiOutlinedInput-root": {
              visibility: "none"
            },
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          name={fieldId}
          label={label}
          autoComplete={Boolean(defn.autoFill) ? "on" : "off"}
          autoFocus={Boolean(defn.autoFocus)}
          disabled={disabled}
          hiddenLabel={Boolean(defn.hideLabel)}
          required={Boolean(required)}
          error={isError}
          onClick={onClick}
          onKeyDown={(event) =>
          {
            if(event.key === "Enter")
            {
              onClick();
            }
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <LayoutFlexRow gap={px(gapHalf)}>
                {isLoading &&
                  <CircularProgress size={20} />
                }
                {icon ? <RawIconButton
                  name={icon}
                  icon={icon}
                  padding={0}
                /> : <IconStrip value={"location"} />}
              </LayoutFlexRow>
            )
          }}
          onBlur={onBlur}
        />

        {isDelete &&
          <LayoutFlexRow
            ml={px(gapHalf)}
            mr={`-${gapHalf}px`}
          >
            <RawIconStrip
              iconStrip={["delete"]}
              onClick={(icon) =>
              {
                if(icon === "delete")
                {
                  onChange(null);
                }
              }}
              iconStripDisable={(!fieldValue || readOnly || disabled) ? ["delete"] : []}
            />
          </LayoutFlexRow>
        }
        <FieldRawRefButton
          defn={defn}
        />
      </LayoutFlexRow>
      {
        <RawCaptureExtraProperties
          captureTime={value?.value.dateTime}
          captureUser={
            {value: value?.value.entUserId} as FieldValueEntUserId
          }
          showCapturedAsHelperText={showCapturedAsHelperText}
        />
      }
    </LayoutFlexCol>
  );
}
