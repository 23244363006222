import {KeyboardVoice} from "@mui/icons-material";
import {AudioFileRounded} from "@mui/icons-material";
import {Box} from "@mui/system";
import {Property} from "csstype";
import React from "react";
import {px} from "../../../../../base/plus/StringPlus";
import theme from "../../../../../base/plus/ThemePlus";
import RawFieldAudioBar from "../../../../form/viewer/advanced/RawFieldAudioBar";
import {LayoutGap} from "../../../layout/LayoutGap";

const COLOR_PROGRESS = "#3e3c3c";
const COLOR_WAVE = "#e3e3e3";
const COLOR_CURSOR = "black";

export default function BubbleRawMediaAudio(props: {
  src?: string;
  width?: Property.Width,
  height?: Property.Height,
  type?: "audio" | "voice"
})
{
  const audioValue = props.src;
  const height = props.height;
  const width = props.width;
  const type = props.type;

  return (
    <Box
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={height}
      width={width}
      bgcolor={theme.palette.grey[200]}
    >

      {
        type === "voice" ?
          <KeyboardVoice
            sx={{
              position: "absolute",
              height: px(theme.common.heightInbox),
              width: px(theme.common.heightInbox)
            }}
            color={"action"}
          /> :

          <AudioFileRounded
            sx={{
              position: "absolute",
              height: px(theme.common.heightInbox),
              width: px(theme.common.heightInbox)
            }}
            color={"action"}
          />
      }
      <LayoutGap height={"85%"} />

      <Box
        sx={{
          height: "15%",
          width: "100%"
        }}
      >
        <RawFieldAudioBar
          showDuration={true}
          src={audioValue}
          scrollable={true}
          cursorColor={COLOR_CURSOR}
          progressColor={COLOR_PROGRESS}
          waveColor={COLOR_WAVE}
        />
      </Box>

    </Box>);
}
