import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickSpreadsheetId} from "../../../../api/meta/base/dto/DefnStudioPickSpreadsheetId";
import {StudioEntSpreadsheetMap} from "../../../../api/meta/base/dto/StudioEntSpreadsheetMap";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdSpreadsheet | MetaIdSpreadsheet[] | undefined;

export default function FieldRawStudioPickSpreadsheetId(props: {
  defn: DefnStudioPickSpreadsheetId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean
})
{
  const defn = props.defn;
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();

  const formStore = formCtx.getStore();
  const spreadsheetMap = formStore?.spreadsheetMap;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const excludeSpreadsheetIdSet = defn.excludeSpreadsheetIdSet;
  const showAlias = defn.showAlias;

  const dtoOptions = useMemo(() => getOption(
    spreadsheetMap,
    showAlias,
    excludeSpreadsheetIdSet
  ), [showAlias, spreadsheetMap, excludeSpreadsheetIdSet]);

  const [options] = useInsertTrashOptions({
    type: "spreadsheetMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOption(
  spreadsheetMap?: StudioEntSpreadsheetMap,
  showAlias?: boolean,
  excludeSpreadsheetIdSet?: MetaIdSpreadsheet[]): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];

  if(!spreadsheetMap)
  {
    return options;
  }

  spreadsheetMap.keys.forEach(spreadsheetId =>
  {
    const spreadsheet = spreadsheetMap.map[spreadsheetId];
    const spreadsheetName = spreadsheet.details.name;

    if(excludeSpreadsheetIdSet && excludeSpreadsheetIdSet.includes(spreadsheetId))
    {
      return;
    }

    options.push({
      metaId: spreadsheetId,
      value: (!showAlias
          ? spreadsheet.details.name
          : `${spreadsheetName} -> ${spreadsheet.alias}`)
        || spreadsheetId
    } as DefnDtoOption);

  });

  return options;
}
