import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {SigCaller} from "../../../api/core/user/sig/SigCaller";
import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {FieldDtoLocation} from "../../../api/meta/base/dto/FieldDtoLocation";
import {StoreItemId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {SigStoreItemAvatar} from "../../../api/store/store/sig/SigStoreItemAvatar";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import {mergeArraySets} from "../../../base/plus/JsPlus";
import {calcDeltaArray} from "../../../base/plus/JsPlus";
import {removeItem} from "../../../base/plus/JsPlus";
import {logError} from "../../../base/util/AppLog";
import {Srvc} from "../../../srvc/Srvc";
import {ICallerState} from "./TypesCacheCaller";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export const sliceCacheCaller = createSlice({
  name: "cacheCaller",
  initialState: {
    entIdUserSet: [] as EntId[],
    entIdUserAvatarMap: {},
    entIdUserAvatarMapMutationCount: 0,

    entIdAdminSet: [] as EntId[],
    entIdAdminAvatarMap: {},
    entIdAdminAvatarMapMutationCount: 0,

    entUserIdMap: {},
    entIdSet: [] as EntId[],

    entAdminIdMap: {},

    callerComboGroupIdSet: [],
    groupAvatarMap: {},
    mutationCount: 0,

    pluginAdminIdMap: {},
    pluginIdAdminSet: [] as PluginBundleId[],
    pluginIdAdminAvatarMap: {},
    pluginIdAdminAvatarMapMutationCount: 0,

    storeItemAdminIdMap: {},
    storeItemIdAdminSet: [] as StoreItemId[],
    storeItemIdAdminAvatarMap: {},
    storeItemIdAdminAvatarMapMutationCount: 0,

    chatNotificationSettingMap: {},
    globalNotificationSetting: {}
  } as ICallerState,
  reducers: {
    clearEntDelta: (state) =>
    {
      state.entIdUserSetDelta = undefined;
      state.entIdAdminSetDelta = undefined;
    },
    setCaller: (state, action: PayloadAction<SigCaller>) =>
    {
      const newCaller = action.payload;
      if(state.callerVersion === newCaller.version)
      {
        return;
      }

      const newUserId = newCaller.userId;
      if(state.callerUserId === undefined)
      {
        state.callerUserId = newUserId;
      }
      else if(state.callerUserId !== newUserId)
      {
        logError("SliceCache", "Invalid caller id");
        setTimeout(() => Srvc.app.auth.rpcSignOut());
        return;
      }

      state.callerVersion = newCaller.version;
      state.callerAbout = newCaller.about;
      state.callerCreationTime = newCaller.creationTime;
      state.callerFirstName = newCaller.firstName;
      state.callerHandle = newCaller.handle;
      state.callerLastName = newCaller.lastName;
      state.callerLastUpdate = newCaller.lastUpdate;
      state.callerMediaIdAvatar = newCaller.mediaIdAvatar;
      state.callerUserIdHash = newCaller.userIdHash;
      state.allowStorePublish = newCaller.allowStorePublish;
      state.allowStudio = newCaller.allowStudio;
      state.enterIsSendMobile = newCaller.enterIsSendMobile;
      state.updateProfile = newCaller.updateProfile;
      state.enterIsSendDesktop = newCaller.enterIsSendDesktop;
      state.callerLanguage = newCaller.languageKey;
      state.chatNotificationSettingMap = newCaller.chatNotificationSettingMap;
      state.globalNotificationSetting = newCaller.globalNotificationSetting;

      const entIdUserSet = Object.keys(newCaller.entUserIdMap);
      const entIdAdminSet = Object.keys(newCaller.entAdminIdMap);
      const pluginIdAdminSet = Object.keys(newCaller.pluginAdminIdMap);
      const storeItemIdAdminSet = Object.keys(newCaller.storeItemAdminIdMap);

      state.callerVersion = newCaller.version;
      state.entUserIdMap = newCaller.entUserIdMap;
      state.entAdminIdMap = newCaller.entAdminIdMap;
      state.pluginAdminIdMap = newCaller.pluginAdminIdMap;
      state.storeItemAdminIdMap = newCaller.storeItemAdminIdMap;

      removeItem(entIdUserSet, ENT_ID_GLOBAL);

      state.entIdUserSetDelta = calcDeltaArray(state.entIdUserSet, entIdUserSet);
      state.entIdAdminSetDelta = calcDeltaArray(state.entIdAdminSet, entIdAdminSet);
      state.entIdSet = mergeArraySets(entIdUserSet, entIdAdminSet);

      state.entIdUserSet = entIdUserSet;
      state.entIdAdminSet = entIdAdminSet;

      state.pluginIdAdminSetDelta = calcDeltaArray(state.pluginIdAdminSet, pluginIdAdminSet);
      state.pluginIdAdminSet = pluginIdAdminSet;

      state.storeItemIdAdminSetDelta = calcDeltaArray(state.storeItemIdAdminSet, storeItemIdAdminSet);
      state.storeItemIdAdminSet = storeItemIdAdminSet;
    },
    setEntAvatarUser: (state, action: PayloadAction<SigEntAvatarUser>) =>
    {
      const sig = action.payload;
      const entId = sig.entId;
      state.entIdUserAvatarMap[entId] = sig;
      state.entIdUserAvatarMapMutationCount = state.entIdUserAvatarMapMutationCount + 1;
    },
    removeEntAvatarUser: (state, action: PayloadAction<EntId>) =>
    {
      const entId = action.payload;
      delete state.entIdUserAvatarMap[entId];
      state.entIdUserAvatarMapMutationCount = state.entIdUserAvatarMapMutationCount + 1;
    },
    setEntAvatarAdmin: (state, action: PayloadAction<SigStudioEntAvatar>) =>
    {
      const sig = action.payload;
      const entId = sig.entId;
      state.entIdAdminAvatarMap[entId] = sig;
      state.entIdAdminAvatarMapMutationCount = state.entIdAdminAvatarMapMutationCount + 1;
    },
    removeEntAvatarAdmin: (state, action: PayloadAction<EntId>) =>
    {
      const entId = action.payload;
      delete state.entIdAdminAvatarMap[entId];
      state.entIdAdminAvatarMapMutationCount = state.entIdAdminAvatarMapMutationCount + 1;
    },
    setPluginAvatarAdmin: (state, action: PayloadAction<SigStudioPluginAvatar>) =>
    {
      const sig = action.payload;
      const pluginBundleId = sig.pluginBundleId;
      state.pluginIdAdminAvatarMap[pluginBundleId] = sig;
      state.pluginIdAdminAvatarMapMutationCount = state.pluginIdAdminAvatarMapMutationCount + 1;
    },
    removePluginAvatarAdmin: (state, action: PayloadAction<PluginBundleId>) =>
    {
      const pluginBundleId = action.payload;
      delete state.pluginIdAdminAvatarMap[pluginBundleId];
      state.pluginIdAdminAvatarMapMutationCount = state.pluginIdAdminAvatarMapMutationCount + 1;
    },
    setStoreItemAvatarAdmin: (state, action: PayloadAction<SigStoreItemAvatar>) =>
    {
      const sig = action.payload;
      const storeItemId = sig.storeItemId;
      state.storeItemIdAdminAvatarMap[storeItemId] = sig;
      state.storeItemIdAdminAvatarMapMutationCount = state.storeItemIdAdminAvatarMapMutationCount + 1;
    },
    removeStoreItemAvatarAdmin: (state, action: PayloadAction<StoreItemId>) =>
    {
      const storeItemId = action.payload;
      delete state.storeItemIdAdminAvatarMap[storeItemId];
      state.storeItemIdAdminAvatarMapMutationCount = state.storeItemIdAdminAvatarMapMutationCount + 1;
    },
    setCallerGeoLocation: (state, action: PayloadAction<FieldDtoLocation>) =>
    {
      state.callerGeoLocation = action.payload;
    },
    setCallerPasswordReset: (state, action: PayloadAction<boolean>) =>
    {
      state.resetPassword = action.payload;
    }

  }
});

export const {
  clearEntDelta,
  setEntAvatarUser,
  removeEntAvatarUser,
  setEntAvatarAdmin,
  removeEntAvatarAdmin,
  setPluginAvatarAdmin,
  removePluginAvatarAdmin,
  setStoreItemAvatarAdmin,
  removeStoreItemAvatarAdmin,
  setCaller,
  setCallerGeoLocation,
  setCallerPasswordReset
} = sliceCacheCaller.actions;
