import {Menu} from "@mui/material";
import {Checkbox} from "@mui/material";
import {FormControlLabel} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useEffect} from "react";
import React from "react";
import {useState} from "react";
import {gapStd} from "../../../base/plus/ThemePlus";
import {IMenuRef} from "./RawMenu";

export interface IMenuMultiselectItem
{
  label?: string,
  key?: string,
  selected?: boolean
}

export type IMenuMultiselectProps = Record<string, IMenuMultiselectItem>;

export function RawMultiselectMenu(props: {
  menuProps: IMenuMultiselectProps,
  onSelectionChange: (selectedValues: string[]) => void,
  anchor?: Element | null,
  cbRef?: IMenuRef,
  onMenuGone: () => void,
})
{
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const anchorEl = props.anchor;
  const onMenuGone = props.onMenuGone;
  const menuProps = props.menuProps;
  const [open, setOpen] = useState(true);
  const menuList = Object.keys(menuProps);

  useEffect(() =>
  {
    const newSelectedValues: string[] = [];

    for(let i = 0; i < menuList.length; i++)
    {
      const menuItem = menuProps[menuList[i]];
      if(menuItem.selected && typeof menuItem.key === "string")
      {
        newSelectedValues.push(menuItem.key);
      }
    }

    setSelectedValues(newSelectedValues);
  }, []);

  const handleToggle = (value: string) =>
  {
    const menuItem = menuProps[value];
    const currentIndex = selectedValues.indexOf(value);

    const newChecked = [...selectedValues];

    if(currentIndex === -1)
    {
      menuItem.selected = true;
      newChecked.push(value);
    }
    else
    {
      menuItem.selected = false;
      newChecked.splice(currentIndex, 1);
    }

    setSelectedValues(newChecked);

    props.onSelectionChange(newChecked);
  };

  function closeMenu()
  {
    setOpen(false);
    onMenuGone && onMenuGone();
  }

  if(props.cbRef)
  {
    props.cbRef.closeMenu = closeMenu;
  }

  return (
    <Menu
      disableAutoFocusItem={true}
      disableAutoFocus={true}
      disableRestoreFocus={true}
      disableEnforceFocus={true}
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
      sx={{maxHeight: gapStd * 40}}
    >
      {
        menuList.map((option) =>
          {
            const menu = menuProps[option];
            return (<MenuItem
              key={option}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={menu.selected}
                    onChange={() =>
                    {
                      handleToggle(option);
                    }}
                  />
                }
                label={menu.label}
              />
            </MenuItem>);
          }
        )}
    </Menu>);
}
