import {StudioModuleSelection} from "../../../../api/meta/base/dto/StudioModuleSelection";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {updateEntModules} from "../SliceCacheStudioEnt";
import {setEntSelectedModules} from "../SliceCacheStudioEnt";
import {updateEntDetails} from "../SliceCacheStudioEnt";
import {ActionUpdateEntModuleMap} from "../TypesCacheStudioEnt";
import {ActionUpdateEntDetails} from "../TypesCacheStudioEnt";

export default class SrvcCacheStudioEntAbout extends ISrvc
{
  updateEntDetails(payload: ActionUpdateEntDetails)
  {
    dispatchEnt(payload.entId, updateEntDetails(payload));
  }

  updateEntModules(payload: ActionUpdateEntModuleMap)
  {
    dispatchEnt(payload.entId, updateEntModules(payload));
  }

  setEntSelectedModules(entId: EntId, payload: StudioModuleSelection)
  {
    dispatchEnt(entId, setEntSelectedModules({
      entId: entId,
      selectedModulesSet: payload
    }));
  }
}
