import ISrvc from "../../../../base/ISrvc";
import {dispatchPlugin} from "../../SrvcCacheStudio";
import {ActionUpdatePluginModuleMap} from "../SliceCacheStudioPlugin";
import {updatePluginModules} from "../SliceCacheStudioPlugin";
import {updatePluginDetails} from "../SliceCacheStudioPlugin";
import {ActionUpdatePluginDetails} from "../SliceCacheStudioPlugin";

export default class SrvcCacheStudioPluginDetails extends ISrvc
{
  updatePluginDetails(payload: ActionUpdatePluginDetails)
  {
    dispatchPlugin(payload.pluginBundleId, updatePluginDetails(payload));
  }

  updatePluginModules(payload: ActionUpdatePluginModuleMap)
  {
    dispatchPlugin(payload.pluginBundleId, updatePluginModules(payload));
  }
}
