import {nextMetaIdAutomation, nextMetaIdAutomationEvent, nextMetaIdAutomationEventStep} from "../../../../api/meta/base/ApiPlus";
import {StudioEntAutomation} from "../../../../api/meta/base/dto/StudioEntAutomation";
import {StudioEntAutomationEvent} from "../../../../api/meta/base/dto/StudioEntAutomationEvent";
import {StudioEntAutomationScheduled} from "../../../../api/meta/base/dto/StudioEntAutomationScheduled";
import {StudioEntAutomationScheduledEvent} from "../../../../api/meta/base/dto/StudioEntAutomationScheduledEvent";
import {StudioEntAutomationSpreadsheet} from "../../../../api/meta/base/dto/StudioEntAutomationSpreadsheet";
import {StudioEntAutomationSpreadsheetEvent} from "../../../../api/meta/base/dto/StudioEntAutomationSpreadsheetEvent";
import {StudioEntAutomationStep} from "../../../../api/meta/base/dto/StudioEntAutomationStep";
import {EntId, MetaIdAutomation, MetaIdEvent, MetaIdStep} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {dragAutomationStep} from "../SliceCacheStudioEnt";
import {moveAutomationStep} from "../SliceCacheStudioEnt";
import {moveAutomationEvent} from "../SliceCacheStudioEnt";
import {addEntAutomation, addEntAutomationEvent, addEntAutomationEventStep, moveEntItem, removeEntAutomation, removeEntAutomationEvent, removeEntAutomationEventStep} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntAutomations extends ISrvc
{
  addEntAutomation(entId: EntId, automation: StudioEntAutomation)
  {
    dispatchEnt(entId, addEntAutomation({
      entId: entId,
      sig: {
        ...automation,
        metaId: automation.metaId ?? nextMetaIdAutomation()
      }
    }));
  }

  duplicateEntAutomation(entId: EntId, metaIdAutomation: MetaIdAutomation)
  {
    const rootState = store.getState();
    const automationMap = rootState.cache.studio.ent.entMap[entId]?.ent?.automationMap;
    if(!automationMap)
    {
      return;
    }

    const index = automationMap.keys.indexOf(metaIdAutomation);

    if(index === -1)
    {
      return;
    }

    const entAutomation = automationMap.map[metaIdAutomation]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(automationMap.map[metaIdAutomation])))
      : undefined;

    dispatchEnt(entId, addEntAutomation({
      entId: entId,
      sig: entAutomation,
      insertIndex: index + 1
    }));
  }

  removeEntAutomation(entId: EntId, metaIdAutomation: MetaIdAutomation)
  {
    dispatchEnt(entId, removeEntAutomation({
      entId: entId,
      metaId: metaIdAutomation
    }));
  }

  addAutomationEvent(entId: string, metaIdAutomation: MetaIdAutomation, automationEvent: StudioEntAutomationEvent)
  {
    dispatchEnt(entId, addEntAutomationEvent({
      entId: entId,
      automationId: metaIdAutomation,
      sig: {
        ...automationEvent,
        metaId: automationEvent.metaId ?? nextMetaIdAutomationEvent()
      }
    }));
  }

  duplicateEntAutomationEvent(entId: EntId, metaIdAutomation: MetaIdAutomation, metaIdEvent: MetaIdEvent)
  {
    const rootState = store.getState();
    const automationMap = rootState.cache.studio.ent.entMap[entId]?.ent?.automationMap;
    const automation = automationMap.map[metaIdAutomation] as StudioEntAutomationSpreadsheet | StudioEntAutomationScheduled;
    if(!automation)
    {
      return;
    }

    const index = automation.eventMap.keys.indexOf(metaIdEvent);

    if(index === -1)
    {
      return;
    }

    const automationEvent = automation.eventMap.map[metaIdEvent]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(automation.eventMap.map[metaIdEvent])))
      : undefined;

    dispatchEnt(entId, addEntAutomationEvent({
      entId: entId,
      automationId: metaIdAutomation,
      sig: automationEvent,
      insertIndex: index + 1
    }));
  }

  removeAutomationEvent(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    metaIdEvent: MetaIdEvent
  )
  {
    dispatchEnt(entId, removeEntAutomationEvent({
      entId: entId,
      automationId: metaIdAutomation,
      metaId: metaIdEvent
    }));
  }

  addAutomationEventStep(
    entId: string,
    metaIdAutomation: MetaIdAutomation,
    metaIdEvent: MetaIdEvent,
    automationStep: StudioEntAutomationStep
  )
  {
    dispatchEnt(entId, addEntAutomationEventStep({
      entId: entId,
      automationId: metaIdAutomation,
      automationEventId: metaIdEvent,
      sig: {
        ...automationStep,
        metaId: automationStep.metaId ?? nextMetaIdAutomationEventStep()
      }
    }));
  }

  duplicateEntAutomationStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    metaIdEvent: MetaIdEvent,
    metaIdStep: MetaIdStep
  )
  {
    const rootState = store.getState();
    const automationMap = rootState.cache.studio.ent.entMap[entId]?.ent?.automationMap;
    const automation = automationMap.map[metaIdAutomation] as StudioEntAutomationSpreadsheet | StudioEntAutomationScheduled;
    const automationEvent = automation.eventMap.map[metaIdEvent] as StudioEntAutomationSpreadsheetEvent | StudioEntAutomationScheduledEvent;
    if(!automationEvent)
    {
      return;
    }

    const index = automationEvent.stepMap.keys.indexOf(metaIdStep);

    if(index === -1)
    {
      return;
    }

    const automationStep = automationEvent.stepMap.map[metaIdStep] ?
      JSON.parse(updateAllMetaIds(JSON.stringify(automationEvent.stepMap.map[metaIdStep])))
      : undefined;

    dispatchEnt(entId, addEntAutomationEventStep({
      entId: entId,
      automationId: metaIdAutomation,
      automationEventId: metaIdEvent,
      sig: automationStep,
      insertIndex: index + 1
    }));
  }

  removeAutomationEventStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    metaIdEvent: MetaIdEvent,
    metaIdStep: MetaIdStep
  )
  {
    dispatchEnt(entId, removeEntAutomationEventStep({
      entId: entId,
      automationId: metaIdAutomation,
      automationEventId: metaIdEvent,
      metaId: metaIdStep
    }));
  }

  moveAutomation(entId: EntId, metaIdAutomation: MetaIdAutomation, moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId: entId,
      metaId: metaIdAutomation,
      moveDirection: moveDirection,
      setKey: "automationList"
    }));
  }

  moveAutomationEvent(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    moveDirection: EnumListItemDirection,
    metaIdEvent: MetaIdEvent
  )
  {
    dispatchEnt(entId, moveAutomationEvent({
      entId: entId,
      automationId: metaIdAutomation,
      automationEventId: metaIdEvent,
      moveDirection: moveDirection
    }));
  }

  moveAutomationStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    moveDirection: EnumListItemDirection,
    metaIdEvent: MetaIdEvent,
    metaIdStep: MetaIdStep
  )
  {
    dispatchEnt(entId, moveAutomationStep({
      entId: entId,
      automationId: metaIdAutomation,
      automationEventId: metaIdEvent,
      automationStepId: metaIdStep,
      moveDirection: moveDirection
    }));
  }

  dragAutomationStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    fromEventId: MetaIdEvent,
    fromStepIndex: number,
    toEventId: MetaIdEvent,
    toStepIndex: number
  )
  {
    dispatchEnt(entId, dragAutomationStep({
      entId: entId,
      metaIdAutomation: metaIdAutomation,
      fromEventId: fromEventId,
      fromStepIndex: fromStepIndex,
      toEventId: toEventId,
      toStepIndex: toStepIndex
    }));
  }
}
