import {Action} from "redux";
import {SigMessage} from "../../api/home/main/sig/SigMessage";
import {ISig} from "../../api/meta/base/sig/ISig";
import {MessageId} from "../../api/meta/base/Types";
import {ChatId} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {getErrorText} from "../../api/nucleus/base/Protocol";
import {isNotChangedSignal} from "../../api/nucleus/base/Protocol";
import {RootState} from "../../Store";
import {store} from "../../Store";
import {fnCreateListSlice} from "../slices/list/SliceList";
import {getListNameFake} from "../slices/list/SliceList";
import {getListItemIdPrefixSkeleton} from "../slices/list/SliceList";
import {listSetError} from "../slices/list/SliceListSharedActions";
import {ListLayoutType} from "../types/list/TypesList";
import {IListItemsById} from "../types/list/TypesList";
import {TypeListItemId} from "../types/list/TypesList";
import {ListItemType} from "../types/list/TypesList";
import {IListConfig} from "../types/list/TypesList";
import {IList} from "../types/list/TypesList";
import {ListItemTypeAPSA} from "../types/list/TypesListAPSA";
import {isListItemTypeAPSA} from "../types/list/TypesListAPSA";
import {IListItemChat} from "../types/list/TypesListChat";
import {IListItemGroup} from "../types/list/TypesListGroup";
import {ListItemTypeGroup} from "../types/list/TypesListGroup";
import {createBubble} from "./BubblePlus";
import {RPC_RETRY_DELAY} from "./ConstantsPlus";
import {gapQuarter} from "./ThemePlus";
import theme from "./ThemePlus";

export type SelectList = (state: RootState) => IList;

export function dispatchList(list: IList | string, action: Action<string>)
{
  action.type = action.type.replace(getListNameFake(), typeof list === "string" ? list : list.listName);
  return store.dispatch(action);
}

export function listRecursiveCallsWithUserParams<T extends ISig, UP>(
  sig: EnvSignal<T>,
  selectList: SelectList,
  fnRpcCall: () => void,
  fnLoad: (listName: string, sig: T, userParams: UP) => void,
  userParams: UP
)
{
  if(isNotChangedSignal(sig))
  {
    return;
  }

  const errorMsg = getErrorText(sig);
  if(errorMsg)
  {
    const list = selectList(store.getState());
    const loaded = list.loaded;
    if(loaded)
    {
      dispatchList(list.listName, listSetError(errorMsg));
      setTimeout(() => fnRpcCall(), RPC_RETRY_DELAY);
    }
  }
  else
  {
    const list = selectList(store.getState());
    fnLoad(list.listName, sig.sig as T, userParams);
  }
}

export function listRecursiveCalls<T extends ISig>(
  sig: EnvSignal<T>,
  selectList: SelectList,
  fnRpcCall: () => void,
  fnLoad: (listName: string, sig: T) => void
)
{
  listRecursiveCallsWithUserParams<T, unknown>(sig, selectList, fnRpcCall, fnLoad, undefined);
}

export function createListItemChat(entId: EntId, chatId: ChatId, sig: SigMessage): IListItemChat
{
  const type = sig.payload.messageType;
  return {
    type: type,
    entId: entId,
    chatId: chatId,
    sig: createBubble(sig)
  } as IListItemChat;
}

export function collectListChatItems(
  entId: EntId,
  chatId: ChatId,
  messageList: SigMessage[],
  uiItemIds: MessageId[],
  uiItemsById: IListItemsById)
{
  messageList.forEach(sig =>
  {
    uiItemIds.push(sig.messageId);
    uiItemsById[sig.messageId] = createListItemChat(entId, chatId, sig);
  });
}

export function listName(rootState: RootState, selectList: SelectList): string
{
  return selectList(rootState).listName;
}

export function listVersion(rootState: RootState, selectList: SelectList): string | undefined
{
  return selectList(rootState).version;
}

export function isNotListItemIdSkeleton(itemId: TypeListItemId)
{
  return !itemId.startsWith(getListItemIdPrefixSkeleton());
}

export function createListItemIdSkeleton(suffix: string): TypeListItemId
{
  return getListItemIdPrefixSkeleton() + "~~" + suffix;
}

export function createListSlice(name: string, config?: IListConfig)
{
  if(name === getListNameFake())
  {
    throw new Error(`List name, ${name}, not allowed`);
  }
  else
  {
    return fnCreateListSlice(name, config);
  }
}

export function getChatItem(rootState: RootState, messageId: MessageId, selectList: SelectList)
{
  return selectList(rootState).itemsById[messageId] as IListItemChat | undefined;
}

export function createListItemGroup(prefix: string): IListItemGroup
{
  return {
    type: "listGroup",
    hideMenu: true,
    ignoreSelection: true,
    header: {
      text: prefix,
      expand: true,
      mt: gapQuarter,
      mb: gapQuarter
    }
  };
}

export function getListItemHeightGroup(listItemType: ListItemTypeGroup): number
{
  const gapHalf = theme.common.gapHalf;
  const heightLine = theme.common.heightLine;

  switch(listItemType)
  {
    case "listGroup":
      return gapHalf + heightLine + gapHalf;
  }
}

export function getListItemHeightAPSA(listItemType: ListItemTypeAPSA): number
{
  const gapHalf = theme.common.gapHalf;
  const gapStd = theme.common.gapStd;
  const sizeAvatar = theme.common.sizeAvatar;
  const heightLine = theme.common.heightLine;

  switch(listItemType)
  {
    case "p":
      return gapHalf + heightLine + gapHalf;
    case "ps":
      return gapHalf + sizeAvatar + gapHalf;
    case "ap":
      return gapHalf + sizeAvatar + gapHalf;
    case "aps":
      return gapHalf + sizeAvatar + gapHalf;
    case "apsa":
      return gapHalf + sizeAvatar + gapHalf;
    case "p3":
      return gapHalf + heightLine + gapHalf;
    case "p3ss":
      return gapHalf + heightLine + heightLine + heightLine + gapHalf;
    case "mpsl":
      return gapStd + heightLine + heightLine + heightLine + heightLine + heightLine + gapStd;
  }
}

export function getListItemHeightMPSL(
  secondaryCount: number,
  withMedia: boolean,
  layout: ListLayoutType = "list"): number
{
  const gapHalf = theme.common.gapHalf;
  const heightLine = theme.common.heightLine;
  let height = gapHalf + (secondaryCount * heightLine) + heightLine + gapHalf;
  if(layout === "list" && withMedia)
  {
    height = gapHalf + (heightLine * Math.max(secondaryCount, 5)) + heightLine + gapHalf;
  }
  if(layout === "card")
  {
    const gapStd = theme.common.gapStd;
    height += gapStd;
  }

  return height;

}

export function getListItemHeight(listItemType?: ListItemType)
{
  return listItemType && isListItemTypeAPSA(listItemType)
    ? getListItemHeightAPSA(listItemType as ListItemTypeAPSA)
    : undefined;
}
