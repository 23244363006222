import React from "react";
import {DtoMessagePayloadReport} from "../../../../api/home/base/dto/DtoMessagePayloadReport";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {SigUserAvatar} from "../../../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRowCommentCount} from "../../../../api/home/main/sig/SigSpreadsheetRowCommentCount";
import {DefnDtoParagraph} from "../../../../api/meta/base/dto/DefnDtoParagraph";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {EntUserId} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getReportName} from "../../../../base/plus/FormPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import {RootState} from "../../../../Store";
import BubbleRawForm from "../raw/bubbleForm/BubbleRawForm";

export default function BubbleReport(props: {
  payload: DtoMessagePayloadReport,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  rowCommentCount?: SigSpreadsheetRowCommentCount;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  header?: IBubbleHeader,
  isStar?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  showComments?: boolean,
  defnForm?: DefnForm,
  shakeFieldIdMap?: Record<MetaIdField, number>;
  blinkFieldIdMap?: Record<MetaIdField, number>;
  hiddenFieldIdMap?: Record<MetaIdField, boolean>;
  highlightFieldIdMap?: Record<MetaIdField, number>;
  chatPatternVar?: DefnDtoParagraph;
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickFooterComments?: CbMenuAnchor,
  cbOnClickFooter?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
})
{
  return (
    <BubbleRawForm
      {...props}
      formValue={props.payload.formValueRaw}
      title={getReportName(props.payload)}
    />
  );
}
