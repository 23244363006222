import _ from "lodash";
import {nextMetaIdGroup} from "../../../../api/meta/base/ApiPlus";
import {StudioEntGroup} from "../../../../api/meta/base/dto/StudioEntGroup";
import {EntId, MetaIdGroup} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntGroup, moveEntItem, removeEntGroup} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntGroups extends ISrvc
{
  addEntGroup(entId: EntId, group: StudioEntGroup)
  {
    dispatchEnt(entId, addEntGroup({
      entId: entId,
      sig: {
        ...group,
        metaId: group.metaId || nextMetaIdGroup()
      }
    }));
  }

  removeEntGroup(entId: EntId, metaIdGroup: MetaIdGroup)
  {
    dispatchEnt(entId, removeEntGroup({
      entId: entId,
      metaId: metaIdGroup
    }));
  }

  copyEntGroup(entId: EntId, metaIdGroup: MetaIdGroup)
  {
    const rootState = store.getState();
    const groupMap = selectCacheStudioEnt(rootState, entId)?.ent?.groupMap;
    const group = groupMap?.map[metaIdGroup];

    if(!_.isEmpty(group))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "group",
        payload: group
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntGroup(entId: EntId, metaIdGroup: MetaIdGroup)
  {
    const rootState = store.getState();
    const groupMap = rootState.cache.studio.ent.entMap[entId].ent.groupMap;
    if(!groupMap)
    {
      return;
    }

    const index = groupMap.keys.indexOf(metaIdGroup);
    if(index === -1)
    {
      return;
    }

    const entGroup = groupMap.map[metaIdGroup]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(groupMap.map[metaIdGroup])))
      : undefined;

    if(entGroup)
    {
      dispatchEnt(entId, addEntGroup({
        entId: entId,
        sig: entGroup,
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdGroup,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "groupList",
      moveDirection
    }));
  }
}
