import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {WsocEntDrawer} from "../../../api/ent/entDrawer/WsocEntDrawer";
import {MsgCallerNotificationSettingPut} from "../../../api/home/drawer/msg/MsgCallerNotificationSettingPut";
import {RpcDrawer} from "../../../api/home/drawer/RpcDrawer";
import {FieldDtoLocation} from "../../../api/meta/base/dto/FieldDtoLocation";
import {StoreItemId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {MsgStoreItemId} from "../../../api/store/store/msg/MsgStoreItemId";
import {WsocStore} from "../../../api/store/store/WsocStore";
import {WsocStudioDrawer} from "../../../api/studio/studioDrawer/WsocStudioDrawer";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import ISrvc from "../../../base/ISrvc";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import {setStoreItemAvatarAdmin} from "./SliceCacheCaller";
import {setCallerGeoLocation} from "./SliceCacheCaller";
import {setPluginAvatarAdmin} from "./SliceCacheCaller";
import {setEntAvatarAdmin} from "./SliceCacheCaller";
import {setEntAvatarUser} from "./SliceCacheCaller";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export default class SrvcCacheCaller extends ISrvc
{
  private readonly subId = "SrvcCacheCaller";

  rpcEntUserExit(entId: EntId)
  {
    RpcStudioMain.entUserExit(entId, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        Srvc.app.pubsub.caller.entAvatarUser(this.subId, entId, true);
      }
    });
  }

  rpcCallerNotificationSettingPut(entId: EntId, msg: MsgCallerNotificationSettingPut)
  {
    RpcDrawer.callerNotificationSettingPut(entId, msg, envSig =>
    {
    });
  }

  wsocEntAvatarUserGet(entId: EntId)
  {
    const rootState = store.getState();
    const caller = rootState.cache.app.caller;
    if(!caller.entIdUserSet.includes(entId))
    {
      return;
    }

    const msg: MsgVersion = {};

    let currAvatar = caller.entIdUserAvatarMap[entId];
    if(currAvatar)
    {
      msg.version = currAvatar.version;
    }

    WsocEntDrawer.entAvatarUserGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setEntAvatarUser(sig));
      }
    });
  }

  wsocEntAvatarAdminGet(entId: EntId)
  {
    const rootState = store.getState();
    const caller = rootState.cache.app.caller;
    if(!caller.entIdAdminSet.includes(entId))
    {
      return;
    }

    const msg: MsgVersion = {
      version: undefined
    };

    let currAvatar = caller.entIdAdminAvatarMap[entId];
    if(currAvatar)
    {
      msg.version = currAvatar.version;
    }

    WsocStudioDrawer.studioEntAvatarGet(entId, msg, envSig =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setEntAvatarAdmin(sig));
      }
    });
  }

  wsocPluginAvatarAdminGet(pluginBundleId: PluginBundleId)
  {
    const rootState = store.getState();
    const caller = rootState.cache.app.caller;
    if(!caller.pluginIdAdminSet.includes(pluginBundleId))
    {
      return;
    }

    const msg: MsgVersion = {};
    let currAvatar = caller.pluginIdAdminAvatarMap[pluginBundleId];
    if(currAvatar)
    {
      msg.version = currAvatar.version;
    }

    WsocStudioDrawer.studioPluginAvatarGet(pluginBundleId, msg, envSig =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setPluginAvatarAdmin(sig));
      }
    });
  }

  wsocStoreItemAvatarGet(storeItemId: StoreItemId)
  {
    const rootState = store.getState();
    const caller = rootState.cache.app.caller;

    const msg = {
      storeItemId: storeItemId
    } as MsgStoreItemId;

    let currAvatar = caller.storeItemIdAdminAvatarMap[storeItemId];
    if(currAvatar)
    {
      msg.version = currAvatar.version;
    }

    WsocStore.storeItemAvatarGet(msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        store.dispatch(setStoreItemAvatarAdmin(sig));
      }
    });
  }

  setCallerGeoLocation(sig: FieldDtoLocation)
  {
    store.dispatch(setCallerGeoLocation(sig));
  }
}
