import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarMapPinShape} from "../../../api/meta/base/dto/StudioVarMapPinShape";

export function fnVariableBuilderMapPinShape(fieldValueMapPinShape: string)
{
  function getVarComp()
  {
    return {
      [fieldValueMapPinShape]: {
        type: "enumMapPinShape",
        metaId: fieldValueMapPinShape,
        label: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarMapPinShape
  )
  {
    valueMap[fieldValueMapPinShape] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueMapPinShape];

    return {
      value: value
    } as StudioVarMapPinShape;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
