import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {FormControl} from "@mui/material";
import {isEmpty} from "lodash";
import {useState} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoColor} from "../../../../api/meta/base/dto/DefnDtoColor";
import {DefnFieldInfo} from "../../../../api/meta/base/dto/DefnFieldInfo";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {EnumDefnTextSize} from "../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../api/meta/base/Types";
import {EnumDefnThemeDividerKind} from "../../../../api/meta/base/Types";
import {fieldReportLeftLabel} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawMarkDown from "../../../atom/raw/RawMarkDown";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldBase from "../raw/FieldBase";
import FieldRawInfo from "../raw/FieldRawInfo";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";

export default function FieldInfo(props: {
  defn: DefnFieldInfo,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const isReport = defnTheme.formVariant === "report";
  const flexGrow = defn.flexGrow ? 1 : undefined;

  const fieldVariant = defnTheme.fieldVariant;
  const showCloseButton = defn.showCloseButton;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      defaultValue={{
        value: defn.defaultVar?.value?.join("")
      } as FieldValueText}
      render={({
        field,
        fieldState
      }) =>
      {

        const {
          isTouched,
          error
        } = fieldState;
        const fieldValue = field.value as FieldValueText | undefined;
        const isError = isTouched && Boolean(error);
        const showLabel = defn.showLabel;
        const onClickClose = (value: boolean) =>
        {
          if(value)
          {
            field.onChange(null);
          }
        };

        if(isReport && defn.showLabel)
        {
          return (
            <FieldBase
              fieldId={fieldId}
              flexGrow={flexGrow}
            >
              <FieldRawKeyValuePair
                left={
                  <RawHighlighter
                    variant={"caption"}
                    value={fieldReportLeftLabel(defn)}
                  />
                }
                right={
                  <RealFieldInfo
                    fieldValue={fieldValue}
                    onClickClose={onClickClose}
                    defn={defn}
                    isReport={isReport}
                  />
                }
              />
            </FieldBase>
          );
        }

        return (
          <FieldBase
            fieldId={fieldId}
            flexGrow={(showCloseButton) ? fieldValue ? flexGrow : undefined : flexGrow}
            mt={!fieldValue
              ? `-${px(gapStd + gapHalf)} !important`
              : undefined}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
              sx={{
                flexGrow: flexGrow,
                height: "100%"
              }}
            >
              {showLabel && fieldValue ?
                <>
                  <Box pb={px(gapQuarter)}>
                    <RawHighlighter
                      variant={"body1"}
                      value={getLabel(defn)}
                    />
                  </Box>
                  <RealFieldInfo
                    fieldValue={fieldValue}
                    onClickClose={onClickClose}
                    defn={defn}
                    isReport={isReport}
                  />
                </>
                :
                <RealFieldInfo
                  fieldValue={fieldValue}
                  onClickClose={onClickClose}
                  defn={defn}
                  isReport={isReport}
                />
              }
            </FormControl>
          </FieldBase>
        );
      }}
    />
  );
}

function RealFieldInfo(props: {
  defn: DefnFieldInfo,
  fieldValue?: FieldValueText,
  isReport: boolean,
  onClickClose: (value: boolean) => void
})
{
  const theme = useTheme();
  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const onClickClose = props.onClickClose;

  const isReport = props.isReport;

  const [isVisible, setIsVisible] = useState<boolean>(true);

  const {
    getJustifyText,
    getFieldLineCount,
    getFieldTextSize
  } = useFieldPropertiesResolver(defn);

  const showCloseButton = defn.showCloseButton;
  const flexGrow = defn.flexGrow ? 1 : undefined;
  const showBorder = defn.showBorder;
  const bgColorVarId = defn.bgColor ?? defn.bgColorVar;
  const textSize = getFieldTextSize();
  const lineCount = getFieldLineCount();
  const justifyText = getJustifyText();
  const pt = getInfoFieldPadding(defn.topPaddingVar ?? defn.topPadding) || defn.pt;
  const pb = getInfoFieldPadding(defn.bottomPaddingVar ?? defn.bottomPadding) || defn.pb;
  const pl = getInfoFieldPadding(defn.leftPaddingVar ?? defn.leftPadding) || defn.pl;
  const pr = getInfoFieldPadding(defn.rightPaddingVar ?? defn.rightPadding) || defn.pr;

  const colorVar = defn.colorVar
    ? (defn.colorVar.value && defn.colorVar.shade
      ? theme.common.colorWithShade(defn.colorVar.value, defn.colorVar.shade)
      : (defn.colorVar.value
        ? theme.common.color(defn.colorVar.value)
        : undefined))
    : undefined;

  const label = (!isReport && defn.showLabel)
    ? defn.label
    : undefined;

  if(!isVisible || ((!label || isEmpty(label)) && (!fieldValue?.value || isEmpty(fieldValue.value))))
  {
    return null;
  }

  return (
    <FieldRawInfo
      showBorder={showBorder}
      flexGrow={flexGrow}
      bgColorVarId={bgColorVarId as DefnDtoColor}
      showCloseButton={showCloseButton}
      onClickClose={() =>
      {
        setIsVisible(false);
        onClickClose(true);
      }}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexGrow={1}
        pt={pt}
        pb={pb}
        pl={pl}
        pr={pr}
      >
        {
          fieldValue && fieldValue.value &&
          <RawMarkDown
            value={fieldValue.value}
            variant={textSize as EnumDefnTextSize}
            color={colorVar}
            noWrap={true}
            textAlign={justifyText as EnumDefnPlacement}
            flexGrow={1}
            width={justifyText ? "100%" : undefined}
            isUserSelect={true}
            includeColor={true}
            includeNewLine={true}
            breakWords={true}
            maxNumberOfLine={!flexGrow ? lineCount as number : undefined}
          />
        }
      </Box>
    </FieldRawInfo>
  );
}

function getInfoFieldPadding(enumDefnThemeDividerKind?: EnumDefnThemeDividerKind)
{
  return enumDefnThemeDividerKind
    ? enumDefnThemeDividerKind === "thick"
      ? px(gapHalf)
      : px(theme.common.sizeDivider)
    : undefined;
}

function getPadding(enumDefnThemeDividerKind?: EnumDefnThemeDividerKind, padding?: number)
{
  return enumDefnThemeDividerKind
    ? enumDefnThemeDividerKind === "thick"
      ? padding ? gapHalf + padding : gapHalf
      : padding ? theme.common.vertInner + padding : gapHalf
    : undefined;
}
