import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Skeleton} from "@mui/material";
import {Property} from "csstype";
import {useState} from "react";
import {useEffect} from "react";
import React from "react";
import {px} from "../../../base/plus/StringPlus";

export default function RawSkeleton(props: {
  variant: "text" | "rectangular" | "rounded" | "circular",
  width: string,
  height: string,
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  durationShowProgressAfter?: number,
  borderRadius?: Property.BorderRadius,
})
{
  const theme = useTheme();
  const [display, setDisplay] = useState(false);
  const durationShowProgressAfter = props.durationShowProgressAfter
    ? props.durationShowProgressAfter
    : 0;

  useEffect(() =>
  {
    const timeout = setTimeout(() =>
    {
      setDisplay(true);
    }, durationShowProgressAfter);

    return () => clearTimeout(timeout);
  }, []);

  if(display)
  {
    return (
      <Skeleton
        variant={props.variant}
        width={props.width}
        height={props.height}
        sx={{
          mt: px(props.mt),
          mb: px(props.mb),
          ml: px(props.ml),
          mr: px(props.mr),
          bgcolor: theme.common.bgcolorActionBar,
          borderRadius: props.borderRadius
        }}
      />
    );
  }
  else
  {
    return (
      <Box
        sx={{
          overflow: "hidden",
          width: props.width,
          height: props.height,
          mt: px(props.mt),
          mb: px(props.mb),
          ml: px(props.ml),
          mr: px(props.mr),
          borderRadius: props.borderRadius
        }}
      />
    );
  }
}
