import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickText} from "../../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldShowCode} from "../../../../../api/meta/base/dto/StudioFieldShowCode";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {EnumArrayDefnCodeType} from "../../../../../api/meta/base/Types";
import {EnumDefnCodeType} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {arrayToOptions} from "../../../../../base/plus/JsPlus";
import {optionsToMapOfOption} from "../../../../../base/plus/JsPlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyDefaultValueTextVar} from "../../base/TypesFormBuilder";
import {propKeyShowLabelFieldId} from "../../base/TypesFormBuilder";
import {propKeyShowLabelVarId} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyShowLabel} from "../../base/TypesFormBuilder";
import {propKeyShowCode} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

export function getDefnFieldShowCode(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyShowLabelFieldId,
    fieldGap1,
    propKeyDefaultFieldId
  ];

  const varIds = [
    propKeyShowLabelVarId,
    fieldGap1,
    propKeyDefaultValueTextVar
  ];

  const constIds = [
    propKeyShowLabel,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyShowCode
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "showCode",
      formId,
      "paragraph",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),
    [propKeyDefaultFieldId]: {
      type: "pickFieldId",
      metaId: propKeyDefaultFieldId,
      name: propKeyDefaultFieldId,
      label: "Default value",
      formId: formId,
      filterFieldTypeSet: ["text", "paragraph"],
      compositeIdSet: sectionIdSetWithCurrentGridId,
      showCompositeName: true,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined
    } as DefnStudioPickFieldId,

    [propKeyShowLabel]: {
      type: "bool",
      metaId: "showLabel",
      name: "Show label",
      label: "Show label",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowLabelVarId]: {
      type: "pickVarId",
      metaId: propKeyShowLabelVarId,
      name: propKeyShowLabelVarId,
      label: "Show label",
      formId: formId,
      varKind: "bool",
      showAsEdit: true
    } as DefnStudioPickVarId,

    [propKeyShowLabelFieldId]: {
      type: "pickFieldId",
      metaId: propKeyShowLabelFieldId,
      name: propKeyShowLabelFieldId,
      label: "Show label",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["bool"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyShowCode]: {
      type: "pickText",
      metaId: propKeyShowCode,
      name: propKeyShowCode,
      label: propKeyShowCode,
      showAs: "radioButtonVertical",
      hideDirtyIndicator: true,
      optionMap: optionsToMapOfOption(arrayToOptions(EnumArrayDefnCodeType))
    } as DefnFieldPickText
  };
}

export function defnValueToStudioFieldShowCode(values: FieldValues): StudioFieldShowCode
{
  const showCode = fnFieldValueToRawValue("pickText", values[propKeyShowCode]) as EnumDefnCodeType;

  return {
    type: "showCode",
    defaultValue: fnFieldValueToRawValue("paragraph", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[propKeyDefaultValueTextVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    showLabel: fnFieldValueToRawValue("bool", values[propKeyShowLabel]),
    showLabelVarId: fnFieldValueToRawValue("pickVarId", values[propKeyShowLabelVarId]),
    showLabelFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyShowLabelFieldId]),
    codeType: showCode
  } as StudioFieldShowCode;
}

export function studioFieldShowCodeToDefnValue(studioField: StudioFieldShowCode): FieldValues
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("paragraph", studioField.defaultValue),
    [propKeyDefaultValueTextVar]: fnRawValueToFieldValue("studioVarIdTextEditor", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyShowLabel]: fnRawValueToFieldValue("bool", studioField.showLabel),
    [propKeyShowLabelVarId]: fnRawValueToFieldValue("pickVarId", studioField.showLabelVarId),
    [propKeyShowLabelFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.showLabelFieldId),
    [propKeyShowCode]: fnRawValueToFieldValue("pickText", studioField.codeType)
  };
}
