import Editor from "@monaco-editor/react";
import {Monaco} from "@monaco-editor/react";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {blue} from "@mui/material/colors";
import {red} from "@mui/material/colors";
import {grey} from "@mui/material/colors";
import {editor} from "monaco-editor";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import theme from "../../../base/plus/ThemePlus";
import RawStyledEditor from "./RawStyledEditor";

const defaultMinHeight = 200;

export interface IEditorRef
{
  setValue: (value: string) => void;
}

export default function RawCodeEditor(props: {
  cbRef?: IEditorRef,
  isError?: boolean,
  value?: string,
  defaultLanguage?: string,
  editorTheme?: string,
  onChange?: (newValue?: string) => void,
  readonly?: boolean,
  enableMiniMap?: boolean,
  enableLineNumbers?: boolean,
  dragAndDrop?: boolean,
  folding?: boolean,
  onMount?: () => void,
  minHeight?: number,
  formatOnPaste?: boolean,
  formatOnType?: boolean,
  hideBorder?: boolean,
  language?: string,
  className?: string,
  styled?: boolean
})
{
  const value = props.value;
  const isError = props.isError;
  const cbRef = props.cbRef;
  const onChange = props.onChange;
  const onMount = props.onMount;
  const formatOnType = props.formatOnType;
  const formatOnPaste = props.formatOnPaste;
  const minHeight = props.minHeight;
  const defaultLanguage = props.defaultLanguage || "json";
  const editorTheme = props.editorTheme || "neo";
  const lineNumbers = props.enableLineNumbers ? "on" : "off";
  const folding = props.folding ?? false;
  const hideBorder = props.hideBorder;
  const language = props.language;
  const className = props.className;

  const theme = useTheme();
  const borderColor = isError ? theme.common.color("error") : theme.palette.divider;

  const options: editor.IStandaloneEditorConstructionOptions = {
    value: value ?? "",
    readOnly: Boolean(props.readonly),
    minimap: {enabled: Boolean(props.enableMiniMap)},
    renderLineHighlight: "none",
    scrollBeyondLastLine: false,
    fontSize: 14,
    dragAndDrop: Boolean(props.dragAndDrop),
    automaticLayout: true,
    formatOnPaste: Boolean(formatOnPaste),
    formatOnType: Boolean(formatOnType),
    scrollbar: {
      verticalScrollbarSize: 8,
      horizontalScrollbarSize: 8
    },
    lineNumbers: lineNumbers,
    wordWrap: "on",
    parameterHints: {
      enabled: false
    },
    suggestOnTriggerCharacters: false,
    quickSuggestions: {
      other: false,
      comments: false,
      strings: false
    },
    lineDecorationsWidth: 6,
    folding: folding,
    padding: {top: 6}
  };

  return (
    <Box
      sx={{
        flex: "1",
        width: "100%",
        height: "100%",
        border: hideBorder ? 0 : `1px solid ${borderColor}`,
        minHeight: px(minHeight ?? defaultMinHeight),
        "& *": {
          userSelect: "text",
          WebkitUserSelect: "text"
        }
      }}
    >
      {
        props.styled
          ? <RawStyledEditor
            value={value ?? ""}
            onChange={onChange}
            options={options}
            theme={editorTheme}
            language={language}
            className={className}
            defaultLanguage={defaultLanguage}
            onMount={(editor, monaco) =>
            {
              if(cbRef)
              {
                cbRef.setValue = text =>
                {
                  editor.setValue(text);
                };
              }
              if(editorTheme === "neo")
              {
                setupEditor(editor, monaco);
              }
              onMount && onMount();
            }}
          />
          : <Editor
            height={"100%"}
            defaultLanguage={defaultLanguage}
            value={value ?? ""}
            onChange={onChange}
            options={options}
            theme={editorTheme}
            onMount={(editor, monaco) =>
            {
              onMount && onMount();
              if(cbRef)
              {
                cbRef.setValue = text =>
                {
                  editor.setValue(text);
                };
              }
              if(editorTheme === "neo")
              {
                setupEditor(editor, monaco);
              }
            }}
          />

      }
    </Box>
  );
}

function setupEditor(editor: editor.IStandaloneCodeEditor, monaco: Monaco)
{
  monaco.editor.defineTheme("neo", neoTheme);
  monaco.editor.setTheme("neo");
  // setTimeout(() =>
  // {
  //   editor.getAction("editor.action.formatDocument").run();
  // }, 1000);
}

//https://microsoft.github.io/monaco-editor/api/modules/monaco.editor.html
//https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-exposed-colors
//https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette.primary
const neoTheme: editor.IStandaloneThemeData = {
  base: "vs",
  inherit: false,
  rules: [
    {
      token: "delimiter",
      foreground: grey[500]
    },
    {
      token: "string.key.json",
      foreground: red[700]
    },
    {
      token: "string.optionId.json",
      foreground: blue[700]
    }
  ],
  colors: {
    "editorCursor.foreground": red[500],
    "editor.lineHighlightBackground": grey[50],
    "editorLineNumber.foreground": grey[300],
    "editor.selectionBackground": theme.common.bgcolorHighlight,
    "editor.findMatchBackground": theme.common.bgcolorHighlight,
    "editor.findMatchHighlightBackground": theme.common.bgcolorHighlight
  }
};
