import {Box} from "@mui/material";
import {useEffect} from "react";
import {useCallback} from "react";
import React from "react";
import {META_ID_OPTION_ORPHAN} from "../../../../api/meta/base/ApiPlus";
import {isOrphanOptionId} from "../../../../api/meta/base/ApiPlus";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnDtoSwimlane} from "../../../../api/meta/base/dto/DefnDtoSwimlane";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnLayoutKanban} from "../../../../api/meta/base/dto/DefnLayoutKanban";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {FieldDtoGridRow} from "../../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {FieldValueOptionId} from "../../../../api/meta/base/dto/FieldValueOptionId";
import {MetaIdComp} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {formatDetailedDateTime} from "../../../../base/plus/DatePlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {hasValues} from "../../../../base/plus/JsPlus";
import {SelectKanban} from "../../../../base/plus/KanbanPlus";
import {fnGetKanbanFieldValueAsText} from "../../../../base/plus/KanbanPlus";
import {dispatchKanban} from "../../../../base/plus/KanbanPlus";
import {random} from "../../../../base/plus/StringPlus";
import {kanbanSetUiModeMobile} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanSetShowMenu} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanRefresh} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {TypeKanbanItemId} from "../../../../base/types/TypeKanban";
import {CbOnClickKanbanVariant} from "../../../../base/types/TypeKanban";
import {CbOnClickKanban} from "../../../../base/types/TypeKanban";
import {IKanbanItemCol} from "../../../../base/types/TypeKanban";
import {IKanbanItem} from "../../../../base/types/TypeKanban";
import {IKanbanColMap} from "../../../../base/types/TypeKanban";
import {TypeKanbanColId} from "../../../../base/types/TypeKanban";
import {IKanbanData} from "../../../../base/types/TypeKanban";
import {IKanbanItemById} from "../../../../base/types/TypeKanban";
import {IKanbanColumnsByItemIds} from "../../../../base/types/TypeKanban";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {useAppSelector} from "../../../app/AppHooks";
import {useAppCtx} from "../../../ctx/CtxApp";
import Kanban from "../../../kanban/Kanban";
import {useFormCtx} from "../base/CtxForm";
import {IFieldGridRawProps} from "../composite/FieldGrid";

export default function FieldRawFieldGridKanban(props: IFieldGridRawProps)
{
  const layout = props.layout;
  const defnForm = props.defnForm;
  const defnGrid = props.defnGrid;
  const fieldValueGridKanban = props.fieldValue;
  const formCtx = useFormCtx();

  const defnTheme = formCtx.getDefnTheme();
  const getOnClick = formCtx.getOnClick();
  const fieldId = getFieldKey(defnGrid);

  const fieldValue = fieldValueGridKanban as FieldValueGrid;
  const isReport = defnTheme.formVariant === "report";
  const showMenu = Boolean(isReport && defnGrid?.rowActionPermissionMap?.keys.length);

  const selectKanban = formCtx.getSelectKanban(fieldId);
  const kanbanName = useAppSelector(state => selectKanban ? selectKanban(state).kanbanName : undefined);

  const kanbanLayout = layout?.kind === "kanban"
    ? layout as DefnLayoutKanban
    : undefined;

  const onClickKanban: CbOnClickKanban = useCallback((
    menuAnchor,
    variant: CbOnClickKanbanVariant,
    itemId: TypeKanbanItemId) =>
  {
    switch(variant)
    {
      case "bubbleMenu":
        getOnClick && getOnClick(fieldId, "fieldGridRowAction", fieldValueGridKanban?.map[itemId], menuAnchor);
        break;
    }
  }, [getOnClick]);

  useEffect(() =>
  {
    if(kanbanName)
    {
      doLoadKanban(kanbanName, fieldValue, defnForm, kanbanLayout);
    }
  }, [kanbanName, fieldValue, defnForm, layout]);

  useEffect(() =>
  {
    if(kanbanName && showMenu)
    {
      dispatchKanban(kanbanName, kanbanSetShowMenu(true));
    }
  }, [kanbanName, showMenu]);

  return selectKanban
    ? <RealGridKanban
      selectKanban={selectKanban}
      cbOnClick={onClickKanban}
    />
    : null;
}

function RealGridKanban(props: {
  selectKanban: SelectKanban,
  cbOnClick?: CbOnClickKanban
})
{
  const appCtx = useAppCtx();
  const isMobile = appCtx.isMobile();

  const selectKanban = props.selectKanban;
  const onClick = props.cbOnClick;

  const kanbanName = useAppSelector(state => selectKanban(state).kanbanName);

  useEffect(() =>
  {
    dispatchKanban(kanbanName, kanbanSetUiModeMobile(isMobile));
  }, [isMobile, kanbanName]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
    >
      <Kanban
        selectKanban={selectKanban}
        cbOnClick={onClick}
      />
    </Box>
  );
}

function doLoadKanban(
  kanbanName: string,
  fieldValueGrid: FieldValueGrid,
  defnForm: DefnFormUi,
  kanbanLayout?: DefnLayoutKanban
)
{
  const kanbanFieldId = kanbanLayout?.kanbanFieldId;
  const showCommentCount = kanbanLayout?.showCommentCount;
  const showFieldIdSet = kanbanLayout?.showFieldIdSet;

  // kanban map
  const groupByRowIdSetMap = {} as Record<MetaIdOption, RowId[]>;
  const formValueMap = {} as Record<RowId, FieldDtoGridRow>;

  //kanban data
  const compMap = defnForm?.compMap;
  const columnIdList = [] as TypeKanbanColId[];
  const columnMap = {} as IKanbanColMap;

  const itemsById = {} as IKanbanItemById;
  const columnsByKanbanValue = {} as IKanbanColumnsByItemIds;

  const kanbanDefnForm = getKanbanDefnForm(defnForm, showFieldIdSet);
  const kanbanField = kanbanFieldId ? compMap[kanbanFieldId] as DefnFieldPickText : undefined;

  if(fieldValueGrid?.keys?.length && kanbanFieldId)
  {
    fieldValueGrid?.keys.forEach((key) =>
    {
      const gridRowValue = fieldValueGrid.map[key];
      const valueMap = gridRowValue.valueMap;
      const option = valueMap
        ? valueMap[kanbanFieldId] as FieldValueOptionId
        : undefined;

      const optionId = option?.optionId;
      const rowId = gridRowValue.rowId;
      formValueMap[rowId] = gridRowValue;

      if(optionId)
      {
        const isRowIdInMap = groupByRowIdSetMap[optionId] ?? [];

        groupByRowIdSetMap[optionId] = [rowId, ...isRowIdInMap];
      }
      else
      {
        const isRowIdInMap = groupByRowIdSetMap[META_ID_OPTION_ORPHAN] ?? [];

        groupByRowIdSetMap[META_ID_OPTION_ORPHAN] = [rowId, ...isRowIdInMap];
      }
    });
  }

  kanbanField?.optionMap?.keys.forEach(optionId =>
  {
    columnsByKanbanValue[optionId] = {itemIds: []};
    columnMap[optionId] = getKanbanColumnItem(optionId, kanbanField, kanbanLayout);
  });

  if(groupByRowIdSetMap)
  {
    Object.entries(groupByRowIdSetMap).forEach(([kanbanValue, rowIdList]) =>
    {
      let colId = !isOrphanOptionId(kanbanValue)
        ? kanbanValue
        : META_ID_OPTION_ORPHAN;

      rowIdList.forEach(rowId =>
      {
        const createdOn = formValueMap[rowId]?.createdOn;
        itemsById[rowId] = {
          creationTime: createdOn ? formatDetailedDateTime(new Date(createdOn)) : "",
          version: random(),
          formValue: formValueMap ? formValueMap[rowId] : undefined
        } as IKanbanItem;
      });

      columnsByKanbanValue[colId] = {itemIds: rowIdList};

      columnMap[colId] = getKanbanColumnItem(kanbanValue, kanbanField, kanbanLayout);
    });

    columnIdList.push(...Object.keys(columnsByKanbanValue) as TypeKanbanColId[]);

    const kanbanData: IKanbanData = {
      defnForm: kanbanDefnForm,
      columnIdList: columnIdList,
      columnsByItemIds: columnsByKanbanValue,
      columnMap: columnMap,
      itemsById: itemsById,
      showCommentCount: showCommentCount,
      hideItemTitle: true,
      hideItemSectionName: !kanbanLayout?.showSectionName,
      hideItemFooter: !kanbanLayout?.showFooter,
      readonly: true,
      userField: {
        defnForm: kanbanDefnForm,
        firstRender: true,
        field: kanbanField
      },
      error: kanbanField ? undefined : "Kanban field not found",
      version: random()
    };

    dispatchKanban(kanbanName, kanbanRefresh(kanbanData));
  }
}

//TODO:Akash getKanbanColumnItem function duplicate with SrvcSsEditorKanban
function getKanbanColumnItem(
  optionId: MetaIdOption,
  kanbanField?: DefnFieldPickText,
  kanbanLayout?: DefnLayoutKanban
)
{
  let colId = !isOrphanOptionId(optionId)
    ? optionId
    : META_ID_OPTION_ORPHAN;

  const swimlaneMap = kanbanLayout?.swimlaneMap;
  const swimLaneOptionMap = {} as Record<MetaIdOption, DefnDtoSwimlane>;
  swimlaneMap?.keys?.forEach(key =>
  {
    const value = swimlaneMap.map[key];
    swimLaneOptionMap[value.valueOptionId || META_ID_OPTION_ORPHAN] = value;
  });
  const color = hasValues(swimLaneOptionMap[colId]?.color) ? swimLaneOptionMap[colId]?.color :
    swimLaneOptionMap[colId]?.colorVar;
  const label = swimLaneOptionMap[colId]?.label;

  return {
    color: kanbanField?.optionMap?.map[optionId]?.color,
    bgcolor: color,
    variant: kanbanLayout?.textSize,
    value: optionId,
    title: label || fnGetKanbanFieldValueAsText(kanbanField, optionId)
  } as IKanbanItemCol;
}

function getKanbanDefnForm(defnForm: DefnForm, showKanbanField?: MetaIdField[]): DefnForm | undefined
{
  if(!defnForm)
  {
    return undefined;
  }
  const newCompMap = {} as Record<MetaIdComp, DefnComp>;
  const compMap = defnForm.compMap;
  const defaultSection = "defaultKanbanSection";

  showKanbanField?.forEach((fieldId) =>
  {
    newCompMap[fieldId] = compMap[fieldId];
  });

  const defnFormUi = createDefaultDefnFormStudio({
    ...newCompMap,
    [defaultSection]: {
      type: "section",
      metaId: defaultSection,
      fieldIdSet: Object.keys(newCompMap)
    } as DefnSection,

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabIdSet: [defaultSection]
    } as DefnTab
  });
  defnFormUi.chatBubbleFieldIdSet = showKanbanField;
  return defnFormUi;
}
