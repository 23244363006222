import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {Slider} from "@mui/material";
import {isArray} from "lodash";
import {useCallback} from "react";
import {SyntheticEvent} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldSlider} from "../../../../api/meta/base/dto/DefnFieldSlider";
import {FieldValueDecimalRange} from "../../../../api/meta/base/dto/FieldValueDecimalRange";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawNumber from "../raw/FieldRawNumber";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

const DEFAULT_SLIDER_MIN = 0;
const DEFAULT_SLIDER_MAX = 100;
export default function FieldSlider(props: {
  defn: DefnFieldSlider,
})
{
  const defn = props.defn;
  const {
    getFieldHelperText,
    getFieldRequired,
    getFieldMinSlider,
    getFieldMaxSlider,
    getFieldStepSlider,
    getFieldDefaultValue
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const isPropertyEditor = formSection.sectionVariant === "propertyEditor";
  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const min = getFieldMinSlider();
  const max = getFieldMaxSlider();
  const defaultValue = getFieldDefaultValue();
  const step = getFieldStepSlider();

  const defnLabel = {
    label: Boolean(required) ? label + " *" : label
  } as DefnFieldLabel;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;

        const isError = Boolean(error);

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={field.value}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              {
                !isPropertyEditor &&
                <FieldLabel defn={defnLabel} />
              }

              <LayoutGap height={px(gapQuarter)} />
              <LayoutFlexRow
                width={"100%"}
                overflowY={"visible"}
                overflowX={"visible"}
              >
                <RealSlider
                  defn={defn}
                  field={field}
                  error={error}
                  required={required}
                  min={min as number}
                  max={max as number}
                  step={step as number}
                  defaultValue={defaultValue as number}
                />
                <FieldRawRefButton
                  defn={defn}
                />
              </LayoutFlexRow>
              {
                (isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message || helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealSlider(props: {
  defn: DefnFieldSlider,
  error?: FieldError,
  required?: boolean,
  min?: number,
  max?: number
  step?: number,
  defaultValue?: number,
  field: ControllerRenderProps
})
{
  const defn = props.defn;

  const error = props.error;
  const field = props.field;
  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const allowRangePicker = defn.allowRangePicker;
  const showAsInputBox = defn.showAsInputBox;
  const fieldValue = field.value as FieldValueDecimalRange | undefined;
  const required = props.required;
  const min = ((props.defaultValue && !props.min) || props.defaultValue && props.min)
    ? Math.min(props.min || 0, props.defaultValue)
    : props.min;
  const max = ((props.defaultValue && !props.max) || props.defaultValue && props.max)
    ? Math.max(props.max || 0, props.defaultValue)
    : props.max;
  const step = props.step;
  const defaultValue = props.defaultValue;

  const valueArray = allowRangePicker ? [
    fieldValue?.minValue ?? min ?? DEFAULT_SLIDER_MIN,
    fieldValue?.maxValue ?? max ?? DEFAULT_SLIDER_MAX
  ] : fieldValue?.maxValue ?? DEFAULT_SLIDER_MIN;

  const getOnClick = formCtx.getOnClick();

  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const onChangeMin = useCallback((value?: number) =>
  {
    if(!value && !fieldValue?.maxValue)
    {
      field.onChange(null);
    }
    else
    {
      field.onChange({
        minValue: value,
        maxValue: fieldValue?.maxValue
      } as FieldValueDecimalRange);
    }
  }, [field, fieldValue?.maxValue]);

  const onChangeMax = useCallback((value?: number) =>
  {
    if(!value && !fieldValue?.minValue)
    {
      field.onChange(null);
    }
    else
    {
      field.onChange({
        minValue: fieldValue?.minValue,
        maxValue: value
      } as FieldValueDecimalRange);
    }
  }, [field, fieldValue?.minValue]);

  const onChange = useCallback((_: Event | SyntheticEvent<Element, Event>, value: number | number[]) =>
  {
    if(!isArray(value))
    {
      field.onChange({
        minValue: 0,
        maxValue: value
      } as FieldValueDecimalRange);
    }
    else
    {
      field.onChange({
        minValue: value[0],
        maxValue: value[1]
      } as FieldValueDecimalRange);
    }
  }, [field]);

  if(showAsInputBox)
  {
    return <LayoutFlexRow
      overflowX={"visible"}
      overflowY={"visible"}
      width={"100%"}
      onClick={onClick}
    >
      <FieldRawNumber
        defn={defn}
        fieldId={fieldId}
        onChange={onChangeMin}
        error={error}
        onBlur={field.onBlur}
        value={fieldValue?.minValue}
        minVar={getMinValue(min ?? DEFAULT_SLIDER_MIN,
          max ?? DEFAULT_SLIDER_MAX,
          defaultValue ?? DEFAULT_SLIDER_MIN
        )}
        label={"Min value"}
        allowDecimal={false}
      />
      <LayoutGap width={px(gapStd)} />
      <FieldRawNumber
        defn={defn}
        fieldId={fieldId}
        onChange={onChangeMax}
        error={error}
        onBlur={field.onBlur}
        value={fieldValue?.maxValue}
        maxVar={getMaxValue(min ?? DEFAULT_SLIDER_MIN,
          max ?? DEFAULT_SLIDER_MAX,
          defaultValue ?? DEFAULT_SLIDER_MAX
        )}
        label={"Max value"}
        allowDecimal={false}
      />
    </LayoutFlexRow>;
  }

  return <Slider
    sx={{
      width: "100%",
      marginRight: px(gapStd)
    }}
    onClick={onClick}
    defaultValue={defaultValue || DEFAULT_SLIDER_MIN}
    aria-label={label}
    valueLabelDisplay="auto"
    value={valueArray}
    onChange={onChange}
    max={getMaxValue(min ?? DEFAULT_SLIDER_MIN,
      max ?? DEFAULT_SLIDER_MAX,
      defaultValue ?? DEFAULT_SLIDER_MAX
    )}

    min={getMinValue(min ?? DEFAULT_SLIDER_MIN,
      max ?? DEFAULT_SLIDER_MAX,
      defaultValue ?? DEFAULT_SLIDER_MIN
    )}
    step={step}
    orientation={"horizontal"}
    disabled={disabled || readOnly}
    aria-readonly={readOnly}
    aria-required={required}
  />;
}

function getMaxValue(minValue: number, maxValue: number, result: number)
{
  if(maxValue > minValue)
  {
    return maxValue;
  }
  else
  {
    return Math.max(result, DEFAULT_SLIDER_MAX);
  }
}

function getMinValue(minValue: number, maxValue: number, result: number)
{
  if(maxValue > minValue)
  {
    return minValue;
  }
  else
  {
    return Math.min(result, DEFAULT_SLIDER_MIN);
  }
}
