import {Variant} from "@mui/material/styles/createTypography";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import RawHighlighter from "../raw/RawHighlighter";
import RawMarkDown from "../raw/RawMarkDown";

export default function LineTextSecondary(props: {
  value?: string,
  optional?: string,
  variant?: Variant;
  color?: StandardCSSProperties["color"],
  searchWords?: string[],
  flexGrow?: StandardCSSProperties["flexGrow"],
  width?: StandardCSSProperties["width"],
  height?: StandardCSSProperties["height"],
  textDecoration?: StandardCSSProperties["textDecoration"],
  ml?: number,
  mr?: number,
  mt?: number,
  mb?: number,
  breakWords?: boolean,
  hasMarkDownText?: boolean,
  maxNumberOfLine?: number
})
{
  let color = props.color;
  if(!color)
  {
    color = "textSecondary";
  }

  if(!props.value)
  {
    color = undefined;
  }

  if(props.hasMarkDownText)
  {
    return <RawMarkDown
      color={color}
      variant={props.variant ?? "body2"}
      value={props.value}
      optional={props.optional}
      searchWords={props.searchWords}
      width={props.width}
      height={props.height}
      ml={props.ml}
      mr={props.mr}
      mt={props.mt}
      mb={props.mb}
      flexGrow={props.flexGrow}
      maxNumberOfLine={props.maxNumberOfLine}
      breakWords={props.breakWords}
    />;
  }

  return (
    <RawHighlighter
      color={color}
      variant={props.variant ?? "body2"}
      value={props.value}
      optional={props.optional}
      searchWords={props.searchWords}
      width={props.width}
      height={props.height}
      textDecoration={props.textDecoration}
      ml={props.ml}
      mr={props.mr}
      mt={props.mt}
      mb={props.mb}
      flexGrow={props.flexGrow}
      maxNumberOfLine={props.maxNumberOfLine}
      breakWords={props.breakWords}
    />
  );
}
