import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {Srvc} from "../../Srvc";

export default class SrvcPubSubHome extends ISrvc
{
  //region mediaList
  mediaList(subscriberId: TypeSubscriberId, entId: EntId, chatId: ChatId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      artifactId: entId,
      aboutId: chatId,
      type: "mediaList"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  //endregion

  handleSigTopic(sig: SigTopic, isOnSub?: boolean): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "mediaList":
        Srvc.home.aside.media.rpcMediaListGet(sig.artifactId, sig.aboutId as ChatId);
        return true;
    }

    return false;
  }

  handleTopicUnSub(sig: SigTopic): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "mediaList":
        // ignore
        return true;
    }

    return false;
  }
}
