import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarRatingKind} from "../../../api/meta/base/dto/StudioVarRatingKind";

export function fnVariableBuilderRatingKind(fieldValueRatingKind: string)
{
  function getVarComp()
  {
    return {
      [fieldValueRatingKind]: {
        type: "enumKindRating",
        metaId: fieldValueRatingKind,
        label: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarRatingKind
  )
  {
    valueMap[fieldValueRatingKind] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueRatingKind];

    return {
      value: value
    } as StudioVarRatingKind;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
