import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {Property} from "csstype";
import {forwardRef} from "react";
import React from "react";
import {STR_LIST_ITEM_MEDIA_PLACEHOLDER_SRC} from "../../../base/plus/ConstantsPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import RawSkeleton from "./RawSkeleton";

type IRawImageProps = {
  src?: string,
  alt?: string,
  display?: Property.Display
  width?: StandardCSSProperties["width"],
  height?: StandardCSSProperties["height"],
  aspectRatio?: Property.AspectRatio,
  bgColor?: CssBackgroundColor,
  borderRadius?: Property.BorderRadius,
  borderTopLeftRadius?: Property.BorderTopLeftRadius,
  borderTopRightRadius?: Property.BorderTopRightRadius,
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius,
  borderBottomRightRadius?: Property.BorderBottomRightRadius,
  objectFit?: Property.ObjectFit,
  showErrorPlaceholder?: boolean,
  error?: boolean,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onLoad?: () => void,
  onError?: (e: React.SyntheticEvent<HTMLImageElement>) => void,
  applyBlurFilter?: boolean
};
const RawImage = forwardRef<HTMLImageElement | HTMLDivElement, IRawImageProps>((props, ref) =>
{
  const borderRadius = props.borderRadius;
  const borderBottomLeftRadius = props.borderBottomLeftRadius;
  const borderBottomRightRadius = props.borderBottomRightRadius;
  const borderTopLeftRadius = props.borderTopLeftRadius;
  const borderTopRightRadius = props.borderTopRightRadius;
  const showErrorPlaceholder = props.showErrorPlaceholder;
  const error = props.error;
  const applyBlurFilter = props.applyBlurFilter;

  if(error || !props.src)
  {
    return (
      <Box
        ref={ref}
        sx={{
          width: props.width ? props.width : "100%",
          height: props.height ? props.height : "auto",
          borderRadius: borderRadius,
          ...!borderRadius && {
            borderBottomLeftRadius: borderBottomLeftRadius,
            borderBottomRightRadius: borderBottomRightRadius,
            borderTopLeftRadius: borderTopLeftRadius,
            borderTopRightRadius: borderTopRightRadius
          },
          cursor: props.onClick && "pointer",
          display: props.display || "",
          overflow: "hidden"
        }}
      >
        {(error && showErrorPlaceholder) &&
          (
            <RawImage
              {...props}
              src={STR_LIST_ITEM_MEDIA_PLACEHOLDER_SRC}
              onError={undefined}
              onLoad={undefined}
              onClick={undefined}
              ref={undefined}
              display={undefined}
            />
          )}

        {!showErrorPlaceholder &&
          (
            <RawSkeleton
              borderRadius={props.borderRadius}
              variant={"rectangular"}
              width={"100%"}
              height={"100%"}
            />
          )}
      </Box>
    );
  }

  return (
    <Box
      onClick={props.onClick}
      sx={{
        width: props.width ? props.width : "100%",
        height: props.height ? props.height : "auto",
        borderRadius: borderRadius,
        ...!borderRadius && {
          borderBottomLeftRadius: borderBottomLeftRadius,
          borderBottomRightRadius: borderBottomRightRadius,
          borderTopLeftRadius: borderTopLeftRadius,
          borderTopRightRadius: borderTopRightRadius
        },
        cursor: props.onClick && "pointer",
        bgcolor: props.bgColor,
        display: props.display || "",
        overflow: "hidden"
      }}
    >
      <img
        ref={ref as React.Ref<HTMLImageElement>}
        src={props.src}
        alt={props.alt}
        onError={props.onError}
        onLoad={props.onLoad}
        loading={"eager"}
        decoding={"async"}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          backgroundColor: "transparent",
          borderRadius: borderRadius,
          ...!borderRadius && {
            borderBottomLeftRadius: borderBottomLeftRadius,
            borderBottomRightRadius: borderBottomRightRadius,
            borderTopLeftRadius: borderTopLeftRadius,
            borderTopRightRadius: borderTopRightRadius
          },
          objectFit: props.objectFit,
          aspectRatio: props.aspectRatio,
          filter: applyBlurFilter ? "blur(10px)" : undefined
        }}
      />
    </Box>
  );
});
export default RawImage;
