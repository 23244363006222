import {IconButton} from "@mui/material";
import {useTheme} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import {useMemo} from "react";
import React from "react";
import {useCallback} from "react";
import {FieldValueLocation} from "../../../../api/meta/base/dto/FieldValueLocation";
import {getLocationAsString} from "../../../../base/plus/FormPlus";
import {openSelectedGeoPoint} from "../../../../base/plus/LocationPlus";
import {getLatLngFromGeoPoint} from "../../../../base/plus/StringPlus";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {isComboId} from "../../../../base/types/TypesComboId";
import IconStrip from "../../../atom/icon/IconStrip";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {getResolvedCellStyle} from "../DataGridPlus";
import {GRID_FONT_VARIANT} from "../DataGridPlus";
import {TypeCapturedFieldValue} from "../DataGridPlus";
import {TypeCapturedValues} from "../DataGridPlus";

export default function GridCellLocation(props: ICellRendererParams<IDataGridCell>)
{
  const theme = useTheme();
  const iconColor = theme.common.color("primary");

  const searchWords = (props.context as AgGridContext).searchWords;
  const colId = props.colDef?.colId;
  const isHyperLink = colId ? (props.context as AgGridContext).hyperLinkColIdMap?.[colId] : undefined;
  const {cellValue, value} = useMemo(() =>
  {
    let cellValue = props.valueFormatted
      ? props.valueFormatted
      : props.value;
    let value = (colId ? props.data?.valueMap[colId] : undefined) as FieldValueLocation | undefined;

    if(colId && isComboId(colId))
    {
      const [fieldId, capturedValue] = colId.split("<>");
      const _capturedValue = capturedValue as TypeCapturedValues;
      if(_capturedValue === "captureLocation")
      {
        value = (props.data?.valueMap[fieldId] as TypeCapturedFieldValue)?.captureLocation;
        cellValue = value ? getLocationAsString(value) : undefined;
      }
    }

    return {cellValue, value};
  }, [colId, props.data?.valueMap, props.value, props.valueFormatted]);

  const latLng = value?.value.geoPoint
    ? getLatLngFromGeoPoint(value?.value.geoPoint)
    : undefined;

  const lat = latLng?.lat || 0;
  const lng = latLng?.lng || 0;

  const cbOnClick = useCallback(() =>
  {
    openSelectedGeoPoint(lat, lng);
  }, [lat, lng]);

  const style = useMemo(() => getResolvedCellStyle(
    colId,
    (props.context as AgGridContext).layout?.styleMap,
    (props.context as AgGridContext).colIdToStyleIdMap,
    (props.context as AgGridContext).defnForm,
    props.data as IDataGridCell
  ), [colId, props.context, props.data]);

  if(!cellValue)
  {
    return null;
  }

  return <LayoutFlexRow>
    <IconButton
      size={"small"}
      sx={{
        mr: 1
      }}
      onClick={cbOnClick}
    >
      <IconStrip
        value={"place"}
        color={iconColor}
      />
    </IconButton>

    <RawHighlighter
      searchWords={searchWords}
      value={typeof cellValue === "string" ? cellValue : `${cellValue ?? ""}`}
      width={"100%"}
      cursor={isHyperLink ? "pointer" : undefined}

      //style
      variant={style?.variant || GRID_FONT_VARIANT}
      color={style?.color || "textSecondary"}
      textDecoration={isHyperLink ? "underline" : style?.textDecoration}
      bold={style?.isBold}
      italic={style?.isItalic}
    />
  </LayoutFlexRow>;
}

