import {Typography} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldParagraph} from "../../../../api/meta/base/dto/DefnFieldParagraph";
import {FieldValueParagraph} from "../../../../api/meta/base/dto/FieldValueParagraph";
import {DEFAULT_PARAGRAPH_LINE_COUNT} from "../../../../base/plus/ConstantsPlus";
import {getFormFieldValueAsText} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldParagraph(props: {
  defn: DefnFieldParagraph,
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const flexHeight = defn.flexHeight;

  const {
    getFieldLineCount
  } = useFieldPropertiesResolver(defn);

  const lineCount = getFieldLineCount();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;
        const isError = Boolean(error);

        const fieldValue = field.value as FieldValueParagraph | undefined;
        const onChange = field.onChange;

        const truncateText = (text: string, maxLength: number) =>
        {
          if(text.length > maxLength)
          {
            return text.slice(0, maxLength);
          }
          return text;
        };

        const paragraph = getFormFieldValueAsText(defn, fieldValue);
        const paragraphValue = paragraph && defn.maxCharCountVar
          ? truncateText(paragraph, defn.maxCharCountVar)
          : paragraph;

        const reportNode = <Typography
          sx={{
            WebkitLineClamp: (lineCount as number) || DEFAULT_PARAGRAPH_LINE_COUNT,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden"
          }}
          component={"span"}
          variant={"caption"}
          color={"textSecondary"}
        >
          {paragraphValue}
        </Typography>;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            reportNode={reportNode}
            flexGrow={flexHeight ? 1 : undefined}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
              sx={{
                flexGrow: 1,
                height: "100%"
              }}
            >
              <LayoutFlexRow
                height={"100%"}
                overflowY={"visible"}
                overflowX={"visible"}
              >
                <FieldRawTextField
                  {...props}
                  rows={flexHeight ? undefined : lineCount as number ?? DEFAULT_PARAGRAPH_LINE_COUNT}
                  value={fieldValue}
                  onBlur={field.onBlur}
                  onChange={onChange}
                  ref={field.ref}
                  error={error}
                  flexHeight={flexHeight}
                />
              </LayoutFlexRow>
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
};
