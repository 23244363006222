import {VisibilityOff} from "@mui/icons-material";
import {CleaningServicesRounded} from "@mui/icons-material";
import {RefreshRounded} from "@mui/icons-material";
import {VisibilityRounded} from "@mui/icons-material";
import {TerminalRounded} from "@mui/icons-material";
import {ChatRounded} from "@mui/icons-material";
import {CloseFullscreenRounded} from "@mui/icons-material";
import {SaveRounded} from "@mui/icons-material";
import {QrCode2Rounded} from "@mui/icons-material";
import {ArrowUpwardRounded} from "@mui/icons-material";
import {ArrowDownwardRounded} from "@mui/icons-material";
import {KeyboardArrowUpRounded} from "@mui/icons-material";
import {KeyboardArrowRightRounded} from "@mui/icons-material";
import {KeyboardArrowDownRounded} from "@mui/icons-material";
import {Lock} from "@mui/icons-material";
import {LockOpen} from "@mui/icons-material";
import {LinkRounded} from "@mui/icons-material";
import {AssignmentIndRounded} from "@mui/icons-material";
import {CodeRounded} from "@mui/icons-material";
import {SettingsRounded} from "@mui/icons-material";
import {UploadRounded} from "@mui/icons-material";
import {ViewListRounded} from "@mui/icons-material";
import {GridViewRounded} from "@mui/icons-material";
import {DescriptionRounded} from "@mui/icons-material";
import {ContentPasteRounded} from "@mui/icons-material";
import {GridOnRounded} from "@mui/icons-material";
import {HelpOutlineRounded} from "@mui/icons-material";
import {ErrorOutlineRounded} from "@mui/icons-material";
import {ArrowForwardIosRounded} from "@mui/icons-material";
import {ArrowBackIosRounded} from "@mui/icons-material";
import {FilterListOffRounded} from "@mui/icons-material";
import {FavoriteRounded} from "@mui/icons-material";
import {NotificationsActiveRounded} from "@mui/icons-material";
import {AttachFileRounded} from "@mui/icons-material";
import {SearchRounded} from "@mui/icons-material";
import {ContentCopyRounded} from "@mui/icons-material";
import {MapRounded} from "@mui/icons-material";
import {MyLocationRounded} from "@mui/icons-material";
import {QuestionAnswer} from "@mui/icons-material";
import {ShoppingCart} from "@mui/icons-material";
import {DeleteRounded} from "@mui/icons-material";
import {ForwardRounded} from "@mui/icons-material";
import {SendRounded} from "@mui/icons-material";
import {SentimentSatisfiedRounded} from "@mui/icons-material";
import {FilterListRounded} from "@mui/icons-material";
import {MoreVertRounded} from "@mui/icons-material";
import {StarRounded} from "@mui/icons-material";
import {OpenInFullRounded} from "@mui/icons-material";
import {CloseRounded} from "@mui/icons-material";
import {PrintRounded} from "@mui/icons-material";
import {DownloadRounded} from "@mui/icons-material";
import {ShareRounded} from "@mui/icons-material";
import {Block} from "@mui/icons-material";
import {ControlPoint} from "@mui/icons-material";
import {RemoveCircleOutline} from "@mui/icons-material";
import {PlaceRounded} from "@mui/icons-material";
import {TagRounded} from "@mui/icons-material";
import {EmailRounded} from "@mui/icons-material";
import {LocalPhoneRounded} from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import PlaceIcon from "@mui/icons-material/Place";
import {CircularProgress} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {stripIconSx} from "../../../base/plus/ThemePlus";
import {EnumIconStrip} from "../../../base/types/TypesIcon";
import {PromptIcon} from "./IconsCustom";
import {UnStarIcon} from "./IconsCustom";

export default function IconStrip(props: {
  value: EnumIconStrip,
  color?: string,
  disabled?: boolean
})
{
  const theme = useTheme();
  const {
    value,
    color,
    disabled
  } = props;

  if(value === undefined)
  {
    return null;
  }
  const sx = stripIconSx(disabled ? theme.common.fgcolorIconDisabled : color);

  switch(value)
  {
    case "backIos":
      return <ArrowBackIosRounded sx={sx} />;
    case "forwardIos":
      return <ArrowForwardIosRounded sx={sx} />;
    case "share":
      return <ShareRounded sx={sx} />;
    case "download":
      return <DownloadRounded sx={sx} />;
    case "print":
      return <PrintRounded sx={sx} />;
    case "close":
      return <CloseRounded sx={sx} />;
    case "clear":
      return <CleaningServicesRounded sx={sx} />;
    case "openInFull":
      return <OpenInFullRounded sx={sx} />;
    case "star":
      return <StarRounded sx={sx} />;
    case "filter":
      return <FilterListRounded sx={sx} />;
    case "filterClear":
      return <FilterListOffRounded sx={sx} />;
    case "more":
      return <MoreVertRounded sx={sx} />;
    case "moreHorizontal":
      return <MoreHorizRoundedIcon sx={sx} />;
    case "smile":
      return <SentimentSatisfiedRounded sx={sx} />;
    case "attach":
      return <AttachFileRounded sx={sx} />;
    case "send":
      return <SendRounded sx={sx} />;
    case "forward":
      return <ForwardRounded sx={sx} />;
    case "delete":
      return <DeleteRounded sx={sx} />;
    case "map":
      return <MapRounded sx={sx} />;
    case "copy":
      return <ContentCopyRounded sx={sx} />;
    case "copyUserId":
      return <ContentCopyRounded sx={sx} />;
    case "copyEntId":
      return <ContentCopyRounded sx={sx} />;
    case "search":
      return <SearchRounded sx={sx} />;
    case "ringBell":
      return <NotificationsActiveRounded sx={sx} />;
    case "heart":
      return <FavoriteRounded sx={sx} />;
    case "debug":
      return <BugReportRoundedIcon sx={sx} />;
    case "save":
      return <SaveRounded sx={sx} />;
    case "error":
      return <ErrorOutlineRounded sx={sx} />;
    case "help":
      return <HelpOutlineRounded sx={sx} />;
    case "msgDelete":
      return <Block
        sx={sx}
        style={{
          width: px(20),
          marginLeft: px(gapQuarter)
        }}
      />;
    case "plus":
      return <ControlPoint sx={sx} />;
    case "minus":
      return <RemoveCircleOutline sx={sx} />;
    case "place":
      return <PlaceIcon sx={sx} />;
    case "edit":
      return <EditIcon sx={sx} />;
    case "table":
      return <GridOnRounded sx={sx} />;
    case "unStar":
      return <UnStarIcon
        disabled={disabled}
        color={color}
      />;
    case "unStarAll":
      return <UnStarIcon
        disabled={disabled}
        color={color}
      />;
    case "paste":
      return <ContentPasteRounded sx={sx} />;
    case "document":
      return <DescriptionRounded sx={sx} />;
    case "currentLocation":
      return <MyLocationRounded sx={sx} />;
    case "grid":
      return <GridViewRounded sx={sx} />;
    case "list":
      return <ViewListRounded sx={sx} />;
    case "comment":
      return <QuestionAnswer sx={{sx}} />;
    case "cart":
      return <ShoppingCart sx={{sx}} />;
    case "upload":
      return <UploadRounded sx={{sx}} />;
    case "expand":
      return <OpenInFullRounded sx={sx} />;
    case "collapse":
      return <CloseFullscreenRounded sx={sx} />;
    case "location":
      return <PlaceRounded sx={sx} />;
    case "hashtag":
      return <TagRounded sx={sx} />;
    case "email":
      return <EmailRounded sx={sx} />;
    case "mobileNumber":
      return <LocalPhoneRounded sx={sx} />;
    case "settings":
      return <SettingsRounded sx={sx} />;
    case "symbol":
      return <CodeRounded sx={sx} />;
    case "handle":
      return <AssignmentIndRounded sx={sx} />;
    case "hyperlink":
      return <LinkRounded sx={sx} />;
    case "prompt":
      return <PromptIcon sx={sx} />;
    case "loading":
      return <CircularProgress
        sx={sx}
        size={sx.width}
      />;
    case "lock":
      return <Lock sx={sx} />;
    case "unlock":
      return <LockOpen sx={sx} />;
    case "expandArrowUp":
      return <KeyboardArrowUpRounded sx={sx} />;
    case "expandArrowDown":
      return <KeyboardArrowDownRounded sx={sx} />;
    case "expandArrowRight":
      return <KeyboardArrowRightRounded sx={sx} />;
    case "scanCode":
      return <QrCode2Rounded sx={sx} />;
    case "sortAsc":
      return <ArrowUpwardRounded sx={sx} />;
    case "sortDesc":
      return <ArrowDownwardRounded sx={sx} />;
    case "miniMize":
      return <CloseFullscreenRounded sx={sx} />;
    case "chat":
      return <ChatRounded sx={sx} />;
    case "terminal":
      return <TerminalRounded sx={sx} />;
    case "visible":
      return <VisibilityRounded sx={sx} />;
    case "refresh":
      return <RefreshRounded sx={sx} />;
    case "add":
      return <AddOutlinedIcon sx={sx} />;
    case "hideVisible":
      return <VisibilityOff sx={sx} />;
  }
}
