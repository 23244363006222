import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldText} from "../../../api/meta/base/dto/DefnFieldText";
import {DefnStudioBuildColor} from "../../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioPickVarId} from "../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioVarHyperlink} from "../../../api/meta/base/dto/StudioVarHyperlink";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fieldGap3} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap2} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap1} from "../../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../form/builder/base/TypesFormBuilder";

const fieldDisplayText = "displayText";
const fieldColorVariable = "colorVarId";
const fieldColor = "color";
const fieldKind = "kind";
const fieldLabelOR = "fieldLabelOR";

export function fnVariableBuilderHyperlink(fieldValueUrl: string, showHyperlinkValueOnly?: boolean)
{
  function getVarComp()
  {
    return {
      [fieldValueUrl]: {
        type: "hyperlink",
        metaId: fieldValueUrl,
        name: "Value",
        required: true
      } as DefnFieldEditable,

      ...!showHyperlinkValueOnly && {
        ...getFieldGap(fieldGap1, "thick"),

        [fieldDisplayText]: {
          type: "text",
          metaId: fieldDisplayText,
          name: "Display text",
          required: true
        } as DefnFieldText,

        ...getFieldGap(fieldGap2),

        [fieldColor]: {
          type: "studioBuildColor",
          metaId: fieldColor,
          name: fieldColor,
          label: "Color",
          allowShades: true
        } as DefnStudioBuildColor,

        [fieldLabelOR]: {
          type: "label",
          label: "OR",
          metaId: fieldLabelOR,
          name: fieldLabelOR,
          justifyTextVar: "center",
          disabled: true
        } as DefnFieldLabel,

        [fieldColorVariable]: {
          type: "pickVarId",
          metaId: fieldColorVariable,
          name: "Color variable",
          varKind: "color",
          showAsEdit: true
        } as DefnStudioPickVarId,

        ...getFieldGap(fieldGap3),

        [fieldKind]: {
          type: "enumKindHyperlink",
          metaId: fieldKind,
          name: "Kind",
          required: true
        } as DefnFieldPickEnum
      }
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarHyperlink
  )
  {
    const varValue = entVariable.value;
    if(varValue)
    {
      valueMap[fieldValueUrl] = fnRawValueToFieldValue("text", varValue.value);
      if(!showHyperlinkValueOnly)
      {
        valueMap[fieldDisplayText] = fnRawValueToFieldValue("text", varValue.displayText);
        valueMap[fieldColorVariable] = fnRawValueToFieldValue("pickVarId", varValue.colorVarId);
        valueMap[fieldColor] = fnRawValueToFieldValue("studioBuildColor", varValue.color);
        valueMap[fieldKind] = fnRawValueToFieldValue("enumKindHyperlink", varValue.kind);
      }
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    return {
      value: {
        value: fnFieldValueToRawValue("text", valueMap[fieldValueUrl]),
        ...!showHyperlinkValueOnly && {
          displayText: fnFieldValueToRawValue("text", valueMap[fieldDisplayText]),
          colorVarId: fnFieldValueToRawValue("pickVarId", valueMap[fieldColorVariable]),
          color: fnFieldValueToRawValue("studioBuildColor", valueMap[fieldColor]),
          kind: fnFieldValueToRawValue("enumKindHyperlink", valueMap[fieldKind])
        }
      }
    } as StudioVarHyperlink;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
