import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioBuildDateTime} from "../../../../api/meta/base/dto/DefnStudioBuildDateTime";
import {EnumArrayDefnDate} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioBuildDateTime(props: {
  defn: DefnStudioBuildDateTime
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const options = [
    "custom",
    ...EnumArrayDefnDate
  ];

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={arrayToMapOfOption(options)}
      />}
    />
  );
}
