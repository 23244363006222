import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDate} from "../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldInfo} from "../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldTime} from "../../../api/meta/base/dto/DefnFieldTime";
import {FieldValueDate} from "../../../api/meta/base/dto/FieldValueDate";
import {StudioBuildDateTime} from "../../../api/meta/base/dto/StudioBuildDateTime";
import {StudioVarDateTime} from "../../../api/meta/base/dto/StudioVarDateTime";
import {AnyTime} from "../../../api/meta/base/Types";
import {EnumArrayDefnDate} from "../../../api/meta/base/Types";
import {EnumDefnDate} from "../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {arrayToMapOfOption} from "../../../base/plus/JsPlus";
import {fieldCustomDateTime} from "../../form/builder/base/TypesFormBuilder";
import {fieldTimeDateTime} from "../../form/builder/base/TypesFormBuilder";

type TypeDateTimeOption =
  | EnumDefnDate
  | "custom";

const fieldNoteInfo = "fieldNoteInfo";
const fieldNote = "fieldNote";

export function fnVariableBuilderDateTime(fieldValueDateTime: string, dateTimeFormat?: string)
{
  function getVarComp(variableDateTimeType?: TypeDateTimeOption)
  {
    const dateTimeOptions: TypeDateTimeOption[] = [
      "custom",
      ...EnumArrayDefnDate as EnumDefnDate[]
    ];

    const variableDateTimeTypeCreatedOrUpdated = variableDateTimeType === "createdOn"
      || variableDateTimeType === "updatedOn";

    return {
      [fieldValueDateTime]: {
        type: "studioBuildDateTime",
        metaId: fieldValueDateTime,
        name: "Value",
        optionMap: arrayToMapOfOption(dateTimeOptions),
        required: true
      } as DefnFieldPickText,

      ...variableDateTimeType === "custom" && {
        [fieldCustomDateTime]: {
          type: "dateTime",
          metaId: fieldCustomDateTime,
          name: "Custom value",
          displayDateFormat: dateTimeFormat
        } as DefnFieldDate
      },
      ...variableDateTimeType !== "custom"
      && !variableDateTimeTypeCreatedOrUpdated && {
        [fieldTimeDateTime]: {
          type: "time",
          metaId: fieldTimeDateTime,
          name: fieldTimeDateTime,
          label: "Time"
        } as DefnFieldTime
      },

      [fieldNoteInfo]: {
        type: "info",
        metaId: fieldNoteInfo,
        name: fieldNoteInfo,
        flexGrow: true,
        label: ""
      } as DefnFieldInfo,

      [fieldNote]: {
        type: "label",
        name: fieldNote,
        metaId: fieldNote,
        italic: true,
        textSizeVar: "caption",
        italicVar: true,
        disabled: true,
        label: "Note: The date time is calculated when bound"
      } as DefnFieldLabel
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarDateTime
  )
  {
    const varValue = entVariable.value;
    if(varValue)
    {
      const dateEnumValue = varValue.value;
      if(dateEnumValue && EnumArrayDefnDate.includes(dateEnumValue))
      {
        valueMap[fieldValueDateTime] = dateEnumValue;
        valueMap[fieldCustomDateTime] = undefined;
        valueMap[fieldTimeDateTime] = varValue.time;
      }
      else if(varValue.customValue)
      {
        valueMap[fieldValueDateTime] = "custom" as TypeDateTimeOption;
        valueMap[fieldCustomDateTime] = fnRawValueToFieldValue("date", varValue.customValue);
      }
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const dateTimeValue = valueMap[fieldValueDateTime] as TypeDateTimeOption;
    const customValue = fnFieldValueToRawValue("date", valueMap[fieldCustomDateTime] as FieldValueDate) as string;
    const time = fnFieldValueToRawValue("time", valueMap[fieldTimeDateTime]) as AnyTime;

    const studioBuildDateDto = {} as StudioBuildDateTime;

    if(!dateTimeValue)
    {
      return;
    }

    if(dateTimeValue && EnumArrayDefnDate.includes(dateTimeValue))
    {
      studioBuildDateDto.value = dateTimeValue as EnumDefnDate;
      studioBuildDateDto.time = time as AnyTime;
      return {
        value: studioBuildDateDto
      } as StudioVarDateTime;
    }
    else if(dateTimeValue === "custom")
    {
      studioBuildDateDto.customValue = customValue;
      return {
        value: studioBuildDateDto
      } as StudioVarDateTime;
    }

    return undefined;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
