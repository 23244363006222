import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {isEqual} from "lodash";
import {StudioComposite} from "../../../api/meta/base/dto/StudioComposite";
import {StudioField} from "../../../api/meta/base/dto/StudioField";
import {StudioForm} from "../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../api/meta/base/dto/StudioGrid";
import {StudioModuleMap} from "../../../api/meta/base/dto/StudioModuleMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {StudioPlugin} from "../../../api/meta/base/dto/StudioPlugin";
import {StudioPluginApi} from "../../../api/meta/base/dto/StudioPluginApi";
import {StudioPluginDeploy} from "../../../api/meta/base/dto/StudioPluginDeploy";
import {StudioPluginDev} from "../../../api/meta/base/dto/StudioPluginDev";
import {StudioPluginDraft} from "../../../api/meta/base/dto/StudioPluginDraft";
import {StudioPluginJar} from "../../../api/meta/base/dto/StudioPluginJar";
import {StudioPluginRpc} from "../../../api/meta/base/dto/StudioPluginRpc";
import {StudioSection} from "../../../api/meta/base/dto/StudioSection";
import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {newGuid} from "../../../api/meta/base/NanoId";
import {EnumStoreLabel} from "../../../api/meta/base/Types";
import {EnumDefnPluginResources} from "../../../api/meta/base/Types";
import {
  MediaIdAvatar,
  MetaId,
  MetaIdComposite,
  MetaIdField,
  MetaIdForm,
  PluginBundleId,
  PluginId
} from "../../../api/meta/base/Types";
import {SigStudioPluginBundle} from "../../../api/studio/studioDrawer/sig/SigStudioPluginBundle";
import {SigAdminEditLockDetail} from "../../../api/studio/studioMain/sig/SigAdminEditLockDetail";
import {SigPluginAdminCaller} from "../../../api/studio/studioMain/sig/SigPluginAdminCaller";
import {SigPluginAdminList} from "../../../api/studio/studioMain/sig/SigPluginAdminList";
import {moveListItem} from "../../../base/plus/StudioPlus";
import {EnumListItemDirection, EnumStudioPluginFooterTab, EnumStudioPluginStep} from "../../../base/types/TypesStudio";
import {Srvc} from "../../../srvc/Srvc";
import {TypeDtoPluginDataSetKey} from "../ent/TypesCacheStudioEnt";
import {ICacheStudioStatePlugin} from "./TypesCacheStudioPlugin";

type ActionStepItemAdd<T> = {
  pluginBundleId: PluginBundleId,
  sig: T,
  insertIndex?: number
};
type ActionStepItemRemove = {
  pluginBundleId: PluginBundleId,
  metaId: MetaId
};

export type ActionUpdatePluginDetails = {
  pluginBundleId: PluginBundleId,
  aboutUsers?: string;
  storeAbout?: string;
  allModules?: StudioModuleMap;
  avatarId?: MediaIdAvatar;
  lastUpdateTime?: string;
  storeLabelSet?: EnumStoreLabel[];
  name?: string;
}

export type ActionUpdatePluginModuleMap = {
  pluginBundleId: PluginBundleId,
  moduleMap?: StudioModuleMap
}

export type ActionUpdatePluginDeploy = {pluginBundleId: PluginBundleId} & StudioPluginDeploy;

export type ActionAddPluginVersion = {pluginBundleId: PluginBundleId, sig: StudioPluginDraft};

export type ActionAddPluginVariable = ActionStepItemAdd<StudioVar>

export type ActionRemovePluginVariable = ActionStepItemRemove;

export type ActionAddPluginForm = ActionStepItemAdd<StudioForm>;

export type ActionRemovePluginForm = ActionStepItemRemove

export type ActionAddPluginFormComposite =
  {formId: MetaIdForm}
  & ActionStepItemAdd<StudioComposite>;

export type ActionAddPluginFormField = {
  formId: MetaIdForm,
  parentCompositeId: MetaIdComposite,
} & ActionStepItemAdd<StudioField>;

export type ActionRemovePluginFormSection = {formId: MetaIdForm} & ActionStepItemRemove;

export type ActionRemovePluginFormField = {
  formId: MetaIdForm,
  sectionId: MetaIdField,
} & ActionStepItemRemove

export type ActionMovePluginItem = {
  pluginBundleId: PluginBundleId,
  metaId: MetaId,
  setKey: TypeDtoPluginDataSetKey,
  moveDirection: EnumListItemDirection
};

export type ActionMovePluginFormItem = {
  pluginBundleId: PluginBundleId,
  formId: MetaIdForm,
  compositeId: MetaIdComposite,
  fieldId?: MetaIdField,
  moveDirection: EnumListItemDirection
}

export type ActionAddPluginApi = ActionStepItemAdd<StudioPluginApi>

export type ActionRemovePluginApi = ActionStepItemRemove

export type ActionAddPluginResourceRpc = ActionStepItemAdd<StudioPluginRpc>

export type ActionAddPluginResourceJar = ActionStepItemAdd<StudioPluginJar>

export type ActionAddPluginResourceDev = ActionStepItemAdd<StudioPluginDev>

export type ActionRemovePluginResource = {pluginBundleId: PluginBundleId, resourceType: EnumDefnPluginResources}

export type ActionPluginDirty = {pluginBundleId: PluginBundleId, isDirty: boolean};

export type ActionPluginStateMap = {
  pluginBundleId: PluginBundleId,
  currentTab?: EnumStudioPluginFooterTab,
  currentStep?: EnumStudioPluginStep
}

export type ActionPluginEditLockDetail = {
  pluginBundleId: PluginBundleId,
  detail?: SigAdminEditLockDetail
}

type TypeStudioPluginDetailsKeys =
  | "about"
  | "storeAbout"
  | "avatarId"
  | "name"
  | "storeLabelSet"

type TypeStudioPluginDeployKeys =
  | "changes"
  | "pluginVersion"

export const sliceCacheStudioPlugin = createSlice({
  name: "cacheStudioMain",
  initialState: {
    pluginMap: {},
    pluginAdminListMap: {},
    pluginAdminCallerMap: {},
    dirtyPluginIdList: [],
    selectedVersionId: "",
    selectedModulesMap: {},
    pluginStateMap: {},
    dirtyVersionMap: {},
    pluginEditLockDetailMap: {}
  } as ICacheStudioStatePlugin,
  reducers: {
    setPlugin: (state: ICacheStudioStatePlugin, action: PayloadAction<SigStudioPluginBundle>) =>
    {
      const sig = action.payload;
      const pluginBundleId = sig.studioPluginBundle.pluginBundleId;
      state.pluginMap[pluginBundleId] = sig;
    },
    updatePlugin: (state: ICacheStudioStatePlugin, action: PayloadAction<SigStudioPluginBundle>) =>
    {
      const sig = action.payload;
      const pluginBundleId = sig.studioPluginBundle.pluginBundleId;
      state.pluginMap[pluginBundleId] = sig;
      verifyPluginDirty(state, pluginBundleId);
    },
    removePlugin: (state: ICacheStudioStatePlugin, action: PayloadAction<PluginBundleId>) =>
    {
      delete state.pluginMap[action.payload];
    },

    mergePlugin: (state: ICacheStudioStatePlugin, action: PayloadAction<SigStudioPluginBundle>) =>
    {
      const sig = action.payload;
      const studioPluginBundle = sig.studioPluginBundle;
      const pluginBundleId = studioPluginBundle.pluginBundleId;
      const deltaStudioPlugin = state.pluginStateMap[pluginBundleId]?.deltaStudio;
      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);
      const draft = studioPluginBundle?.draft;

      if(draft && selectedPluginVersion)
      {
        const studioPlugin = Srvc.cache.studio.fnApplyDeltaStudioArtifactItem(
          () => verifyPluginDirty(state, pluginBundleId),
          draft?.studioPlugin,
          selectedPluginVersion,
          deltaStudioPlugin
        );

        state.pluginMap[pluginBundleId] = {
          ...sig,
          studioPluginBundle: {
            ...studioPluginBundle,
            draft: {
              ...draft,
              studioPlugin: studioPlugin
            }
          }
        };
      }
      else
      {
        state.pluginMap[pluginBundleId] = sig;
      }
    },

    setIsSavingPlugin: (
      state: ICacheStudioStatePlugin,
      action: PayloadAction<{pluginBundleId: PluginBundleId, isSaving: boolean}>) =>
    {
      const {
        pluginBundleId,
        isSaving
      } = action.payload;
      state.pluginStateMap[pluginBundleId] = {
        ...state.pluginStateMap[pluginBundleId],
        isSaving
      };
    },

    addDeltaPluginLocalState: (
      state: ICacheStudioStatePlugin,
      action: PayloadAction<{
        pluginBundleId: PluginBundleId, oldStudioPlugin: StudioPlugin
      }>) =>
    {
      const {
        pluginBundleId,
        oldStudioPlugin
      } = action.payload;

      if(!state.pluginStateMap[pluginBundleId]?.deltaStudio)
      {
        state.pluginStateMap[pluginBundleId] = {
          ...state.pluginStateMap[pluginBundleId],
          deltaStudio: {}
        };
      }

      const oldDelta = state.pluginStateMap[pluginBundleId].deltaStudio;
      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      state.pluginStateMap[pluginBundleId].deltaStudio = Srvc.cache.studio.fnCalcDeltaStudioArtifactItem(
        selectedPluginVersion,
        oldStudioPlugin,
        oldDelta
      );
    },
    removeDeltaPluginLocalState: (state: ICacheStudioStatePlugin, action: PayloadAction<PluginBundleId>) =>
    {
      const pluginBundleId = action.payload;

      if(pluginBundleId && state.pluginStateMap[pluginBundleId]?.deltaStudio)
      {
        delete state.pluginStateMap[pluginBundleId].deltaStudio;
      }
    },

    updatePluginModules: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionUpdatePluginModuleMap>) =>
    {
      const {
        pluginBundleId,
        moduleMap
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        if(moduleMap)
        {
          selectedPluginVersion.moduleMap = moduleMap;
        }
        else
        {
          selectedPluginVersion.moduleMap = {
            keys: [],
            map: {}
          };
        }

        verifyPluginDirty(state, pluginBundleId);
      }

    },

    updatePluginDetails: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionUpdatePluginDetails>) =>
    {
      const {
        pluginBundleId
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);
      let isDirty = false;

      if(selectedPluginVersion)
      {
        const pluginDetails = selectedPluginVersion.details;
        Object.entries(action.payload).forEach(([key, value]) =>
        {
          const _key = key as TypeStudioPluginDetailsKeys;
          if(!pluginDetails[_key] || pluginDetails[_key] !== value)
          {
            switch(_key)
            {
              case "name":
                pluginDetails.name = value as string;
                break;
              case "about":
                pluginDetails.about = value as string;
                break;
              case "storeAbout":
                pluginDetails.storeAbout = value as string;
                break;
              case "avatarId":
                pluginDetails.avatarId = value as MediaIdAvatar;
                break;
              case "storeLabelSet":
                pluginDetails.storeLabelSet = value as EnumStoreLabel[];
            }

            isDirty = true;
          }
        });

        isDirty && verifyPluginDirty(state, pluginBundleId);
      }
    },

    updatePluginDeploy: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionUpdatePluginDeploy>) =>
    {
      const {
        pluginBundleId
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);
      let isDirty = false;

      if(selectedPluginVersion)
      {
        const pluginDeploy = selectedPluginVersion.deploy;
        Object.entries(action.payload).forEach(([key, value]) =>
        {
          if(pluginDeploy && Object.hasOwn(pluginDeploy, key))
          {
            const _key = key as TypeStudioPluginDeployKeys;
            if(pluginDeploy[_key] !== value)
            {
              pluginDeploy[_key] = value;
              isDirty = true;
            }
          }
        });

        isDirty && verifyPluginDirty(state, pluginBundleId);
      }
    },

    duplicatePluginVersion: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginVersion>) =>
    {
      const {
        pluginBundleId,
        sig
      } = action.payload;

      const pluginBundle = state.pluginMap[pluginBundleId].studioPluginBundle;

      if(pluginBundle)
      {
        pluginBundle.draft = sig;
        verifyPluginDirty(state, pluginBundleId);
      }
    },

    //region variable

    addPluginVariable: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginVariable>) =>
    {
      const {
        pluginBundleId,
        sig,
        insertIndex
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const pluginVarMap = selectedPluginVersion.varMap;
        if(!pluginVarMap)
        {
          selectedPluginVersion.varMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(pluginVarMap, sig, sig.metaId, insertIndex);
        isDirty && verifyPluginDirty(state, pluginBundleId);
      }
    },

    removePluginVariable: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionRemovePluginVariable>) =>
    {
      const {
        pluginBundleId,
        metaId
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const pluginVarMap = selectedPluginVersion.varMap;
        if(pluginVarMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(pluginVarMap, metaId);
          isDirty && verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    //endregion

    setPluginAdminList: (
      state: ICacheStudioStatePlugin,
      action: PayloadAction<{pluginBundleId: PluginBundleId, sigPluginAdminList: SigPluginAdminList}>) =>
    {
      const {
        pluginBundleId,
        sigPluginAdminList
      } = action.payload;
      state.pluginAdminListMap[pluginBundleId] = sigPluginAdminList;
    },
    removePluginAdminList: (state: ICacheStudioStatePlugin, action: PayloadAction<PluginBundleId>) =>
    {
      delete state.pluginAdminListMap[action.payload];
    },

    setPluginAdminCaller: (
      state: ICacheStudioStatePlugin,
      action: PayloadAction<{pluginBundleId: PluginBundleId, sigPluginAdminCaller: SigPluginAdminCaller}>) =>
    {
      const {
        pluginBundleId,
        sigPluginAdminCaller
      } = action.payload;
      state.pluginAdminCallerMap[pluginBundleId] = sigPluginAdminCaller;
    },
    removePluginAdminCaller: (state: ICacheStudioStatePlugin, action: PayloadAction<PluginBundleId>) =>
    {
      delete state.pluginAdminCallerMap[action.payload];
    },

    setPluginDirty: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionPluginDirty>) =>
    {
      const {
        pluginBundleId,
        isDirty
      } = action.payload;

      const dirtyPluginIdList = state.dirtyPluginIdList;
      if(isDirty)
      {
        if(!dirtyPluginIdList.includes(pluginBundleId))
        {
          dirtyPluginIdList.push(pluginBundleId);
        }
      }
      else
      {
        state.dirtyPluginIdList = dirtyPluginIdList.filter(_pluginId => _pluginId !== pluginBundleId);
      }
    },

    setSearchPluginId: (state: ICacheStudioStatePlugin, action: PayloadAction<PluginBundleId | undefined>) =>
    {
      state.searchPluginId = action.payload;
    },

    //region form

    addPluginForm: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginForm>) =>
    {
      const {
        pluginBundleId,
        sig,
        insertIndex
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const pluginFormMap = selectedPluginVersion.formMap;
        if(!pluginFormMap)
        {
          selectedPluginVersion.formMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(pluginFormMap, sig, sig.metaId, insertIndex);
        isDirty && verifyPluginDirty(state, pluginBundleId);
      }
    },

    removePluginForm: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionRemovePluginForm>) =>
    {
      const {
        pluginBundleId,
        metaId
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const pluginFormMap = selectedPluginVersion.formMap;
        if(pluginFormMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(pluginFormMap, metaId);
          isDirty && verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    //endregion

    //endregion

    //region form section

    addPluginFormComposite: (
      state: ICacheStudioStatePlugin,
      action: PayloadAction<ActionAddPluginFormComposite>) =>
    {
      const {
        pluginBundleId,
        formId,
        sig: studioComposite,
        insertIndex
      } = action.payload;

      if(!studioComposite.details.name)
      {
        throw new Error(`Section name is undefined: ${JSON.stringify(studioComposite)}`);
      }

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        checkIfFormExists(selectedPluginVersion, formId);
        const form = selectedPluginVersion.formMap.map[formId];
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(form.compositeMap,
          studioComposite,
          (studioComposite as StudioSection | StudioGrid).metaId,
          insertIndex
        );
        isDirty && verifyPluginDirty(state, pluginBundleId);
      }
    },

    removePluginFormComposite: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionRemovePluginFormSection>) =>
    {
      const {
        pluginBundleId,
        formId,
        metaId
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        checkIfFormExists(selectedPluginVersion, formId);
        const form = selectedPluginVersion.formMap.map[formId];
        const compositeMap = form && form.compositeMap;
        if(compositeMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(compositeMap, metaId);
          isDirty && verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    //endregion

    //region form field

    addPluginFormField: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginFormField>) =>
    {
      const {
        pluginBundleId,
        formId,
        parentCompositeId,
        sig: studioField,
        insertIndex
      } = action.payload;

      if(!studioField.details.name)
      {
        throw new Error(`Field name is undefined: ${JSON.stringify(studioField)}`);
      }

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        checkIfFormExists(selectedPluginVersion, formId);
        const form = selectedPluginVersion.formMap.map[formId];
        const composite = form.compositeMap?.map[parentCompositeId];
        if(!composite)
        {
          throw new Error(`Section is undefined: ${JSON.stringify(studioField)}`);
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(composite.fieldMap,
          studioField,
          studioField.metaId,
          insertIndex
        );
        isDirty && verifyPluginDirty(state, pluginBundleId);
      }
    },

    removePluginFormField: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionRemovePluginFormField>) =>
    {
      const {
        pluginBundleId,
        formId,
        sectionId,
        metaId
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        checkIfFormExists(selectedPluginVersion, formId);
        const pluginFormMap = selectedPluginVersion.formMap;
        const form = pluginFormMap.map[formId];
        const composite = form.compositeMap?.map[sectionId];
        if(composite)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(composite.fieldMap, metaId);
          isDirty && verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    //endregion

    movePluginItem(state: ICacheStudioStatePlugin, action: PayloadAction<ActionMovePluginItem>)
    {
      const {
        pluginBundleId,
        metaId,
        setKey,
        moveDirection
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        let keys = [] as MetaId[];
        switch(setKey)
        {
          case "variableList":
            keys = selectedPluginVersion.varMap?.keys ?? [];
            break;
          case "formList":
            keys = selectedPluginVersion.formMap.keys;
            break;
          case "apiList":
            keys = selectedPluginVersion.apiMap.keys;
        }
        moveListItem(metaId, keys, moveDirection);
        verifyPluginDirty(state, pluginBundleId);
      }
    },

    movePluginFormItem(state: ICacheStudioStatePlugin, action: PayloadAction<ActionMovePluginFormItem>)
    {
      const {
        pluginBundleId,
        formId,
        fieldId,
        compositeId,
        moveDirection
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const form = selectedPluginVersion.formMap.map[formId];
        const compositeMap = form.compositeMap;
        const composite = compositeMap?.map[compositeId];

        if(fieldId)
        {
          moveListItem(fieldId, composite.fieldMap.keys, moveDirection);
          verifyPluginDirty(state, pluginBundleId);
        }
        else if(compositeId)
        {
          moveListItem(compositeId, compositeMap.keys, moveDirection);
          verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    //endregion

    //region plugin api

    addPluginApi: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginApi>) =>
    {
      const {
        pluginBundleId,
        sig,
        insertIndex
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const pluginApiMap = selectedPluginVersion.apiMap;
        if(!pluginApiMap)
        {
          selectedPluginVersion.apiMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(pluginApiMap, sig, sig.metaId, insertIndex);
        isDirty && verifyPluginDirty(state, pluginBundleId);
      }
    },

    removePluginApi: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionRemovePluginApi>) =>
    {
      const {
        pluginBundleId,
        metaId
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const pluginApiMap = selectedPluginVersion.apiMap;
        if(pluginApiMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(pluginApiMap, metaId);
          isDirty && verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    //endregion

    //region resources

    addPluginResourceRpc: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginResourceRpc>) =>
    {
      const {
        pluginBundleId,
        sig
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const resourceMap = selectedPluginVersion.resourceMap;
        const oldSig = resourceMap?.rpc;
        if(resourceMap && !isEqual(oldSig, sig))
        {
          resourceMap.rpc = sig;
          verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    addPluginResourceJar: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginResourceJar>) =>
    {
      const {
        pluginBundleId,
        sig
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const resourceMap = selectedPluginVersion.resourceMap;
        const oldSig = resourceMap?.jar;
        if(resourceMap && !isEqual(oldSig, sig))
        {
          resourceMap.jar = sig;
          verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    addPluginResourceDev: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionAddPluginResourceDev>) =>
    {
      const {
        pluginBundleId,
        sig
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const resourceMap = selectedPluginVersion.resourceMap;
        const oldSig = resourceMap?.dev;
        if(resourceMap && !isEqual(oldSig, sig))
        {
          resourceMap.dev = sig;
          verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    removePluginResource: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionRemovePluginResource>) =>
    {
      const {
        pluginBundleId,
        resourceType
      } = action.payload;

      const selectedPluginVersion = selectPluginVersion(state, pluginBundleId);

      if(selectedPluginVersion)
      {
        const resourceMap = selectedPluginVersion.resourceMap;
        if(resourceMap)
        {
          switch(resourceType)
          {
            case "rpc":
              resourceMap.rpc = undefined;
              break;
            case "jar":
              resourceMap.jar = undefined;
              break;
            case "dev":
              resourceMap.dev = undefined;
          }
          verifyPluginDirty(state, pluginBundleId);
        }
      }
    },

    //endregion

    setCurrentPluginVersion: (state: ICacheStudioStatePlugin, action: PayloadAction<{pluginVersionId?: PluginId}>) =>
    {
      state.selectedVersionId = action.payload.pluginVersionId;
    },

    setPluginStateMap(state: ICacheStudioStatePlugin, action: PayloadAction<ActionPluginStateMap>)
    {
      const {
        pluginBundleId,
        currentTab,
        currentStep
      } = action.payload;

      if(currentTab && state.pluginStateMap[pluginBundleId]?.currentTab !== currentTab)
      {
        state.pluginStateMap[pluginBundleId] = {
          ...state.pluginStateMap[pluginBundleId],
          currentTab: currentTab
        };
      }

      if(currentStep && state.pluginStateMap[pluginBundleId]?.currentStep !== currentStep)
      {
        state.pluginStateMap[pluginBundleId] = {
          ...state.pluginStateMap[pluginBundleId],
          currentStep: currentStep
        };
      }

    },

    setPluginSelectedModules(
      state: ICacheStudioStatePlugin,
      action: PayloadAction<{
        pluginBundleId: PluginBundleId,
        pluginId: PluginId,
        selectedModulesSet: StudioModuleSelection
      }>)
    {
      const selectedModules = action.payload.selectedModulesSet;
      const pluginBundleId = action.payload.pluginBundleId;
      const pluginId = action.payload.pluginId;
      if(pluginBundleId && selectedModules)
      {
        const versionModulesMap = {
          [pluginId]: selectedModules
        } as Record<PluginId, StudioModuleSelection>;

        state.selectedModulesMap[pluginBundleId] = {
          ...versionModulesMap
        } as Record<PluginId, StudioModuleSelection>;
      }
    },

    setPluginEditLockDetail: (state: ICacheStudioStatePlugin, action: PayloadAction<ActionPluginEditLockDetail>) =>
    {
      const {
        pluginBundleId,
        detail
      } = action.payload;

      if(detail)
      {
        state.pluginEditLockDetailMap[pluginBundleId] = detail;
      }
    }
  }
});

function selectPluginVersion(
  state: ICacheStudioStatePlugin,
  pluginBundleId: PluginBundleId
): StudioPlugin | undefined
{
  return state.pluginMap[pluginBundleId]?.studioPluginBundle?.draft?.studioPlugin;
}

function checkIfFormExists(pluginVersion: StudioPlugin, formId: MetaIdForm)
{
  const form = pluginVersion.formMap.map[formId];
  if(!form)
  {
    throw new Error(`Form not found: ${formId}`);
  }
}

function verifyPluginDirty(state: ICacheStudioStatePlugin, pluginBundleId: PluginBundleId)
{
  const dirtyPluginIdList = state.dirtyPluginIdList;
  !dirtyPluginIdList.includes(pluginBundleId) && dirtyPluginIdList.push(pluginBundleId);
  state.pluginStateMap[pluginBundleId] = {
    ...state.pluginStateMap[pluginBundleId],
    dirtyVersion: newGuid()
  };
}

export const {
  setPlugin,
  updatePlugin,
  mergePlugin,
  removePlugin,

  setIsSavingPlugin,

  addDeltaPluginLocalState,
  removeDeltaPluginLocalState,

  setPluginDirty,

  setSearchPluginId,

  setCurrentPluginVersion,
  duplicatePluginVersion,
  updatePluginDetails,
  updatePluginModules,

  setPluginAdminCaller,

  addPluginVariable,
  removePluginVariable,

  addPluginForm,
  removePluginForm,

  addPluginFormComposite,
  removePluginFormComposite,

  addPluginFormField,
  removePluginFormField,

  movePluginItem,
  movePluginFormItem,

  addPluginApi,
  removePluginApi,

  addPluginResourceRpc,
  addPluginResourceJar,
  addPluginResourceDev,
  removePluginResource,

  updatePluginDeploy,

  setPluginAdminList,
  removePluginAdminList,

  setPluginStateMap,
  setPluginSelectedModules,
  setPluginEditLockDetail

} = sliceCacheStudioPlugin.actions;
