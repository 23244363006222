import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoColor} from "../../../api/meta/base/dto/DefnDtoColor";
import {DefnStudioBuildColor} from "../../../api/meta/base/dto/DefnStudioBuildColor";
import {StudioVarColor} from "../../../api/meta/base/dto/StudioVarColor";

export function fnVariableBuilderColor(fieldValueColor: string)
{
  function getVarComp()
  {
    return {
      [fieldValueColor]: {
        type: "studioBuildColor",
        metaId: fieldValueColor,
        label: "Value",
        allowShades: true
      } as DefnStudioBuildColor
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarColor
  )
  {
    valueMap[fieldValueColor] = variable.value as DefnDtoColor;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueColor] as DefnDtoColor;

    return {
      value: value
    } as StudioVarColor;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
