import {useTheme} from "@mui/material";
import {IconButton} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import {TypeTooltip} from "../../../base/types/TypesGlobal";
import {EnumIconStrip} from "../../../base/types/TypesIcon";
import {EnumIconAvatar} from "../../../base/types/TypesIcon";
import AvatarAtom from "../avatar/AvatarAtom";
import {BoxP} from "../box/BoxP";
import {BoxPS} from "../box/BoxPS";
import IconStrip from "../icon/IconStrip";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawDot from "../raw/RawDot";
import RawIcon from "../raw/RawIcon";
import {TypeIconStripColorMap} from "../raw/RawIconStrip";
import {TypeIconStripBadgeMap} from "../raw/RawIconStrip";
import RawIconStrip from "../raw/RawIconStrip";
import RawTooltip from "../raw/RawTooltip";

export default function PaneHeader(props: {
  drawerToggleIcon?: string
  actionIcon?: EnumIconStrip,
  avatarSrc1?: string,
  avatarIcon1?: EnumIconAvatar,
  avatarTooltip1?: TypeTooltip,
  avatarSrc2?: string,
  avatarIcon2?: EnumIconAvatar,
  avatarTooltip2?: TypeTooltip,
  primaryText?: string,
  secondaryText?: string,
  secondaryTextColor?: TypeTextColor,
  iconStrip?: EnumIconStrip[],
  iconStripDisable?: EnumIconStrip[]
  iconStripBadgeMap?: TypeIconStripBadgeMap,
  iconStripColorMap?: TypeIconStripColorMap,
  toolTipMap?: Record<EnumIconStrip, string>
  toolbarIconButtons?: React.ReactNode[]

  // style
  height?: string,
  variant?: Variant
  headerBgColor?: CssBackgroundColor

  // handlers
  onClickAction?: () => void,
  onClickAvatar?: () => void,
  onClickIconStrip?: (iconStrip: EnumIconStrip, menuAnchor: Element) => void,
  onClickHeader?: () => void,
  onClickDrawerToggle?: () => void
})
{
  const theme = useTheme();
  const heightHeader = getListItemHeightAPSA("aps");

  const {
    drawerToggleIcon,
    actionIcon,
    avatarSrc1,
    avatarIcon1,
    avatarSrc2,
    avatarIcon2,
    primaryText,
    secondaryText,
    secondaryTextColor,
    iconStripDisable,
    iconStrip,
    iconStripBadgeMap,
    iconStripColorMap,
    height,
    variant,
    headerBgColor,
    toolbarIconButtons,
    onClickAction,
    onClickAvatar,
    onClickIconStrip,
    onClickHeader,
    onClickDrawerToggle
  } = props;

  const _onClickAvatar = onClickAvatar ?? onClickHeader;

  const gap = theme.common.gapHalf;
  return (
    <LayoutFlexRow
      height={height ?? px(heightHeader)}
      width={"100%"}
      pl={px(theme.common.gapHalf)}
      pr={px(theme.common.gapHalf)}
      bgcolor={headerBgColor ?? theme.common.bgcolorActionBar}
    >
      {drawerToggleIcon
        && (
          drawerToggleIcon === "MenuOpen" ?
            <RawTooltip title={"Collapse drawer"}>
              <IconButton
                size={"small"}
                onClick={onClickDrawerToggle}
              >
                <RawIcon icon={"MenuOpenRounded"} />
              </IconButton>
            </RawTooltip>
            : <RawTooltip title={"Expand drawer"}>
              <IconButton
                sx={{
                  transform: "scaleX(-1)",
                  display: "inline-block"
                }}
                size={"small"}
                onClick={onClickDrawerToggle}
              >
                <RawIcon icon={"MenuOpenRounded"} />
              </IconButton>
            </RawTooltip>
        )
      }
      {actionIcon &&
        <IconButton
          size={"small"}
          onClick={onClickAction}
        >
          <IconStrip value={actionIcon} />
        </IconButton>
      }

      {(avatarSrc1 || avatarIcon1) &&
        <AvatarAtom
          ml={gap}
          mr={gap}
          onClick={_onClickAvatar}
          icon={avatarIcon1}
          bgcolor={theme.common.bgcolorSidePane}
          src={avatarSrc1}
          tooltip={props.avatarTooltip1}
        />
      }

      {(avatarSrc1 && avatarSrc2)
        ? <RawDot
          mr={gap}
          ml={gap}
        />
        : null
      }

      {(avatarSrc2 || avatarIcon2) &&
        <AvatarAtom
          ml={gap}
          mr={gap}
          onClick={_onClickAvatar}
          icon={avatarIcon2}
          src={avatarSrc2}
          tooltip={props.avatarTooltip2}
        />
      }

      {
        secondaryText && secondaryText.length > 0
          ? <BoxPS
            primary={{
              text: primaryText || "",
              variant: variant
            }}
            secondary={{
              text: secondaryText,
              color: secondaryTextColor
            }}
            onClick={onClickHeader}
            flexGrow={1}
            pl={gap}
            pr={gap}
          />
          : <BoxP
            primary={{
              text: primaryText || "",
              variant: variant
            }}
            onClick={onClickHeader}
            flexGrow={1}
            pl={gap}
            pr={gap}
          />
      }

      {toolbarIconButtons}

      {iconStrip &&
        <RawIconStrip
          iconStrip={iconStrip}
          iconStripDisable={iconStripDisable}
          iconStripBadgeMap={iconStripBadgeMap}
          iconStripColorMap={iconStripColorMap}
          toolTipMap={props.toolTipMap}
          onClick={onClickIconStrip}
        />
      }
    </LayoutFlexRow>
  );
}
