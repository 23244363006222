import React from "react";
import {useCallback} from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemP3SS} from "../../../base/types/list/TypesListAPSA";
import {useAppSelector} from "../../app/AppHooks";
import {BoxP3SS} from "../../atom/box/BoxP3SS";
import {CbOnClickListItemInternal} from "../impl/ListItem";
import ListItemShell from "../impl/ListItemShell";

export function ListItemP3SS(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItemInternal,
})
{
  const itemId = props.itemId;
  const onClickInternal = props.onClickListItem;
  const itemHeight = getListItemHeightAPSA("p3ss");
  const searchWords = useAppSelector(state => props.selectList(state).searchWords);
  const item = useAppSelector(state => props.selectList(state).itemsById[itemId]) as IListItemP3SS;
  const primary = item?.primary;
  const secondary1 = item?.secondary1;
  const secondary2 = item?.secondary2;

  const cbOnClickCaption = useCallback((e: React.MouseEvent<HTMLElement>) =>
  {
    onClickInternal && onClickInternal(e.currentTarget, itemId, item, "apsaPrimaryCaption");
  }, [onClickInternal, itemId, item]);

  return (
    <ListItemShell itemHeight={itemHeight} {...props}>
      <BoxP3SS
        primary={primary}
        secondary1={secondary1}
        secondary2={secondary2}
        searchWords={searchWords}
        onClickCaption={cbOnClickCaption}
        flexGrow={1}
      />
    </ListItemShell>
  );
}
