import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {useEffect} from "react";
import React from "react";
import {getDefnDtoColorToCssColor} from "../../../base/plus/FormPlus";
import {dispatchKanban} from "../../../base/plus/KanbanPlus";
import {SelectKanban} from "../../../base/plus/KanbanPlus";
import {px} from "../../../base/plus/StringPlus";
import {kanbanSetDisplaySelectedColumnsByItemId} from "../../../base/slices/kanban/SliceKanbanSharedActions";
import {TypeKanbanItemId} from "../../../base/types/TypeKanban";
import {CbOnChangeKanban} from "../../../base/types/TypeKanban";
import {CbOnClickKanban} from "../../../base/types/TypeKanban";
import {IKanbanBinderAll} from "../../../base/types/TypeKanban";
import {useAppSelector} from "../../app/AppHooks";
import {useAppCtx} from "../../ctx/CtxApp";
import {IKanbanProps} from "../Kanban";
import KanbanCol from "./KanbanCol";
import KanbanColHeader from "./KanbanColHeader";

type KanbanColsProps<SR1, SR2, SR3, SR4, SR5, SR6> = {
  selectKanban: SelectKanban
  maxWidth?: number,
  kanbanBinder?: IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  cbOnClick?: CbOnClickKanban
  cbOnChange?: CbOnChangeKanban,
  onRowsSubscribe?: (itemId: TypeKanbanItemId[]) => void,
  onRowsUnsubscribe?: (itemId: TypeKanbanItemId[]) => void,
};
export default function KanbanCols<SR1, SR2, SR3, SR4, SR5, SR6>(props: IKanbanProps<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const appCtx = useAppCtx();
  const mainWidth = appCtx.getMainWidth();
  const selectKanban = props.selectKanban;

  const displayColumnIds = useAppSelector(state => selectKanban(state)?.displayColumnIds);
  const uiModeMobile = useAppSelector(state => selectKanban(state)?.uiModeMobile);
  const columnMap = useAppSelector(state => selectKanban(state).columnMap);
  const columnId = useAppSelector(state => selectKanban(state).displaySelectedColumnsByItemId);

  const bgcolor = columnId
    ? getDefnDtoColorToCssColor(columnMap[columnId]?.bgcolor)
    : undefined;

  return (
    <Box
      sx={{
        height: "100%",
        bgcolor: bgcolor,
        display: "grid",
        gridTemplateColumns: `repeat(${displayColumnIds?.length}, 1fr)`
      }}
    >
      {
        uiModeMobile
          ? <KanbanColsMobileMode {...props} maxWidth={mainWidth} />
          : <KanbanColsNoMobileMode {...props} maxWidth={mainWidth} />
      }
    </Box>
  );
}

function KanbanColsNoMobileMode<SR1, SR2, SR3, SR4, SR5, SR6>(props: KanbanColsProps<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const theme = useTheme();
  const selectKanban = props.selectKanban;
  const bgcolorDefault = theme.common.bgcolorSidePane;
  const displayColumnIds = useAppSelector(state => selectKanban(state).displayColumnIds);
  const columnMap = useAppSelector(state => selectKanban(state).columnMap);

  return displayColumnIds.map(columnId =>
  {
    const bgcolor = getDefnDtoColorToCssColor(columnMap[columnId]?.bgcolor);
    return (
      <Box
        key={columnId}
        bgcolor={bgcolor || bgcolorDefault}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%"
        }}
      >
        <KanbanColHeader
          columnId={columnId}
          {...props}
        />

        <KanbanCol
          columnId={columnId}
          isDraggable={true}
          {...props}
        />
      </Box>
    );
  });
}

function KanbanColsMobileMode<SR1, SR2, SR3, SR4, SR5, SR6>(props: KanbanColsProps<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const bgcolorDefault = theme.common.bgcolorSidePane;
  const selectKanban = props.selectKanban;
  const columnMap = useAppSelector(state => selectKanban(state).columnMap);
  const columnId = useAppSelector(state => selectKanban(state).displaySelectedColumnsByItemId);
  const kanbanName = useAppSelector(state => selectKanban(state).kanbanName);
  const displayColumnIds = useAppSelector(state => selectKanban(state)?.displayColumnIds);
  const firstColumnId = useAppSelector(state => selectKanban(state)?.displayColumnIds?.[0]);
  const bgcolor = columnId
    ? getDefnDtoColorToCssColor(columnMap[columnId]?.bgcolor)
    : undefined;

  useEffect(() =>
  {
    if(!columnId || !displayColumnIds?.includes(columnId))
    {
      dispatchKanban(kanbanName, kanbanSetDisplaySelectedColumnsByItemId(firstColumnId));
    }
  }, [columnId, displayColumnIds, firstColumnId, kanbanName]);

  if(columnId)
  {
    return (
      <Box
        key={columnId}
        bgcolor={bgcolor || bgcolorDefault}
        padding={px(gapHalf)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%"
        }}
      >
        <KanbanColHeader
          columnId={columnId}
          isColumSwitcher={true}
          {...props}
        />

        <KanbanCol
          columnId={columnId}
          isDraggable={false}
          fullWidth={true}
          {...props}
        />
      </Box>
    );
  }
}
