import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {TypeEnumCaptionButton} from "../../list/List";
import {TypeListCounterPositionVariant} from "../../list/List";
import IconListItemPrimary1 from "../icon/IconListItemPrimary1";
import IconListItemPrimary2 from "../icon/IconListItemPrimary2";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import {LayoutGap} from "../layout/LayoutGap";
import RawCounter from "../raw/RawCounter";
import RawSkeleton from "../raw/RawSkeleton";
import LineCaption from "./LineCaption";
import LineCaptionIconButton from "./LineCaptionIconButton";
import LineTextPrimary from "./LineTextPrimary";

export function LinePrimary(props: {
  primary?: ILinePrimary
  searchWords?: string[],
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickIconButtonCaption?: (e: React.MouseEvent<HTMLButtonElement>, type: TypeEnumCaptionButton) => void,
  onChangeCounterValue?: (e: Element, countValue: number, variant: TypeListCounterPositionVariant) => void,
  mb?: number
})
{
  const theme = useTheme();
  const lineHeight = px(theme.common.heightLine) as string;
  const primary = props.primary;
  const onChangeCounterValue = props.onChangeCounterValue;
  const onClickIconButtonCaption = props.onClickIconButtonCaption;
  const gapQuarter = theme.common.gapQuarter;
  const number = primary?.number;

  if(primary)
  {
    const caption = primary.caption;
    const middle = primary.middle;

    return (
      <LayoutFlexRow
        width={"100%"}
        height={lineHeight}
        mb={px(props.mb)}
        pl={px(primary.pl)}
        justifyContent={!middle ? "space-between" : "start"}
      >
        {
          number?.valueType
            ? <LayoutFlexRow>
              <RawCounter
                position={"right"}
                value={number.value}
                onChange={(e, count) => onChangeCounterValue && onChangeCounterValue(e, count, "text")}
                buttonSize={px(theme.common.heightLine)}
                minVar={0}
              />
            </LayoutFlexRow>
            : <LayoutFlexRow
              {...middle && {maxWidth: "40%"}}
              {...middle ? {width: "100%"} : {width: 0}}
              flexGrow={1}
            >
              <LineTextPrimary
                value={primary.text}
                optional={primary.optional}
                variant={primary.variant}
                color={primary.color}
                searchWords={props.searchWords}
                flexGrow={1}
                width={0}      //this is just a workaround because, truncate need width attribute
                height={"100%"}
                bold={primary.bold}
                hasMarkDownText={primary.hasMarkDownText}
                showTooltip={primary.showTooltip}
              />
            </LayoutFlexRow>
        }

        {
          middle &&
          <LayoutFlexRow
            width={"30%"}
            height={"100%"}
            justifyContent={"start"}
          >
            <LineCaption
              caption={middle}
              height={"100%"}
              width={"30%"}
              textAlign={"left"}
              ml={gapQuarter}
              searchWords={props.searchWords}
              onChangeCounterValue={(e, count) => onChangeCounterValue && onChangeCounterValue(e, count, "middle")}
              overflow={"hidden"}
            />
          </LayoutFlexRow>
        }

        {(primary.icon1 || caption || primary.icon2) && (
          <LayoutFlexRow
            justifyContent={"end"}
            flexGrow={middle ? 1 : undefined}
          >
            {
              primary.icon1 &&
              <>
                <LayoutGap width={gapQuarter} />
                <IconListItemPrimary1 value={primary.icon1} />
              </>
            }

            {
              caption &&
              <LineCaption
                caption={caption}
                height={"100%"}
                textAlign={"right"}
                onClick={props.onClickCaption}
                ml={gapQuarter}
                searchWords={props.searchWords}
                onChangeCounterValue={(e, count) => onChangeCounterValue && onChangeCounterValue(e, count, "caption")}
              />
            }

            {
              caption?.iconButtons &&
              caption.iconButtons.map(iconButton =>
              {
                return (
                  <LineCaptionIconButton
                    captionIconButton={iconButton}
                    onClickIconButton={(e) =>
                    {
                      onClickIconButtonCaption && onClickIconButtonCaption(e, iconButton.type);
                    }}
                  />
                );
              })
            }

            {
              primary.icon2 &&
              <>
                <LayoutGap width={gapQuarter} />
                <IconListItemPrimary2 value={primary.icon2} />
              </>
            }
          </LayoutFlexRow>
        )}
      </LayoutFlexRow>
    );
  }
  else
  {
    return (
      <RawSkeleton
        variant="text"
        height={lineHeight}
        width={"100%"}
        mb={props.mb}
      />
    );
  }
}
