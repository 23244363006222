import {isGridId} from "../../../api/meta/base/ApiPlus";
import {isReportId} from "../../../api/meta/base/ApiPlus";
import {isSpreadsheetId} from "../../../api/meta/base/ApiPlus";
import {StudioComposite} from "../../../api/meta/base/dto/StudioComposite";
import {StudioEntReportMap} from "../../../api/meta/base/dto/StudioEntReportMap";
import {StudioEntSpreadsheetMap} from "../../../api/meta/base/dto/StudioEntSpreadsheetMap";
import {StudioField} from "../../../api/meta/base/dto/StudioField";
import {StudioFieldEditable} from "../../../api/meta/base/dto/StudioFieldEditable";
import {StudioFieldMap} from "../../../api/meta/base/dto/StudioFieldMap";
import {StudioFieldPickGridRow} from "../../../api/meta/base/dto/StudioFieldPickGridRow";
import {StudioFieldPickReportRow} from "../../../api/meta/base/dto/StudioFieldPickReportRow";
import {StudioFieldRef} from "../../../api/meta/base/dto/StudioFieldRef";
import {StudioFieldRefReport} from "../../../api/meta/base/dto/StudioFieldRefReport";
import {StudioFieldRefUser} from "../../../api/meta/base/dto/StudioFieldRefUser";
import {StudioForm} from "../../../api/meta/base/dto/StudioForm";
import {StudioFormMap} from "../../../api/meta/base/dto/StudioFormMap";
import {StudioGrid} from "../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../api/meta/base/dto/StudioSection";
import {StudioVarBoolean} from "../../../api/meta/base/dto/StudioVarBoolean";
import {StudioVarMap} from "../../../api/meta/base/dto/StudioVarMap";
import {EntId} from "../../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../../api/meta/base/Types";
import {MetaId} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {getStudioFormParentMap} from "../../../base/plus/StudioFormPlus";
import {TypeStudioFilterType} from "../../../base/plus/StudioPlus";
import theme from "../../../base/plus/ThemePlus";
import {listSetCanShowMenu} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemMPSL} from "../../../base/types/list/TypesListAPSA";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {IListGroup} from "../../../base/types/list/TypesListGroup";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {ILineCaption} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {Srvc} from "../../Srvc";

type FieldItemVariant =
  | "field"
  | "headerFooterField";

type TypeFieldRef =
  | StudioFieldRef
  | StudioFieldPickReportRow
  | StudioFieldPickGridRow
  | StudioFieldRefReport

export default class SrvcFormBuilder extends ISrvc
{
  loadFormList(
    listName: string,
    formMap: StudioFormMap,
    readOnly?: boolean,
    isPluginForm?: boolean,
    usageFilter?: TypeStudioFilterType,
    formUsageMap?: Record<MetaIdForm, number>
  )
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;
    formMap.keys.forEach((key, index) =>
    {
      const form = formMap.map[key];
      const formId = form.metaId as MetaIdForm;
      const formUsageCount = formUsageMap ? formUsageMap[formId] : undefined;

      const color = isPluginForm && form.configForm
        ? "warningLight"
        : !formUsageCount ? "textDisabled" : undefined;

      if(form)
      {
        const secondaryList = [] as ILineSecondary[];

        form.compositeMap.keys.forEach(section =>
        {
          const composite = form.compositeMap.map[section];
          secondaryList.push({
            text: composite.details.name,
            caption: {
              text: composite.type ? toLabel(composite.type) : undefined,
              ignoreSelection: true
            },
            color: color
          });
        });

        uiItemIds.push(formId);
        uiItemsById[formId] = {
          primary: {
            text: form.details.name,
            caption: {
              iconButtons: !readOnly
                ? [
                  {
                    icon: "EditRounded",
                    color: "primary",
                    tooltip: "Edit form",
                    type: "apsaPrimaryCaptionButtonEdit"
                  },
                  {
                    icon: "AddRounded",
                    color: "primary",
                    tooltip: "Add section or grid",
                    type: "apsaPrimaryCaptionButtonAdd"
                  }
                ]
                : undefined
            },
            ignoreSelection: true,
            color: color
          },
          secondaryList,
          hideMenu: readOnly === true,
          userField: {
            showMoveUp: index !== 0,
            showMoveDown: index < (formMap.keys.length - 1)
          },
          type: "mpsl"
        } as IListItemMPSL;
      }
    });

    //form usages filter
    const filterUiItemIds = uiItemIds.filter(formId =>
    {
      if(formId && usageFilter)
      {
        const formUsageCount = formUsageMap ? formUsageMap[formId] : undefined;
        switch(usageFilter)
        {
          case "SingleUse":
            return formUsageCount === 1;
          case "MultiUse":
            return (formUsageCount || 0) > 1;
          case "Unused":
            return (!formUsageCount);
          case "All":
            return true;
        }
      }
      return true;
    });

    dispatchList(listName, listRefresh({
      itemIds: filterUiItemIds,
      itemsById: uiItemsById
    }));
  }

  loadFormDetailsList(
    listName: string,
    form: StudioForm,
    readOnly: boolean,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    reportMap?: StudioEntReportMap,
    varMap?: StudioVarMap)
  {
    const compositeMap = form.compositeMap;
    const formId = form.metaId;

    const uiItemsById = {} as IListItemsById;
    const uiGroupById = {} as IListGroupsById;

    if(!compositeMap)
    {
      return;
    }
    else
    {
      compositeMap.keys.forEach((key, index) =>
      {
        const composite = compositeMap.map[key];

        const {
          showMoveUp,
          showMoveDown
        } = this.getCanMoveUpAndDownItem(compositeMap.keys, index);

        if(composite.type === "section")
        {
          this.doLoadSection(uiItemsById,
            uiGroupById,
            composite as StudioSection,
            formId,
            showMoveUp,
            showMoveDown,
            spreadsheetMap,
            formMap,
            readOnly,
            reportMap,
            varMap
          );
        }
        else if(composite.type === "grid")
        {
          this.doLoadGrid(uiItemsById,
            uiGroupById,
            composite as StudioGrid,
            formId,
            showMoveUp,
            showMoveDown,
            spreadsheetMap,
            formMap,
            readOnly,
            reportMap,
            varMap
          );
        }
      });
    }

    dispatchList(listName, listRefresh({
      groupsById: uiGroupById,
      itemsById: uiItemsById,
      userField: {
        loaded: true
      }
    }));

    dispatchList(listName, listSetCanShowMenu(!readOnly));
  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    Srvc.cache.studio.ent.forms.setUsageFilter(entId, filterType);
  }

  private doLoadSection(
    uiItemsById: IListItemsById,
    uiGroupById: IListGroupsById,
    studioSection: StudioSection,
    formId: MetaIdForm,
    showMoveUp: boolean,
    showMoveDown: boolean,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    readOnly?: boolean,
    reportMap?: StudioEntReportMap,
    varMap?: StudioVarMap)
  {

    this.doLoadItemComposite(uiItemsById,
      uiGroupById,
      studioSection,
      showMoveUp,
      showMoveDown,
      readOnly
    );

    this.doLoadItemCompositeFields(uiItemsById,
      uiGroupById,
      studioSection,
      formId,
      "field",
      spreadsheetMap,
      formMap,
      readOnly,
      reportMap,
      varMap
    );
  }

  private doLoadGrid(
    uiItemsById: IListItemsById,
    uiGroupById: IListGroupsById,
    studioGrid: StudioGrid,
    formId: MetaIdForm,
    showMoveUp: boolean,
    showMoveDown: boolean,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    readOnly?: boolean,
    reportMap?: StudioEntReportMap,
    varMap?: StudioVarMap)
  {

    this.doLoadItemComposite(uiItemsById,
      uiGroupById,
      studioGrid,
      showMoveUp,
      showMoveDown,
      readOnly
    );

    this.doLoadItemCompositeFields(uiItemsById,
      uiGroupById,
      studioGrid,
      formId,
      "field",
      spreadsheetMap,
      formMap,
      readOnly,
      reportMap,
      varMap
    );
  }

  private doLoadItemComposite(
    uiItemsById: IListItemsById,
    uiGroupById: IListGroupsById,
    composite: StudioComposite,
    showMoveUp: boolean,
    showMoveDown: boolean,
    readOnly?: boolean)
  {
    const metaId = (composite as StudioSection | StudioGrid).metaId;

    uiGroupById[metaId] = {
      itemIds: []
    } as IListGroup;

    uiItemsById[metaId] = {
      type: "listGroup",
      hideMenu: readOnly === true,
      disableDrag: true,
      header: {
        text: composite.details.name,
        variant: "subtitle1",
        expand: true,
        middle: {
          text: composite.type ? toLabel(composite.type) : "",
          type: "text",
          color: "primaryLight",
          variant: "caption",
          ignoreSelection: true
        },
        caption: {
          iconButtons: !readOnly
            ? [
              {
                icon: "AddRounded",
                color: "primary",
                tooltip: "Add field",
                type: "apsaPrimaryCaptionButtonAdd"
              }
            ]
            : undefined
        },
        mt: theme.common.gapHalf,
        mb: theme.common.gapHalf
      },
      userField: {
        item: composite,
        variant: "composite",
        showMoveUp,
        showMoveDown
      }
    } as IListItemGroup;
  }

  private doLoadItemCompositeFields(
    uiItemsById: IListItemsById,
    uiGroupById: IListGroupsById,
    composite: StudioComposite,
    formId: MetaIdForm,
    variant: FieldItemVariant,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    readOnly?: boolean,
    reportMap?: StudioEntReportMap,
    varMap?: StudioVarMap)
  {
    const metaId = (composite as StudioSection | StudioGrid).metaId;

    composite.fieldMap?.keys.forEach((metaIdField, index) =>
    {
      const field = composite.fieldMap?.map[metaIdField];
      const {
        showMoveUp,
        showMoveDown
      } = this.getCanMoveUpAndDownItem(composite.fieldMap?.keys, index);

      if(field)
      {
        this.doLoadItemField(uiItemsById,
          uiGroupById,
          field,
          formId,
          composite.fieldMap,
          metaId,
          showMoveUp,
          showMoveDown,
          variant,
          spreadsheetMap,
          formMap,
          readOnly,
          reportMap,
          varMap
        );
      }
    });
  }

  private doLoadItemField(
    uiItemsById: IListItemsById,
    uiGroupById: IListGroupsById,
    field: StudioField,
    formId: MetaIdForm,
    fieldMap: StudioFieldMap,
    parentId: MetaIdField,
    showMoveUp: boolean,
    showMoveDown: boolean,
    variant: FieldItemVariant,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    readOnly?: boolean,
    reportMap?: StudioEntReportMap,
    varMap?: StudioVarMap)
  {
    const metaIdField = field.metaId;
    if(!uiGroupById[parentId])
    {
      uiGroupById[parentId] = {
        itemIds: []
      } as IListGroup;
    }

    (uiGroupById[parentId] as IListGroup).itemIds.push(metaIdField);

    const middle = this.doLoadRefFieldMiddle(formId, field, fieldMap, spreadsheetMap, formMap, reportMap, varMap);

    const sigma = this.doLoadSigmaSymbol(formId, metaIdField, formMap);

    const fieldEditable = field as StudioFieldEditable;
    const requiredVarId = fieldEditable.requiredVarId;
    const variableValue = requiredVarId
      ? (varMap?.map[requiredVarId] as StudioVarBoolean)?.value?.value
      : undefined;
    const fieldName = (Boolean(fieldEditable.required) || Boolean(variableValue))
      ? field.details.name + " *" + sigma
      : field.details.name + sigma;

    uiItemsById[metaIdField] = {
      type: "p",
      bgColor: variant === "headerFooterField" ? theme.common.bgcolorSidePane : undefined,
      primary: {
        text: fieldName,
        pl: theme.common.listGroupItemGap,
        caption: {
          text: field.type ? toLabel(field.type) : "",
          ignoreSelection: true
        },
        middle: middle
      },
      hideMenu: readOnly === true,
      userField: {
        item: field,
        variant: variant,
        showMoveUp,
        showMoveDown
      }
    } as IListItemAPSA;
  }

  private getCanMoveUpAndDownItem(keys?: MetaId[], index?: number)
  {
    if(keys)
    {
      return {
        showMoveUp: keys.length !== 1 && index !== 0,
        showMoveDown: keys.length !== 1 && index !== keys.length - 1
      };
    }
    else
    {
      return {
        showMoveUp: false,
        showMoveDown: false
      };
    }
  }

  private doLoadRefFieldMiddle(
    _formId: MetaIdForm,
    field: StudioFieldEditable,
    fieldMap: StudioFieldMap,
    spreadsheetMap?: StudioEntSpreadsheetMap,
    formMap?: StudioFormMap,
    reportMap?: StudioEntReportMap,
    varMap?: StudioVarMap)
  {
    const fieldType = field.type;

    let refField: TypeFieldRef | undefined;

    switch(fieldType)
    {
      case "ref":
        refField = field as StudioFieldRef;
        break;
      case "pickReportRow":
        refField = field as StudioFieldPickReportRow;
        break;
      case "refUser":
        refField = field as StudioFieldRefUser;
        break;
      case "pickGridRow":
        refField = field as StudioFieldPickGridRow;
        break;
      case "refReport":
        refField = field as StudioFieldRefReport;
        break;
    }

    const isRefFieldChild = (field.refFieldId);

    if(refField)
    {
      let name: string | undefined;
      const refUserMiddleName = "User settings";

      switch(fieldType)
      {
        case "ref":
          const spreadsheetId = (refField as StudioFieldRef)?.spreadsheetId;
          const spreadsheet = spreadsheetId
            ? spreadsheetMap?.map[spreadsheetId]
            : undefined;
          name = spreadsheet?.details.name;
          break;
        case "refUser":
          name = refUserMiddleName;
          break;
        case "pickReportRow":
          const reportId = (refField as StudioFieldPickReportRow)?.reportId;
          const report = reportId
            ? reportMap?.map[reportId]
            : undefined;
          name = report?.details.name;
          break;
        case "refReport":
          const refReportId = (refField as StudioFieldRefReport)?.reportId;
          const refReport = refReportId
            ? reportMap?.map[refReportId]
            : undefined;
          name = refReport?.details.name;
          break;
        case "pickGridRow":
          const form = formMap?.map[_formId];
          const compositeMap = form?.compositeMap;
          const gridId = (refField as StudioFieldPickGridRow).gridId;
          const grid = (compositeMap && gridId)
            ? compositeMap.map[gridId]
            : undefined;
          name = grid?.details.name;
          break;
      }

      return {
        text: `${name || ""}`,
        color: "successLight"
      } as ILineCaption;
    }
    else if(isRefFieldChild)
    {
      let middleText;
      const _refField = field.refFieldId
        ? fieldMap.map[field.refFieldId] as TypeFieldRef
        : undefined;
      const _refUserField = field.refFieldId
        ? fieldMap.map[field.refFieldId] as StudioFieldRefUser
        : undefined;

      if(_refField?.copyFieldMap && EnumArrayDefnFields.includes(_refField.copyFieldMap[field.metaId]))
      {
        middleText = "<> System field";
      }
      else if(_refUserField?.copyFieldVarMap)
      {
        const refTargetId = field?.refTargetId;
        const variableName = refTargetId
          ? varMap?.map[refTargetId]?.details.name
          : undefined;
        middleText = `<> ${variableName}`;
      }
      else
      {
        const refTargetId = field?.refTargetId;

        let formId: MetaIdForm | undefined;

        if(refTargetId)
        {
          if(isSpreadsheetId(refTargetId))
          {
            const spreadsheet = spreadsheetMap?.map[refTargetId];
            formId = spreadsheet?.formId;
          }
          else if(isReportId(refTargetId))
          {
            const report = reportMap?.map[refTargetId];
            formId = report?.outputFormId;
          }
          else if(isGridId(refTargetId))
          {
            formId = _formId;
          }
        }

        const form = formId
          ? formMap?.map[formId]
          : undefined;

        const parentMap = getStudioFormParentMap(form);

        const _refFieldId = _refField?.copyFieldMap
          ? _refField?.copyFieldMap[field.metaId]
          : undefined;

        const parent = _refFieldId
          ? parentMap[_refFieldId]
          : undefined;

        const spreadsheetFieldName = _refFieldId
          ? parent?.fieldMap?.map[_refFieldId]?.details.name
          : undefined;

        middleText = spreadsheetFieldName
          ? `<> ${spreadsheetFieldName}`
          : "";
      }

      return {
        text: middleText,
        color: "successLight"
      } as ILineCaption;

    }

  }

  private doLoadSigmaSymbol(formId: MetaIdForm, metaIdField: MetaIdField, formMap?: StudioFormMap): string
  {
    let sigma = "";
    if(formMap && formMap.keys.length > 0)
    {
      const formulaMap = formMap.map[formId]?.formulaMap;
      if(formulaMap && formulaMap?.keys && formulaMap.keys.length > 0)
      {
        formulaMap.keys.forEach(formulaId =>
        {
          const formula = formulaMap.map[formulaId];
          if(formula?.assignToFieldId === metaIdField)
          {
            sigma = " Σ";
          }
        });
      }
    }
    return sigma;
  }

}

