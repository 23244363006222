import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {IAuthState} from "../types/TypesAuth";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export const sliceAuth = createSlice({
  name: "auth",
  initialState: {} as IAuthState,
  reducers: {
    setFlagBearerToken: (state, action: PayloadAction<boolean>) =>
    {
      state.flagBearerToken = action.payload;
    }
  }
});

export const {
  setFlagBearerToken
} = sliceAuth.actions;

