import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {debounce} from "lodash";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {Droppable} from "react-beautiful-dnd";
import {ListRange} from "react-virtuoso";
import {Virtuoso} from "react-virtuoso";
import {SelectKanban} from "../../../base/plus/KanbanPlus";
import {px} from "../../../base/plus/StringPlus";
import {CbOnChangeKanban} from "../../../base/types/TypeKanban";
import {CbOnClickKanban} from "../../../base/types/TypeKanban";
import {TypeKanbanItemId} from "../../../base/types/TypeKanban";
import {IKanbanBinderAll} from "../../../base/types/TypeKanban";
import {TypeKanbanColId} from "../../../base/types/TypeKanban";
import {useWinHeight} from "../../app/AppHooks";
import {useAppSelector} from "../../app/AppHooks";
import RawNothingHere from "../../atom/raw/RawNothingHere";
import {HeightPreservingItem} from "../../list/impl/ListDraggable";
import {useKanbanColSubscription} from "./hooks/KanbanPubSub";
import {UiKanbanColItem} from "./KanbanColItem";
import KanbanColItem from "./KanbanColItem";

export default function KanbanCol<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  columnId: TypeKanbanColId,
  selectKanban: SelectKanban,
  isDraggable: boolean,
  maxWidth?: number,
  fullWidth?: boolean,
  cbOnClick?: CbOnClickKanban,
  cbOnChange?: CbOnChangeKanban,
  kanbanBinder?: IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  onRowsSubscribe?: (itemIds: TypeKanbanItemId[]) => void,
  onRowsUnsubscribe?: (itemIds: TypeKanbanItemId[]) => void,
})
{
  const theme = useTheme();
  const columnId = props.columnId;
  const isDraggable = props.isDraggable;
  const fullWidth = props.fullWidth;
  const maxWidth = props.maxWidth;
  const selectKanban = props.selectKanban;
  const displayColumnsByItemIds = useAppSelector(state =>
    selectKanban(state).displayColumnsByItemIds[columnId].itemIds);
  const gapStd = theme.common.gapStd;
  const gapHalf = theme.common.gapHalf;
  const gapQuarter = theme.common.gapQuarter;

  const calcBubbleFixedWidth = theme.common.calcBubbleFixedWidth(maxWidth);
  const {itemWidth, colWidth} = useMemo(() =>
  {
    const colWidth = fullWidth
      ? (maxWidth ? px(maxWidth - gapStd) : undefined)
      : px(calcBubbleFixedWidth + gapStd + gapStd + gapQuarter);

    const itemWidth = fullWidth
      ? (maxWidth ? px(maxWidth - gapStd) : undefined)
      : px(calcBubbleFixedWidth);

    return {colWidth, itemWidth};
  }, [fullWidth, maxWidth, gapStd, calcBubbleFixedWidth, gapQuarter]);

  const calcSubscription = useKanbanColSubscription({
    displayColumnsByItemIds: displayColumnsByItemIds,
    onRowsSubscribe: (rowIds: TypeKanbanItemId[]) =>
      props.onRowsSubscribe && props.onRowsSubscribe(rowIds),
    onRowsUnsubscribe: (rowIds: TypeKanbanItemId[]) =>
      props.onRowsUnsubscribe && props.onRowsUnsubscribe(rowIds)
  });

  if(isDraggable)
  {
    return (
      <Droppable
        direction={"vertical"}
        key={columnId}
        droppableId={columnId}
        mode={"virtual"}
        renderClone={(provided, snapshot, rubric) =>
        {
          const itemId = displayColumnsByItemIds[rubric.source.index];
          return (
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                userSelect: "none",
                pb: px(gapHalf),
                "&:last-child": {
                  pb: 0
                }
              }}
              className={`item ${snapshot.isDragging ? "is-dragging" : ""}`}
            >
              <UiKanbanColItem
                key={itemId}
                itemId={itemId}
                columnId={columnId}
                index={rubric.source.index}
                selectKanban={props.selectKanban}
                maxWidth={props.maxWidth}
                width={itemWidth}
                kanbanBinder={props.kanbanBinder}
              />
            </Box>
          );
        }}
      >
        {(provided) => (
          <div
            key={columnId}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              height: "100%",
              width: "100%"
            }}
          >
            <KanbanVirtuoso
              key={columnId}
              columnId={columnId}
              selectKanban={selectKanban}
              isDraggable={props.isDraggable}
              maxWidth={maxWidth}
              fullWidth={fullWidth}
              height={"100%"}
              colWidth={colWidth}
              itemWidth={itemWidth}
              cbOnClick={props.cbOnClick}
              cbOnChange={props.cbOnChange}
              kanbanBinder={props.kanbanBinder}
              scrollerRef={provided.innerRef}
              onScrollStop={calcSubscription}
            />
          </div>
        )}
      </Droppable>
    );
  }
  return <KanbanVirtuoso
    key={columnId}
    columnId={columnId}
    selectKanban={selectKanban}
    isDraggable={props.isDraggable}
    maxWidth={maxWidth}
    fullWidth={fullWidth}
    height={"100%"}
    colWidth={colWidth}
    itemWidth={itemWidth}
    cbOnClick={props.cbOnClick}
    cbOnChange={props.cbOnChange}
    kanbanBinder={props.kanbanBinder}
    onScrollStop={calcSubscription}
  />;
}

function KanbanVirtuoso<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  columnId: TypeKanbanColId,
  selectKanban: SelectKanban,
  isDraggable: boolean,
  maxWidth?: number,
  fullWidth?: boolean,
  height?: string,
  colWidth?: string,
  itemWidth?: string,
  cbOnClick?: CbOnClickKanban,
  cbOnChange?: CbOnChangeKanban,
  kanbanBinder?: IKanbanBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  onScrollStop?: (visibleRowIds: TypeKanbanItemId[]) => void
  scrollerRef?: (ref: HTMLElement | null) => void
})
{
  const columnId = props.columnId;
  const selectKanban = props.selectKanban;
  const maxWidth = props.maxWidth;
  const height = props.height;
  const colWidth = props.colWidth;
  const itemWidth = props.itemWidth;
  const scrollerRef = props.scrollerRef;
  const onScrollStop = props.onScrollStop;

  const winHeight = useWinHeight();
  const displayColumnsByItemIds = useAppSelector(state =>
    selectKanban(state).displayColumnsByItemIds[columnId].itemIds);
  const cbComputeItemKey = useCallback((index: number) =>
    displayColumnsByItemIds[index], [displayColumnsByItemIds]);

  const onScrollSeekEnter = debounce(useCallback((velocity: number, range: ListRange) =>
  {
    if(velocity === 0)
    {
      const from = range.startIndex;
      const to = range.endIndex;

      const rowIds = displayColumnsByItemIds.slice(from, to + 1);
      onScrollStop && onScrollStop(rowIds);
    }
    return false;
  }, [onScrollStop, displayColumnsByItemIds]), 500);

  return (
    <Virtuoso
      style={{
        width: colWidth,
        height: height
      }}
      scrollSeekConfiguration={{
        enter: (velocity, range) =>
        {
          onScrollSeekEnter(velocity, range);
          return false;
        },
        exit: () => false
      }}
      totalCount={displayColumnsByItemIds.length}
      components={{
        Item: HeightPreservingItem,
        "EmptyPlaceholder": () => <RawNothingHere />
      }}
      scrollerRef={scrollerRef as (ref: HTMLElement | Window | null) => any}
      overscan={winHeight / 2}
      computeItemKey={cbComputeItemKey}
      itemContent={(index) =>
      {
        const itemId = displayColumnsByItemIds[index];
        return (
          <KanbanColItem
            columnId={columnId}
            itemId={itemId}
            index={index}
            selectKanban={selectKanban}
            key={itemId}
            maxWidth={maxWidth}
            width={itemWidth}
            cbOnClick={props.cbOnClick}
            cbOnChange={props.cbOnChange}
            isDraggable={props.isDraggable}
            kanbanBinder={props.kanbanBinder}
          />
        );
      }}
    />
  );
}
