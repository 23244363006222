import {isEmpty} from "lodash";
import {nextPluginApiId} from "../../../../api/meta/base/ApiPlus";
import {StudioPluginApi} from "../../../../api/meta/base/dto/StudioPluginApi";
import {PluginApiId, PluginBundleId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioPluginBundle} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {dispatchPlugin} from "../../SrvcCacheStudio";
import {addPluginApi, movePluginItem, removePluginApi} from "../SliceCacheStudioPlugin";
import {IDtoPluginCopy} from "../TypesCacheStudioPlugin";

export default class SrvcCacheStudioPluginApis extends ISrvc
{
  addPluginApi(pluginBundleId: PluginBundleId, pluginApi: StudioPluginApi)
  {
    dispatchPlugin(pluginBundleId, addPluginApi({
      pluginBundleId: pluginBundleId,
      sig: {
        ...pluginApi,
        metaId: pluginApi.metaId || nextPluginApiId()
      }
    }));
  }

  removePluginApi(pluginBundleId: PluginBundleId, pluginApiId: PluginApiId)
  {
    dispatchPlugin(pluginBundleId, removePluginApi({
      pluginBundleId: pluginBundleId,
      metaId: pluginApiId
    }));
  }

  copyPluginApi(pluginBundleId: PluginBundleId, pluginApiId: PluginApiId)
  {
    const selectedVersion = selectCacheStudioPluginBundle(store.getState(),
      pluginBundleId
    )?.draft?.studioPlugin;

    if(selectedVersion)
    {
      const pluginApiMap = selectedVersion.apiMap;
      const pluginApi = pluginApiMap?.map[pluginApiId];

      if(!isEmpty(pluginApi))
      {
        const payloadCopy: IDtoPluginCopy = {
          type: "api",
          payload: pluginApi
        };
        copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
      }
    }
  }

  duplicatePluginApi(pluginBundleId: PluginBundleId, pluginApiId: PluginApiId)
  {
    const selectedVersion = selectCacheStudioPluginBundle(store.getState(),
      pluginBundleId
    )?.draft?.studioPlugin;

    if(selectedVersion)
    {
      const pluginApiMap = selectedVersion.apiMap;
      if(!pluginApiMap)
      {
        return;
      }

      const index = pluginApiMap.keys.indexOf(pluginApiId);
      if(index === -1)
      {
        return;
      }

      const pluginApi = pluginApiMap.map[pluginApiId]
        ? JSON.parse(updateAllMetaIds(JSON.stringify(pluginApiMap.map[pluginApiId])))
        : undefined;
      if(pluginApi)
      {
        dispatchPlugin(pluginBundleId, addPluginApi({
          pluginBundleId: pluginBundleId,
          sig: pluginApi,
          insertIndex: index + 1
        }));
      }
    }
  }

  moveItem(
    pluginBundleId: PluginBundleId,
    metaId: PluginApiId,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(pluginBundleId, movePluginItem({
      pluginBundleId,
      metaId,
      setKey: "apiList",
      moveDirection
    }));
  }

}
