import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {forwardRef} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnFieldDateTime} from "../../../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueDate} from "../../../../api/meta/base/dto/FieldValueDate";
import {is12HourFormat} from "../../../../base/plus/DatePlus";
import {getLocalDateFormat} from "../../../../base/plus/DatePlus";
import {getBuildDateDefaultValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {getRawIcon} from "../../../atom/raw/RawIcon";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLocalizationProvider from "../../../atom/raw/RawLocalizationProvider";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

interface IFieldDateTime
{
  defn: DefnFieldDateTime
  fieldValue?: FieldValueDate,
  label?: string,
  error?: FieldError,
  isTouched?: boolean,
  onBlur: (value: any) => void
  onChange: (value?: FieldValueDate | null) => void,
  readOnly?: boolean,
  disabled?: boolean,
  required?: boolean,
  placeHolder?: string,
  icon?: string,
  minDateTime?: string,
  maxDateTime?: string,
}

export default function FieldDateTime(props: {
  defn: DefnFieldDateTime
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon,
    getFieldMinDateTime,
    getFieldMaxDateTime
  } = useFieldPropertiesResolver(defn);

  const minDateTime = getFieldMinDateTime();
  const maxDateTime = getFieldMaxDateTime();

  const buildMinDateTime = minDateTime && getBuildDateDefaultValue(minDateTime,
    false,
    minDateTime.time
  );
  const buildMaxDateTime = maxDateTime && getBuildDateDefaultValue(maxDateTime,
    false,
    maxDateTime.time
  );

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueDate | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <UiFieldDateTime
                fieldValue={fieldValue}
                label={label}
                defn={defn}
                error={error}
                isTouched={isTouched}
                onBlur={field.onBlur}
                onChange={field.onChange}
                readOnly={readOnly}
                disabled={disabled}
                ref={field.ref}
                required={required}
                placeHolder={placeHolder}
                icon={icon}
                minDateTime={buildMinDateTime}
                maxDateTime={buildMaxDateTime}
              />
              <FormHelperText
                error={isError}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1
                }}
              >
                {error?.message ? error.message : helperText}
              </FormHelperText>
            </FormControl>
          </FieldRawTemplate>
        );
      }
      }
    />
  );
}

const UiFieldDateTime = forwardRef<HTMLDivElement, IFieldDateTime>((
  props: IFieldDateTime,
  ref) =>
{
  const formCtx = useFormCtx();
  const fieldValue = props.fieldValue;
  const defn = props.defn;
  const readOnly = props.readOnly;
  const disabled = props.disabled;
  const required = props.required;
  const placeHolder = props.placeHolder;
  const icon = props.icon;
  const fieldId = defn.metaId;
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const isError = Boolean(props.error);
  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;
  const hideLabel = defn.hideLabel;

  const dateTime = defn?.displayDateFormat
    ? defn.displayDateFormat
    : getLocalDateFormat();

  const minDateTime = props.minDateTime ? new Date(props.minDateTime) : undefined;
  const maxDateTime = props.maxDateTime ? new Date(props.maxDateTime) : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const is12hrFormat = is12HourFormat(dateTime);

  return (
    <LayoutFlexRow
      height={"100%"}
      width={"100%"}
      overflowY={"visible"}
      overflowX={"visible"}
      onClick={() =>
      {
        onClick && onClick();
      }}
    >
      <RawLocalizationProvider>
        <DateTimePicker
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          ref={ref}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          value={fieldValue ? new Date(fieldValue.value) : null}
          label={!hideLabel ? props.label : ""}
          disabled={formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled}
          readOnly={formCtx.isFieldReadonly(defn)}
          autoFocus={Boolean(defn.autoFocus)}
          format={dateTime}
          views={["year", "month", "day", "hours", "minutes", "seconds"]}
          ampm={is12hrFormat}
          ampmInClock={is12hrFormat}
          timeSteps={{
            hours: 1,
            minutes: 1,
            seconds: 1
          }}
          onChange={(event, context) =>
          {
            if(event && !context.validationError)
            {
              const date = new Date(event).toISOString();
              props.onChange({
                value: date
              } as FieldValueDate);
            }
          }}
          slots={{openPickerIcon: getRawIcon(icon ?? "InsertInvitation")}}
          slotProps={{
            textField: {
              fullWidth: true,
              // type: "text",
              size: defnTheme.fieldSize,
              margin: defnTheme.fieldMargin,
              variant: fieldVariant,
              autoComplete: Boolean(defn.autoFill) ? "on" : "off",
              required: Boolean(required),
              onBlur: props.onBlur,
              error: isError,
              placeholder: placeHolder
            }
          }}
        />
      </RawLocalizationProvider>

      <LayoutFlexRow
        ml={px(gapHalf)}
        mr={`-${gapHalf}px`}
      >
        <RawIconStrip
          iconStrip={["delete"]}
          onClick={(icon) =>
          {
            if(icon === "delete")
            {
              props.onChange(null);
            }
          }}
          iconStripDisable={(!fieldValue || readOnly || disabled) ? ["delete"] : []}
        />
      </LayoutFlexRow>
      <FieldRawRefButton
        defn={defn}
      />
    </LayoutFlexRow>
  );
});
