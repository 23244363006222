import {EnumDeeplinkActionType} from "../../../../api/core/base/Types";
import {WsocAside} from "../../../../api/home/aside/WsocAside";
import {SysId} from "../../../../api/meta/base/SysId";
import ISrvc from "../../../../base/ISrvc";

export default class SrvcHomeAsideGroupInvite extends ISrvc
{
  wsocGroupInviteLinkGet(
    aboutId: SysId,
    action: EnumDeeplinkActionType,
    requiredSignIn: boolean,
    cbSuccess: (link: string) => void,
    reset?: boolean)
  {
    const msg = {
      groupId: aboutId,
      reset: reset
    };

    WsocAside.groupInviteLinkGet(msg, envSig =>
    {
      if(envSig.sig?.url)
      {
        cbSuccess(envSig.sig.url);
      }
    });
  }
}
