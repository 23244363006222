import {Property} from "csstype";
import React from "react";
import {MediaIdImage} from "../../../../../api/meta/base/Types";
import {usePageCtx} from "../../../../ctx/CtxPage";
import ImageViewDialog from "../../../avatar/ImageViewDialog";
import RawLazyImage from "../../../raw/RawLazyImage";

export default function BubbleRawMediaImage(props: {
  blurSrc?: MediaIdImage;
  src?: MediaIdImage;
  primaryColor?: string;
  width?: Property.Width,
  height?: Property.Height,
  actualHeight?: number,
  actualWidth?: number
  borderRadius?: Property.BorderRadius,
  borderTopLeftRadius?: Property.BorderTopLeftRadius,
  borderTopRightRadius?: Property.BorderTopRightRadius,
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius,
  borderBottomRightRadius?: Property.BorderBottomRightRadius,
  disableOnClick?: boolean
})
{
  const pageCtx = usePageCtx();

  const {
    blurSrc,
    src,
    primaryColor,
    disableOnClick
  } = props;

  const onClick = (e: React.MouseEvent<HTMLElement>) =>
  {
    if(disableOnClick)
    {
      return;
    }

    e.stopPropagation();
    pageCtx.showDialog(<ImageViewDialog
      src={src}
      srcBlur={blurSrc}
      width={props.actualWidth}
      height={props.actualHeight}
      bgColor={primaryColor}
      objectFit={"cover"}
      onClose={() => pageCtx.showDialog(undefined)}
    />);
  };

  return (
    <RawLazyImage
      {...props}
      src={src}
      srcBlur={blurSrc}
      primaryColor={primaryColor}
      objectFit={"cover"}
      onClick={onClick}
    />
  );
}
