import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {ListItemType} from "../../../base/types/list/TypesList";
import {getListItemHeightGap} from "../../../base/types/list/TypesListGap";
import {ListItemTypeGap} from "../../../base/types/list/TypesListGap";
import {LayoutGap} from "../../atom/layout/LayoutGap";

export function ListItemGap(props: {
  itemType?: ListItemType,
})
{
  const theme = useTheme();
  const itemHeight = getListItemHeightGap(props.itemType as ListItemTypeGap ?? "gapQuarter");

  return (
    <LayoutGap width={"100%"} height={px(itemHeight)} bgcolor={theme.common.bgcolorHover} />
  );
}
