import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldChipSetDate} from "../../../../api/meta/base/dto/DefnFieldChipSetDate";
import {StudioSetOfDate} from "../../../../api/meta/base/dto/StudioSetOfDate";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawDateSet from "../../../atom/raw/RawDateSet";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldDateSet(props: {
  defn: DefnFieldChipSetDate
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const hideLabel = defn.hideLabel;

  const readonly = formCtx.isReadonly();
  const disabled = formCtx.isFieldDisable(defn as DefnFieldChipSetDate) || defn.disabled;

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const helperText = getFieldHelperText();
  const icon = getFieldIcon();
  const label = required ? getCompLabel(defn) + " *" : getCompLabel(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;
        const isError = Boolean(error);

        const fieldValue = field.value as StudioSetOfDate | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowY={"visible"}
                overflowX={"visible"}
              >
                <RawDateSet
                  defn={defn}
                  onChange={field.onChange}
                  disabled={disabled}
                  readonly={readonly}
                  value={fieldValue}
                  placeHolder={placeHolder}
                  error={error}
                  name={defn.name}
                  label={label}
                  allowDuplicate={false}
                  inputFieldSize={defnTheme.fieldSize}
                  hideLabel={hideLabel}
                  required={required}
                  formCtx={formCtx}
                  fieldId={fieldId}
                  icon={icon}
                />
                <FieldRawRefButton defn={defn} />
              </LayoutFlexRow>
              <FormHelperText
                error={isError}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1
                }}
              >
                {error?.message ? error.message : helperText}
              </FormHelperText>
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
