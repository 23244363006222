import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickVarId from "../raw/FieldRawStudioPickVarId";

export default function FieldStudioSetOfVarId(props: {
  defn: DefnStudioPickVarId
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickVarId
        fieldProps={fieldProps}
        defn={props.defn}
        multiSelect={true}
        showChip={true}
        formId={defn.formId}
      />}
    />
  );
}
