// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgEntUserGet} from './msg/MsgEntUserGet';
import {MsgNeoQLResult} from './msg/MsgNeoQLResult';
import {MsgPluginApiSpecGet} from './msg/MsgPluginApiSpecGet';
import {MsgVersion} from '../../core/base/msg/MsgVersion';
import {PluginBundleId} from '../../meta/base/Types';
import {ServiceName} from '../../meta/base/Types';
import {SigEntDeployStatus} from './sig/SigEntDeployStatus';
import {SigEntUser} from './sig/SigEntUser';
import {SigMediaIdDocument} from '../studioEnt/sig/SigMediaIdDocument';
import {SigNeoQLResult} from './sig/SigNeoQLResult';
import {SigPluginApiSpec} from './sig/SigPluginApiSpec';
import {SigSysDefnFormMapGet} from './sig/SigSysDefnFormMapGet';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocStudioMain
{
  static readonly SN: ServiceName = "studioMain";

  static entUserGet(msg: MsgEntUserGet, sigAcceptor: ISigAcceptor<SigEntUser>): void
  {
    wsocCall<SigEntUser>(ENT_ID_GLOBAL, WsocStudioMain.SN, "entUserGet")
      .get(msg, sigAcceptor);
  }

  static neoQLResultGet(entId: EntId, msg: MsgNeoQLResult, sigAcceptor: ISigAcceptor<SigNeoQLResult>): void
  {
    wsocCall<SigNeoQLResult>(entId, WsocStudioMain.SN, "neoQLResultGet")
      .post(msg, sigAcceptor);
  }

  static pluginApiSpecGet(entId: EntId, msg: MsgPluginApiSpecGet, sigAcceptor: ISigAcceptor<SigPluginApiSpec>): void
  {
    wsocCall<SigPluginApiSpec>(entId, WsocStudioMain.SN, "pluginApiSpecGet")
      .get(msg, sigAcceptor);
  }

  static pluginSourceCodeDownload(pluginBundleId: PluginBundleId, sigAcceptor: ISigAcceptor<SigMediaIdDocument>): void
  {
    wsocCall<SigMediaIdDocument>(pluginBundleId, WsocStudioMain.SN, "pluginSourceCodeDownload")
      .get(undefined, sigAcceptor);
  }

  static studioEntDeployStatusGet(entId: EntId, msg: MsgVersion, sigAcceptor: ISigAcceptor<SigEntDeployStatus>): void
  {
    wsocCall<SigEntDeployStatus>(entId, WsocStudioMain.SN, "studioEntDeployStatusGet")
      .get(msg, sigAcceptor);
  }

  static sysDefnFormMapGet(sigAcceptor: ISigAcceptor<SigSysDefnFormMapGet>): void
  {
    wsocCall<SigSysDefnFormMapGet>(ENT_ID_GLOBAL, WsocStudioMain.SN, "sysDefnFormMapGet")
      .get(undefined, sigAcceptor);
  }
}