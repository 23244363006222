import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarDeviceType} from "../../../api/meta/base/dto/StudioVarDeviceType";

export function fnVariableBuilderDeviceType(fieldValueDeviceType: string)
{
  function getVarComp()
  {
    return {
      [fieldValueDeviceType]: {
        type: "enumDeviceType",
        metaId: fieldValueDeviceType,
        label: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarDeviceType
  )
  {
    valueMap[fieldValueDeviceType] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueDeviceType];

    return {
      value: value
    } as StudioVarDeviceType;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
