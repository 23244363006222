import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {useMemo} from "react";
import {DefnFieldError} from "../../../../../../api/meta/base/dto/DefnFieldError";
import {FieldValueError} from "../../../../../../api/meta/base/dto/FieldValueError";
import {MetaIdField} from "../../../../../../api/meta/base/Types";
import RawHighlighter from "../../../../raw/RawHighlighter";

export function BubbleFieldError(props: {
  field: DefnFieldError,
  text: string,
  variant: Variant,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const theme = useTheme();
  const field = props.field;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;
  const variant = props.variant;
  const value = valueMap?.[fieldId] as FieldValueError | undefined;

  const severityColor = useMemo(() =>
  {
    const severity = value?.severity;
    if(severity === "error")
    {
      return theme.common.colorWithShade("error", "s500");
    }
    else if(severity === "suggestion")
    {
      return theme.common.colorWithShade("lightBlue", "s500");
    }
    else if(severity === "warning")
    {
      return theme.common.colorWithShade("orange", "s500");
    }
    else
    {
      return theme.common.color("textSecondary");
    }
  }, [value?.severity]);

  return <RawHighlighter
    variant={variant}
    value={props.text}
    color={severityColor}
    searchWords={props.searchWords}
    isUserSelect={true}
    maxNumberOfLine={5}
    breakWords={true}
    flex={1}
  />;
}
