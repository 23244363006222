import React from "react";
import {DtoMessagePayloadUser} from "../../../api/home/base/dto/DtoMessagePayloadUser";
import {SelectList} from "../../../base/plus/ListPlus";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {useAppSelector} from "../../app/AppHooks";
import BubbleUser from "../../atom/bubble/leftright/BubbleUser";
import ListItemShellChat from "../impl/ListItemShellChat";
import {CbOnClickListItem} from "../List";

export default function ListItemChatUser(props: {
  itemId: TypeListItemId,
  listItem: IListItemChat,
  selectList: SelectList,
  itemMaxWidth: number,
  onClickListItem?: CbOnClickListItem,
  cbOnClickFooterCaption?: CbMenuAnchor,
})
{
  const onClickListItem = props.onClickListItem;
  const selectList = props.selectList;
  const searchWords = useAppSelector(state => selectList(state).searchWords);
  const itemId = props.itemId;
  const listItem = props.listItem;
  const sig = listItem.sig;

  const cbOnClickAddToAddressBook = (menuAnchor: Element) => onClickListItem &&
    onClickListItem(menuAnchor, itemId, listItem, "chatAddUserInAddressBook");
  const cbOnClickSendMessageToUser = (menuAnchor: Element) => onClickListItem &&
    onClickListItem(menuAnchor, itemId, listItem, "chatSendMessageToUser");

  return (
    <ListItemShellChat
      itemId={props.itemId}
      listItem={listItem}
      selectList={props.selectList}
      maxWidth={props.itemMaxWidth}
      onClickListItem={props.onClickListItem}
    >
      <BubbleUser
        payload={sig.payload as DtoMessagePayloadUser}
        replyPayload={sig.replyPayload}
        replyInfo={sig.replyInfo}
        receiptStatus={sig.receiptStatus}
        isCallerSender={Boolean(sig.isCallerSender)}
        creationTime={sig.creationTime}
        maxWidth={props.itemMaxWidth}
        header={sig.header}
        searchWords={searchWords}
        isStar={sig.isStar}
        cbOnClickFooterCaption={props.cbOnClickFooterCaption}
        onClickSendMessageToUser={cbOnClickSendMessageToUser}
        onClickAddToAddressBook={cbOnClickAddToAddressBook}
      />
    </ListItemShellChat>
  );
}
