import {CircularProgress} from "@mui/material";
import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import DialogAtom from "./DialogAtom";

export function DialogVideo(props: {
  onClose: () => void,
  url: string,
  title: string,
  thumbSrc?: string,
  blurImg?: string,
  primaryColor?: string,
  height?: number,
  width?: number
})
{

  return (
    <DialogAtom
      title={props.title}
      fullScreen={true}
      content={
        <Player {...props} />
      }
    />
  );
}

function Player(props: {
  url: string,
  thumbSrc?: string,
  blurImg?: string,
  primaryColor?: string,
  height?: number,
  width?: number
})
{
  const url = props.url;
  const [src, setSrc] = useState<string>();

  useEffect(() =>
  {
    download(url).then(blob =>
    {
      setSrc(URL.createObjectURL(blob));
    });
  }, [url]);

  if(src)
  {
    return (
      <video
        id={"video"}
        controls={true}
        autoPlay={true}
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        <source
          src={src}
        />
      </video>
    );
  }
  else
  {
    return <CircularProgress color={"primary"} />;
  }

}

function download(url: string)
{
  return new Promise<Blob>((resolve, reject) =>
  {
    fetch(url, {cache: "force-cache"}).then(res => resolve(res.blob()))
    .catch(err =>
    {
      reject(err);
    });
  });
}
