import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {StudioVarLanguage} from "../../../api/meta/base/dto/StudioVarLanguage";

export function fnVariableBuilderLanguage(fieldValueLanguage: string)
{
  function getVarComp()
  {
    return {
      [fieldValueLanguage]: {
        type: "language",
        metaId: fieldValueLanguage,
        name: "Language",
        required: true
      } as DefnFieldEditable
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarLanguage
  )
  {
    const varValue = variable.value;
    if(!varValue)
    {
      return;
    }

    valueMap[fieldValueLanguage] = variable.value?.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueLanguage];

    return {
      value: {
        value: value
      }
    } as StudioVarLanguage;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
