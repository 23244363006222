import {cloneDeep} from "lodash";
import _ from "lodash";
import {nextPluginId} from "../../../api/meta/base/ApiPlus";
import {nextPluginBundleId} from "../../../api/meta/base/ApiPlus";
import {nextAdminId} from "../../../api/meta/base/ApiPlus";
import {nextEntId} from "../../../api/meta/base/ApiPlus";
import {isEntId} from "../../../api/meta/base/ApiPlus";
import {StudioEnt} from "../../../api/meta/base/dto/StudioEnt";
import {StudioPlugin} from "../../../api/meta/base/dto/StudioPlugin";
import {StudioPluginBundle} from "../../../api/meta/base/dto/StudioPluginBundle";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {MsgStudioEntCreate} from "../../../api/studio/studioDrawer/msg/MsgStudioEntCreate";
import {MsgStudioPluginCreate} from "../../../api/studio/studioDrawer/msg/MsgStudioPluginCreate";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {MsgStudioEnt} from "../../../api/studio/studioMain/msg/MsgStudioEnt";
import {MsgStudioPlugin} from "../../../api/studio/studioMain/msg/MsgStudioPlugin";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigEntAdminCaller} from "../../../api/studio/studioMain/sig/SigEntAdminCaller";
import ISrvc from "../../../base/ISrvc";
import {formatDateByMonth} from "../../../base/plus/DatePlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../base/plus/StringPlus";
import {getEmptyKeysAndMap} from "../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../base/plus/SysPlus";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {IDtoEntCopy} from "../../../base/types/TypesStudio";
import {removeEnt} from "../../../cache/studio/ent/SliceCacheStudioEnt";
import {IDtoPluginCopy} from "../../../cache/studio/plugin/TypesCacheStudioPlugin";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";
import SrvcStudioDrawerFilter from "./SrvcStudioDrawerFilter";
import SrvcStudioDrawerFindUsages from "./SrvcStudioDrawerFindUsages";
import SrvcStudioDrawerRecentList from "./SrvcStudioDrawerRecentList";
import SrvcStudioDrawerSearch from "./SrvcStudioDrawerSearch";

export class SrvcStudioDrawer extends ISrvc
{
  public readonly recentList = new SrvcStudioDrawerRecentList(state => state.studio.drawer.studioRecentList);
  public readonly search = new SrvcStudioDrawerSearch(state => state.studio.drawer.studioSearch);
  public readonly findUsages = new SrvcStudioDrawerFindUsages(state => state.studio.drawer.studioFindUsages);
  public readonly filter = new SrvcStudioDrawerFilter();

  constructor(readonly studioArtifactPicker: SelectList)
  {
    super();

    this.setSrvcArray(
      this.recentList,
      this.search,
      this.findUsages,
      this.filter
    );
  }

  //region enterprise

  rpcEntDelete(entId: EntId, cbSuccess: CbSuccess)
  {
    RpcStudioDrawer.studioEntRemove(entId, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      store.dispatch(removeEnt(entId));
      cbSuccess();
    });
  }

  studioEntGet(entId: EntId, onSuccess: (ent: StudioEnt) => void)
  {
    const entMap = store.getState().cache.studio.ent.entMap;
    const entSig = entMap[entId];

    if(entSig)
    {
      onSuccess(entSig.ent);
    }
    else
    {
      Srvc.studio.ent.rpcEntGet(entId, true, onSuccess);
    }
  }

  copyEnt(entId: EntId)
  {
    // for safari
    if(typeof ClipboardItem && navigator.clipboard.write !== undefined)
    {
      const clipboardItem = new ClipboardItem({
        "text/plain": new Promise((resolve, reject) =>
        {
          this.studioEntGet(entId, (ent) =>
          {
            const entCopy = {
              studioEnt: _.cloneDeep(ent)
            } as MsgStudioEnt;

            const payloadCopy: IDtoEntCopy = {
              type: "ent",
              payload: entCopy
            };

            const text = updateAllMetaIds(JSON.stringify(payloadCopy, (key, value) =>
            {
              if(this.excludeDeployKeys(key))
              {
                return getEmptyKeysAndMap();
              }

              return value;
            }));

            resolve(new Blob([text], {type: "text/plain"}));

            reject(new Error("Failed to copy data: Something went wrong."));
          });
        })
      });
      copyToClipboard("", clipboardItem);
    }
    // for firefox
    else
    {
      this.studioEntGet(entId, (ent) =>
      {
        const entCopy = {
          studioEnt: _.cloneDeep(ent)
        } as MsgStudioEnt;

        const payloadCopy: IDtoEntCopy = {
          type: "ent",
          payload: entCopy
        };

        const text = updateAllMetaIds(JSON.stringify(payloadCopy, (key, value) =>
        {
          if(this.excludeDeployKeys(key))
          {
            return getEmptyKeysAndMap();
          }

          return value;
        }));

        copyToClipboard(text);
      });
    }
  }

  duplicateEnt(ent: StudioEnt)
  {
    const studioEnt = cloneDeep(ent);

    studioEnt.entId = nextEntId();
    studioEnt.details.name = studioEnt.details.name + " - " + formatDateByMonth(new Date());

    const msgEnt = {
      studioEnt: studioEnt,
      adminId: nextAdminId()
    } as MsgStudioEntCreate;

    Srvc.studio.drawer.recentList.rpcEntCreate(msgEnt, () =>
    {
    });
  }

  rpcEntAdminCallerGet(artifactId: ArtifactId)
  {
    if(isEntId(artifactId))
    {
      RpcStudioMain.entAdminCallerGet(artifactId, adminSig =>
      {
        if(adminSig.error)
        {
          return;
        }

        const adminCaller = adminSig.sig as SigEntAdminCaller;
        if(adminCaller)
        {
          Srvc.cache.studio.ent.admins.setEntAdminCaller(artifactId, adminCaller);
        }
      });
    }
    else
    {
      RpcStudioMain.pluginAdminCallerGet(artifactId, adminSig =>
      {
        if(adminSig.error)
        {
          return;
        }

        const adminCaller = adminSig.sig as SigEntAdminCaller;
        if(adminCaller)
        {
          Srvc.cache.studio.plugin.setPluginAdminCallerGet(artifactId);
        }
      });
    }
  }

  rpcPluginBundleRemove(pluginBundleId: PluginBundleId, cbSuccess: CbSuccess)
  {
    RpcStudioDrawer.studioPluginBundleRemove(pluginBundleId, pluginSig =>
    {
      if(pluginSig.error)
      {
        const errorMsg = getErrorMessage(pluginSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      cbSuccess();
    });
  }

  studioPluginGet(pluginBundleId: PluginBundleId, onSuccess: (plugin: StudioPluginBundle) => void)
  {
    const pluginMap = store.getState().cache.studio.plugin.pluginMap;

    if(pluginMap.hasOwnProperty(pluginBundleId))
    {
      const pluginSig = pluginMap[pluginBundleId];
      onSuccess(pluginSig.studioPluginBundle);
    }
    else
    {
      Srvc.studio.plugin.rpcPluginGet(pluginBundleId, true, (plugin) =>
      {
        onSuccess(plugin);
      });
    }
  }

  //endregion

  //region plugin

  copyPlugin(pluginBundleId: PluginBundleId)
  {
    // for safari
    if(typeof ClipboardItem && navigator.clipboard.write !== undefined)
    {
      const clipboardItem = new ClipboardItem({
        "text/plain": new Promise((resolve, reject) =>
        {
          this.studioPluginGet(pluginBundleId, (plugin) =>
          {
            const payloadCopy = this.getCopyPluginDto(plugin);
            const text = updateAllMetaIds(JSON.stringify(payloadCopy));
            resolve(new Blob([text], {type: "text/plain"}));
            reject(new Error("Failed to copy data: Something went wrong."));
          });
        })
      });
      copyToClipboard("", clipboardItem);
    }
    // for firefox
    else
    {
      this.studioPluginGet(pluginBundleId, (plugin) =>
      {
        const payloadCopy = this.getCopyPluginDto(plugin);
        const text = updateAllMetaIds(JSON.stringify(payloadCopy));
        copyToClipboard(text);
      });
    }
  }

  duplicatePlugin(pluginBundle: StudioPluginBundle)
  {
    const studioPluginBundle = cloneDeep(pluginBundle);
    const pluginBundleId = nextPluginBundleId();

    studioPluginBundle.pluginBundleId = pluginBundleId;

    const lastVersionId = studioPluginBundle.deployMap && studioPluginBundle.deployMap.keys.length
      ? studioPluginBundle.deployMap.keys[studioPluginBundle.deployMap.keys.length - 1]
      : undefined;

    const studioPlugin = studioPluginBundle.draft
      ? studioPluginBundle.draft.studioPlugin
      : studioPluginBundle.deployMap && lastVersionId
        ? studioPluginBundle.deployMap.map[lastVersionId]
        : undefined;

    if(studioPlugin)
    {
      studioPlugin.details.name = studioPlugin.details.name + " - " + formatDateByMonth(new Date());
      studioPlugin.metaId = nextPluginId();

      const msgEnt = {
        studioPlugin: studioPlugin,
        adminId: nextAdminId(),
        pluginBundleId: pluginBundleId
      } as MsgStudioPluginCreate;

      Srvc.studio.drawer.recentList.rpcPluginCreate(msgEnt, () =>
      {
      });
    }

  }

  private getCopyPluginDto(plugin: StudioPluginBundle)
  {
    const draft = plugin.draft;
    const deployMap = plugin.deployMap;

    let lastPlugin: StudioPlugin | undefined;

    if(deployMap?.map && deployMap?.keys && deployMap.keys.length > 0)
    {
      const lastDeployedVersionIndex = deployMap.keys.length - 1;
      lastPlugin = deployMap.map[deployMap.keys[lastDeployedVersionIndex]];
    }

    if(draft?.studioPlugin)
    {
      lastPlugin = draft.studioPlugin;
    }

    const pluginCopy = {
      studioPlugin: _.cloneDeep(lastPlugin)
    } as MsgStudioPlugin;

    const payloadCopy: IDtoPluginCopy = {
      type: "plugin",
      payload: pluginCopy
    };

    return payloadCopy;
  }

  private excludeDeployKeys(key: string)
  {
    if(key === "deployVarMap"
      || key === "deployPluginMap"
      || key === "deployPaymentProviderMap"
    )
    {
      return true;
    }
  }

  //endregion
}
