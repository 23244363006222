import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form";
import {nextMetaIdLayoutGrid} from "../../../../api/meta/base/ApiPlus";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutCard} from "../../../../api/meta/base/dto/StudioDtoLayoutCard";
import {StudioDtoLayoutGrid} from "../../../../api/meta/base/dto/StudioDtoLayoutGrid";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {EnumDefnLayoutGridKind, MetaIdField, MetaIdForm} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFormSectionCompositeId} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../../../base/plus/FormPlus";
import {getCombinedFieldId} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {IFormRef} from "../../../../base/types/TypesForm";
import {IFormFieldError} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {fieldKeyLabel} from "../../../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription, fieldKeyName} from "../../../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "../DialogPlus";
import {
  defnToDtoStudioDtoLayoutCard,
  dtoToValueStudioDtoLayoutCard,
  getCompMapGridLayoutTabDetails,
  getLayoutItemLineSegmentCard,
  getLayoutItemLineSegmentList
} from "../LayoutBuilderPlus";
import {fieldLayoutRenderingMode} from "../TypesLayoutBuilder";
import {fieldGridLayoutSwitcherIdSet} from "../TypesLayoutBuilder";
import {fieldLayoutFilterLayoutMode} from "../TypesLayoutBuilder";
import {
  fieldLayoutDetailBgColorField,
  fieldLayoutDetailsSection,
  fieldLayoutDetailTooltipField,
  fieldLayoutGroupByField,
  fieldLayoutItem,
  fieldLayoutItemTabColors,
  fieldLayoutItemTabFonts,
  fieldLayoutItemTabLabels,
  fieldLayoutItemTabTexts,
  getLayoutItemTabId
} from "../TypesLayoutBuilder";
import DialogDefnForm from "./DialogDefnForm";

const dialogContentHeight = 680;
const dialogContentWidth = 1330;

const tabShowDivider = true;
const tabVariant = "standard";

const itemTabId = getLayoutItemTabId(fieldLayoutItem);
const tabTextsId = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabTexts
]));
const tabColorsId = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabColors
]));
const tabFontsId = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabFonts
]));
const tabLabelsId = getLayoutItemTabId(getCombinedFieldId([
  fieldLayoutItem,
  fieldLayoutItemTabLabels
]));

export default function DialogNewLayoutGridBase(props: {
  metaIdForm: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  layoutType: EnumDefnLayoutGridKind,
  isFormReadOnly?: boolean,
  formStore: FormStore,
  validationError?: IFormFieldError[]
  values?: StudioDtoLayoutGrid,
  isOverride?: boolean,
  overrideCompositeIdSet?: MetaIdComposite[],
  onClickOk: (values: StudioDtoLayoutGrid) => void,
  onClose?: () => void,
})
{
  const layoutType = props.layoutType;
  const values = props.values;
  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);
  const isOverride = props.isOverride;
  const formStore = props.formStore;
  const overrideCompositeIdSet = props.overrideCompositeIdSet;

  const excludeGridIdSet = values?.metaId
    ? [values?.metaId]
    : undefined;
  const defnForm = getDefnForm(
    layoutType,
    props.metaIdForm,
    props.metaIdGrid,
    excludeGridIdSet,
    isOverride,
    formStore,
    overrideCompositeIdSet
  );

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      title={`${(values ? "Update" : isOverride ? "Override" : "New")} grid layout - ${toLabel(layoutType)} `}
      formProps={{
        defnForm: defnForm,
        formReadonly: props.isFormReadOnly,
        store: formStore,
        initValues: dtoToValue(layoutType, values),
        cbRef: cbRef.current,
        onSubmit: value => props.onClickOk(valueToDto(value, layoutType, values, isOverride))
      }}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!(isOverride || values)
        ? "Add more grid layouts"
        : undefined}
    />
  );
}

function getDefnForm(
  layoutType: EnumDefnLayoutGridKind,
  metaIdForm: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeGridIdSet?: MetaIdLayoutGrid[],
  isOverride?: boolean,
  formStore?: FormStore,
  overRideCompositeIdSet?: MetaIdComposite[]
)
{
  const compositeIdSet = overRideCompositeIdSet || getFormSectionCompositeId(formStore, metaIdForm);

  return createDefaultDefnFormStudio({
    ...!isOverride && getCompMapGridLayoutTabDetails(metaIdForm, layoutType, metaIdGrid, excludeGridIdSet),

    ...layoutType === "card"
      ? getDefnItemsTabCard(metaIdForm, metaIdGrid, compositeIdSet, isOverride)
      : getDefnItemsTabList(metaIdForm, metaIdGrid, compositeIdSet, isOverride),

    [defaultSectionKey]: {
      type: "tab",
      showDivider: tabShowDivider,
      tabVariant: tabVariant,
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      tabIdSet: !isOverride ? [fieldLayoutDetailsSection, itemTabId] : [itemTabId]
    } as DefnTab
  } as Record<MetaIdField, DefnField>);
}

function getDefnItemsTabList(
  metaIdForm: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  compositeIdSet?: MetaIdComposite[],
  isOverride?: boolean)
{
  const texts = getLayoutItemLineSegmentList(
    fieldLayoutItemTabTexts,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );
  const colors = getLayoutItemLineSegmentList(
    fieldLayoutItemTabColors,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );
  const fonts = getLayoutItemLineSegmentList(
    fieldLayoutItemTabFonts,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );
  const labels = getLayoutItemLineSegmentList(
    fieldLayoutItemTabLabels,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );

  return {
    ...texts,
    ...colors,
    ...fonts,
    ...labels,
    [itemTabId]: {
      type: "tab",
      name: "List Item",
      label: "List Item",
      showDivider: tabShowDivider,
      tabVariant: tabVariant,
      metaId: itemTabId,
      tabIdSet: [
        tabTextsId,
        tabColorsId,
        tabFontsId,
        tabLabelsId
      ]
    } as DefnTab
  };
}

function getDefnItemsTabCard(
  metaIdForm: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  compositeIdSet?: MetaIdComposite[],
  isOverride?: boolean)
{
  const texts = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabTexts,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );
  const colors = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabColors,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );
  const fonts = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabFonts,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );
  const labels = getLayoutItemLineSegmentCard(
    fieldLayoutItemTabLabels,
    metaIdForm,
    metaIdGrid,
    undefined,
    "gridLayout",
    compositeIdSet,
    isOverride
  );

  return {
    ...texts,
    ...colors,
    ...fonts,
    ...labels,
    [itemTabId]: {
      type: "tab",
      label: "Card Item",
      name: "Card Item",
      showDivider: tabShowDivider,
      tabVariant: tabVariant,
      metaId: itemTabId,
      pb: 0,
      tabIdSet: [
        tabTextsId,
        tabColorsId,
        tabFontsId,
        tabLabelsId
      ]
    } as DefnTab
  };
}

function dtoToValue(layoutType: EnumDefnLayoutGridKind, dto?: StudioDtoLayoutGrid)
{
  if(!dto || (dto.kind !== "card" && dto.kind !== "list"))
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", layoutType)
    };
  }

  const dtoLayout = dto as StudioDtoLayoutCard;

  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", dto.name ?? layoutType),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", dto.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", dto.description),
    ...dtoToValueStudioDtoLayoutCard(dtoLayout),
    [fieldLayoutDetailBgColorField]: fnRawValueToFieldValue("pickFieldId", dto?.bgColorFieldId),
    [fieldLayoutGroupByField]: fnRawValueToFieldValue("pickFieldId", dtoLayout?.groupByFieldId),
    [fieldGridLayoutSwitcherIdSet]: fnRawValueToFieldValue("studioSetOfLayoutGridId", dto.allowToSwitchLayoutIdSet),
    [fieldLayoutRenderingMode]: fnRawValueToFieldValue("enumGridRenderingMode", dtoLayout?.renderingMode),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", dto?.toolTipFieldId)
  };
}

function valueToDto(
  value: FieldValues,
  layoutType: EnumDefnLayoutGridKind,
  dto?: StudioDtoLayoutGrid,
  isOverride?: boolean): StudioDtoLayoutGrid
{
  const dtoLayout = {
    item: defnToDtoStudioDtoLayoutCard(value),
    groupByFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutGroupByField]),
    filter: value[fieldLayoutFilterLayoutMode],
    renderingMode: fnFieldValueToRawValue("enumGridRenderingMode", value[fieldLayoutRenderingMode])
  } as StudioDtoLayoutCard;

  return {
    metaId: dto?.metaId || nextMetaIdLayoutGrid(),
    name: fnFieldValueToRawValue("symbol", !isOverride
      ? value[fieldKeyName]
      : "OverrideGridLayoutName"),
    label: fnFieldValueToRawValue("text", value[fieldKeyLabel]),
    description: fnFieldValueToRawValue("text", value[fieldKeyDescription]),
    kind: fnFieldValueToRawValue("enumLayoutGridKind", layoutType),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutDetailTooltipField]),
    bgColorFieldId: fnFieldValueToRawValue("pickFieldId", value[fieldLayoutDetailBgColorField]),
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioSetOfLayoutGridId", value[fieldGridLayoutSwitcherIdSet]),
    ...(layoutType === "card" || layoutType === "list") && {
      ...dtoLayout
    }
  } as StudioDtoLayoutGrid;
}



