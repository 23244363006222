import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {PluginId} from "../../../api/meta/base/Types";
import {EnumDefnSetupKind} from "../../../api/meta/base/Types";
import {StoreItemId} from "../../../api/meta/base/Types";
import {SigStoreAdminCaller} from "../../../api/store/store/sig/SigStoreAdminCaller";
import {SigStoreAdminList} from "../../../api/store/store/sig/SigStoreAdminList";
import {SigStoreItem} from "../../../api/store/store/sig/SigStoreItem";
import {EnumStoreItemFooterTab} from "../../../base/types/TypesStore";
import {EnumStudioPluginStep} from "../../../base/types/TypesStudio";
import {EnumStudioPluginFooterTab} from "../../../base/types/TypesStudio";
import {EnumStudioEntStep} from "../../../base/types/TypesStudio";
import {EnumStudioEntFooterTab} from "../../../base/types/TypesStudio";
import {ICacheStoreItem} from "./TypesCacheStoreItem";

export type ActionStoreItemStateMap = {
  storeItemId: StoreItemId,
  currentTab: EnumStudioEntFooterTab | EnumStudioPluginFooterTab | EnumStoreItemFooterTab,
  currentStep: EnumStudioEntStep | EnumStudioPluginStep,
}

export const sliceCacheStoreItem = createSlice({
  name: "cacheStoreMain",
  initialState: {
    storeItemMap: {},
    selectedModuleMap: {},
    storeItemStateMap: {},
    searchStoreItemId: "",
    storeItemAdminListMap: {},
    storeItemAdminCallerMap: {}
  } as ICacheStoreItem,

  reducers: {
    setStoreItem: (state: ICacheStoreItem, action: PayloadAction<SigStoreItem>) =>
    {
      const sig = action.payload;
      const storeItemId = sig.storeItem.storeItemId;
      state.storeItemMap[storeItemId] = sig;
    },
    removeStoreItem: (state: ICacheStoreItem, action: PayloadAction<StoreItemId>) =>
    {
      delete state.storeItemMap[action.payload];
    },

    setupModeStoreItem: (
      state: ICacheStoreItem,
      action: PayloadAction<{
        storeItemId: StoreItemId,
        setupMode: EnumDefnSetupKind
      }>) =>
    {
      const {
        storeItemId,
        setupMode
      } = action.payload;

      state.storeItemStateMap[storeItemId] = {
        ...state.storeItemStateMap[storeItemId],
        setupMode
      };
    },

    setStoreItemCurrentTabs: (state: ICacheStoreItem, action: PayloadAction<ActionStoreItemStateMap>) =>
    {
      const {
        storeItemId,
        currentStep,
        currentTab
      } = action.payload;

      if(currentTab && state.storeItemStateMap[storeItemId]?.currentTab !== currentTab)
      {
        state.storeItemStateMap[storeItemId] = {
          ...state.storeItemStateMap[storeItemId],
          currentTab: currentTab
        };
      }

      if(currentStep && state.storeItemStateMap[storeItemId]?.currentStep !== currentStep)
      {
        state.storeItemStateMap[storeItemId] = {
          ...state.storeItemStateMap[storeItemId],
          currentStep: currentStep
        };
      }
    },

    setStoreItemSelectedModules: (state: ICacheStoreItem, action: PayloadAction<{
      storeItemId: StoreItemId,
      selectedModuleSet: StudioModuleSelection
    }>) =>
    {
      const selectedModuleSet = action.payload.selectedModuleSet;
      const storeItemId = action.payload.storeItemId;
      if(storeItemId && selectedModuleSet)
      {
        state.selectedModuleMap[storeItemId] = selectedModuleSet;
      }
    },

    setSearchStoreItemId: (state: ICacheStoreItem, action: PayloadAction<StoreItemId | undefined>) =>
    {
      state.searchStoreItemId = action.payload;
    },

    setStoreItemAdminList: (
      state: ICacheStoreItem,
      action: PayloadAction<{
        storeItemId: StoreItemId,
        sigStoreAdminList: SigStoreAdminList
      }>) =>
    {
      const {
        storeItemId,
        sigStoreAdminList
      } = action.payload;
      state.storeItemAdminListMap[storeItemId] = sigStoreAdminList;
    },

    removeStoreItemAdminList: (
      state: ICacheStoreItem,
      action: PayloadAction<StoreItemId>
    ) =>
    {
      delete state.storeItemAdminListMap[action.payload];
    },

    setStoreItemAdminCaller: (
      state: ICacheStoreItem,
      action: PayloadAction<{
        storeItemId: StoreItemId,
        sigStoreAdminCaller: SigStoreAdminCaller
      }>) =>
    {
      const {
        storeItemId,
        sigStoreAdminCaller
      } = action.payload;
      state.storeItemAdminCallerMap[storeItemId] = sigStoreAdminCaller;
    },

    setStoreItemPluginCurrentVersion: (
      state: ICacheStoreItem,
      action: PayloadAction<{pluginVersionId?: PluginId}>) =>
    {
      state.selectedVersionId = action.payload.pluginVersionId;
    }
  }
});

export const {
  setStoreItem,
  removeStoreItem,

  setupModeStoreItem,
  setStoreItemCurrentTabs,
  setStoreItemSelectedModules,

  setSearchStoreItemId,

  setStoreItemAdminList,
  removeStoreItemAdminList,

  setStoreItemAdminCaller,

  setStoreItemPluginCurrentVersion

} = sliceCacheStoreItem.actions;
