import {ExpandMoreRounded} from "@mui/icons-material";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useEffect} from "react";
import {CSSProperties} from "react";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {CssBackgroundColor} from "../../../../base/plus/ThemePlus";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import RawRadialGradient from "../../raw/RawRadialGradient";
import BubbleRawIcons from "../raw/BubbleRawIcons";

const forwardIcon = "ReplyRounded";
const forwardName = "forward";

const reactionMessageIcon = "EmojiEmotions";
const reactionName = "reaction";

export default function BubbleShell(props: {
  children: React.ReactNode,
  isCallerSender: boolean,
  maxWidth?: number,
  fullWidth?: boolean;
  bgColorShell?: CssBackgroundColor;
  hideMenu?: boolean,
  disableMenuHover?: boolean,
  isHeaderVisible?: boolean,
  isBubbleBlinking?: boolean,
  isBubbleHover?: boolean,
  isMsgForwardable?: boolean,
  justifyContent?: CSSProperties["justifyContent"];
  onClickBubbleShell?: CbMenuAnchor,
  onClickMenu?: CbMenuAnchor,
  onClickChatItemForward?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
  isSelected?: boolean,
})
{
  const bgColorShell = props.bgColorShell;
  const fullWidth = props.fullWidth;
  const callerSender = props.isCallerSender;
  const disableMenuHover = props.disableMenuHover;
  const bubbleBlinkColor = Boolean(props.isBubbleBlinking);
  const isMsgForwardable = Boolean(props.isMsgForwardable);
  const isBubbleHover = Boolean(props.isBubbleHover);
  const isCallerSender = Boolean(props.isCallerSender);
  const onClickMenu = props.onClickMenu;
  const onClickBubbleShell = props.onClickBubbleShell;
  const justifyContent = props.justifyContent;
  const isSelected = props.isSelected;
  const theme = useTheme();
  const [showMenuIcon, setShowMenuIcon] = useState<boolean>(Boolean(disableMenuHover));
  const [bgColor, setBgColor] = useState<CssBackgroundColor | undefined>();
  const [bubbleIconSet, setBubbleIconSet] = useState<Record<string, string>>();

  const borderRadius = theme.common.gapHalf;
  const gapStd = theme.common.gapStd;
  const bubbleShellGap = theme.common.bubbleShellGap;
  const bubbleShellBorder = theme.common.bubbleShellBorder;
  const maxWidth = props.maxWidth
    ? fullWidth
      ? (props.maxWidth - (gapStd))
      : Math.min((props.maxWidth - (gapStd * 3)), theme.common.bubbleShellFixedWidth)
    : theme.common.bubbleShellFixedWidth;

  const getBgColorShell = useCallback(() =>
  {
    if(isSelected)
    {
      return theme.common.bgcolorSelection;
    }

    return bgColorShell
      ? bgColorShell
      : callerSender
        ? theme.common.bubbleBgcolorRight
        : theme.common.bubbleBgcolorLeft;

  }, [bgColorShell, callerSender, isSelected]);

  const showMenu = Boolean(!props.hideMenu && onClickMenu);
  const menuRadius = 6;

  const onClickIcon = (name: string, menuAnchor: Element) =>
  {
    if(name === forwardName)
    {
      props.onClickChatItemForward && props.onClickChatItemForward(menuAnchor);
    }
    else if(name === reactionName)
    {
      props.cbOnClickChatItemReaction && props.cbOnClickChatItemReaction(menuAnchor);
    }
  };

  const onMouseOverBubbleShell = useCallback(() =>
  {
    if(showMenu && !disableMenuHover)
    {
      setShowMenuIcon(true);
    }
  }, [showMenu, disableMenuHover]);

  const onMouseOutBubbleShell = useCallback(() =>
  {
    if(showMenu && !disableMenuHover)
    {
      setShowMenuIcon(false);
    }
  }, [showMenu, disableMenuHover]);

  useEffect(() =>
  {
    if(bubbleBlinkColor)
    {
      const bgColorShell = callerSender
        ? theme.common.bubbleBlinkColorRight
        : theme.common.bubbleBlinkColorLeft;
      setBgColor(bgColorShell);
    }
    else
    {
      setBgColor(getBgColorShell());
    }
  }, [props.isBubbleBlinking, getBgColorShell]);

  useEffect(() =>
  {
    const prepareBubbleIconSet: Record<string, string> = {};

    if(isMsgForwardable)
    {
      prepareBubbleIconSet[forwardName] = forwardIcon;
    }
    setBubbleIconSet(prepareBubbleIconSet);
  }, [isMsgForwardable]);

  useEffect(() =>
  {
    setBubbleIconSet(prevState =>
    {
      const updatedIconSet = {...prevState};

      if(isBubbleHover)
      {
        updatedIconSet[reactionName] = reactionMessageIcon;
      }
      else
      {
        delete updatedIconSet.reaction;
      }

      return updatedIconSet;
    });
  }, [isBubbleHover, isCallerSender]);

  return (
    <Box
      display={"flex"}
      height={"auto"}
      alignItems={"center"}
      justifyContent={justifyContent}
    >
      {
        (bubbleIconSet && isCallerSender) &&
        <BubbleRawIcons
          nameSet={Object.keys(bubbleIconSet).reverse()}
          iconSet={Object.values(bubbleIconSet).reverse()}
          color={"textInverse"}
          enableRipple={true}
          width={px(20)}
          padding={px(2)}
          mr={px(gapHalf)}
          onClick={onClickIcon}
        />
      }
      <Box
        maxWidth={px(maxWidth)}
        bgcolor={bubbleBlinkColor ? bgColor : getBgColorShell()}
        padding={px(bubbleShellGap)}
        borderRadius={px(borderRadius)}
        border={`${px(bubbleShellBorder)} solid`}
        borderColor={theme.common.bubbleBorderColor}
        position={"relative"}
        onMouseEnter={() => onMouseOverBubbleShell()}
        onMouseLeave={() => onMouseOutBubbleShell()}
        onClick={onClickBubbleShell
          ? (e: React.MouseEvent<HTMLElement>) => onClickBubbleShell(e.currentTarget)
          : undefined}
        sx={{
          transition: props.isBubbleBlinking ? "background-color .3s linear" : "" // jump blink effect
        }}
      >
        {
          showMenu &&
          <RawRadialGradient
            type={"top-right"}
            visibilityHidden={!showMenuIcon}
            mt={gapQuarter}
            mr={gapQuarter}
          >

            <ExpandMoreRounded
              onClick={e =>
              {
                e.stopPropagation();
                e.preventDefault();
                onClickMenu && onClickMenu(e.currentTarget);
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                },
                visibility: showMenuIcon
                  ? "visible"
                  : "hidden",
                color: theme.common.color("textDisabled"),
                height: px(20),
                width: px(20),
                background: theme.palette.background.paper,
                borderRadius: px(menuRadius)
              }}
            />
          </RawRadialGradient>
        }
        {props.children}
      </Box>

      {
        (bubbleIconSet && !isCallerSender) &&
        <BubbleRawIcons
          nameSet={Object.keys(bubbleIconSet)}
          iconSet={Object.values(bubbleIconSet)}
          color={"textInverse"}
          enableRipple={true}
          width={px(20)}
          padding={px(2)}
          ml={px(gapHalf)}
          onClick={onClickIcon}
        />
      }
    </Box>
  );
}

