import React from "react";
import {DtoMessagePayloadSpreadsheetRowDeleted} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRowDeleted";
import {STR_ROW_DELETED} from "../../../base/plus/ConstantsPlus";
import {SelectList} from "../../../base/plus/ListPlus";

import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import BubbleDelete from "../../atom/bubble/leftright/BubbleDelete";
import ListItemShellChat from "../impl/ListItemShellChat";
import {CbOnClickListItem} from "../List";

export default function ListItemChatFormDeleted(props: {
  itemId: TypeListItemId,
  listItem: IListItemChat,
  selectList: SelectList,
  itemMaxWidth: number,
  onClickListItem?: CbOnClickListItem,
  cbOnClickFooterCaption?: CbMenuAnchor,
})
{
  const listItem = props.listItem;
  const sig = listItem.sig;

  return (
    <ListItemShellChat
      itemId={props.itemId}
      listItem={props.listItem}
      selectList={props.selectList}
      maxWidth={props.itemMaxWidth}
      onClickListItem={props.onClickListItem}
    >
      <BubbleDelete
        payload={sig.payload as DtoMessagePayloadSpreadsheetRowDeleted}
        isCallerSender={Boolean(sig.isCallerSender)}
        creationTime={sig.creationTime}
        maxWidth={props.itemMaxWidth}
        header={sig.header}
        text={STR_ROW_DELETED}
      />
    </ListItemShellChat>
  );
}
