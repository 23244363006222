import {useTheme} from "@mui/material";
import {Button} from "@mui/material";
import {Stack} from "@mui/material";
import {isEmpty} from "lodash";
import React from "react";
import {StudioForm} from "../../../api/meta/base/dto/StudioForm";
import {IFormFieldError} from "../../../base/types/TypesForm";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import IconForm from "../icon/IconForm";
import {EnumIconFormBuilder} from "../icon/IconForm";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import LineBadge from "../line/LineBadge";
import RawDot from "./RawDot";
import {IPopoverMsg} from "./RawPopover";
import RawTooltip from "./RawTooltip";

interface CustomizedEnumIconFormBuilder
{
  iconStrip: EnumIconFormBuilder;
  showDot: boolean;
}

export type TypeFormBuilderError = Record<EnumIconFormBuilder, {
  count?: number,
  errorList?: IFormFieldError[]
}>;

export default function RawFormIconStrip(props: {
  form?: StudioForm,
  iconStripList: EnumIconFormBuilder[],
  onClick?: (iconName: EnumIconFormBuilder) => void,
  iconButtonDisable?: EnumIconFormBuilder[],
  toolTipMap?: Record<EnumIconFormBuilder, string>
  colorMap?: Record<EnumIconFormBuilder, TypeTextColor>,
  formIconBadgeMap?: TypeFormBuilderError
})
{
  const {
    iconStripList,
    iconButtonDisable,
    onClick,
    toolTipMap,
    colorMap,
    form
  } = props;

  const theme = useTheme();
  const formIconBadgeMap = props.formIconBadgeMap;

  const formulaMapLength = form?.formulaMap.keys?.length ?? 0;
  const visibilityMapLength = form?.visibilityRuleMap.keys.length ?? 0;
  const contentMapLength = form?.layoutMap?.keys.length ?? 0;
  const paymentConfigEdited = !isEmpty(form?.payment);

  const customizedIconStripList = iconStripList.map(iconStrip =>
  {
    let showDot = false;

    switch(iconStrip)
    {
      case "edit":
        showDot = true;
        break;
      case "formula":
        showDot = formulaMapLength > 0;
        break;
      case "visibility":
        showDot = visibilityMapLength > 0;
        break;
      case "contentLayout":
        showDot = contentMapLength > 0;
        break;
      case "payment":
        showDot = paymentConfigEdited;
    }

    return {
      iconStrip,
      showDot
    } as CustomizedEnumIconFormBuilder;
  });

  return (
    <Stack
      direction={"row"}
      width={"auto"}
    >
      {
        customizedIconStripList && customizedIconStripList.length > 0 &&
        customizedIconStripList.map(item =>
          {
            const bgColor = (colorMap && colorMap[item.iconStrip])
              ? colorMap[item.iconStrip]
              : undefined;

            return item.iconStrip === "pipe"
              ? (<LayoutFlexRow
                  key={item.iconStrip}
                  pr={"18px"}
                >
                  |
                </LayoutFlexRow>
              )
              : (
                <RawTooltip
                  title={toolTipMap ? toolTipMap[item.iconStrip] : ""}
                  key={item.iconStrip}
                >
                  <Button
                    disableFocusRipple={true}
                    disableRipple={true}
                    size={"small"}
                    onClick={() => onClick && onClick(item.iconStrip)}
                    disabled={iconButtonDisable?.includes(item.iconStrip)}
                    sx={{
                      position: "relative",
                      minWidth: "32px",
                      color: theme.common.color(bgColor || "textSecondary"),
                      "&:hover": {
                        backgroundColor: "transparent"
                      }
                    }}
                  >
                    <LayoutFlexRow
                      width={"40px"}
                    >

                      <LayoutFlexCol
                        height={"100%"}
                        width={"20px"}
                        justifyContent={"end"}
                      >
                        <LayoutFlexRow mt={"2px"}>
                          <RawDot color={"transparent"} />
                        </LayoutFlexRow>
                        <IconForm
                          iconButton={item.iconStrip}
                          color={"primary"}
                        />
                        <LayoutFlexRow mt={"2px"}>
                          {item.showDot ?
                            <RawDot color={theme.common.color(bgColor || "textSecondary") as TypeTextColor} /> :
                            <RawDot color={"transparent"} />}
                        </LayoutFlexRow>
                      </LayoutFlexCol>

                      {
                        (formIconBadgeMap && formIconBadgeMap[item.iconStrip]) ?
                          <LineBadge
                            badge={{
                              value: formIconBadgeMap[item.iconStrip].count,
                              color: "error"
                            }}
                            mb={24}
                            errorMessage={formIconBadgeMap[item.iconStrip].errorList?.map(err =>
                            {
                              return {
                                msg: err.errorOption.message as string,
                                children: err.errorChildrenOption?.map(child => child.message as string)
                              } as IPopoverMsg;
                            })}
                          />
                          : <LayoutFlexRow
                            height={"100%"}
                            width={"20px"}
                            justifyContent={"end"}
                          >
                            {null}
                          </LayoutFlexRow>
                      }
                    </LayoutFlexRow>

                  </Button>
                </RawTooltip>
              );
          }
        )
      }
    </Stack>
  );
}


