import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {forwardRef} from "react";
import {useState} from "react";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";

export const BoxMenuButton = forwardRef((props: {
  onClick: CbMenuAnchor,
}, ref) =>
{
  const theme = useTheme();
  const defaultColor = theme.common.bgcolorHover;
  const [menuClr, setMenuClr] = useState(defaultColor);
  const gap = px(theme.common.gapStd);
  const onClick = props.onClick;

  return (
    <Box
      ref={ref}
      onMouseEnter={() =>
      {
        setMenuClr(theme.palette.action.disabledBackground);
      }}
      onMouseLeave={() =>
      {
        setMenuClr(defaultColor);
      }}
      onClick={(e) =>
      {
        e.stopPropagation();
        e.preventDefault();

        // this prevents garbage collection of e.currentTarget
        const currentTarget = e.currentTarget;
        setMenuClr(theme.palette.action.active);

        setTimeout(() =>
        {
          setMenuClr(defaultColor);
          onClick && onClick(currentTarget);
        }, theme.common.durationTransition);
      }}
      sx={{
        height: "100%",
        width: gap,
        borderBottom: (gap + " solid " + menuClr),
        borderLeft: (gap + " solid transparent")
      }}
    />

  );
});
