import {UploadRounded} from "@mui/icons-material";
import {DoneAllRounded} from "@mui/icons-material";
import {DoneRounded} from "@mui/icons-material";
import {CancelScheduleSendRounded} from "@mui/icons-material";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import {useTheme} from "@mui/material";
import React from "react";
import {textIconSx} from "../../../base/plus/ThemePlus";
import {EnumBubbleIconCaption} from "../../../base/types/TypesBubble";

export default function IconBubbleCaption(props: {
  value?: EnumBubbleIconCaption,
  color?: "light" | "dark"
})
{
  const theme = useTheme();
  const vertInner = theme.common.vertInner;
  const gapThreeQuarters = theme.common.gapQuarter + theme.common.gapHalf;

  const value = props.value;
  const color = props.color;
  if(value === undefined)
  {
    return null;
  }

  switch(value)
  {
    case "error":
      return <CancelScheduleSendRounded
        sx={textIconSx(color === "light" ? theme.palette.primary.contrastText : theme.palette.text.disabled)}
      />;
    case "pending":
      return <UploadRounded
        sx={textIconSx(color === "light" ? theme.palette.primary.contrastText : theme.palette.text.disabled)}
      />;
    case "singleTick":
      return <DoneRounded
        sx={textIconSx(color === "light" ? theme.palette.primary.contrastText : theme.palette.text.disabled)}
      />;
    case "doubleTick":
      return <DoneAllRounded
        sx={textIconSx(color === "light" ? theme.palette.primary.contrastText : theme.palette.text.disabled)}
      />;
    case "blueTick":
      return <DoneAllRounded sx={textIconSx(theme.palette.primary.light)} />;
    case "star":
      return <StarRateRoundedIcon
        sx={textIconSx(color === "light" ? theme.palette.primary.contrastText : theme.palette.text.disabled)}
      />;
    case "comment":
      return <ChatRoundedIcon
        sx={textIconSx(color === "light" ? theme.palette.primary.contrastText : theme.palette.text.disabled)}
      />;
    case "commentGreen":
      return <ChatRoundedIcon
        sx={textIconSx(theme.common.color("success"))}
      />;
  }

  return <p>Icon missing ${value}</p>;
}
