// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {ServiceName} from '../../meta/base/Types';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocSession
{
  static readonly SN: ServiceName = "session";

  static sessionPut(sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocSession.SN, "sessionPut")
      .put(undefined, sigAcceptor);
  }
}