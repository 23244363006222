import {PayloadAction} from "@reduxjs/toolkit";
import {SigSpreadsheetRowExpiry} from "../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {isOrphanOptionId} from "../../../api/meta/base/ApiPlus";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {getFormFieldValueAsText} from "../../plus/FieldValuePlus";
import {getValidFormFieldValue} from "../../plus/FormPlus";
import {isFormSearchHit} from "../../plus/FormPlus";
import {getFormName} from "../../plus/FormPlus";
import {searchHit} from "../../plus/StringPlus";
import {TypeKanbanSortType} from "../../types/TypeKanban";
import {IKanbanItemGroup} from "../../types/TypeKanban";
import {IKanbanColumnsByItemIds} from "../../types/TypeKanban";
import {TypeKanbanItemId} from "../../types/TypeKanban";
import {TypeKanbanColId} from "../../types/TypeKanban";
import {IKanbanItemCol} from "../../types/TypeKanban";
import {IKanbanItem} from "../../types/TypeKanban";
import {IKanbanItemById} from "../../types/TypeKanban";
import {IKanbanData} from "../../types/TypeKanban";
import {IKanban} from "../../types/TypeKanban";
import {TypeUserFieldValue} from "../../types/TypesGlobal";
import {TypeUserField} from "../../types/TypesGlobal";

interface ActionMoveKanbanItem
{
  itemId: TypeKanbanItemId,
  sourceColumnId: TypeKanbanColId,
  destinationColumnId: TypeKanbanColId,
  destinationIndex: number,
}

interface ActionSetIfExistSigSpreadsheetRowExpiry
{
  itemId: TypeKanbanItemId;
  sigSpreadsheetRowExpiry?: SigSpreadsheetRowExpiry;
}

export const sliceKanbanShared = {
  //region init
  kanbanSetShowMenu: (state: IKanban, action: PayloadAction<boolean>) =>
  {
    state.showMenu = action.payload;
  },
  kanbanSetIsReadOnly: (state: IKanban, action: PayloadAction<boolean>) =>
  {
    state.readonly = action.payload;
  },
  kanbanSetIgnoreSelection: (state: IKanban, action: PayloadAction<boolean>) =>
  {
    state.ignoreSelection = action.payload;
  },
  kanbanSetError: (state: IKanban, action: PayloadAction<string | undefined>) =>
  {
    state.error = action.payload;
    fnKanbanSetDisplay(state);
  },
  kanbanSetUserField: (state: IKanban, action: PayloadAction<TypeUserField | undefined>) =>
  {
    state.userField = action.payload;
  },
  kanbanSetUserFieldVar: (state: IKanban, action: PayloadAction<{varName: string, varValue?: TypeUserFieldValue}>) =>
  {
    // noinspection DuplicatedCode
    const payload = action.payload;

    let userField = state.userField;
    if(userField === undefined)
    {
      userField = {};
    }

    const varName = payload.varName;
    const varValue = payload.varValue;
    if(varValue !== undefined)
    {
      userField = {
        ...userField,
        [varName]: varValue
      };
    }
    else if(userField)
    {
      delete userField[varName];
    }

    state.userField = userField;
  },
  kanbanSetIfExistIsInvisibleSpreadsheetRow: (
    state: IKanban,
    action: PayloadAction<{itemId: TypeKanbanItemId; isInvisible: boolean}>) =>
  {
    const payload = action.payload;
    const item = state.itemsById[payload.itemId];
    if(item)
    {
      item.isInvisibleSpreadsheetRow = payload.isInvisible;
    }
  },

  kanbanSetIfExistUserField: (
    state: IKanban,
    action: PayloadAction<{itemId: TypeKanbanItemId, userField: TypeUserField}>) =>
  {
    const params = action.payload;
    const item = state.itemsById[params.itemId];
    if(item)
    {
      item.userField = params.userField;
    }
  },
  kanbanSetItemIfExistUserFieldVar: (
    state: IKanban,
    action: PayloadAction<{itemId: TypeKanbanItemId, varName: string, varValue?: TypeUserFieldValue}>) =>
  {
    const payload = action.payload;

    const item = state.itemsById[payload.itemId];
    if(item)
    {
      let userField = item.userField;
      if(userField === undefined)
      {
        userField = {};
      }

      const varName = payload.varName;
      const varValue = payload.varValue;
      if(varValue)
      {
        userField = {
          ...userField,
          [varName]: varValue
        };
      }
      else if(userField)
      {
        delete userField[varName];
      }

      item.userField = userField;
    }
  },

  kanbanSetVersion: (state: IKanban, action: PayloadAction<string>) =>
  {
    state.version = action.payload;
  },
  kanbanSetItemByIds: (state: IKanban, action: PayloadAction<IKanbanItemById>) =>
  {
    state.itemsById = action.payload;
  },
  kanbanSetItem: (state: IKanban, action: PayloadAction<{
    itemId: TypeKanbanItemId;
    item: IKanbanItem;
  }>) =>
  {
    const payload = action.payload;
    const itemId = payload.itemId;
    state.itemsById[itemId] = payload.item;
    fnKanbanSetDisplay(state);
  },
  kanbanResetItem: (state: IKanban, action: PayloadAction<TypeKanbanItemId>) =>
  {
    const itemId = action.payload;
    delete state.itemsById[itemId];
    fnKanbanSetDisplay(state);
  },

  kanbanSetItemCol: (state: IKanban, action: PayloadAction<{
    itemId: TypeKanbanColId;
    item: IKanbanItemCol;
  }>) =>
  {
    const payload = action.payload;
    const itemId = payload.itemId;
    state.columnMap[itemId] = payload.item;
    fnKanbanSetDisplay(state);
  },
  kanbanRefresh: (state: IKanban, action: PayloadAction<IKanbanData>) =>
  {
    const kanbanData = action.payload;

    state.columnIdList = kanbanData.columnIdList;
    state.columnsByItemIds = kanbanData.columnsByItemIds;
    state.columnMap = kanbanData.columnMap;
    state.itemsById = kanbanData.itemsById;
    state.defnForm = kanbanData.defnForm;
    state.sortFieldId = kanbanData.sortFieldId;
    state.showCommentCount = kanbanData.showCommentCount;

    if(kanbanData.sortFieldId)
    {
      state.sortOrder = kanbanData.sortOrder || "ASC";
    }

    state.filter = kanbanData.filter;

    state.searchWords = kanbanData.searchWords;
    state.hideItemFooter = kanbanData.hideItemFooter;
    state.hideItemTitle = kanbanData.hideItemTitle;
    state.hideItemSectionName = kanbanData.hideItemSectionName;
    if(kanbanData.readonly !== undefined)
    {
      state.readonly = kanbanData.readonly;
    }

    state.error = kanbanData.error;
    state.version = kanbanData.version;
    state.userField = kanbanData.userField;
    state.loaded = true;
    fnKanbanSetDisplay(state);
  },
  //endregion

  //region search
  kanbanSetSearch: (state: IKanban, action: PayloadAction<string | undefined>) =>
  {
    const searchString = action.payload;

    let searchWords: string[] | undefined = undefined;
    if(searchString && searchString.length > 0)
    {
      searchString.split(" ").forEach(value =>
      {
        value = value.trim();
        if(value.length > 0)
        {
          if(searchWords === undefined)
          {
            searchWords = [];
          }
          searchWords.push(value);
        }
      });
    }
    state.searchWords = searchWords;
    fnKanbanSetDisplay(state);
  },
  kanbanSetSearchWords: (state: IKanban, action: PayloadAction<string[] | undefined>) =>
  {
    state.searchWords = action.payload;
    fnKanbanSetDisplay(state);
  },
  //endregion

  //region filter
  kanbanSetFilter: (state: IKanban, action: PayloadAction<TypeKanbanColId[] | undefined>) =>
  {
    state.filter = action.payload;
    fnKanbanSetDisplay(state);
  },
  //endregion

  //region sort
  kanbanSetSort: (state: IKanban, action: PayloadAction<string | undefined>) =>
  {
    state.sortFieldId = action.payload;
    fnKanbanSetDisplay(state);
  },
  kanbanSetSortOrder: (state: IKanban, action: PayloadAction<TypeKanbanSortType>) =>
  {
    state.sortOrder = action.payload;
    fnKanbanSetDisplay(state);
  },
  //endregion

  //region item selection
  KanbanSetSelectedItemId: (state: IKanban, action: PayloadAction<TypeKanbanItemId | undefined>) =>
  {
    state.selectedItemId = action.payload;
  },
  //endregion
  kanbanMoveItem: (state: IKanban, action: PayloadAction<ActionMoveKanbanItem>) =>
  {
    const payload = action.payload;
    const {
      itemId,
      sourceColumnId,
      destinationColumnId,
      destinationIndex
    } = payload;

    const sourceColumn = state.columnsByItemIds[sourceColumnId];
    const destinationColumn = state.columnsByItemIds[destinationColumnId];

    if(sourceColumn && destinationColumn)
    {
      const sourceItemIds = sourceColumn.itemIds;
      const destinationItemIds = destinationColumn.itemIds;
      const sourceIndex = sourceItemIds.indexOf(itemId);

      if(sourceIndex >= 0 && destinationIndex >= 0)
      {
        sourceItemIds.splice(sourceIndex, 1);
        destinationItemIds.splice(destinationIndex, 0, itemId);
        if(state.itemsById[itemId])
        {
          state.itemsById[itemId].colId = destinationColumnId;
        }
        fnKanbanSetDisplay(state);
      }
    }
  },
  kanbanSetIfExistIsItemWithMedia: (state: IKanban, action: PayloadAction<boolean>) =>
  {
    state.isItemWithMedia = action.payload;
  },
  kanbanSetUiModeMobile: (state: IKanban, action: PayloadAction<boolean>) =>
  {
    state.uiModeMobile = action.payload;
    if(!state.displaySelectedColumnsByItemId)
    {
      state.displaySelectedColumnsByItemId = state.columnIdList[0];
    }
  },

  kanbanSetDisplaySelectedColumnsByItemId: (state: IKanban, action: PayloadAction<TypeKanbanColId>) =>
  {
    state.displaySelectedColumnsByItemId = action.payload;
  },

  kanbanSetIfExistSigSpreadsheetRowExpiry: (
    state: IKanban,
    action: PayloadAction<ActionSetIfExistSigSpreadsheetRowExpiry>) =>
  {
    const payload = action.payload;
    const itemId = payload.itemId;
    const sigSpreadsheetRowExpiry = payload.sigSpreadsheetRowExpiry;
    const item = state.itemsById[itemId];

    if(item)
    {
      item.sigSpreadsheetRowExpiry = sigSpreadsheetRowExpiry;
    }
  }

};

function fnKanbanSetDisplay(state: IKanban): void
{
  const searchWordsLowercase = fnKanbanSetSearchWords(state);
  let columnsByItemIds: IKanbanColumnsByItemIds | undefined = state.columnsByItemIds;
  let columnIdList: TypeKanbanColId[] | undefined = state.columnIdList;

  if(columnsByItemIds)
  {
    columnsByItemIds = fnKanbanFilterSearchColumnsByItemIds(state, columnsByItemIds, searchWordsLowercase);
    columnsByItemIds = fnKanbanSortFieldColumnsByItemIds(state, columnsByItemIds);
  }
  if(columnIdList && columnsByItemIds)
  {
    columnIdList = fnKanbanFilterFieldColumnIds(state, columnIdList);
    columnIdList = fnKanbanSearchColumnIds(state, columnIdList, columnsByItemIds);
  }

  columnIdList = columnIdList.filter(columnId =>
  {
    return (isOrphanOptionId(columnId)
      ? Boolean(columnsByItemIds?.[columnId]?.itemIds?.length)
      : true);
  });

  state.displayColumnsByItemIds = columnsByItemIds;
  state.displayColumnIds = columnIdList;

}

// region filter
function fnKanbanFilterFieldColumnIds(
  state: IKanban,
  columnIdList: TypeKanbanColId[]): TypeKanbanColId[]
{
  if(state.filter !== undefined && state.filter.length > 0)
  {
    return columnIdList.filter(id => state.filter?.includes(id));
  }
  return columnIdList;
}

function fnKanbanSearchColumnIds(
  state: IKanban,
  columnIdList: TypeKanbanColId[],
  columnsByItemIds: IKanbanColumnsByItemIds
): TypeKanbanColId[]
{
  if(state.searchWords?.length)
  {
    return columnIdList.filter(columnId =>
    {
      return columnsByItemIds[columnId]?.itemIds.length > 0;
    });
  }
  return columnIdList;
}

// endregion

// region search
function fnKanbanFilterSearchColumnsByItemIds(
  state: IKanban,
  columnsByItemIds: IKanbanColumnsByItemIds,
  searchWordsLowercase?: string[]): IKanbanColumnsByItemIds
{
  if(searchWordsLowercase && searchWordsLowercase.length > 0)
  {
    const newColumnsByItemIds = {} as IKanbanColumnsByItemIds;
    Object
    .keys(columnsByItemIds)
    .forEach(columnId =>
    {
      const itemIds = columnsByItemIds[columnId].itemIds;
      const newItemIds = fnKanbanFilterSearchItemIds(state, itemIds, searchWordsLowercase);
      newColumnsByItemIds[columnId] = {
        itemIds: newItemIds
      } as IKanbanItemGroup;
    });
    return newColumnsByItemIds;
  }
  return columnsByItemIds;
}

function fnKanbanSetSearchWords(state: IKanban): string[] | undefined
{
  // noinspection DuplicatedCode
  const searchWords = state.searchWords;
  if(searchWords && searchWords.length > 0)
  {
    if(state.externalSearch)
    {
      return undefined;
    }

    const searchWordsLowercase = new Set<string>();
    searchWords.forEach(word =>
    {
      searchWordsLowercase.add(word.toLowerCase());
    });

    return Array.from(searchWordsLowercase);
  }
  else
  {
    state.searchWords = undefined;
    return undefined;
  }
}

function fnKanbanFilterSearchItemIds(
  state: IKanban,
  itemIds: TypeKanbanItemId[],
  searchWordsLowercase?: string[]): TypeKanbanItemId[]
{
  const searchItemIds: TypeKanbanItemId[] = [];
  itemIds.forEach(itemId =>
  {
    const item = state.itemsById[itemId];
    const defnForm = state.defnForm;

    if(defnForm && isFilterSearchKanbanItem(item, defnForm, searchWordsLowercase))
    {
      searchItemIds.push(itemId);
    }
    if(!state.hideItemTitle && item.formValue)
    {
      const formName = getFormName(defnForm, item.formValue);
      if(searchHit(formName, searchWordsLowercase))
      {
        searchItemIds.push(itemId);
      }
    }
  });

  return searchItemIds;
}

function isFilterSearchKanbanItem(
  searchItem: IKanbanItem,
  defnForm: DefnForm,
  searchWordsLowercase?: string[])
{
  const valueMap = searchItem.formValue?.valueMap;
  return isFormSearchHit(defnForm, valueMap, searchWordsLowercase);
}

// endregion

// region sort
function fnKanbanSortFieldColumnsByItemIds(
  state: IKanban,
  columnsByItemIds: IKanbanColumnsByItemIds): IKanbanColumnsByItemIds
{
  const sortFiledId = state.sortFieldId;
  if(sortFiledId)
  {
    const newColumnsByItemIds = {} as IKanbanColumnsByItemIds;
    Object
    .keys(columnsByItemIds)
    .forEach(columnId =>
    {
      const itemIds = columnsByItemIds[columnId].itemIds;
      const newItemIds = fnKanbanFilterSortItemIds(state, itemIds);
      newColumnsByItemIds[columnId] = {
        itemIds: newItemIds
      } as IKanbanItemGroup;
    });
    return newColumnsByItemIds;
  }
  return columnsByItemIds;
}

function fnKanbanFilterSortItemIds(
  state: IKanban,
  itemIds: TypeKanbanItemId[]): TypeKanbanItemId[]
{
  const itemValues = [] as (string | number)[];
  const unSortableItemIds: string[] = [];
  const sortFiledId = state.sortFieldId;
  const isDescending = state.sortOrder === "DESC";
  const textToIdMap = {} as Record<string | number, TypeKanbanItemId[]>;
  if(sortFiledId)
  {
    itemIds.forEach(itemId =>
    {
      const item = state.itemsById[itemId];
      const comp = state.defnForm?.compMap[sortFiledId];
      const sortingItem = item.formValue?.valueMap?.[sortFiledId];
      const sortingItemValue = getFormFieldValueAsText(comp, getValidFormFieldValue(sortingItem));
      if(sortingItemValue)
      {
        const isInt = !isNaN(parseInt(sortingItemValue));
        const value = isInt
          ? parseInt(sortingItemValue)
          : sortingItemValue;

        if(!textToIdMap[value])
        {
          textToIdMap[value] = [itemId];
          itemValues.push(value);
        }
        else
        {
          textToIdMap[value].push(itemId);
        }
      }
      else
      {
        unSortableItemIds.push(itemId);
      }
    });
    itemValues.sort();
  }

  const newItemIds = [] as TypeKanbanItemId[];
  itemValues.sort((a, b) =>
  {
    if(typeof a === "number" && typeof b === "number")
    {
      return a - b;
    }
    else
    {
      return a.toString().localeCompare(b.toString());
    }
  });
  itemValues.forEach(value =>
  {
    const itemIds = textToIdMap[value];
    if(itemIds)
    {
      newItemIds.push(...itemIds);
    }
  });

  return isDescending
    ? [...newItemIds, ...unSortableItemIds].reverse()
    : [...newItemIds, ...unSortableItemIds];
}

// endregion

