import {Popover} from "@mui/material";
import {useTheme} from "@mui/material";
import {PopoverOrigin} from "@mui/material/Popover/Popover";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import RawPopoverText from "./RawPopoverText";

export interface IPopoverMsg
{
  msg: string,
  children?: string[],
}

export interface IPopoverProps
{
  msg: string | string[] | IPopoverMsg[],
  bgcolor?: string,
  color?: string,
  borderRadius?: number,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
}

const popoverMaxWidth = 600;
const popoverMaxHeight = 900;

export default function RawPopover(props: {
  anchorEl: Element,
  popoverProps?: IPopoverProps,
  childNode?: React.ReactNode,
  onClose: () => void
})
{
  const theme = useTheme();

  const {
    anchorEl,
    popoverProps,
    childNode,
    onClose
  } = props;

  const msg = popoverProps?.msg;
  const defaultOrigin = {
    horizontal: "left",
    vertical: "bottom"
  } as PopoverOrigin;

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      transformOrigin={popoverProps?.transformOrigin}
      anchorOrigin={popoverProps?.anchorOrigin ?? defaultOrigin}
      sx={{
        // pointerEvents: "none",
        ".MuiPopover-paper": {
          bgcolor: popoverProps?.bgcolor,
          color: popoverProps?.color,
          maxWidth: px(popoverMaxWidth),
          maxHeight: px(popoverMaxHeight),
          borderRadius: px(popoverProps?.borderRadius ?? theme.common.gapQuarter)
        }
      }}
    >
      {childNode || (msg && <RawPopoverText msg={msg} />)}
    </Popover>
  );
}
