import {Divider} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import {DefnDtoColor} from "../../../../api/meta/base/dto/DefnDtoColor";
import {DefnFieldDivider} from "../../../../api/meta/base/dto/DefnFieldDivider";
import {EnumDefnThemeDirection} from "../../../../api/meta/base/Types";
import {defnTheme} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldBase from "../raw/FieldBase";

export default function FieldDivider(props: {
  defn: DefnFieldDivider
})
{
  const ctxFormSection = useFormSectionCtx();
  const defn = props.defn;

  const color = defn.color;

  const fieldId = defn.metaId;
  const parentSection = ctxFormSection.getParent();
  const sectionDirection = parentSection.sectionDirection;

  return (
    <FieldBase
      fieldId={fieldId}
      pl={defn.pl ?? 0}
      pr={defn.pr ?? 0}
    >
      <RawDivider
        defn={defn}
        sectionDirection={sectionDirection}
        color={color}
      />
    </FieldBase>
  );
}

function RawDivider(props: {
  defn: DefnFieldDivider,
  color?: DefnDtoColor,
  sectionDirection?: EnumDefnThemeDirection
})
{
  const theme = useTheme();

  const defn = props.defn;
  const sectionDirection: EnumDefnThemeDirection | undefined = defn.sectionDirection ?? props.sectionDirection;
  const color = props.color;

  const colorValue = color?.value && theme.common.colorWithShade(color?.value, color?.shade);

  const gapHalf = theme.common.gapHalf;
  const sizeDivider = theme.common.sizeDivider;
  return (
    <Box
      sx={{
        ...sectionDirection === "horizontal"
          ? {
            height: defnTheme.fieldSize === "small"
              ? px(theme.common.smallFieldSize)
              : px(theme.common.mediumFieldSize),
            width: (defn.dividerKindVar === "thick" || defn.dividerKind === "thick")
              ? px(gapHalf)
              : px(sizeDivider)
          }
          : {
            width: "100%",
            height: (defn.dividerKindVar === "thick" || defn.dividerKind === "thick")
              ? px(gapHalf)
              : px(sizeDivider)
          },
        pt: defn.pt !== undefined ? px(defn.pt) : 0,
        pb: defn.pb !== undefined ? px(defn.pb) : 0,
        pl: defn.pl !== undefined ? px(defn.pl) : 0,
        pr: defn.pr !== undefined ? px(defn.pr) : 0,
        display: "flex"
      }}
    >
      <Divider
        orientation={sectionDirection === "horizontal"
          ? "vertical"
          : "horizontal"}
        variant={sectionDirection !== "horizontal"
          ? "fullWidth"
          : undefined}
        flexItem={sectionDirection === "horizontal"}
        color={colorValue ?? theme.common.bgcolorDefault}
        tabIndex={-1}
        sx={{
          borderRadius: 0,
          ...sectionDirection === "horizontal"
            ? {
              height: "100%",
              width: (defn.dividerKindVar === "thick" || defn.dividerKind === "thick")
                ? px(gapHalf)
                : px(sizeDivider)
            }
            : {
              width: "100%",
              height: (defn.dividerKindVar === "thick" || defn.dividerKind === "thick")
                ? px(gapQuarter)
                : px(sizeDivider)
            },
          borderWidth: (defn.dividerKindVar === "thick" || defn.dividerKind === "thick")
            ? px(gapQuarter)
            : px(sizeDivider),
          borderColor: sectionDirection !== "horizontal"
            ? colorValue ?? theme.common.bgcolorDefault
            : undefined
        }}
      />
    </Box>
  );
}
