import {Dialog} from "@mui/material";
import React, {useState} from "react";

export default function AvatarViewDialog(props: {
  onClose: () => void,
  imageSrc?: string
})
{
  const [open, setOpen] = useState<boolean>(true);

  const imageSrc = props.imageSrc;
  return (
    imageSrc
      ? (
        <Dialog
          disableAutoFocus={true}
          disableEnforceFocus={true}
          disableRestoreFocus={true}
          open={open}
          onClose={() =>
          {
            props.onClose();
            setOpen(false);
          }}
          sx={{
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          PaperProps={{
            sx: {
              borderRadius: 0
            }
          }}
        >
          <img
            src={imageSrc}
            alt="profile"
          />
        </Dialog>
      )
      : <></>
  );
}
