import {Box} from "@mui/system";
import {Property} from "csstype";
import React from "react";
import {MediaIdImage} from "../../../../../api/meta/base/Types";
import {MediaIdVideo} from "../../../../../api/meta/base/Types";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../../base/plus/ConstantsPlus";
import {px} from "../../../../../base/plus/StringPlus";
import RawLazyImage from "../../../raw/RawLazyImage";

export default function BubbleRawMediaVideo(props: {
  src?: MediaIdVideo,
  blurImageSrc?: MediaIdImage;
  srcImage?: MediaIdImage;
  primaryColor?: string;
  width?: Property.Width,
  height?: Property.Height,
})
{
  const {
    blurImageSrc,
    srcImage,
    height,
    width,
    primaryColor,
    src
  } = props;

  return (
    <Box position={"relative"}>
      <RawLazyImage
        borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
        alt={src}
        src={srcImage}
        srcBlur={blurImageSrc}
        height={height}
        width={width}
        primaryColor={primaryColor}
        objectFit={"cover"}
      />
      <Box
        position={"absolute"}
        left={0}
        right={0}
        top={0}
        bottom={0}
        margin={"auto"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexGrow: 1,
          bgcolor: "rgba(0, 0, 0, 0.1)",
          borderRadius: px(CHAT_ITEM_IMAGE_BORDER_RADIUS)
        }}
      >
        {
          src && <video
            src={src}
            controls={true}
            controlsList={"nodownload"}
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
              borderRadius: px(CHAT_ITEM_IMAGE_BORDER_RADIUS)
            }}
          />
        }
      </Box>
    </Box>
  );
}
