import {Box} from "@mui/material";
import React from "react";
import {useRef} from "react";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {ICalendarEvent} from "../../../../base/types/TypeCalendar";
import {ICalendarBinderAll} from "../../../../base/types/TypeCalendar";
import {CbOnClickCalendarItem} from "../../../../base/types/TypeCalendar";
import {TypeCalendarItemId} from "../../../../base/types/TypeCalendar";
import CalendarEventItem from "./CalendarEventItem";

export default function CalendarEvent<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  event: ICalendarEvent,
  selectCalendar: SelectCalendar,
  cbOnClick?: CbOnClickCalendarItem,
  calendarBinder?: ICalendarBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  onItemSubscribe?: (itemId: TypeCalendarItemId) => void,
  onItemUnsubscribe?: (itemId: TypeCalendarItemId) => void,
})
{

  const event = props.event;
  const eventRef = useRef<Element>(null);

  return (
    <Box ref={eventRef}>
      <CalendarEventItem
        itemId={event.itemId}
        event={event}
        selectCalendar={props.selectCalendar}
        calendarBinder={props.calendarBinder}
        onItemSubscribe={props.onItemSubscribe}
        onItemUnsubscribe={props.onItemUnsubscribe}
        cbOnClick={(_menuAnchor, variant, itemId, calendarItemForm) =>
        {
          const parentElement = eventRef.current?.parentElement;
          if(parentElement)
          {
            props.cbOnClick && props.cbOnClick(parentElement, variant, itemId, calendarItemForm);
          }
        }}
      />
    </Box>
  );
};
