import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {Typography} from "@mui/material";
import {useCallback} from "react";
import {useEffect} from "react";
import {useMemo} from "react";
import {useState} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {useReactMediaRecorder} from "react-media-recorder-2";
import {nextMediaIdAudio} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldVoice} from "../../../../api/meta/base/dto/DefnFieldVoice";
import {FieldValueAudio} from "../../../../api/meta/base/dto/FieldValueAudio";
import {FieldValueVoice} from "../../../../api/meta/base/dto/FieldValueVoice";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {getDurationFromMs} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {BorderLabel} from "../../../atom/raw/BorderLabel";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import RawCaptureExtraProperties from "../raw/RawCaptureExtraProperties";
import RawFieldAudioBar from "./RawFieldAudioBar";

const COLOR_PROGRESS = "#3e3c3c";
const COLOR_WAVE = "#e3e3e3";
const COLOR_CURSOR = "black";

export default function FieldVoice(props: {
  defn: DefnFieldVoice
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const label = getCompLabel(defn);
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched || Boolean(error);
        const fieldValue = field.value as FieldValueVoice | undefined;

        const onChange = (value?: FieldValueVoice) =>
        {
          if(fieldValue)
          {
            MediaStore.removeMedia(fieldValue.mediaIdAudio);
          }
          if(value)
          {
            field.onChange(value);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <RawAudio
                fieldId={fieldId}
                defn={defn}
                fieldValue={field.value}
                onChange={onChange}
                defnTheme={defnTheme}
                disabled={disabled}
                label={label}
                readOnly={readOnly}
                error={error}
              />

            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawAudio(props: {
  fieldId: MetaIdField,
  defn: DefnFieldVoice,
  defnTheme: DefnDtoFormTheme,
  fieldValue?: FieldValueVoice,
  disabled?: boolean,
  label: string,
  readOnly?: boolean,
  error?: FieldError,
  onChange: (value?: FieldValueAudio) => void
})
{

  return (
    <RealRawAudio {...props} />
  );
}

function RealRawAudio(props: {
  fieldId: MetaIdField,
  defn: DefnFieldVoice,
  fieldValue?: FieldValueVoice,
  disabled?: boolean,
  label: string,
  readOnly?: boolean,
  error?: FieldError,
  onChange: (value?: FieldValueVoice) => void
})
{
  const {
    defn,
    fieldValue,
    disabled,
    readOnly,
    error,
    label,
    fieldId,
    onChange
  } = props;

  const formCtx = useFormCtx();
  const durationMs = parseFloat(getDurationFromMs(fieldValue?.durationMs ?? 0));
  const showCapturedAsHelperText = defn.showCapturedValuesOnAside;

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioDuration, setAudioDuration] = useState<string>("00 : 00");
  const [isRecorderVisible, setIsRecorderVisible] = useState<boolean>(!fieldValue?.mediaIdAudio);

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const audioSrc = useMemo(() =>
  {
    const tempMediaSrc = fieldValue?.mediaIdAudio ? MediaStore.getMedia(fieldValue.mediaIdAudio) : undefined;

    return tempMediaSrc || (fieldValue?.mediaIdAudio
      ? getMediaSrc(fieldValue.mediaIdAudio)
      : undefined);
  }, [fieldValue?.mediaIdAudio]);

  const onStart = useCallback(() => setIsRecording(true), []);

  const onStop = useCallback((_blobUrl: string, blob: Blob) =>
  {
    setAudioDuration("00 : 00");
    setIsRecording(false);
    uploadFile(blob);
    setIsRecorderVisible(false);
  }, []);

  const {
    status,
    startRecording,
    stopRecording,
    clearBlobUrl
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    blobPropertyBag: {
      type: "audio/mp3",
      endings: "transparent"
    },
    onStop: onStop,
    onStart: onStart,
    stopStreamsOnStop: true
  });

  const uploadFile = (blob: Blob) =>
  {
    const mediaIdAudio = nextMediaIdAudio(getFileExt(blob as File) || "mp3");
    const file = new File([blob], defn.name, {
      type: "audio/mp3",
      lastModified: new Date().getTime()
    });

    file && MediaStore.setBlobToUrl(mediaIdAudio, file);
    onChange({
      fileName: file?.name || mediaIdAudio,
      fileSize: file?.size,
      mediaIdAudio: mediaIdAudio
    } as FieldValueVoice);
  };

  const onClickPlayPause = useCallback(() =>
  {
    if(!isRecording && status === "idle")
    {
      startRecording();
    }
    else
    {
      stopRecording();
    }
  }, [isRecording, status]);

  const deleteRecording = () => clearBlobUrl();

  useEffect(() =>
  {
    let intervalId: NodeJS.Timeout;
    if(status === "recording")
    {
      let counter = 1;
      intervalId = setInterval(() =>
      {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);
        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter.toString();

        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter.toString();

        setAudioDuration(`${computedMinute} : ${computedSecond}`);
        counter++;
      }, 1000);
    }

    return () =>
    {
      clearInterval(intervalId);
    };
  }, [status]);

  useEffect(() =>
  {
    stopRecording();
  }, [fieldValue?.mediaIdAudio]);

  return (
    <LayoutFlexRow
      overflowX={"visible"}
      overflowY={"visible"}
      height={"100%"}
    >
      <LayoutFlexCol
        flexGrow={1}
        overflowX={"visible"}
        overflowY={"visible"}
        alignItems={"start"}
        height={"100%"}
      >

        <LayoutFlexRow
          flexGrow={1}
          width={"100%"}
          overflowX={"visible"}
          overflowY={"visible"}
          height={"100%"}
        >
          <BorderLabel
            label={label}
            borderColor={borderColor}
            onClick={onClick}
          >
            {
              isRecorderVisible
                ? <>
                  <RawIconButton
                    name={"AudioPlayPause"}
                    icon={isRecording ? "PauseCircleRounded" : "MicRounded"}
                    onClick={onClickPlayPause}
                  />
                  <LayoutFlexRow
                    flexGrow={1}
                    pr={px(gapStd * 3)}
                  >
                    <Typography>{audioDuration}</Typography>
                  </LayoutFlexRow>
                </>
                : <LayoutFlexRow
                  flexGrow={1}
                  alignItems={"start"}
                  justifyContent={"left"}
                  padding={0}
                >
                  {(!isRecording && audioSrc)
                    ? <RawFieldAudioBar
                      src={audioSrc}
                      durationMs={durationMs}
                      scrollable={true}
                      cursorColor={COLOR_CURSOR}
                      progressColor={COLOR_PROGRESS}
                      waveColor={COLOR_WAVE}
                    />
                    : null
                  }
                </LayoutFlexRow>
            }
          </BorderLabel>
          {
            !(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapHalf)}
              mr={`-${gapHalf}px`}
            >
              <RawIconStrip
                iconStrip={["delete"]}
                onClick={(icon) =>
                {
                  if(icon === "delete")
                  {
                    onChange(undefined);
                    deleteRecording();
                    setIsRecorderVisible(true);
                  }
                }}
                iconStripDisable={(!fieldValue || readOnly || disabled) ? ["delete"] : []}
              />
            </LayoutFlexRow>
          }
          <FieldRawRefButton
            defn={defn}
          />

        </LayoutFlexRow>
        {
          <RawCaptureExtraProperties
            captureTime={fieldValue?.captureTime}
            captureUser={fieldValue?.captureUser}
            captureLocation={fieldValue?.captureLocation}
            showCapturedAsHelperText={showCapturedAsHelperText}
          />
        }
        <FormHelperText
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {error?.message ? error.message : defnDtoTextToString(defn.helperTextVar)}
        </FormHelperText>
      </LayoutFlexCol>
    </LayoutFlexRow>
  );
}
