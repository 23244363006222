import {SigSpreadsheetRowExpiry} from "../../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnLayoutCalendar} from "../../../../api/meta/base/dto/DefnLayoutCalendar";
import {DefnLayoutGrid} from "../../../../api/meta/base/dto/DefnLayoutGrid";
import {FieldDtoGridRow} from "../../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueColor} from "../../../../api/meta/base/dto/FieldValueColor";
import {FormValueRaw} from "../../../../api/meta/base/dto/FormValueRaw";
import {EntUserId} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {createCalendarBubble} from "../../../../base/plus/CalendarPlus";
import {dispatchCalendar} from "../../../../base/plus/CalendarPlus";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {random} from "../../../../base/plus/StringPlus";
import {calendarSetCacheMonthlyData} from "../../../../base/slices/calendar/SliceCalendar";
import {calendarRefresh} from "../../../../base/slices/calendar/SliceCalendar";
import {calendarSetUserFieldVar} from "../../../../base/slices/calendar/SliceCalendar";
import {calendarSetItem} from "../../../../base/slices/calendar/SliceCalendar";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListBinder} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListData} from "../../../../base/types/list/TypesList";
import {ICalendarData} from "../../../../base/types/TypeCalendar";
import {TypeCalendarItemId} from "../../../../base/types/TypeCalendar";
import {ICalendarBinderThree} from "../../../../base/types/TypeCalendar";
import {toComboId} from "../../../../base/types/TypesComboId";
import {ICacheSsEditorRow} from "../../../../cache/app/spreadsheet/ssEditor/TypesCacheSpreadsheetEditor";
import {RootState} from "../../../../Store";
import {store} from "../../../../Store";
import {Srvc} from "../../../Srvc";
import {selectCacheRow} from "../SrvcSpreadsheet";
import {TypeUserFieldSsEditor} from "./SrvcSsEditor";
import {TypeUserFieldSsEditorList} from "./SrvcSsEditorList";

interface TypeUserFieldSsEditorCalendar extends TypeUserFieldSsEditor
{
  calendarDateField: string;
  calendarTitleField: string;
  calendarColorField?: string;
  calendarTooltipField?: string;
}

export default class SrvcSsEditorCalendar extends ISrvc
{
  subscriberId = "SrvcSsEditorCalendar";

  constructor(readonly selectCalendar: SelectCalendar, readonly selectList: SelectList)
  {
    super();
  }

  getCalendarBinder()
  {
    return {
      selectSourceItem1: this.selectSsRow.bind(this),
      onBindSourceItem1: this.onBindSsRow.bind(this),
      selectSourceItem2: this.selectSsRowId.bind(this),
      onBindSourceItem2: this.onBindSsRowId.bind(this),
      selectSourceItem3: this.selectSsExpiry.bind(this),
      onBindSourceItem3: this.onBindSsExpiry.bind(this)
    } as ICalendarBinderThree<SigSpreadsheetRow | undefined, boolean | undefined, SigSpreadsheetRowExpiry | undefined>;
  }

  loadCalendar(
    calendarName: string,
    initValues: Record<RowId, FieldDtoGridRow>,
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    defnForm?: DefnForm,
    searchText?: string
  )
  {

    const itemsById = {} as Record<TypeCalendarItemId, FormValueRaw>;
    Object.entries(initValues).forEach(([rowId, fieldDtoGridRow]) =>
    {
      const typeCalendarItemId = rowId as TypeCalendarItemId;
      const formValueRaw = fieldDtoGridRow as FormValueRaw;
      itemsById[typeCalendarItemId] = formValueRaw;
    });

    const calendarData: ICalendarData = {
      itemsById: itemsById,
      defnForm: defnForm,
      userField: {
        entId: entId,
        spreadsheetId: spreadsheetId
      } as TypeUserFieldSsEditorCalendar,
      cachedMonthlyEvents: {},
      version: random()
    };

    dispatchCalendar(calendarName, calendarRefresh(calendarData));
  }

  setCalendarSetCacheMonthlyData(
    calendarName: string,
    dateValue: string,
    initValues: Record<RowId, FieldDtoGridRow>
  )
  {
    const dateValueItem = initValues as Record<TypeCalendarItemId, FormValueRaw>;

    dispatchCalendar(calendarName, calendarSetCacheMonthlyData({
      dateValue: dateValue,
      dateValueItem: dateValueItem
    }));
  }

  //region calendar load more item aside
  getAsideCalendarItemBinder()
  {
    return {
      selectSourceItem1: this.asideCalendarSelectSsRow.bind(this),
      onBindSourceItem1: this.asideCalendarOnBindSsRow.bind(this),
      selectUserAvatar: this.asideCalendarUserAvatar.bind(this)

    } as IListBinder<SigSpreadsheetRow | undefined>;
  }

  doLoadCalendarAsideItem(
    listName: string,
    rowIds: RowId[],
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    defnForm?: DefnForm,
    spreadsheetLayout?: DefnLayoutGrid
  )
  {
    const uiItemsById = {} as IListItemsById;
    if(rowIds?.length !== 0)
    {
      rowIds?.forEach((itemId) =>
      {
        const rootState = store.getState();

        uiItemsById[itemId] = {
          ...createCalendarBubble(undefined, undefined),
          userField: {
            sigSpreadsheetRow: this.selectSsRow(rootState, itemId)
          }
        };

      });
    }

    dispatchList(listName, listRefresh({
      itemIds: rowIds,
      itemsById: uiItemsById,
      userField: {
        entId: entId,
        spreadsheetId: spreadsheetId,
        defnForm: defnForm,
        spreadsheetLayout: spreadsheetLayout
      } as TypeUserFieldSsEditor,
      version: random()
    } as IListData));
  }

  //endregion

  private selectSsRow(rootState: RootState, rowId: TypeCalendarItemId)
  {
    const entId = this.selectUserField(rootState)?.entId;
    if(entId)
    {
      return selectCacheRow(rootState, entId, rowId);
    }
  }

  private onBindSsRow(calendarName: string, rowId: TypeCalendarItemId, spreadsheetRow?: SigSpreadsheetRow)
  {
    const formValue = spreadsheetRow?.formValue as FormValueRaw;

    dispatchCalendar(calendarName, calendarSetItem({
      itemId: rowId,
      item: formValue
    }));

  }

  private selectSsRowId(
    rootState: RootState,
    rowId: TypeCalendarItemId): ICacheSsEditorRow | undefined
  {
    const spreadsheetId = (this.selectCalendar(rootState).userField as TypeUserFieldSsEditorList)?.spreadsheetId as MetaIdSpreadsheet;
    if(spreadsheetId)
    {
      return rootState.cache.app.spreadsheet.ssEditor.ssStateMap[spreadsheetId]?.rowIdMap[rowId];
    }
  }

  private onBindSsRowId(calendarName: string, rowId: TypeCalendarItemId, source?: ICacheSsEditorRow)
  {
    if(source?.removed)
    {
      dispatchCalendar(calendarName, calendarSetUserFieldVar(
        {
          varName: "isInvalid",
          varValue: true
        }
      ));
    }
    else if(source?.error)
    {
      // dispatchCalendar(calendarName,
      //   calendarSetIfExistIsInvisibleSpreadsheetRow({
      //     itemId: rowId,
      //     isInvisible: true
      //   })
      // );
      // dispatchCalendar(calendarName,
      //   calendarSetItemIfExistUserFieldVar({
      //     itemId: rowId,
      //     varName: "expired",
      //     varValue: true
      //   })
      // );
    }
  }

  private selectSsExpiry(
    rootState: RootState,
    rowId: TypeCalendarItemId): SigSpreadsheetRowExpiry | undefined
  {
    const entId = this.selectUserField(rootState)?.entId;
    if(entId)
    {
      return rootState.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.spreadsheetRowExpiryMap?.[rowId];
    }
  }

  private onBindSsExpiry(
    calendarName: string,
    rowId: TypeCalendarItemId,
    sig?: SigSpreadsheetRowExpiry)
  {
    // dispatchCalendar(calendarName, calendarSetIfExistSigSpreadsheetRowExpiry({
    //   itemId: rowId,
    //   sigSpreadsheetRowExpiry: sig
    // }));
  }

  private selectUserField(rootState: RootState): TypeUserFieldSsEditorCalendar | undefined
  {
    return this.selectCalendar(rootState)?.userField as TypeUserFieldSsEditorCalendar;
  }

  //region calendar load more item aside
  private asideCalendarSelectSsRow(rootState: RootState, rowId: TypeCalendarItemId)
  {
    const calendarItems = rootState.home.aside.calendarItems;
    const userField = calendarItems.userField as TypeUserFieldSsEditor;
    const entId = userField?.entId as EntId;

    if(entId)
    {
      return selectCacheRow(rootState, entId, rowId);
    }
  }

  private asideCalendarOnBindSsRow(listName: string, rowId: TypeCalendarItemId, spreadsheetRow?: SigSpreadsheetRow)
  {
    const formValue = spreadsheetRow?.formValue;
    const rootState = store.getState();
    const userField = this.selectList(rootState).userField as TypeUserFieldSsEditor;
    const defnForm = userField?.defnForm;
    const spreadsheetLayout = userField.spreadsheetLayout as DefnLayoutCalendar;
    const colorFieldId = spreadsheetLayout?.colorFieldId;
    const itemBgColor = colorFieldId
      ? (formValue?.valueMap[colorFieldId] as FieldValueColor)?.value
      : undefined;

    if(defnForm && formValue)
    {
      const listItemSsRowBubble = createCalendarBubble(defnForm, formValue, itemBgColor);
      dispatchList(listName, listSetItem({
        itemId: rowId,
        newItem: listItemSsRowBubble
      }));
    }
  }

  private asideCalendarUserAvatar(rootState: RootState, _: TypeCalendarItemId, entUserId: EntUserId)
  {
    const calendarItems = rootState.home.aside.calendarItems;
    const userField = calendarItems.userField as TypeUserFieldSsEditor;
    const entId = userField?.entId as EntId;

    if(entId)
    {
      const comboEntUserId = toComboId(entId, entUserId);
      const userAvatar = rootState.cache.app.user.userAvatarMap[comboEntUserId];
      if(!userAvatar)
      {
        Srvc.app.spreadsheet.subscribeSsUser(this.subscriberId, entId, entUserId);
      }
      return userAvatar;

    }
  }

  //endregion

}
