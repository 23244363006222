import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarStroke} from "../../../api/meta/base/dto/StudioVarStroke";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";

export function fnVariableBuilderStroke(fieldValueStroke: string)
{
  function getVarComp()
  {
    return {
      [fieldValueStroke]: {
        type: "enumThemeStroke",
        metaId: fieldValueStroke,
        name: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarStroke
  )
  {
    valueMap[fieldValueStroke] = fnRawValueToFieldValue("lineStroke", variable.value);
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueStroke];

    return {
      value: value
    } as StudioVarStroke;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
