import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDuration} from "../../../api/meta/base/dto/DefnFieldDuration";
import {FieldValueDuration} from "../../../api/meta/base/dto/FieldValueDuration";
import {StudioVarDuration} from "../../../api/meta/base/dto/StudioVarDuration";

export function fnVariableBuilderDuration(fieldValueDuration: string)
{
  function getVarComp()
  {
    return {
      [fieldValueDuration]: {
        type: "duration",
        metaId: fieldValueDuration,
        label: "Value",
        required: true,
        hideLabel: true
      } as DefnFieldDuration
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarDuration
  )
  {
    const varValue = entVariable.value;
    if(varValue)
    {
      valueMap[fieldValueDuration] = {
        value: varValue.value
      } as FieldValueDuration;
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const duration = valueMap[fieldValueDuration] as FieldValueDuration;
    if(duration && duration.value)
    {
      return {
        value: {
          value: duration.value
        }
      } as StudioVarDuration;
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
