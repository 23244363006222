import {ISsBrRef} from "../SsBr";
import {useSsBrCtx} from "./CtxSsBr";

export default function useSsBrRefSetter(props: {
  cbRef?: ISsBrRef
})
{
  const ssBrCtx = useSsBrCtx();

  if(props.cbRef)
  {
    props.cbRef.setFilters = (filters) => ssBrCtx.setFilters({filters: filters});
    props.cbRef.setFilterState = (payload) => ssBrCtx.setFilterState(payload);
    props.cbRef.setSearchState = (payload) => ssBrCtx.setSearchState(payload);
    props.cbRef.setFooterPayload = (payload) => ssBrCtx.setFooterPayload(payload);
  }
}
