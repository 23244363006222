import YouTubeIcon from "@mui/icons-material/YouTube";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Typography} from "@mui/material";
import {trim} from "lodash";
import {useLayoutEffect} from "react";
import {useState} from "react";
import React from "react";
import {DtoMessagePayloadLinkText} from "../../../../api/home/base/dto/DtoMessagePayloadLinkText";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../base/plus/ConstantsPlus";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {isYouTubeLink} from "../../../../base/plus/UrlPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import IconBubbleCaption from "../../icon/IconBubbleCaption";
import LayoutFlexCol from "../../layout/LayoutFlexCol";
import {LayoutGap} from "../../layout/LayoutGap";
import RawHighlighter from "../../raw/RawHighlighter";
import RawImage from "../../raw/RawImage";
import BubbleRawCaption from "../raw/BubbleRawCaption";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawLinkText from "../raw/BubbleRawLinkText";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleShell from "./BubbleShell";

export default function BubbleLinkText(props: {
  payload: DtoMessagePayloadLinkText,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  header?: IBubbleHeader,
  isStar?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor,
  onClickSendMessageToUser?: (entUserId: EntUserId, menuAnchor: Element) => void
})
{
  const payload = props.payload;
  const [linkPageIconUrl, setLinkPageIconUrl] = useState(payload?.pageIconUrl);
  const [linkUrl, setLinkUrl] = useState(payload?.pageUrl);

  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const gap = gapHalf - (theme.common.bubbleShellGap + theme.common.bubbleShellBorder);

  const isStar = props.isStar;
  const captionIcon = props.receiptStatus;
  const onClickCaption = props.cbOnClickFooterCaption;
  const searchWords = props.searchWords;
  const pageTitle = payload.pageTitle;
  const pageSubTitle = payload.pageSubTitle;
  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));
  const mentionMap = payload.mentionMap;
  const onClickSendMessageToUser = props.onClickSendMessageToUser;
  const maxWidth = (props.maxWidth > theme.common.bubbleTextMaxWidth
    ? theme.common.bubbleTextMaxWidth
    : props.maxWidth) - 42; // 42 pixels gap for reaction icon that appears on mouse hover

  useLayoutEffect(() =>
  {
    let url = linkUrl ?? "";
    if(!/^https?:\/\//i.test(url))
    {
      url = "http://" + url;
    }
    setLinkUrl(url);
  }, []);

  const onClickMessageToUser = (mentionUser: string, menuAnchor: Element) =>
  {
    if(mentionMap)
    {
      const entUserId = mentionMap[mentionUser];
      onClickSendMessageToUser && onClickSendMessageToUser(entUserId, menuAnchor);
    }
  };

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={maxWidth}
      fullWidth={Boolean(!linkPageIconUrl)}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleBlinking={props.isBubbleBlinking}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      {
        props.header !== undefined &&
        <BubbleRawHeader
          header={props.header}
          onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
        />
      }

      {
        props.replyPayload &&
        <BubbleRawReplyPreview
          replyPayload={props.replyPayload}
          replyInfo={props.replyInfo}
          onClickPreview={props.cbOnClickReplyPreview}
        />
      }

      <Box
        bgcolor={"rgba(0,0,0,0.075)"}
        borderRadius={px(theme.common.gapQuarter)}
      >
        {
          linkPageIconUrl &&
          <a
            href={linkUrl}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <PreviewImage
              imagePreviewLink={linkPageIconUrl}
              isYoutubeLink={isYouTubeLink(linkUrl)}
            />
          </a>
        }

        {
          (trim(pageTitle) || trim(pageSubTitle)) &&
          <LayoutFlexCol
            padding={px(gapHalf)}
            alignItems={"start"}
          >
            {
              pageTitle?.trim() !== "" && pageTitle &&
              <>
                <RawHighlighter
                  variant={"body2"}
                  color={"textPrimary"}
                  bold={false}
                  value={pageTitle}
                  noWrap={false}
                  searchWords={searchWords}
                  breakWords={true}
                />
                <LayoutGap height={px(gapHalf)} />
              </>
            }

            {
              pageSubTitle?.trim() !== "" && pageSubTitle &&
              <RawHighlighter
                variant={"caption"}
                color={"textSecondary"}
                value={pageSubTitle}
                bold={false}
                noWrap={false}
                searchWords={searchWords}
                breakWords={true}
                maxNumberOfLine={3}
              />
            }

          </LayoutFlexCol>
        }
      </Box>

      <BubbleRawLinkText
        value={payload.text}
        maxCharLimit={800}
        mentionMap={mentionMap}
        searchWords={searchWords}
        onClickSendMessageToUser={onClickMessageToUser}
        fakeSpace={
          <>
            {Boolean(isStar) &&
              <IconBubbleCaption
                value={"star"}
                color={"dark"}
              />
            }
            <Typography
              ml={px(theme.common.gapQuarter)}
              variant={"caption"}
            >
              {captionTime}
            </Typography>
            <IconBubbleCaption
              value={captionIcon}
              color={"dark"}
            />
          </>
        }
        caption={
          <BubbleRawCaption
            isStar={Boolean(isStar)}
            textRight={captionTime}
            icon={captionIcon}
            iconColor={"dark"}
            onClickCaption={onClickCaption}
          />
        }
      />
    </BubbleShell>
  );
}

function PreviewImage(props: {imagePreviewLink: string, isYoutubeLink: boolean})
{
  return (
    props.isYoutubeLink
      ? <LayoutFlexCol
        bgcolor={theme.common.color("textDisabled")}
        borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
      >
        <Box
          position={"relative"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
        >
          <RawImage
            borderTopLeftRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
            borderTopRightRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
            src={props.imagePreviewLink}
            height={"auto"}
            width={"100%"}
            aspectRatio={1}
            objectFit={"cover"}
          />

          <Box
            width={"100%"}
            position={"absolute"}
            left={0}
            right={0}
            top={0}
            bottom={0}
            margin={"auto"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: px(CHAT_ITEM_IMAGE_BORDER_RADIUS)
            }}
          >
            <>
              <Box
                sx={{
                  width: px(20),
                  height: px(20),
                  position: "absolute",
                  backgroundColor: "white",
                  zIndex: 0
                }}
              />
              <YouTubeIcon
                sx={{
                  color: theme.common.color("error"),
                  width: px(100),
                  height: px(80),
                  position: "relative",
                  cursor: "pointer"
                }}
              />
            </>
          </Box>
        </Box>
      </LayoutFlexCol>
      : <RawImage
        bgColor={theme.palette.background.paper}
        borderTopLeftRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
        borderTopRightRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
        src={props.imagePreviewLink}
        height={"auto"}
        width={"100%"}
        aspectRatio={1}
        objectFit={"cover"}
      />
  );
}
