import {useEffect} from "react";
import {IDataGridCellRendererParams} from "../../../../base/types/TypeDataGrid";
import {useAppSelector} from "../../../app/AppHooks";

export default function GridCellPrimaryCell<SR1, SR2, SR3, SR4, SR5, SR6>(props: IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const rowId = props.data?.rowId;
  const api = props.api;
  const gridBinder = props.gridBinder;

  const selectSourceRow1 = gridBinder?.selectSourceRow1;
  const selectSourceRow2 = gridBinder?.selectSourceRow2;
  const selectSourceRow3 = gridBinder?.selectSourceRow3;
  const selectSourceRow4 = gridBinder?.selectSourceRow4;
  const selectSourceRow5 = gridBinder?.selectSourceRow5;
  const selectSourceRow6 = gridBinder?.selectSourceRow6;
  const onBindSourceRow1 = gridBinder?.onBindSourceRow1;
  const onBindSourceRow2 = gridBinder?.onBindSourceRow2;
  const onBindSourceRow3 = gridBinder?.onBindSourceRow3;
  const onBindSourceRow4 = gridBinder?.onBindSourceRow4;
  const onBindSourceRow5 = gridBinder?.onBindSourceRow5;
  const onBindSourceRow6 = gridBinder?.onBindSourceRow6;

  const sourceItem1 = useAppSelector(state => (selectSourceRow1 && rowId) ? selectSourceRow1(state, rowId) : undefined);
  const sourceItem2 = useAppSelector(state => (selectSourceRow2 && rowId) ? selectSourceRow2(state, rowId) : undefined);
  const sourceItem3 = useAppSelector(state => (selectSourceRow3 && rowId) ? selectSourceRow3(state, rowId) : undefined);
  const sourceItem4 = useAppSelector(state => (selectSourceRow4 && rowId) ? selectSourceRow4(state, rowId) : undefined);
  const sourceItem5 = useAppSelector(state => (selectSourceRow5 && rowId) ? selectSourceRow5(state, rowId) : undefined);
  const sourceItem6 = useAppSelector(state => (selectSourceRow6 && rowId) ? selectSourceRow6(state, rowId) : undefined);
  useEffect(() =>
  {
    if(onBindSourceRow1 && rowId)
    {
      onBindSourceRow1(rowId, api, sourceItem1);
    }
  }, [sourceItem1, onBindSourceRow1, rowId]);

  useEffect(() =>
  {
    if(onBindSourceRow2 && rowId)
    {
      onBindSourceRow2(rowId, api, sourceItem2);
    }
  }, [sourceItem2, onBindSourceRow2, rowId]);

  useEffect(() =>
  {
    if(onBindSourceRow3 && rowId)
    {
      onBindSourceRow3(rowId, api, sourceItem3);
    }
  }, [sourceItem3, onBindSourceRow3, rowId]);

  useEffect(() =>
  {
    if(onBindSourceRow4 && rowId)
    {
      onBindSourceRow4(rowId, api, sourceItem4);
    }
  }, [sourceItem4, onBindSourceRow4, rowId]);

  useEffect(() =>
  {
    if(onBindSourceRow5 && rowId)
    {
      onBindSourceRow5(rowId, api, sourceItem5);
    }
  }, [sourceItem5, onBindSourceRow5, rowId]);

  useEffect(() =>
  {
    if(onBindSourceRow6 && rowId)
    {
      onBindSourceRow6(rowId, api, sourceItem6);
    }
  }, [sourceItem6, onBindSourceRow6, rowId]);

  return null;
}
