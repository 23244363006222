import {ImageRounded} from "@mui/icons-material";
import {FormHelperText} from "@mui/material";
import {CircularProgress} from "@mui/material";
import {Box} from "@mui/material";
import {TextField} from "@mui/material";
import {FormControl} from "@mui/material";
import {SxProps} from "@mui/system";
import {Property} from "csstype";
import {useCallback} from "react";
import React from "react";
import {useState} from "react";
import {Controller} from "react-hook-form";
import {nextMediaIdImage} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldImage} from "../../../../api/meta/base/dto/DefnFieldImage";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {FieldValueImage} from "../../../../api/meta/base/dto/FieldValueImage";
import {FORM_IMAGE_SIZE_MAX} from "../../../../base/plus/ConstantsPlus";
import {FORM_IMAGE_MAX_MB} from "../../../../base/plus/ConstantsPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {KbToMb} from "../../../../base/plus/MathPlus";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getPrimaryColorAndBlurredImg} from "../../../../base/plus/MediaPlus";
import {compressImage} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {Srvc} from "../../../../srvc/Srvc";
import AvatarViewDialog from "../../../atom/avatar/AvatarViewDialog";
import {IDialogMsg} from "../../../atom/dialog/TypesDialog";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {ICtxFileSelector} from "../../../atom/raw/RawFileSelector";
import RawFileSelector from "../../../atom/raw/RawFileSelector";
import {useFileSelectorCtx} from "../../../atom/raw/RawFileSelector";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawLazyImage from "../../../atom/raw/RawLazyImage";
import {usePageCtx} from "../../../ctx/CtxPage";
import ICtxPage from "../../../ctx/ICtxPage";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import IFormCtx from "../base/CtxForm";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldLabel from "./FieldLabel";

const fieldImagePreviewWidth = "150px";
const fieldImagePreviewHeight = "150px";

const selectBoxStyle = {
  aspectRatio: 1 / 1,
  borderStyle: "dashed",
  borderColor: theme.palette.grey[300],
  borderRadius: px(gapHalf),
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

export function FieldImage(props: {
  defn: DefnFieldImage
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const {
    getFieldRequired,
    getFieldShowLabel,
    getFieldShowPreview,
    getFieldShowSize,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();

  const defnTheme = formCtx.getDefnTheme();
  const fieldId = getFieldKey(defn);
  const showPreview = getFieldShowPreview();
  const showImageData = getFieldShowSize();
  const fieldVariant = defnTheme.fieldVariant;
  const label = getCompLabel(defn);
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();
  const [loading, setLoading] = useState(false);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;
        const isError = Boolean(error);

        const fieldValue = field.value as FieldValueImage | undefined;
        const isEditable = formCtx.isFieldReadonly(defn);
        const onChangeLoading = (loading: boolean) =>
        {
          setLoading(loading);
        };

        const reportNode = <ImagePreview
          image={fieldValue}
          isEditable={isEditable}
          formCtx={formCtx}
          defnImage={defn}
          selectBoxStyle={{
            ...selectBoxStyle,
            border: "none"
          }}
          loading={loading}
          onChangeLoading={onChangeLoading}
          onChange={field.onChange}
          borderRadius={px(0)}
        />;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            reportNode={reportNode}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <RawFieldImage
                loading={loading}
                image={fieldValue}
                isEditable={!isEditable}
                formCtx={formCtx}
                label={label}
                defnImage={defn}
                error={error?.message}
                showPreview={showPreview as boolean}
                showImageData={showImageData as boolean}
                onChange={field.onChange}
                onChangeLoading={onChangeLoading}
                readOnly={readOnly}
                disabled={disabled}
                fieldId={getFieldKey(defn)}
                required={required}
                placeHolder={placeHolder}
                icon={icon}
              />
              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawFieldImage(props: {
  loading?: boolean,
  image?: FieldValueImage,
  label?: string,
  isEditable: boolean,
  formCtx: IFormCtx,
  fieldId: string
  defnImage: DefnFieldImage,
  error?: string,
  showPreview?: boolean,
  showImageData?: boolean,
  onChange: (value?: FieldValueImage | null) => void,
  onChangeLoading: (loading: boolean) => void,
  readOnly?: boolean,
  disabled?: boolean,
  required?: boolean,
  placeHolder?: string,
  icon?: string
})
{
  return (
    <RawFileSelector multiple={true}>
      <UploadImage {...props} />
    </RawFileSelector>
  );
}

function UploadImage(props: {
  loading?: boolean,
  image?: FieldValueImage,
  label?: string,
  isEditable: boolean,
  formCtx: IFormCtx,
  defnImage: DefnFieldImage,
  fieldId: string,
  error?: string,
  showPreview?: boolean,
  showImageData?: boolean,
  onChange: (value?: FieldValueImage | null) => void,
  onChangeLoading: (loading: boolean) => void,
  readOnly?: boolean,
  disabled?: boolean,
  required?: boolean,
  placeHolder?: string,
  icon?: string
})
{
  const {
    image,
    loading,
    isEditable,
    formCtx,
    defnImage,
    label,
    error,
    showImageData,
    showPreview,
    onChange,
    onChangeLoading,
    readOnly,
    disabled,
    fieldId,
    required,
    placeHolder,
    icon
  } = props;

  const pageCtx = usePageCtx();
  const fileSelectorCtx = useFileSelectorCtx();

  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const compId = getFieldKey(defnImage);
  const tempMediaUrl = image?.value.mediaIdImage
    ? MediaStore.getMedia(image.value.mediaIdImage)
    : undefined;
  const getOnClick = formCtx.getOnClick();

  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;

  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const imgUrl = tempMediaUrl || getMediaSrc(image?.value.mediaIdImage);

  return (
    <>
      <LayoutFlexCol
        height={"auto"}
        width={"100%"}
        overflowY={"visible"}
        overflowX={"visible"}
      >
        <LayoutFlexRow
          overflowY={"visible"}
          overflowX={"visible"}
          width={"100%"}
        >

          <TextField
            sx={{
              ...borderColor && {
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: borderColor
                  }
                }
              }
            }}
            fullWidth
            type="text"
            size={defnTheme.fieldSize}
            margin={defnTheme.fieldMargin}
            variant={fieldVariant}
            autoComplete={Boolean(defnImage.autoFill) ? "on" : "off"}
            autoFocus={Boolean(defnImage.autoFocus)}
            disabled={disabled}
            hiddenLabel={Boolean(defnImage.label)}
            label={label}
            name={compId}
            placeholder={placeHolder}
            InputProps={{
              readOnly: true,
              endAdornment: icon
                ? <RawIconButton
                  name={icon}
                  icon={icon}
                />
                : <ImageRounded color={"action"} />
            }}
            required={required}
            error={Boolean(error)}
            value={image ? image.value?.fileName : ""}
            onKeyDown={(e) =>
            {
              if(e.key === "Enter")
              {
                onClick && onClick();
                onClickCapture(
                  formCtx,
                  defnImage,
                  onChangeLoading,
                  onChange,
                  pageCtx,
                  fileSelectorCtx
                );
              }
            }}
            onClick={() =>
            {
              onClick && onClick();
              onClickCapture(
                formCtx,
                defnImage,
                onChangeLoading,
                onChange,
                pageCtx,
                fileSelectorCtx
              );
            }}
          />

          <LayoutFlexRow
            ml={px(gapQuarter)}
            overflowY={"visible"}
            overflowX={"visible"}
          >
            <RawIconStrip
              iconStrip={["visible"]}
              onClick={(icon) =>
              {
                if(icon === "visible" && image)
                {
                  pageCtx.showDialog(<AvatarViewDialog
                    imageSrc={imgUrl}
                    onClose={() => pageCtx.showDialog(undefined)}
                  />);
                }
              }}
              iconStripDisable={(!image) ? ["visible"] : []}
            />
          </LayoutFlexRow>
          {!(readOnly || disabled) &&
            <LayoutFlexRow
              ml={px(gapQuarter)}
              overflowY={"visible"}
              overflowX={"visible"}
            >
              <RawIconStrip
                iconStrip={["delete"]}
                onClick={(icon) =>
                {
                  if(icon === "delete")
                  {
                    image?.value.mediaIdImage && MediaStore.removeMedia(image.value.mediaIdImage);
                    onChange(null);
                  }
                }}
                iconStripDisable={(!image || readOnly || disabled) ? ["delete"] : []}
              />
            </LayoutFlexRow>
          }
          <FieldRawRefButton
            defn={defnImage}
          />
        </LayoutFlexRow>
        {
          showPreview &&
          <Box
            width={"100%"}
            pt={px(gapHalf)}
          >
            <ImagePreview
              image={image}
              isEditable={isEditable}
              formCtx={formCtx}
              defnImage={defnImage}
              selectBoxStyle={selectBoxStyle}
              loading={loading}
              onChangeLoading={onChangeLoading}
              onChange={onChange}
            />
          </Box>
        }
      </LayoutFlexCol>

      {
        (showImageData && image) &&
        <ShowImageSize
          image={image}
        />
      }
    </>
  );
}

function ShowImageSize(props: {
  image: FieldValueImage,
})
{
  const image = props.image;

  return (
    <FieldLabel
      defn={{
        label: `Size: ${KbToMb(image.value.size)} MB`,
        textSizeVar: "caption"
      } as DefnFieldLabel}
    />
  );
}

function ImagePreview(props: {
  isEditable: boolean,
  formCtx: IFormCtx,
  defnImage: DefnFieldImage,
  selectBoxStyle: SxProps
  image?: FieldValueImage,
  loading?: boolean,
  onChangeLoading: (loading: boolean) => void,
  onChange: (value?: FieldValueImage | null) => void,
  borderRadius?: Property.BorderRadius
})
{
  const image = props.image;
  const formCtx = props.formCtx;
  const defnImage = props.defnImage;
  const selectBoxStyle = props.selectBoxStyle;
  const pageCtx = usePageCtx();
  const onChangeLoading = props.onChangeLoading;
  const borderRadius = props.borderRadius;
  const fileSelectorCtx = useFileSelectorCtx();
  const onChange = useCallback((value?: FieldValueImage | null) =>
  {
    props.onChange(value);
    image?.value.mediaIdImage && MediaStore.removeMedia(image?.value.mediaIdImage);
    image?.value.mediaIdBlurImage && MediaStore.removeMedia(image?.value.mediaIdBlurImage);
  }, [image?.value.mediaIdBlurImage, image?.value.mediaIdImage, props.onChange]);

  const tempImageUrl = image?.value.mediaIdImage
    ? MediaStore.getMedia(image.value.mediaIdImage)
    : undefined;

  const imgUrl = tempImageUrl || getMediaSrc(image?.value.mediaIdImage);
  const blurImgUrl = image?.value.mediaIdBlurImage ? getMediaSrc(image?.value.mediaIdBlurImage) : undefined;

  return (
    <Box
      sx={{
        width: fieldImagePreviewWidth,
        height: fieldImagePreviewHeight,
        aspectRatio: "1/1",
        alignSelf: "start"
      }}
    >
      {
        props.loading
          ? <Box
            width={"100%"}
            height={"100%"}
            sx={selectBoxStyle}
          >
            <CircularProgress />
          </Box>
          : image
            ?
            <Box
              width={"100%"}
              height={"100%"}
              sx={selectBoxStyle}
              position={"relative"}
            >
              <RawLazyImage
                src={imgUrl}
                srcBlur={blurImgUrl}
                primaryColor={image.value.primaryColor}
                width={"100%"}
                height={"100%"}
                objectFit={"cover"}
                borderRadius={borderRadius ? borderRadius : px(gapHalf)}
                onClick={() => onClickCapture(
                  formCtx,
                  defnImage,
                  onChangeLoading,
                  onChange,
                  pageCtx,
                  fileSelectorCtx
                )}
              />
            </Box>
            :
            <ImagePlaceHolder
              selectBoxStyle={selectBoxStyle}
              onClick={() => onClickCapture(
                formCtx,
                defnImage,
                onChangeLoading,
                onChange,
                pageCtx,
                fileSelectorCtx
              )}
            />
      }
    </Box>
  );
}

const ProcessImage = (
  pageCtx: ICtxPage,
  files: File[],
  onChangeLoading: (loading: boolean) => void,
  formCtx: IFormCtx,
  defnImage: DefnFieldImage,
  onChange: (value?: FieldValueImage | null) => void
) =>
{
  const metaIdField = defnImage.metaId;
  const maxFileUploadAlert = `number of uploaded should be 1`;
  onChangeLoading(true);

  if(files.length === 1)
  {
    compressImage(
      files[0],
      FORM_IMAGE_MAX_MB,
      FORM_IMAGE_SIZE_MAX,
      (compressedFile) =>
      {
        const error = compressedFile.error;
        if(error)
        {
          const errorMsg: IDialogMsg = {
            text: error,
            color: "error"
          };
          Srvc.app.toast.showErrorToast("Media Error: " + errorMsg);
        }
        if(compressedFile.file)
        {
          const mediaIdImageId = nextMediaIdImage(getFileExt(compressedFile.file));
          getPrimaryColorAndBlurredImg(compressedFile.file, (primaryColor, blurredImage) =>
          {
            if(primaryColor && blurredImage)
            {
              const mediaIdBlurImageId = nextMediaIdImage(getFileExt(blurredImage));
              if(mediaIdBlurImageId && primaryColor)
              {
                if(compressedFile.file)
                {
                  MediaStore.setBlobToUrl(mediaIdImageId, compressedFile.file);
                }

                if(blurredImage)
                {
                  MediaStore.setBlobToUrl(mediaIdBlurImageId, blurredImage);
                }

                onChange({
                  value: {
                    fileName: compressedFile.file?.name ?? "",
                    height: compressedFile.size?.height ?? 0,
                    width: compressedFile.size?.width ?? 0,
                    size: files[0]?.size ?? 0,
                    mediaIdImage: mediaIdImageId,
                    mediaIdBlurImage: mediaIdBlurImageId,
                    primaryColor: primaryColor
                  }
                } as FieldValueImage);
                onChangeLoading(false);
              }
            }
          });
        }
      }
    );
  }
  else
  {
    pageCtx.showErrorToast(maxFileUploadAlert);
  }
};

const onClickCapture = (
  formCtx: IFormCtx,
  defnImage: DefnFieldImage,
  onChangeLoading: (loading: boolean) => void,
  onChange: (value?: FieldValueImage | null) => void,
  pageCtx: ICtxPage,
  fileSelectorCtx: ICtxFileSelector
) =>
{

  if(!formCtx.isFieldReadonly(defnImage))
  {
    fileSelectorCtx.showFileDialog({
      accept: {
        "image/jpeg": [
          ".jpeg",
          ".jpg"
        ],
        "image/png": [".png"]
      },
      onFileSelect: (files) =>
      {
        ProcessImage(pageCtx, files, onChangeLoading, formCtx, defnImage, onChange);
      }
    });
  }
};

function ImagePlaceHolder(props: {
  selectBoxStyle: SxProps,
  onClick: () => void
})
{
  const selectBoxStyle = props.selectBoxStyle;
  const onClick = props.onClick;
  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={selectBoxStyle}
    >
      <RawLazyImage
        src={process.env.PUBLIC_URL + "/placeholderImageMedia.png"}
        width={"100%"}
        height={"100%"}
        objectFit={"cover"}
        borderRadius={px(gapHalf)}
        onClick={onClick}
      />
    </Box>
  );
}
