import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {isEmpty} from "lodash";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {TypeEnumCaptionButton} from "../../list/List";
import {TypeListCounterPositionVariant} from "../../list/List";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import {LinePrimary} from "../line/LinePrimary";
import {LineSecondary} from "../line/LineSecondary";

export function BoxPSL(props: {
  primary?: ILinePrimary,
  secondaryList?: ILineSecondary[],
  searchWords?: string[],
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickIconButtonCaption?: (e: React.MouseEvent<HTMLButtonElement>, type: TypeEnumCaptionButton) => void,
  onChangePrimaryCounter?: (e: Element, countValue: number, variant: TypeListCounterPositionVariant) => void,
  onChangeSecondaryCounter?: (
    e: Element,
    countValue: number,
    variant: TypeListCounterPositionVariant,
    index: number) => void,
  onClick?: () => void,
  bgcolor?: CssBackgroundColor;
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  pt?: number,
  pb?: number,
  pl?: number,
  pr?: number,
  flexGrow?: StandardCSSProperties["flexGrow"],
})
{
  const theme = useTheme();
  const innerGap = theme.common.vertInner / 2;
  const onChangeSecondaryCounter = props.onChangeSecondaryCounter;

  return (
    <LayoutFlexCol
      mt={px(props.mt)}
      mb={px(props.mb)}
      ml={px(props.ml)}
      mr={px(props.mr)}
      pt={px(props.pt)}
      pb={px(props.pb)}
      pl={px(props.pl)}
      pr={px(props.pr)}
      bgcolor={props.bgcolor}
      flexGrow={props.flexGrow}
      flexShrink={1}
      onClick={props.onClick}
      cursorPointer={Boolean(props.onClick)}
    >

      <LinePrimary
        primary={props.primary}
        searchWords={props.searchWords}
        mb={innerGap}
        onClickCaption={props.onClickCaption}
        onClickIconButtonCaption={props.onClickIconButtonCaption}
        onChangeCounterValue={props.onChangePrimaryCounter}
      />

      {
        props.secondaryList?.map((secondary, index) =>
          <LineSecondary
            key={index}
            secondary={!isEmpty(secondary) ? secondary : undefined}
            searchWords={props.searchWords}
            onClickCaption={props.onClickCaption}
            onChangeCounterValue={onChangeSecondaryCounter
              ? (e, countValue, variant) => onChangeSecondaryCounter(e, countValue, variant, index)
              : undefined}
            mt={innerGap}
          />)
      }

    </LayoutFlexCol>
  );
}
