import {combineReducers} from "@reduxjs/toolkit";
import {createChatSlice} from "../../../base/plus/ChatPlus";
import {createListSlice} from "../../../base/plus/ListPlus";

export function reducersMain()
{
  return combineReducers({
    userList: createListSlice("homeChatUserList", {defaultListItemType: "aps"}).reducer,
    entGroupList: createListSlice("homeChatGroupList", {defaultListItemType: "aps"}).reducer,
    groupMemberMentionList: createListSlice("mainGroupMemberMentionList", {defaultListItemType: "aps"}).reducer,
    chat: createChatSlice("homeChat").reducer
  });
}
