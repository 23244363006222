import {Box} from "@mui/material";
import {Property} from "csstype";
import {useMemo, useRef} from "react";
import YouTube, {YouTubeEvent, YouTubePlayer} from "react-youtube"; // Import types properly
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../../base/plus/ConstantsPlus";
import {px} from "../../../../../base/plus/StringPlus";
import theme from "../../../../../base/plus/ThemePlus";
import {getYoutubeVideoId, validYouTubeUrl} from "../../../../../base/plus/UrlPlus";
import LayoutFlexCol from "../../../layout/LayoutFlexCol";

export default function BubbleRawMediaHyperlink(props: {
  value?: string;
  width?: Property.Width;
  height?: Property.Height;
})
{
  const hyperlinkValue = props.value;
  const {
    height,
    width
  } = props;
  const isYoutubeId = useMemo(() => Boolean(hyperlinkValue && validYouTubeUrl(hyperlinkValue)), [hyperlinkValue]);
  const ytPlayerRef = useRef<YouTubePlayer | null>(null); // Initialize with null

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      rel: 0 // Disable related videos
    }
  };

  const emptyBoxClick = () =>
  {
    if(ytPlayerRef.current)
    {
      const playerState = ytPlayerRef.current.getPlayerState();
      if(playerState === 1)
      {
        ytPlayerRef.current.pauseVideo();
      }
      else
      {
        ytPlayerRef.current.playVideo();
      }
    }
  };

  return (
    <LayoutFlexCol height={height}
      width={width}
      bgcolor={theme.palette.grey[200]}
      borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}>
      <Box position="relative" height={height} width={width} justifyContent="center" alignItems="center" display="flex">
        {isYoutubeId && hyperlinkValue && (
          <YouTube
            onReady={(e: YouTubeEvent) =>
            {
              ytPlayerRef.current = e.target;
            }}
            videoId={getYoutubeVideoId(hyperlinkValue)}
            opts={opts}
            style={{
              height: "100%",
              width: "100%",
              zIndex: 0
            }}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            height: "70%",
            width: "100%",
            zIndex: 1,
            opacity: 0,
            backgroundColor: "pink"
          }}
          onClick={emptyBoxClick}
        ></Box>
      </Box>
    </LayoutFlexCol>
  );
}
