import {isEmpty} from "lodash";
import {useEffect} from "react";
import {useMemo} from "react";
import React from "react";
import {DefnLayoutCalendar} from "../../../../api/meta/base/dto/DefnLayoutCalendar";
import {DefnLayoutGrid} from "../../../../api/meta/base/dto/DefnLayoutGrid";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {FormValueRaw} from "../../../../api/meta/base/dto/FormValueRaw";
import {dispatchCalendar} from "../../../../base/plus/CalendarPlus";
import {getCalendarBubbleDefnForm} from "../../../../base/plus/CalendarPlus";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {random} from "../../../../base/plus/StringPlus";
import {getEmptyKeysAndMap} from "../../../../base/plus/StudioPlus";
import {calendarRefresh} from "../../../../base/slices/calendar/SliceCalendar";
import {ICalendarData} from "../../../../base/types/TypeCalendar";
import {TypeCalendarItemId} from "../../../../base/types/TypeCalendar";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {useAppSelector} from "../../../app/AppHooks";
import helperTextData from "../../../atom/assets/PlaceholderTextHome.json";
import helperTextJsonData from "../../../atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import Calendar from "../../../calendar/Calendar";
import {useFormCtx} from "../base/CtxForm";
import {IFieldGridRawProps} from "../composite/FieldGrid";

export default function FieldRawFieldGridCalendar(props: IFieldGridRawProps)
{
  const layout = props.layout;
  const defnForm = props.defnForm;
  const defnGrid = props.defnGrid;

  const formCtx = useFormCtx();
  const fieldId = getFieldKey(defnGrid);

  const fieldValue = useMemo(() => props.fieldValue
      ? props.fieldValue
      : getEmptyKeysAndMap() as FieldValueGrid
    , [props.fieldValue]);

  const selectCalendar = formCtx.getSelectCalendar(fieldId);
  const calendarName = useAppSelector(state => selectCalendar
    ? selectCalendar(state).calendarName
    : undefined);

  useEffect(() =>
  {
    if(calendarName)
    {
      doLoadCalendar(calendarName, fieldValue, defnForm, layout);
    }
  }, [calendarName, fieldValue, defnForm, layout]);

  if(isEmpty(fieldValue))
  {
    return <RawNothingHere helperTextData={helperTextData.nothingToShow} />;
  }

  return selectCalendar
    ? <RealCalendar
      selectCalendar={selectCalendar}
      layout={layout}
    />
    : <RawNothingHere helperTextData={helperTextJsonData.loadingData} />;
}

function RealCalendar(props: {
  selectCalendar: SelectCalendar
  layout?: DefnLayoutCalendar;
})
{
  const selectCalendar = props.selectCalendar;
  const layout = props.layout;

  return (
    <Calendar
      selectCalendar={selectCalendar}
      ignoreItemOverview={false}
      layout={layout}
    />
  );
}

function doLoadCalendar(
  calendarName: string,
  fieldValueGrid: FieldValueGrid,
  defnForm: DefnFormUi,
  layoutGrid?: DefnLayoutGrid
)
{
  const calendarLayout = layoutGrid?.kind === "calendar"
    ? layoutGrid as DefnLayoutCalendar
    : undefined;

  const showFieldIdSet = calendarLayout?.showFieldIdSet;

  //calendar Data
  const itemsById = {} as Record<TypeCalendarItemId, FormValueRaw>;
  const calendarDefnForm = getCalendarBubbleDefnForm(defnForm, showFieldIdSet);

  Object.entries(fieldValueGrid.map).forEach(([rowId, fieldDtoGridRow]) =>
  {
    const typeCalendarItemId = rowId as TypeCalendarItemId;
    const formValueRaw = fieldDtoGridRow as FormValueRaw;
    itemsById[typeCalendarItemId] = formValueRaw;
  });

  const calendarData: ICalendarData = {
    itemsById: itemsById,
    defnForm: calendarDefnForm,
    version: random()
  };

  dispatchCalendar(calendarName, calendarRefresh(calendarData));
}



