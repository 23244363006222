import {nextMetaIdPlugin} from "../../../../../api/meta/base/ApiPlus";
import {StudioEntDeployPlugin} from "../../../../../api/meta/base/dto/StudioEntDeployPlugin";
import {EntId} from "../../../../../api/meta/base/Types";
import ISrvc from "../../../../../base/ISrvc";
import {dispatchEnt} from "../../../SrvcCacheStudio";
import {addEntPluginUserHandel} from "../../SliceCacheStudioEnt";
import {addEntDeployPluginConfig} from "../../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntDeployPlugins extends ISrvc
{
  addEntPluginUserHandel(entId: EntId, pluginUserHandle: string)
  {
    dispatchEnt(entId, addEntPluginUserHandel({
      entId: entId,
      pluginUserHandle: pluginUserHandle
    }));
  }

  addEntDeployPluginConfig(entId: EntId, studioEntPlugin: StudioEntDeployPlugin)
  {
    dispatchEnt(entId, addEntDeployPluginConfig({
      entId: entId,
      sig: {
        ...studioEntPlugin,
        metaId: studioEntPlugin.metaId || nextMetaIdPlugin()
      }
    }));
  }
}
