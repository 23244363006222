import LocalSessionStorage from "./LocalSessionStorage";

const KEY_VERIFY_HANDLE = "verify";

export interface IVerifyHandle
{
  verifyKey: string;
  creationTime: number;
  expiryMins: number;
  handle: string;
  action: string;
}

export default class LocalVerifyHandle
{
  private static getStorage(): Storage
  {
    return LocalSessionStorage;
  }

  static set(verifyKey: string, handle: string, action: string, expiryMins: number)
  {
    const verifyHandle = {
      verifyKey: verifyKey,
      creationTime: new Date().getTime(),
      expiryMins: expiryMins,
      handle: handle,
      action: action
    } as IVerifyHandle;
    this.getStorage().setItem(KEY_VERIFY_HANDLE, JSON.stringify(verifyHandle));
  }

  static get(): IVerifyHandle | undefined
  {
    const item = this.getStorage().getItem(KEY_VERIFY_HANDLE);
    if(item === null)
    {
      return undefined;
    }

    const verifyHandle = JSON.parse(item) as IVerifyHandle;
    const deltaMin = (new Date().getTime() - verifyHandle.creationTime) / 1000 / 60;
    if(deltaMin > verifyHandle.expiryMins)
    {
      this.remove();
      return undefined;
    }
    else
    {
      return verifyHandle;
    }
  }

  static remove()
  {
    this.getStorage().removeItem(KEY_VERIFY_HANDLE);
  }
}
