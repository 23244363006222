import {FormControl} from "@mui/material";
import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldSetOfRole} from "../../../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldSetOfRole} from "../../../../api/meta/base/dto/FieldSetOfRole";
import {StudioEntRoleMap} from "../../../../api/meta/base/dto/StudioEntRoleMap";
import {MetaIdRole} from "../../../../api/meta/base/Types";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {insertSystemRolesStudio} from "../../../../base/plus/StudioPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import RawPickManyAutoComplete from "../../../atom/raw/RawPickManyAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldPickSetOfRole(props: {
  defn: DefnFieldSetOfRole
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldPlaceHolder,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);

  const label = getCompLabel(defn);

  const helperText = getFieldHelperText();
  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
      {
        const fieldValue = fieldProps.field.value as FieldSetOfRole | null;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <RealFieldPickUser
              key={fieldProps.field.name}
              defn={defn}
              fieldProps={fieldProps}
              fieldValue={fieldValue}
              label={label}
              helperText={helperText}
              placeHolder={placeHolder}
              required={required}
              icon={icon}
            />
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealFieldPickUser(props: {
  defn: DefnFieldSetOfRole,
  fieldValue?: FieldSetOfRole | null,
  fieldProps: IControllerFieldProps,
  label?: string,
  helperText?: string,
  placeHolder?: string,
  required?: boolean,
  icon?: string,
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const label = props.label;
  const required = props.required;
  const placeHolder = props.placeHolder;
  const helperText = props.helperText;
  const icon = props.icon;
  const field = props.fieldProps.field;
  const error = props.fieldProps.fieldState.error;

  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const searchText = formCtx.getSearchText();
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const getOnClick = formCtx.getOnClick();
  const allowSystemRole = defn?.allowSystemRoles ?? false;
  const excludeRoleIdSet = defn?.excludeRoleIdSet;
  const filterRoleIdSet = defn.filterRoleIdSet;
  const includeOptionMap = defn.includeOptionMap;
  const callerRoleMap = defn.callerRoleMap;

  const formStore = formCtx.getStore();
  const roleMap = formStore?.roleMap;
  const isError = Boolean(error);

  const dtoOptions = useMemo(() => getOption(roleMap,
    excludeRoleIdSet,
    includeOptionMap,
    allowSystemRole,
    filterRoleIdSet,
    callerRoleMap
  ), [
    excludeRoleIdSet,
    includeOptionMap,
    roleMap,
    allowSystemRole,
    filterRoleIdSet,
    callerRoleMap
  ]);

  const [options] = useInsertTrashOptions({
    type: "roleMap",
    fieldValue: fieldValue?.valueSet,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  const optionMap = optionsToMapOfOption(options);

  const onChange = (values?: MetaIdOption[] | null) =>
  {
    if(values === null || values === undefined || values.length === 0)
    {
      field.onChange(null);
    }
    else
    {
      const displaySet = getValues(values, optionMap);

      field.onChange({
        displaySet: displaySet,
        valueSet: values
      } as FieldSetOfRole);
    }
  };

  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  return (
    <FormControl
      fullWidth
      variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
      error={isError}
    >
      <RawPickManyAutoComplete
        ref={field.ref}
        optionMap={optionMap}
        value={fieldValue?.valueSet ?? []}
        onChange={onChange}
        label={label}
        disabled={disabled}
        readOnly={readOnly}
        error={error}
        helperText={helperText}
        placeHolder={placeHolder}
        required={required}
        icon={icon}
        onClick={onClick}
        searchWords={searchText ? [searchText] : undefined}
        fieldVariant={fieldVariant}
        fieldSize={defnTheme.fieldSize}
        hideLabel={defn.hideLabel}
        name={field.name}
        fieldId={fieldId}
      />
    </FormControl>
  );
}

function getOption(
  _roleMap?: StudioEntRoleMap,
  excludeRoleIdSet?: MetaIdRole[],
  includeOptionMap?: DefnStudioMapOfDtoOption,
  allowSystemRole?: boolean,
  filterRoleIdSet?: MetaIdRole[],
  callerRoleMap?: DefnStudioMapOfDtoOption
): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];

  if(callerRoleMap)
  {
    callerRoleMap.keys.forEach((fieldId) =>
    {
      const option = callerRoleMap.map[fieldId];
      options.push(option);
    });
  }
  else
  {
    if(_roleMap)
    {
      const roleMap = allowSystemRole
        ? insertSystemRolesStudio(_roleMap)
        : _roleMap;

      roleMap.keys.forEach(roleId =>
      {
        const role = roleMap.map[roleId];

        if(filterRoleIdSet && !filterRoleIdSet.includes(role.metaId))
        {
          return;
        }

        if(excludeRoleIdSet && excludeRoleIdSet.includes(role.metaId))
        {
          return;
        }

        options.push({
          metaId: role.metaId,
          value: role.details.name || role.metaId
        });
      });
    }

    if(includeOptionMap)
    {
      includeOptionMap.keys.forEach((fieldId) =>
      {
        const option = includeOptionMap.map[fieldId];
        options.push(option);
      });
    }
  }

  return options;
}

function getValues(keys: MetaIdOption[], optionMap?: DefnStudioMapOfDtoOption)
{
  return optionMap && keys.map(value => optionMap.map[value].value);
}
