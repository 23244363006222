import {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdLayoutGrid} from "../../api/meta/base/ApiPlus";
import {nextMetaIdSwimLane} from "../../api/meta/base/ApiPlus";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutGridId} from "../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutKanban} from "../../api/meta/base/dto/StudioDtoLayoutKanban";
import {StudioFieldPickText} from "../../api/meta/base/dto/StudioFieldPickText";
import {StudioMapOfSwimlane} from "../../api/meta/base/dto/StudioMapOfSwimlane";
import {StudioVarSetOfText} from "../../api/meta/base/dto/StudioVarSetOfText";
import {Color} from "../../api/meta/base/StudioSetsFieldType";
import {ToolTip} from "../../api/meta/base/StudioSetsFieldType";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {px} from "../../base/plus/StringPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {getEmptyKeysAndMap} from "../../base/plus/StudioPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldGap2} from "../form/builder/base/TypesFormBuilder";
import {fieldTabDetails} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyLabel} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription, fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import {getSwimLaneMapDtoToValue} from "./base/DialogPlus";
import {getSwimLaneMapValueToDto} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {getLayoutTabKanban} from "./base/LayoutBuilderPlus";
import {getCommonDetailsTab} from "./base/LayoutBuilderPlus";
import {fieldLayoutSortByFields} from "./base/TypesLayoutBuilder";
import {fieldTabKanban} from "./base/TypesLayoutBuilder";
import {fieldFontSize} from "./base/TypesLayoutBuilder";
import {allowToSwitchLayoutsLabel, fieldKanbanField, fieldShowFields, fieldShowFooter} from "./base/TypesLayoutBuilder";
import {fieldLayoutDetailBgColorField} from "./base/TypesLayoutBuilder";
import {fieldLayoutDetailTooltipField} from "./base/TypesLayoutBuilder";

const dialogContentWidth = 1200;
const dialogContentHeight = 716;

const tabVariant = "standard";

export default function DialogNewLayoutGridKanban(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  metaIdForm?: MetaIdForm,
  metaIdGrid: MetaIdGrid,
  validationError?: IFormFieldError[]
  values?: StudioDtoLayoutKanban,
  onClickOk: (values: StudioDtoLayoutKanban) => void,
  onClose?: () => void
})
{
  const metaIdGrid = props.metaIdGrid;
  const values = props.values;
  const formStore = props.formStore;
  const metaIdForm = props.metaIdForm;
  const formReadonly = props.isFormReadOnly;
  const onClickOk = props.onClickOk;
  const onClose = props.onClose;
  const validationError = props.validationError;

  const excludeLayoutGridIdSet = values?.metaId
    ? [values?.metaId]
    : undefined;
  const form = (metaIdForm && formStore)
    ? formStore.formMap?.map[metaIdForm]
    : undefined;

  const [selectedKanbanFieldId, setSelectedKanbanFieldId] = useState<MetaIdField | undefined>();
  const [textSetVarId, setTextSetVarId] = useState<MetaIdVar>();
  const [swimLane, setSwimLane] = useState<StudioMapOfSwimlane>(getEmptyKeysAndMap());

  const cbRef = useRef({} as IFormRef);

  const onWatch = (key: string, value: any) =>
  {
    if(key === fieldKanbanField)
    {
      let fieldId;
      if(form)
      {
        loopStudioForm(form, (_, field) =>
        {
          if(field.metaId === value)
          {
            fieldId = field.metaId;
          }
        });
      }

      setSelectedKanbanFieldId(fieldId);
    }
  };

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  useEffect(() =>
  {
    if(values)
    {
      setSelectedKanbanFieldId(values.kanbanFieldId);
    }
  }, []);

  useEffect(() =>
  {
    if(textSetVarId)
    {
      let _swimlane = {
        keys: [],
        map: {}
      } as StudioMapOfSwimlane;
      
      const variable = formStore?.varMap?.map[textSetVarId];
      const defaultSwimlaneMap = values?.swimlaneMap;

      if(variable && variable.kind === "setOfText")
      {
        const _variable = variable as StudioVarSetOfText;
        const variableValueMap = _variable.value?.map;
        let unassignedSwimlaneId = nextMetaIdSwimLane();

        if(variableValueMap)
        {
          Object.values(variableValueMap).forEach(value =>
          {
            let swimlaneId = nextMetaIdSwimLane();

            if(defaultSwimlaneMap?.map)
            {
              Object.values(defaultSwimlaneMap?.map).forEach(swimlane =>
              {
                if(swimlane.valueOptionId === value.metaId)
                {
                  swimlaneId = swimlane.metaId;
                }
                if(!swimlane.valueOptionId)
                {
                  unassignedSwimlaneId = swimlane.metaId;
                }
              });
            }

            _swimlane.keys?.push(swimlaneId);
            _swimlane.map[swimlaneId] = {
              metaId: swimlaneId,
              label: value.value,
              valueOptionId: value.metaId
            };
          });

          _swimlane.keys?.push(unassignedSwimlaneId);
          _swimlane.map[unassignedSwimlaneId] = {
            metaId: unassignedSwimlaneId,
            label: "Unassigned"
          };
        }
      }
      setSwimLane(_swimlane);
    }
    else
    {
      setSwimLane(getEmptyKeysAndMap());
    }
  }, [formStore?.varMap?.map, textSetVarId, values?.swimlaneMap]);

  useEffect(() =>
  {
    let _textSetVarId;
    if(form && selectedKanbanFieldId)
    {
      loopStudioForm(form, (_, field) =>
      {
        if(field.metaId === selectedKanbanFieldId)
        {
          const fieldPickText = field as StudioFieldPickText;
          _textSetVarId = fieldPickText.sourceVarId;
        }
      });
    }
    setTextSetVarId(_textSetVarId);
  }, [form, selectedKanbanFieldId]);

  return (
    <DialogDefnForm
      formProps={{
        cbRef: cbRef.current,
        store: formStore,
        defnForm: getDefnForm(metaIdForm,
          metaIdGrid,
          excludeLayoutGridIdSet,
          swimLane
        ),
        formReadonly: formReadonly,
        onWatch: onWatch,
        initValues: dtoToValue(values),
        onSubmit: value => onClickOk(valueToDto(value, swimLane))
      }}
      title={`${values ? "Update" : "New"} grid layout - Kanban`}
      addMoreCheckBoxLabel={!values
        ? "Add more grid layouts"
        : undefined}
      onClose={onClose}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
    />
  );
}

// detail tab
const fieldAllowToSwitchLayoutIdSet = "allowToSwitchLayoutIdSet";

function getDefnForm(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdComposite,
  excludeLayoutGridIdSet?: MetaIdLayoutGrid[],
  swimlane?: StudioMapOfSwimlane
)
{
  const compositeIdSet = [metaIdGrid] as MetaIdComposite[];

  return createDefaultDefnFormStudio({

    ...getGridLayoutTabDetails(metaIdForm, metaIdGrid, excludeLayoutGridIdSet),
    ...getLayoutTabKanban(metaIdForm, compositeIdSet, swimlane, undefined, undefined, false),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: tabVariant,
      tabIdSet: [
        fieldTabDetails,
        fieldTabKanban
      ]
    } as DefnTab
  });
}

function getGridLayoutTabDetails(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeLayoutGridIdSet?: MetaIdLayoutGrid[],
  excludeFieldIdSet?: MetaIdField[])
{
  return {
    ...getCommonDetailsTab(),
    [fieldLayoutDetailTooltipField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailTooltipField,
      name: fieldLayoutDetailTooltipField,
      label: "Tooltip field",
      filterFieldTypeSet: ToolTip,
      formId: metaIdForm,
      excludeFieldIdSet: excludeFieldIdSet,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,
    [fieldLayoutDetailBgColorField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailBgColorField,
      name: fieldLayoutDetailBgColorField,
      label: "Background color field",
      filterFieldTypeSet: Color,
      formId: metaIdForm,
      excludeFieldIdSet: excludeFieldIdSet,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,
    ...getFieldGap(fieldGap2, "thick"),
    [fieldAllowToSwitchLayoutIdSet]: {
      type: "studioSetOfLayoutGridId",
      metaId: fieldAllowToSwitchLayoutIdSet,
      name: fieldAllowToSwitchLayoutIdSet,
      label: allowToSwitchLayoutsLabel,
      excludeLayoutGridIdSet: excludeLayoutGridIdSet,
      formId: metaIdForm,
      pb: px(gapStd)
    } as DefnStudioPickLayoutGridId,

    [fieldTabDetails]: {
      type: "section",
      label: "Details",
      metaId: fieldTabDetails,
      fieldIdSet: [
        fieldKeyName,
        fieldKeyLabel,
        fieldKeyDescription,
        fieldLayoutDetailTooltipField,
        fieldLayoutDetailBgColorField,
        fieldAllowToSwitchLayoutIdSet
      ]
    } as DefnSection

  } as Record<MetaIdField, DefnField>;
}

function dtoToValue(kanban?: StudioDtoLayoutKanban)
{
  if(!kanban)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "Kanban")
    };
  }

  const swimlaneMap = getSwimLaneMapDtoToValue(kanban?.swimlaneMap);

  return {
    //tab details
    [fieldKeyName]: fnRawValueToFieldValue("symbol", kanban.name ?? "Kanban"),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", kanban.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", kanban.description),
    [fieldLayoutDetailBgColorField]: fnRawValueToFieldValue("pickFieldId", kanban.bgColorFieldId),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", kanban.toolTipFieldId),
    [fieldAllowToSwitchLayoutIdSet]: fnRawValueToFieldValue("studioSetOfLayoutGridId", kanban.allowToSwitchLayoutIdSet),

    //tab kanban
    [fieldShowFields]: fnRawValueToFieldValue("studioSetOfFieldId", kanban?.showFieldIdSet),
    [fieldKanbanField]: fnRawValueToFieldValue("pickFieldId", kanban?.kanbanFieldId),
    [fieldShowFooter]: fnRawValueToFieldValue("bool", kanban?.showFooter),
    [fieldLayoutSortByFields]: fnRawValueToFieldValue("studioSetOfFieldId", kanban?.sortByFieldIdSet),
    [fieldFontSize]: fnRawValueToFieldValue("textSize", kanban?.textSize),
    ...swimlaneMap

  };
}

function valueToDto(
  values: FieldValues,
  swimlane?: StudioMapOfSwimlane): StudioDtoLayoutKanban
{

  const swimlaneMap = getSwimLaneMapValueToDto(values, swimlane);

  return {
    metaId: nextMetaIdLayoutGrid(),
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    label: fnFieldValueToRawValue("text", values[fieldKeyLabel]),
    description: fnFieldValueToRawValue("text", values[fieldKeyDescription]),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLayoutDetailTooltipField]),
    bgColorFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLayoutDetailBgColorField]),
    kind: "kanban",
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioSetOfLayoutGridId", values[fieldAllowToSwitchLayoutIdSet]),
    kanbanFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldKanbanField]),
    showFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldShowFields]),
    swimlaneMap: swimlaneMap,
    showFooter: fnFieldValueToRawValue("bool", values[fieldShowFooter]),
    sortByFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldLayoutSortByFields]),
    fontSize: fnFieldValueToRawValue("textSize", values[fieldFontSize])
  } as StudioDtoLayoutKanban;
}
