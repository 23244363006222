import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {FieldChipSetDay} from "../../../api/meta/base/dto/FieldChipSetDay";
import {StudioVarSetOfDay} from "../../../api/meta/base/dto/StudioVarSetOfDay";

export function fnVariableBuilderSetOfDay(fieldValueDaySet: string)
{
  function getVarComp()
  {
    return {
      [fieldValueDaySet]: {
        type: "chipSetDay",
        metaId: fieldValueDaySet,
        label: "Value",
        required: true
      } as DefnFieldEditable
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarSetOfDay
  )
  {
    const varValue = variable.value;
    if(varValue)
    {
      valueMap[fieldValueDaySet] = varValue;
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueDaySet] as FieldChipSetDay;

    return {
      value: value
    } as StudioVarSetOfDay;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
