import {FieldValues} from "react-hook-form";
import {nextMetaIdCode} from "../../../../../api/meta/base/ApiPlus";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioCodeEditor} from "../../../../../api/meta/base/dto/DefnStudioCodeEditor";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldHtml} from "../../../../../api/meta/base/dto/StudioFieldHtml";
import {StudioValueCode} from "../../../../../api/meta/base/dto/StudioValueCode";
import {StudioValueCodeJavascript} from "../../../../../api/meta/base/dto/StudioValueCodeJavascript";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyShowCloseButton} from "../../base/TypesFormBuilder";

export function getDefnFieldHtml(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyDefaultFieldId,
    propKeyPlaceHolderFieldId
  ];

  const varIds = [
    propKeyDefaultValueVar,
    propKeyVarPlaceholder
  ];

  const constIds = [
    propKeyDefaultValue,
    propKeyPlaceHolderValue,
    propKeyShowCloseButton
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "html",
      formId,
      "html",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDefaultValue]: {
      type: "studioCodeEditor",
      metaId: propKeyDefaultValue,
      name: "Default value",
      language: "html"
    } as DefnStudioCodeEditor,

    [propKeyPlaceHolderValue]: {
      type: "studioCodeEditor",
      metaId: propKeyPlaceHolderValue,
      name: "placeholder",
      language: "html"
    } as DefnStudioCodeEditor,

    [propKeyDefaultValueVar]: {
      type: "pickVarId",
      metaId: propKeyDefaultValueVar,
      name: "Default value",
      varKind: "html",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyVarPlaceholder]: {
      type: "pickVarId",
      metaId: propKeyVarPlaceholder,
      name: "Placeholder",
      varKind: "html",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyShowCloseButton]: {
      type: "bool",
      name: propKeyShowCloseButton,
      metaId: propKeyShowCloseButton,
      showAsCheckboxVar: true,
      label: "Show close button",
      showAddEdit: true
    } as DefnFieldSwitch
  };
}

export function defnValueToStudioFieldHtml(values: FieldValues): StudioFieldHtml
{
  const defaultValue = (values[propKeyDefaultValue] as StudioValueCodeJavascript).value;
  const placeHolderValue = (values[propKeyPlaceHolderValue] as StudioValueCodeJavascript).value;

  return {
    type: "html",
    defaultValue: defaultValue,
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    placeHolder: placeHolderValue,
    placeHolderVarId: fnFieldValueToRawValue("pickVarId", values[propKeyVarPlaceholder]),
    placeHolderFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyPlaceHolderFieldId]),
    showCloseButton: fnFieldValueToRawValue("bool", values[propKeyShowCloseButton])
  } as StudioFieldHtml;
}

export function studioFieldHtmlToDefnValue(studioField: StudioFieldHtml)
{

  const defaultValue = {
    value: studioField.defaultValue,
    metaId: nextMetaIdCode()
  } as StudioValueCode;

  const placeHolderValue = {
    value: studioField.placeHolder,
    metaId: nextMetaIdCode()
  } as StudioValueCode;

  return {
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyVarPlaceholder]: fnRawValueToFieldValue("pickVarId", studioField.placeHolderVarId),
    [propKeyPlaceHolderFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.placeHolderFieldId),
    [propKeyShowCloseButton]: fnRawValueToFieldValue("bool", studioField.showCloseButton),
    [propKeyDefaultValue]: defaultValue,
    [propKeyPlaceHolderValue]: placeHolderValue
  };
}


