import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../../base/plus/ListPlus";

export function reducersHomeAsideGroupInfo()
{
  return combineReducers({
    memberList: createListSlice("homeAsideGroupInfoMemberList",
      {
        defaultListItemType: "footer",
        loadMoreItemLimit: 10
      }
    ).reducer,
    searchMemberList: createListSlice("homeAsideSearchMemberList",
      {defaultListItemType: "aps"}
    ).reducer,
    memberListAdd: createListSlice("homeAsideGroupInfoMemberListAdd",
      {defaultListItemType: "aps"}
    ).reducer,
    inviteContacts: createListSlice("homeAsideGroupInfoMemberListInvite",
      {defaultListItemType: "aps"}
    ).reducer
  });
}
