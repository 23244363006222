import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {useWinSize} from "../../../nucleus/app/AppHooks";
import LayoutFlexRow from "../../../nucleus/atom/layout/LayoutFlexRow";
import RawFadeLoader from "../../../nucleus/atom/raw/RawFadeLoader";

export default function UiAuthFadeLoader()
{
  const winSize = useWinSize();

  return (
    <LayoutFlexRow width="100%" height={px(winSize.height)}>
      <RawFadeLoader />
    </LayoutFlexRow>
  );
}
