import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import React from "react";
import {DefnComp} from "../../../../../../api/meta/base/dto/DefnComp";
import {DefnFieldError} from "../../../../../../api/meta/base/dto/DefnFieldError";
import {px} from "../../../../../../base/plus/StringPlus";
import BoxColor from "../../../../box/BoxColor";
import LayoutFlexCol from "../../../../layout/LayoutFlexCol";
import RawHighlighter from "../../../../raw/RawHighlighter";

export function BubbleFieldColor(props: {
  field: DefnFieldError,
  text: string,
  variant: Variant,
  searchWords?: string[],
})
{
  const field = props.field;
  const text = props.text;
  const variant = props.variant;

  return (
    <>
      <FieldColorBox
        comp={field}
        value={text}
      />
      <RawHighlighter
        variant={variant}
        value={text}
        color={"textSecondary"}
        searchWords={props.searchWords}
        isUserSelect={true}
      />
    </>
  );
}

function FieldColorBox(props: {
  value?: string
  comp: DefnComp,
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;

  if(props.comp.type === "color")
  {
    return (
      <LayoutFlexCol
        pt={px(3)}
      >
        <BoxColor
          pr={gapHalf}
          bgcolor={props.value}
          mr={gapHalf}
          size={px(14)}
        />
      </LayoutFlexCol>
    );
  }
  return null;
}
