export const timeZoneToCountryCode: {[key: string]: string} = {
  "Africa/Abidjan": "+225",
  "Africa/Accra": "+233",
  "Africa/Addis_Ababa": "+251",
  "Africa/Algiers": "+213",
  "Africa/Cairo": "+20",
  "Africa/Casablanca": "+212",
  "Africa/Dakar": "+221",
  "Africa/Douala": "+237",
  "Africa/Johannesburg": "+27",
  "Africa/Kampala": "+256",
  "Africa/Khartoum": "+249",
  "Africa/Lagos": "+234",
  "Africa/Luanda": "+244",
  "Africa/Nairobi": "+254",
  "Africa/Zambia": "+260",
  "America/Asuncion": "+595",
  "America/Belize": "+501",
  "America/Bogota": "+57",
  "America/Buenos_Aires": "+54",
  "America/Caracas": "+58",
  "America/Chicago": "+1",
  "America/Costa_Rica": "+506",
  "America/Denver": "+1",
  "America/El_Salvador": "+503",
  "America/Guatemala": "+502",
  "America/Guayaquil": "+593",
  "America/La_Paz": "+591",
  "America/Lima": "+51",
  "America/Los_Angeles": "+1",
  "America/Managua": "+505",
  "America/Mexico_City": "+52",
  "America/Montevideo": "+598",
  "America/New_York": "+1",
  "America/Nuuk": "+299",
  "America/Panama": "+507",
  "America/Puerto_Rico": "+1",
  "America/Santiago": "+56",
  "America/Santo_Domingo": "+1",
  "America/Sao_Paulo": "+55",
  "America/Tegucigalpa": "+504",
  "America/Toronto": "+1",
  "America/Vancouver": "+1",
  "Asia/Almaty": "+7",
  "Asia/Ashgabat": "+993",
  "Asia/Baghdad": "+964",
  "Asia/Baku": "+994",
  "Asia/Bangkok": "+66",
  "Asia/Bishkek": "+996",
  "Asia/Calcutta": "+91",
  "Asia/Dubai": "+971",
  "Asia/Dushanbe": "+992",
  "Asia/Ho_Chi_Minh": "+84",
  "Asia/Hong_Kong": "+852",
  "Asia/Jakarta": "+62",
  "Asia/Jerusalem": "+972",
  "Asia/Karachi": "+92",
  "Asia/Kolkata": "+91",
  "Asia/Kuala_Lumpur": "+60",
  "Asia/Manila": "+63",
  "Asia/Nicosia": "+357",
  "Asia/Riyadh": "+966",
  "Asia/Seoul": "+82",
  "Asia/Shanghai": "+86",
  "Asia/Singapore": "+65",
  "Asia/Taipei": "+886",
  "Asia/Tashkent": "+998",
  "Asia/Tbilisi": "+995",
  "Asia/Tokyo": "+81",
  "Asia/Yerevan": "+374",
  "Atlantic/Faroe": "+298",
  "Atlantic/Reykjavik": "+354",
  "Australia/Sydney": "+61",
  "Europe/Amsterdam": "+31",
  "Europe/Athens": "+30",
  "Europe/Belgrade": "+381",
  "Europe/Berlin": "+49",
  "Europe/Bratislava": "+421",
  "Europe/Brussels": "+32",
  "Europe/Bucharest": "+40",
  "Europe/Budapest": "+36",
  "Europe/Chisinau": "+373",
  "Europe/Copenhagen": "+45",
  "Europe/Dublin": "+353",
  "Europe/Gibraltar": "+350",
  "Europe/Helsinki": "+358",
  "Europe/Istanbul": "+90",
  "Europe/Kiev": "+380",
  "Europe/Lisbon": "+351",
  "Europe/Ljubljana": "+386",
  "Europe/London": "+44",
  "Europe/Luxembourg": "+352",
  "Europe/Madrid": "+34",
  "Europe/Malta": "+356",
  "Europe/Minsk": "+375",
  "Europe/Monaco": "+377",
  "Europe/Moscow": "+7",
  "Europe/Oslo": "+47",
  "Europe/Paris": "+33",
  "Europe/Prague": "+420",
  "Europe/Riga": "+371",
  "Europe/Rome": "+39",
  "Europe/Sofia": "+359",
  "Europe/Stockholm": "+46",
  "Europe/Tallinn": "+372",
  "Europe/Vatican": "+379",
  "Europe/Vienna": "+43",
  "Europe/Vilnius": "+370",
  "Europe/Warsaw": "+48",
  "Europe/Zagreb": "+385",
  "Europe/Zurich": "+41",
  "Indian/Antananarivo": "+261",
  "Indian/Mahe": "+248",
  "Indian/Maldives": "+960",
  "Indian/Mauritius": "+230",
  "Pacific/Apia": "+685",
  "Pacific/Auckland": "+64",
  "Pacific/Chuuk": "+691",
  "Pacific/Efate": "+678",
  "Pacific/Fiji": "+679",
  "Pacific/Funafuti": "+688",
  "Pacific/Guadalcanal": "+677",
  "Pacific/Majuro": "+692",
  "Pacific/Nauru": "+674",
  "Pacific/Noumea": "+687",
  "Pacific/Palau": "+680",
  "Pacific/Port_Moresby": "+675",
  "Pacific/Tarawa": "+686",
  "Pacific/Tongatapu": "+676"
};
