// noinspection DuplicatedCode

import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import _ from "lodash";
import {EntId} from "../../../../api/meta/base/Types";
import LocalFilterPickMany from "../../../../base/local/LocalFilterPickMany";
import {putItem} from "../../../../base/plus/JsPlus";
import {removeItem} from "../../../../base/plus/JsPlus";
import {myWorldItemId} from "../../../../base/types/TypesDrawer";

const localFilter = new LocalFilterPickMany((callerIdHash) => `home_filter_${callerIdHash}`);

export interface IHomeFilterState
{
  entIdSet: EntId[];
}

export const sliceCacheHomeDrawerFilter = createSlice({
  name: "cacheHomeDrawerFilter",
  initialState: {
    entIdSet: [] as EntId[]
  } as IHomeFilterState,
  reducers: {
    loadHomeFilter: (state, action: PayloadAction<ActionLoadHomeFilter>) =>
    {
      const payload = action.payload;
      const callerIdHash = payload.callerIdHash;

      let entIdArray = localFilter.get(callerIdHash);
      state.entIdSet = entIdArray.length > 0
        ? _.intersection(entIdArray, [...payload.entIdNotAdminSet, myWorldItemId])
        : [];
    },
    updateHomeFilter: (state, action: PayloadAction<ActionUpdateHomeFilter>) =>
    {
      const payload = action.payload;
      let entIdSet = state.entIdSet;
      const callerIdHash = payload.callerIdHash;
      const entId = payload.entId;
      const pickValue = payload.pickValue;
      if(pickValue)
      {
        if(!entIdSet)
        {
          entIdSet = [] as EntId[];
          state.entIdSet = entIdSet;
        }

        putItem(entIdSet, entId);
        localFilter.ensure(callerIdHash, entId);
      }
      else
      {
        if(entIdSet)
        {
          removeItem(entIdSet, entId);
          if(entIdSet.length === 0)
          {
            state.entIdSet = [];
            localFilter.clear(callerIdHash);
          }
          else
          {
            localFilter.remove(callerIdHash, entId);
          }
        }
      }
    },
    clearHomeFilter: (state, action: PayloadAction<string>) =>
    {
      const callerIdHash = action.payload;
      state.entIdSet = [];
      localFilter.clear(callerIdHash);
    }
  }
});

export const {
  loadHomeFilter,
  updateHomeFilter,
  clearHomeFilter
} = sliceCacheHomeDrawerFilter.actions;

export type ActionLoadHomeFilter = {callerIdHash: string, entIdAdminSet: EntId[], entIdNotAdminSet: EntId[]};
export type ActionUpdateHomeFilter = {callerIdHash: string, entId: EntId, pickValue: boolean};

