import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";

export function LayoutGap(props: {
  flexGrow?: StandardCSSProperties["flexGrow"],
  bgcolor?: CssBackgroundColor;
  width?: StandardCSSProperties["width"],
  height?: StandardCSSProperties["height"]
  mt?: StandardCSSProperties["marginTop"],
  mb?: StandardCSSProperties["marginBottom"],
  ml?: StandardCSSProperties["marginLeft"],
  mr?: StandardCSSProperties["marginRight"],
  pt?: StandardCSSProperties["paddingTop"],
  pb?: StandardCSSProperties["paddingBottom"],
  pl?: StandardCSSProperties["paddingLeft"],
  pr?: StandardCSSProperties["paddingRight"],
})
{
  return (
    <Box
      sx={{
        flexGrow: props.flexGrow,
        overflow: "hidden",
        width: props.width,
        height: props.height,
        bgcolor: props.bgcolor,
        mt: props.mt ?? px(0),
        mb: props.mb ?? px(0),
        ml: props.ml ?? px(0),
        mr: props.mr ?? px(0),
        pt: props.pt,
        pb: props.pb,
        pl: props.pl,
        pr: props.pr
      }}
    />
  );
}
