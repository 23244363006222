import {isEmpty} from "lodash";
import {nextMetaIdPlugin} from "../../../../api/meta/base/ApiPlus";
import {StudioEntImport} from "../../../../api/meta/base/dto/StudioEntImport";
import {EntId, MetaIdPlugin} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntPlugin, moveEntItem, removeEntPlugin} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntImports extends ISrvc
{
  addEntImport(entId: EntId, importItem: StudioEntImport)
  {
    dispatchEnt(entId, addEntPlugin({
      entId: entId,
      sig: {
        ...importItem,
        metaId: importItem.metaId || nextMetaIdPlugin()
      }
    }));
  }

  removeEntImport(entId: EntId, metaIdPlugin: MetaIdPlugin)
  {
    dispatchEnt(entId, removeEntPlugin({
      entId: entId,
      metaId: metaIdPlugin
    }));
  }

  copyEntImport(entId: EntId, metaIdPlugin: MetaIdPlugin)
  {
    const rootState = store.getState();
    const importMap = selectCacheStudioEnt(rootState, entId)?.ent.pluginMap;
    const importItem = importMap?.map[metaIdPlugin];

    if(!isEmpty(importItem))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "plugin",
        payload: importItem
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntImport(entId: EntId, metaIdPlugin: MetaIdPlugin)
  {
    const rootState = store.getState();
    const importMap = rootState.cache.studio.ent.entMap[entId]?.ent.pluginMap;
    if(!importMap)
    {
      return;
    }

    const index = importMap.keys.indexOf(metaIdPlugin);
    if(index === -1)
    {
      return;
    }

    const entImport = importMap.map[metaIdPlugin]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(importMap.map[metaIdPlugin])))
      : undefined;

    if(entImport)
    {
      dispatchEnt(entId, addEntPlugin({
        entId: entId,
        sig: entImport,
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdPlugin,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "importList",
      moveDirection
    }));
  }

}
