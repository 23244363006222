import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {StudioVarIcon} from "../../../api/meta/base/dto/StudioVarIcon";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";

export function fnVariableBuilderIcon(fieldValueIcon: string)
{
  function getVarComp()
  {
    return {
      [fieldValueIcon]: {
        type: "icon",
        metaId: fieldValueIcon,
        name: "Icon",
        label: "Value",
        required: true
      } as DefnFieldEditable
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarIcon
  )
  {
    const varValue = variable.value;
    if(varValue)
    {
      valueMap[fieldValueIcon] = fnRawValueToFieldValue("text", varValue.value);
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = fnFieldValueToRawValue("text", valueMap[fieldValueIcon]);

    return {
      value: {
        value: value
      }
    } as StudioVarIcon;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
