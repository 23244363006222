export default class LocalSessionStorage extends Storage
{
  private static PREFIX_STORAGE: string | undefined = undefined;
  private static allKeys = new Set<string>();
  readonly length: number = LocalSessionStorage.getStorage().length;

  private static getStorage(): Storage
  {
    return window.sessionStorage;
  }

  static getKey(key: string): string
  {
    if(this.PREFIX_STORAGE === undefined)
    {
      return key;
    }
    return this.PREFIX_STORAGE + "_" + key;
  }

  static setPrefix(key?: string): void
  {
    this.PREFIX_STORAGE = key;
  }

  static clear(): void
  {
    this.getStorage().clear();
  }

  static getItem(key: string): string | null
  {
    return this.getStorage().getItem(this.getKey(key));
  }

  static key(index: number): string | null
  {
    return this.getStorage().key(index);
  }

  static removeItem(key: string): void
  {
    this.getStorage().removeItem(this.getKey(key));
    this.allKeys.delete(key);
  }

  static setItem(key: string, value: string): void
  {
    this.getStorage().setItem(this.getKey(key), value);
    this.allKeys.add(key);
  }
}
