import {combineReducers} from "@reduxjs/toolkit";
import {sliceCacheStudioEnt} from "./ent/SliceCacheStudioEnt";
import {sliceCacheStudioFilter} from "./filter/SliceCacheStudioFilter";
import {sliceCacheStudioLocalState} from "./localState/SliceCacheStudioLocalState";
import {sliceCacheStudioPlugin} from "./plugin/SliceCacheStudioPlugin";

export function reducersCacheStudio()
{
  return combineReducers({
    filter: sliceCacheStudioFilter.reducer,
    ent: sliceCacheStudioEnt.reducer,
    plugin: sliceCacheStudioPlugin.reducer,
    localState: sliceCacheStudioLocalState.reducer
  });
}
