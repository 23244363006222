import React from "react";
import {useContext} from "react";
import {createContext} from "react";
import {ICtxAuth} from "./ICtxAuth";

const ctxAuth = createContext({} as ICtxAuth);

export function useAuthCtx()
{
  return useContext(ctxAuth);
}

export default function AuthCtxProvider(props: {
  authCtx: ICtxAuth,
  children: React.ReactNode
})
{
  return (
    <ctxAuth.Provider value={props.authCtx}>
      {props.children}
    </ctxAuth.Provider>
  );
}
