import {useCallback} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutGridId} from "../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutCalendar} from "../../api/meta/base/dto/StudioDtoLayoutCalendar";
import {StudioDtoLayoutGrid} from "../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioGrid} from "../../api/meta/base/dto/StudioGrid";
import {ToolTip} from "../../api/meta/base/StudioSetsFieldType";
import {EnumStudioCompType} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {px} from "../../base/plus/StringPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {fieldGap2} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import {fieldTabDetails} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyLabel} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription, fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {getCalendarTab} from "./base/LayoutBuilderPlus";
import {getCommonDetailsTab} from "./base/LayoutBuilderPlus";
import {fieldTimeToField} from "./base/TypesLayoutBuilder";
import {fieldTimeFormField} from "./base/TypesLayoutBuilder";
import {fieldDateToField} from "./base/TypesLayoutBuilder";
import {fieldDateFormField} from "./base/TypesLayoutBuilder";
import {fieldColorField} from "./base/TypesLayoutBuilder";
import {fieldShowFieldIdSet} from "./base/TypesLayoutBuilder";
import {fieldTitleField} from "./base/TypesLayoutBuilder";
import {fieldSpreadsheetLayoutSwitcherIdSet} from "./base/TypesLayoutBuilder";
import {fieldLayoutDetailTooltipField} from "./base/TypesLayoutBuilder";
import {fieldTabCalendar} from "./base/TypesLayoutBuilder";
import {allowToSwitchLayoutsLabel} from "./base/TypesLayoutBuilder";

const dialogContentWidth = 900;
const dialogContentHeight = 600;

export default function DialogNewLayoutGridCalendar(props: {
  formStore?: FormStore,
  metaIdForm: MetaIdForm,
  metaIdGrid: MetaIdGrid,
  isFormReadOnly?: boolean,
  validationError?: IFormFieldError[]
  values?: StudioDtoLayoutGrid,
  onClickOk: (values: StudioDtoLayoutGrid) => void,
  onClose?: () => void
})
{
  const values = props.values;
  const validationError = props.validationError;
  const excludeGridIdSet = values?.metaId
    ? [values?.metaId]
    : undefined;
  const formStore = props.formStore;
  const cbRef = useRef({} as IFormRef);
  const metaIdForm = props.metaIdForm;
  const metaIdGrid = props.metaIdGrid;

  const form = metaIdForm
    ? formStore?.formMap?.map[metaIdForm]
    : undefined;

  const [fieldMap, setFieldMap] = useState<Record<MetaIdField, EnumStudioCompType>>({});
  const [fromDatePickedFieldType, setFromDatePickedFieldType] = useState<EnumDefnCompType>();
  const [toDatePickedFieldType, setToDatePickedFieldType] = useState<EnumDefnCompType>();

  useEffect(() =>
  {
    if(form)
    {
      loopStudioForm(form, (composite, field) =>
      {
        if(composite.type === "grid" && (composite as StudioGrid).metaId === metaIdGrid)
        {
          setFieldMap(prevState =>
          {
            return {
              ...prevState,
              [field.metaId]: field.type
            } as Record<MetaIdField, EnumStudioCompType>;
          });
        }
      });
    }

  }, [metaIdGrid, formStore?.formMap]);

  useEffect(() =>
  {
    Object.entries(dtoToValue(props.values)).forEach(([key, value]) =>
    {
      onWatch(key, value);
    });
  }, [fieldMap]);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const onWatch = useCallback((key: string, value: any) =>
  {
    const fieldType = fieldMap[value];
    if(key === fieldDateFormField)
    {
      setFromDatePickedFieldType(fieldMap[value]);

      if(fieldType === "dateTime")
      {
        cbRef.current.setValue(fieldTimeFormField, null);
        cbRef.current.setValue(fieldTimeToField, null);
      }
      else if(fieldType === "dateRange" || fieldType === "dateTimeRange")
      {
        cbRef.current.setValue(fieldTimeFormField, null);
        cbRef.current.setValue(fieldTimeToField, null);
        cbRef.current.setValue(fieldDateToField, null);
      }
    }
    else if(key === fieldDateToField)
    {
      setToDatePickedFieldType(fieldMap[value]);

      if(fieldType === "dateTime")
      {
        cbRef.current.setValue(fieldTimeToField, null);
      }
    }
  }, [fieldMap]);

  return (
    <DialogDefnForm
      formProps={{
        store: formStore,
        cbRef: cbRef.current,
        formReadonly: props.isFormReadOnly,
        defnForm: getDefnForm(props.metaIdForm,
          props.metaIdGrid,
          excludeGridIdSet,
          Object.keys(fieldMap),
          fromDatePickedFieldType,
          toDatePickedFieldType),
        initValues: dtoToValue(values),
        onSubmit: value => props.onClickOk(valueToDto(value)),
        onWatch: onWatch
      }}
      title={`${values ? "Update" : "New"} grid layout - Calendar`}
      addMoreCheckBoxLabel={!values
        ? "Add more grid layouts"
        : undefined}
      onClose={props.onClose}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
    />
  );
}

function getDefnForm(
  metaIdForm: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeGridIdSet?: MetaIdLayoutGrid[],
  includeFieldIdSet?: string[],
  fromDatePickedFieldType?: EnumDefnCompType,
  toDatePickedFieldType?: EnumDefnCompType
)
{
  return createDefaultDefnFormStudio({

    ...getCalendarDetailsTab(metaIdForm, metaIdGrid, excludeGridIdSet),
    ...getCalendarTab(metaIdForm, metaIdGrid, includeFieldIdSet, fromDatePickedFieldType, toDatePickedFieldType),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: "standard",
      tabIdSet: [
        fieldTabDetails,
        fieldTabCalendar
      ]
    } as DefnTab

  });
}

function getCalendarDetailsTab(metaIdForm: MetaIdForm, metaIdGrid?: MetaIdGrid, excludeGridIdSet?: MetaIdLayoutGrid[])
{
  return {
    ...getCommonDetailsTab(),

    [fieldLayoutDetailTooltipField]: {
      type: "pickFieldId",
      metaId: fieldLayoutDetailTooltipField,
      name: fieldLayoutDetailTooltipField,
      label: "Tooltip field",
      filterFieldTypeSet: ToolTip,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined,
      formId: metaIdForm
    } as DefnStudioPickFieldId,

    ...getFieldGap(fieldGap2, "thick"),

    [fieldSpreadsheetLayoutSwitcherIdSet]: {
      type: "studioSetOfLayoutGridId",
      metaId: fieldSpreadsheetLayoutSwitcherIdSet,
      name: fieldSpreadsheetLayoutSwitcherIdSet,
      label: allowToSwitchLayoutsLabel,
      excludeLayoutGridIdSet: excludeGridIdSet,
      formId: metaIdForm,
      pb: px(gapStd)
    } as DefnStudioPickLayoutGridId,

    [fieldTabDetails]: {
      type: "section",
      name: fieldTabDetails,
      label: "Details",
      metaId: fieldTabDetails,
      fieldIdSet: [
        fieldKeyName,
        fieldKeyLabel,
        fieldKeyDescription,
        fieldLayoutDetailTooltipField,
        fieldGap2,
        fieldSpreadsheetLayoutSwitcherIdSet
      ]
    } as DefnSection
  };
}

function dtoToValue(calendar?: StudioDtoLayoutCalendar)
{
  if(!calendar)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "Calendar")
    };

  }
  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", calendar.name ?? "Calendar"),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", calendar.description),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", calendar.label),
    [fieldDateFormField]: fnRawValueToFieldValue("pickFieldId", calendar.fromDateFieldId),
    [fieldDateToField]: fnRawValueToFieldValue("pickFieldId", calendar.toDateFieldId),
    [fieldTimeFormField]: fnRawValueToFieldValue("pickFieldId", calendar.fromTimeFieldId),
    [fieldTimeToField]: fnRawValueToFieldValue("pickFieldId", calendar.toTimeFieldId),
    [fieldTitleField]: fnRawValueToFieldValue("pickFieldId", calendar?.titleFieldId),
    [fieldShowFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", calendar.showFieldIdSet),
    [fieldColorField]: fnRawValueToFieldValue("pickFieldId", calendar?.colorFieldId),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", calendar?.toolTipFieldId),
    [fieldSpreadsheetLayoutSwitcherIdSet]: fnRawValueToFieldValue("studioSetOfLayoutGridId",
      calendar.allowToSwitchLayoutIdSet
    )
  };
}

function valueToDto(values: FieldValues): StudioDtoLayoutCalendar
{
  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    label: fnFieldValueToRawValue("text", values[fieldKeyLabel]),
    kind: "calendar",
    description: fnFieldValueToRawValue("text", values[fieldKeyDescription]),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLayoutDetailTooltipField]),
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioSetOfLayoutGridId",
      values[fieldSpreadsheetLayoutSwitcherIdSet]
    ),
    fromDateFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldDateFormField]),
    toDateFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldDateToField]),
    fromTimeFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldTimeFormField]),
    toTimeFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldTimeToField]),
    titleFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldTitleField]),
    showFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldShowFieldIdSet]),
    colorFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldColorField])
  } as StudioDtoLayoutCalendar;
}
