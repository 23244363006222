import {BeforeMount} from "@monaco-editor/react";
import {OnMount} from "@monaco-editor/react";
import Editor from "@monaco-editor/react";
import {editor} from "monaco-editor";
import {useRef} from "react";
import React from "react";
import {monacoOptions} from "../../../routes/terminal/input/editor/neomeTheme";
import {getTheme} from "../../../routes/terminal/input/editor/neomeTheme";
import {MonacoType} from "../../../routes/terminal/input/editor/Types";
import IModelDeltaDecoration = editor.IModelDeltaDecoration;
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;

const commands: string[] = [];
const whats: string[] = [];

const RawStyledEditor = (props: {
  value: string,
  defaultLanguage?: string,
  height?: string,
  language?: string,
  theme?: string,
  className?: string,
  onChange?: (newValue?: string) => void,
  onMount?: OnMount,
  beforeMount?: BeforeMount,
  options?: IStandaloneEditorConstructionOptions
}) =>
{
  const value = props.value;
  const onChange = props.onChange;
  const onMount = props.onMount;
  const beforeMount = props.beforeMount;
  const monacoRef = useRef<MonacoType>();
  const language = props.language;
  const theme = props.theme;
  const options = props.options;
  const height = props.height;
  const className = props.className;
  const defaultLanguage = props.defaultLanguage ?? "json";

  function isValidSymbol(symbol: string): boolean
  {
    const regex: RegExp = /^(?=.{1,64}$)(?=[A-Z])(?=\S)[A-Za-z0-9]+$/;
    return regex.test(symbol);
  }

  function getSymbolName(name: string): string | undefined
  {
    const tokens = name.split(" ");
    if(tokens.length > 2)
    {
      const cmd = tokens[0];
      if(commands.includes(cmd.toLowerCase()))
      {
        const what = tokens[1];
        if(whats.includes(what.toLowerCase()))
        {
          const name = tokens[2];
          if(isValidSymbol(name))
          {
            return name;
          }
        }
      }
    }
    return undefined;
  }

  function configureCustomLanguage(monaco: MonacoType)
  {
    if(!language)
    {
      return;
    }

    monaco.languages.register({
      id: language,
      extensions: [".neo"]
    });

    monaco.languages.setMonarchTokensProvider(language, {
      commands,
      whats,
      booleans: ["true", "false", "yes", "no"],
      ignoreCase: true,
      tokenizer: {
        root: [
          [
            /[a-zA-Z]+(?=\s*[:])/,
            {
              cases: {
                "@default": "argument"
              }
            }
          ],
          [
            /[a-zA-Z]+/, {
            cases: {
              "@commands": "command",
              "@whats": "what",
              "@booleans": "boolean"
            }
          }
          ],

          [/".*?"/, "string"],
          [/\/\/.*/, "comment"]
        ]
      }
    });
  }

  function configureTheme(monaco: MonacoType)
  {
    if(theme)
    {
      monaco.editor.defineTheme(theme, getTheme());
      monaco.editor.setTheme(theme);
    }
  }

  function setupSymbolNameDecoration(editor: editor.IStandaloneCodeEditor)
  {
    const value = editor.getModel()?.getValue();
    if(!value)
    {
      return;
    }
    const lines = value.split("\n");

    const decorations: IModelDeltaDecoration[] = [];
    const monaco = monacoRef.current;
    lines.forEach((command, lineNumber) =>
    {
      const symbolName = getSymbolName(command);
      if(symbolName)
      {
        const index = command.indexOf(symbolName);
        if(monaco)
        {
          const range = new monaco.Range(
            lineNumber + 1,
            index + 1,
            lineNumber + 1,
            index + symbolName.length + 1
          );
          decorations.push({
            range,
            options: {
              inlineClassName: "symbolName" // Apply a class for the matched text
            }
          });
        }

      }
    });
    editor.deltaDecorations([], decorations);
  }

  return <Editor
    loading={null}
    className={className}
    height={height || "100%"}
    defaultLanguage={defaultLanguage}
    value={value ?? ""}
    onChange={onChange}
    options={options || monacoOptions}
    language={language}
    theme={theme}
    beforeMount={(monaco) =>
    {
      beforeMount && beforeMount(monaco);
      if(monacoRef)
      {
        monacoRef.current = monaco;
      }
      configureTheme(monaco);
      configureCustomLanguage(monaco);
    }}
    onMount={(editor, monaco) =>
    {
      onMount && onMount(editor, monaco);
      setupSymbolNameDecoration(editor);

      if(editor)
      {
        editor.focus();
      }
    }}
  />;
};

export default RawStyledEditor;
