import {isEmpty} from "lodash";
import {nextMetaIdReport} from "../../../../api/meta/base/ApiPlus";
import {StudioEntReport} from "../../../../api/meta/base/dto/StudioEntReport";
import {EntId, MetaIdReport} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntReport, moveEntItem, removeEntReport} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntReports extends ISrvc
{
  addEntReport(entId: EntId, report: StudioEntReport)
  {
    dispatchEnt(entId, addEntReport({
      entId: entId,
      sig: {
        ...report,
        metaId: report.metaId || nextMetaIdReport()
      }
    }));
  }

  removeEntReport(entId: EntId, metaIdReport: MetaIdReport)
  {
    dispatchEnt(entId, removeEntReport({
      entId: entId,
      metaId: metaIdReport
    }));
  }

  copyEntReport(entId: EntId, metaIdReport: MetaIdReport)
  {
    const rootState = store.getState();
    const reportList = selectCacheStudioEnt(rootState, entId)?.ent.reportMap;
    const report = reportList?.map[metaIdReport];

    if(!isEmpty(report))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "report",
        payload: report
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntReport(entId: EntId, metaIdReport: MetaIdReport)
  {
    const rootState = store.getState();
    const reportList = rootState.cache.studio.ent.entMap[entId]?.ent.reportMap;
    if(!reportList)
    {
      return;
    }

    const index = reportList.keys.indexOf(metaIdReport);
    if(index === -1)
    {
      return;
    }
    const entReport = reportList.map[metaIdReport]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(reportList.map[metaIdReport])))
      : undefined;
    if(entReport)
    {
      dispatchEnt(entId, addEntReport({
        entId: entId,
        sig: entReport,
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdReport,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "reportList",
      moveDirection
    }));
  }
}
