import ISrvc from "../../base/ISrvc";
import SrvcHomeApp from "./app/SrvcHomeApp";
import SrvcHomeAside from "./aside/SrvcHomeAside";
import {SrvcHomeDrawer} from "./drawer/SrvcHomeDrawer";
import SrvcHomeMain from "./main/SrvcHomeMain";

export default class SrvcHome extends ISrvc
{
  public readonly app = new SrvcHomeApp();
  public readonly drawer = new SrvcHomeDrawer();
  public readonly main = new SrvcHomeMain();
  public readonly aside = new SrvcHomeAside();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.app,
      this.drawer,
      this.main,
      this.aside
    );
  }
}
