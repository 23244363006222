import {StudioEntDeployVar} from "../../../../../api/meta/base/dto/StudioEntDeployVar";
import {EntId} from "../../../../../api/meta/base/Types";
import ISrvc from "../../../../../base/ISrvc";
import {dispatchEnt} from "../../../SrvcCacheStudio";
import {addEntRequiredOnDeploymentVariable} from "../../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntDeployVariables extends ISrvc
{

  addEntRequiredOnDeploymentVariable(entId: EntId, studioEntDeployVar: StudioEntDeployVar)
  {
    dispatchEnt(entId, addEntRequiredOnDeploymentVariable({
      entId: entId,
      sig: studioEntDeployVar
    }));
  }
}
