import {Box} from "@mui/material";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../../base/plus/StringPlus";
import {EnumBubbleIconCaption} from "../../../../base/types/TypesBubble";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import IconBubbleCaption from "../../icon/IconBubbleCaption";
import {EnumProgressInnerIcon} from "../../icon/IconProgressInner";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import {LayoutGap} from "../../layout/LayoutGap";
import RawProgress from "../../raw/RawProgress";

interface BubbleLineFooterText
{
  text?: string;
  iconRight?: EnumBubbleIconCaption,
  iconLeft?: EnumBubbleIconCaption,
  color?: TypeTextColor;
  onClick?: CbMenuAnchor;
}

export interface BubbleLineFooterProgress extends Omit<BubbleLineFooterText, "text">
{
  timer?: number;
  value?: string;
  icon?: EnumProgressInnerIcon;
}

export default function BubbleLineFooter(props: {
  textLeft?: BubbleLineFooterProgress,
  textCenter?: BubbleLineFooterText,
  textRight: BubbleLineFooterText,
  isStar?: boolean,
  heightLine?: number,
  onClick?: CbMenuAnchor,
})
{
  const theme = useTheme();
  const vertInner = theme.common.vertInner;
  const gapHalf = theme.common.gapHalf;
  const gapQuarter = theme.common.gapQuarter;
  const itemSpacing = theme.common.bubbleFooterItemSpacing;

  const {
    textLeft,
    textCenter,
    textRight,
    isStar,
    heightLine,
    onClick
  } = props;

  return (
    <LayoutFlexRow
      width={"100%"}
      height={px(heightLine)}
      pl={px(gapHalf)}
      pr={px(gapHalf)}
      cursorPointer={Boolean(onClick)}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
      {
        e.stopPropagation();
        onClick && onClick(e.currentTarget);
      }}
    >
      {
        (textLeft && textLeft.timer !== undefined) &&
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pr: px(gapQuarter),
            cursor: Boolean(textLeft?.onClick) ? "pointer" : "default"
          }}
          onClick={event =>
          {
            event.stopPropagation();
            textLeft?.onClick?.(event.currentTarget);
          }}
        >
          <RawProgress
            value={textLeft.timer}
            color={"error"}
            size={theme.common.bubbleFooterTimerSize}
            innerIcon={textLeft.icon}
          />
        </Box>
      }

      {
        (textLeft && textLeft.value) &&
        <TextFooter {...textLeft as BubbleLineFooterText} />
      }
      <LayoutGap flexGrow={1} />

      {
        textCenter !== undefined && <TextFooter pr={itemSpacing} {...textCenter} />
      }

      {
        isStar && (
          <Box
            component={"span"}
            onClick={event => event.stopPropagation()}
            pt={px(vertInner)}
            pr={px(itemSpacing)}
            sx={{cursor: "default"}}
          >
            <IconBubbleCaption value={"star"} />
          </Box>
        )
      }

      <TextFooter {...textRight} pr={gapQuarter} />

    </LayoutFlexRow>
  );
}

function TextFooter(props: BubbleLineFooterText & BubbleLineFooterProgress & {
  pl?: number,
  pr?: number
})
{
  const value = props.text ?? props.value;
  const iconLeft = props.iconLeft;
  const iconRight = props.iconRight;
  const color = props.color;
  const onClick = props.onClick;

  const theme = useTheme();
  const vertInner = theme.common.vertInner;

  return (
    <Box
      sx={{
        cursor: Boolean(onClick) ? "pointer" : "default",
        display: "flex",
        position: "relative",
        flexDirection: "row"
      }}
      onClick={onClick
        ? (e: React.MouseEvent<HTMLElement>) =>
        {
          e.stopPropagation();
          onClick(e.currentTarget);
        }
        : (e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
    >

      {
        iconLeft && (
          <IconBubbleCaption
            value={iconLeft}
          />
        )
      }

      <Typography
        variant={"caption"}
        noWrap={true}
        mt={px(vertInner)}
        sx={{
          color: color
            ? theme.common.color(color)
            : theme.common.color("textSecondary"),
          pr: px(props.pr),
          pl: px(props.pl),
          fontWeight: 400,
          overflow: "hidden"
        }}
      >
        {value}
      </Typography>
      {
        iconRight && (
          <IconBubbleCaption
            value={iconRight}
          />
        )
      }
    </Box>
  );
}


