import {FieldValues} from "react-hook-form";
import {DefnFieldNumber} from "../../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldDecimal} from "../../../../../api/meta/base/dto/StudioFieldDecimal";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyNumberOfDigitAfterPeriodFieldId} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyNumberOfDigitAfterPeriodValue} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyNumberOfDigitAfterPeriodVarId} from "../../base/TypesFormBuilder";

export function getDefnFieldDecimal(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinFieldId,
    propKeyMaxFieldId,
    propKeyNumberOfDigitAfterPeriodFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinVar,
    propKeyMaxVar,
    propKeyNumberOfDigitAfterPeriodVarId
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyMin,
    propKeyMax,
    propKeyNumberOfDigitAfterPeriodValue
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "decimal",
      formId,
      "decimal",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyNumberOfDigitAfterPeriodValue]: {
      type: "number",
      name: propKeyNumberOfDigitAfterPeriodValue,
      metaId: propKeyNumberOfDigitAfterPeriodValue,
      label: "Number of digit after period",
      min: 1,
      max: 16
    } as DefnFieldNumber,

    [propKeyNumberOfDigitAfterPeriodVarId]: {
      type: "pickVarId",
      name: propKeyNumberOfDigitAfterPeriodVarId,
      metaId: propKeyNumberOfDigitAfterPeriodVarId,
      varKind: "number",
      label: "Number of digit after period",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyNumberOfDigitAfterPeriodFieldId]: {
      type: "pickFieldId",
      metaId: propKeyNumberOfDigitAfterPeriodFieldId,
      name: propKeyNumberOfDigitAfterPeriodFieldId,
      label: "Number of digit after period",
      formId: formId,
      filterFieldTypeSet: ["number"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId
  };
}

export function defnValueToStudioFieldDecimal(values: FieldValues): StudioFieldDecimal
{
  return {
    type: "decimal",
    defaultValue: fnFieldValueToRawValue("decimal", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    min: fnFieldValueToRawValue("decimal", values[propKeyMin]),
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    max: fnFieldValueToRawValue("decimal", values[propKeyMax]),
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    numberOfDigitsAfterPeriodVarId: fnFieldValueToRawValue("pickVarId", values[propKeyNumberOfDigitAfterPeriodVarId]),
    numberOfDigitsAfterPeriod: fnFieldValueToRawValue("number", values[propKeyNumberOfDigitAfterPeriodValue]),
    numberOfDigitsAfterPeriodFieldId: fnFieldValueToRawValue("pickFieldId",
      values[propKeyNumberOfDigitAfterPeriodFieldId])
  } as StudioFieldDecimal;
}

export function studioFieldDecimalToDefnValue(studioField: StudioFieldDecimal)
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("decimal", studioField.defaultValue),
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMin]: fnRawValueToFieldValue("decimal", studioField.min),
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMax]: fnRawValueToFieldValue("decimal", studioField.max),
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyNumberOfDigitAfterPeriodVarId]: fnRawValueToFieldValue("pickVarId",
      studioField.numberOfDigitsAfterPeriodVarId),
    [propKeyNumberOfDigitAfterPeriodValue]: fnRawValueToFieldValue("number", studioField.numberOfDigitsAfterPeriod),
    [propKeyNumberOfDigitAfterPeriodFieldId]: fnRawValueToFieldValue("pickFieldId",
      studioField.numberOfDigitsAfterPeriodFieldId)
  };
}
