import {isEmpty} from "lodash";
import {nextMetaIdSpreadsheet} from "../../../../api/meta/base/ApiPlus";
import {StudioEntSpreadsheet} from "../../../../api/meta/base/dto/StudioEntSpreadsheet";
import {EntId, MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {getSpreadsheetAlias} from "../../../../routes/studio/ent/spreadsheets/StudioEntSpreadsheetPlus";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntSpreadsheet, moveEntItem, removeEntSpreadsheet} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntSpreadsheets extends ISrvc
{
  addEntSpreadsheet(entId: EntId, spreadsheet: StudioEntSpreadsheet)
  {
    const rootState = store.getState();
    const spreadsheetMap = selectCacheStudioEnt(rootState, entId)?.ent.spreadsheetMap;

    dispatchEnt(entId, addEntSpreadsheet({
      entId: entId,
      sig: {
        ...spreadsheet,
        alias: spreadsheet.alias || getSpreadsheetAlias(spreadsheetMap) as string,
        metaId: spreadsheet.metaId || nextMetaIdSpreadsheet()
      }
    }));
  }

  removeEntSpreadsheet(entId: EntId, metaIdSpreadsheet: MetaIdSpreadsheet)
  {
    dispatchEnt(entId, removeEntSpreadsheet({
      entId: entId,
      metaId: metaIdSpreadsheet
    }));
  }

  copyEntSpreadsheet(entId: EntId, metaIdSpreadsheet: MetaIdSpreadsheet)
  {
    const rootState = store.getState();
    const spreadsheetMap = selectCacheStudioEnt(rootState, entId)?.ent.spreadsheetMap;
    const spreadsheet = spreadsheetMap?.map[metaIdSpreadsheet];

    if(!isEmpty(spreadsheet))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "spreadsheet",
        payload: spreadsheet
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntSpreadsheet(entId: EntId, metaIdSpreadsheet: MetaIdSpreadsheet)
  {
    const rootState = store.getState();
    const spreadsheetMap = rootState.cache.studio.ent.entMap[entId]?.ent.spreadsheetMap;

    if(!spreadsheetMap)
    {
      return;
    }
    const index = spreadsheetMap.keys.indexOf(metaIdSpreadsheet);
    if(index === -1)
    {
      return;
    }
    const entSpreadsheet = spreadsheetMap.map[metaIdSpreadsheet]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(spreadsheetMap.map[metaIdSpreadsheet])))
      : undefined;
    if(entSpreadsheet)
    {
      dispatchEnt(entId, addEntSpreadsheet({
        entId: entId,
        sig: {
          ...entSpreadsheet,
          alias: getSpreadsheetAlias(spreadsheetMap)
        },
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdSpreadsheet,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "spreadsheetList",
      moveDirection
    }));
  }

}
