import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {enUS} from "date-fns/locale";
import {ReactNode} from "react";

export default function RawLocalizationProvider(props: {
  children: ReactNode
})
{
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={enUS}
    >
      {props.children}
    </LocalizationProvider>
  );
}
