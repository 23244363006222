import {yupResolver} from "@hookform/resolvers/yup";
import {Box} from "@mui/material";
import {cloneDeep} from "lodash";
import {throttle} from "lodash";
import {useState} from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {ErrorOption} from "react-hook-form";
import {FormProvider, useForm} from "react-hook-form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {SetValueConfig} from "react-hook-form/dist/types/form";
import {ISig} from "../../../api/meta/base/sig/ISig";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {sprintf} from "../../../api/nucleus/base/Protocol";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {SelectCalendar} from "../../../base/plus/CalendarPlus";
import {ensureInitValues} from "../../../base/plus/FieldValuePlus";
import {setDefnFieldTabForJump} from "../../../base/plus/FormPlus";
import {getSchema} from "../../../base/plus/FormPlus";
import {getDisplaySection} from "../../../base/plus/FormPlus";
import {getTheme} from "../../../base/plus/FormPlus";
import {prepare} from "../../../base/plus/FormPlus";
import {SelectKanban} from "../../../base/plus/KanbanPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {IListBinderAll} from "../../../base/types/list/TypesList";
import {DefnFieldUi} from "../../../base/types/TypesForm";
import {TypeFocusFieldOptions} from "../../../base/types/TypesForm";
import {FormStore} from "../../../base/types/TypesForm";
import {DefnFormUi} from "../../../base/types/TypesForm";
import {IFormRef} from "../../../base/types/TypesForm";
import {IFormFieldError} from "../../../base/types/TypesForm";
import {IStudioJumpPath} from "../../../base/types/TypesStudio";
import {usePageCtx} from "../../ctx/CtxPage";
import {useFormPropertiesResolver} from "../base/FormHooks";
import {useFormRefAndCtxSetter} from "../base/FormHooks";
import {FormCtxProvider} from "./base/CtxForm";
import {CbOnGetFieldOptions} from "./base/CtxForm";
import {CbOnClickForm} from "./base/CtxForm";
import IFormCtx from "./base/CtxForm";
import FieldFactory from "./base/FieldFactory";

export interface IFormProps<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
{
  defnForm: DefnFormUi,
  cbRef?: IFormRef,
  store?: FormStore;
  initValues?: FieldValues | undefined,
  formReadonly?: boolean,
  formDisable?: boolean,
  selectListMap?: Record<MetaIdField, SelectList>
  selectKanbanMap?: Record<MetaIdField, SelectKanban>
  selectCalendarMap?: Record<MetaIdField, SelectCalendar>
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
  onSubmit?: (values: FieldValues) => void,
  onSubmitEnable?: (enable: boolean) => void,
  onResetEnable?: (enable: boolean) => void,
  onSubmitSuccessful?: () => void,
  onWatch?: (key: MetaIdField, value: any) => void,
  onClickFormItem?: CbOnClickForm,
  onGetFieldOptions?: CbOnGetFieldOptions,
}

export default function Form<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: IFormProps<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>)
{
  const onSubmitEnable = props.onSubmitEnable;
  const onResetEnable = props.onResetEnable;
  const onSubmit = props.onSubmit
    ? throttle(args => props.onSubmit && props.onSubmit(args), 100)
    : undefined;
  const onSubmitSuccessful = props.onSubmitSuccessful;
  const propFormReadonly = props.formReadonly;
  const onWatch = props.onWatch;
  const onClick = props.onClickFormItem;
  const onGetFieldOptions = props.onGetFieldOptions;
  const selectListMap = props.selectListMap;
  const selectKanbanMap = props.selectKanbanMap;
  const selectCalendarMap = props.selectCalendarMap;
  const defnForm = prepare(cloneDeep(props.defnForm));
  const pageCtx = usePageCtx();
  const defnTheme = getTheme(defnForm);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formReadonly, setFormReadonly] = useState(Boolean(propFormReadonly));
  const [focusField, setFocusField] = useState<TypeFocusFieldOptions>();

  const initValues = useMemo(() => ensureInitValues(defnForm, props.initValues ?? {}), [defnForm, props.initValues]);

  const methods = useForm({
    mode: "all",
    // This is for debugging only please do not remove it
    // resolver: async(data, context, options) =>
    // {
    //   // you can debug your validation schema here
    //   return yupResolver(getSchema(defnForm))(data, context, options);
    // },
    resolver: yupResolver(getSchema(defnForm)),
    defaultValues: initValues,
    shouldFocusError: false
  });

  const {
    watch,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    setValue,
    setFocus,
    formState: {
      isValid,
      isDirty,
      dirtyFields
    },
    resetField,
    reset,
    handleSubmit,
    control,
    trigger
  } = methods;

  const defnDisplayComp = getDisplaySection(defnForm);

  const formCtx = {} as IFormCtx;

  useFormRefAndCtxSetter({
    formCtx: formCtx,
    defnForm: defnForm,
    cbRef: props.cbRef,
    formDisable: props.formDisable,
    formReadonly: formReadonly,
    getValues: getValues
  });

  useFormPropertiesResolver({
    defnForm: defnForm,
    getFieldState: getFieldState,
    trigger: trigger,
    watch: watch
  });

  formCtx.control = () => control;

  formCtx.getDefnTheme = () => defnTheme;
  formCtx.getDefnComp = (key: MetaIdField) => defnForm.compMap[key];

  formCtx.isValid = () => isValid;

  formCtx.isSubmitting = () => isSubmitting;

  formCtx.getFocusField = () => focusField;

  formCtx.resetField = (key: MetaIdField) => setValue(key, null, {
    shouldValidate: true,
    shouldTouch: true,
    shouldDirty: true
  });

  formCtx.setFocusField = (options?: TypeFocusFieldOptions) => setFocusField(options);

  formCtx.getSelectList = (key) => selectListMap && selectListMap[key];

  formCtx.getSelectKanban = (key) => selectKanbanMap && selectKanbanMap[key];

  formCtx.getSelectCalendar = (key) => selectCalendarMap && selectCalendarMap[key];

  formCtx.getStore = () => props.store;

  formCtx.isReadonly = () => formReadonly;

  formCtx.getOnClick = () => onClick;

  formCtx.getOnGetFieldOptions = () => onGetFieldOptions;

  formCtx.isDirty = () => isDirty;

  const cbOnSubmit = useCallback((values: FieldValues) =>
  {
    setIsSubmitting(true);
    setFormReadonly(true);
    onSubmit && onSubmit(values);
  }, [onSubmit]);

  const refClearErrors = useCallback(() => clearErrors(), [clearErrors]);

  const refCheckSubmit = useCallback((signal?: EnvSignal<ISig>) =>
  {
    setIsSubmitting(false);
    setFormReadonly(Boolean(propFormReadonly));

    const envError = signal && signal.error;
    if(envError === undefined)
    {
      if(onSubmitSuccessful)
      {
        setTimeout(onSubmitSuccessful);
      }
      return true;
    }

    let showErrorToast = true;
    if(envError.errorCode === "validationError")
    {
      const validationErrors = envError.validationErrors;
      if(validationErrors && validationErrors.length > 0)
      {
        validationErrors.forEach(validationError =>
        {
          if(validationError.errorCode === "invalidParam" && validationError.paramName !== undefined)
          {
            setError(
              validationError.paramName,
              {
                type: "server",
                message: validationError.errorParams === undefined
                  ? validationError.errorMessage as string
                  : sprintf(validationError.errorMessage as string, validationError.errorParams)
              },
              {
                shouldFocus: true
              }
            );
            showErrorToast = false;
          }
        });
      }
    }

    if(showErrorToast)
    {
      const errMsg = getErrorMessage(envError);
      if(errMsg !== undefined)
      {
        pageCtx.showErrorToast(errMsg);
      }
    }

    return false;
  }, [pageCtx, setError, onSubmitSuccessful, propFormReadonly]);

  const refSetError = useCallback((error: IFormFieldError, shouldFocus?: boolean) =>
  {
    const {
      key,
      errorOption,
      errorChildrenOption
    } = error;

    const msg = errorChildrenOption?.map(child => child.message) ?? [];
    msg.unshift(errorOption.message);
    const _errorOption = {
      ...errorOption,
      message: msg?.join("\n")
    } as ErrorOption;

    setError(key, _errorOption, {shouldFocus: Boolean(shouldFocus)});
  }, [setError]);

  const refSetErrors = useCallback((errorList: IFormFieldError[]) =>
  {
    if(errorList.length > 0)
    {
      errorList.forEach(error =>
      {
        refSetError(error);
      });
    }
    else
    {
      refClearErrors();
    }
  }, [refClearErrors, refSetError]);

  const refSubmit = useCallback((forceSubmit?: boolean) =>
  {
    const fieldValues = getValues();

    if(!isSubmitting)
    {
      if(forceSubmit)
      {
        handleSubmit(
          (data) => cbOnSubmit(data),
          () =>
          {
            clearErrors();
            cbOnSubmit(fieldValues);
          }
        )();
      }
      else if(isValid)
      {
        handleSubmit((data) => cbOnSubmit(data))();
      }
    }
  }, [isSubmitting, isValid, handleSubmit, cbOnSubmit, clearErrors, getValues]);

  const refReset = useCallback((valueMap?: Record<MetaIdField, any>) =>
  {
    if(!isSubmitting)
    {
      if(valueMap)
      {
        reset(valueMap);
      }
      else
      {
        reset();
      }
    }
  }, [reset, isSubmitting]);

  const refSetFormReadonly = useCallback((readonly: boolean) =>
  {
    setFormReadonly(readonly);
  }, []);

  const refSetValue = useCallback((key: MetaIdField, value: any, setValueConfig?: SetValueConfig) =>
  {
    setValue(key, value, {
      shouldValidate: setValueConfig ? setValueConfig.shouldValidate : true,
      shouldDirty: setValueConfig ? setValueConfig.shouldDirty : true,
      shouldTouch: setValueConfig ? setValueConfig.shouldTouch : true
    } as SetValueConfig);

  }, [setValue]);

  const refResetValue = useCallback((key: MetaIdField, value: any) =>
  {
    refSetValue(key, value, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false
    });
  }, [refSetValue]);

  const refSetValues = useCallback((valueMap: Record<MetaIdField, any>) =>
  {
    Object.entries(valueMap).forEach(([compKey, value]) =>
    {
      refSetValue(compKey, value);
    });

  }, [refSetValue]);

  const refResetField = useCallback((key: MetaIdField) =>
  {
    resetField(key);
  }, [resetField]);

  const refSetFocus = useCallback((key: MetaIdField) =>
  {
    setFocus(key, {shouldSelect: true});
  }, [setFocus]);

  const refSetFocusWithJump = useCallback((options?: TypeFocusFieldOptions) =>
  {
    if(!options || !options.key)
    {
      setFocusField(undefined);
      return;
    }
    const comp = defnForm.compMap[options.key] as DefnFieldUi;
    if(comp?.metaId === options.key)
    {
      setFocusField(options);
      const parenMap = (comp as DefnFieldUi)._parenMap;
      if(parenMap)
      {
        setDefnFieldTabForJump(parenMap, undefined, (parentKey, childKey) =>
        {
          setTimeout(() =>
          {
            setValue(parentKey, childKey, {
              shouldDirty: false,
              shouldTouch: false,
              shouldValidate: false
            });
          });
        });
      }
      if(options.setFocus)
      {
        setFocus(options.key, {shouldSelect: true});
      }
      if(options.durationMs)
      {
        setTimeout(() =>
        {
          setFocusField(undefined);
        }, options.durationMs);
      }
      return true;
    }

  }, [defnForm, setFocus, setValue]);

  const refJumpToField = useCallback((jumpPath?: IStudioJumpPath[]) =>
  {
    if(!jumpPath)
    {
      setFocusField(undefined);
      return;
    }
    const _jumpPath = jumpPath.reverse();
    for(let i = 0; i < _jumpPath.length; i++)
    {
      const path = _jumpPath[i];
      if(path.step && defnForm.compMap[path.step])
      {
        refSetFocusWithJump({
          key: path.step,
          durationMs: 3000
        });
        return;
      }
    }
  }, [defnForm, refSetFocusWithJump]);

  const refGetValues = useCallback((keys?: MetaIdField[]) =>
  {
    if(keys && keys.length > 0)
    {
      return getValues(keys);
    }
    else
    {
      return getValues();
    }
  }, [getValues]);

  const refIsFieldDirty = useCallback((key: MetaIdField) => Boolean(getFieldState(key)?.isDirty), [getFieldState]);
  const refIsFieldTouched = useCallback((key: MetaIdField) => Boolean(getFieldState(key)?.isTouched), [getFieldState]);
  const refGetFieldError = useCallback((key: MetaIdField) => getFieldState(key)?.error, [getFieldState]);
  const refGetValue = useCallback((key: MetaIdField) => getValues(key), [getValues]);
  const refIsDirty = useCallback(() => isDirty, [isDirty]);
  const refGetDirtyFields = useCallback(() => dirtyFields, [dirtyFields]);

  useEffect(() =>
  {
    if(onSubmitEnable)
    {
      onSubmitEnable((!isSubmitting && isValid));
    }

  }, [isSubmitting, isValid, onSubmitEnable, isDirty]);

  useEffect(() =>
  {
    if(onResetEnable)
    {
      onResetEnable(!isSubmitting && isDirty);
    }
  }, [isDirty, isSubmitting, onResetEnable]);

  useEffect(() =>
  {
    let subscription = onWatch
      ? watch((values, {name}) =>
      {
        const key = name as MetaIdField;
        onWatch(key, values[key]);
      })
      : undefined;

    return () =>
    {
      if(subscription !== undefined)
      {
        subscription.unsubscribe();
      }
    };
  }, [watch, onWatch]);

  useEffect(() =>
  {
    setFormReadonly(Boolean(propFormReadonly));
  }, [propFormReadonly]);

  const cbRef = props.cbRef;

  if(cbRef)
  {
    cbRef.checkSubmit = refCheckSubmit;
    cbRef.remoteSubmit = refSubmit;
    cbRef.remoteReset = refReset;
    cbRef.setReadonly = refSetFormReadonly;
    cbRef.setValue = refSetValue;
    cbRef.setValues = refSetValues;
    cbRef.resetField = refResetField;
    cbRef.remoteResetField = refResetValue;
    cbRef.isFieldDirty = refIsFieldDirty;
    cbRef.isFieldTouched = refIsFieldTouched;
    cbRef.getFieldError = refGetFieldError;
    cbRef.setError = refSetError;
    cbRef.setErrors = refSetErrors;
    cbRef.clearErrors = refClearErrors;
    cbRef.getValues = refGetValues;
    cbRef.getValue = refGetValue;
    cbRef.isDirty = refIsDirty;
    cbRef.getDirtyFields = refGetDirtyFields;
    cbRef.setFocus = refSetFocus;
    cbRef.setFocusWithJump = refSetFocusWithJump;
    cbRef.jumpToField = refJumpToField;
  }

  return (
    <FormProvider {...methods}>
      <FormCtxProvider ctx={formCtx}>
        <Box
          component={"form"}
          width={"100%"}
          height={"100%"}
          onSubmit={handleSubmit(cbOnSubmit)}
          noValidate
          autoComplete="off"
          display={"flex"}
          position={"relative"}
          zIndex={1}
        >
          <FieldFactory
            defnForm={defnForm}
            defnTheme={defnTheme}
            defnComp={defnDisplayComp}
            listBinderMap={props.listBinderMap}
          />
        </Box>
      </FormCtxProvider>
    </FormProvider>
  );
}
