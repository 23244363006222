import {useTheme} from "@mui/material";
import {isEqual} from "lodash";
import {cloneDeep} from "lodash";
import {isArray} from "lodash";
import {useMemo} from "react";
import {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldDtoTree} from "../../../../api/meta/base/dto/FieldDtoTree";
import {FieldDtoTreeNode} from "../../../../api/meta/base/dto/FieldDtoTreeNode";
import {StudioVarValueTree} from "../../../../api/meta/base/dto/StudioVarValueTree";
import {getListItemHeightAPSA} from "../../../../base/plus/ListPlus";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {isValidTreeNode} from "../../../../base/plus/TreePlus";
import {isValidTreeNodeList} from "../../../../base/plus/TreePlus";
import {ILinePrimary} from "../../../../base/types/TypesGlobal";
import {TypeTreeNodeUserField} from "../../../../base/types/TypesTree";
import {ITreeRef} from "../../../../base/types/TypesTree";
import {ITreeNode} from "../../../../base/types/TypesTree";
import {BoxP} from "../../../atom/box/BoxP";
import {BoxShell} from "../../../atom/box/BoxShell";
import DialogPaste from "../../../atom/dialog/DialogPaste";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import RawTree from "../../../atom/raw/RawTree";
import {usePageCtx} from "../../../ctx/CtxPage";
import {DialogImportOrExportXML} from "../../../dialog/DialogImportOrExportXML";
import DialogTreeNode from "../../../dialog/DialogTreeNode";
import FieldRawTreeShell from "./FieldRawTreeShell";

export type TypeCbOnChangeTreeNodes = (value?: StudioVarValueTree | null) => void;

export interface UserFieldTreeBuilder extends TypeTreeNodeUserField
{
  metaId: string;
  name?: string;
  depth: number;
}

export default function FieldRawTreeBuilder(props: {
  fieldValue?: StudioVarValueTree,
  isFormReadOnly?: boolean,
  isLastField?: boolean,
  showAddBtn?: boolean,
  onChange: TypeCbOnChangeTreeNodes,
})
{
  const pageCtx = usePageCtx();
  const theme = useTheme();

  const fieldValue = props.fieldValue;
  const cbRefTree = useMemo(() => ({} as ITreeRef), []);
  const treeNodeHeight = getListItemHeightAPSA("p");

  const refNodeMap = useRef<Record<string, boolean>>({});

  const [rootNodes, setRootNodes] = useState<ITreeNode[]>(dtoToDefn(refNodeMap.current, fieldValue));

  const onChange = (rootNodes?: ITreeNode[]) => props.onChange(defnToDto(rootNodes));

  const nodeAddNew = (node?: ITreeNode) =>
  {
    pageCtx.showDialog(
      <DialogTreeNode
        onClickOk={(values) => cbRefTree.nodeAdd(values, node)}
      />
    );
  };

  const onNodeEdit = (node: ITreeNode) =>
  {
    pageCtx.showDialog(
      <DialogTreeNode
        values={node}
        onClickOk={(values) =>
        {
          cbRefTree.nodeUpdate(values, node);
        }}
      />
    );
  };

  const generateNewNodeId = (nodeId: string) =>
  {
    const getNodeId = (nodeId: string) =>
    {
      const lastChar = nodeId.charAt(nodeId.length - 1);
      const isLastCharNumber = !isNaN(parseInt(lastChar));

      let newNodeId;

      if(isLastCharNumber)
      {
        const lastNum = parseInt(lastChar);
        const newLastChar = (lastNum + 1).toString();
        newNodeId = nodeId.slice(0, nodeId.length - 1) + newLastChar;
      }
      else
      {
        newNodeId = nodeId + "2";
      }

      return newNodeId;
    };

    let newNodeId = getNodeId(nodeId);

    while(refNodeMap.current[newNodeId])
    {
      newNodeId = getNodeId(newNodeId);
    }

    refNodeMap.current[newNodeId] = true;

    return newNodeId;
  };

  const getNewNode = useCallback((treeNode: ITreeNode) =>
  {
    const newNode = cloneDeep(treeNode) as ITreeNode;
    const COPY = "copy";
    newNode.id = treeNode.id + COPY;

    const traverseNode = (treeNode: ITreeNode) =>
    {
      const userField = treeNode.userField as UserFieldTreeBuilder;

      treeNode.id = treeNode.id + COPY;
      (treeNode.userField as UserFieldTreeBuilder).metaId = generateNewNodeId(userField.metaId);

      treeNode.children?.forEach(childNode =>
      {
        traverseNode(childNode);
      });
    };

    traverseNode(newNode);

    return newNode;

  }, []);

  const onNodeDuplicate = useCallback((node: ITreeNode, rootNodes?: ITreeNode[]) =>
  {
    cbRefTree.nodeFind(node, rootNodes, (index, nodes) =>
    {
      const oldNode = cloneDeep(nodes[index]);

      const newNode = getNewNode(oldNode);

      nodes.splice(index + 1, 0, newNode);

      cbRefTree.nodeExpand(newNode);

      if(rootNodes)
      {
        onChange(rootNodes);
      }
    });

  }, [cbRefTree, getNewNode]);

  const onClickApply = useCallback((stringifyTree: string, currNode: ITreeNode, rootNodes?: ITreeNode[]) =>
  {
    const copyNode = JSON.parse(stringifyTree) as ITreeNode;

    if(isValidTreeNode(copyNode))
    {
      const newNode = getNewNode(copyNode);

      currNode.children = [
        ...currNode.children ?? [],
        newNode
      ];

      cbRefTree.nodeExpand(currNode);

      if(rootNodes)
      {
        onChange(rootNodes);
      }
    }
    else
    {
      pageCtx.showErrorToast("Invalid node");
    }

  }, [cbRefTree, getNewNode, pageCtx]);

  const cbOnPasteItem = useCallback((node: ITreeNode, rootNodes?: ITreeNode[]) =>
  {
    pageCtx.showDialog(
      <DialogPaste
        title={"Paste node"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={(text) => onClickApply(text, node, rootNodes)}
      />
    );

  }, [onClickApply, pageCtx]);

  const cbOnClickMenu = useCallback((menuAnchor: Element, node: ITreeNode, isFirst: boolean, isLast: boolean) =>
  {
    const depth = (node.userField as UserFieldTreeBuilder)?.depth;
    let menuProps: IMenuProps = {
      "Edit": () => onNodeEdit(node),
      "gap1": undefined,
      "Copy": () => copyToClipboard(JSON.stringify(node)),
      "Paste": () => cbOnPasteItem(node, rootNodes),
      "Duplicate": () => onNodeDuplicate(node, rootNodes),
      "Remove": () => cbRefTree.nodeRemove(node),
      "gap2": undefined,
      "Move up": {
        onClick: () => cbRefTree.nodeMoveUp(node),
        disabled: isFirst
      },
      "Move down": {
        onClick: () => cbRefTree.nodeMoveDown(node),
        disabled: isLast
      },
      "Move top": {
        onClick: () => cbRefTree.nodeMoveTop(node),
        disabled: isFirst
      },
      "Move bottom": {
        onClick: () => cbRefTree.nodeMoveBottom(node),
        disabled: isLast
      },
      "gap3": undefined,
      "Add child": {
        disabled: depth >= 16,
        onClick: () => nodeAddNew(node)
      }
    };
    pageCtx.showMenu(menuAnchor, menuProps);

  }, [pageCtx, cbOnPasteItem, rootNodes, onNodeDuplicate, cbRefTree]);

  const cbOnClickImportXML = () =>
  {
    pageCtx.showDialog(
      <DialogImportOrExportXML
        onClickOk={(xml) =>
        {
          const convertedDto = xmlToTreeDto(xml);
          if(convertedDto && isArray(convertedDto) && isValidTreeNodeList(convertedDto))
          {
            onChange(convertedDto);
          }
          else
          {
            pageCtx.showErrorToast("Invalid xml");
          }
        }}
      />);
  };

  const cbOnClickExportXML = (rootNodes: ITreeNode[]) =>
  {
    pageCtx.showDialog(
      <DialogImportOrExportXML
        xml={treeDtoToXml(rootNodes)}
      />);
  };

  const getLabel = useCallback((node: ITreeNode, isFirst?: boolean, isLast?: boolean) =>
  {
    const primary: ILinePrimary = {
      text: node.name
    };
    return (
      <BoxShell
        pl={0}
        height={treeNodeHeight}
        infoSpot={node.infoSpot}
        ignoreSelection={true}
        hideMenu={props.isFormReadOnly}
        onClick={() => node.children?.length === 0 && onNodeEdit(node)}
        onClickMenu={(menuAnchor => cbOnClickMenu(menuAnchor, node, Boolean(isFirst), Boolean(isLast)))}
        onClickInfoSpot={(menuAnchor) =>
          pageCtx.showPopover(menuAnchor, {
            msg: "Duplicate node",
            bgcolor: theme.common.bgcolorError
          })}
      >
        <BoxP
          primary={primary}
          flexGrow={1}
        />

      </BoxShell>
    );

  }, [cbOnClickMenu]);

  useEffect(() =>
  {
    if(fieldValue)
    {
      const newTree = dtoToDefn(refNodeMap.current, fieldValue);
      if(!isEqual(rootNodes, newTree))
      {
        setRootNodes(newTree);
      }
    }
  }, [fieldValue]);

  useEffect(() =>
  {
    cbRefTree.expandAll();
  }, []);

  return (
    <FieldRawTreeShell
      rawTree={<RawTree
        cbRef={cbRefTree}
        rootNodes={rootNodes}
        onChange={(rootNodes) => onChange(rootNodes)}
        getLabel={getLabel}
      />}
      buttonStrip={
        props.showAddBtn
          ? <RawButtonStrip
            onClick={(iconButton) =>
            {
              if(iconButton === "importXml")
              {
                cbOnClickImportXML();
              }
              else if(iconButton === "exportXml")
              {
                cbOnClickExportXML(rootNodes);
              }
              else if(iconButton === "add")
              {
                nodeAddNew();
              }
            }}
            iconButtonList={["importXml", "exportXml", "add"]}
            labelMap={{
              importXml: "IMPORT XML",
              exportXml: "EXPORT XML",
              add: "ADD..."
            } as Record<EnumIconButton, string>}
            toolTipMap={{
              add: "Add node"
            } as Record<EnumIconButton, string>}
          />
          : undefined
      }
      showButtonStripAtBottom={props.isLastField}
    />
  );
}

function dtoToDefn(refNodeMap: Record<string, boolean>, dto?: StudioVarValueTree)
{
  const nodes = [] as ITreeNode[];

  let value = dto?.value;
  if(value)
  {
    value.keys = removeDuplicates(value.keys);
    value.keys.forEach((key, index) =>
    {
      const node = value?.map[key];
      if(node)
      {
        nodes.push(createTreeNode(refNodeMap, `root->${index}`, node));
      }
    });
  }
  return nodes;
}

const createTreeNode = (
  refNodeMap: Record<string, boolean>,
  key: string,
  node: FieldDtoTreeNode,
  depth: number = 0): ITreeNode =>
{
  const treeNode = {
    id: key,
    name: node.value,
    userField: {
      metaId: node.metaId,
      name: node.value,
      depth: depth
    } as UserFieldTreeBuilder,
    children: [] as ITreeNode[]
  } as ITreeNode;

  refNodeMap[node.metaId] = true;

  if(node.keys)
  {
    node.keys = removeDuplicates(node.keys);
    node.keys.forEach((_key, index) =>
    {
      const childNode = node.map[_key];
      if(childNode)
      {
        treeNode.children?.push(createTreeNode(refNodeMap, `${key}->${index}`, childNode, depth + 1));
      }
    });
  }

  return treeNode;
};

function defnToDto(nodes?: ITreeNode[])
{

  if(!nodes || !isArray(nodes))
  {
    return null;
  }

  const value = {
    map: {} as Record<string, FieldDtoTreeNode>,
    keys: [] as string[]
  } as FieldDtoTree;

  nodes.forEach(node =>
  {
    const userField = node.userField as UserFieldTreeBuilder;
    value.keys.push(userField.metaId);
    value.map[userField.metaId] = createDtoTreeNodes(node);
  });

  return {
    value: value
  } as StudioVarValueTree;
}

function createDtoTreeNodes(node: ITreeNode): FieldDtoTreeNode
{
  const dto = {} as FieldDtoTreeNode;
  const userField = node.userField as UserFieldTreeBuilder;
  dto.metaId = userField.metaId;
  dto.value = userField.name;
  dto.keys = [];
  dto.map = {};
  if(node.children)
  {
    node.children.forEach(child =>
    {
      const userField = child.userField as UserFieldTreeBuilder;
      dto.keys.push(userField.metaId);
      dto.map[userField.metaId] = createDtoTreeNodes(child);
    });
  }
  return dto;
}

export function treeDtoToXml(nodes: ITreeNode[]): string
{
  return generateXml(nodes);
}

function generateXml(nodes: ITreeNode[]): string
{
  let xml = "";
  const nodeName = "node";
  if(isArray(nodes))
  {
    nodes.forEach((node) =>
    {
      let attributes = "";
      const userField = node.userField as UserFieldTreeBuilder;
      const metaId = userField?.metaId;
      const name = userField?.name;
      if(metaId)
      {
        attributes += ` key="${metaId}"`;
      }
      if(name)
      {
        attributes += ` name="${name}"`;
      }

      xml += `<${nodeName}${attributes}>`;
      if(node.children && node.children.length > 0)
      {
        xml += `\n${generateXml(node.children)}`;
      }
      xml += `</${nodeName}>`;
    });
  }

  return xml;
}

export function xmlToTreeDto(xml: string): ITreeNode[]
{
  const dto = [] as ITreeNode[];
  const parser = new DOMParser();
  const rootXml = `<node key="value">${xml}</node>`.trim(); //  DOMParser requires a root element
  const xmlDoc = parser.parseFromString(rootXml, "application/xhtml+xml");

  const root = xmlDoc.getElementsByTagName("node")[0];
  const nodes = root.children;
  if(nodes && nodes.length > 0)
  {
    for(let i = 0; i < nodes.length; i++)
    {
      const element = nodes[i];
      if(element.nodeName === "node")
      {
        generateJson(element, dto, `${i}`);
      }
    }
  }

  return dto;
}

function generateJson(element: Element, json: ITreeNode[], key: string)
{
  const node = {
    userField: {
      metaId: element.getAttribute("key") || "",
      name: element.getAttribute("name") || ""
    } as UserFieldTreeBuilder,
    children: [] as ITreeNode[],
    id: key,
    name: element.getAttribute("name") || ""
  } as ITreeNode;

  if(element.children.length > 0)
  {
    for(let i = 0; i < element.children.length; i++)
    {
      generateJson(element.children[i], node.children as ITreeNode[], `${key}->${i}`);
    }
  }
  json.push(node);
  return;
}

function removeDuplicates(arr: string[])
{
  const map = new Set<string>(arr);
  return Array.from(map);
}

