import {RootState} from "../../Store";
import SrvcChat from "../app/chat/SrvcChat";

export default class SrvcDeeplinkAsideChat extends SrvcChat
{
  constructor()
  {
    super((state: RootState) => state.deeplink.chat);
  }
}
