import {RootState} from "../../../Store";
import SrvcChat from "../../app/chat/SrvcChat";

export default class SrvcHomeAsideChat extends SrvcChat
{
  constructor()
  {
    super((state: RootState) => state.home.aside.chat);
  }
}
