import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {StudioDtoFuncArg} from "../../api/meta/base/dto/StudioDtoFuncArg";
import {MetaIdField} from "../../api/meta/base/Types";
import {MAX_CHARACTER_COUNT_SYMBOL} from "../../base/plus/ConstantsPlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const compName = "variableFunctionInputListItemName";
const compType = "variableFunctionInputListItemType";
const compRequired = "variableFunctionInputListItemRequired";

export function DialogVariableFunctionInputArgument(props: {
  values?: StudioDtoFuncArg,
  isFormReadonly?: boolean,
  onClickOk: (values: StudioDtoFuncArg) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const values = props.values;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      title={`${(values ? "Update" : "Add")} function input argument`}
      addMoreCheckBoxLabel={!values
        ? "Add more function input arguments"
        : undefined}
      onClose={props.onClose}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(),
        formReadonly: props.isFormReadonly,
        initValues: values && dtoToDefn(values),
        onSubmit: (formValues) =>
        {
          props.onClickOk(defnToDto(formValues) as StudioDtoFuncArg);
        }
      }}
    />
  );
}

function getDefnForm()
{
  return createDefaultDefnFormStudio({
    [compName]: {
      type: "text",
      metaId: compName,
      required: true,
      label: "Arg name",
      maxCharCountVar: MAX_CHARACTER_COUNT_SYMBOL,
      name: compName
    } as DefnFieldText,
    [compType]: {
      type: "enumFuncArgs",
      metaId: compType,
      required: true,
      label: "Type",
      name: compType
    } as DefnFieldEditable,
    [compRequired]: {
      type: "bool",
      metaId: compRequired,
      name: compRequired,
      label: "Required"
    } as DefnFieldSwitch,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      name: defaultSectionKey,
      fieldIdSet: [
        compName,
        compType,
        compRequired
      ]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}

function defnToDto(values: FieldValues)
{
  return {
    name: fnFieldValueToRawValue("text", values[compName]),
    funcArgKind: values[compType],
    required: fnFieldValueToRawValue("bool", values[compRequired])
  } as StudioDtoFuncArg;
}

function dtoToDefn(dto?: StudioDtoFuncArg)
{
  const valueMap = {} as FieldValues;
  if(dto)
  {
    valueMap[compName] = fnRawValueToFieldValue("text", dto.name);
    valueMap[compType] = dto.funcArgKind;
    valueMap[compRequired] = fnRawValueToFieldValue("bool", dto.required);
  }
  return valueMap;
}
