import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickImportPluginId} from "../../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {StudioEntPlugin} from "../../../../api/meta/base/dto/StudioEntPlugin";
import {StudioEntPluginMap} from "../../../../api/meta/base/dto/StudioEntPluginMap";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioPickImportPluginId(props: {
  defn: DefnStudioPickImportPluginId
})
{
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const importMap = formStore?.pluginMap;
  const options = getOptions(importMap);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={optionsToMapOfOption(options)}
      />}
    />
  );
}

function getOptions(
  importMap?: StudioEntPluginMap
)
{
  const options = [] as DefnDtoOption[];

  if(importMap)
  {
    const keys = importMap.keys;
    const map = importMap.map;

    keys && map && keys.forEach(importId =>
    {
      const importItem = map[importId];
      if(importItem && importItem.kind === "plugin")
      {
        const importPluginItem = importItem as StudioEntPlugin;

        options.push({
          metaId: importPluginItem.metaId,
          value: importPluginItem.pluginName
        });
      }
    });
  }

  return options;
}
