import {useTheme} from "@mui/material";
import {useRef} from "react";
import {useCallback} from "react";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {getListItemHeightGroup} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {listSetGroupExpand} from "../../../base/slices/list/SliceListGroupActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {useAppSelector} from "../../app/AppHooks";
import {BoxHeader} from "../../atom/box/BoxHeader";
import {BoxInfoSpot} from "../../atom/box/BoxInfoSpot";
import {BoxMenuButton} from "../../atom/box/BoxMenuButton";
import {DividerHorizontalLight} from "../../atom/layout/DividerHorizontalLight";
import LayoutFlexCol from "../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../atom/layout/LayoutFlexRow";
import {TypeEnumCaptionButton} from "../List";
import {CbOnClickListItem} from "../List";

export function ListItemGroup(props: {
  itemId: TypeListItemId,
  selectList: SelectList,
  onClickListItem?: CbOnClickListItem,
})
{
  const itemId = props.itemId;
  const onClickInternal = props.onClickListItem;

  const theme = useTheme();
  const gapStd = theme.common.gapStd;
  const menuAnchorRef = useRef<Element>();
  const bgcolorSelection = theme.common.bgcolorSelection;

  let itemHeight = getListItemHeightGroup("listGroup");
  let itemWithSecondaryHeight = getListItemHeightAPSA("ps");

  const listName = useAppSelector(state => props.selectList(state).listName);
  const listIgnoreSelection = useAppSelector(state => props.selectList(state).ignoreSelection);
  const item = useAppSelector(state => props.selectList(state).itemsById[itemId]) as IListItemGroup | undefined;
  const ignoreSelection = listIgnoreSelection ? true : (item ? item.ignoreSelection : false);
  const selectedItemId = useAppSelector(state => props.selectList(state).selectedItemId);

  const ignoreSelectionCaption = ignoreSelection ?? item?.header?.caption?.ignoreSelection;

  const bgColor = item?.bgColor;
  const header = item?.header;
  const expand = header && header.expand;
  const ml = (header && header.ml) ?? 0;
  const mr = (header && header.mr) ?? 0;
  const mt = (header && header.mt) ?? 0;
  const mb = (header && header.mb) ?? 0;

  itemHeight += mt + mb;
  itemWithSecondaryHeight += mt + mb;

  const cbOnClick = useCallback((e: React.MouseEvent<HTMLElement>) =>
  {
    if(ignoreSelection)
    {
      return;
    }

    if(item !== undefined)
    {
      const _selectedItemId = selectedItemId === itemId ? undefined : itemId;

      dispatchList(listName, listSetSelectedItemId(_selectedItemId));

      onClickInternal && onClickInternal(e.currentTarget, itemId, item, "listItem", false, Boolean(_selectedItemId));
    }
  }, [ignoreSelection, onClickInternal, selectedItemId, itemId, expand, listName, item]);

  const onClickPrefixIcon = useCallback(() =>
  {
    if(item !== undefined && expand !== undefined)
    {
      dispatchList(listName,
        listSetGroupExpand({
          groupId: itemId,
          expand: !expand
        })
      );
    }
  }, [expand]);

  const cbOnClickCaption = useCallback((e: React.MouseEvent<HTMLElement>) =>
  {
    if(ignoreSelectionCaption)
    {
      return;
    }

    if(item !== undefined)
    {
      onClickInternal && onClickInternal(e.currentTarget, itemId, item, "apsaPrimaryCaption");
    }
  }, [ignoreSelectionCaption, onClickInternal, itemId, item]);

  const onClickIconButtonCaption = useCallback((
    e: React.MouseEvent<HTMLButtonElement>,
    type: TypeEnumCaptionButton) =>
  {
    if(item !== undefined)
    {
      onClickInternal && onClickInternal(e.currentTarget, itemId, item, type);
    }
  }, [ignoreSelectionCaption, onClickInternal, itemId, item]);

  const cbOnClickMenu = useCallback((menuAnchor: Element) =>
  {
    if(item !== undefined)
    {
      onClickInternal && onClickInternal(menuAnchor, itemId, item, "spotMenu");
    }
  }, [itemId, item]);

  const cbOnClickSpotInfo = useCallback((menuAnchor: Element) =>
  {
    if(item !== undefined)
    {
      onClickInternal && onClickInternal(menuAnchor, itemId, item, "spotInfo");
    }
  }, [itemId, item]);

  return (
    <LayoutFlexCol
      width={"100%"}
      bgcolor={selectedItemId === itemId ? bgcolorSelection : bgColor}
    >
      <LayoutFlexRow
        width={"100%"}
        height={px(header?.secondary ? itemWithSecondaryHeight : itemHeight)}
        onClick={expand !== undefined && !ignoreSelection ? cbOnClick : undefined}
        cursorPointer={Boolean(!ignoreSelection && props.onClickListItem)}
        onContextMenu={(!item?.hideMenu && cbOnClickMenu)
          ? (e) =>
          {
            e.preventDefault();
            e.stopPropagation();
            menuAnchorRef.current && cbOnClickMenu(menuAnchorRef.current);
            return false;
          }
          : undefined}
      >
        <BoxHeader
          header={header}
          flexGrow={1}
          onClickPrefixIcon={onClickPrefixIcon}
          onClickCaption={expand !== undefined && !ignoreSelectionCaption ? cbOnClickCaption : undefined}
          onClickIconButtonCaption={expand !== undefined ? onClickIconButtonCaption : undefined}
          ml={ml}
          mr={mr}
          mt={mt}
          mb={mb}
        />

        {
          <LayoutFlexCol
            width={px(gapStd)}
            height={"100%"}
            justifyContent={item?.infoSpot ? "space-between" : "flex-end"}
          >
            {item?.infoSpot &&
              <BoxInfoSpot
                infoSpot={item.infoSpot}
                onClick={cbOnClickSpotInfo}
              />
            }

            {cbOnClickMenu && !item?.hideMenu &&
              <BoxMenuButton
                ref={menuAnchorRef}
                onClick={cbOnClickMenu}
              />
            }
          </LayoutFlexCol>

        }

      </LayoutFlexRow>
      {
        !ignoreSelection &&
        <DividerHorizontalLight />
      }
    </LayoutFlexCol>
  );
}
