import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {nextMetaIdVisibilityAction} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfVisibilityAction} from "../../../../api/meta/base/dto/DefnStudioMapOfVisibilityAction";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {StudioVisibilityAction} from "../../../../api/meta/base/dto/StudioVisibilityAction";
import {StudioVisibilityActionMap} from "../../../../api/meta/base/dto/StudioVisibilityActionMap";
import {MetaIdVisibilityAction} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnResolveArgBinderValue} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {loopStudioForm} from "../../../../base/plus/StudioFormPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewFormVisibilityAction from "../../../dialog/DialogNewFormVisibilityAction";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {CbOnClickFormList} from "../raw/FieldRawList";
import {IFieldRawListRef} from "../raw/FieldRawList";
import {fieldListItemVal} from "../raw/FieldRawList";
import FieldRawList from "../raw/FieldRawList";

export default function FieldStudioMapOfVisibilityAction(props: {
  defn: DefnStudioMapOfVisibilityAction
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnFieldVisibilityAction = props.defn;
  const fieldId = getFieldKey(defnFieldVisibilityAction);

  const formStore = formCtx.getStore();
  const formReadOnly = formCtx.isReadonly();

  // const isLastField = formCtx.isLastFieldInDisplay(defnFieldActionsInput.metaId);
  const isLastField = false;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const label = defnFieldVisibilityAction.label;
  const formId = defnFieldVisibilityAction.formId ?? "";

  return (
    <Controller
      name={defnFieldVisibilityAction.metaId}
      control={formCtx.control()}
      render={({
        field
      }) =>
      {
        const fieldValue = field.value as StudioVisibilityActionMap;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair

              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfVisibilityAction
                  fieldId={fieldId}
                  formStore={formStore}
                  formId={formId}
                  onChange={onChange}
                  isLastField={isLastField}
                  isFormReadOnly={formReadOnly}
                  fieldValue={fieldValue}
                />
              }
            />
          );
        }

        return (
          <RawSetOfVisibilityAction
            fieldId={fieldId}
            formStore={formStore}
            formId={formId}
            onChange={onChange}
            isLastField={isLastField}
            isFormReadOnly={formReadOnly}
            fieldValue={fieldValue}
          />
        );
      }
      }
    />
  );
}

function RawSetOfVisibilityAction(props: {
  fieldId: MetaIdField,
  formStore?: FormStore,
  formId: MetaIdForm,
  isLastField?: boolean,
  isFormReadOnly?: boolean,
  fieldValue?: StudioVisibilityActionMap,
  onChange: (value: StudioVisibilityActionMap) => void
})
{
  const {
    fieldId,
    formStore,
    formId,
    isLastField,
    isFormReadOnly
  } = props;

  const pageCtx = usePageCtx();
  const cbRefList = {} as IFieldRawListRef<StudioVisibilityAction>;
  const fnResolver = formStore
    ? fnUseStudioResolver(formStore)
    : undefined;

  const fieldValue = useMemo(() => convertMapToArray(props.fieldValue), [props.fieldValue]);
  const onChange = (fieldValue: StudioVisibilityAction[]) => props.onChange(convertArrayToMap(fieldValue));
  const doLoad = (selectList: SelectList, fieldValue: StudioVisibilityAction[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue)
    {
      fieldValue.forEach((value) =>
      {
        let compName = "" as string;

        if(formStore?.formMap)
        {
          loopStudioForm(formStore?.formMap?.map[formId], (composite, field) =>
          {
            if(field.metaId === value.compId)
            {
              compName = field.details.name;
            }
            if(composite.type === "section" && (composite as StudioSection).metaId === value.compId)
            {
              compName = (composite as StudioSection).details.name;
            }
            if(composite.type === "grid" && (composite as StudioGrid).metaId === value.compId)
            {
              compName = (composite as StudioGrid).details.name;
            }
          });
        }

        const listId = value.metaId;
        const visibilityAction = value.visibilityAction;
        const secondaryText = value.visibilityActionOn === "sendButton"
          ? toLabel(value.visibilityActionOn)
          : visibilityAction === "executeAction"
            ? (value.actionId ? formStore?.actionMap?.map[value.actionId]?.details.name : "")
            : (visibilityAction === "setValue" && fnResolver && value.source)
              ? fnResolveArgBinderValue(fnResolver, value.source, formId)
              : compName;

        if(listId)
        {
          uiItemIds.push(listId);

          uiItemsById[listId] = {
            type: "ps",
            primary: {
              text: value.name,
              caption: {
                type: "text",
                text: toLabel(visibilityAction),
                ignoreSelection: true
              }
            },
            secondary: {
              text: secondaryText,
              color: "textDisabled"
            },
            hideMenu: isFormReadOnly,
            userField: {
              [fieldListItemVal]: value
            }
          } as IListItemAPSA;
        }
      });
    }

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById
    };
  };

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      pageCtx.showDialog(
        <DialogNewFormVisibilityAction
          formStore={formStore}
          isFormReadOnly={isFormReadOnly}
          formId={formId}
          onClickOk={(newValue) => cbRefList.addItem({
            ...newValue,
            metaId: nextMetaIdVisibilityAction()
          })}
        />
      );
    }
  };

  const onClickList: CbOnClickFormList = (
    key: MetaIdField,
    action: FormClickVariant,
    value,
    menuAnchor,
    menuProps,
    isFirstItem,
    isLastItem,
    validationError) =>
  {
    if(action === "listItem")
    {
      const oldValue = value.userField
        ? value.userField[fieldListItemVal] as StudioVisibilityAction
        : undefined;

      oldValue && pageCtx.showDialog(
        <DialogNewFormVisibilityAction
          formStore={formStore}
          formId={formId}
          isFormReadOnly={isFormReadOnly}
          values={oldValue}
          onClickOk={(newValue) =>
            cbRefList.updateItem({
              ...newValue,
              metaId: oldValue.metaId
            }, key)}
          validationError={validationError}
        />
      );
    }
  };

  return (
    <FieldRawList
      isLastField={isLastField}
      fieldId={fieldId}
      fieldValue={fieldValue}
      cbRef={cbRefList}
      disableSpotMenu={isFormReadOnly}
      onChange={onChange}
      onClickList={onClickList}
      buttonStrip={
        <RawButtonStrip
          iconButtonList={["add"]}
          onClick={onClickIconBtn}
          iconButtonDisable={isFormReadOnly ? ["add"] : []}
        />
      }
      doLoad={doLoad}
    />
  );
}

function convertMapToArray(map?: StudioVisibilityActionMap): StudioVisibilityAction[]
{
  if(!map?.keys)
  {
    return [];
  }

  return map.keys.map((key) =>
  {
    return map.map[key];
  });
}

function convertArrayToMap(arr: StudioVisibilityAction[]): StudioVisibilityActionMap
{
  const keys = [] as MetaIdVisibilityAction[];
  const map = {} as Record<MetaIdVisibilityAction, StudioVisibilityAction>;

  arr.forEach((value) =>
  {
    const key = value.metaId;
    if(key)
    {
      keys.push(key);
      map[key] = value;
    }
  });

  return {
    keys: keys,
    map: map
  } as StudioVisibilityActionMap;
}
