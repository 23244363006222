import {MsgChatId} from "../../../../api/home/base/msg/MsgChatId";
import {WsocMain} from "../../../../api/home/main/WsocMain";
import {UserId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {listSetSelectedItemId} from "../../../../base/slices/list/SliceListSharedActions";
import {store} from "../../../../Store";
import {Srvc} from "../../../Srvc";

export default class SrvcHomeAsideUserInfoActions extends ISrvc
{
  wsocChatClear(entId: EntId, entUserId: UserId)
  {
    const msg = {
      chatId: entUserId
    } as MsgChatId;

    WsocMain.chatClear(entId, msg, Srvc.app.toast.showErrorToast);
  }

  wsocChatRemove(entId: EntId, entUserId: UserId)
  {
    const msg = {
      chatId: entUserId
    } as MsgChatId;

    WsocMain.chatRemove(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        const recentListName = Srvc.home.drawer.recentList.selectList(store.getState()).listName;
        dispatchList(recentListName, listSetSelectedItemId(undefined));
      }
    });
  }
}
