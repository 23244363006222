// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ISigAcceptor} from "./ISigAcceptor";
import {ISig} from "../../meta/base/sig/ISig";
import {IMsg} from "../../meta/base/msg/IMsg";
import {EntId, ServiceName} from "../../meta/base/Types";

export interface IWsocCall<S extends ISig>
{
  get(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>;

  post(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>;

  patch(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>;

  put(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>;

  delete(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IWsocCall<S>;
}

export interface IWsocCallFactory
{
  create<S extends ISig>(entId: EntId, serviceName: ServiceName, apiName: string): IWsocCall<S>;
}

let cbWsocCallFactory: IWsocCallFactory;

export function wsocCall<S extends ISig>(entId: EntId, serviceName: ServiceName, apiName: string)
{
  return cbWsocCallFactory.create<S>(entId, serviceName, apiName);
}

export function setWsocCallFactory(wsocCallFactory: IWsocCallFactory)
{
  cbWsocCallFactory = wsocCallFactory;
}