import {useTheme} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import {useRef} from "react";
import React from "react";
import {FieldValueSignature} from "../../../../api/meta/base/dto/FieldValueSignature";
import {px} from "../../../../base/plus/StringPlus";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import ImageViewDialog from "../../../atom/avatar/ImageViewDialog";
import RawImage from "../../../atom/raw/RawImage";
import {usePageCtx} from "../../../ctx/CtxPage";

export default function GridCellSignature(props: ICellRendererParams<IDataGridCell>)
{

  const pageCtx = usePageCtx();
  const theme = useTheme();
  const isDialogOpen = useRef<NodeJS.Timeout>();

  const vertInner = theme.common.vertInner;
  const sizeDivider = theme.common.sizeDivider;
  const rowHeight = props.node.rowHeight || 0;
  const height = rowHeight - vertInner - sizeDivider;
  const width = rowHeight - vertInner - sizeDivider;

  const colId = props.colDef?.colId;
  const value = (colId ? props.data?.valueMap[colId] : undefined) as FieldValueSignature | undefined;

  const onClickImage = (e: React.MouseEvent<HTMLElement>) =>
  {
    if(e.detail === 2)
    {
      clearTimeout(isDialogOpen.current);
      return;
    }
    isDialogOpen.current = setTimeout(() =>
    {
      if(value?.signature)
      {
        pageCtx.showDialog(
          <ImageViewDialog
            src={value?.signature}
            objectFit={"cover"}
            onClose={() =>
            {
              pageCtx.showDialog(undefined);
            }}
          />);
      }
    }, theme.common.durationTransition);
  };

  if(value?.signature)
  {
    return <RawImage
      src={value?.signature}
      width={px(height)}
      height={px(width)}
      objectFit={"fill"}
      onClick={onClickImage}
    />;
  }
  return null;
}

