import {SigTopic} from "../api/core/session/sig/SigTopic";
import {DtoGroupMemberKey} from "../api/home/base/dto/DtoGroupMemberKey";
import {DtoMessagePayload} from "../api/home/base/dto/DtoMessagePayload";
import {DtoMessagePayloadGroupAboutChange} from "../api/home/base/dto/DtoMessagePayloadGroupAboutChange";
import {DtoMessagePayloadGroupAvatarChange} from "../api/home/base/dto/DtoMessagePayloadGroupAvatarChange";
import {DtoMessagePayloadGroupCreate} from "../api/home/base/dto/DtoMessagePayloadGroupCreate";
import {DtoMessagePayloadGroupExit} from "../api/home/base/dto/DtoMessagePayloadGroupExit";
import {DtoMessagePayloadGroupJoinWithInvite} from "../api/home/base/dto/DtoMessagePayloadGroupJoinWithInvite";
import {DtoMessagePayloadGroupMemberAdd} from "../api/home/base/dto/DtoMessagePayloadGroupMemberAdd";
import {DtoMessagePayloadGroupMemberRemove} from "../api/home/base/dto/DtoMessagePayloadGroupMemberRemove";
import {DtoMessagePayloadGroupNameChange} from "../api/home/base/dto/DtoMessagePayloadGroupNameChange";
import {DtoMessagePayloadReport} from "../api/home/base/dto/DtoMessagePayloadReport";
import {DtoMessagePayloadSpreadsheetPartition} from "../api/home/base/dto/DtoMessagePayloadSpreadsheetPartition";
import {DtoMessagePayloadSpreadsheetRow} from "../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {MsgOffset} from "../api/home/main/msg/MsgOffset";
import {SigMessage} from "../api/home/main/sig/SigMessage";
import {SigSpreadsheetRow} from "../api/home/main/sig/SigSpreadsheetRow";
import {WsocMain} from "../api/home/main/WsocMain";
import {SigTopicMessageNew} from "../api/home/session/sig/SigTopicMessageNew";
import {SigTopicMessageProps} from "../api/home/session/sig/SigTopicMessageProps";
import {DefnForm} from "../api/meta/base/dto/DefnForm";
import {MessageId} from "../api/meta/base/Types";
import {SpreadsheetPartitionId} from "../api/meta/base/Types";
import {ChatId} from "../api/meta/base/Types";
import {EntId} from "../api/meta/base/Types";
import {selectCallerEnt} from "../cache/app/callerEnt/SrvcCacheCallerEnt";
import {isCommentableForm} from "../srvc/app/form/SrvcForm";
import {Srvc} from "../srvc/Srvc";
import {store} from "../Store";
import {RootState} from "../Store";
import ISrvc from "./ISrvc";
import {getMsgPayloadSpreadsheetPartitionId} from "./plus/BubblePlus";
import {getMsgPayloadSpreadsheetId} from "./plus/BubblePlus";
import {getMsgPayloadRowId} from "./plus/BubblePlus";
import {IBubbleMessage} from "./types/TypesBubble";
import {isMsgTypeCenterWithTargetMember} from "./types/TypesChat";
import {isMsgTypeCenter} from "./types/TypesChat";
import {toComboId} from "./types/TypesComboId";
import {TypeSubscriberId} from "./types/TypesGlobal";

export default abstract class ISrvcChat extends ISrvc
{
  isSrvcChat(): boolean
  {
    return true;
  }

  abstract handleSigTopicMessage(rootState: RootState, sig: SigTopic): void;

  abstract handleSigTopicMessageProps(rootState: RootState, sig: SigTopicMessageProps): void;

  abstract handleSigTopicMessageNew(rootState: RootState, sig: SigTopicMessageNew): void;

  abstract handleSigTopicFormResult(rootState: RootState, sig: SigTopic): void;

  abstract handleSigTopicFormComment(rootState: RootState, sig: SigTopic): void;

  abstract handleSigTopicMessageClearChat(rootState: RootState, sig: SigTopic): void;

  abstract handleSigTopicSpreadsheetRowDurationExpiry(rootState: RootState, sig: SigTopic): void;

  getDefnFormFromMsgPayload(rootState: RootState, entId: EntId, payload: DtoMessagePayload): DefnForm | undefined
  {
    const messageType = payload.messageType;
    const callerEnt = selectCallerEnt(rootState, entId);
    if(messageType === "spreadsheetRow")
    {
      const formId = (payload as DtoMessagePayloadSpreadsheetRow).formId;
      return callerEnt?.formMap[formId];
    }
    else if(messageType === "report")
    {
      const formId = (payload as DtoMessagePayloadReport).inputFormId;
      return formId
        ? callerEnt?.formMap[formId] :
        undefined;
    }
    else if(messageType === "spreadsheetPartition")
    {
      const formId = (payload as DtoMessagePayloadSpreadsheetPartition).formId;
      return callerEnt?.formMap[formId];
    }
  }

  //region protected
  protected subscribeBubbleMessage(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    _chatId: ChatId,
    sig: IBubbleMessage,
    unSubscribe?: boolean)
  {
    const rootState = store.getState();
    const payload: DtoMessagePayload | undefined = sig.payload;
    const messageType = payload.messageType;
    const messageId = sig.messageId;
    const defnForm = sig.defnForm || this.getDefnFormFromMsgPayload(rootState, entId, payload);
    Srvc.app.pubsub.msg.message(subscriberId, entId, messageId, unSubscribe);

    if(payload)
    {
      const spreadsheetId = getMsgPayloadSpreadsheetId(payload);
      const sigSpreadsheetRow = sig.sigSpreadsheetRow;
      const isExpired = sig.sigSpreadsheetRowExpiry?.remainingInvisibleTimeMillis === 0;
      const hasExpiry = Boolean(sig.sigSpreadsheetRowExpiry !== undefined
        || (spreadsheetId
          && selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.canExpire));

      if(messageType === "spreadsheetRow")
      {
        this.subscribeSpreadsheetRow(rootState,
          subscriberId,
          entId,
          payload as DtoMessagePayloadSpreadsheetRow,
          hasExpiry,
          isExpired,
          messageId,
          sigSpreadsheetRow,
          defnForm,
          unSubscribe
        );
      }
      else if(messageType === "spreadsheetPartition")
      {
        this.subscribeSpreadsheetRow(rootState,
          subscriberId,
          entId,
          payload as DtoMessagePayloadSpreadsheetPartition,
          hasExpiry,
          isExpired,
          messageId,
          sigSpreadsheetRow,
          defnForm,
          unSubscribe
        );
      }
      else if(messageType === "report")
      {
        const formValueRaw = (payload as DtoMessagePayloadReport).formValueRaw;
        const rowId = (payload as DtoMessagePayloadReport).rowId;
        const inputFormId = (payload as DtoMessagePayloadReport).inputFormId;
        if(inputFormId && rowId)
        {
          const canComment = isCommentableForm(rootState, entId, inputFormId, formValueRaw?.createdBy);
          if(canComment)
          {
            Srvc.app.pubsub.msg.formComment(subscriberId, entId, rowId, unSubscribe);
            this.handleSigTopicFormComment(rootState, {
              type: "formComment",
              aboutId: rowId,
              artifactId: entId
            });
          }
        }
        const valueMap = formValueRaw?.valueMap;
        if(defnForm && valueMap)
        {
          Srvc.app.form.formViewer.subscribeFieldEntUser(subscriberId, entId, defnForm, valueMap, unSubscribe);
        }
      }
      else if(isMsgTypeCenter(messageType))
      {
        const entUserId = this.getInitiatorMemberEntUser(payload)?.entUserId;
        if(entUserId)
        {
          Srvc.app.pubsub.homeAvatar(subscriberId, toComboId(entId, entUserId), unSubscribe);// for bubble header
        }
        if(isMsgTypeCenterWithTargetMember(messageType))
        {
          const entUserId = this.getTargetMemberEntUser(payload)?.entUserId;
          if(entUserId)
          {
            Srvc.app.pubsub.homeAvatar(subscriberId, toComboId(entId, entUserId), unSubscribe);// for bubble header
          }
        }
      }
    }
  }

  protected getInitiatorMemberEntUser(payload: DtoMessagePayload): DtoGroupMemberKey | undefined
  {
    switch(payload.messageType)
    {
      case "groupAboutChange":
        return (payload as DtoMessagePayloadGroupAboutChange).initiatorMember;
      case "groupAvatarChange":
        return (payload as DtoMessagePayloadGroupAvatarChange).initiatorMember;
      case "groupCreate":
        return (payload as DtoMessagePayloadGroupCreate).initiatorMember;
      case "groupExit":
        return (payload as DtoMessagePayloadGroupExit).initiatorMember;
      case "groupJoinWithInvite":
        return (payload as DtoMessagePayloadGroupJoinWithInvite).initiatorMember;
      case "groupMemberAdd":
        return (payload as DtoMessagePayloadGroupMemberAdd).initiatorMember;
      case "groupMemberRemove":
        return (payload as DtoMessagePayloadGroupMemberRemove).initiatorMember;
      case "groupNameChange":
        return (payload as DtoMessagePayloadGroupNameChange).initiatorMember;
    }
  }

  protected getTargetMemberEntUser(payload: DtoMessagePayload): DtoGroupMemberKey | undefined
  {
    switch(payload.messageType)
    {
      case "groupMemberAdd":
        return (payload as DtoMessagePayloadGroupMemberAdd).targetMember;
      case "groupMemberRemove":
        return (payload as DtoMessagePayloadGroupMemberRemove).targetMember;
    }
  }

  protected wsocMessageGet(entId: EntId, chatId: ChatId, offset: number, cbSuccess?: (sigMessage?: SigMessage) => void)
  {
    const msg = {
      chatId: chatId,
      offset: offset
    } as MsgOffset;

    WsocMain.messageGet(entId, msg, (envSig) =>
    {
      const sig = envSig?.sig;
      cbSuccess && cbSuccess(sig);
    });
  }

  //endregion

  //region private

  private subscribeSpreadsheetRow(
    rootState: RootState,
    subscriberId: TypeSubscriberId,
    entId: EntId,
    payload: DtoMessagePayload,
    hasExpiry: boolean,
    isExpired: boolean,
    messageId: MessageId,
    spreadsheetRow?: SigSpreadsheetRow,
    defnForm?: DefnForm,
    unSubscribe?: boolean
  )
  {
    const valueMap = spreadsheetRow?.formValue?.valueMap;
    const rowId = getMsgPayloadRowId(payload);
    const spreadsheetPartitionId = getMsgPayloadSpreadsheetPartitionId(payload);

    if(rowId)
    {
      Srvc.app.pubsub.msg.formResult(subscriberId, entId, rowId, false, unSubscribe);
      const canComment = isCommentableForm(rootState, entId, defnForm?.metaId, spreadsheetRow?.formValue?.createdBy);
      if(canComment)
      {
        Srvc.app.pubsub.msg.formComment(subscriberId, entId, rowId, unSubscribe);
      }
    }

    if(hasExpiry && spreadsheetPartitionId)
    {
      const expirySubscriberId = toComboId(subscriberId, messageId); // expirySubscriberId is unique per MsgId
      if(!unSubscribe && !isExpired)
      {
        this.subscribeSpreadsheetRowExpiry(rootState, expirySubscriberId, entId, spreadsheetPartitionId, unSubscribe);
      }
    }

    if(defnForm && valueMap)
    {
      Srvc.app.form.formViewer.subscribeFieldEntUser(subscriberId, entId, defnForm, valueMap, unSubscribe);
    }
  }

  private subscribeSpreadsheetRowExpiry(
    rootState: RootState,
    subscriberId: TypeSubscriberId,
    entId: EntId,
    spreadsheetPartitionId: SpreadsheetPartitionId,
    unSubscribe?: boolean
  )
  {
    if(spreadsheetPartitionId && entId)
    {
      if(!unSubscribe)
      {
        Srvc.getSrvcChatArray()
        .forEach(srvc => srvc.handleSigTopicSpreadsheetRowDurationExpiry(rootState, {
          type: "spreadsheetRowExpiry",
          aboutId: spreadsheetPartitionId,
          artifactId: entId
        }));
      }

      Srvc.cache.app.spreadsheet.ssRow
      .setCacheSpreadsheetRowExpiry(entId as EntId, spreadsheetPartitionId as SpreadsheetPartitionId);

      Srvc.app.pubsub.msg.spreadsheetRowExpiry(
        subscriberId,
        entId,
        spreadsheetPartitionId,
        true,
        unSubscribe
      );
    }
  }

  //endregion

}
