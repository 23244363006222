// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

export const DefnAdminDoNotEditOptionEnt = [
  "about",
  "translations",
  "variables",
  "actions",
  "admins",
  "automations",
  "deeplinks",
  "deploy",
  "manage",
  "payment",
  "deployPlugins",
  "deployVariables",
  "driveSheets",
  "formEditContentLayout",
  "formEditDetails",
  "formEditFormLayouts",
  "formEditFormulas",
  "formEditPermissions",
  "formEditVisibility",
  "forms",
  "groups",
  "plugins",
  "prompts",
  "reports",
  "roles",
  "spreadsheets",
  "users",
  "virtualAssistant",
  "adminModules"
  ];

export const DefnAdminDoNotShowOptionEnt = [
  "about",
  "translations",
  "variables",
  "actions",
  "admins",
  "automations",
  "dashboard",
  "deeplinks",
  "deploy",
  "manage",
  "payment",
  "deployPlugins",
  "deployVariables",
  "driveSheets",
  "formEditContentLayout",
  "formEditDetails",
  "formEditFormLayouts",
  "formEditFormulas",
  "formEditPermissions",
  "formEditVisibility",
  "forms",
  "governance",
  "groups",
  "plugins",
  "prompts",
  "reports",
  "roles",
  "spreadsheets",
  "users",
  "virtualAssistant",
  "adminModules"
  ];