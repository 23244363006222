import {ChartDataset} from "chart.js";
import {ChartOptions} from "chart.js";
import {Legend} from "chart.js";
import {Tooltip} from "chart.js";
import {Title} from "chart.js";
import {LineElement} from "chart.js";
import {PointElement} from "chart.js";
import {LinearScale} from "chart.js";
import {CategoryScale} from "chart.js";
import {Chart as ChartJS} from "chart.js";
import {useMemo} from "react";
import {Doughnut} from "react-chartjs-2";
import {IChart} from "../../../base/types/TypeCharts";
import useXYChart from "./ChartPlus";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,
  Title, Tooltip, Legend
);

export default function ChartDoughnut(props: IChart)
{
  const {
    getOptions,
    labels,
    getDataPieAndDoughnut
  } = useXYChart(props);

  const dataSet = useMemo(() => getDataPieAndDoughnut(), [getDataPieAndDoughnut]);
  const layout = props.layout;

  return <Doughnut
    options={getOptions(props.allowAnimation, layout) as ChartOptions<"doughnut">}
    data={{
      labels: labels,
      datasets: dataSet as ChartDataset<"doughnut">[]
    }}
  />;
}

