import {ArtifactId} from "../../../api/meta/base/Types";
import {MsgStudioSearchUsages} from "../../../api/studio/studioMain/msg/MsgStudioSearchUsages";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigStudioSearchUsages} from "../../../api/studio/studioMain/sig/SigStudioSearchUsages";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {store} from "../../../Store";

export default class SrvcStudioDrawerFindUsages extends ISrvc
{
  constructor(readonly selectList: SelectList)
  {
    super();
  }

  rpcFindUsages(artifactId: ArtifactId, metaId: string)
  {
    const msg: MsgStudioSearchUsages = {
      metaId: metaId
    };

    RpcStudioMain.studioFindUsages(artifactId, msg, envSig =>
    {
      const findUsages = envSig.sig;
      if(findUsages)
      {
        this.doLoad(findUsages);
      }
    });
  }

  private doLoad(sig: SigStudioSearchUsages)
  {
    const studioFindUsagesList = this.selectList(store.getState());
    const listName = studioFindUsagesList.listName;

    const {
      uiItemsById,
      uiItemIds
    } = this.getListIds(sig);

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  private getListIds(sig: SigStudioSearchUsages)
  {
    const uiItemsById = {} as IListItemsById;
    const uiItemIds = [] as TypeListItemId[];

    const findUsageMap = sig.artifactSearchResult?.searchResult;
    const neatPathMap = sig.artifactSearchResult?.neatPathMap;

    const artifactSearchResult = Object.entries(findUsageMap?.result ?? {});

    if(artifactSearchResult && neatPathMap)
    {
      artifactSearchResult.forEach(([searchPath]) =>
      {
        const artifactItemIds = [] as TypeListItemId[];

        const neatPath = neatPathMap[searchPath];
        const secondaryText = neatPath.secondary
          ? `Property : ${neatPath.secondary}`
          : undefined;

        artifactItemIds.push(searchPath);

        uiItemsById[searchPath] = {
          type: "ps",
          primary: {
            text: neatPath.primary?.join(" > "),
            caption: {
              text: neatPath.caption,
              type: "text",
              ignoreSelection: true
            },
            showTooltip: true
          },
          secondary: {
            text: secondaryText
          },
          hideMenu: true,
          userField: {
            artifactId: searchPath,
            searchPath: searchPath
          }
        } as IListItemAPSA;

        uiItemIds.push(searchPath);
      });
    }

    return {
      uiItemsById,
      uiItemIds
    };
  }
}
