import React from "react";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import FieldPickLanguage from "./FieldPickLanguage";

export default function FieldSetOfLanguage(props: {
  defn: DefnFieldEditable
})
{
  return (
    <FieldPickLanguage
      defn={props.defn}
      multiSelect={true}
    />
  );
}
