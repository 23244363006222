import {isEqual} from "lodash";
import {useState} from "react";
import {useRef} from "react";
import React, {useCallback, useEffect, useMemo} from "react";
import {FieldValues} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldInfo} from "../../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildActionPermission} from "../../../../api/meta/base/dto/DefnStudioBuildActionPermission";
import {DefnStudioMapOfLayoutGrid} from "../../../../api/meta/base/dto/DefnStudioMapOfLayoutGrid";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutGridId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioMapOfLayoutGrid} from "../../../../api/meta/base/dto/StudioMapOfLayoutGrid";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {MetaIdAction} from "../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {ArtifactId, MetaIdField, MetaIdForm} from "../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getValidFormMenuActionIdSet} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {IAsidePropsStudioFormComposite} from "../../../../base/types/TypesAside";
import {FormClickVariant, IFormRef} from "../../../../base/types/TypesForm";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import {fieldMenuActionPermission} from "../../../../routes/studio/ent/forms/UiStudioEntForm";
import {isValidVariable} from "../../../../routes/studio/ent/variables/UiStudioEntVariables";
import {FORMS_GRID_INDEX_FIELD_NAME_VARIABLE} from "../../../atom/assets/HelperTextStudio";
import {FORMS_GRID_SHOW_ALL_ROWS} from "../../../atom/assets/HelperTextStudio";
import {DividerHorizontal} from "../../../atom/layout/DividerHorizontal";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import PaneFooter from "../../../atom/pane/PaneFooter";
import {IPaneOverlayStackRef} from "../../../atom/pane/PaneOverlayStack";
import PaneOverlayStack from "../../../atom/pane/PaneOverlayStack";
import PaneSide from "../../../atom/pane/PaneSide";
import {useAppCtx} from "../../../ctx/CtxApp";
import {useStepStudioAsideDefaultConfig} from "../../base/FormHooks";
import Form from "../../viewer/Form";
import {getCompPermissionMatrix, permissionMatrixDtoToValue, permissionMatrixValueToDto} from "../base/FormBuilderPlus";
import useCopyPasteStudioFormItem from "../base/StudioFormCopyPaste";
import {fieldGap2} from "../base/TypesFormBuilder";
import {getStudioDetailsToDto} from "../base/TypesFormBuilder";
import {getStudioDetailsToValue} from "../base/TypesFormBuilder";
import {fieldIndexFieldNameVarId} from "../base/TypesFormBuilder";
import {
  ASIDE_FOOTER_BUTTON_KIND,
  fieldAsideDefaultLayout,
  fieldDeeplinkDefaultLayoutFlexGrow,
  fieldGap1,
  fieldKeyLabel,
  fieldKeyName,
  fieldMapOfLayoutGrid,
  fieldTabDetails,
  fieldTabPermissions,
  getFieldGap,
  getStudioDetailsCompMap
} from "../base/TypesFormBuilder";

export default function FormBuilderEditGrid(props: {
  artifactId: ArtifactId,
  onClickClose: () => void,
  isPluginForm?: boolean
})
{
  const closeAside = props.onClickClose;
  const isPluginForm = props.isPluginForm;

  const appCtx = useAppCtx();
  const cbRef = useMemo(() => ({} as IFormRef), []);
  const cbOverlayRef = useMemo(() => ({} as IPaneOverlayStackRef), []);

  const {
    copyStudioComposite,
    pasteStudioComposite
  } = useCopyPasteStudioFormItem();

  const asideProps = appCtx.getAsideProps() as IAsidePropsStudioFormComposite;
  const formStore = asideProps.formStore;
  const selectListMap = asideProps.selectListMap;
  const formMap = formStore.formMap;
  const formId = asideProps.formId;
  const gridId = asideProps.metaId;
  const readOnly = asideProps.readOnly;
  const cbOnAddNewVar = asideProps.cbOnAddNewVar;
  const cbOnShowVar = asideProps.cbOnShowVar;
  const cbOnEditVar = asideProps.cbOnEditVar;
  const reset = asideProps.reset;
  const validationResult = formStore.validationResult;

  const [copiedGrid, setCopiedGrid] = useState<StudioGrid>();

  const form = useMemo(() => formId
    ? formMap?.map[formId]
    : undefined, [formMap?.map[formId], formId]);

  const includeActionIdSet = useMemo(() => formStore
    ? getValidFormMenuActionIdSet(formStore, form?.metaId)
    : undefined, [form?.metaId, formStore]);

  const grid = useMemo(() =>
  {
    if(!form || !gridId)
    {
      return undefined;
    }

    return form.compositeMap.map[gridId] as StudioGrid;
  }, [gridId, form?.compositeMap.map]);

  const refGrid = useRef<StudioGrid | undefined>(grid);
  const asidePropsRef = useRef<IAsidePropsStudioFormComposite | undefined>(asideProps);

  const [labelHelperTextName, setLabelHelperTextName] = useState<string | undefined>();
  const [showLabelHelperTextName, setShowLabelHelperTextName] = useState<boolean>();
  const [enableShowBorder, setEnableShowBorder] = useState<boolean>(
    Boolean(grid?.layoutGridMap?.keys
      && grid.layoutGridMap.keys.length > 0));

  // region set, reset and submit
  const dtoToValues = (dto: StudioGrid): FieldValues =>
  {
    return {
      ...getStudioDetailsToValue(dto.details),
      [fieldMinRowVariable]: fnRawValueToFieldValue("pickVarId", dto.minRowsVarId),
      [fieldMaxRowVariable]: fnRawValueToFieldValue("pickVarId", dto.maxRowsVarId),
      [fieldShowAllRowsFieldId]: fnRawValueToFieldValue("pickFieldId", dto.showAllRowsFieldId),
      [fieldMapOfLayoutGrid]: dto.layoutGridMap,
      [fieldShowBorder]: dto.layoutGridMap?.showBorderSet,
      [fieldAsideDefaultLayout]: dto.layoutGridMap?.asideDefaultLayoutId,
      [fieldIndexFieldNameVarId]: fnRawValueToFieldValue("pickVarId", dto.indexFieldNameVarId),
      [fieldMenuActionPermission]: fnRawValueToFieldValue("studioBuildActionPermission", dto.actionPermissionMap),
      [fieldGridRowActionPermission]: dto.rowActionPermissionMap,
      ...permissionMatrixDtoToValue(dto.permissionMatrix)
    };
  };

  const setValues = useCallback((grid: StudioGrid) =>
  {
    cbRef.remoteReset(dtoToValues(grid));
  }, [cbRef]);

  const resetValues = useCallback(() =>
  {
    cbRef.checkSubmit();
    setCopiedGrid(undefined);
  }, [cbRef]);

  const cbOnSubmit = useCallback((values: FieldValues) =>
  {
    if(grid)
    {
      const layoutGridMap = values[fieldMapOfLayoutGrid]
        ? {
          ...values[fieldMapOfLayoutGrid],
          asideDefaultLayoutId: values[fieldAsideDefaultLayout],
          showBorderSet: values[fieldShowBorder]
        } as StudioMapOfLayoutGrid
        : undefined;

      const _grid = copiedGrid || form?.compositeMap.map[refGrid.current?.metaId as MetaIdGrid];

      const msg = {
        ..._grid,
        details: getStudioDetailsToDto(values, _grid?.details),
        minRowsVarId: fnFieldValueToRawValue("pickVarId", values[fieldMinRowVariable]),
        type: "grid",
        indexFieldNameVarId: fnFieldValueToRawValue("pickVarId", values[fieldIndexFieldNameVarId]),
        actionPermissionMap: fnFieldValueToRawValue("studioBuildActionPermission", values[fieldMenuActionPermission]),
        rowActionPermissionMap: values[fieldGridRowActionPermission],
        maxRowsVarId: fnFieldValueToRawValue("pickVarId", values[fieldMaxRowVariable]),
        showAllRowsFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldShowAllRowsFieldId]),
        layoutGridMap: layoutGridMap,
        permissionMatrix: {
          ...permissionMatrixValueToDto(values)
        }
      } as StudioGrid;

      asidePropsRef.current?.cbOnCompositeSubmit &&
      asidePropsRef.current?.cbOnCompositeSubmit(msg);
      resetValues();
    }
  }, [grid, copiedGrid]);

  // endregion

  // region copy & paste

  const onClickIconStrip = useCallback((iconStrip: EnumIconStrip) =>
  {
    if(iconStrip === "copy")
    {
      copyStudioComposite(grid);
    }
    else if(iconStrip === "paste")
    {
      pasteStudioComposite((studioComp) =>
      {
        setValues(studioComp as StudioGrid);
        setCopiedGrid({
          ...studioComp,
          metaId: refGrid.current?.metaId as MetaIdGrid
        });

      }, "grid");
    }
  }, [grid, pasteStudioComposite, setValues]);

  // endregion

  const cbOnClickFormItem = useCallback((metaIdField: MetaIdField, action: FormClickVariant, value?: any) =>
  {
    if(action === "varAddNew")
    {
      if(value && isValidVariable(value))
      {
        cbOnAddNewVar &&
        cbOnAddNewVar(value);
      }
    }
    else if(action === "varShow" && cbOnShowVar)
    {
      cbOnShowVar(value as MetaIdVar);
    }
    else if(action === "varEdit" && value && cbOnEditVar)
    {
      cbOverlayRef.showOverlay(cbOnEditVar(value as StudioVar, cbOverlayRef.closeOverlay));
    }
  }, [cbOnShowVar, cbOnEditVar, cbOnAddNewVar, cbOverlayRef, cbRef]);

  const onWatch = useCallback((key: MetaIdField, value?: any) =>
  {
    if(key === fieldKeyLabel)
    {
      if(fnFieldValueToRawValue("text", value))
      {
        setShowLabelHelperTextName(false);
      }
      else
      {
        setShowLabelHelperTextName(true);
      }
    }
    if(key === fieldKeyName && fnFieldValueToRawValue("symbol", value))
    {
      setLabelHelperTextName(toLabel(fnFieldValueToRawValue("symbol", value) as string));
    }
    if(key === fieldMapOfLayoutGrid && !readOnly)
    {
      if(cbRef.isFieldDirty(fieldMapOfLayoutGrid))
      {
        if(!value || !(value as StudioMapOfLayoutGrid).keys.includes(cbRef.getValue(fieldAsideDefaultLayout)))
        {
          cbRef.setValue(fieldAsideDefaultLayout, null);
        }
        if(!isEqual(grid?.layoutGridMap, value))
        {
          cbOnSubmit(cbRef.getValues());
        }
        if(!value)
        {
          cbRef.setValue(fieldShowBorder, null);
          setEnableShowBorder(false);
        }
        else
        {
          setEnableShowBorder(true);
        }
      }
    }
  }, [cbRef, cbOnSubmit]);

  useEffect(() =>
  {
    if(!reset)
    {
      refGrid.current = grid;
      if(grid)
      {
        setValues(grid);
        Object.entries(dtoToValues(grid)).forEach(([key, value]) =>
        {
          onWatch(key, value);
        });
      }
    }
  }, [gridId, reset]);

  useEffect(() =>
  {
    setLabelHelperTextName(refGrid.current?.details.name);
    setShowLabelHelperTextName(!refGrid.current?.details?.label);

  }, [refGrid.current]);

  useStepStudioAsideDefaultConfig("compositeMap", cbRef, grid, validationResult);

  return (
    <PaneOverlayStack
      width={appCtx.getAsideWidth()}
      cbRef={cbOverlayRef}
    >
      <PaneSide
        primaryText={"Update grid"}
        iconStrip={["copy", "paste"]}
        iconStripDisable={readOnly ? ["paste"] : []}
        toolTipMap={{
          paste: "Paste grid",
          copy: "Copy grid"
        } as Record<EnumIconStrip, string>}
        onClickIconStrip={onClickIconStrip}
        onClickAction={closeAside}
        height={"100%"}
      >
        <LayoutFlexRow
          flexGrow={1}
          height={0}
          overflowY={"auto"}
          overflowX={"auto"}
          bgcolor={theme.common.bgcolorContent}
        >
          <Form
            store={formStore}
            cbRef={cbRef}
            defnForm={getDefn(isPluginForm,
              formId,
              gridId,
              includeActionIdSet,
              labelHelperTextName,
              showLabelHelperTextName,
              enableShowBorder
            )}
            onSubmit={cbOnSubmit}
            initValues={grid ? dtoToValues(grid) : undefined}
            formReadonly={readOnly}
            selectListMap={selectListMap}
            onClickFormItem={cbOnClickFormItem}
            onWatch={onWatch}
          />
        </LayoutFlexRow>

        <DividerHorizontal />

        <PaneFooter
          footerButtonSet={[{icon: "apply"}]}
          onIconButtonClick={(btnName) =>
          {
            if(btnName === ASIDE_FOOTER_BUTTON_KIND)
            {
              closeAside();
            }
          }}
        />
      </PaneSide>
    </PaneOverlayStack>
  );
}

//Tab detail property
const fieldTabGridLayout = "fieldTabLayout";
const fieldMinRowVariable = "minRowsVarId";
const fieldMaxRowVariable = "maxRowsVarId";
const fieldShowAllRowsFieldId = "showAllRowsFieldId";

const fieldTabMenu = "fieldTabMenu";
const fieldTabMenuGrid = "fieldTabMenuGrid";
const fieldTabMenuRow = "fieldTabMenuRow";
const fieldShowBorder = "`showBorderSet`";

export const fieldGridRowActionPermission = "rowActionPermissionMap";

function getDefn(
  isPluginForm?: boolean,
  formId?: MetaIdForm,
  gridId?: MetaIdGrid,
  includeActionIdSet?: MetaIdAction[],
  labelHelperTextName?: string,
  showLabelHelperTextName?: boolean,
  enableShowBorder?: boolean)
{
  const fieldDefaultProperties = getStudioDetailsCompMap(labelHelperTextName, showLabelHelperTextName);
  const fieldDetailProperties = getFieldDetailProperties(formId);

  return createDefaultDefnFormStudio({
    ...fieldDefaultProperties,

    ...getFieldGap(fieldGap1, "thick"),

    ...fieldDetailProperties,

    ...getCompPermissionMatrix(fieldTabPermissions),

    ...getFieldGap(fieldGap2, "thick"),

    [fieldShowAllRowsFieldId]: {
      type: "pickFieldId",
      metaId: fieldShowAllRowsFieldId,
      name: fieldShowAllRowsFieldId,
      label: "Show all rows",
      formId: formId,
      compositeIdSet: gridId
        ? [gridId]
        : [],
      filterFieldTypeSet: ["pickText"],
      helperTextVar: stringToDefnDtoText(FORMS_GRID_SHOW_ALL_ROWS)
    } as DefnStudioPickFieldId,

    [fieldTabDetails]: {
      type: "section",
      metaId: fieldTabDetails,
      label: "Details",
      fieldIdSet: [
        ...Object.keys(fieldDefaultProperties),
        fieldGap1,
        ...Object.keys(fieldDetailProperties),
        fieldGap2,
        fieldShowAllRowsFieldId
      ]
    } as DefnSection,

    [fieldMapOfLayoutGrid]: {
      type: "studioMapOfLayoutGrid",
      metaId: fieldMapOfLayoutGrid,
      formId: formId,
      gridId: gridId,
      isPluginForm: isPluginForm
    } as DefnStudioMapOfLayoutGrid,

    [fieldDeeplinkDefaultLayoutFlexGrow]: {
      type: "info",
      metaId: fieldDeeplinkDefaultLayoutFlexGrow,
      name: "",
      label: "",
      flexGrow: true
    } as DefnFieldInfo,

    ...getFieldGap(fieldGap2, "thick"),

    [fieldShowBorder]: {
      type: "studioSetOfBorder",
      name: fieldShowBorder,
      metaId: fieldShowBorder,
      label: "Show border",
      disabled: !enableShowBorder
    } as DefnFieldPickText,

    [fieldIndexFieldNameVarId]: {
      type: "studioVarIdTextEditor",
      metaId: fieldIndexFieldNameVarId,
      name: fieldIndexFieldNameVarId,
      label: "Index field name variable",
      showAsEdit: true,
      filterVarKindSet: ["text"],
      argBinderFormId: formId,
      helperTextVar: stringToDefnDtoText(FORMS_GRID_INDEX_FIELD_NAME_VARIABLE)
    } as DefnStudioVarIdTextEditor,

    [fieldAsideDefaultLayout]: {
      type: "pickLayoutGridId",
      metaId: fieldAsideDefaultLayout,
      name: "Aside default layout",
      formId: formId,
      gridId: gridId
    } as DefnStudioPickLayoutGridId,

    [fieldTabGridLayout]: {
      type: "section",
      name: fieldTabGridLayout,
      metaId: fieldTabGridLayout,
      label: "Layout",
      fieldIdSet: [
        fieldMapOfLayoutGrid,
        fieldDeeplinkDefaultLayoutFlexGrow,
        fieldGap2,
        fieldShowBorder,
        fieldIndexFieldNameVarId,
        fieldAsideDefaultLayout
      ]
    } as DefnSection,

    [fieldMenuActionPermission]: {
      type: "studioBuildActionPermission",
      metaId: fieldMenuActionPermission,
      name: fieldMenuActionPermission,
      includeActionIdSet: includeActionIdSet,
      allowGrouping: true,
      allowShowMessageTooltip: true
    } as DefnStudioBuildActionPermission,

    [fieldGridRowActionPermission]: {
      type: "studioBuildActionPermission",
      metaId: fieldGridRowActionPermission,
      name: fieldGridRowActionPermission,
      includeActionIdSet: includeActionIdSet,
      allowGrouping: true,
      allowShowMessageTooltip: true
    } as DefnStudioBuildActionPermission,

    [fieldTabMenuGrid]: {
      type: "section",
      name: fieldTabMenuGrid,
      metaId: fieldTabMenuGrid,
      label: "Grid",
      fieldIdSet: [fieldMenuActionPermission]
    } as DefnSection,

    [fieldTabMenuRow]: {
      type: "section",
      name: fieldTabMenuRow,
      metaId: fieldTabMenuRow,
      label: "Row",
      fieldIdSet: [fieldGridRowActionPermission]
    } as DefnSection,

    [fieldTabMenu]: {
      type: "tab",
      name: fieldTabMenu,
      metaId: fieldTabMenu,
      label: "Menu",
      tabIdSet: [fieldTabMenuGrid, fieldTabMenuRow]
    } as DefnTab,

    [defaultSectionKey]: {
      type: "tab",
      tabIdSet: [
        fieldTabDetails,
        fieldTabGridLayout,
        ...!isPluginForm
          ? [fieldTabMenu]
          : [],
        ...!isPluginForm
          ? [fieldTabPermissions]
          : []
      ],
      metaId: defaultSectionKey
    } as DefnTab

  } as Record<MetaIdField, DefnField>);
}

function getFieldDetailProperties(formId?: MetaIdForm)
{
  return {
    [fieldMinRowVariable]: {
      type: "pickVarId",
      metaId: fieldMinRowVariable,
      name: fieldMinRowVariable,
      label: "Min row variable",
      varKind: "number",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,
    [fieldMaxRowVariable]: {
      type: "pickVarId",
      metaId: fieldMaxRowVariable,
      name: fieldMaxRowVariable,
      label: "Max row variable",
      varKind: "number",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId
  };
}

