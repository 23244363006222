import {IconButton} from "@mui/material";
import {Box} from "@mui/material";
import React from "react";
import {DefnDtoColor} from "../../../../api/meta/base/dto/DefnDtoColor";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import IconStrip from "../../../atom/icon/IconStrip";

interface IFieldRawInfo
{
  children: React.ReactNode
  showBorder?: boolean,
  lineCount?: number,
  bgColorVarId?: DefnDtoColor,
  flexGrow?: number,
  showCloseButton?: boolean,
  onClickClose?: () => void
}

export default function FieldRawInfo(props: IFieldRawInfo)
{
  const {
    showBorder,
    lineCount,
    bgColorVarId,
    flexGrow,
    showCloseButton,
    onClickClose,
    children
  } = props;

  const lineHeight = 1.2;
  const boxHeight = lineCount
    ? `${lineCount * lineHeight}em`
    : undefined;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        border: showBorder ? `1px solid ${theme.common.borderColor}` : "none",
        borderRadius: theme.common.bubblePollBorderRadius,
        paddingRight: showCloseButton ? px(gapStd + gapQuarter) : px(gapQuarter),
        paddingTop: showCloseButton ? px(gapHalf) : undefined,
        paddingLeft: px(gapQuarter),
        paddingBottom: px(gapQuarter),
        backgroundColor: (bgColorVarId && bgColorVarId.value)
          ? theme.common.colorWithShade(bgColorVarId.value, bgColorVarId.shade)
          : undefined,
        ...(lineCount && !flexGrow) && {
          height: boxHeight,
          overflowY: lineCount ? "hidden" : undefined,
          justifyContent: "flex-end"
        }
      }}
    >
      {
        children
      }
      {showCloseButton && <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0
        }}
      >
        <IconButton
          sx={{
            width: "22px",
            height: "22px",
            color: theme.common.fgcolorIcon
          }}
          onClick={onClickClose}
        >
          <IconStrip value={"close"} />
        </IconButton>
      </Box>}
    </Box>
  );
}
