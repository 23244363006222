import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {IconContainerProps} from "@mui/material";
import {Box} from "@mui/material";
import {Stack} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {Rating} from "@mui/material";
import {SyntheticEvent} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldRating} from "../../../../api/meta/base/dto/DefnFieldRating";
import {FieldValueDecimal} from "../../../../api/meta/base/dto/FieldValueDecimal";
import {FieldValueNumber} from "../../../../api/meta/base/dto/FieldValueNumber";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import IFormCtx from "../base/CtxForm";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldRating(props: {
  defn: DefnFieldRating
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const {
    getFieldHelperText,
    getFieldRequired
  } = useFieldPropertiesResolver(defn);
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const label = getCompLabel(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const hideLabel = defn.hideLabel;

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const isPropertyEditor = formSection.sectionVariant === "propertyEditor";
  const required = getFieldRequired();
  const helperText = getFieldHelperText();

  const defnLabel = {
    label: Boolean(required) ? label + " *" : label
  } as DefnFieldLabel;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;

        const isError = Boolean(error);
        const fieldValue = (field.value as FieldValueDecimal)?.value;
        const onChange = (event: SyntheticEvent, value: number | null) =>
        {
          if(value === null)
          {
            field.onChange(null);
          }
          else
          {
            field.onChange({value: value} as FieldValueDecimal);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <Stack
                direction={"row"}
                width={!hideLabel ? "100%" : "0%"}
                height={"100%"}
                justifyContent={"space-between"}
              >
                {
                  (!hideLabel && !isPropertyEditor) &&
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: px(gapStd),
                      minWidth: "30%",
                      WebkitFlexBasis: "auto"
                    }}
                    onClick={onClick}
                  >
                    <FieldLabel defn={defnLabel} />
                  </Box>
                }
                <LayoutFlexRow
                  flexGrow={1}
                >
                  <RawRating
                    fieldId={fieldId}
                    field={field}
                    onChange={onChange}
                  />
                </LayoutFlexRow>
              </Stack>

              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }

            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );

  function RawRating(props: {
    fieldId: string,
    field: ControllerRenderProps,
    onChange: (event: SyntheticEvent, value: number | null) => void
  })
  {
    const {
      fieldId,
      field,
      onChange
    } = props;
    return (
      <LayoutFlexRow
        overflowX={"visible"}
        overflowY={"visible"}
        flexGrow={1}
        justifyContent={"flex-start"}
        alignItems={"start"}
        width={"100%"}
        pl={0}
      >
        <RatingKinds
          field={field}
          onChange={onChange}
          formCtx={formCtx}
          fieldId={fieldId}
          defn={defn}
        />
        <FieldRawRefButton
          defn={defn}
        />
      </LayoutFlexRow>
    );
  }
}

function RatingKinds(props: {
  fieldId: string;
  field: ControllerRenderProps;
  formCtx: IFormCtx;
  defn: DefnFieldRating;
  onChange?: (event: React.SyntheticEvent, value: (number | null)) => any,
})
{
  const {
    field,
    fieldId,
    formCtx,
    defn,
    onChange
  } = props;

  const iconKind = defn.ratingKind ?? defn.ratingKindVar;

  if(iconKind === "heart_1")
  {
    return (
      <Heart
        defn={defn}
        fieldId={fieldId}
        field={field}
        onChange={onChange}
        formCtx={formCtx}
      />);
  }
  else if(iconKind === "thumbs_2")
  {
    return (
      <Thumbs
        defn={defn}
        onChange={onChange}
        formCtx={formCtx}
        fieldId={fieldId}
        field={field}
      />);
  }
  else
  {
    return (
      <Stars
        defn={defn}
        fieldId={fieldId}
        field={field}
        onChange={onChange}
        formCtx={formCtx}
      />);
  }
}

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
  };
} = {
  1: {
    icon: <ThumbUpOutlinedIcon
      sx={{mr: gapHalf, overflow: "visible"}}
      color="primary"
    />
  },
  2: {
    icon: <ThumbDownOutlinedIcon
      sx={{mr: gapHalf, overflow: "visible"}}
      color="error"
    />
  }
};

function IconContainer(props: IconContainerProps)
{
  const {value, ...other} = props;
  return <span style={{width: px(gapStd * 2.6)}} {...other}>{customIcons[value].icon}</span>;
}

const Thumbs = (props: {
  fieldId: string;
  field: ControllerRenderProps;
  formCtx: IFormCtx;
  defn: DefnFieldRating;
  onChange?: (event: React.SyntheticEvent, value: (number | null)) => any,
}) =>
{
  const {
    field,
    fieldId,
    formCtx,
    defn,
    onChange
  } = props;

  return (
    <LayoutFlexRow
      overflowX={"visible"}
      overflowY={"visible"}
      pl={1}
    >
      <Rating
        highlightSelectedOnly={true}
        max={2}
        IconContainerComponent={IconContainer}
        sx={{
          "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
            color: theme.palette.action.disabled
          }
        }}
        size={"large"}
        name={fieldId}
        value={(field.value as FieldValueDecimal)?.value ?? 0}
        onChange={onChange}
        disabled={formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled}
        readOnly={formCtx.isFieldReadonly(defn as DefnFieldRating)}
      />
    </LayoutFlexRow>
  );
};

function Heart(props: {
  fieldId: string;
  field: ControllerRenderProps;
  formCtx: IFormCtx;
  defn: DefnFieldRating;
  onChange?: (event: React.SyntheticEvent, value: (number | null)) => any,
})
{
  const {
    fieldId,
    field,
    formCtx,
    defn,
    onChange
  } = props;

  return (
    <Rating
      size={"large"}
      sx={{
        flexGrow: 1,
        width: "100%"
      }}
      name={fieldId}
      value={(field.value as FieldValueNumber)?.value ?? 0}
      onChange={onChange}
      disabled={formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled}
      readOnly={formCtx.isFieldReadonly(defn as DefnFieldRating)}
      max={1}
      defaultChecked={false}
      icon={<FavoriteOutlinedIcon
        fontSize="inherit"
        color={"error"}
      />}
      emptyIcon={<FavoriteBorderOutlinedIcon
        fontSize="inherit"
        color={"disabled"}
      />}
    />);
}

function Stars(props: {
    fieldId: string;
    field: ControllerRenderProps;
    formCtx: IFormCtx;
    defn: DefnFieldRating;
    onChange?: (event: React.SyntheticEvent, value: (number | null)) => any,
  }
)
{
  const {
    fieldId,
    field,
    formCtx,
    defn,
    onChange
  } = props;

  const iconKind = defn.ratingKind;
  let maxStar = 5;
  if(iconKind === "star_3")
  {
    maxStar = 3;
  }
  else if(iconKind === "star_4")
  {
    maxStar = 4;
  }
  else if(iconKind === "star_5")
  {
    maxStar = 5;
  }

  return (
    <LayoutFlexRow
    >
      <Rating
        size={"large"}
        sx={{
          gap: px(14),
          "& > label": {gap: px(14)}
        }}
        name={fieldId}
        value={(field.value as FieldValueDecimal)?.value ?? 0}
        onChange={onChange}
        disabled={formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled}
        readOnly={formCtx.isFieldReadonly(defn as DefnFieldRating)}
        max={maxStar}
      />
    </LayoutFlexRow>
  );
}
