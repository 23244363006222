import {cloneDeep} from "lodash";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {MsgSpreadsheetRowCommentCountGet} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowCommentCountGet";
import {MsgSpreadsheetRowGet} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowGet";
import {WsocEntMain} from "../../../api/ent/entMain/WsocEntMain";
import {DtoMessagePayloadReport} from "../../../api/home/base/dto/DtoMessagePayloadReport";
import {DtoMessagePayloadSpreadsheetPartition} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetPartition";
import {DtoMessagePayloadSpreadsheetRow} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {SigTopicMessageNew} from "../../../api/home/session/sig/SigTopicMessageNew";
import {SigTopicMessageProps} from "../../../api/home/session/sig/SigTopicMessageProps";
import {isRowId} from "../../../api/meta/base/ApiPlus";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {isGlobal} from "../../../api/meta/base/ApiPlus";
import {isMessageId} from "../../../api/meta/base/ApiPlus";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {EntUserId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {SpreadsheetPartitionId} from "../../../api/meta/base/Types";
import {MessageId} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import ISrvcChat from "../../../base/ISrvcChat";
import {resolveChatLabelPatternVar} from "../../../base/plus/ArgBinderPlus";
import {resolveChatPatternVar} from "../../../base/plus/ArgBinderPlus";
import {getMsgPayloadSpreadsheetPartitionId} from "../../../base/plus/BubblePlus";
import {getMsgPayloadSpreadsheetId} from "../../../base/plus/BubblePlus";
import {getMsgPayloadRowId} from "../../../base/plus/BubblePlus";
import {isBubbleFormValueFieldMediaType} from "../../../base/plus/BubblePlus";
import {getFormNameColor} from "../../../base/plus/FormPlus";
import {filterForm} from "../../../base/plus/FormPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getChatItem} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {random} from "../../../base/plus/StringPlus";
import {listChatSetFormBubbleTitleColor} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistMsgReply} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistMessage} from "../../../base/slices/list/SliceListChatAction";
import {listChatHandleSigTopicFormRemove} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistIsVisibleSpreadsheetRow} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistFormComment} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistChatPatternVar} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistSigSpreadsheetRowExpiry} from "../../../base/slices/list/SliceListChatAction";
import {listSetIfExistIsFormWithMedia} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistIsCommentAble} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistFormResult} from "../../../base/slices/list/SliceListChatAction";
import {listChatHandleSigTopicMessageProps} from "../../../base/slices/list/SliceListChatAction";
import {listChatHandleSigTopicMessageRemoveForMe} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistIsStar} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistDefnForm} from "../../../base/slices/list/SliceListChatAction";
import {listChatSetIfExistHeader} from "../../../base/slices/list/SliceListChatAction";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {toComboId} from "../../../base/types/TypesComboId";
import {TypeUserField} from "../../../base/types/TypesGlobal";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {textUserOrYou} from "../../../Store";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {isCommentableForm} from "../../app/form/SrvcForm";
import {Srvc} from "../../Srvc";

type TypeSigAvatar = SigGroupAvatar | SigUserAvatar | SigEntAvatarUser | undefined;

interface IUserFieldListChat extends TypeUserField
{
  rowIdMap: RowIdMap;
  partitionIdMap: PartitionIdMap;
  mutation: string,
  loaded: boolean;
}

type RowIdMap = Record<RowId, MessageId[]>
type PartitionIdMap = Record<SpreadsheetPartitionId, MessageId[]>

export default abstract class SrvcHomeListChat extends ISrvcChat
{
  protected constructor(readonly selectList: SelectList)
  {
    super();
  }

  subscribeItem(
    subscriberId: TypeSubscriberId,
    itemId: TypeListItemId,
    unSubscribe?: boolean)
  {
    if(!isMessageId(itemId))
    {
      return;
    }

    const chatItem = getChatItem(store.getState(), itemId, this.selectList) as IListItemChat;

    if(chatItem)
    {
      const entId = chatItem?.entId;
      const chatId = chatItem?.chatId;

      this.subscribeBubbleMessage(subscriberId, entId, chatId, chatItem.sig, unSubscribe);

      const senderId = chatItem.sig.senderId;
      const comboSenderId = toComboId(entId, senderId);
      Srvc.app.pubsub.homeAvatar(subscriberId, comboSenderId, unSubscribe);// for bubble header
      if(isGroupId(chatId))
      {
        const comboGroupId = toComboId(entId, chatId);
        Srvc.app.pubsub.homeAvatar(subscriberId, comboGroupId, unSubscribe);  // for bubble header only for
      }
    }
  }

  handleSigTopicMessage(rootState: RootState, sig: SigTopic): void
  {
    const messageId = sig.aboutId as MessageId;
    const list = this.selectList(rootState);
    const listName = this.selectList(rootState).listName;
    const item = this.selectList(rootState).itemsById[messageId] as IListItemChat;
    if(!list.loaded || !item || item.entId === undefined || item.entId !== sig.artifactId || item.chatId === undefined)
    {
      return;
    }
    if(item?.sig)
    {
      this.wsocMessageGet(item.entId as EntId, item.chatId as ChatId, item.sig.messageOffset, (sigMessage) =>
      {
        if(sigMessage === undefined)
        {
          dispatchList(listName, listChatHandleSigTopicMessageRemoveForMe({messageId}));
        }
        else if(sigMessage.payload.messageType)
        {
          dispatchList(listName, listChatSetIfExistMessage(sigMessage));
        }
      });
    }
  }

  handleSigTopicMessageProps(rootState: RootState, sig: SigTopicMessageProps)
  {
    const listName = this.selectList(rootState).listName;
    const item = this.selectList(rootState).itemsById[sig.messageId] as IListItemChat;
    if(sig.aboutId !== item?.chatId)
    {
      return;
    }
    dispatchList(listName, listChatHandleSigTopicMessageProps(sig));
  }

  handleSigTopicFormResult(rootState: RootState, sig: SigTopic)
  {
    const listName = this.selectList(rootState).listName;
    const rowIdMap = (this.selectList(rootState).userField as IUserFieldListChat)?.rowIdMap;
    if(rowIdMap)
    {
      const rowId = sig.aboutId as RowId;
      const messageIds = rowIdMap[rowId];
      if(messageIds)
      {
        messageIds?.forEach(messageId =>
        {
          if(messageId)
          {
            const item = this.selectList(rootState).itemsById[messageId] as IListItemChat;
            if(item)
            {
              this.setFormResultIfExit(listName, item, messageId, rowId);
            }
          }
        });
      }
      if(isRowId(rowId))
      {
        const item = this.selectList(rootState).itemsById[rowId] as IListItemChat;
        if(item)
        {
          this.setFormResultIfExit(listName, item, rowId, rowId);
        }
      }
    }
  }

  setFormResultIfExit(listName: string, item: IListItemChat, itemId: string, rowId: RowId)
  {
    const entId = item?.entId;
    const payload = item?.sig.payload;
    const spreadsheetId = payload
      ? getMsgPayloadSpreadsheetId(payload)
      : undefined;
    const sigSpreadsheetRow = item?.sig.sigSpreadsheetRow;

    const msg = {
      rowId: rowId,
      spreadsheetId: spreadsheetId,
      version: sigSpreadsheetRow?.version
    } as MsgSpreadsheetRowGet;

    const rootState = store.getState();
    const entUserId = selectCallerEnt(rootState, entId)?.entUserId;

    WsocEntMain.spreadsheetRowGet(entId as EntId, msg, (envSignal) =>
    {
      if(envSignal?.sig)
      {
        dispatchList(listName, listChatSetFormBubbleTitleColor({
          itemId: itemId,
          formBubbleTitleColor: getFormNameColor(
            entUserId,
            envSignal.sig.formValue?.updatedBy,
            envSignal.sig.updatedKeySet
          )
        }));
        dispatchList(listName, listChatSetIfExistFormResult({
          itemId: itemId,
          sigSpreadsheetRow: envSignal.sig
        }));
      }
      else if(envSignal.error)
      {
        dispatchList(listName, listChatSetFormBubbleTitleColor({
          itemId: itemId,
          formBubbleTitleColor: getFormNameColor(
            entUserId,
            sigSpreadsheetRow?.formValue?.updatedBy,
            sigSpreadsheetRow?.updatedKeySet
          )
        }));
        const errorNotFound = envSignal.error.validationErrors?.find((error) => error.errorCode === "notFound");
        const errorNotAccessible =
          envSignal.error.validationErrors?.find((error) => error.errorCode === "notAccessible");
        if(errorNotAccessible)
        {
          dispatchList(listName, listChatSetIfExistIsVisibleSpreadsheetRow({
            itemId: itemId,
            isInvisibleSpreadsheetRow: true
          }));
        }
        else if(errorNotFound && payload?.messageType !== "spreadsheetPartition")
        {
          dispatchList(listName, listChatHandleSigTopicFormRemove(itemId));
        }
      }
    });

  }

  handleSigTopicFormComment(rootState: RootState, sig: SigTopic): void
  {
    const listName = this.selectList(rootState).listName;
    const rowIdMap = (this.selectList(rootState).userField as IUserFieldListChat)?.rowIdMap;
    if(rowIdMap)
    {
      const rowId = sig.aboutId as RowId;
      const messageIds = rowIdMap[rowId];
      messageIds?.forEach(messageId =>
      {
        if(messageId)
        {
          const item = this.selectList(rootState).itemsById[messageId] as IListItemChat;
          const entId = item.entId;
          let version = item.sig.sigSpreadsheetRow?.version;

          if(item.sig.payload.messageType === "report")
          {
            version = item.sig.reportRowCommentCount?.version;
          }

          if(entId)
          {
            this.wsocSpreadsheetRowCommentCountGet(listName, messageId, entId, rowId, version);

          }
        }
      });
    }
  }

  handleSigTopicMessageNew(rootState: RootState, sig: SigTopicMessageNew): void
  {
    // ignore
  }

  handleSigTopicMessageClearChat(rootState: RootState, sig: SigTopic): void
  {
    // ignore
  }

  handleSigTopicSpreadsheetRowDurationExpiry(rootState: RootState, sig: SigTopic)
  {
    const listName = this.selectList(rootState).listName;
    const partitionIdMap = (this.selectList(rootState).userField as IUserFieldListChat)?.partitionIdMap;
    if(partitionIdMap)
    {
      const rowId = sig.aboutId as SpreadsheetPartitionId;
      const messageIds = partitionIdMap[rowId];
      messageIds?.forEach(messageId =>
      {
        if(messageId)
        {
          const item = this.selectList(rootState).itemsById[messageId] as IListItemChat;
          const entId = item.entId;
          const payload = item.sig.payload;
          const spreadsheetId = getMsgPayloadSpreadsheetId(payload);
          const spreadsheetPartitionId = getMsgPayloadSpreadsheetPartitionId(payload);

          if(spreadsheetId && spreadsheetPartitionId)
          {
            Srvc.app.spreadsheet.wsocSpreadsheetRowExpiryGet(entId,
              spreadsheetPartitionId,
              spreadsheetId,
              undefined,
              (envSignal) =>
              {
                if(envSignal)
                {
                  dispatchList(listName, listChatSetIfExistSigSpreadsheetRowExpiry({
                    itemId: messageId,
                    sigSpreadsheetRowExpiry: envSignal
                  }));
                }
              }
            );
          }
        }
      });
    }
  }

  selectEntAvatar(rootState: RootState, itemId: TypeListItemId): TypeSigAvatar
  {
    if(!isMessageId(itemId))
    {
      return undefined;
    }
    const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
    const entId = chatItem?.entId;

    return !isGlobal(entId)
      ? rootState.cache.app.caller.entIdUserAvatarMap[entId]
      : undefined;
  }

  onBindEntAvatar(listName: string, itemId: TypeListItemId, avatar?: TypeSigAvatar): void
  {
    if(avatar)
    {
      const chatItem = getChatItem(store.getState(), itemId, this.selectList) as IListItemChat;
      dispatchList(listName, listChatSetIfExistHeader({
        itemId: itemId,
        header: {
          ...chatItem.sig.header,
          headerTextLeft1: (avatar as SigEntAvatarUser).name
        }
      }));
    }
  }

  selectGroupAvatar(rootState: RootState, itemId: TypeListItemId): TypeSigAvatar
  {
    if(!isMessageId(itemId))
    {
      return undefined;
    }
    const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
    const entId = chatItem?.entId;
    const chatId = chatItem?.chatId;
    const entChatId = toComboId(entId, chatId);
    const isGroup = isGroupId(chatId);

    return isGroup
      ? rootState.cache.app.group.groupAvatarMap[entChatId]
      : undefined;
  }

  onBindGroupAvatar(listName: string, itemId: TypeListItemId, avatar?: TypeSigAvatar): void
  {
    if(avatar)
    {
      const chatItem = getChatItem(store.getState(), itemId, this.selectList) as IListItemChat;
      dispatchList(listName, listChatSetIfExistHeader({
        itemId: itemId,
        header: {
          ...chatItem.sig.header,
          headerTextLeft2: (avatar as SigEntAvatarUser).name
        }
      }));
    }
  }

  selectDefnForm(rootState: RootState, itemId: TypeListItemId): DefnForm | undefined
  {
    if(isMessageId(itemId)) //for message
    {
      const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
      if(chatItem)
      {
        const messageType = chatItem.sig.payload.messageType;
        const entId = chatItem?.entId;
        const callerEntMapElement = selectCallerEnt(rootState, entId);
        if(messageType === "spreadsheetRow")
        {
          const payload = (chatItem.sig.payload as DtoMessagePayloadSpreadsheetRow);
          const metaIdForm = payload.formId;
          return callerEntMapElement?.formMap[metaIdForm];
        }
        else if(messageType === "report")
        {
          const payload = (chatItem.sig.payload as DtoMessagePayloadReport);
          const metaIdForm = payload.inputFormId;
          return metaIdForm
            ? callerEntMapElement?.formMap[metaIdForm]
            : undefined;
        }
        else if(messageType === "spreadsheetPartition")
        {
          const payload = (chatItem.sig.payload as DtoMessagePayloadSpreadsheetPartition);
          const metaIdForm = payload.formId;
          return callerEntMapElement?.formMap[metaIdForm];
        }
      }
    }
    else if(isRowId(itemId)) //for spreadsheet Row
    {
      const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
      const messageType = chatItem?.sig?.payload?.messageType;
      const entId = chatItem?.entId;
      const callerEntMapElement = selectCallerEnt(rootState, entId);
      if(messageType === "spreadsheetRow")
      {
        const payload = (chatItem.sig.payload as DtoMessagePayloadSpreadsheetRow);
        const metaIdForm = payload.formId;
        return callerEntMapElement?.formMap[metaIdForm];
      }
    }
    else
    {
      return;
    }

  }

  onBindDefnForm(listName: string, itemId: TypeListItemId, defnForm?: DefnForm): void
  {
    if(defnForm)
    {
      const rootState = store.getState();
      const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
      if(chatItem)
      {
        const messageType = chatItem.sig.payload.messageType;
        const entId = chatItem?.entId;
        const callerEnt = selectCallerEnt(rootState, entId);
        let formValue: FormValue | FormValueRaw | undefined;

        if(messageType === "spreadsheetRow")
        {
          const payload = (chatItem.sig.payload as DtoMessagePayloadSpreadsheetRow);
          formValue = payload.spreadsheetRow?.formValue;
        }
        else if(messageType === "report")
        {
          const payload = (chatItem.sig.payload as DtoMessagePayloadReport);
          formValue = payload.formValueRaw;
        }
        else if(messageType === "spreadsheetPartition")
        {
          const payload = (chatItem.sig.payload as DtoMessagePayloadSpreadsheetPartition);
          formValue = payload.spreadsheetRow?.formValue;
        }

        const filteredForm = filterForm(defnForm,
          callerEnt?.roleIdSet || [],
          callerEnt,
          formValue,
          undefined,
          (_, comp) =>
          {
            if(comp && isBubbleFormValueFieldMediaType(comp) && formValue?.valueMap
              && formValue.valueMap[comp?.metaId])
            {
              dispatchList(listName, listSetIfExistIsFormWithMedia({
                itemId: itemId,
                isFormWithMedia: true
              }));
              return;
            }
          }
        );

        dispatchList(listName, listChatSetIfExistDefnForm({
          defnForm: filteredForm,
          itemId: itemId
        }));
      }
    }
  }

  selectIsStarMsg(rootState: RootState, itemId: TypeListItemId): boolean
  {
    if(!isMessageId(itemId))
    {
      return false;
    }
    return Boolean(rootState.cache.app.starMsg.starMsgMap[itemId]);
  }

  onBindIsStarMsg(listName: string, itemId: TypeListItemId, isStar: boolean): void
  {
    if(!isMessageId(itemId))
    {
      return;
    }
    dispatchList(listName, listChatSetIfExistIsStar({
      itemId: itemId,
      isStar: isStar
    }));
  }

  selectCanShowComments(rootState: RootState, itemId: TypeListItemId): boolean
  {
    if(!isMessageId(itemId))
    {
      return false;
    }
    const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
    if(chatItem)
    {
      const entId = chatItem.entId;
      const messageType = chatItem.sig.payload.messageType;
      const senderId = chatItem.sig.senderId;
      if(messageType === "spreadsheetRow" && entId !== undefined)
      {
        const formId = (chatItem.sig.payload as DtoMessagePayloadSpreadsheetRow).formId;
        return isCommentableForm(rootState, entId, formId, senderId);
      }
    }
    return false;
  }

  onBindCanShowComments(listName: string, itemId: TypeListItemId, isCommentAble: boolean): void
  {
    if(!isMessageId(itemId))
    {
      return;
    }
    dispatchList(listName, listChatSetIfExistIsCommentAble({
      itemId: itemId,
      isCommentAble: isCommentAble
    }));
  }

  selectChatPattern(rootState: RootState, itemId: TypeListItemId): SigSpreadsheetRow | undefined
  {
    if(!isMessageId(itemId))
    {
      return;
    }
    const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;
    if(chatItem)
    {
      const entId = chatItem.entId;
      const messageType = chatItem.sig.payload.messageType;

      if((messageType === "spreadsheetRow" || messageType === "spreadsheetPartition" || messageType === "report")
        && entId !== undefined)
      {
        return chatItem.sig.sigSpreadsheetRow;
      }
    }
    return undefined;
  }

  onBindChatPattern(listName: string, itemId: TypeListItemId, sigSpreadsheetRow?: SigSpreadsheetRow): void
  {
    const rootState = store.getState();
    const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat;

    if(chatItem)
    {
      const entId = chatItem.entId;
      if(entId)
      {
        const callerEnt = selectCallerEnt(rootState, entId);
        const defnForm = chatItem.sig.defnForm;
        if(defnForm)
        {
          const defnFormClone = cloneDeep(defnForm);
          const formValue = sigSpreadsheetRow?.formValue;

          const chatPatternVar = resolveChatPatternVar(defnFormClone,
            callerEnt,
            formValue
          );
          const chatLabelPatternVar = resolveChatLabelPatternVar(defnFormClone,
            callerEnt,
            formValue
          );

          dispatchList(listName, listChatSetIfExistChatPatternVar({
            chatPatternVar: chatPatternVar,
            chatLabelPatternVar: chatLabelPatternVar,
            itemId: itemId
          }));
        }
      }
    }
  }

  selectReply(rootState: RootState, itemId: TypeListItemId): SigUserAvatar | undefined
  {
    if(!isMessageId(itemId))
    {
      return;
    }
    const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat | undefined;
    if(chatItem)
    {
      const entId = chatItem.entId;
      const replyPayload = chatItem.sig.replyPayload;
      const senderId = replyPayload?.senderId;
      if(entId && senderId)
      {
        const entUserId = toComboId(entId, senderId);
        return rootState.cache.app.user.userAvatarMap[entUserId];
      }
    }
  }

  onBindReply(listName: string, itemId: TypeListItemId, sender?: SigUserAvatar): void
  {
    if(!sender)
    {
      return;
    }

    const rootState = store.getState();
    const senderName = textUserOrYou(rootState, sender);
    const senderColor = sender.userColor;

    dispatchList(listName, listChatSetIfExistMsgReply({
      itemId: itemId,
      replyInfo: {
        senderName: senderName,
        senderColor: senderColor
      }
    }));
  }

  selectEntUserAvatar(rootState: RootState, itemId: TypeListItemId, entUserId: EntUserId): TypeSigAvatar
  {
    const chatItem = getChatItem(rootState, itemId, this.selectList) as IListItemChat || undefined;
    const entId = chatItem?.entId;
    if(entId)
    {
      const comboEntUserId = toComboId(entId, entUserId);
      return rootState.cache.app.user.userAvatarMap[comboEntUserId];
    }
  }

  protected getHomeListChatUserField(sig: IListItemChat[]): IUserFieldListChat
  {
    const rowIdMap = {} as RowIdMap;
    const partitionIdMap = {} as PartitionIdMap;

    sig.forEach(sigMsg =>
    {
      const messageType = sigMsg?.sig?.payload?.messageType;
      if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition")
      {
        const payload = sigMsg.sig.payload as DtoMessagePayloadSpreadsheetRow | DtoMessagePayloadSpreadsheetPartition;
        const rowId = getMsgPayloadRowId(payload);
        const spreadsheetPartitionId = getMsgPayloadSpreadsheetPartitionId(payload);
        if(rowId)
        {
          this.insetRowIdMapItem(rowId, sigMsg.sig.messageId, rowIdMap);
        }
        if(spreadsheetPartitionId)
        {
          this.insetPartitionIdMapItem(spreadsheetPartitionId, sigMsg.sig.messageId, partitionIdMap);
        }
      }
      else if(messageType === "report")
      {
        const payload = sigMsg.sig.payload as DtoMessagePayloadReport;
        const rowId = payload.rowId;
        if(payload.inputFormId && rowId)
        {
          this.insetRowIdMapItem(rowId, sigMsg.sig.messageId, rowIdMap);
        }
      }
    });

    return {
      rowIdMap: rowIdMap,
      partitionIdMap: partitionIdMap,
      mutation: random(),
      loaded: true
    };
  }

  private wsocSpreadsheetRowCommentCountGet(listName: string,
    messageId: MessageId,
    entId: EntId,
    rowId: RowId,
    version?: string)
  {

    const msg: MsgSpreadsheetRowCommentCountGet = {
      rowId: rowId,
      version: version
    };

    WsocEntMain.spreadsheetRowCommentCountGet(entId, msg, (envSig) =>
    {
      if(envSig.sig)
      {
        dispatchList(listName, listChatSetIfExistFormComment({
          itemId: messageId,
          sigSpreadsheetRowComment: envSig.sig
        }));
      }
    });
  }

  private insetRowIdMapItem = (rowId: RowId, messageId: MessageId, rowIdMap: RowIdMap) =>
  {
    if(!rowIdMap[rowId])
    {
      rowIdMap[rowId] = [];
    }
    if(!rowIdMap[rowId].includes(messageId))
    {
      rowIdMap[rowId].push(messageId);
    }
  };

  private insetPartitionIdMapItem = (
    spreadsheetPartitionId: SpreadsheetPartitionId,
    rowId: RowId,
    partitionIdMap: PartitionIdMap) =>
  {
    if(!partitionIdMap[spreadsheetPartitionId])
    {
      partitionIdMap[spreadsheetPartitionId] = [];
    }
    if(!partitionIdMap[spreadsheetPartitionId].includes(rowId))
    {
      partitionIdMap[spreadsheetPartitionId].push(rowId);
    }
  };
}
