import {ShareRounded} from "@mui/icons-material";
import {PersonAddRounded} from "@mui/icons-material";
import {GridOnRounded} from "@mui/icons-material";
import {MapRounded} from "@mui/icons-material";
import {ArchitectureRounded} from "@mui/icons-material";
import {BadgeRounded} from "@mui/icons-material";
import {AddLocationRounded} from "@mui/icons-material";
import {Forward} from "@mui/icons-material";
import {WhatsApp} from "@mui/icons-material";
import {ContentCopy} from "@mui/icons-material";
import {RestartAlt} from "@mui/icons-material";
import {ExpandMore} from "@mui/icons-material";
import {Microsoft} from "@mui/icons-material";
import React from "react";
import {stripIconSx} from "../../../base/plus/ThemePlus";
import {EnumIconInputCustom} from "../../../base/types/TypesIcon";

export default function IconInputCustom(props: {
  value: EnumIconInputCustom
})
{
  const value = props.value;
  if(value === undefined)
  {
    return null;
  }

  const sx = stripIconSx();
  switch(value)
  {
    case "addLocation":
      return <AddLocationRounded sx={sx} />;
    case "badge":
      return <BadgeRounded sx={sx} />;
    case "architecture":
      return <ArchitectureRounded sx={sx} />;
    case "map":
      return <MapRounded sx={sx} />;
    case "grid":
      return <GridOnRounded sx={sx} />;
    case "addPerson":
      return <PersonAddRounded sx={sx} />;
    case "forward":
      return <Forward sx={sx} />;
    case "whatsApp":
      return <WhatsApp sx={sx} />;
    case "contentCopy":
      return <ContentCopy sx={sx} />;
    case "reset":
      return <RestartAlt sx={sx} />;
    case "expandMore":
      return <ExpandMore sx={sx} />;
    case "share":
      return <ShareRounded sx={sx} />;
    case "microsoft":
      return <Microsoft sx={sx} />;
  }
}
