import {useTheme} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnDtoColor} from "../../../../api/meta/base/dto/DefnDtoColor";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioBuildColor} from "../../../../api/meta/base/dto/DefnStudioBuildColor";
import {ThemeColorWithShades} from "../../../../api/meta/base/StudioSetsFieldType";
import {ThemeColorWithoutShades} from "../../../../api/meta/base/StudioSetsFieldType";
import {EnumDefnThemeDirection} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeColorShade} from "../../../../api/meta/base/Types";
import {EnumDefnThemeColorShade} from "../../../../api/meta/base/Types";
import {EnumDefnThemeColor} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import BoxColor from "../../../atom/box/BoxColor";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";

const themeColor = [
  ...ThemeColorWithoutShades,
  undefined,
  ...ThemeColorWithShades
] as EnumDefnThemeColor[];

const colorOptions: DefnDtoOption[] = themeColor.map(option => ({
  value: toLabel(option),
  metaId: option
}));

const shadeOptions: DefnDtoOption[] = EnumArrayDefnThemeColorShade.map(option => ({
  value: option.slice(1),
  metaId: option
}));

export default function FieldStudioBuildColor(props: {
  defn: DefnStudioBuildColor,
  multiSelect?: boolean,
  showChip?: boolean
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;
  const fieldVariant = defnTheme.fieldVariant;

  const {
    getFieldRequired
  } = useFieldPropertiesResolver(defn);

  const metaId = defn.metaId;
  const disabled = defn.disabled;
  const allowShades = defn.allowShades;
  const direction = defn.direction;
  const required = getFieldRequired();
  const label = defn.label ? defn.label : "Value";

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const fieldValue = field.value as DefnDtoColor;

        const handleValueChange = (color: EnumDefnThemeColor | null) =>
        {
          if(color === null)
          {
            field.onChange(null);
          }
          else
          {
            const dtoColor = getDefnDtoForColor(color, fieldValue);
            if(dtoColor?.value)
            {
              field.onChange(dtoColor);
            }
          }
        };

        const handleShadeChange = (shade: EnumDefnThemeColorShade | null) =>
        {
          if(shade === null)
          {
            field.onChange({
              ...fieldValue,
              shade: undefined
            } as DefnDtoColor);
          }
          else
          {
            const dtoColor = getDefnDtoForShade(shade, fieldValue);
            field.onChange(dtoColor);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <RealFieldBuildColor
              fieldId={metaId}
              disabled={disabled}
              error={error}
              fieldVariant={fieldVariant}
              fieldSize={defnTheme.fieldSize}
              label={label}
              options={colorOptions}
              fieldValue={fieldValue}
              handleValueChange={handleValueChange}
              handleShadeChange={handleShadeChange}
              readOnly={formCtx.isReadonly()}
              onClick={onClick}
              field={field}
              allowShades={allowShades}
              direction={direction}
              required={required}
            />
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealFieldBuildColor(props: {
  fieldId: string,
  field: ControllerRenderProps<FieldValues, string>,
  disabled?: boolean,
  error?: FieldError,
  fieldVariant?: EnumDefnThemeFieldVariant,
  fieldSize?: EnumDefnThemeFieldSize,
  hideLabel?: boolean,
  helperText?: string,
  label?: string,
  fieldValue?: DefnDtoColor,
  options?: DefnDtoOption[],
  onClick?: (() => void),
  readOnly?: boolean,
  handleValueChange: (color: EnumDefnThemeColor | null) => void
  handleShadeChange: (shade: EnumDefnThemeColorShade | null) => void,
  allowShades?: boolean,
  direction?: EnumDefnThemeDirection,
  required?: boolean
})
{
  const theme = useTheme();

  const fieldValue = props.fieldValue;
  const colorValue = fieldValue?.value;
  const [shadeValue, setShadeValue] = useState<EnumDefnThemeColorShade | undefined>(fieldValue?.shade ?? "s500");

  const [bgcolor, setBgcolor] = useState<string | undefined>();

  const calcColor = (color?: EnumDefnThemeColor, shade?: EnumDefnThemeColorShade) =>
  {
    if(color)
    {
      return theme.common.colorWithShade(color, shade ?? "s500");
    }
  };

  useEffect(() =>
  {
    const _color = calcColor(colorValue, shadeValue);
    if(bgcolor !== _color)
    {
      setBgcolor(_color);
    }
  }, [fieldValue]);

  const fieldId = props.fieldId;
  const field = props.field;
  const disabled = props.disabled;
  const error = props.error;
  const fieldVariant = props.fieldVariant;
  const fieldSize = props.fieldSize;
  const label = props.label;
  const readOnly = props.readOnly;
  const handleValueChange = props.handleValueChange;
  const handleShadeChange = props.handleShadeChange;
  const onClick = props.onClick;
  const allowShades = props.allowShades;
  const required = props.required;
  const direction = props.direction ?? "vertical";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: direction === "vertical"
          ? "column"
          : "row"
      }}
    >
      <RawPickOneAutoComplete
        optionMap={optionsToMapOfOption(colorOptions)}
        label={props.hideLabel ? undefined : label}
        value={colorValue}
        disabled={disabled}
        readOnly={readOnly}
        error={error}
        onChange={(color) =>
        {
          if(color === null)
          {
            handleValueChange(null);
          }
          else
          {
            handleValueChange(color as EnumDefnThemeColor);
            setBgcolor(calcColor(color as EnumDefnThemeColor));
            setShadeValue("s500");
          }
        }}
        required={required}
        onClick={onClick}
        fieldVariant={fieldVariant}
        fieldSize={fieldSize}
        name={field.name}
        fieldId={fieldId}
        onBlur={field.onBlur}
        endAdornment={<BoxColor
          bgcolor={bgcolor}
          pl={20}
        />}
      />
      {
        (colorValue && allowShades) && ThemeColorWithShades.includes(colorValue) &&
        <React.Fragment>
          <LayoutGap
            mt={direction === "vertical" ? px(theme.common.gapStd) : undefined}
            ml={direction === "horizontal" ? px(theme.common.gapStd) : undefined}
          />
          <RawPickOneAutoComplete
            optionMap={optionsToMapOfOption(shadeOptions)}
            label={"Shade"}
            value={shadeValue}
            disabled={disabled}
            readOnly={readOnly}
            error={error}
            onChange={(shade) =>
            {
              if(shade === null)
              {
                handleShadeChange(null);
              }
              else
              {
                handleShadeChange(shade as EnumDefnThemeColorShade);
                setBgcolor(calcColor(colorValue, shade as EnumDefnThemeColorShade));
                setShadeValue(fieldValue.value ? shade as EnumDefnThemeColorShade : undefined);
              }
            }}
            required={required}
            onClick={onClick}
            onBlur={field.onBlur}
            fieldVariant={fieldVariant}
            fieldSize={fieldSize}
            name={field.name}
            fieldId={fieldId}
          />
        </React.Fragment>
      }
    </Box>
  );
}

function getDefnDtoForColor(value?: EnumDefnThemeColor, fieldValue?: DefnDtoColor)
{
  if(!value)
  {
    return null;
  }

  const dtoColor = {} as DefnDtoColor;
  if(ThemeColorWithoutShades.includes(value))
  {
    dtoColor.value = value as EnumDefnThemeColor;
    dtoColor.shade = undefined;
  }
  else if(ThemeColorWithShades.includes(value))
  {
    dtoColor.value = value as EnumDefnThemeColor;
    dtoColor.shade = fieldValue?.shade || "s500";
  }

  return dtoColor;
}

function getDefnDtoForShade(value?: EnumDefnThemeColorShade, fieldValue?: DefnDtoColor)
{
  const colorValue = fieldValue?.value;
  const dtoColor = {
    ...fieldValue
  } as DefnDtoColor;

  if(colorValue && ThemeColorWithShades.includes(colorValue))
  {
    dtoColor.shade = value;
  }

  return dtoColor;
}
