import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickTree} from "../../../api/meta/base/dto/DefnFieldPickTree";
import {DefnStudioBuildTree} from "../../../api/meta/base/dto/DefnStudioBuildTree";
import {StudioVarTree} from "../../../api/meta/base/dto/StudioVarTree";
import {StudioVarValueTree} from "../../../api/meta/base/dto/StudioVarValueTree";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {nextTreeNodeId} from "../../../base/plus/SysPlus";
import {ITreeNode} from "../../../base/types/TypesTree";

export function fnVariableBuilderTree(fieldValueTree: string)
{
  function getVarComp()
  {
    return {
      [fieldValueTree]: {
        type: "studioBuildTree",
        metaId: fieldValueTree
      } as DefnStudioBuildTree
    } as Record<MetaIdVar, DefnFieldPickTree>;
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarTree
  )
  {
    valueMap[fieldValueTree] = entVariable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const nodes = valueMap[fieldValueTree] as StudioVarValueTree;

    return {
      value: nodes
    } as StudioVarTree;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}

export function updateAllChildIds(node: ITreeNode, id: string)
{
  node.id = id;
  if(node.children)
  {
    node.children.forEach(child => updateAllChildIds(child, nextTreeNodeId()));
  }
}
