import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {getListItemHeightMPSL} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {ListLayoutType} from "../../../base/types/list/TypesList";
import {IMedia} from "../../../base/types/list/TypesListAPSA";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {TypeEnumCaptionButton} from "../../list/List";
import {TypeListCounterPositionVariant} from "../../list/List";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import {BoxM} from "./BoxM";
import {BoxPSL} from "./BoxPSL";

export function BoxMPSL(props: {
  media?: IMedia[],
  primary?: ILinePrimary,
  secondaryList?: ILineSecondary[],
  showMediaPlaceholder?: boolean,
  isScrolling?: boolean,
  listLayoutType?: ListLayoutType,
  searchWords?: string[],
  onChangePrimaryCounter?: (e: Element, countValue: number, variant: TypeListCounterPositionVariant) => void,
  onChangeSecondaryCounter?: (
    e: Element,
    countValue: number,
    variant: TypeListCounterPositionVariant,
    index: number) => void,
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickIconButtonCaption?: (e: React.MouseEvent<HTMLButtonElement>, type: TypeEnumCaptionButton) => void,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  bgcolor?: CssBackgroundColor;
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  pt?: number,
  pb?: number,
  pl?: number,
  pr?: number,
  flexGrow?: StandardCSSProperties["flexGrow"],
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;

  //5 line height = avatarSize
  const avatarSize = getListItemHeightMPSL(4, Boolean(props.showMediaPlaceholder)) - (gapHalf * 2);

  return (
    <LayoutFlexRow
      mt={px(gapHalf ?? props.mt)}
      mb={px(gapHalf ?? props.mb)}
      ml={px(props.ml)}
      mr={px(props.mr)}
      pt={px(props.pt)}
      pb={px(props.pb)}
      pl={px(props.pl)}
      pr={px(props.pr)}
      bgcolor={props.bgcolor}
      flexGrow={props.flexGrow}
      alignItems={"flex-start"}
      onClick={props.onClick}
      cursorPointer={Boolean(props.onClick)}
      flexShrink={1}
    >
      {
        props.listLayoutType === "list" &&
        <LayoutFlexRow>
          <BoxM
            media={props.media}
            width={px(avatarSize)}
            height={px(avatarSize)}
            showMediaPlaceholder={props.showMediaPlaceholder}
            cursor={props.onClick && "pointer"}
          />
        </LayoutFlexRow>
      }

      <BoxPSL
        primary={props.primary}
        secondaryList={props.secondaryList}
        searchWords={props.searchWords}
        onClickCaption={props.onClickCaption}
        onClickIconButtonCaption={props.onClickIconButtonCaption}
        onChangePrimaryCounter={props.onChangePrimaryCounter}
        onChangeSecondaryCounter={props.onChangeSecondaryCounter}
        bgcolor={props.bgcolor}
        flexGrow={props.flexGrow}
      />
    </LayoutFlexRow>
  );
}

