import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioPickSpreadsheetId} from "../../../../api/meta/base/dto/DefnStudioPickSpreadsheetId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickSpreadsheetId from "../raw/FieldRawStudioPickSpreadsheetId";

export default function FieldStudioPickSpreadsheetId(props: {
  defn: DefnStudioPickSpreadsheetId
  multiSelect?: boolean,
  showChip?: boolean
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickSpreadsheetId
        fieldProps={fieldProps}
        defn={props.defn}
      />}
    />
  );
}
