import {FormControl} from "@mui/material";
import {useRef} from "react";
import {useState} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {Symbol} from "../../../../api/meta/base/Types";
import {MAX_CHARACTER_COUNT_SYMBOL} from "../../../../base/plus/ConstantsPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {hasWhiteSpace} from "../../../../base/plus/StringPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldSymbol(props: {
  defn: DefnFieldText
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const fieldVariant = defnTheme.fieldVariant;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [helperText, setHelperText] = useState<string | undefined>(undefined);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;
        
        const isError = Boolean(error);
        const fieldValue = field.value as Symbol | undefined;

        const onChange = (value?: FieldValueText | null) =>
        {
          let newValue = value?.value;

          if(!newValue)
          {
            field.onChange(null);
            setHelperText(undefined);
          }
          else if(newValue && !hasWhiteSpace(newValue) && newValue.length <= MAX_CHARACTER_COUNT_SYMBOL)
          {
            field.onChange(newValue);
            setHelperText(undefined);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawTextField
                {...props}
                value={fieldValue
                  ? {
                    value: fieldValue
                  }
                  : undefined}
                onBlur={field.onBlur}
                onChange={onChange}
                ref={field.ref}
                error={error}
                errorHelperText={helperText}
                inputRef={inputRef}
              />

            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
