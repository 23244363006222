import {isEmpty} from "lodash";
import {useCallback} from "react";
import {useRef} from "react";
import {useReducer} from "react";
import React from "react";
import {useContext} from "react";
import {createContext} from "react";
import {DtoFieldFilter} from "../../../api/ent/base/dto/DtoFieldFilter";
import {SpreadsheetFilterValue} from "../../../api/ent/base/dto/SpreadsheetFilterValue";
import {SpreadsheetFilterValueStringSet} from "../../../api/ent/base/dto/SpreadsheetFilterValueStringSet";
import {SpreadsheetFilterValueSysIdSet} from "../../../api/ent/base/dto/SpreadsheetFilterValueSysIdSet";
import {SysId} from "../../../api/meta/base/SysId";
import {MetaIdComp} from "../../../api/meta/base/Types";
import {toComboId} from "../../../base/types/TypesComboId";
import {IActionSSBrPayloadSortByFieldId} from "./ICtxSsBr";
import {IActionSSBrPayloadSearchState} from "./ICtxSsBr";
import {IActionSSBrPayloadFilterState} from "./ICtxSsBr";
import {ISsBrFooterPayload} from "./ICtxSsBr";
import {IActionSSBrPayloadReset} from "./ICtxSsBr";
import {IActionSSBrPayloadRemoveModule} from "./ICtxSsBr";
import {IActionSSBrPayloadSetLayout} from "./ICtxSsBr";
import {IActionSSBrPayloadSetFilterOverlay} from "./ICtxSsBr";
import {IActionSSBrPayloadSetAdvancedFilter} from "./ICtxSsBr";
import {IActionSSBrPayloadSetGeneralFilter} from "./ICtxSsBr";
import {IActionSSBrPayloadSetShowSelected} from "./ICtxSsBr";
import {IActionSSBrPayloadSetFilters} from "./ICtxSsBr";
import {IActionSSBrPayload} from "./ICtxSsBr";
import {IActionSsBr} from "./ICtxSsBr";
import {ISsBrStore} from "./ICtxSsBr";
import ICtxSsBr from "./ICtxSsBr";

const ctxSsBr = createContext({} as ICtxSsBr);

export function useSsBrCtx()
{
  return useContext(ctxSsBr);
}

function ctxSsBrReducer(
  state: ISsBrStore,
  action: IActionSsBr<IActionSSBrPayload | undefined>)
{
  switch(action.type)
  {
    case "setFilters":
      return fnSetFilters(state, action.payload as IActionSSBrPayloadSetFilters);
    case "setFooterPayload":
      return fnSetFooterPayload(state, action.payload as ISsBrFooterPayload);
    case "setShowSelected":
      return fnSetShowSelected(state, action.payload as IActionSSBrPayloadSetShowSelected);
    case "setFilterOverlay":
      return fnSetFilterOverlay(state, action.payload as IActionSSBrPayloadSetFilterOverlay);
    case "setGeneralFilter":
      return fnSetGeneralFilter(state, action.payload as IActionSSBrPayloadSetGeneralFilter);
    case "setAdvancedFilter":
      return fnSetAdvancedFilter(state, action.payload as IActionSSBrPayloadSetAdvancedFilter);
    case "setLayout":
      return fnSetLayout(state, action.payload as IActionSSBrPayloadSetLayout);
    case "setFilterState":
      return fnSetFilterState(state, action.payload as IActionSSBrPayloadFilterState);
    case "setSearchState":
      return fnSetSearchState(state, action.payload as IActionSSBrPayloadSearchState);
    case "removeModule":
      return fnRemoveModule(state, action.payload as IActionSSBrPayloadRemoveModule);
    case "reset":
      return fnReset(state, action.payload as IActionSSBrPayloadReset);
    case "setSortByFieldId":
      return fnSetSortByFieldId(state, action.payload as IActionSSBrPayloadSortByFieldId);
  }
}

export default function CtxSsBrProvider(props: {
  children: React.ReactNode,
  filters?: DtoFieldFilter[],
})
{
  // region props
  const filters = props.filters;

  // region hooks
  const ssBrStore = useRef<ISsBrStore>({
    filterOverlay: false,
    showSelected: false,
    filterState: false,
    searchState: false,
    filters: filters ?? []
  });

  const ssBrCtx = useRef<ICtxSsBr>({} as ICtxSsBr);

  const [state, dispatch] = useReducer(ctxSsBrReducer, ssBrStore.current);

  // region actions

  ssBrCtx.current = {
    getFilters: () => state.filters,
    getSelectedFilters: () => state.selectedFilters,
    getFilterOverlay: () => state.filterOverlay,
    getIsShowSelected: () => state.showSelected,
    getLastRemovedTagId: () => state.lastRemovedTagId,
    getFooterPayload: () => state.footerPayload,
    getFilterState: () => state.filterState,
    getSearchState: () => state.searchState,
    getSortByFieldId: () => state.sortByFieldId
  } as ICtxSsBr;

  ssBrCtx.current.setFooterPayload = useCallback((payload?: ISsBrFooterPayload) =>
  {
    dispatch({
      type: "setFooterPayload",
      payload: payload
    });
  }, []);

  ssBrCtx.current.setFilters = useCallback((payload: IActionSSBrPayloadSetFilters) =>
  {
    dispatch({
      type: "setFilters",
      payload: payload
    });
  }, []);

  ssBrCtx.current.setFilterOverlay = useCallback((payload: IActionSSBrPayloadSetFilterOverlay) =>
  {
    dispatch({
      type: "setFilterOverlay",
      payload: payload
    });
  }, []);

  ssBrCtx.current.setShowSelected = useCallback((payload: IActionSSBrPayloadSetShowSelected) =>
  {
    dispatch({
      type: "setShowSelected",
      payload: payload
    });
  }, []);

  ssBrCtx.current.onChangeGeneralFilter = useCallback((payload: IActionSSBrPayloadSetGeneralFilter) =>
  {
    dispatch({
      type: "setGeneralFilter",
      payload: payload
    });
  }, []);

  ssBrCtx.current.onChangeAdvancedFilter = useCallback((payload: IActionSSBrPayloadSetAdvancedFilter) =>
  {
    dispatch({
      type: "setAdvancedFilter",
      payload: payload
    });
  }, []);

  ssBrCtx.current.onClearAll = useCallback((payload: IActionSSBrPayloadReset) =>
  {
    dispatch({
      type: "reset",
      payload: payload
    });
  }, []);

  ssBrCtx.current.removeModule = useCallback((payload?: IActionSSBrPayloadRemoveModule) =>
  {
    dispatch({
      type: "removeModule",
      payload: payload
    });
  }, []);

  ssBrCtx.current.setFilterState = useCallback((payload?: IActionSSBrPayloadFilterState) =>
  {
    dispatch({
      type: "setFilterState",
      payload: payload
    });
  }, []);

  ssBrCtx.current.setSearchState = useCallback((payload?: IActionSSBrPayloadSearchState) =>
  {
    dispatch({
      type: "setSearchState",
      payload: payload
    });
  }, []);

  ssBrCtx.current.setSortByFieldId = useCallback((payload?: IActionSSBrPayloadSortByFieldId) =>
  {
    dispatch({
      type: "setSortByFieldId",
      payload: payload
    });
  }, []);

  return (
    <ctxSsBr.Provider value={ssBrCtx.current}>
      {props.children}
    </ctxSsBr.Provider>
  );
}

function fnSetFooterPayload(state: ISsBrStore, payload: ISsBrFooterPayload)
{
  return {
    ...state,
    footerPayload: payload
  } as ISsBrStore;
}

function fnSetFilters(state: ISsBrStore, payload: IActionSSBrPayloadSetFilters)
{
  return {
    ...state,
    filters: payload.filters
  };
}

function fnSetShowSelected(state: ISsBrStore, payload: IActionSSBrPayloadSetShowSelected)
{
  return {
    ...state,
    showSelected: payload.showSelected
  };
}

function fnSetFilterOverlay(state: ISsBrStore, payload: IActionSSBrPayloadSetFilterOverlay)
{
  return {
    ...state,
    filterOverlay: payload.filterOverlay
  };
}

function fnSetLayout(state: ISsBrStore, payload: IActionSSBrPayloadSetLayout)
{
  return {
    ...state,
    layout: payload.layout
  } as ISsBrStore;
}

function fnSetGeneralFilter(state: ISsBrStore, payload: IActionSSBrPayloadSetGeneralFilter)
{
  const selectedFilters = [...state.selectedFilters || []];

  const sysId = payload.sysId;
  const filterId = payload.filterId;
  const select = payload.select;

  fnOnChangeGeneralFilters(selectedFilters, filterId, sysId, select);

  return {
    ...state,
    selectedFilters: selectedFilters
  } as ISsBrStore;
}

function fnOnChangeGeneralFilters(
  selectedFilters: SpreadsheetFilterValue[],
  filterId: MetaIdComp,
  sysId: SysId,
  select: boolean
)
{
  const filterIndex = selectedFilters.findIndex(filter => filter.metaIdField === filterId);

  if(filterIndex !== -1)
  {
    const filter = selectedFilters[filterIndex] as SpreadsheetFilterValueSysIdSet;
    if(select)
    {
      filter.valueSet.push(sysId);
    }
    else
    {
      if(filter.valueSet.includes(sysId))
      {
        filter.valueSet = filter.valueSet.filter(value => value !== sysId);
      }
    }

    if(filter.valueSet.length === 0)
    {
      selectedFilters.splice(filterIndex, 1);
    }
    else
    {
      selectedFilters.splice(filterIndex, 1, filter);
    }
  }
  else
  {
    if(select)
    {
      selectedFilters.push({
        type: "sysIdSet",
        metaIdField: filterId,
        valueSet: [sysId]
      } as SpreadsheetFilterValueSysIdSet);
    }
  }
}

function fnSetAdvancedFilter(state: ISsBrStore, payload: IActionSSBrPayloadSetAdvancedFilter)
{
  const selectedFilters = [...state.selectedFilters || []];

  const payloadFilterId = payload.filterId;
  const payloadFilter = payload.filter;

  fnOnChangeAdvancedFilters(selectedFilters, payloadFilterId, payloadFilter);

  return {
    ...state,
    selectedFilters: selectedFilters
  };
}

function fnOnChangeAdvancedFilters(
  selectedFilters: SpreadsheetFilterValue[],
  filterId: MetaIdComp,
  filterValue?: SpreadsheetFilterValue)
{
  const filterIndex = selectedFilters.findIndex(filter => filter.metaIdField === filterId);

  if(filterIndex !== -1)
  {
    if(filterValue)
    {
      selectedFilters.splice(filterIndex, 1, filterValue);
    }
    else
    {
      selectedFilters.splice(filterIndex, 1);
    }
  }
  else if(filterValue)
  {
    selectedFilters.push(filterValue);
  }
}

function fnOnChangeAdvancedFilterStringSet(
  selectedFilters: SpreadsheetFilterValue[],
  filterId: MetaIdComp,
  valueId: string
)
{
  const filterIndex = selectedFilters.findIndex(filter => filter.metaIdField === filterId);
  if(filterIndex !== -1)
  {
    const filter = selectedFilters[filterIndex] as SpreadsheetFilterValueStringSet;
    if(filter.valueSet.includes(valueId))
    {
      filter.valueSet = filter.valueSet.filter(value => value !== valueId);
    }
    else
    {
      filter.valueSet.push(valueId);
    }

    if(isEmpty(filter.valueSet))
    {
      selectedFilters.splice(filterIndex, 1);
    }
    else
    {
      selectedFilters.splice(filterIndex, 1, filter);
    }
  }
  else
  {
    selectedFilters.push({
      type: "stringSet",
      metaIdField: filterId,
      valueSet: [valueId]
    } as SpreadsheetFilterValueSysIdSet);
  }
}

function fnReset(state: ISsBrStore, _payload: IActionSSBrPayloadReset)
{
  return {
    ...state,
    selectedFilters: undefined,
    filterState: false
  } as ISsBrStore;
}

function fnSetSortByFieldId(state: ISsBrStore, _payload: IActionSSBrPayloadSortByFieldId)
{
  return {
    ...state,
    sortByFieldId: _payload.sortByFieldId
  } as ISsBrStore;
}

function fnRemoveModule(state: ISsBrStore, payload?: IActionSSBrPayloadRemoveModule)
{
  if(!payload)
  {
    return {
      ...state,
      lastRemovedTagId: undefined
    };
  }

  const selectedFilters = [...state.selectedFilters || []];
  const filterId = payload.filterId;
  const valueId = payload.valueId;

  const filter = selectedFilters.find(filter => filter.metaIdField === filterId);

  if(filter)
  {
    if(filter.type === "sysIdSet" && valueId)
    {
      fnOnChangeGeneralFilters(selectedFilters, filterId, valueId, false);
    }
    else if(filter.type === "stringSet" && valueId)
    {
      fnOnChangeAdvancedFilterStringSet(selectedFilters, filterId, valueId);
    }
    else
    {
      fnOnChangeAdvancedFilters(selectedFilters, filterId);
    }
  }

  const lastRemovedTagId = toComboId(filterId, valueId || "");

  return {
    ...state,
    selectedFilters: selectedFilters,
    lastRemovedTagId: lastRemovedTagId
  } as ISsBrStore;
}

function fnSetFilterState(state: ISsBrStore, payload: IActionSSBrPayloadFilterState)
{
  return {
    ...state,
    filterState: payload.state
  } as ISsBrStore;
}

function fnSetSearchState(state: ISsBrStore, payload: IActionSSBrPayloadSearchState)
{
  return {
    ...state,
    searchState: payload.search
  } as ISsBrStore;
}
