import {useTheme} from "@mui/material";
import {useMemo} from "react";
import React from "react";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {isFormValueAndFormFieldIsMediaType} from "../../../../base/plus/BubblePlus";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {MAX_TOOLTIP_BUBBLE_WIDTH} from "../../../../base/plus/ConstantsPlus";
import {formatDetailedDateTime} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {TypeCalendarItemId} from "../../../../base/types/TypeCalendar";
import {useAppSelector} from "../../../app/AppHooks";
import helperTextJsonData from "../../../atom/assets/PlaceholderTextHome.json";
import BubbleRawForm from "../../../atom/bubble/raw/bubbleForm/BubbleRawForm";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawNothingHere from "../../../atom/raw/RawNothingHere";

export default function CalendarShellBubbleForm(props: {
  selectCalendar: SelectCalendar,
  itemId: TypeCalendarItemId,
  calendarItemForm?: DefnForm
})
{
  const theme = useTheme();
  const calendarItemForm = props.calendarItemForm;
  const selectCalendar = props.selectCalendar;
  const formValue = useAppSelector(state => selectCalendar(state).itemsById[props.itemId]);
  const creationTime = useAppSelector(state => selectCalendar(state).itemsById[props.itemId].createdOn);
  const valueMap = useAppSelector(state => selectCalendar(state).itemsById[props.itemId].valueMap);

  const bubbleShellWidth = theme.common.bubbleShellFixedWidth;
  const tooltipWrapperWidth = useMemo(() => bubbleShellWidth + gapHalf, [bubbleShellWidth]);
  const toolTipBorderColor = theme.common.borderColor;
  const toolTipBorderRadius = theme.common.gapQuarter;

  const isFormWithMedia = calendarItemForm && valueMap
    ? isFormValueAndFormFieldIsMediaType(calendarItemForm, valueMap)
    : false;

  return (formValue && formValue.valueMap)
    ? <LayoutFlexCol
      width={px(tooltipWrapperWidth)}
      height={"auto"}
      justifyContent={"center"}
      padding={px(gapQuarter)}
      border={`1px solid ${toolTipBorderColor}`}
      borderRadius={px(toolTipBorderRadius)}
    >
      <BubbleRawForm
        isCallerSender={true}
        creationTime={creationTime ? formatDetailedDateTime(new Date(creationTime)) : ""}
        maxWidth={MAX_TOOLTIP_BUBBLE_WIDTH}
        defnForm={calendarItemForm}
        formValue={{
          ...formValue,
          valueMap: formValue.valueMap
        }}
        hideFooter={true}
        isFormWithMedia={isFormWithMedia}
      />
    </LayoutFlexCol>
    : <RawNothingHere helperTextData={helperTextJsonData.nothingToShow} />;
}
