import {sliceFakeList} from "./SliceList";

export const {
  listSetP,
  listSetPS,
  listSetAPS,
  listSetAPSA,

  listSetIfExistPrimaryLine,
  listSetIfExistPrimaryLineCaption,
  listSetIfExistPrimaryLineNumber,
  listSetIfExistPrimaryLineMiddle,
  listSetIfExistSecondaryLine,
  listSetIfExistSecondaryLineBadge,
  listSetIfExistSecondaryLineNumber,
  listSetIfExistSecondaryLineMiddle,
  listSetIfExistSecondaryLineCaption,
  listSetIfExistMultiSecondaryLineNumber,
  listSetIfExistMultiSecondaryLineMiddle,
  listSetIfExistMultiSecondaryLineCaption,
  listSetIfExistSecondaryLineIcon,

  listSetIfExistAvatar,
  listSetIfExistAvatarRight,

  listRemoveIfExistOverwriteSecondary
} = sliceFakeList.actions;
