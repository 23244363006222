import {UploadRounded} from "@mui/icons-material";
import {PaymentsRounded} from "@mui/icons-material";
import {ArrowForwardIosRounded} from "@mui/icons-material";
import {ArrowBackIosRounded} from "@mui/icons-material";
import {OpenInFullRounded} from "@mui/icons-material";
import {CloseFullscreenRounded} from "@mui/icons-material";
import {ClearRounded} from "@mui/icons-material";
import {ContentCopyRounded} from "@mui/icons-material";
import {HistoryRounded} from "@mui/icons-material";
import {Assistant} from "@mui/icons-material";
import {DownloadRounded} from "@mui/icons-material";
import {ContactMailRounded} from "@mui/icons-material";
import {CalculateRounded} from "@mui/icons-material";
import {ContentPasteRounded} from "@mui/icons-material";
import {VisibilityRounded} from "@mui/icons-material";
import {EditRounded} from "@mui/icons-material";
import {AddRounded} from "@mui/icons-material";
import {CheckRounded} from "@mui/icons-material";
import {SendRounded} from "@mui/icons-material";
import {Comment} from "@mui/icons-material";
import React from "react";
import {stripIconButtonSx} from "../../../base/plus/ThemePlus";

export type EnumIconButton =
  | "send"
  | "edit"
  | "add"
  | "paste"
  | "apply"
  | "ok"
  | "cancel"
  | "preview"
  | "calculate"
  | "importXml"
  | "exportXml"
  | "close"
  | "invite"
  | "inviteAll"
  | "and"
  | "or"
  | "statement"
  | "downloadDrive"
  | "widget"
  | "comment"
  | "execute"
  | "history"
  | "expand"
  | "compress"
  | "copy"
  | "clear"
  | "backIos"
  | "forwardIos"
  | "payment"
  | "importExcel"
  | "exportExcel"

export default function IconButtonStrip(props: {
  iconButton: EnumIconButton,
  color?: string
})
{
  const sx = stripIconButtonSx(props.color);
  const iconButton = props.iconButton;
  if(iconButton === undefined)
  {
    return null;
  }

  switch(iconButton)
  {
    case "send":
    case "execute":
      return <SendRounded sx={sx} />;
    case "edit":
      return <EditRounded sx={sx} />;
    case "add":
    case "statement":
    case "and":
    case "or":
      return <AddRounded sx={sx} />;
    case "apply":
      return <CheckRounded sx={sx} />;
    case "preview":
      return <VisibilityRounded sx={sx} />;
    case "paste":
      return <ContentPasteRounded sx={sx} />;
    case "calculate":
      return <CalculateRounded sx={sx} />;
    case "ok":
    case "cancel":
    case "importXml":
    case "exportXml":
      return null;
    case "importExcel":
      return <UploadRounded sx={sx} />;
    case "close":
      return <CheckRounded sx={sx} />;
    case "invite":
    case "inviteAll":
      return <ContactMailRounded sx={sx} />;
    case "downloadDrive":
    case "exportExcel":
      return <DownloadRounded sx={sx} />;
    case "widget":
      return <Assistant sx={sx} />;
    case "comment":
      return <Comment sx={sx} />;
    case "history":
      return <HistoryRounded sx={sx} />;
    case "expand":
      return <OpenInFullRounded sx={sx} />;
    case "compress":
      return <CloseFullscreenRounded sx={sx} />;
    case "copy":
      return <ContentCopyRounded sx={sx} />;
    case "clear":
      return <ClearRounded sx={sx} />;
    case "backIos":
      return <ArrowBackIosRounded sx={sx} />;
    case "forwardIos":
      return <ArrowForwardIosRounded sx={sx} />;
    case "payment":
      return <PaymentsRounded sx={sx} />;
  }
}
