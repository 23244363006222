import {useTheme} from "@mui/material";
import {cloneDeep} from "lodash";
import {useMemo} from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import {ValidationResult} from "../../../api/meta/base/dto/ValidationResult";
import {EnumDefnThemeColor} from "../../../api/meta/base/Types";
import {MetaId} from "../../../api/meta/base/Types";
import {getValidationErrorMessage} from "../../../api/nucleus/base/Protocol";
import {SelectList} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getJumpStepFromStudioEntStep} from "../../../base/plus/StudioPlus";
import {getJumpStepsFromPath} from "../../../base/plus/StudioPlus";
import {listSetIfExistInfoSpots} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IFormFieldError} from "../../../base/types/TypesForm";
import {EnumStudioSearchPathKeys} from "../../../base/types/TypesStudio";
import {useAppSelector} from "../../app/AppHooks";
import {IPopoverMsg} from "../../atom/raw/RawPopover";
import {usePageCtx} from "../../ctx/CtxPage";

export function getListValidationErrorList(
  sectionName: EnumStudioSearchPathKeys,
  elementId?: MetaId,
  validationResult?: ValidationResult)
{
  const errorList = [] as IFormFieldError[];
  if(validationResult?.errorMap)
  {
    Object.entries(validationResult.errorMap).forEach(([key, value]) =>
    {
      const errMsg = getValidationErrorMessage(cloneDeep(value));
      const errMsgChild = [] as string[];
      if(value.children)
      {
        Object.entries(value.children).forEach(([key, value]) =>
        {
          errMsgChild.push(getValidationErrorMessage(cloneDeep(value)));
        });
      }
      let steps = getJumpStepsFromPath(key);

      const stepIndex = steps.findIndex(step =>
        step.step === (getJumpStepFromStudioEntStep(sectionName) || sectionName));
      if(stepIndex > -1)
      {
        if(elementId)
        {
          if(steps[0]?.itemId !== elementId)
          {
            steps = [];
          }
          else
          {
            steps = steps.slice(stepIndex + 1);
          }
        }
        else if(!elementId)
        {
          steps = steps.slice(stepIndex);
        }
        steps.forEach(step =>
        {
          if(step.itemId)
          {
            errorList.push({
              key: step.itemId,
              errorOption: {
                type: "error",
                message: errMsg
              },
              errorChildrenOption: errMsgChild.map((msg) => ({
                type: "error",
                message: msg
              }))
            } as IFormFieldError);
          }
        });
      }
    });
  }
  return errorList;
}

export function useValidationErrorList(
  selectList: SelectList,
  sectionName: EnumStudioSearchPathKeys,
  elementId?: MetaId,
  validationResult?: ValidationResult,
  skipErrorSet?: TypeListItemId[]
)
{
  const pageCtx = usePageCtx();
  const theme = useTheme();
  const errorList = useMemo(() => getListValidationErrorList(sectionName, elementId, validationResult),
    [validationResult]
  );
  const listName = useAppSelector(state => selectList(state).listName);
  const itemsById = useAppSelector(state => selectList(state).itemsById);

  const onClickItemInfo = useCallback((anchorEl: Element, itemId: TypeListItemId) =>
  {
    const filteredErrorList = errorList.filter(err => err.key === itemId);
    const msg = filteredErrorList.map(err =>
    {
      return {
        msg: err.errorOption.message as string,
        children: err.errorChildrenOption?.map(child => child.message as string)
      } as IPopoverMsg;
    });
    errorList && pageCtx.showPopover(anchorEl, {
      msg: msg,
      bgcolor: theme.common.bgcolorError
    });
  }, [errorList]);

  useEffect(() =>
  {
    if(errorList && listName)
    {
      const infoSpotErrors = [] as {itemId: TypeListItemId, infoSpot?: EnumDefnThemeColor}[];
      errorList.forEach(err =>
      {
        if(!skipErrorSet || !skipErrorSet.includes(err.key))
        {
          infoSpotErrors.push({
            itemId: err.key,
            infoSpot: "error"
          });
        }
      });

      dispatchList(listName, listSetIfExistInfoSpots(infoSpotErrors));
    }
  }, [errorList, itemsById]);

  return onClickItemInfo;
}
