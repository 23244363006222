import React from "react";
import {DividerHorizontal} from "../atom/layout/DividerHorizontal";
import LayoutFlexRow from "../atom/layout/LayoutFlexRow";
import PaneFooter from "../atom/pane/PaneFooter";
import {useSsBrCtx} from "./base/CtxSsBr";

export default function SsBrFooter(props: {
  onClickSubmit?: () => void
})
{
  const onClickSubmit = props.onClickSubmit;
  const spreadSheetBrCtx = useSsBrCtx();

  const footerPayload = spreadSheetBrCtx.getFooterPayload();
  const totalItems = footerPayload?.totalItems;
  const selectedItems = footerPayload?.selectedItems;
  const submitEnable = footerPayload?.submitEnable;

  return (
    <>
      <DividerHorizontal />
      <PaneFooter
        fabButton={{
          icon: "check",
          position: "right",
          disable: !submitEnable,
          tooltip: "Submit"
        }}
        onFabClick={onClickSubmit}
      >
        <LayoutFlexRow>
          {
            selectedItems
              ? `Total selected: ${selectedItems} of ${totalItems}`
              : undefined
          }
        </LayoutFlexRow>
      </PaneFooter>
    </>
  );
}
