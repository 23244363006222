import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FieldValues} from "react-hook-form";
import {nextMetaIdVar} from "../../api/meta/base/ApiPlus";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {StudioVar} from "../../api/meta/base/dto/StudioVar";
import {EnumStudioVarKind, MetaIdField, MetaIdVar} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../base/plus/JsPlus";
import {arrayToOptions} from "../../base/plus/JsPlus";
import {toLabel} from "../../base/plus/StringPlus";
import {toSymbolCase} from "../../base/plus/StringPlus";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {getDefnToDtoVariableValue} from "../varBldr/base/MapDefnToDtoVariable";
import {dtoVariableToDefn} from "../varBldr/base/MapDtoVariableToDefn";
import {getDtoToDefnVariableValue} from "../varBldr/base/MapDtoVariableToDefn";
import {fieldVariableUserSettingValueKind} from "../varBldr/base/VariablePlus";
import {useVariableValueInput} from "../varBldr/base/VariablePlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const notAllowedVarKind = [
  "any",
  "icon",
  "location",
  "calendar",
  "condition",
  "function",
  "mapping",
  "scheduler",
  "setOfDay",
  "setOfNumber",
  "setOfText",
  "setOfTime",
  "setOfUser",
  "tree",
  "mapOfText"
] as EnumStudioVarKind[];

export default function DialogAddNewVariable(props: {
  formStore?: FormStore,
  varKindSet: EnumStudioVarKind[],
  value?: StudioVar,
  onClickOk: (values: StudioVar) => void,
  onClose?: () => void,
  fieldName?: string,
  fieldPropertyName?: string,
  filterOptionSet?: string[],
  formName?: string
})
{
  const formRef = useMemo(() => ({} as IFormRef), []);

  const onClickOK = props.onClickOk;
  const studioVar = props.value;
  const varKindSet = props.varKindSet;
  const formStore = props.formStore;
  const fieldName = props.fieldName ?? "";
  const formName = props.formName ?? "";
  const fieldPropertyName = props.fieldPropertyName ?? "";
  const filterOptionSet = props.filterOptionSet;
  const derivedName = ("Var" + fieldPropertyName + formName + fieldName).replace("Variable", "");
  const varKindOptions = useMemo(() => arrayToOptions(varKindSet), [varKindSet]);

  const [selectedVariableType, setSelectedVariableType] = useState<EnumStudioVarKind>(varKindSet[0]);

  const {
    variablePropertyTab,
    onWatch,
    onFormClick: onClickFormItem
  } = useVariableValueInput(selectedVariableType, undefined, undefined, true, filterOptionSet);

  const onSubmit = useCallback((values: FieldValues) =>
  {
    const dto = defnToDto(values, studioVar);
    if(dto)
    {
      onClickOK(dto);
    }
  }, [formRef, onClickOK, studioVar]);

  const onWatchForm = useCallback((key: MetaIdField, value: any) =>
  {
    if(key === fieldVariableType && value)
    {
      if(studioVar?.kind === value)
      {
        formRef.setValue(fieldKeyName, fnRawValueToFieldValue("symbol", studioVar?.details.name));
      }
      else
      {
        formRef.setValue(fieldKeyName, fnRawValueToFieldValue("symbol", toSymbolCase(value)));
      }

      setSelectedVariableType(value);
    }

    onWatch(key, value);
  }, [formRef, onWatch, studioVar?.details.name, studioVar?.kind]);

  useEffect(() =>
  {
    if(formRef.setValue)
    {
      formRef.setValue(fieldKeyName, fnRawValueToFieldValue("symbol", varKindSet[0]));
    }
  }, [varKindSet]);

  useEffect(() =>
  {
    if(studioVar)
    {
      Object.entries(dtoVariableToDefn(studioVar))
      .forEach(([key, value]) =>
      {
        onWatchForm(key, value);
      });
    }
  }, [studioVar]);

  return (
    <DialogDefnForm
      formProps={{
        cbRef: formRef,
        store: formStore,
        defnForm: getDefnForm(
          varKindOptions,
          variablePropertyTab,
          selectedVariableType,
          (varKindSet.length === 1 || Boolean(studioVar)),
          props.value?.deploy === "requiredOnDeploy"
        ),
        onSubmit: onSubmit,
        initValues: {
          ...dtoToDefn(studioVar, varKindSet.length === 1
            ? varKindSet[0]
            : undefined),
          [fieldVariableType]: fnRawValueToFieldValue("pickText", varKindSet[0]),
          ...!Boolean(studioVar) && {
            [fieldKeyName]: derivedName ?? ""
          }
        },
        onWatch: onWatchForm,
        onClickFormItem: onClickFormItem
      }}
      title={`${toLabel(fieldPropertyName ?? "Variable")}`}
      onClose={props.onClose}
    />
  );
}

const fieldVariableType = "fieldVariableType";

function getDefnForm(
  varKindOptions: DefnDtoOption[],
  variablePropertyTab: Record<MetaIdVar, DefnField>,
  varKind: EnumStudioVarKind,
  disableType?: boolean,
  disableValueProperty?: boolean
)
{
  const prepareVariablePropertyTab = excludeVarBuilderProperties(varKind, variablePropertyTab);
  return createDefaultDefnFormStudio({
    [fieldVariableType]: {
      type: "pickText",
      name: "Variable type",
      metaId: fieldVariableType,
      disabled: disableType,
      required: true,
      optionMap: optionsToMapOfOption(varKindOptions)
    } as DefnFieldPickText,

    [fieldKeyName]: {
      type: "symbol",
      name: "Name",
      label: "Name",
      required: true,
      metaId: fieldKeyName
    } as DefnFieldEditable,

    ...!notAllowedVarKind.includes(varKind) && !disableValueProperty
      ? prepareVariablePropertyTab
      : {},

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [
        fieldVariableType,
        fieldKeyName,
        ...!notAllowedVarKind.includes(varKind) && !disableValueProperty
          ? Object.keys(prepareVariablePropertyTab)
          : []
      ]
    } as DefnSection
  });
}

function defnToDto(
  values: FieldValues,
  studioVar?: StudioVar
): StudioVar | undefined
{
  let variable = {
    ...studioVar,
    kind: studioVar?.kind || fnFieldValueToRawValue("pickText", values[fieldVariableType]),
    deploy: studioVar?.deploy || "fixedOnDeploy",
    metaId: studioVar?.metaId || nextMetaIdVar()
  } as StudioVar;

  if(variable.details)
  {
    variable.details = {
      ...variable.details,
      name: values[fieldKeyName]
    };
  }
  else
  {
    variable.details = {
      name: values[fieldKeyName]
    };
  }

  if(variable.deploy === "fixedOnDeploy")
  {
    variable = {
      ...variable,
      ...getDefnToDtoVariableValue(values, variable.kind)
    };
  }

  return variable;
}

function dtoToDefn(
  studioVar?: StudioVar,
  varKind?: EnumStudioVarKind
): FieldValues | undefined
{
  if(!studioVar && varKind)
  {
    return {
      [fieldVariableType]: fnRawValueToFieldValue("pickText", varKind)
    };
  }

  if(!studioVar)
  {
    return undefined;
  }

  const valueMap = {} as FieldValues;

  valueMap[fieldKeyName] = studioVar.details.name;
  valueMap[fieldVariableType] = fnRawValueToFieldValue("pickText", studioVar.kind);

  getDtoToDefnVariableValue(studioVar.kind, studioVar, valueMap);

  return valueMap;
}

function excludeVarBuilderProperties(varKind: EnumStudioVarKind, propertyTab: Record<MetaIdField, DefnField>)
{
  switch(varKind)
  {
    case "userSetting":
      return {
        [fieldVariableUserSettingValueKind]: propertyTab[fieldVariableUserSettingValueKind]
      };
    default:
      return propertyTab;
  }
}
