import {IconButton} from "@mui/material";
import {Typography} from "@mui/material";
import {InputLabel} from "@mui/material";
import {useTheme} from "@mui/material";
import {Select} from "@mui/material";
import {Box} from "@mui/material";
import {isEmpty} from "lodash";
import {useState} from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import * as React from "react";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickTree} from "../../../../api/meta/base/dto/DefnFieldPickTree";
import {FieldDtoTree} from "../../../../api/meta/base/dto/FieldDtoTree";
import {FieldDtoTreeNode} from "../../../../api/meta/base/dto/FieldDtoTreeNode";
import {FieldValueOptionId} from "../../../../api/meta/base/dto/FieldValueOptionId";
import {EnumDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {getLabelFromOptionTree} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {ITreeNode} from "../../../../base/types/TypesTree";
import IconStrip from "../../../atom/icon/IconStrip";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import RawTooltip from "../../../atom/raw/RawTooltip";
import RawTree from "../../../atom/raw/RawTree";
import {useFormCtx} from "../base/CtxForm";

export default function FieldRawPickTree(props: {
  defn: DefnFieldPickTree;
  onChange: (value?: FieldValueOptionId | null) => void;
  label?: string;
  required?: boolean;
  value?: FieldValueOptionId;
  placeHolder?: string;
  autoFocus?: boolean;
  fieldSize?: EnumDefnThemeFieldSize;
  readOnly?: boolean
})
{
  const theme = useTheme();
  const formCtx = useFormCtx();
  const defn = props.defn;
  const label = props.label;
  const fieldSize = props.fieldSize;
  const fieldValue = props.value;

  const fieldId = getFieldKey(defn);
  const readOnly = formCtx.isReadonly() || !Boolean(defn.metaId) || formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const getOption = formCtx.getOnGetFieldOptions();
  const onClick = formCtx.getOnClick();
  const isPluginOptions = defn.pluginApi;
  const forceLeafSelection = defn.forceLeafSelection;

  const required = props.required;
  const placeHolder = props.placeHolder;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rootNodes, setRootNodes] = useState<ITreeNode[]>(prepareOptions(defn.sourceVar));
  const [selectedNodes, setSelectedNodes] =
    useState<ITreeNode[] | undefined>(dtoToDefnValue(rootNodes, fieldValue));
  const [selectedNodesAfterAnimation, setSelectedNodesAfterAnimation] = useState<ITreeNode[] | undefined>(dtoToDefnValue(
    rootNodes,
    fieldValue
  ));
  const [defaultExpanded, setDefaultExpanded] = useState<string[]>([]);
  const [defaultSelected, setDefaultSelected] = useState<string>();
  const [valueLabel, setValueLabel] = useState<string | undefined>();
  const [sourceVar, setSourceVar] = useState<FieldDtoTree>();
  const [shrink, setShrink] = useState<boolean>(!isEmpty(fieldValue));

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const getOptions = useCallback(() =>
  {
    if(isPluginOptions && getOption)
    {
      setLoading(true);
      getOption(fieldId, (options) =>
      {
        setSourceVar(options as FieldDtoTree);
        const treeNodes = prepareOptions(options as FieldDtoTree);
        if(fieldValue?.value)
        {
          const selectedNodes = dtoToDefnValue(treeNodes, fieldValue);
          setSelectedNodes(selectedNodes);
        }
        setRootNodes(treeNodes);
        setLoading(false);
      });
    }
  }, []);

  const handleSelectClick = () =>
  {
    if(!readOnly && !disabled)
    {
      setOpen((prevOpen) => !prevOpen);
    }

    onClick && onClick(fieldId, "field");
  };

  const onChange = (value?: ITreeNode[] | null) =>
  {
    const length = value?.length || 1;
    const lastNode = value ? value[length - 1] : undefined;
    props.onChange(lastNode ? defnValueToDto(defn, lastNode, defn.sourceVar || sourceVar) : null);
  };

  const getLabel = (node: ITreeNode) =>
  {
    return <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "40px"
      }}
    >{node.name}</Box>;
  };

  const renderValue = useCallback(() =>
    <Box
      sx={{
        position: "relative"
      }}
    >
      <RawTooltip title={valueLabel}>
        <Typography
          sx={
            {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(100% - 20px)",
              textAlign: "left"
            }
          }
        >{valueLabel}</Typography>
      </RawTooltip>
    </Box>, [valueLabel]);

  useEffect(() =>
  {
    setSelectedNodes(dtoToDefnValue(rootNodes, fieldValue));
  }, [fieldValue]);

  useEffect(() =>
  {
    if(!isPluginOptions)
    {
      const rootNodes = prepareOptions(defn.sourceVar);
      setRootNodes(rootNodes);
    }
  }, [isPluginOptions, defn.sourceVar]);

  useEffect(() =>
  {
    if(isPluginOptions)
    {
      getOptions();
    }
  }, []);

  useEffect(() =>
  {
    const defaultExpanded = selectedNodes?.map(o => o.id) || [];
    const defaultSelected = defaultExpanded.pop();

    setDefaultExpanded(defaultExpanded);
    setDefaultSelected(defaultSelected);

    let resultValueLabel = fieldValue?.value;

    if(selectedNodes)
    {
      if(forceLeafSelection)
      {
        const filteredNodes = selectedNodes.filter(node => (!node.children?.length || node.children.length === 0));
        resultValueLabel = filteredNodes?.[0]?.name;
      }
      else
      {
        resultValueLabel = selectedNodes.map(node => `${node.name}`).join(" > ");
      }
    }

    setValueLabel(prevState => resultValueLabel || prevState);

    if(!selectedNodes || !selectedNodes?.length)
    {
      setShrink(false);
    }

  }, [fieldValue?.value, selectedNodes, forceLeafSelection]);

  useEffect(() =>
  {
    if(open)
    {
      getOptions();
    }
    else
    {
      setLoading(false);
    }
  }, [open]);

  return (
    <>
      <InputLabel
        htmlFor={fieldId}
        id={`label-${fieldId}`}
        size={"small"}
        disabled={disabled}
        {...(shrink && {shrink: true})}
      >
        <RawHighlighter
          value={props.required ? label + " *" : label}
          variant={"body1"}
          flexGrow={1}
          color={disabled ? theme.palette.text.disabled : theme.palette.text.secondary}
        />
      </InputLabel>
      <Select
        id={fieldId}
        labelId={`label-${fieldId}`}
        size={fieldSize}
        fullWidth={true}
        label={label}
        placeholder={placeHolder}
        required={required}
        open={open}
        onKeyDown={(event) =>
        {
          if(event.key === "Enter")
          {
            handleSelectClick();
          }
        }}
        sx={{
          ...borderColor && {
            "& .MuiInputBase-input": {
              borderColor: borderColor,
              borderStyle: "solid",
              borderWidth: "1px !important"
            }
          }
        }}
        onClick={() => handleSelectClick()}
        readOnly={readOnly}
        disabled={disabled}
        name={defn.name}
        autoFocus={props.autoFocus}
        multiple={true}
        {...shrink && {notched: true}}
        value={selectedNodes
          || (fieldValue?.value ? [fieldValue?.value] : undefined)
          || []} // hack 'value?.valueArray' for show value in select
        endAdornment={(fieldValue || loading) &&
          <Box
            position={"relative"}
            right={gapHalf}
          >
            {!readOnly &&
              <IconButton
                disabled={disabled}
                size={"small"}
                sx={{
                  ...!loading && {width: 20, height: 20, mr: px(gapStd)}
                }}
                onClick={(e) =>
                {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedNodes([]);
                  setSelectedNodesAfterAnimation([]);
                  handleSelectClick();
                  onChange([]);
                  setValueLabel(undefined);
                }}
                tabIndex={-1}
              >
                <IconStrip
                  value={loading ? "loading" : "close"}
                  disabled={disabled}
                  color={theme.common.colorWithShade("grey", "s500")}
                />
              </IconButton>
            }
          </Box>
        }
        renderValue={renderValue}
      >
        <Box
          sx={{
            height: "auto",
            transition: "height 0.3s ease-in-out",
            overflow: "hidden"
          }}
        >
          {(loading && rootNodes.length <= 0)
            ? <RawNothingHere helperTextData={{title: "loading..."}} />
            : <RawTree
              onClick={(nodeId) =>
              {
                const nodes = findNodeAndParents(nodeId, rootNodes);
                const isLast = isLastNode(nodeId, rootNodes);
                if(!isLast)
                {
                  handleSelectClick();
                }
                setSelectedNodes(nodes || undefined);
                setTimeout(() =>
                {
                  setSelectedNodesAfterAnimation(nodes || undefined);
                }, 300);

                if(!forceLeafSelection || (forceLeafSelection && isLast))
                {
                  onChange(nodes);
                }
              }}
              defaultSelected={defaultSelected}
              defaultExpanded={defaultExpanded}
              getLabel={getLabel}
              rootNodes={rootNodes}
            />
          }
        </Box>
      </Select>
    </>
  );
}

function prepareOptions(sourceVar?: FieldDtoTree): ITreeNode[]
{
  const rootNodes: ITreeNode[] = [];

  function prepareOption(dto: FieldDtoTreeNode): ITreeNode
  {
    return {
      id: dto.metaId,
      name: (dto.value || ""),
      children: dto.keys?.map(key => prepareOption(dto.map[key]))
    };
  }

  if(sourceVar)
  {
    sourceVar.keys.forEach(key =>
    {
      const dto = sourceVar.map[key];
      const option = prepareOption(dto);
      rootNodes.push(option);
    });
  }
  return rootNodes;
}

function isLastNode(nodeId: string, nodes: ITreeNode[]): boolean
{
  let isLast = false;

  for(let node of nodes)
  {
    if(node.id === nodeId)
    {
      if(isEmpty(node.children))
      {
        isLast = true;
      }
    }

    if(node.children)
    {
      if(isLastNode(nodeId, node.children))
      {
        return true;
      }
    }
  }
  return isLast;
}

function findNodeAndParents(nodeId: string, nodes: ITreeNode[]): ITreeNode[] | null
{
  for(let node of nodes)
  {
    if(node.id === nodeId)
    {
      // Node found, return an array containing only this node
      return [node];
    }
    else if(node.children)
    {
      // Recursively search in children
      const result = findNodeAndParents(nodeId, node.children);
      if(result)
      {
        // Node found in children, return an array containing this node and the result
        return [node, ...result];
      }
    }
  }
  // Node not found
  return null;
}

function defnValueToDto(
  defn: DefnFieldPickTree,
  node: ITreeNode,
  sourceVar?: FieldDtoTree): FieldValueOptionId | undefined
{
  //Only send value if it's plugin
  const nodeId = node.id;
  const value = (defn.pluginApi && nodeId)
    ? getLabelFromOptionTree(sourceVar, nodeId)
    : undefined;
  const dtoValue = {
    optionId: node.id,
    value: value
  };
  return dtoValue as FieldValueOptionId;
}

function dtoToDefnValue(treeOptions: ITreeNode[], value?: FieldValueOptionId): ITreeNode[] | undefined
{
  const firstNode = value?.optionId;

  if(firstNode)
  {
    return findNodeAndParents(firstNode, treeOptions) || undefined;
  }
}
