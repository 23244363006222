import {Box} from "@mui/material";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {useState} from "react";
import React from "react";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleLineText} from "../../../../base/types/TypesBubble";
import {IBubbleLineIcon} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import IconStrip from "../../icon/IconStrip";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import {LayoutGap} from "../../layout/LayoutGap";

export default function BubbleLineHeader(props: {
  icon?: IBubbleLineIcon
  primary?: IBubbleLineText,
  caption?: IBubbleLineText,
  heightLine?: number,
  onClickPrimary?: CbMenuAnchor
})
{
  const theme = useTheme();
  const gapQuarter = theme.common.gapQuarter;
  const gapHalf = theme.common.gapHalf;
  const {
    icon,
    primary,
    caption,
    heightLine
  } = props;

  return (
    <LayoutFlexRow
      width={"100%"}
      height={px(heightLine)}
      pl={px(5)}
      pr={px(24)}
    >

      {
        icon &&
        <>
          <IconStrip
            value={icon.iconName}
            color={icon.color}
          />
          <LayoutGap width={px(gapHalf)} />
        </>
      }

      {
        primary &&
        <TextHeader
          value={{
            ...primary,
            variant: "caption"
          }}
          onClick={props.onClickPrimary}
        />
      }

      <LayoutGap flexGrow={1} />

      {
        caption &&
        <TextHeader value={caption} />
      }

      {
        caption !== undefined &&
        <LayoutGap width={px(gapQuarter)} />
      }

      <LayoutGap width={px(gapQuarter)} />

    </LayoutFlexRow>
  );
}

function TextHeader(props: {
  value: IBubbleLineText
  onClick?: CbMenuAnchor
})
{
  const {value} = props;
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const onClick = props.onClick;
  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Typography
        variant={"subtitle2"}
        noWrap={true}
        sx={{
          color: value.color || theme.common.color("primary"),
          fontWeight: value.bold ? 500 : 400,
          overflow: "hidden"
        }}
      >
        <Box
          onClick={onClick
            ? (e: React.MouseEvent<HTMLElement>) =>
            {
              e.stopPropagation();
              onClick(e.currentTarget);
            }
            : undefined}
          component={"a"}
          sx={{
            cursor: hover ? "pointer" : "none",
            borderBottom: hover ? "1px solid" : "none"
          }}
        >
          {value.text}
        </Box>
      </Typography>
    </Box>
  );
}

