import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldMonth} from "../../../../api/meta/base/dto/DefnFieldMonth";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {getMonthEnum} from "../../builder/base/FieldBuilderPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickMonth(props: {
  defn: DefnFieldMonth
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
        <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={defn}
          optionMap={arrayToMapOfOption(getMonthEnum())}
          multiSelect={false}
          showChip={false}
        />
      }
    />
  );
}
