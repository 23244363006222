import {languages} from "monaco-editor";
import {editor} from "monaco-editor";
import {EnumCliSuggestionIcon} from "../../../../api/app/base/Types";
import theme from "../../../../base/plus/ThemePlus";
import {EnumMonacoThemeType} from "./Types";
import CompletionItemKind = languages.CompletionItemKind;

export const themeName = "neoTheme";
export const languageId = "neoLang";
export const lineHeight = 22;

export const colorString = "#9c27b0";
export const colorComment = "#cddc39";
export const colorCommand = "#2962ff";
export const colorWhat = "#795548";
export const colorArgument = "#ff9800";
export const colorBoolean = "#e91e63";
export const colorDisabled = "rgba(0, 0, 0, 0.001)";

const defaultColor = "#ffffff";

export function getColor(themeType?: EnumMonacoThemeType, readOnly?: boolean)
{
  switch(themeType)
  {
    case "success":
      return theme.common.terminalBgcolorSuccess;
    case "error":
      return theme.common.terminalBgcolorError;
    case "pending":
      return theme.common.terminalBgcolorPending;
    case "input":
      return readOnly ? colorDisabled : defaultColor;
    default:
      return defaultColor;
  }
}

export function getTheme(): editor.IStandaloneThemeData
{
  return {
    base: "vs",
    inherit: false,
    rules: [
      {
        token: "string",
        foreground: colorString
      },
      {
        token: "comment",
        foreground: colorComment
      },
      {
        token: "command",
        foreground: colorCommand
      },
      {
        token: "what",
        foreground: colorWhat
      },
      {
        token: "argument",
        foreground: colorArgument
      },
      {
        token: "boolean",
        foreground: colorBoolean
      },
      {
        token: "cli-token",
        foreground: "#2962ff"
      }
    ],
    colors: {
      "editor.selectionBackground": "#ffff00",
      "editor.lineHighlightBorder": "#00000000",
      "editorSuggestWidget.selectedBackground": "#1976d2"
      //https://microsoft.github.io/monaco-editor/playground.html?source=v0.38.0#example-customizing-the-appearence-exposed-colors
    }
  };
}

export function getInsertText(suggestion: string, lastLine: string)
{
  const lastLineTrimmed = lastLine.trim();
  if(lastLineTrimmed.endsWith(":"))
  {
    return "\"" + suggestion + "\"";
  }
  else
  {
    return suggestion;
  }
}

export const suggestionTriggers = [
  " ",
  ":",
  "\"",
  ","
];

export const monacoOptions: editor.IStandaloneEditorConstructionOptions = {
  fontSize: 16,
  lineHeight: lineHeight,
  lineNumbers: "off",
  renderLineHighlight: "none",
  cursorStyle: "line-thin",
  overviewRulerLanes: 0,
  overviewRulerBorder: false,
  hideCursorInOverviewRuler: true,
  glyphMargin: false,
  folding: false,
  lineDecorationsWidth: 0,
  scrollBeyondLastLine: false,
  lineNumbersMinChars: 0,
  automaticLayout: true,
  minimap: {
    enabled: false
  },
  fixedOverflowWidgets: true,
  scrollbar: {
    horizontal: "auto",
    vertical: "auto",
    verticalHasArrows: false,
    verticalSliderSize: 8,
    verticalScrollbarSize: 8,
    horizontalScrollbarSize: 8,
    horizontalSliderSize: 8,
    useShadows: false,
    alwaysConsumeMouseWheel: false
  },
  occurrencesHighlight: "off",
  // @ts-ignore
  wordBasedSuggestions: false // when suggestion empty don't show previously typed words , bug need to fix from monaco
                              // side
  //wordWrap: "on"
  //https://microsoft.github.io/monaco-editor/typedoc/variables/editor.EditorOptions.html
};

export const readableOptions: editor.IStandaloneEditorConstructionOptions = {
  ...monacoOptions,
  automaticLayout: true,
  readOnly: true,
  domReadOnly: true,
  // @ts-ignore
  renderIndentGuides: false,
  dragAndDrop: false,
  contextmenu: false,
  keyboard: false
};

export function getCliSuggestionIcon(kind: EnumCliSuggestionIcon): CompletionItemKind
{
  switch(kind)
  {
    case "Method":
      return languages.CompletionItemKind.Method;
    case "Function":
      return languages.CompletionItemKind.Function;
    case "Constructor":
      return languages.CompletionItemKind.Constructor;
    case "Field":
      return languages.CompletionItemKind.Field;
    case "Variable":
      return languages.CompletionItemKind.Variable;
    case "Class":
      return languages.CompletionItemKind.Class;
    case "Struct":
      return languages.CompletionItemKind.Struct;
    case "Interface":
      return languages.CompletionItemKind.Interface;
    case "Module":
      return languages.CompletionItemKind.Module;
    case "Property":
      return languages.CompletionItemKind.Property;
    case "Event":
      return languages.CompletionItemKind.Event;
    case "Operator":
      return languages.CompletionItemKind.Operator;
    case "Unit":
      return languages.CompletionItemKind.Unit;
    case "Value":
      return languages.CompletionItemKind.Value;
    case "Constant":
      return languages.CompletionItemKind.Constant;
    case "Enum":
      return languages.CompletionItemKind.Enum;
    case "EnumMember":
      return languages.CompletionItemKind.EnumMember;
    case "Keyword":
      return languages.CompletionItemKind.Keyword;
    case "Text":
      return languages.CompletionItemKind.Text;
    case "Color":
      return languages.CompletionItemKind.Color;
    case "File":
      return languages.CompletionItemKind.File;
    case "Reference":
      return languages.CompletionItemKind.Reference;
    case "CustomColor":
      return languages.CompletionItemKind.Customcolor;
    case "Folder":
      return languages.CompletionItemKind.Folder;
    case "TypeParameter":
      return languages.CompletionItemKind.TypeParameter;
    case "User":
      return languages.CompletionItemKind.User;
    case "Issue":
      return languages.CompletionItemKind.Issue;
    default:
      return languages.CompletionItemKind.Snippet;
  }
}
