import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {OverlayView} from "@react-google-maps/api";
import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {MediaIdAvatar} from "../../api/meta/base/Types";
import {px} from "../../base/plus/StringPlus";
import {gapHalf} from "../../base/plus/ThemePlus";
import {gapQuarter} from "../../base/plus/ThemePlus";
import helperTextJsonData from "../atom/assets/PlaceholderTextHome.json";
import RawHighlighter from "../atom/raw/RawHighlighter";

export interface IMarkerTooltip
{
  showTooltip: boolean;
  value?: MediaIdAvatar;
}

export function MarkerTooltip(props: {
  position: google.maps.LatLng,
  children: React.ReactNode
})
{
  const theme = useTheme();
  const position = props.position;
  const children = props.children;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() =>
  {
    let timeOutId: any;
    if(position)
    {
      timeOutId = setTimeout(() =>
      {
        setIsVisible(true);
      }, 500);
    }

    return () =>
    {
      clearTimeout(timeOutId);
    };

  }, [position]);

  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -height
  });

  if(!isVisible)
  {
    return null;
  }

  return (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <Box
        sx={{
          minWidth: px(150),
          minHeight: px(40),
          maxWidth: px(300),
          bgcolor: theme.common.bgcolorTooltip,
          borderRadius: px(gapQuarter),
          padding: px(gapHalf),
          position: "absolute",
          bottom: 10,
          right: -50
        }}
      >
        {children}
      </Box>
    </OverlayView>
  );
}

export function TooltipTitle(props: {
  tooltip: IMarkerTooltip
})
{
  const darkTheme = createTheme({
    palette: {
      mode: "dark"
    }
  });
  const tooltip = props.tooltip;
  if(!tooltip.showTooltip)
  {
    return null;
  }

  return (
    <RawHighlighter
      value={tooltip.value ? tooltip.value : helperTextJsonData.nothingToShow.title}
      variant={"caption"}
      breakWords={true}
      maxNumberOfLine={6}
      noWrap={false}
      color={darkTheme.palette.text.primary}
    />
  );
}
