import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {CircularProgressProps} from "@mui/material";
import {CircularProgress} from "@mui/material";
import {TypeMuiSupportedColor} from "../../../base/plus/ThemePlus";
import {EnumProgressInnerIcon} from "../icon/IconProgressInner";
import IconProgressInner from "../icon/IconProgressInner";

export default function RawProgress(props: {
  value: number,
  color?: TypeMuiSupportedColor,
  size?: number,
  innerIconSize?: number,
  innerIcon?: EnumProgressInnerIcon
})
{
  const innerIcon = props.innerIcon;
  if(innerIcon)
  {
    return (
      <ProgressWithIcon
        icon={innerIcon}
        size={props.size}
        variant="determinate"
        value={props.value}
        color={props.color}
      />
    );
  }
  else
  {
    return <CircularProgress variant="determinate" {...props} />;
  }
}

function ProgressWithIcon(props: CircularProgressProps & {
    icon: EnumProgressInnerIcon,
    iconSize?: number,
  }
)
{
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex"
      }}
    >
      <CircularProgress variant="determinate"  {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <IconProgressInner
          icon={props.icon}
          size={props.iconSize || theme.common.bubbleFooterTimerInnerIconSize}
        />
      </Box>
    </Box>
  );
}
