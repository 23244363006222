import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {isEmpty} from "lodash";
import {Fragment} from "react";
import {useMemo} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {SigUserAvatar} from "../../../../../api/home/drawer/sig/SigUserAvatar";
import {DefnComp} from "../../../../../api/meta/base/dto/DefnComp";
import {DefnDtoParagraph} from "../../../../../api/meta/base/dto/DefnDtoParagraph";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {DefnSection} from "../../../../../api/meta/base/dto/DefnSection";
import {DefnTab} from "../../../../../api/meta/base/dto/DefnTab";
import {BubbleHiddenField} from "../../../../../api/meta/base/HomeSetsFieldType";
import {EntUserId} from "../../../../../api/meta/base/Types";
import {MetaIdComp} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {defnDtoTextToString} from "../../../../../base/plus/ArgBinderPlus";
import {fnIsHiddenBubbleFormField} from "../../../../../base/plus/BubblePlus";
import {fnGetBubbleFormFieldText} from "../../../../../base/plus/BubblePlus";
import {loopDefnForm} from "../../../../../base/plus/FormPlus";
import {px} from "../../../../../base/plus/StringPlus";
import {RootState} from "../../../../../Store";
import helperTextData from "../../../assets/PlaceholderTextHome.json";
import LayoutFlexCol from "../../../layout/LayoutFlexCol";
import LayoutFlexRow from "../../../layout/LayoutFlexRow";
import RawHighlighter from "../../../raw/RawHighlighter";
import RawNothingHere from "../../../raw/RawNothingHere";
import {BubbleRawFormField} from "./BubbleRawFormField";
import {BubbleRawFormSection} from "./BubbleRawFormSection";

export default function BubbleRawFormContent(props: {
  defnForm?: DefnForm,
  valueMap?: FieldValues,
  canExpire?: boolean,
  hideSectionName?: boolean,
  isInvisibleSpreadsheetRow?: boolean,
  updatedKeySet?: MetaIdComp[];
  chatPatternVar?: DefnDtoParagraph;

  maxWidth?: number,
  searchWords?: string[],
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const theme = useTheme();

  const defnForm = props.defnForm;
  const isInvisibleSpreadsheetRow = props.isInvisibleSpreadsheetRow;
  const valueMap = props?.valueMap;
  const canExpire = props?.canExpire;
  const hideSectionName = props.hideSectionName;
  const searchWords = props.searchWords;

  const gapHalf = theme.common.gapHalf;

  if(isInvisibleSpreadsheetRow)
  {
    return <RawNothingHere helperTextData={helperTextData.formDataVisibilityExpired} />;
  }

  if(!valueMap && canExpire)
  {
    return <RawNothingHere helperTextData={{title: " "}} />;
  }

  if(defnForm !== undefined)
  {
    return (
      <RealBubbleFormContent
        defnForm={defnForm}
        valueMap={valueMap}
        updatedKeySet={props?.updatedKeySet}
        searchWords={searchWords}
        hideSectionName={hideSectionName}
        mt={hideSectionName ? px(gapHalf) : undefined}
        chatPatternVar={props.chatPatternVar}
        maxWidth={props.maxWidth}
        selectUserAvatar={props.selectUserAvatar}
      />
    );
  }

  return null;
}

function RealBubbleFormContent(props: {
  defnForm: DefnForm,
  variant?: Variant;
  valueMap?: Record<MetaIdField, any>;
  updatedKeySet?: MetaIdField[];
  hideSectionName?: boolean,
  searchWords?: string[],
  chatPatternVar?: DefnDtoParagraph;
  mt?: StandardCSSProperties["marginTop"],
  maxWidth?: number,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const valueMap = props.valueMap;
  const mt = props.mt;
  const variant = props.variant;

  const chatPattern = props?.chatPatternVar
    ? defnDtoTextToString(props.chatPatternVar)
    : undefined;
  if(chatPattern || !isEmpty(valueMap))
  {
    return (
      <LayoutFlexCol
        width={"100%"}
        pl={px(gapHalf)}
        pr={px(gapHalf)}
        mt={mt}
      >
        {
          chatPattern
            ? (
              <LayoutFlexRow
                width={"100%"}
                justifyContent={"flex-start"}
              >
                <RawHighlighter
                  variant={variant || "body2"}
                  value={chatPattern}
                  color={"textSecondary"}
                  breakWords={true}
                  searchWords={props.searchWords}
                />
              </LayoutFlexRow>
            )
            : <MemoContentSectionAndFields {...props} />
        }
      </LayoutFlexCol>
    );
  }
  return null;
}

function ContentSectionAndFields(props: {
  defnForm: DefnForm,
  valueMap?: Record<MetaIdField, any>;
  updatedKeySet?: MetaIdField[];
  hideSectionName?: boolean,
  searchWords?: string[],
  chatPatternVar?: DefnDtoParagraph;
  mt?: StandardCSSProperties["marginTop"],
  maxWidth?: number,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const defnForm = props.defnForm;
  const hideSectionName = props.hideSectionName;
  const valueMap = props.valueMap;
  const compMap = defnForm.compMap;
  const displayCompositeId = defnForm.displayCompositeId;
  const chatBubbleFieldIdSet = defnForm.chatBubbleFieldIdSet;

  const canShowSectionName = useShowSectionName({
    defnForm,
    valueMap,
    hideSectionName
  });

  const tab = useMemo(() =>
  {
    const comp = compMap !== undefined
      ? compMap[displayCompositeId] as DefnTab
      : undefined;

    const tabIdSet = comp?.tabIdSet?.filter((metaIdField) =>
    {
      const hidden = compMap?.[metaIdField].hidden;
      return isDefnSection(compMap?.[metaIdField]) && !hidden;
    });

    return {
      ...comp,
      tabIdSet: tabIdSet || []
    };
  }, [compMap, displayCompositeId]);

  if(chatBubbleFieldIdSet && chatBubbleFieldIdSet.length > 0)
  {
    return chatBubbleFieldIdSet.map((compKey) =>
    {
      return (
        <BubbleRawFormField
          key={compKey}
          valueMap={valueMap}
          updatedKeySet={props.updatedKeySet}
          defnForm={defnForm}
          compKey={compKey}
          searchWords={props.searchWords}
          hiddenFieldsType={BubbleHiddenField}
          maxWidth={props.maxWidth}
          selectUserAvatar={props.selectUserAvatar}
        />
      );
    });
  }

  return tab.tabIdSet.map((metaIdField) =>
  {
    const section = compMap?.[metaIdField] as DefnSection;
    if(section.fieldIdSet && section.fieldIdSet.length > 0)
    {
      return (
        <Fragment key={metaIdField}>

          {canShowSectionName?.[section.metaId] && <BubbleRawFormSection section={section} />}

          {
            section.fieldIdSet.map((compKey) =>
              <BubbleRawFormField
                key={compKey}
                valueMap={valueMap}
                updatedKeySet={props.updatedKeySet}
                defnForm={defnForm}
                compKey={compKey}
                searchWords={props.searchWords}
                hiddenFieldsType={BubbleHiddenField}
                maxWidth={props.maxWidth}
                selectUserAvatar={props.selectUserAvatar}
              />
            )
          }
        </Fragment>
      );
    }
    return null;
  });

}

const MemoContentSectionAndFields = React.memo(ContentSectionAndFields);

function useShowSectionName(props: {
  defnForm?: DefnForm,
  valueMap?: FieldValues,
  hideSectionName?: boolean,
})
{
  const {
    hideSectionName,
    valueMap,
    defnForm
  } = props;

  return useMemo(() =>
  {
    if(defnForm && valueMap && !hideSectionName)
    {
      let sectionIdsCount = 0;
      const showSectionNameMap = {} as Record<MetaIdField, boolean>;
      loopDefnForm(defnForm, (parent, comp) =>
      {
        if(parent.type === "section" && !parent.hidden)
        {
          const isHidden = fnIsHiddenBubbleFormField(comp, valueMap, BubbleHiddenField);
          const formFieldValue = fnGetBubbleFormFieldText(comp, valueMap);
          if(!isHidden && !isEmpty(formFieldValue))
          {
            const sectionId = (parent as DefnSection).metaId;
            if(!showSectionNameMap[sectionId])
            {
              sectionIdsCount++;
            }
            showSectionNameMap[sectionId] = true;

          }
        }
      });
      if(sectionIdsCount <= 1)
      {
        return undefined;
      }
      return showSectionNameMap;
    }
    else
    {
      return undefined;
    }
  }, [defnForm, hideSectionName, valueMap]);
}

function isDefnSection(defnComp?: DefnComp): defnComp is DefnSection
{
  return defnComp?.type === "section";
}
