import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {EntUserId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {removeUserTyping} from "../../../cache/app/user/SliceCacheUser";
import {setUserTyping} from "../../../cache/app/user/SliceCacheUser";
import {removeUserLastOnline} from "../../../cache/app/user/SliceCacheUser";
import {removeUserAvatar} from "../../../cache/app/user/SliceCacheUser";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcPubSubUser extends ISrvc
{
  getUserAvatarTopic(entId: EntId, userId: EntUserId)
  {
    return {
      aboutId: userId,
      artifactId: entId,
      type: "userAvatar"
    } as DtoTopic;
  }

  getUserTypingTopic(entId: EntId, userId: EntUserId)
  {
    return {
      aboutId: userId,
      artifactId: entId,
      type: "userTyping"
    } as DtoTopic;
  }

  userAvatar(subscriberId: TypeSubscriberId, entId: EntId, userId: EntUserId, unsubscribe?: boolean): void
  {
    const dtoTopic = this.getUserAvatarTopic(entId, userId);

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  userLastOnline(subscriberId: TypeSubscriberId, userId: EntUserId, entId: EntId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: userId,
      artifactId: entId,
      type: "userLastOnline"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  userTyping(subscriberId: TypeSubscriberId, entId: EntId, userId: EntUserId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: userId,
      artifactId: entId,
      type: "userTyping"
    } as DtoTopic;
    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  //region userCommonGroupList
  userCommonGroupList(subscriberId: TypeSubscriberId, entId: EntId, entUserId: EntUserId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      artifactId: entId,
      aboutId: entUserId,
      type: "userCommonGroupList"
    } as DtoTopic;
    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  //endregion

  handleSigTopic(sig: SigTopic, isOnSub?: boolean): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "userAvatar":
        Srvc.cache.app.user.wsocUserAvatarGet(sig.artifactId, sig.aboutId as EntUserId);
        return true;
      case "userCommonGroupList":
        Srvc.cache.app.user.wsocUserCommonGroupsGet(sig.artifactId, sig.aboutId as EntUserId);
        return true;
      case "userLastOnline":
        Srvc.cache.app.user.wsocUserLastOnline(sig.artifactId, sig.aboutId as EntUserId);
        break;
      case "userTyping":
        if(!isOnSub)
        {
          store.dispatch(setUserTyping({
            entId: sig.artifactId,
            userId: sig.aboutId as EntUserId
          }));
        }
        break;
    }

    return false;
  }

  handleTopicUnSub(topic: DtoTopic): boolean
  {
    const type = topic.type;
    switch(type)
    {
      case "userAvatar":
        store.dispatch(removeUserAvatar({
          entId: topic.artifactId as EntId,
          userId: topic.aboutId as EntUserId
        }));
        return true;
      case "userCommonGroupList":
        Srvc.cache.app.user.clearUserCommonGroups(topic.artifactId as EntId, topic.aboutId as EntUserId);
        return true;
      case "userLastOnline":
        store.dispatch(removeUserLastOnline(topic.aboutId as EntUserId));
        return true;
      case "userTyping":
        store.dispatch(removeUserTyping({
          entId: topic.artifactId,
          userId: topic.aboutId as EntUserId
        }));
        return true;
    }

    return false;
  }
}
