import {StoreRounded} from "@mui/icons-material";
import {ExtensionRounded} from "@mui/icons-material";
import {InsertLinkRounded} from "@mui/icons-material";
import {PersonAddRounded} from "@mui/icons-material";
import {PublicRounded} from "@mui/icons-material";
import {Groups3Rounded} from "@mui/icons-material";
import {CameraAltRounded} from "@mui/icons-material";
import {InsertPhotoRounded} from "@mui/icons-material";
import {InsertDriveFileRounded} from "@mui/icons-material";
import {HelpRounded} from "@mui/icons-material";
import {DashboardRounded} from "@mui/icons-material";
import {NotificationsRounded} from "@mui/icons-material";
import {GridOnRounded} from "@mui/icons-material";
import {AssessmentRounded} from "@mui/icons-material";
import {EmailRounded} from "@mui/icons-material";
import {ForumRounded} from "@mui/icons-material";
import {DynamicFormRounded} from "@mui/icons-material";
import {CastRounded} from "@mui/icons-material";
import {GroupRounded} from "@mui/icons-material";
import {PersonRounded} from "@mui/icons-material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {MuiAvatarFontSize} from "../../../base/plus/ThemePlus";
import {EnumIconAvatar} from "../../../base/types/TypesIcon";

export function IconAvatar(props: {
  avatarIcon: EnumIconAvatar,
  fontSize?: MuiAvatarFontSize,
  iconSize?: StandardCSSProperties["width"],
})
{
  const avatarIcon = props.avatarIcon;
  const fontSize = props.fontSize;
  const size = props.iconSize;

  switch(avatarIcon)
  {
    case "group":
      return <GroupRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "channel":
      return <CastRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "public":
      return <PublicRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "user":
      return <PersonRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "form":
      return <DynamicFormRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "inbox":
      return <ForumRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "enterprise":
      return <Groups3Rounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "post":
      return <EmailRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "poll":
      return <AssessmentRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "sheet":
      return <GridOnRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "dashboard":
      return <DashboardRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "notifications":
      return <NotificationsRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "help":
      return <HelpRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "document":
      return <InsertDriveFileRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "image":
      return <InsertPhotoRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "camera":
      return <CameraAltRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "addPerson":
      return <PersonAddRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "insertLink":
      return <InsertLinkRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "plugin":
      return <ExtensionRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "store":
      return <StoreRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;
    case "grid":
      return <GridOnRounded
        fontSize={fontSize}
        sx={{
          width: size,
          height: size
        }}
      />;

    default:
      return null;
  }
}
