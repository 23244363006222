import {Location} from "history";
import parse from "html-react-parser";
import React from "react";
import {LocationState} from "../types/TypesGlobal";
import {fnMarkdownParser} from "./StringPlus";
import {hasFormatMarkdownText} from "./StringPlus";
import {isProdEnvironment} from "./SysPlus";
import theme from "./ThemePlus";

// noinspection RegExpRedundantEscape,RegExpUnnecessaryNonCapturingGroup
export const EMAIL_REGEX = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

export const YOUTUBE_URL_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed|d|)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

export function getPrevUrl(location: Location)
{
  const from = (location.state as LocationState)?.from;
  if(from)
  {
    return from.search ? from.pathname + from.search : from.pathname;
  }
  else
  {
    return null;
  }
}

export function getPrevUrlState(location: Location)
{
  const from = (location.state as LocationState)?.from;
  if(from)
  {
    return from.state;
  }
  else
  {
    return null;
  }
}

export function getValidURL(text: string): string
{
  let url = text;
  if(!/^https?:\/\//i.test(url))
  {
    url = "http://" + url;
  }
  return url;
}

export function isValidEmail(text: string)
{
  return EMAIL_REGEX.test(text);
}

export function getYoutubeVideoId(url: string)
{
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get("v") ?? "";
}

export function validYouTubeUrl(url: string): boolean
{
  const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?([A-Za-z0-9_-]{11})(\S*)?$/i;
  return pattern.test(url);
}

export function getWhatsAppSignInUrl()
{
  const businessAccount = isProdEnvironment() ? "7984037233" : "7984573315";
  return "https://api.whatsapp.com/send?text=" + "Hi neome, help me sign in!" + "&phone=91" + businessAccount;
}

export const startWitHTTPS = "https:\\/\\/[^\\/\\s]+(?:\\/[^\\/\\s]*)*";

export const startWithWWW = "www\\.[a-zA-Z0-9]+\\.[a-zA-Z]+(?:\\[[^\\]]*])?";
export const endWithFamousTLDS = "\\b[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*\\.(com|org|net|edu|gov|mil|info|biz|name|us|uk|ca|de|fr|jp|cn|in|au|nz|tech|store|blog|app|design|io|ai|dev|gov|edu|int|museum)\\b";

export const regexHTTPS = new RegExp(startWitHTTPS, "i");
export const regexStartWithWWW = new RegExp(startWithWWW, "i");
export const regexFamousTLDS = new RegExp(endWithFamousTLDS, "i");

export function splitUrl(url: string)
{
  if(regexHTTPS.test(url))
  {
    return url.replace(regexHTTPS, (text) => `§${text}§`);
  }
  else if(regexStartWithWWW.test(url))
  {
    return url.replace(regexStartWithWWW, (text) => `§${text}§`);
  }
  else if(regexFamousTLDS.test(url))
  {
    return url.replace(regexFamousTLDS, (text) => `§${text}§`);
  }
}

export function extractUrls(text: string)
{
  const combinedRegex = new RegExp(`${regexHTTPS.source}|${regexStartWithWWW.source}|${regexFamousTLDS.source}`, "gi");

  const match = combinedRegex.exec(text);

  return match ? match[0] : null;
}

export function isValidUrl(text: string)
{
  if(isValidEmail(text))
  {
    return false;
  }

  if(regexHTTPS.test(text))
  {
    return true;
  }
  else if(regexStartWithWWW.test(text))
  {
    return true;
  }
  else if(regexFamousTLDS.test(text))
  {
    return regexFamousTLDS.test(text);
  }
}

function encloseUrlsWithTags(sentence: string): string
{
  const regex = /(\s+|\n+|[^\s]+)/g;
  const parts = sentence.split(regex);

  return parts.map(part =>
  {
    if(isValidUrl(part.trim()))
    {
      return `§${part}§`;
    }
    return part;
  }).join("");
}

function encloseMentionWithTags(sentence: string, mentionWords?: string[])
{
  if(!mentionWords)
  {
    return sentence;
  }
  const regex = new RegExp(`(${mentionWords.join("|")})`, "g");
  const usersWithSentence = sentence.split(regex);
  const enclosedTagWords = usersWithSentence.map(word =>
    mentionWords.includes(word) ? `§${word}§` : word
  );
  return enclosedTagWords.join("");
}

export const parseLinkText = (
  text: string,
  mentionWords?: string[],
  onClickSendMessageToUser?: ((mentionUser: string, menuAnchor: Element) => void) | undefined,
  parseLink?: boolean
) =>
{
  const parts = [];

  let processedText = encloseUrlsWithTags(text);
  processedText = processedText.replace(EMAIL_REGEX, (email) => `§${email}§`);
  processedText = encloseMentionWithTags(processedText, mentionWords);
  const token: string[] = processedText.split("§");

  if(text.includes("§"))
  {
    if(hasFormatMarkdownText(text))
    {
      return parse(fnMarkdownParser(text));
    }
    else
    {
      return text;
    }
  }

  for(let i = 0; i < token.length; i++)
  {
    const word = token[i];
    if(isValidEmail(word))
    {
      const email = word;
      parts.push(
        <a
          key={`email-${i}`}
          href={`mailto:${email}`}
          style={{
            userSelect: "auto",
            WebkitUserSelect: "text",
            color: theme.common.color("primaryDark"),
            cursor: "pointer"
          }}
        >
          {email}
        </a>
      );
    }
    else if(parseLink && isValidUrl(word))
    {
      const url = getValidURL(word as string);
      parts.push(
        <a
          key={`link-${i}`}
          href={url}
          target={"_blank"}
          style={{
            userSelect: "auto",
            WebkitUserSelect: "text",
            color: theme.common.color("primaryDark"),
            cursor: "pointer"
          }}
        >
          {word}
        </a>
      );
    }
    else if(hasFormatMarkdownText(word))
    {
      parts.push(parse(fnMarkdownParser(word)));
    }
    else if(mentionWords && mentionWords.includes(word))
    {
      parts.push(
        <span
          key={`${word}-${i}`}
          style={{
            userSelect: "auto",
            whiteSpace: "pre-wrap",
            color: theme.common.color("primary"),
            cursor: onClickSendMessageToUser ? "pointer" : undefined
          }}
          onClick={(e) => onClickSendMessageToUser && onClickSendMessageToUser(word, e.currentTarget)}
        >
          {word}
          </span>
      );
    }
    else
    {
      parts.push(word);
    }
  }
  return parts;
};

export function isYouTubeLink(url: string)
{
  return YOUTUBE_URL_REGEX.test(url);
}
