import {DtoTopic} from "../../../../api/core/base/dto/DtoTopic";
import {MsgEntFilter} from "../../../../api/home/drawer/msg/MsgEntFilter";
import {SigRecentList} from "../../../../api/home/drawer/sig/SigRecentList";
import {WsocDrawer} from "../../../../api/home/drawer/WsocDrawer";
import {isGroupId} from "../../../../api/meta/base/ApiPlus";
import {ChatId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {toComboId} from "../../../../base/types/TypesComboId";
import {TempSubscriberId} from "../../../../srvc/app/session/SrvcSession";
import {Srvc} from "../../../../srvc/Srvc";
import {store} from "../../../../Store";
import {setRecentList} from "./SliceCacheHomeDrawerRecentList";

export default class SrvcCacheHomeDrawerRecentList extends ISrvc
{
  wsocRecentListGet(firstBoot: boolean, cbSuccess?: () => void)
  {
    Srvc.home.drawer.filter.filter((filter?: EntId[]) =>
    {
      const version = store.getState().cache.home.drawer.recent.version;

      const msg: MsgEntFilter = {
        version: version,
        filterEntIdSet: filter
      };

      WsocDrawer.recentListGet(msg, envSig =>
      {
        const sig = envSig.sig;
        if(sig)
        {
          const dtoTopicList = [] as DtoTopic[];
          if(sig.recentList.length === 0 || this.checkIsDirty(sig))
          {
            store.dispatch(setRecentList(sig));
          }

          if(firstBoot)
          {
            let topRecentCount = 10; // on subscribe to top few recent items
            sig.recentList.forEach(recent =>
            {
              if(topRecentCount > 0)
              {
                dtoTopicList.push(...this.getSubscribeTopics(recent.entId, recent.chatId));
                topRecentCount--;
              }
            });
            Srvc.app.pubsub.handleSubscribeRecentList(TempSubscriberId, dtoTopicList);
          }
        }
        cbSuccess?.();
      });
    });
  }

  getSubscribeTopics(entId: EntId, chatId: ChatId)
  {
    const dtoTopicList = [] as DtoTopic[];
    const dtoTopicAvatar = Srvc.app.pubsub.homeAvatarTopic(toComboId(entId, chatId));
    dtoTopicList.push(Srvc.app.pubsub.msg.getMsgPropsTopic(entId, chatId));
    dtoTopicList.push(Srvc.app.pubsub.msg.getMsgLastTopic(entId, chatId));

    if(dtoTopicAvatar)
    {
      dtoTopicList.push(dtoTopicAvatar);
    }

    if(isGroupId(chatId))
    {
      dtoTopicList.push(Srvc.app.pubsub.group.getGroupTypingTopic(entId, chatId));
    }
    else
    {
      dtoTopicList.push(Srvc.app.pubsub.user.getUserTypingTopic(entId, chatId));
    }
    return dtoTopicList;
  }

  private checkIsDirty(sig: SigRecentList)
  {
    const cache = store.getState().cache.home.drawer.recent;

    const len = cache.recentList.length;
    if(len !== sig.recentList.length)
    {
      return true;
    }
    for(let i = 0; i < len; i++)
    {
      const old = cache.recentList[i];
      const newRecent = sig.recentList[i];
      const entChatId = toComboId(newRecent.entId, newRecent.chatId);
      if(old !== entChatId)
      {
        return true;
      }
    }
  }
}
