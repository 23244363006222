import {isEmpty} from "lodash";
import {nextMetaIdRole} from "../../../../api/meta/base/ApiPlus";
import {StudioEntRole} from "../../../../api/meta/base/dto/StudioEntRole";
import {EntId, MetaIdRole} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {TypeStudioFilterType} from "../../../../base/plus/StudioPlus";
import {selectCacheStudioEnt} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection, IDtoEntCopy} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {setEntUsageFilter} from "../SliceCacheStudioEnt";
import {addEntRole, moveEntItem, removeEntRole} from "../SliceCacheStudioEnt";

export default class SrvcCacheStudioEntRoles extends ISrvc
{
  addEntRole(entId: EntId, role: StudioEntRole)
  {
    dispatchEnt(entId, addEntRole({
      entId: entId,
      sig: {
        ...role,
        metaId: role.metaId || nextMetaIdRole()
      }
    }));
  }

  removeEntRole(entId: EntId, metaIdRole: MetaIdRole)
  {
    dispatchEnt(entId, removeEntRole({
      entId: entId,
      metaId: metaIdRole
    }));
  }

  copyEntRole(entId: EntId, metaIdRole: MetaIdRole)
  {
    const rootState = store.getState();
    const roleMap = selectCacheStudioEnt(rootState, entId)?.ent.roleMap;
    const role = roleMap?.map[metaIdRole];

    if(!isEmpty(role))
    {
      const payloadCopy: IDtoEntCopy = {
        type: "role",
        payload: role
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  }

  duplicateEntRole(entId: EntId, metaIdRole: MetaIdRole)
  {
    const rootState = store.getState();
    const roleMap = rootState.cache.studio.ent.entMap[entId].ent.roleMap;
    if(!roleMap)
    {
      return;
    }

    const index = roleMap.keys.indexOf(metaIdRole);
    if(index === -1)
    {
      return;
    }

    const entRole = roleMap.map[metaIdRole]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(roleMap.map[metaIdRole])))
      : undefined;
    if(entRole)
    {
      dispatchEnt(entId, addEntRole({
        entId: entId,
        sig: entRole,
        insertIndex: index + 1
      }));
    }
  }

  moveItem(
    entId: EntId,
    metaId: MetaIdRole,
    moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "roleList",
      moveDirection
    }));
  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    store.dispatch(setEntUsageFilter({
      entId: entId,
      roleUsageFilter: filterType
    }));
  }
}
