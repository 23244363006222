import {useTheme} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {getListItemHeightAPSA} from "../../../base/plus/ListPlus";
import {px} from "../../../base/plus/StringPlus";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {TypeEnumCaptionButton} from "../../list/List";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import {LinePrimary} from "../line/LinePrimary";
import {LineSecondary} from "../line/LineSecondary";

export function BoxPS(props: {
  primary?: ILinePrimary,
  secondary?: ILineSecondary,
  searchWords?: string[],
  onClickCaption?: (e: React.MouseEvent<HTMLElement>) => void,
  onClickIconButtonCaption?: (e: React.MouseEvent<HTMLButtonElement>, type: TypeEnumCaptionButton) => void,
  onClick?: () => void,
  bgcolor?: CssBackgroundColor;
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  pt?: number,
  pb?: number,
  pl?: number,
  pr?: number,
  flexGrow?: StandardCSSProperties["flexGrow"],
})
{
  const theme = useTheme();
  const itemHeight = getListItemHeightAPSA("ps");
  const innerGap = theme.common.vertInner / 2;

  return (
    <LayoutFlexCol
      flexShrink={1}
      mt={px(props.mt)}
      mb={px(props.mb)}
      ml={px(props.ml)}
      mr={px(props.mr)}
      pt={px(props.pt)}
      pb={px(props.pb)}
      pl={px(props.pl)}
      pr={px(props.pr)}
      bgcolor={props.bgcolor}
      flexGrow={props.flexGrow}
      height={px(itemHeight)}
      onClick={props.onClick}
      cursorPointer={Boolean(props.onClick)}
    >

      <LinePrimary
        primary={props.primary}
        searchWords={props.searchWords}
        mb={innerGap}
        onClickCaption={props.onClickCaption}
        onClickIconButtonCaption={props.onClickIconButtonCaption}
      />

      <LineSecondary
        secondary={props.secondary}
        searchWords={props.searchWords}
        onClickCaption={props.onClickCaption}
        mt={innerGap}
      />

    </LayoutFlexCol>
  );
}
