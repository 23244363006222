import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {cloneDeep} from "lodash";
import {isEqual} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import {useCallback} from "react";
import {useRef} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {Controller} from "react-hook-form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {ObjectSchema} from "yup";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldParagraph} from "../../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickOption} from "../../../../api/meta/base/dto/DefnFieldPickOption";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldPickTree} from "../../../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldRating} from "../../../../api/meta/base/dto/DefnFieldRating";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickPluginFieldId} from "../../../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {FieldDtoTree} from "../../../../api/meta/base/dto/FieldDtoTree";
import {StudioBuildArgBinder} from "../../../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioDtoArgValueConstant} from "../../../../api/meta/base/dto/StudioDtoArgValueConstant";
import {StudioDtoArgValueContext} from "../../../../api/meta/base/dto/StudioDtoArgValueContext";
import {StudioDtoArgValueContextCaller} from "../../../../api/meta/base/dto/StudioDtoArgValueContextCaller";
import {StudioDtoArgValueContextCallerSetting} from "../../../../api/meta/base/dto/StudioDtoArgValueContextCallerSetting";
import {StudioDtoArgValueContextEnt} from "../../../../api/meta/base/dto/StudioDtoArgValueContextEnt";
import {StudioDtoArgValueContextForm} from "../../../../api/meta/base/dto/StudioDtoArgValueContextForm";
import {StudioDtoArgValueContextPlugin} from "../../../../api/meta/base/dto/StudioDtoArgValueContextPlugin";
import {StudioDtoArgValueContextRow} from "../../../../api/meta/base/dto/StudioDtoArgValueContextRow";
import {StudioDtoArgValueDerived} from "../../../../api/meta/base/dto/StudioDtoArgValueDerived";
import {StudioDtoArgValueField} from "../../../../api/meta/base/dto/StudioDtoArgValueField";
import {StudioDtoArgValueInput} from "../../../../api/meta/base/dto/StudioDtoArgValueInput";
import {StudioDtoArgValueVariable} from "../../../../api/meta/base/dto/StudioDtoArgValueVariable";
import {StudioFieldPickText} from "../../../../api/meta/base/dto/StudioFieldPickText";
import {StudioFieldPickTree} from "../../../../api/meta/base/dto/StudioFieldPickTree";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {StudioVarSetOfText} from "../../../../api/meta/base/dto/StudioVarSetOfText";
import {StudioVarTree} from "../../../../api/meta/base/dto/StudioVarTree";
import {ArgBinderType} from "../../../../api/meta/base/StudioSetsArgBinder";
import {ArgBinderContextFieldType} from "../../../../api/meta/base/StudioSetsFieldType";
import {ArgBinderValidConstantType} from "../../../../api/meta/base/StudioSetsFieldType";
import {VisibilityType} from "../../../../api/meta/base/StudioSetsFieldType";
import {DerivedFields} from "../../../../api/meta/base/StudioSetsFieldType";
import {ArgBinderValidContext} from "../../../../api/meta/base/StudioSetsFieldType";
import {StudioBuildArgBinderVariableType} from "../../../../api/meta/base/StudioSetsVarType";
import {EnumDefnArgBinderContextCaller} from "../../../../api/meta/base/Types";
import {EnumDefnArgBinderContextEnt} from "../../../../api/meta/base/Types";
import {EnumDefnArgBinderContextForm} from "../../../../api/meta/base/Types";
import {EnumDefnArgBinderContextPlugin} from "../../../../api/meta/base/Types";
import {EnumDefnArgBinderContextRow} from "../../../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../../../api/meta/base/Types";
import {EnumDefnThemeDirection} from "../../../../api/meta/base/Types";
import {EnumArrayDefnArgBinderContextRow} from "../../../../api/meta/base/Types";
import {EnumArrayDefnArgBinderContextPlugin} from "../../../../api/meta/base/Types";
import {EnumArrayDefnArgBinderContextForm} from "../../../../api/meta/base/Types";
import {EnumArrayDefnArgBinderContextEnt} from "../../../../api/meta/base/Types";
import {EnumArrayDefnArgBinderContextCaller} from "../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {EnumDefnArgBinderContext} from "../../../../api/meta/base/Types";
import {EnumDefnArgBinder} from "../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getConstValueKeyArgBinderFromDto} from "../../../../base/plus/ArgBinderPlus";
import {getArgBinderConstValueKey} from "../../../../base/plus/ArgBinderPlus";
import {getFieldStudioBuildArgBinderKey} from "../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {loopDefnForm} from "../../../../base/plus/FormPlus";
import {prepare} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {calcSchemaField} from "../../../../base/plus/FormYupPlus";
import {random} from "../../../../base/plus/StringPlus";
import {loopStudioForm} from "../../../../base/plus/StudioFormPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {isSystemForm} from "../../../../routes/studio/ent/deploy/plugins/StudioEntDeployPluginPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldSection from "../composite/FieldSection";
import FieldRawTemplate from "../raw/FieldRawTemplate";

type FieldValueArgBinder = StudioBuildArgBinder | undefined | null;

const kind: keyof StudioBuildArgBinder = "kind";

const context: keyof StudioDtoArgValueContext = "kind";
const derived: keyof StudioDtoArgValueDerived = "derivedFieldId";
const field: keyof StudioDtoArgValueField = "fieldId";
const input: keyof StudioDtoArgValueInput = "fieldId";
const variable: keyof StudioDtoArgValueVariable = "varId";
const constant: keyof StudioDtoArgValueConstant = "type";

const caller: keyof StudioDtoArgValueContextCaller = "attribute";
const callerSetting: keyof StudioDtoArgValueContextCallerSetting = "userSettingVarId";
const ent: keyof StudioDtoArgValueContextEnt = "attribute";
const form: keyof StudioDtoArgValueContextForm = "attribute";
const plugin: keyof StudioDtoArgValueContextPlugin = "attribute";
const row: keyof StudioDtoArgValueContextRow = "attribute";

const valueBoolean: keyof StudioDtoArgValueDerived = "valueBoolean";
const valueDate: keyof StudioDtoArgValueDerived = "valueDate";
const valueDouble: keyof StudioDtoArgValueDerived = "valueDouble";
const valueLong: keyof StudioDtoArgValueDerived = "valueLong";
const valueOptionId: keyof StudioDtoArgValueDerived = "valueOptionId";
const valueText: keyof StudioDtoArgValueDerived = "valueText";

const value: keyof StudioBuildArgBinder = "value";

function getKindKey(fieldKey: string)
{
  return `${fieldKey}.${kind}`;
}

function getArgValKey(fieldKey: string, kind?: EnumDefnArgBinder)
{
  const valueKey = `${fieldKey}.${value}`;

  switch(kind)
  {
    case "context":
      return `${valueKey}.${context}`;
    case "derived":
      return `${valueKey}.${derived}`;
    case "field":
      return `${valueKey}.${field}`;
    case "input":
      return `${valueKey}.${input}`;
    case "variable":
      return `${valueKey}.${variable}`;
    case "constant":
      return `${valueKey}.${constant}`;
  }
}

function getArgDerivedValKey(
  fieldKey: string,
  fieldType?: EnumDefnCompType
)
{
  const valueKey = `${fieldKey}.${value}`;

  switch(fieldType)
  {
    case "bool":
      return `${valueKey}.${valueBoolean}`;
    case "date":
      return `${valueKey}.${valueDate}`;
    case "dateTime":
      return `${valueKey}.${valueDate}`;
    case "email":
      return `${valueKey}.${valueText}`;
    case "handle":
      return `${valueKey}.${valueText}`;
    case "hyperlink":
      return `${valueKey}.${valueText}`;
    case "mobileNumber":
      return `${valueKey}.${valueText}`;
    case "number":
      return `${valueKey}.${valueLong}`;
    case "counter":
      return `${valueKey}.${valueLong}`;
    case "decimal":
      return `${valueKey}.${valueDouble}`;
    case "text":
      return `${valueKey}.${valueText}`;
    case "paragraph":
      return `${valueKey}.${valueText}`;
    case "currency":
      return `${valueKey}.${valueOptionId}`;
    case "language":
      return `${valueKey}.${valueOptionId}`;
    case "timeZone":
      return `${valueKey}.${valueOptionId}`;
    case "symbol":
      return `${valueKey}.${valueText}`;
    case "rating":
      return `${valueKey}.${valueLong}`;
    case "pickText":
      return `${valueKey}.${valueOptionId}`;
    case "pickTree":
      return `${valueKey}.${valueOptionId}`;
    case "paymentStatus":
      return `${valueKey}.${valueOptionId}`;
    case "pickRole":
      return `${valueKey}.${valueOptionId}`;
  }
}

function getArgContextValKey(
  fieldKey: string,
  contextKind?: EnumDefnArgBinderContext)
{
  const valueKey = `${fieldKey}.${value}`;

  switch(contextKind)
  {
    case "caller":
      return `${valueKey}.${caller}`;
    case "callerSetting":
      return `${valueKey}.${callerSetting}`;
    case "ent":
      return `${valueKey}.${ent}`;
    case "form":
      return `${valueKey}.${form}`;
    case "plugin":
      return `${valueKey}.${plugin}`;
    case "row":
      return `${valueKey}.${row}`;
  }
}

export default function FieldStudioBuildArgBinder(props: {
  defn: DefnStudioBuildArgBinder,
  defnTheme: DefnDtoFormTheme,
  defnForm: DefnFormUi
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const defnTheme = formCtx.getDefnTheme();
  const label = defn.label;
  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={defn.metaId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;
        const isError = isTouched && Boolean(error);
        const fieldValue = field.value;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <RealFieldStudioBuildArgBinder
                defn={defn}
                defnTheme={props.defnTheme}
                fieldValue={fieldValue}
                label={label}
                onChange={field.onChange}
                defnFormUi={props.defnForm}
              />

              {isError &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealFieldStudioBuildArgBinder(props: {
  defn: DefnStudioBuildArgBinder,
  defnTheme: DefnDtoFormTheme,
  defnFormUi: DefnFormUi,
  fieldValue?: FieldValueArgBinder,
  label?: string,
  onChange: (value?: FieldValueArgBinder) => void,
})
{
  const formCtx = useFormCtx();
  const hookFormCtx = useFormContext();

  const label = props.label;
  const onChange = props.onChange;

  const [defn, setDefn] = useState(props.defn);

  const fieldKey = getFieldKey(defn);
  const error = hookFormCtx.getFieldState(fieldKey)?.error;
  const formStore = formCtx.getStore();
  const defaultValues = hookFormCtx.formState.defaultValues;

  const disabled = Boolean(formCtx.isFieldDisable(defn as DefnStudioBuildArgBinder));
  const fieldValueKey = getFieldStudioBuildArgBinderKey(fieldKey);

  const {
    valueToDto,
    getDefnForm
  } = useArgBinder({
    defn: defn,
    formStore: formStore
  });

  const argBinderValue = useRef<FieldValueArgBinder>(cloneDeep(props.fieldValue));

  const [defnForm, setDefnForm] = useState<DefnFormUi>(prepare(
    getDefnForm(
      fieldValueKey,
      disabled,
      argBinderValue.current,
      label
    )));
  const [version, setVersion] = useState<string>();

  const fnSetValueToDto = useCallback((value?: FieldValueArgBinder, values?: FieldValues) =>
  {
    const argValue = valueToDto(fieldValueKey, cloneDeep(value), values) as FieldValueArgBinder;

    if(!argBinderValue.current || !isEqual(argValue, argBinderValue.current))
    {
      argBinderValue.current = argValue;
      setVersion(random());
    }

  }, [valueToDto]);

  function getValueForYup(fieldValueKey: string, objKey: string): any
  {
    const newKey = objKey.split(".").slice(1).join(".");
    return getValueFromNestedObject(hookFormCtx.getValues()[fieldValueKey], newKey);
  }

  useEffect(() =>
  {
    setDefnForm(prepare(
      getDefnForm(
        fieldValueKey,
        disabled,
        argBinderValue.current,
        label
      )));

  }, [version, defn]);

  useEffect(() =>
  {
    if(!isEqual(defn, props.defn))
    {
      setDefn(props.defn);
    }

  }, [props.defn]);

  useEffect(() =>
  {
    if(!isEqual(props.fieldValue, argBinderValue.current))
    {
      onChange(argBinderValue.current);
    }

  }, [version]);

  useEffect(() =>
  {
    let subscription = hookFormCtx.watch((values, {name}) =>
    {
      if(name && name.startsWith(fieldValueKey))
      {
        fnSetValueToDto(values[fieldValueKey], values);
      }
    });

    return () =>
    {
      if(subscription !== undefined)
      {
        subscription.unsubscribe();
      }
    };

  }, [defaultValues, fnSetValueToDto]);

  useEffect(() =>
  {
    const currentArgBinderValue = hookFormCtx.getValues()[fieldKey];

    if(!isEqual(currentArgBinderValue, props.fieldValue))
    {
      argBinderValue.current = cloneDeep(currentArgBinderValue);
      setVersion(random());
    }
  }, [defaultValues]);

  useEffect(() =>
  {
    if(error)
    {
      const kindKey = getKindKey(fieldValueKey);
      hookFormCtx.setError(kindKey, error);
    }

  }, [error]);

  useEffect(() =>
  {
    const constValueKey = getConstValueKeyArgBinderFromDto(fieldValueKey, hookFormCtx.getValues()[fieldValueKey]);

    loopDefnForm(defnForm, (_, comp) =>
    {
      const fieldSchema = calcSchemaField(defnForm, comp) as ObjectSchema<any>;

      if(fieldSchema)
      {
        const key = getFieldKey(comp);

        const value = (constValueKey && comp.metaId === constValueKey)
          ? hookFormCtx.getValues()[constValueKey] :
          getValueForYup(fieldValueKey, key);

        fieldSchema.validate(value).catch(reason =>
        {
          hookFormCtx.setError(key, reason);
        });
      }
    });

  }, [defnForm]);

  if(defnForm && defnForm?.compMap[fieldValueKey])
  {
    return (
      <FieldSection
        key={fieldValueKey}
        defn={defnForm.compMap[fieldValueKey] as DefnSection}
        defnForm={defnForm}
        defnTheme={props.defnTheme}
      />
    );
  }

  return <></>;
}

function getValueFromNestedObject(obj: object, key: string)
{
  const keys = key.split(".");

  function inner(obj: object, keys: string[])
  {
    const [currentKey, ...remainingKeys] = keys;

    if(!obj || typeof obj !== "object")
    {
      return undefined;
    }

    const value = obj[currentKey as keyof typeof obj];

    if(remainingKeys.length === 0)
    {
      return value;
    }

    return inner(value, remainingKeys);
  }

  return inner(obj, keys);
}

function fnGetOptionArray(inputFormId?: MetaIdForm, filterKindSet?: EnumDefnArgBinder[])
{
  let options = ArgBinderType as EnumDefnArgBinder[];
  if(!inputFormId)
  {
    options = options.filter(value => value !== "input");
  }

  if(filterKindSet)
  {
    options = options.filter(value => !filterKindSet.includes(value));
  }

  return options;
}

function getIntersectingTypes(arr1: string[], arr2?: string[]): string[]
{
  if(!arr2)
  {
    return arr1;
  }
  else
  {
    return arr2.filter(type => arr1.includes(type));
  }
}

function getFieldArgCompMap(
  defn: DefnStudioBuildArgBinder,
  fieldKey: MetaIdField,
  disabled: boolean,
  formId?: MetaIdForm,
  derivedFormId?: MetaIdForm,
  kind?: EnumDefnArgBinder,
  excludeFieldIdSet?: MetaIdField[],
  excludeVarIdSet?: MetaIdVar[],
  inputFormId?: MetaIdForm,
  compositeIdSet?: MetaIdComposite[],
  derivedCompositeIdSet?: MetaIdComposite[],
  includeOptionMap?: DefnStudioMapOfDtoOption,
  pluginId?: MetaIdPlugin,
  derivedPluginId?: MetaIdPlugin,
  required?: boolean
): Record<MetaIdField, DefnComp> | undefined
{
  const filterFieldTypeSet = defn.filterFieldTypeSet;
  const filterVarKindSet = defn.filterVarKindSet;

  const fieldArgValKey = getArgValKey(fieldKey, kind);
  const filterDerivedFieldTypeSet = getIntersectingTypes(DerivedFields, defn.filterDerivedFieldTypeSet);

  if(kind && fieldArgValKey)
  {
    switch(kind)
    {
      case "context":
        return {
          [fieldArgValKey]: {
            type: "pickOption",
            metaId: fieldArgValKey,
            name: fieldArgValKey,
            required: required,
            hideLabel: true,
            disabled: disabled,
            optionSet: defn.filterConstantOptionSet || ArgBinderValidContext
          } as DefnFieldPickOption
        };
      case "constant":
        return {
          [fieldArgValKey]: {
            type: "pickOption",
            metaId: fieldArgValKey,
            name: fieldArgValKey,
            required: required,
            label: "Field type",
            disabled: disabled,
            optionSet: defn.filterConstantFieldTypeSet || ArgBinderValidConstantType
          } as DefnFieldPickOption
        };
      case "derived":
      {
        if(!derivedPluginId)
        {
          return {
            [fieldArgValKey]: {
              type: "pickFieldId",
              metaId: fieldArgValKey,
              name: fieldArgValKey,
              required: required,
              label: "Field",
              disabled: disabled,
              formId: derivedFormId,
              excludeFieldIdSet: excludeFieldIdSet,
              filterFieldTypeSet: filterDerivedFieldTypeSet || DerivedFields,
              compositeIdSet: derivedCompositeIdSet,
              showCompositeName: true
            } as DefnStudioPickFieldId
          };
        }
        else
        {
          return {
            [fieldArgValKey]: {
              type: "pickPluginFieldId",
              metaId: fieldArgValKey,
              name: fieldArgValKey,
              required: required,
              label: "Field",
              disabled: disabled,
              formId: derivedFormId,
              excludeFieldIdSet: excludeFieldIdSet,
              filterFieldTypeSet: filterDerivedFieldTypeSet || DerivedFields,
              showCompositeName: true,
              compositeIdSet: derivedCompositeIdSet,
              pluginId: derivedPluginId
            } as DefnStudioPickPluginFieldId
          };
        }
      }
      case "field":
      {
        if(!pluginId)
        {
          return {
            [fieldArgValKey]: {
              type: "pickFieldId",
              metaId: fieldArgValKey,
              name: fieldArgValKey,
              required: required,
              label: "Field",
              formId: formId,
              disabled: disabled,
              excludeFieldIdSet: excludeFieldIdSet,
              filterFieldTypeSet: filterFieldTypeSet || ArgBinderContextFieldType,
              compositeIdSet: compositeIdSet,
              showCompositeName: true,
              includeOptionMap: includeOptionMap
            } as DefnStudioPickFieldId
          };
        }
        else
        {
          return {
            [fieldArgValKey]: {
              type: "pickPluginFieldId",
              metaId: fieldArgValKey,
              name: fieldArgValKey,
              required: required,
              label: "Field",
              formId: formId,
              disabled: disabled,
              excludeFieldIdSet: excludeFieldIdSet,
              filterFieldTypeSet: filterFieldTypeSet || ArgBinderContextFieldType,
              showCompositeName: true,
              compositeIdSet: compositeIdSet,
              includeOptionMap: includeOptionMap,
              pluginId: pluginId
            } as DefnStudioPickPluginFieldId
          };
        }
      }
      case "input":
      {
        if(!pluginId)
        {
          return {
            [fieldArgValKey]: {
              type: "pickFieldId",
              metaId: fieldArgValKey,
              name: fieldArgValKey,
              required: required,
              label: "Field",
              formId: inputFormId,
              disabled: disabled,
              filterFieldTypeSet: filterFieldTypeSet || VisibilityType,
              showCompositeName: true,
              excludeFieldIdSet: excludeFieldIdSet,
              includeOptionMap: includeOptionMap,
              compositeIdSet: compositeIdSet
            } as DefnStudioPickFieldId
          };
        }
        else
        {
          return {
            [fieldArgValKey]: {
              type: "pickPluginFieldId",
              metaId: fieldArgValKey,
              name: fieldArgValKey,
              required: required,
              label: "Field",
              formId: inputFormId,
              disabled: disabled,
              filterFieldTypeSet: filterFieldTypeSet || VisibilityType,
              showCompositeName: true,
              excludeFieldIdSet: excludeFieldIdSet,
              includeOptionMap: includeOptionMap,
              compositeIdSet: compositeIdSet,
              pluginId: pluginId
            } as DefnStudioPickPluginFieldId
          };
        }
      }
      case "variable":
        return {
          [fieldArgValKey]: {
            type: "pickVarId",
            metaId: fieldArgValKey,
            name: fieldArgValKey,
            required: required,
            label: "Variable",
            varKindSet: filterVarKindSet || StudioBuildArgBinderVariableType,
            disabled: disabled,
            excludeVarIdSet: excludeVarIdSet
          } as DefnStudioPickVarId
        };
    }
  }
}

function getArgContextCompMap(
  defn: DefnStudioBuildArgBinder,
  disabled: boolean,
  contextKind?: EnumDefnArgBinderContext,
  fieldArgContextValKey?: string,
  required?: boolean
): Record<MetaIdField, DefnComp>
{
  if(contextKind && fieldArgContextValKey)
  {
    switch(contextKind)
    {
      case "caller":
        return {
          [fieldArgContextValKey]: {
            type: "pickOption",
            metaId: fieldArgContextValKey,
            disabled: disabled,
            required: required,
            hideLabel: true,
            label: "",
            optionSet: defn.filterConstantCallerContextSet || EnumArrayDefnArgBinderContextCaller
          } as DefnFieldPickOption
        } as Record<MetaIdField, DefnComp>;
      case "ent":
        return {
          [fieldArgContextValKey]: {
            type: "pickOption",
            metaId: fieldArgContextValKey,
            disabled: disabled,
            required: required,
            hideLabel: true,
            label: "",
            optionSet: defn.filterConstantEntSet || EnumArrayDefnArgBinderContextEnt
          } as DefnFieldPickOption
        } as Record<MetaIdField, DefnComp>;
      case "form":
        return {
          [fieldArgContextValKey]: {
            type: "pickOption",
            metaId: fieldArgContextValKey,
            disabled: disabled,
            required: required,
            hideLabel: true,
            label: "",
            optionSet: EnumArrayDefnArgBinderContextForm
          } as DefnFieldPickOption
        } as Record<MetaIdField, DefnComp>;
      case "plugin":
        return {
          [fieldArgContextValKey]: {
            type: "pickOption",
            metaId: fieldArgContextValKey,
            disabled: disabled,
            required: required,
            hideLabel: true,
            label: "",
            optionSet: EnumArrayDefnArgBinderContextPlugin
          } as DefnFieldPickOption
        } as Record<MetaIdField, DefnComp>;
      case "row":
        return {
          [fieldArgContextValKey]: {
            type: "pickOption",
            metaId: fieldArgContextValKey,
            disabled: disabled,
            required: required,
            hideLabel: true,
            label: "",
            optionSet: defn.filterConstantRowSet || EnumArrayDefnArgBinderContextRow
          } as DefnFieldPickOption
        } as Record<MetaIdField, DefnComp>;
      case "callerSetting":
        return {
          [fieldArgContextValKey]: {
            type: "pickVarId",
            metaId: fieldArgContextValKey,
            disabled: disabled,
            required: required,
            hideLabel: true,
            label: "",
            varKindSet: defn.filterConstantCallerSettingContextSet || ["userSetting"]
          } as DefnStudioPickVarId
        } as Record<MetaIdField, DefnComp>;
      default:
        return {};
    }
  }

  return {};
}

function getCompMapFromArgFieldType(
  disabled: boolean,
  fieldType?: EnumDefnCompType,
  fieldArgValKey?: string,
  direction?: EnumDefnThemeDirection,
  required?: boolean,
  pickTextMapOfOption?: DefnStudioMapOfDtoOption,
  pickTreeOption?: FieldDtoTree
): Record<MetaIdField, DefnComp> | undefined
{
  if(fieldType && fieldArgValKey)
  {
    const label = "Value";

    switch(fieldType)
    {
      case "bool":
        return {
          [fieldArgValKey]: {
            type: fieldType,
            required: required,
            metaId: fieldArgValKey,
            disabled: disabled,
            label: label,
            pt: gapHalf,
            positionVar: direction === "vertical"
              ? "start"
              : "center"
          } as DefnFieldSwitch
        } as Record<MetaIdField, DefnComp>;
      case "date":
      case "dateTime":
      case "email":
      case "handle":
      case "hyperlink":
      case "mobileNumber":
      case "number":
      case "decimal":
      case "text":
      case "currency":
      case "language":
      case "timeZone":
      case "symbol":
      case "color":
      case "textSize":
      case "lineStroke":
      case "month":
      case "pinShape":
      case "quarter":
      case "paymentStatus":
      case "time":
      case "duration":
      case "chipSet":
      case "chipSetDateTime":
      case "chipSetDay":
      case "chipSetDeviceSize":
      case "chipSetDeviceType":
      case "chipSetTime":
      case "pickRole":
      case "counter":
        return {
          [fieldArgValKey]: {
            type: fieldType,
            required: required,
            metaId: fieldArgValKey,
            label: label,
            disabled: disabled,
            hideLabel: fieldType === "counter"
          } as DefnFieldEditable
        } as Record<MetaIdField, DefnComp>;
      case "rating":
        return {
          [fieldArgValKey]: {
            type: fieldType,
            required: required,
            metaId: fieldArgValKey,
            label: label,
            disabled: disabled,
            pt: gapHalf
          } as DefnFieldRating
        } as Record<MetaIdField, DefnComp>;
      case "pickText":
        return {
          [fieldArgValKey]: {
            type: fieldType,
            required: required,
            metaId: fieldArgValKey,
            label: label,
            optionMap: pickTextMapOfOption,
            disabled: disabled
          } as DefnFieldPickText
        } as Record<MetaIdField, DefnComp>;
      case "pickTree":
        return {
          [fieldArgValKey]: {
            type: fieldType,
            required: required,
            metaId: fieldArgValKey,
            label: label,
            disabled: disabled,
            sourceVar: pickTreeOption
          } as DefnFieldPickTree
        } as Record<MetaIdField, DefnComp>;
      case "paragraph":
        return {
          [fieldArgValKey]: {
            type: fieldType,
            required: required,
            metaId: fieldArgValKey,
            label: label,
            disabled: disabled,
            lineCountVar: 1,
            pl: direction === "horizontal" ? gapStd * 2 : undefined,
            pr: direction === "horizontal" ? gapStd * 2 : undefined
          } as DefnFieldParagraph
        } as Record<MetaIdField, DefnComp>;
      default:
        return undefined;
    }
  }

}

function useArgBinder(props: {
  defn: DefnStudioBuildArgBinder,
  formStore?: FormStore
})
{
  const defn = props.defn;
  const formStore = props.formStore;

  const parentMap = useRef<Record<MetaIdField, MetaIdComposite>>({});
  const fieldTypeMap = useRef<Record<MetaIdField, EnumDefnCompType>>({});

  useEffect(() =>
  {
    const formId = defn.formId;
    const derivedFormId = defn.derivedFormId;
    const inputFormId = defn.inputFormId;
    const pluginId = defn.pluginId;
    const derivedPluginId = defn.derivedPluginId;

    const formMap = formStore?.formMap;
    const sysFormMap = formStore?.sysFormMap;
    const pluginMap = formStore?.pluginMap;

    const form = formId
      ? isSystemForm(formId)
        ? sysFormMap?.map[formId]
        : formMap?.map[formId]
      : undefined;

    const derivedForm = derivedFormId
      ? formMap?.map[derivedFormId]
      : undefined;

    const inputForm = inputFormId
      ? formMap?.map[inputFormId]
      : undefined;

    const plugin = pluginId
      ? pluginMap?.map[pluginId]
      : undefined;

    const pluginForm = formId && plugin
      ? plugin.pluginFormMap[formId]
      : undefined;

    const derivedPlugin = derivedPluginId
      ? pluginMap?.map[derivedPluginId]
      : undefined;

    const pluginDerivedForm = derivedFormId && derivedPlugin
      ? derivedPlugin.pluginFormMap[derivedFormId]
      : undefined;

    if(form)
    {
      loopStudioForm(form, (composite, field) =>
      {
        if(composite.type === "section" || composite.type === "grid")
        {
          parentMap.current[field.metaId] = (composite as StudioSection | StudioGrid).metaId;
        }
        fieldTypeMap.current[field.metaId] = field.type as EnumDefnCompType;
      });
    }

    if(derivedForm)
    {
      loopStudioForm(derivedForm, (composite, field) =>
      {
        if(composite.type === "section" || composite.type === "grid")
        {
          parentMap.current[field.metaId] = (composite as StudioSection | StudioGrid).metaId;
        }
        fieldTypeMap.current[field.metaId] = field.type as EnumDefnCompType;
      });
    }

    if(inputForm)
    {
      loopStudioForm(inputForm, (composite, field) =>
      {
        if(composite.type === "section" || composite.type === "grid")
        {
          parentMap.current[field.metaId] = (composite as StudioSection | StudioGrid).metaId;
        }
        fieldTypeMap.current[field.metaId] = field.type as EnumDefnCompType;
      });
    }

    if(pluginForm)
    {
      loopDefnForm(pluginForm, (parent, comp) =>
      {
        if(parent.type === "section" || parent.type === "grid")
        {
          parentMap.current[comp.metaId] = (parent as DefnSection | DefnGrid).metaId;
        }
        fieldTypeMap.current[comp.metaId] = comp.type as EnumDefnCompType;
      });
    }

    if(pluginDerivedForm)
    {
      loopDefnForm(pluginDerivedForm, (parent, comp) =>
      {
        if(parent.type === "section" || parent.type === "grid")
        {
          parentMap.current[comp.metaId] = (parent as DefnSection | DefnGrid).metaId;
        }
        fieldTypeMap.current[comp.metaId] = comp.type as EnumDefnCompType;
      });
    }

  }, [defn]);

  const getFieldTypeMap = () => fieldTypeMap.current;

  const getDerivedPickTextOptions = useCallback((
    derivedFieldId?: MetaIdField,
    derivedFieldType?: EnumDefnCompType,
    derivedForm?: StudioForm,
    pluginDerivedForm?: DefnForm) =>
  {
    let derivedFieldPickTextOption: DefnStudioMapOfDtoOption | undefined = undefined;
    let derivedFieldPickTreeOption: FieldDtoTree | undefined = undefined;

    if(derivedFieldId)
    {
      if(derivedFieldType === "pickText")
      {
        if(derivedForm)
        {
          const compId = parentMap.current && parentMap.current[derivedFieldId];
          const comp = derivedForm.compositeMap.map[compId];
          const field = comp?.fieldMap.map[derivedFieldId] as StudioFieldPickText;
          const sourceVarId = (field && field?.sourceVarId)
            ? field.sourceVarId
            : undefined;

          if(sourceVarId)
          {
            const studioVar = formStore?.varMap?.map[sourceVarId] as StudioVarSetOfText;
            const value = studioVar?.value;

            const mapOfOption = {
              keys: [] as MetaIdVar[],
              map: {} as Record<MetaIdVar, DefnDtoOption>
            } as DefnStudioMapOfDtoOption;

            if(value && value.keys.length > 0)
            {
              Object.values(value.map).forEach(option =>
              {
                mapOfOption.keys.push(option.metaId);
                mapOfOption.map[option.metaId] = {
                  metaId: option.metaId,
                  value: option.value
                };
              });
            }
            derivedFieldPickTextOption = mapOfOption;
          }
        }
        else if(pluginDerivedForm)
        {
          const compMap = pluginDerivedForm.compMap;
          derivedFieldPickTextOption = (compMap[derivedFieldId] as DefnFieldPickText).optionMap;
        }
      }

      if(derivedFieldType === "pickTree")
      {
        if(derivedForm)
        {
          const compId = parentMap.current && parentMap.current[derivedFieldId];
          const comp = derivedForm.compositeMap.map[compId];
          const field = comp?.fieldMap.map[derivedFieldId] as StudioFieldPickTree;
          const sourceVarId = (field && field?.sourceVarId)
            ? field.sourceVarId
            : undefined;

          if(sourceVarId)
          {
            const studioVar = formStore?.varMap?.map[sourceVarId] as StudioVarTree;
            const value = studioVar?.value;
            derivedFieldPickTreeOption = value?.value;
          }
        }
        else if(pluginDerivedForm)
        {
          const compMap = pluginDerivedForm.compMap;
          derivedFieldPickTreeOption = (compMap[derivedFieldId] as DefnFieldPickTree).sourceVar;
        }
      }
    }

    return {
      derivedFieldPickTextOption,
      derivedFieldPickTreeOption
    };

  }, [formStore?.varMap?.map]);

  const getDefnForm = useCallback((
    fieldKey: string,
    disabled: boolean,
    fieldValue?: FieldValueArgBinder,
    label?: string) =>
  {
    const excludeFieldIdSet = defn.excludeFieldIdSet;
    const excludeVarIdSet = defn.excludeVarIdSet;
    const formId = defn.formId;
    const derivedFormId = defn.derivedFormId;
    const inputFormId = defn.inputFormId;
    const gridId = defn.gridId;
    const peerFieldId = defn.peerFieldId;
    const required = defn.required;
    const direction = defn.direction;
    const filterKindSet = defn.filterKindSet;
    const includeOptionMap = defn.includeOptionMap;
    const pluginId = defn.pluginId;
    const derivedPluginId = defn.derivedPluginId;
    const compositeIdSet = (defn.compositeIdSet && gridId)
      ? [...defn.compositeIdSet, gridId]
      : gridId
        ? [gridId]
        : defn.compositeIdSet;
    const derivedCompositeIdSet = (defn.derivedCompositeIdSet && gridId)
      ? [...defn.derivedCompositeIdSet, gridId]
      : gridId
        ? [gridId]
        : defn.derivedCompositeIdSet;
    const peerKind = defn.peerKind;

    const showDerivedFieldPicker = Boolean(!peerFieldId);
    const optionSet = fnGetOptionArray(inputFormId, filterKindSet);
    const formMap = formStore?.formMap;
    const pluginMap = formStore?.pluginMap;

    const derivedForm = derivedFormId
      ? formMap?.map[derivedFormId]
      : undefined;
    const derivedPlugin = derivedPluginId
      ? pluginMap?.map[derivedPluginId]
      : undefined;
    const pluginDerivedForm = derivedFormId && derivedPlugin
      ? derivedPlugin.pluginFormMap[derivedFormId]
      : undefined;

    const kind: EnumDefnArgBinder | undefined = peerKind
      ? peerKind
      : optionSet.length === 1
        ? optionSet[0]
        : fieldValue?.kind;
    let contextKind: EnumDefnArgBinderContext | undefined = undefined;
    let derivedFieldId: MetaIdField | undefined = peerFieldId;
    let derivedFieldType: EnumDefnCompType | undefined = undefined;
    let constantFieldType: EnumDefnCompType | undefined = undefined;

    if(kind === "context")
    {
      contextKind = (fieldValue?.value as StudioDtoArgValueContext)?.kind;
    }

    if(kind === "derived")
    {
      derivedFieldId = peerFieldId || (fieldValue?.value as StudioDtoArgValueDerived)?.derivedFieldId;
      derivedFieldType = (derivedFieldId && fieldTypeMap.current)
        ? fieldTypeMap.current[derivedFieldId]
        : undefined;
    }

    if(kind === "constant")
    {
      constantFieldType = (fieldValue?.value as StudioDtoArgValueConstant)?.type;
    }

    const kindKey = getKindKey(fieldKey);

    const fieldArgValKey = kind
      ? getArgValKey(fieldKey, kind)
      : undefined;

    const fieldArgDerivedValKey = (derivedFieldType && kind)
      ? getArgDerivedValKey(fieldKey, derivedFieldType)
      : undefined;

    const fieldArgContextValKey = (contextKind && kind)
      ? getArgContextValKey(fieldKey, contextKind)
      : undefined;

    const fieldArgConstantValKey = (constantFieldType && kind)
      ? getArgBinderConstValueKey(fieldKey, constantFieldType)
      : undefined;

    const showFieldArgComp = kind
      ? kind !== "derived" || (kind === "derived" && showDerivedFieldPicker)
      : undefined;

    const {
      derivedFieldPickTextOption,
      derivedFieldPickTreeOption
    } = getDerivedPickTextOptions(derivedFieldId, derivedFieldType, derivedForm, pluginDerivedForm);

    const derivedCompMap = (kind === "derived" && derivedFieldType)
      ? getCompMapFromArgFieldType(
        disabled,
        derivedFieldType,
        fieldArgDerivedValKey,
        direction,
        required,
        derivedFieldPickTextOption,
        derivedFieldPickTreeOption
      )
      : undefined;

    const contextCompMap = (kind === "context" && contextKind)
      ? getArgContextCompMap(
        defn,
        disabled,
        contextKind,
        fieldArgContextValKey,
        required
      )
      : undefined;

    const constCompMap = (kind === "constant" && constantFieldType)
      ? getCompMapFromArgFieldType(
        disabled,
        constantFieldType,
        fieldArgConstantValKey,
        direction,
        required
      )
      : undefined;

    return createDefaultDefnFormStudio({
      ...showFieldArgComp && getFieldArgCompMap(
        defn,
        fieldKey,
        disabled,
        formId,
        derivedFormId,
        kind,
        excludeFieldIdSet,
        excludeVarIdSet,
        inputFormId,
        compositeIdSet,
        derivedCompositeIdSet,
        includeOptionMap,
        pluginId,
        derivedPluginId,
        required
      ),

      ...derivedCompMap,

      ...contextCompMap,

      ...constCompMap,

      [kindKey]: {
        type: "pickOption",
        metaId: kindKey,
        label: label,
        disabled: disabled,
        optionSet: optionSet,
        required: required
      } as DefnFieldPickOption,

      [fieldKey]: {
        type: "section",
        metaId: fieldKey,
        fieldIdSet: [
          ...(optionSet.length === 1 || peerKind)
            ? []
            : [kindKey],
          ...(showFieldArgComp && fieldArgValKey)
            ? [fieldArgValKey]
            : [],
          ...derivedCompMap
            ? [fieldArgDerivedValKey]
            : [],
          ...contextCompMap
            ? [fieldArgContextValKey]
            : [],
          ...constCompMap
            ? [fieldArgConstantValKey]
            : []
        ],
        sectionDirection: direction ?? "horizontal"
      } as DefnSection,

      [defaultSectionKey]: {
        type: "section",
        metaId: defaultSectionKey,
        name: defaultSectionKey,
        fieldIdSet: [fieldKey]
      } as DefnSection

    } as Record<MetaIdField, DefnField>);
  }, [defn, formStore?.formMap, formStore?.pluginMap, getDerivedPickTextOptions]);

  const valueToDto = useCallback((fieldId: MetaIdField, fieldValue?: FieldValueArgBinder, values?: FieldValues) =>
  {
    const argName = defn.name;
    const peerKind = defn.peerKind;
    const peerFieldId = defn.peerFieldId;
    const inputFormId = defn.inputFormId;
    const filterKindSet = defn.filterKindSet;

    let studioBuildArgBinder: FieldValueArgBinder = null;
    const optionSet = fnGetOptionArray(inputFormId, filterKindSet);

    const kind = peerKind
      ? peerKind
      : optionSet?.length === 1
        ? optionSet[0]
        : fieldValue?.kind as EnumDefnArgBinder | undefined;

    if(kind)
    {
      studioBuildArgBinder = {
        kind: kind,
        argName: argName
      } as StudioBuildArgBinder;

      switch(kind)
      {
        case "context":
        {
          const fieldContextArgValue = fieldValue?.value as StudioDtoArgValueContext | undefined;
          const contextKind = fieldContextArgValue?.kind as EnumDefnArgBinderContext | undefined;

          if(contextKind)
          {
            let studioContextArgValue: StudioDtoArgValueContext = {
              kind: contextKind
            };
            let contextArgValue: string | undefined;

            switch(contextKind)
            {
              case "caller":
                contextArgValue = fnFieldValueToRawValue("pickOption",
                  (fieldContextArgValue as StudioDtoArgValueContextCaller)?.attribute
                ) as EnumDefnArgBinderContextCaller | undefined;
                if(contextArgValue)
                {
                  (studioContextArgValue as StudioDtoArgValueContextCaller).attribute =
                    contextArgValue as EnumDefnArgBinderContextCaller;
                }
                break;
              case "ent":
                contextArgValue = fnFieldValueToRawValue("pickOption",
                  (fieldContextArgValue as StudioDtoArgValueContextEnt)?.attribute
                ) as EnumDefnArgBinderContextEnt | undefined;
                if(contextArgValue)
                {
                  (studioContextArgValue as StudioDtoArgValueContextEnt).attribute =
                    contextArgValue as EnumDefnArgBinderContextEnt;
                }
                break;
              case "form":
                contextArgValue = fnFieldValueToRawValue("pickOption",
                  (fieldContextArgValue as StudioDtoArgValueContextForm)?.attribute
                ) as EnumDefnArgBinderContextForm | undefined;
                if(contextArgValue)
                {
                  (studioContextArgValue as StudioDtoArgValueContextForm).attribute =
                    contextArgValue as EnumDefnArgBinderContextForm;
                }
                break;
              case "plugin":
                contextArgValue = fnFieldValueToRawValue("pickOption",
                  (fieldContextArgValue as StudioDtoArgValueContextPlugin)?.attribute
                ) as EnumDefnArgBinderContextPlugin | undefined;
                if(contextArgValue)
                {
                  (studioContextArgValue as StudioDtoArgValueContextPlugin).attribute =
                    contextArgValue as EnumDefnArgBinderContextPlugin;
                }
                break;
              case "row":
                contextArgValue = fnFieldValueToRawValue("pickOption",
                  (fieldContextArgValue as StudioDtoArgValueContextRow)?.attribute
                ) as EnumDefnArgBinderContextRow | undefined;
                if(contextArgValue)
                {
                  (studioContextArgValue as StudioDtoArgValueContextRow).attribute =
                    contextArgValue as EnumDefnArgBinderContextRow;
                }
                break;
              case "callerSetting":
                contextArgValue = fnFieldValueToRawValue("pickVarId",
                  (fieldContextArgValue as StudioDtoArgValueContextCallerSetting)?.userSettingVarId
                ) as MetaIdVar | undefined;
                if(contextArgValue)
                {
                  (studioContextArgValue as StudioDtoArgValueContextCallerSetting).userSettingVarId =
                    contextArgValue as MetaIdVar;
                }
                break;
            }

            studioBuildArgBinder = {
              ...studioBuildArgBinder,
              value: studioContextArgValue
            };
          }
        }
          break;
        case "field":
        {
          const fieldFieldArgValue = fieldValue?.value as StudioDtoArgValueField | undefined;
          const fieldFieldId = fieldFieldArgValue?.fieldId;

          if(fieldFieldId)
          {
            const compositeId = parentMap.current && parentMap.current[fieldFieldId];

            if(compositeId || EnumArrayDefnFields.includes(fieldFieldId))
            {
              studioBuildArgBinder = {
                ...studioBuildArgBinder,
                value: {
                  fieldId: fieldFieldId,
                  compositeId: compositeId
                } as StudioDtoArgValueField
              };
            }
          }
        }
          break;
        case "input":
        {
          const fieldInputArgValue = fieldValue?.value as StudioDtoArgValueInput | undefined;
          const inputFieldId = fieldInputArgValue?.fieldId;

          if(inputFieldId)
          {
            const compositeId = parentMap.current && parentMap.current[inputFieldId];

            if(compositeId || EnumArrayDefnFields.includes(inputFieldId))
            {
              studioBuildArgBinder = {
                ...studioBuildArgBinder,
                value: {
                  fieldId: inputFieldId,
                  compositeId: compositeId
                } as StudioDtoArgValueInput
              };
            }
          }
        }
          break;
        case "variable":
        {
          const fieldVarArgValue = fieldValue?.value as StudioDtoArgValueVariable | undefined;
          const varId = fieldVarArgValue?.varId;

          if(varId)
          {
            studioBuildArgBinder = {
              ...studioBuildArgBinder,
              value: {
                varId: varId
              } as StudioDtoArgValueVariable
            };
          }
        }
          break;
        case "derived":
        {
          const fieldDerivedArgValue = fieldValue?.value as StudioDtoArgValueDerived | undefined;
          const derivedFieldId = peerFieldId || fieldDerivedArgValue?.derivedFieldId;

          if(derivedFieldId)
          {
            const derivedFieldType = derivedFieldId && fieldTypeMap.current
              ? fieldTypeMap.current[derivedFieldId]
              : undefined;

            const derivedValue = {
              derivedFieldId: derivedFieldId,
              derivedFieldType: derivedFieldType
            } as StudioDtoArgValueDerived;

            if(derivedFieldType)
            {
              switch(derivedFieldType)
              {
                case "bool":
                  derivedValue.valueBoolean =
                    fnFieldValueToRawValue("bool", fieldDerivedArgValue?.valueBoolean) as boolean;
                  break;
                case "date":
                case "dateTime":
                  derivedValue.valueDate =
                    fnFieldValueToRawValue(derivedFieldType, fieldDerivedArgValue?.valueDate) as string;
                  break;
                case "rating":
                case "decimal":
                  derivedValue.valueDouble =
                    fnFieldValueToRawValue(derivedFieldType, fieldDerivedArgValue?.valueDouble) as number;
                  break;
                case "number":
                case "counter":
                  derivedValue.valueLong =
                    fnFieldValueToRawValue(derivedFieldType, fieldDerivedArgValue?.valueLong) as number;
                  break;
                case "symbol":
                case "text":
                case "paragraph":
                case "hyperlink":
                case "mobileNumber":
                case "email":
                case "handle":
                  derivedValue.valueText =
                    fnFieldValueToRawValue(derivedFieldType, fieldDerivedArgValue?.valueText) as string;
                  break;
                case "pickText":
                case "language":
                case "timeZone":
                case "currency":
                case "pickTree":
                case "paymentStatus":
                case "pickRole":
                  derivedValue.valueOptionId =
                    fnFieldValueToRawValue(derivedFieldType, fieldDerivedArgValue?.valueOptionId) as string;
                  break;
              }
            }

            studioBuildArgBinder = {
              ...studioBuildArgBinder,
              value: derivedValue
            };
          }
        }
          break;
        case "constant":
        {
          const fieldVarArgValue = fieldValue?.value as StudioDtoArgValueConstant | undefined;
          const constantFieldType = fieldVarArgValue?.type;

          if(constantFieldType)
          {
            const constantValue = {
              type: constantFieldType
            } as StudioDtoArgValueConstant;

            const valueKey = getArgBinderConstValueKey(fieldId, constantFieldType);
            const constArgValue = values ? values[valueKey] : undefined;

            if(constArgValue)
            {
              constantValue.value = constArgValue;
            }

            studioBuildArgBinder = {
              ...studioBuildArgBinder,
              value: constantValue
            };
          }
        }
          break;
      }
    }

    return studioBuildArgBinder;

  }, [defn]);

  return {
    valueToDto,
    getDefnForm,
    getFieldTypeMap
  };
}
