import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {CssBackgroundColor} from "../../../base/plus/ThemePlus";
import {EnumIconStrip} from "../../../base/types/TypesIcon";
import {EnumIconAvatar} from "../../../base/types/TypesIcon";
import {useDeviceType} from "../../app/AppHooks";
import {useAppCtx} from "../../ctx/CtxApp";
import {DividerHorizontal} from "../layout/DividerHorizontal";
import {TypeIconStripColorMap} from "../raw/RawIconStrip";
import {TypeIconStripBadgeMap} from "../raw/RawIconStrip";
import {ToastNetworkOn} from "../toast/ToastNetworkOn";
import PaneHeader from "./PaneHeader";

export default function PaneSide(props: {
  actionIcon?: EnumIconStrip,
  avatarSrc1?: string,
  avatarIcon1?: EnumIconAvatar,
  avatarSrc2?: string,
  avatarIcon2?: EnumIconAvatar,
  primaryText?: string,
  secondaryText?: string,
  iconStrip?: EnumIconStrip[],
  iconStripDisable?: EnumIconStrip[]
  iconStripBadgeMap?: TypeIconStripBadgeMap,
  iconStripColorMap?: TypeIconStripColorMap,
  showNetworkToast?: boolean,
  showToggleButton?: boolean,
  toolTipMap?: Record<EnumIconStrip, string>
  toolbarIconButtons?: React.ReactNode[]

  // style
  bgcolor?: CssBackgroundColor,
  height?: string,
  zindex?: number,
  hideActionIcon?: boolean,

  // handlers
  onClickAction?: () => void,
  onClickAvatar?: () => void,
  onClickIconStrip?: (iconStrip: EnumIconStrip, menuAnchor: Element) => void,

  // content of aside
  children: React.ReactNode
})
{
  const theme = useTheme();
  const deviceType = useDeviceType();

  const appCtx = useAppCtx();
  const isMobile = appCtx.isMobile();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        bgcolor: theme.common.bgcolorDefault,
        zIndex: props.zindex
      }}
    >
      <PaneHeader
        drawerToggleIcon={props.showToggleButton ? (!isMobile
          ? (appCtx.isDrawerOpen()
            ? "MenuOpen"
            : "MenuClose")
          : undefined) : undefined}
        actionIcon={!props.hideActionIcon
          ? props.actionIcon
            ? props.actionIcon
            : deviceType === "mobile" ? "backIos" : "forwardIos"
          : undefined}
        onClickAction={props.onClickAction}
        primaryText={props.primaryText}
        secondaryText={props.secondaryText}
        iconStrip={props.iconStrip}
        iconStripBadgeMap={props.iconStripBadgeMap}
        iconStripDisable={props.iconStripDisable}
        iconStripColorMap={props.iconStripColorMap}
        onClickAvatar={props.onClickAvatar}
        onClickIconStrip={props.onClickIconStrip}
        toolTipMap={props.toolTipMap}
        toolbarIconButtons={props.toolbarIconButtons}
        onClickDrawerToggle={() =>
        {
          if(appCtx.isDrawerOpen())
          {
            appCtx.setDrawerVisibility(false);
          }
          else
          {
            appCtx.setDrawerVisibility(true);
          }
        }}
      />

      <DividerHorizontal />

      {Boolean(props.showNetworkToast) ? <ToastNetworkOn /> : null}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          width: "100%",
          bgcolor: theme.common.bgcolorSidePane,
          flexGrow: 1
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
