import ISrvc from "../../base/ISrvc";
import SrvcCacheBadge from "./badge/SrvcCacheBadge";
import SrvcCacheCaller from "./caller/SrvcCacheCaller";
import SrvcCacheCallerEnt from "./callerEnt/SrvcCacheCallerEnt";
import SrvcCacheGroup from "./group/SrvcCacheGroup";
import SrvcCacheSpreadsheet from "./spreadsheet/SrvcCacheSpreadsheet";
import SrvcCacheStarMsgList from "./starMsg/SrvcCacheStarMsgList";
import SrvcCacheUser from "./user/SrvcCacheUser";

export default class SrvcCacheApp extends ISrvc
{
  public readonly badge = new SrvcCacheBadge();
  public readonly caller = new SrvcCacheCaller();
  public readonly callerEnt = new SrvcCacheCallerEnt();
  public readonly user = new SrvcCacheUser();
  public readonly group = new SrvcCacheGroup();
  public readonly startMsg = new SrvcCacheStarMsgList();
  public readonly spreadsheet = new SrvcCacheSpreadsheet();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.badge,
      this.caller,
      this.callerEnt,
      this.user,
      this.group,
      this.startMsg,
      this.spreadsheet
    );
  }
}
