import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {random} from "../../../../base/plus/StringPlus";
import {TypeBulkImportStatusType} from "./TypesCacheSpreadsheetEditor";
import {ICacheSsEditorRows} from "./TypesCacheSpreadsheetEditor";
import {ICacheSsEditorRow} from "./TypesCacheSpreadsheetEditor";
import {ICacheSsEditorRowStateMap} from "./TypesCacheSpreadsheetEditor";
import {ActionCacheHomeAppSsEditorSetRowIds} from "./TypesCacheSpreadsheetEditor";
import {ActionSetICacheSsEditorRow} from "./TypesCacheSpreadsheetEditor";
import {ICacheHomeAppSsEditorSlice} from "./TypesCacheSpreadsheetEditor";

export const sliceCacheSpreadsheetEditor = createSlice({
  name: "cacheSpreadsheetEditor",
  initialState: {
    ssStateMap: {}
  } as ICacheHomeAppSsEditorSlice,
  reducers: {
    setCacheSsEditorRowIdMap: (
      state: ICacheHomeAppSsEditorSlice,
      action: PayloadAction<ActionCacheHomeAppSsEditorSetRowIds>) =>
    {
      const payload = action.payload;
      const spreadsheetId = payload.spreadsheetId;

      state.ssStateMap[spreadsheetId] = {
        rowIdMap: {},
        bulkImportStatus: "load"
      } as ICacheSsEditorRowStateMap;
    },

    setCacheSsEditorRowState: (
      state: ICacheHomeAppSsEditorSlice,
      action: PayloadAction<ActionSetICacheSsEditorRow>) =>
    {
      const payload = action.payload;
      const spreadsheetId = payload.spreadsheetId;
      if(!state.ssStateMap[spreadsheetId])
      {
        return;
      }
      state.ssStateMap[spreadsheetId].rowIdMap[payload.rowId] = payload.sig;
    },

    setCacheSsEditorRowsState: (
      state: ICacheHomeAppSsEditorSlice,
      action: PayloadAction<ICacheSsEditorRows>) =>
    {
      const payload = action.payload;
      const spreadsheetId = payload.spreadsheetId;
      if(!state.ssStateMap[spreadsheetId])
      {
        state.ssStateMap[spreadsheetId] = {
          rowIdMap: {},
          bulkImportStatus: "load"
        } as ICacheSsEditorRowStateMap;
      }
      payload.rowIds.forEach((rowId) =>
      {
        if(payload.removed)
        {
          delete state.ssStateMap[spreadsheetId]?.rowIdMap[rowId];
        }
        else if(payload.error)
        {
          state.ssStateMap[spreadsheetId].rowIdMap[rowId] = {
            removed: false,
            error: payload.error
          } as ICacheSsEditorRow;
        }
      });
    },

    setCacheSsEditorBulkImportStatus: (
      state: ICacheHomeAppSsEditorSlice,
      action: PayloadAction<{
        spreadsheetId: MetaIdSpreadsheet;
        bulkImportStatus: TypeBulkImportStatusType;
      }>) =>
    {
      const payload = action.payload;
      const spreadsheetId = payload.spreadsheetId;
      const bulkImportStatus = payload.bulkImportStatus;

      if(!state.ssStateMap[spreadsheetId])
      {
        return;
      }

      state.ssStateMap[spreadsheetId].bulkImportStatus = bulkImportStatus;
    },

    setCacheSsEditorRowMutation: (
      state: ICacheHomeAppSsEditorSlice,
      action: PayloadAction<MetaIdSpreadsheet>) =>
    {
      const spreadsheetId = action.payload;
      if(!state.ssStateMap[spreadsheetId])
      {
        return;
      }
      state.ssStateMap[spreadsheetId].mutation = random(5);
    },

    clearCacheSsEditorState: (state: ICacheHomeAppSsEditorSlice, action: PayloadAction<MetaIdSpreadsheet>) =>
    {
      const payload = action.payload;
      delete state.ssStateMap[payload];
    }
  }
});

export const {
  setCacheSsEditorRowIdMap,
  setCacheSsEditorRowState,
  setCacheSsEditorRowsState,

  setCacheSsEditorRowMutation,
  clearCacheSsEditorState,

  setCacheSsEditorBulkImportStatus
} = sliceCacheSpreadsheetEditor.actions;
