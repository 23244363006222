import {MsgAsideSearch} from "../../../api/home/aside/msg/MsgAsideSearch";
import {WsocAside} from "../../../api/home/aside/WsocAside";
import {SigAsideSearch} from "../../../api/home/drawer/sig/SigAsideSearch";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {SigMessage} from "../../../api/home/main/sig/SigMessage";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {MessageId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {getFormNameColor} from "../../../base/plus/FormPlus";
import {createListItemChat} from "../../../base/plus/ListPlus";
import {getChatItem} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listChatSetIfExistHeader} from "../../../base/slices/list/SliceListChatAction";
import {listSetUserFieldVar} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinderSix} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {toComboId} from "../../../base/types/TypesComboId";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {textUserOrYou} from "../../../Store";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";
import SrvcHomeListChat from "../app/SrvcHomeListChat";

type TypeSigSearchAvatar = SigGroupAvatar | SigUserAvatar | undefined;

export default class SrvcHomeAsideSearch extends SrvcHomeListChat
{
  constructor()
  {
    super((state: RootState) => state.home.aside.searchList);
  }

  load(listName: string, entId: EntId, chatId: ChatId, searchId: string, searchStr: string)
  {
    dispatchList(listName,
      listSetUserFieldVar({
        varName: "loaded",
        varValue: false
      })
    );
    const msg: MsgAsideSearch = {
      searchId: searchId,
      searchQuery: searchStr,
      chatId: chatId,
      pageSize: 50
    };

    WsocAside.asideSearch(entId, msg, (envSig) =>
    {
      if(Srvc.app.toast.showErrorToast(envSig))
      {
        dispatchList(listName,
          listSetUserFieldVar({
            varName: "loaded",
            varValue: true
          })
        );
        return;

      }
      const sig = envSig.sig as SigAsideSearch;
      this.doLoadChatItem(listName, entId, chatId, sig.messageList);
    });
  }

  //region binder
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectUserAvatar.bind(this),
      onBindSourceItem1: this.onBindUserAvatar.bind(this),

      selectSourceItem2: this.selectDefnForm.bind(this),
      onBindSourceItem2: this.onBindDefnForm.bind(this),

      selectSourceItem3: this.selectIsStarMsg.bind(this),
      onBindSourceItem3: this.onBindIsStarMsg.bind(this),

      selectSourceItem4: this.selectCanShowComments.bind(this),
      onBindSourceItem4: this.onBindCanShowComments.bind(this),

      selectSourceItem5: this.selectChatPattern.bind(this),
      onBindSourceItem5: this.onBindChatPattern.bind(this),

      selectSourceItem6: this.selectReply.bind(this),
      onBindSourceItem6: this.onBindReply.bind(this),

      selectUserAvatar: this.selectEntUserAvatar.bind(this)
    } as IListBinderSix<TypeSigSearchAvatar, DefnForm, boolean, boolean, SigSpreadsheetRow, SigUserAvatar>;
  }

  private selectUserAvatar(rootState: RootState, messageId: MessageId): TypeSigSearchAvatar
  {
    const chatItem = getChatItem(rootState, messageId, this.selectList) as IListItemChat;
    const entId = chatItem?.entId;
    const senderId = chatItem?.sig.senderId;
    const isGroup = isGroupId(chatItem?.chatId);
    if(isGroup)
    {
      const entUserId = toComboId(entId, senderId);
      return rootState.cache.app.user.userAvatarMap[entUserId];
    }
  }

  private onBindUserAvatar(listName: string, messageId: MessageId, avatar?: TypeSigSearchAvatar): void
  {
    if(avatar)
    {
      const rootState = store.getState();
      const chatItem = getChatItem(rootState, messageId, this.selectList) as IListItemChat;
      dispatchList(listName, listChatSetIfExistHeader({
        itemId: messageId,
        header: {
          ...chatItem.sig.header,
          headerTextLeft3: textUserOrYou(rootState, avatar as SigUserAvatar)
        }
      }));
    }
  }

  //endregion

  private doLoadChatItem(listName: string, entId: EntId, chatId: ChatId, sigMessage: SigMessage[])
  {
    const rootState = store.getState();
    const uiItemsById = {} as IListItemsById;
    const uiItemIds = [] as TypeComboId[];

    const entUserId = selectCallerEnt(rootState, entId)?.entUserId;

    sigMessage.forEach(sig =>
    {
      uiItemIds.push(sig.messageId);
      const listItem = createListItemChat(entId, chatId, sig);

      listItem.sig.formBubbleTitleColor = getFormNameColor(
        entUserId,
        listItem.sig.sigSpreadsheetRow?.formValue?.updatedBy,
        listItem.sig.sigSpreadsheetRow?.updatedKeySet
      );

      uiItemsById[sig.messageId] = listItem;
    });

    const searchWords = this.selectList(rootState).searchWords;

    dispatchList(listName, listRefresh({
      searchWords: searchWords,
      itemsById: uiItemsById,
      itemIds: uiItemIds,
      userField: this.getHomeListChatUserField(Object.values(uiItemsById) as IListItemChat[])
    }));
  }
}

