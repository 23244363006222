import {ChartDataset} from "chart.js";
import {ChartOptions} from "chart.js";
import {Legend} from "chart.js";
import {Tooltip} from "chart.js";
import {LineElement} from "chart.js";
import {PointElement} from "chart.js";
import {LinearScale} from "chart.js";
import {Chart as ChartJS} from "chart.js";
import {useMemo} from "react";
import {Scatter} from "react-chartjs-2";
import {IChart} from "../../../base/types/TypeCharts";
import useXYChart from "./ChartPlus";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export default function ChartScatterPlot(props: IChart)
{
  const {
    getOptions,
    labels,
    getDataScatterPlot
  } = useXYChart(props);

  const dataSet = useMemo(() => getDataScatterPlot(), [getDataScatterPlot]);
  const layout = props.layout;

  return <Scatter
    options={getOptions(props.allowAnimation, layout) as ChartOptions<"scatter">}
    data={{
      labels: labels,
      xLabels: labels,
      datasets: dataSet as ChartDataset<"scatter">[]
    }}
  />;
}

