import {RpcAside} from "../../../../api/home/aside/RpcAside";
import {MsgGroupId} from "../../../../api/home/base/msg/MsgGroupId";
import {RpcDrawer} from "../../../../api/home/drawer/RpcDrawer";
import {EntUserId} from "../../../../api/meta/base/Types";
import {GroupId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {comboToAboutId} from "../../../../base/types/TypesComboId";
import {Srvc} from "../../../Srvc";

export default class SrvcHomeAsideGroupInfoMemberListActions extends ISrvc
{
  wsocGroupExit(groupId: GroupId)
  {
    const msg = {
      groupId: groupId
    } as MsgGroupId;

    if(groupId)
    {
      RpcDrawer.groupExit(msg, Srvc.app.toast.showErrorToast);
    }
  }

  wsocGroupMemberRemove(groupId: GroupId, itemId: EntUserId)
  {
    const msg = {
      groupId: groupId,
      removeMemberSet: [itemId]
    };
    RpcAside.groupMembersRemove(msg, Srvc.app.toast.showErrorToast);
  }

  wsocGroupMemberRemoveAdmin(groupId: GroupId, itemId: EntUserId)
  {
    const msg = {
      groupId: groupId,
      removeAdminSet: [itemId]
    };
    RpcAside.groupMembersRemove(msg, Srvc.app.toast.showErrorToast);
  }

  wsocGroupMembersAddAdmin(groupId: GroupId, itemId: EntUserId)
  {
    const msg = {
      groupId: groupId,
      insertAdminSet: [itemId]
    };
    RpcAside.groupMembersAdd(msg, Srvc.app.toast.showErrorToast);
  }

  wsocGroupMembersAdd(groupId: GroupId, itemIds: EntUserId[])
  {
    const aboutIdSet = itemIds.map(item => comboToAboutId(item));
    const msg = {
      groupId: groupId,
      insertMemberSet: aboutIdSet
    };
    RpcAside.groupMembersAdd(msg, Srvc.app.toast.showErrorToast);
  }
}
