import {isEqual} from "lodash";
import {useEffect} from "react";
import React, {useCallback, useMemo, useState} from "react";
import {Controller} from "react-hook-form";
import {FieldErrors} from "react-hook-form";
import {DefnStudioMapOfMapping} from "../../../../api/meta/base/dto/DefnStudioMapOfMapping";
import {StudioDtoMappingGridMap} from "../../../../api/meta/base/dto/StudioDtoMappingGridMap";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {ITreeNode, ITreeRef} from "../../../../base/types/TypesTree";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import RawTree from "../../../atom/raw/RawTree";
import {usePageCtx} from "../../../ctx/CtxPage";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {fnGetTreeNodeLabel} from "../base/FieldVarMappingPlus";
import {IMappingVarFunctions} from "../base/FieldVarMappingPlus";
import {useMappingVarFunctions} from "../base/FieldVarMappingPlus";
import {convertMappingToRootNodes} from "../base/FieldVarMappingPlus";
import {convertRootNodesToMapping} from "../base/FieldVarMappingPlus";
import {useFieldsMappingFunctions} from "../base/FieldVarMappingPlus";
import {useCompositesMappingFunctions} from "../base/FieldVarMappingPlus";
import {INodeUserFieldMappingVar} from "../base/FieldVarMappingPlus";
import FieldRawTreeShell from "../raw/FieldRawTreeShell";

export default function FieldStudioGridMappingTree(props: {
  defn: DefnStudioMapOfMapping,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;

  const isFormReadOnly = formCtx.isReadonly();
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const formStore = formCtx.getStore();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        formState: {errors}
      }) =>
      {
        const fieldValue = field.value;
        const onChange = field.onChange;
        const _errors = errors?.["mapping"] ? errors : undefined;
        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          return <></>;
        }

        return (
          <FieldRawGridMappingTree
            defn={defn}
            isFormReadOnly={isFormReadOnly}
            showAddBtn={true}
            formStore={formStore}
            fieldValue={fieldValue}
            onChange={onChange}
            errors={_errors}
          />
        );
      }}
    />
  );
}

function FieldRawGridMappingTree(props: {
  defn: DefnStudioMapOfMapping,
  fieldValue?: StudioDtoMappingGridMap,
  showAddBtn?: boolean,
  isFormReadOnly?: boolean,
  formStore?: FormStore,
  errors?: FieldErrors,
  onChange: (value: StudioDtoMappingGridMap) => void,
})
{
  const pageCtx = usePageCtx();
  const formCtx = useFormCtx();
  const cbRefTree = useMemo(() => ({} as ITreeRef), []);

  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const errors = props.errors;
  const showAddBtn = props.showAddBtn;
  const isFormReadOnly = Boolean(props.isFormReadOnly);

  const formStore = formCtx.getStore();
  const toFormId = defn.toFormId;

  const [rootNodes, setRootNodes] = useState<ITreeNode[]>(convertMappingToRootNodes(fieldValue, errors));

  const mappingVarFromFunctions = useMappingVarFunctions("from", defn, formStore);
  const mappingVarToFunctions = useMappingVarFunctions("to", defn, formStore);

  const fromForm = mappingVarFromFunctions.form;
  const toForm = mappingVarToFunctions.form;

  const mappingVarFunctions = useCallback((type: "from" | "to"): IMappingVarFunctions =>
  {
    return type === "from"
      ? mappingVarFromFunctions
      : mappingVarToFunctions;
  }, [mappingVarFromFunctions, mappingVarToFunctions]);

  const onChange = (rootNodes: ITreeNode[]) => props.onChange(convertRootNodesToMapping(rootNodes));

  const {
    addMappingComp,
    editMappingComp,
    pasteMappingComp
  } = useCompositesMappingFunctions({
    defn: defn,
    getMappingVarFunctions: mappingVarFunctions,
    cbRefTree,
    isFormReadOnly: isFormReadOnly
  });

  const {
    addMappingField,
    editMappingField,
    pasteMappingField
  } = useFieldsMappingFunctions({
    defn: defn,
    getMappingVarFunctions: mappingVarFunctions,
    cbRefTree,
    isFormReadOnly: isFormReadOnly
  });

  const cbOnAddField = useCallback((node: ITreeNode) =>
  {
    const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
    if(userField)
    {
      const fromCompId = userField.studioDtoMappingComposite?.fromGridId;
      const toCompId = userField.studioDtoMappingComposite?.toGridId;

      if(fromForm && toForm && toCompId)
      {
        addMappingField(node, fromCompId, toCompId);
      }
    }
  }, [fromForm, toForm, addMappingField]);

  const cbOnEdit = useCallback((node: ITreeNode) =>
    {
      const userField = node.userField && node.userField["sig"] as INodeUserFieldMappingVar;
      if(userField)
      {
        const type = userField.type;
        const fromCompositeId = userField.studioDtoMappingComposite?.fromGridId;
        const toCompositeId = userField.studioDtoMappingComposite?.toGridId;

        if(fromForm && toForm)
        {
          if(type === "composite")
          {
            editMappingComp(node);
          }
          else if(type === "field" && (fromCompositeId || toCompositeId))
          {
            editMappingField(node, fromCompositeId, toCompositeId);
          }
        }
      }
    },
    [
      fromForm,
      toForm,
      editMappingComp,
      editMappingField
    ]
  );

  useEffect(() =>
  {
    const newTree = convertMappingToRootNodes(fieldValue, errors);

    if(!isEqual(rootNodes, newTree))
    {
      setRootNodes(newTree);
    }

  }, [mappingVarFunctions, fieldValue]);

  return (
    <FieldRawTreeShell
      rawTree={<RawTree
        showUiFlatTree={true}
        cbRef={cbRefTree}
        rootNodes={rootNodes}
        onChange={(rootNodes) => rootNodes && onChange(rootNodes)}
        getLabel={fnGetTreeNodeLabel(
          isFormReadOnly,
          pageCtx,
          mappingVarFunctions,
          cbRefTree,
          cbOnEdit,
          cbOnAddField,
          pasteMappingField,
          formStore,
          errors
        )}
      />}
      buttonStrip={
        showAddBtn &&
        <RawButtonStrip
          onClick={(iconName) =>
          {
            if(iconName === "add" && fromForm && toForm)
            {
              addMappingComp();
            }
            if(iconName === "paste")
            {
              pasteMappingComp();
            }
          }}
          iconButtonList={["paste", "add"]}
          iconButtonDisable={(isFormReadOnly || !toFormId)
            ? ["paste", "add"]
            : undefined
          }
          toolTipMap={{
            add: "Add mapping",
            paste: "Paste mapping"
          } as Record<EnumIconButton, string>}
          labelMap={{
            add: "ADD...",
            paste: "PASTE"
          } as Record<EnumIconButton, string>}
        />
      }
      label={"Mapping"}
    />
  );
}




