import {TextField} from "@mui/material";
import {isInteger} from "lodash";
import {toNumber} from "lodash";
import {ChangeEvent} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {FORM_FIELD_SMALL_PADDING} from "../../../../base/plus/ConstantsPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconButton from "../../../atom/raw/RawIconButton";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldRawLogInfoButton from "./FieldRawLogInfoButton";
import FieldRawRefButton from "./FieldRawRefButton";

export default function FieldRawNumber(props: {
  defn: DefnFieldEditable,
  fieldId: string,
  label: string,
  value?: number,
  onChange: (value?: number) => void,
  error?: FieldError,
  onBlur?: () => void,
  minVar?: number,
  maxVar?: number
  allowDecimal?: boolean,
  showLogInfo?: boolean
})
{
  const defn = props.defn;
  const {
    getFieldPlaceHolder,
    getFieldRequired,
    getFieldDigitsAfterPeriod,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const fieldId = props.fieldId;
  const label = props.label;
  const value = props.value;
  const error = props.error;
  const onBlur = props.onBlur;
  const minVar = props.minVar;
  const maxVar = props.maxVar;
  const allowDecimal = props.allowDecimal;
  const icon = getFieldIcon();

  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const defnTheme = formCtx.getDefnTheme();
  const getOnClick = formCtx.getOnClick();
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn) || defn.disabled;

  const isError = Boolean(error);
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);
  const fieldVariant = defnTheme.fieldVariant;
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const isFieldStandard = fieldVariant === "standard";
  const filledInputPadding = defnTheme.fieldSize === "small"
    ? FORM_FIELD_SMALL_PADDING
    : FORM_FIELD_MEDIUM_PADDING;

  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
  {
    const value = event.target.value;
    const numericValue = toNumber(value);
    if(isNaN(numericValue) || value.trim() === "")
    {
      props.onChange(undefined);
      return;
    }

    if(!allowDecimal)
    {
      props.onChange(parseInt(value));
    }
    else
    {
      const digitsAfterPeriod = getFieldDigitsAfterPeriod();
      if(digitsAfterPeriod)
      {
        props.onChange(parseFloat(numericValue.toFixed(digitsAfterPeriod)));
      }
      else
      {
        props.onChange(numericValue);
      }
    }
  };

  const required = getFieldRequired();
  const placeholder = getFieldPlaceHolder();

  const validateInput = (e: React.KeyboardEvent<HTMLDivElement>, isDecimalAllowed: boolean) =>
  {
    const allowedChars = "-0123456789";

    if(isDecimalAllowed && e.key === "." && isInteger(value))
    {
      return false;
    }
    return (e.code !== "Backspace" && !allowedChars.includes(e.key));
  };

  return (
    <LayoutFlexRow
      overflowX={"visible"}
      overflowY={"visible"}
      flexShrink={1}
    >
      <TextField
        fullWidth
        type={"number"}
        size={defnTheme.fieldSize}
        margin={defnTheme.fieldMargin}
        variant={fieldVariant}
        sx={{
          ...sectionVariant && {
            ...isFieldStandard && {
              pt: isReport ? 0 : filledInputPadding,
              pb: isReport ? 0 : filledInputPadding,
              pl: "14px"
            },
            ".MuiInputBase-input": {
              textOverflow: "ellipsis",
              MozAppearance: "textfield"
            },
            ".MuiFilledInput-input ": {
              pt: filledInputPadding,
              pb: filledInputPadding
            }
          },
          ...borderColor && {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: borderColor
              }
            }
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: icon ? "none" : undefined
          },
          "& input[type=number]": {
            MozAppearance: icon ? "textfield" : undefined
          }
        }}
        autoComplete={Boolean(defn.autoFill) ? "on" : "off"}
        autoFocus={Boolean(defn.autoFocus)}
        disabled={disabled}
        hiddenLabel={Boolean(defn.hideLabel)}
        label={defn.hideLabel ? undefined : label}
        name={fieldId}
        placeholder={placeholder ? placeholder : undefined}
        required={required}
        InputProps={{
          endAdornment: (
            icon ?
              <RawIconButton
                name={icon}
                icon={icon}
              />
              : undefined
          ),
          readOnly: readOnly,
          inputProps: {
            min: minVar,
            max: maxVar
          }
        }}
        value={value ?? ""}
        onBlur={onBlur}
        onChange={onChange}
        onPaste={(event) =>
        {
          if(allowDecimal)
          {
            return;
          }

          let pasteData = event.clipboardData.getData("text");
          if(pasteData.includes("."))
          {
            return;
          }
          if(/[^0-9]/.test(pasteData))
          {
            return pasteData;
          }
        }}
        onKeyDown={(event) =>
        {
          if(event.key === "Enter")
          {
            onClick?.();
          }
          if(event.key !== "Tab" && validateInput(event, allowDecimal ?? false))
          {
            event.preventDefault();
          }
        }}
        onClick={(e) =>
        {
          onClick && onClick();
        }}
        error={isError}
      />

      <FieldRawRefButton defn={defn} />

      {props.showLogInfo &&
        <FieldRawLogInfoButton
          defn={defn}
        />
      }

    </LayoutFlexRow>
  );
};


