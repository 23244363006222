import {MsgReportFieldDataGet} from "../../../api/ent/entAside/msg/MsgReportFieldDataGet";
import {RpcEntAside} from "../../../api/ent/entAside/RpcEntAside";
import {SigReportFieldData} from "../../../api/ent/entAside/sig/SigReportFieldData";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {RowId} from "../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {SelectList} from "../../../base/plus/ListPlus";
import SrvcRefBr from "./SrvcRefBr";

export default class SrvcReportBr extends SrvcRefBr
{
  constructor(readonly selectList: SelectList)
  {
    super(selectList);
  }

  rpcEntReportFieldDataGet(params: {
    entId: EntId,
    formId: MetaIdForm,
    refReportFieldId: MetaIdField,
    inputFormValue: FormValueRaw;
    inputFormCompositeId?: MetaIdComposite;
    inputFormGridRowId?: RowId;
    cbSuccess?: (envSig: EnvSignal<SigReportFieldData>) => void,
  })
  {
    const {
      entId,
      formId,
      refReportFieldId,
      inputFormValue,
      inputFormCompositeId,
      inputFormGridRowId,
      cbSuccess
    } = params;

    const msg = {
      formId: formId,
      reportFieldId: refReportFieldId,
      inputFormValue: inputFormValue,
      inputFormCompositeId: inputFormCompositeId,
      inputFormGridRowId: inputFormGridRowId
    } as MsgReportFieldDataGet;

    RpcEntAside.entReportFieldDataGet(entId, msg, (envSig) =>
    {
      cbSuccess && cbSuccess(envSig);
    });
  }

}
