import {EnumMessageType} from "../../api/home/base/Types";
import {SigUserAvatar} from "../../api/home/drawer/sig/SigUserAvatar";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {MediaIdAvatar} from "../../api/meta/base/Types";
import {EntUserId} from "../../api/meta/base/Types";
import {MessageId} from "../../api/meta/base/Types";
import {SpreadsheetPartitionId} from "../../api/meta/base/Types";
import {ChatId, EntId, RowId} from "../../api/meta/base/Types";
import {RootState} from "../../Store";
import {IBubbleMessage} from "./TypesBubble";

export const CHAT_START_ITEM_ID = 100000;

export type SelectChat = (state: RootState) => IChat;
export type SelectChatBadge = (state: RootState, entId: EntId, chatId: ChatId) => number;
export type TypeChatItemId = number;
export type TypeChatSkipInvokeBusy = "skip" | "invoke" | "busy";
export type TypeChatGap = "large" | "small";
export type TypeChatImageMode = "color" | "blur" | "original"

export interface IChatItem
{
  gap: TypeChatGap;
}

export interface IChatItemDate extends IChatItem
{
  date: string;
}

export interface IChatItemSig extends IChatItem
{
  sig: IBubbleMessage;
}

export interface IChat
{
  chatName: string;
  loadedVersion?: string;
  jumpVersion?: string;
  displayDateFormat?: string;

  // sig message list
  sigChatId?: ChatId;
  sigEntId?: EntId;
  sigEntUserId?: EntUserId;
  sigEntAvtarId?: MediaIdAvatar;

  sigTopOffset: number;
  sigBottomOffset: number;

  // model
  topOffset: number;
  bottomOffset: number;

  // pick
  pickItemIds?: Record<TypeChatItemId, boolean>;
  pickMode: boolean;
  pickCount: number;

  // hover
  hoverItemId?: TypeChatItemId;

  // select
  selectedItemId?: TypeChatItemId;

  // display data
  displayItemIds: TypeChatItemId[],  // would contain dates, texts, sigs
  displayItemMap: Record<TypeChatItemId, IChatItem>,
  displayOffsetMap: Record<number, TypeChatItemId>; // offset -> TypeChatItemId
  displayMsgIdMap: Record<MessageId, TypeChatItemId>; // TypeChatItemId -> messageId
  displayRowIdMap: Record<RowId, TypeChatItemId[]>; // rowId -> TypeChatItemId[]
  displayPartitionIdMap: Record<SpreadsheetPartitionId, TypeChatItemId[]>; // rowId -> TypeChatItemId[]

  displayFirstItemId?: TypeChatItemId; // to support first divider
  displayLastItemId?: TypeChatItemId; // to support last divider
  displayItemCount?: number; // to support empty view
  displayInitialTopMostItemId?: TypeChatItemId;
  displayLastItemIsSender: boolean;  // to support auto scroll to bottom
  displayBlinkItemId?: TypeChatItemId;
  displayJumpToLast?: boolean;
  displayScrollToLast?: boolean;
  displayScrollToItemId?: TypeChatItemId

  // UI support
  uiAtBottom?: boolean;  // to support FAB with badge in the corner of chat list
  uiScrolling: boolean;
  uiImageMode: TypeChatImageMode;

  // general
  appending: TypeChatSkipInvokeBusy;
  prepending: TypeChatSkipInvokeBusy;
  appendingNewMsg?: boolean;
}

export interface IChatBinder
{
  selectDefnForm: (state: RootState, itemId: TypeChatItemId) => DefnForm | undefined,
  bindDefnForm: (chatName: string, itemId: TypeChatItemId, sourceItem?: DefnForm) => void,

  selectIsStarMsg: (state: RootState, itemId: TypeChatItemId) => boolean,
  bindIsStarMsg: (chatName: string, itemId: TypeChatItemId, sourceItem?: boolean) => void,

  selectMsgMarkAsRead: (state: RootState, itemId: TypeChatItemId) => IChatItemSig | undefined
  bindMsgMarkAsRead: (chatName: string, itemId: TypeChatItemId, sourceItem?: IChatItemSig) => void,

  selectSenderAvatar: (state: RootState, itemId: TypeChatItemId) => SigUserAvatar | undefined
  bindSenderAvatar: (chatName: string, itemId: TypeChatItemId, sourceItem?: SigUserAvatar) => void,

  selectCenterInitiatorUserAvatar: (state: RootState, itemId: TypeChatItemId) => SigUserAvatar | undefined
  bindCenterInitiatorUserAvatar: (chatName: string, itemId: TypeChatItemId, sourceItem?: SigUserAvatar) => void,

  selectCenterTargetUserAvatar: (state: RootState, itemId: TypeChatItemId) => SigUserAvatar | undefined
  bindCenterTargetUserAvatar: (chatName: string, itemId: TypeChatItemId, sourceItem?: SigUserAvatar) => void,

  selectCanShowComments: (state: RootState, itemId: TypeChatItemId) => boolean
  bindCanShowComments: (chatName: string, itemId: TypeChatItemId, sourceItem?: boolean) => void,

  selectChatPattern: (state: RootState, itemId: TypeChatItemId) => IBubbleMessage | undefined
  bindChatPattern: (chatName: string, itemId: TypeChatItemId, sourceItem?: IBubbleMessage) => void,

  selectIsMsgForwardable: (state: RootState, itemId: TypeChatItemId) => boolean | undefined
  bindIsMsgForwardable: (chatName: string, itemId: TypeChatItemId, sourceItem?: boolean) => void,

  selectCanExpire: (state: RootState, itemId: TypeChatItemId) => boolean | undefined
  bindIsCanExpire: (chatName: string, itemId: TypeChatItemId, sourceItem?: boolean) => void,

  selectReply: (state: RootState, itemId: TypeChatItemId) => SigUserAvatar | undefined
  bindReply: (chatName: string, itemId: TypeChatItemId, sourceItem?: SigUserAvatar) => void,

  // no need to bind because we don't store userAvatar in redux
  selectUserAvatar: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined

}

export function isMsgTypeCenter(msgType: EnumMessageType)
{
  switch(msgType)
  {
    case "groupAboutChange":
    case "groupAvatarChange":
    case "groupCreate":
    case "groupExit":
    case "groupJoinWithInvite":
    case "groupMemberAdd":
    case "groupMemberRemove":
    case "groupNameChange":
      return true;
  }

  return false;
}

export function isMsgTypeCenterWithTargetMember(msgType: EnumMessageType): boolean
{
  switch(msgType)
  {
    case "groupMemberAdd":
    case "groupMemberRemove":
      return true;
  }
  return false;
}

export function isChatItemDate(item: IChatItem)
{
  return item && (item as IChatItemDate).date !== undefined;
}

export function isChatItemPickable(item: IChatItem)
{
  const sig = (item as IChatItemSig).sig;
  return sig && !isMsgTypeCenter(sig.payload.messageType);
}

export function getMessageOffset(item: IChatItem): number | undefined
{
  return !isChatItemDate(item)
    ? (item as IChatItemSig).sig.messageOffset
    : undefined;
}

export function getMessageId(item: IChatItem): string | undefined
{
  return isChatItemPickable(item)
    ? (item as IChatItemSig).sig.messageId
    : undefined;
}
