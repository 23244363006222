import {useTheme} from "@mui/material";
import React from "react";
import {DtoMessagePayloadDocument} from "../../../../api/home/base/dto/DtoMessagePayloadDocument";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {bytesToSize} from "../../../../base/plus/MathPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import LayoutFlexCol from "../../layout/LayoutFlexCol";
import BubbleLineFooter from "../line/BubbleLineFooter";
import BubbleRawDoc from "../raw/BubbleRawDoc";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleShell from "./BubbleShell";

export default function BubbleDocument(props: {
  payload: DtoMessagePayloadDocument,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  receiptStatus?: EnumReceiptStatus,
  searchWords?: string[],
  header?: IBubbleHeader,
  isStar?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  isBubbleBlinking?: boolean,
  isDownloading?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  onClickDownload?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const sizeAvatar = theme.common.sizeAvatar;
  const heightLine = theme.common.heightLine;
  const payload = props.payload;
  const fileName = payload.fileName || "";
  const searchWords = props.searchWords;
  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));
  const width = theme.common.calcBubbleFixedWidth(props.maxWidth);

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={props.maxWidth}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleBlinking={props.isBubbleBlinking}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      <LayoutFlexCol
        width={px(width)}
        height={"auto"}
      >
        {
          props.header !== undefined &&
          <BubbleRawHeader
            header={props.header}
            onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
          />
        }

        {
          props.replyPayload &&
          <BubbleRawReplyPreview
            replyPayload={props.replyPayload}
            replyInfo={props.replyInfo}
            onClickPreview={props.cbOnClickReplyPreview}
          />
        }

        <BubbleRawDoc
          isLoading={props.isDownloading}
          fileName={fileName}
          onClickDownload={props.onClickDownload}
          itemHeight={gapHalf + sizeAvatar + gapHalf}
          searchWords={searchWords}
        />

        <BubbleLineFooter
          isStar={props.isStar}
          heightLine={heightLine}
          textLeft={{
            value: `${payload?.fileExt} - ${bytesToSize(payload.fileSize ?? 0)}`
          }}
          textRight={{
            text: captionTime,
            iconRight: props.receiptStatus,
            onClick: props.cbOnClickFooterCaption
          }}
        />
      </LayoutFlexCol>
    </BubbleShell>
  );
}
