import {DtoAutomationVariableInfo} from "../../../../api/ent/base/dto/DtoAutomationVariableInfo";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../../Store";

export default class SrvcControlPanelAutomation extends ISrvc
{
  selectList(state: RootState)
  {
    return state.production.main.variableList;
  }

  loadVariableList(
    entId: EntId,
    listName: string,
    readOnly: boolean,
    variables?: DtoAutomationVariableInfo[])
  {
    if(!variables)
    {
      return;
    }

    const {
      uiItemIds,
      uiItemsById
    } = this.getVariablesUiItemById(variables, readOnly);

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));

  }

  getVariablesUiItemById(
    variables: DtoAutomationVariableInfo[],
    readOnly?: boolean
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    variables.forEach((variableInfo) =>
    {
      let metaId = variableInfo.form.metaId;
      if(metaId)
      {
        uiItemIds.push(metaId);

        uiItemsById[metaId] = {
          type: "p",
          primary: {
            text: variableInfo.name
          },
          hideMenu: readOnly
        } as IListItemAPSA;
      }

    });

    return {
      uiItemIds,
      uiItemsById
    };
  }
}
