import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {EnumDefnPaymentMethodKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPaymentMethodKind} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldEnumPaymentMethodKind(props: {
  defn: DefnFieldEditable
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;

  const fieldId = getFieldKey(defn);

  const mapOfOption = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  (EnumArrayDefnPaymentMethodKind as EnumDefnPaymentMethodKind[]).forEach(option =>
  {
    const value = option === "upi" ? "UPI" : toLabel(option);

    mapOfOption.keys.push(option);
    mapOfOption.map[option] = {
      metaId: option,
      value: value
    };
  });

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
        <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={defn}
          optionMap={mapOfOption}
          multiSelect={true}
          showChip={true}
        />
      }
    />
  );
}
