import {useEffect} from "react";
import {DefnFieldRefReport} from "../../../../api/meta/base/dto/DefnFieldRefReport";
import {useFormCtx} from "../base/CtxForm";

export default function FieldRefReport(props: {
  defn: DefnFieldRefReport
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;

  const copyFieldMap = defn.copyFieldMap;

  useEffect(() =>
  {
    if(copyFieldMap)
    {
      Object.keys(copyFieldMap).forEach(fieldId =>
      {
        formCtx.setRefChildParentId(fieldId, defn.metaId);
      });
    }

  }, [copyFieldMap]);

  return null;
};
