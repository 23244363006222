import React, {useRef, useState} from "react";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import {AudioBarDurationTimer} from "./AudioBarDurationTimer";
import AudioBarPlayButton from "./AudioBarPlayButton";
import {IAudioBarRef} from "./AudioBarPlayer";
import {AudioBarPlayer} from "./AudioBarPlayer";

type RawWaveAudioBarProps = {
  cursorColor: string;
  progressColor: string;
  waveColor: string;
  src?: string;
  scrollable?: boolean,
  durationMs?: number,
  showDuration?: boolean,
  caption?: React.ReactNode,
};

export default function RawAudioBar(props: RawWaveAudioBarProps)
{
  const [isPlaying, setIsPlaying] = useState(false);
  const [timer, setTimer] = useState<number>();
  const audioBarRef = useRef<IAudioBarRef>(null);

  return (
    <LayoutFlexRow
      alignItems={"start"}
      width="100%"
      onClick={(e: React.MouseEvent<HTMLElement>) =>
      {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <AudioBarPlayButton
        isPlaying={isPlaying}
        cbOnPlay={audioBarRef.current?.play}
        cbOnPause={audioBarRef.current?.pause}
      />

      <LayoutFlexCol
        width={"85%"}
        alignItems={"start"}
      >
        <AudioBarPlayer
          {...props}
          ref={audioBarRef}
          onStartPlaying={(currentDurationMs) =>
          {
            setTimer(currentDurationMs * 1000);
            setIsPlaying(true);
          }}
          onStopPlaying={() => setIsPlaying(false)}
          onChangeDuration={value => value !== undefined
            ? setTimer(value * 1000)
            : setTimer(undefined)}
        />
        <AudioBarDurationTimer
          durationMs={timer !== undefined ? timer : props.durationMs}
          showDuration={props.showDuration}
          caption={props.caption}
        />
      </LayoutFlexCol>
    </LayoutFlexRow>
  );
};



