import {ArrowDownwardRounded} from "@mui/icons-material";
import {ArrowUpwardRounded} from "@mui/icons-material";
import {MoreVertRounded} from "@mui/icons-material";
import {FilterListRounded} from "@mui/icons-material";
import {useTheme} from "@mui/material";
import {SxProps} from "@mui/system";
import {IHeaderGroupParams} from "ag-grid-community";
import {IHeaderParams} from "ag-grid-community";
import type {SortDirection} from "ag-grid-community/dist/types/core/entities/colDef";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {DefnDtoTableStyle} from "../../../../api/meta/base/dto/DefnDtoTableStyle";
import {DefnMapOfTableStyle} from "../../../../api/meta/base/dto/DefnMapOfTableStyle";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {resolveTableStyle} from "../DataGridPlus";

export function GridCellHeader(props: IHeaderParams)
{
  const theme = useTheme();
  const headerName = props.displayName;
  const showFilter = props.enableFilterButton;
  const showMenu = props.enableMenu;
  const enableSorting = props.enableSorting;
  const column = props.column;
  const styleMap = (props.context as AgGridContext).layout?.styleMap;
  const headerStyle = getStyleForHeader(column.getColId(), styleMap);

  const [isFiltered, setIsFiltered] = useState(column.isFilterActive());
  const [sortDirection, setSortDirection] = useState<SortDirection>();

  useEffect(() =>
  {
    const onFilterChanged = () => setIsFiltered(column.isFilterActive());
    const onSortingChanged = () => setSortDirection(column.getSort());

    column.addEventListener("filterChanged", onFilterChanged);
    column.addEventListener("sortChanged", onSortingChanged);

    return () =>
    {
      column.removeEventListener("filterChanged", onFilterChanged);
      column.addEventListener("sortChanged", onSortingChanged);
    };
  }, [column]);

  return (
    <LayoutFlexRow
      flexGrow={1}
      height={"100%"}
      width={"100%"}
      bgcolor={headerStyle?.bgcolor}
      pl={px(theme.common.gapStd)}
      pr={px(theme.common.gapStd)}
      cursorPointer={enableSorting}
      onClick={() => enableSorting && props.progressSort(true)}
    >
      <RawHighlighter
        width={"100%"}
        value={headerName}
        flexGrow={1}
        textAlign={"center"}

        //style
        variant={headerStyle?.variant || "caption"}
        bold={headerStyle?.isBold}
        color={headerStyle?.color}
        italic={headerStyle?.isItalic}
        textDecoration={headerStyle?.textDecoration}
      />

      {
        enableSorting && (
          sortDirection === "asc"
            ? <ArrowUpwardRounded sx={iconStyle} />
            : sortDirection === "desc"
              ? <ArrowDownwardRounded sx={iconStyle} />
              : null)
      }

      {
        showFilter &&
        <FilterListRounded
          sx={iconStyle}
          color={isFiltered ? "primary" : undefined}
          onClick={(event) =>
          {
            event.stopPropagation();
            props.showFilter(event.target as HTMLElement);
          }}
        />
      }

      {
        showMenu &&
        <MoreVertRounded
          sx={{...iconStyle, mr: 0}}
          onClick={(event) =>
          {
            event.stopPropagation();
            props.showColumnMenu(event.target as HTMLElement);
          }}
        />
      }
    </LayoutFlexRow>
  );
}

export function GridCellHeaderGroup(props: IHeaderGroupParams)
{
  const theme = useTheme();
  const headerName = props.displayName;

  const styleMap = (props.context as AgGridContext).layout?.styleMap;
  const headerStyle = getStyleForHeader(props.columnGroup.getGroupId(), styleMap);

  return (
    <LayoutFlexRow
      flexGrow={1}
      height={"100%"}
      width={"100%"}
      bgcolor={headerStyle?.bgcolor}
      pl={px(theme.common.gapStd)}
      pr={px(theme.common.gapStd)}
    >
      <RawHighlighter
        width={"100%"}
        value={headerName}
        flexGrow={1}
        textAlign={"center"}

        //style
        variant={headerStyle?.variant || "caption"}
        bold={headerStyle?.isBold}
        color={headerStyle?.color}
        italic={headerStyle?.isItalic}
        textDecoration={headerStyle?.textDecoration}
      />
    </LayoutFlexRow>
  );
}

const iconSize = 18;

const iconStyle = {
  fontSize: px(iconSize),
  marginRight: px(theme.common.gapHalf),
  cursor: "pointer",
  borderRadius: iconSize
} as SxProps;

function getStyleForHeader(colId: MetaIdField, styleMap?: DefnMapOfTableStyle)
{
  let fieldIdStyle: DefnDtoTableStyle | undefined;
  let defaultStyle: DefnDtoTableStyle | undefined;

  styleMap?.keys?.forEach(styleId =>
  {
    const style = styleMap.map[styleId];
    const fieldIdSet = style.fieldIdSet;
    if(style.fieldLayoutOn === "header")
    {
      if(fieldIdSet)
      {
        if(fieldIdSet.some(fieldId => colId.includes(fieldId)))
        {
          fieldIdStyle = style;
        }
      }
      else
      {
        defaultStyle = style;
      }
    }
  });

  if(fieldIdStyle)
  {
    return resolveTableStyle(fieldIdStyle);
  }
  else if(defaultStyle)
  {
    return resolveTableStyle(defaultStyle);
  }
}

