import {FieldValues} from "react-hook-form/dist/types/fields";
import {StudioFieldQuarter} from "../../../../../api/meta/base/dto/StudioFieldQuarter";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";

export function getDefnFieldPickQuarter(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "quarter",
      formId,
      "quarter",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    )
  };
}

export function defnValueToStudioFieldPickQuarter(values: FieldValues): StudioFieldQuarter
{
  return {
    type: "quarter",
    defaultValue: fnFieldValueToRawValue("quarter", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId])
  } as StudioFieldQuarter;
}

export function studioFieldPickPickQuarterToDefnValue(studioField: StudioFieldQuarter): FieldValues
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("quarter", studioField.defaultValue),
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId)
  };
}
