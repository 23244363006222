import {useCallback} from "react";
import React from "react";
import {FieldErrors} from "react-hook-form";
import {Controller} from "react-hook-form";
import {nextMetaIdVisibilityCondition} from "../../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnStudioMapOfVisibilityCondition} from "../../../../api/meta/base/dto/DefnStudioMapOfVisibilityCondition";
import {StudioDtoVisibilityCondition} from "../../../../api/meta/base/dto/StudioDtoVisibilityCondition";
import {StudioMapOfVisibilityCondition} from "../../../../api/meta/base/dto/StudioMapOfVisibilityCondition";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnResolveVisibilityConditionStatement} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewFormVisibilityCondition from "../../../dialog/DialogNewFormVisibilityCondition";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import {IResolvedStatement} from "../raw/FieldRawTreeCondition";
import FieldRawTreeCondition from "../raw/FieldRawTreeCondition";

export default function FieldStudioMapOfVisibilityCondition(props: {
  defn: DefnStudioMapOfVisibilityCondition,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defnFieldBuilderSetOfVisibilityCondition = props.defn;
  const fieldId = getFieldKey(defnFieldBuilderSetOfVisibilityCondition);
  const metaIdForm = defnFieldBuilderSetOfVisibilityCondition.formId;
  const formStore = formCtx.getStore();
  const readonly = formCtx.isReadonly();

  const isLastField = false;
  const label = defnFieldBuilderSetOfVisibilityCondition.label;

  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";

  return (
    <Controller
      name={defnFieldBuilderSetOfVisibilityCondition.metaId}
      control={formCtx.control()}
      render={({
        field,
        formState: {errors}
      }) =>
      {
        const fieldValue = field.value as StudioMapOfVisibilityCondition;
        const onChange = field.onChange;

        if(isReport)
        {
          return <></>;
        }
        else if(sectionVariant === "propertyEditor")
        {
          const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
          const defnLabel = {
            label: label
          } as DefnFieldLabel;

          return (
            <FieldRawKeyValuePair
              leftHeight={labelHeight}
              left={
                <FieldLabel defn={defnLabel} />
              }
              right={
                <RawSetOfVisibilityCondition
                  fieldId={fieldId}
                  fieldValue={fieldValue}
                  onChange={onChange}
                  isLastField={isLastField}
                  isFormReadOnly={readonly}
                  metaIdForm={metaIdForm}
                  formStore={formStore}
                  defn={defnFieldBuilderSetOfVisibilityCondition}
                  errors={errors}
                />
              }
            />
          );
        }

        return (
          <RawSetOfVisibilityCondition
            fieldId={fieldId}
            fieldValue={fieldValue}
            onChange={onChange}
            isLastField={isLastField}
            isFormReadOnly={readonly}
            metaIdForm={metaIdForm}
            formStore={formStore}
            defn={defnFieldBuilderSetOfVisibilityCondition}
            errors={errors}
          />
        );
      }}
    />
  );
}

function RawSetOfVisibilityCondition(props: {
  defn: DefnStudioMapOfVisibilityCondition,
  fieldId: MetaIdField,
  metaIdForm?: MetaIdForm,
  formStore?: FormStore,
  isLastField?: boolean,
  isFormReadOnly?: boolean,
  fieldValue?: StudioMapOfVisibilityCondition,
  errors?: FieldErrors,
  onChange: (value: StudioMapOfVisibilityCondition | null) => void
})
{
  const {
    formStore,
    defn,
    metaIdForm
  } = props;

  const readOnly = props.isFormReadOnly;

  const pageCtx = usePageCtx();
  const metaId = defn.metaId;

  const errors = props.errors?.[metaId] ? props.errors : undefined;
  const validationResult = formStore?.validationResult;
  const fnResolver = formStore ? fnUseStudioResolver(formStore) : undefined;

  const cbResolveStatement = useCallback((statement: StudioDtoVisibilityCondition) =>
  {
    return {
      primary: fnResolver
        ? fnResolveVisibilityConditionStatement(statement, fnResolver, metaIdForm)
        : ""
    } as IResolvedStatement;

  }, [fnResolver, metaIdForm]);

  const cbCreateStatementNode = (
    item?: StudioMapOfVisibilityCondition,
    cb?: (newItem: StudioDtoVisibilityCondition) => void) =>
  {
    if(formStore)
    {
      pageCtx.showDialog(<DialogNewFormVisibilityCondition
        formStore={formStore}
        sourceFormId={metaIdForm}
        isFormReadOnly={readOnly}
        onClickOk={(values) =>
        {
          cb && cb(values);
        }}
        nodeId={item?.metaId}
        metaId={metaId}
        values={item?.statement as StudioDtoVisibilityCondition}
        validationResult={validationResult}
      />);
    }
  };

  return <FieldRawTreeCondition
    fieldValue={props.fieldValue}
    label={defn.label}
    isFormReadOnly={readOnly}
    errors={errors}
    cbCreateStatementNode={cbCreateStatementNode}
    cbResolveStatement={cbResolveStatement}
    onChange={props.onChange}
    isLastField={props.isLastField}
    cbGetMetaId={() => nextMetaIdVisibilityCondition()}
  />;
}
