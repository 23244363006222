import {useCallback} from "react";
import {useState} from "react";
import {useRef} from "react";
import {useMemo} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdFormula} from "../../api/meta/base/ApiPlus";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioCodeEditor} from "../../api/meta/base/dto/DefnStudioCodeEditor";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioDtoFormula} from "../../api/meta/base/dto/StudioDtoFormula";
import {StudioValueCode} from "../../api/meta/base/dto/StudioValueCode";
import {AssignedFormula} from "../../api/meta/base/StudioSetsFieldType";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {getFieldName} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {getExcludeConditionVarIds} from "../../base/plus/StudioPlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export default function DialogNewFormFormula(props: {
  formStore: FormStore,
  metaIdForm?: MetaIdForm,
  isFormReadOnly?: boolean,
  values?: StudioDtoFormula,
  onClickOk: (values: StudioDtoFormula) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const dto = props.values;
  const formStore = props.formStore;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  const [fieldName, setFieldName] = useState<string | undefined>();

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const excludeConditionVars = useMemo(() => formStore.varMap
    ? getExcludeConditionVarIds(formStore.varMap, props.metaIdForm, undefined, true)
    : [], [formStore.varMap, props.metaIdForm]);

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(key === fieldAssignField && props.metaIdForm && formStore)
    {
      setFieldName(getFieldName(value, props.metaIdForm, formStore) || undefined);
    }
  }, []);

  return (
    <DialogDefnForm
      formProps={{
        defnForm: getDefnForm(props.metaIdForm, excludeConditionVars),
        onSubmit: values => props.onClickOk(valueToDto(values, dto, fieldName)),
        store: formStore,
        initValues: dtoToValue(dto),
        cbRef: cbRef.current,
        formReadonly: props.isFormReadOnly,
        onWatch: onWatch
      }}
      title={`${dto ? "Update" : "New"} form formula`}
      addMoreCheckBoxLabel={!dto
        ? "Add more formulas"
        : undefined}
      onClose={props.onClose}
      contentWidth={1200}
      contentHeight={650}
      preventEnterKey={true}
    />
  );
}

const fieldConditionVariable = "fieldConditionVariable";
const fieldAssignField = "fieldAssignField";
const fieldFormula = "formula";

function getDefnForm(metaIdForm?: MetaIdForm, excludeConditionVars?: MetaIdVar[])
{
  return createDefaultDefnFormStudio({
    [fieldAssignField]: {
      type: "pickFieldId",
      metaId: fieldAssignField,
      name: "Assign formula to field",
      required: true,
      formId: metaIdForm,
      filterFieldTypeSet: AssignedFormula,
      showCompositeName: true
    } as DefnStudioPickFieldId,
    [fieldConditionVariable]: {
      type: "pickVarId",
      metaId: fieldConditionVariable,
      varKind: "condition",
      name: "Condition variable",
      excludeVarIdSet: excludeConditionVars
    } as DefnStudioPickVarId,
    [fieldFormula]: {
      type: "studioCodeEditor",
      metaId: fieldFormula,
      name: fieldFormula,
      label: "Formula",
      language: "javascript",
      showExpandBtn: false,
      required: true,
      lineCount: 4
    } as DefnStudioCodeEditor,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldAssignField, fieldConditionVariable, fieldFormula]
    } as DefnSection
  });
}

function dtoToValue(values?: StudioDtoFormula): FieldValues | undefined
{
  if(!values)
  {
    return undefined;
  }
  return {
    [fieldAssignField]: fnRawValueToFieldValue("pickFieldId", values.assignToFieldId),
    [fieldFormula]: fnRawValueToFieldValue("studioCodeEditor", values.formula),
    [fieldConditionVariable]: fnRawValueToFieldValue("pickVarId", values.conditionVarId)
  };
}

function valueToDto(values: FieldValues, dtoFormula?: StudioDtoFormula, fieldName?: string): StudioDtoFormula
{
  return {
    ...dtoFormula ?? {} as StudioDtoFormula,
    name: fieldName,
    metaId: dtoFormula?.metaId || nextMetaIdFormula(),
    assignToFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldAssignField]) as string,
    formula: fnFieldValueToRawValue("studioCodeEditor", values[fieldFormula]) as StudioValueCode,
    conditionVarId: fnFieldValueToRawValue("pickVarId", values[fieldConditionVariable]) as string
  };
}
