import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickText} from "../../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldTime} from "../../../../../api/meta/base/dto/DefnFieldTime";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioBuildTime} from "../../../../../api/meta/base/dto/StudioBuildTime";
import {StudioFieldTime} from "../../../../../api/meta/base/dto/StudioFieldTime";
import {EnumArrayDefnTime} from "../../../../../api/meta/base/Types";
import {EnumDefnTime} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {arrayToMapOfOption} from "../../../../../base/plus/JsPlus";
import {hasValues} from "../../../../../base/plus/JsPlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {fieldValueMaxTimeEnum} from "../../base/TypesFormBuilder";
import {fieldValueMinTimeEnum} from "../../base/TypesFormBuilder";
import {fieldValueDefaultTimeEnum} from "../../base/TypesFormBuilder";
import {propKeyMax} from "../../base/TypesFormBuilder";
import {propKeyMin} from "../../base/TypesFormBuilder";
import {propKeyShowSecondVar} from "../../base/TypesFormBuilder";
import {propKeyShowSecondFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyMaxFieldId} from "../../base/TypesFormBuilder";
import {propKeyMinFieldId} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyMinVar} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {propKeyMaxVar} from "../../base/TypesFormBuilder";
import {propKeyShowSecond} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";

const timeOptions = [
  "custom",
  ...EnumArrayDefnTime as EnumDefnTime[]
];

export function getDefnFieldTime(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[],
  defaultValueTime?: string,
  minValueTime?: string,
  maxValueTime?: string
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap3,
    propKeyMinFieldId,
    propKeyMaxFieldId,
    propKeyShowSecondFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar,
    fieldGap3,
    propKeyMinVar,
    propKeyMaxVar,
    propKeyShowSecondVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    fieldValueDefaultTimeEnum,
    ...defaultValueTime === "custom" ? [propKeyDefaultValue] : [],
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    fieldValueMinTimeEnum,
    ...minValueTime === "custom" ? [propKeyMin] : [],
    fieldValueMaxTimeEnum,
    ...maxValueTime === "custom" ? [propKeyMax] : [],
    fieldGap4,
    propKeyShowSecond
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "time",
      formId,
      "time",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [fieldValueDefaultTimeEnum]: {
      type: "pickText",
      metaId: fieldValueDefaultTimeEnum,
      name: fieldValueDefaultTimeEnum,
      label: "Default value",
      optionMap: arrayToMapOfOption(timeOptions)
    } as DefnFieldPickText,
    ...defaultValueTime === "custom" && {
      [propKeyDefaultValue]: {
        type: "time",
        metaId: propKeyDefaultValue,
        name: propKeyDefaultValue,
        label: "Default time",
        required: true
      } as DefnFieldTime
    },

    [fieldValueMinTimeEnum]: {
      type: "pickText",
      metaId: fieldValueMinTimeEnum,
      name: fieldValueMinTimeEnum,
      label: "Min value",
      optionMap: arrayToMapOfOption(timeOptions)
    } as DefnFieldPickText,
    ...minValueTime === "custom" && {
      [propKeyMin]: {
        type: "time",
        name: propKeyMin,
        metaId: propKeyMin,
        label: "Min time"
      } as DefnFieldTime
    },

    [fieldValueMaxTimeEnum]: {
      type: "pickText",
      metaId: fieldValueMaxTimeEnum,
      name: fieldValueMaxTimeEnum,
      label: "Max value",
      optionMap: arrayToMapOfOption(timeOptions)
    } as DefnFieldPickText,
    ...maxValueTime === "custom" && {
      [propKeyMax]: {
        type: "time",
        name: propKeyMax,
        metaId: propKeyMax,
        label: "Max time"
      } as DefnFieldTime
    },

    [propKeyShowSecond]: {
      type: "bool",
      name: propKeyShowSecond,
      metaId: propKeyShowSecond,
      label: "Show seconds",
      showAsCheckbox: true
    } as DefnFieldSwitch,

    [propKeyShowSecondVar]: {
      type: "pickVarId",
      name: propKeyShowSecondVar,
      metaId: propKeyShowSecondVar,
      label: "Show seconds",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyShowSecondFieldId]: {
      type: "pickFieldId",
      name: propKeyShowSecondFieldId,
      metaId: propKeyShowSecondFieldId,
      label: "Show seconds",
      formId: formId,
      filterFieldTypeSet: ["bool"],
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true
    } as DefnStudioPickFieldId
  };
}

export function defnValueToStudioFieldTime(values: FieldValues): StudioFieldTime
{

  const defaultStudioBuildTimeDto = getTimeDTO(
    values,
    fieldValueDefaultTimeEnum,
    propKeyDefaultValue
  );

  const minStudioBuildTimeDto = getTimeDTO(
    values,
    fieldValueMinTimeEnum,
    propKeyMin
  );

  const maxStudioBuildTimeDto = getTimeDTO(
    values,
    fieldValueMaxTimeEnum,
    propKeyMax
  );

  return {
    type: "time",
    defaultValue: hasValues(defaultStudioBuildTimeDto) ? defaultStudioBuildTimeDto : undefined,
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    min: hasValues(minStudioBuildTimeDto) ? minStudioBuildTimeDto : undefined,
    minVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMinVar]),
    minFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMinFieldId]),
    max: hasValues(maxStudioBuildTimeDto) ? maxStudioBuildTimeDto : undefined,
    maxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxVar]),
    maxFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxFieldId]),
    showSecond: fnFieldValueToRawValue("bool", values[propKeyShowSecond]),
    showSecondVarId: fnFieldValueToRawValue("pickVarId", values[propKeyShowSecondVar]),
    showSecondFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyShowSecondFieldId])
  } as StudioFieldTime;
}

export function studioFieldTimeToDefnValue(studioField: StudioFieldTime): FieldValues
{

  const dto = {
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyMin]: fnRawValueToFieldValue("time", studioField.min),
    [propKeyMinVar]: fnRawValueToFieldValue("pickVarId", studioField.minVarId),
    [propKeyMinFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.minFieldId),
    [propKeyMax]: fnRawValueToFieldValue("time", studioField.max),
    [propKeyMaxVar]: fnRawValueToFieldValue("pickVarId", studioField.maxVarId),
    [propKeyMaxFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxFieldId),
    [propKeyShowSecond]: fnRawValueToFieldValue("bool", studioField.showSecond),
    [propKeyShowSecondVar]: fnRawValueToFieldValue("pickVarId", studioField.showSecondVarId),
    [propKeyShowSecondFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.showSecondFieldId)
  };

  const defaultValueDto = handleTimeValue(
    propKeyDefaultValue,
    fieldValueDefaultTimeEnum,
    studioField.defaultValue
  );

  const minValueDto = handleTimeValue(
    propKeyMin,
    fieldValueMinTimeEnum,
    studioField.min
  );

  const maxValueDto = handleTimeValue(
    propKeyMax,
    fieldValueMaxTimeEnum,
    studioField.max
  );

  return {
    ...dto,
    ...defaultValueDto,
    ...minValueDto,
    ...maxValueDto
  };
}

function getTimeDTO(values: FieldValues, timeKey: string, customKey: string)
{
  const timeValue = fnFieldValueToRawValue("pickText", values[timeKey]);
  const customValue = values[customKey];

  const studioBuildTimeDto = {} as StudioBuildTime;

  if(timeValue && timeValue !== "custom")
  {
    studioBuildTimeDto.value = timeValue as EnumDefnTime;
  }
  else if(timeValue === "custom" && customValue)
  {
    studioBuildTimeDto.customValue = customValue;
  }
  return studioBuildTimeDto;
}

function handleTimeValue(
  propKeyValue: string,
  fieldValueTimeEnum: string,
  value?: StudioBuildTime
)
{
  if(value)
  {
    const timeEnumValue = value.value;
    if(timeEnumValue)
    {
      return {
        [fieldValueTimeEnum]: fnRawValueToFieldValue("pickText", timeEnumValue),
        [propKeyValue]: null
      };
    }
    else if(value.customValue)
    {
      return {
        [propKeyValue]: value.customValue,
        [fieldValueTimeEnum]: fnRawValueToFieldValue("pickText", "custom" as EnumDefnTime)
      };
    }
  }
  return {};
}
