import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import RawSkeleton from "../raw/RawSkeleton";

export default function AvatarSkeleton(props: {
  ml?: number,
  mr?: number,
})
{
  const theme = useTheme();
  const avatarSize = px(theme.common.sizeAvatar) as string;

  return (
    <RawSkeleton
      variant="circular"
      width={avatarSize}
      height={avatarSize}
      ml={props.ml}
      mr={props.mr}
    />
  );
}
