import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {StudioVarImageCorner} from "../../../api/meta/base/dto/StudioVarImageCorner";

export function fnVariableBuilderImageCorner(fieldValueImageCorner: string)
{
  function getVarComp()
  {
    return {
      [fieldValueImageCorner]: {
        type: "enumThemeImageCorner",
        metaId: fieldValueImageCorner,
        name: "Value",
        required: true
      } as DefnFieldPickEnum
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarImageCorner
  )
  {
    valueMap[fieldValueImageCorner] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueImageCorner];

    return {
      value: value
    } as StudioVarImageCorner;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
