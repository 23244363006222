import {FormGroup} from "@mui/material";
import {Box} from "@mui/material";
import {Checkbox} from "@mui/material";
import {FormControlLabel} from "@mui/material";
import {FormControl} from "@mui/material";
import {useState} from "react";
import {ChangeEvent} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldSetOfText} from "../../../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldSetOfOptionId} from "../../../../api/meta/base/dto/FieldSetOfOptionId";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {EnumDefnThemeSectionVariant} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {mapToOptions} from "../../../../base/plus/JsPlus";
import {px} from "../../../../base/plus/StringPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "./FieldRawKeyValuePair";

type TypeOnChange = (value: FieldSetOfOptionId | null) => void;

const defaultCheckboxRowPerLine = 6;
const checkboxFieldHeight = 42;

export default function FieldRawCheckboxGroup(props: {
  defn: DefnFieldSetOfText,
  fieldId: MetaIdField,
  showChip?: boolean,
  optionMap?: DefnStudioMapOfDtoOption
  fieldVariant?: EnumDefnThemeFieldVariant;
  sectionVariant?: EnumDefnThemeSectionVariant;
  fieldSize?: EnumDefnThemeFieldSize;
  error?: FieldError,
  value?: MetaIdOption[],
  required?: boolean,
  label?: string,
  placeHolder?: string,
  hideLabel?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  helperText?: string,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: TypeOnChange;
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const onClick = formCtx.getOnClick();

  const defn = props.defn;
  const label = props.label;
  const optionArray = mapToOptions(props.optionMap) as DefnDtoOption[];
  const sectionVariant = props.sectionVariant;
  const value: FieldSetOfOptionId = props.optionMap && props.value
    ? pickManyToFieldValue(props.optionMap, props.value)
    : {
      displaySet: [],
      valueSet: []
    };
  const readOnly = props.readOnly;
  const disabled = props.disabled;

  const [selectCheckBox, setSelectCheckBox] = useState<FieldSetOfOptionId | undefined>(value);

  const onChange = (event: React.SyntheticEvent) =>
  {
    const _event = event as ChangeEvent<HTMLInputElement>;
    const value = _event.target.value;

    const isChecked = _event.target.checked;
    const fieldVal: FieldSetOfOptionId = {
      displaySet: [],
      valueSet: []
    };

    if(selectCheckBox?.valueSet && selectCheckBox.valueSet.length > 0)
    {
      fieldVal.valueSet.push(...selectCheckBox.valueSet);
    }

    if(isChecked)
    {
      fieldVal.valueSet.push(value);
    }
    else
    {
      const index = fieldVal.valueSet.indexOf(value);
      if(index > -1)
      {
        fieldVal.valueSet.splice(index, 1);
      }
    }

    setSelectCheckBox(fieldVal);
    props.onChange && props.onChange(fieldVal);
  };

  if(sectionVariant === "propertyEditor")
  {
    const defnLabel = {
      label: label,
      boldVar: true
    } as DefnFieldLabel;

    const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
    return (
      <FieldRawKeyValuePair
        leftHeight={labelHeight}
        left={
          <FieldLabel defn={defnLabel} />
        }
        right={
          <FormGroup
            onChange={(event) =>
            {
              if(!readOnly && !disabled)
              {
                onChange(event);
              }
            }}
            onClick={() =>
            {
              onClick && onClick(defn.metaId, "field", value);
            }}
          >
            <CheckboxGroupOptions
              defn={defn}
              value={value}
              options={optionArray}
              disabled={disabled || readOnly}
            />
          </FormGroup>
        }
      />
    );
  }

  return (
    <FormControl
      fullWidth={true}
    >
      <FormGroup
        onChange={(event) =>
        {
          if(!readOnly && !disabled)
          {
            onChange(event);
          }
        }}
        onClick={() =>
        {
          onClick && onClick(defn.metaId, "field", value);
        }}
      >
        <CheckboxGroupOptions
          defn={defn}
          value={value}
          options={optionArray}
          disabled={disabled || readOnly}
        />
      </FormGroup>
    </FormControl>
  );

};

function CheckboxGroupOptions(props: {
  defn: DefnFieldSetOfText,
  value?: FieldSetOfOptionId,
  options?: DefnDtoOption[],
  disabled?: boolean
})
{
  const defn = props.defn;
  const disabled = props.disabled;

  const direction = defn.showAs === "checkboxHorizontal" ? "horizontal" : "vertical";

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: direction === "vertical"
          ? px(checkboxFieldHeight * (defn.pageSize || defaultCheckboxRowPerLine))
          : "auto",
        display: "flex",
        flexDirection: direction === "horizontal"
          ? "row"
          : "column",
        flexWrap: "wrap",
        paddingLeft: "0px"
      }}
    >
      {
        props.options?.map((option) =>
        {
          if(option.metaId)
          {
            const isChecked = Boolean(props.value?.valueSet.includes(option.metaId));
            return <CheckboxField
              key={option.metaId}
              value={option.metaId}
              label={option.value ?? ""}
              isChecked={isChecked}
              disabled={disabled}
            />;
          }
          return null;
        })
      }
    </Box>
  );
}

function CheckboxField(props: {
  value: string,
  label: string,
  pt?: number,
  pb?: number,
  pr?: number,
  pl?: number,
  bgColor?: string,
  isChecked?: boolean,
  disabled?: boolean,
})
{
  const value = props.value;
  const label = props.label;
  const pt = props.pt;
  const pb = props.pb;
  const pr = props.pr;
  const pl = props.pl;
  const isChecked = props.isChecked;
  const disabled = props.disabled;

  return (
    <FormControlLabel
      key={value}
      value={value}
      id={value}
      sx={{
        margin: 0,
        pt: px(pt),
        pb: px(pb),
        pr: px(pr),
        pl: px(pl)
      }}
      control={<Checkbox
        defaultChecked={isChecked}
        disabled={disabled}
      />}
      label={label}
    />
  );
}

function pickManyToFieldValue(optionMap: DefnStudioMapOfDtoOption, value: MetaIdOption[]): FieldSetOfOptionId
{
  const displaySet = [] as string[];
  value?.forEach(optionId =>
  {
    displaySet.push(optionMap.map[optionId].value);
  });

  return {
    valueSet: value,
    displaySet
  };
}

