import React from "react";
import {Controller} from "react-hook-form";
import {DefnStudioPickCompId} from "../../../../api/meta/base/dto/DefnStudioPickCompId";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPickCompId from "../raw/FieldRawStudioPickCompId";

export default function FieldStudioSetOfCompId(props: {
  defn: DefnStudioPickCompId
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPickCompId
        fieldProps={fieldProps}
        defn={props.defn}
        multiSelect={true}
        showChip={true}
      />}
    />
  );
}
