import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFormId} from "../../../../api/meta/base/dto/DefnStudioPickFormId";
import {StudioFormMap} from "../../../../api/meta/base/dto/StudioFormMap";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {mapToOptions} from "../../../../base/plus/JsPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {isSystemForm} from "../../../../routes/studio/ent/deploy/plugins/StudioEntDeployPluginPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdForm | MetaIdForm[] | undefined

export default function FieldRawStudioPickFormId(props: {
  defn: DefnStudioPickFormId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const defn = props.defn;
  const fieldProps = props.fieldProps;

  const excludeFormIdSet = defn.excludeFormIdSet;
  const alias = defn.alias;
  const allowSystemForms = defn.allowSystemForms;
  const includeOptionMap = defn.includeOptionMap;

  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const formMap = formStore?.formMap
    ? formStore.formMap
    : undefined;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const dtoOptions = useMemo(() => getOption(
      formMap,
      excludeFormIdSet,
      alias,
      allowSystemForms,
      includeOptionMap
    ),
    [
      formMap,
      excludeFormIdSet,
      alias,
      allowSystemForms,
      includeOptionMap
    ]
  );

  const [options] = useInsertTrashOptions({
    type: "formMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOption(
  formMap?: StudioFormMap,
  excludeFormIdSet?: MetaIdForm[],
  alias?: string,
  allowSystemForms?: boolean,
  extraOptionSet?: DefnStudioMapOfDtoOption): DefnDtoOption[]
{
  if(!formMap)
  {
    return [];
  }

  const options = [] as DefnDtoOption[];

  formMap.keys.forEach((formId) =>
  {
    if(excludeFormIdSet?.includes(formId) || (!allowSystemForms && isSystemForm(formId)))
    {
      return;
    }

    const form = formMap.map[formId];
    const value = form.details.name || form.metaId;

    options.push({
      metaId: form.metaId,
      value: (alias
        ? `${value} -> ${alias}`
        : value)
    } as DefnDtoOption);
  });

  if(extraOptionSet && extraOptionSet.keys.length > 0)
  {
    const extraOptions = mapToOptions(extraOptionSet);
    extraOptions && options.push(...extraOptions);
  }

  return options;
}
