import {combineReducers} from "@reduxjs/toolkit";
import {reducersCacheApiBr} from "./apiBr/StoreCacheApiBr";
import {reducersCacheApp} from "./app/StoreCacheApp";
import {reducersCacheProduction} from "./controlPanel/StoreCacheControlPanel";
import {reducersCacheHome} from "./home/StoreCacheHome";
import {reducersCacheStore} from "./store/StoreCacheStore";
import {reducersCacheStudio} from "./studio/StoreCacheStudio";
import {sliceTerminal} from "./terminal/SliceCacheTerminal";

export function reducersCache()
{
  return combineReducers({
    app: reducersCacheApp(),
    apiBr: reducersCacheApiBr(),
    home: reducersCacheHome(),
    studio: reducersCacheStudio(),
    store: reducersCacheStore(),
    production: reducersCacheProduction(),
    terminal: sliceTerminal.reducer
  });
}
