import {Box} from "@mui/system";
import {Property} from "csstype";
import parse from "html-react-parser";
import React from "react";
import {CHAT_ITEM_IMAGE_BORDER_RADIUS} from "../../../../../base/plus/ConstantsPlus";
import {px} from "../../../../../base/plus/StringPlus";
import theme from "../../../../../base/plus/ThemePlus";

export default function BubbleRawMediaHtml(props: {
  value?: string;
  width?: Property.Width,
  height?: Property.Height,
})
{
  const {
    height,
    width,
    value
  } = props;

  const htmlValue = value ? parse(value) : "";
  return (
    <Box
      overflow={"hidden"}
      height={height}
      width={width}
      bgcolor={theme.palette.grey[200]}
      borderRadius={px(CHAT_ITEM_IMAGE_BORDER_RADIUS)}
    >
      {htmlValue}
    </Box>
  );
}
