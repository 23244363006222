import {SigSpreadsheetRowExpiry} from "../../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {META_ID_OPTION_ORPHAN} from "../../../../api/meta/base/ApiPlus";
import {isOrphanOptionId} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoSwimlane} from "../../../../api/meta/base/dto/DefnDtoSwimlane";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnLayoutKanban} from "../../../../api/meta/base/dto/DefnLayoutKanban";
import {FieldValueOptionId} from "../../../../api/meta/base/dto/FieldValueOptionId";
import {EntUserId} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {MetaIdOption} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {isBubbleFormValueFieldMediaType} from "../../../../base/plus/BubblePlus";
import {dispatchChat} from "../../../../base/plus/ChatPlus";
import {getFormNameColor} from "../../../../base/plus/FormPlus";
import {loopDefnForm} from "../../../../base/plus/FormPlus";
import {hasValues} from "../../../../base/plus/JsPlus";
import {fnGetKanbanFieldValueAsText} from "../../../../base/plus/KanbanPlus";
import {dispatchKanban} from "../../../../base/plus/KanbanPlus";
import {SelectKanban} from "../../../../base/plus/KanbanPlus";
import {random} from "../../../../base/plus/StringPlus";
import {kanbanSetItemIfExistUserFieldVar} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanSetSearch} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanSetIfExistIsInvisibleSpreadsheetRow} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanSetIfExistSigSpreadsheetRowExpiry} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanSetItem} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanSetUserFieldVar} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanRefresh} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {kanbanSetIfExistIsItemWithMedia} from "../../../../base/slices/kanban/SliceKanbanSharedActions";
import {IKanbanBinderThree} from "../../../../base/types/TypeKanban";
import {IKanbanItem} from "../../../../base/types/TypeKanban";
import {TypeKanbanItemId} from "../../../../base/types/TypeKanban";
import {IKanbanItemCol} from "../../../../base/types/TypeKanban";
import {IKanbanData} from "../../../../base/types/TypeKanban";
import {IKanbanItemById} from "../../../../base/types/TypeKanban";
import {IKanbanColumnsByItemIds} from "../../../../base/types/TypeKanban";
import {IKanbanColMap} from "../../../../base/types/TypeKanban";
import {TypeKanbanColId} from "../../../../base/types/TypeKanban";
import {toComboId} from "../../../../base/types/TypesComboId";
import {TypeSubscriberId} from "../../../../base/types/TypesGlobal";
import {selectCallerEnt} from "../../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {ICacheSsEditorRow} from "../../../../cache/app/spreadsheet/ssEditor/TypesCacheSpreadsheetEditor";
import {getCompLabel} from "../../../../nucleus/form/viewer/base/FormViewerPlus";
import {RootState} from "../../../../Store";
import {store} from "../../../../Store";
import {Srvc} from "../../../Srvc";
import {isCommentableForm} from "../../form/SrvcForm";
import {selectCacheRow} from "../SrvcSpreadsheet";
import {TypeUserFieldSsEditorItem} from "./SrvcSsEditor";
import {TypeUserFieldSsEditor} from "./SrvcSsEditor";
import {TypeUserFieldSsEditorList} from "./SrvcSsEditorList";

interface TypeUserFieldSsEditorKanban extends TypeUserFieldSsEditor
{
  firstRender: boolean;
  field: DefnFieldPickText;
}

interface TypeUserFieldSsEditorKanbanItem extends TypeUserFieldSsEditorItem
{
}

export default class SrvcSsEditorKanban extends ISrvc
{
  subscriberId = "SrvcSsEditorKanban" as TypeSubscriberId;

  constructor(readonly selectKanban: SelectKanban)
  {
    super();
  }

  getKanbanBinder()
  {
    return {
      selectSourceItem1: this.selectSsRow.bind(this),
      onBindSourceItem1: this.onBindSsRow.bind(this),
      selectSourceItem2: this.selectSsRowId.bind(this),
      onBindSourceItem2: this.onBindSsRowId.bind(this),
      selectSourceItem3: this.selectSsExpiry.bind(this),
      onBindSourceItem3: this.onBindSsExpiry.bind(this),
      selectIsCommentable: this.selectIsCommentable.bind(this),
      selectUserAvatar: this.selectUserAvatar.bind(this)
    } as IKanbanBinderThree<SigSpreadsheetRow | undefined, boolean | undefined, SigSpreadsheetRowExpiry | undefined>;
  }

  loadKanban(
    kanbanName: string,
    groupByRowIdSetMap: Record<MetaIdOption, RowId[]>,
    defnForm: DefnForm,
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    layout?: DefnLayoutKanban,
    searchText?: string,
    appliedFilters?: TypeKanbanColId[])
  {
    const compMap = defnForm.compMap;
    const columnMap = {} as IKanbanColMap;
    const columnsByKanbanValue = {} as IKanbanColumnsByItemIds;
    const itemsById = {} as IKanbanItemById;

    const kanbanFieldId = layout?.kanbanFieldId;
    const showFieldIdSet = layout?.showFieldIdSet;
    const kanbanField = kanbanFieldId ? compMap[kanbanFieldId] as DefnFieldPickText : undefined;
    const showCommentCount = layout?.showCommentCount;

    kanbanField?.optionMap?.keys.forEach(optionId =>
    {
      columnsByKanbanValue[optionId] = {itemIds: []};
      columnMap[optionId] = this.createKanbanColumnItem(optionId, kanbanField, layout);
    });

    columnsByKanbanValue[META_ID_OPTION_ORPHAN] = {itemIds: []};
    columnMap[META_ID_OPTION_ORPHAN] = this.createKanbanColumnItem(META_ID_OPTION_ORPHAN,
      kanbanField,
      layout
    );

    this.calculateKanbanData(entId, itemsById, columnsByKanbanValue, columnMap, groupByRowIdSetMap, defnForm, layout);

    const columnIdList = Object.keys(columnsByKanbanValue) as TypeKanbanColId[];

    const defnFormUi = {
      ...defnForm,
      chatBubbleFieldIdSet: showFieldIdSet
    };

    const kanbanData: IKanbanData = {
      defnForm: defnFormUi,
      columnIdList: columnIdList,
      columnsByItemIds: columnsByKanbanValue,
      columnMap: columnMap,
      itemsById: itemsById,
      showCommentCount: showCommentCount,
      hideItemTitle: true,
      hideItemSectionName: !layout?.showSectionName,
      hideItemFooter: !layout?.showFooter,
      userField: {
        entId: entId,
        defnForm: defnFormUi,
        firstRender: true,
        spreadsheetId: spreadsheetId,
        field: kanbanField
      } as TypeUserFieldSsEditorKanban,
      error: kanbanField ? undefined : "Kanban field not found",
      version: random(),
      searchWords: searchText?.split(" "),
      filter: appliedFilters
    };

    loopDefnForm(defnForm, (_parent, comp) =>
    {
      if(isBubbleFormValueFieldMediaType(comp))
      {
        dispatchChat(kanbanName, kanbanSetIfExistIsItemWithMedia(true));
      }
    });

    dispatchKanban(kanbanName, kanbanRefresh(kanbanData));

    dispatchKanban(kanbanName, kanbanSetSearch(searchText));
  }

  subscribeKanban(
    entId: EntId,
    rowIds: RowId[],
    spreadsheetId: MetaIdSpreadsheet,
    unSubscribe?: boolean)
  {
    Srvc.app.spreadsheet.subscribeSpreadsheetRows(this.subscriberId, entId, rowIds, spreadsheetId, unSubscribe);
  }

  private calculateKanbanData(
    entId: EntId,
    itemsById: IKanbanItemById,
    columnsByKanbanValue: IKanbanColumnsByItemIds,
    columnMap: IKanbanColMap,
    groupByRowIdSetMap: Record<MetaIdOption, RowId[]>,
    defnForm: DefnForm,
    layout?: DefnLayoutKanban)
  {
    const kanbanFieldId = layout?.kanbanFieldId;
    const kanbanField = kanbanFieldId ? defnForm.compMap[kanbanFieldId] as DefnFieldPickText : undefined;

    Object.entries(groupByRowIdSetMap).forEach(([kanbanValue, rowIdList]) =>
    {
      let colId = !isOrphanOptionId(kanbanValue)
        ? kanbanField?.optionMap?.map[kanbanValue]
          ? kanbanValue
          : META_ID_OPTION_ORPHAN
        : META_ID_OPTION_ORPHAN;

      const rootState = store.getState();
      rowIdList.forEach(rowId =>
      {
        const spreadsheetRow = selectCacheRow(rootState, entId, rowId);
        itemsById[rowId] = this.createKanbanRowItem(rootState, colId, rowId, spreadsheetRow);
      });

      columnsByKanbanValue[colId] = {itemIds: rowIdList};

      columnMap[colId] =
        this.createKanbanColumnItem(kanbanValue, kanbanField, layout);
    });
  }

  private createKanbanColumnItem(
    optionId: MetaIdOption,
    kanbanField?: DefnFieldPickText,
    kanbanLayout?: DefnLayoutKanban): IKanbanItemCol
  {
    const swimlaneMap = kanbanLayout?.swimlaneMap;
    const swimLaneOptionMap = {} as Record<MetaIdOption, DefnDtoSwimlane>;
    swimlaneMap?.keys?.forEach(key =>
    {
      const value = swimlaneMap.map[key];
      swimLaneOptionMap[value.valueOptionId || META_ID_OPTION_ORPHAN] = value;
    });
    const color = hasValues(swimLaneOptionMap[optionId]?.color) ? swimLaneOptionMap[optionId]?.color :
      swimLaneOptionMap[optionId]?.colorVar;
    const label = swimLaneOptionMap[optionId]?.label;

    return {
      color: kanbanField?.optionMap?.map[optionId]?.color,
      bgcolor: color,
      variant: kanbanLayout?.textSize,
      value: optionId,
      fieldName: kanbanField ? getCompLabel(kanbanField) : undefined,
      title: label || fnGetKanbanFieldValueAsText(kanbanField, optionId)
    } as IKanbanItemCol;
  }

  private createKanbanRowItem(
    rootState: RootState,
    colId: TypeKanbanColId,
    rowId: TypeKanbanItemId,
    spreadsheetRow?: SigSpreadsheetRow)
  {
    if(!spreadsheetRow?.formValue)
    {
      return {
        creationTime: "",
        version: random(),
        hideMenu: false,
        colId: colId,
        userField: {
          sigSpreadsheetRow: this.selectSsRow(rootState, rowId)
        } as TypeUserFieldSsEditorKanbanItem
      } as IKanbanItem;
    }

    const formValue = spreadsheetRow?.formValue;
    const userField = this.selectUserField(rootState);
    const expired = this.selectItemUserField(rootState, rowId)?.expired;
    const entId = userField?.entId as EntId;

    const entUserId = selectCallerEnt(rootState, entId)?.entUserId;
    const isCallerSender = entUserId === formValue?.createdBy;

    return {
      rowCommentCount: spreadsheetRow?.rowCommentCount,
      formValue: formValue,
      formBubbleTitleColor: getFormNameColor(
        entUserId,
        spreadsheetRow?.formValue?.updatedBy,
        spreadsheetRow?.updatedKeySet
      ),
      updatedKeySet: spreadsheetRow?.updatedKeySet,
      creationTime: formValue?.createdOn,
      isCallerSender: isCallerSender,
      colId: colId,
      version: spreadsheetRow?.version,
      userField: {
        colId,
        expired,
        sigSpreadsheetRow: spreadsheetRow
      } as TypeUserFieldSsEditorKanbanItem
    } as IKanbanItem;
  }

  private selectIsCommentable(
    rootState: RootState,
    _: TypeKanbanItemId,
    formId: MetaIdForm,
    entUserId: EntUserId)
  {
    const entId = this.selectUserField(rootState)?.entId;
    if(entId)
    {
      return isCommentableForm(rootState, entId, formId, entUserId);
    }
  }

  private selectSsRow(rootState: RootState, rowId: TypeKanbanItemId)
  {
    const entId = this.selectUserField(rootState)?.entId;
    if(entId)
    {
      return selectCacheRow(rootState, entId, rowId);
    }
  }

  private onBindSsRow(kanbanName: string, rowId: TypeKanbanItemId, spreadsheetRow?: SigSpreadsheetRow)
  {
    const formValue = spreadsheetRow?.formValue;
    const rootState = store.getState();
    const kanbanItem = this.selectKanban(rootState);
    const version = kanbanItem.itemsById[rowId]?.version;
    const commentCount = kanbanItem.itemsById[rowId]?.rowCommentCount?.commentCount || 0;
    const spreadSheetComment = spreadsheetRow?.rowCommentCount?.commentCount || 0;
    if((version === spreadsheetRow?.version && (commentCount === spreadSheetComment)))
    {
      return;
    }
    const userField = this.selectUserField(rootState);
    const colId = kanbanItem.itemsById[rowId]?.colId;
    const kanbanFieldId = userField?.field?.metaId;

    const kanbanFieldValue = kanbanFieldId && (formValue?.valueMap[kanbanFieldId] as FieldValueOptionId)?.optionId;

    if(formValue && (kanbanFieldValue || (!kanbanFieldValue && !isOrphanOptionId(colId))))
    {
      const isValid = kanbanFieldValue === colId;
      if(!isValid)
      {
        dispatchKanban(kanbanName, kanbanSetUserFieldVar(
          {
            varName: "isInvalid",
            varValue: true
          }
        ));
      }
    }
    const newItem = this.createKanbanRowItem(rootState, colId, rowId, spreadsheetRow);
    dispatchKanban(kanbanName, kanbanSetItem({
      itemId: rowId,
      item: newItem
    }));
  }

  private selectSsRowId(
    rootState: RootState,
    rowId: TypeKanbanItemId): ICacheSsEditorRow | undefined
  {
    const spreadsheetId = (this.selectKanban(rootState).userField as TypeUserFieldSsEditorList)?.spreadsheetId as MetaIdSpreadsheet;
    if(spreadsheetId)
    {
      return rootState.cache.app.spreadsheet.ssEditor.ssStateMap[spreadsheetId]?.rowIdMap[rowId];
    }
  }

  private onBindSsRowId(kanbanName: string, rowId: TypeKanbanItemId, source?: ICacheSsEditorRow)
  {
    if(source?.removed)
    {
      dispatchKanban(kanbanName, kanbanSetUserFieldVar(
        {
          varName: "isInvalid",
          varValue: true
        }
      ));
    }
    else if(source?.error)
    {
      dispatchKanban(kanbanName,
        kanbanSetIfExistIsInvisibleSpreadsheetRow({
          itemId: rowId,
          isInvisible: true
        })
      );
      dispatchKanban(kanbanName,
        kanbanSetItemIfExistUserFieldVar({
          itemId: rowId,
          varName: "expired",
          varValue: true
        })
      );
    }
  }

  private selectSsExpiry(
    rootState: RootState,
    rowId: TypeKanbanItemId): SigSpreadsheetRowExpiry | undefined
  {
    const entId = this.selectUserField(rootState)?.entId;
    if(entId)
    {
      return rootState.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.spreadsheetRowExpiryMap?.[rowId];
    }
  }

  private onBindSsExpiry(
    kanbanName: string,
    rowId: TypeKanbanItemId,
    sig?: SigSpreadsheetRowExpiry)
  {
    if(sig)
    {
      dispatchKanban(kanbanName, kanbanSetIfExistSigSpreadsheetRowExpiry({
        itemId: rowId,
        sigSpreadsheetRowExpiry: sig
      }));
    }
  }

  private selectUserAvatar(rootState: RootState, entUserId: EntUserId)
  {
    const entId = this.selectUserField(rootState)?.entId;
    if(entId)
    {
      const comboEntUserId = toComboId(entId, entUserId);
      const userAvatar = rootState.cache.app.user.userAvatarMap[comboEntUserId];
      if(!userAvatar)
      {
        Srvc.app.spreadsheet.subscribeSsUser(this.subscriberId, entId, entUserId);
      }
      return userAvatar;
    }
  }

  private selectUserField(rootState: RootState): TypeUserFieldSsEditorKanban | undefined
  {
    return this.selectKanban(rootState)?.userField as TypeUserFieldSsEditorKanban;
  }

  private selectItemUserField(
    rootState: RootState,
    itemId: TypeKanbanItemId): TypeUserFieldSsEditorKanbanItem | undefined
  {
    return this.selectKanban(rootState).itemsById[itemId]?.userField as TypeUserFieldSsEditorKanbanItem;
  }
}
