import {Typography} from "@mui/material";
import React from "react";
import {isGridId} from "../../../../../api/meta/base/ApiPlus";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {FormValue} from "../../../../../api/meta/base/dto/FormValue";
import {MetaIdGrid} from "../../../../../api/meta/base/Types";
import {MetaIdComp} from "../../../../../api/meta/base/Types";
import {toLabel} from "../../../../../base/plus/StringPlus";
import {px} from "../../../../../base/plus/StringPlus";
import theme from "../../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../layout/LayoutFlexRow";
import RawQuickLink from "../../../raw/RawQuickLink";
import BubbleRawDivider from "../BubbleRawDivider";

const STR_COMMA_SEPARATOR = ",";

export default function BubbleRawFormShowGridAsHyperLink(props: {
  defnForm?: DefnForm,
  formValue?: FormValue,
  cbOnClickGridHyperlink?: (menuAnchor: Element, gridId: MetaIdGrid) => void
})
{
  const defnForm = props.defnForm;
  const formValue = props.formValue;
  const onLinkClick = props.cbOnClickGridHyperlink;
  const gridDetailMap = prepareGridDetailMap(formValue?.valueMap, defnForm);
  const gridIdSet = gridDetailMap ? Object.keys(gridDetailMap) : [];
  const gap = theme.common.gapQuarter;

  return (
    <>
      <BubbleRawDivider />
      <LayoutFlexRow
        width={"100%"}
        maxWidth={"100%"}
        justifyContent={"start"}
        pr={px(gapHalf)}
        pl={px(gapHalf)}
        pt={px(gapQuarter / 2)}
        pb={px(gapQuarter / 2)}
        overflowX={"hidden"}
        overflowY={"hidden"}
      >
        {
          gridIdSet.map((gridId, index) =>
          {
            const gridName = gridDetailMap ? gridDetailMap[gridId] : undefined;
            if(gridName && gridId)
            {
              return (
                <React.Fragment>
                  {
                    index !== 0 &&
                    <Typography
                      variant={"body2"}
                      mr={px(gap)}
                    >
                      {STR_COMMA_SEPARATOR}
                    </Typography>
                  }
                  <RawQuickLink
                    onClick={(_name, menuAnchor) => onLinkClick && onLinkClick(menuAnchor, gridId)}
                    label={gridName}
                    name={""}
                    variant={"body2"}
                    color={"primary"}
                  />
                </React.Fragment>
              );
            }
            else
            {
              return null;
            }
          })
        }
      </LayoutFlexRow>
    </>
  );
}

function prepareGridDetailMap(
  valueMap?: Record<MetaIdComp, any>,
  defnForm?: DefnForm): Record<MetaIdGrid, string> | undefined
{
  if(!valueMap || !defnForm)
  {
    return;
  }
  const gridDetailMap = {} as Record<MetaIdGrid, string>;
  const valueIdSet = Object.keys(valueMap);
  const compMap = defnForm.compMap;

  valueIdSet.forEach((fieldId) =>
  {
    if(fieldId !== undefined && isGridId(fieldId))
    {
      const gridLabel = compMap[fieldId]?.label;
      const gridName = compMap[fieldId]?.name;
      gridDetailMap[fieldId] = gridLabel || toLabel(gridName);
    }
  });

  return gridDetailMap;
}
