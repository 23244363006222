import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {RpcEntDrawer} from "../../../api/ent/entDrawer/RpcEntDrawer";
import {WsocEntDrawer} from "../../../api/ent/entDrawer/WsocEntDrawer";
import {RpcEntMain} from "../../../api/ent/entMain/RpcEntMain";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {setUserAvatar} from "../user/SliceCacheUser";
import {setCallerEnt} from "./SliceCacheCallerEnt";
import {ICallerEnt} from "./TypesCacheCallerEnt";

//*************************************************************
// 1. Editing this file requires explicit approval from Bhavesh
// 2. After editing this file ensure code review from Bhavesh
//*************************************************************

export default class SrvcCacheCallerEnt extends ISrvc
{
  wsocCallerEntGet(entId: EntId)
  {
    const rootState = store.getState();
    const callerEntVersion = selectCallerEnt(rootState, entId)?.callerEntVersion;
    const msg = {} as MsgVersion;
    if(callerEntVersion)
    {
      msg.version = callerEntVersion;
    }

    WsocEntDrawer.entCallerGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;

      if(sig)
      {
        if(sig.version !== callerEntVersion)
        {
          store.dispatch(setCallerEnt(sig));
        }
      }
    });
  }

  rpcCallerEntGet(entId: EntId)
  {
    const rootState = store.getState();
    const callerEntVersion = selectCallerEnt(rootState, entId)?.callerEntVersion;
    const msg = {} as MsgVersion;
    if(callerEntVersion)
    {
      msg.version = callerEntVersion;
    }

    RpcEntDrawer.entCallerGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;

      if(sig)
      {
        if(sig.version !== callerEntVersion)
        {
          store.dispatch(setCallerEnt(sig));
        }
      }
    });
  }

  rpcEntUserAvatarListGet(entId: EntId, cbSuccess?: () => void)
  {
    const msg = {} as MsgVersion;

    RpcEntMain.entUserAvatarListGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        Object.keys(sig.avatarMap).forEach(userId =>
        {
          const avatar = sig.avatarMap[userId];
          store.dispatch(setUserAvatar(avatar));
        });
      }
      cbSuccess && cbSuccess();
    });
  }

}

export function selectCallerEnt(state: RootState, entId: EntId): ICallerEnt | undefined
{
  return state.cache.app.callerEnt.callerEntMap[entId];
}

export function selectCallerEntForm(state: RootState, entId: EntId, formId: MetaIdForm)
{
  return selectCallerEnt(state, entId)?.formMap[formId] as DefnForm | undefined;
}
