// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ISigAcceptor} from "./ISigAcceptor";
import {ISig} from "../../meta/base/sig/ISig";
import {IMsg} from "../../meta/base/msg/IMsg";
import {EntId, ServiceName} from "../../meta/base/Types";

export interface IRpcCall<S extends ISig>
{
  sendBearerToken(): IRpcCall<S>;

  sendRefreshToken(): IRpcCall<S>;

  get(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>;

  post(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>;

  patch(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>;

  put(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>;

  delete(msg: IMsg | undefined, sigAcceptor: ISigAcceptor<S>): IRpcCall<S>;
}

export interface IRpcCallFactory
{
  create<S extends ISig>(entId: EntId, serviceName: ServiceName, apiName: string): IRpcCall<S>;
}

let cbRpcCallFactory: IRpcCallFactory;

export function rpcCall<S extends ISig>(entId: EntId, serviceName: ServiceName, apiName: string)
{
  return cbRpcCallFactory.create<S>(entId, serviceName, apiName);
}

export function setRpcCallFactory(rpcCallFactory: IRpcCallFactory)
{
  cbRpcCallFactory = rpcCallFactory;
}