import {Box} from "@mui/material";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import {IPopoverMsg} from "./RawPopover";

export default function RawPopoverText(props: {
  msg: string | string[] | IPopoverMsg[],
})
{
  const msg = props.msg;
  const theme = useTheme();
  const gap = theme.common.gapStd;
  if(typeof msg === "string")
  {
    return <RawPopoverTextMsg msg={msg as string | string[]} />;
  }
  else if(Array.isArray(msg))
  {
    return (msg).map((_msg, index) =>
    {
      if(typeof _msg === "string")
      {

        return <RawPopoverTextMsg
          key={_msg}
          msg={_msg}
        />;
      }
      if(typeof _msg === "object")
      {
        let message = msg.length === 1 ? _msg.msg : `${index + 1}. ${_msg.msg}`;
        if(index === 4)
        {
          message = `...${msg.length - index} more`;
        }
        if(index > 4)
        {
          return;
        }
        if(_msg.children?.length)
        {
          return (
            <LayoutFlexCol
              alignItems={"flex-start"}
              key={_msg.msg}
            >
              <RawPopoverTextMsg msg={message} />
              <RawPopoverTextMsg
                msg={_msg.children}
                pl={gap}
              />
            </LayoutFlexCol>);
        }
        return (
          <RawPopoverTextMsg
            key={message}
            msg={message}
          />
        );
      }
    });
  }

}

function RawPopoverTextMsg(props: {
  msg?: string | string[],
  pl?: number
})
{
  const theme = useTheme();
  const msg = props.msg;

  if(Array.isArray(msg) && msg.length > 0)
  {
    return (
      <LayoutFlexCol
        pl={px(props.pl)}
        alignItems={"flex-start"}
      >
        {msg.map((text, index) =>
          <Typography
            variant={"caption"}
            key={text + index}
          >
            {text}
          </Typography>
        )}
      </LayoutFlexCol>
    );
  }
  else if(msg)
  {
    return (
      <Box
        sx={{
          lineHeight: "0",
          padding: px(theme.common.gapQuarter)
        }}
      >
        <Typography variant={"caption"}>
          {msg}
        </Typography>
      </Box>
    );
  }
}
