import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {isArray} from "lodash";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldChipSet} from "../../../../api/meta/base/dto/DefnFieldChipSet";
import {FieldSetOfString} from "../../../../api/meta/base/dto/FieldSetOfString";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToOptions} from "../../../../base/plus/JsPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawChipsInput from "../../../atom/raw/RawChipsInput";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldChipSet(props: {
  defn: DefnFieldChipSet,
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const defnTheme = formCtx.getDefnTheme();
  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);

  const fieldVariant = defnTheme.fieldVariant;

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldChipSet) || defn.disabled;
  const allowDuplicate = defn.allowDuplicate;

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const helperText = getFieldHelperText();
  const icon = getFieldIcon();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);

        const fieldValue = field.value as FieldSetOfString;
        const chipList = fieldValue && fieldValue.valueSet.length > 0
          ? arrayToOptions(fieldValue.valueSet, true)
          : undefined;

        const onChange = (value?: DefnDtoOption[]) =>
        {
          if(value && isArray(value))
          {
            field.onChange({
              valueSet: value.map(opt => opt.value)
            } as FieldSetOfString);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
              >
                <RawChipsInput
                  chipList={chipList}
                  placeHolder={placeHolder}
                  label={label}
                  required={required}
                  name={defn.name}
                  onChange={(event) => onChange(event)}
                  readOnly={readOnly}
                  disabled={disabled}
                  hideLabel={Boolean(defn.hideLabel)}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  isError={isError}
                  autoFocus={Boolean(defn.autoFocus)}
                  allowDuplicate={allowDuplicate}
                  formCtx={formCtx}
                  fieldId={fieldId}
                  icon={icon}
                />
                <FieldRawRefButton defn={defn} />
              </LayoutFlexRow>
              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
