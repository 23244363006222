import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldTextSize} from "../../../../api/meta/base/dto/DefnFieldTextSize";
import {EnumArrayDefnTextSize} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldTextSize(props: {
  defn: DefnFieldTextSize
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const fieldValue = field.value as string | undefined;
        const onChange = (values: string) =>
        {
          if(values)
          {
            field.onChange(values);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawStudioPick
            fieldProps={{
              field: {
                ...field,
                value: fieldValue,
                onChange: onChange
              },
              fieldState: fieldState
            }}
            defn={props.defn}
            optionMap={arrayToMapOfOption(EnumArrayDefnTextSize)}
            multiSelect={false}
            showChip={false}
          />
        );
      }}
    />
  );
}
