import ISrvc from "../../../base/ISrvc";
import {SelectList} from "../../../base/plus/ListPlus";
import SrvcStoreDrawerFilter from "./SrvcStoreDrawerFilter";
import SrvcStoreDrawerRecentList from "./SrvcStoreDrawerRecentList";
import SrvcStoreDrawerSearch from "./SrvcStoreDrawerSearch";

export class SrvcStoreDrawer extends ISrvc
{
  public readonly recentList = new SrvcStoreDrawerRecentList(state => state.store.drawer.storeRecentList);
  public readonly search = new SrvcStoreDrawerSearch(state => state.store.drawer.storeSearch);
  public readonly filter = new SrvcStoreDrawerFilter();

  constructor(
    readonly selectStoreList: SelectList,
    readonly selectStoreFilterList: SelectList)
  {
    super();

    this.setSrvcArray(
      this.recentList,
      this.search,
      this.filter
    );
  }
}
