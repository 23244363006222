import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Divider} from "@mui/material";
import {CSSProperties} from "react";
import React from "react";
import {EnumDefnThemeDividerKind} from "../../../api/meta/base/Types";
import {px} from "../../../base/plus/StringPlus";

export function DividerHorizontal(props: {
  borderColor?: CSSProperties["borderColor"],
  kind?: EnumDefnThemeDividerKind
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const sizeDivider = theme.common.sizeDivider;

  const kind = props.kind;
  const borderColor = props.borderColor;

  return (
    <Box
      sx={{
        width: "100%",
        height: kind === "thick"
          ? px(gapHalf)
          : px(sizeDivider)
      }}
    >
      <Divider
        orientation={"horizontal"}
        variant={"fullWidth"}
        sx={{
          border: kind === "thick" ? "none" : undefined,
          width: "100%",
          height: kind === "thick"
            ? px(gapHalf)
            : px(sizeDivider),
          bgcolor: borderColor ?? kind === "thick"
            ? theme.common.bgcolorDefault
            : theme.common.borderColor,
          borderColor: borderColor ?? theme.common.borderColor
        }}
      />
    </Box>
  );
}
