import {FieldValues} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldRating} from "../../../../../api/meta/base/dto/StudioFieldRating";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {EnumDefnKindRating} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyPickRatingVariant} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyKind} from "../../base/TypesFormBuilder";

export function getDefnFieldRating(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyKind,
    fieldGap2,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyPickRatingVariant,
    fieldGap2,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "rating",
      formId,
      "number",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyKind]: {
      type: "pickVarId",
      name: propKeyKind,
      metaId: propKeyKind,
      varKind: "ratingKind",
      label: "Rating kind",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyPickRatingVariant]: {
      type: "enumKindRating",
      name: propKeyPickRatingVariant,
      metaId: propKeyPickRatingVariant,
      label: "Rating kind"
    } as DefnFieldPickEnum

  };
}

export function defnValueToStudioFieldRating(values: FieldValues): StudioFieldRating
{
  return {
    type: "rating",
    ratingKind: fnFieldValueToRawValue("enumKindRating", values[propKeyPickRatingVariant]),
    ratingKindVarId: fnFieldValueToRawValue("pickVarId", values[propKeyKind])
  } as StudioFieldRating;
}

export function studioFieldRatingToDefnValue(studioField: StudioFieldRating)
{
  return {
    [propKeyPickRatingVariant]: fnRawValueToFieldValue("enumKindRating", studioField.ratingKind as EnumDefnKindRating),
    [propKeyKind]: fnRawValueToFieldValue("pickVarId", studioField.ratingKindVarId)
  };
}

