import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnStudioMapOfCondition} from "../../../api/meta/base/dto/DefnStudioMapOfCondition";
import {DefnStudioPickFormId} from "../../../api/meta/base/dto/DefnStudioPickFormId";
import {DefnStudioPickGridId} from "../../../api/meta/base/dto/DefnStudioPickGridId";
import {DefnStudioPickImportPluginId} from "../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {DefnStudioPickPluginCompId} from "../../../api/meta/base/dto/DefnStudioPickPluginCompId";
import {DefnStudioPickPluginFormId} from "../../../api/meta/base/dto/DefnStudioPickPluginFormId";
import {StudioVarCondition} from "../../../api/meta/base/dto/StudioVarCondition";
import {MetaIdPlugin} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {arrayToMapOfOption} from "../../../base/plus/JsPlus";
import {fieldGap1} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap2} from "../../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../form/builder/base/TypesFormBuilder";
import {fieldVariableConditionTree} from "../base/VariablePlus";
import {fieldVariableConditionSourceType} from "../base/VariablePlus";
import {fieldVariableConditionSourcePluginId} from "../base/VariablePlus";
import {TypeFromToType} from "../base/VariablePlus";
import {fieldVariableLabel} from "../base/VariablePlus";
import {fieldVariableConditionInputFormId} from "../base/VariablePlus";
import {fieldVariableConditionSourceGridId} from "../base/VariablePlus";
import {fieldVariableConditionSourceFormId} from "../base/VariablePlus";

export function fnVariableBuilderCondition(fieldValueCondition: string)
{
  function getVarComp(
    fromType: TypeFromToType,
    sourceFormId: MetaIdForm,
    sourceGridId: MetaIdForm,
    selectedConditionVarId?: MetaIdVar,
    inputFormId?: MetaIdForm,
    sourcePluginId?: MetaIdPlugin)
  {
    return {
      [fieldVariableLabel]: {
        type: "label",
        metaId: fieldVariableLabel,
        name: "ForSource",
        label: "From source",
        colorVar: {
          value: "textSecondary"
        }
      } as DefnFieldLabel,

      [fieldVariableConditionSourceType]: {
        type: "pickText",
        metaId: fieldVariableConditionSourceType,
        name: fieldVariableConditionSourceType,
        required: true,
        optionMap: arrayToMapOfOption(["regular", "plugin"] as TypeFromToType[]),
        defaultOptionIdArray: ["regular"] as TypeFromToType[],
        showAs: "radioButtonHorizontal"
      } as DefnFieldPickText,

      ...fromType === "plugin" && {
        [fieldVariableConditionSourcePluginId]: {
          type: "pickImportPluginId",
          metaId: fieldVariableConditionSourcePluginId,
          name: fieldVariableConditionSourcePluginId,
          label: "Plugin"
        } as DefnStudioPickImportPluginId,

        [fieldVariableConditionSourceFormId]: {
          type: "pickPluginFormId",
          metaId: fieldVariableConditionSourceFormId,
          pluginId: sourcePluginId,
          name: "Form"
        } as DefnStudioPickPluginFormId,

        [fieldVariableConditionSourceGridId]: {
          type: "pickPluginCompId",
          metaId: fieldVariableConditionSourceGridId,
          pluginId: sourcePluginId,
          formId: sourceFormId,
          filterCompTypeSet: ["grid"],
          name: "Grid"
        } as DefnStudioPickPluginCompId
      },

      ...fromType === "regular" && {
        [fieldVariableConditionSourceFormId]: {
          type: "pickFormId",
          metaId: fieldVariableConditionSourceFormId,
          name: "Form"
        } as DefnStudioPickFormId,
        [fieldVariableConditionSourceGridId]: {
          type: "pickGridId",
          metaId: fieldVariableConditionSourceGridId,
          name: "Grid",
          formId: sourceFormId
        } as DefnStudioPickGridId
      },

      ...getFieldGap(fieldGap1, "thick"),

      [fieldVariableConditionInputFormId]: {
        type: "pickFormId",
        metaId: fieldVariableConditionInputFormId,
        name: fieldVariableConditionInputFormId,
        label: "Input form"
      } as DefnStudioPickFormId,

      ...getFieldGap(fieldGap2, "thick"),

      [fieldVariableConditionTree]: {
        type: "studioMapOfCondition",
        metaId: fieldVariableConditionTree,
        name: "Condition",
        required: true,
        sourceFormId: sourceFormId,
        sourceGridId: sourceGridId,
        inputFormId: inputFormId,
        sourcePluginId: sourcePluginId,
        excludeVarIdSet: selectedConditionVarId ? [selectedConditionVarId] : undefined
      } as DefnStudioMapOfCondition

    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarCondition
  )
  {
    const varValue = entVariable.value;
    const fromSourcePluginId = fnRawValueToFieldValue("pickImportPluginId", varValue?.sourcePluginId);

    valueMap[fieldVariableConditionSourceType] = fromSourcePluginId
      ? fnRawValueToFieldValue("pickText", "plugin")
      : fnRawValueToFieldValue("pickText", "regular");

    valueMap[fieldVariableConditionSourceFormId] = fnRawValueToFieldValue("pickFormId", varValue?.sourceFormId);
    valueMap[fieldVariableConditionSourceGridId] = fnRawValueToFieldValue("pickGridId", varValue?.sourceGridId);
    valueMap[fieldVariableConditionInputFormId] = fnRawValueToFieldValue("pickFormId", varValue?.inputFormId);
    valueMap[fieldVariableConditionSourcePluginId] = fromSourcePluginId;
    valueMap[fieldVariableConditionTree] = fnRawValueToFieldValue("studioMapOfCondition", varValue?.node);
  }

  function defnToDto(valueMap: FieldValues)
  {
    const fromSourcePluginId = fnFieldValueToRawValue("pickImportPluginId",
      valueMap[fieldVariableConditionSourcePluginId]
    ) as MetaIdPlugin | undefined;

    return {
      value: {
        node: fnFieldValueToRawValue("studioMapOfCondition", valueMap[fieldVariableConditionTree]),
        sourceFormId: fromSourcePluginId
          ? fnFieldValueToRawValue("pickPluginFormId", valueMap[fieldVariableConditionSourceFormId])
          : fnFieldValueToRawValue("pickFormId", valueMap[fieldVariableConditionSourceFormId]),
        sourceGridId: fromSourcePluginId
          ? fnFieldValueToRawValue("pickPluginCompId", valueMap[fieldVariableConditionSourceGridId])
          : fnFieldValueToRawValue("pickGridId", valueMap[fieldVariableConditionSourceGridId]),
        inputFormId: fnFieldValueToRawValue("pickFormId", valueMap[fieldVariableConditionInputFormId]),
        sourcePluginId: fromSourcePluginId
      }
    } as StudioVarCondition;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
