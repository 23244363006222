import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {FieldChipSetTime} from "../../../api/meta/base/dto/FieldChipSetTime";
import {StudioVarSetOfTime} from "../../../api/meta/base/dto/StudioVarSetOfTime";

export function fnVariableBuilderSetOfTime(fieldValueSetOfTime: string)
{
  function getVarComp()
  {
    return {
      [fieldValueSetOfTime]: {
        type: "chipSetTime",
        metaId: fieldValueSetOfTime,
        name: "Value",
        required: true,
        multiSelect: true
      } as DefnFieldEditable
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarSetOfTime
  )
  {
    const varValue = variable.value;
    if(varValue)
    {
      valueMap[fieldValueSetOfTime] = {
        valueSet: varValue.valueSet
      } as FieldChipSetTime;
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const timeSetValue = valueMap[fieldValueSetOfTime] as FieldChipSetTime;
    if(timeSetValue && !isEmpty(timeSetValue))
    {
      return {
        value: timeSetValue
      } as StudioVarSetOfTime;
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
