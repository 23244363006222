import {FieldValues} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickText} from "../../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {FieldValueOptionId} from "../../../../../api/meta/base/dto/FieldValueOptionId";
import {StudioFieldSwitch} from "../../../../../api/meta/base/dto/StudioFieldSwitch";
import {StudioVarMap} from "../../../../../api/meta/base/dto/StudioVarMap";
import {LabelPlacement} from "../../../../../api/meta/base/StudioSetsFieldType";
import {SwitchPosition} from "../../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getExcludeVarIdSet} from "../../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../../base/plus/JsPlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyBoolDefaultValue} from "../../base/TypesFormBuilder";
import {propKeyShowAsCheckboxFieldId} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyLabelPlacement} from "../../base/TypesFormBuilder";
import {propKeyPosition} from "../../base/TypesFormBuilder";
import {propKeyShowAsCheckBox} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {propKeyShowCapturedValuesOnAside} from "../../base/TypesFormBuilder";
import {propKeyPositionVar} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {propKeyCaptureTime} from "../../base/TypesFormBuilder";
import {propKeyCaptureUser} from "../../base/TypesFormBuilder";
import {propKeyCaptureLocation} from "../../base/TypesFormBuilder";
import {propKeyShowAsCheckBoxVar} from "../../base/TypesFormBuilder";
import {propKeyLabelPlacementVar} from "../../base/TypesFormBuilder";

export function getDefnFieldBool(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  varMap?: StudioVarMap,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyShowAsCheckboxFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyShowAsCheckBoxVar,
    propKeyPositionVar,
    propKeyLabelPlacementVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyBoolDefaultValue,
    fieldGap2,
    propKeyCaptureLocation,
    propKeyCaptureTime,
    propKeyCaptureUser,
    fieldGap3,
    propKeyShowAsCheckBox,
    propKeyPosition,
    propKeyLabelPlacement,
    fieldGap4,
    propKeyShowCapturedValuesOnAside
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "bool",
      formId,
      "bool",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyBoolDefaultValue]: {
      type: "pickText",
      metaId: propKeyBoolDefaultValue,
      name: propKeyBoolDefaultValue,
      label: "Default value",
      optionMap: arrayToMapOfOption(["True", "False"]),
      showAs: "dropdown"
    } as DefnFieldPickText,

    [propKeyCaptureLocation]: {
      type: "bool",
      name: propKeyCaptureLocation,
      metaId: propKeyCaptureLocation,
      label: "Capture location",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureTime]: {
      type: "bool",
      name: propKeyCaptureTime,
      metaId: propKeyCaptureTime,
      label: "Capture time",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureUser]: {
      type: "bool",
      name: propKeyCaptureUser,
      metaId: propKeyCaptureUser,
      label: "Capture user",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowAsCheckBox]: {
      type: "bool",
      name: propKeyShowAsCheckBox,
      metaId: propKeyShowAsCheckBox,
      label: "Show as checkbox",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowAsCheckBoxVar]: {
      type: "pickVarId",
      name: propKeyShowAsCheckBoxVar,
      label: "Show as checkbox",
      metaId: propKeyShowAsCheckBoxVar,
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyShowAsCheckboxFieldId]: {
      type: "pickFieldId",
      metaId: propKeyShowAsCheckboxFieldId,
      name: propKeyShowAsCheckboxFieldId,
      label: "Show as checkbox",
      filterFieldTypeSet: ["bool"],
      formId: formId,
      showCompositeName: true,
      excludeFieldIdSet: [fieldId],
      compositeIdSet: sectionIdSetWithCurrentGridId
    } as DefnStudioPickFieldId,

    [propKeyPosition]: {
      type: "enumPlacement",
      name: propKeyPosition,
      metaId: propKeyPosition,
      label: "Position",
      filterOptionSet: SwitchPosition
    } as DefnFieldPickEnum,

    [propKeyPositionVar]: {
      type: "pickVarId",
      name: propKeyPositionVar,
      label: "Position",
      metaId: propKeyPositionVar,
      varKind: "placement",
      showAsEdit: true,
      formId: formId,
      filterOptionSet: SwitchPosition,
      excludeVarIdSet: getExcludeVarIdSet(SwitchPosition as EnumDefnPlacement[], varMap)
    } as DefnStudioPickVarId,

    [propKeyLabelPlacement]: {
      type: "enumPlacement",
      name: propKeyLabelPlacement,
      metaId: propKeyLabelPlacement,
      label: "Label placement",
      filterOptionSet: LabelPlacement
    } as DefnFieldPickEnum,

    [propKeyLabelPlacementVar]: {
      type: "pickVarId",
      name: propKeyLabelPlacementVar,
      label: "Label placement",
      metaId: propKeyLabelPlacementVar,
      varKind: "placement",
      showAsEdit: true,
      formId: formId,
      filterOptionSet: LabelPlacement,
      excludeVarIdSet: getExcludeVarIdSet(LabelPlacement as EnumDefnPlacement[], varMap)
    } as DefnStudioPickVarId,

    [propKeyShowCapturedValuesOnAside]: {
      type: "enumCaptureValueKind",
      name: propKeyShowCapturedValuesOnAside,
      metaId: propKeyShowCapturedValuesOnAside,
      label: "Show captured values on aside"
    } as DefnFieldPickEnum
  };
}

export function defnValueToStudioFieldSwitch(values: FieldValues): StudioFieldSwitch
{
  const defaultValue = fnFieldValueToRawValue("pickText", values[propKeyBoolDefaultValue] as FieldValueOptionId);

  return {
    type: "bool",
    defaultValue: defaultValue !== undefined ? defaultValue === "True" : undefined,
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    captureLocation: fnFieldValueToRawValue("bool", values[propKeyCaptureLocation]),
    captureTime: fnFieldValueToRawValue("bool", values[propKeyCaptureTime]),
    captureUser: fnFieldValueToRawValue("bool", values[propKeyCaptureUser]),
    labelPlacement: fnFieldValueToRawValue("enumPlacement", values[propKeyLabelPlacement]),
    labelPlacementVarId: fnFieldValueToRawValue("pickVarId", values[propKeyLabelPlacementVar]),
    showAsCheckbox: fnFieldValueToRawValue("bool", values[propKeyShowAsCheckBox]),
    showAsCheckboxVarId: fnFieldValueToRawValue("pickVarId", values[propKeyShowAsCheckBoxVar]),
    showAsCheckboxFieldId: fnFieldValueToRawValue("pickVarId", values[propKeyShowAsCheckboxFieldId]),
    position: fnFieldValueToRawValue("enumPlacement", values[propKeyPosition]),
    positionVarId: fnFieldValueToRawValue("pickVarId", values[propKeyPositionVar]),
    showCapturedValuesOnAside: fnFieldValueToRawValue("enumCaptureValueKind", values[propKeyShowCapturedValuesOnAside])
  } as StudioFieldSwitch;
}

export function studioFieldToDefnValueSwitch(studioField: StudioFieldSwitch)
{
  return {
    [propKeyBoolDefaultValue]: studioField.defaultValue !== undefined
      ? fnRawValueToFieldValue("pickText", studioField.defaultValue ? "True" : "False")
      : undefined,
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyCaptureLocation]: fnRawValueToFieldValue("bool", studioField.captureLocation),
    [propKeyCaptureTime]: fnRawValueToFieldValue("bool", studioField.captureTime),
    [propKeyCaptureUser]: fnRawValueToFieldValue("bool", studioField.captureUser),
    [propKeyLabelPlacement]: fnRawValueToFieldValue("enumPlacement", studioField.labelPlacement),
    [propKeyLabelPlacementVar]: fnRawValueToFieldValue("pickVarId", studioField.labelPlacementVarId),
    [propKeyShowAsCheckBox]: fnRawValueToFieldValue("bool", studioField.showAsCheckbox),
    [propKeyShowAsCheckBoxVar]: fnRawValueToFieldValue("pickVarId", studioField.showAsCheckboxVarId),
    [propKeyShowAsCheckboxFieldId]: fnRawValueToFieldValue("pickVarId", studioField.showAsCheckboxFieldId),
    [propKeyPosition]: fnRawValueToFieldValue("enumPlacement", studioField.position),
    [propKeyPositionVar]: fnRawValueToFieldValue("pickVarId", studioField.positionVarId),
    [propKeyShowCapturedValuesOnAside]: fnRawValueToFieldValue("enumCaptureValueKind",
      studioField.showCapturedValuesOnAside
    )
  };
}
