export const PAYMENT_SECTION_ID = "metaSection-paymentSection";
export const FIELD_INVOICE_ID = "metaField-invoiceId";
export const FIELD_INVOICE_AMOUNT = "metaField-invoiceAmount";
export const FIELD_PAYMENT_ID = "metaField-paymentId";
export const FIELD_PAYMENT_STATUS = "metaField-paymentStatus";
export const FIELD_PAYMENT_PROVIDER_ID = "metaField-paymentProviderId";

export const ArrayPaymentFields = [
  FIELD_INVOICE_ID,
  FIELD_INVOICE_AMOUNT,
  FIELD_PAYMENT_ID,
  FIELD_PAYMENT_STATUS,
  FIELD_PAYMENT_PROVIDER_ID,
  PAYMENT_SECTION_ID
];

export interface IPaymentParams
{
  apiKey: string;
  amount: number;
  currency: string;
  orderId: string;
  entName: string;
  entImage?: string;
  description?: string;
  customerName?: string;
  customerEmail?: string;
  customerPhone?: string;
  notes?: Record<string, string>;
  themeColor?: string;
}
