import {useTheme} from "@mui/material";
import {Box} from "@mui/system";
import {Property} from "csstype";
import React from "react";
import {px} from "../../../base/plus/StringPlus";

export default function BoxColor(props: {
  bgcolor?: Property.BackgroundColor
  size?: Property.Height,
  pl?: number,
  pr?: number,
  pt?: number,
  pb?: number,
  ml?: number,
  mr?: number
})
{
  const theme = useTheme();
  const bgcolor = props.bgcolor;
  const pl = px(props.pl);
  const pr = px(props.pr);
  const pt = px(props.pt);
  const pb = px(props.pb);
  const ml = px(props.ml);
  const mr = px(props.mr);

  const size = props.size ?? px(theme.common.heightLine);
  const vertInner = theme.common.vertInner;

  return (
    <Box
      bgcolor={bgcolor}
      borderRadius={px(vertInner)}
      pl={pl}
      pr={pr}
      pt={pt}
      pb={pb}
      height={size}
      width={size}
      ml={ml}
      mr={mr}
    />
  );
}
