// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgCliCode} from './msg/MsgCliCode';
import {MsgCliGetCode} from './msg/MsgCliGetCode';
import {MsgCliSuggestion} from './msg/MsgCliSuggestion';
import {ServiceName} from '../../meta/base/Types';
import {SigCliCode} from './sig/SigCliCode';
import {SigCliGetCode} from './sig/SigCliGetCode';
import {SigCliSuggestion} from './sig/SigCliSuggestion';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocCli
{
  static readonly SN: ServiceName = "cli";

  static getCliCode(msg: MsgCliGetCode, sigAcceptor: ISigAcceptor<SigCliGetCode>): void
  {
    wsocCall<SigCliGetCode>(ENT_ID_GLOBAL, WsocCli.SN, "getCliCode")
      .get(msg, sigAcceptor);
  }

  static getCliSuggestion(msg: MsgCliSuggestion, sigAcceptor: ISigAcceptor<SigCliSuggestion>): void
  {
    wsocCall<SigCliSuggestion>(ENT_ID_GLOBAL, WsocCli.SN, "getCliSuggestion")
      .get(msg, sigAcceptor);
  }

  static putCliCode(msg: MsgCliCode, sigAcceptor: ISigAcceptor<SigCliCode>): void
  {
    wsocCall<SigCliCode>(ENT_ID_GLOBAL, WsocCli.SN, "putCliCode")
      .put(msg, sigAcceptor);
  }
}