import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import React from "react";
import {IDialogMsg} from "./TypesDialog";

export default function DialogTypography(props: {
  msg: IDialogMsg | string,
  pt?: StandardCSSProperties["paddingTop"],
  pb?: StandardCSSProperties["paddingBottom"],
})
{
  const msg = props.msg;
  const theme = useTheme();

  let text;
  let variant: Variant | undefined;
  let color;
  if(typeof msg === "string")
  {
    text = msg;
  }
  else
  {
    text = msg.text;
    variant = msg.variant;
    color = theme.common.color(msg.color ?? "textPrimary");
  }

  return (
    <Typography
      color={color}
      textAlign={"center"}
      variant={variant}
      sx={{
        pt: props.pt,
        pb: props.pb
      }}
    >
      {text}
    </Typography>
  );
}
