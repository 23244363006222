import {useTheme} from "@mui/material";
import {FormControl} from "@mui/material";
import {FormHelperText} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldCounter} from "../../../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {FieldValueNumber} from "../../../../api/meta/base/dto/FieldValueNumber";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawCounter from "../../../atom/raw/RawCounter";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldCounter(props: {
  defn: DefnFieldCounter
})
{
  const defn = props.defn;
  const formCtx = useFormCtx();
  const theme = useTheme();

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldMinCounter,
    getFieldMaxCounter,
    getFieldStepCounter
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const min = getFieldMinCounter() as number;
  const max = getFieldMaxCounter() as number;
  const step = getFieldStepCounter() as number;

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const justifyContent = defn.justifyContent;

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const isPropertyEditor = formSection.sectionVariant === "propertyEditor";
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const hideLabel = defn.hideLabel;
  const form = formSectionCtx.getDefnForm();
  const isReport = form._defnTheme?.formVariant === "report";
  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick && !disabled
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const defnLabel = {
    label: Boolean(required) ? label + " *" : label
  } as DefnFieldLabel;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error
        } = fieldState;

        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueNumber | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
                height={px(theme.common.gapStd * 2.5)}
                onClick={onClick}
                cursorPointer={(onClick !== undefined) ? true : undefined}
              >
                {
                  (!hideLabel && !isPropertyEditor)
                    ? <FieldRawKeyValuePair
                      disableFlexBasis={!isReport}
                      leftMaxWidth={"50%"}
                      mr={theme.common.gapStd}
                      left={<FieldLabel defn={defnLabel} />}
                      right={
                        <RawCounter
                          readOnly={readOnly}
                          disabled={disabled}
                          value={fieldValue?.value}
                          maxVar={max}
                          minVar={min}
                          step={step}
                          disableAnimation={true}
                          position={"left"}
                          onChange={(_, value) => field.onChange({value: value} as FieldValueNumber)}
                        />
                      }
                    />
                    : <RawCounter
                      readOnly={readOnly}
                      disabled={disabled}
                      value={fieldValue?.value}
                      maxVar={max}
                      minVar={min}
                      step={step}
                      disableAnimation={true}
                      position={justifyContent === "end" ? "right" : "left"}
                      onChange={(_, value) => field.onChange({value: value} as FieldValueNumber)}
                    />
                }
                <FieldRawRefButton
                  defn={defn}
                />
              </LayoutFlexRow>

              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message || helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}
