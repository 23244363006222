import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {StudioEnt} from "../../../api/meta/base/dto/StudioEnt";
import {StudioEntDetails} from "../../../api/meta/base/dto/StudioEntDetails";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {newGuid} from "../../../api/meta/base/NanoId";
import {EnumDefnStoreItem} from "../../../api/meta/base/Types";
import {AdminId} from "../../../api/meta/base/Types";
import {TimeZoneKey} from "../../../api/meta/base/Types";
import {EnumStoreLabel} from "../../../api/meta/base/Types";
import {EntId, EnumDefnSetupKind, LanguageKey, MediaIdAvatar, MetaId} from "../../../api/meta/base/Types";
import {getValidationErrorMessages} from "../../../api/nucleus/base/Protocol";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {MsgStudioEntCreate} from "../../../api/studio/studioDrawer/msg/MsgStudioEntCreate";
import {MsgStudioEnt} from "../../../api/studio/studioMain/msg/MsgStudioEnt";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigAdminEditLockDetail} from "../../../api/studio/studioMain/sig/SigAdminEditLockDetail";
import ISrvc from "../../../base/ISrvc";
import {isoDateTimeNow} from "../../../base/plus/DatePlus";
import {fnDeltaItemModules} from "../../../base/plus/StudioPlus";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {TypeListSortType} from "../../../base/types/TypesStudio";
import {EnumListItemDirection} from "../../../base/types/TypesStudio";
import {setEntEditLockDetail} from "../../../cache/studio/ent/SliceCacheStudioEnt";
import {setSetupMode} from "../../../cache/studio/ent/SliceCacheStudioEnt";
import {ActionUpdateEntModuleMap} from "../../../cache/studio/ent/TypesCacheStudioEnt";
import {TypeDtoEntDataSetKey} from "../../../cache/studio/ent/TypesCacheStudioEnt";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";
import SrvcStudioEntActions from "./SrvcStudioEntActions";
import SrvcStudioEntAdmins from "./SrvcStudioEntAdmins";
import SrvcStudioEntAutomations from "./SrvcStudioEntAutomations";
import SrvcStudioEntDeeplinks from "./SrvcStudioEntDeeplinks";
import SrvcStudioEntDeploy from "./SrvcStudioEntDeploy";
import SrvcStudioEntDriveSheet from "./SrvcStudioEntDriveSheet";
import SrvcStudioEntForms from "./SrvcStudioEntForms";
import SrvcStudioEntGroups from "./SrvcStudioEntGroups";
import SrvcStudioEntPlugins from "./SrvcStudioEntPlugins";
import SrvcStudioEntPrompts from "./SrvcStudioEntPrompt";
import SrvcStudioEntReports from "./SrvcStudioEntReports";
import SrvcStudioEntRoles from "./SrvcStudioEntRoles";
import SrvcStudioEntSpreadsheets from "./SrvcStudioEntSpreadsheets";
import SrvcStudioEntTranslations from "./SrvcStudioEntTranslations";
import SrvcStudioEntUsers from "./SrvcStudioEntUsers";
import SrvcStudioEntVariables from "./SrvcStudioEntVariables";

export interface IUpdateEntDetails
{
  entId: EntId,
  about?: string,
  storeAbout?: string,
  avatarId?: MediaIdAvatar,
  languageSet?: LanguageKey[],
  name?: string,
  setupMode?: EnumDefnSetupKind,
  storeLabelSet?: EnumStoreLabel[],
  storeItemType?: EnumDefnStoreItem;
  timeZone?: TimeZoneKey,
  displayDateFormat?: string
}

export default class SrvcStudioEnt extends ISrvc
{
  public readonly actions = new SrvcStudioEntActions();
  public readonly admins = new SrvcStudioEntAdmins();
  public readonly automations = new SrvcStudioEntAutomations();
  public readonly deploy = new SrvcStudioEntDeploy();
  public readonly driveSheet = new SrvcStudioEntDriveSheet();
  public readonly forms = new SrvcStudioEntForms();
  public readonly groups = new SrvcStudioEntGroups();
  public readonly imports = new SrvcStudioEntPlugins();
  public readonly reports = new SrvcStudioEntReports();
  public readonly roles = new SrvcStudioEntRoles();
  public readonly spreadsheets = new SrvcStudioEntSpreadsheets();
  public readonly variables = new SrvcStudioEntVariables();
  public readonly translations = new SrvcStudioEntTranslations();
  public readonly users = new SrvcStudioEntUsers();
  public readonly prompts = new SrvcStudioEntPrompts();
  public readonly deeplinks = new SrvcStudioEntDeeplinks();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.actions,
      this.admins,
      this.automations,
      this.deploy,
      this.driveSheet,
      this.forms,
      this.groups,
      this.imports,
      this.reports,
      this.roles,
      this.spreadsheets,
      this.variables,
      this.translations,
      this.users,
      this.prompts,
      this.deeplinks
    );
  }

  //region RPC

  rpcEntGet(entId: EntId, replace?: boolean, onSuccess?: (ent: StudioEnt) => void)
  {
    Srvc.cache.studio.ent.rpcStudioEntGet(entId, replace, onSuccess);
  }

  rpcStudioEntPartMerge(
    entId: EntId,
    srcPartId: MetaId,
    tgtPartId: MetaId,
    cbSuccess?: CbSuccess)
  {
    Srvc.cache.studio.ent.rpcStudioEntPartMerge(entId, srcPartId, tgtPartId, cbSuccess);
  }

  rpcEntPut(entId: EntId)
  {
    const srvcCacheStudioEnt = Srvc.cache.studio.ent;
    srvcCacheStudioEnt.setIsSavingEnt(entId, true);
    const rootState = store.getState();
    const sigEnt = rootState.cache.studio.ent.entMap[entId];
    const msg = {
      studioEnt: {
        ...sigEnt.ent
      }
    } as MsgStudioEnt;
    srvcCacheStudioEnt.setEntDirty(entId, false);

    RpcStudioMain.studioEntPut(entId, msg, envSig =>
    {
      srvcCacheStudioEnt.setIsSavingEnt(entId, false);
      if(envSig.error)
      {
        const notChanged = envSig.error.validationErrors?.find(error => error.errorCode === "notChanged");
        if(notChanged)
        {
          return;
        }
        srvcCacheStudioEnt.setEntDirty(entId, true);
        const errorMsg = getErrorMessage(envSig.error) || getValidationErrorMessages(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
      }
    });
  }

  rpcEntAdminEditLockDetailGet(entId: EntId, version?: string, cbResult?: (sig: SigAdminEditLockDetail) => void)
  {
    const msg = {
      version
    } as MsgVersion;

    RpcStudioMain.entAdminEditLockDetailGet(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
      }
      else if(envSig.sig)
      {
        store.dispatch(
          setEntEditLockDetail({
            entId: entId,
            detail: envSig.sig
          })
        );

        cbResult && cbResult(envSig.sig);
      }
      return;
    });
  }

  //endregion

  setSetupMode(entId: EntId, setupMode: EnumDefnSetupKind)
  {
    store.dispatch(setSetupMode({
      entId,
      setupMode
    }));
  }

  updateEntDetails(updateDetails: IUpdateEntDetails)
  {
    Srvc.cache.studio.ent.about.updateEntDetails(updateDetails);
  }

  updateEntModules(moduleMap: ActionUpdateEntModuleMap)
  {
    Srvc.cache.studio.ent.about.updateEntModules(moduleMap);
  }

  moveItem(entId: EntId, metaId: MetaId, moveDirection: EnumListItemDirection, setKey: TypeDtoEntDataSetKey)
  {
    Srvc.cache.studio.ent.moveItem(entId, metaId, moveDirection, setKey);
  }

  moveItemIndex(entId: EntId, metaId: MetaId, setKey: TypeDtoEntDataSetKey, startIndex: number, endIndex: number)
  {
    Srvc.cache.studio.ent.moveItemIndex(entId, metaId, setKey, startIndex, endIndex);
  }

  sortList(entId: EntId, setKey: TypeDtoEntDataSetKey, sortType: TypeListSortType)
  {
    Srvc.cache.studio.ent.sortList(entId, setKey, sortType);
  }

  filterListByTag(entId: EntId, modules?: StudioModuleSelection)
  {
    const rootState = store.getState();
    const entSelectedModules = rootState.cache.studio.ent.selectedModuleMap[entId];

    return fnDeltaItemModules(entSelectedModules, modules);
  }

  getNewMsgEnt(
    adminId: AdminId,
    entId: EntId,
    about: StudioEntDetails
  ): MsgStudioEntCreate
  {
    return {
      adminId: adminId,
      studioEnt: {
        ...this.getStudioVersion(adminId),
        entId: entId,
        details: about,
        varMap: this.getEmptyEntMap(),
        translationMap: this.getEmptyEntMap(),
        actionMap: this.getEmptyEntMap(),
        automationMap: this.getEmptyEntMap(),
        driveSheetMap: this.getEmptyEntMap(),
        formMap: this.getEmptyEntMap(),
        moduleMap: this.getEmptyEntMap(),
        groupMap: this.getEmptyEntMap(),
        pluginMap: this.getEmptyEntMap(),
        promptMap: this.getEmptyEntMap(),
        roleMap: this.getEmptyEntMap(),
        reportMap: this.getEmptyEntMap(),
        deeplinkMap: this.getEmptyEntMap(),
        spreadsheetMap: this.getEmptyEntMap(),
        deployVarMap: this.getEmptyEntMap(),
        deployPluginMap: this.getEmptyEntMap(),
        deployPaymentProviderMap: this.getEmptyEntMap()
      }
    } as MsgStudioEntCreate;
  }

  private getEmptyEntMap()
  {
    return {
      keys: [] as MetaId[],
      map: {}
    };
  }

  private getStudioVersion(adminId: AdminId)
  {
    return {
      version: newGuid(),
      lastUpdateTime: isoDateTimeNow(),
      createdBy: adminId,
      creationTime: isoDateTimeNow(),
      lastUpdateBy: adminId
    };
  }
}
