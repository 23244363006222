import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickGroupId} from "../../../../api/meta/base/dto/DefnStudioPickGroupId";
import {StudioEntGroupMap} from "../../../../api/meta/base/dto/StudioEntGroupMap";
import {MetaIdGroup} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

type TypeFieldValue = MetaIdGroup | MetaIdGroup[] | undefined

export default function FieldRawStudioPickGroupId(props: {
  defn: DefnStudioPickGroupId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean,
})
{
  const fieldProps = props.fieldProps;

  const formCtx = useFormCtx();

  const formStore = formCtx.getStore();
  const groupMap = formStore?.groupMap;

  const fieldValue = fieldProps.field.value as TypeFieldValue;

  const dtoOptions = useMemo(() => getOption(
    groupMap
  ), [fieldValue, formStore, groupMap]);

  const [options] = useInsertTrashOptions({
    type: "groupMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getOption(groupMap?: StudioEntGroupMap): DefnDtoOption[]
{
  if(!groupMap)
  {
    return [];
  }
  const options: DefnDtoOption[] = [];
  groupMap.keys.forEach(groupId =>
  {
    const group = groupMap.map[groupId];
    options.push({
      metaId: group.metaId,
      value: group.details.name || group.metaId
    });
  });
  return options;
}
