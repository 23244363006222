import {AxiosRequestConfig} from "axios";
import axios from "axios";
import {nextRequestId} from "../../api/meta/base/ApiPlus";
import {ISig} from "../../api/meta/base/sig/ISig";
import {EntUserId} from "../../api/meta/base/Types";
import {RequestId} from "../../api/meta/base/Types";
import {ServiceName} from "../../api/meta/base/Types";
import {MediaId} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {EnvError} from "../../api/nucleus/base/dto/EnvError";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {IMediaCall} from "../../api/nucleus/base/IMediaCall";
import {ISigAcceptor} from "../../api/nucleus/base/ISigAcceptor";
import {EnumEnvErrorCode} from "../../api/nucleus/base/Types";
import ISrvcAuth from "../ISrvcAuth";
import LocalCookie from "../local/LocalCookie";
import {hostPortProvider} from "../plus/HostPortPlus";
import {getHttpProtocol} from "../plus/SysPlus";
import {logError} from "../util/AppLog";
import {LOG_RECV} from "../util/AppLog";
import {isInfoLog} from "../util/AppLog";
import {logInfo} from "../util/AppLog";
import {LOG_SEND} from "../util/AppLog";

const HEADER_AUTHORIZATION = "Authorization";
const HEADER_REQUEST_ID = "X-Request-Id";
const MAX_UPLOAD_CONTENT_LENGTH = 8 * 1024 * 1024;

const UPLOAD_INSTANCE = axios.create({
  timeout: 60000,
  headers: {
    "cache-control": "private, no-cache, no-store",
    "pragma": "no-cache",
    "content-type": "application/octet-stream"
  },
  maxContentLength: MAX_UPLOAD_CONTENT_LENGTH,
  maxBodyLength: MAX_UPLOAD_CONTENT_LENGTH
});

export default class MediaCall implements IMediaCall
{
  private readonly requestId = nextRequestId();

  constructor(
    public readonly srvcAuth: ISrvcAuth,
    public readonly entId: EntId,
    public readonly serviceName: ServiceName,
    public readonly mediaId: MediaId
  )
  {
  }

  upload(blob: Blob, sigAcceptor: ISigAcceptor<ISig>, fileName?: string, entUserId?: EntUserId): IMediaCall
  {
    return this.execute({
      method: "post",
      data: blob,
      params: {
        len: blob.size,
        fileName: fileName,
        entUserId: entUserId
      }
    } as AxiosRequestConfig, sigAcceptor);
  }

  execute(config: AxiosRequestConfig, sigAcceptor: ISigAcceptor<ISig>): IMediaCall
  {
    config.headers = {};
    config.headers[HEADER_REQUEST_ID] = this.requestId;

    const bearerToken = this.srvcAuth.getBearerToken();
    if(bearerToken === undefined)
    {
      const envSig = createErrorSignal(this.requestId, "unauthorizedBearerToken");
      sigAcceptor(envSig);
      return this;
    }

    config.headers[HEADER_AUTHORIZATION] = bearerToken;

    const hostPort = hostPortProvider.getHostPort();
    config.baseURL = `${getHttpProtocol()}://${hostPort}`;

    config.url = `${(this.entId)}/${this.serviceName}/${(this.mediaId)}`;

    const method = config.method;
    if(isInfoLog())
    {
      const blob = config.data as Blob;
      logInfo("Upload", `${LOG_SEND} /${method}/${config.url}, blob = ${blob.size} bytes`);
    }

    UPLOAD_INSTANCE(config)
    .then(response =>
    {
      let envSig: EnvSignal<ISig>;

      const responseData = response.data;
      if(responseData === undefined)
      {
        envSig = createErrorSignal(this.requestId, "networkError");
      }
      else
      {
        envSig = createSuccessSignal(this.requestId);
      }

      //envSig.httpStatus = response.status;
      //envSig.httpStatusText = response.statusText;

      const refreshToken = envSig.cookieValue;
      if(refreshToken !== undefined)
      {
        LocalCookie.set(refreshToken, Boolean(envSig.cookieRememberMe));
      }

      if(isInfoLog())
      {
        logInfo("Upload", `${LOG_RECV} /${method}/${config.url}, httpStatus = ${response.status}`);
      }

      sigAcceptor(envSig);
    })
    .catch(error =>
    {
      logError("MediaCall", error);

      if(error.response)
      {
        let envSig = error.response.data as EnvSignal<ISig>;
        if(envSig === undefined)
        {
          envSig = createErrorSignal(this.requestId, "networkError");
        }

        //envSig.httpStatus = error.response.status;
        //envSig.httpStatusText = error.response.statusText;

        if(envSig.error)
        {
          logInfo("MediaCall", `!! /${method}/${config.url}, errSig = ${JSON.stringify(envSig.error)}`);
        }
        else
        {
          logInfo("MediaCall", `!! /${method}/${config.url}, envSig = ${JSON.stringify(envSig)}`);
        }

        sigAcceptor(envSig);
      }
      else
      {
        const envSig = createErrorSignal(this.requestId, "networkError");

        if(isInfoLog())
        {
          logInfo("MediaCall", `!! /${method}/${config.url}, NetworkError, ${error}`);
        }

        sigAcceptor(envSig);
      }
    });

    return this;
  }
}

function createErrorSignal(requestId: RequestId, errorCode: EnumEnvErrorCode): EnvSignal<ISig>
{
  return {
    requestId: requestId,
    error: {errorCode: errorCode} as EnvError
  } as EnvSignal<ISig>;
}

function createSuccessSignal(requestId: RequestId): EnvSignal<ISig>
{
  return {
    requestId: requestId
  } as EnvSignal<ISig>;
}
