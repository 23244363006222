import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldTextSize} from "../../../api/meta/base/dto/DefnFieldTextSize";
import {StudioVarTextSize} from "../../../api/meta/base/dto/StudioVarTextSize";

export function fnVariableBuilderFont(fieldValueFont: string)
{
  function getVarComp()
  {
    return {
      [fieldValueFont]: {
        type: "textSize",
        metaId: fieldValueFont,
        name: "Value",
        required: true
      } as DefnFieldTextSize
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarTextSize
  )
  {
    valueMap[fieldValueFont] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueFont];

    return {
      value: value
    } as StudioVarTextSize;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
