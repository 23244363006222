import {useRef} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldSymbol} from "../../api/meta/base/dto/DefnFieldSymbol";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioPickFormId} from "../../api/meta/base/dto/DefnStudioPickFormId";
import {StudioEntPipelineVar} from "../../api/meta/base/dto/StudioEntPipelineVar";
import {MetaIdField} from "../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldName = "name";
const fieldFormId = "formId";

export default function DialogMapOfPipelineVariable(props: {
  formStore?: FormStore,
  isFormReadOnly?: boolean,
  values?: StudioEntPipelineVar,
  onClickOk: (values: StudioEntPipelineVar) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{
  const values = props.values;
  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} pipeline variable`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(),
        store: props.formStore,
        formReadonly: props.isFormReadOnly,
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: values ? {...dtoToValue(values)} : undefined
      }}
      onClose={props.onClose}
      addMoreCheckBoxLabel={values ? undefined : "Add more pipeline variable"}
    />
  );
}

function valueToDto(values: FieldValues): StudioEntPipelineVar
{
  return {
    name: fnFieldValueToRawValue("symbol", values[fieldName]),
    formId: fnFieldValueToRawValue("pickFormId", values[fieldFormId])
  } as StudioEntPipelineVar;
}

function dtoToValue(dto?: StudioEntPipelineVar)
{
  if(dto)
  {
    return {
      [fieldName]: fnRawValueToFieldValue("symbol", dto.name),
      [fieldFormId]: fnRawValueToFieldValue("pickFormId", dto.formId)
    };
  }
}

function getDefnForm()
{
  return createDefaultDefnFormStudio({
    [fieldName]: {
      type: "symbol",
      name: fieldName,
      metaId: fieldName,
      label: "Name",
      required: true
    } as DefnFieldSymbol,
    [fieldFormId]: {
      type: "pickFormId",
      name: fieldFormId,
      metaId: fieldFormId,
      label: "Form",
      required: true
    } as DefnStudioPickFormId,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [fieldName, fieldFormId]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}


