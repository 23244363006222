import {FieldValues} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldParagraph} from "../../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioPickLayoutFormContentId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutFormContentId";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutFormFooter} from "../../../../api/meta/base/dto/StudioDtoLayoutFormFooter";
import {StudioDtoLayoutFormHeader} from "../../../../api/meta/base/dto/StudioDtoLayoutFormHeader";
import {StudioDtoLayoutFormTemplate} from "../../../../api/meta/base/dto/StudioDtoLayoutFormTemplate";
import {StudioDtoLayoutFormWatermark} from "../../../../api/meta/base/dto/StudioDtoLayoutFormWatermark";
import {StudioVarMap} from "../../../../api/meta/base/dto/StudioVarMap";
import {Position} from "../../../../api/meta/base/StudioSetsFieldType";
import {WatermarkPosition} from "../../../../api/meta/base/StudioSetsFieldType";
import {EnumDefnRenderingKind} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getCombinedFieldId} from "../../../../base/plus/FormPlus";
import {getExcludeVarIdSet} from "../../../../base/plus/FormPlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {fieldGap5} from "./TypesFormBuilder";
import {fieldGap1} from "./TypesFormBuilder";
import {fieldTabDetails} from "./TypesFormBuilder";
import {fieldKeyDescription} from "./TypesFormBuilder";
import {fieldKeyName} from "./TypesFormBuilder";
import {fieldGap4} from "./TypesFormBuilder";
import {fieldGap3} from "./TypesFormBuilder";
import {getFieldGap} from "./TypesFormBuilder";

type TypeLayoutForm = "commonHeader" | "commonFooter" | "commonWatermark";

interface LayoutVariables
{
  fieldMetaId: string;
  fieldLabel: string;
  varKind?: EnumStudioVarKind;
  filterOptionSet?: EnumDefnPlacement[];
  excludeVarIdSet?: MetaIdVar[];
}

export function fnGetFormBuilderFormTemplateLayoutBuilder()
{
  return {
    getDefn,
    dtoToValues,
    valueToDto
  };
}

function getDefn(
  metaIdForm?: MetaIdForm,
  varMap?: StudioVarMap)
{
  return createDefaultDefnFormStudio({
    ...getFormLayoutDetails(),
    ...getFormLayoutHeaderTab(metaIdForm, varMap),
    ...getFormLayoutFooterTab(metaIdForm),
    ...getFormLayoutWatermarkTab(metaIdForm, varMap),

    [defaultSectionKey]: {
      type: "tab",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      tabVariant: "fullWidth",
      tabIdSet: [
        fieldTabDetails,
        fieldHeaderSection,
        fieldFooterSection,
        fieldWatermarkSection
      ]
    } as DefnTab
  } as Record<MetaIdField, DefnField>);
}

function getFormLayoutDetails()
{
  return {
    [fieldKeyName]: {
      type: "symbol",
      name: fieldKeyName,
      metaId: fieldKeyName,
      label: "Name",
      required: true
    } as DefnFieldText,

    [fieldKeyDescription]: {
      type: "paragraph",
      name: fieldKeyDescription,
      metaId: fieldKeyDescription,
      label: "Description"
    } as DefnFieldParagraph,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldPaperSize]: {
      type: "enumRenderingKind",
      metaId: fieldPaperSize,
      name: fieldPaperSize,
      label: "Paper size"
    } as DefnFieldPickEnum,

    [fieldTabDetails]: {
      type: "section",
      metaId: fieldTabDetails,
      name: "Details",
      fieldIdSet: [fieldKeyName, fieldKeyDescription, fieldGap1, fieldPaperSize]
    } as DefnSection
  };
}

function getFormLayoutHeaderTab(formId?: MetaIdForm, varMap?: StudioVarMap)
{
  const sectionId = header;
  const headerFontVariable = {
    fieldMetaId: fieldHeaderFontVariable,
    fieldLabel: "Form name font variable"
  } as LayoutVariables;
  const headerPatternVariable = {
    fieldMetaId: fieldHeaderPatternVariable,
    fieldLabel: "Form name pattern variable"
  } as LayoutVariables;
  const headerOpacityVariable = {
    fieldMetaId: fieldHeaderOpacityVariable,
    fieldLabel: "Form name opacity variable"
  } as LayoutVariables;
  const headerPositionVariable = {
    fieldMetaId: fieldHeaderPositionVariable,
    fieldLabel: "Form name position variable",
    filterOptionSet: Position,
    excludeVarIdSet: getExcludeVarIdSet(Position as EnumDefnPlacement[], varMap)
  } as LayoutVariables;

  return {
    [fieldHeaderShowSeparator]: {
      type: "bool",
      name: fieldHeaderShowSeparator,
      metaId: fieldHeaderShowSeparator,
      showAsCheckboxVar: true,
      label: "Show separator"
    } as DefnFieldSwitch,
    [fieldHeaderBackgroundColorVariable]: {
      type: "pickVarId",
      name: fieldHeaderBackgroundColorVariable,
      metaId: fieldHeaderBackgroundColorVariable,
      label: "Background color variable",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [fieldHeaderForegroundColorVariable]: {
      type: "pickVarId",
      name: fieldHeaderForegroundColorVariable,
      metaId: fieldHeaderForegroundColorVariable,
      label: "Foreground color variable",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    },

    ...getFieldGap(fieldGap3, "thick"),

    [fieldShowEnterprise]: {
      type: "bool",
      name: fieldShowEnterprise,
      metaId: fieldShowEnterprise,
      showAsCheckboxVar: true,
      label: "Show enterprise"
    } as DefnFieldSwitch,

    [fieldHyperLinkVariable]: {
      type: "studioSetOfVarId",
      metaId: fieldHyperLinkVariable,
      showAsEdit: true,
      formId: formId,
      label: "Hyperlinks variable",
      varKind: "hyperlink"
    } as DefnStudioPickVarId,

    ...getFieldGap(fieldGap4, "thick"),

    ...getLayoutVariables(
      sectionId,
      headerFontVariable,
      headerPatternVariable,
      headerOpacityVariable,
      headerPositionVariable,
      formId
    ),

    [fieldHeaderShowSeparatorSection]: {
      type: "section",
      name: fieldHeaderShowSeparatorSection,
      metaId: fieldHeaderShowSeparatorSection,
      fieldIdSet: [fieldHeaderShowSeparator]
    } as DefnSection,

    ...getFieldGap(fieldGap5, "thick"),

    [fieldHeaderContentLayout]: {
      type: "pickLayoutFormContentId",
      name: fieldHeaderContentLayout,
      label: "Content layout",
      metaId: fieldHeaderContentLayout,
      formId: formId,
      filterKindSet: ["content"]
    } as DefnStudioPickLayoutFormContentId,

    [fieldHeaderSection]: {
      type: "section",
      metaId: fieldHeaderSection,
      name: "Header",
      fieldIdSet: [
        fieldShowEnterprise,
        fieldGap3,
        fieldHyperLinkVariable,
        sectionId,
        fieldHeaderBackgroundColorVariable,
        fieldHeaderForegroundColorVariable,
        fieldGap4,
        fieldHeaderContentLayout,
        fieldGap5,
        fieldHeaderShowSeparatorSection
      ]
    } as DefnSection
  };
}

function getFormLayoutFooterTab(formId?: MetaIdForm)
{
  return {

    [fieldFooterShowSeparator]: {
      type: "bool",
      name: fieldFooterShowSeparator,
      metaId: fieldFooterShowSeparator,
      showAsCheckboxVar: true,
      label: "Show separator"
    } as DefnFieldSwitch,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldFooterContentLayout]: {
      type: "pickLayoutFormContentId",
      name: fieldFooterContentLayout,
      label: "Content layout",
      metaId: fieldFooterContentLayout,
      formId: formId,
      filterKindSet: ["content"]
    } as DefnStudioPickLayoutFormContentId,

    [fieldFooterSection]: {
      type: "section",
      metaId: fieldFooterSection,
      name: "Footer",
      fieldIdSet: [
        fieldFooterShowSeparator,
        fieldGap1,
        fieldFooterContentLayout
      ]
    } as DefnSection
  };
}

function getFormLayoutWatermarkTab(formId?: MetaIdForm, varMap?: StudioVarMap)
{
  const sectionId = waterMark;
  const watermarkFontVariable = {
    fieldMetaId: fieldWatermarkFontVariable,
    fieldLabel: textFontLabel
  } as LayoutVariables;
  const watermarkPatternVariable = {
    fieldMetaId: fieldWatermarkPatternVariable,
    fieldLabel: textPatternLabel
  } as LayoutVariables;
  const watermarkPositionVariable = {
    fieldMetaId: fieldTextPositionVariable,
    fieldLabel: "Text position variable",
    varKind: "placement",
    filterOptionSet: WatermarkPosition,
    excludeVarIdSet: getExcludeVarIdSet(WatermarkPosition as EnumDefnPlacement[], varMap)
  } as LayoutVariables;
  const watermarkOpacityVariable = {
    fieldMetaId: fieldWatermarkOpacityVariable,
    fieldLabel: textOpacityLabel
  } as LayoutVariables;

  return {
    ...getLayoutVariables(
      sectionId,
      watermarkFontVariable,
      watermarkPatternVariable,
      watermarkOpacityVariable,
      watermarkPositionVariable,
      formId
    ),
    [fieldWatermarkSection]: {
      type: "section",
      metaId: fieldWatermarkSection,
      name: "Watermark",
      fieldIdSet: [sectionId]
    } as DefnSection
  };
}

function getLayoutVariables(
  sectionId: string,
  fieldFont: LayoutVariables,
  fieldPattern: LayoutVariables,
  fieldOpacity: LayoutVariables,
  fieldPosition?: LayoutVariables,
  formId?: MetaIdForm,
  fieldLabel?: LayoutVariables)
{
  return {
    ...fieldLabel && {
      [fieldLabel.fieldMetaId]: {
        type: "label",
        metaId: fieldLabel.fieldMetaId,
        label: fieldLabel.fieldLabel,
        disabled: true
      } as DefnFieldLabel
    },

    [fieldPattern.fieldMetaId]: {
      type: "studioVarIdTextEditor",
      metaId: fieldPattern.fieldMetaId,
      label: fieldPattern.fieldLabel,
      argBinderFormId: formId,
      showAsEdit: true,
      filterVarKindSet: ["text"]
    } as DefnStudioVarIdTextEditor,
    [fieldFont.fieldMetaId]: {
      type: "pickVarId",
      metaId: fieldFont.fieldMetaId,
      label: fieldFont.fieldLabel,
      varKind: "textSize",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,
    ...fieldPosition && {
      [fieldPosition.fieldMetaId]: {
        type: "pickVarId",
        metaId: fieldPosition?.fieldMetaId,
        label: fieldPosition?.fieldLabel,
        varKind: fieldPosition?.varKind ?? "placement",
        showAsEdit: true,
        formId: formId,
        filterOptionSet: fieldPosition?.filterOptionSet,
        excludeVarIdSet: fieldPosition?.excludeVarIdSet
      } as DefnStudioPickVarId
    },
    [fieldOpacity.fieldMetaId]: {
      type: "pickVarId",
      metaId: fieldOpacity.fieldMetaId,
      label: fieldOpacity.fieldLabel,
      varKind: "decimal",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [sectionId]: {
      type: "section",
      metaId: sectionId,
      name: sectionId,
      fieldIdSet: [
        ...fieldLabel
          ? [fieldLabel.fieldMetaId]
          : [],
        fieldPattern.fieldMetaId,
        fieldFont.fieldMetaId,
        ...fieldPosition
          ? [fieldPosition.fieldMetaId]
          : [],
        fieldOpacity.fieldMetaId
      ]
    } as DefnSection

  };
}

function dtoToValues(dto: StudioDtoLayoutFormTemplate): FieldValues
{
  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", dto.name),
    [fieldKeyDescription]: fnRawValueToFieldValue("paragraph", dto.description),
    [fieldPaperSize]: fnRawValueToFieldValue("enumRenderingKind", dto.paperSize
      ? getPaperSizeDtoToValue(dto.paperSize)
      : undefined
    ),

    [fieldShowEnterprise]: fnRawValueToFieldValue("bool", dto.commonHeader?.showEnterprise),
    [fieldHyperLinkVariable]: fnRawValueToFieldValue("studioSetOfVarId", dto.commonHeader?.hyperlinkVarIdSet),
    [fieldHeaderShowSeparator]: fnRawValueToFieldValue("bool", dto.commonHeader?.showSeparator),
    [fieldHeaderFontVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.formNameTextSizeVarId),
    [fieldHeaderPatternVariable]: fnRawValueToFieldValue("studioVarIdTextEditor",
      dto.commonHeader?.formNamePatternVarId
    ),
    [fieldHeaderBackgroundColorVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.backgroundColorVarId),
    [fieldHeaderForegroundColorVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.foregroundColorVarId),
    [fieldHeaderOpacityVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.formNameOpacityVarId),
    [fieldHeaderPositionVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.formNamePositionVarId),
    [fieldHeaderContentLayout]: fnRawValueToFieldValue("pickLayoutFormContentId", dto.commonHeader?.formLayoutId),

    [fieldWatermarkFontVariable]: fnRawValueToFieldValue("pickVarId", dto.commonWatermark?.textSizeVarId),
    [fieldWatermarkPatternVariable]: fnRawValueToFieldValue("studioVarIdTextEditor",
      dto.commonWatermark?.textPatternVarId
    ),
    [fieldWatermarkOpacityVariable]: fnRawValueToFieldValue("pickVarId", dto.commonWatermark?.textOpacityVarId),
    [fieldTextPositionVariable]: fnRawValueToFieldValue("pickVarId",
      dto.commonWatermark?.textPositionVarId
    ),

    [fieldFooterContentLayout]: fnRawValueToFieldValue("pickLayoutFormContentId", dto.commonFooter?.contentLayoutId),
    [fieldFooterShowSeparator]: fnRawValueToFieldValue("bool", dto.commonFooter?.showSeparator)
  };
}

function valueToDto(
  values: FieldValues,
  layout?: StudioDtoLayoutFormTemplate
): StudioDtoLayoutFormTemplate
{
  const commonHeader = {
    ...layout?.commonHeader,
    formNameTextSizeVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderFontVariable]),
    formNameOpacityVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderOpacityVariable]),
    formNamePatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[fieldHeaderPatternVariable]),
    formNamePositionVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderPositionVariable]),
    hyperlinkVarIdSet: fnFieldValueToRawValue("pickVarId", values[fieldHyperLinkVariable]),
    showEnterprise: fnFieldValueToRawValue("bool", values[fieldShowEnterprise]),
    showSeparator: fnFieldValueToRawValue("bool", values[fieldHeaderShowSeparator]),
    backgroundColorVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderBackgroundColorVariable]),
    foregroundColorVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderForegroundColorVariable]),
    formLayoutId: fnFieldValueToRawValue("pickLayoutFormContentId", values[fieldHeaderContentLayout])
  } as StudioDtoLayoutFormHeader;

  const commonWatermark = {
    ...layout?.commonWatermark,
    textSizeVarId: fnFieldValueToRawValue("pickVarId", values[fieldWatermarkFontVariable]),
    textOpacityVarId: fnFieldValueToRawValue("pickVarId", values[fieldWatermarkOpacityVariable]),
    textPatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[fieldWatermarkPatternVariable]),
    textPositionVarId: fnFieldValueToRawValue("pickVarId", values[fieldTextPositionVariable])
  } as StudioDtoLayoutFormWatermark;

  const commonFooter = {
    ...layout?.commonFooter,
    contentLayoutId: fnFieldValueToRawValue("pickLayoutFormContentId", values[fieldFooterContentLayout]),
    showSeparator: fnFieldValueToRawValue("bool", values[fieldFooterShowSeparator])
  } as StudioDtoLayoutFormFooter;

  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    description: fnFieldValueToRawValue("paragraph", values[fieldKeyDescription]),
    paperSize: fnFieldValueToRawValue("enumRenderingKind", getPaperSizeValueToDto(values[fieldPaperSize])),
    commonFooter: commonFooter,
    commonHeader: commonHeader,
    commonWatermark: commonWatermark,
    type: "template"
  } as StudioDtoLayoutFormTemplate;
}

//layout type
const header = "commonHeader" as TypeLayoutForm;
const waterMark = "commonWatermark" as TypeLayoutForm;
const footer = "commonFooter" as TypeLayoutForm;

const fieldPaperSize = "paperSize";

//Tab Header
const fieldShowEnterprise = "showEnterprise";
const fieldHyperLinkVariable = "hyperlinkVarIdSet";
const fieldHeaderShowSeparatorSection = "fieldHeaderShowSeparatorSection";
const fieldHeaderShowSeparator = "showSeparatorHeader";
const fieldHeaderBackgroundColorVariable = getCombinedFieldId([header, "backgroundColorVarId"]);
const fieldHeaderForegroundColorVariable = getCombinedFieldId([header, "foregroundColorVarId"]);

const fieldHeaderFontVariable = "formNameFontVarId";
const fieldHeaderPatternVariable = "formNamePatternVarId";
const fieldHeaderOpacityVariable = "formNameOpacityVarId";
const fieldHeaderPositionVariable = "formNamePositionVarId";

const fieldHeaderSection = "formLayoutHeaderSection";
const fieldHeaderContentLayout = "headerContentLayout";

//Tab Footer
const fieldFooterSection = getCombinedFieldId([footer, "Section"]);
const fieldFooterContentLayout = "footerContentLayout";
const fieldFooterShowSeparator = "showSeparatorFooter";

//Tab WaterMark
const fieldWatermarkSection = "formLayoutWatermarkSection";

const fieldWatermarkFontVariable = getCombinedFieldId([waterMark, "textFontVarId"]);
const fieldWatermarkPatternVariable = getCombinedFieldId([waterMark, "textPatternVarId"]);
const fieldWatermarkOpacityVariable = getCombinedFieldId([waterMark, "textOpacityVarId"]);
const fieldTextPositionVariable = getCombinedFieldId([waterMark, "textPositionVarId"]);

//common label
const textFontLabel = "Text font variable";
const textPatternLabel = "Text pattern variable";
const textOpacityLabel = "Text opacity variable";

export function getPaperSizeDtoToValue(item: EnumDefnRenderingKind): string
{
  switch(item)
  {
    case "mm_48":
      return "48 mm";
    case "mm_104":
      return "104 mm";
    case "mm_72":
      return "72 mm";
    case "mm_80":
      return "80 mm";
    case "mm_58":
      return "58 mm";
    default:
      return item;
  }
}

export function getPaperSizeValueToDto(item: string): EnumDefnRenderingKind
{
  switch(item)
  {
    case "48 mm":
      return "mm_48";
    case "104 mm":
      return "mm_104";
    case "72 mm":
      return "mm_72";
    case "80 mm":
      return "mm_80";
    case "58 mm":
      return "mm_58";
    default:
      return item as EnumDefnRenderingKind;
  }
}


