import {FieldValues} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {StudioFieldVoice} from "../../../../../api/meta/base/dto/StudioFieldVoice";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyShowCapturedValuesOnAside} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyMaxSize} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeFieldId} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyMaxSizeVar} from "../../base/TypesFormBuilder";
import {propKeyCaptureTime} from "../../base/TypesFormBuilder";
import {propKeyCaptureUser} from "../../base/TypesFormBuilder";
import {propKeyCaptureLocation} from "../../base/TypesFormBuilder";

export function getDefnFieldVoice(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId,
    fieldGap2,
    propKeyMaxSizeFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    fieldGap2,
    propKeyMaxSizeVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    fieldGap2,
    propKeyCaptureLocation,
    propKeyCaptureTime,
    propKeyCaptureUser,
    fieldGap3,
    propKeyMaxSize,
    propKeyShowCapturedValuesOnAside
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "voice",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyCaptureLocation]: {
      type: "bool",
      name: propKeyCaptureLocation,
      metaId: propKeyCaptureLocation,
      label: "Capture location",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureTime]: {
      type: "bool",
      name: propKeyCaptureTime,
      metaId: propKeyCaptureTime,
      label: "Capture time",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureUser]: {
      type: "bool",
      name: propKeyCaptureUser,
      metaId: propKeyCaptureUser,
      label: "Capture user",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowCapturedValuesOnAside]: {
      type: "enumCaptureValueKind",
      name: propKeyShowCapturedValuesOnAside,
      metaId: propKeyShowCapturedValuesOnAside,
      label: "Show captured values on aside"
    } as DefnFieldPickEnum
  };
}

export function defnValueToStudioFieldVoice(values: FieldValues): StudioFieldVoice
{
  return {
    type: "voice",
    maxSize: fnFieldValueToRawValue("number", values[propKeyMaxSize]),
    maxSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyMaxSizeVar]),
    maxSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyMaxSizeFieldId]),
    captureLocation: fnFieldValueToRawValue("bool", values[propKeyCaptureLocation]),
    captureUser: fnFieldValueToRawValue("bool", values[propKeyCaptureUser]),
    captureTime: fnFieldValueToRawValue("bool", values[propKeyCaptureTime]),
    showCapturedValuesOnAside: fnFieldValueToRawValue("enumCaptureValueKind", values[propKeyShowCapturedValuesOnAside])
  } as StudioFieldVoice;
}

export function studioFieldVoiceToDefnValue(studioField: StudioFieldVoice): FieldValues
{
  return {
    [propKeyMaxSize]: fnRawValueToFieldValue("number", studioField.maxSize),
    [propKeyMaxSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.maxSizeVarId),
    [propKeyMaxSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.maxSizeFieldId),
    [propKeyCaptureLocation]: fnRawValueToFieldValue("bool", studioField.captureLocation),
    [propKeyCaptureUser]: fnRawValueToFieldValue("bool", studioField.captureUser),
    [propKeyCaptureTime]: fnRawValueToFieldValue("bool", studioField.captureTime),
    [propKeyShowCapturedValuesOnAside]: fnRawValueToFieldValue("enumCaptureValueKind",
      studioField.showCapturedValuesOnAside
    )
  };
}
