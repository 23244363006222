import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickRole} from "../../api/meta/base/dto/DefnFieldPickRole";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {EnumDefnPermission} from "../../api/meta/base/Types";
import {MetaIdRole} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {IFormRef} from "../../base/types/TypesForm";
import {IFormFieldError} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {IRolePermission} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export default function DialogNewPermission(props: {
  formStore?: FormStore,
  values?: IRolePermission,
  isFormReadonly?: boolean,
  onClickOk: (values: IRolePermission) => void,
  onClose?: () => void,
  excludeRolesIdSet?: MetaIdRole[],
  filterOptionSet?: EnumDefnPermission[],
  validationError?: IFormFieldError[]
})
{
  const oldValues = props.values;
  const excludeRolesIdSet = props.excludeRolesIdSet;
  const filterOptionSet = props.filterOptionSet;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  return (
    <DialogDefnForm
      formProps={{
        cbRef: cbRef.current,
        defnForm: getDefnForm(excludeRolesIdSet, filterOptionSet),
        formReadonly: props.isFormReadonly,
        store: props.formStore,
        onSubmit: values => props.onClickOk(valueToDto(values)),
        initValues: dtoToValue(oldValues)
      }}
      title={`${oldValues ? "Edit" : "New"} permission`}
      onClose={props.onClose}
    />
  );
}

const compRole = "role";
const compPermission = "permission";

function getDefnForm(
  excludeRolesIdSet?: MetaIdRole[],
  filterOptionSet?: EnumDefnPermission[])
{

  return createDefaultDefnFormStudio({
    [compRole]: {
      type: "pickRole",
      metaId: compRole,
      name: compRole,
      label: "Role",
      allowSystemRoles: true,
      required: true,
      excludeRoleIdSet: excludeRolesIdSet
    } as DefnFieldPickRole,
    [compPermission]: {
      type: "enumPermission",
      metaId: compPermission,
      name: compPermission,
      label: "Permission",
      filterOptionSet: filterOptionSet
    } as DefnFieldPickEnum,
    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: [compRole, compPermission]
    } as DefnSection
  });
}

function valueToDto(values: FieldValues): IRolePermission
{
  return {
    metaIdRole: fnFieldValueToRawValue("pickRole", values[compRole]),
    permission: fnFieldValueToRawValue("enumPermission", values[compPermission])
  } as IRolePermission;
}

function dtoToValue(dto?: IRolePermission): FieldValues
{
  return {
    [compRole]: fnRawValueToFieldValue("pickRole", dto?.metaIdRole),
    [compPermission]: fnRawValueToFieldValue("enumPermission", dto?.permission ?? "read")
  };
}
