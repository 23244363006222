import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {StoreItemId} from "../../api/meta/base/Types";
import {ArtifactId} from "../../api/meta/base/Types";
import {PluginBundleId} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import LocalFilterPickOne from "../../base/local/LocalFilterPickOne";
import {mergeArraySets} from "../../base/plus/JsPlus";

const localApiBrFilter = new LocalFilterPickOne((callerIdHash) => `apiBr_filter_${callerIdHash}`);

export interface IApiBrFilterState
{
  artifactId?: ArtifactId;
}

export const sliceCacheApiBrFilter = createSlice({
  name: "cacheApiBrFilter",
  initialState: {} as IApiBrFilterState,
  reducers: {
    loadApiBrFilter: (state, action: PayloadAction<ActionLoadApiBrFilter>) =>
    {
      const payload = action.payload;
      const callerIdHash = payload.callerIdHash;
      const entIdSet = mergeArraySets(payload.entIdAdminSet, payload.entIdNotAdminSet);
      const pluginSet = payload.entIdPluginSet;
      const storeSet = payload.storeItemIdAdminSet;

      const apiBrFilter = localApiBrFilter.get(callerIdHash);
      state.artifactId = apiBrFilter &&
      (entIdSet.includes(apiBrFilter) || pluginSet.includes(apiBrFilter) || storeSet.includes(apiBrFilter))
        ? apiBrFilter
        : undefined;
    },
    setApiBrFilter: (state, action: PayloadAction<ActionSetApiBrFilter>) =>
    {
      const payload = action.payload;
      const callerIdHash = payload.callerIdHash;
      const artifactId = payload.artifactId;

      if(state.artifactId !== artifactId)
      {
        state.artifactId = artifactId;
        if(artifactId === undefined)
        {
          localApiBrFilter.clear(callerIdHash);
        }
        else
        {
          localApiBrFilter.set(callerIdHash, artifactId);
        }
      }
    }
  }
});

export const {
  loadApiBrFilter,
  setApiBrFilter
} = sliceCacheApiBrFilter.actions;

export type ActionLoadApiBrFilter = {
  callerIdHash: string,
  entIdAdminSet: EntId[],
  entIdNotAdminSet: EntId[],
  entIdPluginSet: PluginBundleId[],
  storeItemIdAdminSet: StoreItemId[]
};

export type ActionSetApiBrFilter = {
  callerIdHash: string,
  artifactId?: ArtifactId,
};
