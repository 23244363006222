import {SigGroupAvatar} from "../../../../api/home/drawer/sig/SigGroupAvatar";
import {GroupId} from "../../../../api/meta/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../../base/plus/MediaPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListBinder} from "../../../../base/types/list/TypesList";
import {IList} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {toComboIdDto} from "../../../../base/types/TypesComboId";
import {TypeComboIdGroup} from "../../../../base/types/TypesComboId";
import {dtoToComboId} from "../../../../base/types/TypesComboId";
import {toComboId} from "../../../../base/types/TypesComboId";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import {RootState} from "../../../../Store";
import {store} from "../../../../Store";
import {Srvc} from "../../../Srvc";

type TypeSigGroupAvatar = SigGroupAvatar | undefined;

export default class SrvcHomeAsideUserInfoCommonGroup extends ISrvc
{
  public readonly subscriberId = "SrvcHomeAsideUserInfoCommonGroup";

  subscribe(entGroupId: TypeComboIdGroup, unsubscribe?: boolean)
  {
    const split = toComboIdDto(entGroupId);
    const entId = split.entId;
    const groupId = split.chatId;
    Srvc.app.pubsub.group.groupAvatar(this.subscriberId, entId, groupId, unsubscribe);
  }

  load(listName: string, entId: EntId, entUserId: EntUserId)
  {
    const itemsById = {} as IListItemsById;
    const itemIds = [] as GroupId[];
    const entComboId = toComboId(entId, entUserId);
    const groupIdList = store.getState().cache.app.user.userCommonGroupListMap[entComboId]?.userCommonGroupList?.groupIdList;

    groupIdList?.forEach((groupId) =>
    {
      const entGroupId = dtoToComboId({
        entId: entId,
        chatId: groupId
      });
      itemIds.push(entGroupId);
    });

    dispatchList(listName, listRefresh({
      itemsById: itemsById,
      itemIds: itemIds
    } as IList));
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectGroupAvatar.bind(this),
      onBindSourceItem1: this.bindGroupAvatar.bind(this)
    } as IListBinder<TypeSigGroupAvatar>;
  }

  private selectGroupAvatar(
    state: RootState,
    entChatId: TypeComboId
  ): TypeSigGroupAvatar
  {
    return state.cache.app.group.groupAvatarMap[entChatId];
  }

  private bindGroupAvatar(
    listName: string,
    entChatId: TypeComboId,
    avatar: TypeSigGroupAvatar
  ): void
  {
    if(avatar)
    {
      const newItem = {
        version: avatar.version,
        type: "aps",
        avatarLeft: {
          src: getImageThumbnail(avatar.avatarId),
          icon: "group"
        },
        primary: {
          text: getLabel(avatar)
        },
        secondary: {
          text: avatar.about
        },
        hideMenu: true
      } as IListItemAPSA;

      dispatchList(listName, listSetItem({
        itemId: entChatId,
        newItem: newItem
      }));
    }
  }
}
