import {StudioComposite} from "../../api/meta/base/dto/StudioComposite";
import {StudioDtoFormula} from "../../api/meta/base/dto/StudioDtoFormula";
import {StudioEntAction} from "../../api/meta/base/dto/StudioEntAction";
import {StudioEntAutomation} from "../../api/meta/base/dto/StudioEntAutomation";
import {StudioEntAutomationEvent} from "../../api/meta/base/dto/StudioEntAutomationEvent";
import {StudioEntAutomationStep} from "../../api/meta/base/dto/StudioEntAutomationStep";
import {StudioEntDeeplink} from "../../api/meta/base/dto/StudioEntDeeplink";
import {StudioEntDriveSheet} from "../../api/meta/base/dto/StudioEntDriveSheet";
import {StudioEntGroup} from "../../api/meta/base/dto/StudioEntGroup";
import {StudioEntImport} from "../../api/meta/base/dto/StudioEntImport";
import {StudioEntPlugin} from "../../api/meta/base/dto/StudioEntPlugin";
import {StudioEntReport} from "../../api/meta/base/dto/StudioEntReport";
import {StudioEntRole} from "../../api/meta/base/dto/StudioEntRole";
import {StudioEntSpreadsheet} from "../../api/meta/base/dto/StudioEntSpreadsheet";
import {StudioEntTranslation} from "../../api/meta/base/dto/StudioEntTranslation";
import {StudioField} from "../../api/meta/base/dto/StudioField";
import {StudioForm} from "../../api/meta/base/dto/StudioForm";
import {StudioVar} from "../../api/meta/base/dto/StudioVar";
import {StudioVisibilityRule} from "../../api/meta/base/dto/StudioVisibilityRule";
import {EnumDefnKindReport, EnumStudioVarKind, MetaId} from "../../api/meta/base/Types";
import {MsgStudioPluginCreate} from "../../api/studio/studioDrawer/msg/MsgStudioPluginCreate";
import {MsgStudioEnt} from "../../api/studio/studioMain/msg/MsgStudioEnt";
import {TypeArtifact} from "./TypesForm";
import {EnumMainPropsStudioJumpStep, EnumMainPropsStudioJumpStepTab} from "./TypesMain";

export const spreadsheetAlias = "s";

export interface IStudioSize
{
  stepperWidth: number,
  mainContentWidth: number
}

export type EnumStudioStepFormItem =
  | "Forms"
  | "FormDetails"
  | "FormFormulas"
  | "FormVisibilityRules"
  | "FormContentLayout";

export type EnumStudioStepAutomationItem =
  | "AutomationEvents"
  | "AutomationsSteps"

export type EnumStudioStepItem =
  | EnumStudioStepFormItem
  | EnumStudioStepAutomationItem

//region ent

export type EnumStudioEntStep =
  | "About"
  | "Plugins"
  | "Roles"
  | "Variables"
  | "Forms"
  | "Spreadsheets"
  | "Reports"
  | "Automations"
  | "Actions"
  | "Prompts"
  | "Groups"
  | "Deeplinks"
  | "DriveSheets"
  | "Translations"
  | "Deploy";

export type EnumStudioEntFooterTab =
  | "Dashboard"
  | "Builder"
  | "Terminal"
  | "Designer"
  | "Admins"

export type EnumProductionEntFooterTab =
  | "Manage"
  | "Debugger"
  | "Admins"
  | "Users";

export type EnumProductionEntManageTab =
  | "overview"
  | "dashboard"
  | "history"
  | "settings"
  | "payment"

export type EnumProductionEntDebuggerTab =
  | "log"
  | "automation"
  | "query"

export type EnumProductionPluginFooterTab =
  | "Manage"
  | "Admins"

export type EnumStudioEntAboutTab =
  | "Details"
  | "Variables"
  | "Translations";

export type EnumStudioEntDeployTab =
  | "Plugins"
  | "Variables"
  | "Payments";

export type EnumStudioEntAside =
  | "Automations"
  | "AutomationEvent"
  | "AutomationStep"
  | "Variables"
  | "Schedulers"
  | "Translations"
  | "Plugin"
  | "Roles"
  | "Groups"
  | "Forms"
  | "FormFormulas"
  | "FormVisibilityRules"
  | "FormContentLayout"
  | "FormPreview"
  | "FormComposite"
  | "Section"
  | "Grid"
  | "Field"
  | "Spreadsheets"
  | "Reports"
  | "Prompts"
  | "Actions"
  | "Plugins"
  | "Deeplinks"
  | "DriveSheets"
  | "Users"
  | "Admins"
  | "storeItemEntAdmin"
  | "DeployVariable"
  | "DeployPlugin"
  | "DeployPaymentProvider"
  | "Payment"
  | "DriveSettings"
  | "PromptSettings"
  | "PaymentConfig";

export interface IDtoEntCopy
{
  type: TypeDtoEntListItem;
  payload: TypeDtoEntDataSetItem;
}

type TypeDtoEntDataSetItem =
  | MsgStudioPluginCreate
  | MsgStudioEnt
  | StudioEntTranslation
  | StudioEntImport
  | StudioEntRole
  | StudioEntGroup
  | StudioEntAction
  | StudioEntSpreadsheet
  | StudioEntReport
  | StudioEntPlugin
  | StudioEntDriveSheet
  | StudioDtoFormula
  | StudioEntDeeplink
  | StudioEntAutomation
  | StudioEntAutomationEvent
  | StudioEntAutomationStep
  | StudioForm
  | StudioComposite
  | StudioField
  | StudioVar
  | StudioVisibilityRule

type TypeDtoEntListItem =
  | "action"
  | "automation"
  | "automationEvent"
  | "automationStep"
  | "drive"
  | "driveSheetLayout"
  | "ent"
  | "formula"
  | "studioForm"
  | "studioFormSection"
  | "studioFormGrid"
  | "studioFormField"
  | "studioFormHeader"
  | "studioFormFooter"
  | "studioFormContentLayout"
  | "visibilityRule"
  | "group"
  | "plugin"
  | "report"
  | "role"
  | "deeplink"
  | "scheduler"
  | "prompt"
  | "spreadsheet"
  | "translation"
  | "variable";

export type TypeListSortType =
  | "sortAsc"
  | "sortDesc"

export const StudioEntFooterTabList: EnumStudioEntFooterTab[] = [
  "Builder",
  "Terminal",
  "Designer"
];

export const StudioEntAllSteps: EnumStudioEntStep[] = [
  "About",
  "Plugins",
  "Roles",
  "Variables",
  "Forms",
  "Spreadsheets",
  "Reports",
  "Automations",
  "Actions",
  "Groups",
  "Deeplinks",
  "DriveSheets",
  "Prompts",
  "Translations",
  "Deploy"
];

export const ProductionEntFooterTabList: EnumProductionEntFooterTab[] = [
  "Manage",
  "Debugger",
  "Admins",
  "Users"
];

export const ProductionPluginFooterTabList: EnumProductionPluginFooterTab[] = [
  "Manage",
  "Admins"
];

export const StudioEntQuickSteps: EnumStudioEntStep[][] =
  [
    [
      "About"
    ],
    [
      "Roles",
      "Variables",
      "Forms",
      "Spreadsheets"
    ],
    [
      "Actions",
      "Groups"
    ],
    [
      "Deploy"
    ]
  ];

export const StudioEntStandardSteps: EnumStudioEntStep[][] =
  [
    [
      "About"
    ],
    [
      "Roles",
      "Variables",
      "Forms",
      "Spreadsheets",
      "Reports"
    ],
    [
      "Actions",
      "Groups"
    ],
    [
      "Deploy"
    ]
  ];

export const StudioEntAdvanceSteps: EnumStudioEntStep[][] =
  [
    [
      "About"
    ],
    [
      "Plugins",
      "Roles",
      "Variables",
      "Forms",
      "Spreadsheets",
      "Reports",
      "Automations"
    ],
    [
      "Actions",
      "Groups",
      "Deeplinks",
      "DriveSheets",
      "Prompts",
      "Translations"
    ],
    [
      "Deploy"
    ]
  ];

export const StudioEntFocusBackendSteps: EnumStudioEntStep[][] =
  [
    [
      "Plugins",
      "Roles",
      "Variables",
      "Forms",
      "Spreadsheets",
      "Reports",
      "Automations"
    ]
  ];

export const StudioEntFocusFrontendSteps: EnumStudioEntStep[][] =
  [
    [
      "Actions",
      "Groups",
      "Deeplinks",
      "DriveSheets"
    ]
  ];

export const StudioEntFocusDeploySteps: EnumStudioEntStep[][] =
  [
    [
      "About"
    ],
    [
      "Prompts",
      "Translations"
    ],
    [
      "Deploy"
    ]
  ];

export const StudioEntShowAllSteps: EnumStudioEntStep[][] =
  [
    [
      "About"
    ],
    [
      "Plugins",
      "Roles",
      "Variables",
      "Forms",
      "Spreadsheets",
      "Reports",
      "Automations"
    ],
    [
      "Actions",
      "Groups",
      "Deeplinks",
      "DriveSheets",
      "Prompts",
      "Translations"
    ],
    [
      "Deploy"
    ]
  ];

export const studioReportMap: Record<EnumDefnKindReport, string> = {
  composite: "Composite",
  mapper: "Mapper",
  spreadsheet: "Spreadsheet",
  plugin: "Plugin",
  query: "Query"
};

//endregion

//region plugin

export type EnumStudioPluginStep =
  | "Details"
  | "Variables"
  | "Forms"
  | "Api"
  | "Resources"
  | "Deploy";

export type EnumStudioPluginFooterTab =
  | "Terminal"
  | "Builder"
  | "Admins";

export type EnumStudioPluginAside =
  | "Forms"
  | "FormVisibilityRules"
  | "Section"
  | "Grid"
  | "Field"
  | "Variables"
  | "Api"
  | "Resources"
  | "Admins"
  | "storeItemPluginAdmins";

export const StudioPluginFooterTabList: EnumStudioPluginFooterTab[] = ["Builder", "Terminal"];

//endregion

export type EnumStudioSearchPathKeys =
  | "actionMap"
  | "automationMap"
  | "driveSheetMap"
  | "groupMap"
  | "importMap"
  | "reportMap"
  | "roleMap"
  | "deeplinkMap"
  | "spreadsheetMap"
  | "eventMap"
  | "stepMap"
  | "resourceMap"
  | "translationMap"
  | "deployPluginMap"
  | "deployVarMap"
  | "deployPaymentProviderMap"
  | "varMap"
  | "formMap"
  | "compositeMap"
  | "headerMap"
  | "footerMap"
  | "fieldMap"
  | "promptMap"
  | "apiMap"
  | "admins"
  | "users"
  | "map"
  | "keys"
  | "details"
  | "about"
  | "createdBy"
  | "creationTime"
  | "setupMode"
  | "deploy"
  | "permissionMatrix"
  | "layoutSpreadsheetMap"
  | "actionPermissionMap"
  | "layoutMap"
  | "actionMenuForm"
  | "pluginApiIdMap"
  | "formulaMap"
  | "actionIdSet"
  | "visibilityRuleMap"
  | "bubblePatternVarId"
  | "smsPatternVarId"
  | "derivedLabelFieldId"
  | "payment";

export type EnumStudioHelpPath =
  | TypeArtifact
  | EnumStudioEntStep
  | EnumStudioStepFormItem
  | EnumStudioPluginStep
  | EnumStudioEntAboutTab
  | EnumStudioEntFooterTab
  | EnumStudioPluginFooterTab
  | EnumStudioVarKind
  | EnumStudioEntDeployTab
  | "main"
  | "drawer"
  | "modules"
  | "sections"
  | "fields"
  | "icons"
  | "query"
  | "fieldMapping"
  | "sectionMapping"
  | "newCondition"
  | "linkVariable";

export type EnumListItemDirection =
  | "up"
  | "down"
  | "top"
  | "bottom";

export interface IStudioJumpPath
{
  step?:
    | EnumMainPropsStudioJumpStep
    | EnumMainPropsStudioJumpStepTab
    | EnumStudioEntAside
    | EnumStudioPluginAside
    | EnumStudioStepFormItem
    | EnumStudioSearchPathKeys
    | "studioEnt"
    | "pluginBundle";
  itemId?: MetaId;
}

export interface ILatLng
{
  lat: number,
  lng: number
}

export interface ILocationAddress
{
  address: string,
  city: string,
  country: string,
  geoPoint: ILatLng,
}

