import React from "react";
import {DtoMessagePayloadSpreadsheetPartition} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetPartition";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {EntUserId} from "../../../api/meta/base/Types";
import {SelectList} from "../../../base/plus/ListPlus";

import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {CbMenuAnchor} from "../../../base/types/TypesGlobal";
import {RootState} from "../../../Store";
import {useAppSelector} from "../../app/AppHooks";
import BubbleSpreadsheet from "../../atom/bubble/leftright/BubbleSpreadsheet";
import ListItemShellChat from "../impl/ListItemShellChat";
import {CbOnClickListItem} from "../List";

export default function ListItemChatSpreadsheet(props: {
  itemId: TypeListItemId,
  listItem: IListItemChat,
  selectList: SelectList,
  itemMaxWidth: number,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
  onClickListItem?: CbOnClickListItem,
  cbOnClickFooterCaption?: CbMenuAnchor,
})
{
  const selectList = props.selectList;
  const searchWords = useAppSelector(state => selectList(state).searchWords);
  const listItem = props.listItem;
  const sig = listItem.sig;

  return (
    <ListItemShellChat
      itemId={props.itemId}
      listItem={props.listItem}
      selectList={props.selectList}
      maxWidth={props.itemMaxWidth}
      onClickListItem={props.onClickListItem}
    >
      <BubbleSpreadsheet
        payload={sig.payload as DtoMessagePayloadSpreadsheetPartition}
        replyPayload={sig.replyPayload}
        replyInfo={sig.replyInfo}
        receiptStatus={sig.receiptStatus}
        isCallerSender={Boolean(sig.isCallerSender)}
        creationTime={sig.creationTime}
        titleColor={sig.formBubbleTitleColor}
        maxWidth={props.itemMaxWidth}
        header={sig.header}
        searchWords={searchWords}
        isStar={sig.isStar}
        defnForm={sig.defnForm}
        showComments={sig.isCommentable}
        sigSpreadsheetRow={sig.sigSpreadsheetRow}
        isFormWithMedia={sig.isFormWithMedia}
        cbOnClickFooterCaption={props.cbOnClickFooterCaption}
        isInvisibleSpreadsheetRow={sig.isInvisibleSpreadsheetRow}
        sigSpreadsheetRowExpiry={sig.sigSpreadsheetRowExpiry}
        canExpire={sig.canExpire}
        chatPatternVar={sig.chatPatternVar}
        selectUserAvatar={props.selectUserAvatar}
      />
    </ListItemShellChat>
  );
}
