import {Typography} from "@mui/material";
import React from "react";
import LayoutFlexRow from "../layout/LayoutFlexRow";

export default function DialogTextError(props: {
  value: string
})
{
  return (
    <LayoutFlexRow width={"100%"} flexGrow={1}>
      <Typography color="error" variant="body1">
        {props.value}
      </Typography>
    </LayoutFlexRow>
  );
}
