import {FieldValues} from "react-hook-form/dist/types/fields";
import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {EnumStudioVarKind} from "../../../api/meta/base/Types";
import {getStudioDetailsToDto} from "../../form/builder/base/TypesFormBuilder";
import {fnVariableBuilderBoolean} from "../builder/VariableBuilderBoolean";
import {fnVariableBuilderButtonVariant} from "../builder/VariableBuilderButtonVariant";
import {fnVariableBuilderColor} from "../builder/VariableBuilderColor";
import {fnVariableBuilderCondition} from "../builder/VariableBuilderCondition";
import {fnVariableBuilderCurrency} from "../builder/VariableBuilderCurrency";
import {fnVariableBuilderDate} from "../builder/VariableBuilderDate";
import {fnVariableBuilderDateTime} from "../builder/VariableBuilderDateTime";
import {fnVariableBuilderDay} from "../builder/VariableBuilderDay";
import {fnVariableBuilderSetOfDay} from "../builder/VariableBuilderDaySet";
import {fnVariableBuilderDecimal} from "../builder/VariableBuilderDecimal";
import {fnVariableBuilderDeviceSize} from "../builder/VariableBuilderDeviceSize";
import {fnVariableBuilderDeviceType} from "../builder/VariableBuilderDeviceType";
import {fnVariableBuilderDirection} from "../builder/VariableBuilderDirection";
import {fnVariableBuilderDividerType} from "../builder/VariableBuilderDividerType";
import {fnVariableBuilderDocument} from "../builder/VariableBuilderDocument";
import {fnVariableBuilderDuration} from "../builder/VariableBuilderDuration";
import {fnVariableBuilderEmail} from "../builder/VariableBuilderEmail";
import {fnVariableBuilderFont} from "../builder/VariableBuilderFont";
import {fnVariableBuilderFunction} from "../builder/VariableBuilderFunction";
import {fnVariableBuilderHtml} from "../builder/VariableBuilderHtml";
import {fnVariableBuilderHyperlink} from "../builder/VariableBuilderHyperlink";
import {fnVariableBuilderIcon} from "../builder/VariableBuilderIcon";
import {fnVariableBuilderImage} from "../builder/VariableBuilderImage";
import {fnVariableBuilderImageCorner} from "../builder/VariableBuilderImageCorner";
import {fnVariableBuilderLanguage} from "../builder/VariableBuilderLanguage";
import {fnVariableBuilderLocation} from "../builder/VariableBuilderLocation";
import {fnVariableBuilderMapOfText} from "../builder/VariableBuilderMapOfText";
import {fnVariableBuilderMapping} from "../builder/VariableBuilderMapping";
import {fnVariableBuilderMapPinShape} from "../builder/VariableBuilderMapPinShape";
import {fnVariableBuilderMobileNumber} from "../builder/VariableBuilderMobileNumber";
import {fnVariableBuilderMonth} from "../builder/VariableBuilderMonth";
import {fnVariableBuilderNumber} from "../builder/VariableBuilderNumber";
import {fnVariableBuilderSetOfNumber} from "../builder/VariableBuilderNumberSet";
import {fnVariableBuilderParagraph} from "../builder/VariableBuilderParagraph";
import {fnVariableBuilderPlacement} from "../builder/VariableBuilderPlacement";
import {fnVariableBuilderQuarter} from "../builder/VariableBuilderQuarter";
import {fnVariableBuilderRatingKind} from "../builder/VariableBuilderRatingKind";
import {fnVariableBuilderSequence} from "../builder/VariableBuilderSequence";
import {fnVariableBuilderSetOfDate} from "../builder/VariableBuilderSetOfDate";
import {fnVariableBuilderSetOfTime} from "../builder/VariableBuilderSetOfTime";
import {fnVariableBuilderSetOfUsers} from "../builder/VariableBuilderSetOfUsers";
import {fnVariableBuilderStroke} from "../builder/VariableBuilderStroke";
import {fnVariableBuilderSymbol} from "../builder/VariableBuilderSymbol";
import {fnVariableBuilderText} from "../builder/VariableBuilderText";
import {fnVariableBuilderSetOfText} from "../builder/VariableBuilderTextSet";
import {fnVariableBuilderTime} from "../builder/VariableBuilderTime";
import {fnVariableBuilderTimeZone} from "../builder/VariableBuilderTimeZone";
import {fnVariableBuilderTree} from "../builder/VariableBuilderTree";
import {fnVariableBuilderUserSetting} from "../builder/VariableBuilderUserSetting";
import {fieldVariableValue} from "./VariablePlus";
import {compVariableDeployType} from "./VariablePlus";

export function defnToDtoVariable(
  valueMap: FieldValues,
  variableType: EnumStudioVarKind,
  refVariable: StudioVar | undefined)
{
  let entVariable = {
    ...refVariable,
    deploy: valueMap[compVariableDeployType] || refVariable?.deploy,
    kind: variableType,
    details: getStudioDetailsToDto(valueMap, refVariable?.details)
  } as StudioVar;

  if(entVariable.deploy === "fixedOnDeploy")
  {
    entVariable = {
      ...entVariable,
      ...getDefnToDtoVariableValue(valueMap, variableType)
    };
  }

  return entVariable;
}

export function getDefnToDtoVariableValue(
  valueMap: FieldValues,
  variableType: EnumStudioVarKind)
{
  const valueKey = fieldVariableValue;

  switch(variableType)
  {
    case "any":
      return undefined;
    case "bool":
    {
      const {defnToDto} = fnVariableBuilderBoolean(valueKey);
      return defnToDto(valueMap);
    }
    case "setOfDate":
    {
      const {defnToDto} = fnVariableBuilderSetOfDate(valueKey);
      return defnToDto(valueMap);
    }
    case "color":
    {
      const {defnToDto} = fnVariableBuilderColor(valueKey);
      return defnToDto(valueMap);
    }
    case "condition":
    {
      const {defnToDto} = fnVariableBuilderCondition(valueKey);
      return defnToDto(valueMap);
    }
    case "currency":
    {
      const {defnToDto} = fnVariableBuilderCurrency(valueKey);
      return defnToDto(valueMap);
    }
    case "date":
    {
      const {defnToDto} = fnVariableBuilderDate(valueKey);
      return defnToDto(valueMap);
    }
    case "dateTime":
    {
      const {defnToDto} = fnVariableBuilderDateTime(valueKey);
      return defnToDto(valueMap);
    }
    case "day":
    {
      const {defnToDto} = fnVariableBuilderDay(valueKey);
      return defnToDto(valueMap);
    }
    case "decimal":
    {
      const {defnToDto} = fnVariableBuilderDecimal(valueKey);
      return defnToDto(valueMap);
    }
    case "setOfDay":
    {
      const {defnToDto} = fnVariableBuilderSetOfDay(valueKey);
      return defnToDto(valueMap);
    }
    case "deviceSize":
    {
      const {defnToDto} = fnVariableBuilderDeviceSize(valueKey);
      return defnToDto(valueMap);
    }
    case "deviceType":
    {
      const {defnToDto} = fnVariableBuilderDeviceType(valueKey);
      return defnToDto(valueMap);
    }
    case "direction":
    {
      const {defnToDto} = fnVariableBuilderDirection(valueKey);
      return defnToDto(valueMap);
    }
    case "dividerKind":
    {
      const {defnToDto} = fnVariableBuilderDividerType(valueKey);
      return defnToDto(valueMap);
    }
    case "document":
    {
      const {defnToDto} = fnVariableBuilderDocument(valueKey);
      return defnToDto(valueMap);
    }
    case "duration":
    {
      const {defnToDto} = fnVariableBuilderDuration(valueKey);
      return defnToDto(valueMap);
    }
    case "email":
    {
      const {defnToDto} = fnVariableBuilderEmail(valueKey);
      return defnToDto(valueMap);
    }
    case "buttonVariant":
    {
      const {defnToDto} = fnVariableBuilderButtonVariant(valueKey);
      return defnToDto(valueMap);
    }
    case "textSize":
    {
      const {defnToDto} = fnVariableBuilderFont(valueKey);
      return defnToDto(valueMap);
    }
    case "hyperlink":
    {
      const {defnToDto} = fnVariableBuilderHyperlink(valueKey);
      return defnToDto(valueMap);
    }
    case "icon":
    {
      const {defnToDto} = fnVariableBuilderIcon(valueKey);
      return defnToDto(valueMap);
    }
    case "image":
    {
      const {defnToDto} = fnVariableBuilderImage(valueKey);
      return defnToDto(valueMap);
    }
    case "imageCorner":
    {
      const {defnToDto} = fnVariableBuilderImageCorner(valueKey);
      return defnToDto(valueMap);
    }
    case "language":
    {
      const {defnToDto} = fnVariableBuilderLanguage(valueKey);
      return defnToDto(valueMap);
    }
    case "location":
    {
      const {defnToDto} = fnVariableBuilderLocation(valueKey);
      return defnToDto(valueMap);
    }
    case "month":
    {
      const {defnToDto} = fnVariableBuilderMonth(valueKey);
      return defnToDto(valueMap);
    }
    case "quarter":
    {
      const {defnToDto} = fnVariableBuilderQuarter(valueKey);
      return defnToDto(valueMap);
    }
    case "mapPinShape":
    {
      const {defnToDto} = fnVariableBuilderMapPinShape(valueKey);
      return defnToDto(valueMap);
    }
    case "mapping":
    {
      const {defnToDto} = fnVariableBuilderMapping(valueKey);
      return defnToDto(valueMap);
    }
    case "mobileNumber":
    {
      const {defnToDto} = fnVariableBuilderMobileNumber(valueKey);
      return defnToDto(valueMap);
    }
    case "number":
    {
      const {defnToDto} = fnVariableBuilderNumber(valueKey);
      return defnToDto(valueMap);
    }
    case "setOfNumber":
    {
      const {defnToDto} = fnVariableBuilderSetOfNumber(valueKey);
      return defnToDto(valueMap);
    }
    case "setOfText":
    {
      const {defnToDto} = fnVariableBuilderSetOfText(valueKey);
      return defnToDto(valueMap);
    }
    case "mapOfText":
    {
      const {defnToDto} = fnVariableBuilderMapOfText(valueKey);
      return defnToDto(valueMap);
    }
    case "time":
    {
      const {defnToDto} = fnVariableBuilderTime(valueKey);
      return defnToDto(valueMap);
    }
    case "setOfTime":
    {
      const {defnToDto} = fnVariableBuilderSetOfTime(valueKey);
      return defnToDto(valueMap);
    }
    case "timeZone":
    {
      const {defnToDto} = fnVariableBuilderTimeZone(valueKey);
      return defnToDto(valueMap);
    }
    case "tree":
    {
      const {defnToDto} = fnVariableBuilderTree(valueKey);
      return defnToDto(valueMap);
    }
    case "text":
    {
      const {defnToDto} = fnVariableBuilderText(valueKey);
      return defnToDto(valueMap);
    }
    case "paragraph":
    {
      const {defnToDto} = fnVariableBuilderParagraph(valueKey);
      return defnToDto(valueMap);
    }
    case "ratingKind":
    {
      const {defnToDto} = fnVariableBuilderRatingKind(valueKey);
      return defnToDto(valueMap);
    }
    case "sequence":
    {
      const {defnToDto} = fnVariableBuilderSequence(valueKey);
      return defnToDto(valueMap);
    }
    case "stroke":
    {
      const {defnToDto} = fnVariableBuilderStroke(valueKey);
      return defnToDto(valueMap);
    }
    case "symbol":
    {
      const {defnToDto} = fnVariableBuilderSymbol(valueKey);
      return defnToDto(valueMap);
    }
    case "html":
    {
      const {defnToDto} = fnVariableBuilderHtml(valueKey);
      return defnToDto(valueMap);
    }
    case "function":
    {
      const {defnToDto} = fnVariableBuilderFunction(valueKey);
      return defnToDto(valueMap);
    }
    case "userSetting":
    {
      const {defnToDto} = fnVariableBuilderUserSetting(valueKey);
      return defnToDto(valueMap);
    }
    case "placement":
    {
      const {defnToDto} = fnVariableBuilderPlacement(valueKey);
      return defnToDto(valueMap);
    }
    case "setOfUser":
    {
      const {defnToDto} = fnVariableBuilderSetOfUsers(valueKey);
      return defnToDto(valueMap);
    }
  }
}
