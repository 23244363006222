import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDate} from "../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldInfo} from "../../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {FieldValueDate} from "../../../api/meta/base/dto/FieldValueDate";
import {StudioBuildDate} from "../../../api/meta/base/dto/StudioBuildDate";
import {StudioVarDate} from "../../../api/meta/base/dto/StudioVarDate";
import {EnumDefnDate} from "../../../api/meta/base/Types";
import {EnumArrayDefnDate} from "../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {arrayToMapOfOption} from "../../../base/plus/JsPlus";

type DateType =
  | EnumDefnDate
  | "custom"

const fieldCustomValue = "customValue";
const fieldNoteInfo = "fieldNoteInfo";
const fieldNote = "fieldNote";

export function fnVariableBuilderDate(fieldValueDate: string, dateTimeFormat?: string)
{
  function getVarComp(variableDateType?: DateType)
  {
    const dateOptions: DateType[] = [
      "custom",
      ...EnumArrayDefnDate as EnumDefnDate[]
    ];

    return {
      [fieldValueDate]: {
        type: "studioBuildDate",
        metaId: fieldValueDate,
        name: "Value",
        optionMap: arrayToMapOfOption(dateOptions),
        required: true
      } as DefnFieldPickText,
      ...variableDateType === "custom"
        ? {
          [fieldCustomValue]: {
            type: "date",
            metaId: fieldCustomValue,
            name: "Custom value",
            required: true,
            displayDateFormat: dateTimeFormat
          } as DefnFieldDate
        }
        : {},

      [fieldNoteInfo]: {
        type: "info",
        metaId: fieldNoteInfo,
        name: fieldNoteInfo,
        flexGrow: true,
        label: ""
      } as DefnFieldInfo,

      [fieldNote]: {
        type: "label",
        name: fieldNote,
        metaId: fieldNote,
        italic: true,
        textSizeVar: "caption",
        italicVar: true,
        disabled: true,
        label: "Note: The date is calculated when bound"
      } as DefnFieldLabel
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarDate
  )
  {
    const varValue = entVariable.value;
    if(varValue)
    {
      const dateEnumValue = varValue.value;
      if(dateEnumValue && EnumArrayDefnDate.includes(dateEnumValue))
      {
        valueMap[fieldValueDate] = dateEnumValue;
        valueMap[fieldCustomValue] = null;
      }
      else if(varValue.customValue)
      {
        valueMap[fieldValueDate] = "custom" as DateType;
        valueMap[fieldCustomValue] = fnRawValueToFieldValue("date", varValue.customValue);
      }
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const dateValue = valueMap[fieldValueDate] as DateType;
    const customValue = fnFieldValueToRawValue("date", valueMap[fieldCustomValue] as FieldValueDate) as string;

    const studioBuildDateDto = {} as StudioBuildDate;

    if(!dateValue)
    {
      return;
    }

    if(dateValue && EnumArrayDefnDate.includes(dateValue))
    {
      studioBuildDateDto.value = dateValue as EnumDefnDate;
      return {
        value: studioBuildDateDto
      };
    }
    else if(dateValue === "custom" && customValue)
    {
      studioBuildDateDto.customValue = customValue;
      return {
        value: studioBuildDateDto
      };
    }
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
