import {SigTopic} from "../../../../api/core/session/sig/SigTopic";
import {MsgLastMessageGet} from "../../../../api/home/drawer/msg/MsgLastMessageGet";
import {WsocDrawer} from "../../../../api/home/drawer/WsocDrawer";
import {MsgOffset} from "../../../../api/home/main/msg/MsgOffset";
import {SigLastMessage} from "../../../../api/home/main/sig/SigLastMessage";
import {WsocMain} from "../../../../api/home/main/WsocMain";
import {SigTopicMessageProps} from "../../../../api/home/session/sig/SigTopicMessageProps";
import {isRowId} from "../../../../api/meta/base/ApiPlus";
import {MessageId} from "../../../../api/meta/base/Types";
import {ChatId, EntId} from "../../../../api/meta/base/Types";
import {isNotFoundSignal} from "../../../../api/nucleus/base/Protocol";
import ISrvc from "../../../../base/ISrvc";
import {FN_NOOP} from "../../../../base/plus/SysPlus";
import {comboToAboutId} from "../../../../base/types/TypesComboId";
import {TypeComboIdChat} from "../../../../base/types/TypesComboId";
import {toComboId} from "../../../../base/types/TypesComboId";
import {Srvc} from "../../../../srvc/Srvc";
import {RootState} from "../../../../Store";
import {store} from "../../../../Store";
import {removeLastMessage} from "./SliceCacheHomeDrawerMsgLast";
import {setLastMessage} from "./SliceCacheHomeDrawerMsgLast";
import {ILastMessage} from "./TypesCacheHomeDrawerMsgLast";

export default class SrvcCacheHomeDrawerMsgLast extends ISrvc
{
  private readonly subscriberId = "SrvcCacheHomeDrawerMsgLast";

  wsocLastMessageGet(entId: EntId, chatId: ChatId)
  {
    const msg: MsgLastMessageGet = {
      chatId: chatId
    };

    const entChatId = toComboId(entId, chatId) as TypeComboIdChat;
    const lastMessage = store.getState().cache.home.drawer.msgLast.lastMessageMap[entChatId];
    if(lastMessage)
    {
      msg.version = lastMessage.version;
    }

    WsocDrawer.lastMessageGet(entId, msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        this.acceptLastMessage(entChatId, sig);
      }
      else
      {
        if(isNotFoundSignal(envSig))
        {
          store.dispatch(removeLastMessage({
            entId,
            chatId
          }));
        }
      }
    });
  }

  unsubscribe(entId: EntId, chatId: ChatId)
  {
    store.dispatch((dispatch, getState) =>
    {
      const rootState = getState();
      const entChatId = toComboId(entId, chatId);
      const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
      if(lastMessage)
      {
        const senderId = lastMessage.senderId;
        if(senderId)
        {
          Srvc.app.pubsub.user.userAvatar(this.getSubscriberId(lastMessage.messageId), entId, senderId, true);
        }

        dispatch(removeLastMessage({
          entId: entId,
          chatId: chatId
        }));
      }
    });
  }

  getLatestMessageChatId(rootState: RootState, msgId: MessageId): ChatId | undefined
  {
    const comboId = rootState.cache.home.drawer.msgLast.lastMessageMsgIdToChatIdMap[msgId];
    if(comboId)
    {
      const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[comboId];
      if(lastMessage.messageId === msgId)
      {
        return comboToAboutId(comboId);
      }
    }
  }

  handleSigTopicMessageProps(sig: SigTopicMessageProps)
  {
    const entId = sig.artifactId as EntId;
    const chatId = sig.aboutId as ChatId;
    const msgId = sig.messageId as MessageId;
    if(isRowId(chatId))
    {
      return;
    }
    const rootState = store.getState();
    const entChatId = toComboId(entId, chatId);
    const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
    if(lastMessage?.messageId === msgId)
    {
      this.wsocLastMessageGet(entId, chatId);
    }
  }

  handleSigTopicMessage(sig: SigTopic)
  {
    const rootState = store.getState();
    const entId = sig.artifactId as EntId;
    const msgId = sig.aboutId as MessageId;

    const chatId = Srvc.cache.home.drawer.msgLast.getLatestMessageChatId(rootState, msgId);
    if(chatId && !isRowId(chatId))
    {
      Srvc.cache.home.drawer.msgLast.wsocLastMessageGet(entId, chatId);
    }
  }

  private acceptLastMessage(entChatId: TypeComboIdChat, sig: SigLastMessage): void
  {
    store.dispatch((dispatch, getState) =>
    {
      const prevSig = getState().cache.home.drawer.msgLast.lastMessageMap[entChatId] as ILastMessage | undefined;

      const prevSenderId = prevSig ? prevSig.senderId : undefined;
      const senderId = sig.senderId;
      const prevMsgId = prevSig?.messageId;
      if(prevSenderId !== senderId)
      {
        if(prevSenderId && prevMsgId)
        {
          Srvc.app.pubsub.user.userAvatar(this.getSubscriberId(prevMsgId), sig.entId, prevSenderId, true);
        }

        if(senderId)
        {
          Srvc.app.pubsub.user.userAvatar(this.getSubscriberId(sig.messageId), sig.entId, senderId);
        }
      }
      if(prevMsgId && prevMsgId !== sig.messageId)
      {
        Srvc.app.pubsub.msg.message(this.getSubscriberId(prevMsgId), sig.entId, prevMsgId, true);
      }
      if(!prevMsgId || prevMsgId !== sig.messageId)
      {
        Srvc.app.pubsub.msg.message(this.getSubscriberId(sig.messageId), sig.entId, sig.messageId);
      }

      dispatch(setLastMessage(sig));
      if(!sig.receiptStatus)
      {
        if(!prevSig || prevSig.messageId !== sig.messageId)
        {
          // mark message as received
          this.wsocMessageMarkReceived(sig.entId, sig.chatId, sig.messageOffset);
        }
      }
    });
  }

  private wsocMessageMarkReceived(entId: EntId, chatId: ChatId, messageOffset: number)
  {
    const msg = {
      chatId: chatId,
      offset: messageOffset
    } as MsgOffset;

    WsocMain.messageMarkReceived(entId, msg, FN_NOOP);
  }

  private getSubscriberId(messageId: MessageId)
  {
    return this.subscriberId + "#" + messageId;
  }
}
