import {StudioEntActionMap} from "../../../api/meta/base/dto/StudioEntActionMap";
import {StudioEntPromptMap} from "../../../api/meta/base/dto/StudioEntPromptMap";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";

export default class SrvcStudioEntPrompts extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entPromptList;
  }

  loadPromptList(listName: string, promptMap?: StudioEntPromptMap, actionMap?: StudioEntActionMap, readOnly?: boolean)
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(!promptMap)
    {
      return;
    }

    promptMap.keys.forEach((itemId) =>
    {
      const prompt = promptMap.map[itemId];
      uiItemIds.push(itemId);

      let description: string;

      if(prompt.actionId && actionMap && actionMap.map[prompt.actionId])
      {
        description = toLabel(actionMap.map[prompt.actionId].details.name);
      }
      else
      {
        description = "No action";
      }

      uiItemsById[itemId] = {
        type: "ps",
        hideMenu: readOnly,
        primary: {
          text: prompt.name
        },
        secondary: {
          text: description,
          color: "textDisabled"
        },
        userField: {
          value: prompt
        }
      } as IListItemAPSA;

    });

    dispatchList(listName, listRefresh({
      itemIds: uiItemIds,
      itemsById: uiItemsById
    }));

  }
}
