// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

export const BubbleHiddenField = [
  "camera",
  "image",
  "ref",
  "audio",
  "voice",
  "video"
  ];