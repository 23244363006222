import {camelCase} from "lodash";
import {isEmpty} from "lodash";
import {useState} from "react";
import {useRef} from "react";
import React, {useCallback} from "react";
import {FieldValues} from "react-hook-form";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioSetOfModule} from "../../api/meta/base/dto/DefnStudioSetOfModule";
import {StudioModuleSelection} from "../../api/meta/base/dto/StudioModuleSelection";
import {StudioPluginValidVarKind} from "../../api/meta/base/StudioSetsVarType";
import {
  StudioVarCollectionsType,
  StudioVarConstantType,
  StudioVarObjectsType,
  StudioVarPickersType
} from "../../api/meta/base/StudioSetsVarType";
import {EnumDefnDeploy, EnumStudioVarKind} from "../../api/meta/base/Types";
import {STR_MODULES} from "../../base/plus/ConstantsPlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {toSymbolCase} from "../../base/plus/StringPlus";
import {toLabel} from "../../base/plus/StringPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import {IListItem} from "../../base/types/list/TypesList";
import {TypeListItemId} from "../../base/types/list/TypesList";
import {IListItemAPSA} from "../../base/types/list/TypesListAPSA";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import {fieldKeyModulesSet, fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {ListClickVariant} from "../list/List";
import {compVariableDeployType} from "../varBldr/base/VariablePlus";
import {convertEnumArrToDialogListArr} from "./base/DialogPlus";
import {TypeListMap} from "./base/impl/DialogList";
import DialogList from "./base/impl/DialogList";

export interface IDialogAddVariable
{
  name: string;
  deployType: EnumDefnDeploy;
  selectedVariable: EnumStudioVarKind;
  modules?: StudioModuleSelection;
}

type TypeVariableTab =
  | "Constants"
  | "Pickers"
  | "Collections"
  | "Objects";

const varArrayConstantAPSA = getKindArr(StudioVarConstantType);
const varArrayPickersAPSA = getKindArr(StudioVarPickersType);
const varArrayCollectionsAPSA = getKindArr(StudioVarCollectionsType);
const varArrayObjectsAPSA = getKindArr(StudioVarObjectsType);

export default function DialogNewVariable(props: {
  onClickOk: (values: IDialogAddVariable) => void,
  onClose?: () => void,
  isPluginVariable?: boolean,
  formStore?: FormStore,
})
{
  const isPluginVariable = props.isPluginVariable;
  const formStore = props.formStore;
  const cbRef = useRef({} as IFormRef);

  const [searchText, setSearchText] = useState("");
  const [selectedVariable, setSelectedVariable] = useState<EnumStudioVarKind>();
  const [currentTab, setCurrentTab] = useState<TypeVariableTab>("Constants");

  const {
    filteredConstants,
    filteredCollections,
    filteredObjects,
    filteredPickers,
    filteredTabs
  } = getListOptions(searchText, isPluginVariable);

  const listMap = {
    keys: filteredTabs,
    map: {
      "Constants": varArrayConstantAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredConstants.includes(searchElement);
      }),
      "Pickers": varArrayPickersAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredPickers.includes(searchElement);
      }),
      "Collections": varArrayCollectionsAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredCollections?.includes(searchElement);
      }),
      "Objects": varArrayObjectsAPSA.filter(value =>
      {
        const searchElement = value?.primary?.text;
        return searchElement && filteredObjects.includes(searchElement);
      })
    }
  } as TypeListMap;

  const onClickList2 = (
    _menuAnchor: Element,
    _itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant) =>
  {
    switch(variant)
    {
      case "listItem":
      {
        const variableName = (item as IListItemAPSA)?.primary?.text;
        setSelectedVariable(camelCase(variableName) as EnumStudioVarKind);
        cbRef.current.setValue(fieldKeyName, variableName ? "Var" + toSymbolCase(variableName) : undefined);
        cbRef.current.setFieldDisable(fieldKeyName, isEmpty(variableName));
        cbRef.current.setFieldReadonly(fieldKeyName, isEmpty(variableName));
      }
        break;
    }
  };

  const onClickList1 = (
    _menuAnchor: Element,
    _itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant) =>
  {
    switch(variant)
    {
      case "listItem":
      {
        const tabName = (item as IListItemAPSA)?.primary?.text;
        setCurrentTab(tabName as TypeVariableTab);
      }
        break;
    }
  };

  const onSubmit = useCallback((value: FieldValues) =>
  {
    const dto = selectedVariable && valueToDto(value, selectedVariable) as IDialogAddVariable;
    dto && props.onClickOk && props.onClickOk(dto);
  }, [selectedVariable]);

  return (
    <DialogList
      formProps={{
        store: formStore,
        cbRef: cbRef.current,
        defnForm: getDefnForm(isPluginVariable),
        initValues: {
          [compVariableDeployType]: "fixedOnDeploy"
        },
        onSubmit: onSubmit
      }}
      listMap={listMap}
      title={"New variable"}
      onSearch={setSearchText}
      onClickList2={onClickList2}
      onClickList1={onClickList1}
      leftFooterCheckBoxActions={[
        {
          label: "Add more variables",
          value: "addMore"
        }
      ]}
    />
  );
}

const fieldTabConstants = "Constants";
const fieldTabPickers = "Pickers";
const fieldTabCollections = "Collections";
const fieldTabObjects = "Objects";

function getValidStudioVarTypes(isPluginVariable?: boolean)
{
  let constants = StudioVarConstantType;
  let pickers = StudioVarPickersType;
  let collections = StudioVarCollectionsType;
  let objects = StudioVarObjectsType;

  if(isPluginVariable)
  {
    constants = constants.filter(type => StudioPluginValidVarKind.includes(type));
    collections = collections.filter(type => StudioPluginValidVarKind.includes(type));
    objects = objects.filter(type => StudioPluginValidVarKind.includes(type));
    pickers = pickers.filter(type => StudioPluginValidVarKind.includes(type));
  }

  return {
    constants,
    collections,
    objects,
    pickers
  };
}

function getListOptions(searchText: string, isPluginVariable?: boolean)
{
  let filteredTabs = [
    fieldTabConstants,
    fieldTabPickers,
    fieldTabCollections,
    fieldTabObjects
  ];

  const filteredConstants = getValidStudioVarTypes(isPluginVariable)
  .constants
  .filter(variable => variable !== "any")
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredCollections = getValidStudioVarTypes(isPluginVariable)
  .collections
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredObjects = getValidStudioVarTypes(isPluginVariable)
  .objects
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  const filteredPickers = getValidStudioVarTypes(isPluginVariable)
  .pickers
  .filter(variable => checkSearchContains(toLabel(variable), searchText));

  if(!filteredConstants.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabConstants);
  }

  if(!filteredCollections.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabCollections);
  }

  if(!filteredObjects.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabObjects);
  }

  if(!filteredPickers.length)
  {
    filteredTabs = filteredTabs.filter(item => item !== fieldTabPickers);
  }

  return {
    filteredTabs,
    filteredConstants,
    filteredCollections,
    filteredObjects,
    filteredPickers
  };
}

function getDefnForm(
  isPluginVariable?: boolean
)
{

  return createDefaultDefnFormStudio({

    [fieldKeyName]: {
      type: "symbol",
      name: "Name",
      metaId: fieldKeyName,
      required: true,
      disabled: true
    } as DefnFieldEditable,

    ...!isPluginVariable && {
      [compVariableDeployType]: {
        type: "enumDeployVar",
        label: "Deploy",
        required: true,
        metaId: compVariableDeployType
      } as DefnFieldPickEnum
    },

    [fieldKeyModulesSet]: {
      type: "studioSetOfModule",
      metaId: fieldKeyModulesSet,
      name: toLabel(STR_MODULES)
    } as DefnStudioSetOfModule,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      sectionDirection: "horizontal",
      justifyContent: "spaceBetween",
      flexGrow: false,
      pb: gapStd,
      fieldIdSet: [
        fieldKeyName,
        ...!isPluginVariable
          ? [compVariableDeployType]
          : [],
        fieldKeyModulesSet
      ]
    } as DefnSection
  });
}

function checkSearchContains(variableText: string, searchText: string)
{
  return variableText.toUpperCase().indexOf(searchText.toUpperCase()) !== -1;
}

function valueToDto(values: FieldValues, selectedVariable: EnumStudioVarKind): IDialogAddVariable
{
  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    deployType: fnFieldValueToRawValue("enumDeployVar", values[compVariableDeployType]),
    modules: fnFieldValueToRawValue("studioSetOfModule", values[fieldKeyModulesSet]),
    selectedVariable: selectedVariable
  } as IDialogAddVariable;
}

function getKindArr(arr: string[])
{
  return convertEnumArrToDialogListArr(arr as EnumStudioVarKind[], getDescription);
}

function getDescription(kind: EnumStudioVarKind): string | undefined
{
  switch(kind)
  {
    case "bool":
      return "Stores true or false value";
    case "date":
      return "Stores generic or custom date values";
    case "dateTime":
      return "Stores generic or custom date and time values";
    case "day":
      return "Stores day value";
    case "decimal":
      return "Stores a decimal value";
    case "document":
      return "Stores a document";
    case "duration":
      return "Stores duration with time and unit";
    case "email":
      return "Stores email value";
    case "html":
      return "Stores html code";
    case "hyperlink":
      return "Stores a URL";
    case "icon":
      return "Stores an icon from a predefined list of icons";
    case "image":
      return "Stores an image";
    case "location":
      return "Stores a location on Google Maps";
    case "mobileNumber":
      return "Stores a mobile number";
    case "number":
      return "Stores a number";
    case "paragraph":
      return "Stores text in a text area";
    case "symbol":
      return "Stores a symbol (a capitalized text)";
    case "text":
      return "Stores text";
    case "time":
      return "Stores a time value";
    case "buttonVariant":
      return "Stores value of a button variant from Text, Contained, Outline or Icon";
    case "color":
      return "Stores a color value from a list of available colors";
    case "currency":
      return "Stores a currency type";
    case "deviceSize":
      return "Stores the size of the device";
    case "deviceType":
      return "Stores the type of the device";
    case "textSize":
      return "Stores the font size";
    case "imageCorner":
      return "Stores the values of corner radius of an image. The corners can be rectangle, circle or rounded";
    case "language":
      return "Stores the language";
    case "mapPinShape":
      return "Stores the shape of the pin on the map";
    case "month":
      return "Stores the name of the month";
    case "quarter":
      return "Stores the quarter";
    case "ratingKind":
      return "Stores the rating kind - star, heart or thumbs";
    case "stroke":
      return "Stores the stroke width";
    case "placement":
      return "Stores the placement value of the theme";
    case "timeZone":
      return "Stores the time zone";
    case "setOfDay":
      return "Stores single or multiple days";
    case "setOfTime":
      return "Stores single or multiple times";
    case "mapOfText":
      return "Stores an object of text with key-value pairs";
    case "setOfNumber":
      return "Stores an array of numbers";
    case "setOfText":
      return "Stores an array of text";
    case "setOfUser":
      return "Stores an array of users";
    case "setOfDate":
      return "Stores an array of dates";
    case "tree":
      return "Stores a predefined tree";
    case "condition":
      return "Stores a single or multiple conditions from source form/plugin. When applied, certain actions are performed when these conditions are met";
    case "function":
      return "Stores a function returning a text, number, text array or number array";
    case "mapping":
      return "Maps a form/plugin to another form/plugin. It is used in mapping output forms to reports";
    case "sequence":
      return "Stores a sequence of incrementing the value by 1 with the starting value required";
    case "userSetting":
      return "Stores enterprise user settings";
    default:
      return "Unknown kind";
  }
}







