import {useMemo} from "react";
import React from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickLayoutGridId} from "../../../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {StudioCompositeMap} from "../../../../api/meta/base/dto/StudioCompositeMap";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {EnumDefnLayoutGridKind} from "../../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useInsertTrashOptions} from "../../../../base/plus/StudioFormPlus";
import {IControllerFieldProps} from "../../../../base/types/TypesForm";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "./FieldRawStudioPick";

export default function FieldRawStudioPickLayoutGridId(props: {
  defn: DefnStudioPickLayoutGridId,
  fieldProps: IControllerFieldProps,
  multiSelect?: boolean,
  showChip?: boolean
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const formId = defn.formId;
  const gridId = defn.gridId;
  const excludeLayoutGridIdSet = defn.excludeLayoutGridIdSet;
  const filterLayoutKindSet = defn.filterLayoutKindSet;

  const formStore = formCtx.getStore();
  const studioForm = formStore?.formMap?.map[formId];
  const setOfGridId = useMemo(() => getCompGridIdSet(studioForm), [studioForm]);

  const fieldValue = props.fieldProps.field.value;

  const dtoOptions = useMemo(() => studioForm
    ? getPickLayoutGridOptions(
      setOfGridId,
      studioForm?.compositeMap,
      excludeLayoutGridIdSet,
      filterLayoutKindSet,
      gridId
    )
    : [], [excludeLayoutGridIdSet, filterLayoutKindSet, gridId, setOfGridId, studioForm]);

  const [options] = useInsertTrashOptions({
    type: "layoutGridMap",
    fieldValue: fieldValue,
    formStore: formStore,
    originalOptions: dtoOptions
  });

  return <FieldRawStudioPick
    {...props}
    multiSelect={props.multiSelect}
    showChip={props.showChip}
    optionMap={optionsToMapOfOption(options)}
  />;
}

function getPickLayoutGridOptions(
  setOfGridId: MetaIdGrid[],
  compositeMap?: StudioCompositeMap,
  excludeLayoutGridIdSet?: MetaIdLayoutGrid[],
  filterLayoutKindSet?: EnumDefnLayoutGridKind[],
  gridId?: MetaIdGrid
): DefnDtoOption[]
{
  const options: DefnDtoOption[] = [];

  if(!compositeMap)
  {
    return options;
  }

  compositeMap.keys.forEach((key) =>
  {
    const studioComposite = compositeMap.map[key] as StudioGrid;

    if(studioComposite.type === "grid" && setOfGridId.includes(studioComposite.metaId))
    {
      const layoutGridMap = studioComposite.layoutGridMap;

      layoutGridMap?.keys?.forEach((layoutGridId) =>
      {
        const layoutGrid = layoutGridMap?.map[layoutGridId];
        const layoutName = layoutGridMap?.map[layoutGridId].kind as EnumDefnLayoutGridKind;

        const label = `${studioComposite.details.name}.${layoutGrid.name}`;
        const optionId = layoutGrid?.metaId;

        if((excludeLayoutGridIdSet && excludeLayoutGridIdSet.includes(optionId)))
        {
          return;
        }

        if(filterLayoutKindSet && !filterLayoutKindSet.includes(layoutName))
        {
          return;
        }

        if(gridId && gridId !== studioComposite.metaId)
        {
          return;
        }

        options.push({
          value: label || optionId,
          metaId: optionId
        });
      });
    }
  });
  return options;
}

function getCompGridIdSet(studioForm?: StudioForm): MetaIdGrid[]
{
  let setOfGridId = [] as MetaIdGrid[];
  const compositeMap = studioForm?.compositeMap;
  if(compositeMap?.keys)
  {
    compositeMap?.keys.forEach((key) =>
    {
      const studioComposite = compositeMap.map[key] as StudioGrid;
      if(studioComposite.type === "grid")
      {
        setOfGridId.push(studioComposite.metaId);
      }
    });
  }

  return setOfGridId;
}
