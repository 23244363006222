import {FieldValues} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {StudioFieldLocation} from "../../../../../api/meta/base/dto/StudioFieldLocation";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {px} from "../../../../../base/plus/StringPlus";
import {gapStd} from "../../../../../base/plus/ThemePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyShowCapturedValuesOnAside} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyDefaultValue} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {propKeySuffix} from "../../base/TypesFormBuilder";
import {propKeyPrefix} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyCaptureMode} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueVar} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDefaultFieldId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyCaptureTime} from "../../base/TypesFormBuilder";
import {propKeyCaptureUser} from "../../base/TypesFormBuilder";

export function getDefnFieldLocation(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyDefaultFieldId,
    fieldGap2,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyDefaultValueVar,
    fieldGap2,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    fieldGap1,
    propKeyDefaultValue,
    fieldGap2,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue,
    propKeyPrefix,
    propKeySuffix,
    fieldGap3,
    propKeyCaptureTime,
    propKeyCaptureUser,
    propKeyCaptureMode,
    fieldGap4,
    propKeyShowCapturedValuesOnAside
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "location",
      formId,
      "location",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyCaptureTime]: {
      type: "bool",
      name: propKeyCaptureTime,
      metaId: propKeyCaptureTime,
      label: "Capture time",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureUser]: {
      type: "bool",
      name: propKeyCaptureUser,
      metaId: propKeyCaptureUser,
      label: "Capture user",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyCaptureMode]: {
      type: "enumCaptureMode",
      name: propKeyCaptureMode,
      metaId: propKeyCaptureMode,
      label: "Capture mode",
      pb: px(gapStd)
    } as DefnFieldPickEnum,

    [propKeyShowCapturedValuesOnAside]: {
      type: "enumCaptureValueKind",
      name: propKeyShowCapturedValuesOnAside,
      metaId: propKeyShowCapturedValuesOnAside,
      label: "Show captured values on aside",
      filterOptionSet: ["captureTime", "captureUser"]
    } as DefnFieldPickEnum

  };
}

export function defnValueToStudioFieldLocation(values: FieldValues): StudioFieldLocation
{
  return {
    type: "location",
    defaultValue: fnFieldValueToRawValue("location", values[propKeyDefaultValue]),
    defaultVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDefaultValueVar]),
    defaultFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDefaultFieldId]),
    captureTime: fnFieldValueToRawValue("bool", values[propKeyCaptureTime]),
    captureUser: fnFieldValueToRawValue("bool", values[propKeyCaptureUser]),
    captureMode: fnFieldValueToRawValue("enumCaptureMode", values[propKeyCaptureMode]),
    showCapturedValuesOnAside: fnFieldValueToRawValue("enumCaptureValueKind", values[propKeyShowCapturedValuesOnAside])
  } as StudioFieldLocation;
}

export function studioFieldLocationToDefnValue(studioField: StudioFieldLocation)
{
  return {
    [propKeyDefaultValue]: fnRawValueToFieldValue("location", studioField.defaultValue),
    [propKeyDefaultValueVar]: fnRawValueToFieldValue("pickVarId", studioField.defaultVarId),
    [propKeyDefaultFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.defaultFieldId),
    [propKeyCaptureTime]: fnRawValueToFieldValue("bool", studioField.captureTime),
    [propKeyCaptureUser]: fnRawValueToFieldValue("bool", studioField.captureUser),
    [propKeyCaptureMode]: fnRawValueToFieldValue("enumCaptureMode", studioField.captureMode ?? "manual"),
    [propKeyShowCapturedValuesOnAside]: fnRawValueToFieldValue("enumCaptureValueKind",
      studioField.showCapturedValuesOnAside)
  };
}
