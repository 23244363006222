import {combineReducers} from "redux";
import {createChatSlice} from "../../../base/plus/ChatPlus";
import {createListSlice} from "../../../base/plus/ListPlus";
import {reducersHomeAsideGroupInfo} from "./groupInfo/StoreHomeGroupInfo";
import {reducersHomeAsideMedia} from "./media/StoreHomeMedia";
import {reducersHomeAsideUserInfo} from "./userInfo/StoreHomeUserInfo";

export function reducersAside()
{
  return combineReducers({
    groupInfo: reducersHomeAsideGroupInfo(),
    userInfo: reducersHomeAsideUserInfo(),
    media: reducersHomeAsideMedia(),
    chat: createChatSlice("asideChat").reducer,
    groupMemberMentionList: createListSlice("asideGroupMemberMentionList", {defaultListItemType: "aps"}).reducer,
    messageInfoList: createListSlice("homeAsideMessageInfo", {defaultListItemType: "aps"}).reducer,
    searchList: createListSlice("homeAsideSearchList", {externalSearch: true}).reducer,
    messageShare: createListSlice("homeAsideShare", {defaultListItemType: "aps"}).reducer,
    calendarItems: createListSlice("homeAsideCalendarItems").reducer
  });
}
