import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldQuarter} from "../../../../api/meta/base/dto/DefnFieldQuarter";
import {EnumArrayDefnQuarter} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickQuarter(props: {
  defn: DefnFieldQuarter
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
        <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={defn}
          optionMap={arrayToMapOfOption(EnumArrayDefnQuarter)}
          multiSelect={false}
          showChip={false}
        />
      }
    />
  );
}
