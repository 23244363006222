import ISrvc from "../../../base/ISrvc";
import SrvcCacheControlPanelQuery from "../ent/query/SrvcCacheControlPanelQuery";

export default class SrvcCacheControlPanelPlugin extends ISrvc
{
  public readonly query = new SrvcCacheControlPanelQuery();

  constructor()
  {
    super();
    this.setSrvcArray(
      this.query
    );
  }
}
