import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldNumber} from "../../../api/meta/base/dto/DefnFieldNumber";
import {StudioVarSequence} from "../../../api/meta/base/dto/StudioVarSequence";
import {stringToDefnDtoText} from "../../../base/plus/ArgBinderPlus";
import {VAR_SEQUENCE_VALUE} from "../../atom/assets/HelperTextStudio";

export function fnVariableBuilderSequence(fieldValueSequence: string)
{
  function getVarComp()
  {
    // TODO:Brijesh add sequence variable
    return {
      [fieldValueSequence]: {
        type: "number",
        name: fieldValueSequence,
        metaId: fieldValueSequence,
        label: "Value",
        required: true,
        helperTextVar: stringToDefnDtoText(VAR_SEQUENCE_VALUE)
      } as DefnFieldNumber
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarSequence
  )
  {
    valueMap[fieldValueSequence] = variable.value;
  }

  function defnToDto(valueMap: FieldValues)
  {
    const value = valueMap[fieldValueSequence];
    if(value === undefined || value === null || value === "")
    {
      return;
    }

    return {
      value: value
    } as StudioVarSequence;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
