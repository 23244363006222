import {PayloadAction} from "@reduxjs/toolkit";
import {IListItemId} from "../../types/list/TypesList";
import {TypeListItemId} from "../../types/list/TypesList";
import {IListItem} from "../../types/list/TypesList";
import {IList} from "../../types/list/TypesList";
import {IListGroup} from "../../types/list/TypesListGroup";
import {TypeListGroup} from "../../types/list/TypesListGroup";
import {IListItemGroup} from "../../types/list/TypesListGroup";
import {TypeListItemGroupId} from "../../types/list/TypesListGroup";
import {IListGroupsById} from "../../types/list/TypesListGroup";
import {ILineCaption} from "../../types/TypesGlobal";
import {TypeUserField} from "../../types/TypesGlobal";
import {ILineHeader} from "../../types/TypesGlobal";
import {fnListSetDisplay} from "./SliceList";

export type ListActionSetGroup = {
  version: string,
  itemId: TypeListItemId,
  header: ILineHeader,
  userField?: TypeUserField
};

export const sliceListGroup =
  {
    listSetGroup: (state: IList, action: PayloadAction<ListActionSetGroup>) =>
    {
      const item = action.payload;
      state.itemsById[item.itemId] = {
        version: item.version,
        itemId: item.itemId,
        type: "listGroup",
        header: item.header,
        userField: item.userField
      } as IListItem;
    },
    listSetGroupsById: (state: IList, action: PayloadAction<IListGroupsById>) =>
    {
      state.itemIds = undefined;
      state.groupsById = action.payload;
      state.loaded = true;
      fnListSetDisplay(state);
    },
    listSetGroupExpand: (state: IList, action: PayloadAction<{groupId: TypeListItemGroupId, expand: boolean}>) =>
    {
      const groupId = action.payload.groupId;
      const expand = action.payload.expand;
      const groupsById = state.groupsById;

      if(groupsById)
      {
        fnSetGroupExpand(state, groupId, expand);
        const itemsById = state.itemsById;
        const groupItem = itemsById[groupId] as IListItemGroup;
        if(groupItem)
        {
          const header = groupItem.header;
          if(header && groupItem.type === "listGroup")
          {
            if(header.expand !== expand)
            {
              header.expand = expand;
              fnListSetDisplay(state);
            }
          }
        }
      }
    },
    listSetGroupExpandAll: (state: IList, action: PayloadAction<boolean>) =>
    {
      const expand = action.payload;
      const groupsById = state.groupsById;
      let setItemIds = false;
      if(groupsById)
      {
        Object.keys(groupsById)
        .forEach(groupId =>
        {
          const groupItem = state.itemsById[groupId] as IListItemGroup;
          if(groupItem)
          {
            const header = groupItem.header;
            if(header && groupItem.type === "listGroup")
            {
              if(header.expand !== expand)
              {
                header.expand = expand;
                setItemIds = true;
              }
            }
          }
        });

        if(setItemIds)
        {
          fnListSetDisplay(state);
        }
      }
    },
    listSetGroupIfExistHeaderLine: (state: IList, action: PayloadAction<Omit<IListItemId & ILineHeader, "caption">>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemGroup;
      if(item && item.header)
      {
        const header = item.header;
        params.text && (header.text = params.text);
        params.optional && (header.optional = params.optional);
        params.color && (header.color = params.color);
        params.variant && (header.variant = params.variant);
        params.expand && (header.expand = params.expand);
        params.mt && (header.mt = params.mt);
        params.mb && (header.mb = params.mb);
        params.ml && (header.ml = params.ml);
        params.mr && (header.mr = params.mr);
      }
    },
    listSetGroupIfExistHeaderCaptionLine: (state: IList, action: PayloadAction<IListItemId & ILineCaption>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemGroup;
      if(!item || !item.header)
      {
        return;
      }

      const header = item.header;
      if(params.text || params.color || params.text)
      {
        let caption = header.caption;
        if(!caption)
        {
          caption = {} as ILineCaption;
          header.caption = caption;
        }

        if(params.text)
        {
          caption.text = params.text;
        }

        if(params.color)
        {
          caption.color = params.color;
        }

        if(params.variant)
        {
          caption.variant = params.variant;
        }
      }
    }
  };

function fnSetGroupExpand(state: IList, groupId: TypeListItemId, expand: boolean)
{
  const groupsById = state.groupsById;
  if(!groupsById)
  {
    return;
  }

  const group = groupsById[groupId] as TypeListGroup;
  if(group === "gapQuarter" || group === "gapHalf" || group === "gapStd")
  {
    return;
  }

  const groupItemIds = (groupsById[groupId] as IListGroup).itemIds;
  groupItemIds.forEach(itemId =>
  {
    const item = state.itemsById[itemId] as IListItem;
    if(item?.type === "listGroup")
    {
      const header = (item as IListItemGroup).header;
      if(header && item?.type === "listGroup")
      {
        if(header.expand && !expand)
        {
          header.expand = expand;
        }
      }

      fnSetGroupExpand(state, itemId, expand);
    }
  });
}
