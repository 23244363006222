import {useTheme} from "@mui/material";
import {FormControl} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {Switch} from "@mui/material";
import {Checkbox} from "@mui/material";
import {FormControlLabel} from "@mui/material";
import {useCallback} from "react";
import React from "react";
import {Noop} from "react-hook-form";
import {Controller} from "react-hook-form";
import {FieldError} from "react-hook-form";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {FieldValueSwitch} from "../../../../api/meta/base/dto/FieldValueSwitch";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {EnumDefnThemeSectionVariant} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import IFormCtx from "../base/CtxForm";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import RawCaptureExtraProperties from "../raw/RawCaptureExtraProperties";

export default function FieldSwitch(props: {
  defn: DefnFieldSwitch
})
{
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const formCtx = useFormCtx();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        return <RealFieldSwitch
          {...props}
          onBlur={field.onBlur}
          onChange={field.onChange}
          fieldValue={field.value}
          error={fieldState.error}
          isTouched={fieldState.isTouched}
        />;
      }}
    />
  );
}

function RealFieldSwitch(props: {
  defn: DefnFieldSwitch,
  isTouched: boolean;
  error?: FieldError;
  fieldValue?: FieldValueSwitch,
  onChange: (value?: FieldValueSwitch) => void,
  onBlur: () => void
})
{
  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const isTouched = props.isTouched;
  const error = props.error;
  const onChange = props.onChange;
  const formCtx = useFormCtx();

  const theme = useTheme();
  const defnTheme = formCtx.getDefnTheme();
  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const helperTextVar = defn.helperTextVar;
  const showCapturedAsHelperText = defn.showCapturedValuesOnAside;

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();

  const position = defn.position ?? defn.positionVar;
  const labelPlacement = defn.labelPlacement ?? defn.labelPlacementVar;

  const {
    getFieldRequired,
    getFieldShowAsCheckbox,
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const showAsCheckbox = getFieldShowAsCheckbox();

  const sectionVariant = formSection.sectionVariant;
  const fieldVariant = defnTheme.fieldVariant;
  const isError = isTouched && Boolean(error);
  const readOnly = formCtx.isFieldDisable(defn as DefnFieldEditable)
    || formCtx.isFieldReadonly(defn)
    || !Boolean(defn.metaId);

  const getOnClick = formCtx.getOnClick();

  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const onChangeBool = useCallback((value: boolean) =>
  {
    onChange({
      ...fieldValue,
      value: value
    } as FieldValueSwitch);
  }, [fieldValue]);

  return (
    <FieldRawTemplate
      defn={defn}
      fieldValue={fieldValue}
    >
      <FormControl
        fullWidth
        variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
        error={isError}
      >
        <LayoutFlexRow
          width={"100%"}
          justifyContent={"space-between"}
          overflowY={"visible"}
          overflowX={"visible"}
          onClick={onClick}
        >
          <FormControlLabel
            label={defn.hideLabel ? undefined : label}
            disabled={readOnly}
            labelPlacement={labelPlacement as "end" | "start" | "top" | "bottom" ?? "end"}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: position,
              justifyContent: position
            }}
            control={
              showAsCheckbox
                ? <LocalCheckbox
                  defnCheckbox={defn}
                  defnTheme={defnTheme}
                  formCtx={formCtx}
                  value={fieldValue?.value}
                  onChange={onChangeBool}
                  onBlur={props.onBlur}
                  sectionVariant={sectionVariant}
                  fieldVariant={fieldVariant}
                  readOnly={readOnly}
                  onClick={onClick}
                  required={required}
                />
                : <Switch
                  onChange={(e) => onChangeBool(e.target.checked)}
                  checked={fieldValue?.value}
                  size={"small"}
                  readOnly={readOnly}
                  disabled={readOnly}
                  onClick={onClick}
                  required={required}
                  onKeyDown={(event) =>
                  {
                    if(event.key === "Enter")
                    {
                      onChangeBool(!fieldValue?.value);
                    }
                  }}
                />
            }
          />
          <FieldRawRefButton
            defn={defn}
          />
        </LayoutFlexRow>
        {
          <RawCaptureExtraProperties
            captureTime={fieldValue?.captureTime}
            captureUser={fieldValue?.captureUser}
            captureLocation={fieldValue?.captureLocation}
            showCapturedAsHelperText={showCapturedAsHelperText}
          />
        }
        {
          isError ? (
            <FormHelperText
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: 1,
                marginLeft: px(gapStd)
              }}
              error={isError}
            >
              {error?.message}
            </FormHelperText>
          ) : (
            <FormHelperText
              sx={{
                ml: 0,
                mt: 0,
                color: theme.common.color("textSecondary"),
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: 1,
                marginLeft: px(gapStd)
              }}
              error={false}
            >
              {getFieldHelperText()}
            </FormHelperText>
          )
        }
      </FormControl>
    </FieldRawTemplate>
  );
}

function LocalCheckbox(props: {
  defnCheckbox: DefnFieldSwitch,
  defnTheme: DefnDtoFormTheme,
  formCtx: IFormCtx,
  value?: boolean;
  onChange: (value: boolean) => void;
  onBlur: Noop,
  sectionVariant?: EnumDefnThemeSectionVariant,
  fieldVariant?: EnumDefnThemeFieldVariant,
  readOnly?: boolean,
  required?: boolean,
  onClick?: () => void;
})
{
  return (
    <Checkbox
      autoFocus={Boolean(props.defnCheckbox.autoFocus)}
      disabled={props.readOnly}
      size={"small"}
      disableRipple={true}
      sx={{
        pt: 0,
        pb: 0
      }}
      checked={props.value ?? false}
      onBlur={props.onBlur}
      onChange={(_, checked) => props.onChange(checked)}
      readOnly={props.readOnly}
      onClick={props.onClick}
      required={props.required}
      onKeyDown={(event) =>
      {
        if(event.key === "Enter")
        {
          props.onChange(!props.value);
        }
      }
      }
    />
  );
}
