import {EntId} from "../../../../api/meta/base/Types";
import {MsgSpreadsheetHistory} from "../../../../api/studio/studioMain/msg/MsgSpreadsheetHistory";
import {RpcStudioMain} from "../../../../api/studio/studioMain/RpcStudioMain";
import {SigEntDashboardData} from "../../../../api/studio/studioMain/sig/SigEntDashboardData";
import {SigSpreadsheetHistory} from "../../../../api/studio/studioMain/sig/SigSpreadsheetHistory";
import ISrvc from "../../../../base/ISrvc";
import {Srvc} from "../../../Srvc";

export default class SrvcControlPanelManage extends ISrvc
{
  rpcSpreadsheetHistoryGet(
    entId: EntId,
    cbSuccess?: (sig: SigSpreadsheetHistory) => void,
    cbError?: () => void
  )
  {
    const msg: MsgSpreadsheetHistory = {};

    RpcStudioMain.spreadsheetHistoryGet(entId, msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        // Srvc.app.toast.showErrorToast(envSig);
        cbError && cbError();
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }

  rpcEntDashboardDataGet(
    entId: EntId,
    cbSuccess?: (sig: SigEntDashboardData) => void,
    cbError?: () => void
  )
  {
    RpcStudioMain.entDashboardDataGet(entId, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        cbError && cbError();
      }
      else if(envSig.sig)
      {
        cbSuccess && cbSuccess(envSig.sig);
      }
    });
  }
}
