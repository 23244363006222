import {useTheme} from "@mui/material";
import {FormHelperText} from "@mui/material";
import {Box} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {OverridableStringUnion} from "@mui/types";
import {useMemo} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {EnumDefnThemeDirection} from "../../../api/meta/base/Types";
import {defnTheme} from "../../../base/plus/FormPlus";
import {px} from "../../../base/plus/StringPlus";
import RawHighlighter from "./RawHighlighter";

export default function RawLabel(props: {
  defn: DefnFieldLabel,
  error?: FieldError,
  isReport?: boolean,
  bold?: boolean,
  italic?: boolean,
  strikeThrough?: boolean,
  underline?: boolean,
  opacity?: number,
  textSize?: string,
  value?: string,
  sectionDirection?: EnumDefnThemeDirection
})
{
  const theme = useTheme();
  const defn = props.defn;

  const bold = props.bold;
  const italic = props.italic;
  const strikeThrough = props.strikeThrough;
  const underline = props.underline;
  const opacity = props.opacity;
  const value = props.value;
  const sectionDirection = props.sectionDirection;

  const textDecoration = strikeThrough
    ? "line-through"
    : underline
      ? "underline"
      : undefined;

  const isReport = props.isReport;
  const error = props.error;
  const isError = Boolean(error);
  const variant = props.textSize as OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>
    || (isReport
    === true ? "caption" : "subtitle1") as OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;

  const dtoColor = defn.bgColor ?? defn.bgColorVar;
  const pickTextColor = dtoColor
    ? (dtoColor.value && dtoColor.shade
      ? theme.common.colorWithShade(dtoColor.value, dtoColor.shade)
      : (dtoColor.value
        ? theme.common.color(dtoColor.value)
        : undefined))
    : undefined;

  const color = useMemo(() =>
  {
    if(isError)
    {
      return "error";
    }
    else if(defn.disabled)
    {
      return "textDisabled";
    }
    else if(defn.colorVar)
    {
      return defn.colorVar.value;
    }
    return "textPrimary";
  }, [defn.colorVar, defn.disabled, isError]);

  return (
    <>
      <Box
        flexGrow={1}
        display={"flex"}
        sx={{
          justifyContent: defn.justifyText ?? defn.justifyTextVar,
          alignItems: "center",
          opacity: (opacity && opacity >= 0 && opacity <= 1) ? opacity : 1,
          pt: defn.pt !== undefined ? px(defn.pt) : 0,
          pb: defn.pb !== undefined ? px(defn.pb) : 0,
          pl: defn.pl !== undefined ? px(defn.pl) : 0,
          pr: defn.pr !== undefined ? px(defn.pr) : 0,
          backgroundColor: pickTextColor,
          height: sectionDirection === "horizontal"
            ? defnTheme.fieldSize === "small"
              ? px(theme.common.smallFieldSize)
              : px(theme.common.mediumFieldSize)
            : undefined
        }}
      >
        <RawHighlighter
          variant={variant}
          value={value}
          bold={bold}
          italic={italic}
          color={color}
          breakWords={true}
          noWrap={true}
          textDecoration={textDecoration as StandardCSSProperties["textDecoration"]}
          textAlign={defn.justifyText ?? defn.justifyTextVar}
        />
      </Box>
      {isError && <FormHelperText
        error={isError}
        children={error?.message}
      />}
    </>
  );
}
