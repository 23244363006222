import {SwipeableDrawer} from "@mui/material";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useRef} from "react";
import React from "react";
import {useDeviceType} from "../app/AppHooks";
import {DividerVertical} from "../atom/layout/DividerVertical";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../atom/layout/LayoutFlexRow";
import {useSsBrCtx} from "./base/CtxSsBr";

interface ITemplateSpreadSheetBrProps
{
  search: React.ReactNode;
  filters?: React.ReactNode;
  main: React.ReactNode;
  footer: React.ReactNode;
}

export default function TemplateSsBr(props: ITemplateSpreadSheetBrProps)
{
  const deviceType = useDeviceType();

  switch(deviceType)
  {
    case "mobile":
      return <TemplateSsBrMobile {...props} />;
    default:
      return <TemplateSsBrDesktop {...props} />;
  }
}

function TemplateSsBrDesktop(props: ITemplateSpreadSheetBrProps)
{
  const theme = useTheme();

  return (
    <LayoutFlexCol
      width={"100%"}
      flexGrow={1}
    >
      {props.search}

      <LayoutFlexRow
        width={"100%"}
        flexGrow={1}
      >
        {
          props.filters &&
          <LayoutFlexCol
            width={"clamp(200px,30vw,280px)"}
            bgcolor={theme.common.bgcolorContent}
            height={"100%"}
          >
            {
              props.filters
            }
          </LayoutFlexCol>
        }

        <DividerVertical />

        <LayoutFlexCol
          height={"100%"}
          width={"clamp(60%,65%,70%)"}
          flexGrow={1}
        >
          {props.main}
          {props.footer}
        </LayoutFlexCol>
      </LayoutFlexRow>
    </LayoutFlexCol>
  );
}

function TemplateSsBrMobile(props: ITemplateSpreadSheetBrProps)
{
  const theme = useTheme();
  const spreadSheetBrCtx = useSsBrCtx();
  const open = spreadSheetBrCtx.getFilterOverlay();
  const ref = useRef(null);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) =>
  {
    if(event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift"))
    {
      return;
    }

    spreadSheetBrCtx.setFilterOverlay({filterOverlay: open ? !open : open});
  };

  return (
    <LayoutFlexCol
      width={"100%"}
      flexGrow={1}
      bgcolor={theme.common.bgcolorSidePane}
    >
      {props.search}

      <Box
        position={"relative"}
        ref={ref}
        width={"100%"}
        flexGrow={1}
      >
        <SwipeableDrawer
          anchor={"right"}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          PaperProps={{
            style: {
              width: "75%",
              position: "absolute"
            }
          }}
          BackdropProps={{style: {position: "absolute"}}}
          ModalProps={{
            container: ref.current,
            style: {position: "absolute"},
            keepMounted: true
          }}
          swipeAreaWidth={0}
          variant={"temporary"}
        >
          {props.filters}
        </SwipeableDrawer>

        <LayoutFlexCol
          height={"100%"}
          width={"100%"}
          flexGrow={1}
        >
          {props.main}
          {props.footer}
        </LayoutFlexCol>
      </Box>
    </LayoutFlexCol>
  );
}
