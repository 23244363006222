import {SysId} from "../../api/meta/base/SysId";
import {EntId} from "../../api/meta/base/Types";
import {ChatId} from "../../api/meta/base/Types";
import {TypeListItemId} from "./list/TypesList";

export type TypeComboId = TypeListItemId; // entId + aboutId
export type TypeComboIdGroup = TypeComboId;
export type TypeComboIdUser = TypeComboId;
export type TypeComboIdChat = TypeComboId;

export interface DtoComboId
{
  chatId: ChatId;
  entId: EntId;
}

export function dtoToComboId<AboutId extends SysId>(split: DtoComboId): TypeComboId
{
  return toComboId(split.entId, split.chatId);
}

export function toComboId(entId: EntId, aboutId: SysId): TypeComboId
{
  return entId + "<>" + aboutId;
}

export function toComboIdDto(comboId: TypeComboId): DtoComboId
{
  const split = comboId.split("<>") as SysId[];
  return {
    entId: split[0] as EntId,
    chatId: split[1] as SysId
  };
}

export function comboToEntId(comboId: TypeComboId): EntId
{
  return toComboIdDto(comboId).entId;
}

export function comboToAboutId<AboutId extends SysId>(comboId: TypeComboId): AboutId
{
  const split = toComboIdDto(comboId);
  return split.chatId as AboutId;
}

export function isComboId(comboId: TypeComboId)
{
  return comboId.split("<>").length === 2;
}


