import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";

const homeRecentList = "homeRecentList";
const homeAddressBook = "homeAddressBook";
const homeNewChat = "homeNewChat";
const homeStarMessages = "homeStarMessages";
const homeCallerUserSetting = "homeCallerUserSetting";

export function reducersDrawer()
{
  return combineReducers({
    homeRecentList: createListSlice(homeRecentList, {defaultListItemType: "aps"}).reducer,
    homeCallerUserSetting: createListSlice(homeCallerUserSetting, {defaultListItemType: "aps"}).reducer,
    homeAddressBook: createListSlice(homeAddressBook, {defaultListItemType: "aps"}).reducer,
    homeDeviceList: createListSlice("homeDeviceList", {defaultListItemType: "ps"}).reducer,
    homeNewChat: createListSlice(homeNewChat,
      {
        defaultListItemType: "aps",
        canShowMenu: false
      }
    ).reducer,
    homeNewGroup: createListSlice("homeNewGroup",
      {
        defaultListItemType: "aps",
        pickType: "pickMany",
        canShowMenu: false
      }
    ).reducer,
    homeFilter: createListSlice("homeFilter",
      {
        defaultListItemType: "aps",
        pickType: "pickMany"
      }
    ).reducer,
    homeSubscriptions: createListSlice("homeSubscriptions", {defaultListItemType: "aps"}).reducer,
    searchList: createListSlice("homeSearch",
      {
        defaultListItemType: "aps",
        externalSearch: true
      }
    ).reducer,
    starMessageList: createListSlice(homeStarMessages).reducer
  });
}

export function onActionMainBack()
{
  dispatchList(homeRecentList, listSetSelectedItemId());
  dispatchList(homeAddressBook, listSetSelectedItemId());
  dispatchList(homeNewChat, listSetSelectedItemId());
  dispatchList(homeStarMessages, listSetSelectedItemId());
  dispatchList(homeCallerUserSetting, listSetSelectedItemId());
}
