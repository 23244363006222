import {combineReducers} from "@reduxjs/toolkit";
import {createListSlice} from "../../../base/plus/ListPlus";
import {reducersEntAside} from "./StoreStudioEntAside";

export function reducersEnt()
{
  return combineReducers({
    entVariableList: createListSlice("entVariableList", {defaultListItemType: "ps", draggable: true}).reducer,
    entTranslationList: createListSlice("entTranslationList", {defaultListItemType: "p"}).reducer,
    entImportList: createListSlice("entImportList", {defaultListItemType: "aps", draggable: true}).reducer,
    entRoleList: createListSlice("entRoleList", {defaultListItemType: "ps", draggable: true}).reducer,
    entGroupList: createListSlice("entGroupList", {draggable: true}).reducer,
    entSpreadsheetList: createListSlice("entSpreadsheetList", {defaultListItemType: "ps", draggable: true}).reducer,
    entActionList: createListSlice("entActionList", {defaultListItemType: "ps", draggable: true}).reducer,
    entActionPermissionMatrixMapList: createListSlice("entActionPermissionMatrixMapList",
      {defaultListItemType: "p"}).reducer,
    entReportList: createListSlice("entReportList", {defaultListItemType: "ps", draggable: true}).reducer,
    entDeeplinkList: createListSlice("entDeeplinkList", {defaultListItemType: "ps", draggable: true}).reducer,
    entDriveList: createListSlice("entDriveList", {defaultListItemType: "ps", draggable: true}).reducer,
    entPluginList: createListSlice("entPluginList", {defaultListItemType: "ps"}).reducer,
    entUserList: createListSlice("entUserList").reducer,
    entAdminList: createListSlice("entAdminList", {defaultListItemType: "ps"}).reducer,
    entFormSectionList: createListSlice("entFormSectionList",
      {
        defaultListItemType: "p",
        showEmptyGroups: true,
        draggable: true
      }
    ).reducer,
    entFormSectionPickerList: createListSlice("entFormSectionPickerList", {defaultListItemType: "p"}).reducer,
    entFormList: createListSlice("entFormList", {draggable: true}).reducer,
    entPromptList: createListSlice("entPromptList", {defaultListItemType: "p"}).reducer,
    entAutomationList: createListSlice("entAutomationList", {defaultListItemType: "ps", draggable: true}).reducer,
    entAutomationEventList: createListSlice("entAutomationEventList",
      {
        defaultListItemType: "listGroup",
        showEmptyGroups: true,
        draggable: true
      }
    ).reducer,
    entDeployPluginList: createListSlice("entDeployPluginList", {defaultListItemType: "ps"}).reducer,
    entDeployVariableList: createListSlice("entDeployVariableList", {defaultListItemType: "ps"}).reducer,
    entDeployPaymentProvider: createListSlice("entDeployPaymentProvider", {defaultListItemType: "ps"}).reducer,
    aside: reducersEntAside()
  });
}
