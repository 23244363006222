import ISrvc from "../../base/ISrvc";
import SrvcCacheHomeDrawer from "./drawer/SrvcCacheHomeDrawer";

export default class SrvcCacheHome extends ISrvc
{
  public readonly drawer = new SrvcCacheHomeDrawer();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.drawer
    );
  }
}
