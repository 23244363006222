import {FieldValues} from "react-hook-form";
import {StudioFieldHyperlinkRow} from "../../../../../api/meta/base/dto/StudioFieldHyperlinkRow";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyHyperlinkFieldIdSet} from "../../base/TypesFormBuilder";
import {propKeyDisplayTextVarId} from "../../base/TypesFormBuilder";
import {fieldSpreadsheet} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";

export function getDefnFieldHyperlinkRow(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "hyperlinkRow",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    )
  };
}

export function defnValueToStudioFieldHyperlinkRow(values: FieldValues): StudioFieldHyperlinkRow
{
  return {
    type: "hyperlinkRow",
    spreadsheetId: fnFieldValueToRawValue("pickSpreadsheetId", values[fieldSpreadsheet]),
    displayTextVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[propKeyDisplayTextVarId]),
    hyperlinkFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[propKeyHyperlinkFieldIdSet])
  } as StudioFieldHyperlinkRow;
}

export function studioFieldHyperlinkRowToDefnValue(studioField: StudioFieldHyperlinkRow): FieldValues
{
  return {
    [fieldSpreadsheet]: fnRawValueToFieldValue("pickSpreadsheetId", studioField.spreadsheetId),
    [propKeyDisplayTextVarId]: fnRawValueToFieldValue("studioVarIdTextEditor", studioField.displayTextVarId),
    [propKeyHyperlinkFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", studioField.hyperlinkFieldIdSet)
  };
}
