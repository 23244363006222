import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldText} from "../../../api/meta/base/dto/DefnFieldText";
import {StudioVarText} from "../../../api/meta/base/dto/StudioVarText";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {findWordsBetweenDollarBraces} from "../../../base/plus/StringPlus";
import {VAR_TEXT_MAX_LENGTH} from "../../atom/assets/HelperTextStudio";

export function fnVariableBuilderText(fieldValueText: string)
{
  function getVarComp()
  {
    return {
      [fieldValueText]: {
        type: "text",
        metaId: fieldValueText,
        name: "Value",
        required: true,
        helperText: VAR_TEXT_MAX_LENGTH
      } as DefnFieldText
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    entVariable: StudioVarText
  )
  {
    const varValue = entVariable.value;
    if(varValue)
    {
      valueMap[fieldValueText] = fnRawValueToFieldValue("text", varValue.value);
    }
  }

  function defnToDto(
    valueMap: FieldValues
  )
  {
    const value = fnFieldValueToRawValue("text", valueMap[fieldValueText]) as string;
    const val = findWordsBetweenDollarBraces(value);
    const paramSet = val.paramSet;

    return {
      value: {
        value: val.queryStr,
        paramSet: isEmpty(paramSet)
          ? undefined
          : paramSet
      }
    } as StudioVarText;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}
