import {IconButton} from "@mui/material";
import React from "react";
import {px} from "../../../base/plus/StringPlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import RawIcon from "./RawIcon";
import RawTooltip from "./RawTooltip";

export default function RawIconButton(props: {
  name: string,
  icon: string,
  enableRipple?: boolean,
  onClick?: (name: string, e: React.MouseEvent<HTMLButtonElement>) => void,
  disabled?: boolean,
  color?: TypeTextColor,
  width?: string,
  padding?: number,
  tooltip?: string
})
{
  const name = props.name;
  const onClick = props.onClick;
  const padding = props.padding;
  const tooltip = props.tooltip;

  return (
    <RawTooltip title={tooltip}>
      <IconButton
        size={"small"}
        disableRipple={!props.enableRipple}
        onClick={onClick
          ? (e) =>
          {
            e.stopPropagation();
            onClick(name, e);
          }
          : undefined}
        sx={{
          ...(padding !== undefined) && {
            padding: px(padding)
          }
        }}
      >
        <RawIcon
          key={name}
          icon={props.icon}
          width={props.width}
          color={props.color}
          disabled={props.disabled}
        />
      </IconButton>
    </RawTooltip>
  );
}
