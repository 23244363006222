import {MsgCliCode} from "../../api/app/cli/msg/MsgCliCode";
import {MsgCliGetCode} from "../../api/app/cli/msg/MsgCliGetCode";
import {MsgCliSuggestion} from "../../api/app/cli/msg/MsgCliSuggestion";
import {RpcCli} from "../../api/app/cli/RpcCli";
import {SigCliGetCode} from "../../api/app/cli/sig/SigCliGetCode";
import {SigCliSuggestion} from "../../api/app/cli/sig/SigCliSuggestion";
import {WsocCli} from "../../api/app/cli/WsocCli";
import {newGuidBig} from "../../api/meta/base/NanoId";
import {SysId} from "../../api/meta/base/SysId";
import {TimeZoneKey} from "../../api/meta/base/Types";
import {ArtifactId} from "../../api/meta/base/Types";
import {getErrorMessage} from "../../api/nucleus/base/Protocol";
import ISrvc from "../../base/ISrvc";
import {getLocalDateFormat} from "../../base/plus/DatePlus";
import {getFirstUtcValue} from "../../base/plus/DatePlus";
import {updateLastArtifactMap} from "../../cache/terminal/SliceCacheTerminal";
import {updateSuggestionMap} from "../../cache/terminal/SliceCacheTerminal";
import {addLogItemList} from "../../cache/terminal/SliceCacheTerminal";
import {removeLogItemList} from "../../cache/terminal/SliceCacheTerminal";
import {store} from "../../Store";
import {Srvc} from "../Srvc";

export default class SrvcTerminal extends ISrvc
{
  putCliCode(cliCode: string, displayDateFormat: string, artifactId: ArtifactId, cbSuccess?: () => void)
  {
    const msg: MsgCliCode = {
      artifactId: artifactId,
      cliCode: cliCode,
      cliCodeId: newGuidBig(),
      timeZone: getFirstUtcValue() as TimeZoneKey,
      displayDateFormat: displayDateFormat
    };

    WsocCli.putCliCode(msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
      }
      else if(envSig.sig)
      {
        const sig = envSig.sig;
        const cliCodeId = sig.cliCodeId;

        if(cliCodeId)
        {
          if(sig.lastDeployUnitId && sig.lastArtifactName)
          {
            store.dispatch(updateLastArtifactMap({
              entId: artifactId,
              value: {
                artifactId: sig.lastDeployUnitId,
                artifactName: sig.lastArtifactName
              }
            }));
          }

          if(sig.appendItemList?.children)
          {
            store.dispatch(addLogItemList({
              artifactId: artifactId,
              logItemList: sig.appendItemList?.children
            }));
          }
        }

        cbSuccess && cbSuccess();
      }
      return;
    });
  }

  getCliSuggestions(
    artifactId: ArtifactId,
    code: string,
    column: number,
    lineNumber: number,
    callback: (sig: SigCliSuggestion) => void)
  {
    const msg: MsgCliSuggestion = {
      code,
      column,
      lineNumber
    };

    WsocCli.getCliSuggestion(msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
      }
      else if(envSig.sig)
      {
        const sig = envSig.sig;
        callback(sig);
        store.dispatch(updateSuggestionMap(sig.tokenMap));
      }
    });
  }

  getCliCode(
    artifactId: ArtifactId,
    metaId?: SysId,
    cb?: (sig: SigCliGetCode) => void
  )
  {
    const msg: MsgCliGetCode = {
      artifactId: artifactId,
      metaId: metaId,
      timeZone: getFirstUtcValue() as TimeZoneKey,
      displayDateFormat: getLocalDateFormat()
    };

    RpcCli.getCliCode(msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
      }
      else if(envSig.sig)
      {
        const sig = envSig.sig;
        cb && cb(sig);
      }
    });
  }

  clear(artifactId: ArtifactId)
  {
    store.dispatch(removeLogItemList({
      artifactId: artifactId
    }));
  }
}
