import {useTheme} from "@mui/material";
import {TextField} from "@mui/material";
import {Chip} from "@mui/material";
import {Box} from "@mui/material";
import {Autocomplete} from "@mui/material";
import {FieldSection} from "@mui/x-date-pickers";
import {BaseSingleInputFieldProps} from "@mui/x-date-pickers";
import {TimePicker} from "@mui/x-date-pickers";
import {PickerValidDate} from "@mui/x-date-pickers/models/pickers";
import {format} from "date-fns";
import {isArray} from "lodash";
import {useMemo} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {FieldChipSetTime} from "../../../api/meta/base/dto/FieldChipSetTime";
import {AnyTime} from "../../../api/meta/base/Types";
import {extractTimeFormat} from "../../../base/plus/DatePlus";
import {toTimeStringToDate} from "../../../base/plus/DatePlus";
import {formatCaptionOnlyTime} from "../../../base/plus/DatePlus";
import {px} from "../../../base/plus/StringPlus";
import IFormCtx from "../../form/viewer/base/CtxForm";
import {getRawIcon} from "./RawIcon";
import RawLocalizationProvider from "./RawLocalizationProvider";

export default function RawTimeSet(props: {
  onChange: (values: FieldChipSetTime) => void,
  formCtx: IFormCtx,
  fieldId: string,
  value?: FieldChipSetTime
  readonly?: boolean,
  disabled?: boolean,
  label?: string,
  placeHolder?: string,
  helperText?: string,
  error?: FieldError,
  name?: string,
  allowDuplicate?: boolean,
  inputFieldSize?: "small" | "medium",
  icon?: string
  hideLabel?: boolean,
  timeFormat?: string,
  is12hrFormat?: boolean,
})
{
  const {
    value,
    onChange,
    readonly,
    disabled,
    allowDuplicate,
    icon,
    hideLabel,
    timeFormat,
    is12hrFormat
  } = props;

  const fieldValueTimeSet = useMemo(() => value?.valueSet && isArray(value.valueSet)
    ? value.valueSet
    : [], [value?.valueSet]);

  const onAccept = useCallback((inputValue: any) =>
  {
    if(inputValue)
    {
      const newValue = dateToFieldValueTime(inputValue);
      const updatedValue = [...fieldValueTimeSet, newValue];

      if(!allowDuplicate && fieldValueTimeSet && fieldValueTimeSet.length > 0)
      {
        fieldValueTimeSet.indexOf(dateToFieldValueTime(inputValue)) === -1 &&
        onChange({
          valueSet: updatedValue
        });
      }
      else
      {
        onChange({
          valueSet: updatedValue
        });
      }
    }
  }, [allowDuplicate, fieldValueTimeSet, onChange]);

  return (
    <RawLocalizationProvider>
      <TimePicker
        readOnly={readonly}
        disabled={disabled}
        timeSteps={{
          hours: 1,
          minutes: 1,
          seconds: 1
        }}
        slots={{
          field: AutocompleteField
        }}
        ampm={is12hrFormat}
        views={["hours", "minutes"]}
        slotProps={{
          field: () => ({
            ...props,
            onChangeField: onChange,
            openPickerIcon: getRawIcon(icon ?? "InsertInvitation"),
            enableAccessibleFieldDOMStructure: false
          })
        }}
        onAccept={onAccept}
      />
    </RawLocalizationProvider>
  );
}

type AutoCompleteFieldProps = BaseSingleInputFieldProps<any, PickerValidDate, FieldSection, boolean, any> &
  {
    /**
     * @typescript-to-proptypes-ignore
     */
    onChangeField: (values: FieldChipSetTime) => void,
    openPickerIcon: React.ReactNode,
    value?: FieldChipSetTime
    readonly?: boolean,
    disabled?: boolean,
    label?: string,
    placeHolder?: string,
    helperText?: string,
    error?: FieldError,
    name?: string,
    allowDuplicate?: boolean,
    inputFieldSize?: "small" | "medium",
    fieldId: string,
    formCtx: IFormCtx,
    hideLabel?: boolean,
    timeFormat?: string
  }

function AutocompleteField(props: AutoCompleteFieldProps)
{
  const {
    label,
    disabled,
    readOnly,
    value,
    onChangeField,
    error,
    placeHolder,
    inputFieldSize,
    helperText,
    InputProps: {
      ref,
      endAdornment
    } = {},
    inputProps,
    formCtx,
    fieldId,
    hideLabel,
    timeFormat
  } = props;

  const fieldValueTimeSet = value?.valueSet && isArray(value.valueSet)
    ? value.valueSet
    : [];

  const theme = useTheme();

  const onDelete = useCallback((chipToDelete: string) => () =>
  {
    if(value)
    {
      const chips = value.valueSet.filter((currChip: AnyTime) =>
        fieldValueTimeToDate(currChip, timeFormat) !== chipToDelete);

      onChangeField({
        valueSet: chips
      });
    }
  }, [onChangeField, value]);

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  return (
    <Autocomplete
      multiple
      fullWidth={true}
      freeSolo
      readOnly={true}
      ref={ref}
      disabled={disabled}
      renderTags={(chips: string[]) =>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.7
          }}
        >
          {
            chips.map((chip, index) => (
              <Chip
                key={chip + index}
                label={chip}
                onDelete={!readOnly
                  ? onDelete(chip)
                  : undefined}
                sx={{
                  height: px(theme.common.chipHeight),
                  borderRadius: "4px",
                  "& .MuiSvgIcon-root": {
                    height: px(theme.common.chipCrossButtonSize),
                    fontSize: px(theme.common.chipCrossButtonSize)
                  }
                }}
              />
            ))
          }
        </Box>
      }
      renderInput={(params) =>
      {
        return <TextField
          {...params}
          inputProps={{...params.inputProps, ...inputProps}}
          InputProps={{
            ...params.InputProps,
            endAdornment: endAdornment || params.InputProps.endAdornment
          }}
          onClick={onClick}
          sx={{
            "& .MuiInputAdornment-positionEnd": {
              "& .MuiButtonBase-root": {
                position: "absolute",
                right: theme.common.gapStd
              }
            },
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          label={hideLabel ? undefined : label}
          placeholder={placeHolder}
          helperText={error?.message ? error.message : helperText}
          error={Boolean(error?.message)}
          aria-readonly={readOnly}
          disabled={disabled}
          size={inputFieldSize ?? "small"}
        />;
      }}
      options={[]}
      value={createDateChipStrSet(fieldValueTimeSet, timeFormat)}
    />
  );

}

function dateToFieldValueTime(date: Date): AnyTime
{
  return date.toTimeString().split(" ")[0];
}

function createDateChipStrSet(timeSet?: string[], timeFormat?: string)
{
  const values = [] as string[];

  if(Array.isArray(timeSet))
  {
    timeSet.forEach((time) =>
    {
      if(time)
      {
        const timeStr = fieldValueTimeToDate(time, timeFormat);
        if(timeStr)
        {
          values.push(timeStr);
        }
      }
    });
  }

  return values;
}

function fieldValueTimeToDate(fieldValueTime: AnyTime, timeFormat?: string)
{
  const date = toTimeStringToDate(fieldValueTime);
  if(date)
  {
    if(timeFormat)
    {
      return format(date, extractTimeFormat(timeFormat, false));
    }
    else
    {
      return formatCaptionOnlyTime(date, false);
    }
  }
}

