import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {DtoMessagePayloadSpreadsheetRow} from "../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRow";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {isGlobal} from "../../../api/meta/base/ApiPlus";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {MessageId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {getMsgPayloadSpreadsheetId} from "../../../base/plus/BubblePlus";
import {getFormNameColor} from "../../../base/plus/FormPlus";
import {createListItemChat} from "../../../base/plus/ListPlus";
import {getChatItem} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listChatSetIfExistHeader} from "../../../base/slices/list/SliceListChatAction";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinderSeven} from "../../../base/types/list/TypesList";
import {IListData} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {toComboId} from "../../../base/types/TypesComboId";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {ICacheStarMessage} from "../../../cache/app/starMsg/TypesCacheStarMsgList";
import {ICacheStarMsgListState} from "../../../cache/app/starMsg/TypesCacheStarMsgList";
import {textUserOrYou} from "../../../Store";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import SrvcHomeListChat from "../app/SrvcHomeListChat";

type TypeSigAvatar = SigGroupAvatar | SigUserAvatar | SigEntAvatarUser | undefined;
export default class SrvcHomeDrawerStarMessages extends SrvcHomeListChat
{

  constructor()
  {
    super((state: RootState) => state.home.drawer.starMessageList);
  }

  load(listName: string, starMsgMap: ICacheStarMsgListState, filter: EntId[])
  {
    const rootState = store.getState();
    const uiItemsById = {};
    const uiItemIds = [] as MessageId[];
    starMsgMap.starMsgList.forEach(messageId =>
    {
      uiItemIds.push(messageId);
      const starMsg = starMsgMap.starMsgMap[messageId];
      this.doLoadMsg(rootState, starMsg, uiItemsById);
    });
    const searchWords = this.selectList(rootState).searchWords;

    dispatchList(listName, listRefresh({
      searchWords: searchWords,
      itemsById: uiItemsById,
      itemIds: uiItemIds,
      userField: this.getHomeListChatUserField(Object.values(uiItemsById) as IListItemChat[])
    } as IListData));
  };

  getListBinder()
  {
    return {

      selectSourceItem1: this.selectGroupAvatar.bind(this),
      onBindSourceItem1: this.onBindGroupAvatar.bind(this),

      selectSourceItem2: this.selectUserAvatar.bind(this),
      onBindSourceItem2: this.onBindUserAvatar.bind(this),

      selectSourceItem3: this.selectDefnForm.bind(this),
      onBindSourceItem3: this.onBindDefnForm.bind(this),

      selectSourceItem4: this.selectIsStarMsg.bind(this),
      onBindSourceItem4: this.onBindIsStarMsg.bind(this),

      selectSourceItem5: this.selectCanShowComments.bind(this),
      onBindSourceItem5: this.onBindCanShowComments.bind(this),

      selectSourceItem6: this.selectChatPattern.bind(this),
      onBindSourceItem6: this.onBindChatPattern.bind(this),

      selectSourceItem7: this.selectReply.bind(this),
      onBindSourceItem7: this.onBindReply.bind(this),

      selectUserAvatar: this.selectEntUserAvatar.bind(this)
    } as IListBinderSeven<TypeSigAvatar, TypeSigAvatar, DefnForm, boolean, boolean, SigSpreadsheetRow, SigUserAvatar>;
  }

  private doLoadMsg(rootState: RootState, starMsg: ICacheStarMessage, uiItemsById: IListItemsById)
  {
    const entId = starMsg.entId;
    const chatId = starMsg.chatId;
    const messageId = starMsg.messageId;
    let canExpire: boolean | undefined = undefined;
    if(starMsg)
    {
      const item = createListItemChat(entId, chatId, starMsg);
      const entUserId = selectCallerEnt(rootState, entId)?.entUserId;

      const messageType = item.sig.payload.messageType;
      const sigSpreadsheetRow = (item.sig.payload as DtoMessagePayloadSpreadsheetRow)?.spreadsheetRow;

      if(messageType === "spreadsheetRow" || messageType === "spreadsheetPartition")
      {

        const spreadsheetId = getMsgPayloadSpreadsheetId(item.sig.payload);
        canExpire = spreadsheetId
          ? selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.canExpire
          : undefined;
      }

      const entAvatar = !isGlobal(entId)
        ? rootState.cache.app.caller.entIdUserAvatarMap[entId]
        : undefined;

      uiItemsById[messageId] = {
        ...item,
        sig: {
          ...item.sig,
          header: {
            ...item.sig.header,
            ...entAvatar?.name && {headerTextLeft1: entAvatar.name},
            alwaysShowHeader: true
          },
          formBubbleTitleColor: getFormNameColor(
            entUserId,
            sigSpreadsheetRow?.formValue?.updatedBy,
            sigSpreadsheetRow?.updatedKeySet
          ),
          canExpire: canExpire
        }
      } as IListItemChat;
    }
  }

  //region binder

  private selectUserAvatar(rootState: RootState, messageId: MessageId): TypeSigAvatar
  {
    const chatItem = getChatItem(rootState, messageId, this.selectList) as IListItemChat;
    const entId = chatItem?.entId;
    const senderId = chatItem?.sig.senderId;
    const entUserId = toComboId(entId, senderId);

    return rootState.cache.app.user.userAvatarMap[entUserId];
  }

  private onBindUserAvatar(listName: string, messageId: MessageId, avatar?: TypeSigAvatar): void
  {
    if(avatar)
    {
      const rootState = store.getState();
      const chatItem = getChatItem(rootState, messageId, this.selectList) as IListItemChat;
      dispatchList(listName, listChatSetIfExistHeader({
        itemId: messageId,
        header: {
          ...chatItem.sig.header,
          headerTextLeft3: textUserOrYou(rootState, avatar as SigUserAvatar),
          color: (avatar as SigUserAvatar).userColor
        }
      }));
    }
  }

}

