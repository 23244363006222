import {Divider} from "@mui/material";
import {grey} from "@mui/material/colors";
import {CSSProperties} from "react";
import React from "react";

export function DividerVertical(props: {bgcolor?: CSSProperties["borderColor"]})
{
  return (
    <Divider
      orientation={"vertical"}
      sx={{
        height: "100%",
        bgcolor: props.bgcolor ?? grey[100]
      }}
    />
  );
}
