import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {isEqual} from "lodash";
import {cloneDeep} from "lodash";
import {isArray} from "lodash";
import {nextMetaIdField} from "../../../api/meta/base/ApiPlus";
import {StudioComposite} from "../../../api/meta/base/dto/StudioComposite";
import {StudioEnt} from "../../../api/meta/base/dto/StudioEnt";
import {StudioEntAutomationPluginWebhook} from "../../../api/meta/base/dto/StudioEntAutomationPluginWebhook";
import {StudioEntAutomationPluginWebhookEvent} from "../../../api/meta/base/dto/StudioEntAutomationPluginWebhookEvent";
import {StudioEntAutomationScheduled} from "../../../api/meta/base/dto/StudioEntAutomationScheduled";
import {StudioEntAutomationScheduledEvent} from "../../../api/meta/base/dto/StudioEntAutomationScheduledEvent";
import {StudioEntAutomationSpreadsheet} from "../../../api/meta/base/dto/StudioEntAutomationSpreadsheet";
import {StudioEntAutomationSpreadsheetEvent} from "../../../api/meta/base/dto/StudioEntAutomationSpreadsheetEvent";
import {StudioEntAutomationWebhook} from "../../../api/meta/base/dto/StudioEntAutomationWebhook";
import {StudioEntAutomationWebhookEvent} from "../../../api/meta/base/dto/StudioEntAutomationWebhookEvent";
import {StudioEntPlugin} from "../../../api/meta/base/dto/StudioEntPlugin";
import {StudioField} from "../../../api/meta/base/dto/StudioField";
import {StudioFieldEditable} from "../../../api/meta/base/dto/StudioFieldEditable";
import {StudioFieldPickGridRow} from "../../../api/meta/base/dto/StudioFieldPickGridRow";
import {StudioFieldPickReportRow} from "../../../api/meta/base/dto/StudioFieldPickReportRow";
import {StudioFieldRef} from "../../../api/meta/base/dto/StudioFieldRef";
import {StudioFieldRefReport} from "../../../api/meta/base/dto/StudioFieldRefReport";
import {StudioFieldRefUser} from "../../../api/meta/base/dto/StudioFieldRefUser";
import {StudioForm} from "../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../api/meta/base/dto/StudioGrid";
import {StudioMapOfLayoutFormContent} from "../../../api/meta/base/dto/StudioMapOfLayoutFormContent";
import {StudioModuleMap} from "../../../api/meta/base/dto/StudioModuleMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {StudioSection} from "../../../api/meta/base/dto/StudioSection";
import {StudioVarUserSetting} from "../../../api/meta/base/dto/StudioVarUserSetting";
import {newGuid} from "../../../api/meta/base/NanoId";
import {MetaIdLayoutForm} from "../../../api/meta/base/Types";
import {MetaIdPaymentProvider} from "../../../api/meta/base/Types";
import {EnumStudioCompType} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {EnumDefnStoreItem} from "../../../api/meta/base/Types";
import {TimeZoneKey} from "../../../api/meta/base/Types";
import {EnumStoreLabel} from "../../../api/meta/base/Types";
import {
  EntId,
  EnumArrayDefnFields,
  EnumDefnFields,
  LanguageKey,
  MediaIdAvatar,
  MetaId,
  MetaIdField,
  MetaIdForm
} from "../../../api/meta/base/Types";
import {SigEntAdminCaller} from "../../../api/studio/studioMain/sig/SigEntAdminCaller";
import {SigEntAdminList} from "../../../api/studio/studioMain/sig/SigEntAdminList";
import {SigEntUserList} from "../../../api/studio/studioMain/sig/SigEntUserList";
import {SigStudioEnt} from "../../../api/studio/studioMain/sig/SigStudioEnt";
import {SigSysDefnFormMapGet} from "../../../api/studio/studioMain/sig/SigSysDefnFormMapGet";
import {isDeepEqual} from "../../../base/plus/JsPlus";
import {calcDeltaArray} from "../../../base/plus/JsPlus";
import {arrayToRecord, flipRecord} from "../../../base/plus/JsPlus";
import {getSystemFieldTypeSet} from "../../../base/plus/StudioFormPlus";
import {isSystemField} from "../../../base/plus/StudioFormPlus";
import {moveListItemIndex} from "../../../base/plus/StudioPlus";
import {moveListItem} from "../../../base/plus/StudioPlus";
import {Srvc} from "../../../srvc/Srvc";
import {ActionRemoveEntDeployPaymentProvider} from "./TypesCacheStudioEnt";
import {ActionAddEntDeployPaymentProvider} from "./TypesCacheStudioEnt";
import {ActionDragAutomationStep} from "./TypesCacheStudioEnt";
import {ActionDragFormItem} from "./TypesCacheStudioEnt";
import {ActionUpdateEntModuleMap} from "./TypesCacheStudioEnt";
import {IUsageFilter} from "./TypesCacheStudioEnt";
import {ICacheStudioArtifactUsage} from "./TypesCacheStudioEnt";
import {TypeFieldRef} from "./TypesCacheStudioEnt";
import {ActionUpdateEntPromptSettings} from "./TypesCacheStudioEnt";
import {ActionEntEditLockDetail} from "./TypesCacheStudioEnt";
import {ActionSortEntList} from "./TypesCacheStudioEnt";
import {ActionRemoveEntStudioContentLayoutMap} from "./TypesCacheStudioEnt";
import {ActionAddEntStudioContentLayoutMap} from "./TypesCacheStudioEnt";
import {ActionEntSetSetupMode} from "./TypesCacheStudioEnt";
import {ActionAddEntGroup} from "./TypesCacheStudioEnt";
import {ActionRemoveEntGroup} from "./TypesCacheStudioEnt";
import {ActionAddEntAction} from "./TypesCacheStudioEnt";
import {ActionRemoveEntAction} from "./TypesCacheStudioEnt";
import {ActionAddEntVariable} from "./TypesCacheStudioEnt";
import {ActionRemoveEntVariable} from "./TypesCacheStudioEnt";
import {ActionAddEntTranslation} from "./TypesCacheStudioEnt";
import {ActionRemoveEntTranslation} from "./TypesCacheStudioEnt";
import {ActionRemoveEntTranslationLanguage} from "./TypesCacheStudioEnt";
import {ActionAddEntImport} from "./TypesCacheStudioEnt";
import {ActionRemoveEntImport} from "./TypesCacheStudioEnt";
import {ActionAddEntRole} from "./TypesCacheStudioEnt";
import {ActionRemoveEntRole} from "./TypesCacheStudioEnt";
import {ActionAddEntDriveSheet} from "./TypesCacheStudioEnt";
import {ActionRemoveDriveSheet} from "./TypesCacheStudioEnt";
import {ActionAddEntReport} from "./TypesCacheStudioEnt";
import {ActionRemoveEntReport} from "./TypesCacheStudioEnt";
import {ActionAddEntAutomation} from "./TypesCacheStudioEnt";
import {ActionRemoveEntAutomation} from "./TypesCacheStudioEnt";
import {ActionAddEntAutomationEvent} from "./TypesCacheStudioEnt";
import {ActionRemoveEntAutomationEvent} from "./TypesCacheStudioEnt";
import {ActionAddEntAutomationStep} from "./TypesCacheStudioEnt";
import {ActionRemoveEntAutomationStep} from "./TypesCacheStudioEnt";
import {ActionAddEntStudioForm} from "./TypesCacheStudioEnt";
import {ActionRemoveEntStudioForm} from "./TypesCacheStudioEnt";
import {ActionAddEntStudioVisibilityRuleMap} from "./TypesCacheStudioEnt";
import {ActionRemoveEntStudioVisibilityRuleMap} from "./TypesCacheStudioEnt";
import {ActionAddEntStudioFormFormula} from "./TypesCacheStudioEnt";
import {ActionRemoveEntStudioFormFormula} from "./TypesCacheStudioEnt";
import {ActionAddEntDeeplink} from "./TypesCacheStudioEnt";
import {ActionRemoveEntDeeplink} from "./TypesCacheStudioEnt";
import {ActionMoveAutomationEvent} from "./TypesCacheStudioEnt";
import {ActionMoveAutomationStep} from "./TypesCacheStudioEnt";
import {ActionAddEntFormComposite} from "./TypesCacheStudioEnt";
import {ActionRemoveFormComposite} from "./TypesCacheStudioEnt";
import {ActionRemoveFormField} from "./TypesCacheStudioEnt";
import {ActionAddEntFormField} from "./TypesCacheStudioEnt";
import {ActionMoveEntItem} from "./TypesCacheStudioEnt";
import {ActionMoveFormItem} from "./TypesCacheStudioEnt";
import {ActionAddPluginUserHandle} from "./TypesCacheStudioEnt";
import {ActionAddRequiredOnDeploymentVariable} from "./TypesCacheStudioEnt";
import {ActionAddPluginConfig} from "./TypesCacheStudioEnt";
import {ActionEntStateMap} from "./TypesCacheStudioEnt";
import {ActionEntDeployStatus} from "./TypesCacheStudioEnt";
import {ActionEntDirty} from "./TypesCacheStudioEnt";
import {ActionUpdateEntDriveSpreadsheetSettings} from "./TypesCacheStudioEnt";
import {ActionUpdateEntDeployLock} from "./TypesCacheStudioEnt";
import {ActionUpdateEntDeployPayment} from "./TypesCacheStudioEnt";
import {TypeStudioEntDetailsKeys} from "./TypesCacheStudioEnt";
import {TypeStudioEntDriveSheetSettingsKeys} from "./TypesCacheStudioEnt";
import {ActionUpdateEntDetails} from "./TypesCacheStudioEnt";
import {ActionAddEntSpreadsheet} from "./TypesCacheStudioEnt";
import {ActionRemoveEntSpreadsheet} from "./TypesCacheStudioEnt";
import {ActionAddEntPrompt} from "./TypesCacheStudioEnt";
import {ActionRemoveEntPrompt} from "./TypesCacheStudioEnt";
import {ICacheStudioStateEnt, TypeEntMap} from "./TypesCacheStudioEnt";

export const sliceCacheStudioEnt = createSlice({
  name: "cacheStudioMain",
  initialState: {
    entMap: {} as TypeEntMap,
    entUserListMap: {},
    entAdminListMap: {},
    entAdminCallerMap: {},
    dirtyEntIdList: [],
    entAvatarMap: {},
    mutationCountEntAvatarMap: 0,
    selectedModuleMap: {},
    entStateMap: {},
    entEditLockDetailMap: {},
    entSysFormMap: {}
  } as ICacheStudioStateEnt,
  reducers: {
    setEnt: (state: ICacheStudioStateEnt, action: PayloadAction<SigStudioEnt>) =>
    {
      const sig = action.payload;
      const entId = sig.ent.entId;
      state.entMap[entId] = sig;
    },

    updateEnt: (state: ICacheStudioStateEnt, action: PayloadAction<SigStudioEnt>) =>
    {
      const sig = action.payload;
      const entId = sig.ent.entId;
      state.entMap[entId] = sig;
      verifyEntDirty(state, entId);
    },

    removeEnt: (state: ICacheStudioStateEnt, action: PayloadAction<EntId>) =>
    {
      const entId = action.payload;

      if(state.entMap[entId])
      {
        delete state.entMap[entId];
      }

      if(state.entStateMap[entId])
      {
        delete state.entStateMap[entId];
      }
    },

    mergeEnt: (state: ICacheStudioStateEnt, action: PayloadAction<SigStudioEnt>) =>
    {
      const sig = action.payload;
      const entId = sig.ent.entId;
      const deltaStudioEnt = state.entStateMap[entId]?.deltaStudio;
      const ent = Srvc.cache.studio.fnApplyDeltaStudioArtifactItem(() => verifyEntDirty(state, entId),
        sig.ent,
        state.entMap[entId]?.ent,
        deltaStudioEnt
      );
      state.entMap[entId] = {
        ...sig,
        ent: ent
      };
    },

    setIsSavingEnt: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        isSaving: boolean
      }>) =>
    {
      const {
        entId,
        isSaving
      } = action.payload;

      state.entStateMap[entId] = {
        ...state.entStateMap[entId],
        isSaving: isSaving
      };
    },

    setSetupMode: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionEntSetSetupMode>) =>
    {
      const {
        entId,
        setupMode
      } = action.payload;

      state.entStateMap[entId] = {
        ...state.entStateMap[entId],
        setupMode: setupMode
      };
    },

    addDeltaEntLocalState: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        oldEnt: StudioEnt
      }>) =>
    {
      const {
        entId,
        oldEnt
      } = action.payload;

      if(!state.entStateMap[entId]?.deltaStudio)
      {
        state.entStateMap[entId] = {
          ...state.entStateMap[entId],
          deltaStudio: {}
        };
      }
      const oldDelta = state.entStateMap[entId].deltaStudio;
      const ent = state.entMap[entId]?.ent;
      state.entStateMap[entId].deltaStudio = Srvc.cache.studio.fnCalcDeltaStudioArtifactItem(ent, oldEnt, oldDelta);
    },
    removeDeltaEntLocalState: (state: ICacheStudioStateEnt, action: PayloadAction<EntId>) =>
    {
      const entId = action.payload;

      if(entId && state.entStateMap[entId]?.deltaStudio)
      {
        delete state.entStateMap[entId].deltaStudio;
      }
    },

    setEntUserList: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        sigEntUserList: SigEntUserList
      }>) =>
    {
      const {
        entId,
        sigEntUserList
      } = action.payload;

      state.entUserListMap[entId] = {
        ...sigEntUserList,
        entUserSet: sigEntUserList.entUserSet.map((user) =>
        {
          return {
            ...user,
            managerId: user.managerId || ""
          };
        })
      };
    },
    removeEntUserList: (state: ICacheStudioStateEnt, action: PayloadAction<EntId>) =>
    {
      delete state.entUserListMap[action.payload];
    },

    setEntAdminList: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        sigEntAdminList: SigEntAdminList
      }>) =>
    {
      const {
        entId,
        sigEntAdminList
      } = action.payload;
      state.entAdminListMap[entId] = sigEntAdminList;
    },
    removeEntAdminList: (state: ICacheStudioStateEnt, action: PayloadAction<EntId>) =>
    {
      delete state.entAdminListMap[action.payload];
    },

    setEntAdminCaller: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        sigEntAdminCaller: SigEntAdminCaller
      }>) =>
    {
      const {
        entId,
        sigEntAdminCaller
      } = action.payload;
      state.entAdminCallerMap[entId] = sigEntAdminCaller;
    },

    setSearchEntId: (state: ICacheStudioStateEnt, action: PayloadAction<EntId | undefined>) =>
    {
      state.searchEntId = action.payload;
    },

    setEntDirty: (state: ICacheStudioStateEnt, action: PayloadAction<ActionEntDirty>) =>
    {
      const {
        entId,
        isDirty
      } = action.payload;
      const dirtyEntIdList = state.dirtyEntIdList;
      if(isDirty)
      {
        if(!dirtyEntIdList.includes(entId))
        {
          dirtyEntIdList.push(entId);
        }
      }
      else
      {
        state.dirtyEntIdList = dirtyEntIdList.filter(_entId => _entId !== entId);
      }
    },

    setEntEditLockDetail: (state: ICacheStudioStateEnt, action: PayloadAction<ActionEntEditLockDetail>) =>
    {
      const {
        entId,
        detail
      } = action.payload;

      if(detail)
      {
        state.entEditLockDetailMap[entId] = detail;
      }
    },

    setEntSysFormMap: (state: ICacheStudioStateEnt, action: PayloadAction<SigSysDefnFormMapGet>) =>
    {
      const sig = action.payload;
      state.entSysFormMap = sig.sysDefnFormMap;
    },

    removeEntSysFormMap: (state: ICacheStudioStateEnt, _) =>
    {
      state.entSysFormMap = {};
    },

    //region about - details
    updateEntModules: (state: ICacheStudioStateEnt, action: PayloadAction<ActionUpdateEntModuleMap>) =>
    {
      const {
        entId,
        moduleMap
      } = action.payload;

      const entSig = state.entMap[entId];
      let sigModuleMap = entSig.ent.moduleMap;

      if(!sigModuleMap || !sigModuleMap.keys.length)
      {
        sigModuleMap = {
          keys: [],
          map: {}
        };
      }

      if(!isEqual(sigModuleMap, moduleMap))
      {
        const allTags = moduleMap as StudioModuleMap;

        //move object into trash
        const delta = calcDeltaArray(sigModuleMap.keys, allTags.keys);
        entSig.ent.trash = entSig.ent.trash ?? {};
        const trashModuleMap = entSig.ent.trash.moduleMap ?? {};
        delta.deletes.forEach((metaId) =>
        {
          trashModuleMap[metaId] = sigModuleMap.map[metaId].name;
        });
        entSig.ent.trash = {
          ...entSig.ent.trash,
          moduleMap: trashModuleMap
        };

        entSig.ent.moduleMap = allTags;
      }

    },
    updateEntDetails: (state: ICacheStudioStateEnt, action: PayloadAction<ActionUpdateEntDetails>) =>
    {
      const {
        entId
      } = action.payload;

      const entSig = state.entMap[entId];
      let isDirty = false;

      if(entSig && entSig.ent)
      {
        const entDetails = entSig.ent.details;

        Object.entries(action.payload).forEach(([key, value]) =>
        {
          const _key = key as TypeStudioEntDetailsKeys;

          if(!entDetails[_key] || entDetails[_key] !== value)
          {
            switch(_key)
            {
              case "about":
                entDetails.about = value as string;
                break;
              case "storeAbout":
                entDetails.storeAbout = value as string;
                break;
              case "timeZone":
                entDetails.timeZone = value as TimeZoneKey;
                break;
              case "displayDateFormat":
                entDetails.displayDateFormat = value as string;
                break;
              case "avatarId":
                entDetails.avatarId = value as MediaIdAvatar;
                isDirty = true;
                break;
              case "languageSet":
                entDetails.languageSet = value as LanguageKey[];
                break;
              case "name":
                entDetails.name = value as string;
                break;
              case "storeLabelSet":
                entDetails.storeLabelSet = value as EnumStoreLabel[];
                break;
              case "storeItemType":
                entDetails.storeItemType = value as EnumDefnStoreItem;
                break;
            }
          }
        });

        isDirty && verifyEntDirty(state, entId);
      }
    },
    //endregion

    //region variable
    addEntVariable: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntVariable>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.varMap)
        {
          entSig.ent.varMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.varMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntVariable: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntVariable>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const varMap = entSig.ent.varMap;
        const deployVarMap = entSig.ent.deployVarMap;

        if(varMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.varMap = entSig.ent.trash.varMap ?? {};
          entSig.ent.trash.varMap[metaId] = varMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(varMap, metaId);
          if(isDirty)
          {
            Srvc.cache.studio.cacheStudioItemRemove(deployVarMap, metaId);
            verifyEntDirty(state, entId);
          }
        }
      }
    },
    //endregion

    //region  translation
    addEntTranslation: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntTranslation>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.translationMap)
        {
          entSig.ent.translationMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.translationMap,
          sig,
          sig.metaId,
          insertIndex
        );
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntTranslation: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntTranslation>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const translationMap = entSig.ent.translationMap;
        if(translationMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(translationMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },

    removeEntTranslationLanguage: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionRemoveEntTranslationLanguage>) =>
    {
      const {
        entId,
        metaId,
        languageKey
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const translationMap = entSig.ent.translationMap;
        if(translationMap)
        {
          let isDirty = false;
          const map = translationMap.map;
          const translation = map[metaId];

          if(translation && translation.translationMap && translation.translationMap[languageKey])
          {
            delete translation.translationMap[languageKey];
            isDirty = true;
          }

          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region group
    addEntGroup: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntGroup>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.groupMap)
        {
          entSig.ent.groupMap = {
            map: {},
            keys: []
          };
        }

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.groupMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntGroup: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntGroup>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const groupMap = entSig.ent.groupMap;
        if(groupMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.groupMap = entSig.ent.trash.groupMap ?? {};
          entSig.ent.trash.groupMap[metaId] = groupMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(groupMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region plugins
    addEntPlugin: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntImport>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.pluginMap)
        {
          entSig.ent.pluginMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.pluginMap, sig, sig.metaId, insertIndex);
        if(isDirty)
        {
          if(sig.kind === "plugin")
          {
            const pluginKeys = entSig.ent.deployPluginMap.keys;
            const currentVersion = entSig.ent.pluginMap.map[sig.metaId].pluginVersion;
            const newVersion = (sig as StudioEntPlugin).pluginVersion;

            if(!entSig.ent.deployPluginMap)
            {
              entSig.ent.deployPluginMap = {
                map: {},
                keys: []
              };

              Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.deployPluginMap,
                {metaId: sig.metaId},
                sig.metaId);
            }
            else if(!pluginKeys.includes(sig.metaId))
            {
              Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.deployPluginMap,
                {metaId: sig.metaId},
                sig.metaId);
            }
            else if(currentVersion !== newVersion)
            {
              Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.deployPluginMap,
                {metaId: sig.metaId},
                sig.metaId);
            }
          }
          verifyEntDirty(state, entId);
        }
      }
    },
    removeEntPlugin: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntImport>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const importMap = entSig.ent.pluginMap;
        const deployPluginMap = entSig.ent.deployPluginMap;

        if(importMap)
        {
          const kind = importMap.map[metaId].kind;
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(importMap, metaId);
          if(isDirty)
          {
            if(kind === "plugin")
            {
              Srvc.cache.studio.cacheStudioItemRemove(deployPluginMap, metaId);
            }
            verifyEntDirty(state, entId);
          }
        }
      }
    },
    //endregion

    //region roles
    addEntRole: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntRole>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.roleMap)
        {
          entSig.ent.roleMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.roleMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntRole: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntRole>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const roleMap = entSig.ent.roleMap;
        if(roleMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.roleMap = entSig.ent.trash.roleMap ?? {};
          entSig.ent.trash.roleMap[metaId] = roleMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(roleMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region Report
    addEntReport: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntReport>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.reportMap)
        {
          entSig.ent.reportMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.reportMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntReport: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntReport>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const reportMap = entSig.ent.reportMap;
        if(reportMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.reportMap = entSig.ent.trash.reportMap ?? {};
          entSig.ent.trash.reportMap[metaId] = reportMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(reportMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region automation
    addEntAutomation: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntAutomation>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        if(!entSig.ent.automationMap)
        {
          entSig.ent.automationMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.automationMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntAutomation: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntAutomation>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const automationMap = entSig.ent.automationMap;
        if(automationMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(automationMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    addEntAutomationEvent: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntAutomationEvent>) =>
    {
      const {
        entId,
        automationId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const automation = entSig.ent.automationMap.map[automationId];
        const automationKind = automation.kind;

        let isDirty = false;

        if(automationKind === "spreadsheet")
        {
          isDirty = Srvc.cache.studio.cacheStudioItemInsert((automation as StudioEntAutomationSpreadsheet).eventMap,
            sig,
            sig.metaId,
            insertIndex
          );
        }
        else if(automationKind === "scheduled")
        {
          isDirty = Srvc.cache.studio.cacheStudioItemInsert((automation as StudioEntAutomationScheduled).eventMap,
            sig,
            sig.metaId,
            insertIndex
          );
        }
        else if(automationKind === "pluginWebhook")
        {
          isDirty = Srvc.cache.studio.cacheStudioItemInsert((automation as StudioEntAutomationPluginWebhook).eventMap,
            sig,
            sig.metaId,
            insertIndex
          );
        }
        else if(automationKind === "webhook")
        {
          isDirty = Srvc.cache.studio.cacheStudioItemInsert((automation as StudioEntAutomationWebhook).eventMap,
            sig,
            sig.metaId,
            insertIndex
          );
        }

        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntAutomationEvent: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntAutomationEvent>) =>
    {
      const {
        entId,
        automationId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const automation = entSig.ent.automationMap.map[automationId];
        const automationKind = automation.kind;

        let isDirty = false;

        if(automationKind === "spreadsheet")
        {
          isDirty =
            Srvc.cache.studio.cacheStudioItemRemove((automation as StudioEntAutomationSpreadsheet).eventMap, metaId);
        }
        else if(automationKind === "scheduled")
        {
          isDirty =
            Srvc.cache.studio.cacheStudioItemRemove((automation as StudioEntAutomationScheduled).eventMap, metaId);
        }
        else if(automationKind === "pluginWebhook")
        {
          isDirty =
            Srvc.cache.studio.cacheStudioItemRemove((automation as StudioEntAutomationPluginWebhook).eventMap, metaId);
        }
        else if(automationKind === "webhook")
        {
          isDirty =
            Srvc.cache.studio.cacheStudioItemRemove((automation as StudioEntAutomationWebhook).eventMap, metaId);
        }

        isDirty && verifyEntDirty(state, entId);
      }
    },
    addEntAutomationEventStep: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntAutomationStep>) =>
    {
      const {
        entId,
        automationId,
        automationEventId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const automation = entSig.ent.automationMap.map[automationId];
        const automationKind = automation.kind;

        let isDirty = false;

        if(automationKind === "spreadsheet")
        {
          const spreadSheetEvent = (automation as StudioEntAutomationSpreadsheet).eventMap.map[automationEventId] as StudioEntAutomationSpreadsheetEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemInsert(spreadSheetEvent.stepMap, sig, sig.metaId, insertIndex);
        }
        else if(automationKind === "scheduled")
        {
          const scheduledEvent = (automation as StudioEntAutomationScheduled).eventMap.map[automationEventId] as StudioEntAutomationScheduledEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemInsert(scheduledEvent.stepMap, sig, sig.metaId, insertIndex);
        }
        else if(automationKind === "pluginWebhook")
        {
          const webhookEvent = (automation as StudioEntAutomationPluginWebhook).eventMap.map[automationEventId] as StudioEntAutomationPluginWebhookEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemInsert(webhookEvent.stepMap, sig, sig.metaId, insertIndex);
        }
        else if(automationKind === "webhook")
        {
          const webhookEvent = (automation as StudioEntAutomationWebhook).eventMap.map[automationEventId] as StudioEntAutomationWebhookEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemInsert(webhookEvent.stepMap, sig, sig.metaId, insertIndex);
        }

        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntAutomationEventStep: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntAutomationStep>) =>
    {
      const {
        entId,
        automationId,
        automationEventId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const automation = entSig.ent.automationMap.map[automationId];
        const automationKind = automation.kind;

        let isDirty = false;

        if(automationKind === "spreadsheet")
        {
          const spreadSheetEvent = (automation as StudioEntAutomationSpreadsheet).eventMap.map[automationEventId] as StudioEntAutomationSpreadsheetEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemRemove(spreadSheetEvent.stepMap, metaId);
        }
        else if(automationKind === "scheduled")
        {
          const scheduledEvent = (automation as StudioEntAutomationScheduled).eventMap.map[automationEventId] as StudioEntAutomationScheduledEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemRemove(scheduledEvent.stepMap, metaId);
        }
        else if(automationKind === "pluginWebhook")
        {
          const webhookEvent = (automation as StudioEntAutomationPluginWebhook).eventMap.map[automationEventId] as StudioEntAutomationPluginWebhookEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemRemove(webhookEvent.stepMap, metaId);
        }
        else if(automationKind === "webhook")
        {
          const webhookEvent = (automation as StudioEntAutomationWebhook).eventMap.map[automationEventId] as StudioEntAutomationWebhookEvent;
          isDirty = Srvc.cache.studio.cacheStudioItemRemove(webhookEvent.stepMap, metaId);
        }

        isDirty && verifyEntDirty(state, entId);
      }
    },
    //endregion

    //region spreadsheet
    addEntSpreadsheet: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntSpreadsheet>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.spreadsheetMap)
        {
          entSig.ent.spreadsheetMap = {
            map: {},
            keys: []
          };
        }

        // calc trash of spreadsheetLayouts
        const oldSSLayouts = entSig.ent.spreadsheetMap.map[sig.metaId]?.layoutSpreadsheetMap;
        const newSSLayouts = sig.layoutSpreadsheetMap;
        const deltaSSLayoutIds = calcDeltaArray(oldSSLayouts?.keys || [], newSSLayouts?.keys || []);
        const removedSSLayoutsIds = deltaSSLayoutIds.deletes;
        entSig.ent.trash = entSig.ent.trash ?? {};
        entSig.ent.trash.layoutGridMap = entSig.ent.trash.layoutGridMap ?? {};
        removedSSLayoutsIds.forEach(metaIdLayoutSpreadsheet =>
        {
          if(entSig.ent.trash?.layoutGridMap && oldSSLayouts)
          {
            entSig.ent.trash.layoutGridMap[metaIdLayoutSpreadsheet] =
              oldSSLayouts.map[metaIdLayoutSpreadsheet];
          }
        });

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.spreadsheetMap,
          sig,
          sig.metaId,
          insertIndex
        );
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntSpreadsheet: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntSpreadsheet>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const spreadsheetMap = entSig.ent.spreadsheetMap;
        if(spreadsheetMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.spreadsheetMap = entSig.ent.trash.spreadsheetMap ?? {};
          entSig.ent.trash.spreadsheetMap[metaId] = spreadsheetMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(spreadsheetMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region action
    addEntAction: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntAction>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.actionMap)
        {
          entSig.ent.actionMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.actionMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntAction: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntAction>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const actionMap = entSig.ent.actionMap;
        if(actionMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.actionMap = entSig.ent.trash.actionMap ?? {};
          entSig.ent.trash.actionMap[metaId] = actionMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(actionMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region drive
    addEntDriveSheet: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntDriveSheet>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.driveSheetMap)
        {
          entSig.ent.driveSheetMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.driveSheetMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntDriveSheet: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveDriveSheet>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const driveSheetMap = entSig.ent.driveSheetMap;
        if(driveSheetMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(driveSheetMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    updateEntDriveSpreadsheetSettings(
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionUpdateEntDriveSpreadsheetSettings>)
    {
      const {
        entId
      } = action.payload;

      const entSig = state.entMap[entId];
      let isDirty = false;
      if(entSig && entSig.ent)
      {
        const driveSheetMap = entSig.ent.driveSheetMap;
        if(!driveSheetMap)
        {
          entSig.ent.driveSheetMap = {
            keys: [],
            map: {}
          };
        }
        const driveSheetSettings = driveSheetMap;
        Object.entries(action.payload).forEach(([key, value]) =>
        {
          const _key = key as TypeStudioEntDriveSheetSettingsKeys;
          if(_key !== "entId")
          {
            if(driveSheetSettings && driveSheetSettings[_key] !== value)
            {
              driveSheetSettings[_key] = value;
              isDirty = true;
            }
          }
        });
        isDirty && verifyEntDirty(state, entId);
      }
    },

    //endregion

    //region form
    addEntForm: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntStudioForm>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.formMap)
        {
          entSig.ent.formMap = {
            map: {},
            keys: []
          };
        }
        if(!sig.compositeMap)
        {
          sig.compositeMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.formMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },

    removeEntForm: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntStudioForm>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const formMap = entSig.ent.formMap;
        if(formMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.formMap = entSig.ent.trash.formMap ?? {};
          entSig.ent.trash.formMap[metaId] = formMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(formMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },

    //endregion

    //region form visibility rules
    addEntVisibilityRuleMap: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionAddEntStudioVisibilityRuleMap>) =>
    {
      const {
        entId,
        sig,
        formId,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];

        if(!form.visibilityRuleMap)
        {
          form.visibilityRuleMap = {
            keys: [],
            map: {}
          };
        }

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(form.visibilityRuleMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },

    removeEntVisibilityRuleMap: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionRemoveEntStudioVisibilityRuleMap>) =>
    {
      const {
        entId,
        metaId,
        formId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];
        const visibilityRuleMap = form && form.visibilityRuleMap;

        if(visibilityRuleMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(visibilityRuleMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },

    //endregion

    addEntDefaultAsideLayout: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        formId: MetaIdForm
        defaultAsideLayout?: MetaIdLayoutForm
      }>
    ) =>
    {
      const {
        entId,
        defaultAsideLayout,
        formId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        let layoutMap = entSig.ent.formMap.map[formId].layoutMap;
        if(!layoutMap)
        {
          layoutMap = {
            map: {},
            keys: []
          };
        }
        if(layoutMap)
        {
          const _defaultAsideLayout = layoutMap.asideDefaultLayoutId;
          let isDirty = true;

          if(defaultAsideLayout && defaultAsideLayout === _defaultAsideLayout)
          {
            isDirty = false;
          }
          else
          {
            layoutMap.asideDefaultLayoutId = defaultAsideLayout;
          }
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },

    //region form content layout

    addEntContentLayoutMap: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionAddEntStudioContentLayoutMap>) =>
    {
      const {
        entId,
        sig,
        formId,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];

        if(!form.layoutMap)
        {
          form.layoutMap = {
            keys: [],
            map: {}
          } as StudioMapOfLayoutFormContent;
        }

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(form.layoutMap,
          sig,
          sig.metaId,
          insertIndex
        );
        isDirty && verifyEntDirty(state, entId);
      }
    },

    removeEntContentLayoutMap: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionRemoveEntStudioContentLayoutMap>) =>
    {
      const {
        entId,
        metaId,
        formId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];
        const contentLayoutMap = form && form.layoutMap;

        if(contentLayoutMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.contentMap = entSig.ent.trash.contentMap ?? {};
          entSig.ent.trash.contentMap[metaId] = contentLayoutMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(contentLayoutMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },

    //endregion

    //region form formula
    addEntFormFormula: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionAddEntStudioFormFormula>
    ) =>
    {
      const {
        entId,
        sig,
        formId,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];

        if(!form.formulaMap)
        {
          form.formulaMap = {
            map: {},
            keys: []
          };
        }
        if(isArray(form.formulaMap.keys))
        {
          // @ts-ignore
          const isDirty = Srvc.cache.studio.cacheStudioItemInsert(form.formulaMap, sig, sig.metaId, insertIndex);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },

    removeEntFormFormula: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionRemoveEntStudioFormFormula>) =>
    {
      const {
        entId,
        metaId,
        formId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];
        const formulaMap = form && form.formulaMap;
        if(formulaMap && isArray(formulaMap.keys))
        {
          // @ts-ignore
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(formulaMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region form composite
    addEntFormComposite: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionAddEntFormComposite>) =>
    {
      const {
        entId,
        formId,
        sig: studioComposite,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];

        if(!form.compositeMap)
        {
          form.compositeMap = {
            keys: [],
            map: {}
          };
        }

        // calc trash of grid layouts
        if(studioComposite.type === "grid")
        {
          const oldGridLayouts = (entSig.ent
            .formMap.map[formId]
            ?.compositeMap.map[(studioComposite as StudioGrid).metaId] as StudioGrid)?.layoutGridMap;
          const newGridLayouts = (studioComposite as StudioGrid).layoutGridMap;
          const deltaGridLayoutIds = calcDeltaArray(oldGridLayouts?.keys || [], newGridLayouts?.keys || []);
          const removedGridLayoutsIds = deltaGridLayoutIds.deletes;
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.layoutGridMap = entSig.ent.trash.layoutGridMap ?? {};
          removedGridLayoutsIds.forEach(metaIdLayoutGrid =>
          {
            if(entSig.ent.trash?.layoutGridMap && oldGridLayouts)
            {
              entSig.ent.trash.layoutGridMap[metaIdLayoutGrid] = oldGridLayouts.map[metaIdLayoutGrid];
            }
          });
        }

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(form.compositeMap,
          studioComposite,
          (studioComposite as (StudioGrid | StudioSection)).metaId,
          insertIndex
        );
        isDirty && verifyEntDirty(state, entId);
      }
    },

    removeEntFormComposite: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveFormComposite>) =>
    {
      const {
        entId,
        formId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];
        const compositeMap = form && form.compositeMap;
        if(compositeMap)
        {
          //move object into trash
          entSig.ent.trash = entSig.ent.trash ?? {};
          entSig.ent.trash.compositeMap = entSig.ent.trash.compositeMap ?? {};
          entSig.ent.trash.compositeMap[metaId] = compositeMap.map[metaId];

          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(compositeMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    //region form field
    addEntFormField: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntFormField>) =>
    {
      const {
        entId,
        formId,
        parentCompositeId,
        sig: studioField,
        insertIndex
      } = action.payload;

      if(!studioField.details.name)
      {
        throw new Error(`Field name is undefined: ${JSON.stringify(studioField)}`);
      }

      const entSig = state.entMap[entId];
      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const form = entSig.ent.formMap.map[formId];
        const composite = form.compositeMap?.map[parentCompositeId] as StudioComposite;

        if(!composite?.fieldMap)
        {
          composite.fieldMap = {
            keys: [],
            map: {}
          };
        }

        let isDirty;
        if(studioField.type === "ref"
          || studioField.type === "pickReportRow"
          || studioField.type === "pickGridRow"
          || studioField.type === "refReport")
        {
          const studioFieldRef = studioField as TypeFieldRef;
          if(!studioFieldRef?.copyFieldMap)
          {
            throw new Error(`Field ${studioField.type} copyFieldMap is undefined: ${JSON.stringify(studioField)}`, {
              cause: "addEntFormField"
            });
          }
          isDirty = insertFormFieldRef(
            entSig,
            studioFieldRef,
            composite,
            formId
          );
        }
        else if(studioField.type === "refUser")
        {
          const studioFieldRefUser = studioField as StudioFieldRefUser;
          if(!studioFieldRefUser?.copyFieldVarMap)
          {
            throw new Error(`Field ${studioField.type} copyFieldVarMap is undefined: ${JSON.stringify(studioField)}`,
              {cause: "addEntFormField"}
            );
          }

          isDirty = insertFormFieldRefUser(
            entSig,
            studioFieldRefUser,
            composite
          );
        }
        else
        {
          isDirty = Srvc.cache.studio.cacheStudioItemInsert(composite.fieldMap,
            studioField,
            studioField.metaId,
            insertIndex
          );
        }
        isDirty && verifyEntDirty(state, entId);
      }
    },

    removeEntFormField: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveFormField>) =>
    {
      const {
        entId,
        formId,
        parentCompositeId,
        metaId,
        preventTrashRemove
      } = action.payload;

      const entSig = state.entMap[entId];
      let isDirty;
      if(entSig)
      {
        checkIfFormExists(entSig, formId);
        const formMap = entSig.ent.formMap;
        const form = formMap.map[formId];
        const composite = form.compositeMap?.map[parentCompositeId];
        if(composite)
        {
          const field = composite.fieldMap.map[metaId]?.type;
          if(field === "ref"
            || field === "refReport"
            || field === "pickReportRow"
            || field === "pickGridRow")
          {
            const fieldRef = composite.fieldMap.map[metaId] as TypeFieldRef;
            isDirty = removeFormFieldRef(composite, fieldRef.metaId, fieldRef?.copyFieldMap);
          }
          else if(field === "refUser")
          {
            const fieldRef = composite.fieldMap.map[metaId] as StudioFieldRefUser;
            isDirty = removeFormFieldRef(composite, fieldRef.metaId, fieldRef?.copyFieldVarMap);
          }
          else
          {
            //move object into trash
            if(!preventTrashRemove)
            {
              entSig.ent.trash = entSig.ent.trash ?? {};
              entSig.ent.trash.fieldMap = entSig.ent.trash.fieldMap ?? {};
              entSig.ent.trash.fieldMap[metaId] = composite.fieldMap.map[metaId];
            }
            const field = composite.fieldMap.map[metaId];
            const refFieldId = (field as StudioFieldEditable)?.refFieldId;
            if(field && refFieldId)
            {
              const copyFieldMap = (composite.fieldMap.map[refFieldId] as StudioFieldRef | StudioFieldPickReportRow | StudioFieldPickGridRow).copyFieldMap;
              if(copyFieldMap && copyFieldMap[metaId])
              {
                delete copyFieldMap[metaId];
              }
            }
            isDirty = Srvc.cache.studio.cacheStudioItemRemove(composite.fieldMap, metaId);
          }
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    // region prompt

    addEntPrompt: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntPrompt>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.promptMap)
        {
          entSig.ent.promptMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.promptMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntPrompt: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntPrompt>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const promptMap = entSig.ent.promptMap;
        if(promptMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(promptMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    updateEntPromptSettings(
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionUpdateEntPromptSettings>)
    {
      const {
        entId,
        fieldSeparatorSet,
        lineSeparator,
        acceptPromptViaEmail,
        acceptPromptViaWhatsapp
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig && entSig.ent)
      {
        const driveSheetMap = entSig.ent.promptMap;

        driveSheetMap.fieldSeparatorSet = fieldSeparatorSet;
        driveSheetMap.lineSeparator = lineSeparator;
        driveSheetMap.acceptPromptViaEmail = acceptPromptViaEmail;
        driveSheetMap.acceptPromptViaWhatsapp = acceptPromptViaWhatsapp;

        verifyEntDirty(state, entId);
      }
    },

    // endregion

    // region deeplink
    addEntDeeplink: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddEntDeeplink>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        if(!entSig.ent.deeplinkMap)
        {
          entSig.ent.deeplinkMap = {
            map: {},
            keys: []
          };
        }
        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.deeplinkMap, sig, sig.metaId, insertIndex);
        isDirty && verifyEntDirty(state, entId);
      }
    },
    removeEntDeeplink: (state: ICacheStudioStateEnt, action: PayloadAction<ActionRemoveEntDeeplink>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const deeplinkMap = entSig.ent.deeplinkMap;
        if(deeplinkMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(deeplinkMap, metaId);
          isDirty && verifyEntDirty(state, entId);
        }
      }
    },
    //endregion

    // region deploy

    addEntPluginUserHandel: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddPluginUserHandle>) =>
    {
      const {
        entId,
        pluginUserHandle
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const driveUserHandleForPlugins = entSig.ent.deployPluginMap.singletonPluginsAdminId;
        let isDirty = true;

        if(pluginUserHandle && pluginUserHandle === driveUserHandleForPlugins)
        {
          isDirty = false;
        }
        else
        {
          entSig.ent.deployPluginMap.singletonPluginsAdminId = pluginUserHandle;
        }

        isDirty && verifyEntDirty(state, entId);
      }
    },

    addEntDeployPluginConfig: (state: ICacheStudioStateEnt, action: PayloadAction<ActionAddPluginConfig>) =>
    {
      const {
        entId,
        sig
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const pluginMap = entSig.ent.deployPluginMap;

        if(!pluginMap)
        {
          entSig.ent.deployPluginMap = {
            keys: [],
            map: {}
          };
        }

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(pluginMap, sig, sig.metaId);
        isDirty && verifyEntDirty(state, entId);
      }
    },

    addEntRequiredOnDeploymentVariable: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionAddRequiredOnDeploymentVariable>) =>
    {
      const {
        entId,
        sig
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        const varMap = entSig.ent.deployVarMap;

        if(!varMap)
        {
          entSig.ent.deployVarMap = {
            map: {},
            keys: []
          };
        }

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(varMap, sig, sig.metaId);
        isDirty && verifyEntDirty(state, entId);
      }
    },

    updateEntDeployManage(state: ICacheStudioStateEnt, action: PayloadAction<ActionUpdateEntDeployLock>)
    {
      const {
        entId
      } = action.payload;

      const entSig = state.entMap[entId];
      let isDirty = false;
      // if(entSig && entSig.ent)
      // {
      //   const manage = entSig.ent.deploy.manage;
      //   Object.entries(action.payload).forEach(([key, value]) =>
      //   {
      //     const _key = key as TypeStudioEntManageKeys;
      //     if(_key !== "entId")
      //     {
      //       if(manage[_key] !== value)
      //       {
      //         manage[_key] = value;
      //         isDirty = true;
      //       }
      //     }
      //   });
      //   isDirty && verifyEntDirty(state, entId);
      // }
    },

    updateEntDeployPayment(state: ICacheStudioStateEnt, action: PayloadAction<ActionUpdateEntDeployPayment>)
    {
      const {
        entId
      } = action.payload;

      const entSig = state.entMap[entId];
      let isDirty = false;
      // if(entSig && entSig.ent)
      // {
      //   if(!entSig.ent.deploy.payment)
      //   {
      //     entSig.ent.deploy.payment = {};
      //   }
      //
      //   const payment = entSig.ent.deploy.payment;
      //   Object.entries(action.payload).forEach(([key, value]) =>
      //   {
      //     const _key = key as TypeStudioEntPaymentKeys;
      //     if(payment && _key !== "entId")
      //     {
      //       if(payment[_key] !== value)
      //       {
      //         payment[_key] = value;
      //         isDirty = true;
      //       }
      //     }
      //   });
      //   isDirty && verifyEntDirty(state, entId);
      // }
    },

    addEntDeployDefaultPaymentProviderId: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        defaultPaymentProviderId?: MetaIdPaymentProvider
      }>
    ) =>
    {
      const {
        entId,
        defaultPaymentProviderId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        if(!entSig.ent.deployPaymentProviderMap)
        {
          entSig.ent.deployPaymentProviderMap = {
            map: {},
            keys: []
          };
        }

        const _defaultPaymentProviderId = entSig.ent.deployPaymentProviderMap?.defaultPaymentProviderId;
        let isDirty = true;

        if(defaultPaymentProviderId && defaultPaymentProviderId === _defaultPaymentProviderId)
        {
          isDirty = false;
        }
        else
        {
          entSig.ent.deployPaymentProviderMap.defaultPaymentProviderId = defaultPaymentProviderId;
        }

        isDirty && verifyEntDirty(state, entId);
      }
    },

    addEntDeployPaymentProvider: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionAddEntDeployPaymentProvider>) =>
    {
      const {
        entId,
        sig,
        insertIndex
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        if(!entSig.ent.deployPaymentProviderMap)
        {
          entSig.ent.deployPaymentProviderMap = {
            map: {},
            keys: []
          };
        }

        const isDirty = Srvc.cache.studio.cacheStudioItemInsert(entSig.ent.deployPaymentProviderMap,
          sig,
          sig.metaId,
          insertIndex);

        isDirty && verifyEntDirty(state, entId);
      }
    },

    removeEntDeployPaymentProvider: (
      state: ICacheStudioStateEnt,
      action: PayloadAction<ActionRemoveEntDeployPaymentProvider>) =>
    {
      const {
        entId,
        metaId
      } = action.payload;

      const entSig = state.entMap[entId];

      if(entSig)
      {
        const deeplinkMap = entSig.ent.deployPaymentProviderMap;

        if(deeplinkMap)
        {
          const isDirty = Srvc.cache.studio.cacheStudioItemRemove(deeplinkMap, metaId);

          isDirty && verifyEntDirty(state, entId);
        }
      }
    },

    // endregion

    sortEntList(state: ICacheStudioStateEnt, action: PayloadAction<ActionSortEntList>)
    {
      const {
        entId,
        setKey,
        sortType
      } = action.payload;

      const ent = state.entMap[entId].ent;
      if(ent)
      {
        switch(setKey)
        {
          case "automationList":
            if(ent.automationMap.keys.length > 1)
            {
              ent.automationMap.keys.sort((a, b) =>
              {
                const nameA = ent.automationMap.map[a].name.toLowerCase();
                const nameB = ent.automationMap.map[b].name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });
            }
            break;
          case "variableList":
            if(ent.varMap.keys.length > 1)
            {
              ent.varMap.keys.sort((a, b) =>
              {
                const nameA = ent.varMap.map[a].details.name.toLowerCase();
                const nameB = ent.varMap.map[b].details.name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "translationList":
            if(ent.translationMap.keys.length > 1)
            {
              ent.translationMap.keys.sort((a, b) =>
              {
                const nameA = ent.translationMap.map[a].phrase?.toLowerCase() ?? "";
                const nameB = ent.translationMap.map[b].phrase?.toLowerCase() ?? "";
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "groupList":
            if(ent.groupMap.keys.length > 1)
            {
              ent.groupMap.keys.sort((a, b) =>
              {
                const nameA = ent.groupMap.map[a].details.name.toLowerCase();
                const nameB = ent.groupMap.map[b].details.name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "actionList":
            if(ent.actionMap.keys.length > 1)
            {
              ent.actionMap.keys.sort((a, b) =>
              {
                const nameA = ent.actionMap.map[a].details.name.toLowerCase();
                const nameB = ent.actionMap.map[b].details.name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "reportList":
            if(ent.reportMap.keys.length > 1)
            {
              ent.reportMap.keys.sort((a, b) =>
              {
                const nameA = ent.reportMap.map[a].details.name.toLowerCase();
                const nameB = ent.reportMap.map[b].details.name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "roleList":
            if(ent.roleMap.keys.length > 1)
            {
              ent.roleMap.keys.sort((a, b) =>
              {
                const nameA = ent.roleMap.map[a].details.name.toLowerCase();
                const nameB = ent.roleMap.map[b].details.name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "spreadsheetList":
            if(ent.spreadsheetMap.keys.length > 1)
            {
              ent.spreadsheetMap.keys.sort((a, b) =>
              {
                const nameA = ent.spreadsheetMap.map[a].details.name.toLowerCase();
                const nameB = ent.spreadsheetMap.map[b].details.name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "formList":
            if(ent.formMap.keys.length > 1)
            {
              ent.formMap.keys.sort((a, b) =>
              {
                const nameA = ent.formMap.map[a].details.name.toLowerCase();
                const nameB = ent.formMap.map[b].details.name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "importList":
            if(ent.pluginMap.keys.length > 1)
            {
              ent.pluginMap.keys.sort((a, b) =>
              {
                const nameA = ent.pluginMap.map[a].pluginName.toLowerCase();
                const nameB = ent.pluginMap.map[b].pluginName.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "promptList":
            if(ent.promptMap.keys.length > 1)
            {
              ent.promptMap.keys.sort((a, b) =>
              {
                const nameA = ent.promptMap.map[a].name.toLowerCase();
                const nameB = ent.promptMap.map[b].name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "deeplinkMap":
            if(ent.deeplinkMap.keys.length > 1)
            {
              ent.deeplinkMap.keys.sort((a, b) =>
              {
                const nameA = ent.deeplinkMap.map[a].name.toLowerCase();
                const nameB = ent.deeplinkMap.map[b].name.toLowerCase();
                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
          case "driveSheetList":
            if(ent.driveSheetMap.keys.length > 1)
            {
              ent.driveSheetMap.keys.sort((a, b) =>
              {
                const spreadsheetIdA = ent.driveSheetMap.map[a].spreadsheetId;
                const spreadsheetIdB = ent.driveSheetMap.map[b].spreadsheetId;

                const nameA = ent.spreadsheetMap.map[spreadsheetIdA].details.name;
                const nameB = ent.spreadsheetMap.map[spreadsheetIdB].details.name;

                return sortType === "sortDesc" ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0) :
                  (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
              });

              verifyEntDirty(state, entId);
            }
            break;
        }
      }
    },

    moveEntItem(state: ICacheStudioStateEnt, action: PayloadAction<ActionMoveEntItem>)
    {
      const {
        entId,
        metaId,
        setKey,
        moveDirection,
        startIndex,
        endIndex
      } = action.payload;

      const ent = state.entMap[entId].ent;
      if(ent)
      {
        let keys = [] as MetaId[];
        switch(setKey)
        {
          case "variableList":
            keys = ent.varMap.keys;
            break;
          case "translationList":
            keys = ent.translationMap.keys;
            break;
          case "groupList":
            keys = ent.groupMap.keys;
            break;
          case "actionList":
            keys = ent.actionMap.keys;
            break;
          case "reportList":
            keys = ent.reportMap.keys;
            break;
          case "roleList":
            keys = ent.roleMap.keys;
            break;
          case "spreadsheetList":
            keys = ent.spreadsheetMap.keys;
            break;
          case "formList":
            keys = ent.formMap.keys;
            break;
          case "automationList":
            keys = ent.automationMap.keys;
            break;
          case "driveSheetList":
            keys = ent.driveSheetMap.keys;
            break;
          case "importList":
            keys = ent.pluginMap.keys;
            break;
          case "promptList":
            keys = ent.promptMap.keys;
            break;
          case "deeplinkMap":
            keys = ent.deeplinkMap.keys;
            break;
          case "deployPaymentProviderList":
            keys = ent.deployPaymentProviderMap?.keys ?? [];
            break;
        }

        if(startIndex !== undefined && endIndex !== undefined)
        {
          moveListItemIndex(keys, startIndex, endIndex);
        }
        else if(moveDirection)
        {
          moveListItem(metaId, keys, moveDirection);
        }

        verifyEntDirty(state, entId);
      }
    },

    moveFormItem(state: ICacheStudioStateEnt, action: PayloadAction<ActionMoveFormItem>)
    {
      const {
        entId,
        formId,
        fieldId,
        compositeId,
        moveDirection,
        formulaId,
        variant,
        visibilityRuleId,
        contentLayoutId
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        const form = entSig.ent.formMap.map[formId];
        const compositeMap = form.compositeMap;
        switch(variant)
        {
          case "field":
          {
            if(compositeId)
            {
              const composite = compositeMap?.map[compositeId];
              const fieldMapKeys = composite?.fieldMap.keys;
              fieldMapKeys && fieldId && moveListItem(fieldId, fieldMapKeys, moveDirection);
              verifyEntDirty(state, entId);
            }
          }
            break;
          case "composite":
            compositeId && moveListItem(compositeId, compositeMap.keys, moveDirection);
            verifyEntDirty(state, entId);
            break;
          case "formula":
          {
            const formulaMap = form.formulaMap;
            const formulaMapKeys = formulaMap?.keys;
            formulaMapKeys && formulaId && moveListItem(formulaId, formulaMapKeys, moveDirection);
            verifyEntDirty(state, entId);
          }
            break;
          case "visibilityRule":
          {
            const visibilityRuleMap = form.visibilityRuleMap;
            const visibilityRuleMapKeys = visibilityRuleMap?.keys;
            visibilityRuleMapKeys && visibilityRuleId && moveListItem(visibilityRuleId,
              visibilityRuleMapKeys,
              moveDirection
            );
            verifyEntDirty(state, entId);
          }
            break;
          case "contentLayout":
          {
            const contentMap = form.layoutMap;
            const contentMapKeys = contentMap?.keys;
            contentMapKeys && contentLayoutId && moveListItem(contentLayoutId,
              contentMapKeys,
              moveDirection
            );
            verifyEntDirty(state, entId);
          }
            break;
        }
      }
    },

    dragFormItem(state: ICacheStudioStateEnt, action: PayloadAction<ActionDragFormItem>)
    {
      const {
        entId,
        formId,
        itemId,
        variant,
        fromCompId,
        fromCompIndex,
        toCompId,
        toCompIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        const form = entSig.ent.formMap.map[formId];
        const compositeMap = form.compositeMap;
        switch(variant)
        {
          case "field":
          {
            if(fromCompId && toCompId)
            {
              const field = compositeMap?.map[fromCompId]?.fieldMap.map[itemId];
              if(field?.type === "ref"
                || field?.type === "refReport"
                || field?.type === "refUser"
                || field?.type === "pickReportRow"
                || field?.type === "pickGridRow"
                || (field as StudioFieldEditable)?.refFieldId)
              {
                if(fromCompId !== toCompId)
                {
                  return;
                }
              }
              const fromComposite = compositeMap?.map[fromCompId];
              const toComposite = compositeMap?.map[toCompId];
              if(fromComposite && toComposite && fromCompIndex !== undefined && toCompIndex !== undefined)
              {
                fromComposite?.fieldMap.keys.splice(fromCompIndex, 1);

                if(fromCompId !== toCompId)
                {
                  entSig.ent.trash = entSig.ent.trash ?? {};
                  entSig.ent.trash.fieldMap = entSig.ent.trash.fieldMap ?? {};
                  entSig.ent.trash.fieldMap[itemId] = fromComposite.fieldMap.map[itemId];

                  const newFieldId = nextMetaIdField();
                  toComposite?.fieldMap.keys.splice(toCompIndex, 0, newFieldId);
                  toComposite.fieldMap.map[newFieldId] = {
                    ...fromComposite.fieldMap.map[itemId],
                    metaId: newFieldId
                  };
                  delete fromComposite.fieldMap.map[itemId];
                }
                else
                {
                  toComposite?.fieldMap.keys.splice(toCompIndex, 0, itemId);
                }

                verifyEntDirty(state, entId);
              }
            }
          }
            break;
        }
      }
    },

    moveAutomationEvent(state: ICacheStudioStateEnt, action: PayloadAction<ActionMoveAutomationEvent>)
    {
      const {
        entId,
        automationId,
        automationEventId,
        moveDirection
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        const automation = entSig.ent.automationMap.map[automationId] as (StudioEntAutomationSpreadsheet | StudioEntAutomationScheduled);
        const eventKeys = automation.eventMap.keys;

        moveListItem(automationEventId, eventKeys, moveDirection);
        verifyEntDirty(state, entId);
      }
    },

    moveAutomationStep(state: ICacheStudioStateEnt, action: PayloadAction<ActionMoveAutomationStep>)
    {
      const {
        entId,
        automationId,
        automationEventId,
        automationStepId,
        moveDirection
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        const automation = entSig.ent.automationMap.map[automationId] as (StudioEntAutomationSpreadsheet | StudioEntAutomationScheduled);
        const event = automation.eventMap.map[automationEventId];
        const stepKeys = event.stepMap.keys;

        moveListItem(automationStepId, stepKeys, moveDirection);
        verifyEntDirty(state, entId);
      }
    },

    dragAutomationStep(state: ICacheStudioStateEnt, action: PayloadAction<ActionDragAutomationStep>)
    {
      const {
        entId,
        metaIdAutomation,
        fromEventId,
        fromStepIndex,
        toEventId,
        toStepIndex
      } = action.payload;

      const entSig = state.entMap[entId];
      if(entSig)
      {
        const automation = entSig.ent.automationMap.map[metaIdAutomation] as (StudioEntAutomationSpreadsheet | StudioEntAutomationScheduled);
        if(fromEventId && toEventId)
        {
          const fromEvent = automation.eventMap.map[fromEventId];
          const toEvent = automation.eventMap.map[toEventId];

          if(fromEvent && toEvent && fromStepIndex !== undefined && toStepIndex !== undefined)
          {
            const stepId = fromEvent.stepMap.keys.splice(fromStepIndex, 1)[0];
            toEvent.stepMap.keys.splice(toStepIndex, 0, stepId);
            if(fromEventId !== toEventId)
            {
              toEvent.stepMap.map[stepId] = fromEvent.stepMap.map[stepId];
              delete fromEvent.stepMap.map[stepId];
            }
            verifyEntDirty(state, entId);
          }
        }
      }
    },

    setEntCurrentTabs(state: ICacheStudioStateEnt, action: PayloadAction<ActionEntStateMap>)
    {
      const {
        entId,
        currentStep,
        currentTab
      } = action.payload;

      if(currentTab && state.entStateMap[entId]?.currentTab !== currentTab)
      {
        state.entStateMap[entId] = {
          ...state.entStateMap[entId],
          currentTab: currentTab
        };
      }

      if(currentStep && state.entStateMap[entId]?.currentStep !== currentStep)
      {
        state.entStateMap[entId] = {
          ...state.entStateMap[entId],
          currentStep: currentStep
        };
      }
    },

    setEntDeployStatus(state: ICacheStudioStateEnt, action: PayloadAction<ActionEntDeployStatus>)
    {
      const {
        entId,
        deployStatus
      } = action.payload;

      let newStatus = {...deployStatus};
      let newJobKey = newStatus.jobKey;
      let currentJobKey = state.entStateMap[entId]?.deployStatus?.jobKey;

      if(deployStatus.executionState === "completed" || deployStatus.executionState === "failed")
      {
        if(newJobKey && newJobKey !== currentJobKey)
        {
          newStatus = {
            ...newStatus,
            jobKey: ""
          };
        }
      }

      state.entStateMap[entId] = {
        ...state.entStateMap[entId],
        deployStatus: newStatus
      };
    },

    removeEntDeployStatus(state: ICacheStudioStateEnt, action: PayloadAction<EntId>)
    {
      const entId = action.payload;

      state.entStateMap[entId] = {
        ...state.entStateMap[entId],
        deployStatus: undefined
      };
    },

    setEntSelectedModules(
      state: ICacheStudioStateEnt,
      action: PayloadAction<{
        entId: EntId,
        selectedModulesSet: StudioModuleSelection
      }>)
    {
      const selectedModules = action.payload.selectedModulesSet;
      const entId = action.payload.entId;
      if(entId && selectedModules)
      {
        state.selectedModuleMap[entId] = selectedModules;
      }
    },

    setEntUsageMap(
      state: ICacheStudioStateEnt,
      action: PayloadAction<ICacheStudioArtifactUsage & {entId: EntId}>)
    {
      const {
        entId,
        varUsageMap,
        roleUsageMap,
        formUsageMap
      } = action.payload;

      if(!state.entStateMap[entId]?.usageMap)
      {
        state.entStateMap[entId] = {
          ...state.entStateMap[entId],
          usageMap: {
            formUsageMap: formUsageMap,
            varUsageMap: varUsageMap,
            roleUsageMap: roleUsageMap
          }
        };
      }
      else
      {
        state.entStateMap[entId].usageMap = {
          formUsageMap: formUsageMap,
          varUsageMap: varUsageMap,
          roleUsageMap: roleUsageMap
        };
      }
    },

    setEntUsageFilter(
      state: ICacheStudioStateEnt,
      action: PayloadAction<IUsageFilter & {entId: EntId}>)
    {
      const {
        entId,
        formUsageFilter,
        variableUsageFilter,
        roleUsageFilter,
        translationUsageFilter
      } = action.payload;

      if(formUsageFilter && state.entStateMap[entId]?.usageFilter?.formUsageFilter !== formUsageFilter)
      {
        state.entStateMap[entId].usageFilter = {
          ...state.entStateMap[entId].usageFilter,
          formUsageFilter: formUsageFilter
        };
      }
      if(variableUsageFilter && state.entStateMap[entId]?.usageFilter?.variableUsageFilter !== variableUsageFilter)
      {
        state.entStateMap[entId].usageFilter = {
          ...state.entStateMap[entId].usageFilter,
          variableUsageFilter: variableUsageFilter
        };
      }
      if(roleUsageFilter && state.entStateMap[entId]?.usageFilter?.roleUsageFilter !== roleUsageFilter)
      {
        state.entStateMap[entId].usageFilter = {
          ...state.entStateMap[entId].usageFilter,
          roleUsageFilter: roleUsageFilter
        };
      }
      if(translationUsageFilter && state.entStateMap[entId]?.usageFilter?.translationUsageFilter
        !== translationUsageFilter)
      {
        state.entStateMap[entId].usageFilter = {
          ...state.entStateMap[entId].usageFilter,
          translationUsageFilter: translationUsageFilter
        };
      }
    }
  }
});

function checkIfFormExists(entSig: SigStudioEnt, formId: MetaIdForm)
{
  const form = entSig.ent.formMap.map[formId];
  if(!form)
  {
    throw new Error(`Form not found: ${formId}`);
  }
}

function verifyEntDirty(state: ICacheStudioStateEnt, entId: EntId)
{
  const dirtyEntIdList = state.dirtyEntIdList;
  !dirtyEntIdList.includes(entId) && dirtyEntIdList.push(entId);
  state.entStateMap[entId] = {
    ...state.entStateMap[entId],
    dirtyVersion: newGuid()
  };
}

function insertFormFieldRefUser(
  entSig: SigStudioEnt,
  refUserField: StudioFieldRefUser,
  parentComposite: StudioComposite
)
{
  const keys = parentComposite.fieldMap.keys;
  const map = parentComposite.fieldMap.map;
  const studioEnt = entSig.ent;
  const fieldRefUser = cloneDeep(refUserField);
  const metaId = fieldRefUser.metaId;
  const varMap = studioEnt.varMap;

  let isDirty = true;
  let refFieldIndex = keys.findIndex(key => key === metaId);
  if(refFieldIndex !== -1)
  {
    const oldSig = map[metaId] as StudioFieldRefUser;
    fieldRefUser.copyFieldVarMap = fnCalcRefCopyVarMap(oldSig?.copyFieldVarMap, fieldRefUser?.copyFieldVarMap);

    if(isDeepEqual(oldSig, fieldRefUser))
    {
      isDirty = false;
    }
  }
  else
  {
    keys.push(metaId);
  }

  const newCopyVarMap = {} as Record<MetaIdField, MetaIdVar>;
  let index = 0;

  Object.entries(fieldRefUser.copyFieldVarMap ?? {}).forEach(([key, value]) =>
  {
    const insertIndex = ((refFieldIndex !== -1 ? refFieldIndex : 0) + index) + 1;

    if(!parentComposite.fieldMap.map[key])
    {
      index++;
      const name = (varMap.map[value] as StudioVarUserSetting).details.name;
      const label = (varMap.map[value] as StudioVarUserSetting).details.label;
      const userSettingValue = (varMap.map[value] as StudioVarUserSetting).value;
      const refTargetId = (varMap.map[value] as StudioVarUserSetting).metaId;
      const kind = userSettingValue?.userSettingOptionKind;

      let fieldType: EnumStudioCompType | undefined;

      switch(kind)
      {
        case "text":
          fieldType = "text";
          break;
        case "pickOne":
          fieldType = "pickText";
          break;
        case "pickMany":
          fieldType = "setOfText";
          break;
      }

      const copyField = {
        details: {
          name: name,
          label: label,
          modules: {
            moduleIdSet: []
          }
        },
        type: fieldType,
        metaId: nextMetaIdField(),
        refFieldId: metaId,
        refTargetId: refTargetId,
        permissionMatrix: {
          defaultPermission: parentComposite.permissionMatrix?.defaultPermission || "write",
          map: {},
          keys: []
        }
      } as StudioFieldEditable;

      newCopyVarMap[copyField.metaId] = value;

      isDirty = Srvc.cache.studio.cacheStudioItemInsert(parentComposite.fieldMap,
        copyField,
        copyField.metaId,
        insertIndex
      );
    }
    else
    {
      newCopyVarMap[key] = value;
    }
  });

  fieldRefUser.copyFieldVarMap = newCopyVarMap;

  const parentCompositeKeys = [...parentComposite.fieldMap.keys];

  parentCompositeKeys.forEach((key) =>
  {
    const field = parentComposite.fieldMap.map[key] as StudioFieldEditable;
    if(field)
    {
      if(field?.refFieldId && field.refFieldId === metaId && !newCopyVarMap[field.metaId])
      {
        isDirty = Srvc.cache.studio.cacheStudioItemRemove(parentComposite.fieldMap, field.metaId);
      }
    }
  });
  map[metaId] = fieldRefUser;

  return isDirty;
}

function insertFormFieldRef(
  entSig: SigStudioEnt,
  field: TypeFieldRef,
  parentComposite: StudioComposite,
  formId: MetaIdForm)
{
  const keys = parentComposite.fieldMap.keys;
  const map = parentComposite.fieldMap.map;
  const fieldRef = cloneDeep(field);
  const metaId = fieldRef.metaId;
  const fieldType = fieldRef.type;
  const studioEnt = entSig.ent;

  let sourceFormId: MetaIdForm | undefined;
  let SourceName: string | undefined;
  let refTargetId: MetaId | undefined;

  switch(fieldType)
  {
    case "ref":
      const spreadsheetId = (fieldRef as StudioFieldRef)?.spreadsheetId;
      const SourceSpreadsheet = spreadsheetId
        ? studioEnt.spreadsheetMap.map[spreadsheetId]
        : undefined;
      SourceName = SourceSpreadsheet?.details.name;
      sourceFormId = SourceSpreadsheet?.formId;
      refTargetId = spreadsheetId;
      break;
    case "refReport":
    case "pickReportRow":
      const reportId = (fieldRef as StudioFieldPickReportRow | StudioFieldRefReport)?.reportId;
      const SourceReport = reportId
        ? studioEnt.reportMap.map[reportId]
        : undefined;
      SourceName = SourceReport?.details.name;
      sourceFormId = SourceReport?.outputFormId;
      refTargetId = reportId;
      break;
    case "pickGridRow":
      const gridId = (fieldRef as StudioFieldPickGridRow)?.gridId;
      const compositeMap = studioEnt.formMap.map[formId].compositeMap;
      const SourceGrid = gridId
        ? compositeMap.map[gridId]
        : undefined;
      SourceName = SourceGrid?.details.name;
      sourceFormId = formId;
      refTargetId = gridId;
      break;
  }

  const sourceForm = sourceFormId
    ? studioEnt.formMap.map[sourceFormId]
    : undefined;

  let isDirty = true;
  let refFieldIndex = keys.findIndex(key => key === metaId);
  if(refFieldIndex !== -1)
  {
    const oldSig = map[metaId] as TypeFieldRef;
    fieldRef.copyFieldMap = fnCalcRefCopyFieldMap(oldSig?.copyFieldMap, fieldRef?.copyFieldMap);

    if(isDeepEqual(oldSig, fieldRef))
    {
      isDirty = false;
    }
  }
  else
  {
    keys.push(metaId);
  }

  let sourceFieldMap = sourceForm
    ? fnGetSourceFieldMap(sourceForm)
    : {} as Record<MetaIdField, StudioField>;
  const newCopyFieldMap = {} as Record<MetaIdField, MetaIdField>;
  let index = 0;

  Object.entries(fieldRef.copyFieldMap ?? {}).forEach(([key, value]) =>
  {
    const insertIndex = ((refFieldIndex !== -1 ? refFieldIndex : 0) + index) + 1;

    if(!parentComposite.fieldMap.map[key])
    {
      index++;
      let copyField;
      if(isSystemField(value))
      {
        const name = value[0] === "$" ? value.substring(1) : value;
        copyField = {
          details: {
            name: `${SourceName}${name}`,
            label: `${SourceName}${name}`
          },
          type: getSystemFieldTypeSet(value as EnumDefnFields)[0],
          metaId: nextMetaIdField(),
          refFieldId: metaId,
          refTargetId: refTargetId,
          permissionMatrix: {
            defaultPermission: "invisible",
            map: {},
            keys: []
          }
        } as StudioFieldEditable;

        newCopyFieldMap[copyField.metaId] = value;
      }
      else
      {
        const sourceField = sourceFieldMap[value];
        copyField = {
          ...sourceField,
          details: {
            ...sourceField.details,
            name: sourceField.details.name,
            label: sourceField.details.label
          },
          metaId: nextMetaIdField(),
          refFieldId: metaId,
          refTargetId: refTargetId,
          permissionMatrix: {
            map: {},
            keys: []
          }
        } as StudioFieldEditable;
        newCopyFieldMap[copyField.metaId] = value;
      }
      isDirty = Srvc.cache.studio.cacheStudioItemInsert(parentComposite.fieldMap,
        copyField,
        copyField.metaId,
        insertIndex
      );
    }
    else
    {
      newCopyFieldMap[key] = value;
    }
  });

  fieldRef.copyFieldMap = newCopyFieldMap;
  const parentCompositeKeys = [...parentComposite.fieldMap.keys];
  parentCompositeKeys.forEach((key) =>
  {
    const field = parentComposite.fieldMap.map[key] as StudioFieldEditable;
    if(field)
    {
      if(field?.refFieldId && field.refFieldId === metaId && !newCopyFieldMap[field.metaId])
      {
        isDirty = Srvc.cache.studio.cacheStudioItemRemove(parentComposite.fieldMap, field.metaId);
      }
    }
  });
  map[metaId] = fieldRef;

  return isDirty;
}

function removeFormFieldRef(
  parentComposite: StudioComposite,
  fieldId: MetaIdField,
  _copyFieldMap?: Record<MetaIdField, MetaIdField>)
{
  const copyFieldMap = _copyFieldMap || {};
  let isDirty;
  [...parentComposite.fieldMap.keys].forEach((key) =>
  {
    const field = parentComposite.fieldMap.map[key] as StudioFieldEditable;
    if(field)
    {
      if(field.refFieldId && field.refFieldId === fieldId && copyFieldMap[field.metaId])
      {
        Srvc.cache.studio.cacheStudioItemRemove(parentComposite.fieldMap, field.metaId);
      }
    }
  });

  isDirty = Srvc.cache.studio.cacheStudioItemRemove(parentComposite.fieldMap, fieldId);

  return isDirty;
}

function fnGetSourceFieldMap(sourceForm: StudioForm)
{
  let sourceFieldMap = {} as Record<MetaIdField, StudioField>;

  sourceForm.compositeMap.keys.forEach((key) =>
  {
    const composite = sourceForm.compositeMap.map[key];
    if(composite)
    {
      sourceFieldMap = {
        ...sourceFieldMap,
        ...composite.fieldMap.map
      };
    }
  });

  return sourceFieldMap;
}

function fnCalcRefCopyFieldMap(
  oldCopyFieldMap?: Record<MetaIdField, MetaIdField>,
  newCopyFieldMap?: Record<MetaIdField, MetaIdField>)
{
  const copyFieldMap = {} as Record<MetaIdField, MetaIdField>;
  const systemFieldMap = arrayToRecord(EnumArrayDefnFields) as Record<string, EnumDefnFields>;

  if(oldCopyFieldMap && newCopyFieldMap)
  {
    const flippedOldCopyFieldMap = flipRecord(oldCopyFieldMap);
    Object.entries(newCopyFieldMap).forEach(([key, value]) =>
    {
      const copyFieldId = flippedOldCopyFieldMap[value] || systemFieldMap[key];
      if(copyFieldId)
      {
        copyFieldMap[copyFieldId] = value;
      }
      else
      {
        copyFieldMap[key] = value;
      }
    });
  }
  return copyFieldMap;
}

function fnCalcRefCopyVarMap(
  oldCopyVarMap?: Record<MetaIdField, MetaIdVar>,
  newCopyVarMap?: Record<MetaIdField, MetaIdVar>)
{
  const copyVarMap = {} as Record<MetaIdField, MetaIdVar>;
  if(oldCopyVarMap && newCopyVarMap)
  {
    const flippedOldCopyVarMap = flipRecord(oldCopyVarMap);
    Object.entries(newCopyVarMap).forEach(([key, value]) =>
    {
      const copyVarId = flippedOldCopyVarMap[value];
      if(copyVarId)
      {
        copyVarMap[copyVarId] = value;
      }
      else
      {
        copyVarMap[key] = value;
      }
    });
  }
  return copyVarMap;
}

//endregion

export const {
  setEnt,
  updateEnt,
  removeEnt,

  mergeEnt,       // incoming ent will be given first priority, local ent will be merged with incoming ent

  setIsSavingEnt,

  setSetupMode,

  addDeltaEntLocalState,
  removeDeltaEntLocalState,

  setEntUserList,
  removeEntUserList,

  setEntAdminList,
  removeEntAdminList,

  setEntAdminCaller,

  setEntDirty,
  setEntEditLockDetail,
  setSearchEntId,

  setEntSysFormMap,
  removeEntSysFormMap,

  updateEntDetails,
  updateEntModules,

  addEntVariable,
  removeEntVariable,

  addEntTranslation,
  removeEntTranslation,
  removeEntTranslationLanguage,

  addEntGroup,
  removeEntGroup,

  addEntPlugin,
  removeEntPlugin,

  addEntRole,
  removeEntRole,

  addEntSpreadsheet,
  removeEntSpreadsheet,

  addEntPrompt,
  removeEntPrompt,
  updateEntPromptSettings,

  addEntAction,
  removeEntAction,

  addEntReport,
  removeEntReport,

  addEntAutomation,
  removeEntAutomation,

  addEntAutomationEvent,
  removeEntAutomationEvent,

  addEntAutomationEventStep,
  removeEntAutomationEventStep,

  addEntForm,
  removeEntForm,

  addEntVisibilityRuleMap,
  removeEntVisibilityRuleMap,

  addEntContentLayoutMap,
  removeEntContentLayoutMap,

  addEntFormFormula,
  removeEntFormFormula,

  addEntFormComposite,
  removeEntFormComposite,

  addEntFormField,
  removeEntFormField,

  addEntDriveSheet,
  removeEntDriveSheet,
  updateEntDriveSpreadsheetSettings,

  addEntDeeplink,
  removeEntDeeplink,

  addEntDeployDefaultPaymentProviderId,
  addEntDeployPaymentProvider,
  removeEntDeployPaymentProvider,

  sortEntList,
  moveEntItem,
  moveFormItem,
  dragFormItem,

  moveAutomationEvent,
  moveAutomationStep,
  dragAutomationStep,

  addEntPluginUserHandel,
  addEntRequiredOnDeploymentVariable,
  addEntDeployPluginConfig,
  updateEntDeployManage,
  updateEntDeployPayment,

  setEntCurrentTabs,
  setEntDeployStatus,
  removeEntDeployStatus,
  setEntSelectedModules,

  setEntUsageMap,
  setEntUsageFilter,

  addEntDefaultAsideLayout

} = sliceCacheStudioEnt.actions;
