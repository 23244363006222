import {Checkbox, FormControlLabel} from "@mui/material";
import {debounce} from "lodash";
import {isEmpty} from "lodash";
import React, {useCallback, useEffect, useMemo} from "react";
import {DtoFieldFilter} from "../../api/ent/base/dto/DtoFieldFilter";
import {DtoFieldFilterOption} from "../../api/ent/base/dto/DtoFieldFilterOption";
import {SpreadsheetFilterValueBoolean} from "../../api/ent/base/dto/SpreadsheetFilterValueBoolean";
import {SpreadsheetFilterValueDateRange} from "../../api/ent/base/dto/SpreadsheetFilterValueDateRange";
import {SpreadsheetFilterValueDoubleRange} from "../../api/ent/base/dto/SpreadsheetFilterValueDoubleRange";
import {SpreadsheetFilterValueLongRange} from "../../api/ent/base/dto/SpreadsheetFilterValueLongRange";
import {SpreadsheetFilterValueStringSet} from "../../api/ent/base/dto/SpreadsheetFilterValueStringSet";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldCounter} from "../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldDateRange} from "../../api/meta/base/dto/DefnFieldDateRange";
import {DefnFieldDecimal} from "../../api/meta/base/dto/DefnFieldDecimal";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldRating} from "../../api/meta/base/dto/DefnFieldRating";
import {DefnFieldSetOfText} from "../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSlider} from "../../api/meta/base/dto/DefnFieldSlider";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnLayoutCard} from "../../api/meta/base/dto/DefnLayoutCard";
import {DefnLayoutGrid} from "../../api/meta/base/dto/DefnLayoutGrid";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {FieldSetOfOptionId} from "../../api/meta/base/dto/FieldSetOfOptionId";
import {FieldValueDateRange} from "../../api/meta/base/dto/FieldValueDateRange";
import {FieldValueDecimalRange} from "../../api/meta/base/dto/FieldValueDecimalRange";
import {FieldValueSwitch} from "../../api/meta/base/dto/FieldValueSwitch";
import {SysId} from "../../api/meta/base/SysId";
import {EnumDefnLayoutCardFilterKind, MetaIdComp, MetaIdField} from "../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../base/plus/JsPlus";
import {isSystemField} from "../../base/plus/StudioFormPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import {DefnFormUi, IFormRef} from "../../base/types/TypesForm";
import {DividerHorizontal} from "../atom/layout/DividerHorizontal";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import PaneFooter from "../atom/pane/PaneFooter";
import Form from "../form/viewer/Form";
import {useSsBrCtx} from "./base/CtxSsBr";

export default function SsBrFilters(props: {
  filterViewType: EnumDefnLayoutCardFilterKind,
  defnFormRef: DefnForm,
  cbOnClickShowSelected?: (showSelectedItems: boolean) => void,
  currentLayout?: DefnLayoutGrid
})
{
  const spreadSheetBrCtx = useSsBrCtx();
  const cbRef = useMemo(() => ({} as IFormRef), []);

  const filters = useMemo(() => spreadSheetBrCtx.getFilters(), [spreadSheetBrCtx]);
  const filterViewType = props.filterViewType;
  const defnFormRef = props.defnFormRef;
  const currentLayout = props.currentLayout;
  const cbOnClickShowSelected = props.cbOnClickShowSelected;

  const selectedFilters = spreadSheetBrCtx.getSelectedFilters();
  const isShowSelectedEnabled = spreadSheetBrCtx.getIsShowSelected();
  const lastRemovedTagId = spreadSheetBrCtx.getLastRemovedTagId();

  const filterDefnForm = useMemo(() => getDefn(filters, filterViewType, defnFormRef, currentLayout),
    [defnFormRef, filterViewType, filters, currentLayout]);
  const filterFieldMap = useMemo(() => getFilterFieldTypeMap(filters), [filters]);

  const onChangeGeneralFilter = useCallback((filter: DtoFieldFilter, sysId: SysId, select: boolean) =>
  {
    spreadSheetBrCtx.onChangeGeneralFilter({
      filterId: filter.metaIdField,
      sysId: sysId,
      select: select
    });
  }, [spreadSheetBrCtx]);

  const onChangeAdvancedFilterDoubleRange = debounce(useCallback((
    filter: DtoFieldFilter,
    value?: SpreadsheetFilterValueDoubleRange) =>
  {
    spreadSheetBrCtx.onChangeAdvancedFilter({
      filterId: filter.metaIdField,
      filter: value
    });
  }, [spreadSheetBrCtx]), 500);

  const onChangeAdvancedFilterLongRange = debounce(useCallback((
    filter: DtoFieldFilter,
    value?: SpreadsheetFilterValueLongRange) =>
  {
    spreadSheetBrCtx.onChangeAdvancedFilter({
      filterId: filter.metaIdField,
      filter: value
    });
  }, [spreadSheetBrCtx]), 500);

  const onChangeAdvancedFilterDateRange = useCallback((
    filter: DtoFieldFilter,
    value?: SpreadsheetFilterValueDateRange) =>
  {
    spreadSheetBrCtx.onChangeAdvancedFilter({
      filterId: filter.metaIdField,
      filter: value
    });
  }, [spreadSheetBrCtx]);

  const onChangeAdvancedFilterStringSet = useCallback((
    filter: DtoFieldFilter,
    value?: SpreadsheetFilterValueStringSet) =>
  {
    const _filterValue = value?.valueSet.length
      ? {
        type: "stringSet",
        metaIdField: filter.metaIdField,
        valueSet: value.valueSet
      } as SpreadsheetFilterValueStringSet
      : undefined;

    spreadSheetBrCtx.onChangeAdvancedFilter({
      filterId: filter.metaIdField,
      filter: _filterValue
    });
  }, [spreadSheetBrCtx]);

  const onChangeAdvancedFilterBoolean = useCallback((
    filter: DtoFieldFilter,
    value?: SpreadsheetFilterValueBoolean) =>
  {
    spreadSheetBrCtx.onChangeAdvancedFilter({
      filterId: filter.metaIdField,
      filter: value
    });
  }, [spreadSheetBrCtx]);

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(key)
    {
      const field = filterDefnForm.compMap[key] as DefnField;
      const filter = filterFieldMap[key];
      const defnFieldType = filter?.defnFieldType;

      if(key === fieldSortByFieldIdSet)
      {
        spreadSheetBrCtx.setSortByFieldId({
          sortByFieldId: fnFieldValueToRawValue("pickText", value) as string
        });
      }

      switch(defnFieldType)
      {
        case "ref":
        case "rowId":
          if(field?.type === "bool" && value !== undefined && value !== null)
          {
            const _value = (value as FieldValueSwitch).value;
            onChangeGeneralFilter(filter, field.metaId, Boolean(_value));
          }
          break;

        case "counter":
          if(field.type === "counter")
          {
            const counterValue = value
              ? fnFieldValueToRawValue("counter", value) as number | null | undefined
              : undefined;
            const longRangeValue = counterValue
              ? {
                type: "longRange",
                metaIdField: filter.metaIdField,
                max: counterValue,
                min: 0
              } as SpreadsheetFilterValueLongRange
              : undefined;
            onChangeAdvancedFilterLongRange(filter, longRangeValue);
          }
          break;

        case "number":
          if(field.type === "slider")
          {
            const sliderValue = value as FieldValueDecimalRange | null;
            const longRangeValue = sliderValue
              ? {
                type: "longRange",
                metaIdField: filter.metaIdField,
                min: sliderValue.minValue,
                max: sliderValue.maxValue
              } as SpreadsheetFilterValueLongRange
              : undefined;
            onChangeAdvancedFilterLongRange(filter, longRangeValue);
          }
          break;

        case "decimal":
          if(field.type === "decimal")
          {
            const doubleValue = value
              ? fnFieldValueToRawValue("decimal", value) as number | undefined
              : undefined;
            const doubleRangeValue = doubleValue
              ? {
                type: "doubleRange",
                metaIdField: filter.metaIdField,
                min: 0,
                max: doubleValue
              } as SpreadsheetFilterValueDoubleRange
              : undefined;
            onChangeAdvancedFilterDoubleRange(filter, doubleRangeValue);
          }
          break;

        case "slider":
          if(field?.type === "slider")
          {
            const sliderValue = value as FieldValueDecimalRange | null;
            const doubleRangeValue = sliderValue
              ? {
                type: "doubleRange",
                metaIdField: filter.metaIdField,
                min: sliderValue.minValue,
                max: sliderValue.maxValue
              } as SpreadsheetFilterValueDoubleRange
              : undefined;
            onChangeAdvancedFilterDoubleRange(filter, doubleRangeValue);
          }
          break;

        case "date":
        case "dateRange":
        case "dateTime":
        case "dateTimeRange":
          if(field?.type === "dateRange" || field?.type === "dateTimeRange")
          {
            const _value = value as FieldValueDateRange | undefined;
            const dateRangeValue = value
              ? {
                type: "dateRange",
                metaIdField: filter.metaIdField,
                from: _value?.from,
                to: _value?.to
              } as SpreadsheetFilterValueDateRange
              : undefined;
            onChangeAdvancedFilterDateRange(filter, dateRangeValue);
          }
          break;

        case "rating":
          if(field?.type === "rating")
          {
            const ratingValue = value
              ? fnFieldValueToRawValue("rating", value) as number | null | undefined
              : undefined;

            const doubleRangeValue = ratingValue
              ? {
                type: "doubleRange",
                metaIdField: filter.metaIdField,
                min: 0,
                max: ratingValue
              } as SpreadsheetFilterValueDoubleRange
              : undefined;
            onChangeAdvancedFilterDoubleRange(filter, doubleRangeValue);
          }
          break;

        case "text":
          if(field?.type === "text")
          {
            const text = value
              ? fnFieldValueToRawValue("text", value) as string | undefined
              : undefined;
            if(text)
            {
              if(text.length && text.length >= 2)
              {
                onChangeAdvancedFilterStringSet(filter, {
                  type: "stringSet",
                  metaIdField: filter.metaIdField,
                  valueSet: [text]
                } as SpreadsheetFilterValueStringSet);
              }
            }
            else
            {
              onChangeAdvancedFilterStringSet(filter);
            }
          }
          break;

        case "pickText":
          if(field?.type === "setOfText")
          {
            const setOfOptionIds = value as FieldSetOfOptionId | undefined;
            const stringSet = setOfOptionIds?.valueSet.length
              ? {
                type: "stringSet",
                metaIdField: filter.metaIdField,
                valueSet: setOfOptionIds.valueSet
              } as SpreadsheetFilterValueStringSet
              : undefined;
            onChangeAdvancedFilterStringSet(filter, stringSet);
          }
          break;

        case "bool":
          if(field.type === "bool")
          {
            const bool = fnFieldValueToRawValue("bool", value) as boolean | undefined;
            const filterBoolean = bool
              ? {
                type: "bool",
                metaIdField: filter.metaIdField,
                value: bool
              } as SpreadsheetFilterValueBoolean
              : undefined;
            onChangeAdvancedFilterBoolean(filter, filterBoolean);
          }
          break;
      }
    }
  }, [
    filterDefnForm.compMap,
    filterFieldMap,
    onChangeGeneralFilter,
    onChangeAdvancedFilterLongRange,
    onChangeAdvancedFilterDoubleRange,
    onChangeAdvancedFilterDateRange,
    onChangeAdvancedFilterStringSet,
    onChangeAdvancedFilterBoolean
  ]);

  useEffect(() =>
  {
    if(isEmpty(selectedFilters))
    {
      cbRef.remoteReset(undefined);
    }
  }, [cbRef, selectedFilters]);

  useEffect(() =>
  {
    if(lastRemovedTagId)
    {
      const [parentId, fieldId] = lastRemovedTagId.split("<>");

      if(fieldId === "")
      {
        cbRef.setValue(parentId, null);
      }
      else
      {
        const parentFilter = filterFieldMap[parentId];
        if(parentFilter.defnFieldType === "pickText")
        {
          const values = cbRef.getValue(parentId) as FieldSetOfOptionId | undefined;
          if(values)
          {
            const newValues = values.valueSet.filter(value => value !== fieldId);
            cbRef.setValue(parentId, {
              valueSet: newValues
            } as FieldSetOfOptionId);
          }
        }
        else
        {
          cbRef.setValue(fieldId, null);
        }
      }

      spreadSheetBrCtx.removeModule();
    }
  }, [cbRef, filterFieldMap, lastRemovedTagId]);

  return (
    <React.Fragment>
      <LayoutFlexCol
        height={0}
        flexGrow={1}
        width={"100%"}
        overflowX={"auto"}
        overflowY={"auto"}
      >
        <Form
          cbRef={cbRef}
          onWatch={onWatch}
          defnForm={filterDefnForm}
        />
      </LayoutFlexCol>

      <DividerHorizontal />

      <PaneFooter>
        <FormControlLabel
          control={<Checkbox
            size={"small"}
            checked={isShowSelectedEnabled}
            onChange={(_event, checked) =>
            {
              spreadSheetBrCtx.setShowSelected({showSelected: checked});
              cbOnClickShowSelected && cbOnClickShowSelected(checked);
            }}
          />}
          label={"Show selected"}
        />
      </PaneFooter>
    </React.Fragment>
  );
};

const advancedFiltersKey = "~~advancedFiltersKey~~";
const fieldSortByFieldIdSet = "~~fieldSortByFieldIdSet~~";

function getDefn(
  filters: DtoFieldFilter[],
  filterViewType: EnumDefnLayoutCardFilterKind,
  defnFormRef: DefnForm,
  currentLayout?: DefnLayoutGrid
)
{
  const commonFilters = filters.filter(filter =>
    filter.defnFieldType === "ref" || filter.defnFieldType === "rowId");

  const commonFiltersIds = commonFilters.map(filter => filter.metaIdField);
  const advancedFilters = filters.filter(filter => !commonFiltersIds.includes(filter.metaIdField));

  const {
    generalFiltersCompMap,
    generalFilterIds
  } = getGeneralFiltersDefn(commonFilters);

  const {
    advancedFiltersCompMap,
    advancedFilterIds
  } = getAdvancedFiltersDefn(advancedFilters, defnFormRef, currentLayout);

  const formDefinition = {
    ...generalFiltersCompMap,
    ...advancedFiltersCompMap
  } as Record<MetaIdField, DefnComp>;

  if(filterViewType === "tab")
  {
    const tabIdSet = advancedFilterIds.length
      ? [...generalFilterIds, advancedFiltersKey]
      : generalFilterIds;

    formDefinition[advancedFiltersKey] = {
      type: "section",
      metaId: advancedFiltersKey,
      name: "Advanced filters",
      fieldIdSet: advancedFilterIds
    } as DefnSection;

    formDefinition[defaultSectionKey] = {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: tabIdSet.length <= 2
        ? "fullWidth"
        : "scrollable",
      tabIdSet: tabIdSet
    } as DefnTab;
  }
  else
  {
    const fieldIdSet = advancedFilterIds.length
      ? [...generalFilterIds, advancedFiltersKey]
      : generalFilterIds;

    formDefinition[advancedFiltersKey] = {
      type: "section",
      metaId: advancedFiltersKey,
      name: "Advanced filters",
      fieldIdSet: advancedFilterIds
    } as DefnSection;

    formDefinition[defaultSectionKey] = {
      type: "tab",
      metaId: defaultSectionKey,
      tabIdSet: fieldIdSet,
      showAsTree: true
    } as DefnTab;
  }

  return {
    compMap: formDefinition,
    theme: {
      colSpacing: filterViewType === "tree" ? 0 : 1
    },
    displayCompositeId: defaultSectionKey
  } as DefnFormUi;
}

function getGeneralFiltersDefn(filters: DtoFieldFilter[])
{
  const definition: Record<string, DefnField> = {};
  const filterIds = [] as MetaIdComp[];

  for(const filter of filters)
  {
    if(filter.defnFieldType === "ref" || filter.defnFieldType === "rowId")
    {
      if(filter.valueList && filter.valueList.length > 0)
      {
        const label = filter.label || filter.name;
        definition[filter.metaIdField] = {
          type: "section",
          metaId: filter.metaIdField,
          fieldIdSet: filter.valueList.map((option) => option.value),
          name: label
        } as DefnSection;

        filterIds.push(filter.metaIdField);
        addBooleanFromValueList(filter.valueList, definition);
      }
    }
  }

  return {
    generalFiltersCompMap: definition,
    generalFilterIds: filterIds
  };
}

function addBooleanFromValueList(
  valueList: DtoFieldFilterOption[],
  definition: Record<string, DefnField>
)
{
  for(const option of valueList)
  {
    if(option.childFilters && option.childFilters.length > 0)
    {
      definition[option.value] = {
        type: "section",
        metaId: option.value,
        fieldIdSet: option.childFilters.map((childOption) => childOption.value),
        name: option.label,
        pt: 0,
        pb: 0
      } as DefnSection;

      addBooleanFromValueList(option.childFilters, definition);
    }
    else
    {
      definition[option.value] = {
        type: "bool",
        metaId: option.value,
        name: option.label,
        showAsCheckboxVar: true,
        pt: 0,
        pb: 0
      } as DefnFieldSwitch;
    }
  }
}

function getAdvancedFiltersDefn(
  filters: DtoFieldFilter[],
  defnFormRef: DefnForm,
  currentLayout?: DefnLayoutGrid
)
{
  const definition: Record<string, DefnField> = {};
  const filterIds = [] as MetaIdComp[];

  filters.forEach((filter, index) =>
  {
    const defnFieldType = filter.defnFieldType;
    const comp = defnFormRef.compMap[filter.metaIdField];
    const label = filter.label || filter.name;
    const paddingTop = index > 0 ? gapStd : undefined;
    switch(defnFieldType)
    {
      case "text":
        definition[filter.metaIdField] = {
          type: "text",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          minCharCountVar: 2,
          pt: paddingTop
        } as DefnFieldText;
        filterIds.push(filter.metaIdField);
        break;
      case "counter":
      {
        const maxVar = (comp as DefnFieldCounter)?.maxVar;
        const minVar = (comp as DefnFieldCounter)?.minVar;
        definition[filter.metaIdField] = {
          type: "counter",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          maxVar: maxVar,
          minVar: minVar,
          pt: paddingTop
        } as DefnFieldCounter;
      }
        break;
      case "pickText":
      {
        const options = filter.valueList?.map(option => ({
          metaId: option.value,
          value: option.label
        } as DefnDtoOption));

        definition[filter.metaIdField] = {
          type: "setOfText",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          optionMap: optionsToMapOfOption(options),
          pt: paddingTop
        } as DefnFieldSetOfText;
        filterIds.push(filter.metaIdField);
      }
        break;
      case "date":
      case "dateRange":
        definition[filter.metaIdField] = {
          type: "dateRange",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          pt: paddingTop,
          allowSingleDate: true
        } as DefnFieldDateRange;
        filterIds.push(filter.metaIdField);
        break;
      case "dateTime":
      case "dateTimeRange":
        definition[filter.metaIdField] = {
          type: "dateTimeRange",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          pt: paddingTop,
          allowSingleDate: true
        } as DefnFieldDateRange;
        filterIds.push(filter.metaIdField);
        break;
      case "number":
      case "slider":
      {
        const maxVar = (comp as DefnFieldNumber | DefnFieldSlider)?.maxVar;
        const minVar = (comp as DefnFieldNumber | DefnFieldSlider)?.minVar;
        definition[filter.metaIdField] = {
          type: "slider",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          maxVar: maxVar,
          minVar: minVar,
          allowRangePicker: Boolean(maxVar),
          showAsInputBox: Boolean(!maxVar),
          pt: paddingTop
        } as DefnFieldSlider;
        filterIds.push(filter.metaIdField);
      }
        break;
      case "decimal":
      {
        const maxVar = (comp as DefnFieldDecimal)?.maxVar;
        const minVar = (comp as DefnFieldDecimal)?.minVar;
        definition[filter.metaIdField] = {
          type: "decimal",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          maxVar: maxVar,
          minVar: minVar,
          pt: paddingTop
        } as DefnFieldDecimal;
        filterIds.push(filter.metaIdField);
      }
        break;
      case "rating":
        definition[filter.metaIdField] = {
          type: "rating",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          pt: paddingTop
        } as DefnFieldRating;
        filterIds.push(filter.metaIdField);
        break;
      case "bool":
        definition[filter.metaIdField] = {
          type: "bool",
          metaId: filter.metaIdField,
          name: label,
          label: label,
          pt: paddingTop
        } as DefnFieldSwitch;
        filterIds.push(filter.metaIdField);
    }
  });
  const layout = currentLayout as DefnLayoutCard;
  const sortByFieldIdSet = layout?.filter?.sortByFieldIdSet;

  if(sortByFieldIdSet && sortByFieldIdSet.length)
  {
    const keys = [] as string[];
    const map = {} as Record<string, DefnDtoOption>;

    sortByFieldIdSet.forEach(fieldId =>
    {
      if(isSystemField(fieldId))
      {
        keys.push(fieldId);
        map[fieldId] = {
          metaId: fieldId,
          value: fieldId
        };
      }
      else
      {
        const field = defnFormRef.compMap[fieldId] as DefnField;
        keys.push(fieldId);
        map[fieldId] = {
          metaId: fieldId,
          value: field.name
        };
      }
    });

    definition[fieldSortByFieldIdSet] = {
      type: "pickText",
      metaId: fieldSortByFieldIdSet,
      name: fieldSortByFieldIdSet,
      label: "Sort by",
      optionMap: {
        keys: keys,
        map: map
      },
      pt: gapStd
    } as DefnFieldPickText;
    filterIds.push(fieldSortByFieldIdSet);

  }

  return {
    advancedFiltersCompMap: definition,
    advancedFilterIds: filterIds
  };
}

function getFilterFieldTypeMap(filters: DtoFieldFilter[])
{
  const filterMap = {} as Record<MetaIdComp, DtoFieldFilter>;

  function recursive(valueList: DtoFieldFilterOption[], filter: DtoFieldFilter)
  {
    valueList.forEach(value =>
    {
      if(value.childFilters?.length)
      {
        recursive(value.childFilters, filter);
      }
      else
      {
        filterMap[value.value] = filter;
      }
    });
  }

  filters.forEach(filter =>
  {
    filterMap[filter.metaIdField] = filter;

    if(filter.valueList?.length)
    {
      recursive(filter.valueList, filter);
    }
  });

  return filterMap;
}
