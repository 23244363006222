import {Box} from "@mui/material";
import {OverlayViewF} from "@react-google-maps/api";
import {MarkerF} from "@react-google-maps/api";
import {OverlayView} from "@react-google-maps/api";
import {useCallback} from "react";
import {useMemo} from "react";
import React from "react";
import {SigEntAvatarUser} from "../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {SigUserAvatar} from "../../api/home/drawer/sig/SigUserAvatar";
import {isEntUserId} from "../../api/meta/base/ApiPlus";
import {EnumDefnMapPinShape} from "../../api/meta/base/Types";
import {useLocationMapResolver} from "../../base/plus/MapLayoutPlus";
import {getMediaSrc} from "../../base/plus/MediaPlus";
import {textUser} from "../../base/plus/SrvcPlus";
import {toLabel} from "../../base/plus/StringPlus";
import {px} from "../../base/plus/StringPlus";
import {gapQuarter} from "../../base/plus/ThemePlus";
import {gapHalf} from "../../base/plus/ThemePlus";
import theme from "../../base/plus/ThemePlus";
import {TypeMapMarker} from "../../base/types/TypeMap";
import {ILatLng} from "../../base/types/TypesStudio";
import {useAppSelector} from "../app/AppHooks";
import BoxAvatar from "../atom/box/BoxAvatar";
import RawTooltip from "../atom/raw/RawTooltip";
import useLocationPubSub from "./hooks/LocationPubSub";

const SVG_MARKER_PIN = "M7 20.0664C2.333 13.9764 0 9.64341 0 7.06641C0 5.20989 0.737498 3.42941 2.05025 2.11666C3.36301 0.803904 5.14348 0.0664063 7 0.0664062C8.85652 0.0664063 10.637 0.803904 11.9497 2.11666C13.2625 3.42941 14 5.20989 14 7.06641C14 9.64341 11.667 13.9764 7 20.0664ZM7 11.0664C8.06087 11.0664 9.07828 10.645 9.82843 9.89483C10.5786 9.14469 11 8.12727 11 7.06641C11 6.00554 10.5786 4.98812 9.82843 4.23798C9.07828 3.48783 8.06087 3.06641 7 3.06641C5.93913 3.06641 4.92172 3.48783 4.17157 4.23798C3.42143 4.98812 3 6.00554 3 7.06641C3 8.12727 3.42143 9.14469 4.17157 9.89483C4.92172 10.645 5.93913 11.0664 7 11.0664Z";
const SVG_MARKER_CIRCLE = "M7,32L7,32c-3.9,0-7-3.1-7-7l0,0c0-3.9,3.1-7,7-7h0c3.9,0,7,3.1,7,7v0C14,28.9,10.9,32,7,32z";
const SVG_MARKER_SQUARE = "M11,32H1c-0.6,0-1-0.4-1-1V21c0-0.6,0.4-1,1-1h10c0.6,0,1,0.4,1,1v10C12,31.6,11.6,32,11,32z";
const SVG_MARKER_TRIANGLE = "M7.1,19.6L0.9,30.5C0.5,31.2,1,32,1.7,32h12.5c0.8,0,1.3-0.8,0.9-1.5L8.9,19.6C8.5,19,7.5,19,7.1,19.6z";
const SVG_MARKER_STAR = "M8.7,17.2l1.6,4.1c0.1,0.3,0.3,0.4,0.6,0.4l4.4,0.3c0.6,0,0.9,0.8,0.4,1.3l-3.4,2.8c-0.2,0.2-0.3,0.5-0.2,0.7l1.1,4.3 c0.2,0.6-0.5,1.1-1.1,0.8l-3.7-2.4c-0.2-0.1-0.5-0.1-0.8,0l-3.7,2.4c-0.5,0.3-1.2-0.2-1.1-0.8l1.1-4.3c0.1-0.3,0-0.5-0.2-0.7 l-3.4-2.8C-0.2,22.9,0,22.1,0.7,22l4.4-0.3c0.3,0,0.5-0.2,0.6-0.4l1.6-4.1C7.6,16.6,8.4,16.6,8.7,17.2z";
const SVG_MARKER_HEART = "M0,22.9c0,3.9,3.2,6,5.6,7.8C6.4,31.4,7.2,32,8,32s1.6-0.6,2.4-1.3c2.4-1.9,5.6-3.9,5.6-7.8c0-3.9-4.4-6.6-8-2.9 C4.4,16.3,0,19,0,22.9z";

interface IMarkerPinShape
{
  path: string;
  scale: number;
}

export function MapMarker<SR1, SR2, SR3>(props: TypeMapMarker<SR1, SR2, SR3>)
{
  const defnForm = props.defnForm;
  const layout = props.layout;
  const value = props.value;
  const locationMarkerBinder = props.locationMarkerBinder;
  const cbOnClickMarker = props.cbOnClickMarker;
  const onMouseOverMarker = props.onMouseOverMarker;
  const onMouseOutMarker = props.onMouseOutMarker;

  const markerId = value.rowId;
  const selectUserAvatar = locationMarkerBinder?.selectUserAvatar;
  const selectEntAvatarUser = locationMarkerBinder?.selectEntAvatarUser;

  const {
    getColorValue,
    getLntLng,
    getTooltipValue,
    getShapeValue,
    getGroupByFieldValue
  } = useLocationMapResolver(defnForm, layout);

  const groupByFieldValue = useMemo(() => getGroupByFieldValue(value.valueMap), [getGroupByFieldValue]);

  const sigUserAvatar = useAppSelector(state =>
  {
    return (selectUserAvatar && groupByFieldValue && isEntUserId(groupByFieldValue))
      ? selectUserAvatar(state, markerId, groupByFieldValue)
      : undefined;
  });

  const entAvatarUser = useAppSelector(state =>
  {
    return selectEntAvatarUser
      ? selectEntAvatarUser(state)
      : undefined;
  });

  useLocationPubSub({
    markerId: markerId,
    locationMarkerBinder: locationMarkerBinder
  });

  const latLng = useMemo(() =>
  {
    return getLntLng(value.valueMap);

  }, [value.valueMap]);

  const position = useMemo(() =>
  {
    return latLng
      ? {
        lat: latLng.lat,
        lng: latLng.lng
      } as ILatLng
      : undefined;

  }, [latLng]);

  const markerPinShape = getMarkerPath(getShapeValue(value.valueMap));

  const colorValue = getColorValue(value.valueMap);

  const icon = useMemo(() =>
  {
    return {
      path: markerPinShape.path,
      scale: markerPinShape.scale,
      fillColor: colorValue,
      fillOpacity: 1,
      strokeColor: colorValue,
      anchor: new google.maps.Point(6, 20)
    };
  }, [colorValue, markerPinShape]);

  const onClickMarker = useCallback((event: EventTarget | null) =>
  {
    cbOnClickMarker && cbOnClickMarker(
      markerId,
      event,
      latLng
    );

  }, [cbOnClickMarker, markerId, latLng]);

  const onMouseOver = useCallback((item: google.maps.MapMouseEvent | null) =>
  {
    const position = item?.latLng;
    const markerToolTip = getTooltipValue(value.valueMap);

    if(markerToolTip && position)
    {
      onMouseOverMarker && onMouseOverMarker(position, {
        value: markerToolTip,
        showTooltip: true
      });
    }

  }, [getTooltipValue, onMouseOverMarker]);

  const onMouseOut = useCallback((item: google.maps.MapMouseEvent | null) =>
  {
    const position = item?.latLng;

    if(position)
    {
      onMouseOutMarker && onMouseOutMarker(position);
    }

  }, [onMouseOutMarker]);

  if(!position)
  {
    return null;
  }

  if(layout?.renderingMode === "liveLocation")
  {
    return <UserNameMarker
      key={markerId}
      position={position}
      sigUserAvatar={sigUserAvatar}
      entAvatarUser={entAvatarUser}
      backgroundColor={colorValue ?? theme.common.color("black")}
      onClick={onClickMarker}
      groupByFieldValue={groupByFieldValue}
    />;
  }

  return (
    <MarkerF
      key={markerId}
      position={position}
      draggable={false}
      icon={icon}
      animation={window.google.maps.Animation.DROP}
      onClick={(e) => onClickMarker((e.domEvent as Event).currentTarget)}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
}

function UserNameMarker(props: {
  position: ILatLng,
  backgroundColor: string,
  groupByFieldValue?: string,
  sigUserAvatar?: SigUserAvatar,
  entAvatarUser?: SigEntAvatarUser,
  onClick: (event: EventTarget | null) => void
})
{
  const position = props.position;
  const sigUserAvatar = props.sigUserAvatar;
  const entAvatarUser = props.entAvatarUser;
  const groupByFieldValue = props.groupByFieldValue;
  const backgroundColor = props.backgroundColor;
  const onClick = props.onClick;

  const sizeAvatar = theme.common.sizeAvatar;
  const primaryText = sigUserAvatar
    ? textUser(sigUserAvatar)
    : groupByFieldValue;

  const getPixelPositionOffset = useCallback((width: number, height: number) =>
  {
    return {
      x: -(width / 2.5),
      y: -height - 12
    };

  }, []);

  return (
    <OverlayViewF
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <RawTooltip
        title={toLabel(primaryText ?? "")}
        placement={"top-start"}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: px(sizeAvatar),
            height: px(sizeAvatar)
          }}
          onClick={(e) =>
          {
            e.stopPropagation();

            onClick(e.currentTarget);
          }}
        >

          <Box
            style={{
              position: "absolute",
              right: "7%",
              bottom: 0,
              borderRadius: "50%",
              border: `${px(gapQuarter)} solid ${backgroundColor}`,
              pointerEvents: "none"
            }}
            onClick={(e) =>
            {
              e.stopPropagation();

              onClick(e.currentTarget);
            }}
          >
            <BoxAvatar
              avatar={{
                src: getMediaSrc(sigUserAvatar?.avatarId || entAvatarUser?.avatarId),
                icon: "user",
                tooltip: toLabel(primaryText ?? "")
              }}
            />
          </Box>

          <Box
            style={{
              width: 0,
              height: 0,
              borderLeft: `${px(10)} solid transparent`,
              borderRight: `${px(10)} solid transparent`,
              borderTop: `${px(12)} solid ${backgroundColor}`,
              position: "absolute",
              bottom: `-${px(gapHalf)}`,
              right: "40%",
              zIndex: -1
            }}
          />

        </Box>

      </RawTooltip>

    </OverlayViewF>
  );
}

function getMarkerPath(value?: EnumDefnMapPinShape): IMarkerPinShape
{
  switch(value)
  {
    case "circle":
      return {
        path: SVG_MARKER_CIRCLE,
        scale: 1.2
      };
    case "circleMedium":
      return {
        path: SVG_MARKER_CIRCLE,
        scale: 1.5
      };
    case "circleBig":
      return {
        path: SVG_MARKER_CIRCLE,
        scale: 1.7
      };
    case "square":
      return {
        path: SVG_MARKER_SQUARE,
        scale: 1.2
      };
    case "squareMedium":
      return {
        path: SVG_MARKER_SQUARE,
        scale: 1.5
      };
    case "squareBig":
      return {
        path: SVG_MARKER_SQUARE,
        scale: 1.7
      };
    case "triangle":
      return {
        path: SVG_MARKER_TRIANGLE,
        scale: 1.2
      };
    case "triangleMedium":
      return {
        path: SVG_MARKER_TRIANGLE,
        scale: 1.5
      };
    case "triangleBig":
      return {
        path: SVG_MARKER_TRIANGLE,
        scale: 1.7
      };
    case "star":
      return {
        path: SVG_MARKER_STAR,
        scale: 1.2
      };
    case "starMedium":
      return {
        path: SVG_MARKER_STAR,
        scale: 1.5
      };
    case "starBig":
      return {
        path: SVG_MARKER_STAR,
        scale: 1.7
      };
    case "heart":
      return {
        path: SVG_MARKER_HEART,
        scale: 1.2
      };
    case "heartMedium":
      return {
        path: SVG_MARKER_HEART,
        scale: 1.5
      };
    case "heartBig":
      return {
        path: SVG_MARKER_HEART,
        scale: 1.7
      };
    case "pin":
      return {
        path: SVG_MARKER_PIN,
        scale: 1.2
      };
    default:
      return {
        path: SVG_MARKER_PIN,
        scale: 1.2
      };
  }
}
