import {Box} from "@mui/material";
import React from "react";
import {DateCellWrapperProps} from "react-big-calendar";
import {calendarShellBorderColor} from "../../../../base/plus/CalendarPlus";
import theme from "../../../../base/plus/ThemePlus";

export default function CalendarCellWrapper(props: {
  dateCellProps: DateCellWrapperProps,
  currentDate: Date
})
{
  const cellDate = props.dateCellProps.value;
  const isOtherMonth = props.currentDate.getMonth() !== cellDate.getMonth();

  const cellWrapperBgColor = isOtherMonth
    ? theme.common.bgcolorDefault
    : theme.common.bgcolorContent;

  return (
    <Box
      sx={{
        flexGrow: 1,
        borderRight: `1px solid ${calendarShellBorderColor}`,
        bgcolor: cellWrapperBgColor
      }}
    >
    </Box>
  );
};
