import {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {DtoAutomationStateInfo} from "../../../api/ent/base/dto/DtoAutomationStateInfo";
import {DtoAutomationVariableInfo} from "../../../api/ent/base/dto/DtoAutomationVariableInfo";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DtoLogTree} from "../../../api/meta/base/dto/DtoLogTree";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {AutomationExecutionId} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {IDebuggerLogEntry} from "./TypeCacheControlPanelEnt";
import {IControlPanelEntQuery} from "./TypeCacheControlPanelEnt";
import {TypeControlPanelEntMap} from "./TypeCacheControlPanelEnt";
import {ICacheControlPanelStateEnt} from "./TypeCacheControlPanelEnt";

export const sliceCacheControlPanelEnt = createSlice({
  name: "cacheControlPanelMain",
  initialState: {
    entMap: {} as TypeControlPanelEntMap
  } as ICacheControlPanelStateEnt,
  reducers: {
    initializeEntDebugger: (state, action: PayloadAction<EntId>) =>
    {
      const entId = action.payload;
      ensureDebuggerCache(entId, state);
    },
    setEntQuery: (state, action: PayloadAction<{entId: EntId, query: IControlPanelEntQuery}>) =>
    {
      const {
        entId,
        query
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];
      entCache.query = query;
    },
    setEntQueryResult: (state, action: PayloadAction<{
      entId: EntId, logTree?: DtoLogTree, defnOutputForm?: DefnForm;
      outputFormValue?: FormValue;
    }>) =>
    {
      const {
        entId,
        logTree,
        defnOutputForm,
        outputFormValue
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];
      entCache.query.resultLogTree = logTree;
      entCache.query.defnOutputForm = defnOutputForm;
      entCache.query.outputFormValue = outputFormValue;
    },
    setEntQueryInputForm: (state, action: PayloadAction<{entId: EntId, inputFormId: MetaIdForm}>) =>
    {
      const {
        entId,
        inputFormId
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];
      entCache.query.inputFormId = inputFormId;
    },
    setEntQueryOutputForm: (state, action: PayloadAction<{entId: EntId, outputFormId: MetaIdForm}>) =>
    {
      const {
        entId,
        outputFormId
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];
      if(entCache)
      {
        entCache.query.outputFormId = outputFormId;
      }
    },
    setEntQueryCurrentQuery: (state,
      action: PayloadAction<{entId: EntId, currentQuery?: string, saveToCache?: boolean}>) =>
    {
      const {
        entId,
        currentQuery,
        saveToCache
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];

      entCache.query.currentQuery = currentQuery;
      if(saveToCache && currentQuery)
      {
        const cacheQueries = entCache.query.cacheQueries;
        if(cacheQueries.includes(currentQuery))
        {
          return;
        }
        if(cacheQueries.length === 10)
        {
          cacheQueries.shift();
        }
        cacheQueries.push(currentQuery);
      }
    },
    setAutomationStateInfoList: (state,
      action: PayloadAction<{entId: EntId, automationStateInfoList: DtoAutomationStateInfo[]}>) =>
    {
      const {
        entId,
        automationStateInfoList
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];
      entCache.automation.automationStateInfoList = automationStateInfoList;
    },
    setAutomationExecutionStatus: (state,
      action: PayloadAction<{
        entId: EntId,
        executionId: AutomationExecutionId,
        variables?: DtoAutomationVariableInfo[]
      }>) =>
    {
      const {
        entId,
        executionId,
        variables
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];
      entCache.automation.selectedExecutionId = executionId;
      entCache.automation.variableMap = variables?.reduce((acc: Record<string, DtoAutomationVariableInfo>,
        curr) =>
      {
        const id = curr.form.metaId;
        if(id)
        {
          acc[id] = curr;
        }
        return acc;
      }, {});
    },
    setDebuggerList: (state,
      action: PayloadAction<{entId: EntId, logList: IDebuggerLogEntry[]}>) =>
    {
      const {
        entId,
        logList
      } = action.payload;
      ensureDebuggerCache(entId, state);
      const entCache = state.entMap[entId];
      entCache.log.logList = logList;
    }
  }
});

export const {
  initializeEntDebugger,
  setEntQuery,
  setEntQueryResult,
  setEntQueryInputForm,
  setEntQueryOutputForm,
  setEntQueryCurrentQuery,
  setAutomationStateInfoList,
  setAutomationExecutionStatus,
  setDebuggerList
} = sliceCacheControlPanelEnt.actions;

function ensureDebuggerCache(entId: EntId, state: ICacheControlPanelStateEnt)
{
  const entCache = state.entMap[entId];
  if(!entCache)
  {
    state.entMap[entId] = {
      query: {
        cacheQueries: []
      },
      automation: {
        automationStateInfoList: []
      },
      log: {
        logList: []
      }
    };
  }
}
