import {EnumAutomationStateFilterKind} from "../../../../api/ent/base/Types";
import {MsgAutomationStateInfoList} from "../../../../api/ent/entMain/msg/MsgAutomationStateInfoList";
import {RpcEntMain} from "../../../../api/ent/entMain/RpcEntMain";
import {SigAutomationState} from "../../../../api/ent/entMain/sig/SigAutomationState";
import {AutomationExecutionId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import {getErrorMessage} from "../../../../api/nucleus/base/Protocol";
import ISrvc from "../../../../base/ISrvc";
import {CbSuccess} from "../../../../base/types/TypesGlobal";
import {Srvc} from "../../../../srvc/Srvc";
import {store} from "../../../../Store";
import {setAutomationExecutionStatus} from "../SliceCacheControlPanelEnt";
import {setAutomationStateInfoList} from "../SliceCacheControlPanelEnt";

export type CbSuccessAutomationExecutionStatusGet = (sig?: SigAutomationState) => void;
export default class SrvcCacheControlPanelAutomation extends ISrvc
{
  rpcEntAutomationInfoListGet(
    entId: EntId,
    filterAutomationStateSet?: EnumAutomationStateFilterKind[],
    cbSuccess?: CbSuccess)
  {
    const msg = {
      filterAutomationStateSet: filterAutomationStateSet
    } as MsgAutomationStateInfoList;

    RpcEntMain.entAutomationStateInfoListGet(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;
      store.dispatch(setAutomationStateInfoList({
        entId: entId,
        automationStateInfoList: sig?.automationStateInfoList ?? []
      }));

      cbSuccess && cbSuccess();
    });
  }

  rpcEntAutomationExecutionStatusGet(
    entId: EntId,
    executionId: AutomationExecutionId,
    cbSuccess?: CbSuccessAutomationExecutionStatusGet)
  {
    const msg = {automationExecutionId: executionId};
    RpcEntMain.automationExecutionStatusGet(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;

      cbSuccess && cbSuccess(sig);
      store.dispatch(setAutomationExecutionStatus({
        entId,
        executionId,
        variables: sig?.variables
      }));
    });
  }

  rpcEntAutomationResume(
    entId: EntId,
    executionId: AutomationExecutionId,
    filterAutomationStateSet?: EnumAutomationStateFilterKind[],
    cbSuccess?: CbSuccess)
  {
    const msg = {
      automationExecutionId: executionId
    };

    RpcEntMain.entAutomationResume(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }

      cbSuccess && cbSuccess();

      this.rpcEntAutomationInfoListGet(entId, filterAutomationStateSet);
    });
  }
}
